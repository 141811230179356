import { WidgetOptions } from '@sprinklr/stories/widget/WidgetOptions';
import { WidgetOptionsImpl } from 'models/Widget/WidgetOptions';
import { WidgetFont } from '@sprinklr/stories/widget/Widget';
import { observable } from 'mobx';
import i18n from 'i18next';
import { AlignmentOptions } from '../StackedBarChartWidget/types';
import { ButtonClick } from 'components/Widget/WidgetComponent/types';

export const linkTypes = isPresentations => {
    return [
        {
            value: 'url',
            label: i18n.t('URL'),
        },
        {
            value: 'slideNumber',
            label: isPresentations ? i18n.t('Slide Number') : i18n.t('Scene Number'),
        },
    ];
};

export interface HyperlinkButtonWidgetOptions extends WidgetOptions {
    url: any;
    text?: any;
    backgroundColor?: any;
    buttonBorder?: {
        enabled: boolean;
        color?: any;
        size?: any;
        radius?: number;
    };
    font?: WidgetFont;
    slideNumber?: {
        id?: number;
        name?: string;
    };
    onButtonClick?: ButtonClick;
    linkType?: string;
    buttonTextAlignment?: AlignmentOptions;
}

export class HyperlinkButtonWidgetOptionsImpl extends WidgetOptionsImpl
    implements HyperlinkButtonWidgetOptions {
    @observable size = 15;
    @observable url = '';
    @observable text = '';
    @observable fontSize = 25;
    @observable backgroundColor = '';
    @observable colorFont = '';
    @observable buttonBorder = {
        enabled: false,
        size: 0,
        radius: 0,
        color: '',
    };
    @observable linkType = 'url';
    @observable buttonTextAlignment = 'center' as AlignmentOptions;
    @observable font = {
        family: '',
    };
}
