import { ComputedStyle, styler } from 'utils/GenerateStyles/GenerateStyles';

export const treeMapOptionStyles = options => {
    const { spacing, padding, sentimentSize } = options;

    const computedStyles: ComputedStyle[] = [
        {
            selector: '.node',
            styles: {
                padding: styler(spacing, 0.1, 'em', ''),
            },
        },
        {
            selector: '.node_inner',
            styles: {
                padding: styler(padding, 0.1, 'em', ''),
            },
        },
        {
            selector: '.icon__container',
            styles: {
                fontSize: styler(sentimentSize, 0.015, 'em', ''),
            },
        },
    ];

    return computedStyles;
};
