import {
    AnalyticsFilter,
    AnalyticsGroupBy,
    AnalyticsProjection,
    AnalyticsRequest,
    AnalyticsSort,
} from '@sprinklr/stories/analytics/AnalyticsRequest';
import {
    Projection,
    WidgetRequestGroupBy,
    Page,
    Sort,
    Filter,
} from '@sprinklr/modules/research/widget/types';

import { AnalyticsTimePeriod } from '@sprinklr/stories/analytics/AnalyticsRequest';
import { FilterType } from '@sprinklr/modules/filters/types/filter';
import {
    SpaceBatchRequest,
    SpacePreviousTimeFilter,
    SpaceRequest,
    SpaceTimeFilter,
} from '@sprinklr/stories/reporting/types';
import { decorators, formatHeading, getProjectionDecorations } from './toReportingRequests';

// TODO move display-builder dependencies
import TimePeriodService from '@sprinklr/display-builder/services/TimePeriodService/TimePeriodService';
import TimePeriod from '@sprinklr/display-builder/models/TimePeriod/TimePeriod';

export const toSpaceTimeFilter = (
    analyticsTimePeriod?: AnalyticsTimePeriod,
    timeField?: string
): SpaceTimeFilter | null => {
    if (!analyticsTimePeriod) {
        return null;
    }
    const timePeriod: TimePeriod = TimePeriodService.createFromAnalyticsTimePeriod(
        analyticsTimePeriod
    );
    return {
        field: timeField, // not sure if this is used...
        sinceTime: timePeriod.startDate?.valueOf(),
        untilTime: timePeriod.endDate?.valueOf(),
    };
};

export const toSpacePreviousTimeFilter = (
    firstAnalyticsTimePeriod: AnalyticsTimePeriod,
    timeField?: string
): SpacePreviousTimeFilter | null => {
    const timePeriod: TimePeriod = TimePeriodService.createFromAnalyticsTimePeriod(
        firstAnalyticsTimePeriod
    );
    const previousTimePeriod = TimePeriodService.previousPeriod(timePeriod);
    return {
        field: timeField, // not sure if this is used...
        sinceTime: previousTimePeriod.startDate?.valueOf(),
        untilTime: previousTimePeriod.endDate?.valueOf(),
    };
};

export const toSpaceProjection = (analyticsProjection: AnalyticsProjection): Projection => {
    const spaceProjection = {
        key: analyticsProjection.heading,
        measurement: analyticsProjection.measurementName,
        aggregateFunction: analyticsProjection.aggregateFunction,
        details: analyticsProjection.details,
        filters: [],
    };

    if (decorators.includes(spaceProjection.aggregateFunction)) {
        spaceProjection.aggregateFunction = 'SUM';
    }

    return spaceProjection;
};

export const toSpaceSort = (analyticsSort: AnalyticsSort): Sort => {
    return {
        key: analyticsSort.heading,
        order: analyticsSort.order,
        additional: analyticsSort.details,
    };
};

export const toSpaceGroupBy = (
    analyticsGroupBy: AnalyticsGroupBy,
    firstProjection?: Projection
): WidgetRequestGroupBy => {
    let page: Page;
    let sort: Sort;
    let projections: Projection[];

    if (analyticsGroupBy.limit > 0 && analyticsGroupBy.limitType !== 'NONE') {
        page = {
            page: 0,
            skip: 0,
            size: analyticsGroupBy.limit,
        };
        if (firstProjection) {
            firstProjection = {
                ...firstProjection,
                // projection keys have to be unique within the request
                key: `${analyticsGroupBy.heading}_firstProjection.key`,
            };

            sort = {
                key: firstProjection?.key,
                order: analyticsGroupBy.limitType === 'BOTTOM' ? 'ASC' : 'DESC',
                additional: undefined,
            };
            projections = [firstProjection];
        }
    }

    return {
        key: analyticsGroupBy.heading,
        field: analyticsGroupBy.dimensionName,
        groupType: analyticsGroupBy.groupType as any,
        details: analyticsGroupBy.details,
        projections,
        filters: null,
        page,
        sort,
        childrenGroupBys: null,
        namedFilters: null,
    };
};

export const toSpaceFilter = (analyticsFilter: AnalyticsFilter): Filter => {
    // if ('GLOBAL_BRAND_ID' === displayFilter.dimensionName) {
    //     request.additional.global = true;
    // }
    //
    // if ('PARTNER_BRAND_ID' === displayFilter.dimensionName) {
    //     request.additional.partner = true;
    // }

    return {
        field: analyticsFilter.dimensionName,
        filterType: (analyticsFilter.filterType as unknown) as FilterType,
        values: analyticsFilter.values,
        details: analyticsFilter.details,
        allValuesAllowed: analyticsFilter.allValues,
        // TODO: these?
        // accessibleValues: object[],
        // userFilter: boolean,
        // lockedWithValues: boolean,
        // hidden: boolean,
        // favourite: boolean,
        // mandatory: boolean,
        // locked: boolean,
        // ignoreAddingToDimensionFilter: boolean,
        // filters: Filter[],
        // advancedFilters: {
        //     filter: string,
        //     valueOf: any,
        //     fieldInfo: any,
        //     type: 'FILTER_QL',
        // },
    };
};

const toSpaceRequest = (analyticsRequest: AnalyticsRequest): SpaceRequest => {
    const topicIdsFilter = analyticsRequest.filters?.find(
        (filter: AnalyticsFilter) =>
            filter.dimensionName === 'TOPIC_IDS' &&
            filter.filterType === 'IN' &&
            filter.values.length > 0
    );

    const key = (
        (analyticsRequest.additional?.widgetId ? analyticsRequest.additional?.widgetId + '_' : '') +
        analyticsRequest.report
    ).toUpperCase();

    const request: SpaceRequest = {
        key,
        reportingEngine: analyticsRequest.reportingEngine,
        report: analyticsRequest.report,

        // TODO: What is it and do we use it?
        // query: null,
        filters: analyticsRequest.filters
            ?.filter((filter: AnalyticsFilter) => {
                // "details" cannot be null with measurement filters
                // or the request will fail
                if (!filter.details) {
                    filter.details = {};
                }
                return (
                    filter.dimensionName &&
                    filter.filterType &&
                    filter.values &&
                    filter.values.length > 0 &&
                    !(
                        topicIdsFilter &&
                        filter.dimensionName === 'TOPIC_GROUP_IDS' &&
                        filter.filterType === 'IN'
                    )
                );

                // PRESERVE THIS - this just (?) stopped being necessary/working, but who knows what tomorrow holds? -- DCBS, 2018-08022
                // && !(wordCloudFilter && filter.filterType === "NIN" && filter.dimensionName === "LST_KEYWORD_LIST");   // Keyword List 'Not Containing' filter special rules
                // PRESERVE THIS - this just (?) stopped being necessary/working, but who knows what tomorrow holds? -- DCBS, 2018-08022
            })
            .map(toSpaceFilter),
        // TODO: What is it and do we use it?
        postFilters: null,

        projections: [],
        projectionDecorations: getProjectionDecorations(
            analyticsRequest.projections,
            analyticsRequest.projectionDecorations
        ),
        // TODO: What is it and do we use it?
        projectionFilters: null,

        groupBys: [],

        // TODO: What is it and do we use it?
        streamRequestInfo: null,

        // TODO: What is it and do we use it?
        excludeFields: [],
        // TODO: What is it and do we use it?
        includeFields: [],

        sorts: [],

        reportDateField: analyticsRequest.timeField,
        timeFilter: toSpaceTimeFilter(
            analyticsRequest.timePeriods?.[0],
            analyticsRequest.timeField
        ),
        // TODO: this doesn't seem to behave the way we want
        previousTimeFilter: analyticsRequest.timePeriods?.[1]?.previousPeriod
            ? toSpacePreviousTimeFilter(
                  analyticsRequest.timePeriods?.[0],
                  analyticsRequest.timeField
              )
            : null,
        timezone: analyticsRequest.timePeriods?.[0]?.timeZone,
        tzOffset: null,

        page: null,

        additional: analyticsRequest.additional || {},
        // cacheDisabled: boolean
    };

    // when true, the outer page object applies to the first groupBy, and all the groupBy page objects are ignored
    delete request.additional.STACKED;

    // attempt to avoid gigantic drilldown payload in response
    request.additional.IGNORE_DRILL_DOWN = 'true';

    // When true, the result is returned as flattened "rows" instead of a nested tree
    // request.additional.TABULAR;

    // Not sure why this is string true.
    // request.additional.API_QUERY = 'true';

    // if (analyticsRequest.skipResolve) {
    //     request.additional.SKIP_RESOLVER = true;
    // }

    // addDefaultFilterForPaid(externalRequest, request);

    let projections = analyticsRequest.projections.map(toSpaceProjection);
    projections = projections
        // ensure unique
        .filter(
            (projection, index) =>
                projections.findIndex(
                    p =>
                        p.key === projection.key ||
                        (p.measurement === projection.measurement &&
                            p.aggregateFunction === projection.aggregateFunction)
                ) === index
        );

    const groupBys = (analyticsRequest.groupBys || [])
        .filter((groupBy: AnalyticsGroupBy) => {
            return !!groupBy.dimensionName;
        })
        .map(groupBy => {
            return toSpaceGroupBy(groupBy, projections[0]);
        });

    let lastGroup: WidgetRequestGroupBy = null;
    let firstGroup: WidgetRequestGroupBy = null;
    // Nest groupBys
    groupBys.forEach(groupBy => {
        // if ('GLOBAL_BRAND_ID' === groupBy.dimensionName) {
        //     request.additional.global = true;
        // }
        //
        // if ('PARTNER_BRAND_ID' === groupBy.dimensionName) {
        //     request.additional.partner = true;
        // }
        //
        // if (
        //     'TOPIC_CLUSTER' === groupBy.dimensionName ||
        //     'TITLE_TOPIC_CLUSTER' === groupBy.dimensionName ||
        //     'MESSAGE_TOPIC_CLUSTER' === groupBy.dimensionName
        // ) {
        //     request.additional.TOPIC_CLUSTER = 'true';
        // }

        if (null === firstGroup) {
            firstGroup = groupBy;
        } else {
            lastGroup.childrenGroupBys = [groupBy];
        }
        lastGroup = groupBy;
    });

    if (firstGroup !== null) {
        request.groupBys = [firstGroup];
    }

    // Add projections to last groupBy, or to the request if no groupBys
    if (lastGroup !== null) {
        lastGroup.projections = projections;
        if (analyticsRequest.includeTotal) {
            request.projections = projections;
        }
    } else {
        request.projections = projections;
    }

    if (lastGroup !== null) {
        if (analyticsRequest.sorts) {
            analyticsRequest.sorts
                .filter((sort: AnalyticsSort) => {
                    return !!sort.heading;
                })
                .forEach(sort => {
                    const matchingProjection = analyticsRequest.projections.find(
                        projection => projection.heading === sort.heading
                    );
                    const spaceSort = toSpaceSort(sort);
                    if (matchingProjection) {
                        spaceSort.key = formatHeading(
                            sort.heading,
                            matchingProjection.aggregateFunction
                        );
                        request.sorts.push(spaceSort);
                    } else if (
                        analyticsRequest.groupBys.find(groupBy => groupBy.heading === sort.heading)
                    ) {
                        request.sorts.push(spaceSort);
                    }
                });
        }

        if (analyticsRequest.sorts && analyticsRequest.sorts.length > 0 && !lastGroup.sort) {
            lastGroup.sort = toSpaceSort(analyticsRequest.sorts[0]);
        }

        if (analyticsRequest.limit > 0) {
            request.page = {
                page: 0,
                skip: 0,
                size: analyticsRequest.limit,
            };
        }
    }

    // The API can return non-us state values (numbers, weird three-digit codes, etc.). Add a Country == US filter to correct this.
    if (
        analyticsRequest.groupBys?.some(groupBy => groupBy.dimensionName === 'US_STATE') &&
        !analyticsRequest.filters?.some(filter => filter.dimensionName === 'COUNTRY')
    ) {
        request.filters.push({
            field: 'COUNTRY',
            filterType: 'IN',
            values: ['US'],
        });
    }

    // SprinklrCollectionUtils.nullSafeList(externalRequest.getFetchFields()).forEach(request::addIncludeField);
    // UIRequestAdapter.adapt(reportingEngine, request);
    // if (requestValidator != null) {
    //     requestValidator.validateReportingRequest(request);
    // }

    return request;
};

export const getReports = (analyticsRequest: AnalyticsRequest): string[] => {
    const reports = [
        analyticsRequest.report,
        ...analyticsRequest.projections
            .filter(projection => !!projection.details?.origReport)
            .map(projection => projection.details?.origReport),
    ];
    return Array.from(new Set(reports).values());
};

export const splitRequestByReport = (analyticsRequest: AnalyticsRequest) => {
    const reports = getReports(analyticsRequest);
    const projections: AnalyticsProjection[] = JSON.parse(
        JSON.stringify(analyticsRequest.projections || [])
    );
    projections.forEach((projection, index) => {
        projection.details = projection.details || {};
        projection.details.origOrder = index;
    });

    return reports.reduce((map, report, index) => {
        const reportRequest: AnalyticsRequest = JSON.parse(JSON.stringify(analyticsRequest));
        reportRequest.report = report;
        reportRequest.projections = projections.filter(projection => {
            return (
                projection.details?.origReport === report ||
                (!projection.details?.origReport && index === 0)
            );
        });
        map[report] = reportRequest;
        return map;
    }, {} as { [report: string]: AnalyticsRequest });
};

export const toSpaceBatchRequest = (analyticsRequest: AnalyticsRequest): SpaceBatchRequest => {
    const split = splitRequestByReport(analyticsRequest);
    let firstRequestKey: string;

    const requests = Object.keys(split).reduce((batch, report, index) => {
        const spaceRequest = toSpaceRequest(split[report]);
        const key = spaceRequest.key;
        if (index === 0) {
            firstRequestKey = key;
        }
        batch[key] = spaceRequest;

        const previousPeriod = analyticsRequest.timePeriods?.[1]?.previousPeriod === true;
        if (previousPeriod && spaceRequest.previousTimeFilter) {
            const previousPeriodRequest: SpaceRequest = JSON.parse(JSON.stringify(spaceRequest));
            const prevKey = key + '_PREV';
            previousPeriodRequest.key = prevKey;
            previousPeriodRequest.timeFilter = spaceRequest.previousTimeFilter;
            previousPeriodRequest.previousTimeFilter = null;
            batch[prevKey] = previousPeriodRequest;
        }

        return batch;
    }, {} as Record<string, SpaceRequest>);

    return {
        firstRequestKey,
        pivotRequest: Object.keys(requests).length > 1,
        requests,
    };
};

export default toSpaceRequest;
