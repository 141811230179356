import BulkItem from './BulkItem';

export default class TopicTheme extends BulkItem {
    private displayName: string;
    private imageUrl: string;
    private color: string;

    constructor(id: string, name: string, displayName?: string, imageUrl?: string, color?: string) {
        super(id, name);

        this.displayName = displayName;
        this.imageUrl = imageUrl;
        this.color = color;
    }

    toString() {
        return this.displayName ? this.displayName : this.name;
    }

    get sortValue() {
        return this.displayName;
    }

    toColor(): string {
        return this.color;
    }

    toImageUrl(): string {
        return this.imageUrl;
    }

    // Construct a TopicTheme from an object, checking for properties first.
    public static fromObject(object: any) {
        const allProps = ['id', 'name', 'displayName', 'imageUrl', 'color'].every(prop =>
            object.hasOwnProperty(prop)
        );

        if (allProps) {
            return new TopicTheme(
                object.id,
                object.name,
                object.displayName,
                object.imageUrl,
                object.color
            );
        }
    }
}
