import { Moment } from 'moment';
import chunk from 'lodash/chunk';
import Axios, { CancelTokenSource } from 'axios';

// types
import TimePeriod from 'models/TimePeriod/TimePeriod';
import {
    AnalyticsEngine,
    AnalyticsGroupBy,
    AnalyticsSort,
} from '@sprinklr/stories/analytics/AnalyticsRequest';
import { Post } from '@sprinklr/stories/post/Post';
import { PostsEngine, PostsRequest } from '@sprinklr/stories/post/PostsRequest';
import { ReportingRequest, ReportingResponse } from '@sprinklr/stories/reporting/types';

// services
import AnalyticsService from '../../AnalyticsService/AnalyticsService';
import PostsService from '../PostsService';
import { PostsProvider } from './PostsProvider';
import SprinklrAPIService, {
    PromiseWithCancel,
} from 'services/SprinklrAPIService/SprinklrAPIService';
import TimePeriodService from 'services/TimePeriodService/TimePeriodService';

// utils
import { WidgetDimensionKey } from 'utils/Widget/WidgetDimensionKey';
import ObjectUtils from 'utils/ObjectUtils/ObjectUtils';

interface PostResponse {
    posts: Post[];
    postIds: string[];
}

enum Drilldown {
    topHashtags = 'topHashtags',
    topHashtags_2 = 'topHashtags_2',
    topInfluencers = 'topInfluencers',
    topInfluencerPosts = 'topInfluencerPosts',
    topKeywords = 'topKeywords',
    topMedia = 'topMedia',
    mentionsTrend = 'mentionsTrend',
    topPosts = 'topPosts',
    topThemes = 'topThemes',
    benchmarkingTrendsWithLens = 'benchmarkingTrendsWithLens',
    benchmarkingTrendsWithCateogry = 'benchmarkingTrendsWithCateogry',
}

export default class PostsProviderSmartTrends implements PostsProvider {
    constructor(private readonly sprinklrAPIService: SprinklrAPIService) {}

    request(request: PostsRequest, cancelSource?: CancelTokenSource): PromiseWithCancel<Post[]> {
        let requests: PostsRequest[] = [ObjectUtils.copy(request)];

        const postGroupByOffset = PostsProviderSmartTrends.prepareRequest(requests[0]);

        const additionalRequests = AnalyticsService.splitRequests(requests[0] as any) as any;
        if (additionalRequests) {
            requests = requests.concat(additionalRequests);
        }

        const promise = this.requestInternal(
            requests[0],
            null,
            postGroupByOffset,
            cancelSource
        ).then(firstResult => {
            if (!additionalRequests) {
                return firstResult.posts;
            } else {
                // Remove first request
                const remaining = requests.slice();
                remaining.shift();

                const promises = remaining.map(request => {
                    return this.requestInternal(
                        request,
                        firstResult.postIds,
                        postGroupByOffset,
                        cancelSource
                    );
                });

                return Promise.all(promises).then(results => {
                    return PostsProviderSmartTrends.combinePosts(remaining, firstResult, results);
                });
            }
        });

        if (!cancelSource) {
            cancelSource = Axios.CancelToken.source();
        }

        (promise as any).cancel = (message?: string) => {
            cancelSource.cancel(message);
        };

        return promise;
    }

    // Massages the request in-place so that it can be correctly processed for split request
    static prepareRequest(request: PostsRequest): number {
        const engine: PostsEngine = request.reportingEngine || 'PLATFORM';
        const engineMeta = PostsService.engines[engine];

        if (!engineMeta) {
            throw new Error('Engine "' + request.reportingEngine + '" not supported');
        }

        const postKey = engineMeta.postKey;
        const groupBys: AnalyticsGroupBy[] = [];

        const sorts: AnalyticsSort[] = request.sorts || [];
        if (sorts.length && sorts[0].heading) {
            groupBys.push({
                heading: request.sorts[0].heading,
                dimensionName: request.sorts[0].heading,
                groupType: 'FIELD',
            });
        }

        // Add the postKey after any sorting groupbys
        const postGroupByOffset = groupBys.length;
        groupBys.push({
            heading: postKey,
            dimensionName: postKey,
            groupType: 'FIELD',
        });

        if (request.projections) {
            request.projections.length = 0;
        }
        request.groupBys = groupBys;
        request.sorts = sorts;

        return postGroupByOffset;
    }

    private requestInternal(
        request: PostsRequest,
        postIds: string[],
        postGroupByOffset: number,
        cancelSource?: CancelTokenSource
    ): PromiseWithCancel<PostResponse> {
        if (!cancelSource) {
            cancelSource = Axios.CancelToken.source();
        }

        const engine: PostsEngine = request.reportingEngine || 'PLATFORM';
        const engineMeta = PostsService.engines[engine];

        if (!engineMeta) {
            throw new Error('Engine "' + request.reportingEngine + '" not supported');
        }

        // "postDimension" overrides the default groupby for post message
        const postKey = request.postDimension || engineMeta.postKey;

        // Filter secondary requests by the post ids we got on the first request
        if (postIds) {
            request.filters.push({
                dimensionName: 'POST_ID',
                filterType: 'IN',
                values: postIds,
            });
        }

        const timePeriod = request.timePeriod
            ? this.sprinklrAPIService.getTimePeriod(request.timePeriod)
            : TimePeriodService.createFromTimePeriodKey('last_7_days');

        const startTime: Moment = timePeriod.startDate;
        const endTime: Moment = timePeriod.endDate;

        const requestObject: ReportingRequest = {
            reportingEngine: engine as AnalyticsEngine,
            report: request.report || engineMeta.defaultReport,

            projectionDecorations: [],
            projections: [],
            groupBys: request.groupBys,
            filters: request.filters,
            sorts: request.sorts
                .filter(sort => !!sort.heading)
                .map(sort => {
                    return {
                        heading: sort.heading,
                        order: sort.order,
                    };
                }),
            startTime: startTime ? startTime.valueOf() : null,
            endTime: endTime ? endTime.valueOf() : null,
            timeZone: request.timePeriod ? request.timePeriod.timeZone : undefined,

            page: request.page || 0,
            pageSize: request.pageSize || 20,

            skipResolve: true,
        };

        let promise: any = this.sprinklrAPIService.query(requestObject, cancelSource);

        promise = promise.then((response: ReportingResponse) => {
            const postIds = response.rows.map((row: any[]) => {
                return row[postGroupByOffset];
            });

            response.headings = response.headings || [];
            response.rows = response.rows || [];

            return this.getPostsById(
                postIds,
                new WidgetDimensionKey(postKey),
                request.reportingEngine as AnalyticsEngine,
                request.report,
                timePeriod,
                cancelSource
            ).then((posts: any) => {
                // Note: the drilldown lookup is too slow and not needed for the moment.
                // In the future, we may support specifying specific drilldowns to automatically
                // load, instead of all like below.

                // Load the secondary drill-down information for each post
                // const promises = posts.map(post => this.loadDrilldowns(post, request, timePeriod));

                // return (Promise as any).allSettled(promises).then(() => {
                //     return { posts: posts, postIds: postIds };
                // });

                return { posts: posts, postIds: postIds };
            });
        });

        promise.cancel = function(message?: string) {
            cancelSource.cancel(message);
        };

        return promise;
    }

    /**
     *
     * @param postIds
     * @param projections
     * @param fieldName
     * @param engine
     * @param report
     * @param timePeriod
     * @param cancelSource
     * @returns {any}
     */
    private getPostsById(
        postIds: string[],
        fieldName: WidgetDimensionKey,
        engine: AnalyticsEngine,
        report: string,
        timePeriod?: TimePeriod,
        cancelSource?: CancelTokenSource
    ) {
        if (!cancelSource) {
            cancelSource = Axios.CancelToken.source();
        }

        // Note: bulklookup for Smart Trends will only resolve a maximum of 10 posts,
        // all the rest get put into the "__deleted" array.
        //
        // Workaround is to pull in groups of 10 and reasssemble at the end
        const postGroups = chunk(postIds, 10);
        const promises = [];

        postGroups.forEach((group: any[]) => {
            promises.push(
                this.getPostsById2(group, fieldName, engine, report, timePeriod, cancelSource)
            );
        });

        const promise: any = (Promise as any).allSettled(promises).then(results => {
            const combined = [];

            results.forEach(result => {
                if (result.status === 'fulfilled') {
                    combined.push.apply(combined, result.value);
                }
            });

            return combined;
        });

        promise.cancel = (message?: string) => {
            cancelSource.cancel(message);
        };

        return promise;
    }

    /**
     *
     * @param postIds
     * @param projections
     * @param fieldName
     * @param engine
     * @param report
     * @param timePeriod
     * @param cancelSource
     * @returns {any}
     */
    private getPostsById2(
        postIds: string[],
        fieldName: WidgetDimensionKey,
        engine: AnalyticsEngine,
        report: string,
        timePeriod?: TimePeriod,
        cancelSource?: CancelTokenSource
    ): PromiseWithCancel<any[]> {
        if (!cancelSource) {
            cancelSource = Axios.CancelToken.source();
        }

        const promise: any = this.sprinklrAPIService
            .bulkLookup(engine, report, fieldName, postIds, timePeriod, cancelSource)
            .then(postsMap => {
                if (!postsMap) {
                    return [];
                }

                const posts = postIds
                    .filter((postId: any) => {
                        return postId in postsMap;
                    })
                    .map((postId: any) => {
                        return postsMap[postId] as any;
                    });

                posts.forEach((post: any) => {
                    const details = post.object;
                    delete post.object;

                    post.snMsgId = post.id;
                    post.snCreatedTime = details?.snCreatedTime;
                    post.title = post.name;
                    post.message = details?.trendDetails?.dimensions?.TREND_SUMMARY?.[0].name || '';
                    post.trendDetails = details;
                });

                return posts;
            });

        promise.cancel = (message?: string) => {
            cancelSource.cancel(message);
        };

        return promise;
    }

    private resolveTrends(
        trendRows: any[],
        request: any,
        timePeriod: any,
        cancelSource?: CancelTokenSource
    ): PromiseWithCancel<PostResponse> {
        if (!cancelSource) {
            cancelSource = Axios.CancelToken.source();
        }

        const trendIds = trendRows.map((row: any[]) => {
            return row[0];
        });

        return this.getPostsById(
            trendIds,
            new WidgetDimensionKey('TREND_ID'),
            request.reportingEngine as AnalyticsEngine,
            request.report,
            timePeriod,
            cancelSource
        ).then((posts: any) => {
            trendRows.forEach((trend, offset) => {
                trend[0] = posts[offset];
            });

            return posts;
        });
    }

    private loadDrilldowns(post: Post, request: any, timePeriod: any): PromiseWithCancel<void> {
        const drilldowns = Object.keys(Drilldown);
        const promises = [];

        // Load the secondary drill-down information for each post
        const details = post.trendDetails;

        details.drilldown = {};

        drilldowns.forEach((drilldown: Drilldown) => {
            promises.push(this.loadDrilldown(post, drilldown, details, request));
        });

        const promiseTrends = [];

        return (Promise as any).allSettled(promises).then(results => {
            results.forEach(result => {
                const value = result.value;
                if (result.status === 'fulfilled') {
                    value.post.trendDetails.drilldown[value.drilldown] = value.result;

                    switch (value.drilldown) {
                        case Drilldown.benchmarkingTrendsWithLens:
                        case Drilldown.benchmarkingTrendsWithCateogry:
                            promiseTrends.push(
                                this.resolveTrends(value.result.rows, request, timePeriod)
                            );
                            break;
                    }
                }
            });

            return (Promise as any).allSettled(promiseTrends);
        });
    }

    // Convert trend filters into the mobile format
    private loadDrilldown(
        post: Post,
        type: Drilldown,
        trendDetails: any,
        requestObject: any
    ): PromiseWithCancel<{ post: Post; drilldown: string; result: ReportingResponse }> {
        if (!trendDetails) {
            return Promise.resolve(null);
        }

        let engine: any = 'RDB_FIREHOSE';
        let groupByDimension;
        let groupByType: any = 'FIELD';
        let sorts: any = [],
            pageSize: number;
        const projection = ['MENTIONS_COUNT'];
        let startTime = trendDetails.trendStartTime;
        let endTime = trendDetails.trendEndTime;
        let timePeriod;

        switch (type) {
            case Drilldown.topHashtags:
                groupByDimension = 'HASHTAGS';

                sorts.push({
                    heading: projection[0],
                    order: 'DESC',
                });

                pageSize = 3;
                break;

            case Drilldown.topHashtags_2:
                groupByDimension = 'HASHTAGS';
                projection[1] = 'DISTINCT_USER_COUNT';

                sorts.push({
                    heading: projection[0],
                    order: 'DESC',
                });

                pageSize = 20;
                break;

            case Drilldown.topInfluencers:
                groupByDimension = 'REACH_USERS';
                projection[0] = 'REACH_COUNT';

                sorts.push({
                    heading: projection[0],
                    order: 'DESC',
                });

                pageSize = 20;
                break;

            case Drilldown.topInfluencerPosts:
                groupByDimension = 'ES_MESSAGE_ID';
                projection[0] = 'REACH_COUNT';

                sorts.push({
                    heading: projection[0],
                    order: 'DESC',
                });

                pageSize = 10;
                break;

            case Drilldown.topKeywords:
                groupByDimension = 'WORD_CLOUD_MESSAGE';
                pageSize = 100;
                break;

            case Drilldown.topMedia:
                groupByDimension = 'ES_MESSAGE_ID';
                projection[0] = 'RETWEET_COUNT';
                projection[1] = 'REPLY_COUNT';
                projection[2] = 'FAVOURITE_COUNT';

                sorts.push({
                    heading: projection[0],
                    order: 'DESC',
                });

                pageSize = 3;
                break;

            case Drilldown.mentionsTrend:
                groupByDimension = 'SN_CREATED_TIME';
                groupByType = 'DATE_HISTOGRAM';

                timePeriod = TimePeriodService.createFromTimePeriodKey('last_7_days');
                startTime = timePeriod.startDate.valueOf();
                endTime = timePeriod.endDate.valueOf();

                pageSize = 100;
                break;

            case Drilldown.topPosts:
                groupByDimension = 'ES_MESSAGE_ID';
                projection[0] = 'FAVOURITE_COUNT';
                projection[1] = 'RETWEET_COUNT';
                projection[2] = 'REPLY_COUNT';

                sorts.push({
                    heading: projection[1],
                    order: 'DESC',
                });

                pageSize = 3;
                break;

            case Drilldown.topThemes:
                groupByDimension = 'SMART_CLUSTERING_PHRASE';
                pageSize = 100;
                break;

            case Drilldown.benchmarkingTrendsWithLens:
            case Drilldown.benchmarkingTrendsWithCateogry:
                engine = 'TRENDING_EVENT';
                groupByDimension = 'TREND_ID';
                projection[0] = 'TREND_MENTIONS_COUNT';
                projection[1] = 'TREND_REACH';
                projection[2] = 'TREND_TOTAL_ENGAGEMENTS';

                timePeriod = TimePeriodService.createFromTimePeriodKey('last_30_days');
                startTime = timePeriod.startDate.valueOf();
                endTime = timePeriod.endDate.valueOf();

                pageSize = 10;
                break;
        }

        const groupBys: AnalyticsGroupBy[] = [
            {
                heading: groupByDimension,
                dimensionName: groupByDimension,
                groupType: groupByType,
            },
        ];

        if (groupByType === 'DATE_HISTOGRAM') {
            groupBys[0].details = {
                interval: '1d',
            };
        }

        const projections = projection.map(projection => {
            return {
                heading: projection,
                measurementName: projection,
                aggregateFunction: 'SUM' as any,
            };
        });

        const request: ReportingRequest = {
            reportingEngine: engine,
            report: engine,
            groupBys: groupBys,
            projectionDecorations: [],
            projections: projections,
            filters: this.getFilters(trendDetails.reportVsFilters[engine]),
            sorts: sorts,
            startTime: startTime,
            endTime: endTime,
            page: 0,
            pageSize: pageSize,
            timeZone: requestObject.timeZone,
        };

        return this.sprinklrAPIService.query(request).then(result => {
            return { post: post, drilldown: type, result: result };
        });
    }

    // Convert trend filters into the mobile format
    private getFilters(filters: any[]): any[] {
        if (!filters) {
            return [];
        }

        return filters.map(filter => {
            return {
                dimensionName: filter.field,
                filterType: filter.filterType,
                values: filter.values,
            };
        });
    }

    // Used when using splitRequests() to merge the post's projections together
    static combinePosts(
        requests: PostsRequest[],
        firstResult: PostResponse,
        results: PostResponse[]
    ): Post[] {
        let posts: Post[] = [];

        posts = posts.concat(firstResult.posts);

        results.forEach((result, offset) => {
            posts.forEach(post => {
                const projections = (post as any).projections;
                const found = result.posts.find(
                    post2 => post.universalId === post2.universalId
                ) as any;

                if (found) {
                    const keys = Object.keys(found.projections);
                    keys.forEach(key => {
                        projections[key] = found.projections[key];
                    });
                } else {
                    requests[offset].projections.forEach(projection => {
                        projections[projection.heading] = {
                            value: 0,
                            additional: true,
                            origOrder: projection.details?.origOrder,
                        };
                    });
                }
            });
        });

        return posts;
    }
}
