import { Theme, ThemeItem, ThemeItemFields, builtInThemes } from 'models/Theme/Theme';
import { BaseRecordService } from 'utils/BaseRecordService/BaseRecordService';
import { Mapper } from '../DataStore/DataStore';
import { GraphQLService } from '../GraphQLService/GraphQLService';
import { ClientMutationId, ModelName } from '../GraphQLQueries/GraphQLQueries';
import { Storyboard } from 'models/Storyboard/Storyboard';

const MODELNAME: ModelName = 'theme';

export class ThemeService extends BaseRecordService<ThemeItem> {
    constructor(graphQL: GraphQLService, mapper: Mapper<ThemeItem>, isPresentations: boolean) {
        super(graphQL, mapper, isPresentations);
    }

    create(theme: Theme): Promise<ThemeItem> {
        if (!theme) {
            throw Error('theme is required');
        }

        return this.mutate({
            name: 'createTheme',
            queryParams: {
                query: `mutation CreateTheme($input: CreateThemeInput!) {
                            createTheme(input: $input) {
                                theme {
                                    ${ThemeItemFields}
                                }
                                ${ClientMutationId}
                            }
                        }
                    `,
                variables: { input: { theme } },
            },
            extractor: result => result['createTheme'][MODELNAME],
            model: MODELNAME,
        });
    }

    clone(theme: Theme | string): Promise<ThemeItem> {
        if (!theme) {
            throw Error('theme is required');
        }

        return this.mutate({
            name: 'cloneTheme',
            queryParams: {
                query: `mutation CloneTheme($input: CloneThemeInput!) {
                            cloneTheme(input: $input) {
                                theme {
                                    ${ThemeItemFields}
                                }
                                ${ClientMutationId}
                            }
                        }
                    `,
                variables: { input: { id: this.themeId(theme) } },
            },
            extractor: result => result['cloneTheme'][MODELNAME],
            model: MODELNAME,
        });
    }

    findAll(): Promise<Array<ThemeItem>> {
        return this.queryMany({
            name: 'clientThemes',
            queryParams: {
                query: `{client {
                        themes {
                            ${ThemeItemFields}
                        }
                    }}
                    `,
                variables: {},
            },
            extractor: result => result['client']['themes'],
            model: MODELNAME,
        });
    }

    find(id: string): Promise<ThemeItem> {
        if (!id) {
            throw Error('id is required');
        }

        return this.query({
            name: 'theme',
            queryParams: {
                query: `query FetchTheme($id: ID!) {
                            theme(id: $id) {
                                ${ThemeItemFields}
                            }
                        }
                    `,
                variables: { id },
            },
            extractor: result => result[MODELNAME],
            model: MODELNAME,
        });
    }

    update(theme: ThemeItem): Promise<ThemeItem> {
        if (!theme) {
            throw Error('theme is required');
        }

        return this.mutate({
            name: 'updateTheme',
            queryParams: {
                query: `mutation UpdateTheme($input: UpdateThemeInput!) {
                            updateTheme(input: $input) {
                                theme {
                                    ${ThemeItemFields}
                                }
                                ${ClientMutationId}
                            }
                        }
                    `,
                variables: { input: { theme, id: theme.id } },
            },
            extractor: result => result['updateTheme'][MODELNAME],
            model: MODELNAME,
        });
    }

    setClientDefault(
        theme: ThemeItem | string,
        isPresentations: boolean
    ): Promise<Array<ThemeItem>> {
        if (!theme) {
            throw Error('theme is required');
        }

        return this.mutateMany({
            name: 'setClientDefaultTheme',
            queryParams: {
                query: `mutation SetClientDefaultTheme($input: SetClientDefaultThemeInput!) {
                            setClientDefaultTheme(input: $input) {
                                themes {
                                    ${ThemeItemFields}
                                }
                                ${ClientMutationId}
                            }
                        }
                    `,
                variables: { input: { id: this.themeId(theme), isPresentations: isPresentations } },
            },
            extractor: result => result['setClientDefaultTheme']['themes'],
            model: MODELNAME,
        });
    }

    destroy(theme: ThemeItem | string): Promise<any> {
        if (!theme) {
            throw Error('theme is required');
        }

        const themeId = this.themeId(theme);

        return this.mutate({
            name: 'deleteTheme',
            queryParams: {
                query: `mutation DeleteTheme($input: DeleteThemeInput!) {
                            deleteTheme(input: $input) {
                                ${ClientMutationId}
                            }
                        }
                    `,
                variables: { input: { id: themeId } },
            },
            extractor: () => themeId,
            model: MODELNAME,
        });
    }

    setRequiredThemeLibrary(): Promise<Array<ThemeItem>> {
        const darkTheme = builtInThemes[0];
        const lightTheme = builtInThemes[1];

        const defaultTheme = this.isPresentations ? lightTheme : darkTheme;

        return this.create(defaultTheme).then(theme =>
            this.setClientDefault(theme, this.isPresentations)
        );
    }

    computeThemeFromLegacy(storyboard: Storyboard): Theme {
        const optionValues = [
            'monochromeStartColor',
            'monochromeEndOffset',
            'colorBlendStartColor',
            'colorBlendStartColor',
            'colorBlendEndColor',
            'colorPaletteType',
        ];

        const themeValues = [
            'colorPrimary',
            'colorBackground',
            'colorFont',
            'fontFamilyPrimary',
            'fontFamilySecondary',
        ];

        const fontValues = ['fontSecondary', 'fontPrimary'];

        const valueMap: any = {};

        storyboard.activeVersion.scenes.forEach(scene => {
            scene.panels.forEach(panel => {
                const panelTheme = panel.widget && panel.widget.theme;

                if (panelTheme) {
                    themeValues.forEach(value => {
                        if (valueMap[value]) {
                            valueMap[value].push(panelTheme[value]);
                        } else {
                            valueMap[value] = [panelTheme[value]];
                        }
                    });

                    // LEGZ DRY this up a bit
                    const primaryFontFamily =
                        panelTheme.fontPrimary && panelTheme.fontPrimary.family;

                    if (primaryFontFamily) {
                        if (valueMap.fontPrimary) {
                            valueMap.fontPrimary.push({
                                family: primaryFontFamily,
                                url: panelTheme.fontPrimary.url ? panelTheme.fontPrimary.url : '',
                            });
                        } else {
                            valueMap.fontPrimary = [
                                {
                                    family: primaryFontFamily,
                                    url: panelTheme.fontPrimary.url
                                        ? panelTheme.fontPrimary.url
                                        : '',
                                },
                            ];
                        }
                    }

                    const secondaryFontFamily =
                        panelTheme.fontSecondary && panelTheme.fontSecondary.family;

                    if (secondaryFontFamily) {
                        if (valueMap.fontSecondary) {
                            valueMap.fontSecondary.push({
                                family: secondaryFontFamily,
                                url: panelTheme.fontSecondary.url
                                    ? panelTheme.fontSecondary.url
                                    : '',
                            });
                        } else {
                            valueMap.fontSecondary = [
                                {
                                    family: secondaryFontFamily,
                                    url: panelTheme.fontSecondary.url
                                        ? panelTheme.fontSecondary.url
                                        : '',
                                },
                            ];
                        }
                    }

                    if (panelTheme.backgroundImage && panelTheme.backgroundImage.url) {
                        if (valueMap.backgroundImage) {
                            valueMap.backgroundImage.push(panelTheme.backgroundImage.url);
                        } else {
                            valueMap.backgroundImage = [panelTheme.backgroundImage.url];
                        }
                    }

                    optionValues.forEach(value => {
                        panel.widget &&
                            panel.widget.children &&
                            panel.widget.children.forEach(widget => {
                                if (widget.options && widget.options[value]) {
                                    if (valueMap[value]) {
                                        valueMap[value].push(widget.options[value]);
                                    } else {
                                        valueMap[value] = [widget.options[value]];
                                    }
                                }
                            });
                    });
                }
            });
        });

        const fontPrimary = mode(valueMap.fontPrimary);
        const fontSecondary = mode(valueMap.fontSecondary);

        const theme: Theme = {
            colorPalette: {
                type: mode(valueMap.colorPaletteType) || 'solid',
                monochrome: {
                    startColor: mode(valueMap.monochromeStartColor),
                    endOffset: mode(valueMap.monochromeEndOffset) || 50,
                },
                colorBlend: {
                    startColor: mode(valueMap.colorBlendStartColor),
                    endColor: mode(valueMap.colorBlendEndColor),
                },
                solid: mode(valueMap.colorPrimary),
                ranked: {
                    colors: [valueMap.colorPrimary],
                    contrast: 0,
                },
            },
            typography: {
                color: mode(valueMap.colorFont) === null ? '#fff' : mode(valueMap.colorFont),
                primary: {
                    family: (fontPrimary && fontPrimary.family) || mode(valueMap.fontFamilyPrimary),
                    url: fontPrimary && fontPrimary.url,
                },
                secondary: {
                    family:
                        (fontSecondary && fontSecondary.family) ||
                        mode(valueMap.fontFamilySecondary),
                    url: fontSecondary && fontSecondary.url,
                },
            },
            background: {
                color: mode(valueMap.colorBackground),
            },
        };

        if (mode(valueMap.backgroundImage)) {
            theme.background.url = mode(valueMap.backgroundImage);
            theme.background.size = 'cover';
            theme.background.repeat = 'no-repeat';
        }
        return theme;
    }

    private themeId(theme: ThemeItem | string): string {
        if (typeof theme === 'string') {
            return theme;
        }

        return theme.id;
    }
}

const mode = arr => {
    return (
        arr &&
        arr
            .filter(item => item !== undefined)
            .sort((a, b) => arr.filter(v => v === a).length - arr.filter(v => v === b).length)
            .pop()
    );
};
