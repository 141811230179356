import {
    AnalyticsRequest,
    GroupBy,
    Label,
    Metric,
    Sort,
} from './PanelTemplatesTheme/PanelTemplatesTheme';
import { Panel } from '../../models/Panel/Panel';
import { WIDGET_LABEL_SIZE } from '../../components/Widget/WidgetLabel/constants';
import ListeningDimensions from '@sprinklr/stories-services/AnalyticsService/ListeningDimensions';
import ListeningMetrics from '@sprinklr/stories-services/AnalyticsService/ListeningMetrics';

export var TemplateChannelMetrics: Panel = {
    id: 'channel-metrics',
    name: 'Channel Metrics',
    description: '',
    tags: ['Smart', 'Channel Metrics', 'landscape', 'portrait'],
    widget: {
        id: '1',
        position: {
            width: '1920px',
            height: '1080px',
            top: '0',
            left: '0',
        },
        classes: 'template_channel_metrics',
        children: [
            {
                type: 'metric',
                id: '1',
                name: 'metric',
                useGlobalTimePeriod: true,
                analyticsRequests: [
                    // Note: All global variables like reportingEngine, report, and filters
                    // are injected via AnalyticsRequest.getRequests()
                    new AnalyticsRequest({
                        limit: 1,
                        projections: [
                            new Metric({
                                LISTENING: { source: ListeningMetrics.distinctUsers },
                                INBOUND_MESSAGE: { dimensionName: 'INBOUND_COUNT' },
                                PLATFORM: { dimensionName: 'TOTAL_ENGAGEMENT' },
                                BENCHMARKING: { dimensionName: 'TOTAL_ENGAGEMENT' },
                                PAID: { dimensionName: 'ENGAGEMENT' },
                                ADVOCACY: {
                                    dimensionName: 'ADVOCACY_TOTAL_USER_ENGAGEMENTS',
                                    heading: 'Total_Engagements',
                                },
                                RDB_FIREHOSE: {
                                    dimensionName: 'DISTINCT_USER_COUNT',
                                    heading: 'Distinct Users',
                                },
                                STORY_MESSAGE: {
                                    dimensionName: 'STORY_ACTIVE_COUNT',
                                    heading: 'Story Count',
                                },
                            }),
                        ],
                        groupBys: [
                            new GroupBy({
                                LISTENING: { source: ListeningDimensions.socialNetwork },
                                INBOUND_MESSAGE: { dimensionName: 'SN_TYPE' },
                                PLATFORM: { dimensionName: 'SN_TYPE' },
                                BENCHMARKING: { dimensionName: 'BENCHMARKING_SN_TYPE' },
                                PAID: { dimensionName: 'socialChannelTypes' },
                                ADVOCACY: { dimensionName: 'SN_TYPE', groupType: 'FIELD' },
                                RDB_FIREHOSE: { dimensionName: 'RDB_QUERY_IDS' },
                                STORY_MESSAGE: { dimensionName: 'STORY_CATEGORY' },
                            }),
                        ],
                        sorts: [new Sort({ order: 'DESC', projections: 0 })],
                        timePeriods: [{ key: 'last_7_days' }, { previousPeriod: true }],
                    }) as any,
                ],
                position: {
                    width: '33%',
                    height: '50%',
                    top: '0',
                    left: '0',
                    portrait: {
                        width: '50%',
                        height: '33%',
                        top: '0',
                        left: '0',
                    },
                },
                label: {
                    titleText: new Label({
                        LISTENING: 'Top Distinct User Platform',
                        INBOUND_MESSAGE: 'Top Inbound Platform',
                        PLATFORM: 'Top Engagements Platform',
                        BENCHMARKING: 'Top Engagements Platform',
                        PAID: 'Spent',
                        ADVOCACY: 'Top Engagements Platform',
                        STORY_MESSAGE: 'Top Stories Platform',
                    }) as any,
                    enabled: true,
                    size: WIDGET_LABEL_SIZE.secondary,
                },
                options: {
                    player: {
                        playing: true,
                        duration: 15,
                    },
                    showMetricValue: true,
                    metricValueSize: 45,

                    showMetricName: true,
                    metricNameSize: 12,

                    metricPercentChangeSize: 14,
                    showIcon: true,
                    showMetricChange: false,
                    alignment: 'left',
                    animationDelay: 0,
                    portrait: {
                        metricValueSize: 40,
                    },
                },
            },
            {
                type: 'metric',
                id: '2',
                name: 'metric',
                useGlobalTimePeriod: true,
                analyticsRequests: [
                    // Note: All global variables like reportingEngine, report, and filters
                    // are injected via AnalyticsRequest.getRequests()
                    new AnalyticsRequest({
                        limit: 1,
                        projections: [
                            new Metric({
                                LISTENING: { source: ListeningMetrics.mentions },
                                INBOUND_MESSAGE: { dimensionName: 'OUTBOUND_COUNT' },
                                PLATFORM: { dimensionName: 'POST_REACH_COUNT' },
                                BENCHMARKING: {
                                    dimensionName: 'MESSAGE_COUNT',
                                    report: 'BENCHMARKING_SIGNAL',
                                },
                                PAID: {
                                    dimensionName: 'uniqueReach',
                                    report: 'PAID_UNIQUE_MEASUREMENTS_STAT',
                                },
                                ADVOCACY: { dimensionName: 'POINTS' },
                                STORY_MESSAGE: {
                                    dimensionName: 'MENTIONS_COUNT',
                                    heading: 'Mentions',
                                },
                            }),
                        ],
                        groupBys: [
                            new GroupBy({
                                LISTENING: { source: ListeningDimensions.socialNetwork },
                                INBOUND_MESSAGE: { dimensionName: 'SN_TYPE' },
                                PLATFORM: { dimensionName: 'SN_TYPE' },
                                BENCHMARKING: { dimensionName: 'BENCHMARKING_SN_TYPE' },
                                PAID: { dimensionName: 'socialChannelTypes' },
                                ADVOCACY: { dimensionName: 'SN_TYPE', groupType: 'FIELD' },
                                STORY_MESSAGE: { dimensionName: 'STORY_CATEGORY' },
                            }),
                        ],
                        sorts: [new Sort({ order: 'DESC', projections: 0 })],
                        timePeriods: [{ key: 'last_7_days' }, { previousPeriod: true }],
                    }) as any,
                ],
                position: {
                    width: '33%',
                    height: '50%',
                    top: '0',
                    left: '33.33%',
                    portrait: {
                        width: '50%',
                        height: '33%',
                        top: '33.33%',
                        left: '0',
                    },
                },
                label: {
                    titleText: new Label({
                        LISTENING: 'Top Mentions Platform',
                        INBOUND_MESSAGE: 'Top Outbound Platform',
                        PLATFORM: 'Top Post Reach Channel',
                        BENCHMARKING: 'Top Messages Platform',
                        PAID: 'Spent by Campaign',
                        ADVOCACY: 'Top Points Platform',
                        STORY_MESSAGE: 'Top Mentions Platform',
                    }) as any,
                    enabled: true,
                    size: WIDGET_LABEL_SIZE.secondary,
                },
                options: {
                    player: {
                        playing: true,
                        duration: 15,
                    },
                    showMetricValue: true,
                    metricValueSize: 45,

                    showMetricName: true,
                    metricNameSize: 12,

                    metricPercentChangeSize: 14,
                    showIcon: true,
                    showMetricChange: false,
                    alignment: 'left',
                    animationDelay: 100,
                    portrait: {
                        metricValueSize: 40,
                    },
                },
            },
            {
                type: 'metric',
                id: '3',
                name: 'metric',
                useGlobalTimePeriod: true,
                analyticsRequests: [
                    // Note: All global variables like reportingEngine, report, and filters
                    // are injected via AnalyticsRequest.getRequests()
                    new AnalyticsRequest({
                        limit: 1,
                        projections: [
                            new Metric({
                                LISTENING: { source: ListeningMetrics.reach },
                                INBOUND_MESSAGE: { dimensionName: 'LIKE_COUNT' },
                                PLATFORM: {
                                    dimensionName: 'FOLLOWERS_COUNT',
                                    report: 'ACCOUNT_INSIGHTS',
                                },
                                BENCHMARKING: {
                                    dimensionName: 'subscriberCountTotal',
                                    report: 'ACCOUNT_FACTS',
                                },
                                PAID: { dimensionName: 'positiveSentimentCount' },
                                ADVOCACY: {
                                    dimensionName: 'ADVOCACY_TOTAL_USER_SHARES',
                                    heading: 'Total_Shares',
                                },
                                STORY_MESSAGE: {
                                    dimensionName: 'REACH',
                                    heading: 'Reach',
                                },
                            }),
                        ],
                        groupBys: [
                            new GroupBy({
                                LISTENING: { source: ListeningDimensions.socialNetwork },
                                INBOUND_MESSAGE: { dimensionName: 'SN_TYPE' },
                                PLATFORM: { dimensionName: 'SN_TYPE' },
                                BENCHMARKING: { dimensionName: 'BENCHMARKING_SN_TYPE' },
                                PAID: { dimensionName: 'socialChannelTypes' },
                                ADVOCACY: { dimensionName: 'SN_TYPE', groupType: 'FIELD' },
                                STORY_MESSAGE: { dimensionName: 'STORY_CATEGORY' },
                            }),
                        ],
                        sorts: [new Sort({ order: 'DESC', projections: 0 })],
                        timePeriods: [{ key: 'last_7_days' }, { previousPeriod: true }],
                    }) as any,
                ],
                position: {
                    width: '33%',
                    height: '50%',
                    top: '0',
                    left: '66.66%',
                    portrait: {
                        width: '50%',
                        height: '33%',
                        top: '66.66%',
                        left: '0',
                    },
                },
                label: {
                    titleText: new Label({
                        LISTENING: 'Top Reach Platform',
                        INBOUND_MESSAGE: 'Top Likes Platform',
                        PLATFORM: 'Top Followers Platform',
                        BENCHMARKING: 'Top Followers Platform',
                        PAID: 'Impressions',
                        ADVOCACY: 'Top Shares Platform',
                        STORY_MESSAGE: 'Top Reach Platform',
                    }) as any,
                    enabled: true,
                    size: WIDGET_LABEL_SIZE.secondary,
                },
                options: {
                    player: {
                        playing: true,
                        duration: 15,
                    },
                    showMetricValue: true,
                    metricValueSize: 45,

                    showMetricName: true,
                    metricNameSize: 12,

                    metricPercentChangeSize: 14,
                    showIcon: true,
                    showMetricChange: false,
                    alignment: 'left',
                    animationDelay: 200,
                    portrait: {
                        metricValueSize: 40,
                    },
                },
            },
            {
                type: 'metric',
                id: '4',
                name: 'metric',
                useGlobalTimePeriod: true,
                analyticsRequests: [
                    // Note: All global variables like reportingEngine, report, and filters
                    // are injected via AnalyticsRequest.getRequests()
                    new AnalyticsRequest({
                        limit: 1,
                        projections: [
                            new Metric({
                                LISTENING: { source: ListeningMetrics.distinctUsers },
                                INBOUND_MESSAGE: { dimensionName: 'INBOUND_COUNT' },
                                PLATFORM: { dimensionName: 'TOTAL_ENGAGEMENT' },
                                BENCHMARKING: { dimensionName: 'TOTAL_ENGAGEMENT' },
                                PAID: { dimensionName: 'ENGAGEMENT' },
                                ADVOCACY: {
                                    dimensionName: 'ADVOCACY_TOTAL_USER_ENGAGEMENTS',
                                    heading: 'Total_Engagements',
                                },
                            }),
                        ],
                        groupBys: [
                            new GroupBy({
                                LISTENING: { source: ListeningDimensions.socialNetwork },
                                INBOUND_MESSAGE: { dimensionName: 'SN_TYPE' },
                                PLATFORM: { dimensionName: 'SN_TYPE' },
                                BENCHMARKING: { dimensionName: 'BENCHMARKING_SN_TYPE' },
                                PAID: { dimensionName: 'socialChannelTypes' },
                                ADVOCACY: { dimensionName: 'SN_TYPE', groupType: 'FIELD' },
                            }),
                        ],
                        sorts: [new Sort({ order: 'ASC', projections: 0 })],
                        timePeriods: [{ key: 'last_7_days' }, { previousPeriod: true }],
                    }) as any,
                ],
                position: {
                    width: '33%',
                    height: '50%',
                    top: '50%',
                    left: '0',
                    portrait: {
                        width: '50%',
                        height: '33%',
                        top: '0',
                        left: '50%',
                    },
                },
                label: {
                    titleText: new Label({
                        LISTENING: 'Bottom Distinct User Platform',
                        INBOUND_MESSAGE: 'Bottom Inbound Platform',
                        PLATFORM: 'Bottom Engagements Platform',
                        BENCHMARKING: 'Bottom Engagements Platform',
                        PAID: 'Impressions by Campaign',
                        ADVOCACY: 'Bottom Engagements Platform',
                        STORY_MESSAGE: 'Bottom Top Stories Platform',
                    }) as any,
                    enabled: true,
                    size: WIDGET_LABEL_SIZE.secondary,
                },
                options: {
                    player: {
                        playing: true,
                        duration: 15,
                    },
                    showMetricValue: true,
                    metricValueSize: 45,

                    showMetricName: true,
                    metricNameSize: 12,

                    metricPercentChangeSize: 14,
                    showIcon: true,
                    showMetricChange: false,
                    alignment: 'left',
                    animationDelay: 300,
                    portrait: {
                        metricValueSize: 40,
                    },
                },
            },
            {
                type: 'metric',
                id: '5',
                name: 'metric',
                useGlobalTimePeriod: true,
                analyticsRequests: [
                    // Note: All global variables like reportingEngine, report, and filters
                    // are injected via AnalyticsRequest.getRequests()
                    new AnalyticsRequest({
                        limit: 1,
                        projections: [
                            new Metric({
                                LISTENING: { source: ListeningMetrics.mentions },
                                INBOUND_MESSAGE: { dimensionName: 'OUTBOUND_COUNT' },
                                PLATFORM: { dimensionName: 'POST_REACH_COUNT' },
                                BENCHMARKING: {
                                    dimensionName: 'MESSAGE_COUNT',
                                    report: 'BENCHMARKING_SIGNAL',
                                },
                                PAID: {
                                    dimensionName: 'uniqueReach',
                                    report: 'PAID_UNIQUE_MEASUREMENTS_STAT',
                                },
                                ADVOCACY: { dimensionName: 'POINTS' },
                            }),
                        ],
                        groupBys: [
                            new GroupBy({
                                LISTENING: { source: ListeningDimensions.socialNetwork },
                                INBOUND_MESSAGE: { dimensionName: 'SN_TYPE' },
                                PLATFORM: { dimensionName: 'SN_TYPE' },
                                BENCHMARKING: { dimensionName: 'BENCHMARKING_SN_TYPE' },
                                PAID: { dimensionName: 'socialChannelTypes' },
                                ADVOCACY: { dimensionName: 'SN_TYPE', groupType: 'FIELD' },
                            }),
                        ],
                        sorts: [new Sort({ order: 'ASC', projections: 0 })],
                        timePeriods: [{ key: 'last_7_days' }, { previousPeriod: true }],
                    }) as any,
                ],
                position: {
                    width: '33%',
                    height: '50%',
                    top: '50%',
                    left: '33.33%',
                    portrait: {
                        width: '50%',
                        height: '33%',
                        top: '33.33%',
                        left: '50%',
                    },
                },
                label: {
                    titleText: new Label({
                        LISTENING: 'Bottom Mentions Platform',
                        INBOUND_MESSAGE: 'Bottom Outbound Platform',
                        PLATFORM: 'Bottom Post Reach Channel',
                        BENCHMARKING: 'Bottom Messages Platform',
                        PAID: 'Conversions',
                        ADVOCACY: 'Bottom Points Platform',
                        STORY_MESSAGE: 'Bottom Mentions Platform',
                    }) as any,
                    enabled: true,
                    size: WIDGET_LABEL_SIZE.secondary,
                },
                options: {
                    player: {
                        playing: true,
                        duration: 15,
                    },
                    showMetricValue: true,
                    metricValueSize: 45,

                    showMetricName: true,
                    metricNameSize: 12,

                    metricPercentChangeSize: 14,
                    showIcon: true,
                    showMetricChange: false,
                    alignment: 'left',
                    animationDelay: 400,
                    portrait: {
                        metricValueSize: 40,
                    },
                },
            },
            {
                id: '6',
                name: 'metric',
                useGlobalTimePeriod: true,
                analyticsRequests: [
                    // Note: All global variables like reportingEngine, report, and filters
                    // are injected via AnalyticsRequest.getRequests()
                    new AnalyticsRequest({
                        limit: 1,
                        projections: [
                            new Metric({
                                LISTENING: { source: ListeningMetrics.reach },
                                INBOUND_MESSAGE: { dimensionName: 'LIKE_COUNT' },
                                PLATFORM: {
                                    dimensionName: 'FOLLOWERS_COUNT',
                                    report: 'ACCOUNT_INSIGHTS',
                                },
                                BENCHMARKING: {
                                    dimensionName: 'subscriberCountTotal',
                                    report: 'ACCOUNT_FACTS',
                                },
                                PAID: { dimensionName: 'positiveSentimentCount' },
                                ADVOCACY: {
                                    dimensionName: 'ADVOCACY_TOTAL_USER_SHARES',
                                    heading: 'Total_Shares',
                                },
                            }),
                        ],
                        groupBys: [
                            new GroupBy({
                                LISTENING: { source: ListeningDimensions.socialNetwork },
                                INBOUND_MESSAGE: { dimensionName: 'SN_TYPE' },
                                PLATFORM: { dimensionName: 'SN_TYPE' },
                                BENCHMARKING: { dimensionName: 'BENCHMARKING_SN_TYPE' },
                                PAID: { dimensionName: 'socialChannelTypes' },
                                ADVOCACY: { dimensionName: 'SN_TYPE', groupType: 'FIELD' },
                            }),
                        ],
                        sorts: [new Sort({ order: 'ASC', projections: 0 })],
                        timePeriods: [{ key: 'last_7_days' }, { previousPeriod: true }],
                    }) as any,
                ],
                position: {
                    width: '33%',
                    height: '50%',
                    top: '50%',
                    left: '66.66%',
                    portrait: {
                        width: '50%',
                        height: '33%',
                        top: '66.66%',
                        left: '50%',
                    },
                },
                label: {
                    titleText: new Label({
                        LISTENING: 'Bottom Reach Platform',
                        INBOUND_MESSAGE: 'Bottom Likes Platform',
                        PLATFORM: 'Bottom Followers Platform',
                        BENCHMARKING: 'Bottom Followers Platform',
                        PAID: 'Conversions by Campaign',
                        ADVOCACY: 'Bottom Shares Platform',
                        STORY_MESSAGE: 'Bottom Reach Platform',
                    }) as any,
                    enabled: true,
                    size: WIDGET_LABEL_SIZE.secondary,
                },
                options: {
                    player: {
                        playing: true,
                        duration: 15,
                    },
                    showMetricValue: true,
                    metricValueSize: 45,

                    showMetricName: true,
                    metricNameSize: 12,

                    metricPercentChangeSize: 14,
                    showIcon: true,
                    showMetricChange: false,
                    alignment: 'left',
                    animationDelay: 500,
                    portrait: {
                        metricValueSize: 40,
                    },
                },
                type: 'metric',
            },
        ],
        label: {
            titleText: 'Channel Metrics',
            enabled: true,
            size: WIDGET_LABEL_SIZE.primary,
        },
    },
};
