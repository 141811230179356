import { FontCase } from 'models/Theme/Theme';
import { DecimalFormat } from 'utils/Number/NumberUtils';
import { WidgetOptionsImpl } from 'models/Widget/WidgetOptions';
import { SmartSize } from '@sprinklr/stories/widget/types';
import { observable } from 'mobx';
import { FunnelChartWidgetOptions } from './types';

export interface MetricOptions {
    name: {
        enabled: boolean;
        size: number;
        case: FontCase;
    };
    value: {
        enabled: boolean;
        size: number;
        format: DecimalFormat;
        case: FontCase;
    };
}

export class FunnelChartWidgetOptionsImpl extends WidgetOptionsImpl
    implements FunnelChartWidgetOptions {
    @observable metric: MetricOptions = {
        name: {
            enabled: true,
            size: 25,
            case: 'lowercase',
        },
        value: {
            enabled: true,
            size: 40,
            format: '1k',
            case: 'lowercase',
        },
    };

    @observable spacing = 40;
    @observable labelSpacing = 20;
    @observable borderRadius = 0;
    @observable sortByVolume = true;
    @observable layoutType: 'stacked' | 'bar' = 'bar';
    @observable smartSize: SmartSize = {
        enabled: true,
        mode: 'responsive',
        size: 'xl',
        baseline: {
            spacing: 40,
            labelSpacing: 20,
            metric: {
                name: {
                    size: 25,
                },
                value: {
                    size: 40,
                },
            },
        },
    };
}
