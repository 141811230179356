import { AnalyticsEngine, AnalyticsFilter } from '@sprinklr/stories/analytics/AnalyticsRequest';

export interface SourceDimension {
    dimension: string;
    required: boolean;
    additional: {
        engine?: string;
        reportName: string;
        metaParent?: boolean;
        metaChild?: boolean;
        excludePlaylist?: boolean;
        permissions?: string;
        includeCompetitor?: boolean;
        xor?: boolean;
    };
}

const LISTENING_TOPIC_IDS: SourceDimension[] = [
    {
        dimension: 'TOPIC_GROUP_IDS',
        required: true,
        additional: {
            engine: 'LISTENING',
            reportName: 'SPRINKSIGHTS',
            metaParent: true, // Client-side prop, expresses parent-child relationship between filters
        },
    },
    {
        dimension: 'TOPIC_IDS',
        required: false,
        additional: {
            engine: 'LISTENING',
            reportName: 'SPRINKSIGHTS',
            metaChild: true, // Client-side prop, expresses parent-child relationship between filters
        },
    },
];

const LISTENING_TOPIC_TAGS: SourceDimension[] = [
    {
        dimension: 'TOPIC_TAGS',
        required: true,
        additional: {
            engine: 'LISTENING',
            reportName: 'SPRINKSIGHTS',
        },
    },
];

const HIERARCHY_ID: SourceDimension[] = [
    {
        dimension: 'HIERARCHY_ID',
        required: true,
        additional: {
            engine: 'LISTENING',
            reportName: 'SPRINKSIGHTS',
        },
    },
];

const PLATFORM_ACCOUNT_ID: SourceDimension[] = [
    {
        dimension: 'CLIENT_ID',
        required: true,
        additional: {
            engine: 'PLATFORM',
            reportName: undefined,
            metaParent: true, // Client-side prop, expresses parent-child relationship between filters
        },
    },
    // This is list is filtered by CLIENT_ID, like TOPIC_GROUP_IDS
    {
        dimension: 'ACCOUNT_ID',
        required: false,
        additional: {
            engine: 'PLATFORM',
            reportName: undefined,
            excludePlaylist: true,
            permissions: 'VIEW_REPORTING',
            metaChild: true, // Client-side prop, expresses parent-child relationship between filters
        },
    },
];

const BENCHMARKING_BRAND_ID: SourceDimension[] = [
    {
        dimension: 'BRAND_ID',
        required: false,
        additional: {
            reportName: 'POST_STATS_LIFETIME',
            includeCompetitor: false,
        },
    },
    {
        dimension: 'BRAND_ID',
        required: false,
        additional: {
            reportName: 'POST_STATS_LIFETIME',
            includeCompetitor: true,
        },
    },
];

const CAMPAIGN_ID: SourceDimension[] = [
    {
        dimension: 'CAMPAIGN_ID',
        required: false,
        additional: {
            engine: 'CAMPAIGN',
            reportName: 'CAMPAIGN',
        },
    },
];

const ADS_ACCOUNT_ID: SourceDimension[] = [
    {
        dimension: 'clientId',
        required: true,
        additional: {
            engine: 'PLATFORM',
            reportName: undefined,
            metaParent: true, // Client-side prop, expresses parent-child relationship between filters
        },
    },
    {
        dimension: 'accountIds', //This is filtered by client id
        required: false,
        additional: {
            engine: 'PLATFORM',
            reportName: undefined,
            excludePlaylist: true,
            permissions: 'VIEW_REPORTING',
            metaChild: true, // Client-side prop, expresses parent-child relationship between filters
        },
    },
];

const LOCATION: SourceDimension[] = [
    {
        dimension: 'WOEID',
        required: false,
        additional: {
            engine: 'TWITTER',
            reportName: 'TRENDS',
        },
    },
];

const RDB_FIREHOSE: SourceDimension[] = [
    {
        dimension: 'RDB_QUERY_IDS',
        required: false,
        additional: {
            engine: 'RDB_FIREHOSE',
            reportName: 'RDB_FIREHOSE',
            xor: true, // User should select one of these filters, but not both
        },
    },
    {
        dimension: 'QUERY',
        required: false,
        additional: {
            engine: 'RDB_FIREHOSE',
            reportName: 'RDB_FIREHOSE',
            xor: true, // User should select one of these filters, but not both
        },
    },
];

const STORY_MESSAGE_QUERY: SourceDimension[] = [
    {
        dimension: 'STORY_QUERY_ID',
        required: true,
        additional: {
            engine: 'STORY_MESSAGE',
            reportName: 'SPRINKSIGHTS',
        },
    },
];

const STORY_MESSAGE_TAGS: SourceDimension[] = [
    {
        dimension: 'STORY_TAGS',
        required: true,
        additional: {
            engine: 'STORY_MESSAGE',
            reportName: 'SPRINKSIGHTS',
        },
    },
];

const STORY_MESSAGE_QUERY_TAGS: SourceDimension[] = [
    {
        dimension: 'STORY_QUERY_TAGS',
        required: true,
        additional: {
            engine: 'STORY_MESSAGE',
            reportName: 'SPRINKSIGHTS',
        },
    },
];

/**
 * Wraps a raw AnalyticsFilter, providing business logic for form interaction
 */
export default class WidgetFilterSources {
    static findRulesDimension(engine: string, dataFilters: AnalyticsFilter[]): string {
        switch (engine) {
            case 'LISTENING':
                if (WidgetFilterSources.checkRules(HIERARCHY_ID, dataFilters)) {
                    return 'HIERARCHY_ID';
                } else if (WidgetFilterSources.checkRules(LISTENING_TOPIC_TAGS, dataFilters)) {
                    return 'TOPIC_TAGS';
                } else {
                    return 'TOPIC_IDS';
                }

            case 'INBOUND_MESSAGE':
            case 'PLATFORM':
                return 'ACCOUNT_ID';

            case 'PAID':
                return 'accountIds';

            case 'BENCHMARKING':
                return 'BRAND_ID';

            case 'CAMPAIGN':
                return 'CAMPAIGN_ID';

            case 'TWITTER':
                return 'WOEID';

            case 'RDB_FIREHOSE':
                return 'RDB_QUERY_IDS';

            case 'STORY_MESSAGE':
                if (WidgetFilterSources.checkRules(STORY_MESSAGE_QUERY_TAGS, dataFilters)) {
                    return 'STORY_QUERY_TAGS';
                } else if (WidgetFilterSources.checkRules(STORY_MESSAGE_TAGS, dataFilters)) {
                    return 'STORY_TAGS';
                } else {
                    return 'STORY_QUERY_ID';
                }
        }

        return null;
    }

    static getRulesDimensions(engine: string): Array<{ id: string; name: string }> {
        switch (engine) {
            case 'LISTENING':
                return [
                    { id: 'TOPIC_IDS', name: 'Topic Ids' },
                    { id: 'TOPIC_TAGS', name: 'Topic Tags' },
                    { id: 'HIERARCHY_ID', name: 'Product/Location Insights' },
                ];

            case 'INBOUND_MESSAGE':
            case 'PLATFORM':
                return [{ id: 'ACCOUNT_ID', name: 'Account Id' }];

            case 'PAID':
                return [{ id: 'accountIds', name: 'Account Id' }];

            case 'BENCHMARKING':
                return [{ id: 'BRAND_ID', name: 'Brand Id' }];

            case 'CAMPAIGN':
                return [{ id: 'CAMPAIGN_ID', name: 'Campaign Id' }];

            case 'TWITTER':
                return [{ id: 'TRENDING_TOPIC', name: 'Trending Topic' }];

            case 'STORY_MESSAGE':
                return [
                    { id: 'STORY_QUERY_ID', name: 'Story Query' },
                    { id: 'STORY_TAGS', name: 'Story Tags' },
                    { id: 'STORY_QUERY_TAGS', name: 'Story Query Tags' },
                ];
        }

        return [];
    }

    static getRules(engine: AnalyticsEngine, dimension: string): SourceDimension[] {
        switch (engine) {
            case 'LISTENING':
                if (dimension === 'TOPIC_IDS') {
                    return LISTENING_TOPIC_IDS;
                } else if (dimension === 'TOPIC_TAGS') {
                    return LISTENING_TOPIC_TAGS;
                } else if (dimension === 'HIERARCHY_ID') {
                    return HIERARCHY_ID;
                }
                break;

            // Reporting Insights: Social and Inbound analytics
            case 'INBOUND_MESSAGE':
            case 'PLATFORM':
                return PLATFORM_ACCOUNT_ID;

            case 'PAID':
                return ADS_ACCOUNT_ID;

            // Benchmarking Insights
            case 'BENCHMARKING':
                return BENCHMARKING_BRAND_ID;

            case 'CAMPAIGN':
                return CAMPAIGN_ID;

            case 'TWITTER':
                return LOCATION;

            case 'RDB_FIREHOSE':
                return RDB_FIREHOSE;

            case 'STORY_MESSAGE':
                if (dimension === 'STORY_QUERY_ID') {
                    return STORY_MESSAGE_QUERY;
                } else if (dimension === 'STORY_TAGS') {
                    return STORY_MESSAGE_TAGS;
                } else if (dimension === 'STORY_QUERY_TAGS') {
                    return STORY_MESSAGE_QUERY_TAGS;
                }
        }

        return null;
    }

    static setFilterDefaults(
        engine: string,
        dimensionOld: string,
        dimensionNew: string,
        dataFilters: AnalyticsFilter[]
    ): void {
        if (dimensionOld && dimensionOld !== dimensionNew) {
            switch (engine) {
                case 'LISTENING':
                case 'STORY_MESSAGE':
                    const rulesOld = WidgetFilterSources.getRules(engine, dimensionOld);

                    let x = rulesOld.length;
                    while (x--) {
                        let y = dataFilters.length;
                        while (y--) {
                            if (dataFilters[y].dimensionName === rulesOld[x].dimension) {
                                dataFilters.shift();
                            }
                        }
                    }

                    switch (dimensionNew) {
                        case 'TOPIC_IDS':
                            dataFilters.unshift({
                                dimensionName: 'TOPIC_GROUP_IDS',
                                filterType: 'IN',
                                values: [],
                            });
                            break;

                        case 'TOPIC_TAGS':
                            dataFilters.unshift({
                                dimensionName: 'TOPIC_TAGS',
                                filterType: 'IN',
                                values: [],
                            });
                            break;

                        case 'HIERARCHY_ID':
                            dataFilters.unshift({
                                dimensionName: 'HIERARCHY_ID',
                                filterType: 'IN',
                                values: [],
                            });
                            break;

                        case 'STORY_QUERY_ID':
                            dataFilters.unshift({
                                dimensionName: 'STORY_QUERY_ID',
                                filterType: 'IN',
                                values: [],
                            });
                            break;

                        case 'STORY_TAGS':
                            dataFilters.unshift({
                                dimensionName: 'STORY_TAGS',
                                filterType: 'IN',
                                values: [],
                            });
                            break;

                        case 'STORY_QUERY_TAGS':
                            dataFilters.unshift({
                                dimensionName: 'STORY_QUERY_TAGS',
                                filterType: 'IN',
                                values: [],
                            });
                            break;
                    }
                    break;

                case 'INBOUND_MESSAGE':
                case 'PLATFORM':
                case 'BENCHMARKING':
                case 'CAMPAIGN':
                    break;
            }
        }
    }

    private static checkRules(rules: SourceDimension[], dataFilters: AnalyticsFilter[]): boolean {
        if (dataFilters.length && rules[0].dimension === dataFilters[0].dimensionName) {
            return true;
        } else {
            return false;
        }
    }
}
