import { GraphQLService } from '../GraphQLService/GraphQLService';
import { UserAsset, UserAssetFields } from 'models/UserAsset/UserAsset';
import { BaseRecordService } from 'utils/BaseRecordService/BaseRecordService';
import { Mapper } from '../DataStore/DataStore';
import { AxiosInstance } from 'axios';

const createUserAsset = `
mutation CreateUserAsset($input: CreateUserAssetInput!) {
    createUserAsset(input: $input) {
        userAsset {
            ${UserAssetFields}
        }
        clientMutationId
    }
}
`;

const deleteUserAsset = `
mutation DeleteUserAsset($input: DeleteUserAssetInput!) {
    deleteUserAsset(input: $input) {
        userAsset {
            ${UserAssetFields}
        }
        clientMutationId
    }
}
`;

const userAssetUploadSucceeded = `
mutation UserAssetUploadSucceeded($input: UserAssetUploadSucceededInput!) {
    userAssetUploadSucceeded(input: $input) {
        userAsset {
            ${UserAssetFields}
        }
        clientMutationId
    }
}
`;

const userAssetUploadFailed = `
mutation UserAssetUploadFailed($input: UserAssetUploadFailedInput!) {
    userAssetUploadFailed(input: $input) {
        userAsset {
            ${UserAssetFields}
        }
        clientMutationId
    }
}
`;

export type UploadProgressCallback = (progressEvent: any, userAsset?: UserAsset) => void;

export class UserAssetService extends BaseRecordService<UserAsset> {
    constructor(private axios: AxiosInstance, graphQL: GraphQLService, mapper: Mapper<UserAsset>) {
        super(graphQL, mapper);
    }

    /**
     * Create a UserAsset. This must be done before uploading in order to get signed upload URLs.
     * @param userAsset
     */
    public createAsset(userAsset: UserAsset): Promise<UserAsset> {
        return this.mutate({
            queryParams: {
                query: createUserAsset,
                variables: { input: { userAsset } },
            },
            extractor: (result: any) => result.createUserAsset.userAsset,
        });
    }

    public deleteAsset(userAsset: UserAsset | string): Promise<UserAsset> {
        return this.mutate({
            queryParams: {
                query: deleteUserAsset,
                variables: {
                    input: {
                        userAssetId: this.id(userAsset),
                    },
                },
            },
            extractor: (result: any) => result.deleteUserAsset.userAsset,
        });
    }

    /**
     * Tell the Display API that the upload of the asset from the browser to S3 failed. Optionally include details such
     * as the exact error message received.
     * @param userAsset
     * @param errorMessage
     */
    public uploadFailed(userAsset: UserAsset | string, errorMessage?: string): Promise<UserAsset> {
        return this.mutate({
            queryParams: {
                query: userAssetUploadFailed,
                variables: {
                    input: {
                        userAssetId: this.id(userAsset),
                        errorMessage,
                    },
                },
            },
            extractor: (result: any) => result.userAssetUploadFailed.userAsset,
        });
    }

    /**
     * Tell the Display API that a UserAsset has been successfully uploaded. It's recommended to wait until this promise
     * resolves before attempting to load the asset from the userAsset.contentUrl as the Display API will block the
     * response until it's verified that S3 confirms the existence of the upload. This helps avoid a race condition
     * between upload completion and full availability of the asset via CloudFront.
     * @param userAsset
     */
    public uploadSucceeded(userAsset: UserAsset | string): Promise<UserAsset> {
        return this.mutate({
            queryParams: {
                query: userAssetUploadSucceeded,
                variables: {
                    input: {
                        userAssetId: this.id(userAsset),
                    },
                },
            },
            extractor: (result: any) => result.userAssetUploadSucceeded.userAsset,
        });
    }

    private id(userAsset: UserAsset | string): string {
        if (typeof userAsset === 'string') {
            return userAsset;
        }

        return userAsset && userAsset.id;
    }

    /**
     * One-stop UserAsset upload. Will invoke this.createAsset(), perform a PUT with axios, and upon success (or failure)
     * notify the Display API.
     * @param file
     * @param onUploadProgress
     */
    public upload(
        file: File,
        metadata?: { panelId?: string; storyboardId?: string },
        onUploadProgress?: UploadProgressCallback
    ): Promise<UserAsset> {
        const userAsset: UserAsset = {
            mediaType: file.type,
            size: file.size,
            name: file.name,
            ...metadata,
        };

        return this.createAsset(userAsset).then((createdAsset: UserAsset) => {
            const config = {
                headers: {
                    'Content-Type': file.type,
                },
                // if no onUploadProgress callback is provided, the default will log to the console.
                onUploadProgress: onUploadProgress
                    ? event => onUploadProgress(event, createdAsset)
                    : (progressEvent: UploadProgressCallback) => {
                          console.log('upload progress', progressEvent);
                      },
            };

            return (
                this.axios
                    .put(createdAsset.uploadUrl, file, config)
                    // let the API know we succeeded
                    .then(() => this.uploadSucceeded(createdAsset))
                    // or let the API know we failed
                    .catch(reason => {
                        this.uploadFailed(createdAsset, reason);
                        throw reason;
                        // Promise.reject(reason);
                    })
            );
        });
    }
}
