import { borderStyles, ComputedStyle, styler } from 'utils/GenerateStyles/GenerateStyles';
import { GetWidgetTypeStyles } from 'models/Widget/WidgetType';
import { Theme } from 'models/Theme/Theme';

export const rankedTableOptionStyles = (options, mergedTheme, theme) => {
    const { columnNameSize, rowSize, showRowSize, dimensionSize, borders } = options;

    const fallBackColor = theme.typography.color || mergedTheme.typography.color;
    const { top, bottom } = borders;

    const computedStyles: ComputedStyle[] = [
        {
            selector: '.column_name',
            styles: {
                fontSize: styler(columnNameSize, 0.1, 'em', ''),
            },
        },
        {
            selector: '.dimension_size',
            styles: {
                fontSize: styler(dimensionSize, 0.1, 'em', ''),
            },
        },
    ];

    if (showRowSize) {
        computedStyles.push({
            selector: '.row_name_group',
            styles: {
                lineHeight: styler(rowSize, 0.1, 'em', ''),
                paddingTop: styler(rowSize, 0.025, 'em', ''),
                paddingBottom: styler(rowSize, 0.025, 'em', ''),
            },
        });

        computedStyles.push({
            selector: '.cell',
            styles: {
                paddingTop: styler(rowSize, 0.025, 'em', ''),
                paddingBottom: styler(rowSize, 0.025, 'em', ''),
            },
        });
        if (top.enabled) {
            computedStyles.push({
                selector: '.ranked_table .row_key',
                styles: {
                    borderBottom: `${top.size * 0.1}px solid ${top.color || fallBackColor}`,
                },
            });
        }
        if (bottom.enabled) {
            computedStyles.push({
                selector: '.ranked_table .row.last_row',
                styles: {
                    borderBottom: `${bottom.size * 0.1}px solid ${bottom.color || fallBackColor}`,
                },
            });
        }
    }
    if (top?.enabled) {
        computedStyles.push({
            selector: '.ranked_table .row_key',
            styles: {
                borderBottom: `${top.size * 0.1}px solid ${top.color || fallBackColor}`,
            },
        });
    }
    if (bottom?.enabled) {
        computedStyles.push({
            selector: '.ranked_table .row.last_row',
            styles: {
                borderBottom: `${bottom.size * 0.1}px solid ${bottom.color || fallBackColor}`,
            },
        });
    }

    return computedStyles;
};

export const getRankedTableCss: GetWidgetTypeStyles = (mergedTheme: Theme) => {
    const { typography: { color = null } = {} } = mergedTheme;
    const borderStyle = borderStyles(color);

    return `.ranked_table .row, .ranked_table .row_key {border-bottom: ${borderStyle}}`;
};
