import { AnalyticsRequest, GroupBy, Metric, Sort } from './PanelTemplatesTheme/PanelTemplatesTheme';
import { DefaultWidgetPosition } from './PanelTemplatesTheme/constants';
import { Widget } from '@sprinklr/stories/widget/Widget';
import { WIDGET_LABEL_SIZE } from '../../components/Widget/WidgetLabel/constants';
import ListeningDimensions from '@sprinklr/stories-services/AnalyticsService/ListeningDimensions';
import { TEXT_SHADOW_DEFAULT } from 'models/Widget/Widget';

export var WidgetBubbleChart: Widget = {
    type: 'bubbleChart',
    id: 'bubble-chart',
    name: 'Bubble Chart',
    useGlobalTimePeriod: true,
    analyticsRequests: [
        // Note: All global variables like reportingEngine, report, filters, and projections
        // are injected via AnalyticsRequest.getRequests()
        new AnalyticsRequest({
            limit: 3,
            projections: [
                new Metric({
                    STORY_MESSAGE: { dimensionName: 'NEWS_COUNT', heading: 'Mentions' },
                }),
            ],
            groupBys: [
                new GroupBy({
                    LISTENING: { source: ListeningDimensions.wordCloudMessage },
                    INBOUND_MESSAGE: { dimensionName: 'ACCOUNT_ID' },
                    PLATFORM: { dimensionName: 'ACCOUNT_ID' },
                    BENCHMARKING: { dimensionName: 'BENCHMARKING_ACCOUNT_ID' },
                    PAID: { dimensionName: 'accountIds' },
                    ADVOCACY: { dimensionName: 'COMMUNITY_USER_ID' },
                    RDB_FIREHOSE: { dimensionName: 'RDB_QUERY_IDS' },
                    STORY_MESSAGE: { dimensionName: 'COUNTRY' },
                    TWITTER: { dimensionName: 'TRENDING_TOPIC' },
                }),
            ],
            sorts: [
                new Sort({ order: 'DESC', projections: 0 }),
                new Sort({ order: 'ASC', groupBys: 0 }),
            ],
            timePeriods: [{ key: 'last_7_days' }, { previousPeriod: true }],
        }) as any,
    ],
    position: DefaultWidgetPosition,
    label: {
        titleText: 'Bubble Chart',
        overlap: true,
        enabled: false,
        size: WIDGET_LABEL_SIZE.primary,
        textShadow: TEXT_SHADOW_DEFAULT,
    },
    options: {
        player: {
            playing: true,
            duration: 15,
        },
        labelSize: 25,
        metricValueSize: 48,
        metricPercentChangeSize: 20,
        metricNameSize: 15,
        highlightPercentChange: false,
        showMetricValue: true,
        showMetricName: true,
        showMetricChange: false,
        smartSize: {
            enabled: true,
            mode: 'responsive',
            size: 'm',
        },
        portrait: {
            zoom: 55,
        },
    },
};
