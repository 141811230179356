import { BaseRecordService } from 'utils/BaseRecordService/BaseRecordService';
import { Pptx } from 'models/Pptx/Pptx';
import { CommonDocFields } from 'models/CommonDoc/CommonDoc';
import { GraphQLService } from 'services/GraphQLService/GraphQLService';
import { Mapper } from 'services/DataStore/DataStore';
import { CompletableFields } from 'models/Pdf/Pdf';

export class PptxFields {
    public static readonly fields = `
        ${CommonDocFields}
        ${CompletableFields}
        filename
        storyboardId
        storyboardVersionId
        downloadUrl
        pages {
            ${CompletableFields}
            number
            panelId
            panelVersionId
        }
    `;
    public static readonly toString = () => PptxFields.fields;
}

const fetchPptx = `
query fetchPptx($id: ID!) {
    pptx(id: $id) {
        ${PptxFields}
    }
}`;

const createPptx = `
mutation CreateStoryboardVersionPptx($input: CreateStoryboardVersionPptxInput!) {
    createStoryboardVersionPptx(input: $input) {
        pptx {
            ${PptxFields}
        }
        clientMutationId
    }
}`;

export class PptxService extends BaseRecordService<Pptx> {
    constructor(graphQL: GraphQLService, mapper: Mapper<Pptx>) {
        super(graphQL, mapper);
    }

    find(id: string): Promise<Pptx> {
        return this.query({
            name: 'pptx',
            queryParams: {
                query: fetchPptx,
                variables: { id },
            },
            model: 'pptx',
            extractor: result => result['pptx'],
        });
    }

    createStoryboardVersionPptx(storyboardVersionId: string): Promise<Pptx> {
        return this.mutate({
            name: 'createStoryboardVersionPptx',
            queryParams: {
                query: createPptx,
                variables: { input: { storyboardVersionId } },
            },
            extractor: result => result['createStoryboardVersionPptx']['pptx'],
            model: 'pptx',
        });
    }
}
