import { Mapper } from '../DataStore/DataStore';
import { GraphQLService } from '../GraphQLService/GraphQLService';
import { BaseRecordService } from 'utils/BaseRecordService/BaseRecordService';
import { User } from 'models/User/User';
import { UserImpl } from 'models/User/User';

export class UserService extends BaseRecordService<User> {
    // private queries: GraphQLQueries;

    constructor(graphQL: GraphQLService, mapper: Mapper<User>) {
        super(graphQL, mapper);
        // this.queries = new GraphQLQueries();
    }

    partnerUsers(): Promise<Array<User>> {
        return this.queryManyRaw(
            {
                query: `query partnerUsers {
                    partner {
                        users { ${UserImpl.graphQLFields} }
                    }
                }`,
                variables: {},
            },
            (data: any) => {
                if (data && data.partner && data.partner.users) {
                    return data.partner.users as Array<User>;
                } else {
                    return [] as Array<User>;
                }
            }
        );
    }
}
