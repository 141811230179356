import { AnalyticsRequest, GroupBy, Sort, Metric } from './PanelTemplatesTheme/PanelTemplatesTheme';
import { DefaultPosition } from './PanelTemplatesTheme/constants';
import { Panel } from '../../models/Panel/Panel';
import { WIDGET_LABEL_SIZE } from '../../components/Widget/WidgetLabel/constants';
import ListeningDimensions from '@sprinklr/stories-services/AnalyticsService/ListeningDimensions';
import ListeningMetrics from '@sprinklr/stories-services/AnalyticsService/ListeningMetrics';

export const TemplateAxisBubbleChart: Panel = {
    id: 'axis-bubble',
    name: 'Axis Bubble Chart',
    description: '',
    tags: ['Standard', 'Bubble', 'landscape', 'portrait'],
    widget: {
        id: '1',
        position: {
            width: '1920px',
            height: '1080px',
            top: '0',
            left: '0',
        },
        classes: 'template_axis_bubble_chart',
        children: [
            {
                type: 'axisBubbleChart',
                id: '1',
                name: 'Axis Bubble Chart',
                useGlobalTimePeriod: true,
                analyticsRequests: [
                    // Note: All global variables like reportingEngine, report, filters, and projections
                    // are injected via AnalyticsRequest.getRequests()
                    new AnalyticsRequest({
                        limit: 100,
                        groupBys: [
                            new GroupBy({
                                LISTENING: { source: ListeningDimensions.topicName },
                                INBOUND_MESSAGE: { dimensionName: 'ACCOUNT_ID' },
                                PLATFORM: { dimensionName: 'ACCOUNT_ID' },
                                BENCHMARKING: {
                                    source: ListeningDimensions.topicName,
                                    dimensionName: 'BRAND_ID',
                                },
                                PAID: { dimensionName: 'accountIds' },
                                ADVOCACY: { dimensionName: 'AUDIENCE_ACTIVITY_TYPE' },
                                RDB_FIREHOSE: { dimensionName: 'RDB_QUERY_IDS' },
                                STORY_MESSAGE: { dimensionName: 'PUBLICATION_NAME' },
                            }),
                        ],
                        projections: [
                            new Metric({
                                LISTENING: { source: ListeningMetrics.mentions },
                                INBOUND_MESSAGE: { dimensionName: 'OUTBOUND_COUNT' },
                                PLATFORM: { dimensionName: 'ENGAGEMENT_RATE' },
                                BENCHMARKING: { dimensionName: 'ENGAGEMENT_RATE' },
                                PAID: { dimensionName: 'impressions' },
                                ADVOCACY: {
                                    dimensionName: 'AUDIENCE_ACTIVITY_COUNT',
                                    heading: 'Activity_Count',
                                },
                                RDB_FIREHOSE: {
                                    dimensionName: 'REACH_COUNT',
                                    heading: 'Reach',
                                },
                                STORY_MESSAGE: {
                                    dimensionName: 'WEB_REACH',
                                    heading: 'Web_Reach',
                                },
                            }),
                            new Metric({
                                LISTENING: { source: ListeningMetrics.reach },
                                INBOUND_MESSAGE: { dimensionName: 'INBOUND_COUNT' },
                                PLATFORM: { dimensionName: 'TOTAL_ENGAGEMENT' },
                                BENCHMARKING: { dimensionName: 'TOTAL_ENGAGEMENT' },
                                PAID: { dimensionName: 'spent' },
                                ADVOCACY: { dimensionName: 'POINTS' },
                                RDB_FIREHOSE: {
                                    dimensionName: 'MENTIONS_COUNT',
                                    heading: 'Mentions',
                                },
                                STORY_MESSAGE: {
                                    dimensionName: 'IMPACT',
                                    heading: 'Web_Impact',
                                    aggregateFunction: 'AVG',
                                },
                            }),
                            new Metric({
                                LISTENING: { source: ListeningMetrics.distinctUsers },
                                INBOUND_MESSAGE: { dimensionName: 'INBOUND_COUNT' },
                                PLATFORM: {
                                    dimensionName: 'POST_REACH_COUNT',
                                    heading: 'Post_Reach_Trend',
                                    details: {
                                        origReport: 'POST_INSIGHTS_TREND',
                                        dataType: 'NUMERIC',
                                    },
                                },
                                BENCHMARKING: { dimensionName: 'TOTAL_LIKES' },
                                PAID: { dimensionName: 'spent' },
                                ADVOCACY: {
                                    dimensionName: 'ADVOCACY_TOTAL_USER_ENGAGEMENTS',
                                    heading: 'Total_Engagements',
                                },
                                RDB_FIREHOSE: {
                                    dimensionName: 'DISTINCT_USER_COUNT',
                                    heading: 'Distinct Users',
                                },
                                STORY_MESSAGE: {
                                    dimensionName: 'NEWS_COUNT',
                                    heading: 'News_Mentions',
                                },
                            }),
                        ],
                        sorts: [new Sort({ order: 'DESC', projections: 0 })],
                        timePeriods: [{ key: 'last_7_days' }, { previousPeriod: true }],
                    }) as any,
                ],
                label: {
                    titleText: 'Axis Bubble Chart',
                    enabled: false,
                    size: WIDGET_LABEL_SIZE.primary,
                },
                position: DefaultPosition,
                options: {
                    player: {
                        playing: true,
                        duration: 15,
                    },
                    maxItems: 20,
                    smartSize: {
                        enabled: true,
                        mode: 'responsive',
                        size: 'xl',
                    },
                    portrait: {
                        widgetOrientation: 'portrait',
                    },
                },
            },
        ],
    },
};
