import * as React from 'react';
import {
    engagementTunables,
    extraPostDataTunables,
    headerTunables,
    imageAdjustmentTunables,
    imagePostTunables,
    nativeStylingTunables,
    opacityTunables,
    postTitleTunables,
    profanityTunables,
    smartSizeTunables,
    textEntityTunables,
    textPostTunables,
    userGroupTunables,
} from 'components/Sidebar/RenderTunables/SharedTunables';
import { CollapseProps, TunablesCollapse } from 'components/Sidebar/RenderTunables/RenderTunables';
import { gridLayoutOptions } from 'components/Panel/PanelEditorSidebar/PanelDesign/PanelDesignOptions';
import { Widget } from '@sprinklr/stories/widget/Widget';
import { Theme } from 'models/Theme/Theme';
import { PostCardWidgetOptions } from '../options';
import ActionSliderGroup from 'components/_UI/Forms/Actions/ActionSliderGroup';
import { action } from 'mobx';
import { FormatBucketsOptions } from '@sprinklr/stories/post/PostsFormatRequest';
import { LabeledSelect } from 'components/spaceKit';

const postCardCompositions = [
    { value: 'a', label: 'Default', icon: 'collage_a' },
    { value: 'b', label: 'Alternate', icon: 'collage_b' },
];

export const updateGridLayout = action((event: { value: number }, widget: Widget) => {
    (widget.options as PostCardWidgetOptions).gridLayout.columns = event.value;
    if (widget.postRequests && widget.postRequests[0] && widget.postRequests[0].format) {
        (widget.postRequests[0].format.options as FormatBucketsOptions).panels[0].total =
            event.value;
    }
});

const PostCardTunables = (widget: Widget, mergedTheme: Theme): TunablesCollapse[] => [
    {
        props: {
            label: 'Post Card',
            startOpen: true,
        } as CollapseProps,
        children: [...headerTunables(widget), opacityTunables(widget), smartSizeTunables(widget)],
    },
    nativeStylingTunables(widget),
    {
        props: {
            label: 'Layout',
            startOpen: true,
        } as CollapseProps,
        children: [
            <LabeledSelect
                label='Columns'
                key='widget.options.gridLayout'
                onChange={event => updateGridLayout(event as any, widget)}
                value={(widget.options as PostCardWidgetOptions).gridLayout.columns as any}
                options={gridLayoutOptions}
            />,
            <ActionSliderGroup
                key='widget.options.gridLayout.gap'
                label='Gap'
                option={(widget.options as PostCardWidgetOptions).gridLayout}
                value='gap'
                showValue
                min={0}
                max={100}
            />,
        ],
    },
    extraPostDataTunables(widget, mergedTheme),
    ...getPostTunables(widget, mergedTheme),
];

const getPostTunables = (widget, mergedTheme) => {
    if (
        (widget.options as PostCardWidgetOptions).nativeStyling &&
        (widget.options as PostCardWidgetOptions).nativeStyling.enabled
    ) {
        return [];
    } else {
        return [
            postTitleTunables(widget, mergedTheme),
            textPostTunables(widget, mergedTheme),
            imagePostTunables(widget, mergedTheme),
            imageAdjustmentTunables(widget),
            userGroupTunables(widget),
            engagementTunables(widget),
            textEntityTunables(widget),
            profanityTunables(widget),
        ];
    }
};

export default PostCardTunables;
