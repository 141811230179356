import * as React from 'react';
import styled, { css } from 'components/_UI/designSystem';
import { observer } from 'mobx-react';
import { SocialMediaAsset } from '../../../../models/SocialMediaAsset/SocialMediaAsset';
import { WidgetFont } from '@sprinklr/stories/widget/Widget';
import { CreateFontFace } from 'models/Widget/Widget';
import StyleTag from '../../../StyleTag/StyleTag';
import { observable, action } from 'mobx';
import i18n from '../../../../i18n';
import ActionInput from '../../../_UI/Forms/Actions/ActionInput';
import { TooltipPopper, Flexbox, Text, Icon } from 'components/spaceKit';
import { isGoogleFont } from 'utils/Fonts/googleFonts';
import { getResolvedFamilyWeight } from 'components/_UI/FontLoader/helpers';

export interface TypographyFontControlProps {
    label?: string;
    createFontFaces?: boolean;
    activeFont?: WidgetFont;
    activeFontFamily?: string;
    builtinFonts?: WidgetFont[];
    samFonts?: SocialMediaAsset[];
    onSamFontSelected?: (font: WidgetFont) => void;
    onBuiltinFontSelected?: (font: WidgetFont) => void;
    enableDeselect?: boolean;
    onUpdated?(): void;
}

const StyledFontListItem = styled.li`
    display: flex;
    flex: 1 1 auto;
    align-content: center;
    justify-content: space-between;
    padding: 6px 0;
    opacity: 0.65;
    transition: opacity 300ms;
    cursor: pointer;
    ${({ active }: { active: boolean }) =>
        active &&
        css`
            opacity: 1;
        `}
`;

const StyledInput = styled(ActionInput)`
    border-top-width: 0;
    border-left-width: 0;
    border-right-width: 0;
    outline: none;
`;

const StyledFontText = styled(Text.TextReg12)`
    cursor: pointer;
    ${({ fontFamily }: { fontFamily: string }) =>
        fontFamily &&
        css`
            font-family: ${fontFamily};
        `};
    ${({ fontWeight }: any) =>
        fontWeight &&
        css`
            font-weight: ${fontWeight};
        `};
    ${({ fontStyle }: any) =>
        fontStyle &&
        css`
            font-style: ${fontStyle};
        `};
`;

class TypographyFontControl extends React.Component<TypographyFontControlProps> {
    public static defaultProps: TypographyFontControlProps = {
        enableDeselect: true,
    };

    @observable search: { searchString: string } = {
        searchString: '',
    };
    @observable controlsOpen = false;
    node: any;

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    @action
    toggleControls = () => {
        this.props.onUpdated && this.props.onUpdated();
        this.controlsOpen = !this.controlsOpen;
        this.search.searchString = '';
    };

    render() {
        const { createFontFaces, label, activeFont, samFonts, builtinFonts } = this.props;
        const { onSamFontSelected, onBuiltinFontSelected, enableDeselect } = this.props;

        const searchLC = this.search.searchString ? this.search.searchString.toLowerCase() : '';

        const filteredSamFonts =
            samFonts?.filter(font => font?.sortKey?.toLowerCase()?.includes(searchLC)) ?? [];

        const filteredBuiltInFonts =
            builtinFonts?.filter(font => font?.family?.toLowerCase()?.includes(searchLC)) ?? [];

        if (!activeFont) {
            return <div />;
        }

        return (
            <div ref={this.getNode}>
                {createFontFaces && (
                    <StyleTag
                        css={samFonts
                            .map(asset => CreateFontFace(asset.digitalAsset.font))
                            .join('\n')}
                    />
                )}

                <Flexbox middle onClick={this.toggleControls}>
                    {label && (
                        <TooltipPopper label={label}>
                            {
                                i18n.t(
                                    'To add a new font, upload an OTF based font to Sprinklr Asset Manager as a document file type'
                                ) as string
                            }
                        </TooltipPopper>
                    )}
                    <Flexbox middle between>
                        <StyledFontText
                            fontFamily={
                                this.checkFamily(activeFont)
                                    ? `"${
                                          TypographyFontControl.getGoogleSafeFont(activeFont).family
                                      }"`
                                    : 'inherit'
                            }
                            fontWeight={TypographyFontControl.getGoogleSafeFont(activeFont).weight}>
                            {activeFont.family}
                        </StyledFontText>
                        <Icon
                            value={this.controlsOpen ? 'chevron-down' : 'chevron-right'}
                            size='s'
                        />
                    </Flexbox>
                </Flexbox>

                {this.controlsOpen && (
                    <Flexbox vertical gap='l'>
                        <Flexbox middle gap>
                            <Icon value='search' />
                            <StyledInput
                                placeholder='Search Fonts'
                                option={this.search}
                                value='searchString'
                            />
                        </Flexbox>

                        <ul className='fonts_sam'>
                            {filteredSamFonts &&
                                filteredSamFonts.map((asset, idx) => {
                                    const { family, style, weight } =
                                        asset && asset.digitalAsset && asset.digitalAsset.font;

                                    return (
                                        <StyledFontListItem
                                            key={family + style + weight + idx}
                                            active={
                                                family === activeFont.family &&
                                                weight === activeFont.weight &&
                                                style === activeFont.style
                                            }
                                            onClick={() =>
                                                enableDeselect ||
                                                (!enableDeselect &&
                                                    (asset.digitalAsset.font.family !==
                                                        activeFont.family ||
                                                        asset.digitalAsset.font.style !==
                                                            activeFont.style ||
                                                        asset.digitalAsset.font.weight !==
                                                            activeFont.weight))
                                                    ? onSamFontSelected(asset.digitalAsset.font)
                                                    : undefined
                                            }>
                                            <StyledFontText
                                                fontFamily={`"${asset.digitalAsset.font.family}"`}
                                                fontWeight={asset.digitalAsset.font.weight}
                                                fontStyle={asset.digitalAsset.font.style}>
                                                {asset.digitalAsset.font.family}
                                            </StyledFontText>

                                            {enableDeselect &&
                                                asset.digitalAsset.font.family ===
                                                    activeFont.family &&
                                                asset.digitalAsset.font.weight ===
                                                    activeFont.weight &&
                                                asset.digitalAsset.font.style ===
                                                    activeFont.style && (
                                                    <Icon value='icon-undo' size='ml' />
                                                )}
                                        </StyledFontListItem>
                                    );
                                })}
                        </ul>

                        {filteredBuiltInFonts.length > 0 && filteredSamFonts.length > 0 && (
                            <div className='fonts_divider'></div>
                        )}

                        <ul className='fonts_built_in'>
                            {filteredBuiltInFonts.map(option => (
                                <StyledFontListItem
                                    key={option.family + option.style + option.weight}
                                    active={option.family === activeFont.family}
                                    onClick={() =>
                                        onBuiltinFontSelected({ family: option.family })
                                    }>
                                    <StyledFontText
                                        fontFamily={this.getFontFamily(option)}
                                        fontWeight={option.weight}>
                                        {option.family}
                                    </StyledFontText>
                                    {enableDeselect && option.family === activeFont.family && (
                                        <Icon value='icon-undo' size='ml' />
                                    )}
                                </StyledFontListItem>
                            ))}
                        </ul>
                    </Flexbox>
                )}
            </div>
        );
    }

    private static getGoogleSafeFont(font: WidgetFont): { weight: any; family: string } {
        if (isGoogleFont({ family: font.family })) {
            return getResolvedFamilyWeight({ family: font.family, weight: font.weight });
        }
        return { family: font.family, weight: font.weight };
    }

    private getFontFamily(option) {
        if (option.useFamily) {
            if (isGoogleFont({ family: option.family })) {
                return getResolvedFamilyWeight({
                    family: option.family,
                    weight: undefined,
                }).family;
            }
            return option.family;
        }
        return 'inherit';
    }

    private getNode = node => {
        if (!node) {
            return;
        }
        this.node = node;
    };

    private handleClickOutside = event => {
        // check to see if the click happened outside the color palette
        if (this.node && !this.node.contains(event.target) && this.controlsOpen) {
            this.toggleControls();
        }
    };

    private checkFamily(activeFont: WidgetFont): boolean {
        const isBuiltIn = this.props.builtinFonts.some(
            builtinFont => activeFont.family === builtinFont.family
        );
        if (isBuiltIn && activeFont.useFamily) {
            return false;
        } else {
            return true;
        }
    }
}

export default observer(TypographyFontControl);
