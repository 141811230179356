import { IAxis, ITicks } from 'components/_charts/Primitives/XAxis';
import { observable } from 'mobx';
import { Points, PointValue } from 'components/_charts/ComboChart/types';
import { DecimalFormat } from 'utils/Number/NumberUtils';
import { LegendAlignment, LegendOptions } from 'components/_charts/Primitives/Legend';
import { WidgetOptions } from '@sprinklr/stories/widget/WidgetOptions';
import { WidgetOptionsImpl } from 'models/Widget/WidgetOptions';
import { SmartSize } from '@sprinklr/stories/widget/types';
import { LineChartSeries } from 'components/_charts/LineChart/types';
import { ComboLayer } from '@sprinklr/stories/widget/WidgetOptions';
import { FontCase, Theme } from 'models/Theme/Theme';
import { ComboLineOptions } from 'components/_charts/ComboChart/Primitives/ComboLine/ComboLine';
import { Curves } from 'components/_charts/ComboChart/Primitives/ComboCurves/ComboCurves';
import merge from 'deepmerge';
import { ComboColumnOptions } from 'components/_charts/ComboChart/Primitives/ComboColumn/ComboColumn';
import { ComboScatterPlotOptions } from 'components/_charts/ComboChart/Primitives/ComboScatterPlot/ComboScatterPlot';
import { Grid } from './types';

export class AxisImpl implements IAxis {
    @observable enabled = true;
    @observable label = {
        enabled: true,
        size: 40,
        padding: 50,
    };
    @observable ticks: ITicks = {
        enabled: true,
        label: {
            enabled: true,
            size: 30,
            numberFormat: '1.2k',
            numberCase: 'lowercase',
            timeFormat: 'ddd ha',
            angle: 0,
        },
        line: {
            enabled: true,
        },
        numTicks: 10,
    };
    @observable padding = {
        start: 0,
    };
}

export class PointValueImpl implements PointValue {
    @observable enabled = true;
    @observable size = 20;
    @observable color = '';
    @observable format: DecimalFormat = '1.2k';
    @observable padding = 25;
    @observable case: FontCase = 'lowercase';
}

export class PointsImpl implements Points {
    @observable marker = {
        enabled: true,
        size: 10,
        color: '',
        stroke: {
            enabled: false,
            size: 2,
            color: '#fff',
        },
    };
    @observable value = new PointValueImpl();
}

export class LegendImpl implements LegendOptions {
    @observable enabled = true;
    @observable alignment: LegendAlignment = 'left';
    @observable size = 16;
    @observable points = {
        enabled: true,
        size: 50,
        stroke: {
            enabled: true,
            size: 3,
        },
    };
}

export class PointsLightImpl implements Points {
    @observable value = new PointValueImpl();
}

export interface ComboChartWidgetOptions extends WidgetOptions {
    data?: LineChartSeries[];
    comboLayers?: ComboLayer[];
    legend: LegendOptions;
    xAxis: IAxis;
    yAxis: IAxis;
    grid: Grid;
    mergedTheme?: Theme;
    blendMode?: 'string';
    sharedScale?: boolean;
}

export class ComboLineOptionsImpl implements ComboLineOptions {
    @observable curve: Curves = 'curveLinear';
    @observable stroke = {
        color: '',
        size: 3,
    };
    @observable points = new PointsImpl();
    @observable yAxisZeroStart = true;
}

export class ComboAreaOptions {
    points: Points;
    color: string;
    curve: Curves;
    opacity: number;
}

export class ComboAreaOptionsImpl implements ComboAreaOptions {
    @observable points = merge(new PointsImpl(), { marker: { enabled: false } });
    @observable color = '';
    @observable curve: Curves = 'curveLinear';
    @observable yAxisZeroStart = true;
    @observable opacity = 100;
}
export class ComboColumnOptionsImpl implements ComboColumnOptions {
    @observable color = '';
    @observable points = new PointsLightImpl();
    @observable yAxisZeroStart = true;
}

export class ComboScatterPlotOptionsImpl implements ComboScatterPlotOptions {
    @observable stroke = {
        color: '',
        size: 3,
    };
    @observable points = new PointsImpl();
    @observable value = new PointValueImpl();
    @observable yAxisZeroStart = true;
}

export class ComboYAxisImpl extends AxisImpl implements IAxis {
    @observable padding = {
        start: 5,
    };
}

export class ComboChartWidgetOptionsImpl extends WidgetOptionsImpl
    implements ComboChartWidgetOptions {
    @observable legend = new LegendImpl();
    @observable xAxis: IAxis = new AxisImpl();
    @observable yAxis: IAxis = new ComboYAxisImpl();
    @observable padding = 50;
    @observable dateFormat = 'dddd';
    @observable sharedScale = true;
    @observable grid: Grid = {
        enabled: true,
    };
    @observable smartSize: SmartSize = {
        enabled: false,
        mode: 'responsive',
        size: 'xl',
        baseline: {
            padding: 50,
        },
        optionDefaults: {},
        breakpoints: {
            xs: {
                options: {},
            },
            s: {
                options: {},
            },
        },
    };
}

class DualYAxisImpl extends AxisImpl implements IAxis {
    @observable padding = {
        start: 1.5,
    };
}

export class DualAxisWidgetOptionsImpl extends WidgetOptionsImpl
    implements ComboChartWidgetOptions {
    @observable legend = new LegendImpl();
    @observable xAxis: IAxis = new AxisImpl();
    @observable yAxis: IAxis = new DualYAxisImpl();
    @observable padding = 50;
    @observable dateFormat = 'dddd';
    @observable sharedScale = false;
    @observable grid: Grid = {
        enabled: true,
    };
    @observable smartSize: SmartSize = {
        enabled: false,
        mode: 'responsive',
        size: 'xl',
        baseline: {
            padding: 50,
        },
        optionDefaults: {},
        breakpoints: {
            xs: {
                options: {},
            },
            s: {
                options: {},
            },
        },
    };
}
