import { Widget } from '@sprinklr/stories/widget/Widget';
import { WIDGET_LABEL_SIZE } from '../../components/Widget/WidgetLabel/constants';
import { generateID } from './PanelTemplatesTheme/helpers';
import { DefaultPosition } from './PanelTemplatesTheme/constants';
import { TEXT_SHADOW_DEFAULT } from 'models/Widget/Widget';

export const WidgetCustomTableRichText: Widget = {
    type: 'customTableRichText',
    id: '1',
    name: 'Rich Text',
    position: DefaultPosition,
    label: {
        titleText: 'Rich Text',
        enabled: false,
        size: WIDGET_LABEL_SIZE.primary,
        textShadow: TEXT_SHADOW_DEFAULT,
    },
    options: {
        player: {
            playing: false,
            duration: 0,
        },
        contentPadding: 0,
        contentRichText: {
            entityMap: {},
            blocks: [
                {
                    key: 'key_' + generateID(),
                    text: '',
                    type: 'unstyled',
                    depth: 0,
                    inlineStyleRanges: [
                        {
                            offset: 0,
                            length: 1,
                            style: 'CUSTOM_FONT_SIZE_20' as any,
                        },
                    ],
                    entityRanges: [],
                    data: {},
                },
            ],
        },
    },
};
