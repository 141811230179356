import * as React from 'react';
import i18n from 'i18next';
import styled from 'components/_UI/designSystem';
import { SocialMediaAssetService } from '../../services/SocialMediaAssetService/SocialMediaAssetService';
import { UserService } from '../../services/UserService/UserService';
import AuthService from '../../services/Auth/AuthService';
import { inject, observer } from 'mobx-react';
import { action, observable } from 'mobx';
import {
    MediaAssetType,
    SAMSearch,
    SAMSearchImpl,
    SearchRequestSortImpl,
    SocialAssetFacetAndFilterImpl,
} from '../../services/SocialMediaAssetService/SAMSearch';
import { SocialMediaAsset } from '../../models/SocialMediaAsset/SocialMediaAsset';
import { User } from '../../models/User/User';
import SAMPreview from './SAMPreview';
import {
    Flexbox,
    Spinner,
    Search,
    IconButton,
    Select,
    Text,
    LabeledCheckbox,
} from 'components/spaceKit';

const debounce: any = require('debounce');

const FiltersList = styled(Flexbox)`
    overflow-y: auto;
    max-height: 136px;
`;
const SAMPickerResults = styled.ul`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
`;
const StyledSelectMedium = styled(Flexbox)`
    max-width: 250px;
`;
const StyledSelectSmall = styled(Flexbox)`
    max-width: 100px;
`;

export interface SAMSearchComponentProps {
    socialMediaAssetService?: SocialMediaAssetService;
    userService?: UserService;
    authService?: AuthService;
    assetTypes?: MediaAssetType[];
    prepopulate?: boolean;
    enableFilters?: boolean;
    onSamAssetSelected?(asset: SocialMediaAsset): void;
}

class SAMSearchComponent extends React.Component<SAMSearchComponentProps, any> {
    @observable assets: SocialMediaAsset[] = undefined;
    @observable samSearch: SAMSearch = new SAMSearchImpl();
    @observable authors: User[] = [];
    @observable selectedAuthors: any[] = [];
    @observable selectedAssetTypes: MediaAssetType[];
    @observable showFilters = false;
    @observable loading = true;

    private debounceSearch = debounce(() => {
        this.runSearch();
    }, 500);

    // tslint:disable-next-line:member-ordering
    public static defaultProps: SAMSearchComponentProps = {
        enableFilters: true,
    };

    componentDidMount() {
        this.init();
        this.loadUsers().then(() => {
            if (this.props.prepopulate) {
                this.debounceSearch();
            }
        });
    }

    render() {
        const assetTypes = this.props.assetTypes || SocialAssetFacetAndFilterImpl.assetTypes;
        const clientId = this.props.authService.getCurrentClient().clientId;

        if (!this.assets) {
            return <Spinner />;
        } else if (this.assets) {
            return (
                <Flexbox vertical gap>
                    <Flexbox vertical noGrow gap='m'>
                        <Flexbox right middle gap='m'>
                            <Flexbox gap='s' noGrow>
                                <Search
                                    onChange={this.keywordSearchChange.bind(this)}
                                    expandable
                                    placeholder={i18n.t('Search')}
                                />

                                {this.props.enableFilters && (
                                    <IconButton
                                        icon='filter'
                                        toolTip={i18n.t('Filters')}
                                        onClick={this.toggleFilters.bind(this)}
                                    />
                                )}
                            </Flexbox>
                            <StyledSelectMedium>
                                <Select
                                    onChange={(option: any) => {
                                        this.sortKeyChange(option.value);
                                    }}
                                    value={this.samSearch.sortList[0].key as any}
                                    isClearable={false}
                                    options={Object.keys(SearchRequestSortImpl.keys).map(key => ({
                                        value: key,
                                        label: SearchRequestSortImpl.keys[key],
                                    }))}
                                />
                            </StyledSelectMedium>
                            <StyledSelectSmall>
                                <Select
                                    onChange={(option: any) => {
                                        this.sortOrderChange(option.value);
                                    }}
                                    value={this.samSearch.sortList[0].order as any}
                                    isClearable={false}
                                    options={[
                                        { value: 'ASC', label: 'ASC' },
                                        { value: 'DESC', label: 'DESC' },
                                    ]}
                                />
                            </StyledSelectSmall>
                        </Flexbox>

                        <Flexbox gap>
                            {this.showFilters && (
                                <Flexbox>
                                    <Flexbox vertical gap>
                                        <Text.TextSemiBold12>
                                            {i18n.t('File Types')}
                                        </Text.TextSemiBold12>
                                        <FiltersList vertical gap='m'>
                                            {assetTypes.map((assetType, index) => (
                                                <LabeledCheckbox
                                                    key={index}
                                                    label={assetType}
                                                    checked={
                                                        this.selectedAssetTypes.indexOf(
                                                            assetType
                                                        ) !== -1
                                                    }
                                                    onChange={this.assetTypeChange.bind(
                                                        this,
                                                        assetType
                                                    )}
                                                />
                                            ))}
                                        </FiltersList>
                                    </Flexbox>
                                    <Flexbox vertical gap>
                                        <Text.TextSemiBold12>
                                            {i18n.t('Authors')}
                                        </Text.TextSemiBold12>
                                        <FiltersList vertical gap='m'>
                                            <LabeledCheckbox
                                                key='all-authors'
                                                label={i18n.t('All')}
                                                checked={
                                                    this.selectedAuthors.length ===
                                                    this.authors.length
                                                }
                                                onChange={this.onSelectAllAuthors}
                                            />
                                            {this.authors
                                                .sort((a, b) =>
                                                    a.visibleId > b.visibleId ? 1 : -1
                                                )
                                                .map((author, index) => (
                                                    <LabeledCheckbox
                                                        key={index}
                                                        label={author.visibleId}
                                                        checked={
                                                            this.selectedAuthors.indexOf(author) !==
                                                            -1
                                                        }
                                                        onChange={this.onSelectedAuthorChange.bind(
                                                            this,
                                                            author
                                                        )}
                                                    />
                                                ))}
                                        </FiltersList>
                                    </Flexbox>
                                </Flexbox>
                            )}
                        </Flexbox>
                    </Flexbox>

                    {this.assets && (
                        <SAMPickerResults>
                            {this.assets.map(asset => (
                                <li key={asset.id}>
                                    <SAMPreview
                                        key={asset.id}
                                        asset={asset}
                                        onAssetClicked={(assetId: string) =>
                                            this.assetSelected(assetId)
                                        }
                                    />
                                </li>
                            ))}
                        </SAMPickerResults>
                    )}

                    {this.assets && !this.assets.length && (
                        <Flexbox center middle>
                            {this.loading && <Spinner />}
                            {!this.loading && (
                                <Text.TextLight20>{i18n.t('No Matching Assets')}</Text.TextLight20>
                            )}
                        </Flexbox>
                    )}
                </Flexbox>
            );
        }
    }

    @action
    private init() {
        if (this.props.assetTypes) {
            this.setSelectedAssetTypes(this.props.assetTypes);
        }

        this.samSearch.withFacets = true;
        // this.samSearch.filters.clientIds = [this.props.authService.getCurrentClient().clientId];
    }

    @action
    private runSearch() {
        const clientId = this.props.authService.getCurrentClient().clientId;

        this.loading = true;
        this.assets = [];

        this.props.socialMediaAssetService
            .search(this.samSearch)
            .then(
                action('load-assets', (results: Array<SocialMediaAsset>) => {
                    // console.log("Found assets: ", results);
                    this.assets = results.filter(asset => {
                        //Check if owned by the client
                        if (asset.ownerClientId === clientId) {
                            return true;
                        }

                        //Check if globally shared, or shared with the selected client.
                        return (
                            asset.shareConfigs &&
                            asset.shareConfigs.some(shareConfig => {
                                return (
                                    shareConfig.shareLevel === 'GLOBAL' ||
                                    (shareConfig.shareLevel === 'CLIENT' &&
                                        shareConfig.sharedWithIds.indexOf(clientId) != -1)
                                );
                            })
                        );
                    });

                    this.loading = false;
                })
            )
            .catch(error => {
                // console.error("asset load error", error);
            });
    }

    @action
    private loadUsers(): Promise<any> {
        return this.props.userService.partnerUsers().then(
            action('load-authors', (users: Array<User>) => {
                // console.log('users', users);
                this.samSearch.filters.authorIds = [];
                this.selectedAuthors = users;
                this.authors = users;
            })
        );

        /*
        this.props.authService.getSessionContext().then(action('set-default-author', (sessCtx: SessionContext) => {
            // We may want to use the session author ID to sort the user list or visually identify it in some way
            // but we no longer automatically filter the assets by the session id
            // tslint:disable-next-line:no-unused-variable
            const authorIdString = `${sessCtx.user.userId}`;
        }));
        */
    }

    @action
    private keywordSearchChange(value: any) {
        // console.log('evt', evt);

        if (value === null) {
            this.samSearch.keywordSearch = '';
        } else {
            this.samSearch.keywordSearch = value;
        }

        this.debounceSearch();
    }

    @action
    private sortKeyChange(value: any) {
        this.samSearch.sortList[0].key = value;
        this.debounceSearch();
    }

    @action
    private sortOrderChange(value: any) {
        this.samSearch.sortList[0].order = value;
        this.debounceSearch();
    }

    @action
    private onSelectedAuthorChange(value: number) {
        const index = this.selectedAuthors.indexOf(value);

        if (index === -1) {
            this.selectedAuthors.push(value);
        } else {
            this.selectedAuthors.splice(index, 1);
        }

        this.samSearch.filters.authorIds =
            this.authors.length === this.selectedAuthors.length
                ? []
                : this.selectedAuthors.map(author => author.id);

        this.debounceSearch();
    }

    @action
    onSelectAllAuthors = () => {
        if (this.authors.length === this.selectedAuthors.length) {
            this.selectedAuthors = [];
        } else {
            this.selectedAuthors = [...this.authors];
        }
        this.debounceSearch();
    };

    @action
    private assetTypeChange(assetType: any) {
        const index = this.selectedAssetTypes.indexOf(assetType);

        if (index === -1) {
            this.selectedAssetTypes.push(assetType);
        } else {
            this.selectedAssetTypes.splice(index, 1);
        }

        this.samSearch.filters.assetTypes = this.selectedAssetTypes;
        this.debounceSearch();
    }

    @action
    private setSelectedAssetTypes(value: MediaAssetType[]) {
        this.samSearch.filters.assetTypes = value;
        this.selectedAssetTypes = value;

        if (this.selectedAssetTypes === null) {
            this.samSearch.filters.assetTypes = [];
        } else {
            this.samSearch.filters.assetTypes = value;
        }
    }

    @action
    private toggleFilters() {
        this.showFilters = !this.showFilters;
    }

    @action
    private assetSelected(assetId: string): void {
        // console.log("Registered a click on assetId " + assetId);
        const asset = this.assets.filter(asset => assetId === asset.id).shift();
        // console.log("Registered a click on asset ", asset);
        if (this.props.onSamAssetSelected) {
            this.props.onSamAssetSelected(asset);
        }
    }
}

export default inject(
    'socialMediaAssetService',
    'userService',
    'authService'
)(observer(SAMSearchComponent));
