import { DefaultPosition } from '../PanelTemplatesTheme/constants';

import { Panel } from 'models/Panel/Panel';
import { WidgetDataTableSummary } from './WidgetDataTableSummary';

export const PanelDataTableSummary: Panel = {
    name: 'Summary Table',
    id: 'data-table-summary',
    description: '',
    tags: ['Standard', 'Data Table', 'landscape', 'portrait'],
    widget: {
        id: '1',
        position: {
            width: '1920px',
            height: '1080px',
            top: '0',
            left: '0',
        },
        classes: 'template_data_table_summary',
        children: [
            {
                ...WidgetDataTableSummary,
                position: DefaultPosition,
                options: {
                    ...WidgetDataTableSummary.options,
                    smartSize: {
                        enabled: true,
                        mode: 'responsive',
                        size: 'xl',
                    },
                },
            },
        ],
    },
};
