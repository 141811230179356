import Axios, { AxiosInstance, CancelTokenSource } from 'axios';
import { Post, PostProfile } from '@sprinklr/stories/post/Post';
import { PostsRequest } from '@sprinklr/stories/post/PostsRequest';
import TimePeriod from 'models/TimePeriod/TimePeriod';
import { WidgetDimensionKey } from 'utils/Widget/WidgetDimensionKey';
import {
    default as SprinklrAPIService,
    PromiseWithCancel,
} from 'services/SprinklrAPIService/SprinklrAPIService';
import { PostsProvider } from './PostsProvider';
import merge from 'lodash/merge';

export default class PostsProviderMonitoringColumn implements PostsProvider {
    constructor(
        private readonly sprinklrAPIService: SprinklrAPIService,
        private readonly axios: AxiosInstance
    ) {}

    request(request: PostsRequest, cancelSource?: CancelTokenSource): PromiseWithCancel<any[]> {
        return this.requestPosts(request, cancelSource).then((posts: any[]) => {
            // The "rows" argument is not working with the /feed API endpoint correctly
            // For example, you request "rows=5" and you get 35 posts back!
            // This enforces the limit value.
            if (request.pageSize && posts.length > request.pageSize) {
                posts.length = request.pageSize;
            }

            const accountIds: any[] = posts
                .filter(post => {
                    return post.accountId && !post.senderProfile;
                })
                .map(post => {
                    return post.accountId;
                });

            return this.sprinklrAPIService
                .bulkLookup(
                    'PLATFORM',
                    request.report,
                    new WidgetDimensionKey('ACCOUNT_ID'),
                    accountIds,
                    null,
                    cancelSource
                )
                .then((accountsMap: { [accountId: string]: any }) => {
                    posts.forEach((post: any) => {
                        // post.searchDetails are not always present.
                        if (
                            post.senderProfile ||
                            !post.accountId ||
                            !(post.accountId in accountsMap)
                        ) {
                            return;
                        }

                        const account = accountsMap[post.accountId];

                        post.senderProfile = {
                            id: account.id,
                            snType: account.snType,
                            location: account.location,
                            snId: account.id,
                            name: account.displayName,
                            screenName: account.screenName,
                            bio: account.bio,
                            following: account.followingCount,
                            followers: account.followerCount,
                            statusCount: account.statusCount,
                            permalink: account.permalink,
                            profileUrl: account.accountUrl,
                            profileImgUrl: account.profileImgUrl,
                            createdTime: account.createdTime,
                            modifiedTime: account.modifiedTime,
                            clientId: account.clientId,
                            accountId: account.accountId,
                            delFlag: account.isDeleted,
                            accountsFollowedByUser: account.accountFollowedByUser,
                            accountsFollowingUser: account.accountFollowingUser,
                        } as PostProfile;
                    });

                    return posts;
                });
        });
    }

    private requestPosts(
        request: PostsRequest,
        cancelSource?: CancelTokenSource
    ): PromiseWithCancel<Post[]> {
        if (!cancelSource) {
            cancelSource = Axios.CancelToken.source();
        }

        let promise;

        switch (request.columnType) {
            case 'SUGGESTION':
                const suggestionRequest = this.buildSuggestionColumnRequest(request);
                suggestionRequest.cancelToken = cancelSource.token;

                promise = this.axios.request(suggestionRequest).then((response: any) => {
                    if (!response.data) {
                        return Promise.reject(response);
                    }

                    return response.data
                        .map((row: any) => {
                            const suggestion: any = row.suggestion || {};
                            const asset: any =
                                row.suggestionObjectMap[suggestion.suggestionAssets[0].assetId];

                            if (asset.assetObject && asset.assetObject.postAsset) {
                                const postAsset = asset.assetObject.postAsset;

                                let images = [];
                                let videos = [];

                                postAsset.postAssetChannelContent.forEach(channelContent => {
                                    channelContent.postSAMAssets.forEach(samAsset => {
                                        if (samAsset.mediaAssetType === 'PHOTO') {
                                            images = [samAsset];
                                        } else {
                                            if (!samAsset.url) {
                                                samAsset.url = samAsset.mediaUrl;
                                            }
                                            videos = [samAsset];
                                        }
                                    });
                                });

                                return {
                                    accountId: postAsset.postAccountId,
                                    assetChannelContent: postAsset.postAssetChannelContent,
                                    permalink: postAsset.postPermalink,
                                    publishedTime: postAsset.postPublishedTime,
                                    type: postAsset.postType,
                                    snMsgId: postAsset.snMsgId,
                                    message: asset.assetObject.description,
                                    images: images,
                                    videos: this.fixVideos(videos),
                                };
                            }

                            if (asset.assetObject && asset.assetObject.digitalAsset) {
                                const digitalAsset = asset.assetObject.digitalAsset;

                                let images = [];
                                let videos = [];

                                if (digitalAsset.mediaMimeType.indexOf('video') != -1) {
                                    if (!digitalAsset.url) {
                                        digitalAsset.url = digitalAsset.mediaUrl;
                                    }
                                    videos = [digitalAsset];
                                } else {
                                    images = [digitalAsset];
                                }

                                return {
                                    accountId: digitalAsset.postAccountId,
                                    permalink: digitalAsset.mediaUrl,
                                    publishedTime: asset.assetObject.createdTime,
                                    type: 'SPRINKLR',
                                    snMsgId: '__not_a_real_snMsgId__' + asset.assetId,
                                    message: asset.assetObject.description,
                                    images: images,
                                    videos: this.fixVideos(videos),
                                };
                            }
                        })
                        .filter(post => post);
                });
                break;

            case 'UGC':
                const ucgRequest = this.buildUGCColumnRequest(request);
                ucgRequest.cancelToken = cancelSource.token;

                promise = this.axios.request(ucgRequest).then((response: any) => {
                    if (!response.data || !response.data.ugcAssets) {
                        return Promise.reject(response);
                    }

                    return response.data.ugcAssets.map((asset: any) => {
                        const message: any = asset.inboundMessage || {};
                        const ugcAsset: any = asset.ugcAsset;
                        if (ugcAsset) {
                            const assetType: string = ugcAsset.assetType;
                            const stripMessage: boolean =
                                'PHOTO' === assetType || 'VIDEO' === assetType;
                            let textOnly: boolean = 'TEXT' === assetType;

                            if (!textOnly && ugcAsset.tags && ugcAsset.tags.length !== 0) {
                                ugcAsset.tags.forEach(tag => {
                                    if (!tag) {
                                        return;
                                    }
                                    if (
                                        tag.toLowerCase() === 'text_only' ||
                                        tag.toLowerCase() === 'text only' ||
                                        tag.toLowerCase() === 'text-only'
                                    ) {
                                        textOnly = true;
                                    }
                                });
                            }

                            if (textOnly) {
                                message.mediaList = [];
                            }
                            if (stripMessage) {
                                message.message = null;
                            }

                            // Replace the inbound message images and videos, which can expire,
                            // with the urls from with ugcAsset that live on the SAM
                            if (ugcAsset.socialAsset) {
                                switch (ugcAsset.assetType) {
                                    case 'PHOTO':
                                        if (ugcAsset.socialAsset.mediaUrl) {
                                            message.images = [
                                                {
                                                    url: ugcAsset.socialAsset.mediaUrl,
                                                    previewImageUrl:
                                                        ugcAsset.socialAsset.previewUrl,
                                                },
                                            ];
                                        }
                                        break;

                                    case 'VIDEO':
                                        if (ugcAsset.socialAsset.mediaUrl) {
                                            message.videos = [
                                                {
                                                    url: ugcAsset.socialAsset.mediaUrl,
                                                    previewImageUrl:
                                                        ugcAsset.socialAsset.previewUrl,
                                                },
                                            ];
                                        }
                                        break;

                                    case 'POST':
                                        if (ugcAsset.socialAsset.postCommonSAMIds?.length) {
                                            message.images = [];
                                            message.videos = [];

                                            ugcAsset.socialAsset.postCommonSAMIds.forEach(sam => {
                                                switch (sam.mediaAssetType) {
                                                    case 'PHOTO':
                                                        message.images.push({
                                                            url: sam.mediaUrl,
                                                            previewImageUrl: sam.previewUrl,
                                                        });
                                                        break;

                                                    case 'VIDEO':
                                                        message.videos.push({
                                                            url: sam.mediaUrl,
                                                            previewImageUrl: sam.previewUrl,
                                                        });
                                                        break;
                                                }
                                            });
                                        }
                                        break;
                                }
                            }
                        }

                        message.workflowProperties = message.workflowProperties || {};
                        if (!message.workflowProperties.partnerCustomProperties) {
                            message.workflowProperties.partnerCustomProperties = {};
                        }

                        if (asset.clientCustomProperties) {
                            message.workflowProperties.clientCustomProperties =
                                asset.clientCustomProperties;
                        } else if (asset.partnerCustomProperties) {
                            message.workflowProperties.partnerCustomProperties =
                                asset.partnerCustomProperties;
                        }

                        if (asset.ugcAsset?.customFields) {
                            message.workflowProperties.partnerCustomProperties = merge(
                                message.workflowProperties.partnerCustomProperties,
                                asset.ugcAsset.customFields
                            );
                        }

                        if (asset.ugcAsset?.customProperties?.mappedCustomProperties) {
                            message.workflowProperties.partnerCustomProperties = merge(
                                message.workflowProperties.partnerCustomProperties,
                                asset.ugcAsset.customProperties.mappedCustomProperties
                            );
                        }

                        return message;
                    });
                });
                break;

            default:
                const columnRequest = this.buildMonitoringColumnRequest(request);
                columnRequest.cancelToken = cancelSource.token;

                promise = this.axios.request(columnRequest).then((response: any) => {
                    if (!response.data) {
                        return Promise.reject(response);
                    }

                    if (request.columnType === 'OUTBOUND') {
                        return response.data.entities;
                    }

                    return response.data;
                });
                break;
        }

        promise.cancel = (message?: string) => {
            cancelSource.cancel(message);
        };

        return promise;
    }

    private buildMonitoringColumnRequest(request: PostsRequest): any {
        const page: number = request.page || 1;

        const limit: number = request.pageSize || 20;
        const start: number = Math.max(0, page - 1) * limit;

        let url = '';
        let method = 'GET';
        if (request.columnType === 'OUTBOUND') {
            url = `/api/v3.1/stream/outbound/${request.columnId}/feed?start=${start}&rows=${limit}`;
            method = 'POST';
        } else {
            url = `/api/v3.1/stream/${request.columnId}/feed?start=${start}&rows=${limit}`;
        }

        if (request.timePeriod) {
            const timePeriod: TimePeriod = this.sprinklrAPIService.getTimePeriod(
                request.timePeriod
            );
            const startTime = timePeriod.startDate;
            const endTime = timePeriod.endDate;

            if (startTime) {
                url += `&sinceDate=${startTime.valueOf()}`;
            }

            if (endTime) {
                url += `&untilDate=${endTime.valueOf()}`;
            }
        }

        return {
            method: method,
            url,
        };
    }

    private buildUGCColumnRequest(request: PostsRequest): any {
        const page: number = request.page || 1;

        const limit: number = request.pageSize || 20;
        const start: number = Math.max(0, page - 1) * limit;

        const url = `/api/v3.1/ugc/stream/${request.columnId}/feed`;
        const data: any = {
            start,
            rows: limit,
        };

        // if (request.timePeriod) {
        //     let timePeriod = this.sprinklrAPIService.getTimePeriod(request.timePeriod);
        //     if (request.timePeriod.startTime) {
        //         data.sinceDate = request.timePeriod.startTime;
        //     }

        //     if (request.timePeriod.endTime) {
        //         data.untilDate = request.timePeriod.endTime;
        //     }
        // }

        return {
            method: 'POST',
            url,
            data,
        };
    }

    private buildSuggestionColumnRequest(request: PostsRequest): any {
        const page: number = request.page || 1;
        const limit: number = request.pageSize || 20;
        const start: number = Math.max(0, page - 1) * limit;

        const url = `/api/v3.1/suggestion/stream/${request.columnId}/feed?start=${start}&rows=${limit}`;

        return {
            method: 'GET',
            url,
        };
    }

    private fixVideos(videos: any): any[] {
        videos.forEach(video => {
            video.poster = video.previewUrl;
        });

        return videos;
    }
}
