import { WidgetType } from 'models/Widget/WidgetType';

export class WidgetTypeWrapper {
    id: string;
    type: WidgetType;

    constructor(type: WidgetType) {
        this.type = type;
        this.id = type.id;
    }

    getGroupByLabel(offset: number): string {
        if (this.type.groupByLabels) {
            return this.type.groupByLabels[offset];
        } else {
            return null;
        }
    }

    getMetricLabel(offset: number): string {
        if (this.type.metricLabels) {
            return this.type.metricLabels[offset];
        } else {
            return null;
        }
    }
}
