import * as React from 'react';
import styled from 'components/_UI/designSystem';
import { action } from 'mobx';
import { observer } from 'mobx-react';
import {
    presetImageFilterOptions,
    presetValues,
} from 'components/Panel/PanelEditorSidebar/PanelDesign/PanelDesignOptions';
import { getPx, Row, Flexbox, Text, fullbleed } from 'components/spaceKit';
import Flower from '../../../../assets/flower.jpg';

type ActionImageFilterPresetsProps = {
    value: string;
    option: object;
};

const getFiltersStyles = option => {
    const current = presetValues[option.value];
    let filters = '';
    current.forEach(item => {
        if (item.optionName === 'imageFilterBlurValue') {
            filters += `blur( ${item.value}px)`;
        }
        if (item.optionName === 'imageFilterBrightnessValue') {
            filters += `brightness(${item.value}%)`;
        }
        if (item.optionName === 'imageFilterContrastValue') {
            filters += `contrast(${item.value}%)`;
        }
        if (item.optionName === 'imageFilterGrayscaleValue') {
            filters += `grayscale(${item.value}%)`;
        }
        if (item.optionName === 'imageFilterSaturateValue') {
            filters += `saturate(${item.value}%)`;
        }
        if (item.optionName === 'imageFilterHueRotateValue') {
            filters += `hue-rotate(${item.value}deg)`;
        }
        if (item.optionName === 'imageFilterOpacityValue') {
            filters += `opacity(${item.value}%)`;
        }
        if (item.optionName === 'imageFilterInvertValue') {
            filters += `invert(${item.value}%)`;
        }
        if (item.optionName === 'imageFilterSepiaValue') {
            filters += `sepia(${item.value}%)`;
        }
    });

    return {
        filter: filters,
        backgroundImage: `url(${Flower})`,
    };
};

const getOverlaysStyles = (option: { label: string; value: any }) => {
    const imageBlendColor = presetValues[option.value].find(
        type => type.optionName === 'imageBlendColor'
    ).value;
    const imageBlendMode = presetValues[option.value].find(
        type => type.optionName === 'imageBlendMode'
    ).value;
    return { backgroundColor: `${imageBlendColor}`, mixBlendMode: imageBlendMode };
};

const applyFilter = action((option, value, filter) => {
    presetValues[filter.value].map(x => {
        option[x.optionName] = x.value;
    });
    option[value] = filter.value;
});

const StyledActionImageFilterPresets = styled(Row)`
    width: 100%;
`;

const StyledImageFilter = styled.div`
    max-width: ${getPx(14)};
    width: ${getPx(14)};
    height: ${getPx(14)};
    border-radius: 50%;
    position: relative;
    cursor: pointer;
    transform: translate3d(0, 0, 0);
    transition: border 300ms;
    border: 2px solid transparent;
    overflow: hidden;
`;

const StyledImage = styled.div`
    background-size: cover;
    background-position: center center;
    ${fullbleed()};
`;

const StyledOverlay = styled.div`
    ${fullbleed()};
`;

const ActionImageFilterPresets: React.FC<ActionImageFilterPresetsProps> = ({ option, value }) => (
    <StyledActionImageFilterPresets maxColumns={5} gap='m'>
        {presetImageFilterOptions &&
            presetImageFilterOptions.map((preset, index) => (
                <Flexbox
                    center
                    middle
                    vertical
                    between
                    key={preset.value}
                    onClick={() => applyFilter(option, value, preset)}>
                    <StyledImageFilter>
                        <StyledImage style={getFiltersStyles(preset)} />
                        <StyledOverlay style={getOverlaysStyles(preset)} />
                    </StyledImageFilter>
                    <Text.TextReg11 className='image-filter_label'>{preset.label}</Text.TextReg11>
                </Flexbox>
            ))}
    </StyledActionImageFilterPresets>
);

StyledActionImageFilterPresets.displayName = 'ActionImageFilterPresets';
export default observer(ActionImageFilterPresets);
