import { Label, PostSource, Sort } from './PanelTemplatesTheme/PanelTemplatesTheme';
import { Panel } from 'models/Panel/Panel';
import { WIDGET_LABEL_SIZE } from 'components/Widget/WidgetLabel/constants';
import { postCompositionConfigGrid } from 'components/Panel/PanelEditorSidebar/PanelDesign/PanelDesignOptions';
import { PostsWidgetOptions } from '../../widgets/PostsWidget/options';

export var TemplatePostEngagement2by4: Panel = {
    id: 'post-engagement-2-by-4',
    name: 'Posts by Metrics',
    description: '',
    tags: ['Content', 'Posts', 'Post Engagement', '2 by 4', 'landscape', 'portrait'],
    widget: {
        position: {
            width: '1920px',
            height: '1080px',
            top: '0',
            left: '0',
        },
        classes: 'template_engagement template_engagement_multi template_engagement_multi_2_by_4',
        children: [
            {
                type: 'postsGrid',
                id: '1',
                name: 'engagement',
                useGlobalTimePeriod: true,
                postRequests: [
                    {
                        sources: [
                            {
                                type: 'posts',
                                // Note: All global variables like reportingEngine, report, and filters
                                // are injected via PostRequest.getRequests()
                                id: new PostSource({
                                    pageSize: 48,
                                    sorts: [
                                        new Sort({
                                            order: 'DESC',
                                            heading: {
                                                LISTENING: 'REACH_COUNT',
                                                INBOUND_MESSAGE: 'INBOUND_COUNT',
                                                PLATFORM: 'TOTAL_ENGAGEMENT',
                                                BENCHMARKING: 'TOTAL_ENGAGEMENT',
                                                CAMPAIGN: 'OUTBOUND_STATUS',
                                                RDB_FIREHOSE: 'SN_CREATED_TIME',
                                                STORY_MESSAGE: 'IMPACT',
                                            },
                                        }),
                                    ],
                                    includeFaceDetection: false,
                                    timePeriod: {
                                        key: 'last_7_days',
                                    },
                                }) as any,
                                options: {
                                    count: 300,
                                    imagePreload: true,
                                    removeDuplicates: true,
                                    includeFaceDetection: false,
                                    shufflePosts: false,
                                    removeSensitivePosts: true,
                                    includeQuoteTweets: false,
                                },
                            },
                        ],
                        format: {
                            type: 'buckets',
                            options: {
                                padding: 1,
                                buckets: {
                                    text: { filter: "post.type == 'text'" },
                                    image: { filter: "post.type == 'image'" },
                                    any: { filter: 'post.type' },
                                },
                                panels: [{ total: 4, buckets: ['any', -1] }],
                            },
                        },
                    },
                ],
                position: {
                    landscape: {
                        left: '2%',
                        top: '3.75%',
                        width: '46.75%',
                        height: '92.5%',
                    },
                    portrait: {
                        height: '47%',
                        width: '93%',
                        top: '2%',
                        left: '3.5%',
                    },
                },
                options: Object.assign(
                    {
                        postTemplate: '',
                        userGroupEffect: 'top',
                        theme: 'primary',
                        showSentiment: true,
                        showText: true,
                        player: {
                            playing: true,
                            duration: 15,
                        },
                        imageFaceDetection: false,
                        postVersion: 2,
                        smartSize: {
                            enabled: true,
                            mode: 'responsive',
                            size: 'xl',
                        },
                    } as PostsWidgetOptions,
                    postCompositionConfigGrid.a
                ),
                label: {
                    titleText: new Label({
                        LISTENING: 'Top Posts by Reach',
                        INBOUND_MESSAGE: 'Top Posts by Inbound Count',
                        PLATFORM: 'Top Posts by Total Engagements',
                        BENCHMARKING: 'Top Posts by Total Engagements',
                    }) as any,
                    size: WIDGET_LABEL_SIZE.secondary,
                    enabled: false,
                },
            },
            {
                type: 'postsGrid',
                id: '2',
                name: 'engagement',
                useGlobalTimePeriod: true,
                postRequests: [
                    {
                        sources: [
                            {
                                type: 'posts',
                                // Note: All global variables like reportingEngine, report, and filters
                                // are injected via PostRequest.getRequests()
                                id: new PostSource({
                                    pageSize: 50,
                                    sorts: [
                                        new Sort({
                                            order: 'DESC',
                                            heading: {
                                                LISTENING: 'INFLUENCER_SCORE',
                                                INBOUND_MESSAGE: 'OUTBOUND_COUNT',
                                                PLATFORM: 'POST_SHARE_COUNT',
                                                BENCHMARKING: 'POST_SHARE_COUNT',
                                                CAMPAIGN: 'OUTBOUND_STATUS',
                                                RDB_FIREHOSE: 'SN_CREATED_TIME',
                                                STORY_MESSAGE: 'IMPACT',
                                            },
                                        }),
                                    ],
                                    includeFaceDetection: false,
                                    timePeriod: {
                                        key: 'last_7_days',
                                    },
                                }) as any,
                                options: {
                                    count: 300,
                                    imagePreload: true,
                                    removeDuplicates: true,
                                    includeFaceDetection: false,
                                    shufflePosts: false,
                                    removeSensitivePosts: true,
                                },
                            },
                        ],
                        format: {
                            type: 'buckets',
                            options: {
                                padding: 1,
                                buckets: {
                                    text: { filter: "post.type == 'text'" },
                                    image: { filter: "post.type == 'image'" },
                                    any: { filter: 'post.type' },
                                },
                                panels: [{ total: 4, buckets: ['any', -1] }],
                            },
                        },
                    },
                ],

                // top: '3.72%',
                // left: '2.08%',
                // height: '2.56%',
                // width: '95.84'

                position: {
                    landscape: {
                        left: '51%',
                        top: '3.75%',
                        width: '46.75%',
                        height: '92.5%',
                    },
                    portrait: {
                        height: '47%',
                        width: '93%',
                        top: '51%',
                        left: '3.5%',
                    },
                },
                options: Object.assign(
                    {
                        player: {
                            playing: true,
                            duration: 15,
                        },
                        userGroupEffect: 'top',
                        theme: 'primary',
                        showSentiment: true,
                        showText: true,
                        imageFaceDetection: false,
                        postVersion: 2,
                        smartSize: {
                            enabled: true,
                            mode: 'responsive',
                            size: 'm',
                        },
                    } as PostsWidgetOptions,
                    postCompositionConfigGrid.a
                ),
                label: {
                    titleText: new Label({
                        LISTENING: 'Top Posts by Influencer',
                        INBOUND_MESSAGE: 'Top Posts by Outbound Count',
                        PLATFORM: 'Top Posts by Shares',
                        BENCHMARKING: 'Top Posts by Shares',
                    }) as any,
                    size: WIDGET_LABEL_SIZE.secondary,
                    enabled: false,
                },
            },
        ],
        theme: {
            ext: {
                padding: 0,
            },
        },
        // label: {
        //     titleText: 'Posts by Metrics',
        //     enabled: true,
        //     size: LabelSize.primary
        // }
    },
};
