import { Panel } from '../../models/Panel/Panel';
import { WidgetStoryCard } from './WidgetStoryCard';
import { DefaultPosition } from './PanelTemplatesTheme/constants';

export const TemplateStoryCard: Panel = {
    id: 'story-card',
    name: 'Story Card',
    description: '',
    tags: ['Content', 'Posts', 'Card', 'landscape', 'portrait'],
    widget: {
        id: '1',
        position: {
            width: '1920px',
            height: '1080px',
            top: '0',
            left: '0',
        },
        classes: 'template_story_card',
        children: [
            {
                ...WidgetStoryCard,
                position: DefaultPosition,
                options: {
                    ...WidgetStoryCard.options,
                    smartSize: {
                        enabled: true,
                        mode: 'responsive',
                        size: 'xl',
                    },
                },
            },
        ],
        theme: {
            ext: {
                padding: 0,
            },
        },
    },
};
