import * as React from 'react';
import { action } from 'mobx';
import { observer } from 'mobx-react';
import { LabeledSelect, ValueType } from 'components/spaceKit';

type ActionSelectGroupProps = {
    label?: string;
    error?: string;
    required?: boolean;
    value: string; // key on object to modify
    option: {}; // object to modify
    inheritClear?: boolean;
    isClearable?: boolean;
    options: Array<{ [key: string]: any }>;
    defaultValue?: ValueType;
    isSearchable?: boolean;
    isMulti?: boolean;
    isDisabled?: boolean;
    name?: string;
    isLoading?: boolean;
    placeholder?: string;
    valueKey?: string;
    labelKey?: string;
    clearValue?: { [key: string]: any };
};

const ActionSelectGroup: React.FC<ActionSelectGroupProps> = ({ option, value, ...group }) => {
    const currentValue = group.options.find(groupOption => groupOption.value === option[value]);
    return (
        <LabeledSelect
            {...group}
            value={currentValue}
            onChange={action((e: { name: string; value: string }) => (option[value] = e.value))}
        />
    );
};

ActionSelectGroup.displayName = 'ActionSelectGroup';
export default observer(ActionSelectGroup);
