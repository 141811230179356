import BulkItem from './BulkItem';

export default class SourceItem extends BulkItem {
    sourceType: string;

    constructor(id: string, name: string, sourceType: string) {
        super(id, name);

        this.sourceType = sourceType;
    }

    public static fromObject(object: any) {
        // SOURCE_ID
        const allProps = ['id', 'name', 'sourceType'].every(prop => object.hasOwnProperty(prop));

        if (allProps) {
            return new SourceItem(object.id, object.name, object.sourceType);
        }

        // REVIEW_SOURCE
        const allProps2 = ['id', 'name', 'assetClasses'].every(prop => object.hasOwnProperty(prop));

        if (allProps2) {
            return new SourceItem(object.id, object.name, '');
        }

        return null;
    }

    toString() {
        return this.name;
    }

    get sortValue() {
        return this.name;
    }
}
