import { PostSource, Sort } from './PanelTemplatesTheme/PanelTemplatesTheme';
import { DefaultWidgetPosition } from './PanelTemplatesTheme/constants';
import { Widget } from '@sprinklr/stories/widget/Widget';
import { WIDGET_LABEL_SIZE } from 'components/Widget/WidgetLabel/constants';
import { postCompositionConfigGrid } from 'components/Panel/PanelEditorSidebar/PanelDesign/PanelDesignOptions';
import { WidgetOptions } from '@sprinklr/stories/widget/WidgetOptions';
import { NativeStylingOptionsDefaultImpl } from 'src/widgets/PostsWidget/options';
import { TEXT_SHADOW_DEFAULT } from 'models/Widget/Widget';

export const WidgetGridV2: Widget = {
    type: 'postsGridV2',
    id: 'post-grid-v2',
    name: 'Grid',
    thumbSrc: 'template-post-engagement-6.jpg',
    useGlobalTimePeriod: true,
    postRequests: [
        {
            sources: [
                {
                    type: 'posts',
                    // Note: All global variables like reportingEngine, report, and filters
                    // are injected via PostRequest.getRequests()
                    id: new PostSource({
                        pageSize: 50,
                        sorts: [
                            new Sort({
                                order: 'DESC',
                                heading: {
                                    MONITORING_DASHBOARD: 'REACH_COUNT',
                                    LISTENING: 'REACH_COUNT',
                                    INBOUND_MESSAGE: 'INBOUND_COUNT',
                                    PLATFORM: 'TOTAL_ENGAGEMENT',
                                    BENCHMARKING: 'TOTAL_ENGAGEMENT',
                                    CAMPAIGN: 'OUTBOUND_STATUS',
                                    RDB_FIREHOSE: 'SN_CREATED_TIME',
                                    STORY_MESSAGE: 'IMPACT',
                                },
                            }),
                        ],
                        timePeriod: {
                            key: 'last_7_days',
                        },
                    }) as any,
                    options: {
                        count: 200,
                        imagePreload: true,
                        removeDuplicates: true,
                        shufflePosts: false,
                        removeSensitivePosts: true,
                        includeQuoteTweets: false,
                    },
                },
            ],
            format: {
                type: 'buckets',
                options: {
                    padding: 1,
                    buckets: {
                        any: { filter: 'post.type' },
                    },
                    panels: [{ total: 6, buckets: ['any', -1] }],
                },
            },
        },
    ],
    position: DefaultWidgetPosition,
    options: Object.assign(
        {
            player: {
                playing: true,
                duration: 15,
            },
            userGroupEffect: 'top',
            theme: 'primary',
            showSentiment: true,
            smartSize: {
                enabled: true,
                mode: 'responsive',
                size: 'm',
            },
            portrait: {
                widgetOrientation: 'portrait',
            },
            postVersion: 2,
            postTemplate: 'gridV2',
            gridLayout: {
                columns: 3,
                rows: 2,
                gap: 10,
            },
            nativeStyling: new NativeStylingOptionsDefaultImpl(),
        } as WidgetOptions,
        postCompositionConfigGrid.a
    ),
    label: {
        titleText: 'Grid',
        enabled: false,
        size: WIDGET_LABEL_SIZE.primary,
        textShadow: TEXT_SHADOW_DEFAULT,
    },
};
