/**
 * Created by dstelter on 11/30/16.
 */

import { GraphQLQuery } from '../GraphQLService/GraphQLService';
import { WidgetFragments } from '../../models/Widget/Widget';
import { ThemeFragment, ChildThemeFragment } from '../../models/Theme/Theme';
import { CommonDocFields } from '../../models/CommonDoc/CommonDoc';
import { VersionCapableFields } from '../../models/VersionCapable/VersionCapable';
import {
    StoryboardVersionFields,
    StoryboardVersionLightFieldsOnlyFirstScene,
} from '../../models/Storyboard/StoryboardVersion';
import { StoryboardFields, WatermarkSnippet } from '../../models/Storyboard/Storyboard';
import { SceneFields, SceneTransitionSnippet } from '../../models/Scene/Scene';
import { PanelFields } from '../../models/Panel/Panel';
import { LayoutFields } from '../../models/Layout/Layout';
import { LayoutCustomFields } from '../../models/Layout/LayoutCustom';
import { PanelVersionFields } from '../../models/Panel/PanelVersion';
import { SceneVersionFields } from '../../models/Scene/SceneVersion';
import { EmbedFields } from '../../models/Embed/Embed';
import {
    LocationFields,
    LocationLightFields,
    LocationSchedulesFields,
} from '../../models/Location/Location';
import { AccessControlledFields } from '../Auth/AuthService';
import { ScheduledStoryboardFields } from '../../models/ScheduledStoryboard/ScheduledStoryboard';
import { DataSourceFragment } from 'models/DataSource/DataSource';
import { HeaderSnippet } from 'components/Storyboard/StoryboardHeaderFooter/Header/options';
import { PageNumberSnippet } from 'components/Storyboard/StoryboardHeaderFooter/PageNumber/options';

export type QueryDescriptor = {
    name?: QueryName;
    queryParams: {
        query: string;
        variables: {};
    };
    model?: string;
    extractor?: (any) => any;
};

export const ClientMutationId = 'clientMutationId';

export type ModelName =
    | 'layout'
    | 'layoutCustom'
    | 'location'
    | 'panel'
    | 'partner'
    | 'partnerLimit'
    | 'scene'
    | 'storyboard'
    | 'storyboardVersion'
    | 'panelVersion'
    | 'sceneVersion'
    | 'embed'
    | 'theme'
    | 'pdf'
    | 'panelPublishResult'
    | 'scheduledStoryboard'
    | 'pptx';

export type LocationMutationName =
    | 'createLocation'
    | 'deleteLocation'
    | 'renameLocation'
    | 'setLocationRemoteCustomCSS'
    | 'setLocationActiveStoryboardId'
    | 'addLocationStoryboardId'
    | 'removeLocationStoryboardId'
    | 'patchLocation'
    | 'setLocationPausedAtIndex'
    | 'setLocationSharedWithUserIds'
    | 'setLocationPassword';
export type LayoutMutationName = 'createLayout' | 'deleteLayout' | 'renameLayout';
export type EmbedMutationName = 'createEmbed' | 'deleteEmbed' | 'renameEmbed' | 'updateEmbed';
export type PanelVersionMutationName = 'updatePanelVersion' | 'abandonPanelVersion';

export type SceneVersionMutationName =
    | 'renameSceneVersion'
    | 'setSceneVersionPanelVersionIds'
    | 'abandonSceneVersion';

export type StoryboardMutationName =
    | 'createStoryboard'
    | 'deleteStoryboard'
    | 'renameStoryboard'
    | 'addStoryboardSceneId'
    | 'addStoryboardScene'
    | 'removeStoryboardSceneId'
    | 'setStoryboardSceneIds'
    | 'setStoryboardSceneDuration'
    | 'cloneStoryboard'
    | 'abandonStoryboardVersion'
    | 'promoteStoryboardVersion'
    | 'saveStoryboardVersion'
    | 'replaceStoryboardPanelWithEmpty'
    | 'copyStoryboardVersionToNewVersion'
    | 'setStoryboardSharedWithUserIds'
    | 'setStoryboardPassword'
    | 'setImportComplete'
    | 'setAccessControl';

export type StoryboardVersionMutationName =
    | 'renameStoryboardVersion'
    | 'addStoryboardVersionSceneVersion'
    | 'removeStoryboardVersionSceneVersionId'
    | 'setStoryboardVersionSceneVersionIds'
    | 'setStoryboardVersionSceneVersionPanelVersionIds'
    | 'setStoryboardVersionSceneDuration'
    | 'cloneSceneVersion'
    | 'replaceStoryboardVersionPanelVersionWithEmpty'
    | 'copyStoryboardVersionToNewVersion'
    | 'storyboardVersionDeepThemeReset'
    | 'setStoryboardVersionSceneTransition';

export type ThemeMutationName =
    | 'createTheme'
    | 'cloneTheme'
    | 'updateTheme'
    | 'setClientDefaultTheme'
    | 'deleteTheme';

export type PdfMutationName = 'createStoryboardVersionPdf';

export type PptxMutationName = 'createStoryboardVersionPptx';

export type MutationName =
    | LayoutMutationName
    | LocationMutationName
    | StoryboardMutationName
    | PanelVersionMutationName
    | SceneVersionMutationName
    | StoryboardVersionMutationName
    | EmbedMutationName
    | ThemeMutationName
    | PdfMutationName
    | PptxMutationName
    | string;

export type LocationQueryName =
    | 'location'
    | 'clientLocations'
    | 'clientLocationsLight'
    | 'clientLocationSchedules';
export type LayoutQueryName = 'layout' | 'clientLayouts';
export type PanelQueryName = 'panel' | 'clientPanels';
export type PanelVersionQueryName = 'panelVersion';
export type EmbedQueryName = 'embed' | 'clientEmbeds';
export type SceneQueryName = 'scene' | 'clientScenes';
export type StoryboardVersionQueryName = 'clientStoryboardVersions';
export type StoryboardQueryName = 'storyboard' | 'clientStoryboards' | 'clientStoryboardsLight';
export type PartnerQueryName = 'partner';
export type PartnerLimitQueryName = 'partnerLimit';
export type ThemeQueryName = 'theme' | 'clientThemes';
export type PdfQueryName = 'pdf';
export type PptxQueryName = 'pptx';

export type QueryName =
    | LocationQueryName
    | LayoutQueryName
    | PanelQueryName
    | PanelVersionQueryName
    | PartnerQueryName
    | PartnerLimitQueryName
    | SceneQueryName
    | StoryboardQueryName
    | StoryboardVersionQueryName
    | EmbedQueryName
    | ThemeQueryName
    | PdfQueryName
    | PptxQueryName;

export interface MutationDescriptor {
    name?: MutationName;
    queryParams: GraphQLQuery;
    extractor: (any) => any;
    model?: ModelName;
}

/**
 * @deprecated
 */
export class GraphQLQueries {
    public mutationDescriptor(
        name: MutationName,
        model: ModelName,
        props: any
    ): MutationDescriptor {
        let descriptor: MutationDescriptor;

        switch (name) {
            default:
                descriptor = this.createMutationDescriptor(name, props, model);
        }

        if (!descriptor.queryParams || !descriptor.queryParams.query) {
            throw new Error(`Query descriptor for mutation ${name} has no query body.`);
        }

        return descriptor;
    }

    public queryDescriptor(
        name: QueryName,
        model: ModelName,
        props: any,
        opts?: any
    ): QueryDescriptor {
        const extractorFn = result => result[model];

        return {
            name,
            queryParams: {
                query: this[name],
                variables: props,
            },
            extractor: extractorFn,
            model,
        };
    }

    /**
     *
     * @param name of the mutation, must be one of MutationName
     * @param props
     * @param model
     * @param extractor
     * @returns {{name: MutationName, queryParams: {query: any, variables: {input: any}}, extractor: ((any:any)=>any), model: ModelName}}
     */
    public createMutationDescriptor(
        name: MutationName,
        props: any,
        model: ModelName,
        extractor?: (any) => any
    ): MutationDescriptor {
        const extractorFn = extractor ? extractor : result => result[name][model];

        return {
            name,
            queryParams: {
                query: this[name],
                variables: { input: props },
            },
            extractor: extractorFn,
            model,
        };
    }

    public get partnerLimit(): string {
        return `
            {
                partnerLimit {
                        partnerId
                        wall
                        single
                        distributed
                        distributedWall
                        custom
                        sharingEnabled
                        externalSsoUrlSecurity
                        featuresEnabled
                }
            }
        `;
    }

    public get partner(): string {
        return `
            {
                partner {
                        id
                        displayCounts {
                            count
                            type
                        }
                }
            }
        `;
    }

    public get renameLayout(): string {
        return `mutation rename($input: RenameLayoutInput!)
            {
                    renameLayout(input: $input) {
                        clientMutationId
                        layout {
                            ${LayoutFields}
                        }
                    }
            }
            `;
    }

    public get clientLayoutsQuery(): string {
        return `
            {
                client {
                    layouts {
                        ${LayoutFields}
                    }
                }
            }
            `;
    }

    public get clientLayoutsCustomQuery(): string {
        return `
            {
                client {
                    layoutsCustom {
                        ${LayoutCustomFields}
                    }
                }
            }
            `;
    }

    public get clientLocationsQuery(): string {
        return `
            {
                client {
                    locations {
                        ${LocationFields}
                    }
                }
            }
            ${WidgetFragments}
            ${DataSourceFragment}
            ${ChildThemeFragment}
            `;
    }

    public get setLocationSharedWithUserIds(): string {
        return `mutation setLocationSharedWithUserIds($input: SetLocationSharedWithUserIdsInput!) {
            setLocationSharedWithUserIds(input: $input) {
                location {
                    ${LocationFields}
                }
            }
        }
        ${WidgetFragments}
        ${DataSourceFragment}
        ${ChildThemeFragment}
        `;
    }

    public get clientLocationsLightQuery(): string {
        return `
            {
                client {
                    locations {
                        ${LocationLightFields}
                    }
                }
            }
            `;
    }

    public get locationSchedulesQuery(): string {
        return `
            {
                client {
                    locations {
                        ${LocationSchedulesFields}
                    }
                }
            }
            `;
    }

    public get clientEmbedsQuery(): string {
        return `
            {
                client {
                    embeds {
                        ${EmbedFields}
                    }
                }
            }
            `;
    }

    public get storyboard(): string {
        return `
            query RetrieveStoryboard($id: ID!) {
                storyboard(id: $id) {
                   ${StoryboardFields}
                   ${WatermarkSnippet}
                }
            }
            ${WidgetFragments}
            ${DataSourceFragment}
            ${ChildThemeFragment}
            `;
    }

    public get storyboardVersionsLite(): string {
        return `
            query RetrieveStoryboardVersions($masterId: ID!) {
                client {
                    storyboardVersions(masterId: $masterId) {
                       ${StoryboardVersionLightFieldsOnlyFirstScene}
                    }
                }
            }
            `;
    }

    public get createStoryboard(): string {
        return `mutation newStoryboard($input: CreateStoryboardInput!) {
                createStoryboard(input: $input) {
                    storyboard {
                       ${StoryboardFields}
                    }
                    ${ClientMutationId}
                }
            }
            ${WidgetFragments}
            ${ChildThemeFragment}
            ${DataSourceFragment}
            `;
    }

    public get renameStoryboard(): string {
        return `mutation rename($input: RenameStoryboardInput!) {
            renameStoryboard(input: $input) {
                ${ClientMutationId}
                storyboard {
                   ${StoryboardFields}
                }
            }
        }
        ${WidgetFragments}
        ${ChildThemeFragment}
        ${DataSourceFragment}
        `;
    }

    public get addStoryboardVersionSceneVersion(): string {
        return `
            mutation ($input: AddStoryboardVersionSceneVersionInput!) {
                addStoryboardVersionSceneVersion(input: $input) {
                    storyboardVersion {
                        ${StoryboardVersionFields}
                    }
                    ${ClientMutationId}
                }
            }
            ${WidgetFragments}
            ${ChildThemeFragment}
            `;
    }

    public get removeStoryboardVersionSceneVersionId(): string {
        return `
            mutation ($input: RemoveStoryboardVersionSceneVersionIdInput!) {
                removeStoryboardVersionSceneVersionId(input: $input) {
                    storyboardVersion {
                        ${StoryboardVersionFields}
                    }
                    ${ClientMutationId}
                }
            }
            ${WidgetFragments}
            ${ChildThemeFragment}
            `;
    }

    public get setStoryboardVersionSceneVersionIds(): string {
        return `
            mutation ($input: SetStoryboardVersionSceneVersionIdsInput!) {
                setStoryboardVersionSceneVersionIds(input: $input) {
                    storyboardVersion {
                        ${StoryboardVersionFields}
                    }
                    ${ClientMutationId}
                }
            }
            ${WidgetFragments}
            ${ChildThemeFragment}
            `;
    }

    public get setStoryboardVersionSceneVersionPanelVersionIds(): string {
        return `
            mutation ($input: SetStoryboardVersionSceneVersionPanelVersionIdsInput!) {
                setStoryboardVersionSceneVersionPanelVersionIds(input: $input) {
                    storyboardVersion {
                        ${StoryboardVersionFields}
                    }
                    ${ClientMutationId}
                }
            }
            ${WidgetFragments}
            ${ChildThemeFragment}
            `;
    }

    public get setStoryboardVersionSceneDuration(): string {
        return `
            mutation ($input: SetStoryboardVersionSceneDurationInput!) {
                setStoryboardVersionSceneDuration(input: $input) {
                    storyboardVersion {
                        ${StoryboardVersionFields}
                    }
                    ${ClientMutationId}
                }
            }
            ${WidgetFragments}
            ${ChildThemeFragment}
            `;
    }

    public get setStoryboardVersionSceneVersionDuration(): string {
        return `
            mutation ($input: SetStoryboardVersionSceneVersionDurationInput!) {
                setStoryboardVersionSceneVersionDuration(input: $input) {
                    storyboardVersion {
                        ${StoryboardVersionFields}
                    }
                    ${ClientMutationId}
                }
            }
            ${WidgetFragments}
            ${ChildThemeFragment}
            `;
    }

    public get setStoryboardVersionSceneVersionDisabled(): string {
        return `
            mutation ($input: SetStoryboardVersionSceneVersionDisabledInput!) {
                setStoryboardVersionSceneVersionDisabled(input: $input) {
                    storyboardVersion {
                        ${StoryboardVersionFields}
                    }
                    ${ClientMutationId}
                }
            }
            ${WidgetFragments}
            ${ChildThemeFragment}
            `;
    }

    public get promoteStoryboardVersion(): string {
        return `
            mutation ($input: PromoteStoryboardVersionInput!) {
                promoteStoryboardVersion(input: $input) {
                    storyboard {
                        ${StoryboardFields}
                        ${PageNumberSnippet}
                        ${HeaderSnippet}
                        ${WatermarkSnippet}
                    }
                    ${ClientMutationId}
                }
            }
            ${WidgetFragments}
            ${ChildThemeFragment}
            ${DataSourceFragment}
        `;
    }

    public get saveStoryboardVersion(): string {
        return `
            mutation ($input: SaveStoryboardVersionInput!) {
                saveStoryboardVersion(input: $input) {
                    storyboard {
                        ${StoryboardFields}
                        ${WatermarkSnippet}
                    }
                    ${ClientMutationId}
                }
            }
            ${WidgetFragments}
            ${ChildThemeFragment}
            ${DataSourceFragment}
        `;
    }

    public get copyStoryboardVersionToNewVersion(): string {
        return `
            mutation ($input: CopyStoryboardVersionToNewVersionInput!) {
                copyStoryboardVersionToNewVersion(input: $input) {
                    storyboard {
                        ${StoryboardFields}
                        ${WatermarkSnippet}
                    }
                    ${ClientMutationId}
                }
            }
            ${WidgetFragments}
            ${ChildThemeFragment}
            ${DataSourceFragment}
        `;
    }

    public get replaceStoryboardVersionPanelVersionWithEmpty(): string {
        return `
            mutation ($input: ReplaceStoryboardVersionPanelVersionWithEmptyInput!) {
                replaceStoryboardVersionPanelVersionWithEmpty(input: $input) {
                    storyboardVersion {
                        ${StoryboardVersionFields}
                    }
                    ${ClientMutationId}
                }
            }
            ${WidgetFragments}
            ${ChildThemeFragment}
        `;
    }

    public get abandonStoryboardVersion(): string {
        return `
            mutation ($input: AbandonStoryboardVersionInput!) {
                abandonStoryboardVersion(input: $input) {
                    storyboard {
                        ${StoryboardFields}
                    }
                    ${ClientMutationId}
                }
            }
            ${WidgetFragments}
            ${ChildThemeFragment}
            ${DataSourceFragment}
        `;
    }

    public get cloneSceneVersion(): string {
        return `
            mutation ($input: CloneSceneVersionInput!) {
                cloneSceneVersion(input: $input) {
                    storyboardVersion {
                        ${StoryboardVersionFields}
                    }
                    ${ClientMutationId}
                }
            }
            ${WidgetFragments}
            ${ChildThemeFragment}
        `;
    }

    public get storyboardVersionDeepThemeReset(): string {
        return `
            mutation ($input: StoryboardVersionDeepThemeResetInput!) {
                storyboardVersionDeepThemeReset(input: $input) {
                    storyboardVersion {
                        ${StoryboardVersionFields}
                    }
                    ${ClientMutationId}
                }
            }
            ${WidgetFragments}
            ${ChildThemeFragment}
        `;
    }

    public get setStoryboardVersionSceneTransition(): string {
        return `
            mutation ($input: SetStoryboardVersionSceneTransitionInput!) {
                setStoryboardVersionSceneTransition(input: $input) {
                    storyboardVersion {
                        ${StoryboardVersionFields}
                    }
                    ${ClientMutationId}
                }
            }
        `;
    }

    public get abandonSceneVersion(): string {
        return `
            mutation ($input: AbandonSceneVersionInput!) {
                abandonSceneVersion(input: $input) {
                    sceneVersion {
                        ${SceneVersionFields}
                        storyboardVersion {
                            ${CommonDocFields}
                            ${VersionCapableFields}
                        }
                    }
                    ${ClientMutationId}
                }
            }
            ${WidgetFragments}
            ${ChildThemeFragment}
        `;
    }

    public get abandonPanelVersion(): string {
        return `
            mutation ($input: AbandonPanelVersionInput!) {
                abandonPanelVersion(input: $input) {
                    panelVersion {
                        ${PanelVersionFields}
                        storyboardVersion {
                            ${CommonDocFields}
                            ${VersionCapableFields}
                        }
                        sceneVersion {
                            ${CommonDocFields}
                            ${VersionCapableFields}
                        }
                    }
                    ${ClientMutationId}
                }
            }
            ${WidgetFragments}
            ${ChildThemeFragment}
        `;
    }

    public get setStoryboardSharedWithUserIds(): string {
        return `
            mutation ($input: SetStoryboardSharedWithUserIdsInput!) {
                setStoryboardSharedWithUserIds(input: $input) {
                    storyboard {
                        ${StoryboardFields}
                    }
                }
            }
            ${WidgetFragments}
            ${ChildThemeFragment}
            ${DataSourceFragment}
        `;
    }

    public get cloneStoryboard(): string {
        return `
            mutation clone($input: CloneStoryboardInput!) {
                cloneStoryboard(input: $input) {
                    storyboard {
                        ${StoryboardFields}
                    }
                    ${ClientMutationId}
                }
            }
            ${WidgetFragments}
            ${ChildThemeFragment}
            ${DataSourceFragment}
        `;
    }

    public get deleteStoryboard(): string {
        return `mutation delete($input: DeleteStoryboardInput!) {
            deleteStoryboard(input: $input) {
                ${ClientMutationId}
            }
        }`;
    }

    public get location(): string {
        return `query RetrieveLocation($id: ID!) {
                location(id: $id) {
                    ${LocationFields}
                }
            }
            ${WidgetFragments}
            ${DataSourceFragment}
            ${ChildThemeFragment}
            `;
    }

    public get scene(): string {
        return `
            query RetrieveScene($id: ID!) {
                scene(id: $id) {
                    ${SceneFields}
                }
            }
            ${WidgetFragments}
            ${ChildThemeFragment}
            `;
    }

    public get createLayout(): string {
        return `mutation newLayout($input: CreateLayoutInput!) {
                createLayout(input: $input) {
                    clientMutationId
                    layout {
                        ${LayoutFields}
                    }
                }
            }`;
    }

    public get deleteLocation(): string {
        return `mutation delete($input: DeleteLocationInput!) {
                deleteLocation(input: $input) {
                    ${ClientMutationId}
                }
            }`;
    }

    public get createLocation(): string {
        return `mutation newLocation($input: CreateLocationInput!) {
                    createLocation(input: $input) {
                    location {
                        ${LocationFields}
                    }
                }
            }
            ${WidgetFragments}
            ${DataSourceFragment}
            ${ChildThemeFragment}
            `;
    }

    public get patchLocation(): string {
        return `mutation PatchLocation($input: PatchLocationInput!) {
                    patchLocation(input: $input) {
                    location {
                        ${LocationLightFields}
                    }
                }
            }
            `;
    }

    public get setLocationActiveStoryboardId(): string {
        return `mutation ($input: SetLocationActiveStoryboardIdInput!) {
                setLocationActiveStoryboardId(input: $input) {
                    ${ClientMutationId}
                    location {
                        ${LocationLightFields}
                    }
                }
            }
            `;
    }

    public get setLocationPassword(): string {
        return `mutation ($input: SetLocationPasswordInput!) {
                setLocationPassword(input: $input) {
                    ${ClientMutationId}
                    location {
                        ${LocationLightFields}
                    }
                }
            }
            `;
    }

    public get setLocationPausedAtIndex(): string {
        return `mutation ($input: SetLocationPausedAtIndexInput!) {
                setLocationPausedAtIndex(input: $input) {
                    ${ClientMutationId}
                    location {
                        ${CommonDocFields}
                        pausedAtIndex
                        startTime
                    }
                }
            }
        `;
    }

    public get addLocationStoryboardId(): string {
        return `mutation ($input: AddLocationStoryboardIdInput!) {
                addLocationStoryboardId(input: $input) {
                    ${ClientMutationId}
                    location {
                        ${LocationLightFields}
                    }
                }
            }
            `;
    }

    public get removeLocationStoryboardId(): string {
        return `mutation ($input: RemoveLocationStoryboardIdInput!) {
                removeLocationStoryboardId(input: $input) {
                    ${ClientMutationId}
                    location {
                        ${LocationLightFields}
                    }
                }
            }
            `;
    }

    public get renameLocation(): string {
        return `mutation rename($input: RenameLocationInput!) {
                renameLocation(input: $input) {
                    ${ClientMutationId}
                    location {
                        ${LocationLightFields}
                    }
                }
            }
            `;
    }

    public get setLocationRemoteCustomCSS(): string {
        return `mutation updateRemoteCustomCSS($input: SetLocationRemoteCustomCSSInput!) {
                setLocationRemoteCustomCSS(input: $input) {
                    ${ClientMutationId}
                    location {
                        ${LocationFields}
                    }
                }
            }
            ${DataSourceFragment}
            ${WidgetFragments}
            ${ChildThemeFragment}
            `;
    }

    public get renameSceneVersion(): string {
        return `mutation renameSceneVer($input: RenameSceneVersionInput!) {
                renameSceneVersion(input: $input) {
                    ${ClientMutationId}
                    sceneVersion {
                        ${SceneVersionFields}
                        storyboardVersion {
                            id treeDirty
                        }
                    }
                }
            }
            ${WidgetFragments}
            ${ChildThemeFragment}
            `;
    }

    public get setSceneVersionPanelVersionIds(): string {
        return `mutation ($input: SetSceneVersionPanelVersionIdsInput!) {
                setSceneVersionPanelVersionIds(input: $input) {
                    sceneVersion {
                        ${SceneVersionFields}
                    }
                    ${ClientMutationId}
                }
            }
            ${WidgetFragments}
            ${ChildThemeFragment}
            `;
    }

    public get panel(): string {
        return `query RetrievePanel($id: ID!) {
                panel(id: $id) {
                    ${PanelFields}
                }
            }
            ${WidgetFragments}
            ${ChildThemeFragment}
            `;
    }

    public get panelVersion(): string {
        return `query RetrievePanelVersion($id: ID!) {
                panelVersion(id: $id) {
                    ${PanelVersionFields}
                }
            }
            ${WidgetFragments}
            ${ChildThemeFragment}
            `;
    }

    public get updatePanelVersion(): string {
        return `mutation modifyPanelVersion($input: UpdatePanelVersionInput!) {
                updatePanelVersion(input: $input) {
                    panelVersion {
                        ${PanelVersionFields}
                    }
                }
            }
            ${WidgetFragments}
            ${ChildThemeFragment}
            `;
    }

    public get embed(): string {
        return `query RetrieveEmbed($id: ID!) {
                embed(id: $id) {
                    ${EmbedFields}
                }
            }
            `;
    }

    public get createEmbed(): string {
        return `mutation newEmbed($input: CreateEmbedInput!) {
                createEmbed(input: $input) {
                    embed {
                        ${EmbedFields}
                    }
                }
            }
            `;
    }

    public get updateEmbed(): string {
        return `mutation modifyEmbed($input: UpdateEmbedInput!) {
                updateEmbed(input: $input) {
                    embed {
                        ${EmbedFields}
                    }
                }
            }
            `;
    }

    public get renameEmbed(): string {
        return `
            mutation rename($input: RenameEmbedInput!) {
                renameEmbed(input: $input)
                {
                    ${ClientMutationId}
                }
            }
            `;
    }

    public get deleteEmbed(): string {
        return `
            mutation delete($input: DeleteEmbedInput!) {
                deleteEmbed(input: $input)
                {
                    ${ClientMutationId}
                }
            }
            `;
    }

    public get setStoryboardPassword(): string {
        return `mutation ($input: SetStoryboardPasswordInput!) {
                setStoryboardPassword(input: $input) {
                    ${ClientMutationId}
                    storyboard {
                         ${StoryboardFields}
                    }
                }
            }
            ${WidgetFragments}
            ${ThemeFragment}
            ${DataSourceFragment}
            `;
    }

    public get importDashboard(): string {
        return `mutation ($input: ImportDashboardInput!) {
                importDashboard(input: $input) {
                    ${ClientMutationId}
                    storyboard {
                         ${StoryboardFields}
                    }
                }
            }
            ${WidgetFragments}
            ${ThemeFragment}
            ${DataSourceFragment}
            `;
    }

    public get setStoryboardImportComplete(): string {
        return `mutation ($input: SetStoryboardImportCompleteInput!) {
                setStoryboardImportComplete(input: $input) {
                    ${ClientMutationId}
                    storyboard {
                         ${StoryboardFields}
                    }
                }
            }
            ${WidgetFragments}
            ${ThemeFragment}
            ${DataSourceFragment}
            `;
    }

    public get importStoryboards(): string {
        return `mutation ImportStoryboardMut($input: ImportStoryboardInput!) {
              importStoryboard(input: $input) {
                ${ClientMutationId}
                storyboards {
                     ${StoryboardFields}
                }
              }
            }
            ${WidgetFragments}
            ${ThemeFragment}
            ${DataSourceFragment}
        `;
    }

    public get importStoryboardFromTemplate(): string {
        return `mutation importStoryboardFromTemplateMut($input: ImportStoryboardFromTemplateInput!) {
              importStoryboardFromTemplate(input: $input) {
                ${ClientMutationId}
                storyboards {
                     ${StoryboardFields}
                }
              }
            }
            ${WidgetFragments}
            ${ThemeFragment}
            ${DataSourceFragment}
        `;
    }

    public get setStoryboardAccessControl(): string {
        return `mutation ($input: SetStoryboardAccessControlInput!) {
                setStoryboardAccessControl(input: $input) {
                    ${ClientMutationId}
                    storyboard {
                         ${StoryboardFields}
                    }
                }
            }
            ${WidgetFragments}
            ${ThemeFragment}
            ${DataSourceFragment}
            `;
    }

    public get addScheduledStoryboard(): string {
        return `mutation ($input: AddScheduledStoryboardInput!) {
                addScheduledStoryboard(input: $input) {
                    ${ClientMutationId}
                    scheduledStoryboard {
                         ${ScheduledStoryboardFields}
                    }
                }
            }
            `;
    }

    public get updateScheduledStoryboard(): string {
        return `mutation ($input: UpdateScheduledStoryboardInput!) {
                updateScheduledStoryboard(input: $input) {
                    ${ClientMutationId}
                    scheduledStoryboard {
                         ${ScheduledStoryboardFields}
                    }
                }
            }
            `;
    }

    public get deleteScheduledStoryboard(): string {
        return `mutation ($input: DeleteScheduledStoryboardInput!) {
                deleteScheduledStoryboard(input: $input) {
                    ${ClientMutationId}
                }
            }
            `;
    }

    public get createCustomLayout(): string {
        return `mutation newCustomLayout($input: CreateCustomLayoutInput!) {
                createCustomLayout(input: $input) {
                    clientMutationId
                    layoutCustom {
                        ${LayoutCustomFields}
                    }
                }
            }`;
    }

    public get renameCustomLayout(): string {
        return `mutation rename($input: RenameCustomLayoutInput!) {
                renameCustomLayout(input: $input) {
                    clientMutationId
                    layoutCustom {
                        ${LayoutCustomFields}
                    }
                }
            }
            `;
    }

    public get deleteCustomLayout(): string {
        return `mutation delete($input: DeleteCustomLayoutInput!) {
                deleteCustomLayout(input: $input) {
                     ${ClientMutationId}
                }
            }
            `;
    }
}
