import { Panel } from 'models/Panel/Panel';
import { WidgetSmartTrends } from 'src/stores/PanelTemplatesStore/WidgetSmartTrends';
import merge from 'deepmerge';
import _cloneDeep from 'lodash/cloneDeep';
const getMergedWidget = () => {
    const widget = _cloneDeep(WidgetSmartTrends);
    widget.options = merge(widget.options, {
        smartTrends: { fontScale: 30 },
    });
    widget.position = {
        width: '100%',
        height: '100%',
        top: '0',
        left: '0',
    };

    return widget;
};

export const TemplateSmartTrends: Panel = {
    id: 'smart-trends',
    name: 'Trend Cards',
    description: '',
    tags: ['Content', 'Posts', 'Smart Trends', 'landscape', 'portrait'],
    widget: {
        id: '1',
        position: {
            width: '1920px',
            height: '1080px',
            top: '0',
            left: '0',
        },
        classes: 'template_smart_trends',
        children: [getMergedWidget()],
        theme: {
            ext: {
                padding: 20,
            },
        },
    },
};
