import { action } from 'mobx';
import { Mapper } from '../DataStore/DataStore';
import { GraphQLService } from '../GraphQLService/GraphQLService';
import { GraphQLQueries, ModelName } from '../GraphQLQueries/GraphQLQueries';
import { BaseRecordService } from 'utils/BaseRecordService/BaseRecordService';
import { Embed } from 'models/Embed/Embed';

const MODELNAME: ModelName = 'embed';

/**
 * Created by dstelter on 12/15/16.
 */
export class EmbedService extends BaseRecordService<Embed> {
    private queries: GraphQLQueries;

    constructor(graphQL: GraphQLService, mapper: Mapper<Embed>) {
        super(graphQL, mapper);
        this.queries = new GraphQLQueries();
    }

    find(id: string | number): Promise<Embed> {
        return this.query(this.queries.queryDescriptor('embed', MODELNAME, { id: id }));
    }

    create(embed: Embed): Promise<Embed> {
        return this.mutate(
            this.queries.mutationDescriptor('createEmbed', MODELNAME, { embed: embed })
        );
    }

    destroy(embed: Embed | string): Promise<any> {
        return this.mutate(
            this.queries.mutationDescriptor('deleteEmbed', MODELNAME, { id: this.embedId(embed) })
        );
    }

    findAll(query?: any): Promise<Array<Embed>> {
        query = query || {};

        const queryParams = {
            query: this.queries.clientEmbedsQuery,
            variables: query,
        };

        const extractor = result => {
            return result.client.embeds;
        };

        return this.graphQL
            .query(queryParams)
            .then(extractor)
            .then(result => this.mapper.createMany(result));
    }

    rename(embed: Embed, name: string): Promise<Embed> {
        return this.mutate(
            this.queries.mutationDescriptor('renameEmbed', MODELNAME, {
                embedId: this.embedId(embed),
                name: name,
            })
        ).then(
            action(() => {
                embed.name = name;
                return embed;
            })
        );
    }

    update(embed: Embed): Promise<Embed> {
        if (!embed) {
            throw new Error('Embed parameter is required.');
        }

        const embedId = this.embedId(embed);

        // note we're supplying the embedId as a separate field, this is due to the InputObject type for Embed on the API.
        return this.mutate(
            this.queries.mutationDescriptor('updateEmbed', MODELNAME, {
                embed: embed,
                embedId: embedId,
            })
        );
    }

    /**
     * Check the embed's widget to ensure it has all the latest things in it
     *
     * @param record
     * @returns {void}
     */
    protected recordLoaded(record: Embed): void {}
}
