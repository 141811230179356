declare const process: any;

export type ApplicationMode = 'DISPLAY' | 'EMBED' | 'PRESENTATIONS';
export type ApplicationIdentifier = 'spr-display' | 'spr-gallery' | 'spr-presentations' | 'spr-test';

export interface EnvironmentConfig {
    apiRoot: string;
    sprinklrRoot: string;
    embedRoot: string;
    embedAnalytics: string;
    geoRoot: string;
    developmentMode: boolean;
    applicationMode: ApplicationMode;
    useSkuLimits: boolean;
    sandboxed?: boolean;
    localGalleryAnalytics?: boolean; // for gallery v2 to test analytics events while in the builder use this flag
    applicationIdentifier: ApplicationIdentifier;
}

// comes from file localconfig.json, see webpack.config.js
declare const localAppConfig: EnvironmentConfig;

const config: Omit<EnvironmentConfig, "applicationIdentifier"> = ((environment): Omit<EnvironmentConfig, 'applicationIdentifier'> => {
    switch (environment) {
        case 'dev':
        case 'production':
            return {
                apiRoot: 'https://display-api.sprinklr.com/',
                sprinklrRoot: 'https://app.sprinklr.com/',
                embedRoot: '',
                embedAnalytics: '',
                geoRoot: 'https://display-geo.sprinklr.com/',
                applicationMode: 'DISPLAY',
                developmentMode: false,
                useSkuLimits: true,
            };
        case 'staging':
        case 'preprod':
            return {
                apiRoot: 'https://display-api.sprinklr.com/',
                sprinklrRoot: 'https://app.sprinklr.com/',
                embedRoot: '',
                embedAnalytics: '',
                geoRoot: 'https://display-geo.sprinklr.com/',
                applicationMode: 'DISPLAY',
                developmentMode: false,
                useSkuLimits: true,
            };
        case 'dev2':
            return {
                apiRoot: 'https://dev-display-api.sprinklr.com/',
                sprinklrRoot: 'https://app.sprinklr.com/',
                embedRoot: '',
                embedAnalytics: '',
                geoRoot: 'https://display-geo.sprinklr.com/',
                applicationMode: 'DISPLAY',
                developmentMode: false,
                useSkuLimits: false,
            };
        case 'gallery-local':
            return {
                apiRoot: 'https://display-api.sprinklr.com/',
                sprinklrRoot: 'https://app.sprinklr.com/',
                embedRoot: 'https://spr-gallery-test.s3.amazonaws.com/',
                embedAnalytics: 'https://prod-external-share-api.sprinklr.com/',
                geoRoot: 'https://display-geo.sprinklr.com/',
                applicationMode: 'EMBED',
                developmentMode: true,
                useSkuLimits: false,
                localGalleryAnalytics: false,
            };
        case 'gallery-dev':
            return {
                apiRoot: 'https://dev-display-api.sprinklr.com/',
                sprinklrRoot: 'https://app.sprinklr.com/',
                embedRoot: 'https://gallery-dev.sprinklr.com/',
                embedAnalytics: 'https://qa4-external-share-api.sprinklr.com/',
                geoRoot: 'https://display-geo.sprinklr.com/',
                applicationMode: 'EMBED',
                developmentMode: false,
                useSkuLimits: false,
            };
        case 'gallery-prod':
            return {
                apiRoot: 'https://display-api.sprinklr.com/',
                sprinklrRoot: 'https://app.sprinklr.com/',
                embedRoot: 'https://gallery.sprinklr.com/',
                embedAnalytics: 'https://prod-external-share-api.sprinklr.com/',
                geoRoot: 'https://display-geo.sprinklr.com/',
                applicationMode: 'EMBED',
                developmentMode: false,
                useSkuLimits: true,
            };
        case 'presentations-dev':
        case 'presentations-local':
            return {
                apiRoot: 'https://display-api.sprinklr.com/',
                sprinklrRoot: 'https://app.sprinklr.com/',
                embedRoot: '',
                embedAnalytics: '',
                geoRoot: 'https://display-geo.sprinklr.com/',
                applicationMode: 'PRESENTATIONS',
                developmentMode: false,
                useSkuLimits: false,
            };
        case 'presentations-staging':
            return {
                apiRoot: 'https://prod2-display-api.sprinklr.com/',
                sprinklrRoot: 'https://prod2.sprinklr.com/',
                embedRoot: '',
                embedAnalytics: '',
                geoRoot: 'https://display-geo.sprinklr.com/',
                applicationMode: 'PRESENTATIONS',
                developmentMode: false,
                useSkuLimits: false,
            };
        case 'presentations-preprod':
        case 'presentations-prod':
            return {
                apiRoot: 'https://display-api.sprinklr.com/',
                sprinklrRoot: 'https://app.sprinklr.com/',
                embedRoot: 'https://presentations.sprinklr.com/',
                embedAnalytics: '',
                geoRoot: 'https://display-geo.sprinklr.com/',
                applicationMode: 'PRESENTATIONS',
                developmentMode: false,
                useSkuLimits: false,
            };
        case 'presentations-prod2':
            return {
                apiRoot: 'https://prod2-display-api.sprinklr.com/',
                sprinklrRoot: 'https://prod2.sprinklr.com/',
                embedRoot: 'https://presentations-prod2.sprinklr.com/',
                embedAnalytics: '',
                geoRoot: 'https://display-geo.sprinklr.com/',
                applicationMode: 'PRESENTATIONS',
                developmentMode: false,
                useSkuLimits: false,
            };
        case 'presentations-prod3':
            return {
                apiRoot: 'https://prod3-display-api.sprinklr.com/',
                sprinklrRoot: 'https://prod3.sprinklr.com/',
                embedRoot: 'https://presentations-prod3.sprinklr.com/',
                embedAnalytics: '',
                geoRoot: 'https://display-geo.sprinklr.com/',
                applicationMode: 'PRESENTATIONS',
                developmentMode: false,
                useSkuLimits: false,
            };
        case 'presentations-prod4':
            return {
                apiRoot: 'https://prod4-display-api.sprinklr.com/',
                sprinklrRoot: 'https://prod4.sprinklr.com/',
                embedRoot: 'https://presentations-prod4.sprinklr.com/',
                embedAnalytics: '',
                geoRoot: 'https://display-geo.sprinklr.com/',
                applicationMode: 'PRESENTATIONS',
                developmentMode: false,
                useSkuLimits: false,
            };
        case 'presentations-prod8':
            return {
                apiRoot: 'https://prod8-display-api.sprinklr.com/',
                sprinklrRoot: 'https://prod8.sprinklr.com/',
                embedRoot: 'https://presentations-prod8.sprinklr.com/',
                embedAnalytics: '',
                geoRoot: 'https://display-geo.sprinklr.com/',
                applicationMode: 'PRESENTATIONS',
                developmentMode: false,
                useSkuLimits: false,
            };
        case 'presentations-prod0':
            return {
                apiRoot: 'https://prod0-display-api.sprinklr.com/',
                sprinklrRoot: 'https://prod0.sprinklr.com/',
                embedRoot: 'https://presentations-prod0.sprinklr.com/',
                embedAnalytics: '',
                geoRoot: 'https://display-geo.sprinklr.com/',
                applicationMode: 'PRESENTATIONS',
                developmentMode: false,
                useSkuLimits: false,
            };
        case 'presentations-qa4':
            return {
                apiRoot: 'https://qa4-display-api.sprinklr.com/',
                sprinklrRoot: 'https://qa4.sprinklr.com/',
                embedRoot: 'https://presentations-qa4.sprinklr.com/',
                embedAnalytics: '',
                geoRoot: 'https://display-geo.sprinklr.com/',
                applicationMode: 'PRESENTATIONS',
                developmentMode: false,
                useSkuLimits: false,
            };
        case 'gallery-qa4':
            return {
                apiRoot: 'https://qa4-display-api.sprinklr.com/',
                sprinklrRoot: 'https://qa4.sprinklr.com/',
                embedRoot: 'https://presentations-qa4.sprinklr.com/',
                embedAnalytics: 'https://qa4-external-share-api.sprinklr.com/',
                geoRoot: 'https://display-geo.sprinklr.com/',
                applicationMode: 'EMBED',
                developmentMode: false,
                useSkuLimits: false,
            };
        case 'presentations-azrqa':
            return {
                apiRoot: 'https://azrqa-display-api.sprinklr.com/',
                sprinklrRoot: 'https://azrqa.sprinklr.com/',
                embedRoot: 'https://presentations-azrqa.sprinklr.com/',
                embedAnalytics: '',
                geoRoot: 'https://display-geo.sprinklr.com/',
                applicationMode: 'PRESENTATIONS',
                developmentMode: false,
                useSkuLimits: false,
            };
        case 'prod2':
            return {
                apiRoot: 'https://prod2-display-api.sprinklr.com/',
                sprinklrRoot: 'https://prod2.sprinklr.com/',
                embedRoot: '',
                embedAnalytics: '',
                geoRoot: 'https://display-geo.sprinklr.com/',
                applicationMode: 'DISPLAY',
                developmentMode: false,
                useSkuLimits: true,
            };
        case 'prod3':
            return {
                apiRoot: 'https://prod3-display-api.sprinklr.com/',
                sprinklrRoot: 'https://prod3.sprinklr.com/',
                embedRoot: '',
                embedAnalytics: '',
                geoRoot: 'https://display-geo.sprinklr.com/',
                applicationMode: 'DISPLAY',
                developmentMode: false,
                useSkuLimits: true,
            };
        case 'prod4':
            return {
                apiRoot: 'https://prod4-display-api.sprinklr.com/',
                sprinklrRoot: 'https://prod4.sprinklr.com/',
                embedRoot: '',
                embedAnalytics: '',
                geoRoot: 'https://display-geo.sprinklr.com/',
                applicationMode: 'DISPLAY',
                developmentMode: false,
                useSkuLimits: true,
            };
        case 'prod8':
            return {
                apiRoot: 'https://prod8-display-api.sprinklr.com/',
                sprinklrRoot: 'https://prod8.sprinklr.com/',
                embedRoot: '',
                embedAnalytics: '',
                geoRoot: 'https://display-geo.sprinklr.com/',
                applicationMode: 'DISPLAY',
                developmentMode: false,
                useSkuLimits: true,
            };
        case 'gallery-prod4':
            return {
                apiRoot: 'https://prod4-display-api.sprinklr.com/',
                sprinklrRoot: 'https://prod4.sprinklr.com/',
                embedRoot: 'https://gallery-prod4.sprinklr.com/',
                embedAnalytics: 'https://prod4-external-share-api.sprinklr.com/',
                geoRoot: 'https://display-geo.sprinklr.com/',
                applicationMode: 'EMBED',
                developmentMode: false,
                useSkuLimits: true,
            };
        case 'gallery-prod8':
            return {
                apiRoot: 'https://prod8-display-api.sprinklr.com/',
                sprinklrRoot: 'https://prod8.sprinklr.com/',
                embedRoot: 'https://gallery-prod8.sprinklr.com/',
                embedAnalytics: 'https://prod8-external-share-api.sprinklr.com/',
                geoRoot: 'https://display-geo.sprinklr.com/',
                applicationMode: 'EMBED',
                developmentMode: false,
                useSkuLimits: true,
            };
        case 'gallery-prod2':
            return {
                apiRoot: 'https://prod2-display-api.sprinklr.com/',
                sprinklrRoot: 'https://prod2.sprinklr.com/',
                embedRoot: 'https://gallery-prod2.sprinklr.com/',
                embedAnalytics: 'https://prod2-external-share-api.sprinklr.com/',
                geoRoot: 'https://display-geo.sprinklr.com/',
                applicationMode: 'EMBED',
                developmentMode: false,
                useSkuLimits: true,
            };
        case 'gallery-prod3':
            return {
                apiRoot: 'https://prod3-display-api.sprinklr.com/',
                sprinklrRoot: 'https://prod3.sprinklr.com/',
                embedRoot: 'https://gallery-prod3.sprinklr.com/',
                embedAnalytics: 'https://prod3-external-share-api.sprinklr.com/',
                geoRoot: 'https://display-geo.sprinklr.com/',
                applicationMode: 'EMBED',
                developmentMode: false,
                useSkuLimits: true,
            };
        case 'prod0':
            return {
                apiRoot: 'https://prod0-display-api.sprinklr.com/',
                sprinklrRoot: 'https://prod0.sprinklr.com/',
                embedRoot: '',
                embedAnalytics: '',
                geoRoot: 'https://display-geo.sprinklr.com/',
                applicationMode: 'DISPLAY',
                developmentMode: false,
                useSkuLimits: false,
            };
        case 'gallery-prod0':
            return {
                apiRoot: 'https://prod0-display-api.sprinklr.com/',
                sprinklrRoot: 'https://prod0.sprinklr.com/',
                embedRoot: 'https://gallery-prod0.sprinklr.com/',
                embedAnalytics: 'https://prod0-external-share-api.sprinklr.com/',
                geoRoot: 'https://display-geo.sprinklr.com/',
                applicationMode: 'EMBED',
                developmentMode: false,
                useSkuLimits: false,
            };
        case 'azrqa':
            return {
                apiRoot: 'https://azrqa-display-api.sprinklr.com/',
                sprinklrRoot: 'https://azrqa-webui.sprinklr.com/',
                embedRoot: '',
                embedAnalytics: '',
                geoRoot: 'https://display-geo.sprinklr.com/',
                applicationMode: 'DISPLAY',
                developmentMode: false,
                useSkuLimits: false,
            };
        case 'qa4':
            return {
                apiRoot: 'https://qa4-display-api.sprinklr.com/',
                sprinklrRoot: 'https://qa4.sprinklr.com/',
                embedRoot: '',
                embedAnalytics: '',
                geoRoot: 'https://display-geo.sprinklr.com/',
                applicationMode: 'DISPLAY',
                developmentMode: false,
                useSkuLimits: false,
            };
        case 'qa5':
            return {
                apiRoot: 'https://qa5-display-api.sprinklr.com/',
                sprinklrRoot: 'https://qa5.sprinklr.com/',
                embedRoot: '',
                embedAnalytics: '',
                geoRoot: 'https://display-geo.sprinklr.com/',
                applicationMode: 'DISPLAY',
                developmentMode: false,
                useSkuLimits: false,
            };
        case 'display-embed-dev':
            return {
                apiRoot: 'https://local.sprinklr.com:8090/',
                sprinklrRoot: 'https://app.sprinklr.com/',
                embedRoot: '',
                embedAnalytics: '',
                geoRoot: 'https://display-geo.sprinklr.com/',
                applicationMode: 'DISPLAY',
                developmentMode: false,
                useSkuLimits: true,
            };
        default:
            if (typeof localAppConfig !== 'undefined' && localAppConfig) {
                const localConfig = localAppConfig;
                if (!localConfig.geoRoot) {
                    localConfig.geoRoot = 'https://display-geo.sprinklr.com/';
                }
                if (!localConfig.embedAnalytics) {
                    localConfig.embedAnalytics = 'https://qa4-external-share-api.sprinklr.com/';
                }
                return localConfig;
            }
            return {
                apiRoot: 'https://display-api.sprinklr.com/',
                // apiRoot: 'https://local.sprinklr.com:8090/',
                sprinklrRoot: 'https://app.sprinklr.com/',
                embedRoot: '',
                embedAnalytics: '',
                geoRoot: 'https://display-geo.sprinklr.com/',
                applicationMode: 'DISPLAY',
                developmentMode: true,
                useSkuLimits: false,
            };
    }
})(process.env.TARGET_ENV);

let applicationIdentifier;

switch (config.applicationMode) {
    case 'EMBED':
        applicationIdentifier = 'spr-gallery';
        break;
    case 'PRESENTATIONS':
        applicationIdentifier = 'spr-presentations';
        break;
    case 'DISPLAY':
    default:
        applicationIdentifier = 'spr-display';
}

export default {...config, applicationIdentifier};
