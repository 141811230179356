import TopicTheme from './TopicTheme';
import SLAFrequency from './SLAFrequency';
import CommunityUser from './CommunityUser';
import LookupItem from './LookupItem';

export type AnalyticChartLabel =
    | number
    | string
    | BulkItem
    | TopicTheme
    | SLAFrequency
    | CommunityUser
    | LookupItem;

export default class BulkItem {
    id: string;
    protected name: string;

    protected constructor(id: string, name?: string) {
        this.id = id;
        this.name = name;
    }

    toString(): string {
        return this.id === undefined || this.id === null ? '' : this.id;
    }

    toLowerCase(): string {
        return this.toString().toLowerCase();
    }

    toName(): string {
        return this.name;
    }

    toColor(): string {
        return undefined;
    }

    toImageUrl(): string {
        return undefined;
    }

    toEmail(): string {
        return undefined;
    }

    get sortValue(): any {
        return this.name;
    }
}
