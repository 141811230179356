import { WidgetOptions } from '@sprinklr/stories/widget/WidgetOptions';
import { WidgetOptionsImpl } from 'models/Widget/WidgetOptions';
import { SmartSize } from '@sprinklr/stories/widget/types';
import { IAxis, ITicks } from 'components/_charts/Primitives/XAxis';
import { LegendAlignment, LegendOptions } from 'components/_charts/Primitives/Legend';
import { observable } from 'mobx';
import { ZAxis } from 'components/_charts/Primitives/SizeLegend';
import { DecimalFormat } from 'utils/Number/NumberUtils';
import { Grid } from '../ComboChartWidget/types';

export interface ZAxisValues {
    enabled: boolean;
    size?: number;
    color?: string;
    autoSize: boolean;
    format: DecimalFormat;
}

export interface AxisBubbleChartWidgetOptions extends WidgetOptions {
    data?: any[];
    xAxis?: IAxis;
    yAxis?: IAxis;
    yAxisZeroStart?: boolean;
    useThemeColor?: boolean;
    showSocialColors?: boolean;
    bubbleOpacity?: number;
    zAxisValues?: ZAxisValues;
}

export class LegendOptionsImpl implements LegendOptions {
    @observable enabled = true;
    @observable size = 16;
    @observable alignment: LegendAlignment = 'left';
    @observable points = {
        enabled: true,
        size: 50,
        stroke: {
            enabled: false,
            size: 3,
        },
    };
}

export class AxisImpl implements IAxis {
    @observable enabled = true;
    @observable label = {
        enabled: true,
        size: 40,
        padding: 50,
    };
    @observable ticks: ITicks = {
        enabled: true,
        label: {
            enabled: true,
            size: 30,
            numberFormat: '1.2k',
            numberCase: 'lowercase',
            timeFormat: 'ddd ha',
            angle: 0,
        },
        line: {
            enabled: true,
        },
        numTicks: 10,
    };
    @observable padding = {
        start: 0,
        end: 0,
    };
}

export class ZAxisImpl implements ZAxis {
    @observable enabled = true;
    @observable numberFormat: DecimalFormat = '1.2k';
    @observable radius = {
        min: 20,
        max: 70,
        size: 100,
    };
}

export class AxisBubbleChartWidgetOptionsImpl extends WidgetOptionsImpl
    implements AxisBubbleChartWidgetOptions {
    @observable grid: Grid = {
        enabled: true,
    };
    @observable bubbleOpacity = 100;
    @observable xAxis: IAxis = new AxisImpl();
    @observable yAxis: IAxis = new AxisImpl();
    @observable zAxis: ZAxis = new ZAxisImpl();
    @observable yAxisZeroStart = true;
    @observable legend = new LegendOptionsImpl();
    @observable maxItems = 10;
    @observable useThemeColor = true;
    @observable useThemeImage = true;
    @observable themeImageOpacity = 100;
    @observable showBorderColor = true;
    @observable showSocialColors = true;
    @observable zAxisValues: ZAxisValues = {
        enabled: false,
        autoSize: true,
        format: '1.2k',
        size: 20,
        color: null,
    };
    @observable smartSize: SmartSize = {
        enabled: false,
        mode: 'responsive',
        size: 'xl',
        baseline: {
            xAxis: {
                label: {
                    size: 40,
                },
                ticks: {
                    label: {
                        size: 30,
                    },
                },
            },
            yAxis: {
                label: {
                    size: 40,
                },
                ticks: {
                    label: {
                        size: 30,
                    },
                },
            },
            zAxis: {
                radius: {
                    min: 20,
                    max: 70,
                    size: 100,
                },
            },
            legend: {
                size: 20,
                points: {
                    size: 50,
                },
            },
        },
        optionDefaults: {
            legend: {
                enabled: true,
            },
        },
        breakpoints: {
            xs: {
                options: {
                    legend: {
                        enabled: false,
                    },
                },
            },
            s: {
                options: {
                    legend: {
                        enabled: false,
                    },
                },
            },
        },
    };
}
