import * as React from 'react';
import { observer } from 'mobx-react';
import { getEndianTime } from '../../utils/Number/NumberUtils';
import styled, { css } from 'components/_UI/designSystem';
import NativeProfileLockup, {
    StyledTimeStamp,
    StyledUserName,
    StyledSecondaryName,
    StyledLocation,
} from './Parts/NativeProfileLockup';
import NativeEngagement, { StyledNativeEngagement } from './Parts/NativeEngagement';
import NativeText from './Parts/NativeText';
import NativeMedia from './Parts/NativeMedia';
import {
    useNativePost,
    getNativeMediaOverrides,
    baseStyles,
    getEntityStyles,
    StyledMeta,
    getNativeColor,
} from './Helpers';
import { NativePostProps } from './DefaultPost';
import NativeFlex from './Bits/NativeFlex';
import { StyledIcon } from 'components/spaceKit';
import { NativeColorMode } from '../../widgets/PostsWidget/types';
import NativeLinkPreview from './NativeLinkPreview';
import NativeQuotedTweetPreview from './NativeQuotedTweetPreview.tsx';

const colors = {
    blue: '#1DA1F2',
    secondaryBlack: '#14171A',
    darkGray: '#657786',
    lightGray: '#AAB8C2',
    extraLightGray: '#E1E8ED',
    extraExtraLightGray: '#F5F8FA',
};

const theme = {
    light: {
        link: colors.blue,
        userName: colors.secondaryBlack,
        secondary: colors.darkGray,
    },
    dark: {
        link: colors.blue,
        userName: colors.extraExtraLightGray,
        secondary: colors.extraLightGray,
    },
};

type StyledPostProps = {
    ratio: number;
    colorMode: NativeColorMode;
};

const NativeFlexMeta = styled(NativeFlex)`
    ${({ colorMode }: any) =>
        css`
            .extra_post_data_value {
                width: auto;
                color: ${getNativeColor(theme, colorMode, 'secondary')};
            }
        `}
`;

const StyledPost = styled(NativeFlex)`
    ${(props: NativePostProps) => baseStyles(props.options)}
    ${({ ratio }: StyledPostProps) => getNativeMediaOverrides(ratio > 2.3 ? 40 : 60, 60)}

    ${({ colorMode }: any) =>
        css`
            ${getEntityStyles(getNativeColor(theme, colorMode, 'link'))}
            ${StyledUserName} {
                color: ${getNativeColor(theme, colorMode, 'userName')};
            }
            ${StyledSecondaryName} {
                color: ${getNativeColor(theme, colorMode, 'secondary')};
            }
            ${StyledTimeStamp} {
                color: ${getNativeColor(theme, colorMode, 'secondary')};
            }
            ${StyledNativeEngagement} {
                ${StyledIcon} {
                    svg {
                        fill: ${getNativeColor(theme, colorMode, 'secondary')};
                    }
                }
                color: ${getNativeColor(theme, colorMode, 'secondary')};
                font-weight: 700;
            }
        `}
`;

const TwitterPost: React.FC<NativePostProps> = ({
    post,
    playerInterval,
    options,
    extraPostDataTags,
}) => {
    const {
        postRef,
        screenName,
        name,
        profileImgUrl,
        verified,
        images,
        videos,
        snCreatedTime,
        snStats,
        unique,
        snType,
        textEntities,
        urlEntities,
        hasMedia,
        text,
        rtl,
        language,
        orientation,
        ratio,
        location,
        retweeter,
        isRetweet,
        quotedMessage,
    } = useNativePost(post);

    const {
        lineClamp,
        maxCharacters,
        colorMode,
        removeUrls,
        emoji,
        hideZero,
        retweetIndicator,
        showLinkPreview,
        showQuotedTweet,
        quotedTweetSize,
    } = options;

    if (!post) {
        return <div key={unique} />;
    }

    const showRetweet = isRetweet && retweetIndicator;
    const showQuoted: boolean = quotedMessage?.message && showQuotedTweet;

    const ProfileAndText = (
        <>
            {(profileImgUrl || name || screenName || snType) && (
                <NativeProfileLockup
                    colorMode={colorMode}
                    verified={verified}
                    name={name}
                    secondary={screenName ? `@${screenName}` : undefined}
                    tertiary={retweetIndicator ? retweeter : undefined}
                    showRetweet={showRetweet}
                    snType={snType}
                    profileImgUrl={profileImgUrl}
                />
            )}
            {text && (
                <NativeText
                    removeUrls={removeUrls}
                    emoji={emoji}
                    colorMode={colorMode}
                    hasMedia={hasMedia}
                    lineClamp={lineClamp}
                    maxCharacters={maxCharacters}
                    channel={snType}
                    text={text}
                    rtl={rtl}
                    language={language}
                    textEntities={textEntities && textEntities.message}
                />
            )}
        </>
    );
    const timeStampAndEngagement = (
        <NativeFlex vertical bottom gap='s'>
            <NativeFlex middle gap='s' noGrow>
                {snCreatedTime && (
                    <StyledTimeStamp colorMode={colorMode}>
                        {getEndianTime(snCreatedTime, 'h:mm a - D MMM YYYY')}
                    </StyledTimeStamp>
                )}
                <StyledLocation colorMode={colorMode} location={location} />
            </NativeFlex>
            <NativeEngagement snStats={snStats} snType={snType} hideZero={hideZero} />
        </NativeFlex>
    );

    return (
        <StyledPost
            key={unique}
            ref={postRef}
            ratio={ratio}
            colorMode={colorMode}
            hasMedia={hasMedia}
            orientation={orientation}
            vertical={orientation === 'vertical'}
            options={options}
            gap
            pad>
            <>
                {orientation === 'vertical' && (
                    <>
                        {ProfileAndText}

                        {hasMedia && (
                            <NativeMedia
                                snType={snType}
                                playerInterval={playerInterval}
                                images={images}
                                videos={videos}
                            />
                        )}

                        {showLinkPreview && (
                            <NativeLinkPreview
                                urlEntity={urlEntities.message[0]}
                                colorMode={colorMode}
                            />
                        )}

                        {showQuoted && (
                            <NativeQuotedTweetPreview
                                quotedTweet={quotedMessage}
                                colorMode={colorMode}
                                quotedTweetSize={quotedTweetSize}
                                orientation={orientation}
                            />
                        )}

                        <NativeFlexMeta bottom>
                            {timeStampAndEngagement}
                            {extraPostDataTags}
                        </NativeFlexMeta>
                    </>
                )}

                {orientation === 'horizontal' && (
                    <>
                        {hasMedia && (
                            <NativeMedia
                                snType={snType}
                                playerInterval={playerInterval}
                                images={images}
                                videos={videos}
                            />
                        )}
                        <StyledMeta between vertical gap>
                            {ProfileAndText}
                            {showLinkPreview && (
                                <NativeLinkPreview
                                    urlEntity={urlEntities.message[0]}
                                    colorMode={colorMode}
                                />
                            )}

                            {showQuoted && (
                                <NativeQuotedTweetPreview
                                    quotedTweet={quotedMessage}
                                    colorMode={colorMode}
                                    quotedTweetSize={quotedTweetSize}
                                    orientation={orientation}
                                />
                            )}

                            <NativeFlexMeta bottom>
                                {timeStampAndEngagement}
                                {extraPostDataTags}
                            </NativeFlexMeta>
                        </StyledMeta>
                    </>
                )}
            </>
        </StyledPost>
    );
};

TwitterPost.displayName = 'TwitterPost';
export default observer(TwitterPost);
