import { action, computed, observable } from 'mobx';
import { AnalyticsEngine, AnalyticsTimePeriod } from '@sprinklr/stories/analytics/AnalyticsRequest';
import { WidgetDataRequest, Widget } from '@sprinklr/stories/widget/Widget';
import MetaInfoService from '../../services/MetaInfoService/MetaInfoService';
import { WidgetFilter } from './Filter/WidgetFilter';
import { WidgetGroupBy } from './GroupBy/WidgetGroupBy';
import { WidgetMetric } from './Metric/WidgetMetric';
import { WidgetSLAPreset } from './SLAPreset/WidgetSLAPreset';
import { WidgetSort } from './Sort/WidgetSort';
import { WidgetTimePeriod } from './TimePeriod/WidgetTimePeriod';
import { WidgetDimensionKey } from './WidgetDimensionKey';
import Dimension from '@sprinklr/stories/analytics/Dimension';
import TimePeriod from '../../models/TimePeriod/TimePeriod';
import { DataSource } from 'models/DataSource/DataSource';

export type WidgetRequestType = '' | 'embed' | 'profiles' | 'analytics' | 'posts' | 'trends';

/**
 * Base class for managing a data request
 *
 * @see WidgetRequestAnalytics
 * @see WidgetRequestPosts
 * @see WidgetRequestEmbed
 * @see WidgetRequestProfiles
 * @see WidgetRequestTrends
 */
export class WidgetRequest {
    @observable
    metrics: WidgetMetric[];

    @observable
    groupBys: WidgetGroupBy[];

    @observable
    slaPreset: WidgetSLAPreset;

    @observable
    timeField: string;

    @observable
    sourceGroup = '';

    // Array of dimension options for the current engine.  ie. LISTENING has Topic Ids and Topic Tags
    @observable
    sourceGroups: Array<{ id: string; name: string }>;

    @observable
    sources: WidgetFilter[];

    @observable
    filters: WidgetFilter[];

    @observable
    sorts: WidgetSort[];

    @observable
    timePeriods: WidgetTimePeriod[];

    @observable
    timePeriod: WidgetTimePeriod;

    metaInfoService: MetaInfoService;

    @observable
    protected request: WidgetDataRequest;

    constructor(metaInfoService: MetaInfoService, request: WidgetDataRequest) {
        this.init(metaInfoService, request);
    }

    public applyGlobalDataSource(dataSource: DataSource) {}

    @computed
    get isIncomplete(): boolean {
        return this.getIsIncomplete();
    }

    @computed
    get sourcesInComplete(): boolean {
        let x;

        if (this.sources) {
            x = this.sources.length;
            while (x--) {
                if (this.sources[x].isIncomplete) {
                    return true;
                }
            }
        }

        return false;
    }

    getRequest(): WidgetDataRequest {
        return this.request;
    }

    getOptions(): any {
        return null;
    }

    getFormatOptions(): any {
        return null;
    }

    get type(): WidgetRequestType {
        return '';
    }

    get limit(): number {
        return -1;
    }

    setLimit(value: number): void {}

    get currency(): string {
        return '';
    }

    setCurrency(currency: string): void {}

    canAddFilter(): boolean {
        return false;
    }

    canDeleteFilter(): boolean {
        return false;
    }

    get isLoading() {
        if (this.filters) {
            let x = this.filters.length;
            while (x--) {
                if (this.filters[x].isLoading) {
                    return true;
                }
            }
        }

        return false;
    }

    get engine(): AnalyticsEngine {
        return null;
    }

    setEngine(value: string, alwaysChange = false): void {}

    setSLAPreset(preset: WidgetSLAPreset): void {}

    setTimeField(timeField: string): void {
        return null;
    }

    setTimePeriod(timePeriods: TimePeriod[]): void {}

    get report(): string {
        return null;
    }

    setReport(value: string): void {}

    setSourceGroup(dimension: string): void {}

    get percentChanged(): boolean {
        return false;
    }

    setPercentChanged(value: boolean): void {}

    get sentiment(): boolean {
        return false;
    }

    setSentiment(value: boolean): void {}

    addMetric(metric?: WidgetMetric): void {}

    setMetric(origMetric: string, metric: any): void {}

    deleteMetric(metric: WidgetMetric): void {}

    getExcludedMetrics(metric: WidgetMetric): string[] {
        return null;
    }

    addGroupBy(dimension?: WidgetGroupBy): void {}

    addSort(sort?: WidgetSort): WidgetSort {
        return null;
    }

    deleteSorts(): void {}

    deleteGroupBy(dimension: WidgetGroupBy): void {}

    getExcludedGroupBys(dimension: WidgetGroupBy): WidgetDimensionKey[] {
        return null;
    }

    addFilter(filter?: WidgetFilter): WidgetFilter {
        return null;
    }

    deleteFilter(filter: WidgetFilter): void {}

    getExcludedFilters(filter: WidgetFilter): WidgetDimensionKey[] {
        return null;
    }

    sync(): void {}

    protected getIsIncomplete(): boolean {
        let x;

        if (this.metrics) {
            if (this.metrics.length > 0 && (!this.timePeriods || this.timePeriods.length === 0)) {
                return true;
            }

            x = this.metrics.length;
            while (x--) {
                if (this.metrics[x].isIncomplete) {
                    return true;
                }
            }
        }

        if (this.groupBys) {
            x = this.groupBys.length;
            while (x--) {
                if (this.groupBys[x].isIncomplete) {
                    return true;
                }
            }
        }

        if (this.sourcesInComplete) {
            return true;
        }

        if (this.filters) {
            x = this.filters.length;
            while (x--) {
                if (this.filters[x].isIncomplete) {
                    return true;
                }
            }
        }

        if (this.timePeriods && this.timePeriods.length > 0) {
            x = this.timePeriods.length;
            while (x--) {
                if (this.timePeriods[x].isIncomplete) {
                    return true;
                }
            }
        }

        return false;
    }

    protected createDataIfNeeded(request: WidgetDataRequest) {}

    @action
    private init(metaInfoService: MetaInfoService, request: WidgetDataRequest) {
        this.request = request;
        this.metaInfoService = metaInfoService;
    }

    /**
     * Clear out the filters, including any filters on the wrapped request.
     */
    @action
    public clearFilters() {
        if (this.filters) {
            this.filters.length = 0;
        }

        if (this.request) {
            const filters = (this.request as any).filters;
            if (filters && filters.length) {
                filters.length = 0;
            }
        }
    }
}

export const getNormalizedTimePeriod = (template: Widget): TimePeriod[] => {
    if (template) {
        if (template.analyticsRequests && template.analyticsRequests.length) {
            const request = template.analyticsRequests[0];
            return request && (request as any).json && (request as any).json.timePeriods;
        } else if (template.postRequests && template.postRequests.length) {
            const request = template.postRequests[0];
            const timePeriod =
                request &&
                request.sources &&
                request.sources[0] &&
                (request.sources[0] as any).id &&
                (request.sources[0] as any).id.json &&
                (request.sources[0] as any).id.json.timePeriod;
            return timePeriod && [timePeriod];
        } else if (template.profileRequests && template.profileRequests.length) {
            const request = template.profileRequests[0];
            const timePeriod = request && (request as any).json && (request as any).json.timePeriod;
            return timePeriod && [timePeriod];
        }
    }
};

export const setWidgetTimePeriods = action((widget: Widget, timePeriods: WidgetTimePeriod[]) => {
    if (widget) {
        if (widget.analyticsRequests?.length) {
            timePeriods.forEach((period, offset) => {
                if (widget.analyticsRequests[0].timePeriods[offset]) {
                    period.copyTo(widget.analyticsRequests[0].timePeriods[offset] as any);
                }
            });
        } else if ((widget.postRequests?.[0]?.sources?.[0] as any)?.id?.timePeriod) {
            if (!timePeriods.length) {
                timePeriods.push(new WidgetTimePeriod(null));
            }
            timePeriods[0].copyTo((widget.postRequests[0].sources[0] as any).id.timePeriod);
        } else if (widget.profileRequests?.[0]?.timePeriod) {
            if (!timePeriods.length) {
                timePeriods.push(new WidgetTimePeriod(null));
            }
            timePeriods[0].copyTo(widget.profileRequests[0].timePeriod as any);
        }
    }
});

export const setRequestTimePeriod = action((request, timePeriodField, value) => {
    if (request.timePeriods) {
        request.timePeriods.forEach((timePeriod: any) => {
            if (timePeriod instanceof WidgetTimePeriod) {
                timePeriod.set(timePeriodField, value);
            } else {
                timePeriod[timePeriodField] = value;
            }
        });
    } else if (
        request.sources &&
        request.sources[0] &&
        (request.sources[0] as any).id &&
        (request.sources[0] as any).id.json &&
        (request.sources[0] as any).id.json.timePeriod
    ) {
        (request.sources[0] as any).id.json.timePeriod[timePeriodField] = value;
    } else {
        if (request.timePeriod) {
            if (request.timePeriod instanceof WidgetTimePeriod) {
                request.timePeriod.set(timePeriodField, value);
            } else {
                (request.timePeriod as any)[timePeriodField] = value;
            }
        }
    }
});
