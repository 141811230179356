import * as React from 'react';
import i18n from 'i18next';
import {
    adsTunables,
    engagementTunables,
    headerTunables,
    imageAdjustmentTunables,
    imagePostTunables,
    nativeStylingTunables,
    onChangeComposition,
    opacityTunables,
    postTitleTunables,
    profanityTunables,
    reviewPostTunables,
    smartSizeTunables,
    textEntityTunables,
    textPostTunables,
    userGroupTunables,
} from 'components/Sidebar/RenderTunables/SharedTunables';
import { CollapseProps, TunablesCollapse } from 'components/Sidebar/RenderTunables/RenderTunables';
import {
    endianOptions,
    postCompositionConfigSinglePost,
} from 'components/Panel/PanelEditorSidebar/PanelDesign/PanelDesignOptions';
import { Widget } from '@sprinklr/stories/widget/Widget';
import { PostsWidgetSingleOptionsImpl } from 'src/widgets/PostsWindowWidget/SinglePost/options';
import LabelGroup from 'components/_UI/Forms/LabelGroup/LabelGroup';
import ActionSegmentedGroup from 'components/_UI/Forms/Actions/ActionSegmentedGroup';
import ActionColorSwatchGroup from 'components/_UI/Forms/Actions/ActionColorSwatchGroup';
import ActionSliderGroup from 'components/_UI/Forms/Actions/ActionSliderGroup';
import { Text } from 'components/spaceKit';
import ActionCheckboxGroup from 'components/_UI/Forms/Actions/ActionCheckboxGroup';
import ActionCheckboxSliderGroup from 'components/_UI/Forms/Actions/ActionCheckboxSliderGroup';
import ActionSelectGroup from 'components/_UI/Forms/Actions/ActionSelectGroup';
import { Theme } from 'models/Theme/Theme';
import { PostCardWidgetOptions } from '../../PostsWidget/options';

const singlePostCompositions = [
    { value: 'a', label: 'Default', icon: 'singlePost_a' },
    { value: 'b', label: 'Alternate', icon: 'singlePost_b' },
    { value: 'c', label: 'Special', icon: 'singlePost_c' },
];

export const getNonNativeTunables = (widget, mergedTheme) => {
    const isNative =
        (widget.options as PostCardWidgetOptions).nativeStyling &&
        (widget.options as PostCardWidgetOptions).nativeStyling.enabled;
    if (!isNative) {
        return [
            {
                props: {
                    label: i18n.t('Post Composition'),
                    startOpen: true,
                } as CollapseProps,
                children: [
                    <ActionSegmentedGroup
                        key='widget.options.postCompositionType'
                        label={i18n.t('Composition')}
                        option={widget.options}
                        value='postCompositionType'
                        options={singlePostCompositions}
                        handleSideEffects={selection =>
                            onChangeComposition(widget, selection, postCompositionConfigSinglePost)
                        }
                    />,
                ],
            },
            postTitleTunables(widget, mergedTheme),
            textPostTunables(widget, mergedTheme),
            imagePostTunables(widget, mergedTheme),
            reviewPostTunables(widget, mergedTheme),
            {
                props: {
                    label: i18n.t('Authenticated Account Comments'),
                    startOpen: true,
                    isEnabled: (widget.options as PostsWidgetSingleOptionsImpl).comments.enabled,
                    option: (widget.options as PostsWidgetSingleOptionsImpl).comments,
                    value: 'enabled',
                } as CollapseProps,
                children: [
                    <LabelGroup
                        label={i18n.t('Back')}
                        key='widget.options.comments.background.color'>
                        <ActionColorSwatchGroup
                            option={
                                (widget.options as PostsWidgetSingleOptionsImpl).comments.background
                            }
                            value='color'
                        />
                    </LabelGroup>,

                    <ActionSliderGroup
                        key='widget.options.maxItems'
                        label={i18n.t('Comment Duration')}
                        option={(widget.options as PostsWidgetSingleOptionsImpl).comments.animation}
                        value='duration'
                        showValue
                        suffix='sec'
                        min={0}
                        max={50}
                    />,

                    <Text.TextSemiBold13 key='Individual Comment header'>
                        {i18n.t('Individual Comment')}
                    </Text.TextSemiBold13>,

                    <LabelGroup
                        label={i18n.t('Text')}
                        key='widget.options.comments.comment.text.color'>
                        <ActionColorSwatchGroup
                            option={
                                (widget.options as PostsWidgetSingleOptionsImpl).comments.comment
                                    .text
                            }
                            value='color'
                        />
                    </LabelGroup>,

                    <LabelGroup
                        label={i18n.t('Back')}
                        key='widget.options.comments.comment.background.color'>
                        <ActionColorSwatchGroup
                            option={
                                (widget.options as PostsWidgetSingleOptionsImpl).comments.comment
                                    .background
                            }
                            value='color'
                        />
                    </LabelGroup>,

                    <ActionCheckboxGroup
                        key='widget.options.showYAxis'
                        label={i18n.t('Brand Comments Match')}
                        option={
                            (widget.options as PostsWidgetSingleOptionsImpl).comments.comment
                                .background
                        }
                        value='brandEnabled'
                    />,
                    <ActionCheckboxSliderGroup
                        key='widget.options.comments.comment.text.scale.enabled'
                        label={i18n.t('Scale Text')}
                        showValue
                        checkbox={{
                            option: (widget.options as PostsWidgetSingleOptionsImpl).comments
                                .comment.text.scale,
                            value: 'enabled',
                        }}
                        slider={{
                            option: (widget.options as PostsWidgetSingleOptionsImpl).comments
                                .comment.text.scale,
                            value: 'value',
                            min: 0,
                            max: 100,
                        }}
                    />,
                    <ActionCheckboxSliderGroup
                        key='widget.options.comments.comment.userGroup.timeStamp'
                        label={i18n.t('Time Stamp')}
                        showValue
                        checkbox={{
                            option: (widget.options as PostsWidgetSingleOptionsImpl).comments
                                .comment.userGroup.timeStamp,
                            value: 'enabled',
                        }}
                        slider={{
                            option: (widget.options as PostsWidgetSingleOptionsImpl).comments
                                .comment.userGroup.timeStamp,
                            value: 'size',
                            min: 0,
                            max: 15,
                        }}
                    />,
                    (widget.options as PostsWidgetSingleOptionsImpl).comments.comment.userGroup
                        .timeStamp.enabled && (
                        <ActionSelectGroup
                            label={i18n.t('Orientation')}
                            key='widget.options.comments.comment.userGroup.timeStamp.endianTime'
                            option={
                                (widget.options as PostsWidgetSingleOptionsImpl).comments.comment
                                    .userGroup.timeStamp
                            }
                            value='endianTime'
                            options={endianOptions}
                        />
                    ),
                ],
            },
            imageAdjustmentTunables(widget),
            userGroupTunables(widget),
            engagementTunables(widget),
            adsTunables(widget),
            textEntityTunables(widget),
            profanityTunables(widget),
        ];
    } else {
        return [];
    }
};

const SinglePostTunables = (widget: Widget, mergedTheme: Theme): TunablesCollapse[] => [
    {
        props: {
            label: i18n.t('Single Post'),
            startOpen: true,
        } as CollapseProps,
        children: [...headerTunables(widget), opacityTunables(widget), smartSizeTunables(widget)],
    },
    nativeStylingTunables(widget),
    ...getNonNativeTunables(widget, mergedTheme),
];

export default SinglePostTunables;
