import { Mapper } from '../DataStore/DataStore';
import { GraphQLService } from '../GraphQLService/GraphQLService';
import { GraphQLQueries, ModelName } from '../GraphQLQueries/GraphQLQueries';
import { BaseRecordService } from 'utils/BaseRecordService/BaseRecordService';
import { LayoutCustom } from 'models/Layout/LayoutCustom';
import { EnvironmentConfig } from '../../config';

const MODELNAME: ModelName = 'layoutCustom';
/**
 * Created by dstelter on 12/13/16.
 */
export class LayoutCustomService extends BaseRecordService<LayoutCustom> {
    private queries: GraphQLQueries;
    private config: EnvironmentConfig;

    constructor(graphQL: GraphQLService, mapper: Mapper<LayoutCustom>, config: EnvironmentConfig) {
        super(graphQL, mapper);
        this.queries = new GraphQLQueries();
        this.config = config;
    }

    findAll(query?: any): Promise<Array<LayoutCustom>> {
        query = query || {};

        const queryParams = {
            query: this.queries.clientLayoutsCustomQuery,
            variables: query,
        };

        const extractor = result => {
            return result.client.layoutsCustom;
        };

        return this.graphQL
            .query(queryParams)
            .then(extractor)
            .then(result => this.mapper.createMany(result))
            .then(result => result.filter(layout => !layout.deleted));
    }

    create(layoutCustom: LayoutCustom): Promise<LayoutCustom> {
        return this.mutate(
            this.queries.mutationDescriptor('createCustomLayout', MODELNAME, {
                layoutCustom: layoutCustom,
            })
        );
    }

    rename(layoutCustom: LayoutCustom | string, name: string): Promise<LayoutCustom> {
        return this.mutate(
            this.queries.mutationDescriptor('renameCustomLayout', MODELNAME, {
                layoutId: this.layoutId(layoutCustom),
                name: name,
            })
        );
    }

    destroy(id: string): Promise<any> {
        return this.mutate(
            this.queries.mutationDescriptor('deleteCustomLayout', MODELNAME, { id: id })
        );
    }
}
