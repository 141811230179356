import { AnalyticsGroupBy } from '@sprinklr/stories/analytics/AnalyticsRequest';

/**
 * Known/Default Listening Group Bys
 */
export default class ListeningDimensions {
    static minute: AnalyticsGroupBy = {
        heading: 'Created Time_1m',
        dimensionName: 'SN_CREATED_TIME',
        groupType: 'DATE_HISTOGRAM',
        details: {
            interval: '1m',
        },
    };

    static hour: AnalyticsGroupBy = {
        heading: 'Created Time_1h',
        dimensionName: 'SN_CREATED_TIME',
        groupType: 'DATE_HISTOGRAM',
        details: {
            interval: '1h',
        },
    };

    static timeOfDay: AnalyticsGroupBy = {
        heading: 'TIME_OF_DAY',
        dimensionName: 'TIME_OF_DAY',
        groupType: 'FIELD',
    };

    static day: AnalyticsGroupBy = {
        heading: 'Created Time_1d',
        dimensionName: 'SN_CREATED_TIME',
        groupType: 'DATE_HISTOGRAM',
        details: {
            interval: '1d',
        },
    };

    static dayOfWeek: AnalyticsGroupBy = {
        heading: 'DAY_OF_WEEK',
        dimensionName: 'DAY_OF_WEEK',
        groupType: 'FIELD',
    };

    static week: AnalyticsGroupBy = {
        heading: 'Created Time_1w',
        dimensionName: 'SN_CREATED_TIME',
        groupType: 'DATE_HISTOGRAM',
        details: {
            interval: '1w',
        },
    };

    static month: AnalyticsGroupBy = {
        heading: 'Created Time_1M',
        dimensionName: 'SN_CREATED_TIME',
        groupType: 'DATE_HISTOGRAM',
        details: {
            interval: '1M',
        },
    };

    static sentiment: AnalyticsGroupBy = {
        heading: 'SEM_SENTIMENT',
        dimensionName: 'SEM_SENTIMENT',
        groupType: 'FIELD',
    };

    static topicId: AnalyticsGroupBy = {
        heading: 'TOPIC_IDS',
        dimensionName: 'TOPIC_IDS',
        groupType: 'FIELD',
    };

    static topicName: AnalyticsGroupBy = {
        heading: 'TOPIC_NAME',
        dimensionName: 'TOPIC_IDS',
        groupType: 'FIELD',
    };

    static topicGroupId: AnalyticsGroupBy = {
        heading: 'TOPIC_GROUP_IDS',
        dimensionName: 'TOPIC_GROUP_IDS',
        groupType: 'FIELD',
    };

    static socialNetwork: AnalyticsGroupBy = {
        heading: 'LISTENING_MEDIA_TYPE',
        dimensionName: 'LISTENING_MEDIA_TYPE',
        groupType: 'FIELD',
    };

    static wordCloudMessage: AnalyticsGroupBy = {
        heading: 'WORD_CLOUD_MESSAGE',
        dimensionName: 'WORD_CLOUD_MESSAGE',
        groupType: 'FIELD',
    };

    static emoticon: AnalyticsGroupBy = {
        heading: 'Emoticon',
        dimensionName: 'EMOTICONS',
        groupType: 'FIELD',
    };

    static topicCluster: AnalyticsGroupBy = {
        heading: 'TOPIC_CLUSTER',
        dimensionName: 'TOPIC_CLUSTER',
        groupType: 'FIELD',
    };
    static topicClusterMessage: AnalyticsGroupBy = {
        heading: 'MESSAGE_TOPIC_CLUSTER',
        dimensionName: 'MESSAGE_TOPIC_CLUSTER',
        groupType: 'FIELD',
    };
    static verifiedUser: AnalyticsGroupBy = {
        heading: 'VERIFIED_USER',
        dimensionName: 'VERIFIED_USER',
        groupType: 'FIELD',
    };

    static gender: AnalyticsGroupBy = {
        heading: 'GENDER',
        dimensionName: 'GENDER',
        groupType: 'FIELD',
    };

    static city: AnalyticsGroupBy = {
        heading: 'CITY',
        dimensionName: 'CITY',
        groupType: 'FIELD',
    };

    static usState: AnalyticsGroupBy = {
        heading: 'US_STATE',
        dimensionName: 'US_STATE',
        groupType: 'FIELD',
    };

    static country: AnalyticsGroupBy = {
        heading: 'COUNTRY',
        dimensionName: 'COUNTRY',
        groupType: 'FIELD',
    };

    static photoInnapropriate: AnalyticsGroupBy = {
        heading: 'PHOTO_INAPPROPRIATE_CONTENT',
        dimensionName: 'PHOTO_INAPPROPRIATE_CONTENT',
        groupType: 'FIELD',
    };

    static partnerProfileLists: AnalyticsGroupBy = {
        heading: 'PARTNER_PROFILE_LISTS',
        dimensionName: 'PARTNER_PROFILE_LISTS',
        groupType: 'FIELD',
    };
}
