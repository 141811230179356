import BulkItem from './BulkItem';

export default class AccountItem extends BulkItem {
    accountId: string;
    displayName: string;
    screenName: string;
    snType: string;
    accountUrl: string;
    profileImgUrl: string;

    constructor(
        id: string,
        accountId: string,
        displayName: string,
        screenName: string,
        snType: string,
        accountUrl: string,
        profileImgUrl: string
    ) {
        super(id);

        this.accountId = accountId;
        this.displayName = displayName;
        this.screenName = screenName;
        this.snType = snType;
        this.accountUrl = accountUrl;
        this.profileImgUrl = profileImgUrl;
    }

    public static fromObject(object: any) {
        let allProps;
        if ('WEB' === object.snType) {
            allProps = ['accountId', 'screenName', 'snType'].every(prop =>
                object.hasOwnProperty(prop)
            );
            if (allProps && !object.displayName) {
                object.displayName = object.screenName;
            }
        } else if (object.snType === 'GOOGLE') {
            allProps = ['id', 'accountId', 'screenName', 'snType', 'accountUrl'].every(prop =>
                object.hasOwnProperty(prop)
            );
        } else {
            allProps = ['accountId', 'screenName', 'snType'].every(prop =>
                object.hasOwnProperty(prop)
            );
        }

        if (allProps) {
            return new AccountItem(
                object.id,
                object.accountId,
                object.displayName,
                object.screenName,
                object.snType,
                object.accountUrl,
                object.profileImgUrl
            );
        }

        return null;
    }

    toString() {
        if (this.displayName) {
            return this.displayName;
        }

        if (this.screenName) {
            return this.screenName;
        }

        return this.snType;
    }

    get sortValue() {
        return this.toString();
    }
}
