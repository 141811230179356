import { Partner } from '../../models/Partner/Partner';
import { BaseRecordService } from '../../utils/BaseRecordService/BaseRecordService';
import { GraphQLQueries, ModelName } from '../GraphQLQueries/GraphQLQueries';
import { GraphQLService } from '../GraphQLService/GraphQLService';
import { Mapper } from '../DataStore/DataStore';

const MODELNAME: ModelName = 'partner';

export class PartnerService extends BaseRecordService<Partner> {
    private queries: GraphQLQueries;

    constructor(graphQL: GraphQLService, mapper: Mapper<Partner>) {
        super(graphQL, mapper);
        this.queries = new GraphQLQueries();
    }

    find(): Promise<Partner> {
        return this.query(this.queries.queryDescriptor('partner', MODELNAME, {}));
    }
}
