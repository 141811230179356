import * as React from 'react';
import i18n from 'i18next';
import {
    headerTunables,
    opacityTunables,
    zoomTunables,
    smartSizeTunables,
} from 'components/Sidebar/RenderTunables/SharedTunables';
import { TunablesCollapse, CollapseProps } from 'components/Sidebar/RenderTunables/RenderTunables';
import { Widget } from '@sprinklr/stories/widget/Widget';
import ActionCheckboxGroup from '../../components/_UI/Forms/Actions/ActionCheckboxGroup';
import ActionSliderGroup from '../../components/_UI/Forms/Actions/ActionSliderGroup';
import ActionSelectGroup from '../../components/_UI/Forms/Actions/ActionSelectGroup';
import ActionCheckboxSliderGroup from '../../components/_UI/Forms/Actions/ActionCheckboxSliderGroup';
import ActionSegmentedGroup from '../../components/_UI/Forms/Actions/ActionSegmentedGroup';
import { FunnelChartWidgetOptionsImpl } from './options';
import {
    metricValueCaseOptions,
    metricValueOptions,
} from 'components/Panel/PanelEditorSidebar/PanelDesign/PanelDesignOptions';

const FunnelChartTunables = (widget: Widget): TunablesCollapse[] => [
    {
        props: {
            label: i18n.t('Funnel Chart'),
            startOpen: true,
        } as CollapseProps,
        children: [
            ...headerTunables(widget, { header: true }),
            opacityTunables(widget),
            smartSizeTunables(widget),
        ],
    },
    {
        props: {
            label: i18n.t('Labels'),
            startOpen: true,
        } as CollapseProps,
        children: [
            <ActionSliderGroup
                key='widget.options.labelSpacing'
                label={i18n.t('Spacing')}
                option={widget.options}
                value='labelSpacing'
                showValue
                min={0}
                max={100}
            />,
            <ActionCheckboxSliderGroup
                key='widget.options.metric.name'
                label={i18n.t('Metric Name')}
                showValue
                checkbox={{
                    option: (widget.options as FunnelChartWidgetOptionsImpl).metric.name,
                    value: 'enabled',
                }}
                slider={{
                    option: (widget.options as FunnelChartWidgetOptionsImpl).metric.name,
                    value: 'size',
                    min: 0,
                    max: 100,
                }}
            />,
            <ActionCheckboxSliderGroup
                key='widget.options.metric.value'
                label={i18n.t('Metric Value')}
                showValue
                checkbox={{
                    option: (widget.options as FunnelChartWidgetOptionsImpl).metric.value,
                    value: 'enabled',
                }}
                slider={{
                    option: (widget.options as FunnelChartWidgetOptionsImpl).metric.value,
                    value: 'size',
                    min: 0,
                    max: 100,
                }}
            />,
            <ActionSegmentedGroup
                between
                key='widget.options.metricValueFormat'
                label={i18n.t('Format')}
                option={(widget.options as FunnelChartWidgetOptionsImpl).metric.value}
                value={'format'}
                options={metricValueOptions}
                showOptionLabel
            />,
            (widget.options as FunnelChartWidgetOptionsImpl).metric.value.case &&
                (widget.options as FunnelChartWidgetOptionsImpl).metric.value.format !==
                    '1,234' && (
                    <ActionSelectGroup
                        label={i18n.t('Unit Case')}
                        key='widget.options.metricValueCase'
                        option={(widget.options as FunnelChartWidgetOptionsImpl).metric.value}
                        value='case'
                        options={metricValueCaseOptions}
                    />
                ),
        ],
    },
    {
        props: {
            label: i18n.t('Tunables'),
            startOpen: true,
        } as CollapseProps,
        children: [
            <ActionCheckboxGroup
                key='widget.options.sortByVolume'
                label={i18n.t('Sort by volume')}
                option={widget.options}
                value='sortByVolume'
            />,
            <ActionSelectGroup
                key='widget.options.layoutType'
                label={i18n.t('Funnel Type')}
                options={[
                    {
                        label: i18n.t('Separated'),
                        value: 'bar',
                    },
                    {
                        label: i18n.t('Stacked'),
                        value: 'stacked',
                    },
                ]}
                option={widget.options}
                value='layoutType'
            />,
            (widget.options as FunnelChartWidgetOptionsImpl).layoutType === 'bar' && [
                <ActionSliderGroup
                    key='widget.options.borderRadius'
                    label={i18n.t('Border Radius')}
                    option={widget.options}
                    value='borderRadius'
                    showValue
                    min={0}
                    max={100}
                />,
                <ActionSliderGroup
                    key='widget.options.spacing'
                    label={i18n.t('Bar Spacing')}
                    option={widget.options}
                    value='spacing'
                    showValue
                    min={0}
                    max={100}
                />,
            ],
        ],
    },
    zoomTunables(widget, { zoom: true, clip: true }),
];

export default FunnelChartTunables;
