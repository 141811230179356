import ListeningMetrics from '@sprinklr/stories-services/AnalyticsService/ListeningMetrics';
import { AnalyticsRequest, GroupBy, Sort, Metric } from './PanelTemplatesTheme/PanelTemplatesTheme';
import { DefaultPosition } from './PanelTemplatesTheme/constants';
import { Panel } from '../../models/Panel/Panel';
import { WIDGET_LABEL_SIZE } from '../../components/Widget/WidgetLabel/constants';
import ListeningDimensions from '@sprinklr/stories-services/AnalyticsService/ListeningDimensions';

export var TemplateBubblePlotChart: Panel = {
    id: 'bubble-plot',
    name: 'Bubble Plot Chart',
    description: '',
    tags: ['Standard', 'Bubble', 'landscape', 'portrait'],
    widget: {
        id: '1',
        position: {
            width: '1920px',
            height: '1080px',
            top: '0',
            left: '0',
        },
        classes: 'template_axis_bubble_chart',
        children: [
            {
                type: 'bubblePlotChart',
                id: '1',
                name: 'Bubble Plot Chart',
                useGlobalTimePeriod: true,
                analyticsRequests: [
                    // Note: All global variables like reportingEngine, report, filters, and projections
                    // are injected via AnalyticsRequest.getRequests()
                    new AnalyticsRequest({
                        limit: 2000,
                        groupBys: [
                            new GroupBy({
                                LISTENING: { source: ListeningDimensions.day },
                                INBOUND_MESSAGE: {
                                    source: ListeningDimensions.day,
                                    dimensionName: 'snCTm',
                                },
                                PLATFORM: {
                                    source: ListeningDimensions.day,
                                    dimensionName: 'date',
                                },
                                BENCHMARKING: {
                                    dimensionName: 'DAY_OF_WEEK',
                                    limitType: 'TOP',
                                    limit: 8,
                                },
                                PAID: {
                                    source: ListeningDimensions.day,
                                    dimensionName: 'measurementTime',
                                },
                                ADVOCACY: {
                                    source: ListeningDimensions.day,
                                    dimensionName: 'date',
                                },
                                RDB_FIREHOSE: {
                                    source: ListeningDimensions.day,
                                    dimensionName: 'SN_CREATED_TIME',
                                },
                                STORY_MESSAGE: {
                                    dimensionName: 'COUNTRY',
                                    limitType: 'NONE',
                                },
                            }),
                            new GroupBy({
                                LISTENING: {
                                    source: ListeningDimensions.topicName,
                                    limitType: 'TOP',
                                    limit: 8,
                                },
                                INBOUND_MESSAGE: {
                                    dimensionName: 'ACCOUNT_ID',
                                    limitType: 'TOP',
                                    limit: 8,
                                },
                                PLATFORM: {
                                    dimensionName: 'ACCOUNT_ID',
                                    limitType: 'TOP',
                                    limit: 8,
                                },
                                BENCHMARKING: {
                                    dimensionName: 'BRAND_ID',
                                    limitType: 'TOP',
                                    limit: 8,
                                },
                                PAID: { dimensionName: 'accountIds', limitType: 'TOP', limit: 8 },
                                ADVOCACY: {
                                    dimensionName: 'AUDIENCE_ACTIVITY_TYPE',
                                    limitType: 'TOP',
                                    limit: 8,
                                },
                                RDB_FIREHOSE: {
                                    dimensionName: 'RDB_QUERY_IDS',
                                    limitType: 'TOP',
                                    limit: 8,
                                },
                                STORY_MESSAGE: { dimensionName: 'PUBLICATION_NAME' },
                            }),
                        ],
                        projections: [
                            new Metric({
                                LISTENING: { source: ListeningMetrics.mentions },
                                INBOUND_MESSAGE: { dimensionName: 'OUTBOUND_COUNT' },
                                PLATFORM: { dimensionName: 'ENGAGEMENT_RATE' },
                                BENCHMARKING: { dimensionName: 'ENGAGEMENT_RATE' },
                                PAID: { dimensionName: 'impressions' },
                                ADVOCACY: {
                                    dimensionName: 'ADVOCACY_TOTAL_USER_ENGAGEMENTS',
                                    heading: 'Total_Engagements',
                                },
                                RDB_FIREHOSE: {
                                    dimensionName: 'DISTINCT_USER_COUNT',
                                    heading: 'Distinct Users',
                                },
                                STORY_MESSAGE: {
                                    dimensionName: 'NEWS_COUNT',
                                    heading: 'News_Mentions',
                                },
                            }),
                        ],
                        sorts: [new Sort({ order: 'DESC', projections: 0 })],
                        timePeriods: [{ key: 'last_7_days' }, { previousPeriod: true }],
                    }) as any,
                ],
                label: {
                    titleText: 'Bubble Plot Chart',
                    enabled: false,
                    size: WIDGET_LABEL_SIZE.primary,
                },
                position: DefaultPosition,
                options: {
                    player: {
                        playing: true,
                        duration: 15,
                    },
                    maxItems: 10,
                    useThemeColor: false,
                    smartSize: {
                        enabled: true,
                        mode: 'responsive',
                        size: 'xl',
                    },
                    portrait: {
                        widgetOrientation: 'portrait',
                    },
                },
            },
        ],
    },
};
