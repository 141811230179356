import { toJS } from 'mobx';
import ListeningMetrics from '@sprinklr/stories-services/AnalyticsService/ListeningMetrics';
import { AnalyticsEngine } from '@sprinklr/stories/analytics/AnalyticsRequest';
import { inflateTemplate } from './helpers';

export class GlobalMetrics {
    private options: { [key: string]: boolean };

    constructor(options?: { [key: string]: boolean }) {
        this.options = options;
    }

    getJSON(engine: AnalyticsEngine): any {
        switch (engine) {
            case 'BENCHMARKING':
                return [toJS(ListeningMetrics.totalEngagements)];

            case 'INBOUND_MESSAGE':
                return [toJS(ListeningMetrics.inboundCount)];

            case 'LISTENING':
            case 'STORY_MESSAGE':
                if (this.options && this.options.sentiment) {
                    return [
                        toJS(ListeningMetrics.mentions),
                        toJS(ListeningMetrics.positiveMentions),
                        toJS(ListeningMetrics.negativeMentions),
                        toJS(ListeningMetrics.neutralMentions),
                    ];
                } else {
                    return [toJS(ListeningMetrics.mentions)];
                }

            case 'PLATFORM':
                return [toJS(ListeningMetrics.totalEngagements)];

            case 'PAID':
                return [
                    {
                        heading: 'spent',
                        measurementName: 'spent',
                        aggregateFunction: 'SUM',
                        details: {
                            dataType: 'CURRENCY',
                        },
                    },
                ];

            case 'ADVOCACY':
                return [
                    {
                        heading: 'Total_Engagements',
                        measurementName: 'ADVOCACY_TOTAL_USER_ENGAGEMENTS',
                        aggregateFunction: 'SUM',
                        details: null,
                    },
                ];

            case 'TWITTER':
                return [
                    {
                        heading: 'Mentions',
                        measurementName: 'MENTIONS',
                        aggregateFunction: 'SUM',
                    },
                ];

            case 'RDB_FIREHOSE':
                return [
                    {
                        heading: 'Mentions',
                        measurementName: 'MENTIONS_COUNT',
                        aggregateFunction: 'SUM',
                    },
                ];

            default:
                return [
                    {
                        heading: '',
                        measurementName: '',
                        aggregateFunction: 'SUM',
                    },
                ];
        }
    }
}

export class AnalyticsRequest {
    private json: any;
    private options: { [key: string]: boolean };

    constructor(json: any, options?: { [key: string]: boolean }) {
        this.json = json;
        this.options = options;
    }

    getJSON(engine: AnalyticsEngine, source: any, json: any, isPresentations: boolean): any {
        const result: any = {};

        result.reportingEngine = engine;
        if (!this.json.projections) {
            result.projections = new GlobalMetrics(this.options).getJSON(engine);

            if (engine === 'PAID') {
                result.additional = {
                    Currency: 'USD',
                };
            }
        }

        switch (engine) {
            case 'BENCHMARKING':
                result.report = 'POST_STATS_LIFETIME';
                break;

            case 'INBOUND_MESSAGE':
                result.report = 'INBOUND_MESSAGE';
                break;

            case 'LISTENING':
                result.report = 'SPRINKSIGHTS';
                break;

            case 'PLATFORM':
                result.report = 'POST_INSIGHTS';
                break;

            case 'PAID':
                result.report = 'DAILY_AD_STAT';
                break;

            case 'ADVOCACY':
                result.report = 'ADVOCACY_ACTIVITY';
                break;

            case 'TWITTER':
                result.report = 'TRENDS';
                break;

            case 'RDB_FIREHOSE':
                result.report = 'RDB_FIREHOSE';
                break;

            case 'STORY_MESSAGE':
                result.report = 'STORY_MESSAGE';
                break;

            // case "UNIFIED_ANALYTICS_REPORTING_ENGINE":
            //     result.report = "";
            //     break;
        }

        inflateTemplate(this.json, engine, null, result, isPresentations);

        result.filters = (source && source.filters) || [];

        let x = result.filters.length;
        while (x--) {
            result.filters[x] = toJS(result.filters[x]);
        }

        if (result.timePeriods) {
            result.timePeriods.forEach((timePeriod: any) => {
                if (!timePeriod.timeZone) {
                    timePeriod.timeZone = 'America/Los_Angeles';
                }
            });
        }

        if (result.timePeriod) {
            if (!result.timePeriod.timeZone) {
                result.timePeriod.timeZone = 'America/Los_Angeles';
            }
        }

        return result;
    }
}

export class PostSource {
    private json: any;

    constructor(json: any) {
        this.json = json;
    }

    getSource(engine: string): string {
        if (engine === 'MONITORING_DASHBOARD') {
            return 'LISTENING_COLUMN';
        } else if (engine === 'CAMPAIGN') {
            return 'CAMPAIGN';
        } else {
            return 'REPORTING';
        }
    }

    setFilters(filters: any[]) {
        this.json.filters = filters;
    }

    setSorts(sorts: any[]) {
        this.json.sorts = sorts;
    }

    getJSON(engine: string, source: any, json: any, isPresentations: boolean): any {
        const result: any = {
            columnType: null,
            columnId: null,
            reportingEngine: null,
            report: null,
            projections: null,
            sorts: null,
            filters: null,
            timePeriods: null,
        };

        result.source = this.getSource(engine);
        if (engine !== 'MONITORING_DASHBOARD') {
            result.reportingEngine = engine;
        }

        switch (engine) {
            case 'MONITORING_DASHBOARD':
                result.columnType = source.columnType;
                result.columnId = source.columnId;
                break;

            case 'BENCHMARKING':
                result.report = 'POST_STATS_LIFETIME';
                break;

            case 'INBOUND_MESSAGE':
                result.report = 'INBOUND_MESSAGE';
                break;

            case 'LISTENING':
                result.report = 'SPRINKSIGHTS';
                break;

            case 'PLATFORM':
                result.report = 'POST_INSIGHTS';
                break;

            case 'PAID':
                result.report = 'DAILY_AD_STAT';
                result.projections = [
                    {
                        heading: 'spent',
                        measurementName: 'spent',
                        aggregateFunction: 'SUM',
                        details: {
                            dataType: 'CURRENCY',
                        },
                    },
                ];
                break;

            case 'CAMPAIGN':
                result.report = 'SPRINKSIGHTS';
                break;

            case 'RDB_FIREHOSE':
                result.report = 'RDB_FIREHOSE';
                break;

            // case "UNIFIED_ANALYTICS_REPORTING_ENGINE":
            //     result.report = "";
            //     break;
        }

        inflateTemplate(this.json, engine, null, result, isPresentations);

        const filters = result.filters;

        result.filters = toJS(source.filters);

        let x = result.filters.length;
        while (x--) {
            result.filters[x] = toJS(result.filters[x]);
        }

        // Append any extra filters we inflated from above
        if (filters) {
            result.filters.push.apply(result.filters, filters);
        }

        if (result.timePeriods) {
            result.timePeriods.forEach((timePeriod: any) => {
                if (!timePeriod.timeZone) {
                    timePeriod.timeZone = 'America/Los_Angeles';
                }
            });
        }

        // Presentations don't want big page sizes.  Corie request.
        if (isPresentations && result.pageSize > 10) {
            result.pageSize = 10;
        }

        return result;
    }
}

export class Metric {
    private mapping: { [engine: string]: {} };

    constructor(mapping: { [engine: string]: {} }) {
        this.mapping = mapping;
    }

    getJSON(engine: AnalyticsEngine, source: any, json: any): any {
        const metric: any = this.mapping[engine];
        let result: any;

        if (!metric) {
            result = new GlobalMetrics().getJSON(engine)[0];
            return result;
        }

        if (!metric.source && !metric.dimensionName) {
            throw new Error("Metric requires at least 'source' or 'dimensionName'");
        }

        if (metric.source) {
            result = toJS(metric.source);
        } else {
            result = {};
        }

        if (metric.dimensionName) {
            result.measurementName = metric.dimensionName;

            if (engine === 'PAID' && metric.dimensionName === 'spent') {
                result.details = {
                    dataType: 'CURRENCY',
                };
            }
        }

        if (!result.heading) {
            if (metric.heading) {
                result.heading = metric.heading;
            } else {
                // Strip digits and then split by camel case (if present)
                result.heading = result.measurementName
                    .replace(/\d/g, '')
                    .replace(/([a-z])([A-Z])/g, '$1_$2');
            }
        }

        if (!result?.details?.alternateHeading) {
            if (!result?.details) {
                result.details = {
                    alternateHeading: null,
                };
            } else {
                result.details = {
                    ...result.details,
                    alternateHeading: null,
                };
            }
        }

        if (metric.aggregateFunction) {
            result.aggregateFunction = metric.aggregateFunction;
        }

        if (!result.aggregateFunction) {
            result.aggregateFunction = 'SUM';
        }

        // This metric requires its own origReport
        if (metric.origReport !== undefined) {
            if (!result.details) {
                result.details = {};
            }

            result.details.origReport = metric.origReport;
        }

        // This metric requires a different report, so override it here
        if (metric.report !== undefined) {
            json.report = metric.report;
        }

        return result;
    }
}

export class GroupBy {
    private mapping: { [engine: string]: {} };

    constructor(mapping: { [engine: string]: {} }) {
        this.mapping = mapping;
    }

    getJSON(engine: string): any {
        const groupBy: any = this.mapping[engine];
        let result: any;

        // No groupby was defined for this engine, ignore
        if (!groupBy) {
            return undefined;
        }

        if (groupBy && !groupBy.source && !groupBy.dimensionName) {
            throw new Error("GroupBy requires at least 'source' or 'dimensionName'");
        }

        if (groupBy && groupBy.source) {
            result = toJS(groupBy.source);
        } else {
            result = {};
        }

        if (groupBy && groupBy.dimensionName) {
            result.dimensionName = groupBy.dimensionName;
        }

        if (groupBy && groupBy.groupType) {
            result.groupType = groupBy.groupType;
        }

        if (groupBy && groupBy.limitType) {
            result.limitType = groupBy.limitType;
        }

        if (groupBy && groupBy.limit) {
            result.limit = groupBy.limit;
        }

        if (!result.heading) {
            result.heading = result.dimensionName;
        }

        if (!result.groupType) {
            result.groupType = 'FIELD';
        }

        if (!result?.details?.alternateHeading) {
            if (!result?.details) {
                result.details = {
                    alternateHeading: null,
                };
            } else {
                result.details = {
                    ...result.details,
                    alternateHeading: null,
                };
            }
        }

        return result;
    }
}

export class Filter {
    private options: { [key: string]: any };

    constructor(options?: { [key: string]: any }) {
        this.options = options;
    }

    getJSON(engine: string, source: any, json: any): any {
        const options: any = this.options;
        const result: any = {
            dimensionName: null,
            filterType: null,
            values: null,
        };

        result.filterType = 'IN';

        if (options.dimensionName !== undefined) {
            result.dimensionName = options.dimensionName[engine];
        }

        if (options.values !== undefined) {
            result.values = options.values[engine];
        }

        return result;
    }
}

export class Sort {
    private options: { [key: string]: any };

    constructor(options?: { [key: string]: any }) {
        this.options = options;
    }

    getJSON(engine: string, source: any, json: any): any {
        const options: any = this.options;
        const result: any = {
            order: null,
            heading: null,
        };

        result.order = options.order;
        if (
            options.projections !== undefined &&
            json.projections &&
            json.projections[options.projections]
        ) {
            result.heading = json.projections[options.projections].heading;
        } else if (
            options.groupBys !== undefined &&
            json.groupBys &&
            json.groupBys[options.groupBys]
        ) {
            result.heading = json.groupBys[options.groupBys].heading;
        } else if (options.heading !== undefined && options.heading[engine]) {
            result.heading = options.heading[engine];
        }

        return result;
    }
}

export class Label {
    private mapping: { [engine: string]: string };

    constructor(mapping: { [engine: string]: string }) {
        this.mapping = mapping;
    }

    getJSON(engine: string): string {
        return this.mapping[engine];
    }
}
