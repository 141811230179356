import * as React from 'react';
import { action } from 'mobx';
import { observer } from 'mobx-react';
import { SegmentedGroup } from 'components/spaceKit';

type Option = {
    icon?: string | React.ReactElement;
    title?: string;
    label?: string;
    value: string;
};

export type ActionSegmentedGroupProps = {
    value: string;
    option: any;
    disabled?: boolean;
    between?: boolean;
    label?: string;
    showOptionLabel?: boolean;
    options: Option[];
    handleSideEffects?: (selection: any) => void;
};

const ActionSegmentedGroup: React.FC<ActionSegmentedGroupProps> = ({
    value,
    option,
    label,
    options,
    between,
    disabled,
    showOptionLabel,
    handleSideEffects,
}) => {
    const handleOnChange = action((selection: string) => {
        option[value] = selection;
        handleSideEffects && handleSideEffects(selection);
    });

    return (
        <SegmentedGroup
            disabled={disabled}
            between={between}
            label={label}
            options={options}
            showOptionLabel={showOptionLabel}
            activeValue={option[value]}
            onChange={handleOnChange}
        />
    );
};

export default observer(ActionSegmentedGroup);
