import { action, observable, runInAction } from 'mobx';
import moment from 'moment';
import merge from 'deepmerge';
import { overwriteMerge } from 'utils/ArrayUtils/ArrayUtils';
import { getEmptyColorPalette, getEmptyTheme, ThemeItemImpl } from 'models/Theme/Theme';
import {
    AnalyticsGroupBy,
    AnalyticsProjection,
    AnalyticsRequest,
    AnalyticsTimePeriod,
} from '@sprinklr/stories/analytics/AnalyticsRequest';
import { EmbedOptionsImpl } from 'models/Embed/EmbedOptions';
import { SceneImpl, SceneTransition, SceneTransitionImpl } from 'models/Scene/Scene';
import {
    DigitalAssetImpl,
    SocialMediaAssetImpl,
} from 'models/SocialMediaAsset/SocialMediaAssetImpl';
import { UserImpl } from 'models/User/User';
import { ExtImpl, LegacyThemeImpl, WidgetImpl, WidgetLabelImpl } from 'models/Widget/Widget';
import { WidgetOptionsImpl } from 'models/Widget/WidgetOptions';
import ObjectUtils from 'utils/ObjectUtils/ObjectUtils';
import WidgetTypeService from '../WidgetTypes/WidgetTypeService';
import { StoryboardImpl, StoryboardVersionImpl } from 'models/Storyboard/Storyboard';
import { LocationImpl } from 'models/Location/Location';
import { SceneVersionImpl } from 'models/Scene/SceneVersion';
import { PanelImpl } from 'models/Panel/Panel';
import { PanelVersionImpl } from 'models/Panel/PanelVersion';
import { EmbedImpl } from 'models/Embed/Embed';
import { LayoutImpl } from 'models/Layout/Layout';
import { LayoutCustomImpl } from 'models/Layout/LayoutCustom';
import { PartnerImpl } from 'models/Partner/Partner';
import { Completable, Pdf, PdfPage } from 'models/Pdf/Pdf';
import { CommonDocImpl } from 'models/CommonDoc/CommonDoc';
import {
    PageNumberImpl,
    ScenePageNumberImpl,
} from 'components/Storyboard/StoryboardHeaderFooter/PageNumber/options';
import { PageNumber } from 'components/Storyboard/StoryboardHeaderFooter/PageNumber/types';
import { UserAssetImpl } from 'models/UserAsset/UserAsset';
import { PanelPublishResultImpl } from 'models/PublishResult/PanelPublishResult';
import { scrubber } from 'utils/GenerateStyles/GenerateStyles';
import {
    ScheduledStoryboard,
    ScheduledStoryboardImpl,
} from 'models/ScheduledStoryboard/ScheduledStoryboard';
import { Pptx } from 'models/Pptx/Pptx';
import {
    HeaderPropsImpl,
    SceneHeaderPropsImpl,
} from 'components/Storyboard/StoryboardHeaderFooter/Header/options';
import { HeaderModel } from 'components/Storyboard/StoryboardHeaderFooter/Header/types';

/**
 * Created by dstelter on 12/13/16.
 */

class CompletableImpl implements Completable {
    @observable complete?: boolean;
    @observable failure?: boolean;
    @observable errorMessage?: string;
}

export class PdfPageImpl extends CompletableImpl implements PdfPage {
    @observable number?: number;
    @observable panelId?: string;
    @observable panelVersionId?: string;
    @observable creationDuration?: number;
}

export class PdfImpl extends CommonDocImpl implements Pdf {
    @observable storyboardVersionId?: string;
    @observable storyboardId?: string;
    @observable size?: number;
    @observable filename?: string;
    @observable downloadUrl?: string;
    @observable pageCount?: number;
    @observable creationDuration?: number;
    @observable pages?: PdfPageImpl[] = [];
    @observable complete?: boolean;
    @observable failure?: boolean;
    @observable errorMessage?: string;
}

export class PptxImpl extends CommonDocImpl implements Pptx {
    @observable storyboardVersionId?: string;
    @observable storyboardId?: string;
    @observable size?: number;
    @observable filename?: string;
    @observable downloadUrl?: string;
    @observable pageCount?: number;
    @observable creationDuration?: number;
    @observable complete?: boolean;
    @observable failure?: boolean;
    @observable errorMessage?: string;
}

interface ContainsRelation {
    type: any;
    mapper?: string;
    factory?: MappedFieldFactory;
}

type MappedFieldFactory = (data: any, fieldName: string) => any;

interface ContainsRelationMap {
    [name: string]: ContainsRelation;
}

export class Mapper<T> {
    private contains: ContainsRelationMap;
    private mappers: MapperMap;
    private postCreate: (mapper: Mapper<T>, data) => T;
    readonly useDeduplication: boolean;

    /**
     * The config object supports the following properties:
     * contains: a map of related mappers, ex:
     *
     *    contains: {
     *           storyboards: {
     *               type: Array,
     *               mapper: 'storyboard',
     *           },
     *           layout: {
     *               type: Object,
     *               mapper: 'layout',
     *           }
     *       }
     *
     * useDeduplication: default true, if === false disables using deduplication of DataStore, if registered. Has no effect
     *     if a DataStore is not hooked in.
     *
     * @param recordType
     * @param name
     * @param config
     */
    constructor(readonly recordType, readonly name: string, config: any) {
        this.contains = config.contains || {};
        this.useDeduplication = config.useDeduplication !== false;
        // console.log('set up contains relations: ', this.contains);
        this.mappers = {};
    }

    setPostCreate(postCreate: (mapper: Mapper<T>, data) => T) {
        this.postCreate = postCreate;
    }

    createMany(records: any, complete?: (record: T) => void): T[] {
        if (!records) {
            return [];
        }

        // Note the runInAction() here - this is VERY NECESSARY even though we're just calling more
        // actions at this point! Because this lets mobx know we want to compose these into a single transaction,
        // if this isn't done there are sometimes serious performance implications and render thrashing.
        return runInAction(() => records.map(record => this.create(record, complete)));
    }

    @action
    create(data: any, complete?: (record: T) => void): T {
        if (!data) {
            console.error(`Received empty ${this.name}`);
            return data;
        }

        const newInstance: any = this.postCreate ? {} : new this.recordType();

        // Populate the record with mapped, generated or raw values
        Object.keys(data).forEach(fieldName => {
            // Generated from factory
            const fieldFactory: MappedFieldFactory = this.getFieldFactory(fieldName);
            if (fieldFactory) {
                newInstance[fieldName] = fieldFactory(data, fieldName);
                return;
            }

            const value = data[fieldName];

            const relatedMapper: Mapper<any> = this.getRelatedMapper(fieldName);

            if (value === null || value === undefined || !relatedMapper) {
                // Assign raw value
                newInstance[fieldName] = value;
                return;
            }

            // Mapped instances
            if (this.contains[fieldName].type === Array) {
                newInstance[fieldName] = [];

                value.forEach(item => {
                    newInstance[fieldName].push(relatedMapper.create(item));
                });
            } else {
                newInstance[fieldName] = relatedMapper.create(value);
            }
        });

        if (this.postCreate) {
            const postCreateVal = this.postCreate(this, newInstance);
            if (postCreateVal) {
                if (complete) {
                    complete(postCreateVal);
                }

                return postCreateVal;
            }
        }

        if (complete) {
            complete(newInstance);
        }

        return newInstance;
    }

    getRelatedMapper(fieldName: string): Mapper<any> | null {
        if (this.contains[fieldName]) {
            return this.mappers[this.contains[fieldName].mapper];
        }

        return null;
    }

    relateMappers(mappers: any) {
        for (const name in this.contains) {
            // skip non-mapper contains relations, like field constructors
            if (!this.contains[name].mapper) {
                continue;
            }
            const mapperName = this.contains[name].mapper;
            if (mappers[mapperName]) {
                this.mappers[mapperName] = mappers[mapperName];
            }
        }
    }

    private getFieldFactory(name: string): MappedFieldFactory | null {
        if (this.contains[name]) {
            return this.contains[name].factory;
        }

        return null;
    }
}

interface MapperMap {
    [name: string]: Mapper<any>;
}

interface RecordMap {
    [name: string]: any;
}

export class DataStoreFactory {
    /**
     * TODO: this is kinda gross right now, requires setUpMappers() call, which has to be public... make it better.
     * @returns {DataStore}
     */
    create(widgetTypeService: WidgetTypeService): DataStore {
        const ds = new DataStore();

        ds.defineMapper<LocationImpl>(LocationImpl, 'location', {
            contains: {
                storyboards: {
                    type: Array,
                    mapper: 'storyboard',
                },
                activeStoryboard: {
                    type: Object,
                    mapper: 'storyboard',
                },
                layout: {
                    type: Object,
                    mapper: 'layout',
                },
            },
        });

        const themeFactory = (data: any, fieldName: string) => {
            return getEmptyTheme().setValues({ ...data[fieldName] });
        };

        const PageNumberFactory = (data: any, fieldName: string): PageNumber => {
            const pageNumberObject = new PageNumberImpl();
            pageNumberObject.setValues(data[fieldName]);
            return pageNumberObject;
        };

        const scenePageNumberFactory = (data: any, fieldName: string): PageNumber => {
            const pageNumberObject = new ScenePageNumberImpl();
            pageNumberObject.setValues(data[fieldName]);
            return pageNumberObject;
        };

        const HeaderFactory = (data: any, fieldName: string): HeaderModel => {
            const headerObject = new HeaderPropsImpl();
            headerObject.setValues(data[fieldName]);
            return headerObject;
        };

        const sceneHeaderFactory = (data: any, fieldName: string): HeaderModel => {
            const headerObject = new SceneHeaderPropsImpl();
            headerObject.setValues(data[fieldName]);
            return headerObject;
        };

        ds.defineMapper<StoryboardImpl>(StoryboardImpl, 'storyboard', {
            contains: {
                scenes: {
                    type: Array,
                    mapper: 'scene',
                },
                firstScene: {
                    type: Object,
                    mapper: 'scene',
                },
                featuredScene: {
                    type: Object,
                    mapper: 'scene',
                },
                layout: {
                    type: Object,
                    mapper: 'layout',
                },
                activeVersion: {
                    type: Object,
                    mapper: 'storyboardVersion',
                },
                versions: {
                    type: Array,
                    mapper: 'storyboardVersion',
                },
                locations: {
                    type: Array,
                    mapper: 'location',
                },
                theme: {
                    type: Object,
                    factory: themeFactory,
                },
                pageNumber: {
                    type: Object,
                    factory: PageNumberFactory,
                },
                header: {
                    type: Object,
                    factory: HeaderFactory,
                },
            },
        });

        ds.defineMapper<StoryboardVersionImpl>(StoryboardVersionImpl, 'storyboardVersion', {
            contains: {
                scenes: {
                    type: Array,
                    mapper: 'sceneVersion',
                },
                firstScene: {
                    type: Object,
                    mapper: 'sceneVersion',
                },
                featuredScene: {
                    type: Object,
                    mapper: 'sceneVersion',
                },
                layout: {
                    type: Object,
                    mapper: 'layout',
                },
                savedByUser: {
                    type: Object,
                    mapper: 'user',
                },
                theme: {
                    type: Object,
                    factory: themeFactory,
                },
                pageNumber: {
                    type: Object,
                    factory: PageNumberFactory,
                },
                header: {
                    type: Object,
                    factory: HeaderFactory,
                },
            },
        });

        ds.defineMapper<UserImpl>(UserImpl, 'user', {});

        ds.defineMapper<SceneImpl>(SceneImpl, 'scene', {
            contains: {
                panels: {
                    type: Array,
                    mapper: 'panel',
                },
                layout: {
                    type: Object,
                    mapper: 'layout',
                },
                sceneTransition: {
                    type: Object,
                    factory: (data: any, fieldName: string) => {
                        return DataStoreFactory.setDefaultSceneTransition(data);
                    },
                },
                theme: {
                    type: Object,
                    factory: themeFactory,
                },
                header: {
                    type: Object,
                    factory: sceneHeaderFactory,
                },
                pageNumber: {
                    type: Object,
                    factory: scenePageNumberFactory,
                },
            },
        });

        ds.defineMapper<SceneVersionImpl>(SceneVersionImpl, 'sceneVersion', {
            contains: {
                panels: {
                    type: Array,
                    mapper: 'panelVersion',
                },
                storyboardVersion: {
                    type: Object,
                    mapper: 'storyboardVersion',
                },
                layout: {
                    type: Object,
                    mapper: 'layout',
                },
                sceneTransition: {
                    type: Object,
                    factory: (data: any, fieldName: string) => {
                        return DataStoreFactory.setDefaultSceneTransition(data);
                    },
                },
                theme: {
                    type: Object,
                    factory: themeFactory,
                },
                header: {
                    type: Object,
                    factory: sceneHeaderFactory,
                },
                pageNumber: {
                    type: Object,
                    factory: scenePageNumberFactory,
                },
            },
        });

        ds.defineMapper<PanelImpl>(PanelImpl, 'panel', {
            contains: {
                widget: {
                    type: Object,
                    mapper: 'widget',
                },
                theme: {
                    type: Object,
                    factory: themeFactory,
                },
            },
        });

        ds.defineMapper<PanelVersionImpl>(PanelVersionImpl, 'panelVersion', {
            contains: {
                widget: {
                    type: Object,
                    mapper: 'widget',
                },
                theme: {
                    type: Object,
                    factory: themeFactory,
                },
                storyboardVersion: {
                    type: Object,
                    mapper: 'storyboardVersion',
                },
                sceneVersion: {
                    type: Object,
                    mapper: 'sceneVersion',
                },
            },
        });

        ds.defineMapper<PartnerImpl>(PartnerImpl, 'partner', {});

        ds.defineMapper<UserImpl>(UserImpl, 'user', {});

        ds.defineMapper<LayoutImpl>(LayoutImpl, 'layout', {});
        ds.defineMapper<LayoutCustomImpl>(LayoutCustomImpl, 'layoutCustom', {});

        // ds.defineMapper<PartnerImpl>(PartnerImpl, 'partner', {
        //     contains: {
        //         users: {
        //             type: Array,
        //             mapper: 'user',
        //         }
        //     }
        // });

        ds.defineMapper<EmbedImpl>(EmbedImpl, 'embed', {
            contains: {
                options: {
                    type: Object,
                    factory: (data: any, fieldName: string) => {
                        return new EmbedOptionsImpl().setValues(data[fieldName]);
                    },
                },
                postRequests: {
                    type: Array,
                    factory: (data: any, fieldName: string) => {
                        const requests = data.postRequests;

                        requests?.forEach(request => {
                            if (!request.mergeSources) {
                                request.mergeSources = 'date';
                            }
                        });

                        return requests;
                    },
                },
            },
        });

        ds.defineMapper<WidgetImpl>(WidgetImpl, 'widget', {
            useDeduplication: false, // widgets don't have globally-unique IDs
            contains: {
                children: {
                    type: Array,
                    mapper: 'widget',
                },
                theme: {
                    type: Object,
                    factory: (data: any, fieldName: string) => {
                        const theme = getEmptyTheme().setValues({ ...data[fieldName] });
                        const legacyThemeObject = new LegacyThemeImpl();
                        legacyThemeObject.ext = new ExtImpl();
                        legacyThemeObject.setValues(data[fieldName]);

                        return merge.all(
                            [{ ...legacyThemeObject }, theme, scrubber({ ...data[fieldName] })],
                            { arrayMerge: overwriteMerge }
                        );
                    },
                },
                label: {
                    type: Object,
                    factory: (data: any, fieldName: string) => {
                        const labelObject = new WidgetLabelImpl();
                        labelObject.setValues(data[fieldName]);
                        return labelObject;
                    },
                },
                options: {
                    type: Object,
                    factory: (data: any, fieldName: string) => {
                        // some widgets have no type specified, use default options ctor
                        let Options: typeof WidgetOptionsImpl = WidgetOptionsImpl;

                        const widgetType = widgetTypeService.get(data.type);
                        if (widgetType) {
                            const type = widgetType.type;

                            Options = type.optionsType || WidgetOptionsImpl;

                            // Used only by Filmstrip, at the moment, to override formatting options
                            if (type && type.requestOverrides) {
                                type.requestOverrides(data);
                            }
                        }

                        return new Options().setValues(data[fieldName]);
                    },
                },
                analyticsRequests: {
                    type: Array,
                    factory: (data: any, fieldName: string) => {
                        // Check to make sure correct data is defined
                        const requests: AnalyticsRequest[] = data.analyticsRequests;
                        if (requests && requests.length) {
                            let timePeriods = requests[0].timePeriods;
                            if (!timePeriods || !timePeriods[0]) {
                                timePeriods = requests[0].timePeriods = [{}];
                            }

                            this.setGroupBys(requests[0].groupBys);
                            this.setProjections(requests[0].projections);
                            this.setTimePeriod(timePeriods[0]);

                            if (requests[0].includeTotal === undefined) {
                                requests[0].includeTotal = false;
                            }
                        }

                        return requests;
                    },
                },
                postRequests: {
                    type: Array,
                    factory: (data: any, fieldName: string) => {
                        // Check to make sure correct  post data options are defined
                        const requests = data.postRequests;
                        if (requests && requests.length) {
                            const sources = requests[0].sources;
                            if (sources && sources.length) {
                                const source = sources[0];

                                if (source.id) {
                                    if (!source.id.timePeriod) {
                                        source.id.timePeriod = {};
                                    }
                                    if (source.id.showInReplyToPost === undefined) {
                                        source.id.showInReplyToPost = false;
                                    }
                                    if (source.id.useImagePlaceholder === undefined) {
                                        source.id.useImagePlaceholder = false;
                                    }

                                    this.setGroupBys(source.id.groupBys);
                                    this.setProjections(source.id.projections);
                                    this.setTimePeriod(source.id.timePeriod);
                                }

                                if (!source.options) {
                                    source.options = {};
                                }

                                if (source.options.removeDuplicates === undefined) {
                                    source.options.removeDuplicates = false;
                                }

                                if (source.options.shufflePosts === undefined) {
                                    source.options.shufflePosts = false;
                                }

                                if (source.options.includeFaceDetection === undefined) {
                                    source.options.includeFaceDetection = false;
                                }

                                if (source.options.removeSensitivePosts === undefined) {
                                    source.options.removeSensitivePosts = true;
                                }

                                if (source.options.includeQuoteTweets === undefined) {
                                    source.options.includeQuoteTweets = false;
                                }
                            }
                            if (requests[0].showInReplyToPost === undefined) {
                                requests[0].showInReplyToPost = false; // postService.getPosts uses this postRequest
                            }
                        }

                        return requests;
                    },
                },
                profileRequests: {
                    type: Array,
                    factory: (data: any, fieldName: string) => {
                        // Check to make sure correct data is defined
                        const requests = data.profileRequests;
                        if (requests && requests.length) {
                            if (!requests[0].timePeriod) {
                                requests[0].timePeriod = {};
                            }

                            this.setTimePeriod(requests[0].timePeriod);
                        }

                        return requests;
                    },
                },
            },
        });

        ds.defineMapper<SocialMediaAssetImpl>(SocialMediaAssetImpl, 'socialMediaAsset', {
            contains: {
                digitalAsset: {
                    type: Object,
                    factory: (data: any, fieldName: string) => {
                        return Object.assign(new DigitalAssetImpl(), data[fieldName]);
                    },
                },
            },
        });

        ds.defineMapper<ThemeItemImpl>(ThemeItemImpl, 'theme', {
            contains: {
                colorPalette: {
                    type: Object,
                    factory: (data: any, fieldName: string) => {
                        const colorPalette = getEmptyColorPalette().setValues({
                            ...data[fieldName],
                        });
                        // fill ranked with solid if null
                        if (colorPalette && colorPalette.solid && !colorPalette.ranked.colors) {
                            colorPalette.ranked.colors = [colorPalette.solid];
                        }
                        return colorPalette;
                    },
                },
            },
        });

        ds.defineMapper<PdfImpl>(PdfImpl, 'pdf', {});
        ds.defineMapper<PptxImpl>(PptxImpl, 'pptx', {});
        ds.defineMapper<UserAssetImpl>(UserAssetImpl, 'userAsset', {});
        ds.defineMapper<PanelPublishResultImpl>(PanelPublishResultImpl, 'panelPublishResult', {});

        ds.defineMapper<ScheduledStoryboard>(ScheduledStoryboardImpl, 'scheduledStoryboard', {});

        ds.setUpMappers();

        return ds;
    }

    private static setDefaultSceneTransition(data: any): SceneTransition {
        if (!data.sceneTransition) {
            const sceneTransitionObject = new SceneTransitionImpl();
            sceneTransitionObject.type = 'fade';
            sceneTransitionObject.easing = 'easeCubic';
            sceneTransitionObject.duration = 800;
            sceneTransitionObject.enabled = false;
            data.sceneTransition = sceneTransitionObject;
        }
        return data.sceneTransition;
    }

    private setGroupBys(groupBys: AnalyticsGroupBy[]) {
        if (!groupBys) {
            return;
        }

        groupBys.forEach(groupBy => {
            // NOTE: alternateHeading needs to be either on or off.  If on, then it's
            // the text the user wants to use without modifications, if off, any UI displaying
            // that text uses "heading"
            //  https://sprinklr.atlassian.net/browse/VT-500
            if (!groupBy.details) {
                groupBy.details = {
                    alternateHeading: null,
                };
            }
        });
    }

    private setProjections(projections: AnalyticsProjection[]) {
        if (!projections) {
            return;
        }
        projections.forEach(projection => {
            // NOTE: alternateHeading needs to be either on or off.  If on, then it's
            // the text the user wants to use without modifications, if off, any UI displaying
            // that text uses "heading"
            //  https://sprinklr.atlassian.net/browse/VT-500
            if (!projection.details) {
                projection.details = {
                    alternateHeading: null,
                };
            }
        });
    }

    private setTimePeriod(timePeriod: AnalyticsTimePeriod) {
        if (!timePeriod) {
            return;
        }

        // HACKTOWN:  DISPLAY-1275.  Seeing requests data come from server with
        // no "timePeriod.key".  Set to default value in this case.
        if (!timePeriod.key) {
            timePeriod.key = 'last_7_days';
        }

        if (timePeriod.duration === undefined) {
            timePeriod.duration = 1;
        }

        if (timePeriod.startTime === undefined) {
            timePeriod.startTime = moment()
                .startOf('day')
                .valueOf();
        }

        if (timePeriod.endTime === undefined) {
            timePeriod.endTime = moment()
                .endOf('day')
                .valueOf();
        }
    }
}

export class DataStore {
    private mappers: MapperMap;
    private records: RecordMap | {};

    constructor() {
        this.mappers = {};
        this.records = {};
    }

    getMapper(name: string): Mapper<any> {
        return this.mappers[name];
    }

    public setUpMappers() {
        let key;
        for (key in this.mappers) {
            const mapper = this.mappers[key];
            mapper.relateMappers(this.mappers);
            mapper.setPostCreate(this.postCreate);
            this.records[mapper.name] = {};
        }
    }

    defineMapper<T>(T, name: string, config: any): Mapper<T> {
        const mapper = new Mapper<T>(T, name, config);
        this.mappers[name] = mapper;

        return mapper;
    }

    addMapper(mapper: any) {
        this.mappers[mapper.name] = mapper;
    }

    add(name: string, record: any): any {
        const mapper = this.mappers[name];
        return mapper.create(record);
    }

    public getRecords() {
        return this.records;
    }

    private getCached(mapperName: string, id: string | number) {
        if (this.records[mapperName] && this.records[mapperName][id]) {
            return this.records[mapperName][id];
        }
    }

    private setCached(mapperName: string, id: string | number, record: any) {
        if (!this.records[mapperName]) {
            this.records[mapperName] = {};
        }

        if (record && record.id && id && record.id !== id) {
            throw new Error(
                `${mapperName} added to cache has id of ${record.id} but is being indexed as ${id}`
            );
        }
        this.records[mapperName][id] = record;
    }

    private copyInto(mapper: Mapper<any>, dest: any, src: any) {
        if (!dest || !src) {
            return;
        }

        let field;
        let relatedMapper;

        for (field in src) {
            relatedMapper = mapper.getRelatedMapper(field);

            if (relatedMapper && relatedMapper.useDeduplication) {
                // mapped fields already single references by id and they already get a deep copy on their properties
                dest[field] = src[field];
                continue;
            }

            // Use this to fix the so-called "Ghost Object" issue in panel editor.  This was caused
            // because old code used to replace "widget" with brand new object which caused ghost issue
            // and screwed up mobx tracking.
            // if (src[field] && typeof src[field] === 'object') {
            //     console.log(`merging ${mapper.name}.${field}`, src[field]);
            // }
            ObjectUtils.copyChanged(dest, field, src, true);
        }
    }

    postCreate = <T>(mapper: Mapper<T>, data: any): T => {
        if (!mapper.useDeduplication) {
            const instance = new mapper.recordType();
            Object.assign(instance, data);
            return instance;
        }

        // console.log(`ds got postCreate ${mapper.name}: `, data);
        const id = data.id;
        const cached = this.getCached(mapper.name, data.id);

        if (cached) {
            if (id !== cached.id) {
                const message = `Cache returned a ${mapper.name} with id ${cached.id} for id lookup of ${id}'`;
                throw new Error(message);
            }

            // do copy-into of data, maintain reference
            this.copyInto(mapper, cached, data);

            return cached;
        } else {
            const instance = new mapper.recordType();
            Object.assign(instance, data);
            this.setCached(mapper.name, instance.id, instance);
            return instance;
        }
    };
}
