import { action, observable } from 'mobx';
import Config from '../../config';

export interface EmbedOptions {
    analytics_sprinklr: boolean;
    background_size?: string;
    background_type?: string;
    card_background_position?: string;
    card_background_size?: string;
    card_product_gallery_color?: string;
    card_text_format?: string;
    card_type?: string;
    carousel_autoplay?: boolean;
    carousel_autoplay_speed?: number;
    carousel_pagination_color?: string;
    cell_count?: number;
    css?: string;
    custom_field?: string;
    custom_field_type?: string;
    embed_exported?: boolean;
    font_color?: string;
    font_color_secondary?: string;
    font_color_secondary_textonly?: string;
    font_color_textonly?: string;
    font_family?: string;
    font_family_all_loader_css?: string;
    font_family_loader_css?: string;
    font_family_secondary?: string;
    font_family_secondary_loader_css?: string;
    font_family_secondary_textonly?: string;
    font_family_secondary_textonly_loader_css?: string;
    font_family_textonly?: string;
    font_family_textonly_loader_css?: string;
    font_size_primary?: number;
    font_size_secondary?: number;
    font_size_secondary_textonly?: number;
    font_size_textonly?: number;
    highlight_color?: string;
    highlight_color_textonly?: string;
    image_preload?: boolean;
    include_card?: boolean;
    include_card_product_gallery?: boolean;
    include_engagement?: boolean;
    include_ga?: boolean;
    include_jquery?: boolean;
    include_post_product_gallery?: boolean;
    infinite_scroll?: boolean;
    items_first_load?: number;
    items_per_load?: number;
    items_required_enabled?: boolean;
    items_required_count?: number;
    link_color?: string;
    link_color_textonly?: string;
    link_url_new_window?: boolean;
    live_refresh?: boolean;
    live_refresh_type?: string;
    max_columns?: boolean;
    max_columns_count?: number;
    post_background?: string;
    post_background_textonly?: string;
    post_click_behavior: string;
    post_click_behavior_mobile: string;
    post_gutter?: number;
    post_product_gallery_color?: string;
    post_product_gallery_label?: string;
    post_video_as_image?: boolean;
    product_field?: string;
    product_gallery_enabled?: boolean;
    product_gallery_header_enabled?: boolean;
    product_gallery_header_text?: string;
    product_gallery_type?: string;
    remove_duplicates?: string;
    secondary_post_permalink?: boolean;
    shoppable_product_tags?: boolean; // New integration with Space product tagging
    show_focus_outline?: boolean;
    social_icon_color?: string;
    social_icon_color_textonly?: string;
    stream_filters?: boolean;
    stream_filters_type?: string;
    ticker_height?: number;
    ticker_width?: number;
    tracking_params?: string;
    tracking_product_params?: string;
    twitter_intents_color?: string;
    twitter_intents_color_textonly?: string;
    type?: string;
    user_border_color?: string;
    user_border_color_textonly?: string;
    userbar_background?: string;
    video_mute?: boolean;
    shoppable_url?: string;
    shoppable_hero?: boolean;
    profile_header_enabled?: boolean;
    detect_profile_info?: boolean;
    profile_handle?: string;
    profile_image?: string;
    profile_link_enabled?: boolean;
    profile_link_anchor?: string;
    profile_link_url?: string;
    review_product_name_enabled?: boolean;
    review_product_name_size?: number;
    review_product_name_color?: string;
    review_location_enabled?: boolean;
    review_location_size?: number;
    review_location_color?: string;
    review_star_rating_enabled?: boolean;
    review_star_rating_size?: number;
    review_star_rating_color?: string;
    breakpoints?: string;
}

export class EmbedOptionsImpl implements EmbedOptions {
    @observable analytics_sprinklr = false;
    @observable background_size = 'contain';
    @observable background_type = 'color';
    @observable card_background_position = 'top center';
    @observable card_background_size = 'cover';
    @observable card_product_gallery_color = 'rgba(0,0,0,0.75)';
    @observable card_text_format = 'clamp';
    @observable card_type = 'card-basic';
    @observable carousel_autoplay = false;
    @observable carousel_autoplay_speed = 3000;
    @observable carousel_pagination_color = 'rgba(19,142, 243, 0.5)';
    @observable cell_count = 4;
    @observable css = '/* place custom css here */';
    @observable custom_field?: string;
    @observable custom_field_type = 'value';
    @observable font_color = '#fff';
    @observable font_color_secondary = '#fff';
    @observable font_color_secondary_textonly = '#fff';
    @observable font_color_textonly = '#fff';
    @observable font_family = 'OpenSansRegular';
    @observable font_family_all_loader_css = '';
    @observable font_family_loader_css = '';
    @observable font_family_secondary = 'OpenSans600';
    @observable font_family_secondary_loader_css = '';
    @observable font_family_secondary_textonly = 'OpenSans600';
    @observable font_family_secondary_textonly_loader_css = '';
    @observable font_family_textonly = 'OpenSansRegular';
    @observable font_family_textonly_loader_css = '';
    @observable font_size_primary = 18;
    @observable font_size_secondary = 13;
    @observable font_size_secondary_textonly = 13;
    @observable font_size_textonly = 22;
    @observable highlight_color = 'rgba(19,142,243,0.5)';
    @observable highlight_color_textonly = '#40a3f5';
    @observable image_preload = true;
    @observable include_card = true;
    @observable include_card_on_mobile = true;
    @observable include_card_product_gallery = true;
    @observable include_engagement = false;
    @observable include_ga = true;
    @observable include_jquery = true; // UI toggle for jQuery.  Used by backend to know if to include jQuery with embed or not
    @observable include_post_product_gallery = false;
    @observable infinite_scroll = true;
    @observable items_first_load = 20;
    @observable items_per_load = 10;
    @observable items_required_enabled = false;
    @observable items_required_count = 1;
    @observable js = null;
    @observable link_color = '#fff';
    @observable link_color_textonly = '#fff';
    @observable link_url_new_window = true;
    @observable live_refresh = true;
    @observable live_refresh_type = 'queue';
    @observable max_columns = false;
    @observable max_columns_count = 5;
    @observable post_background = '#9b9ba5';
    @observable post_background_textonly = '#9b9ba5';
    @observable post_click_behavior = 'card';
    @observable post_click_behavior_mobile = 'post_source_url';
    @observable post_gutter = 2;
    @observable post_product_gallery_color = 'rgba(0,0,0,0.75)';
    @observable post_product_gallery_label = '';
    @observable post_video_as_image = false;
    @observable product_field?: string;
    @observable product_gallery_enabled = true;
    @observable product_gallery_header_enabled = true;
    @observable product_gallery_header_text = 'Featured in Post';
    @observable product_gallery_type = 'carousel';
    @observable remove_duplicates = 'all';
    @observable secondary_post_permalink = true;
    @observable shoppable_product_tags = false;
    @observable show_focus_outline = false;
    @observable social_icon_color = '#fff';
    @observable social_icon_color_textonly = '#fff';
    @observable stream_filters = false;
    @observable stream_filters_type = 'control-feedtype';
    @observable ticker_height = 650;
    @observable ticker_width = 300;
    @observable transition_rotation = '["-45";"45";"90";"-90"]'; // unused
    @observable tracking_params = '[]';
    @observable tracking_product_params = '[]';
    @observable twitter_intents_color = '#fff';
    @observable twitter_intents_color_textonly = '#fff';
    @observable type = 'packery-editorial';
    @observable user_bar_height = 40; // Used to be used.  Commented out in Styles currently.
    @observable user_border_color = 'rgba(255, 255, 255, 0.2)';
    @observable user_border_color_textonly = 'rgba(255, 255, 255, 0.2)';
    @observable userbar_background = 'rgba(0,0,0,.5)';
    @observable video_mute = false;
    @observable shoppable_url = '';
    @observable shoppable_hero = true;
    @observable profile_header_enabled = false;
    @observable detect_profile_info = true;
    @observable profile_handle = '';
    @observable profile_image = '';
    @observable profile_link_enabled = false;
    @observable profile_link_anchor = 'Visit Website';
    @observable profile_link_url = '';
    @observable review_product_name_enabled = true;
    @observable review_product_name_size = 15;
    @observable review_product_name_color = '#fff';
    @observable review_location_enabled = true;
    @observable review_location_size = 15;
    @observable review_location_color = '#fff';
    @observable review_star_rating_enabled = true;
    @observable review_star_rating_size = 50;
    @observable review_star_rating_color = '#fff';
    @observable breakpoints = `{}`;

    private fontBaseUrl: string;

    constructor() {
        this.fontBaseUrl = Config.embedRoot.replace(/\/+$/, '') + '/embed/fonts/'; // ensure no excess /

        this.font_family_loader_css = `@font-face{font-family:OpenSansRegular;src: url(${this.fontUrl(
            '/opensans/OpenSansRegular.ttf'
        )}); url(${this.fontUrl('/opensans/opensansregular.woff')});}`;
        this.font_family_secondary_loader_css = `@font-face{font-family:OpenSans600;src: url(${this.fontUrl(
            '/opensans/OpenSans600.ttf'
        )}); url(${this.fontUrl('/opensans/opensans600.woff')});}`;
        this.font_family_secondary_textonly_loader_css = `@font-face{font-family:OpenSans600;src: url(${this.fontUrl(
            '/opensans/OpenSans600.ttf'
        )}); url(${this.fontUrl('/opensans/opensans600.woff')}));}`;
        this.font_family_textonly_loader_css = `@font-face{font-family:OpenSansRegular;src: url(${this.fontUrl(
            '/opensans/OpenSansRegular.ttf'
        )}); url(${this.fontUrl('/opensans/opensansregular.woff')});}`;
    }

    @action
    setValues(opts?: any): this {
        if (opts) {
            Object.assign(this, opts);
        }
        return this;
    }

    private fontUrl(fontPath: string): string {
        return this.fontBaseUrl + fontPath.replace(/^\/+/, ''); // ensure no leading slashes
    }
}
