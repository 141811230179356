//
// This service will preload images for arrays that have them
//
// Author: David Boyd
//
import { Cache } from '../Cache/Cache';

interface ImagePreloadItem {
    images?: Array<{ url: string }>;
}

export class ImagePreload<T extends ImagePreloadItem> {
    // Cache to keep track of previously failed image loads.  Once in the cache,
    // these images are skipped on subsequent loads
    private blacklist = new Cache<boolean>(512); // Don't allow more than 512 blacklisted image urls in the cache at one time
    private useBlacklist = true;

    setBlacklist(on: boolean) {
        this.useBlacklist = on;
    }

    // Called when a new set of data is received on a background timer
    // "newPosts" is a PostanoPosts object that has been recevied and needs
    // to be merged in.
    run(source: Array<T>, removeFailures?: boolean): Promise<any> {
        const _this = this;

        return new Promise(function(resolve: any, reject: any) {
            let loaded = 0;
            let item;
            let image: any;
            const result: Array<T> = source;
            let imageUrl: string;

            const resolve2 = result2 => {
                // Give some breathing room and make sure the images are fully available.  Seems to work better this way.
                setTimeout(function() {
                    resolve(result2);
                }, 500);
            };

            const count = source.length;
            if (count !== 0) {
                let x = count;
                while (x--) {
                    item = source[x];

                    imageUrl = _this.getUrl(item);

                    // If this is already in the blacklist, it has failed previously, so don't check again
                    if (_this.useBlacklist) {
                        if (_this.blacklist.get(imageUrl)) {
                            if (removeFailures !== undefined && removeFailures) {
                                source.splice(x, 1);
                            }

                            imageUrl = null;
                        }
                    }

                    if (imageUrl && imageUrl !== 'image-fallback') {
                        image = new Image();

                        image.index = x;
                        image.imageUrl = imageUrl;

                        // Successfully loaded the image
                        image.onload = function() {
                            this.onerror = this.onabort = this.onload = null;

                            if (++loaded === count) {
                                resolve2(result);
                            }
                        };

                        // Error loading the image
                        image.onerror = image.onabort = function() {
                            this.onerror = this.onabort = this.onload = null;

                            // Add to our blacklist so we don't need to load it again
                            if (_this.useBlacklist) {
                                _this.blacklist.set(this.imageUrl, true);
                            }

                            // If caller wants it, remove the bad image from the item array
                            if (removeFailures !== undefined && removeFailures) {
                                _this.removeOffsetForUrl(source, this.index, this.imageUrl);
                            }

                            if (++loaded === count) {
                                resolve2(result);
                            }
                        };

                        // Go and get it!
                        image.src = imageUrl;
                    } else {
                        if (++loaded === count) {
                            resolve2(result);
                        }
                    }
                }
            } else {
                resolve2(result);
            }
        });
    }

    private getUrl(item): string {
        let result = item.videos && item.videos.length && item.videos[0].poster;
        if (!result) {
            result = item.images && item.images.length && item.images[0].url;
        }

        return result;
    }

    private removeOffsetForUrl(source: Array<T>, offset: number, url: string) {
        let item: T;

        if (offset >= source.length) {
            offset = source.length - 1;
        }

        for (; offset >= 0; offset--) {
            item = source[offset];

            if (url === this.getUrl(item)) {
                break;
            }
        }

        if (offset >= 0) {
            source.splice(offset, 1);
        }
    }
}
