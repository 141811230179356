import { AxiosInstance, AxiosRequestConfig } from 'axios';
import moment from 'moment';

export default class HeartbeatService {
    protected axios: AxiosInstance;
    protected token: string;
    protected clientId: number;

    constructor(axios: AxiosInstance) {
        this.axios = axios;
    }

    setToken(token: string) {
        this.token = token;
    }

    setClientId(clientId: number) {
        this.clientId = clientId;
    }

    pulse(fromPanelId: string, toPanelId: string) {
        if (this.token) {
            const clientTime: number = moment().valueOf();

            const requestOpts: AxiosRequestConfig = {
                headers: { 'X-Spr-Display-Token': this.token },
            };

            this.axios
                .get(
                    '/heartbeat/' +
                        this.clientId +
                        '/' +
                        fromPanelId +
                        '/' +
                        toPanelId +
                        '/' +
                        clientTime,
                    requestOpts
                )
                .then((response: any) => {
                    //noop.
                });
        }
    }
}
