import { action, computed, observable, toJS } from 'mobx';
// options
import { WidgetOptionsImpl } from 'models/Widget/WidgetOptions';
import {
    ComboAreaOptionsImpl,
    ComboColumnOptionsImpl,
    ComboLineOptionsImpl,
} from '../../widgets/ComboChartWidget/options';
import { ComboChartOptionsUnion } from 'components/_charts/ComboChart/types';
// types
import { Widget } from '@sprinklr/stories/widget/Widget';
import { WidgetType } from 'models/Widget/WidgetType';
import { Panel } from 'models/Panel/Panel';
import { AnalyticsRequest } from '@sprinklr/stories/analytics/AnalyticsRequest';
import { PostsRequest } from '@sprinklr/stories/post/PostsRequest';
import { Theme } from 'models/Theme/Theme';
// services
import WidgetTypeService from 'services/WidgetTypes/WidgetTypeService';
import { WidgetRequestAnalytics } from 'utils/Widget/WidgetRequestAnalytics';
import TimePeriodService from 'services/TimePeriodService/TimePeriodService';
// helpers
import { getPxPosition } from 'utils/Number/NumberUtils';
import { WidgetRequestPosts } from 'utils/Widget/WidgetRequestPosts';
import { PanelTemplatesStore } from '../../stores/PanelTemplatesStore/PanelTemplatesStore';
import { WidgetDimensionKey } from 'utils/Widget/WidgetDimensionKey';
import { placeWidget } from 'components/Panel/PanelEditorSidebar/PanelDesign/PanelDesignOptions';
// constants
import { DefaultPosition } from '../../stores/PanelTemplatesStore/PanelTemplatesTheme/constants';
// templates
import { WidgetBarChart } from '../../stores/PanelTemplatesStore/WidgetBarChart';
import { WidgetColumnChart } from '../../stores/PanelTemplatesStore/WidgetColumnChart';
import { WidgetPieChart } from '../../stores/PanelTemplatesStore/WidgetPieChart';
import { WidgetAxisBubbleChart } from '../../stores/PanelTemplatesStore/WidgetAxisBubbleChart';
import { WidgetRankedTable } from '../../stores/PanelTemplatesStore/WidgetRankedTable';
import { WidgetSingleMetric } from '../../stores/PanelTemplatesStore/WidgetSingleMetric';
import { WidgetDualAxis } from '../../stores/PanelTemplatesStore/WidgetDualAxis';
import { WidgetLineChart } from '../../stores/PanelTemplatesStore/WidgetLineChart';
import { WidgetStackedColumnChart } from '../../stores/PanelTemplatesStore/WidgetStackedColumnChart';
import { WidgetStackedBarChart } from '../../stores/PanelTemplatesStore/WidgetStackedBarChart';
import { WidgetDNAChart } from '../../stores/PanelTemplatesStore/WidgetDNAChart';
import { WidgetWordCloud } from '../../stores/PanelTemplatesStore/WidgetWordCloud';
import { WidgetComboChart } from '../../stores/PanelTemplatesStore/WidgetComboChart';
import { WidgetWorldHeatMap } from '../../stores/PanelTemplatesStore/WidgetWorldHeatMap';
import { WidgetPostCard } from '../../stores/PanelTemplatesStore/WidgetPostCard';
import { WidgetGridV2 } from '../../stores/PanelTemplatesStore/WidgetGridV2';
import { TemplateBarChart } from '../../stores/PanelTemplatesStore/TemplateBarChart';
import { TemplateColumnChart } from '../../stores/PanelTemplatesStore/TemplateColumnChart';
import { TemplatePieChart } from '../../stores/PanelTemplatesStore/TemplatePieChart';
import { TemplateAxisBubbleChart } from '../../stores/PanelTemplatesStore/TemplateAxisBubbleChart';
import { TemplateSingleMetric } from '../../stores/PanelTemplatesStore/TemplateSingleMetric';
import { TemplateDualAxis } from '../../stores/PanelTemplatesStore/TemplateDualAxis';
import { TemplateLineChart } from '../../stores/PanelTemplatesStore/TemplateLineChart';
import { TemplateStackedColumnChart } from '../../stores/PanelTemplatesStore/TemplateStackedColumnChart';
import { TemplateStackedBarChart } from '../../stores/PanelTemplatesStore/TemplateStackedBarChart';
import { TemplateDNAChart } from '../../stores/PanelTemplatesStore/TemplateDNAChart';
import { TemplateWordCloud } from '../../stores/PanelTemplatesStore/TemplateWordCloud';
import { TemplateComboChart } from '../../stores/PanelTemplatesStore/TemplateComboChart';
import { TemplateWorldHeatMap } from '../../stores/PanelTemplatesStore/TemplateWorldHeatMap';
import { FormatBucketsOptions } from '@sprinklr/stories/post/PostsFormatRequest';

export type WidgetIndexObject = {
    locked: boolean;
    widgetId: string;
    zIndex: number;
};
export default class WidgetService {
    @observable public editingWidget: Widget;
    @observable widgetIndex: Array<WidgetIndexObject> = [];
    @observable widgetsWithValidationErrors: { [key: string]: boolean } = {};

    @computed get lockedWidgets(): string[] {
        const lockedArray = [];
        this.widgetIndex.map(item => {
            if (item.locked === true) {
                lockedArray.push(item.widgetId);
            }
        });
        return lockedArray;
    }
    private widgetTypeService: WidgetTypeService;
    private resetLayout = false;

    constructor(widgetTypeService: WidgetTypeService) {
        this.widgetTypeService = widgetTypeService;
    }

    @action
    public deleteWidget(panel: Panel, widget: Widget) {
        const widgets = panel.widget.children;
        const index = widgets.indexOf(widget);
        widgets.splice(index, 1);
    }

    @action
    public setRandomWidgetId(widget: Widget) {
        widget.id = Math.random()
            .toString(36)
            .substr(2, 9);
        if (widget.children) {
            widget.children.forEach(this.setRandomWidgetId);
        }
    }

    @action
    public cloneWidget(rootWidget: Widget, widget: Widget) {
        const clonedWidget: Widget = toJS(widget);

        this.setRandomWidgetId(clonedWidget);

        const top = getPxPosition(toJS(widget.position.top), toJS(rootWidget.position.height));
        const left = getPxPosition(toJS(widget.position.left), toJS(rootWidget.position.width));

        const widgetPositionOffset = {
            top: `${top + 100}px`,
            left: `${left + 100}px`,
            height: widget.position.height,
            width: widget.position.width,
        };
        clonedWidget.position = widgetPositionOffset;
        rootWidget.children.push(clonedWidget);
    }

    @action
    public copyWidget(widget: Widget) {
        const copiedWidget = JSON.stringify(toJS(widget));
        localStorage.setItem('copiedWidget', copiedWidget);
    }

    @action
    public pasteWidget(theme?: Theme) {
        let pastedWidget;
        try {
            pastedWidget = JSON.parse(localStorage.getItem('copiedWidget'));
        } catch (e) {
            return;
        }
        pastedWidget.id =
            '_id' +
            Math.random()
                .toString(36)
                .substr(2, 9);
        if (theme) {
            pastedWidget.theme = theme;
        }

        return pastedWidget;
    }

    // Determine if any of the widgets in a panel are of a specified hotSwapType
    public checkForWidgetType(panel: Panel, tags: string[]): boolean {
        let result = false;
        if (panel && panel.widget && panel.widget.children) {
            panel.widget.children.forEach(widget => {
                const widgetType = this.widgetTypeService.get(widget.type);
                if (widgetType.type.tags === tags) {
                    result = true;
                    return;
                }
            });
        }
        return result;
    }

    // Get all of the widgets from the widget library that match a specified tags;
    public getAvailableWidgets(tags: string[]) {
        const allWidgetTypes = this.widgetTypeService.list;

        const result = [];
        tags = tags || [];

        // this should support matching multiple tags
        tags.map(tag => {
            allWidgetTypes.filter(item => {
                if (item.type.tags && item.type.tags.indexOf(tag) !== -1) {
                    result.push(item);
                }
            });
            // console.log('result', result);
            return result;
        });
        return result;
    }

    // Swap the type and options for a widget
    // Note: this does not solve for other aspects of the widget JSON like Data
    // Note: this also doesn't account for settings that had been updated by the user if that are also present in the impl ot the new widget
    @action
    public swapWidget(selected: Widget, newWidgetType: WidgetType) {
        let Options: typeof WidgetOptionsImpl = WidgetOptionsImpl;
        selected.type = newWidgetType.id;

        const widgetType = this.widgetTypeService.get(newWidgetType.id);
        if (widgetType) {
            Options = widgetType.type.optionsType;
        }

        const newOptions = new Options();
        Object.assign(selected.options, newOptions);
    }

    @action
    public resetDraggableLayout(val: boolean) {
        this.resetLayout = val;
    }

    public getResetStatus() {
        return this.resetLayout;
    }

    @action
    public toggleLockWidget(widgetId) {
        this.widgetIndex.map(item => {
            if (item.widgetId === widgetId) {
                item.locked = !item.locked;
            }
        });
    }

    @action
    public getLockedWidgets() {
        return this.lockedWidgets;
    }

    @action
    getWidgetIndex() {
        return this.widgetIndex;
    }

    @action
    setWidgetIndex(widgetArray) {
        this.widgetIndex = widgetArray;
    }

    public hasValidationError(widget: Widget): boolean {
        return this.widgetsWithValidationErrors[widget.id];
    }

    /**
     * Import helpers
     */
    public static getImportWidgetTemplate(type): Widget {
        switch (type) {
            case 'BAR':
                return WidgetBarChart;
            case 'COLUMN':
                return WidgetColumnChart;
            case 'PIE':
            case 'DONUT':
                return WidgetPieChart;
            case 'BUBBLE':
                return WidgetAxisBubbleChart;
            case 'TABLE':
            case 'CROSSTAB':
            case 'ENGAGEMENT_STATS_CHART':
                return WidgetRankedTable;
            case 'COUNTER':
            case 'COUNTER_SUMMARY':
                // A counter widget may represent multiple single-metric widgets.
                return WidgetSingleMetric;
            case 'DUAL_AXIS':
                return WidgetDualAxis;
            case 'LINE':
            case 'SPLINE':
            case 'AREA':
            case 'AREA_SPLINE':
                return WidgetLineChart;
            case 'STACKED_COLUMN':
                return WidgetStackedColumnChart;
            case 'STACKED_BAR':
                return WidgetStackedBarChart;
            case 'HEAT_MAP':
                return WidgetDNAChart;
            case 'WORD_CLOUD':
                return WidgetWordCloud;
            case 'COMBINATION':
                return WidgetComboChart;
            case 'MAP':
                return WidgetWorldHeatMap;
            case 'POST_CARD':
                return WidgetPostCard;
            case 'TOP_POSTS':
                return WidgetGridV2;
            case 'STREAM':
            case 'AUDIENCE_STREAM':
                return null;
            // return WidgetGrid;    // TODO: Prompt user for post widget type?

            case 'CUSTOM_PIVOT_TABLE':
            case 'STACKED_TWIN_AXIS':
                // Unsure
                return null;

            case 'TITLE':
            case 'FREE_TEXT':
                // Rich text?
                return null;

            default:
                console.log('Unknown Chart Type', type);
                return null;
        }
    }

    /**
     * Import helpers for multi-widget scenes
     */
    public static getMultiImportWidgetTemplate(type): Widget {
        switch (type) {
            case 'BAR':
                return WidgetBarChart;
            case 'COLUMN':
                return WidgetColumnChart;
            case 'PIE':
            case 'DONUT':
                return WidgetPieChart;
            case 'BUBBLE':
                return WidgetAxisBubbleChart;
            case 'TABLE':
            case 'CROSSTAB':
                return WidgetRankedTable;
            case 'ENGAGEMENT_STATS_CHART':
            case 'COUNTER':
            case 'COUNTER_SUMMARY':
                // A counter or engagement stats chart widget may represent multiple single-metric widgets.
                return WidgetSingleMetric;
            case 'DUAL_AXIS':
                return WidgetDualAxis;
            case 'LINE':
            case 'SPLINE':
            case 'AREA':
            case 'AREA_SPLINE':
                return WidgetLineChart;
            case 'STACKED_COLUMN':
                return WidgetStackedColumnChart;
            case 'STACKED_BAR':
                return WidgetStackedBarChart;
            case 'HEAT_MAP':
                return WidgetDNAChart;
            case 'WORD_CLOUD':
                return WidgetWordCloud;
            case 'COMBINATION':
                return WidgetComboChart;
            case 'MAP':
                return WidgetWorldHeatMap;
            case 'POST_CARD':
                return WidgetPostCard;
            case 'TOP_POSTS':
                return WidgetGridV2;
            case 'STREAM':
            case 'AUDIENCE_STREAM':
                return null;
            // return WidgetGrid;    // TODO: Prompt user for post widget type?

            case 'CUSTOM_PIVOT_TABLE':
            case 'STACKED_TWIN_AXIS':
                // Unsure
                return null;

            case 'TITLE':
            case 'FREE_TEXT':
                // Rich text?
                return null;

            default:
                console.log('Unknown Chart Type', type);
                return null;
        }
    }

    public static getDashboardImportWidgetTemplate(type): Widget {
        switch (type) {
            case 'BAR':
                return TemplateBarChart;
            case 'COLUMN':
                return TemplateColumnChart;
            case 'PIE':
            case 'DONUT':
                return TemplatePieChart;
            case 'BUBBLE':
                return TemplateAxisBubbleChart;
            case 'TABLE':
            case 'CROSSTAB':
            case 'ENGAGEMENT_STATS_CHART':
                return WidgetRankedTable; // TODO - panel version of this template?
            case 'COUNTER':
            case 'COUNTER_SUMMARY':
                // A counter widget may represent multiple single-metric widgets.
                return TemplateSingleMetric;
            case 'DUAL_AXIS':
                return TemplateDualAxis;
            case 'LINE':
            case 'SPLINE':
            case 'AREA':
            case 'AREA_SPLINE':
                return TemplateLineChart;
            case 'STACKED_COLUMN':
                return TemplateStackedColumnChart;
            case 'STACKED_BAR':
                return TemplateStackedBarChart;
            case 'HEAT_MAP':
                return TemplateDNAChart;
            case 'WORD_CLOUD':
                return TemplateWordCloud;
            case 'COMBINATION':
                return TemplateComboChart;
            case 'MAP':
                return TemplateWorldHeatMap;
            case 'POST_CARD':
                return WidgetPostCard;
            case 'TOP_POSTS':
                return WidgetGridV2;
            case 'STREAM':
            case 'AUDIENCE_STREAM':
                return null;
            // return WidgetGrid;    // TODO: Prompt user for post widget type?

            case 'CUSTOM_PIVOT_TABLE':
            case 'STACKED_TWIN_AXIS':
                // Unsure
                return null;

            case 'TITLE':
            case 'FREE_TEXT':
                // Rich text?
                return null;

            default:
                console.log('Unknown Chart Type', type);
                return null;
        }
    }

    public importAxes(selected, request, metaInfoService, index) {
        let axes = null;

        if (selected.additional) {
            if (selected.additional.hybridaxes) {
                axes = JSON.parse(selected.additional.hybridaxes[index]); // Special case for hybrid widgets that are manually combined from multiple desktop widgets
            } else if (selected.additional.axes) {
                axes = JSON.parse(selected.additional.axes);
            }
        }

        if (!request || !axes) {
            return;
        }

        request.projections = [];
        request.groupBys = [];

        axes.forEach(async (axis: any) => {
            if (axis.measurement) {
                const metric = await metaInfoService?.getMetric(request.reportingEngine, null, axis.measurement.name)

                if (metric) {
                    const heading =
                        axis.label ||
                        axis.measurement.displayName ||
                        metric.displayName ||
                        metric.name ||
                        axis.measurement.name;

                    const projection = {
                        heading,
                        measurementName: metric.name,
                        aggregateFunction: axis.measurementFn,
                        details: {
                            origReport: axis.measurement.report,
                            alternateHeading: '',
                            dataType: undefined,
                        },
                    };

                    if (metric.dataType) {
                        projection.details.dataType = metric.dataType;
                    }

                    if (request.sorts) {
                        request.sorts.forEach(sort => {
                            if (sort.heading === projection.measurementName) {
                                sort.heading = projection.heading;
                            }
                        });
                    }

                    request.projections.push(projection);
                } else {
                    const heading =
                        axis.measurement.name || axis.label || axis.measurement.displayName;

                    const projection = {
                        heading,
                        measurementName: axis.measurement.name,
                        aggregateFunction: axis.measurementFn,
                        details: {
                            origReport: axis.measurement.report,
                            alternateHeading: '',
                            dataType: undefined,
                        },
                    };

                    if (axis.measurement.dataType) {
                        projection.details.dataType = axis.measurement.dataType;
                    }

                    if (request.sorts) {
                        request.sorts.forEach(sort => {
                            if (sort.heading === projection.measurementName) {
                                sort.heading = projection.heading;
                            }
                        });
                    }

                    request.projections.push(projection);
                }

                if (axis.measurement.report) {
                    request.report = axis.measurement.report;
                }
            }

            if (axis.dimension && !axis.dimension.groupByNotAllowed) {
                const groupBy = {
                    heading: axis.dimension.fieldName,
                    dimensionName: axis.dimension.name,
                    groupType: 'FIELD', // Not sure we can determine non-FIELD group types.
                    details: {
                        srcType: axis.dimension.srcType,
                        fieldName: axis.dimension.fieldName,
                        displayName: axis.dimension.displayName,
                        interval: undefined,
                    },
                };

                if (groupBy.dimensionName.toLowerCase() === 'date') {
                    groupBy.details.interval = '1d'; // TODO: Calculate
                }

                request.groupBys.push(groupBy);
            }
        });
    }

    public importFromRequest(coreRequest, request, metaInfoService) {
        if (!request || !coreRequest) {
            return;
        }

        request.projections = [];
        request.groupBys = [];
        request.filters = [];

        this.importProjections(coreRequest, coreRequest.report, request.projections);
        this.importGroupBys(coreRequest.groupBys, request.groupBys);
        this.importFiltersFromRequest(coreRequest.filters, request.filters);
    }

    private importFiltersFromRequest(coreFilters, widgetFilters) {
        if (coreFilters) {
            coreFilters
                .filter(filter => filter.values.length > 0)
                .forEach(filter => {
                    widgetFilters.push({
                        dimensionName: filter.field,
                        filterType: filter.filterType,
                        values: filter.values,
                        details: filter.details,
                    });
                });
        }
    }

    private importProjections(request, report, result) {
        if (request.projections && request.projections.length) {
            request.projections.forEach(projection => {
                const newProjection = {
                    heading: projection.key,
                    measurementName: projection.measurement,
                    aggregateFunction: projection.aggregateFunction,
                    details: {
                        origReport: report,
                        alternateHeading: '',
                    },
                };

                result.push(newProjection);
            });
        }

        if (request.groupBys && request.groupBys.length) {
            request.groupBys.forEach(groupBy => this.importProjections(groupBy, report, result));
        } else if (request.childrenGroupBys && request.childrenGroupBys.length) {
            request.childrenGroupBys.forEach(groupBy =>
                this.importProjections(groupBy, report, result)
            );
        }
    }

    private importGroupBys(groupBys, result) {
        if (groupBys && groupBys.length) {
            groupBys.forEach(groupBy => {
                const newGroupBy = {
                    heading: groupBy.field,
                    dimensionName: groupBy.field,
                    groupType: groupBy.groupType,
                    details: {
                        interval: undefined,
                    },
                };

                if (newGroupBy.dimensionName.toLowerCase() === 'date') {
                    newGroupBy.details.interval = '1d'; // TODO: Calculate
                }

                result.push(newGroupBy);

                if (groupBy.childrenGroupBys && groupBy.childrenGroupBys.length) {
                    this.importGroupBys(groupBy.childrenGroupBys, result);
                }
            });
        }
    }

    public importPostAxes(selected, request, metaInfoService) {
        const axes =
            selected.additional && selected.additional.axes
                ? JSON.parse(selected.additional.axes)
                : null;

        if (!request || !axes) {
            return;
        }

        request.projections = [];
        request.groupBys = [];

        // console.log('axes', axes);

        axes.forEach(async (axis: any) => {
            if (axis.measurement) {
                const metric = await metaInfoService?.getMetric(request.reportingEngine, null, axis.measurement.name)

                if (metric) {
                    const heading =
                        axis.label ||
                        axis.measurement.displayName.replace(/ /g, '_') ||
                        metric.displayName.replace(/ /g, '_') ||
                        metric.name ||
                        axis.measurement.name;

                    const projection = {
                        heading,
                        measurementName: metric.name,
                        aggregateFunction: axis.measurementFn,
                        details: {
                            origReport: axis.measurement.report,
                            alternateHeading: '',
                            dataType: undefined,
                        },
                    };

                    if (metric.dataType) {
                        projection.details.dataType = metric.dataType;
                    }

                    request.projections.push(projection);
                } else {
                    const heading =
                        axis.measurement.name ||
                        axis.label ||
                        axis.measurement.displayName.replace(/ /g, '_');

                    const projection = {
                        heading,
                        measurementName: axis.measurement.name,
                        aggregateFunction: axis.measurementFn,
                        details: {
                            origReport: axis.measurement.report,
                            alternateHeading: '',
                            dataType: undefined,
                        },
                    };

                    if (axis.measurement.dataType) {
                        projection.details.dataType = axis.measurement.dataType;
                    }

                    request.projections.push(projection);
                }

                if (axis.measurement.report) {
                    request.report = axis.measurement.report;
                }
            }

            if (
                axis.dimension &&
                !axis.dimension.groupByNotAllowed &&
                axis.dimension.additional?.CHART_TYPE !== 'STREAM'
            ) {
                const groupBy = {
                    heading: axis.dimension.displayName.replace(/ /g, '_'),
                    dimensionName: axis.dimension.name,
                    groupType: 'FIELD', // Not sure we can determine non-FIELD group types.
                    details: {
                        srcType: axis.dimension.srcType,
                        fieldName: axis.dimension.fieldName,
                        displayName: axis.dimension.displayName,
                        interval: undefined,
                    },
                };

                if (groupBy.dimensionName.toLowerCase() === 'date') {
                    groupBy.details.interval = '1d'; // TODO: Calculate
                }

                request.groupBys.push(groupBy);
            }
        });
    }

    public async importPostSorts(selected, request, metaInfoService) {
        const selectedRequest = selected?.requests?.length > 0 && selected.requests[0];

        if (selectedRequest?.sorts) {
            request.sorts = selectedRequest.sorts.map(sort => {
                return {
                    heading: sort.key,
                    order: sort.order,
                };
            });
        } else if (
            selectedRequest?.childrenGroupBys?.length > 0 &&
            selectedRequest?.childrenGroupBys[0]?.sort
        ) {
            request.sorts[0].heading = selectedRequest.childrenGroupBys[0].sort.key;
            request.sorts[0].order = selectedRequest.childrenGroupBys[0].sort.order;
        } else if (request?.projections?.length > 0) {
            request.sorts[0].heading = request?.projections[1].measurementName;
        }

        if (request.sorts?.length > 0) {
            const dimensions = request.sorts.map(sort => {
                return new WidgetDimensionKey(sort.heading);
            });

            const data = await metaInfoService.areDimensions(
                request.reportingEngine,
                request.report,
                dimensions
            );

            if (data) {
                data.forEach((isDimension, offset) => {
                    request.sorts[offset].isDimension = isDimension;
                });
            }
        }
    }

    // Set the request's report to match the first projection
    public setReportFromFirstProjection(request) {
        if (
            request.projections &&
            request.projections[0] &&
            request.projections[0].details?.origReport
        ) {
            request.report = request.projections[0].details?.origReport;
        }
    }

    public importDateLimit(request, widgetTimePeriod) {
        const timePeriod = TimePeriodService.createFromAnalyticsTimePeriod(widgetTimePeriod);
        const defaultInterval = timePeriod.defaultInterval() || '1d';

        request.groupBys &&
            request.groupBys.forEach(groupBy => {
                if (!groupBy.dimensionName) {
                    return;
                }

                if (groupBy.dimensionName.toLowerCase() === 'date') {
                    groupBy.groupType = 'DATE_HISTOGRAM';
                    groupBy.details.interval = defaultInterval;
                    // console.log('defaultInterval', defaultInterval);

                    if (timePeriod && widgetTimePeriod.getRecommendedLimit) {
                        const limit = widgetTimePeriod.getRecommendedLimit(defaultInterval);
                        if (limit > request.limit) {
                            request.limit = limit;
                        }
                    }
                    // console.log('groupBy', JSON.parse(JSON.stringify(groupBy)));
                }
            });
    }

    public importWidgetFilters(selected, request) {
        if (selected.widgetFilters && selected.reports) {
            request.filters = [];
            selected.reports.forEach(report => {
                if (selected.widgetFilters[report]) {
                    selected.widgetFilters[report]
                        .filter(filter => filter.values.length > 0)
                        .forEach(filter => {
                            request.filters.push({
                                dimensionName: filter.field,
                                filterType: filter.filterType,
                                values: filter.values,
                                details: filter.details,
                            });
                        });
                }
            });
        }
    }

    public importPostFilters(request, selected, dashboard) {
        this.importDashboardFilters(request.sources[0].id, selected, dashboard);

        const selectedRequest = selected.requests[0];

        if (selectedRequest?.filters) {
            const postFilters = selectedRequest.filters
                .filter(filter => filter.values.length !== 0)
                .map(filter => {
                    return {
                        dimensionName: filter.field,
                        filterType: filter.filterType,
                        values: filter.values,
                    };
                });
            request.sources[0].id.filters = [...request.sources[0].id.filters, ...postFilters];
        }

        this.addAllAccountsFilter(request.sources[0].id);
        this.addAllClientsFilter(request.sources[0].id);
    }

    public importWidgetSorts(request, selected) {
        if (selected.sorts && selected.reports) {
            request.sorts = [];
            selected.reports.forEach(report => {
                if (selected.sorts[report] && Array.isArray(selected.sorts[report])) {
                    selected.sorts[report].forEach(sort => {
                        const heading = WidgetService.getProjectionHeading(request, sort.key);
                        if (heading) {
                            request.sorts.push({
                                heading,
                                order: sort.order,
                            });
                        }
                    });
                }
            });
        }
    }

    /**
     * TODO:
     * The 'key' param in the widget sorts is only mostly a match in that it contains the measurement name.
     * e.g., TOTAL_ENGAGEMENT__SUM__POST_INSIGHTS vs TOTAL_ENGAGEMENT
     *
     * Currently doing a startsWith string match, but other solutions may be better. e.g., contains, splitting on double-underscores,
     * or asking the core team to provide different value for this key.
     *
     * @param request
     * @param measurementName
     */
    private static getProjectionHeading(request, measurementName: string) {
        if (request.projections) {
            const projections = request.projections.filter(projection =>
                measurementName.startsWith(projection.measurementName)
            );
            if (projections.length) {
                return projections[0].heading;
            }
        }
        return null;
    }

    /**
     * Import
     */

    @action
    importDashboardWidget = async (
        metaInfoService,
        widgetTypeService,
        panelTemplatesStore,
        dashboard,
        selected,
        timePeriod,
        overrideGlobalTimePeriod,
        isPresentations,
        panelLevel
    )  => {
        const positions = this.dividePosition(DefaultPosition, selected.chartTypes.length, 'v');

        const result = [];

        selected.chartTypes.forEach(async (type, index) => {
            // const widgetTemplate = panelLevel ? WidgetService.getDashboardImportWidgetTemplate(type) : WidgetService.getImportWidgetTemplate(type);
            const widgetTemplate =
                selected.chartTypes.length > 1
                    ? WidgetService.getMultiImportWidgetTemplate(type)
                    : WidgetService.getImportWidgetTemplate(type);

            if (!widgetTemplate) {
                return;
            }
            // Assign default position
            const indexedPosition = positions[index] ? positions[index] : DefaultPosition;
            widgetTemplate.position = indexedPosition;

            if (widgetTemplate.label && selected.name) {
                widgetTemplate.label.titleText = selected.name;
                widgetTemplate.label.enabled = true;
                (widgetTemplate.options as any).labelTimeRangeEnabled = true;
            }

            if ('TOP_POSTS' === type) {
                (widgetTemplate.options as any).nativeStyling.enabled = true;
                (widgetTemplate.options as any).nativeStyling.border.enabled = true;
                (widgetTemplate.options as any).gridLayout.rows = 1;
                (widgetTemplate.postRequests[0].format
                    .options as FormatBucketsOptions).panels[0].total = 3;
            }

            if ('DUAL_AXIS' === type || 'COMBINATION' === type) {
                const firstChartType =
                    selected.axisElements[1]?.chartType ||
                    selected.chartParams[0].yAxis[0].chartType;
                const secondChartType =
                    selected.axisElements[2]?.chartType ||
                    selected.chartParams[0].yAxis[1]?.chartType ||
                    firstChartType;

                widgetTemplate.options.comboLayers[0].chartType = this.getImportChartTypes(
                    firstChartType
                );
                widgetTemplate.options.comboLayers[1].chartType = this.getImportChartTypes(
                    secondChartType
                );

                widgetTemplate.options.comboLayers[0].options = this.getImportChartOptionsImpl(
                    firstChartType
                ) as ComboChartOptionsUnion;
                widgetTemplate.options.comboLayers[1].options = this.getImportChartOptionsImpl(
                    secondChartType
                ) as ComboChartOptionsUnion;
            }

            if (
                'COUNTER' === type ||
                'COUNTER_SUMMARY' === type ||
                (selected.chartTypes.length > 1 && 'ENGAGEMENT_STATS_CHART' === type)
            ) {
                // Split a counter widget or engagement stats chart into multiple single metrics.
                let axes: any[] =
                    selected.additional && selected.additional.axes
                        ? JSON.parse(selected.additional.axes)
                        : null;

                axes = axes.filter(axis => {
                    return !!axis.measurement;
                });

                const counterPositions =
                    axes.length > 1 && this.dividePosition(indexedPosition, axes.length, 'h');

                if (axes) {
                    return axes.map((axis, counterIndex) => {
                        if (axis) {
                            selected.additional.axes = JSON.stringify([axis]);
                            widgetTemplate.position = counterPositions[counterIndex]
                                ? counterPositions[counterIndex]
                                : indexedPosition;

                            result.push(
                                this.importAnalyticsWidget(
                                    metaInfoService,
                                    dashboard,
                                    selected,
                                    index,
                                    widgetTemplate,
                                    timePeriod,
                                    overrideGlobalTimePeriod,
                                    isPresentations
                                )
                            );
                        }
                    });
                }
            } else {
                if (widgetTemplate.analyticsRequests) {
                    result.push(
                        this.importAnalyticsWidget(
                            metaInfoService,
                            dashboard,
                            selected,
                            index,
                            widgetTemplate,
                            timePeriod,
                            overrideGlobalTimePeriod,
                            isPresentations
                        )
                    );
                } else if (widgetTemplate.postRequests) {
                    result.push(
                        await this.importPostsWidget(
                            type,
                            metaInfoService,
                            dashboard,
                            selected,
                            widgetTemplate,
                            timePeriod,
                            overrideGlobalTimePeriod,
                            isPresentations
                        )
                    );
                }
            }
        });

        return result;
    };

    private importAnalyticsWidget(
        metaInfoService,
        dashboard,
        selected,
        index,
        widgetTemplate,
        importTimePeriod,
        importOverrideGlobalTimePeriod,
        isPresentations
    ) {
        const req = {
            reportingEngine: selected.engines[index]
                ? selected.engines[index]
                : selected.engines[0],
            report: null,
            projections: [],
            groupBys: [],
            filters: [],
            timePeriods: [],
        };

        const templateRequest = new WidgetRequestAnalytics(
            metaInfoService,
            req as AnalyticsRequest
        );
        const widget = PanelTemplatesStore.get(widgetTemplate, templateRequest, isPresentations);
        const request = widget.analyticsRequests[0];

        const coreRequest =
            selected.requests && selected.requests.length ? selected.requests[0] : null;

        // Set Metrics and Dimensions
        if (selected.additional?.axes) {
            this.importAxes(selected, request, metaInfoService, index);

            // Set Widget Filters

            request.filters = [];
            if (coreRequest) {
                this.importFiltersFromRequest(coreRequest.filters, request.filters);
            } else {
                this.importWidgetFilters(selected, request);
            }
        } else {
            // Use the requests to build from instead
            this.importFromRequest(coreRequest, request, metaInfoService);
        }

        this.setReportFromFirstProjection(request);

        this.importDateLimit(request, importTimePeriod);

        // Set Time Period
        request.timePeriods[0] = importTimePeriod.toJSON();
        widget.useGlobalTimePeriod = !importOverrideGlobalTimePeriod;

        // Set Dashboard filters, if not overridden by widget filters
        this.importDashboardFilters(request, selected, dashboard);

        this.addAllAccountsFilter(request);
        this.addAllClientsFilter(request);

        this.importSLAConfig(request, dashboard);

        this.importWidgetSorts(request, selected);

        // Set Page Size - TODO: Not working for some reason.
        if (request && selected.pageInfo) {
            request.page = selected.pageInfo.page;
            request.pageSize = selected.pageInfo.size;
        }
        // et limit
        if (request && selected.requests[0]?.limit) {
            request.limit = selected.requests[0].limit;
        }
        // Import specific styling
        widget.options.metricValueFormat = '1.23k';

        if (widget.type === 'stackedBarChart') {
            widget.options.stackedType = 'b';
            widget.options.stacked100Percent = false;
            request.limit = 100;
        }

        return widget;
    }

    /**
     *
     * @param dashboard
     * @param selected
     * @param widgetTemplate
     */
    private async importPostsWidget(
        type,
        metaInfoService,
        dashboard,
        selected,
        widgetTemplate,
        importTimePeriod,
        importOverrideGlobalTimePeriod,
        isPresentations
    ) {
        const req = Object.assign({}, widgetTemplate.postRequests[0], {
            reportingEngine: selected.engines[0],
        });

        const templateRequest = new WidgetRequestPosts(metaInfoService, req as PostsRequest);
        const widget = PanelTemplatesStore.get(widgetTemplate, templateRequest, isPresentations);

        const request = widget.postRequests[0];

        const widgetIndex = selected?.chartTypes?.findIndex(chartName => chartName === type);

        const selectedRequest = selected?.requests[widgetIndex];

        // reporting enging
        if (selectedRequest && request?.sources?.length > 0) {
            request.sources[0].id.reportingEngine = selectedRequest.reportingEngine;
            request.sources[0].id.report = selectedRequest.report;
        }

        // Axis Elements
        this.importPostAxes(selected, request.sources[0].id, metaInfoService);

        // Set Time Period
        if (request && request.timePeriods) {
            request.sources[0].id.timePeriods[0] = importTimePeriod.toJSON();
            widget.useGlobalTimePeriod = !importOverrideGlobalTimePeriod;
        }

        // Set Filters
        this.importPostFilters(request, selected, dashboard);

        // Set sorts
        await this.importPostSorts(selected, request.sources[0].id, metaInfoService);

        // Set Page Size
        if (request && selected?.pageInfo) {
            request.sources[0].id.page = selected.pageInfo.page;
            request.sources[0].id.pageSize = selected.pageInfo.size;
        }

        return widget;
    }

    // Set Dashboard Filters
    public importDashboardFilters(request, selected, dashboard, isPost?) {
        // Defensive check for no filters.
        if (!dashboard.filters || dashboard.filters.length === 0) {
            return;
        }

        // ignoreAllDashboardFilters, overrideDashboardFilters, and overrideAllDashboardFilters are string-y booleans.
        const ignoreAllDashboardFilters: boolean =
            selected.additional && selected.additional.ignoreAllDashboardFilters === 'true';
        const overrideDashboardFilters: boolean =
            selected.additional && selected.additional.overrideDashboardFilters === 'true';
        const overrideAllDashboardFilters: boolean =
            selected.additional && selected.additional.overrideAllDashboardFilters === 'true';

        // Don't import any dashboard filters if ignored.
        if (ignoreAllDashboardFilters || overrideAllDashboardFilters) {
            return;
        }

        dashboard.filters
            .filter(dashboardFilter => dashboardFilter.values.length > 0)
            .forEach(dashboardFilter => {
                const filterMatches = requestFilter => {
                    if (
                        'UNIVERSAL_CASE_CUSTOM_PROPERTY' === requestFilter.dimensionName &&
                        'UNIVERSAL_CASE_CUSTOM_PROPERTY' === dashboardFilter.field
                    ) {
                        return (
                            requestFilter.details.fieldName === dashboardFilter.details.fieldName
                        );
                    } else {
                        return requestFilter.dimensionName === dashboardFilter.field;
                    }
                };

                const exists: boolean = request.filters.some(requestFilter =>
                    filterMatches(requestFilter)
                );

                if (exists && overrideDashboardFilters) {
                    return; // Don't overwrite the widget filter.
                }

                // Overwrite filter
                // having filter.details present was breaking PostRequests, not sure if this is necessary for AnalyticsReqests
                if (exists) {
                    request.filters.forEach((requestFilter, index) => {
                        if (filterMatches(requestFilter)) {
                            request.filters[index] = {
                                dimensionName: dashboardFilter.field,
                                filterType: dashboardFilter.filterType,
                                values: dashboardFilter.values,
                                details: !isPost && dashboardFilter.details,
                            };
                        }
                    });

                    return;
                }

                // Add filter
                request.filters.push({
                    dimensionName: dashboardFilter.field,
                    filterType: dashboardFilter.filterType,
                    values: dashboardFilter.values,
                    details: !isPost && dashboardFilter.details,
                });
            });
    }

    public addAllAccountsFilter(request) {
        // Add All Accounts filter, if one doesn't exist.
        if (
            request.reportingEngine !== 'LISTENING' &&
            request.filters.filter(f => f.dimensionName === 'ACCOUNT_ID').length === 0
        ) {
            request.filters.push({
                dimensionName: 'ACCOUNT_ID',
                filterType: 'IN',
                values: [],
                details: {
                    allValues: true,
                },
            });
        }
    }

    public addAllClientsFilter(request) {
        if (
            request.reportingEngine !== 'LISTENING' &&
            request.filters.filter(f => f.dimensionName === 'CLIENT_ID').length === 0
        ) {
            request.filters.push({
                dimensionName: 'CLIENT_ID',
                filterType: 'IN',
                values: [],
                details: {
                    allValues: true,
                },
            });
        }
    }

    public importSLAConfig(request, dashboard) {
        // Set SLA config
        if (dashboard.additional && dashboard.additional.slaConfig) {
            if (!request.additional) {
                request.additional = {};
            }
            request.additional.slaConfig = JSON.parse(dashboard.additional.slaConfig);
        }
    }

    public static canImportDashboardWidget(widget): boolean {
        if (!widget || !widget.additional || (!widget.additional.axes && !widget.requests)) {
            return false;
        }

        if (!widget.chartTypes || widget.chartTypes.length === 0) {
            return false;
        }

        if (widget.chartTypes) {
            // Scan for charts we support
            const supportedCharts = widget.chartTypes.filter(type => {
                return null !== WidgetService.getImportWidgetTemplate(type);
            });

            if (!supportedCharts.length) {
                return false;
            }
        }

        return true;
    }

    public static primaryChartType(widget): string {
        if (!widget || !widget.chartTypes || widget.chartTypes.length === 0) {
            return null;
        }

        return widget.chartTypes[0];
    }

    /**
     * Returns an array of positions, as represented by the input division divided horizontally or vertically as indicated
     */
    private dividePosition = (position: any, divisions: number, direction: 'h' | 'v') => {
        const left = parseInt(position.left);
        const top = parseInt(position.top);

        // this is for the standard layout dimensions - if we ever need to import at different sizes; we would need to replace this hard-coded size info
        // this also may need to be changed/added to as we test more dashboards
        const padding = 80;
        const gutter = 20;
        const width = 1920;
        const height = 1080;

        const positionNames = [];
        const positions = [];

        if (divisions === 1) {
            positionNames.push('full');
        } else if ('h' === direction) {
            if (divisions === 2) {
                positionNames.push('half_left', 'half_right');
            } else if (divisions === 3 && position.height === '100%') {
                positionNames.push('third_left', 'third_centered', 'third_right');
            } else if (divisions === 3 && top === 0) {
                positionNames.push('sixth_top_left', 'sixth_top_centered', 'sixth_top_right');
            } else {
                positionNames.push(
                    'sixth_bottom_left',
                    'sixth_bottom_centered',
                    'sixth_bottom_right'
                );
            }
        } else {
            if (divisions === 2) {
                positionNames.push('half_top', 'half_bottom');
            }
        }
        positionNames.forEach(positionName => {
            const position = placeWidget(
                width + 'px',
                height + 'px',
                padding,
                gutter,
                positionName
            );
            positions.push(position);
        });
        return positions;
    };

    /**
     * Arrange widgets in the smallest n x n grid, and scale the grid appropriately
     */
    private getImportWidgetPositions = (count: number) => {
        const positions = [];

        if (!count || count < 0) {
            return positions;
        }

        if (count === 1) {
            return DefaultPosition;
        }

        const n = Math.ceil(Math.sqrt(count));

        const horizontalPadding = 10; // Percent
        const verticalPadding = 10; // Percent
        const widgetWidth = Math.floor((100 - 2 * horizontalPadding) / n);
        const widgetHeight = Math.floor((100 - 2 * verticalPadding) / n);

        for (let y = 0; y < n; y++) {
            for (let x = 0; x < n; x++) {
                positions.push({
                    width: widgetWidth + '%',
                    height: widgetHeight + '%',
                    left: widgetWidth * x + horizontalPadding + '%',
                    top: widgetHeight * y + verticalPadding + '%',
                });
            }
        }

        return positions;
    };

    private getImportChartTypes = (chartType: string) => {
        switch (chartType.toLowerCase()) {
            case 'column':
                return 'comboColumn';
            case 'area':
            case 'areaspline':
                return 'comboArea';
            default:
                return 'comboLine';
        }
    };

    private getImportChartOptionsImpl = (chartType: string) => {
        switch (chartType.toLowerCase()) {
            case 'column':
                return new ComboColumnOptionsImpl();
            case 'area':
            case 'areaspline':
                return new ComboAreaOptionsImpl();
            default:
                return new ComboLineOptionsImpl();
        }
    };
}
