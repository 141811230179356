import * as React from 'react';
import { inject } from 'mobx-react';
import { AxiosInstance } from 'axios';
import { Icon, Button } from 'components/spaceKit';

export interface ClearUrlPlayerState {
    localStorageItemCount?: number;
    logoutUrl?: string;
}

export interface ClearUrlPlayerProps {
    axios?: AxiosInstance;
}

@inject('axios')
export class ClearUrlPlayer extends React.Component<ClearUrlPlayerProps, ClearUrlPlayerState> {
    constructor(props: any, state: ClearUrlPlayerState) {
        super(props, state);
        this.state = {
            localStorageItemCount: 0,
            logoutUrl: this.props.axios.defaults.baseURL + 'logout',
        };
    }

    componentDidMount() {
        this.checkLocalStorageCount();
    }

    private clearLocalStorage() {
        window.localStorage.clear();
        this.checkLocalStorageCount();
    }

    private checkLocalStorageCount() {
        this.setState({
            localStorageItemCount: window.localStorage.length,
        });
    }

    render() {
        const sprinklrLogoutUrl = 'https://app.sprinklr.com/ui/logout';
        const { localStorageItemCount, logoutUrl } = this.state;

        return (
            <div>
                <Icon value='splash' />
                <div>
                    Local storage items: {localStorageItemCount}
                    <Button type='secondary' onClick={() => this.clearLocalStorage()}>
                        Clear
                    </Button>
                </div>
                <p>To clear the Sprinklr and Display API sessions use the following:</p>
                <ul>
                    <li>
                        Sprinklr App logout:{' '}
                        <a href={sprinklrLogoutUrl} target='_blank' rel='noreferrer'>
                            {sprinklrLogoutUrl}
                        </a>
                    </li>
                    <li>
                        Display API logout:{' '}
                        <a href={logoutUrl} target='_blank' rel='noreferrer'>
                            {logoutUrl}
                        </a>
                    </li>
                </ul>
            </div>
        );
    }
}
