import * as React from 'react';
import i18n from 'i18next';
import { action } from 'mobx';
import AuthService from 'services/Auth/AuthService';
import {
    headerTunables,
    opacityTunables,
    smartSizeTunables,
} from 'components/Sidebar/RenderTunables/SharedTunables';
import { CollapseProps, TunablesCollapse } from 'components/Sidebar/RenderTunables/RenderTunables';
import { Widget } from '@sprinklr/stories/widget/Widget';
import { Theme } from 'models/Theme/Theme';
import ActionCheckboxSliderGroup from 'components/_UI/Forms/Actions/ActionCheckboxSliderGroup';
import { GeoStreamWidgetOptionsImpl } from '@sprinklr/display-builder/widgets/GeoStreamWidget/options';
import LabelGroup from 'components/_UI/Forms/LabelGroup/LabelGroup';
import ActionColorSwatchGroup from 'components/_UI/Forms/Actions/ActionColorSwatchGroup';
import { getPostMergedColors } from 'utils/GenerateStyles/GenerateStyles';
import { ColorResult, LabeledSelect } from 'components/spaceKit';
import ActionCheckboxSliderColorSwatchGroup from 'components/_UI/Forms/Actions/ActionCheckboxSliderColorSwatchGroup';
import ActionCheckboxGroup from 'components/_UI/Forms/Actions/ActionCheckboxGroup';
import { regionOptions } from 'components/Panel/PanelEditorSidebar/PanelDesign/PanelDesignOptions';
import { onRegionChange } from '../WorldHeatMapWidget/WorldHeatMapTunables';
import { isSmartTrends } from 'src/widgets/SmartTrendsWidget/utils';

export const GeoStreamTunables = (
    widget: Widget,
    mergedTheme: Theme,
    authService?: AuthService
): TunablesCollapse[] => {
    const { highlightLocation } = widget.options as GeoStreamWidgetOptionsImpl;
    const inheritedColor = getPostMergedColors(widget.theme, mergedTheme).textOnlyBackgroundColor;

    const tickerWidget = widget.children?.[0];
    const mapWidget = widget.children?.[1];

    const containsSmartTrends = isSmartTrends(tickerWidget);

    const handleRegionChange = event => {
        onRegionChange(
            event,
            mapWidget,
            (tickerWidget?.postRequests?.[0]?.sources?.[0] as any)?.id as any,
            false
        );
    };

    return [
        {
            props: {
                label: i18n.t('Geo Stream'),
                startOpen: true,
            } as CollapseProps,
            children: [
                ...headerTunables(widget),
                opacityTunables(widget),
                !containsSmartTrends ? (
                    <LabeledSelect
                        label={i18n.t('Region Filter')}
                        key='widget.options.region'
                        inline
                        value={mapWidget.options.region as any}
                        onChange={handleRegionChange}
                        options={regionOptions}
                    />
                ) : null,
            ],
        },
        {
            props: {
                label: i18n.t('Highlight'),
                startOpen: true,
            } as CollapseProps,
            children: [
                !containsSmartTrends ? (
                    <LabelGroup
                        label={i18n.t('Highlight Color')}
                        key='widget.options.highlightLocation.color'>
                        <ActionColorSwatchGroup
                            title={i18n.t('Highlight Color')}
                            secondaryIcon={!!highlightLocation.color ? 'icon-undo' : undefined}
                            value={null}
                            option={null}
                            color={highlightLocation.color || inheritedColor}
                            onClear={action(() => (highlightLocation.color = ''))}
                            onChange={action(
                                ({ rgb: { r, g, b, a } }: any) =>
                                    (highlightLocation.color = `rgba(${r}, ${g}, ${b}, ${a})`)
                            )}
                        />
                    </LabelGroup>
                ) : null,

                <ActionCheckboxSliderGroup
                    key='widget.options.post.line'
                    label={i18n.t('Connector Line')}
                    showValue
                    checkbox={{
                        option: highlightLocation.line,
                        value: 'enabled',
                    }}
                    slider={{
                        option: highlightLocation.line,
                        value: 'size',
                        min: 0,
                        max: 10,
                    }}
                />,
                <ActionCheckboxGroup
                    key='widget.options.post.line.animate'
                    label={i18n.t('Animate Line')}
                    option={highlightLocation.line}
                    value='animate'
                />,
                <ActionCheckboxSliderGroup
                    key='widget.options.highlightLocation.post.border'
                    label={i18n.t('Post Border')}
                    showValue
                    checkbox={{
                        option: highlightLocation?.post?.border,
                        value: 'enabled',
                    }}
                    slider={{
                        option: highlightLocation?.post?.border,
                        value: 'size',
                        min: 0,
                        max: 10,
                    }}
                />,
                !containsSmartTrends
                    ? [
                          <ActionCheckboxSliderColorSwatchGroup
                              key='widget.options.highlightLocation.post.shadow'
                              label={i18n.t('Drop Shadow')}
                              showValue
                              checkbox={{
                                  option: highlightLocation?.post?.shadow,
                                  value: 'enabled',
                              }}
                              slider={{
                                  option: highlightLocation?.post?.shadow,
                                  value: 'size',
                                  min: 0,
                                  max: 10,
                              }}
                              colorSwatch={{
                                  option: highlightLocation?.post?.shadow,
                                  value: 'color',
                                  onClear: action(
                                      () =>
                                          (highlightLocation.post.shadow.color =
                                              mergedTheme?.typography.color)
                                  ),
                                  onChange: action(
                                      ({ rgb: { r, g, b, a } }: ColorResult) =>
                                          (highlightLocation.post.shadow.color = `rgba(${r}, ${g}, ${b}, ${a})`)
                                  ),
                                  color: !!highlightLocation.post.shadow.color
                                      ? highlightLocation.post.shadow.color
                                      : mergedTheme?.typography.color,
                                  secondaryIcon: highlightLocation.post.shadow.color
                                      ? 'icon-undo'
                                      : undefined,
                              }}
                          />,
                          <ActionCheckboxSliderGroup
                              key='widget.options.highlightLocation.post.radius'
                              label={i18n.t('Border Radius')}
                              showValue
                              checkbox={{
                                  option: highlightLocation?.post?.radius,
                                  value: 'enabled',
                              }}
                              slider={{
                                  option: highlightLocation?.post?.radius,
                                  value: 'size',
                                  min: 0,
                                  max: 100,
                              }}
                          />,
                      ]
                    : null,
            ],
        },
    ];
};

export default GeoStreamTunables;
