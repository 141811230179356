import * as React from 'react';
import i18n from 'i18next';
import {
    headerTunables,
    metricValueTunables,
    opacityTunables,
    percentChangeTunables,
    previousValueTunables,
    smartSizeTunables,
    thresholdsTunables,
} from 'components/Sidebar/RenderTunables/SharedTunables';
import { CollapseProps, TunablesCollapse } from 'components/Sidebar/RenderTunables/RenderTunables';
import { Widget } from '@sprinklr/stories/widget/Widget';
import { Theme } from 'models/Theme/Theme';
import ActionCheckboxGroup from '../../components/_UI/Forms/Actions/ActionCheckboxGroup';

export const MetricWidgetTunables = (widget: Widget, mergedTheme: Theme): TunablesCollapse[] => [
    {
        props: {
            label: i18n.t('Single Metric'),
            startOpen: true,
        } as CollapseProps,
        children: [
            ...headerTunables(widget),
            opacityTunables(widget),
            smartSizeTunables(widget),
            <ActionCheckboxGroup
                key='widget.options.showBackgroundCircle'
                label={i18n.t('Show Background Circle')}
                option={widget.options}
                value='showBackgroundCircle'
            />,
        ],
    },
    {
        props: {
            label: i18n.t('Labels'),
            startOpen: true,
        } as CollapseProps,
        children: [
            ...metricValueTunables(widget, {
                value: { enable: true, size: true },
                format: true,
                name: true,
                change: true,
            }),
            ...percentChangeTunables(widget, mergedTheme, {
                highlight: true,
                format: true,
                color: true,
            }),
            ...previousValueTunables(widget),
        ],
    },
    thresholdsTunables(widget, mergedTheme, 'stackedBar'),
];

export default MetricWidgetTunables;
