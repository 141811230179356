import * as React from 'react';
import { action } from 'mobx';
import { observer } from 'mobx-react';
import { ColorResult, CheckboxSliderColorSwatchGroup } from 'components/spaceKit';

type ActionCheckboxSliderColorSwatchGroupProps = {
    label?: string;
    title?: string;
    disabled?: boolean;
    showValue?: boolean;
    suffix?: string;
    checkbox: {
        checked?: boolean;
        readOnly?: boolean;
        value: string;
        option: any;
    };
    slider: {
        min?: number;
        max?: number;
        value: string;
        option: any;
        disabled?: boolean;
    };
    colorSwatch: {
        option: any;
        onClick?: () => void;
        value: string;
        icon?: string;
        disabled?: boolean;
        toolTip?: string;
        secondaryIcon?: string;
        onChange?: (rgb: ColorResult) => void;
        onClear?: () => void;
        color?: string;
    };
};

const ActionCheckboxSliderColorSwatchGroup: React.FC<ActionCheckboxSliderColorSwatchGroupProps> = ({
    label,
    showValue,
    suffix,
    checkbox,
    slider,
    colorSwatch,
}: ActionCheckboxSliderColorSwatchGroupProps) => (
    <>
        <CheckboxSliderColorSwatchGroup
            label={label}
            slider={{
                value: slider?.option && slider?.value && slider.option[slider.value],
                min: slider && slider.min,
                max: slider && slider.max,
                disabled: slider && slider.disabled,
                onChange: action(
                    (e: React.ChangeEvent<HTMLInputElement>) =>
                        slider?.option &&
                        slider?.value &&
                        (slider.option[slider.value] = parseFloat(e.target.value))
                ),
            }}
            colorSwatch={{
                value: colorSwatch && colorSwatch.color,
                icon:
                    colorSwatch?.option &&
                    colorSwatch?.icon &&
                    colorSwatch.option[colorSwatch.icon],
                disabled: checkbox?.option && checkbox?.value && !checkbox.option[checkbox.value],
                toolTip: colorSwatch && colorSwatch.toolTip,
                secondaryIcon: colorSwatch && colorSwatch.secondaryIcon,
                onChange: colorSwatch && !!colorSwatch.onChange ? colorSwatch.onChange : undefined,
                onClear: colorSwatch && !!colorSwatch.onClear ? colorSwatch.onClear : undefined,
            }}
            checkbox={
                checkbox &&
                slider?.option &&
                slider?.value && {
                    checked: checkbox.option[checkbox.value],
                    onChange: action(
                        (e: React.ChangeEvent<HTMLInputElement>) =>
                            (checkbox.option[checkbox.value] = e.target.checked)
                    ),
                }
            }
            showValue={showValue}
            suffix={suffix}
        />
    </>
);

ActionCheckboxSliderColorSwatchGroup.displayName = 'ActionCheckboxSliderColorSwatchGroup';
export default observer(ActionCheckboxSliderColorSwatchGroup);
