import SignalService from 'services/SignalService/SignalService';
import { Widget } from '@sprinklr/stories/widget/Widget';

export type PlayerEvents = 'ping' | 'play' | 'stop' | 'previous' | 'next' | 'isPlaying';
export const events = ['ping', 'play', 'stop', 'previous', 'next', 'isPlaying'];

enum State {
    stopped,
    playing,
    paused,
}

export class PlayerInterval {
    static ping = 'ping';
    static play = 'play';
    static stop = 'stop';
    static previous = 'previous';
    static next = 'next';
    static isPlaying = 'isPlaying';
    static sequenceEnd = 'sequenceEnd'; //We have hit the end of the list (and would roll over to the start of the list).
    static sequenceBegin = 'sequenceBegin'; //We have hit the beginning the list (and would roll backwards to the end of the list).

    private interval: number | any = 0;
    private intervalTimeout: number | any = 0;
    private playerListening = false;
    private playerAuto = false;
    private player: any;
    private widget: Widget;
    private signalService: SignalService;
    private boundEvent: any;
    private state: State = State.stopped;
    private startedAt = 0;
    private disablePlay: boolean;

    constructor(signalService: SignalService, player: any, boundEvent: any, disablePlay: boolean) {
        this.signalService = signalService;
        this.player = player;
        this.boundEvent = boundEvent;
        this.disablePlay = disablePlay;
    }

    setWidget(widget: Widget) {
        this.widget = widget;
    }

    isPlaying(): boolean {
        return this.state !== State.stopped;
    }

    isPaused(): boolean {
        return this.state === State.paused;
    }

    isWaitVideoEnd(): boolean {
        return this.player && this.player.waitVideoEnd;
    }

    listenEvents(): void {
        if (!this.playerListening) {
            this.signalService.addAll(events, this.boundEvent);
            this.playerListening = true;
        }
    }

    unlistenEvents(): void {
        this.stop();

        if (this.playerListening) {
            this.signalService.removeAll(events, this.boundEvent);
            this.playerListening = false;
        }
    }

    startAuto() {
        if (!this.playerAuto) {
            this.start();
            this.playerAuto = true;
        }
    }

    start() {
        this.setState(State.playing);
    }

    stop() {
        this.setState(State.stopped);
    }

    // pause and resume are designed to be called by components when they
    // have sub-animations or video that could run-over the designated
    // player interval duration.
    //
    // The components call pause(), do what they need, then call resume()
    // when done.
    pause() {
        this.setState(State.paused);
    }

    resume() {
        if (this.state === State.paused) {
            this.setState(State.playing);
        }
    }

    resumeNext() {
        switch (this.state) {
            case State.paused:
                this.setState(State.playing, true);
                break;

            case State.playing:
                this.signalService.dispatch(PlayerInterval.ping, this.widget);
                this.startInternal();
                break;
        }
    }

    private setState(newState: State, forceNext = false) {
        // Start with our old state, then check our new state
        switch (this.state) {
            case State.stopped:
                switch (newState) {
                    case State.playing:
                        this.startInternal();
                        break;

                    case State.paused:
                        // If we're going to pause and we're already stopped, stay stopped
                        newState = State.stopped;
                        break;
                }
                break;

            case State.playing:
                switch (newState) {
                    case State.stopped:
                        this.stopInternal();
                        break;

                    case State.paused:
                        this.pauseInternal();
                        break;
                }
                break;

            case State.paused:
                switch (newState) {
                    case State.stopped:
                        this.stopInternal();
                        break;

                    case State.playing:
                        const durationElapsed = Date.now() - this.startedAt;
                        const durationRemaining = this.player.duration * 1000 - durationElapsed;

                        if (!forceNext && durationRemaining > 0) {
                            this.intervalTimeout = setTimeout(() => {
                                this.intervalTimeout = null;
                                this.signalService.dispatch(PlayerInterval.ping, this.widget);
                                this.startInternal();
                            }, durationRemaining);
                        } else {
                            this.signalService.dispatch(PlayerInterval.ping, this.widget);
                            this.startInternal();
                        }
                        break;
                }
                break;
        }

        this.state = newState;
    }

    private clearInterval() {
        if (this.intervalTimeout) {
            clearTimeout(this.intervalTimeout as number);
            this.intervalTimeout = 0;
        }

        if (this.interval) {
            clearInterval(this.interval as number);
            this.interval = 0;
        }
    }

    private startInternal() {
        if (!this.interval && !this.disablePlay && this.player && this.player.playing) {
            const duration = this.player.duration * 1000;

            this.startedAt = Date.now();

            this.interval = setInterval(() => {
                this.signalService.dispatch(PlayerInterval.ping, this.widget);
            }, duration);

            this.signalService.dispatch(PlayerInterval.isPlaying, this.widget, true);
        }
    }

    private stopInternal() {
        this.signalService.dispatch(PlayerInterval.isPlaying, this.widget, false);
        this.clearInterval();
    }

    private pauseInternal() {
        this.clearInterval();
    }
}
