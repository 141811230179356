import * as React from 'react';
import i18n from 'i18next';
import {
    blackListTunables,
    customLabelTunables,
    customRatioTunables,
    headerTunables,
    legendTunables,
    opacityTunables,
    smartSizeTunables,
    themeTunables,
    totalMetricValueTunables,
    zoomTunables,
} from 'components/Sidebar/RenderTunables/SharedTunables';
import { CollapseProps, TunablesCollapse } from 'components/Sidebar/RenderTunables/RenderTunables';
import { Widget } from '@sprinklr/stories/widget/Widget';
import ActionCheckboxGroup from 'components/_UI/Forms/Actions/ActionCheckboxGroup';
import ActionCheckboxSliderGroup from 'components/_UI/Forms/Actions/ActionCheckboxSliderGroup';
import ActionSliderGroup from 'components/_UI/Forms/Actions/ActionSliderGroup';

export const PieChartTunables = (widget: Widget): TunablesCollapse[] => [
    {
        props: {
            label: i18n.t('Pie Chart'),
            startOpen: true,
        } as CollapseProps,
        children: [...headerTunables(widget), opacityTunables(widget), smartSizeTunables(widget)],
    },
    {
        props: {
            label: i18n.t('Labels'),
            startOpen: true,
        } as CollapseProps,
        children: [
            customLabelTunables(widget, i18n.t('Slice Labels')),
            <ActionSliderGroup
                key='widget.options.labelLineSpace'
                label={i18n.t('Label Spacing')}
                option={widget.options}
                value='labelLineSpace'
                showValue
                min={0}
                max={50}
            />,
            ...customRatioTunables(widget, i18n.t('Slice Percentage')),
            ...totalMetricValueTunables(widget),
        ],
    },
    legendTunables(widget, {
        header: true,
        smartLegendHeader: true,
        headerFontFamily: true,
        orientation: true,
        orientationOpts: ['bottom', 'right'],
        labelSize: true,
        ratio: true,
        value: true,
        wrap: true,
        legendValueFontFamily: true,
        legendRatioFontFamily: true,
        legendNameFontFamily: true,
    }),
    themeTunables(widget, { color: true }),
    {
        props: {
            label: i18n.t('Tunables'),
            startOpen: true,
        } as CollapseProps,
        children: [
            <ActionSliderGroup
                key='widget.options.maxItems'
                label={i18n.t('Max Slices')}
                option={widget.options}
                value='maxItems'
                showValue
                min={2}
                max={15}
            />,
            <ActionSliderGroup
                key='widget.options.customLabelMax'
                label={i18n.t('Max Slice Labels')}
                option={widget.options}
                value='customLabelMax'
                showValue
                min={1}
                max={widget.options.maxItems}
            />,
            <ActionCheckboxGroup
                key='widget.options.showOtherItems'
                label={i18n.t('Group Remaining as Other')}
                option={widget.options}
                value='showOtherItems'
            />,
            <ActionSliderGroup
                key='widget.options.lineWidth'
                label={i18n.t('Line Width')}
                option={widget.options}
                value='lineWidth'
                showValue
                min={1}
                max={15}
            />,
            <ActionCheckboxSliderGroup
                key='widget.options.showInnerRadius'
                label={i18n.t('Inner Radius')}
                showValue
                checkbox={{
                    option: widget.options,
                    value: 'showInnerRadius',
                }}
                slider={{
                    option: widget.options,
                    value: 'innerRadiusSize',
                    min: 0,
                    max: 49,
                }}
            />,
            blackListTunables(widget),
        ],
    },
    zoomTunables(widget, { zoom: true }),
];

export default PieChartTunables;
