import { observable } from 'mobx';
export interface UserSnapshot {
    userId?: number;
    userType?: string;
    firstName?: string;
    lastName?: string;
    emailAddress?: string;
    visibleId?: string;
    profileImageUrl?: string;
    clientId?: number;
    activeClientId?: number;
    partnerId?: number;
    ipAddress?: string;
    locale?: string;
}

export class UserSnapshotImpl implements UserSnapshot {
    @observable userId?: number;
    @observable userType?: string;
    @observable firstName?: string;
    @observable lastName?: string;
    @observable emailAddress?: string;
    @observable locale?: string;
    @observable profileImageUrl?: string;
    @observable visibleId?: string;
    @observable clientId?: number;
    @observable activeClientId?: number;
    @observable partnerId?: number;
    @observable ipAddress?: string;
}

export const UserSnapshotFields = `
    userId userType
    clientId activeClientId partnerId
    visibleId emailAddress
    firstName lastName
    profileImageUrl
    ipAddress
    locale
`;
