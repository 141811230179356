import * as React from 'react';
import styled, { css } from 'components/_UI/designSystem';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import { Flexbox, Button, getPx } from 'components/spaceKit';

type ActionTextAreaBlacklistProps = {
    option: object;
    value: string;
    placeholder?: string;
    className?: string;
    disabled?: boolean;
};

export const StyledTextArea = styled.textarea<{ disabled?: boolean }>`
    width: 100%;
    padding: ${getPx(2)};

    background-color: ${({ theme }) => theme && theme.input.default.background};
    border: 1px solid ${({ theme }) => theme && theme.input.default.border};
    color: ${({ theme }) => theme && theme.input.default.color};
    &::placeholder {
        color: ${({ theme }) => theme && theme.input.placeholder.color};
    }
    &:active,
    &:focus {
        border: 1px solid ${({ theme }) => theme && theme.input.active.border};
        box-shadow: none;
    }

    ${({ theme, disabled }) =>
        disabled &&
        css`
            border: 1px solid ${theme && theme.input.disabled.border};
            color: ${theme && theme.input.disabled.color};
            background-color: ${theme && theme.input.disabled.background};
        `}
`;

class ActionTextAreaBlacklist extends React.Component<ActionTextAreaBlacklistProps> {
    @observable textAreaValue: string | null = null;

    render() {
        const { placeholder, option, value, className, disabled } = this.props;
        return (
            <Flexbox vertical gap className={className}>
                <StyledTextArea
                    rows={10}
                    value={
                        this.textAreaValue || this.textAreaValue === ''
                            ? this.textAreaValue
                            : option[value]
                    }
                    placeholder={placeholder}
                    onChange={this.handleTextArea}
                    disabled={disabled}
                />
                <Button type='secondary' onClick={this.applyList}>
                    Apply
                </Button>
            </Flexbox>
        );
    }

    @action
    handleTextArea = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        this.textAreaValue = e.target.value;
    };

    @action
    applyList = () => {
        if (this.textAreaValue === undefined || this.textAreaValue === null) {
            return;
        }
        // splits on comma, trims outer whitespace, and filters only item with values
        const words: string[] = this.textAreaValue
            .split(',')
            .map(word => word.trim().toLowerCase())
            .filter(s => !!s);

        this.props.option[this.props.value] = words;
        this.textAreaValue = null;
    };
}

export default observer(ActionTextAreaBlacklist);
