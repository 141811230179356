import * as React from 'react';
import i18n from 'i18next';
import {
    headerTunables,
    opacityTunables,
    zoomTunables,
} from 'components/Sidebar/RenderTunables/SharedTunables';
import { TunablesCollapse, CollapseProps } from 'components/Sidebar/RenderTunables/RenderTunables';
import { Widget } from '@sprinklr/stories/widget/Widget';
import LabelGroup from '../../components/_UI/Forms/LabelGroup/LabelGroup';
import ActionInput from '../../components/_UI/Forms/Actions/ActionInput';

const CustomContentSocialPostTunables = (widget: Widget): TunablesCollapse[] => [
    {
        props: {
            label: i18n.t('Social Post'),
            startOpen: true,
        } as CollapseProps,
        children: [...headerTunables(widget, { header: true }), opacityTunables(widget)],
    },
    {
        props: {
            label: i18n.t('Content'),
            startOpen: true,
        },
        children: [
            <LabelGroup label={i18n.t('Url')} key='widget.options.contentSocialPostUrl'>
                <ActionInput option={widget.options} value='contentSocialPostUrl' />
            </LabelGroup>,
        ],
    },
    zoomTunables(widget, { zoom: true, clip: true }),
];

export default CustomContentSocialPostTunables;
