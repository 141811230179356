import * as React from 'react';
import { action } from 'mobx';
import { observer } from 'mobx-react';
import { Widget } from '@sprinklr/stories/widget/Widget';
import { LabeledCheckbox } from 'components/spaceKit';

interface FaceDetectionToolProps {
    widget: Widget;
    colorPickerValue?: string;
}

/**
 * This probably seems overblown for a single checkbox... but this is operating differently from many of the other
 * controls in the design section of the sidebar as it changes properties in the postsRequests as well as widget.options.
 *
 * ThresholdTool.tsx is similar - both could benefit from a good pattern for this.
 */

class FaceDetectionTool extends React.Component<FaceDetectionToolProps, any> {
    @action
    checkboxOnChange = (e: any) => {
        const checked = e.target.checked;
        const { widget } = this.props;

        if (!widget) {
            return; // shouldn't happen...
        }

        if (widget.options) {
            widget.options['imageFaceDetection'] = checked;
        }

        // todo: wtf is "postRequest" singular here??
        if (widget.postRequest) {
            widget.postRequest.includeFaceDetection = checked;
        }

        if (widget.postRequests && widget.postRequests.forEach) {
            widget.postRequests
                .filter(req => req && req.sources && req.sources.forEach && req.sources.filter)
                .forEach((req: any) => {
                    req.sources
                        .filter(src => src.options && src.id)
                        .forEach((src: any) => {
                            src.options.includeFaceDetection = checked;
                            src.id.includeFaceDetection = checked;
                        });
                });
        }
    };

    render() {
        const { widget } = this.props;

        if (!widget || !widget.options) {
            return null; // unlikely but users love to ruin things with JSON editor...
        }

        return (
            <LabeledCheckbox
                label='Smart Crop'
                checked={widget.options.imageFaceDetection}
                onChange={this.checkboxOnChange}
            />
        );
    }
}

export default observer(FaceDetectionTool);
