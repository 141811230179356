import { LayoutConstrained } from 'models/LayoutConstrained/LayoutConstrained';
export class LayoutComparisonService {
    /**
     * Checks two LayoutConstrained objects for matching layouts. Tolerates nulls/undefineds
     * @param one
     * @param two
     * @returns {boolean}
     */
    layoutsMatch(one: LayoutConstrained, two: LayoutConstrained): boolean {
        return (
            !!one &&
            !!two &&
            !!one.layout &&
            !!two.layout &&
            !!one.layout.screens &&
            !!two.layout.screens &&
            one.layout.screens.length === two.layout.screens.length
        );
    }
}
