import classNames from 'classnames';
import * as React from 'react';
import { PostComposition } from 'models/Widget/WidgetType';

const tickerCompositions = ({
    compositionType: type,
    parts,
    config,
    onPostClick,
}: PostComposition): JSX.Element => {
    const {
        userGroup,
        postText,
        media,
        metaGroup,
        icon,
        wrappedIcon,
        engagementMetrics,
        postComments,
        postTitle,
    } = parts;
    const {
        isInterstitialMedia,
        postOuterClasses,
        hasMedia,
        noMedia,
        isPanelPortrait,
        postLayoutOrientation,
    } = config;

    switch (type) {
        case 'a':
            return (
                <div className={postOuterClasses} onClick={onPostClick} onTouchEnd={onPostClick}>
                    <div
                        className={classNames(
                            `post_inner flex rel h-100 ${postLayoutOrientation}`,
                            {
                                'vertical p1': noMedia && !isInterstitialMedia,
                            }
                        )}>
                        {hasMedia &&
                            !isInterstitialMedia && [
                                <div
                                    key='mediaContainer'
                                    className={classNames(`media_group flex rel w-40`, {})}>
                                    {media}
                                </div>,
                                <div
                                    key='textContainer'
                                    className={classNames(
                                        `text_group flex vertical p-1 w-60 h-100`,
                                        {}
                                    )}>
                                    {userGroup}

                                    <div className='post_text_engagement_group flex vertical bottom left'>
                                        {[postText, metaGroup]}
                                    </div>
                                </div>,
                            ]}

                        {noMedia &&
                            !isInterstitialMedia && [userGroup, postTitle, postText, metaGroup]}

                        {isInterstitialMedia && media}
                    </div>
                </div>
            );
        default:
            return <div></div>;
    }
};

export default tickerCompositions;
