import React, { useCallback } from 'react';
import { action, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { LabeledSlider } from 'components/spaceKit';

type ActionSliderGroupProps = {
    value: string;
    option: any;
    label?: string;
    title?: string;
    showValue?: boolean;
    suffix?: string;
    min?: number;
    max?: number;
    disabled?: boolean;
    valAdjust?: number;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const ActionSliderGroup: React.FC<ActionSliderGroupProps> = ({
    title,
    label,
    min,
    max,
    suffix,
    showValue,
    option,
    value,
    disabled,
    valAdjust,
    onChange,
}) => {
    const handleChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            if (onChange) {
                onChange(event);
            } else {
                runInAction(() => (option[value] = parseFloat(event.target.value)));
            }
        },
        [onChange, option, value]
    );
    return (
        <LabeledSlider
            title={title}
            label={label}
            min={min}
            max={max}
            value={option[value]}
            suffix={suffix}
            showValue={showValue}
            disabled={disabled}
            valAdjust={valAdjust}
            onChange={handleChange}
        />
    );
};

ActionSliderGroup.displayName = 'ActionSliderGroup';
export default observer(ActionSliderGroup);
