// This grabbed from https://github.com/mobxjs/mobx-utils

import { action, configure, extendObservable } from 'mobx';

configure({
    // enforceActions: "always"
});

export type IDisposer = () => void;

export const NOOP = () => {};

export const IDENTITY = (_: any) => _;

export function invariant(cond: boolean, message = 'Illegal state') {
    if (!cond) {
        throw new Error('[mobx-utils] ' + message);
    }
}

const deprecatedMessages: string[] = [];
export function deprecated(msg: string) {
    if (deprecatedMessages.indexOf(msg) !== -1) {
        return;
    }
    deprecatedMessages.push(msg);
    console.error('[mobx-utils] Deprecated: ' + msg);
}

export const pixelToPercentage = (childDim, parentDim, decimals?: number) => {
    let result;
    if (decimals) {
        result = +((childDim / parentDim) * 100).toFixed(decimals) + '%';
    } else {
        result = +((childDim / parentDim) * 100) + '%';
    }
    return result;
};

export const numberDiff = (a, b) => {
    return Math.abs(a - b);
};

// ie. setObservable(this.projection, "details", "aggregateConvert", convert)
export const setObservable = action((...args: any[]): void => {
    const extendIt = (source: any, key: string, isParent: boolean, value?: any) => {
        value = value !== undefined ? value : {};

        if (Object.getOwnPropertyDescriptor(source, key) === undefined) {
            const obj = {};
            obj[key] = value;
            extendObservable(source, obj);
        } else if (!isParent || (isParent && (source[key] === undefined || source[key] === null))) {
            source[key] = value;
        }
    };

    if (args.length > 2) {
        let source = args[0];
        let key = args[1];
        const value = args[args.length - 1];
        const count = args.length - 2;

        if (count === 1) {
            extendIt(source, key, false, value);
        } else if (count === 2) {
            extendIt(source, key, true);

            source = source[key];
            key = args[2];
            extendIt(source, key, false, value);
        }
    }
});
