import { action, computed, runInAction, toJS } from 'mobx';
import { ImagePreload } from 'utils/ImagePreload/ImagePreload';
import { Post } from '@sprinklr/stories/post/Post';
import { PostsRequest } from '@sprinklr/stories/post/PostsRequest';
import { Source } from './Source';
import { PostsOptions, SourcePost } from '@sprinklr/stories/post/PostsFormatRequest';
import PostsService from '@sprinklr/stories-services/PostsService/PostsService';
import PostsTransform from '@sprinklr/stories-services/PostsService/PostsTransform';
import SignalService from 'services/SignalService/SignalService';
import SprinklrAPIService, {
    BulkLookupValues,
    PromiseWithCancel,
} from 'services/SprinklrAPIService/SprinklrAPIService';
import { WidgetDimensionKey } from 'utils/Widget/WidgetDimensionKey';

export class SourcePosts extends Source<Post, PostsRequest> {
    options: PostsOptions = {
        imagePreload: false,
        removeSensitivePosts: true,
        includeQuoteTweets: false,
    };
    private preload = new ImagePreload<Post>();
    private duplicateCheck = {};
    private loaded = false;
    private performUpdate = true;
    settingPosts = false;

    constructor(
        signalService: SignalService,
        private readonly postsService: PostsService,
        private readonly sprinklrAPIService: SprinklrAPIService,
        request: PostsRequest,
        options: PostsOptions,
        posts?: Post[],
        private disableImagePreload?: boolean
    ) {
        super(signalService, 'snMsgId', request, options.ordered);

        this.options = options;

        if (
            !this.disableImagePreload &&
            options.imagePreload &&
            options.imagePreloadBlacklist !== undefined &&
            !options.imagePreloadBlacklist
        ) {
            this.preload.setBlacklist(false);
        }

        // We have post data already provided
        if (posts) {
            this.performUpdate = false;

            // Note: This timeout required so that image preload has chance to finish before
            // assigning posts. Without, you end up with posts with bad images within them.
            setTimeout(() => {
                this.setDataS3(posts);
            });
        }
    }

    static create(
        signalService: SignalService,
        postsService: PostsService,
        sprinklrAPIService: SprinklrAPIService,
        source: SourcePost,
        disableImagePreload = false
    ): SourcePosts {
        return new SourcePosts(
            signalService,
            postsService,
            sprinklrAPIService,
            source.id,
            source.options,
            source.data,
            disableImagePreload
        );
    }

    // asStructure makes sure observer won't be signaled only if the
    // dimensions object changed in a deepEqual manner
    // @observable windowDimensions = asStructure({
    //     width: jquery(window).width(),
    //     height: jquery(window).height()
    // });

    isLoaded(): boolean {
        return this.loaded;
    }

    isShuffle(): boolean {
        return this.options && this.options.shufflePosts;
    }

    needsUpdate(): boolean {
        return this.performUpdate;
    }

    updateItems(): PromiseWithCancel<Post[]> {
        this.request.removeSensitivePosts = this.options.removeSensitivePosts;
        this.request.includeQuoteTweets = this.options.includeQuoteTweets;
        return this.postsService.getPosts(this.request);
    }

    @computed get items(): Post[] {
        return this.itemsInternal;
    }

    // Posts data comes from S3 JSON payload, not API call via PostsService
    setDataS3(posts: Post[]) {
        posts = toJS(posts);
        posts = PostsTransform.transform(posts);
        this.setData(posts);
    }

    setData(posts: Post[]) {
        // Guard against recursive entry.  Image preload below is async, and this can
        // be called recursively before it returns and that can cause data sync issues via ArrayDiff.
        //   https://sprinklr.atlassian.net/browse/DISPLAY-1377
        if (this.settingPosts) {
            return;
        }

        this.settingPosts = true;
        this.loaded = true;

        // didn't want to pollute this.loaded so we're watching this.builderLoaded
        // Hacktown: setTimeout to get around updating observable in computed
        setTimeout(() => {
            runInAction(() => (this.builderLoaded = true));
        });

        let post: Post;
        let x = posts.length;
        while (x--) {
            post = posts[x];

            if (!post.unique) {
                post.unique = post.snMsgId;
            }
        }

        const changed = this.diff.merge(this.itemsInternal, posts);
        if (changed) {
            if (this.options.removeDuplicates) {
                this.removeDuplicates(changed.added, changed.removed);
            }

            if (!changed.isEmpty()) {
                if (!this.disableImagePreload && this.options.imagePreload) {
                    this.preload.run(changed.added, true).then(
                        action(() => {
                            this.diff.sync(this.itemsInternal, changed);

                            if (!changed.isEmpty()) {
                                this.signalService.dispatch(Source.changed, this);
                            }

                            this.loadStoryImages(changed.added);
                            this.settingPosts = false;
                        })
                    );
                } else {
                    this.diff.sync(this.itemsInternal, changed);
                    this.signalService.dispatch(Source.changed, this);

                    this.loadStoryImages(changed.added);
                    this.settingPosts = false;
                }
            } else {
                this.settingPosts = false;
            }
        } else {
            this.settingPosts = false;
        }

        // Arrays in mobx are wrapped in iObservableArray objects with own methods, hence the need for the cast
        // (<IObservableArray<Post>> this.itemsInternal).replace(posts);
    }

    // Called by  evaluateSubTree() in Bucket.ts
    static evaluate(property: string, post: any): string {
        switch (property) {
            case 'type':
                if (post.videos.length != 0) {
                    return 'video';
                } else if (post.images.length != 0) {
                    return 'image';
                }

                return 'text';

            case 'image':
                return post.images.length && post.images[0];

            case 'video':
                return post.videos.length && post.videos[0];

            default:
                return null;
        }
    }

    static testPostsAdd(dest: Post[], total: number, amount: number): number {
        for (let x = total; x < total + amount; x++) {
            this.testPost(x + '', 'David Boyd test post #' + x, dest);
        }

        return total + amount;
    }

    static testPost(id: string, message: string, dest: Post[]) {
        dest.push({
            partnerId: 349,
            clientId: 3475,
            sourceId: -1,
            sourceType: 'LISTENING',
            snType: 'twitter',
            snMsgId: id,
            permalink: 'https://twitter.com/show/status/1072504469018083333',
            message: message,
            senderProfile: {
                id: '1',
                age: 0,
                name: '\u30ed\u30fc\u30bd\u30f3',
                screenName: 'akiko_lawson',
                bio:
                    '\u3010\u30ed\u30fc\u30bd\u30f3\u516c\u5f0fTwitter\u3011\u30ed\u30fc\u30bd\u30f3\u30af\u30eb\u30fc\u266a\u3042\u304d\u3053\u3067\u3059(^^)\u6700\u65b0\u60c5\u5831\u3092\u30de\u30a4\u30da\u30fc\u30b9\u3067\u304a\u77e5\u3089\u305b\u3057\u307e\u3059\u3002instagram\u306f https://www.instagram.com/akiko_lawson/ \u2606 youtube\u306fhttps://m.youtube.com/user/lawsonnews',
                following: 194131,
                followers: 3869521,
                favCount: 209,
                statusCount: 35122134,
                permalink: 'https://twitter.com/akiko_lawson',
                createdTime: '1266578160000',
                profileImgUrl:
                    'http://pbs.twimg.com/profile_images/875517108884455426/q3HMm7hU_normal.jpg',
                verified: true,
                participationIndex: 0,
                influencerIndex: 0,
                spamIndex: 0,
                snId: '1',
            },
            snCreatedTime: 1544539966240,
            snStats: {
                contentLength: 141,
                reachCount: 3858880,
                numFollowers: 3858880,
            },
            workflowProperties: {
                sentiment: 0,
                isSpam: false,
                isProfane: false,
                tags: [],
            },
            language: 'en',
            images: [
                {
                    type: 'PHOTO',
                    url: 'https://pbs.twimg.com/media/DuIln8OWsAI2Ecq.jpg',
                },
            ],
            videos: [],
            unique: id,
        });
    }

    // - Two or more text posts verbatim have the same text; remove all duplicates and show oldest post (by post time)
    // - Two or more posts verbatim have the same message text but different images, do not remove any posts
    // - Two or more posts verbatim have the same message text and image; remove all duplicates and show oldest post (by post time)
    //
    //   See https://sprinklr.atlassian.net/browse/DISPLAY-761
    @action
    private removeDuplicates(added: Post[], removed: Post[]): void {
        let post: Post;
        let key: string;
        let found: string;
        const removeOffsets: number[] = [];

        added.forEach((post, offset) => {
            key = this.getMessage(post); // + (post.images.length ? ":" + post.images[0].url : "");

            // Remove duplicate newer post
            if (this.duplicateCheck[key]) {
                removeOffsets.unshift(offset);
            } else {
                this.duplicateCheck[key] = post.snMsgId;
            }
        });

        // Remove the duplicates
        removeOffsets.forEach(offset => added.splice(offset, 1));

        removed.forEach(post => {
            key = this.getMessage(post); //  + (post.images.length ? ":" + post.images[0].url : "");

            // If our original post that we prevented duplicates on has been removed, then
            // remove its value in the map so that new posts with this id can be shown.
            found = this.duplicateCheck[key];
            if (found === post.snMsgId) {
                delete this.duplicateCheck[key];
            }
        });
    }

    // HACKTOWN:  This is required because images for stories are fetched from a 3rd party provider
    // which takes 1 to 10 seconds.  This replicates the behavior on Space, where they load the
    // placeholder image first, then call the provider to resolve to the real images and finally
    // update in-place.
    private loadStoryImages(added: Post[]): void {
        const posts = {};
        const args: string[] = [];

        added.forEach(post => {
            if (post.seedUrl) {
                posts[post.seedUrl] = post;
                args.push(post.seedUrl);
            }
        });

        if (args.length) {
            this.sprinklrAPIService
                .bulkLookup(
                    'STORY_MESSAGE',
                    'STORY_MESSAGE',
                    new WidgetDimensionKey('STORY_URL_IMAGE'),
                    args,
                    null,
                    null
                )
                .then((results: BulkLookupValues) => {
                    const keys = Object.keys(results);
                    if (keys.length) {
                        keys.forEach(key => {
                            PostsTransform.updateImageUrl(posts[key], results[key]);
                        });

                        this.signalService.dispatch(Source.changed, this);
                    }
                });
        }
    }

    private getMessage(post: Post): string {
        // Remove mobx from the post
        post = toJS(post);

        let message = post.message;

        if (post.textEntities) {
            const entities = post.textEntities.message;

            if (entities) {
                let entity: any;
                let start: number;
                let stop: number;
                let found: any;
                const fixSprinklr = {};

                // Ensure sorted ascending because result will be screwed up if not!
                entities.sort((a: any, b: any) => a.indices[0] - b.indices[0]);

                // HACKTOWN:  Need to preprocess and merge duplicate url entities because of
                // bug I discovered in textEntities payload from Sprinklr.
                //
                // textEntities can have duplicates for urls like so:
                //
                // {
                //   "indices": [
                //     102,
                //     125
                //   ],
                //   "url": "https:\/\/t.co\/XbfclE1DNn"
                // },
                // {
                //   "indices": [
                //     106,
                //     129
                //   ],
                //   "url": "https:\/\/t.co\/XbfclE1DNn"
                // }

                let x = entities.length;
                while (x--) {
                    entity = entities[x];

                    if (entity.url) {
                        found = fixSprinklr[entity.url];

                        if (!found) {
                            fixSprinklr[entity.url] = entity.indices;
                        } else {
                            // Do the ranges intersect?
                            if (entity.indices[0] <= found[1] && entity.indices[1] >= found[0]) {
                                found[0] = Math.min(entity.indices[0], found[0]);
                                found[1] = Math.max(entity.indices[1], found[1]);

                                entities.splice(x, 1);
                            }
                        }
                    }
                }

                x = entities.length;
                while (x--) {
                    entity = entities[x];

                    if (entity.url) {
                        start = entity.indices[0];
                        stop = entity.indices[1];

                        if (message) {
                            message = message.slice(0, start) + message.slice(stop);
                        }
                    }
                }
            }
        }

        // Remove all spaces in message text
        if (message && message !== ' ') {
            message = message.replace(/[ \n\r]/g, '');
            // If no "message" field, then can't determine if duplicate, so return unique snMsgId instead
            // Corie said this is way to go - 2/6/18
        } else {
            message = post.snMsgId;
        }

        return message;
    }
}
