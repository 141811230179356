import { AnalyticsRequest, GroupBy, Sort } from './PanelTemplatesTheme/PanelTemplatesTheme';
import { Panel } from '../../models/Panel/Panel';
import { WIDGET_LABEL_SIZE } from '../../components/Widget/WidgetLabel/constants';
import ListeningDimensions from '@sprinklr/stories-services/AnalyticsService/ListeningDimensions';

export var TemplateConversationTopics: Panel = {
    id: 'conversation-topics',
    name: 'Conversation Topics',
    description: '',
    tags: ['Smart', 'Conversation Topics', 'landscape', 'portrait'],
    widget: {
        id: '1',
        position: {
            width: '1920px',
            height: '1080px',
            top: '0',
            left: '0',
        },
        classes: 'template_conversation_topics',
        children: [
            {
                type: 'wordCloud',
                id: '1',
                name: 'Word Cloud',
                useGlobalTimePeriod: true,
                analyticsRequests: [
                    // Note: All global variables like reportingEngine, report, filters, and projections
                    // are injected via AnalyticsRequest.getRequests()
                    new AnalyticsRequest({
                        limit: 100,
                        groupBys: [
                            new GroupBy({
                                LISTENING: { source: ListeningDimensions.wordCloudMessage },
                                INBOUND_MESSAGE: { dimensionName: 'HASHTAGS' },
                                PLATFORM: null /* This engine type not supported for this template */,
                                BENCHMARKING: { dimensionName: 'HASHTAGS' },
                                PAID: { dimensionName: 'imageTag' },
                                ADVOCACY: { dimensionName: 'COMMUNITY_USER_ID' },
                                RDB_FIREHOSE: { dimensionName: 'RDB_QUERY_IDS' },
                                STORY_MESSAGE: { dimensionName: 'WORD_CLOUD_MESSAGE' },
                            }),
                        ],
                        sorts: [
                            new Sort({ order: 'DESC', projections: 0 }),
                            new Sort({ order: 'ASC', groupBys: 0 }),
                        ],
                        timePeriods: [{ key: 'last_7_days' }],
                    }) as any,
                ],
                position: {
                    width: '60%',
                    height: '100%',
                    top: '0',
                    left: '0%',
                    portrait: {
                        width: '100%',
                        height: '60%',
                        top: '40%',
                        left: '0%',
                    },
                },
                label: {
                    titleText: 'Brand Keywords',
                    enabled: true,
                    size: WIDGET_LABEL_SIZE.secondary,
                },
                options: {
                    player: {
                        playing: true,
                        duration: 15,
                    },
                    padding: 20,
                    limit: 100,
                    minFontSize: 30,
                    maxFontSize: 120,
                    showSentiment: false,
                },
            },
            {
                type: 'bubbleChart',
                id: '2',
                name: 'Bubble Chart',
                useGlobalTimePeriod: true,
                analyticsRequests: [
                    // Note: All global variables like reportingEngine, report, filters, and projections
                    // are injected via AnalyticsRequest.getRequests()
                    new AnalyticsRequest({
                        limit: 3,
                        groupBys: [
                            new GroupBy({
                                LISTENING: { source: ListeningDimensions.wordCloudMessage },
                                INBOUND_MESSAGE: { dimensionName: 'ACCOUNT_ID' },
                                PLATFORM: { dimensionName: 'ACCOUNT_ID' },
                                BENCHMARKING: { dimensionName: 'BRAND_ID' }, //BENCHMARKING_ACCOUNT_ID
                                PAID: { dimensionName: 'accountIds' },
                                ADVOCACY: { dimensionName: 'COMMUNITY_USER_ID' },
                                STORY_MESSAGE: { dimensionName: 'WORD_CLOUD_MESSAGE' },
                            }),
                        ],
                        sorts: [
                            new Sort({ order: 'DESC', projections: 0 }),
                            new Sort({ order: 'ASC', groupBys: 0 }),
                        ],
                        timePeriods: [{ key: 'last_7_days' }, { previousPeriod: true }],
                    }) as any,
                ],
                position: {
                    width: '40%',
                    height: '100%',
                    top: '0',
                    left: '60%',
                    portrait: {
                        width: '100%',
                        height: '40%',
                        top: '0',
                        left: '0',
                    },
                },
                label: {
                    titleText: 'Top Keywords',
                    overlap: true,
                    enabled: true,
                    size: WIDGET_LABEL_SIZE.secondary,
                },
                options: {
                    player: {
                        playing: true,
                        duration: 15,
                    },
                    labelSize: 12,

                    showMetricValue: true,
                    metricValueSize: 45,

                    showMetricName: true,
                    metricNameSize: 12,

                    showMetricChange: false,
                    metricPercentChangeSize: 14,
                    showSentiment: false,
                },
            },
        ],
    },
};
