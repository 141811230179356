import * as React from 'react';
import i18n from 'i18next';
import { opacityTunables } from 'components/Sidebar/RenderTunables/SharedTunables';
import { CollapseProps, TunablesCollapse } from 'components/Sidebar/RenderTunables/RenderTunables';
import { Theme } from 'models/Theme/Theme';
import { Widget } from '@sprinklr/stories/widget/Widget';
import LabelGroup from '../../components/_UI/Forms/LabelGroup/LabelGroup';
import { verticalAlignOptions } from 'components/Panel/PanelEditorSidebar/PanelDesign/PanelDesignOptions';
import SupportsRichText from '../../components/Panel/PanelEditorSidebar/PanelDesign/SupportsRichText';
import ActionSliderGroup from '../../components/_UI/Forms/Actions/ActionSliderGroup';
import ActionSegmentedGroup from '../../components/_UI/Forms/Actions/ActionSegmentedGroup';
import ActionColorSwatchGroup from '../../components/_UI/Forms/Actions/ActionColorSwatchGroup';
import { CustomContentRichTextOptionsImpl } from './options';
import { Flexbox } from 'components/spaceKit';

const CustomTableRichTextTunables = (widget: Widget, mergedTheme: Theme): TunablesCollapse[] => [
    {
        props: {
            label: i18n.t('Cell'),
            startOpen: true,
        } as CollapseProps,
        children: [
            opacityTunables(widget),
            <SupportsRichText key='supportsRichText' widget={widget} mergedTheme={mergedTheme} />,
            <ActionSliderGroup
                key='widget.options.contentPadding'
                label={i18n.t('Padding')}
                option={widget.options}
                value='contentPadding'
                showValue
                min={0}
                max={100}
            />,
            <ActionSegmentedGroup
                key='widget.options.verticalAlign'
                label={i18n.t('Vertical Align')}
                option={widget.options}
                value='verticalAlign'
                options={verticalAlignOptions}
            />,
            <Flexbox gap='m' key='rich text tunable colors'>
                <LabelGroup
                    label={i18n.t('Back')}
                    key='label widget.options.contentBackgroundColor'>
                    <ActionColorSwatchGroup
                        key='widget.options.contentBackgroundColor'
                        option={widget.options}
                        value='contentBackgroundColor'
                        secondaryIcon={
                            (widget.options as CustomContentRichTextOptionsImpl)
                                .contentBackgroundColor
                        }
                    />
                </LabelGroup>
                <LabelGroup label={i18n.t('Bullets')} key='label widget.options.bulletColor'>
                    <ActionColorSwatchGroup
                        key='widget.options.bulletColor'
                        value={'bulletColor'}
                        option={widget.options}
                        secondaryIcon={
                            (widget.options as CustomContentRichTextOptionsImpl).bulletColor
                        }
                    />
                </LabelGroup>
            </Flexbox>,
        ],
    },
];

export default CustomTableRichTextTunables;
