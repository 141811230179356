//
// Resolves meta-info based on data within posts
//
import { action, extendObservable } from 'mobx';
import { CancelTokenSource } from 'axios';
import { Post, PostMedia, PostProduct } from '@sprinklr/stories/post/Post';
import { WidgetDimensionKey } from 'utils/Widget/WidgetDimensionKey';
import {
    BulkLookupValues,
    CustomField,
    CustomFieldSearch,
    default as SprinklrAPIService,
    PromiseWithCancel,
} from 'services/SprinklrAPIService/SprinklrAPIService';
import { ImageResult, Intuition, IntuitionImage } from 'models/Intuition/Intuition';
import InstagramImageService from './InstagramImageService';
import { IntuitionService } from 'services/IntuitionService/IntuitionService';
import PostsService from './PostsService';
import PostsTransform from './PostsTransform';
import PostsProviderReporting from './providers/PostsProviderReporting';

export default class PostsResolve {
    constructor(
        private readonly sprinklrAPIService: SprinklrAPIService,
        private readonly intuitionService: IntuitionService,
        private readonly instagramImageService: InstagramImageService
    ) {}

    @action
    async inReplyToPosts(posts: Post[]): Promise<Post[]> {
        const postParentIds: string[] =
            posts &&
            posts
                .filter(post => post.parentUniversalMessageKey !== null)
                .map(
                    (post: Post) =>
                        post.parentUniversalMessageKey &&
                        post.parentUniversalMessageKey.universalMessageId
                );

        const inReplyToPosts: BulkLookupValues =
            postParentIds.length > 0 && (await this.getInReplyToPosts(postParentIds));

        inReplyToPosts &&
            posts
                .filter(post => post.parentUniversalMessageKey)
                .forEach(post => {
                    const parent =
                        post.parentUniversalMessageKey &&
                        inReplyToPosts[post.parentUniversalMessageKey.universalMessageId];
                    parent && PostsTransform.transformPost(parent, undefined); // parent might be deleted
                    post.inReplyToPost = parent as any;
                });

        return posts;
    }

    private async getInReplyToPosts(postIds: string[]): Promise<BulkLookupValues> {
        const type = { name: 'ES_MESSAGE_ID', isCustom: false } as WidgetDimensionKey;
        return this.sprinklrAPIService
            .bulkLookup('LISTENING', undefined, type, postIds, undefined)
            .then((bulklookupValue: BulkLookupValues) => {
                return bulklookupValue;
            });
    }

    /**
     * Use the Intuition service to detect faces & add that info to the post images.
     * @param posts
     */
    async detectFaces(posts: Post[]): Promise<Post[]> {
        // Make a map of images, and be careful in case of repeated urls!
        const imagesKeyedByUrls: { [key: string]: PostMedia[] } | {} = {};
        posts
            .filter(post => post && post.images && post.images[0] && post.images[0].url)
            .forEach(post => {
                const image = post.images[0];
                if (!imagesKeyedByUrls[image.url]) {
                    imagesKeyedByUrls[image.url] = [image];
                } else {
                    imagesKeyedByUrls[image.url].push(image);
                }
            });

        await this.intuitionService
            .detectFaces(Object.keys(imagesKeyedByUrls))
            .then((intuition: Intuition) => {
                intuition.images.forEach((image: IntuitionImage) => {
                    const postImages = imagesKeyedByUrls[image.imageUrl];
                    const faceResult: ImageResult = image.results.find(
                        result =>
                            // dcbs TODO: it's awkward here - our API wrapper should make this less weird
                            // Check for success & class name "FACE" as opposed to "NO_FACE".
                            result.success &&
                            result.classifications.some(cls => cls.name === 'FACE')
                    );
                    if (postImages && faceResult) {
                        postImages.forEach(postImage => {
                            postImage.enrichment = {
                                width: faceResult.width,
                                height: faceResult.height,
                                faces: {
                                    type: 'faces',
                                    boundingBox: faceResult.boundingBox,
                                    features: faceResult.classifications.map(cls => ({ ...cls })),
                                },
                            };
                            // console.log('added intuition enrichments: ', postImage);
                        });
                    }
                });
            });

        return posts;
    }

    filterBrokenImages = async (posts: Post[], usePlaceholder = false): Promise<Post[]> => {
        const promises = posts.map((post: Post, index) => {
            if (!post.images || !post.images[0]) {
                return Promise.resolve();
            }

            return this.instagramImageService
                .isValidImageUrl(post.images[0].url)
                .then((isValid: boolean) => {
                    if (!isValid) {
                        // This is a Corie request for Visa.
                        // For PAID, match the presentation that Space uses.
                        // If a post has an invalid image, it uses a fallback SVG
                        // instead.  It doesn't remove the post. 5/21/20
                        if (usePlaceholder) {
                            post.images[0].url = 'image-fallback';
                        } else {
                            posts.splice(index, 1);
                        }
                    }
                });
        });

        return Promise.all(promises).then(() => {
            return posts;
        });
    };

    // Unlike filterBrokenImages above, no filtering is performed here as that seems to occur... elsewhere for videos.
    // Just check if the poster image is loadable and if it's not, replace with the special image-fallback string val.
    fixBrokenVideoPosters = async (posts: Post[]): Promise<Post[]> => {
        const promises = posts.map((post: Post) => {
            if (!post.videos || !post.videos[0]) {
                return Promise.resolve();
            }

            return this.instagramImageService
                .isValidImageUrl(post.videos[0].poster)
                .then((isValid: boolean) => {
                    if (!isValid) {
                        post.videos[0].poster = 'image-fallback';
                    }
                });
        });

        return Promise.all(promises).then(() => {
            return posts;
        });
    };

    fixExpiredInstagram = async (posts: Post[]): Promise<Post[]> => {
        const promises: Array<Promise<void>> = posts.map(async (post: Post, index: number) => {
            if (!post.snType || post.snType.toLowerCase() !== 'instagram' || !post.permalink) {
                return;
            }

            // Don't correct url if hosted on s3
            if (
                post.videos?.length &&
                post.videos[0].url &&
                post.videos[0].url.includes(PostsService.S3_URL)
            ) {
                return;
            }

            // Don't correct url if hosted on s3
            if (
                post.images?.length &&
                post.images[0].url &&
                post.images[0].url.includes(PostsService.S3_URL)
            ) {
                return;
            }

            // If the video's poster url is valid, don't replace the video url.
            const needVideos =
                post.videos?.length &&
                (!post.videos[0].poster ||
                    (post.videos[0].poster &&
                        !(await this.instagramImageService.isValidImageUrl(
                            post.videos[0].poster
                        ))));

            const needImages =
                post.images?.[0]?.url &&
                !(await this.instagramImageService.isValidImageUrl(post.images[0].url));

            if (needVideos || needImages) {
                try {
                    const response = await this.sprinklrAPIService.getInstagramOEmbed(
                        post.accountId,
                        post.permalink
                    );

                    if (needVideos) {
                        post.videos[0].poster = response.thumbnail_url;
                    }

                    if (needImages) {
                        post.images[0].url = response.thumbnail_url;
                    }
                } catch (e) {
                    console.log('oembed lookup failed for ' + post.permalink);
                }
            }
        });

        await Promise.all(promises);

        return posts;
    };

    @action
    async alertPosts(posts: Post[]): Promise<Post[]> {
        const promises: Array<Promise<void>> =
            posts &&
            posts.map(async (post: Post) => {
                extendObservable(post, { actualTweets: undefined });
                const alertPosts =
                    (post as any).module !== 'BENCHMARKING' && (await this.getPostsOnAlert(post));

                if (alertPosts && alertPosts.length > 0) {
                    const transformedPosts = await PostsTransform.transform(alertPosts);
                    post.actualTweets = transformedPosts;
                }
            });

        (await promises) && promises.length && Promise.all(promises);
        return posts;
    }

    private getPostsOnAlert(post: Post): PromiseWithCancel<Post[]> {
        const hasCustom =
            post.hasOwnProperty('channelCustomProperties') && post.channelCustomProperties;
        const topicGroups =
            hasCustom &&
            post.channelCustomProperties.hasOwnProperty(
                'CHANNELπSPRINKLR_ALERTπlst_topic_group_id'
            );
        const topicIds =
            hasCustom &&
            post.channelCustomProperties.hasOwnProperty('CHANNELπSPRINKLR_ALERTπlst_topic_id');
        const topicTags =
            hasCustom &&
            post.channelCustomProperties.hasOwnProperty('CHANNELπSPRINKLR_ALERTπlst_topic_tag');
        const data = (post as any).alertDetails && (post as any).alertDetails[0].data;

        const engine = new PostsProviderReporting(this.sprinklrAPIService);

        return engine.request({
            source: 'LISTENING_COLUMN',
            reportingEngine: 'LISTENING',
            page: 0,
            pageSize: 15,
            timePeriod: data
                ? {
                      key: 'custom',
                      wholePeriods: false,
                      duration: data[post.alertDetails.length - 1].tS - data[0].tS,
                      startTime: data[0].x,
                      endTime: data[post.alertDetails[0].data.length - 1].tS,
                  }
                : {
                      key: 'last_24_hours',
                  },
            sorts: [
                {
                    order: 'DESC',
                    heading: 'TWITTER_RETWEETS',
                    isDimension: false,
                },
            ],
            filters: [
                topicGroups && {
                    dimensionName: 'TOPIC_GROUP_IDS',
                    filterType: 'IN',
                    values: (post as any).channelCustomProperties.CHANNELπSPRINKLR_ALERTπlst_topic_group_id.map(
                        id => id
                    ),
                },
                topicIds && {
                    dimensionName: 'TOPIC_IDS',
                    filterType: 'IN',
                    values: (post as any).channelCustomProperties.CHANNELπSPRINKLR_ALERTπlst_topic_id.map(
                        id => id
                    ),
                },
                topicTags && {
                    dimensionName: 'TOPIC_TAGS',
                    filterType: 'IN',
                    values: (post as any).channelCustomProperties.CHANNELπSPRINKLR_ALERTπlst_topic_tag.map(
                        id => id
                    ),
                },
            ],
        });
    }

    async comments(posts: Post[], includeComments: boolean): Promise<Post[]> {
        const promises: Array<Promise<void>> =
            includeComments &&
            posts &&
            posts.map(async (post: Post) => {
                const comments = await this.sprinklrAPIService.getPostComments(post, 0, 20);
                if (comments && comments.length > 0) {
                    const transformedComments = PostsTransform.transform(comments);
                    post.comments = transformedComments.filter(
                        comment => comment.snMsgId !== post.snMsgId
                    );
                }
            });

        (await promises) && promises.length && Promise.all(promises);
        return posts;
    }

    // Used for embed.  For text-only posts, inserts images from custom field "Screenshot"
    customImageUrl(posts: Post[], customKey = 'Screenshot'): Promise<Post[]> {
        return (
            this.sprinklrAPIService &&
            this.sprinklrAPIService
                .searchCustomFields({ keyword: customKey })
                .then((customFields: CustomField[]) => {
                    const customKeyId: string =
                        customFields && this.getCustomPropertyId(customFields, customKey);
                    if (customKeyId) {
                        posts.map(post => {
                            if (
                                post.workflowProperties &&
                                post.workflowProperties.partnerCustomProperties &&
                                !post.images.length
                            ) {
                                const match: string[] =
                                    post.workflowProperties.partnerCustomProperties[customKeyId];
                                if (match && match.length) {
                                    post.images = [
                                        {
                                            url: match[0],
                                        },
                                    ];
                                }
                            }
                            return post;
                        });
                    }
                    return posts;
                })
        );
    }

    // Used for embed.  Adds custom field display name aliases into partnerCustomProperties map
    customFields(posts: Post[]): Promise<Post[]> {
        return (
            this.sprinklrAPIService &&
            this.sprinklrAPIService.searchCustomFields().then((customFields: CustomField[]) => {
                const map = this.customFieldMap(customFields);

                posts.forEach(post => {
                    const properties = post?.workflowProperties?.partnerCustomProperties;
                    if (properties) {
                        for (const key in properties) {
                            if (map[key]) {
                                properties[map[key]] = properties[key];
                            }
                        }
                    }

                    return post;
                });

                return posts;
            })
        );
    }

    productTags(
        posts: Post[],
        productFieldNames?: string[],
        cancelSource?: CancelTokenSource
    ): Promise<Post[]> {
        let fieldNamesPromise: Promise<string[]>;
        if (productFieldNames) {
            fieldNamesPromise = Promise.resolve(productFieldNames);
        } else {
            fieldNamesPromise = this.getProductFieldNames(posts, cancelSource);
        }

        return fieldNamesPromise.then(fieldNames => {
            if (!fieldNames || fieldNames.length === 0) {
                return Promise.resolve(posts);
            }

            return this.getProducts(posts, fieldNames, cancelSource).then(
                (productMap: { [productId: string]: any }) => {
                    if (!productMap || Object.keys(productMap).length === 0) {
                        return posts;
                    }

                    posts.forEach(displayPost => {
                        if (!displayPost.workflowProperties) {
                            return;
                        }

                        const products: PostProduct[] = [];

                        fieldNames.forEach(fieldName => {
                            if (
                                displayPost.workflowProperties.clientCustomProperties &&
                                displayPost.workflowProperties.clientCustomProperties[fieldName]
                            ) {
                                displayPost.workflowProperties.clientCustomProperties[fieldName]
                                    .filter(productId => productId in productMap)
                                    .forEach(productId => {
                                        products.push(productMap[productId]);
                                    });
                            }

                            if (
                                displayPost.workflowProperties.partnerCustomProperties &&
                                displayPost.workflowProperties.partnerCustomProperties[fieldName]
                            ) {
                                displayPost.workflowProperties.partnerCustomProperties[fieldName]
                                    .filter(productId => productId in productMap)
                                    .forEach(productId => {
                                        products.push(productMap[productId]);
                                    });
                            }
                        });

                        if (products.length) {
                            displayPost.products = products;

                            // These products did not originate from from productTags
                            displayPost.fromProductTags = false;
                        }
                    });

                    return posts;
                }
            );
        });
    }

    private customFieldMap = (customFields: CustomField[]): { [fieldName: string]: string } => {
        const result = {};

        customFields.forEach(field => {
            result[field.fieldName] = field.name;
        });

        return result;
    };

    private getCustomPropertyId = (customFields: CustomField[], customKey: string): string => {
        const property = customKey && customFields.find(field => field.name === customKey);
        return property && property.fieldName;
    };

    private getProducts(
        posts: Post[],
        productFieldNames?: string[],
        cancelSource?: CancelTokenSource
    ): Promise<{ [productId: string]: any }> {
        return this.getProductIds(posts, productFieldNames).then(productIds => {
            if (!productIds || productIds.length === 0) {
                return {};
            }

            return this.sprinklrAPIService
                .bulkLookup(
                    undefined,
                    undefined,
                    new WidgetDimensionKey('PRODUCT_ID'),
                    productIds,
                    undefined,
                    cancelSource
                )
                .then(productMap => {
                    for (const productId in productMap) {
                        if (productMap.hasOwnProperty(productId)) {
                            const product: any = productMap[productId];
                            if (product.medias) {
                                product.medias = product.medias.map(PostsTransform.transformMedia);
                            }

                            // Sanitize the product string it doesn't contain the productId
                            if (product.name) {
                                const match = ` - ${product.productId}`;
                                product.name = product.name.replace(match, '');
                            }
                        }
                    }
                    return productMap;
                });
        });
    }

    private getCustomFieldsNames(posts: Post[]): string[] {
        const fieldNames: string[] = [];

        posts.forEach(post => {
            if (!post.workflowProperties) {
                return;
            }

            if (post.workflowProperties.clientCustomProperties) {
                const customPropertyNames = Object.keys(
                    post.workflowProperties.clientCustomProperties
                );
                customPropertyNames.forEach(property => {
                    if (fieldNames.indexOf(property) === -1) {
                        fieldNames.push(property);
                    }
                });
            }

            if (post.workflowProperties.partnerCustomProperties) {
                const customPropertyNames = Object.keys(
                    post.workflowProperties.partnerCustomProperties
                );
                customPropertyNames.forEach(property => {
                    if (fieldNames.indexOf(property) === -1) {
                        fieldNames.push(property);
                    }
                });
            }
        });

        return fieldNames;
    }

    private getProductFieldNames(
        posts: Post[],
        cancelSource?: CancelTokenSource
    ): Promise<string[]> {
        const fieldNames: string[] = this.getCustomFieldsNames(posts);

        if (fieldNames.length === 0) {
            console.error('No client client custom properties found in posts');
            return Promise.resolve(null);
        }

        const search: CustomFieldSearch = {
            fieldNames,
        };

        return this.sprinklrAPIService
            .searchCustomFields(search, cancelSource)
            .then((customFields: CustomField[]) => {
                const productFields: CustomField[] =
                    customFields &&
                    customFields.filter(customField => 'PRODUCTS' === customField.optionKey);

                if (!productFields || productFields.length === 0) {
                    return null;
                }

                return productFields.map(field => field.fieldName);
            });
    }

    private getProductIds(posts: Post[], productFieldNames?: string[]): Promise<string[]> {
        let fieldNamesPromise: Promise<string[]>;
        if (productFieldNames) {
            fieldNamesPromise = Promise.resolve(productFieldNames);
        } else {
            fieldNamesPromise = this.getProductFieldNames(posts);
        }

        return fieldNamesPromise.then((fieldNames): string[] => {
            if (!fieldNames || fieldNames.length === 0) {
                return [];
            }

            const productIds: string[] = [];

            posts.forEach(post => {
                if (!post.workflowProperties) {
                    return;
                }

                fieldNames.forEach(fieldName => {
                    const customProperties = post.workflowProperties.clientCustomProperties;
                    const partnerProperties = post.workflowProperties.partnerCustomProperties;

                    if (customProperties && customProperties[fieldName]) {
                        customProperties[fieldName].forEach(productId => {
                            if (productIds.indexOf(productId) === -1) {
                                productIds.push(productId);
                            }
                        });
                    }

                    if (partnerProperties && partnerProperties[fieldName]) {
                        partnerProperties[fieldName].forEach(productId => {
                            if (productIds.indexOf(productId) === -1) {
                                productIds.push(productId);
                            }
                        });
                    }
                });
            });

            return productIds;
        });
    }
}
