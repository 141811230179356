import { ComputedStyle, styler } from 'utils/GenerateStyles/GenerateStyles';
import { GetWidgetTypeStyles } from 'models/Widget/WidgetType';
import { Theme } from 'models/Theme/Theme';

export const BASE_BORDER_COLOR = 'rgba(109, 109, 109, 0.58)';

export const dataTableOptionStyles = (options, mergedTheme, theme) => {
    const { layout, base, rows } = options;

    const { padding, autoRowHeight } = layout;
    const computedStyles: ComputedStyle[] = [];
    const fallBackColor = theme?.typography?.color ?? mergedTheme?.typography?.color;

    if (autoRowHeight) {
        computedStyles.push({
            selector: '.data_table',
            styles: {
                height: '100%',
            },
        });
    }

    if (padding) {
        if (autoRowHeight) {
            computedStyles.push({
                selector: '.data_table td, .data_table th',
                styles: {
                    paddingRight: styler(padding, 0.025, 'em', ''),
                    paddingLeft: styler(padding, 0.025, 'em', ''),
                },
            });
        } else {
            computedStyles.push({
                selector: '.data_table td, .data_table th',
                styles: {
                    padding: styler(padding, 0.025, 'em', ''),
                },
            });
        }
    }
    if (base?.size) {
        computedStyles.push({
            selector: '.data_table',
            styles: {
                fontSize: styler(base.size, 0.025, 'em', ''),
            },
        });
    }
    if (base?.alignment) {
        computedStyles.push({
            selector: '.data_table td',
            styles: {
                textAlign: base.alignment,
            },
        });
        computedStyles.push({
            selector: '.data_table th',
            styles: {
                textAlign: base.alignment,
            },
        });
    }

    const baseBorder = rows?.borders?.base;
    const topBorder = rows?.borders?.top;
    const bottomBorder = rows?.borders?.bottom;

    const baseBorderColor = baseBorder.color || fallBackColor;

    if (baseBorder?.enabled) {
        computedStyles.push({
            selector: '.data_table td',
            styles: {
                borderBottom: `${baseBorder.size * 0.1}px solid ${baseBorderColor}`,
            },
        });

        if (!topBorder?.enabled) {
            computedStyles.push({
                selector: '.data_table th:not(.placeholder)',
                styles: {
                    borderBottom: `${baseBorder.size * 0.1}px solid ${baseBorder.color ||
                        baseBorderColor}`,
                },
            });
        }
    }

    if (topBorder?.enabled) {
        computedStyles.push({
            selector: '.data_table th:not(.placeholder)',
            styles: {
                borderBottom: `${topBorder.size * 0.1}px solid ${topBorder.color ||
                    baseBorderColor}`,
            },
        });
    }

    if (bottomBorder?.enabled) {
        computedStyles.push({
            selector: '.data_table tbody tr:last-child td',
            styles: {
                borderBottom: `${bottomBorder.size * 0.1}px solid ${bottomBorder.color ||
                    baseBorderColor}`,
            },
        });
    }

    return computedStyles;
};

export const getDataTableCss: GetWidgetTypeStyles = (mergedTheme: Theme) => {
    return '';
};
