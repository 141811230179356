import { PostSource, Sort } from './PanelTemplatesTheme/PanelTemplatesTheme';
import { DefaultWidgetPosition } from './PanelTemplatesTheme/constants';
import { Widget } from '@sprinklr/stories/widget/Widget';
import { WIDGET_LABEL_SIZE } from '../../components/Widget/WidgetLabel/constants';
import { postCompositionConfigFilmStrip } from '../../components/Panel/PanelEditorSidebar/PanelDesign/PanelDesignOptions';
import { TEXT_SHADOW_DEFAULT } from 'models/Widget/Widget';

export const WidgetPostsFilmStrip: Widget = {
    type: 'postsFilmStrip',
    id: 'filmstrip',
    name: 'Film Strip',
    useGlobalTimePeriod: true,
    postRequests: [
        {
            sources: [
                {
                    type: 'posts',
                    // Note: All global variables like reportingEngine, report, and filters
                    // are injected via PostRequest.getRequests()
                    id: new PostSource({
                        pageSize: 18,
                        sorts: [
                            new Sort({
                                order: 'DESC',
                                heading: {
                                    MONITORING_DASHBOARD: 'REACH_COUNT',
                                    LISTENING: 'REACH_COUNT',
                                    INBOUND_MESSAGE: 'INBOUND_COUNT',
                                    PLATFORM: 'TOTAL_ENGAGEMENT',
                                    BENCHMARKING: 'TOTAL_ENGAGEMENT',
                                    CAMPAIGN: 'OUTBOUND_STATUS',
                                    RDB_FIREHOSE: 'SN_CREATED_TIME',
                                    STORY_MESSAGE: 'IMPACT',
                                },
                            }),
                        ],
                        timePeriod: {
                            key: 'last_7_days',
                        },
                    }) as any,
                    options: {
                        count: 200,
                        imagePreload: true,
                        removeDuplicates: true,
                        removeSensitivePosts: true,
                        includeQuoteTweets: false,
                    },
                },
            ],
            format: {
                type: 'window',
                options: {
                    padding: 3,
                },
            },
        },
    ],
    position: DefaultWidgetPosition,
    options: {
        player: {
            playing: true,
            duration: 6,
        },
        postVersion: 2,
        nativeStyling: {
            retweetIndicator: true,
        },
        ...postCompositionConfigFilmStrip.a,
        smartSize: {
            enabled: true,
            mode: 'responsive',
            size: 'm',
        },
        portrait: {
            widgetOrientation: 'portrait',
        },
    },
    label: {
        titleText: 'Film Strip',
        enabled: false,
        size: WIDGET_LABEL_SIZE.primary,
        textShadow: TEXT_SHADOW_DEFAULT,
    },
};
