import {
    adsTunables,
    engagementTunables,
    headerTunables,
    imageAdjustmentTunables,
    imagePostTunables,
    nativeStylingTunables,
    opacityTunables,
    postTitleTunables,
    profanityTunables,
    smartSizeTunables,
    textEntityTunables,
    textPostTunables,
    userGroupTunables,
} from 'components/Sidebar/RenderTunables/SharedTunables';
import { CollapseProps, TunablesCollapse } from 'components/Sidebar/RenderTunables/RenderTunables';
import { Widget } from '@sprinklr/stories/widget/Widget';
import { Theme } from 'models/Theme/Theme';
import i18n from 'i18next';
import { PostCardWidgetOptions } from '../PostsWidget/options';
import AuthService from 'services/Auth/AuthService';
import { StoryboardVersion } from 'models/Storyboard/Storyboard';
import { WidgetType } from 'models/Widget/WidgetType';
import ActionSliderGroup from 'components/_UI/Forms/Actions/ActionSliderGroup';
import * as React from 'react';
import { LabeledSlider } from 'components/spaceKit';
import { FormatOrderedOptions, SourcePost } from '@sprinklr/stories/post/PostsFormatRequest';
import { action } from 'mobx';
import SmartTrendsTunables from 'src/widgets/SmartTrendsWidget/SmartTrendsTunables';
import { isSmartTrends } from 'src/widgets/SmartTrendsWidget/utils';

const getNonNativeTunables = (widget, mergedTheme) => {
    const isNative = (widget.options as PostCardWidgetOptions)?.nativeStyling?.enabled;
    if (!isNative) {
        return [
            postTitleTunables(widget, mergedTheme),
            textPostTunables(widget, mergedTheme),
            imagePostTunables(widget, mergedTheme),
            imageAdjustmentTunables(widget),
            userGroupTunables(widget),
            engagementTunables(widget),
            adsTunables(widget),
            textEntityTunables(widget),
            profanityTunables(widget),
        ];
    } else {
        return [];
    }
};
const TickerTunables = (
    widget: Widget,
    mergedTheme: Theme,
    authService?: AuthService,
    storyboard?: StoryboardVersion,
    parentType?: WidgetType
): TunablesCollapse[] => {
    // Ticker returns smart trends tunables when smart trends engine is selected
    const containsSmartTrends = isSmartTrends(widget);
    if (containsSmartTrends) {
        return SmartTrendsTunables(widget);
    }

    const defaultTunables =
        parentType?.id !== 'geoStream'
            ? [...headerTunables(widget), opacityTunables(widget), smartSizeTunables(widget)]
            : [];

    const updateCount = action(event => {
        (widget.postRequests[0].format.options as FormatOrderedOptions).fixedList =
            event.target.value;
    });

    const postCount = (widget?.postRequests?.[0].format?.options as FormatOrderedOptions)
        ?.fixedList;

    return [
        {
            props: {
                label: i18n.t('Ticker'),
                startOpen: true,
            } as CollapseProps,
            children: [
                ...defaultTunables,

                <LabeledSlider
                    key={'post count'}
                    label='Post Count'
                    min={1}
                    max={7}
                    value={postCount}
                    showValue
                    onChange={updateCount}
                />,
            ],
        },
        nativeStylingTunables(widget),
        ...getNonNativeTunables(widget, mergedTheme),
    ];
};

export default TickerTunables;
function runInAction(arg0: () => void) {
    throw new Error('Function not implemented.');
}
