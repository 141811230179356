import BulkItem from './BulkItem';

export default class BenchmarkBrand extends BulkItem {
    isCompetitor: boolean;
    private imageUrl: string;
    private color: string;

    constructor(id: string, name: string, competitorBrand: boolean, imageUrl?: string) {
        super(id, name);

        this.isCompetitor = competitorBrand;
        this.imageUrl = imageUrl;
    }

    // notably "star ratings" only have an id e.g. "5.0"
    // always return SOMETHING - avoids item.toString().replace() fails etc.
    // Also in keeping with newer ECMA standards, even null.toString() and undefined.toString()
    // will be error-free soon.
    toString() {
        return this.name ?? this.id ?? '';
    }

    toColor(): string {
        return this.color;
    }

    toImageUrl(): string {
        return this.imageUrl;
    }

    // Construct a BenchmarkBrand from an object, checking for properties first.
    public static fromObject(object: any) {
        const allProps = ['id', 'name', 'imageUrl'].every(prop => object.hasOwnProperty(prop));

        if (allProps) {
            return new BenchmarkBrand(object.id, object.name, object.isCompetitor, object.imageUrl);
        }
    }

    get sortValue() {
        return this.name;
    }
}
