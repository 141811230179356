import BulkItem from '@sprinklr/stories/analytics/BulkItem';
import { FontCase } from 'models/Theme/Theme';
import { stripBadChars } from '../StringUtils/StringUtils';

const labelMap = {
    MENTIONS_COUNT: 'Mentions',
    PositiveMentions: 'Positive Mentions',
    NegativeMentions: 'Negative Mentions',
    NeutralMentions: 'Neutral Mentions',
};

const stringCase = (string: string, sCase: FontCase): string => {
    if (!string || !sCase) {
        return;
    }

    switch (sCase) {
        default:
        case 'inherit':
            return string;
        case 'capitalize':
            // uppercase first letter of each word from https://codepen.io/w3resource/pen/MXVXNM
            return string.replace(
                /\w\S*/g,
                txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
            );
        case 'uppercase':
            return string.toUpperCase();
        case 'lowercase':
            return string.toLowerCase();
    }
};

const normalizeLabel = oldLabel => {
    if (oldLabel instanceof BulkItem) {
        return oldLabel; // Do not normalize
    }

    if (!labelMap.hasOwnProperty(oldLabel)) {
        return (oldLabel + '').split('_').join(' ');
    } else {
        return labelMap[oldLabel];
    }
};

/**
 * Strip bad characters but also replace dashes and whitespace with underscores
 */
const sanitizeString = string => {
    return stripBadChars(string).replace(/[-\s]+/g, '_');
};

export { normalizeLabel, sanitizeString, stringCase };
