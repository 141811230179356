import { AnalyticsRequest, Metric } from './PanelTemplatesTheme/PanelTemplatesTheme';
import { Panel } from '../../models/Panel/Panel';
import { WIDGET_LABEL_SIZE } from '../../components/Widget/WidgetLabel/constants';

export var TemplateSingleMetric: Panel = {
    id: 'single-metric',
    name: 'Single Metric',
    description: '',
    tags: ['Standard', 'Single Metric', 'landscape', 'portrait'],
    widget: {
        id: '1',
        position: {
            width: '1920px',
            height: '1080px',
            top: '0',
            left: '0',
        },
        classes: 'template_single_metric',
        children: [
            {
                type: 'metric',
                id: '1',
                name: 'Single Metric',
                position: {
                    width: '100%',
                    height: '100%',
                    top: '0',
                    left: '0',
                },
                useGlobalTimePeriod: true,
                analyticsRequests: [
                    // Note: All global variables like reportingEngine, report, filters, and projections
                    // are injected via AnalyticsRequest.getRequests()
                    new AnalyticsRequest({
                        limit: 1,
                        projections: [
                            new Metric({
                                STORY_MESSAGE: { dimensionName: 'STORY_ACTIVE_COUNT' },
                            }),
                        ],
                        groupBys: [],
                        sorts: [],
                        timePeriods: [{ key: 'last_7_days' }, { previousPeriod: true }],
                    }) as any,
                ],
                label: {
                    titleText: 'Single Metric',
                    overlap: true,
                    size: WIDGET_LABEL_SIZE.primary,
                    enabled: true,
                },
                options: {
                    player: {
                        playing: true,
                        duration: 15,
                    },
                    showBackgroundCircle: true,
                    metricValueType: 'primary',
                    primaryClasses: 'middle right',

                    showMetricValue: true,
                    metricValueSize: 60,

                    showMetricName: true,
                    metricNameSize: 15,

                    showMetricChange: true,
                    metricPercentChangeSize: 15,
                    highlightPercentChange: false,
                    smartSize: {
                        enabled: true,
                        mode: 'responsive',
                        size: 'xl',
                    },
                    portrait: {
                        widgetOrientation: 'portrait',
                        metricValueSize: 45,
                    },
                },
            },
        ],
    },
};
