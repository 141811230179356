import { computed, action, toJS, observable, runInAction } from 'mobx';
import { ProfileRequest } from '@sprinklr/stories/profile/ProfileRequest';
import { PostProfile } from '@sprinklr/stories/post/Post';
import { Source } from './Source';
import { ProfileOptions, SourceProfile } from '@sprinklr/stories/post/PostsFormatRequest';
import ProfileService from '../../services/ProfileService/ProfileService';
import SignalService from '../../services/SignalService/SignalService';
import { PromiseWithCancel } from '../../services/SprinklrAPIService/SprinklrAPIService';

export class SourceProfiles extends Source<PostProfile, ProfileRequest> {
    options: ProfileOptions = {};
    private profileService: ProfileService;
    private loaded = false;
    private performUpdate = true;
    private settingProfiles = false;

    constructor(
        signalService: SignalService,
        profileService: ProfileService,
        request: ProfileRequest,
        options: ProfileOptions,
        posts?: PostProfile[]
    ) {
        super(signalService, 'name', request, true);

        this.profileService = profileService;
        this.options = options;

        // We have post data already provided
        if (posts) {
            this.performUpdate = false;

            // Note: This timeout required so that image preload has chance to finish before
            // assigning posts. Without, you end up with posts with bad images within them.
            setTimeout(() => {
                this.setDataS3(posts);
            });
        }
    }

    static create(
        signalService: SignalService,
        profileService: ProfileService,
        source: SourceProfile
    ): SourceProfiles {
        return new SourceProfiles(
            signalService,
            profileService,
            source.id,
            source.options,
            source.data
        );
    }

    isLoaded(): boolean {
        return this.loaded;
    }

    isShuffle(): boolean {
        return false;
    }

    needsUpdate(): boolean {
        return this.performUpdate;
    }

    updateItems(): PromiseWithCancel<PostProfile[]> {
        return this.profileService.getProfiles(this.request);
    }

    @computed get items(): PostProfile[] {
        return this.itemsInternal;
    }

    // Posts data comes from S3 JSON payload, not API call via ProfileService
    setDataS3(profiles: PostProfile[]) {
        this.setData(toJS(profiles));
    }

    setData(profiles: PostProfile[]) {
        // Guard against recursive entry.  Image preload below is async, and this can
        // be called recursively before it returns and that can cause data sync issues via ArrayDiff.
        //   https://sprinklr.atlassian.net/browse/DISPLAY-1377
        if (this.settingProfiles) {
            return;
        }

        this.settingProfiles = true;
        this.loaded = true;

        // didn't want to pollute this.loaded so we're watching this.builderLoaded
        // Hacktown: setTimeout to get around updating observable in computed
        setTimeout(() => {
            runInAction(() => (this.builderLoaded = true));
        });

        let profile: PostProfile;
        let x = profiles.length;
        while (x--) {
            profile = profiles[x];

            if (!profile.order) {
                profile.order = x + 1;
            }
        }

        const changed = this.diff.merge(this.itemsInternal, profiles);
        if (changed && !changed.isEmpty()) {
            // Wrap in setTimeout to avoid mobx errors about changing observable values within computed func
            setTimeout(() => {
                this.diff.sync(this.itemsInternal, changed);
                this.signalService.dispatch(Source.changed, this);

                this.settingProfiles = false;
            });
        } else {
            this.settingProfiles = false;
        }
    }
}
