import BulkItem from './BulkItem';

export default class SLAFrequency extends BulkItem {
    // e.g., 1 hour to 3 hours
    // 'less than' and 'greater than' values represented by 0 and '*', respectively.
    from: number; // microseconds
    to: number | '*'; // microseconds or '*'
    objective: number;

    constructor(id: string, name: string, from: number, to: number | '*') {
        super(id, name);

        this.from = from;
        this.to = to;
    }

    // Construct a TopicTheme from an object, checking for properties first.
    public static fromObject(object: any): SLAFrequency {
        const allProps = ['id', 'name', 'from', 'to'].every(prop => object.hasOwnProperty(prop));

        if (allProps) {
            return new SLAFrequency(object.id, object.name, object.from, object.to);
        }
    }

    toString(): string {
        return this.name;
    }

    get sortValue(): number {
        return this.from;
    }

    get hasObjective(): boolean {
        return !!this.objective;
    }

    get objectiveMet(): boolean {
        const from = typeof this.from === 'number' ? this.from : parseInt(this.from);
        return this.objective > from;
    }
}
