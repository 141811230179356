import {
    AnalyticsEngine,
    AnalyticsGroupBy,
    AnalyticsProjection,
    AnalyticsTimePeriod,
} from '@sprinklr/stories/analytics/AnalyticsRequest';
import { Widget } from '@sprinklr/stories/widget/Widget';
import { SourcePost, SourceProfile } from '@sprinklr/stories/post/PostsFormatRequest';
import { PostsEngine } from '@sprinklr/stories/post/PostsRequest';
import { WidgetGroupBy } from './GroupBy/WidgetGroupBy';
import i18n from 'i18next';

/**
 * @param {Widget} widget widget
 * @param {string[]} dimList array of dimensionNames to check
 * @param {number=} dimIndex (optional) the index of the groupBy
 * @return boolean
 */
export const widgetHasDim = (widget: Widget, dimList: string[], dimIndex?: number): boolean => {
    let index = 0;

    if (dimIndex) {
        index = dimIndex;
    }

    const dimName = widget.analyticsRequests?.[0]?.groupBys?.[index]?.dimensionName;

    if (dimName) {
        return dimList.some(dim => dim === dimName);
    }

    return false;
};

export const widgetDimIsDate = (widget: Widget, dimIndex = 0): boolean => {
    return widget.analyticsRequests?.[0]?.groupBys?.[dimIndex]?.groupType === 'DATE_HISTOGRAM';
};

export const widgetDimIsEmoji = (widget: Widget, dimIndex = 0): boolean => {
    const emojiDimensions = ['SPECIFIC_THEME_TAG_Emoji', 'EMOTICONS', 'EMOJI'];
    return emojiDimensions.includes(
        widget.analyticsRequests?.[0]?.groupBys?.[dimIndex]?.dimensionName
    );
};

/**
 * @param {Widget} widget widget
 * @param {string[]} metricList array of metric headings to check
 * @param {number=} metricIndex (optional) the index of the metric
 * @param {boolean=} contains (optional) contains the string
 * @return boolean
 */
export const widgetHasMetric = (
    widget: Widget,
    metricList: string[],
    metricIndex?: number,
    contains?: boolean
): boolean => {
    let index = 0;

    if (metricIndex) {
        index = metricIndex;
    }

    const metricHeading = widget.analyticsRequests?.[0]?.projections?.[index]?.heading;

    if (metricHeading) {
        if (contains) {
            return metricList.some(metric => metricHeading.includes(metric));
        } else {
            return metricList.some(metric => metric === metricHeading);
        }
    }

    return false;
};

/**
 * Checks to see if the metric is SLA.
 * TODO: Add more dataTypes if we find them matching SLA
 * @param widget {Widget} widget to check
 * @param offset {number=} (optional) zero based index of the metric
 */
export function isMetricTimeDiff(widget: Widget, offset = 0): boolean {
    const isTimeDiff =
        widget.analyticsRequests?.[0]?.projections?.[offset]?.details?.dataType ===
        'TIME_DIFFERENCE';

    return !!isTimeDiff;
}

export function getWidgetEngine(widget: Widget): AnalyticsEngine | PostsEngine | null {
    // check for analytics engine
    if (widget.analyticsRequests?.[0]?.reportingEngine) {
        return widget.analyticsRequests[0].reportingEngine;
    }

    // check for posts engine
    if (
        (widget.postRequests?.[0]?.sources?.[0] as SourcePost | SourceProfile)?.id?.reportingEngine
    ) {
        return (widget.postRequests[0].sources[0] as SourcePost | SourceProfile).id.reportingEngine;
    }

    return null;
}

export const widgetDimsLength = (widget: Widget): number => {
    return widget?.analyticsRequests?.[0]?.groupBys?.length;
};

function isWidgetGroupBy(
    groupByOrProjection: AnalyticsGroupBy | AnalyticsProjection | WidgetGroupBy
): groupByOrProjection is WidgetGroupBy {
    return (groupByOrProjection as WidgetGroupBy)?.setAlternateHeading !== undefined;
}

// Note: Combo and Dual-axis use the label "Time" if dimension is 'DATE_HISTOGRAM'
// so we account for that in our check
export function groupByOrProjectionAlternateHeading(
    groupByOrProjection: AnalyticsGroupBy | AnalyticsProjection | WidgetGroupBy
): string {
    if (!groupByOrProjection) {
        return;
    }
    const isTime =
        (groupByOrProjection as AnalyticsGroupBy)?.groupType === 'DATE_HISTOGRAM' &&
        (groupByOrProjection as AnalyticsGroupBy)?.details?.interval?.includes('h');

    let heading = groupByOrProjection?.heading;
    if (isTime) {
        heading = 'Time';
    } else if ((groupByOrProjection as AnalyticsGroupBy)?.details?.interval) {
        heading = groupByOrProjection?.heading.split('_')[0];
    }

    const altHeading = isWidgetGroupBy(groupByOrProjection)
        ? groupByOrProjection?.groupBy?.details?.alternateHeading
        : groupByOrProjection.details?.alternateHeading;
    return !!altHeading && altHeading !== heading ? altHeading : heading;
}

export function widgetDataSourceName(widget: Widget): string {
    // console.log('widgetDataSourceName', widget);
    const request = widget?.analyticsRequests?.length && widget?.analyticsRequests[0];
    if (request) {
        switch (request.reportingEngine) {
            case 'RDB_FIREHOSE':
                const query_filter = request.filters.find(
                    filter => filter.dimensionName === 'QUERY'
                );
                if (query_filter) {
                    return query_filter.values[0];
                }

                const id_filter = request.filters.find(
                    filter => filter.dimensionName === 'RDB_QUERY_IDS'
                );
                if (id_filter) {
                    // return id_filter.values[0]; // TODO, bulklookup query name
                    return i18n.t('Listening Explorer');
                }
                break;

            case 'PAID':
                return i18n.t('Ads Reporting');

            case 'ADVOCACY':
                return i18n.t('Advocacy');

            case 'BENCHMARKING':
                return i18n.t('Benchmarking Insights');

            case 'CAMPAIGN':
                return i18n.t('Content Marketing');

            case 'MONITORING_DASHBOARD':
                return i18n.t('Engagement Dashboard');

            case 'LISTENING':
                return i18n.t('Listening Insights');

            case 'INBOUND_MESSAGE':
                return i18n.t('Reporting Insights: Inbound Analytics');

            case 'PLATFORM':
                return i18n.t('Reporting Insights: Social Analytics');

            case 'STORY_MESSAGE':
                return i18n.t('Story Analytics');

            case 'TWITTER':
                return i18n.t('Twitter Trends');

            case 'UNIFIED_ANALYTICS_REPORTING_ENGINE':
                return i18n.t('Unified Analytics');

            default:
                return request.reportingEngine;
        }
    }

    if (widget.postRequests) {
    }

    return null;
}
//Remove previous period if unused by widget-AMR
export function removeUnusedPreviousPeriod(
    timePeriods: AnalyticsTimePeriod[],
    options: any,
    type: string
) {
    if (
        timePeriods.length &&
        !options.showMetricChange &&
        !options.compareMode &&
        type !== 'dataTableGroupedSummary' &&
        type !== 'dataTableSummary'
    ) {
        return timePeriods.filter(timePeriod => {
            return !timePeriod.previousPeriod;
        });
    }
    return timePeriods;
}

/**
 * checks to see if a widget is visible based on options
 * @param {Widget} widget - the widget to check
 */
export function widgetIsVisible(widget: Widget) {
    return widget.options.opacity > 0;
}
