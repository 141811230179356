import { WidgetOptions } from '@sprinklr/stories/widget/WidgetOptions';
import { WidgetOptionsImpl } from 'models/Widget/WidgetOptions';
import { Region } from 'models/Geo/types';
import { LegendOrientation, SmartSize, SecondLegendOptions } from '@sprinklr/stories/widget/types';
import { DecimalFormat } from 'utils/Number/NumberUtils';
import { Threshold } from 'components/Threshold/Thresholds';
import { Points } from 'components/_charts/ComboChart/types';
import { CityMarkerOptions, MapBorderOptions } from './types';
import { observable } from 'mobx';
import { PointsImpl } from '../ComboChartWidget/options';
import { AnimationOverTime } from './types';
import { PostLocation } from '@sprinklr/stories/post/Post';

export interface WorldHeatMapWidgetOptions extends WidgetOptions {
    // component props
    data?: any;
    limit?: number;
    colors?: string[];
    classes?: string;
    colorSecondary?: string;
    legendPosition?: string;
    region?: Region;
    regionState?: string;
    regionCounty?: string;
    regionsNeighborsColor?: string;
    legendOrientation?: LegendOrientation;
    legendSize?: number;
    legendMarkerSize?: number;
    secondLegend?: SecondLegendOptions;
    legendShowValue?: boolean;
    legendValueFormat?: DecimalFormat;
    cityMarkers?: CityMarkerOptions;
    regionMapEnabled?: boolean;
    mapBorder?: MapBorderOptions;
    scaleMultiplier?: number;
    prefix?: string;
    animationOverTime?: AnimationOverTime;
    thresholdsEnabled?: boolean;
    thresholds?: Threshold[];
    maxThresholds?: number;
    regionPointsEnabled: boolean;
    regionPoints: Points;
    highlightedLocation?: PostLocation;
}

export class WorldHeatMapWidgetOptionsImpl extends WidgetOptionsImpl
    implements WorldHeatMapWidgetOptions {
    @observable zoom = 50;
    @observable offsetX = 0;
    @observable offsetY = 0;
    @observable region: Region = 'world';
    @observable regionState = 'all';
    @observable regionCounty = 'none';
    @observable regionsNeighborsColor = `rgba(0,0,0,.75)`;
    @observable legendOrientation: LegendOrientation = 'bottom';
    @observable legendSize = 15;
    @observable legendMarkerSize = 35;
    @observable showMetricName = true;
    @observable secondLegend: SecondLegendOptions = {
        showLegend: true,
        legendOrientation: 'bottom',
        legendSize: 15,
    };
    @observable legendShowValue = true;
    @observable scaleMultiplier = 50;
    @observable legendValueFormat: DecimalFormat = '1.23k';
    @observable cumulative = true;
    @observable cityMarkers: CityMarkerOptions = {
        enabled: false,
        opacity: 67,
        border: {
            enabled: false,
            color: `rgba(255,255,255,.75)`,
            size: 10,
        },
        fill: {
            enabled: false,
            color: 'rgba(255, 255, 255, .5)',
        },
    };
    @observable regionMapEnabled;
    @observable mapBorder: MapBorderOptions = {
        enabled: false,
        color: `rgba(255,255,255,.75)`,
        fill: `rgba(255,255,255,0)`,
        size: 10,
    };
    @observable smartSize: SmartSize = {
        enabled: false,
        mode: 'responsive',
        size: 'xl',
        baseline: {
            legendSize: 15,
            mapBorder: {
                size: 10,
            },
        },
        optionDefaults: {},
        breakpoints: {},
    };

    @observable animationOverTime: AnimationOverTime = {
        enabled: false,
        duration: 500,
        timeFormat: 'MMMM Do, YYYY h:mma',
        pauseOnEnd: {
            enabled: false,
            duration: 2000,
        },
    };

    @observable thresholdsEnabled = false;
    @observable thresholds = [];
    @observable maxThresholds = 10;
    @observable regionPointsEnabled = false;
    @observable regionPoints = new PointsImpl();
}
