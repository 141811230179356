import { WidgetOptions } from '@sprinklr/stories/widget/WidgetOptions';
import { WidgetOptionsImpl } from 'models/Widget/WidgetOptions';
import { ProfanityFilter, RetweetIndicatorOptions } from '../PostsWidget/types';
import { observable } from 'mobx';
import {
    BlastDelimiterTypes,
    PostCompositionTypeValues,
    PostLayoutTypes,
    PostTextFormat,
    SmartSize,
    TimeStampOptions,
    UserGroupLayoutTypes,
} from '@sprinklr/stories/widget/types';
import { PostOptionLocation } from '../PostsWindowWidget/types';

export interface WaterfallWidgetOptions extends WidgetOptions {
    animationLength?: number;
    columnCount?: number;
    columnOverflow?: number;
    secondaryOverlayColor?: string;
    socialIconBgColor?: boolean;
    retweetIndicator?: RetweetIndicatorOptions;
}

export class WaterfallWidgetOptionsImpl extends WidgetOptionsImpl
    implements WaterfallWidgetOptions {
    @observable showImageStyles = true; // this shouldn't be in the widget options  - it's just a UI concern
    @observable imageFilterBlur = false;
    @observable imageFilterBrightness = false;
    @observable imageFilterContrast = false;
    @observable imageFilterGrayscale = false;
    @observable imageFilterSaturate = false;
    @observable imageFilterHueRotate = false;
    @observable imageFilterOpacity = false;
    @observable imageFilterInvert = false;
    @observable imageFilterSepia = false;
    @observable imageFilterBlurValue = 0;
    @observable imageFilterBrightnessValue = 100;
    @observable imageFilterContrastValue = 100;
    @observable imageFilterGrayscaleValue = 0;
    @observable imageFilterSaturateValue = 100;
    @observable imageFilterHueRotateValue = 0;
    @observable imageFilterOpacityValue = 100;
    @observable imageFilterInvertValue = 0;
    @observable imageFilterSepiaValue = 0;
    @observable imageBlendMode: string = null;
    @observable imageSecondaryBlendMode = '';
    @observable imageBlendColor = 'rgba(0,0,0,.0)';
    @observable imageSecondaryBlendColor = '';
    @observable presetImageFilters = '';
    @observable postImageTextFormat: PostTextFormat = 'clamp';
    @observable imagePostLineClamp = true;
    @observable imagePostLineClampValue = 3;
    @observable imageTextColor = '';
    @observable imagePostHideText = false;
    @observable showEngagementMetrics = true;
    @observable engagementMetricsSize = 9;
    @observable textOnlyColor = '';
    @observable textOnlyTextFormat: PostTextFormat = 'clamp';
    @observable showUserGroup = true;
    @observable postLineClamp = true;
    @observable postLineClampValue = 3;
    @observable hashtagTextColor = '';
    @observable hashtagBackgroundColor = '';
    @observable mentionTextColor = '';
    @observable mentionBackgroundColor = '';
    @observable urlTextColor = '';
    @observable urlBackgroundColor = '';
    @observable showTextEntityUrls = false;
    @observable showExpandedEntityUrls = false;
    @observable showUserAvatar = true;
    @observable showUserName = true;
    @observable showUserScreenName = true;
    @observable userGroupIconSize = 7;
    @observable userGroupNameSize = 12;
    @observable userGroupScreenNameSize = 9;
    @observable showText = true;
    @observable showTimeStamp = true;
    @observable timeStampSize = 11;
    @observable timeStampFormat: TimeStampOptions = 'timeAgo';
    @observable endianTime = 'D MMM YYYY, h:mm a';
    @observable stripLinks?: boolean = true;
    @observable blastDelimiter: BlastDelimiterTypes = 'words'; // words, letters, sentences, lines
    @observable showSecondaryImageOverlay?: boolean = false;
    @observable showZeroEngagementValues?: boolean = false;
    @observable imageFiltersOpen?: boolean = true;
    @observable postLayoutType: PostLayoutTypes = 'd';
    @observable postTextOnlySize = 15;
    @observable postImageTextSize = 15;
    @observable showIconInUserGroup = true;
    @observable removeGenericPII = true;
    @observable textOnlyBackgroundColor = '';
    @observable imagePostBackgroundColor = '';
    @observable secondaryOverlayColor = '';
    @observable maxCharacters?: number = 400;
    @observable userGroupLayoutType: UserGroupLayoutTypes = 'a';
    @observable postCompositionType: PostCompositionTypeValues = 'a';
    @observable userGroupAvatarSize = 12;
    @observable socialIconBgColor = false;
    @observable spacing = 6;
    @observable padding = 6;
    @observable columnCount = 2;
    @observable columnOverflow = 1;
    @observable location: PostOptionLocation = {
        enabled: false,
        size: 11,
    };
    @observable smartSize: SmartSize = {
        enabled: false,
        mode: 'responsive',
        size: 'xl',
        baseline: {
            engagementMetricsSize: 9,
            postLineClampValue: 3,
            userGroupIconSize: 7,
            userGroupNameSize: 12,
            userGroupScreenNameSize: 9,
            timeStampSize: 11,
            postTextOnlySize: 15,
            postImageTextSize: 15,
            userGroupAvatarSize: 12,
            spacing: 6,
            padding: 6,
            retweetIndicator: {
                size: 9,
            },
        },
        optionDefaults: {
            columnCount: 3,
        },
        breakpoints: {
            xs: {
                options: {
                    columnCount: 1,
                },
            },
            s: {
                options: {
                    columnCount: 2,
                },
            },
        },
    };
    @observable profanityFilter: ProfanityFilter = {
        enabled: false,
        profanityList: [],
        obscureSymbol: '*',
        replace: false,
        replaceList: {},
    };

    @observable retweetIndicator: RetweetIndicatorOptions = {
        enabled: false,
        opacity: 50,
        size: 9,
    };

    @observable showQuotedTweet?: boolean = false;
    @observable quotedTweetSize?: number = 10;
}
