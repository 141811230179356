import { WidgetOptions } from '@sprinklr/stories/widget/WidgetOptions';
import { WidgetOptionsImpl } from 'models/Widget/WidgetOptions';
import { LegendOrientation, SmartSize, XAxisDateFormats } from '@sprinklr/stories/widget/types';
import { LineChartAnnotation, LineChartSeries } from 'components/_charts/LineChart/types';
import { Threshold } from 'components/Threshold/Thresholds';
import { IAxisLabel } from 'components/_charts/Primitives/XAxis';
import {
    InterpolationValues,
    ScaleTypeValues,
    TopPointType,
    XAxisTickNum,
    YAxisPositions,
} from './types';
import { observable } from 'mobx';
import { DecimalFormat } from 'utils/Number/NumberUtils';
import { FontCase } from 'models/Theme/Theme';

export class AxisLabelImpl implements IAxisLabel {
    @observable enabled = false;
    @observable size = 30;
    @observable padding = 15;
}

export interface LineChartWidgetOptions extends WidgetOptions {
    data?: LineChartSeries[];
    showPointMarkers?: boolean;
    showTopPointMarker?: boolean;
    showTopPointLabel?: boolean;
    showTopPointValue?: boolean;
    topPointType?: TopPointType;
    topPointEndianTime?: string;
    showTopPointIcon?: boolean;
    showTopPointOrb?: boolean;
    maxTopPoints?: number;
    xAxisTickAngle?: number;
    showYAxis?: boolean;
    showXAxis?: boolean;
    lineWidth?: number;
    lineSize?: number;
    labelSize?: number;
    valueSize?: number;
    iconSize?: number;
    orbSize?: number;
    scaleType?: ScaleTypeValues;
    interpolation?: InterpolationValues;
    channelColoredStroke?: boolean;
    maxItems?: number;
    thresholds?: Threshold[];
    thresholdsAxis?: boolean;
    thresholdsEnabled: boolean;
    useThemeColor?: boolean;
    useThemeImage?: boolean;
    wholeYAxisLabels?: boolean;
    annotations?: LineChartAnnotation[];
    showLegend?: boolean;
    legendOrientation?: LegendOrientation;
    xAxisLabel: IAxisLabel;
    yAxisLabel: IAxisLabel;
    yAxisPosition: YAxisPositions;
    zeroStart: boolean;
    compareMode: boolean;
}

export class LineChartWidgetOptionsImpl extends WidgetOptionsImpl
    implements LineChartWidgetOptions {
    @observable showXAxis = true;
    @observable showYAxis = true;
    @observable xAxisDateFormat: XAxisDateFormats = 'auto';
    @observable xAxisTickAngle = 0;
    @observable xAxisTicksNum: XAxisTickNum = {
        enabled: false,
        value: 7,
    };
    @observable yAxisTicksNum: 10;
    @observable interpolation: InterpolationValues = 'linear';
    @observable scaleType: ScaleTypeValues = 'linear';
    @observable showTopPointMarker = false;
    @observable showTopPointDot = true;
    @observable showTopPointLabel = false;
    @observable showTopPointValue = false;
    @observable topPointType: TopPointType = 'value';
    @observable topPointEndianTime = 'Do MMM';
    @observable showTopPointIcon = true;
    @observable showTopPointOrb = true;
    @observable showPointMarkers = false;

    @observable pointMarkersSize = 10;
    @observable topPointMarkerSize = 10;
    @observable labelsOnTop = false;
    @observable labelSize = 15;
    @observable valueSize = 60;
    @observable metricValueFormat: DecimalFormat = '1k';
    @observable metricValueCase: FontCase = 'lowercase';
    @observable yAxisValueFormat: DecimalFormat = '1k';
    @observable yAxisValueCase: FontCase = 'lowercase';
    @observable yAxisShowTimeRemainder = true;

    @observable iconSize = 10;
    @observable orbSize = 10;
    @observable maxTopPoints = 10;
    @observable lineWidth = 5;
    @observable channelColoredStroke = false;
    @observable maxItems = 10;
    @observable separateLabels = false;

    @observable marginTop: number | string | null = 25;
    @observable marginBottom: number | string | null = 75;
    @observable marginLeft: number | string | null = 85;
    @observable marginRight: number | string | null = 75;

    @observable thresholds: Threshold[] = [];
    @observable thresholdsEnabled = false;
    @observable thresholdsLegend = false;
    @observable thresholdsStacked?: boolean = false;
    @observable thresholdLineWidth?: number = 0.5;
    @observable thresholdsPercent?: boolean = false;
    @observable thresholdsAxis?: boolean = true;
    @observable thresholdsDashed?: boolean = false;
    @observable maxThresholds?: number = 5;
    @observable wholeYAxisLabels?: boolean = false;

    @observable annotations?: LineChartAnnotation[] = [];
    @observable zeroFill = true;
    @observable cumulative = false;

    @observable showLegend = true;
    @observable legendOrientation: LegendOrientation = 'bottom';
    @observable legendNameSize = 20;
    @observable legendWrap = true;
    @observable zeroStart = true;
    @observable compareMode = false;

    @observable xAxisLabel = new AxisLabelImpl();
    @observable yAxisLabel = new AxisLabelImpl();
    @observable yAxisPosition: YAxisPositions = 'right';

    @observable smartSize: SmartSize = {
        enabled: false,
        mode: 'responsive',
        size: 'xl',
        baseline: {
            labelSize: 12,
            valueSize: 60,
            iconSize: 10,
            orbSize: 10,
            maxTopPoints: 10,
            lineWidth: 5,
            topPointMarkerSize: 10,
        },
        optionDefaults: {
            showTopPointMarker: false,
            showPointMarkers: false,
            showXAxis: true,
            showYAxis: true,
            lineWidth: 5,
            showLegend: true,
            legendOrientation: 'bottom',
            // showTopPointDot:  true,
            // showTopPointLabel: true,
            // showTopPointValue:  true
        },
        breakpoints: {
            xs: {
                options: {
                    showTopPointMarker: false,
                    showLegend: false,
                    showPointMarkers: false,
                    showXAxis: false,
                    showYAxis: false,
                    yAxisTicksNum: 5,
                },
            },
            s: {
                options: {
                    showLegend: true,
                    legendOrientation: 'right',
                    showYAxis: false,
                    lineWidth: 5,
                    yAxisTicksNum: 5,
                },
            },
            m: {
                options: {
                    showLegend: true,
                    legendOrientation: 'right',
                    legendNameSize: 8,
                    yAxisTicksNum: 5,
                },
            },
            l: {
                options: {
                    showLegend: true,
                    legendOrientation: 'bottom',
                    yAxisTicksNum: 10,
                },
            },
        },
    };
}
