import { AnalyticsRequest, GroupBy, Metric, Sort } from './PanelTemplatesTheme/PanelTemplatesTheme';
import { DefaultWidgetPosition } from './PanelTemplatesTheme/constants';
import { Widget } from '@sprinklr/stories/widget/Widget';
import { WIDGET_LABEL_SIZE } from '../../components/Widget/WidgetLabel/constants';
import ListeningDimensions from '@sprinklr/stories-services/AnalyticsService/ListeningDimensions';
import { TEXT_SHADOW_DEFAULT } from 'models/Widget/Widget';

export var WidgetColumnChart: Widget = {
    type: 'barChart',
    id: 'column-chart',
    name: 'Column Chart',
    thumbSrc: 'template-column-chart.jpg',
    useGlobalTimePeriod: true,
    analyticsRequests: [
        // Note: All global variables like reportingEngine, report, filters, and projections
        // are injected via AnalyticsRequest.getRequests()
        new AnalyticsRequest({
            limit: 6,
            projections: [
                new Metric({
                    STORY_MESSAGE: { dimensionName: 'WEB_REACH', heading: 'Web_Reach' },
                }),
            ],
            groupBys: [
                new GroupBy({
                    LISTENING: { source: ListeningDimensions.topicName },
                    INBOUND_MESSAGE: { dimensionName: 'ACCOUNT_ID' },
                    PLATFORM: { dimensionName: 'ACCOUNT_ID' },
                    BENCHMARKING: { dimensionName: 'BENCHMARKING_ACCOUNT_ID' },
                    PAID: { dimensionName: 'accountIds' },
                    RDB_FIREHOSE: { dimensionName: 'RDB_QUERY_IDS' },
                    STORY_MESSAGE: { dimensionName: 'PUBLICATION_NAME' },
                    TWITTER: { dimensionName: 'TRENDING_TOPIC' },
                }),
            ],
            sorts: [
                new Sort({ order: 'DESC', projections: 0 }),
                new Sort({ order: 'ASC', groupBys: 0 }),
            ],
            timePeriods: [{ key: 'last_7_days' }, { previousPeriod: true }],
        }) as any,
    ],
    position: DefaultWidgetPosition,
    label: {
        titleText: 'Bar Chart',
        enabled: false,
        size: WIDGET_LABEL_SIZE.primary,
        textShadow: TEXT_SHADOW_DEFAULT,
    },
    options: {
        player: {
            playing: true,
            duration: 15,
        },
        orientation: 'horizontal',
        labelSize: 25,
        labelEnable: false,
        deprecated: {
            labelEnable: true,
        },
        metricValueSize: 50,
        metricPercentChangeSize: 17,
        metricNameSize: 15,
        highlightPercentChange: false,
        showMetricValue: true,
        showMetricName: true,
        showMetricChange: false,
        showSentiment: false,
        smartSize: {
            enabled: true,
            mode: 'responsive',
            size: 'm',
        },
        portrait: {
            widgetOrientation: 'portrait',
            limit: 3,
            metricNameSize: 15,
            labelSize: 20,
        },
        xAxis: {
            enabled: true,
            label: {
                enabled: false,
                size: 50,
                padding: 20,
            },
            ticks: {
                enabled: true,
                label: {
                    enabled: true,
                    size: 50,
                    numberFormat: '1.2k',
                    timeFormat: 'ddd ha',
                    angle: 0,
                },
                line: {
                    enabled: false,
                },
            },
        },
        yAxis: {
            enabled: false,
            label: {
                enabled: true,
                size: 50,
                padding: 20,
            },
            ticks: {
                enabled: true,
                label: {
                    enabled: false,
                    size: 50,
                    numberFormat: '1.2k',
                    timeFormat: 'ddd ha',
                },
                line: {
                    enabled: true,
                },
            },
        },
    },
};
