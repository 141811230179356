import * as React from 'react';
import { DigitalAsset, SocialMediaAsset } from '../../models/SocialMediaAsset/SocialMediaAsset';
import { observer } from 'mobx-react';
import StyleTag from '../StyleTag/StyleTag';
import { Card } from 'components/spaceKit';

interface SAMPreviewProps {
    asset?: SocialMediaAsset;
    sampleText?: string;
    onAssetClicked?(id: string): void;
}

class SAMPreview extends React.Component<SAMPreviewProps, any> {
    public static readonly defaultProps: SAMPreviewProps = {
        sampleText: 'The lorem fox jumps over the ipsum text',
    };

    constructor(props: SAMPreviewProps, ctx: any) {
        super(props, ctx);
    }

    render() {
        const { asset } = this.props;
        const digitalAsset: DigitalAsset = asset ? asset.digitalAsset : null;
        let style = null;
        let textSample = null;
        let dimensions;

        if (digitalAsset) {
            if (digitalAsset.imageHeight && digitalAsset.imageWidth) {
                dimensions = `${digitalAsset.imageHeight}${digitalAsset.imageHeightUnit} x ${digitalAsset.imageWidth}${digitalAsset.imageWidthUnit}`;
            }

            if (asset.assetType === 'FONT') {
                // TODO: add fallbacks for non-enhanced fonts? should be handled by SocialMediaAssetService probably...
                const fontFamily =
                    asset.digitalAsset && asset.digitalAsset.font && asset.digitalAsset.font.family;

                const cssText = `
                   @font-face {
                      font-family: '${fontFamily}';
                      src: url('${digitalAsset && digitalAsset.mediaUrl}');
                   }
                `;
                style = <StyleTag css={cssText} />;
                textSample = <span style={{ fontFamily }}>{this.props.sampleText}</span>;
            }
        } else {
            console.warn('Have no digital asset, props: ', this.props);
        }

        return (
            <Card
                title={asset.name && asset.name}
                subTitle={dimensions && dimensions}
                titleClamp={2}
                onContentClick={() => this.props.onAssetClicked(asset.id)}>
                {style}

                {digitalAsset && digitalAsset.previewUrl && (
                    <div
                        style={{
                            backgroundImage: `url('${digitalAsset.previewUrl}')`,
                            width: '100%',
                            height: '100%',
                        }}
                        className='fullbleed bg-default bg-contain'
                    />
                )}

                {textSample}
            </Card>
        );
    }
}

export default observer(SAMPreview);
