import * as React from 'react';
import AuthService from 'services/Auth/AuthService';

import authService from '../../authContext';

const AuthContext = React.createContext(null);

const AuthProvider = ({ children }) => {
    return <AuthContext.Provider value={authService}>{children}</AuthContext.Provider>;
};

const useAuth = () => {
    const authService: AuthService = React.useContext(AuthContext) as AuthService;
    if (authService === undefined) {
        throw new Error('useAuth must be used within a AuthProvider');
    }
    return authService;
};

export { AuthProvider, AuthContext, useAuth };
