import { AnalyticsTimePeriod } from '@sprinklr/stories/analytics/AnalyticsRequest';
import { TimePeriodKey } from 'models/TimePeriod/TimePeriod';

export interface SingleTimePeriod {
    duration?: number;
    startTime?: number;
    endTime?: number;
    timeZone?: string;
    key?: TimePeriodKey;
    wholePeriods?: boolean;
    xValue?: number;
}

export const SingleTimePeriodFields = `
    duration
    startTime
    endTime
    timeZone
    key
    wholePeriods
    xValue
`;

export class SingleTimePeriodImpl {
    duration?: number;
    startTime?: number;
    endTime?: number;
    timeZone?: string;
    key?: string;
    wholePeriods?: boolean;
    xValue?: number;

    constructor(src?: SingleTimePeriod) {
        if (typeof src === 'object') {
            Object.assign(this, src);
        }
    }

    toAnalyticsTimePeriod(): AnalyticsTimePeriod {
        return this as AnalyticsTimePeriod;
    }
}
