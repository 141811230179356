import * as React from 'react';
import i18n from 'i18next';
import {
    headerTunables,
    labelTunables,
    opacityTunables,
    smartSizeTunables,
    zoomTunables,
} from 'components/Sidebar/RenderTunables/SharedTunables';
import { CollapseProps, TunablesCollapse } from 'components/Sidebar/RenderTunables/RenderTunables';
import { Widget } from '@sprinklr/stories/widget/Widget';
import ActionSliderGroup from 'components/_UI/Forms/Actions/ActionSliderGroup';
import ActionCheckboxSliderGroup from 'components/_UI/Forms/Actions/ActionCheckboxSliderGroup';
import { colorPaletteDirectionOptions, SunburstWidgetOptions } from './options';
import LabelGroup from 'components/_UI/Forms/LabelGroup/LabelGroup';
import ActionColorSwatchGroup from 'components/_UI/Forms/Actions/ActionColorSwatchGroup';
import ActionRadioGroup from 'components/_UI/Forms/Actions/ActionRadioGroup';
import ActionSelectGroup from 'components/_UI/Forms/Actions/ActionSelectGroup';

export const SunburstTunables = (widget: Widget): TunablesCollapse[] => [
    {
        props: {
            label: i18n.t('Sunburst'),
            startOpen: true,
        } as CollapseProps,
        children: [...headerTunables(widget), opacityTunables(widget), smartSizeTunables(widget)],
    },
    zoomTunables(widget, { zoom: true }),
    {
        props: {
            label: i18n.t('Arc'),
            startOpen: true,
        },
        children: [
            labelTunables(widget),

            <ActionCheckboxSliderGroup
                key='widget.options.spacing'
                label={i18n.t('Spacing')}
                showValue
                checkbox={{
                    option: (widget.options as SunburstWidgetOptions).sunburst.spacing,
                    value: 'enabled',
                }}
                slider={{
                    option: (widget.options as SunburstWidgetOptions).sunburst.spacing,
                    value: 'width',
                    min: 0,
                    max: 50,
                }}
            />,
            <ActionCheckboxSliderGroup
                key='widget.options.stroke'
                label={i18n.t('Border')}
                showValue
                checkbox={{
                    option: (widget.options as SunburstWidgetOptions).sunburst.stroke,
                    value: 'enabled',
                }}
                slider={{
                    option: (widget.options as SunburstWidgetOptions).sunburst.stroke,
                    value: 'width',
                    min: 0,
                    max: 50,
                }}
            />,
            (widget.options as SunburstWidgetOptions).sunburst.stroke.enabled ? (
                <LabelGroup key='widget.options.stroke.color' label={i18n.t('Border Color')}>
                    <ActionColorSwatchGroup
                        key='widget.options.stroke'
                        option={(widget.options as SunburstWidgetOptions).sunburst.stroke}
                        value='color'
                    />
                </LabelGroup>
            ) : null,
            <ActionSliderGroup
                key='options.maxItems'
                label={i18n.t('Max Items')}
                option={(widget.options as SunburstWidgetOptions).sunburst}
                value='maxItems'
                showValue
                min={1}
                max={10}
            />,
        ],
    },
    {
        props: {
            label: i18n.t('Outer Items'),
            startOpen: true,
        },
        children: [
            <ActionSliderGroup
                key='options.maxChildItems'
                label={i18n.t('Max Items')}
                option={(widget.options as SunburstWidgetOptions).sunburst.childItems}
                value='max'
                showValue
                min={1}
                max={10}
            />,
            <LabelGroup label={i18n.t('Color Palette')} key='widget.options.sunburst.children'>
                <ActionRadioGroup
                    key='widget.options.sunburst.childItems.colorPalette.type.solid'
                    label={i18n.t('Solid')}
                    option={
                        (widget.options as SunburstWidgetOptions).sunburst.childItems.colorPalette
                    }
                    value='type'
                    valueCheck='solid'
                />
                <ActionRadioGroup
                    key='widget.options.sunburst.childItems.colorPalette.type.monochrome'
                    label={i18n.t('Tint')}
                    option={
                        (widget.options as SunburstWidgetOptions).sunburst.childItems.colorPalette
                    }
                    value='type'
                    valueCheck='monochrome'
                />
                <ActionRadioGroup
                    key='widget.options.sunburst.childItems.colorPalette.type.hue'
                    label={i18n.t('Hue')}
                    option={
                        (widget.options as SunburstWidgetOptions).sunburst.childItems.colorPalette
                    }
                    value='type'
                    valueCheck='hue'
                />
            </LabelGroup>,
            (widget.options as SunburstWidgetOptions).sunburst.childItems.colorPalette.type ===
                'monochrome' && (
                <ActionSliderGroup
                    key='options.contrast'
                    label={i18n.t('Contrast')}
                    option={
                        (widget.options as SunburstWidgetOptions).sunburst.childItems.colorPalette
                            .monochrome
                    }
                    value='endOffset'
                    showValue
                    suffix='%'
                    min={1}
                    max={100}
                />
            ),
            (widget.options as SunburstWidgetOptions).sunburst.childItems.colorPalette.type ===
                'hue' && (
                <ActionSliderGroup
                    key='options.rotation'
                    label={i18n.t('Rotation')}
                    option={
                        (widget.options as SunburstWidgetOptions).sunburst.childItems.colorPalette
                            .hue
                    }
                    value='rotation'
                    showValue
                    suffix='deg'
                    min={0}
                    max={180}
                />
            ),
            ((widget.options as SunburstWidgetOptions).sunburst.childItems.colorPalette.type ===
                'hue' ||
                (widget.options as SunburstWidgetOptions).sunburst.childItems.colorPalette.type ===
                    'monochrome') && (
                <ActionSelectGroup
                    key='options.sunburst.childItems.colorPalette'
                    label={i18n.t('Direction')}
                    value='direction'
                    option={
                        (widget.options as SunburstWidgetOptions).sunburst.childItems.colorPalette
                    }
                    options={colorPaletteDirectionOptions}
                />
            ),
        ],
    },
];

export default SunburstTunables;
