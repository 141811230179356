import { WidgetOptions } from '@sprinklr/stories/widget/WidgetOptions';
import { WidgetOptionsImpl } from 'models/Widget/WidgetOptions';
import { observable } from 'mobx';
import { HAlign, VAlign } from '@sprinklr/stories/widget/types';
import { TableData } from 'components/_charts/CustomTableChartWidget/types';

export interface CustomTableChartWidgetOptions extends WidgetOptions {
    tableData?: TableData;
}

export class CustomTableChartWidgetOptionsImpl extends WidgetOptionsImpl
    implements CustomTableChartWidgetOptions {
    @observable fills = {
        header: '',
        body: '',
        border: '',
    };
    @observable borderSize = 10;
    @observable contentPadding = 10;
    @observable horizontalAlign: HAlign = 'left';
    @observable verticalAlign: VAlign = 'middle';
}
