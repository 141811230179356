import * as React from 'react';
import i18n from 'i18next';
import {
    headerTunables,
    metricValueTunables,
    opacityTunables,
    smartSizeTunables,
    userGroupTunables,
} from 'components/Sidebar/RenderTunables/SharedTunables';
import { CollapseProps, TunablesCollapse } from 'components/Sidebar/RenderTunables/RenderTunables';
import { Widget } from '@sprinklr/stories/widget/Widget';
import ActionCheckboxSliderGroup from 'components/_UI/Forms/Actions/ActionCheckboxSliderGroup';
import { ProfileWidgetOptions } from './options';
import ActionSegmentedGroup from 'components/_UI/Forms/Actions/ActionSegmentedGroup';
import { verticalAlignOptions } from 'components/Panel/PanelEditorSidebar/PanelDesign/PanelDesignOptions';

const ProfileTunables = (widget: Widget): TunablesCollapse[] => [
    {
        props: {
            label: i18n.t('Profile'),
            startOpen: true,
        } as CollapseProps,
        children: [
            ...headerTunables(widget),
            opacityTunables(widget),
            smartSizeTunables(widget),
            <ActionSegmentedGroup
                key='widget.options.verticalAlign'
                label={i18n.t('Vertical Align')}
                option={widget.options}
                value='verticalAlign'
                options={verticalAlignOptions}
            />,
        ],
    },
    {
        props: {
            label: i18n.t('Labels'),
            startOpen: true,
        } as CollapseProps,
        children: [
            ...metricValueTunables(widget, {
                value: { enable: true, size: true, hideZero: true },
                format: true,
                name: true,
            }),
            <ActionCheckboxSliderGroup
                key='widget.options.rank'
                label='Rank'
                showValue
                checkbox={{
                    option: (widget.options as ProfileWidgetOptions).rank,
                    value: 'enabled',
                }}
                slider={{
                    option: (widget.options as ProfileWidgetOptions).rank,
                    value: 'size',
                    min: 0,
                    max: 100,
                }}
            />,
            <ActionCheckboxSliderGroup
                key='profileDescriptionSize'
                label='Bio'
                showValue
                checkbox={{
                    option: widget.options as ProfileWidgetOptions,
                    value: 'showProfileDescription',
                }}
                slider={{
                    option: widget.options as ProfileWidgetOptions,
                    value: 'profileDescriptionSize',
                    min: 1,
                    max: 50,
                }}
            />,
            widget.options.showProfileDescription && (
                <ActionCheckboxSliderGroup
                    key='widget.options.clamp'
                    label='Bio Max Lines'
                    showValue
                    checkbox={{
                        option: (widget.options as ProfileWidgetOptions).clamp,
                        value: 'enabled',
                    }}
                    slider={{
                        option: (widget.options as ProfileWidgetOptions).clamp,
                        value: 'lines',
                        min: 1,
                        max: 10,
                    }}
                />
            ),
        ],
    },
    userGroupTunables(widget, true),
];

export default ProfileTunables;
