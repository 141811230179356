import { GraphQLService } from '../GraphQLService/GraphQLService';
import { Intuition, IntuitionImageFields } from 'models/Intuition/Intuition';
import { chunk } from 'utils/ArrayUtils/ArrayUtils';

const detectFaces = `
query detectFaces($imageUrls: [String!]!) {
  intuition {
    images(imageUrls: $imageUrls) {
      ${IntuitionImageFields}
    }
  }
}
`;

/**
 * The Sprinklr Intuition service through Display API graphql.
 *
 * Upstream docs: https://sprinklr.gitpages.sprinklr.com/machine-learning/intuition-3.0/api/
 *
 */
export class IntuitionService {
    readonly batchSize: number = 21; // should be a multiple of the back-end request batch size, which is 3 at present.

    constructor(private graphQLService: GraphQLService) {}

    /**
     * makes multiple requests under the hood to avoid timeouts.
     * @param imageUrls
     */
    public detectFaces(imageUrls: string | string[]): Promise<Intuition> {
        if (typeof imageUrls === 'string') {
            imageUrls = [imageUrls];
        }

        return Promise.all(
            chunk(imageUrls, this.batchSize).map(urls => {
                return this.graphQLService
                    .query({
                        query: detectFaces,
                        variables: { imageUrls: urls },
                    })
                    .then(result => result.intuition);
            })
        ).then((results: any) => ({ images: results.flatMap(intuition => intuition.images) }));
    }
}
