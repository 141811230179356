import { Moment } from 'moment';
import Axios, { CancelTokenSource } from 'axios';
import {
    AnalyticsEngine,
    AnalyticsFilter,
    AnalyticsGroupBy,
    AnalyticsProjection,
    AnalyticsSort,
} from '@sprinklr/stories/analytics/AnalyticsRequest';
import AnalyticsService from '../../AnalyticsService/AnalyticsService';
import { Post, PostProfile } from '@sprinklr/stories/post/Post';
import { PostsEngine, PostsRequest } from '@sprinklr/stories/post/PostsRequest';
import TimePeriod from 'models/TimePeriod/TimePeriod';
import { WidgetDimensionKey } from 'utils/Widget/WidgetDimensionKey';
import {
    default as SprinklrAPIService,
    PromiseWithCancel,
} from 'services/SprinklrAPIService/SprinklrAPIService';
import PostsService from '../PostsService';
import { PostsProvider } from './PostsProvider';
import TimePeriodService from 'services/TimePeriodService/TimePeriodService';
import { WidgetSort } from 'utils/Widget/Sort/WidgetSort';
import ObjectUtils from 'utils/ObjectUtils/ObjectUtils';
import { ReportingRequest, ReportingResponse } from '@sprinklr/stories/reporting/types';

interface ProjectionDecor {
    value: any;
    additional: boolean;
    dataType?: string;
    origOrder?: number;
    alternateHeading?: string;
}

interface PostResponse {
    posts: Post[];
    postIds: string[];
}

const decorators = ['CHANGE', 'PERCENTAGE_CHANGE', 'PERCENTAGE'];

export default class PostsProviderReporting implements PostsProvider {
    constructor(private readonly sprinklrAPIService: SprinklrAPIService) {}

    request(request: PostsRequest, cancelSource?: CancelTokenSource): PromiseWithCancel<Post[]> {
        let requests: PostsRequest[] = [ObjectUtils.copy(request)];

        const postGroupByOffset = this.prepareRequest(requests[0]);

        const additionalRequests = AnalyticsService.splitRequests(requests[0] as any) as any;
        if (additionalRequests) {
            requests = requests.concat(additionalRequests);
        }

        const promise = this.requestInternal(
            requests[0],
            null,
            postGroupByOffset,
            cancelSource
        ).then(firstResult => {
            if (!additionalRequests) {
                return firstResult.posts;
            } else {
                // Remove first request
                const remaining = requests.slice();
                remaining.shift();

                const promises = remaining.map(request => {
                    return this.requestInternal(
                        request,
                        firstResult.postIds,
                        postGroupByOffset,
                        cancelSource
                    );
                });

                return Promise.all(promises).then(results => {
                    return PostsProviderReporting.combinePosts(remaining, firstResult, results);
                });
            }
        });

        if (!cancelSource) {
            cancelSource = Axios.CancelToken.source();
        }

        (promise as any).cancel = (message?: string) => {
            cancelSource.cancel(message);
        };

        return promise;
    }

    // Massages the request in-place so that it can be correctly processed for split request
    private prepareRequest(request: PostsRequest): number {
        const engine: PostsEngine = request.reportingEngine || 'PLATFORM';
        const engineMeta = PostsService.engines[engine];

        if (!engineMeta) {
            throw new Error('Engine "' + request.reportingEngine + '" not supported');
        }

        request.sorts = request.sorts.filter(sort => !!sort.heading);

        // We want to remove the topic groups filter if a topic ids filter is present
        const topicIdsFilter = request.filters.find(
            (filter: AnalyticsFilter) =>
                filter.dimensionName === 'TOPIC_IDS' && filter.filterType === 'IN'
        );

        const filters = request.filters
            .filter((filter: AnalyticsFilter) => {
                return (
                    filter.dimensionName &&
                    filter.filterType &&
                    filter.values &&
                    filter.values.length > 0 &&
                    !(
                        topicIdsFilter &&
                        filter.dimensionName === 'TOPIC_GROUP_IDS' &&
                        filter.filterType === 'IN'
                    )
                );
            })
            .map(filter => {
                // Ensure filters have details
                if (!filter.details) {
                    filter.details = {};
                }
                return filter;
            });

        const projections = [];

        const postKey = request.postDimension || engineMeta.postKey;
        const groupBys: AnalyticsGroupBy[] = [];

        let sorts: AnalyticsSort[] = request.sorts || [];

        // Note: Commented out to achieve parity to how space does things.  If there's
        // no sort defined in Space, then no sort is applied to query
        //
        // default sort key
        // if (!sorts || sorts.length === 0) {
        //     sorts = [
        //         {
        //             order: 'DESC',
        //             heading: engineMeta.defaultSortKey,
        //             isDimension: false,
        //         },
        //     ];
        // }

        // Normalize the origReport for each projection, if not defined.
        // Needed so splitRequests() works correctly
        if (request.projections) {
            request.projections.forEach(projection => {
                if (!projection.details) {
                    projection.details = {};
                }

                if (!projection.details.origReport || !projection.details.origReport.length) {
                    projection.details.origReport = engineMeta.defaultReport;
                }
            });
        }

        const report = request.report || engineMeta.defaultReport;

        if (engine === 'PAID') {
            sorts = sorts.map((sort: AnalyticsSort) => {
                const heading = WidgetSort.getHeading(sort);

                // Use the projection heading for sort heading.  request.sorts contain the projection name
                // instead.  This makes the sorting work correctly for the API.
                // Example of failure to sort was heading of "click" and projection heading of "Clicks".
                const projection = request.projections.find(
                    projection => projection.measurementName === heading
                );

                return {
                    order: sort.order,
                    heading: (projection && projection.heading) || heading,
                };
            });

            projections.push({
                measurementName: 'STATUS_ID',
                heading: 'totalCount_STATUS_ID_0',
                aggregateFunction: 'CARDINALITY',
                details: {
                    origReport: report,
                },
            });
        } else {
            sorts = sorts.map((sort: AnalyticsSort) => {
                const heading = WidgetSort.getHeading(sort);
                const report = WidgetSort.getReport(sort) || request.report;

                if (sort.isDimension === true) {
                    const groupBy: AnalyticsGroupBy = {
                        heading: heading,
                        dimensionName: heading as any,
                        groupType: 'FIELD',
                    };

                    // If the request already has this sort in its groupbys, then
                    // copy over details that are relevant.
                    if (request.groupBys) {
                        const found = request.groupBys.find(
                            item => item.dimensionName === groupBy.heading
                        );
                        if (found) {
                            groupBy.groupType = found.groupType;
                            if (found.details?.interval) {
                                groupBy.details = {
                                    interval: found.details.interval,
                                };
                            }
                        }
                    }

                    groupBys.push(groupBy);
                } else if (engine !== 'RDB_FIREHOSE') {
                    // If first projection, then change the report for the request
                    if (!projections.length) {
                        request.report = report;
                    }

                    projections.push({
                        heading,
                        measurementName: heading,
                        aggregateFunction: 'SUM',
                        details: {
                            origReport: report,
                        },
                    });
                }

                return {
                    order: sort.order,
                    heading,
                };
            });
        }

        // Add the postKey after any sorting groupbys
        const postGroupByOffset = groupBys.length;
        groupBys.push({
            heading: postKey,
            dimensionName: postKey,
            groupType: 'FIELD',
        });

        if (projections.length === 0 && engine !== 'RDB_FIREHOSE') {
            projections.push({
                heading: engineMeta.defaultSortKey,
                measurementName: engineMeta.defaultSortKey,
                aggregateFunction: 'SUM',
            });
        }

        if (engineMeta.additionalProjections) {
            engineMeta.additionalProjections.forEach(proj => {
                projections.push({
                    heading: proj.heading ? proj.heading : proj.measurementName,
                    measurementName: proj.measurementName,
                    aggregateFunction: proj.aggregateFunction ? proj.aggregateFunction : 'SUM',
                    details: {
                        origReport: report,
                    },
                });
            });
        }

        // If the user specified any additional projections, then add them (for post card feature)
        if (request.projections) {
            // Use alternate heading name, if specified
            const extraProjections = request.projections.map(projection => {
                if (!projection.details) {
                    projection.details = {};
                }

                // Flag for processing after results are returned
                projection.details.additional = true;

                if (projection.details?.alternateHeading) {
                    return {
                        ...projection,
                        heading: projection.details.alternateHeading.replace(/[^\w]/g, '_'),
                    };
                } else {
                    return projection;
                }
            });

            projections.push.apply(projections, extraProjections);
            request['extraProjections'] = extraProjections;
        }

        // If the user specified any additional groupBys, then add them (for post card feature)
        if (request.groupBys) {
            const extraGroupBys = request.groupBys.map(groupBy => {
                if (groupBy?.details?.alternateHeading) {
                    return {
                        ...groupBy,
                        heading: groupBy.details.alternateHeading.replace(/\ /g, '_'),
                    };
                } else {
                    return groupBy;
                }
            });

            groupBys.push.apply(groupBys, extraGroupBys);
            request['extraGroupBys'] = extraGroupBys;
        }

        if (request.projections) {
            // Metric aggregateFunctions that are "CHANGE", "PERCENTAGE_CHANGE" or "PERCENTAGE" need
            // to be handled by adding a projectionDecorations array to the ReportingRequest
            const projectionDecorators = request.projections
                .filter((projection: AnalyticsProjection) => {
                    return decorators.indexOf(projection.aggregateFunction) != -1;
                })
                .map(
                    projection => projection.aggregateFunction
                    // Rows come back in this order: "CHANGE", "PERCENTAGE_CHANGE", "PERCENTAGE", no
                    // matter what order the decorations are in.  Pre-sorting decorations makes
                    // post-processing more efficient.
                )
                .sort((a, b) => {
                    let result = a.localeCompare(b);
                    // PERCENTAGE_CHANGE comes before PERCENTAGE
                    if (a.charAt(0) === 'P' && b.charAt(0) === 'P') {
                        result = -result;
                    }
                    return result;
                });

            // Remove duplicate decorations
            request.projectionDecorations = projectionDecorators.filter((item, pos) => {
                return projectionDecorators.indexOf(item) == pos;
            });
        }

        request.projections = projections;
        request.groupBys = groupBys;
        request.filters = filters;
        request.sorts = sorts;

        return postGroupByOffset;
    }

    private requestInternal(
        request: PostsRequest,
        postIds: string[],
        postGroupByOffset: number,
        cancelSource?: CancelTokenSource
    ): PromiseWithCancel<PostResponse> {
        if (!cancelSource) {
            cancelSource = Axios.CancelToken.source();
        }

        const engine: PostsEngine = request.reportingEngine || 'PLATFORM';
        const engineMeta = PostsService.engines[engine];

        if (!engineMeta) {
            throw new Error('Engine "' + request.reportingEngine + '" not supported');
        }

        // "postDimension" overrides the default groupby for post message
        const postKey = request.postDimension || engineMeta.postKey;

        // Filter secondary requests by the post ids we got on the first request
        if (postIds) {
            request.filters.push({
                dimensionName: 'POST_ID',
                filterType: 'IN',
                values: postIds,
            });
        }

        const timePeriod = request.timePeriod
            ? this.sprinklrAPIService.getTimePeriod(request.timePeriod)
            : TimePeriodService.createFromTimePeriodKey('last_7_days');

        const startTime: Moment = timePeriod.startDate;
        const endTime: Moment = timePeriod.endDate;

        const requestObject: ReportingRequest = {
            reportingEngine: engine as AnalyticsEngine,
            report: request.report || engineMeta.defaultReport,

            projectionDecorations: request.projectionDecorations,
            // Remap any decorator aggregate functions to "SUM"
            projections:
                request.projections &&
                request.projections.map(projection => {
                    if (decorators.indexOf(projection.aggregateFunction) != -1) {
                        const newProjection = Object.assign({}, projection);
                        newProjection.aggregateFunction = 'SUM';
                        return newProjection;
                    }

                    return projection;
                }),
            groupBys: request.groupBys,
            filters: request.filters,
            sorts: !postIds ? request.sorts : [],

            startTime: startTime ? startTime.valueOf() : null,
            endTime: endTime ? endTime.valueOf() : null,
            timeZone: request.timePeriod ? request.timePeriod.timeZone : undefined,

            page: request.page || 0,
            pageSize: request.pageSize || 20,

            skipResolve: true,
        };

        if (engine === 'PAID') {
            // HACKTOWN: Required or different posts are returned from Sprinklr api.  Crazy!
            requestObject.additional = { Currency: request.additional?.Currency || 'USD' };
        }

        let promise: any = this.sprinklrAPIService.query(requestObject, cancelSource);

        promise = promise.then((response: ReportingResponse) => {
            const postIds = response.rows.map((row: any[]) => {
                return row[postGroupByOffset];
            });

            response.headings = response.headings || [];
            response.rows = response.rows || [];

            // If any of our metrics used aggregate functions
            // CHANGE, PERCENTAGE_CHANGE, or PERCENTAGE,
            // massage the headings and rows to remove the redundant data
            if (request.projectionDecorations && request.projectionDecorations.length) {
                response.headings.forEach(heading => {
                    request.projections.forEach(
                        (projection: AnalyticsProjection, offset: number) => {
                            if (projection.heading === heading) {
                                this.truncateForDecorators(
                                    projection,
                                    request.projectionDecorations,
                                    response
                                );
                            }
                        }
                    );
                });
            }

            // These are additional fields that will appear in the post card (based on feature in core)
            const fields = [...(request.groupBys || []), ...(request.projections || [])];

            const extraProjections = request['extraProjections'] || [];
            const extraGroupBys = request['extraGroupBys'] || [];
            const extra = [...extraGroupBys, ...extraProjections];

            const projections: { [name: string]: { [name: string]: any } } = {};
            response.rows.forEach((row: any[]) => {
                const decor = {};
                for (let i = 1; i < row.length; ++i) {
                    const heading = response.headings[i];
                    decor[heading] = this.buildDecor(request, i, fields, extra, heading, row[i]);
                }

                projections[row[postGroupByOffset]] = decor;
            });

            return this.getPostsById(
                postIds,
                projections,
                new WidgetDimensionKey(postKey),
                request.reportingEngine as AnalyticsEngine,
                request.report,
                timePeriod,
                cancelSource
            ).then((posts: any) => {
                // Find groupby fields and save them so we can do a lookup
                if (request.groupBys && request.groupBys.length) {
                    let doLookup = false;

                    // Put your desired lookup dimensions into this map
                    const dimensions = [
                        { id: 'ACCOUNT_GROUP_ID', header: 'Account Group', values: [] }, // PAID
                        { id: 'paidInitiativeId', header: 'paidInitiativeId', values: [] }, // PAID
                        { id: 'BRAND_ID', header: 'Brand', values: [] }, // BENCHMARKING
                        { id: 'CAMPAIGN_ID', header: 'Campaign', values: [] }, // PLATFORM
                        { id: 'ADVOCATES', header: 'Top Advocates', values: [] }, // LISTENING
                        { id: 'BRAND_REPUTATION_L1', header: 'Brand Reputation - L1', values: [] },
                        { id: 'BRAND_REPUTATION_L2', header: 'Brand Reputation - L2', values: [] },
                        { id: 'BRAND_REPUTATION_L3', header: 'Brand Reputation - L3', values: [] },
                        { id: 'GLOBAL_CATEGORY_L1', header: 'Global Category - L1', values: [] },
                        { id: 'GLOBAL_CATEGORY_L2', header: 'Global Category - L2', values: [] },
                        { id: 'GLOBAL_CATEGORY_L3', header: 'Global Category - L3', values: [] },
                        { id: 'TOPIC_IDS', header: 'Topic', values: [] }, // LISTENING
                        // { id: "CAMPAIGN_ID_DIMENSION", header: "Campaign Id", values: [] }, // PLATFORM. 500 response
                    ];

                    // Different engines have diffent dimension names for "Account"
                    const accountDimension = request.groupBys
                        .filter(groupBy => groupBy.heading === 'Account')
                        .map(groupBy => groupBy.dimensionName);

                    if (accountDimension.length) {
                        dimensions.push({ id: accountDimension[0], header: 'Account', values: [] });
                    }

                    // Different engines have diffent dimension names for "Account"
                    const themeTagDimension = request.groupBys
                        .filter(
                            groupBy => groupBy.dimensionName.indexOf('SPECIFIC_THEME_TAG_') === 0
                        )
                        .map(groupBy => groupBy.heading);

                    if (themeTagDimension.length) {
                        dimensions.push({
                            id: 'LST_THEME_DISPLAY_NAME',
                            header: themeTagDimension[0],
                            values: [],
                        });
                    }

                    const keywordDimensions = ['LST_KEYWORD_LIST', 'BENCHMARKING_KEYWORD_LIST'];

                    keywordDimensions.forEach(dimensionName => {
                        const keywordGroupBys = request.groupBys
                            .filter(
                                groupBy => groupBy.dimensionName.indexOf(dimensionName + '_') === 0
                            )
                            .map(groupBy => groupBy.heading);

                        if (keywordGroupBys.length) {
                            dimensions.push({
                                id: dimensionName,
                                header: keywordGroupBys[0],
                                values: [],
                            });
                        }
                    });

                    posts.forEach(post => {
                        dimensions.forEach(dimension => {
                            const projections = post.projections;
                            if (projections) {
                                // Replace key like "5a047151e4b02720b585d6ae" with label
                                request.groupBys.forEach(groupBy => {
                                    // Custom groupbys get a "details.displayName" added, leverage that
                                    const displayName =
                                        groupBy.details && groupBy.details.displayName;
                                    if (displayName) {
                                        const key = groupBy.details.fieldName;
                                        if (projections[key]) {
                                            projections[displayName] = projections[key];
                                            delete projections[key];
                                        }
                                    }
                                });

                                let projection = projections[dimension.header];

                                // Projection header can be either. ie "Campaign" or "CAMPAIGN_ID"
                                if (!projection && projections[dimension.id]) {
                                    projection = projections[dimension.id];
                                    dimension.header = dimension.id;
                                }

                                if (projection && projection.value) {
                                    dimension.values.push(projection.value);
                                    doLookup = true;
                                }
                            }
                        });
                    });

                    // Resolve the ids to full names
                    if (doLookup) {
                        return this.sprinklrAPIService
                            .bulkLookupMulti(
                                request.reportingEngine as any,
                                request.report,
                                dimensions.map(
                                    dimension => new WidgetDimensionKey(dimension.id as any)
                                ),
                                dimensions.map(dimension => dimension.values),
                                null,
                                cancelSource
                            )
                            .then(response => {
                                posts.forEach(post => {
                                    dimensions.forEach(dimension => {
                                        const data = response[dimension.id];
                                        if (data) {
                                            const id =
                                                post.projections[dimension.header] &&
                                                post.projections[dimension.header].value;
                                            if (id && data[id]) {
                                                // contactInfo for ADVOCATES response
                                                const name =
                                                    (data[id].contactInfo &&
                                                        data[id].contactInfo.fullName) ||
                                                    data[id].displayName ||
                                                    data[id].name;
                                                if (name) {
                                                    post.projections[dimension.header].value = name;
                                                }
                                            }
                                        }
                                    });
                                });

                                return { posts: posts, postIds: postIds };
                            });
                    }
                }

                return { posts: posts, postIds: postIds };
            });
        });

        promise.cancel = function(message?: string) {
            cancelSource.cancel(message);
        };

        return promise;
    }

    private buildDecor(
        request: PostsRequest,
        offset: number,
        fields: any[],
        extras: any[],
        heading: string,
        value: any
    ): ProjectionDecor {
        const result: ProjectionDecor = { value: value, origOrder: offset, additional: false };
        const field = fields[offset];

        // This is set for projections
        result.additional = field.details?.additional;

        // Check for groupBys
        if (!result.additional) {
            result.additional = !!extras.find(
                item => item.dimensionName && item.dimensionName === field.dimensionName
            );
        }

        let dataType = field?.details?.dataType;
        if (dataType) {
            // Special case for generic currency types.  Use the value
            // specified by the user.
            if (dataType === 'CURRENCY' && request.additional?.Currency) {
                dataType = 'CURRENCY_' + request.additional.Currency;
            }

            result.dataType = dataType;
        } else {
            dataType = field?.aggregateFunction;
            if (dataType) {
                result.dataType = dataType;
            }
        }

        if (field?.details?.alternateHeading) {
            result.alternateHeading = field.details.alternateHeading;
        }

        return result;
    }

    // Used when metrics use CHANGE, PERCENTAGE_CHANGE, or PERCENTAGE.
    // This will truncate the data.headings and rows to remove extraneous
    // data for each metric
    private truncateForDecorators(
        projection: AnalyticsProjection,
        decorators: string[],
        data: any
    ) {
        const aggregate = projection.aggregateFunction;
        const convert = decorators.indexOf(aggregate) != -1;
        const heading = projection.heading + (convert ? '_' + aggregate : '');
        const found = data.headings.indexOf(projection.heading + (convert ? '_' + aggregate : ''));
        if (found != -1) {
            // No matter what order the projectionDecorations are in, the
            // results come back in this pre-defined order:
            //   CHANGE, PERCENTAGE_CHANGE, PERCENTAGE
            const removeBefore = convert ? decorators.indexOf(aggregate) + 1 : 0;
            const removeAfter = decorators.length - removeBefore;

            data.headings[found] = projection.heading;
            data.headings.splice(found + 1, removeAfter);
            data.headings.splice(found - removeBefore, removeBefore);
            data.rows.forEach((row: any[]) => {
                row.splice(found + 1, removeAfter);
                row.splice(found - removeBefore, removeBefore);
            });

            // Overwrite the existing non-decorated heading with the new decorated one
            projection.heading = heading;
        }
    }

    /**
     *
     * @param postIds
     * @param projections
     * @param fieldName
     * @param engine
     * @param report
     * @param timePeriod
     * @param cancelSource
     * @returns {any}
     */
    private getPostsById(
        postIds: string[],
        projections: { [name: string]: { [name: string]: any } },
        fieldName: WidgetDimensionKey,
        engine: AnalyticsEngine,
        report: string,
        timePeriod?: TimePeriod,
        cancelSource?: CancelTokenSource
    ): PromiseWithCancel<any[]> {
        if (!cancelSource) {
            cancelSource = Axios.CancelToken.source();
        }

        const promise: any = this.sprinklrAPIService
            .bulkLookup(engine, report, fieldName, postIds, timePeriod, cancelSource)
            .then(postsMap => {
                if (!postsMap) {
                    return [];
                }

                const posts = postIds
                    .filter((postId: any) => {
                        return postId in postsMap;
                    })
                    .map((postId: any) => {
                        const post = postsMap[postId] as any;
                        if (projections && projections[postId]) {
                            post.projections = projections[postId];
                        }
                        return post;
                    });

                if (engine !== 'PLATFORM') {
                    return posts;
                }
                const authorIds = posts
                    .map((post: any) => {
                        return post.accountId;
                    })
                    .filter((value, index, self) => {
                        return value && self.indexOf(value) === index;
                    });

                return this.sprinklrAPIService
                    .bulkLookup(
                        engine,
                        report,
                        new WidgetDimensionKey('ACCOUNT_ID'),
                        authorIds,
                        timePeriod,
                        cancelSource
                    )
                    .then((accountsMap: { [accountId: string]: any }) => {
                        posts.forEach((post: any) => {
                            if (!(post.accountId in accountsMap)) {
                                return;
                            }

                            const account = accountsMap[post.accountId];

                            post.senderProfile = {
                                id: account.id,
                                snType: account.snType,
                                location: account.location,
                                snId: account.id,
                                name: account.displayName,
                                screenName: account.screenName,
                                bio: account.bio,
                                following: account.followingCount,
                                followers: account.followerCount,
                                statusCount: account.statusCount,
                                permalink: account.permalink,
                                profileUrl: account.accountUrl,
                                profileImgUrl: account.profileImgUrl,
                                createdTime: account.createdTime,
                                modifiedTime: account.modifiedTime,
                                clientId: account.clientId,
                                accountId: account.accountId,
                                delFlag: account.isDeleted,
                                accountsFollowedByUser: account.accountFollowedByUser,
                                accountsFollowingUser: account.accountFollowingUser,
                            } as PostProfile;
                        });

                        return posts;
                    });
            });

        promise.cancel = (message?: string) => {
            cancelSource.cancel(message);
        };

        return promise;
    }

    // Used when using splitRequests() to merge the post's projections together
    static combinePosts(
        requests: PostsRequest[],
        firstResult: PostResponse,
        results: PostResponse[]
    ): Post[] {
        let posts: Post[] = [];

        posts = posts.concat(firstResult.posts);

        results.forEach((result, offset) => {
            posts.forEach(post => {
                const projections = (post as any).projections;
                const found = result.posts.find(
                    post2 => post.universalId === post2.universalId
                ) as any;
                if (found) {
                    const keys = Object.keys(found.projections);
                    keys.forEach(key => {
                        projections[key] = found.projections[key];
                    });
                } else {
                    requests[offset].projections.forEach(projection => {
                        projections[projection.heading] = {
                            value: 0,
                            additional: true,
                            origOrder: projection.details?.origOrder,
                        };
                    });
                }
            });
        });

        return posts;
    }
}
