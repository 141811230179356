import React from 'react';
import { Axis } from '@vx/axis';
import { Group } from '@vx/group';
import { DecimalFormat } from 'utils/Number/NumberUtils';
import { getFieldTypeValue } from '@sprinklr/stories/analytics/Field';
import { FieldType } from '@sprinklr/stories/reporting/types';
import { CurrencyFormat, XAxisDateFormats } from '@sprinklr/stories/widget/types';
import { IAxis } from './XAxis';
import { FontCase } from 'models/Theme/Theme';

export type YAxisProps = {
    decimalFormat?: DecimalFormat;
    valueCase?: FontCase;
    dateFormat?: XAxisDateFormats;
    currencyFormat?: CurrencyFormat;
    hideAxisLine?: boolean;
    hideTicks?: boolean;
    hideZero?: boolean;
    label?: string;
    labelType?: FieldType;
    isPercentage?: boolean;
    numTicks?: number;
    orientation?: 'left' | 'right';
    scale?: any;
    options: IAxis;
    top?: number;
    left?: number;
    labelOffset?: number;
    fontSizeScale?: number;
    width?: number;
};

const YAxis = React.forwardRef(
    (
        {
            decimalFormat,
            valueCase,
            hideAxisLine,
            hideTicks,
            hideZero,
            label,
            labelType,
            numTicks,
            orientation,
            scale,
            options,
            isPercentage,
            top,
            left,
            labelOffset,
            fontSizeScale,
            currencyFormat,
            width,
        }: YAxisProps,
        ref
    ) => {
        const { ticks, label: yAxisLabel } = options;
        const labelSize = yAxisLabel.size * (fontSizeScale || 0.75);
        const labelPadding = labelSize * yAxisLabel.padding * 0.01;
        const labelYOffset = labelPadding + labelSize;
        const labelY =
            !labelOffset && ticks.label.enabled
                ? ticks.label.size * 1 + labelYOffset
                : labelOffset || labelYOffset;
        const numberFormat = decimalFormat ? decimalFormat : ticks.label.numberFormat;
        const numberCase: FontCase = valueCase ? valueCase : ticks.label.numberCase;
        return (
            <Group width={width} innerRef={ref as React.Ref<SVGGElement>}>
                <Axis
                    hideAxisLine={hideAxisLine}
                    hideTicks={hideTicks || !options.ticks.line.enabled || !options.ticks.enabled}
                    hideZero={hideZero}
                    label={options.label.enabled ? label : undefined}
                    labelClassName='primary_font_family'
                    labelOffset={labelY}
                    labelProps={{
                        textAnchor: 'middle',
                        fontSize: options.label.size * (fontSizeScale || 0.75),
                    }}
                    left={left || 0}
                    numTicks={numTicks}
                    orientation={orientation}
                    scale={scale}
                    tickClassName='primary_font_family'
                    tickFormat={d =>
                        getFieldTypeValue(d, labelType, {
                            decimalFormat: numberFormat,
                            isPercentage,
                            currencyFormat,
                            valueCase: numberCase,
                        })
                    }
                    tickLabelProps={(value, index) => ({
                        textAnchor: orientation === 'left' ? 'end' : 'start',
                        dx: orientation === 'left' ? '-0.5em' : '0.5em',
                        dy: '0.25em',
                        fontSize: options.ticks.label.size * (fontSizeScale || 0.5),
                        display: !options.ticks.label.enabled ? 'none' : 'initial',
                    })}
                    tickLength={!options.ticks.label.enabled ? 0 : 10}
                    top={top || 0}
                />
            </Group>
        );
    }
);

YAxis.defaultProps = {
    hideZero: false,
};

export default YAxis;
