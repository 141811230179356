import { AnalyticsRequest, Metric, GroupBy, Sort } from './PanelTemplatesTheme/PanelTemplatesTheme';
import { DefaultWidgetPosition } from './PanelTemplatesTheme/constants';
import { Widget } from '@sprinklr/stories/widget/Widget';
import { WIDGET_LABEL_SIZE } from '../../components/Widget/WidgetLabel/constants';
import ListeningDimensions from '@sprinklr/stories-services/AnalyticsService/ListeningDimensions';
import { TEXT_SHADOW_DEFAULT } from 'models/Widget/Widget';

export var WidgetStackedBarChart: Widget = {
    type: 'stackedBarChart',
    id: 'stacked-bar-chart',
    name: 'Stacked Bar Chart',
    useGlobalTimePeriod: true,
    analyticsRequests: [
        // Note: All global variables like reportingEngine, report, filters, and projections
        // are injected via AnalyticsRequest.getRequests()
        new AnalyticsRequest({
            projections: [
                new Metric({
                    ADVOCACY: { dimensionName: 'POINTS' },
                    STORY_MESSAGE: { dimensionName: 'NEWS_COUNT', heading: 'Mentions' },
                }),
            ],
            limit: 2000,
            groupBys: [
                new GroupBy({
                    LISTENING: {
                        source: ListeningDimensions.topicName,
                        limitType: 'TOP',
                        limit: 16,
                    },
                    INBOUND_MESSAGE: { dimensionName: 'ACCOUNT_ID', limitType: 'TOP', limit: 16 },
                    PLATFORM: { dimensionName: 'ACCOUNT_ID', limitType: 'TOP', limit: 16 },
                    BENCHMARKING: {
                        dimensionName: 'BENCHMARKING_ACCOUNT_ID',
                        limitType: 'TOP',
                        limit: 16,
                    },
                    PAID: { dimensionName: 'accountIds', limitType: 'TOP', limit: 16 },
                    ADVOCACY: { dimensionName: 'COMMUNITY_USER_ID', limitType: 'TOP', limit: 16 },
                    RDB_FIREHOSE: { dimensionName: 'DAY_OF_WEEK', limitType: 'TOP', limit: 8 },
                    STORY_MESSAGE: {
                        dimensionName: 'PUBLICATION_NAME',
                        limitType: 'TOP',
                        limit: 16,
                    },
                }),
                new GroupBy({
                    LISTENING: {
                        source: ListeningDimensions.socialNetwork,
                        limitType: 'TOP',
                        limit: 8,
                    },
                    INBOUND_MESSAGE: { dimensionName: 'SN_TYPE', limitType: 'TOP', limit: 8 },
                    PLATFORM: { dimensionName: 'SN_TYPE', limitType: 'TOP', limit: 8 },
                    BENCHMARKING: {
                        dimensionName: 'BENCHMARKING_SN_TYPE',
                        limitType: 'TOP',
                        limit: 8,
                    },
                    PAID: { dimensionName: 'socialChannelTypes', limitType: 'TOP', limit: 8 },
                    ADVOCACY: {
                        dimensionName: 'AUDIENCE_ACTIVITY_TYPE',
                        limitType: 'TOP',
                        limit: 8,
                    },
                    RDB_FIREHOSE: { dimensionName: 'RDB_QUERY_IDS', limitType: 'TOP', limit: 8 },
                    STORY_MESSAGE: {
                        dimensionName: 'SENTIMENT',
                        limitType: 'TOP',
                        limit: 8,
                    },
                }),
            ],
            sorts: [
                new Sort({ order: 'DESC', projections: 0 }),
                new Sort({ order: 'ASC', groupBys: 0 }),
            ],
            timePeriods: [{ key: 'last_7_days' }],
        }) as any,
    ],
    position: DefaultWidgetPosition,
    label: {
        titleText: 'Stacked Bar Chart',
        enabled: false,
        size: WIDGET_LABEL_SIZE.primary,
        textShadow: TEXT_SHADOW_DEFAULT,
    },
    options: {
        player: {
            playing: true,
            duration: 15,
        },
        orientation: 'vertical',

        labelSize: 30,

        stacked100Percent: true,
        stackedInline: true,
        stackedType: 'a',

        legendOrientation: 'top',
        showLegend: true,
        legendWrap: true,
        legendNameSize: 13,
        spacing: 6,

        minSegmentRatio: 3,
        smartSize: {
            enabled: true,
            mode: 'responsive',
            size: 'm',
        },
        portrait: {
            widgetOrientation: 'portrait',
            stackedType: 'c',
            labelSize: 20,
        },

        barLabelAlignment: 'right',

        xAxis: {
            enabled: false,
            label: {
                enabled: true,
                size: 50,
                padding: 20,
            },
            ticks: {
                enabled: true,
                label: {
                    enabled: false,
                    size: 50,
                    numberFormat: '1.2k',
                    timeFormat: 'ddd ha',
                    angle: 0,
                },
                line: {
                    enabled: true,
                },
            },
        },
        yAxis: {
            enabled: false,
            label: {
                enabled: true,
                size: 50,
                padding: 20,
            },
            ticks: {
                enabled: true,
                label: {
                    enabled: false,
                    size: 50,
                    numberFormat: '1.2k',
                    timeFormat: 'ddd ha',
                },
                line: {
                    enabled: false,
                },
            },
        },
    },
};
