import { observable, action, computed, toJS } from 'mobx';
import {
    RawDraftContentState,
    EditorState,
    convertFromRaw,
    convertToRaw,
    RichUtils,
    SelectionState,
    RawDraftInlineStyleRange,
} from 'draft-js';
import createStyles from 'draft-js-custom-styles';
import { Widget } from '@sprinklr/stories/widget/Widget';

const { styles } = createStyles(
    [
        'font-size',
        'color',
        'font-family',
        'font-weight',
        'font-style',
        'line-height',
        'letter-spacing',
    ],
    'CUSTOM_'
);

export interface EditorStateInterface {
    widget?: Widget;
    state?: EditorState;
    node?: HTMLElement;
}

export default class EditorStateService {
    @observable public currentContentIsEmpty = false;
    @observable public currentKeyCode: number = null;
    @observable public currentlyDraggingWidget: Widget = null;
    @observable public draggingNodeHtml: string = null;
    @observable public preservedStyle: RawDraftInlineStyleRange[] = null;

    @observable public editorStore: EditorStateInterface = {
        widget: null,
        state: null,
        node: null,
    };

    @observable public panelInnerDims: {
        left: number;
        top: number;
        width: number;
        height: number;
    } = null;
    @observable private clickableNodes = [];
    @observable private mouseX = 0;
    @observable private mouseY = 0;

    @computed get hasSelection() {
        let selectedText = '';
        let hasFocus = false;
        if (this.editorStore.state) {
            const selection = this.editorStore.state.getSelection();
            const anchorKey = selection.getAnchorKey();
            const currentContent = this.editorStore.state.getCurrentContent();
            const currentContentBlock = currentContent.getBlockForKey(anchorKey);
            const start = selection.getStartOffset();
            const end = selection.getEndOffset();
            hasFocus = selection.getHasFocus();
            selectedText =
                currentContentBlock.getText().slice(start, end) || currentContentBlock.getText();
        }
        return hasFocus && selectedText.length > 0;
    }

    @computed get currentBlockText(): string {
        if (this.editorStore.state) {
            const selection = this.editorStore.state.getSelection();
            const anchorKey = selection.getAnchorKey();
            const currentContent = this.editorStore.state.getCurrentContent();
            const currentContentBlock = currentContent.getBlockForKey(anchorKey);
            return currentContentBlock.getText();
        }
        return '';
    }

    @computed get currentSelectionText(): string {
        if (this.editorStore.state) {
            const editorState: EditorState = this.editorStore.state;
            const selection = editorState.getSelection();
            const contentState = editorState.getCurrentContent();
            const startKey = selection.getStartKey();
            const endKey = selection.getEndKey();
            const blocks = contentState.getBlockMap();

            let lastWasEnd = false;
            const selectedBlock = blocks
                .skipUntil(block => {
                    return block.getKey() === startKey;
                })
                .takeUntil(block => {
                    const result = lastWasEnd;

                    if (block.getKey() === endKey) {
                        lastWasEnd = true;
                    }

                    return result;
                });

            return selectedBlock
                .map(block => {
                    const key = block.getKey();
                    let text = block.getText();

                    let start = 0;
                    let end = text.length;

                    if (key === startKey) {
                        start = selection.getStartOffset();
                    }
                    if (key === endKey) {
                        end = selection.getEndOffset();
                    }

                    text = text.slice(start, end);
                    return text;
                })
                .join('\n');
        }
        return '';
    }

    @computed get currentBlockKey(): string {
        if (this.editorStore.state) {
            const selection = this.editorStore.state.getSelection();
            return selection.getAnchorKey();
        }
        return '';
    }

    @action
    resetEditorStore(): void {
        this.editorStore = {
            widget: null,
            state: null,
            node: null,
        };
    }

    @action
    registerClickableNode(node): () => void {
        if (this.clickableNodes.indexOf(node) === -1) {
            this.clickableNodes.push(node);
        }

        return (): void => {
            const index = this.clickableNodes.indexOf(node);
            if (index === -1) {
                return;
            }
            this.clickableNodes.splice(index, 1);
        };
    }

    isWithinBounds(mouseX, mouseY, node) {
        if (!node) {
            return false;
        }

        const { x, y, height: h, width: w } = node.getBoundingClientRect();

        return mouseX > x && mouseX < x + w && mouseY > y && mouseY < y + h;
    }

    @action
    checkInteractivePosition(mouseX, mouseY) {
        this.mouseX = mouseX;
        this.mouseY = mouseY;
    }

    @computed get editDragDisabled(): boolean {
        if (!!this.currentlyDraggingWidget) {
            return false;
        }

        let isClickable = false;

        this.clickableNodes &&
            this.clickableNodes.forEach(node => {
                if (this.isWithinBounds(this.mouseX, this.mouseY, node)) {
                    isClickable = true;
                }
            });

        return isClickable;
    }

    @action
    setSelectedWidget(widget: Widget) {
        if (widget === this.editorStore.widget) {
            this.preservedStyle = widget.options.contentRichText.blocks[0].inlineStyleRanges;
            this.setEditorSelection();
            return;
        }
        const contentRichText = widget.options.contentRichText;
        const content = convertFromRaw(toJS(contentRichText));
        const newEditorState = EditorState.createWithContent(content);

        this.editorStore.widget = widget;
        this.editorStore.state = newEditorState;
        this.preservedStyle = widget.options.contentRichText.blocks[0].inlineStyleRanges;
        this.setEditorSelection();
    }

    @action
    setEditorSelection() {
        if (this.editorStore.state) {
            const selection = this.editorStore.state.getSelection();
            const contentState = this.editorStore.state.getCurrentContent();
            const anchorKey = selection.getAnchorKey();
            const currentContentBlock = contentState.getBlockForKey(anchorKey);
            const focusKey = selection.getFocusKey();
            const focusOffset = selection.getFocusOffset();
            const anchorOffset = selection.getAnchorOffset();
            const singleCharacterCheck: boolean =
                this.currentSelectionText.length === 1 && focusOffset === 0 && anchorOffset === 0;

            let selectionState = selection;

            if (selection.isCollapsed() && !selection.getHasFocus() && !singleCharacterCheck) {
                // if widget was just selected
                selectionState = new SelectionState({
                    anchorKey: contentState.getFirstBlock().getKey(),
                    anchorOffset: 0,
                    focusOffset: 1,
                    focusKey: contentState.getLastBlock().getKey(),
                });
            } else if (
                selection.isCollapsed() &&
                !selection.getHasFocus() &&
                singleCharacterCheck
            ) {
                selectionState = new SelectionState({
                    anchorKey,
                    anchorOffset: 1,
                    focusKey,
                    focusOffset: 1,
                });
            }

            this.setEditorState(
                this.editorStore.widget,
                EditorState.forceSelection(this.editorStore.state, selectionState)
            );
        }
    }

    @action
    getEditorState(widget: Widget) {
        const contentRichText: RawDraftContentState =
            widget && widget.options && widget.options.contentRichText;

        if (this.editorStore.widget === widget && this.editorStore.state) {
            return this.editorStore.state;
        } else if (contentRichText) {
            const raw = convertFromRaw(toJS(contentRichText));
            const editorState = EditorState.createWithContent(raw);
            return editorState;
        } else {
            return EditorState.createEmpty();
        }
    }

    @action
    setEditorState(widget: Widget, editorState: EditorState) {
        const selection = editorState.getSelection();
        const contentState = editorState.getCurrentContent();
        const anchorKey = selection.getAnchorKey();
        const currentContentBlock = contentState.getBlockForKey(anchorKey);
        const currentContentLength = currentContentBlock.getText().length;

        const start = contentState.getFirstBlock();
        const end = contentState.getLastBlock();

        if (currentContentLength === 1 && this.currentContentIsEmpty && start === end) {
            this.setOnlyCharacter(widget, currentContentBlock);
            return;
        } else if (currentContentLength === 0) {
            this.currentContentIsEmpty = true;
        }

        let newEditorState = editorState;
        if (
            (currentContentLength === 0 || currentContentLength === 1) &&
            this.preservedStyle &&
            this.editorStore.state
        ) {
            const stateSelection = this.editorStore.state.getSelection();
            const stateContentState = this.editorStore.state.getCurrentContent();
            const stateAnchorKey = stateSelection.getAnchorKey();
            const stateCurrentContentBlock = stateContentState.getBlockForKey(stateAnchorKey);
            const stateCurrentContentLength = stateCurrentContentBlock.getText().length;

            if (stateCurrentContentLength <= currentContentLength) {
                newEditorState = this.preserveFontSize(editorState, start === end);
            }
        }
        this.editorStore.widget = widget;
        this.editorStore.state = EditorState.set(editorState, {});
        this.saveEditorState(widget, newEditorState);
    }

    preserveFontSize(editorState: EditorState, selectAll = false): EditorState {
        const selection = editorState.getSelection();

        let newSelection: any = selection.merge({
            anchorOffset: 0,
            focusOffset: 0,
        });

        if (selectAll) {
            const currentContent = editorState.getCurrentContent();
            newSelection = editorState.getSelection().merge({
                anchorKey: currentContent.getFirstBlock().getKey(),
                anchorOffset: 0,
                focusOffset: currentContent.getLastBlock().getText().length,
                focusKey: currentContent.getLastBlock().getKey(),
            });
        }
        const newStateWithSelection = EditorState.forceSelection(editorState, newSelection);
        const preservedFontSize = this.preservedStyle.find(
            preserve => preserve && preserve.style.includes('CUSTOM_FONT_SIZE_')
        );
        const fontSize =
            this.preservedStyle && this.preservedStyle.length > 0 && preservedFontSize
                ? parseFloat(preservedFontSize.style.replace('CUSTOM_FONT_SIZE_', ''))
                : 20;

        let newEditorState = editorState;
        newEditorState = styles.fontSize.toggle(newStateWithSelection, fontSize);
        newEditorState = EditorState.forceSelection(newEditorState, selection);
        newEditorState = EditorState.set(newEditorState, {});
        return newEditorState;
    }

    @action
    setOnlyCharacter(widget: Widget, currentContentBlock) {
        const onlyCharacter = currentContentBlock.getText();
        const raw = convertFromRaw(this.getDefaultContent(onlyCharacter));
        let newEditorState = EditorState.moveFocusToEnd(EditorState.createWithContent(raw));

        newEditorState = this.preserveFontSize(newEditorState);

        this.editorStore.state = newEditorState;
        this.saveEditorState(widget, newEditorState);
        this.currentContentIsEmpty = false;
    }

    getEditorStateStyleMap() {
        const content = convertToRaw(this.editorStore.state.getCurrentContent());
        const result = [];
        content.blocks.forEach(block => {
            block.inlineStyleRanges.forEach(styleRange => {
                result.push(styleRange.style);
            });
        });

        return result;
    }

    @action
    saveEditorState = (widget, editorState: EditorState) => {
        const content = convertToRaw(editorState.getCurrentContent());
        this.editorStore.state = editorState;
        widget.options.contentRichText = content;
    };

    @action
    setEditorInlineStyle(widget, inlineStyle) {
        this.editorStore.state = RichUtils.toggleInlineStyle(this.editorStore.state, inlineStyle);
        this.saveEditorState(widget, this.editorStore.state);
    }

    @action
    changeBlockType(widget, blockType) {
        this.editorStore.state = RichUtils.toggleBlockType(this.editorStore.state, blockType);
        this.saveEditorState(widget, this.editorStore.state);
    }

    getStyle(widget, editorState, style) {
        const current = styles[style] && styles[style].current(editorState);
        return current;
    }

    @action
    setStyle(widget, editorState, styleValue, style) {
        const newEditorState = styles[style].toggle(editorState, styleValue);
        this.editorStore.state = EditorState.set(newEditorState, {});
        this.saveEditorState(widget, newEditorState);
    }

    @action
    setFontFamily(widget, editorState, font) {
        const currentFamily = styles.fontFamily.current(editorState);
        const currentWeight = styles.fontWeight.current(editorState);
        const currentStyle = styles.fontStyle.current(editorState);

        const weightChanged =
            currentWeight === undefined || font.weight
                ? currentWeight !== (font.weight && font.weight.toString())
                : false;
        const styleChanged =
            currentStyle === undefined || font.style
                ? currentStyle !== (font.style && font.style.toString())
                : false;
        const familyHasntChanged = currentFamily && font.family === currentFamily;
        const familyChanged = font.family !== currentFamily;

        let newEditorState;

        if (!familyChanged && !weightChanged && !styleChanged) {
            newEditorState = styles.fontFamily.remove(
                styles.fontWeight.remove(styles.fontStyle.remove(editorState))
            );
        }

        if (familyChanged && !weightChanged && !styleChanged) {
            newEditorState = styles.fontFamily.toggle(editorState, font.family);
        }
        if (!familyChanged && weightChanged && !styleChanged) {
            newEditorState = styles.fontWeight.toggle(editorState, font.weight);
        }
        if (!familyChanged && !weightChanged && styleChanged) {
            newEditorState = styles.fontStyle.toggle(editorState, font.style);
        }

        if (familyChanged && weightChanged && !styleChanged) {
            newEditorState = styles.fontFamily.toggle(
                styles.fontWeight.toggle(editorState, font.weight),
                font.family
            );
        }
        if (familyChanged && !weightChanged && styleChanged) {
            newEditorState = styles.fontFamily.toggle(
                styles.fontStyle.toggle(editorState, font.style),
                font.family
            );
        }
        if (!familyChanged && weightChanged && styleChanged) {
            newEditorState = styles.fontWeight.toggle(
                styles.fontStyle.toggle(editorState, font.style),
                font.weight
            );
        }
        if (familyChanged && weightChanged && styleChanged) {
            newEditorState = styles.fontFamily.toggle(
                styles.fontWeight.toggle(
                    styles.fontStyle.toggle(editorState, font.style),
                    font.weight
                ),
                font.family
            );
        }

        this.editorStore.state = EditorState.set(newEditorState, {});
        this.saveEditorState(widget, newEditorState);
    }

    @action
    clearColor(widget, editorState, fontColor) {
        const newEditorState = styles.color.remove(editorState, fontColor);
        this.editorStore.state = EditorState.set(newEditorState, {});
        this.saveEditorState(widget, newEditorState);
    }

    getDefaultContent(text: string): RawDraftContentState {
        const defaultFontSize = 'CUSTOM_FONT_SIZE_20';
        const inlineStyleRanges = [];

        if (this.preservedStyle) {
            this.preservedStyle.forEach(styleBlock => {
                inlineStyleRanges.push({
                    offset: 0,
                    length: 1,
                    style: styleBlock.style.includes('CUSTOM_FONT_SIZE_')
                        ? styleBlock.style || defaultFontSize
                        : styleBlock.style,
                });
            });
        }

        return {
            entityMap: {},
            blocks: [
                {
                    key:
                        'key_' +
                        Math.random()
                            .toString(36)
                            .substr(2, 9),
                    text,
                    type: 'unstyled',
                    depth: 0,
                    inlineStyleRanges,
                    entityRanges: [],
                    data: {},
                },
            ],
        };
    }
}
