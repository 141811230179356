import { AnalyticsProjection } from '@sprinklr/stories/analytics/AnalyticsRequest';

/**
 * Known/Default  projections
 */
export default class ListeningMetrics {
    // ----------------
    //  Listening
    // ----------------
    static mentions: AnalyticsProjection = {
        heading: 'Mentions',
        measurementName: 'MENTIONS_COUNT',
        aggregateFunction: 'SUM',
    };

    static ownedMentions: AnalyticsProjection = {
        heading: 'Owned Mentions',
        measurementName: 'OWNED_MENTIONS',
        aggregateFunction: 'SUM',
    };

    static earnedMentions: AnalyticsProjection = {
        heading: 'Earned Mentions',
        measurementName: 'EARNED_MENTIONS',
        aggregateFunction: 'SUM',
    };

    static mentionsExRetweets: AnalyticsProjection = {
        heading: 'Mentions Excluding Retweets',
        measurementName: 'MENTIONS_EX_RETWEETS',
        aggregateFunction: 'SUM',
    };

    static positiveMentions: AnalyticsProjection = {
        heading: 'Positive Mentions',
        measurementName: 'POS_SEN',
        aggregateFunction: 'SUM',
    };

    static negativeMentions: AnalyticsProjection = {
        heading: 'Negative Mentions',
        measurementName: 'NEG_SEN',
        aggregateFunction: 'SUM',
    };

    static neutralMentions: AnalyticsProjection = {
        heading: 'Neutral Mentions',
        measurementName: 'NEU_SEN',
        aggregateFunction: 'SUM',
    };

    static distinctUsers: AnalyticsProjection = {
        heading: 'Distinct Users',
        measurementName: 'DISTINCT_USER_COUNT',
        aggregateFunction: 'SUM',
    };

    static reach: AnalyticsProjection = {
        heading: 'Reach',
        measurementName: 'REACH_COUNT',
        aggregateFunction: 'SUM',
    };

    static ownedReach: AnalyticsProjection = {
        heading: 'Owned Reach',
        measurementName: 'OWNED_REACH',
        aggregateFunction: 'SUM',
    };

    static earnedReach: AnalyticsProjection = {
        heading: 'Earned Reach',
        measurementName: 'EARNED_REACH',
        aggregateFunction: 'SUM',
    };

    // ----------------------------------------------
    //  Other engines
    // ----------------------------------------------

    static inboundCount: AnalyticsProjection = {
        heading: 'Inbound Count',
        measurementName: 'INBOUND_COUNT',
        aggregateFunction: 'SUM',
    };

    static totalEngagements: AnalyticsProjection = {
        heading: 'Total Engagements',
        measurementName: 'TOTAL_ENGAGEMENT',
        aggregateFunction: 'SUM',
    };
}
