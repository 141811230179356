import * as React from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import Icon from '../Icon/Icon';
import './EngagementMetrics.scss';

interface EngagementMetricsProps {
    classes?: string;
    channel?: string;
    likes?: number;
    comments?: number;
    shares?: number;
    retweets?: number;
    reachCount?: number;
    views?: number;
    showZeroEngagementValues?: boolean;
    metricClasses?: string;
}

class EngagementMetrics extends React.Component<EngagementMetricsProps, any> {
    public static defaultProps: EngagementMetricsProps = {
        classes: '',
    };

    render() {
        const {
            channel,
            likes,
            comments,
            shares,
            retweets,
            views,
            showZeroEngagementValues,
            classes,
            metricClasses,
        } = this.props;

        const channelStats = {
            facebook: [
                { icon: 'facebook_likes', metric: 'likes', value: likes },
                { icon: 'facebook_share', metric: 'shares', value: shares },
                { icon: 'facebook_comments', metric: 'comments', value: comments },
            ],
            instagram: [
                { icon: 'instagram_likes', metric: 'likes', value: likes },
                { icon: 'instagram_comments', metric: 'comments', value: comments },
            ],
            twitter: [
                { icon: 'twitter_retweet', metric: 'retweets', value: retweets },
                { icon: 'instagram_likes', metric: 'likes', value: likes }, // using instagram_likes on purpose
                { icon: 'twitter_replies', metric: 'comments', value: comments },
            ],
            linkedin: [
                { icon: 'linkedin_likes', metric: 'likes', value: likes },
                { icon: 'linkedin_shares', metric: 'shares', value: shares },
                { icon: 'linkedin_comments', metric: 'comments', value: comments },
            ],
            youtube: [
                { icon: 'youtube_likes', metric: 'likes', value: likes },
                { icon: 'youtube_comments', metric: 'comments', value: comments },
                { icon: 'preview', metric: 'views', value: views },
            ],
        };

        const globalStats = [
            // {icon: 'youtube_reach', metric: 'reach', value: reachCount}, // we don't have an icon yet...
            // also need to figure out if this should have same "showZero" logic...
        ];

        let combinedStats = [];

        if (channelStats[channel]) {
            combinedStats = combinedStats.concat(channelStats[channel]);
        }

        combinedStats = combinedStats.concat(globalStats);

        const metricContainerClasses = `engagement_metric_channel engagement_metric_${channel} flex`;

        return (
            <div className={`engagement_metrics_container flex no-grow ${classes}`}>
                {combinedStats && (
                    <div className={metricContainerClasses}>
                        {combinedStats.map(
                            (stats, idx) =>
                                (showZeroEngagementValues || stats.value > 0) && (
                                    <div
                                        key={idx}
                                        className={classNames(
                                            `engagement_metric engagement_metric_${stats.metric}  middle center flex no-grow`,
                                            {
                                                engagement_metric_value_0: !stats.value,
                                                [metricClasses]: !!metricClasses,
                                            }
                                        )}>
                                        <Icon
                                            value={stats.icon}
                                            classes='flex mr-05 primary_fill'
                                            reset={true}
                                        />
                                        <div className='engagement_metric_number flex'>
                                            {!stats.value ? 0 : stats.value}
                                        </div>
                                    </div>
                                )
                        )}
                    </div>
                )}
            </div>
        );
    }
}

export default observer(EngagementMetrics);
