import * as React from 'react';
import { observer } from 'mobx-react';
import styled, { css } from 'components/_UI/designSystem';
import { getEntityStyles, getNativeColor, getNativeMediaOverrides } from './Helpers';
import { NativeColorMode } from 'src/widgets/PostsWidget/types';
import NativeProfileLockup, {
    StyledSecondaryName,
    StyledUserName,
    StyledNativeProfileLockup,
} from './Parts/NativeProfileLockup';
import NativeFlex from './Bits/NativeFlex';
import NativeMedia, { StyledMedia } from './Parts/NativeMedia';
import { styler } from 'utils/GenerateStyles/GenerateStyles';
import { formatTextEntities } from 'utils/EntityUtils/EntityUtils';

export type NativeQuotedTweetProps = {
    quotedTweet: any;
    quotedTweetSize: number;
    colorMode: NativeColorMode;
    orientation?: string;
};

const colors = {
    lightGray: '#777',
    lighterGray: '#DBDBDB',
};

const theme = {
    light: {
        border: colors.lighterGray,
    },
    dark: {
        border: colors.lightGray,
    },
};

type StyledProps = {
    colorMode?: NativeColorMode;
    size?: any;
    orientation?: any;
    hasMedia?: any;
};

const StyledQuotedTweetPreview = styled.div`
    display: flex;
    color: black;
    ${({ colorMode }: StyledProps) =>
        css`
            border: 0.5px ${getNativeColor(theme, colorMode, 'border')} solid;
        `}
    border-radius: 2px;
    ${({ size }: StyledProps) =>
        css`
            padding: ${styler(size, 0.085, 'em', '')};
            width: ${size * 5 + 50 + '%'};
        `}
    ${({ orientation, size }: StyledProps) =>
        orientation === 'horizontal'
            ? css`
                  font-size: ${styler(size, 0.18, 'rem', '')};
              `
            : css`
                  font-size: ${styler(size, 0.12, 'rem', '')};
              `}
    ${({ orientation, hasMedia, size }: StyledProps) =>
        orientation === 'horizontal' &&
        hasMedia &&
        css`
            justify-content: space-between;
            height: ${styler(size, 6, '%', '')};
        `}
    ${({ orientation, hasMedia, size }: StyledProps) =>
        orientation === 'vertical' &&
        hasMedia &&
        css`
            flex-direction: column;
            height: ${styler(size, 5.5, '%', '')};
        `}
    
`;

const StyledPost = styled(NativeFlex)`
    width: 100%;
    ${({ size, orientation }: StyledProps) =>
        orientation === 'horizontal'
            ? css`
                  padding: ${styler(size, 0.1, 'rem', '')};
                  flex-direction: row;
                  ${StyledMedia} {
                      width: 60%;
                      max-width: 60%;
                      min-width: 60%;
                  }
              `
            : css`
                  padding: ${styler(size, 0.05, 'rem', '')};
                  flex-direction: column;
                  justify-content: space-between;
                  ${StyledMedia} {
                      max-height: 50%;
                  }
              `}
    ${({ colorMode }: any) =>
        css`
            ${getEntityStyles(getNativeColor(theme, colorMode, 'link'))}
            ${StyledUserName} {
                color: ${getNativeColor(theme, colorMode, 'userName')};
            }
            ${StyledSecondaryName} {
                color: ${getNativeColor(theme, colorMode, 'secondary')};
            }
        `}
`;

const StyledTweetMessage = styled.div`
    display: flex;
    flex-direction: column;
    word-wrap: break-word;
    overflow: hidden;
    width: 100%;
    -webkit-line-clamp: 7;
    ${({ size }: StyledProps) =>
        css`
            ${StyledNativeProfileLockup} {
                font-size: ${styler(size, 0.18, 'rem', '')};
            }
        `}
    ${({ size, orientation, hasMedia }: StyledProps) =>
        orientation === 'horizontal' &&
        hasMedia &&
        css`
            width: 40%;
            min-width: 40%;
            max-width: 40%;
            padding-left: ${styler(size, 0.2, 'em', '')};
        `}
    ${({ size, orientation, hasMedia }: StyledProps) =>
        orientation === 'vertical' &&
        hasMedia &&
        css`
            height: 50%;
            max-height: 60%;
            width: 100%;
            padding-bottom: ${styler(size, 0.2, 'em', '')};
        `}
`;

const NativeQuotedTweetPreview: React.FC<NativeQuotedTweetProps> = ({
    quotedTweet,
    colorMode,
    orientation,
    quotedTweetSize,
}) => {
    const { senderProfile, mediaList } = quotedTweet;
    const hasImage = mediaList?.length && mediaList[0].picture;

    const formattedMessage = formatTextEntities(quotedTweet.message, quotedTweet.urlEntities);
    return (
        <StyledQuotedTweetPreview
            colorMode={colorMode}
            orientation={orientation}
            size={quotedTweetSize}
            hasMedia={hasImage}>
            {orientation === 'horizontal' && (
                <StyledPost orientation={orientation} hasMedia={hasImage} size={quotedTweetSize}>
                    {hasImage && (
                        <NativeMedia images={mediaList} videos={null} snType={'twitter'} />
                    )}

                    <StyledTweetMessage
                        size={quotedTweetSize}
                        orientation={orientation}
                        hasMedia={hasImage}>
                        <NativeProfileLockup
                            colorMode={colorMode}
                            profileImgUrl={senderProfile?.profileImgUrl}
                            name={senderProfile?.name}
                            secondary={'@' + senderProfile?.screenName}
                            snType={'twitter'}
                            snCreatedTime={quotedTweet?.snCreatedTime}
                            verified={senderProfile?.verified}
                        />
                        <div className={'mt1'}>{formattedMessage}</div>
                    </StyledTweetMessage>
                </StyledPost>
            )}
            {orientation !== 'horizontal' && (
                <StyledPost
                    orientation={orientation}
                    vertical={orientation === 'vertical'}
                    size={quotedTweetSize}>
                    <StyledTweetMessage
                        size={quotedTweetSize}
                        orientation={orientation}
                        hasMedia={hasImage}>
                        <NativeProfileLockup
                            colorMode={colorMode}
                            profileImgUrl={senderProfile?.profileImgUrl}
                            name={senderProfile?.name}
                            secondary={'@' + senderProfile?.screenName}
                            snType={'twitter'}
                            snCreatedTime={quotedTweet?.snCreatedTime}
                            verified={senderProfile?.verified}
                        />
                        <div className={'mt-05 mb-05'}>{formattedMessage}</div>
                    </StyledTweetMessage>
                    {hasImage && (
                        <NativeMedia images={mediaList} videos={null} snType={'twitter'} />
                    )}
                </StyledPost>
            )}
        </StyledQuotedTweetPreview>
    );
};

NativeQuotedTweetPreview.displayName = 'NativeQuotedTweetPreview';
export default observer(NativeQuotedTweetPreview);
