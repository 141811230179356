import { action, computed, observable, toJS } from 'mobx';
import { AnalyticsFilter, AnalyticsFilterType } from '@sprinklr/stories/analytics/AnalyticsRequest';
import MetaInfoService from '../../../services/MetaInfoService/MetaInfoService';
import {
    BulkLookupValue,
    ReportingLookupFilter,
} from '../../../services/SprinklrAPIService/SprinklrAPIService';
import { WidgetDimensionKey } from '../WidgetDimensionKey';
import { WidgetRequest } from '../WidgetRequest';
import { WidgetRequestAnalytics } from '../WidgetRequestAnalytics';
import { WidgetFilter } from './WidgetFilter';
import WidgetFilterSources, { SourceDimension } from './WidgetFilterSources';
import { setObservable } from '../../../utils/Mobx/Utils';

/**
 * Wraps a raw AnalyticsFilter, providing business logic for form interaction
 */
export class WidgetFilterAnalytics extends WidgetFilter {
    @observable
    private dimensionLoaded = false;

    @observable
    dimensionDetails: any;

    @observable
    private filter: AnalyticsFilter;
    private rule: SourceDimension;
    private offset: number;

    @observable
    allValues = false;

    @observable
    isMetricFilter: boolean;

    private parent: WidgetRequestAnalytics;

    constructor(
        parent: WidgetRequest,
        filter?: AnalyticsFilter,
        offset?: number,
        rule?: SourceDimension
    ) {
        super();
        if (!filter) {
            filter = {
                dimensionName: null,
                filterType: 'IN',
                values: [],
            };
        }

        this.parent = parent as WidgetRequestAnalytics;
        this.filter = filter;
        this.offset = offset;
        this.rule = rule;

        if (filter.details && filter.details.allValues) {
            this.allValues = filter.details.allValues;
        }

        if (rule && this.filter.dimensionName === 'BRAND_ID') {
            // Special-case for BRAND_ID.  Need to make two copies and then
            // trim out competitors versus non-competitor ids per the rule passed.
            this.filter = JSON.parse(JSON.stringify(this.filter));
        }

        try {
            this.parent.metaInfoService
                .getDimension(this.parent.engine, this.parent.report, this.dimension)
                .then(dimension => {
                    this.setDetails(dimension);

                // if a metric is being used as a filter, change defailt filter type to "less than" because metric filters are numeric
                if (dimension?.measurementGroups?.length && dimension.measurementGroups[0] === 'COMMON') {
                    this.isMetricFilter = true;
                    if (filter.filterType === 'IN') {
                        filter.filterType = 'LT';
                    }
                }
                });
        } catch (e) {
            // Ignore
        }

        this.setDimensionLabel();
    }

    /**
     * Initialize the parent's "Sources" and "Filters" arrays by splitting the raw request's filters array
     *
     * @param parent
     * @param dataFilters
     */
    static create(parent: WidgetRequestAnalytics, dataFilters: AnalyticsFilter[]): void {
        dataFilters = dataFilters || [];

        parent.sourceGroup = WidgetFilterSources.findRulesDimension(parent.engine, dataFilters);
        parent.sourceGroups = WidgetFilterSources.getRulesDimensions(parent.engine);

        const filters: WidgetFilter[] = [];
        const filtersRemain = dataFilters.slice();

        const rules = WidgetFilterSources.getRules(parent.engine, parent.sourceGroup);
        if (rules) {
            let rule: any;
            let filter: AnalyticsFilter;

            // Pull off the first few filters as our sources
            const sources: WidgetFilter[] = [];
            const processed = {};
            for (let x = 0; x < rules.length; x++) {
                rule = rules[x];

                const found = dataFilters.findIndex((item: AnalyticsFilter) => {
                    return rule.dimension === item.dimensionName && item.filterType === 'IN';
                });

                if (found != -1) {
                    filter = dataFilters[found];

                    // This prevents this source filter from being added to our filters array below
                    filtersRemain[found] = null;
                } else {
                    filter = {
                        dimensionName: rule.dimension,
                        filterType: 'IN',
                        values: [],
                    };
                }
                sources.push(new WidgetFilterAnalytics(parent, filter, x, rule));
            }

            parent.sources = sources;
        }

        // Finally add the remaining filters
        filtersRemain.forEach((item: AnalyticsFilter) => {
            if (item) {
                filters.push(new WidgetFilterAnalytics(parent, item));
            }
        });

        parent.filters = filters;
    }

    @computed
    get isIncomplete(): boolean {
        const { dimensionName, values } = this.filter;

        if (this.rule && !this.rule.required) {
            return false;
            // Note: This causes problems with custom fields without childNames, so its commented out
            // } else if (this.dimension.isCustom) {
            //     return (!details || !details.fieldName || !details.fieldName.length || values.length === 0);
        } else {
            return !dimensionName || !dimensionName.length || (values.length === 0 && !this.all);
        }
    }

    @computed
    get dimension(): WidgetDimensionKey {
        // HACKTOWN: Force mobx to re-compute when *anything* changes in GroupBy.
        // Required if select Custom in GroupBy UI and then hit Revert.
        // Doesn't recompute without this line.
        const checker = toJS(this.filter);

        return WidgetDimensionKey.create(this.filter);
    }

    //
    get additional(): any {
        return this.rule ? this.rule.additional : null;
    }

    @computed
    get condition(): AnalyticsFilterType {
        return this.filter.filterType;
    }

    @computed
    get values(): string[] {
        return this.filter.values;
    }

    getJSON(): AnalyticsFilter {
        return this.filter;
    }

    @action
    setDimension(dimension: WidgetDimensionKey): void {
        setObservable(this.filter, 'dimensionName', dimension.name);

        // Is this a custom dimension?
        if (dimension.isCustom) {
            if (dimension.clientId) {
                setObservable(this.filter, 'details', 'clientId', dimension.clientId);
            }
            setObservable(this.filter, 'details', 'srcType', 'CUSTOM');
            setObservable(this.filter, 'details', 'fieldName', dimension.customName);

            // Just a built-in dimension
        } else {
            // If this was a custom filter, then remove fields we don't want
            this.filter.details = undefined;
        }

        this.setDimensionLabel();
    }

    @action
    setCondition(condition: AnalyticsFilterType): void {
        this.filter.filterType = condition;
    }

    @action
    setValues(values: string[], filter?: ReportingLookupFilter): void {
        this.filter.values.length = 0;
        this.filter.values.push.apply(this.filter.values, values);

        // We need to add or remove JSON for the source filter when values change
        if (this.rule) {
            this.parent.setFiltersFromSources();
        }
    }

    @computed
    get inputDate(): boolean {
        // TODO: this needs to support other date dimensions
        return this.dimension.name === 'SN_CREATED_TIME';
    }

    @computed
    get inputText(): boolean {
        if (this.dimensionLoaded && this.dimensionDetails) {
            return (
                this.dimensionDetails.additional &&
                this.dimensionDetails.additional.INPUT_TYPE === 'FREE_TEXT'
            );
        }

        return false;
    }

    @computed
    get numericMinValue(): number {
        return this.condition === 'RANK' ? 1 : undefined;
    }

    /**
     * True if the filter requires a numeric value e.g. numeric dimension or RANK filter.
     */
    @computed
    get requiresNumericValue(): boolean {
        return this.condition === 'RANK' || this.inputNumeric;
    }

    /**
     * True if the dimension requires a numeric value.
     */
    @computed
    get inputNumeric(): boolean {
        // if (this.filter?.filterType === 'RANK') {
        //     return true;
        // }

        if (this.dimensionLoaded && this.dimensionDetails) {
            return this.dimensionDetails.dataType === 'NUMERIC';
        }

        return false;
    }

    // Special-case needed for BRAND_ID as data source
    get brandCompetitor(): boolean {
        return this.rule?.additional?.includeCompetitor;
    }

    toJSON(): any {
        return this.filter;
    }

    @action
    private setDetails(dimension) {
        this.dimensionDetails = dimension;
        this.dimensionLoaded = true;
    }

    private setDimensionLabel(): void {
        const engine = this.parent.engine;
        const report =
            this.additional && this.additional.reportName
                ? this.additional.reportName
                : this.parent.report;

        this.parent.metaInfoService.getDimensionLabel(engine, report, this.dimension).then(
            action(label => {
                this.dimensionLabel = label;

                // HACKTOWN:  Can't find how these labels are found via Sprinklr API, so hardcoding
                if (!this.dimensionLabel && (this.dimension.name as any) === 'CLIENT_ID') {
                    this.dimensionLabel = 'Client';
                } else if (this.additional && this.additional.includeCompetitor) {
                    this.dimensionLabel = 'Competitor';
                } else if (!this.dimensionLabel && (this.dimension.name as any) === 'ACCOUNT_ID') {
                    this.dimensionLabel = 'Account';
                }

                this.isLoading = false;
            })
        );
    }

    @action
    selectAllValues(value: boolean) {
        this.allValues = value;
        if (!this.filter.details) {
            this.filter.details = {};
        }
        this.filter.details.allValues = value;

        if (value) {
            this.setValues(['all']);
        } else {
            this.setValues([]);
        }
    }

    @computed
    get all(): boolean {
        return this.allValues;
    }
}
