export type FieldTypes = 'DIMENSION' | 'MEASUREMENT' | 'CUSTOM_DIMENSION' | 'CUSTOM_MEASUREMENT';
export type ChartType = 'TABLE' | 'AUDIENCE_STREAM' | string;

export interface FieldTypeEntity {
    id: string;
    supportedFieldTypes: FieldTypes[];
    groupIds: string[];
}

/**
 * Mirrors com.spr.reporting.metadata.domain.SearchRequestDTO in sprinklr.app
 * {
 * "tags":[],
 * "fetchFilterDimensions":false,
 * "fetchPivotDimensions":false,
 * "chartType":"TABLE",
 * "fieldTypes":["DIMENSION","MEASUREMENT"],
 * "fields":[],
    "fieldTypeEntities": [
        {
            "id": "DIMENSION",
            "supportedFieldTypes": "DIMENSION",
            "groupIds": [
                "NO_GROUP"
            ]
        }
    ],
 * "query":"a",
 * "page":{"page":0,"size":40}}
 */
export default class MetaSearchRequest {
    reportName = null;
    tags = [];
    fetchFilterDimensions = false;
    fetchPivotDimensions = false;
    chartType: ChartType = 'TABLE';
    fieldTypes?: FieldTypes[];
    fieldTypeEntities?: FieldTypeEntity[];
    fields: string[];
    query?: string;
    page: {
        page: number;
        size: number;
    };

    constructor(query: string, fieldTypes: FieldTypes[], page: number = 0, pageCount: number = 40, fields: string[] = [], report = null) {
        this.reportName = report;
        this.query = query;
        this.fieldTypes = fieldTypes;
        this.page = {
            page,
            size: pageCount,
        };
        this.fields = fields?.slice() || [];
    }
}