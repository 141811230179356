import * as React from 'react';
import i18n from 'i18next';
import { observable, action } from 'mobx';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react';
import SprinklrAPIService, { CustomField } from 'services/SprinklrAPIService/SprinklrAPIService';
import { Widget } from '@sprinklr/stories/widget/Widget';
import ActionSliderGroup from './ActionSliderGroup';
import { PostsWidgetOptionsImpl } from '../../../../widgets/PostsWidget/options';
import { LabeledSelect } from 'components/spaceKit';

type ActionCustomPropertiesProps = {
    sprinklrAPIService?: SprinklrAPIService;
    widget: Widget;
};

type SelectOptions = { label: string; value: string };

class ActionCustomProperties extends React.Component<ActionCustomPropertiesProps> {
    @observable customProperties: SelectOptions[] = [];
    componentDidMount() {
        this.props.sprinklrAPIService &&
            this.props.sprinklrAPIService.searchCustomFields({}).then(
                action((customFields: CustomField[]) => {
                    const properties = [];
                    customFields?.map(field =>
                        properties.push({ label: field.name, value: field.fieldName })
                    );
                    this.customProperties = properties;
                })
            );
    }

    render() {
        const { widget } = this.props;
        const options = widget.options as PostsWidgetOptionsImpl;
        return (
            <>
                <LabeledSelect
                    options={[{ label: 'None', value: '' }, ...this.customProperties.slice()]}
                    onChange={this.onChangeCustomFields}
                    value={{
                        label: options.customFields.fieldId,
                        value: options.customFields.fieldId,
                    }}
                    label={i18n.t('Custom Property')}
                />
                {options.customFields.fieldId && (
                    <ActionSliderGroup
                        key='options.customFields.size'
                        label={i18n.t('Size')}
                        option={options.customFields}
                        value='size'
                        showValue
                        min={0}
                        max={100}
                    />
                )}
            </>
        );
    }

    @action
    onChangeCustomFields = (selection: SelectOptions) => {
        console.log('selection', selection);
        (this.props.widget.options as PostsWidgetOptionsImpl).customFields.fieldId =
            selection.value;
    };
}

export default inject('sprinklrAPIService')(observer(ActionCustomProperties));
