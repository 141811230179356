import { computed, observable, toJS } from 'mobx';
import { WidgetJSON } from '../WidgetWrapper';
import { WidgetRequest } from '../WidgetRequest';
import { WidgetRequestAnalytics } from '../WidgetRequestAnalytics';
import { AnalyticsSort, AnalyticsSortOrder } from '@sprinklr/stories/analytics/AnalyticsRequest';
import { setObservable } from 'utils/Mobx/Utils';

/**
 * Wraps a raw AnalyticsSort, providing business logic for form interaction
 */
export class WidgetSort implements WidgetJSON {
    @observable private sort: AnalyticsSort;

    private parent: WidgetRequestAnalytics;

    constructor(parent: WidgetRequest, sort?: AnalyticsSort) {
        if (!sort) {
            sort = {
                order: 'DESC',
                heading: null,
            };
        }

        this.parent = parent as WidgetRequestAnalytics;
        this.sort = sort;
    }

    @computed
    get heading(): string {
        return WidgetSort.getHeading(this.sort);
    }

    @computed
    get report(): string {
        // HACKTOWN: Forces recompute if details block suddendly appears
        const HACKTOWN = toJS(this.sort);
        return WidgetSort.getReport(this.sort);
    }

    @computed
    get uniqueKey(): string {
        return 'uniqueKey';
    }

    @computed
    get order(): AnalyticsSortOrder {
        return this.sort.order;
    }

    getUnique(report?: string): string {
        report = this.report || report;

        if (report) {
            return ("M_" + report + "_" + this.heading).toUpperCase();
        }

        return this.heading;
    }

    static getHeading(sort: AnalyticsSort): string {
        return sort.heading;
    }

    static getReport(sort: AnalyticsSort): string {
        return sort.details?.origReport;
    }

    // Return the dimension name, if the heading matches one of the headings in metrics or dimensions
    get dimension(): string {
        if (
            this.parent.metrics &&
            this.parent.metrics.length &&
            this.parent.metrics[0].heading === this.heading
        ) {
            return this.parent.metrics[0].metric;
        } else if (
            this.parent.groupBys &&
            this.parent.groupBys.length &&
            this.parent.groupBys[0].heading === this.heading
        ) {
            return this.parent.groupBys[0].dimension.name;
        } else {
            return null;
        }
    }

    setHeading(heading: string, report?: string, isDimension?: boolean): void {
        this.sort.heading = heading;

        if (report) {
            setObservable(this.sort, 'details', 'origReport', report);

            if (isDimension !== undefined) {
                this.sort.isDimension = isDimension;
            }
        }
    }

    setOrder(order: AnalyticsSortOrder): void {
        this.sort.order = order;
    }

    toJSON(): any {
        return this.sort;
    }
}
