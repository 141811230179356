export const twitterDimensions = [
    {
        name: 'TRENDING_TOPIC',
        fieldName: 'TRENDING_TOPIC',
        hidden: false,
        uniqueKey: 'TRENDING_TOPIC',
        type: 'NORMAL',
        sortable: true,
        multiValue: false,
        multiValuedBackend: false,
        filterDimension: false,
        channelTypes: [],
        allowedAsDrilldown: false,
        groupByAllowed: true,
        displayName: 'Trending Topic',
    },
    {
        name: 'WOEID',
        fieldName: 'WOEID',
        hidden: false,
        uniqueKey: 'WOEID',
        type: 'NORMAL',
        sortable: false,
        multiValue: false,
        multiValuedBackend: false,
        filterDimension: true,
        channelTypes: [],
        allowedAsDrilldown: false,
        groupByAllowed: false,
        displayName: 'Location',
    },
];

export const twitterMetrics = [
    {
        name: 'MENTIONS',
        fieldName: 'MENTIONS',
        hidden: false,
        uniqueKey: 'MENTIONS',
        type: 'DELTA',
        isShared: false,
        shared: false,
        displayName: 'Mentions',
    },
];
