import {
    AnalyticsEngine,
    AnalyticsFilter,
    AnalyticsGroupBy,
    AnalyticsSort,
} from '@sprinklr/stories/analytics/AnalyticsRequest';
import TimePeriod from 'models/TimePeriod/TimePeriod';
import { ProfileRequest } from '@sprinklr/stories/profile/ProfileRequest';
import Axios, { CancelTokenSource } from 'axios';
import SprinklrAPIService, { PromiseWithCancel } from '../SprinklrAPIService/SprinklrAPIService';
import { PostsRequest } from '@sprinklr/stories/post/PostsRequest';
import PostsService from '@sprinklr/stories-services/PostsService/PostsService';
import { WidgetDimensionKey } from 'utils/Widget/WidgetDimensionKey';
import { Post, PostProfile } from '@sprinklr/stories/post/Post';
import TimePeriodService from 'services/TimePeriodService/TimePeriodService';
import { ReportingRequest, ReportingResponse } from '@sprinklr/stories/reporting/types';

interface EngineDefault {
    defaultReport: string;
    defaultSortKey: string;
    profileKey: string;
}

type EngineDefaults = { [key: string]: EngineDefault };

export default class ProfileService {
    // candidates for listening profile ids
    //     // Sprinklr Metadata
    //     ACCOUNT_GROUP_ID,
    //     ACCOUNT_ID,
    //     USER_ID,
    //     AFFECTED_USER_ID,
    //     PARTNER_PROFILE_LISTS,
    //     CLIENT_PROFILE_LISTS
    //
    // // Generic user id field
    //     FROM_SN_USER
    //
    // // Options in UI
    //     PROLIFIC_USERS, // seems to be pass through to FROM_SN_USER
    //     REACH_USERS, // seems to be pass through to FROM_SN_USER
    //     KLOUT_USERS, // seems to be pass through to FROM_SN_USER
    //     ADVOCATES, // automatically adds positive sentiment filter
    //     DETRACTORS, // automatically adds negative sentiment filter
    //     USER_MENTION,
    //     PHOTO_BRANDS_KLOUT

    // candidates for reporting/platform profile ids
    // ACCOUNT_ID

    static engines: EngineDefaults = {
        // PAID: {
        //     profileKey: 'STATUS_ID', // adBuyId? adSetId?
        //     defaultSortKey: 'TOTAL_ENGAGEMENT'
        // },
        LISTENING: {
            defaultReport: 'SPRINKSIGHTS',
            defaultSortKey: 'REACH_COUNT',
            profileKey: 'PROLIFIC_USERS',
        },
        // INBOUND_MESSAGE: {
        //     defaultReport: 'INBOUND_MESSAGE',
        //     defaultSortKey: 'INBOUND_COUNT',
        //     profileKey: 'POST_ID'
        // },
        PLATFORM: {
            defaultReport: 'POST_INSIGHTS',
            defaultSortKey: 'TOTAL_ENGAGEMENT',
            profileKey: 'ACCOUNT_ID',
        },
        BENCHMARKING: {
            defaultReport: 'POST_STATS_LIFETIME',
            defaultSortKey: 'TOTAL_ENGAGEMENT',
            profileKey: 'BENCHMARKING_ACCOUNT_ID', // BRAND_ID
        },
        STORY_MESSAGE: {
            defaultReport: 'STORY_MESSAGE',
            defaultSortKey: 'MENTIONS_COUNT',
            profileKey: 'ADVOCATES',
        },
    };

    sprinklrAPIService: SprinklrAPIService;

    postsService: PostsService;

    constructor(sprinklrAPIService: SprinklrAPIService, postsService: PostsService) {
        this.sprinklrAPIService = sprinklrAPIService;
        this.postsService = postsService;
    }

    public getProfiles(
        request: ProfileRequest,
        cancelSource?: CancelTokenSource
    ): PromiseWithCancel<PostProfile[]> {
        if (!cancelSource) {
            cancelSource = Axios.CancelToken.source();
        }

        const engine: AnalyticsEngine = request.reportingEngine || 'LISTENING';
        const engineMeta = ProfileService.engines[engine];

        if (!engineMeta) {
            throw new Error('Engine "' + request.reportingEngine + '" not supported');
        }

        const timePeriod = request.timePeriod
            ? this.sprinklrAPIService.getTimePeriod(request.timePeriod)
            : TimePeriodService.createFromTimePeriodKey('last_7_days');

        const topicIdsFilter = request.filters.some(
            (filter: AnalyticsFilter) =>
                filter.dimensionName === 'TOPIC_IDS' &&
                filter.filterType === 'IN' &&
                filter.values.length > 0
        );

        const filters = request.filters.filter((filter: AnalyticsFilter) => {
            return (
                filter.dimensionName &&
                filter.filterType &&
                filter.values &&
                filter.values.length > 0 &&
                !(
                    topicIdsFilter &&
                    filter.dimensionName === 'TOPIC_GROUP_IDS' &&
                    filter.filterType === 'IN'
                )
            );
        });

        const projections = [];

        // If groupbys are provided, this is a new-style request
        const newRequest = request.groupBys?.length > 0;
        const groupBys: AnalyticsGroupBy[] = newRequest
            ? request.groupBys
            : [
                  {
                      heading: engineMeta.profileKey,
                      dimensionName: engineMeta.profileKey,
                      groupType: 'FIELD',
                  },
              ];

        let sorts: AnalyticsSort[] = request.sorts || [];

        // default sort key.
        if (!sorts || sorts.length === 0) {
            sorts = [
                {
                    order: 'DESC',
                    heading: engineMeta.defaultSortKey,
                    isDimension: false,
                },
            ];
        }

        sorts = sorts.map((sort: AnalyticsSort) => {
            if (sort.isDimension === true) {
                groupBys.push({
                    heading: sort.heading,
                    dimensionName: sort.heading as any,
                    groupType: 'FIELD',
                });
            } else if (sort.heading) {
                projections.push({
                    heading: sort.heading,
                    measurementName: sort.heading,
                    aggregateFunction: newRequest ? 'SUM' : 'MAX', // not SUM! could catch multiple posts over time interval
                });
            }

            return {
                order: sort.order,
                heading: sort.heading,
            };
        });

        if (projections.length === 0) {
            projections.push({
                heading: engineMeta.defaultSortKey,
                measurementName: engineMeta.defaultSortKey,
                aggregateFunction: newRequest ? 'SUM' : 'MAX', // not SUM! could catch multiple posts over time interval
            });
        }

        const startTime = timePeriod.startDate;
        const endTime = timePeriod.endDate;

        const requestObject: ReportingRequest = {
            reportingEngine: engine,
            report: request.report || engineMeta.defaultReport,

            projections,
            groupBys,
            filters,
            sorts,

            startTime: startTime ? startTime.valueOf() : null,
            endTime: endTime ? endTime.valueOf() : null,
            timeZone: request.timePeriod ? request.timePeriod.timeZone : undefined,

            page: request.page || 0,
            pageSize: request.pageSize || 20,

            skipResolve: true,
        };

        // console.log('reporting posts request', JSON.stringify(requestObject, null, 4));

        let promise: any = this.sprinklrAPIService.query(requestObject, cancelSource);

        promise = promise.then((response: ReportingResponse): any => {
            const profileIds = response.rows.map((row: any[]): string => {
                return row[0];
            });

            if (engine !== 'LISTENING' && engine !== 'BENCHMARKING') {
                // REPORTING doesn't have senderProfile in post response
                return this.getProfilesById(
                    profileIds,
                    new WidgetDimensionKey(engineMeta.profileKey),
                    request.reportingEngine,
                    request.report,
                    timePeriod,
                    cancelSource
                );
            }

            const promises: any[] = profileIds.map((profileId: string) => {
                const newFilters = requestObject.filters.slice();
                newFilters.push({
                    dimensionName: groupBys[0].dimensionName,
                    filterType: 'IN',
                    values: [profileId],
                });

                const postsRequest: PostsRequest = {
                    source: 'REPORTING',
                    reportingEngine: engine,
                    report: requestObject.report,
                    filters: newFilters,
                    sorts,
                    timePeriod: request.timePeriod,
                    pageSize: 1,
                    includeQuoteTweets: true,
                };

                return this.postsService
                    .getPosts(postsRequest, cancelSource)
                    .then((posts: Post[]): any => {
                        const profile = posts[0]?.senderProfile ?? null;
                        if (profile) {
                            // Copy this down, since not present in senderProfile
                            profile.snType = posts[0].snType;
                        }
                        return profile;
                    })
                    .catch(error => {
                        console.error(error);
                    });
            });

            return Promise.all(promises).then((profiles: PostProfile[]) => {
                return profiles.filter(profile => {
                    return !!profile;
                });
            });
        });

        promise.cancel = (message?: string) => {
            cancelSource.cancel(message);
        };

        return promise;
    }

    private getProfilesById(
        profileIds: string[],
        fieldName: WidgetDimensionKey,
        engine: AnalyticsEngine,
        report: string,
        timePeriod?: TimePeriod,
        cancelSource?: CancelTokenSource
    ): PromiseWithCancel<PostProfile[]> {
        if (!cancelSource) {
            cancelSource = Axios.CancelToken.source();
        }

        const promise: any = this.sprinklrAPIService
            .bulkLookup(engine, report, fieldName, profileIds, timePeriod, cancelSource)
            .then(response => {
                const duplicates = {};

                if (!response) {
                    return [];
                }

                return profileIds
                    .filter((profileId: any) => {
                        return profileId in response;
                    })
                    .map((postId: any) => {
                        const profile = response[postId];

                        // Massage stats so Profile widget likes it
                        if (engine === 'STORY_MESSAGE') {
                            const socialProfile =
                                profile?.socialProfiles?.length && profile?.socialProfiles[0];

                            profile.name = profile?.contactInfo?.fullName || profile.name;
                            profile.screenName = socialProfile?.username || profile.name;
                            profile.favCount = socialProfile?.favCount;
                            profile.followers = socialProfile?.followers;
                            profile.profileImgUrl = socialProfile?.profileImageUrl;
                        }

                        return profile;
                    })
                    .filter((profile: any) => {
                        if (engine === 'STORY_MESSAGE') {
                            const found = duplicates[profile.name];
                            if (found) {
                                // Merge metrics together for duplicates.  Not sure right behavior.
                                found.favCount += profile.favCount || 0;
                                found.followers += profile.followers || 0;
                                return false;
                            }

                            duplicates[profile.name] = profile;
                        }

                        return true;
                    });
            });

        promise.cancel = (message?: string) => {
            cancelSource.cancel(message);
        };

        return promise;
    }
}
