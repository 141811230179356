export const paddedDefaultPosition = {
    top: '3.72%',
    left: '2.08%',
    height: '92.56%',
    width: '95.84%',
    portrait: {
        top: '2%',
        left: '3%',
        width: '94%',
        height: '96%',
    },
};

export const DefaultPosition = {
    width: '100%',
    height: '100%',
    top: '0',
    left: '0',
};

export const DefaultWidgetPosition = {
    width: '50%',
    height: '50%',
    top: '25%',
    left: '25%',
};

// Side-by side
export const DefaultTwinWidgetPositions = [
    {
        width: '33%',
        height: '33%',
        top: '33%',
        left: '16%',
    },
    {
        width: '33%',
        height: '33%',
        top: '33%',
        left: '50%',
    },
];

// Two in top row, one below
export const DefaultTripleWidgetPositions = [
    {
        width: '33%',
        height: '33%',
        top: '16%',
        left: '16%',
    },
    {
        width: '33%',
        height: '33%',
        top: '16%',
        left: '50%',
    },
    {
        width: '33%',
        height: '33%',
        top: '50%',
        left: '33%',
    },
];

// Two-by-two grid
export const DefaultQuadWidgetPositions = [
    {
        width: '33%',
        height: '33%',
        top: '16%',
        left: '16%',
    },
    {
        width: '33%',
        height: '33%',
        top: '16%',
        left: '50%',
    },
    {
        width: '33%',
        height: '33%',
        top: '50%',
        left: '16%',
    },
    {
        width: '33%',
        height: '33%',
        top: '50%',
        left: '50%',
    },
];

export const BrandColors = {
    lightBlue: '#3CB4D2',
    blue: '#0c7edd',
    darkBlue: '#005fc8',
    violet: '#6464dc',
    purple: '#3c3c78',
    orange: '#ff9421',
    yellow: '#ffd200',
    red: '#ff6060',
    teal: '#20b796',
    negative: '#FF5050',
    grey: '#23232d',
};

export const BrandColorsArray = [
    BrandColors.lightBlue,
    BrandColors.blue,
    BrandColors.darkBlue,
    BrandColors.violet,
    BrandColors.purple,
    BrandColors.orange,
    BrandColors.yellow,
    BrandColors.red,
    BrandColors.teal,
];
