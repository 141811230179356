import * as React from 'react';
import i18n from 'i18next';
import { action } from 'mobx';

import { TunablesCollapse, CollapseProps } from '../../components/Sidebar/RenderTunables/RenderTunables';
import LabelGroup from '../../components/_UI/Forms/LabelGroup/LabelGroup';
import ActionSliderGroup from '../../components/_UI/Forms/Actions/ActionSliderGroup';
import ActionColorSwatchGroup from '../../components/_UI/Forms/Actions/ActionColorSwatchGroup';
import { LabeledCheckbox } from 'components/spaceKit';
import ActionCheckboxSliderColorSwatchGroup from 'components/_UI/Forms/Actions/ActionCheckboxSliderColorSwatchGroup';
import ActionSelectGroup from 'components/_UI/Forms/Actions/ActionSelectGroup';
import ActionInput from 'components/_UI/Forms/Actions/ActionInput';

import { Theme } from 'src/models/Theme/Theme';
import { Widget } from '@sprinklr/stories/widget/Widget';
import { endianOptions } from 'components/Panel/PanelEditorSidebar/PanelDesign/PanelDesignOptions';
import { DynamicTitleWidgetOptions } from './options';
import { opacityTunables } from 'components/Sidebar/RenderTunables/SharedTunables';

const DynamicTitleTunables = (
    widget: Widget,
    mergedTheme: Theme,
): TunablesCollapse[] => {
    const dataSourceOptions = widget.options.dataSourceNames && Object.keys(widget.options.dataSourceNames).map(dataSource => {
        return { 
            label: widget.options.dataSourceNames[dataSource],
            value: widget.options.dataSourceNames[dataSource]
        };
    });

    if ((!dataSourceOptions || !dataSourceOptions.length) && widget.options.useSourceAsTitle) {
        widget.options.useSourceAsTitle = false;
        widget.options.useStoryboardNameAsTitle = true;
    }
    return [
    {
        props: {
            label: i18n.t('Dynamic Title'),
            startOpen: true,
        } as CollapseProps,
        children: [
            opacityTunables(widget),
            <ActionSliderGroup
                key='widget.fontSize'
                label={i18n.t('Size')}
                option={widget.options}
                value='fontSize'
                showValue
                min={0}
                max={100}
            />,
            <LabelGroup label={i18n.t('Color')} key='options.color'>
                <ActionColorSwatchGroup
                    value={null}
                    option={null}
                    secondaryIcon={
                        (widget.options as DynamicTitleWidgetOptions).color
                            ? 'icon-undo'
                            : undefined
                    }
                    color={
                        (widget.options as DynamicTitleWidgetOptions).color
                        !== ''
                            ? (widget.options as DynamicTitleWidgetOptions)
                                .color
                            : mergedTheme.typography && mergedTheme.typography.color
                    }
                    onClear={action(
                        () =>
                            ((widget.options as DynamicTitleWidgetOptions).color =
                                '')
                    )}
                    onChange={action(
                        ({ rgb: { r, g, b, a } }: any) =>
                            ((widget.options as DynamicTitleWidgetOptions).color = `rgba(${r}, ${g}, ${b}, ${a})`)
                    )}
                />
            </LabelGroup>,
            dataSourceOptions && dataSourceOptions.length && 
                <LabeledCheckbox
                    key='widget.options.useSourceAsTitle'
                    label={i18n.t('Use Source Name As Title')}
                    onChange={e => setSourceAsTitle(e, widget)}
                    checked={dataSourceOptions.length && widget.options.useSourceAsTitle}
                    disabled={widget.options.useStoryboardNameAsTitle}
                />,
            widget.options.useSourceAsTitle && dataSourceOptions && dataSourceOptions.length && (
                <ActionSelectGroup
                    label={i18n.t('Data Source')}
                    key='widget.options.sourceName'
                    option={widget.options}
                    value='sourceName'
                    options={dataSourceOptions}
                    defaultValue={dataSourceOptions[0]}
                />
            ),
            <LabeledCheckbox
                key='widget.options.useStoryboardNameAsTitle'
                label={i18n.t('Use Storyboard Name As Title')}
                onChange={e => setStoryboardNameAsTitle(e, widget)}
                checked={widget.options.useStoryboardNameAsTitle}
                disabled={widget.options.useSourceAsTitle}
            />,
            (!widget.options.useSourceAsTitle || !dataSourceOptions.length) && !widget.options.useStoryboardNameAsTitle &&
                (
                    <LabelGroup label={i18n.t('Title Text')} key='inputTitleText'>
                        <ActionInput
                            key='widget.options.inputTitleText'
                            option={widget.options}
                            value='inputTitleText'
                        />
                    </LabelGroup>
                ),
                <ActionCheckboxSliderColorSwatchGroup
                    key='widget.options.date'
                    label={i18n.t('Date Range')}
                    showValue
                    checkbox={{
                        option: widget.options.date,
                        value: 'enabled',
                    }}
                    slider={{
                        option: widget.options.date,
                        value: 'size',
                        min: 0,
                        max: 100,
                    }}
                    colorSwatch={{
                        option: widget.options.date,
                        value: 'color',
                        onClear: action(
                            () => (widget.options.date.color = '')
                        ),
                        secondaryIcon: widget.options.date.color
                            ? 'icon-undo'
                            : undefined,
                        color:
                            widget.options.date.color !== '' ? widget.options.date.color : mergedTheme?.typography?.color,
                        onChange: action(
                            ({ rgb: { r, g, b, a } }) =>
                                (widget.options.date.color = `rgba(${r}, ${g}, ${b}, ${a})`)
                        ),
                    }}
                />,
                widget.options.date.enabled && (
                    <ActionSelectGroup
                        label={i18n.t('Date Format')}
                        key='widget.options.date.format'
                        option={widget.options.date}
                        value='format'
                        options={[
                            { value: 'Pretty Time', label: 'Pretty Time' },
                            ...endianOptions,
                        ]}
                    />
                ),
        ],
    },
]};

const setSourceAsTitle  = action((e, currWidget: Widget) => {
    return currWidget.options.useSourceAsTitle = e.target.checked;
});

const setStoryboardNameAsTitle  = action((e, currWidget: Widget) => {
    return currWidget.options.useStoryboardNameAsTitle = e.target.checked;
});

export default DynamicTitleTunables;
