import { AnalyticsRequest, GroupBy, Metric, Sort } from './PanelTemplatesTheme/PanelTemplatesTheme';
import { DefaultWidgetPosition } from './PanelTemplatesTheme/constants';
import { Widget } from '@sprinklr/stories/widget/Widget';
import { WIDGET_LABEL_SIZE } from '../../components/Widget/WidgetLabel/constants';
import { TEXT_SHADOW_DEFAULT } from 'models/Widget/Widget';

export var WidgetProfile: Widget = {
    type: 'profile',
    id: 'profile-1-by-3',
    name: 'Profile',
    useGlobalTimePeriod: true,
    profileRequests: [
        // Note: All global variables like reportingEngine, report, filters, and projections
        // are injected via AnalyticsRequest.getRequests()
        new AnalyticsRequest({
            page: 0,
            pageSize: 50,
            groupBys: [
                new GroupBy({
                    LISTENING: { dimensionName: 'ADVOCATES' },
                }),
            ],
            projections: [
                new Metric({
                    STORY_MESSAGE: { dimensionName: 'MENTIONS_COUNT', heading: 'Mentions' },
                }),
            ],
            sorts: [
                new Sort({
                    order: 'DESC',
                    heading: {
                        LISTENING: 'MENTIONS_COUNT',
                        PLATFORM: 'TOTAL_ENGAGEMENT',
                        BENCHMARKING: 'TOTAL_ENGAGEMENT',
                        RDB_FIREHOSE: 'SN_CREATED_TIME',
                        STORY_MESSAGE: 'MENTIONS_COUNT',
                    },
                }),
            ],
            timePeriod: {
                key: 'last_7_days',
            },
        }) as any,
    ],
    position: DefaultWidgetPosition,
    options: {
        player: {
            playing: true,
            duration: 15,
        },
        numbProfiles: 3,
        showDescription: true,
        showIcon: true,
        verticalAlign: 'top',

        showMetricValue: true,
        metricValueSize: 30,

        showMetricName: true,
        metricNameSize: 10,
        userGroupAvatarSize: 70,
        smartSize: {
            enabled: true,
            mode: 'responsive',
            size: 'm',
        },
        portrait: {
            widgetOrientation: 'portrait',
        },
    },
    label: {
        titleText: 'Profiles',
        size: WIDGET_LABEL_SIZE.primary,
        enabled: false,
        textShadow: TEXT_SHADOW_DEFAULT,
    },
};
