import Color from 'color';
import { Theme } from 'models/Theme/Theme';
import { scaleLinear, scaleSqrt } from 'd3-scale';
import { rgb } from 'd3-color';
import { isValidColorString } from 'utils/StringUtils/StringUtils';

type ScaleTypes = 'scaleLinear' | 'scaleSqrt';

const getColorRotation = (color: string, rotation: number): { start: string; end: string } => {
    return {
        start: rgb(
            Color(color)
                .rotate(-rotation)
                .toString()
        ).toString(),
        end: rgb(
            Color(color)
                .rotate(rotation)
                .toString()
        ).toString(),
    };
};

const getScale = (scaleType: ScaleTypes): any => {
    switch (scaleType) {
        case 'scaleLinear':
            return scaleLinear();
        case 'scaleSqrt':
            return scaleSqrt();
    }
};

const getColorRange = (
    start: string,
    stop: string,
    length: number,
    scale: ScaleTypes = 'scaleLinear'
): string[] => {
    const range = new Array(length).fill(0);
    const getColorValue = getScale(scale)
        .domain([0, length - 1])
        .range([start, stop]);
    return range.map((color, index) => getColorValue(index));
};

//Generates a gradient with more than two colors
const getMultiColorRange = (colors, totalItems): string[] => {
    const range = new Array(totalItems).fill(0);
    const denominator = colors.length - 1;
    const domain = [];
    for (let i = 0; i <= denominator; i++) {
        domain.push((totalItems - 1) * (i / denominator));
    }

    const getColorValue = getScale('scaleLinear')
        .domain(domain)
        .range(colors);

    return range.map((color, index) => getColorValue(index));
};

const get3ColorRange = (
    start: string,
    middle: string,
    stop: string,
    length: number,
    scale: ScaleTypes = 'scaleLinear'
) => {
    const getFirstColor = getScale(scale)
        .domain([0, length / 2])
        .range([start, middle]);
    const getLastColor = getScale(scale)
        .domain([0, length / 2])
        .range([middle, stop]);

    const firstCount = Math.floor(length / 2);
    const lastCount = firstCount + (firstCount * 2 < length ? 1 : 0);

    const first = new Array(firstCount).fill(0).map((color, index) => getFirstColor(index));
    const last = new Array(lastCount).fill(0).map((color, index) => getLastColor(index));
    last.splice(0, 1);
    return [...first, middle, ...last];
};

//Generates gradient with color from 1 to 0.2 Alpha
const getCustomGradientColors = (colors, totalItems): string[] => {
    const start = colors[0].trim();
    const stop = rgb(
        Color(start.trim())
            .lighten(0.8)
            .toString()
    ).toString();

    return getColorRange(stop, start.trim(), totalItems);
};

const generateColorPalette = (
    mergedTheme: Theme,
    totalItems,
    reversed = false,
    theme?: Theme,
    gradient?,
    noRepeat = false
): string[] => {
    const { colorPalette } = mergedTheme;

    if (
        !colorPalette ||
        !colorPalette.colorBlend ||
        !colorPalette.monochrome ||
        !colorPalette.ranked
    ) {
        return [];
    }
    const {
        type,
        colorBlend: { startColor, endColor },
        monochrome: { startColor: mStartColor, endOffset },
        solid,
        ranked: { colors, contrast },
    } = colorPalette;

    if (type === 'colorBlend') {
        return generateColorBlendColors(startColor, endColor, totalItems, reversed);
    } else if (type === 'monochrome') {
        return generateMonochromeColors(mStartColor, endOffset * 0.01, totalItems, reversed);
    } else if (type === 'solid') {
        if (colors && colors.length === 1) {
            return generateSolidColorArray(colors[0], totalItems);
        }
        return generateSolidColorArray(solid, totalItems);
    } else if (type === 'ranked') {
        if (
            theme &&
            theme.colorPalette.ranked &&
            theme.colorPalette.ranked.colors &&
            theme.colorPalette.ranked.colors.length > 0
        ) {
            return generateRankedColors(
                theme.colorPalette.ranked.colors.slice(),
                contrast,
                totalItems,
                gradient,
                noRepeat
            );
        } else {
            return generateRankedColors(colors, contrast, totalItems, gradient, noRepeat);
        }
    }
};

const generateRankedColors = (
    colors,
    contrast,
    totalItems,
    gradient?: boolean,
    noRepeat?: boolean
) => {
    let output = [];

    if (!colors || (colors && colors.length === 0)) {
        return output;
    }

    if (gradient) {
        output = getCustomGradientColors(colors, totalItems);
    } else {
        const divide = totalItems / colors.length + 1;

        for (let i = divide; i >= 0; i--) {
            const modifiedColors = colors
                .slice()
                .filter(color => isValidColorString(color)) // Check for invalid color strings
                .map((color, idx) => {
                    let stop = rgb(
                        Color(color.trim())
                            .darken(contrast * 0.01)
                            .toString()
                    ).toString();
                    if (noRepeat) {
                        stop = rgb(
                            Color(stop.trim())
                                .lighten((divide - i) * 0.8)
                                .toString()
                        ).toString();
                    }
                    const getVal = getScale('scaleLinear')
                        .domain([0, divide])
                        .range([color, stop]);
                    return getVal(i);
                });

            output = [...modifiedColors, ...output];
        }
    }

    return output;
};

const generateSolidColorArray = (color, limit: number) => {
    const output = [];
    for (let i = limit; i >= 0; i--) {
        output.push(color);
    }
    return output;
};

export const getMonochromeStopColor = (start: string, endOffset: number): string => {
    return Color(start.trim())
        .alpha(Math.abs(0.99 - endOffset))
        .toString();
};

const generateMonochromeColors = (
    start: string,
    darkness: number,
    limit: number,
    reversed: boolean
) => {
    if (!start || !darkness || !limit) {
        return [];
    }

    const result = getColorRange(start.trim(), getMonochromeStopColor(start, darkness), limit);
    return reversed ? result.reverse() : result;
};

const generateColorBlendColors = (
    start: string,
    stop: string,
    limit: number,
    reversed: boolean
): string[] => {
    if (!start || !stop || !limit) {
        return [];
    }
    const result = getColorRange(start.trim(), stop.trim(), limit);
    return reversed ? result.reverse() : result;
};

const getSocialColor = (label: string, theme: Theme) => {
    if (socialColors[label]) {
        return socialColors[label];
    } else {
        return theme.colorPalette.solid;
    }
};
//
// export const socialColors = {
//     instagram: '#e4405f',
//     twitter: '#00abf6',
//     facebook: '#3B5998',
//     vimeo: '#1bb6ec',
//     tumblr: '#2e4c69',
//     pinterest: '#b8242a',
//     googleplus: '#db4a36',
//     flickr: '#FF0084',
//     youtube: '#bb0000',
//     blogger: '#ff6704',
//     vine: '#02a379',
//     rss: '#ff7010',
//     google: '#62a6fb',
//     foursquare: '#0072b1',
//     weibo: '#E43037',
//     wordpress: '#21759b',
//     wechat: '#7BB32E',
//     outlook: '#0072C6',
// };

// updated list sourced from https://www.lockedownseo.com/social-media-colors/#sass
export const socialColors = {
    facebook: '#1877f2',
    twitter: '#1da1f2',
    youtube: '#ff0000',
    instagram: '#e1306c',
    google: '#ea4335',
    pinterest: '#bd081c',
    googleplus: '#db4437',
    linkedin: '#0a66c2',
    vimeo: '#1ab7ea',
    tumblr: '#2c4762',
    snapchat: '#fffc00',
    blogger: '#ff6704',
    whatsapp: '#25d366',
    tiktok: '#010101',
    mastodon: '#2b90d9',
    apple: '#a6b1b7',
    amazon: '#ff9900',
    alexa: '#00a7ce',
    periscope: '#40a4c4',
    foursquare: '#f94877',
    yelp: '#d32323',
    swarm: '#ffa633',
    medium: '#02b875',
    skype: '#00aff0',
    android: '#a4c639',
    stumbleupon: '#e94826',
    flickr: '#f40083',
    yahoo: '#430297',
    twitch: '#9146ff',
    soundcloud: '#ff5500',
    spotify: '#1db954',
    dribbble: '#ea4c89',
    slack: '#e01e5a',
    reddit: '#ff5700',
    deviantart: '#05cc47',
    pocket: '#ee4056',
    quora: '#aa2200',
    slideshare: '#e68523',
    fivehundredpx: '#0099e5',
    vk: '#4a76a8',
    listlyorange: '#df6d46',
    listlyblue: '#52b1b3',
    vine: '#00b489',
    steam: '#171a21',
    discord: '#7289da',
    telegram: '#0088cc',
    clarity: '#61bed9',
    homeadvisor: '#f89000',
    houzz: '#4dbc15',
    angieslist: '#29a036',
    glassdoor: '#0caa41',
    wordpress: '#00749C',
};

const getColor = (label: string, color: string) => {
    return color;
};

export {
    generateRankedColors,
    generateMonochromeColors,
    generateColorBlendColors,
    generateSolidColorArray,
    getSocialColor,
    getColor,
    generateColorPalette,
    getColorRotation,
    getColorRange,
    get3ColorRange,
};
