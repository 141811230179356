import Color from 'color';
import { action, extendObservable, observable, computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { SocialMediaAsset } from '../../../../models/SocialMediaAsset/SocialMediaAsset';
import { Theme } from '../../../../models/Theme/Theme';
import { WidgetFont, Widget } from '@sprinklr/stories/widget/Widget';
import EditorStateService from '../../../../services/EditorStateService/EditorStateService';
import { SAMSearchFonts } from '../../../../services/SocialMediaAssetService/SAMSearch';
import { SocialMediaAssetService } from '../../../../services/SocialMediaAssetService/SocialMediaAssetService';
import { fontFamilyOptions, InlineStyles } from './PanelDesignOptions';
import TypographyFontControl from './TypographyFontControl';
import LabelGroup from '../../../_UI/Forms/LabelGroup/LabelGroup';
import ActionColorSwatchGroup from '../../../_UI/Forms/Actions/ActionColorSwatchGroup';
import i18n from '../../../../i18n';
import { LabeledSlider, Icon, Flexbox } from 'components/spaceKit';
import { CustomContentRichTextOptionsImpl } from 'src/widgets/CustomContentWidget/options';
const debounce: any = require('debounce');

export interface SupportsRichTextProps {
    widget: Widget;
    editorStateService?: EditorStateService;
    socialMediaAssetService?: SocialMediaAssetService;
    mergedTheme?: Theme;
}

class SupportsRichText extends React.Component<SupportsRichTextProps, any> {
    @observable
    private assets: SocialMediaAsset[] = [];

    private boundKeyDown: any;
    private focusTimeout: any;

    constructor(props: any) {
        super(props);
        this.boundKeyDown = this.keyDown.bind(this);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.boundKeyDown, false);
    }

    componentDidMount() {
        document.addEventListener('keydown', this.boundKeyDown, false);
        this.props.socialMediaAssetService
            .search(SAMSearchFonts)
            .then(this.handleSamFonts)
            .catch(error => {
                console.error('asset load error', error);
            });
    }

    @computed get current() {
        const { editorStateService, widget } = this.props;
        // forces a watch on these
        const watch = [widget.options.styleMap, widget.options.contentRichText];

        const editorState = editorStateService.getEditorState(widget);
        let current = {
            fontSize: undefined,
            lineHeight: undefined,
            color: undefined,
            fontFamily: undefined,
            fontStyle: undefined,
            fontWeight: undefined,
            letterSpacing: undefined,
        };

        if (editorState) {
            current = {
                fontSize: editorStateService.getStyle(widget, editorState, 'fontSize') || 20,
                lineHeight: editorStateService.getStyle(widget, editorState, 'lineHeight') || 26,
                color: editorStateService.getStyle(widget, editorState, 'color'),
                fontFamily: editorStateService.getStyle(widget, editorState, 'fontFamily'),
                fontWeight: editorStateService.getStyle(widget, editorState, 'fontWeight'),
                fontStyle: editorStateService.getStyle(widget, editorState, 'fontStyle'),
                letterSpacing:
                    editorStateService.getStyle(widget, editorState, 'letterSpacing') || 25,
            };
        }
        return current;
    }

    render() {
        const { mergedTheme, widget } = this.props;

        const activeFontFamily = this.current.fontFamily
            ? {
                  family: this.current.fontFamily,
                  style: this.current.fontStyle,
                  weight: this.current.fontWeight,
              }
            : undefined;
        const mergedFont = mergedTheme && mergedTheme.typography && mergedTheme.typography.primary;
        const mergedColor =
            (mergedTheme && mergedTheme.typography && mergedTheme.typography.color) || '#fff';
        const usingInheritedFont =
            mergedFont &&
            mergedFont.family !== this.current.fontFamily &&
            mergedFont.style !== this.current.fontStyle &&
            mergedFont.weight !== this.current.fontWeight;

        return (
            <>
                {this.assets && (
                    <TypographyFontControl
                        key='activeFontFamily'
                        label='Font Family'
                        createFontFaces={false}
                        enableDeselect={!usingInheritedFont}
                        activeFont={activeFontFamily || mergedFont}
                        builtinFonts={fontFamilyOptions}
                        samFonts={this.assets}
                        onSamFontSelected={this.samFontSelected}
                        onBuiltinFontSelected={this.builtinFontSelected}
                    />
                )}
                <LabeledSlider
                    key='widget.options.currentFontSize'
                    label='Font Size'
                    showValue
                    min={0}
                    max={100}
                    value={parseInt(this.current.fontSize, 10)}
                    onChange={this.setFontSize}
                />
                <LabeledSlider
                    key='widget.options.currentLineHeight'
                    label='Line Height'
                    showValue
                    min={0}
                    max={100}
                    value={parseInt(this.current.lineHeight, 10)}
                    onChange={this.setLineHeight}
                />
                <LabeledSlider
                    key='widget.options.currentLetterSpacing'
                    label='Letter Spacing'
                    showValue
                    min={0}
                    max={100}
                    value={parseInt(this.current.letterSpacing, 10)}
                    onChange={this.setLetterSpacing}
                />

                <div className='flex between mt-1 mb-1' key='customRichTextIcons'>
                    <Icon value='text-align-left' onClick={this.setAlignment.bind(this, 'left')} />
                    <Icon
                        value='text-align-center'
                        onClick={this.setAlignment.bind(this, 'center')}
                    />
                    <Icon
                        value='text-align-right'
                        onClick={this.setAlignment.bind(this, 'right')}
                    />

                    <Icon
                        value='format-ordered-list'
                        onClick={this.setBlockType.bind(this, 'ordered-list-item')}
                    />
                    <Icon
                        value='format-unordered-list'
                        onClick={this.setBlockType.bind(this, 'unordered-list-item')}
                    />

                    <Icon
                        value='text-style-bold'
                        onClick={this.setInlineStyle.bind(this, 'BOLD')}
                    />
                    <Icon
                        value='text-style-italic'
                        onClick={this.setInlineStyle.bind(this, 'ITALIC')}
                    />
                    <Icon
                        value='text-style-quote'
                        onClick={this.setBlockType.bind(this, 'blockquote')}
                    />
                </div>

                <Flexbox gap='m'>
                    <LabelGroup label={i18n.t('Text')} key='label widget.options.fontColor'>
                        <ActionColorSwatchGroup
                            key='widget.options.fontColor'
                            value={null}
                            option={null}
                            color={this.current.color || mergedColor}
                            onChange={this.setColor}
                            secondaryIcon={
                                this.current.color && this.current.color !== mergedColor
                                    ? 'icon-undo'
                                    : undefined
                            }
                            onClear={() => this.setColor(mergedColor)}
                        />
                    </LabelGroup>
                    <LabelGroup label={i18n.t('Bullets')} key='label widget.options.bulletColor'>
                        <ActionColorSwatchGroup
                            key='widget.options.bulletColor'
                            value={'bulletColor'}
                            option={widget.options}
                            secondaryIcon={
                                (widget.options as CustomContentRichTextOptionsImpl).bulletColor
                            }
                        />
                    </LabelGroup>
                </Flexbox>
            </>
        );
    }

    @action
    private handleSamFonts = (results: SocialMediaAsset[]) => {
        this.assets = results
            .filter(asset => !!asset.digitalAsset.font)
            .sort((a, b) => {
                // paranoia
                const keyA = a.sortKey ? a.sortKey : a.name ? a.name : '';
                const keyB = b.sortKey ? b.sortKey : b.name ? b.name : '';

                if (keyA < keyB) {
                    return -1;
                }
                if (keyA > keyB) {
                    return 1;
                }
                return 0;
            });
    };

    @action
    private setAlignment(value) {
        this.props.widget.options.contentTextAlign = value;
    }

    private setBlockType(blockType) {
        this.props.editorStateService.changeBlockType(this.props.widget, blockType);
    }

    @action
    private samFontSelected = (font: WidgetFont) => {
        const { widget, editorStateService } = this.props;
        const editorState = editorStateService.getEditorState(widget);
        const prefix = 'CUSTOM_FONT_FAMILY_';
        const { family, weight, style } = font;

        editorStateService.setFontFamily(widget, editorState, font);
        this.removeUnusedStyleMap(prefix, widget.options.styleMap);

        const key = `${prefix}${family}`;
        widget.options.styleMap[key] = {
            fontFamily: `"${family}"`,
            fontStyle: style,
            fontWeight: weight,
            url: font.url,
        };

        if (!widget.options.styleMap[key]) {
            extendObservable(widget.options.styleMap, {
                [key]: {
                    fontFamily: `"${family}"`,
                    fontStyle: style,
                    fontWeight: weight,
                    url: font.url,
                },
            });
        } else {
            widget.options.styleMap[key].fontFamily = `"${family}"`;
            widget.options.styleMap[key].fontStyle = style;
            widget.options.styleMap[key].fontWeight = weight;
            widget.options.styleMap[key].url = font.url;
        }
    };

    @action
    private builtinFontSelected = (font: WidgetFont) => {
        const { widget, editorStateService } = this.props;
        const editorState = editorStateService.getEditorState(widget);
        const prefix = 'CUSTOM_FONT_FAMILY_';
        const { family, weight, style } = font;

        editorStateService.setFontFamily(widget, editorState, font);
        this.removeUnusedStyleMap(prefix, widget.options.styleMap);

        const key = `${prefix}${family}`;

        if (!widget.options.styleMap[key]) {
            extendObservable(widget.options.styleMap, {
                [key]: {
                    fontFamily: family,
                    fontStyle: style,
                    fontWeight: weight,
                },
            });
        } else {
            widget.options.styleMap[key].fontFamily = family;
            widget.options.styleMap[key].fontStyle = style;
            widget.options.styleMap[key].fontWeight = weight;
        }
    };

    @action
    private setColor = value => {
        const { widget, editorStateService } = this.props;
        const prefix = 'CUSTOM_COLOR_';

        const editorState = editorStateService.getEditorState(widget);

        if (!value) {
            editorStateService.clearColor(widget, editorState, '');
            return;
        }

        const fontColor = this.getColorValue(value);
        if (fontColor) {
            editorStateService.setStyle(widget, editorState, fontColor, 'color');
            this.removeUnusedStyleMap(prefix, widget.options.styleMap);

            const key = `${prefix}${fontColor}`;
            widget.options.styleMap[key] = {
                color: fontColor,
            };

            if (
                widget.options.contentRichText.blocks &&
                widget.options.contentRichText.blocks.length === 1 &&
                widget.options.contentRichText.blocks[0].inlineStyleRanges.length === 0
            ) {
                widget.options.contentRichText.blocks[0].inlineStyleRanges.push({
                    offset: 0,
                    length: 1,
                    style: `${prefix}${fontColor}` as any,
                });
            }
        }
    };

    private getColorValue(value) {
        let result = value;
        if (value.rgb) {
            const rgba = [];
            for (const prop in value.rgb) {
                if (value.rgb.hasOwnProperty(prop)) {
                    rgba.push(value.rgb[prop]);
                }
            }
            result = Color(rgba).string();
        } else if (value.hex) {
            result = value.hex;
        }
        return result;
    }

    @action
    private removeUnusedStyleMap(prefix: string, styleMap) {
        const editorStateStyleMap = this.props.editorStateService.getEditorStateStyleMap();
        for (const prop in styleMap) {
            if (styleMap.hasOwnProperty(prop)) {
                const matchKey = editorStateStyleMap.indexOf(prop) !== -1;
                const matchPrefix = prop.indexOf(prefix) !== -1;
                if (!matchKey && matchPrefix) {
                    delete styleMap[prop];
                }
            }
        }
    }

    @action
    private setFontSize = event => {
        const { widget, editorStateService } = this.props;
        const prefix = 'CUSTOM_FONT_SIZE_';

        editorStateService.setStyle(
            widget,
            editorStateService.getEditorState(widget),
            event.target.value,
            'fontSize'
        );
        this.removeUnusedStyleMap(prefix, widget.options.styleMap);

        const key = `${prefix}${event.target.value}`;
        this.props.widget.options.styleMap[key] = {
            fontSize: `${event.target.value * 0.1}em`,
        };
        this.setEditorFocus();
    };

    private calculateLineHeight(sliderValue, max) {
        const lineHeightVal = max * (sliderValue / 100);
        return lineHeightVal;
    }

    @action
    private setLineHeight = event => {
        const lineHeight = this.calculateLineHeight(event.target.value, 5);
        const { widget, editorStateService } = this.props;
        const prefix = 'CUSTOM_LINE_HEIGHT_';

        editorStateService.setStyle(
            widget,
            editorStateService.getEditorState(widget),
            event.target.value,
            'lineHeight'
        );
        this.removeUnusedStyleMap(prefix, widget.options.styleMap);

        const key = `${prefix}${event.target.value}`;
        this.props.widget.options.styleMap[key] = {
            lineHeight: `${lineHeight}`,
        };
        this.setEditorFocus();
    };

    @action
    private setLetterSpacing = event => {
        const { widget, editorStateService } = this.props;

        const sliderMax = 100;
        const sliderMedian = sliderMax / 4;
        const letterSpacingMax = 4;
        const sliderPercent = (event.target.value - sliderMedian) / sliderMedian;
        const letterSpacingValue = sliderPercent * letterSpacingMax;

        const prefix = 'CUSTOM_LETTER_SPACING_';

        editorStateService.setStyle(
            widget,
            editorStateService.getEditorState(widget),
            event.target.value,
            'letterSpacing'
        );
        this.removeUnusedStyleMap(prefix, widget.options.styleMap);

        const key = `${prefix}${event.target.value}`;
        this.props.widget.options.styleMap[key] = {
            letterSpacing: `${letterSpacingValue * 0.1}em`,
        };
        this.setEditorFocus();
    };

    @action
    private setInlineStyle(inlineStyle: InlineStyles) {
        if (this.props.widget.options.contentRichText) {
            this.props.editorStateService.setEditorInlineStyle(this.props.widget, inlineStyle);
        }
    }

    @action
    private setEditorFocus = debounce(() => {
        const isInput =
            document.activeElement.attributes &&
            document.activeElement.attributes.length &&
            document.activeElement.attributes.getNamedItem('data-id') &&
            document.activeElement.attributes.getNamedItem('data-id').value === 'input-suffix';

        if (!isInput) {
            this.props.editorStateService.editorStore.node &&
                this.props.editorStateService.editorStore.node.focus();
        }
    }, 25);

    @action
    private keyDown(event: any) {
        this.props.editorStateService.currentKeyCode = event.keyCode;
    }
}

export default inject('editorStateService', 'socialMediaAssetService')(observer(SupportsRichText));
