import Axios, { AxiosInstance, CancelTokenSource } from 'axios';
import { toJS } from 'mobx';
import { AnalyticsEngine, AnalyticsProjection } from '@sprinklr/stories/analytics/AnalyticsRequest';
import { Post } from '@sprinklr/stories/post/Post';
import { ChannelType, PostsRequest } from '@sprinklr/stories/post/PostsRequest';
import { WidgetDimensionKey } from 'utils/Widget/WidgetDimensionKey';
import { GraphQLService } from 'services/GraphQLService/GraphQLService';
import SprinklrAPIService, {
    PromiseWithCancel,
} from 'services/SprinklrAPIService/SprinklrAPIService';
import InstagramImageService from './InstagramImageService';
import { IntuitionService } from 'services/IntuitionService/IntuitionService';
import MetaInfoService from 'services/MetaInfoService/MetaInfoService';
import { PostsProvider, PostsProviderDefaults } from './providers/PostsProvider';
import PostsProviderCampaign from './providers/PostsProviderCampaign';
import PostsProviderMonitoringColumn from './providers/PostsProviderMonitoringColumn';
import PostsProviderReporting from './providers/PostsProviderReporting';
import PostsProviderSmartTrends from './providers/PostsProviderSmartTrends';
import PostsResolve from './PostsResolve';
import PostsTransform from './PostsTransform';
import AnalyticsService from '../AnalyticsService/AnalyticsService';

export interface MonitoringDashboard {
    id: string;
    name: string;
    columnOrder: string[];
    columns: MonitoringColumn[];
}

export interface MonitoringColumn {
    id: string;
    dashboardId: string;
    name: string;
    description: string;
    ownerUserId?: number;
    channel: ChannelType;
    type: string;
    sourceType: string;
}

const engagementProjectionsToUnifiedNames = {
    POST_LIKE_COUNT: { common: 'numLikes' },
    POST_SHARE_COUNT: { common: 'numShares', twitter: 'numRetweets' }, // thanks twitter
    POST_COMMENT_COUNT: { common: 'numComments' },
    TOTAL_ENGAGEMENT: { common: 'totalEngagement' },
};

export default class PostsService {
    constructor(
        private readonly sprinklrAPIService: SprinklrAPIService,
        private readonly graphQLService: GraphQLService,
        private readonly axios: AxiosInstance,
        private readonly intuitionService: IntuitionService,
        private readonly instagramImageService: InstagramImageService,
        private readonly metaInfoService: MetaInfoService,
        private readonly analyticsService: AnalyticsService
    ) {
        // Methods used to resolve meta-info based on data within posts
        this.resolve = new PostsResolve(
            sprinklrAPIService,
            intuitionService,
            instagramImageService
        );
    }

    resolve: PostsResolve;
    static S3_URL = 's3.amazonaws.com';

    static engines: PostsProviderDefaults = {
        // PAID: {
        //     defaultReport: null,
        //     postKey: 'STATUS_ID'
        //     defaultSortKey: 'totalEngagement'
        // },
        UNIFIED_ANALYTICS_REPORTING_ENGINE: {
            defaultReport: null,
            defaultSortKey: null,
            postKey: 'POST_ID',
        },
        LISTENING: {
            defaultReport: 'SPRINKSIGHTS',
            defaultSortKey: 'REACH_COUNT',
            postKey: 'ES_MESSAGE_ID',
        },
        INBOUND_MESSAGE: {
            defaultReport: 'INBOUND_MESSAGE',
            defaultSortKey: 'MESSAGE_COUNT',
            postKey: 'UNIFIED_MESSAGE_ID',
        },
        PLATFORM: {
            defaultReport: 'POST_INSIGHTS',
            defaultSortKey: 'TOTAL_ENGAGEMENT',
            postKey: 'POST_ID',
        },
        BENCHMARKING: {
            defaultReport: 'POST_STATS_LIFETIME',
            defaultSortKey: 'TOTAL_ENGAGEMENT',
            additionalProjections: [
                { measurementName: 'POST_LIKE_COUNT', aggregateFunction: 'SUM' },
                { measurementName: 'POST_SHARE_COUNT', aggregateFunction: 'SUM' },
                { measurementName: 'POST_COMMENT_COUNT', aggregateFunction: 'SUM' },
            ],
            postKey: 'SIGNAL_ID',
        },
        PAID: {
            defaultReport: 'DAILY_AD_STAT',
            defaultSortKey: null,
            postKey: 'STATUS_ID',
        },
        CAMPAIGN: {
            defaultReport: 'CAMPAIGN',
            defaultSortKey: 'SCHEDULE_DATE',
            postKey: 'ES_MESSAGE_ID',
        },
        RDB_FIREHOSE: {
            defaultReport: 'RDB_FIREHOSE',
            defaultSortKey: 'SN_CREATED_TIME',
            postKey: 'ES_MESSAGE_ID',
        },
        // This engine also supports STORY_CREATED. That specified with "postDimension"
        // override on the PostRequest
        STORY_MESSAGE: {
            defaultReport: 'STORY_MESSAGE',
            defaultSortKey: 'REACH',
            postKey: 'ES_MESSAGE_ID',
        },
        TRENDING_EVENT: {
            defaultReport: 'TRENDING_EVENT',
            defaultSortKey: null,
            postKey: "TREND_ID",
        },
    };

    getPosts(request: PostsRequest, cancelSource?: CancelTokenSource): PromiseWithCancel<Post[]> {
        if (!cancelSource) {
            cancelSource = Axios.CancelToken.source();
        }

        if (request.reportingEngine != "TRENDING_EVENT" &&
            request.removeSensitivePosts &&
            !toJS(request.filters).filter(filter => filter.dimensionName === 'POSSIBLY_SENSITIVE')
                .length
        ) {
            request.filters.push({
                dimensionName: 'POSSIBLY_SENSITIVE',
                filterType: 'IN',
                values: ['false'],
            });
        }

        const resolveFilters = Promise.all(
            request?.filters?.map(filter => {
                const allValues = filter.allValues || filter.details?.allValues;
                delete filter.allValues;

                if (filter.filterType === 'RANK') {
                    const requestCopy = JSON.parse(JSON.stringify(request));

                    const sortProjections = requestCopy.sorts
                        .filter(s => !requestCopy.projections.some(p => p.heading === s.heading))
                        .map(s => {
                            return {
                                heading: s.heading,
                                measurementName: s.heading,
                                aggregateFunction: 'SUM',
                            } as AnalyticsProjection;
                        });

                    requestCopy.projections.push(...sortProjections);

                    return this.analyticsService.rankedFilter(filter, requestCopy, cancelSource);
                } else {
                    return allValues
                        ? this.metaInfoService
                              .getValueLabels(
                                  request.reportingEngine as AnalyticsEngine,
                                  request.report,
                                  WidgetDimensionKey.create(filter)
                              )
                              .then(data => (filter.values = data.values?.map(label => label.id)))
                        : new Promise<void>(resolve => resolve());
                }
            }) || [new Promise<void>(resolve => resolve())]
        );

        // TODO: think this could benefit from some streamlining - specifically parallelize the async IO stuff?
        const promise = resolveFilters
            .then(() => this.request(request, cancelSource))
            .then(async (posts: Post[]) => {
                posts = PostsTransform.transform(posts, request.projections, request.filters);

                if (request.imagesEnabled === false) {
                    posts = this.removeImages(posts);
                }
                if (request.videosEnabled === false) {
                    posts = this.removeVideos(posts);
                }

                if (!request.includeQuoteTweets) {
                    posts = this.removeQuoteTweets(posts);
                }

                const usePlaceholder =
                    request.useImagePlaceholder || request.reportingEngine === 'PAID';

                return this.resolve
                    .fixExpiredInstagram(posts)
                    .then(PostsTransform.addThumbsToImages)
                    .then(async resolvedPosts => {
                        return await this.resolve.filterBrokenImages(resolvedPosts, usePlaceholder);
                    })
                    .then(async resolvedPosts => {
                        if (usePlaceholder) {
                            return await this.resolve.fixBrokenVideoPosters(resolvedPosts);
                        } else {
                            return resolvedPosts;
                        }
                    })
                    .then(async resolvedPosts => {
                        if (request.includeComments) {
                            resolvedPosts = await this.resolve.comments(
                                resolvedPosts,
                                request.includeComments
                            );
                        }

                        if (
                            request.groupBys
                                ?.map(groupBy => groupBy.heading)
                                .includes('Media_Type') &&
                            resolvedPosts?.length &&
                            resolvedPosts[0].extraPostData?.length > 0
                        ) {
                            const dimension = request.groupBys.filter(groupBy => {
                                if (groupBy.heading === 'Media_Type') {
                                    return groupBy;
                                }
                            });

                            resolvedPosts = await this.getMediaType(
                                resolvedPosts,
                                (dimension[0] as any).dimensionName,
                                request.report,
                                request.reportingEngine
                            );
                        }
                        if (request.includeAlertPosts) {
                            resolvedPosts = await this.resolve.alertPosts(resolvedPosts);
                        }
                        if (request.showInReplyToPost) {
                            resolvedPosts = await this.resolve.inReplyToPosts(resolvedPosts);
                        }
                        if (request.includeFaceDetection) {
                            resolvedPosts = await this.resolve.detectFaces(resolvedPosts);
                        }
                        return resolvedPosts;
                    });
            })
            .catch(thrown => {
                return Promise.reject(thrown);
            });

        (promise as any).cancel = (message?: string) => {
            cancelSource.cancel(message);
        };

        return promise;
    }

    // Used by the embed
    getPostsForRequests(
        requests: PostsRequest[],
        cancelSource?: CancelTokenSource
    ): Promise<Post[]> {
        const promises = requests.map(request => {
            return this.getPosts(request, cancelSource);
        });

        return Promise.all(promises).then((postResults: Post[][]) => {
            if (requests.length > 1) {
                return PostsService.combineLists(postResults, requests[1].mergeSources === 'date');
            } else {
                return postResults[0];
            }
        });
    }

    removeImages = (posts: Post[]): Post[] => {
        return posts.filter(post => !post.images || post.images.length === 0);
    };

    removeVideos = (posts: Post[]): Post[] => {
        return posts.filter(post => !post.videos || post.videos.length === 0);
    };

    removeQuoteTweets = (posts: Post[]): Post[] => {
        return posts.filter(post => !post.quotedMessage);
    };

    getMediaType = (postResults, dimensionName, report, engine) => {
        const dimension = new WidgetDimensionKey(dimensionName, undefined);
        return this.metaInfoService.getValueLabels(engine, report, dimension).then(data => {
            postResults.map(result => {
                result.extraPostData.map(data => {
                    if (data.heading === 'Media_Type') {
                        data.values?.map(result => {
                            if (data.value === result.id) {
                                data.value = result.name;
                            }
                        });
                    }
                });
            });
            return postResults;
        });
    };

    private request(
        request: PostsRequest,
        cancelSource?: CancelTokenSource
    ): PromiseWithCancel<any[]> {
        let provider: PostsProvider = null;
        let promise = null;

        switch (request.source) {
            case 'LISTENING_COLUMN':
                provider = new PostsProviderMonitoringColumn(this.sprinklrAPIService, this.axios);
                break;

            case 'REPORTING':
                if (request.reportingEngine === "TRENDING_EVENT") {
                    provider = new PostsProviderSmartTrends(this.sprinklrAPIService);
                } else {
                    provider = new PostsProviderReporting(this.sprinklrAPIService);
                }
                break;

            case 'CAMPAIGN':
                provider = new PostsProviderCampaign(this.sprinklrAPIService);
                break;

            default:
                promise = new Promise((resolve, reject) => {
                    reject('request.source: "' + request.source + '" is not supported.');
                });
        }

        if (provider) {
            promise = provider.request(request, cancelSource);
        }

        promise = promise.catch(thrown => {
            // if (Axios.isCancel(thrown)) {
            //     console.log('Cancelled!');
            //     console.log(thrown);
            // }
            return Promise.reject(thrown);
        });

        return promise;
    }

    static combineLists<T extends { snCreatedTime?: number }>(
        postResults: T[][],
        sortByDate: boolean
    ): T[] {
        let posts: T[] = [];

        postResults.forEach(result => {
            posts = posts.concat(result);
        });

        posts = posts.filter(p => {
            return !!p;
        });

        if (sortByDate) {
            posts.sort(PostsService.sortByDateDesc);
        }

        return posts;
    }

    private static sortByDateDesc<T extends { snCreatedTime?: number }>(p1: T, p2: T) {
        const no1 = p1.snCreatedTime === undefined || p1.snCreatedTime === null;
        const no2 = p2.snCreatedTime === undefined || p2.snCreatedTime === null;
        if (no1 && no2) {
            return 0;
        }
        if (no2) {
            return -1;
        }
        if (no1) {
            return 1;
        }
        return p2.snCreatedTime - p1.snCreatedTime;
    }
}
