import React from 'react';
import { observer } from 'mobx-react';
import { GlyphDot } from '@vx/glyph';
import { GenerateSafeString } from 'utils/StringUtils/StringUtils';
import BulkItem from '@sprinklr/stories/analytics/BulkItem';
import Icon from 'components/Icon/Icon';
import classNames from 'classnames';

// Legend

export type LegendAlignment = 'left' | 'center' | 'middle';

export interface LegendOptions {
    enabled: boolean;
    size: number;
    alignment: LegendAlignment;
    points: {
        enabled: boolean;
        size: number;
        stroke: {
            enabled: boolean;
            size: number;
        };
    };
}

type Item = {
    name: string | BulkItem;
    color?: string;
    snType?: string;
};

type LegendProps = {
    items: Item[];
    options: LegendOptions;
    innerRef?: any;
    onLabelClick?: (index: number) => void;
    showAccountIcon?: boolean;
};

const Legend: React.FC<LegendProps> = ({
    items = [],
    options,
    innerRef,
    onLabelClick,
    showAccountIcon,
}: LegendProps) => {
    const { alignment = 'left', points, enabled } = options;

    if (!enabled) {
        return;
    }

    return (
        <div className={`legend flex ${alignment} wrap mb-1 mr-1`} ref={innerRef}>
            {items.length > 0 &&
                items.map((legendItem, index) => {
                    const handleClick = () => {
                        if (onLabelClick) {
                            onLabelClick(index);
                        }
                    };
                    return (
                        <div
                            key={legendItem.name + legendItem.color}
                            className={`legend_item legend_item_index_${index +
                                1} middle legend_item_label_${GenerateSafeString(
                                legendItem.name + ''
                            )} flex no-grow mr-1`}
                            onClick={handleClick}>
                            {points && points.enabled && (
                                <svg width={points.size} height={points.size}>
                                    <GlyphDot
                                        cx={points.size * 0.5}
                                        cy={points.size * 0.5}
                                        r={points.size * 0.3}
                                        fill={legendItem.color}
                                        stroke={legendItem.color}
                                        strokeWidth={points.stroke.enabled ? points.stroke.size : 0}
                                    />
                                </svg>
                            )}
                            <div className='legend_item_label primary_font_family'>
                                {legendItem.name}
                                {legendItem.snType && showAccountIcon && (
                                    <Icon
                                        padding={
                                            options.size > 24
                                                ? options.size * 0.005
                                                : options.size * 0.0075
                                        }
                                        classes={classNames('ml-025')}
                                        reset
                                        value={legendItem.snType.toLowerCase()}
                                        wrap
                                    />
                                )}
                            </div>
                        </div>
                    );
                })}
        </div>
    );
};

Legend.displayName = 'Legend';
export default observer(Legend);
