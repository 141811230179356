import * as React from 'react';
import i18n from 'i18next';
import {
    headerTunables,
    metricValueTunables,
    opacityTunables,
    percentChangeTunables,
    smartSizeTunables,
    themeTunables,
} from 'components/Sidebar/RenderTunables/SharedTunables';
import { CollapseProps, TunablesCollapse } from 'components/Sidebar/RenderTunables/RenderTunables';
import { Widget } from '@sprinklr/stories/widget/Widget';
import {
    horizontalAlignOptions,
    xAxisOptions,
} from 'components/Panel/PanelEditorSidebar/PanelDesign/PanelDesignOptions';
import ActionCheckboxSliderGroup from 'components/_UI/Forms/Actions/ActionCheckboxSliderGroup';
import ActionCheckboxGroup from 'components/_UI/Forms/Actions/ActionCheckboxGroup';
import ActionSliderGroup from 'components/_UI/Forms/Actions/ActionSliderGroup';
import ActionSelectGroup from 'components/_UI/Forms/Actions/ActionSelectGroup';
import { RankedTableWidgetOptionsImpl } from './options';
import ActionSegmentedGroup from 'components/_UI/Forms/Actions/ActionSegmentedGroup';
import { action } from 'mobx';
import ActionCheckboxSliderColorSwatchGroup from 'components/_UI/Forms/Actions/ActionCheckboxSliderColorSwatchGroup';
import { Theme } from 'models/Theme/Theme';

const getFallbackColor = (theme: Theme, mergedTheme: Theme): string => {
    return theme?.typography?.color || mergedTheme?.typography?.color || '';
};
const RankedTableTunables = (widget: Widget, mergedTheme: Theme): TunablesCollapse[] => [
    {
        props: {
            label: i18n.t('Table'),
            startOpen: true,
        } as CollapseProps,
        children: [...headerTunables(widget), opacityTunables(widget), smartSizeTunables(widget)],
    },
    {
        props: {
            label: i18n.t('Labels'),
            startOpen: true,
        } as CollapseProps,
        children: [
            widget.options.invertDataSet && (
                <ActionSliderGroup
                    key='widget.options.labelSize'
                    label={i18n.t('Row Name')}
                    option={widget.options}
                    value='labelSize'
                    showValue
                    min={0}
                    max={100}
                />
            ),
            <ActionCheckboxGroup
                key='widget.options.autoRowHeight'
                label={i18n.t('Auto Distribute Row Height')}
                option={widget.options}
                value='autoRowHeight'
            />,
            !(widget.options as RankedTableWidgetOptionsImpl).autoRowHeight && (
                <ActionCheckboxSliderGroup
                    key='widget.options.rowSize'
                    label={i18n.t('Row Size')}
                    showValue
                    checkbox={{
                        option: widget.options,
                        value: 'showRowSize',
                    }}
                    slider={{
                        option: widget.options,
                        value: 'rowSize',
                        min: 0,
                        max: 100,
                    }}
                />
            ),
            <ActionCheckboxGroup
                key='widget.options.rowNumbers'
                label={i18n.t('Show Row Numbers')}
                option={widget.options}
                value='rowNumbers'
            />,
            <ActionCheckboxSliderGroup
                key='widget.options.showColumnName'
                label={i18n.t('Column Name')}
                showValue
                checkbox={{
                    option: widget.options,
                    value: 'showColumnName',
                }}
                slider={{
                    option: widget.options,
                    value: 'columnNameSize',
                    min: 0,
                    max: 50,
                }}
            />,
            <ActionSegmentedGroup
                key='widget.options.alignment.columnName'
                label={i18n.t('Column Name Align')}
                option={(widget.options as any).alignment}
                value={'columnName'}
                options={horizontalAlignOptions}
            />,
            <ActionSliderGroup
                key='widget.options.dimensionSize'
                label={i18n.t('Dimension Size')}
                option={widget.options}
                value='dimensionSize'
                showValue
                min={0}
                max={100}
            />,

            <ActionSegmentedGroup
                key='widget.options.alignment.rowName'
                label={i18n.t('Dimension Align')}
                option={(widget.options as any).alignment}
                value={'rowName'}
                options={horizontalAlignOptions}
            />,
            <ActionSelectGroup
                key='widget.options.columnNameFormat'
                label={i18n.t('Date Format')}
                option={widget.options}
                value='columnNameFormat'
                options={xAxisOptions}
            />,
            ...metricValueTunables(widget, {
                value: { enable: true, size: true },
                format: true,
                name: true,
                change: true,
            }),
            <ActionSegmentedGroup
                key='widget.options.alignment.metric'
                label={i18n.t('Metric Align')}
                option={(widget.options as any).alignment}
                value={'metric'}
                options={horizontalAlignOptions}
            />,
            ...percentChangeTunables(widget),
            <ActionCheckboxSliderColorSwatchGroup
                key='widget.border.top'
                label='Border Top'
                showValue
                checkbox={{
                    option: (widget.options as RankedTableWidgetOptionsImpl)?.borders?.top,
                    value: 'enabled',
                }}
                slider={{
                    option: (widget.options as RankedTableWidgetOptionsImpl)?.borders?.top,
                    value: 'size',
                    min: 1,
                    max: 100,
                }}
                colorSwatch={{
                    option: null,
                    value: null,
                    onClear: action(
                        () =>
                            ((widget.options as RankedTableWidgetOptionsImpl).borders.top.color =
                                '')
                    ),
                    onChange: action(
                        ({ rgb: { r, g, b, a } }: any) =>
                            ((widget.options as RankedTableWidgetOptionsImpl).borders.top.color = `rgba(${r}, ${g}, ${b}, ${a})`)
                    ),
                    color: !!(widget.options as RankedTableWidgetOptionsImpl)?.borders?.top.color
                        ? (widget.options as RankedTableWidgetOptionsImpl)?.borders?.top.color
                        : getFallbackColor(widget.theme, mergedTheme),
                    secondaryIcon: !!(widget.options as RankedTableWidgetOptionsImpl)?.borders?.top
                        .color
                        ? 'icon-undo'
                        : undefined,
                }}
            />,
            <ActionCheckboxSliderColorSwatchGroup
                key='widget.points.value'
                label='Border Bottom'
                showValue
                checkbox={{
                    option: (widget.options as RankedTableWidgetOptionsImpl)?.borders?.bottom,
                    value: 'enabled',
                }}
                slider={{
                    option: (widget.options as RankedTableWidgetOptionsImpl)?.borders?.bottom,
                    value: 'size',
                    min: 1,
                    max: 100,
                }}
                colorSwatch={{
                    option: null,
                    value: null,
                    onClear: action(
                        () =>
                            ((widget.options as RankedTableWidgetOptionsImpl).borders.bottom.color =
                                '')
                    ),
                    onChange: action(
                        ({ rgb: { r, g, b, a } }: any) =>
                            ((widget.options as RankedTableWidgetOptionsImpl).borders.bottom.color = `rgba(${r}, ${g}, ${b}, ${a})`)
                    ),
                    color: !!(widget.options as RankedTableWidgetOptionsImpl)?.borders?.bottom.color
                        ? (widget.options as RankedTableWidgetOptionsImpl)?.borders?.bottom.color
                        : getFallbackColor(widget.theme, mergedTheme),
                    secondaryIcon: !!(widget.options as RankedTableWidgetOptionsImpl)?.borders
                        ?.bottom.color
                        ? 'icon-undo'
                        : undefined,
                }}
            />,
        ],
    },
    themeTunables(widget, { color: true, image: true, email: true, imageSize: true }),
    {
        props: {
            label: i18n.t('Tunables'),
            startOpen: true,
        } as CollapseProps,
        children: [
            <ActionCheckboxGroup
                key='widget.options.invertDataSet'
                label={i18n.t('Flip Rows/Columns')}
                option={widget.options}
                value='invertDataSet'
            />,
        ],
    },
];

export default RankedTableTunables;
