import { observable } from 'mobx';

export class Intuition {
    @observable images?: IntuitionImage[];
    @observable image?: IntuitionImage;
}

type StringKeyedMap = { [key: string]: any };

export class IntuitionImage {
    @observable payloadId?: string;
    @observable imageUrl?: string;
    @observable payloadTags?: StringKeyedMap;
    @observable results?: ImageResult[];
}

export class ImageResult {
    @observable engineId?: string;
    @observable engineType?: string;
    @observable success?: boolean;
    @observable height?: number;
    @observable width?: number;
    @observable boundingBox?: BoundingBox;
    @observable classifications?: Classification[];
}

export class BoundingBox {
    @observable x0: number;
    @observable y0: number;
    @observable x1: number;
    @observable y1: number;
}

export class Classification {
    @observable boundingBox?: BoundingBox;
    @observable boundingBoxRaw?: string;
    @observable name?: string;
    @observable confidence?: number;
    @observable height?: number;
    @observable width?: number;
    @observable additional?: StringKeyedMap;
}

export const IntuitionImageFields = `
  imageUrl
  results {
    engineId
    engineType
    success
    classifications {
      name
      boundingBox {
        x0 y0 x1 y1
      }
    }
    height
    width
    boundingBox {
      x0 y0 x1 y1
    }
  }
`;

export const IntuitionImageFieldsComplete = `
  payloadId
  payloadTags
  imageUrl
  results {
    engineId
    engineType
    success
    classifications {
      boundingBox {
        x0 y0 x1 y1
      }
      boundingBoxRaw
      name
      confidence
      additional
      height
      width
    }
    height
    width
    boundingBox {
      x0 y0 x1 y1
    }
  }
`;
