import * as React from 'react';
import {
    headerTunables,
    opacityTunables,
    imagePostTunables,
    textPostTunables,
    userGroupTunables,
    engagementTunables,
    textEntityTunables,
    imageAdjustmentTunables,
    smartSizeTunables,
    nativeStylingTunables,
    extraPostDataTunables,
} from 'components/Sidebar/RenderTunables/SharedTunables';
import { CollapseProps, TunablesCollapse } from 'components/Sidebar/RenderTunables/RenderTunables';
import { Widget } from '@sprinklr/stories/widget/Widget';
import { Theme } from 'models/Theme/Theme';
import { PostCardWidgetOptions } from '../options';

const postCardCompositions = [
    { value: 'a', label: 'Default', icon: 'collage_a' },
    { value: 'b', label: 'Alternate', icon: 'collage_b' },
];

const RunOfShowTunables = (widget: Widget, mergedTheme: Theme): TunablesCollapse[] => [
    {
        props: {
            label: 'Post Card',
            startOpen: true,
        } as CollapseProps,
        children: [...headerTunables(widget), opacityTunables(widget), smartSizeTunables(widget)],
    },
    nativeStylingTunables(widget),
    extraPostDataTunables(widget, mergedTheme),
    ...getNonNativeTunables(widget, mergedTheme),
];

const getNonNativeTunables = (widget, mergedTheme) => {
    if (
        (widget.options as PostCardWidgetOptions).nativeStyling &&
        (widget.options as PostCardWidgetOptions).nativeStyling.enabled
    ) {
        return [];
    } else {
        return [
            textPostTunables(widget, mergedTheme),
            imagePostTunables(widget, mergedTheme),
            imageAdjustmentTunables(widget),
            userGroupTunables(widget),
            engagementTunables(widget),
            textEntityTunables(widget),
        ];
    }
};

export default RunOfShowTunables;
