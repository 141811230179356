export const SUFFIX_MAP = {
    1: 'st',
    2: 'nd',
    3: 'rd',
};
export const SMART_TREND_THEME = {
    light: {
        color: 'rgb(10, 10, 20)',
        backgroundColor: '#fff',
        tag: {
            color: 'rgb(10, 10, 20)',
            backgroundColor: '#eee',
        },
    },
    dark: {
        color: '#fff',
        backgroundColor: '#23232D',
        tag: {
            color: '#fff',
            backgroundColor: '#484848',
        },
    },
};
export const measurementNameMap = {
    TREND_SCORE: 'Score',
    TREND_DISTINCT_USERS_CHANGE: 'Change in Distinct Users',
    TREND_RANK: 'Rank',
    TREND_TOTAL_ENGAGEMENTS_PERCENTAGE_CHANGE: '% Change in Total Engagements',
    TREND_DISTINCT_USERS_PERCENTAGE_CHANGE: 'Change in Distinct Users',
    TREND_DISTINCT_USERS: 'Distinct Users',
    TREND_MENTIONS_COUNT_PERCENTAGE_CHANGE: '% Change in Mentions',
    TREND_TOTAL_ENGAGEMENTS_CHANGE: 'Change in Total Engagements',
    TREND_REACH_PERCENTAGE_CHANGE: '% Change in Reach',
    TREND_REACH: 'Reach',
    TREND_TOTAL_ENGAGEMENTS: 'Total Engagements',
    TREND_REACH_CHANGE: 'Change in Reach',
    TREND_MENTIONS_COUNT: 'Mentions',
    TREND_MENTIONS_COUNT_CHANGE: 'Change in Mentions',
};

export type MeasurementName = keyof typeof measurementNameMap;
