import { Mapper } from '../DataStore/DataStore';
import { GraphQLService } from '../GraphQLService/GraphQLService';
import {
    ClientMutationId,
    GraphQLQueries,
    ModelName,
    MutationDescriptor,
} from '../GraphQLQueries/GraphQLQueries';
import { BaseRecordService } from '../../utils/BaseRecordService/BaseRecordService';
import { SceneVersion, SceneVersionFields } from '../../models/Scene/SceneVersion';
import { SceneTransition, SceneTransitionSnippet } from '../../models/Scene/Scene';
import { CommonDocFields } from '../../models/CommonDoc/CommonDoc';
import { VersionFields } from '../../models/Version/Version';

const MODELNAME: ModelName = 'sceneVersion';

export class SceneVersionService extends BaseRecordService<SceneVersion> {
    private queries: GraphQLQueries;

    constructor(graphQL: GraphQLService, mapper: Mapper<SceneVersion>) {
        super(graphQL, mapper);
        this.queries = new GraphQLQueries();
    }

    rename(scene: SceneVersion, name: string): Promise<SceneVersion> {
        return this.mutate(
            this.queries.mutationDescriptor('renameSceneVersion', MODELNAME, {
                sceneId: scene.masterId,
                versionId: scene.id,
                storyboardId: scene.storyboardId,
                name: name,
            })
        );
    }

    abandonDraft(scene: SceneVersion): Promise<SceneVersion> {
        return this.mutate(
            this.queries.mutationDescriptor('abandonSceneVersion', MODELNAME, {
                sceneId: scene.masterId,
                versionId: scene.id,
            })
        );
    }

    updateSceneTransition(
        sceneVersionId: string,
        sceneTransition: SceneTransition
    ): Promise<SceneVersion> {
        const mutation: MutationDescriptor = {
            queryParams: {
                query: `
                    mutation ($input: UpdateStoryboardSceneTransitionInput!) {
                       updateStoryboardSceneTransition(input: $input) {
                            sceneVersion {
                                   ${CommonDocFields}
                                   ${VersionFields}
                                   ${SceneTransitionSnippet}
                            }
                            ${ClientMutationId}
                        }
                    }
                `,
                variables: {
                    input: {
                        sceneVersionId,
                        sceneTransition,
                    },
                },
            },
            extractor: result => result.updateStoryboardSceneTransition.sceneVersion,
        };

        return this.mutate(mutation);
    }
}
