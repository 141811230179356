import * as React from 'react';
import i18n from 'i18next';

import {
    blackListTunables,
    headerTunables,
    labelTunables,
    metricValueTunables,
    opacityTunables,
    percentChangeTunables,
    sizingTunables,
    smartSizeTunables,
    textFormatTunables,
    themeTunables,
} from 'components/Sidebar/RenderTunables/SharedTunables';
import { CollapseProps, TunablesCollapse } from 'components/Sidebar/RenderTunables/RenderTunables';
import { Widget } from '@sprinklr/stories/widget/Widget';
import LabelGroup from 'components/_UI/Forms/LabelGroup/LabelGroup';
import ActionSelectGroup from 'components/_UI/Forms/Actions/ActionSelectGroup';
import { emojiPlatformOptions } from 'components/Panel/PanelEditorSidebar/PanelDesign/PanelDesignOptions';
import { widgetDimIsEmoji } from 'utils/Widget/WidgetUtils';

const TreeMapTunables = (widget: Widget): TunablesCollapse[] => [
    {
        props: {
            label: i18n.t('Tree Map'),
            startOpen: true,
        } as CollapseProps,
        children: [...headerTunables(widget), opacityTunables(widget), smartSizeTunables(widget)],
    },
    {
        props: {
            label: i18n.t('Labels'),
            startOpen: true,
        },
        children: [
            labelTunables(widget),
            ...textFormatTunables(widget, { fit: true, clamp: true, wrap: true }),
            ...metricValueTunables(widget, {
                value: { enable: true, size: true },
                format: true,
                name: true,
                change: true,
            }),
            ...percentChangeTunables(widget),
            widgetDimIsEmoji(widget) && (
                <LabelGroup label='Emoji Set' key='widget.treemap.emoji'>
                    <ActionSelectGroup
                        key='widget.emoji.platform'
                        options={emojiPlatformOptions}
                        option={widget.options.emoji}
                        value='platform'
                    />
                </LabelGroup>
            ),
        ],
    },
    themeTunables(widget, { color: true, image: true, email: true }),
    sizingTunables(widget, { spacing: { max: 15 }, padding: { min: 5, max: 20 } }),
    {
        props: {
            label: i18n.t('Tunables'),
            startOpen: false,
        } as CollapseProps,
        children: [blackListTunables(widget)],
    },
];

export default TreeMapTunables;
