import { generateID } from './PanelTemplatesTheme/helpers';
import { WIDGET_LABEL_SIZE } from '../../components/Widget/WidgetLabel/constants';
import { Panel } from '../../models/Panel/Panel';

export var LabelSize = {
    primary: 20,
    secondary: 15,
};

export var TemplateEditorial2: Panel = {
    id: 'editorial-2',
    name: 'Title Slide',
    description: '',
    tags: ['Editorial', 'text', 'landscape', 'portrait', 'interstitial'],
    widget: {
        id: generateID(),
        position: {
            width: '1920px',
            height: '1080px',
            top: '0',
            left: '0',
        },
        classes: 'template_custom',
        label: {
            titleText: 'Panel header',
            enabled: false,
            size: WIDGET_LABEL_SIZE.primary,
        },
        children: [
            {
                type: 'customContentRichText',
                name: 'Rich Text',
                id: generateID(),
                position: {
                    left: '3.8%',
                    top: '35%',
                    width: '91%',
                    height: '20%',
                },
                label: {
                    titleText: 'Widget Header',
                    enabled: false,
                    size: 40,
                },
                options: {
                    player: {
                        playing: false,
                        duration: 15,
                    },
                    contentRichText: {
                        entityMap: {},
                        blocks: [
                            {
                                key: 'ag6qs',
                                text: 'Main Header',
                                type: 'unstyled',
                                depth: 0,
                                inlineStyleRanges: [
                                    {
                                        offset: 0,
                                        length: 11,
                                        style: 'BOLD',
                                    },
                                    {
                                        offset: 0,
                                        length: 11,
                                        style: 'CUSTOM_FONT_SIZE_40' as any,
                                    },
                                ],
                                entityRanges: [],
                                data: {},
                            },
                        ],
                    },
                },
            },
            {
                type: 'customContentRichText',
                name: 'Rich Text',
                id: generateID(),
                position: {
                    left: '5.5%',
                    top: '48%',
                    width: '91%',
                    height: '20%',
                },
                label: {
                    titleText: 'Widget Header',
                    enabled: false,
                    size: LabelSize.primary,
                },
                options: {
                    player: {
                        playing: false,
                        duration: 15,
                    },
                    contentRichText: {
                        entityMap: {},
                        blocks: [
                            {
                                key: '123',
                                text: 'Subheader',
                                type: 'unstyled',
                                depth: 0,
                                inlineStyleRanges: [
                                    {
                                        offset: 0,
                                        length: 9,
                                        style: 'CUSTOM_FONT_SIZE_24' as any,
                                    },
                                ],
                                entityRanges: [],
                                data: {},
                            },
                        ],
                    },
                    contentPadding: 5,
                    contentTextAlign: 'left',
                    verticalAlign: 'bottom',
                },
            },
        ],
    },
};
