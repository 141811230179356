import * as React from 'react';
import { Post } from '@sprinklr/stories/post/Post';
import { observer } from 'mobx-react';
import TwitterPost from './TwitterPost';
import { PlayerInterval } from 'utils/PlayerInterval/PlayerInterval';
import usePosition from '../../hooks/UsePosition';
import classNames from 'classnames';
import InstagramPost from './InstagramPost';
import FacebookPost from './FacebookPost';
import YoutubePost from './YoutubePost';
import DefaultPost from './DefaultPost';
import { scaleLinear } from '@vx/scale';
import { NativeStylingOptions } from 'src/widgets/PostsWidget/types';
import { Emoji } from '@sprinklr/stories/widget/types';
import { purifyString } from 'utils/StringUtils/StringUtils';
import { getTextEntities } from 'utils/EntityUtils/EntityUtils';

// NativePostWrapper
export interface NativePostWrapperProps {
    options: NativeStylingOptions;
    post: Post;
    playerInterval: PlayerInterval;
    postOuterClasses: string;
    postInnerClasses: string;
    postExtraPostData?: React.ReactNode;
    extraPostDataTags?: React.ReactNode;
    emoji?: Emoji;
    onPostClick?: (event: React.MouseEvent | React.TouchEvent) => void;
    showLinkPreview?: boolean;
    showQuotedTweet?: boolean;
    quotedTweetSize?: number;
}

const NativePostWrapper: React.FunctionComponent<NativePostWrapperProps> = ({
    playerInterval,
    postOuterClasses,
    postInnerClasses,
    postExtraPostData,
    post,
    options,
    emoji,
    onPostClick,
    showLinkPreview,
    showQuotedTweet,
    quotedTweetSize,
    extraPostDataTags,
}) => {
    const containerRef = React.useRef();
    const { height, width } = usePosition(containerRef);
    const isPortrait = height > width;
    const key = `${height}${width}${post.unique}`;
    const { fontScale } = options;
    const getFontScale = scaleLinear({
        domain: [0, 100],
        range: [0.25, 3],
    });

    if (postExtraPostData) {
        return (
            <div
                className={classNames(`${cleanseClassnames(postOuterClasses)} flex `, {
                    vertical: isPortrait,
                })}
                ref={containerRef}>
                <div
                    style={{
                        fontSize: `${getFontScale(fontScale)}em`,
                    }}
                    className={classNames(`native_post flex media ${postInnerClasses}`, {
                        'h-60 w-100': isPortrait,
                        'w-40 h-100': !isPortrait,
                    })}
                    onClick={onPostClick}
                    onTouchEnd={onPostClick}>
                    {getNativePostTemplate(
                        post,
                        playerInterval,
                        key,
                        options,
                        emoji,
                        showLinkPreview,
                        showQuotedTweet,
                        quotedTweetSize,
                        extraPostDataTags
                    )}
                </div>
                <div
                    className={classNames(`flex top ${postInnerClasses}`, {
                        'h-40 w-100': isPortrait,
                        'w-50 h-100': !isPortrait,
                    })}>
                    {postExtraPostData}
                </div>
            </div>
        );
    } else {
        return (
            <div
                style={{
                    fontSize: `${getFontScale(fontScale)}em`,
                }}
                onClick={onPostClick}
                onTouchEnd={onPostClick}
                className={`native_post ${cleanseClassnames(postOuterClasses)}`}>
                {getNativePostTemplate(
                    post,
                    playerInterval,
                    key,
                    options,
                    emoji,
                    showLinkPreview,
                    showQuotedTweet,
                    quotedTweetSize,
                    extraPostDataTags
                )}
            </div>
        );
    }
};

function purifyText(str: string, options: NativeStylingOptions): string {
    return purifyString(str, {
        obscureSymbol: options.profanityFilter.obscureSymbol,
        profanityList: options.profanityFilter.profanityList,
    });
}

const getNativePostTemplate = (
    post: Post,
    playerInterval: PlayerInterval,
    key: string,
    options: NativeStylingOptions,
    emoji: Emoji,
    showLinkPreview: boolean,
    showQuotedTweet: boolean,
    quotedTweetSize: number,
    extraPostDataTags?: React.ReactNode
) => {
    const textEntities = getTextEntities({
        textEntities: post.textEntities,
        urlEntities: post.urlEntities,
        showExpandedEntityUrls: options.showExpandedEntityUrls,
    });
    const mergedPost = { ...post, textEntities };
    const mergedOptions = { ...options, emoji, showLinkPreview, showQuotedTweet, quotedTweetSize };
    const resolvedPost = options.profanityFilter?.enabled
        ? {
              ...mergedPost,
              message: purifyText(mergedPost.message, options),
              title: purifyText(mergedPost?.title ?? '', options),
          }
        : post;
    switch (post.snType) {
        case 'twitter':
            return (
                <TwitterPost
                    extraPostDataTags={extraPostDataTags}
                    post={resolvedPost}
                    playerInterval={playerInterval}
                    key={key}
                    options={mergedOptions}
                />
            );
        case 'instagram':
            return (
                <InstagramPost
                    extraPostDataTags={extraPostDataTags}
                    post={resolvedPost}
                    playerInterval={playerInterval}
                    key={key}
                    options={mergedOptions}
                />
            );
        case 'facebook':
            return (
                <FacebookPost
                    extraPostDataTags={extraPostDataTags}
                    post={resolvedPost}
                    playerInterval={playerInterval}
                    key={key}
                    options={mergedOptions}
                />
            );
        case 'youtube':
            return (
                <YoutubePost
                    extraPostDataTags={extraPostDataTags}
                    post={resolvedPost}
                    playerInterval={playerInterval}
                    key={key}
                    options={mergedOptions}
                />
            );
        default:
            return (
                <DefaultPost
                    extraPostDataTags={extraPostDataTags}
                    post={resolvedPost}
                    playerInterval={playerInterval}
                    key={key}
                    options={mergedOptions}
                />
            );
    }
};

const cleanseClassnames = (classes: string): string => {
    return classes
        .replace('post_has_video', '')
        .replace('post_text_only', '')
        .replace('post_has_image', '')
        .replace('primary_background', '')
        .replace('vertical', '');
};

export default observer(NativePostWrapper);
