import * as React from 'react';
import i18n from 'i18next';
import { action } from 'mobx';
import {
    cumulativeTunables,
    headerTunables,
    legendTunables,
    opacityTunables,
    secondLegendTunables,
    smartSizeTunables,
    thresholdsTunables,
    zoomTunables,
} from 'components/Sidebar/RenderTunables/SharedTunables';
import { CollapseProps, TunablesCollapse } from 'components/Sidebar/RenderTunables/RenderTunables';
import {
    endianOptions,
    metricValueOptions,
    regionOptions,
} from 'components/Panel/PanelEditorSidebar/PanelDesign/PanelDesignOptions';
import { Widget } from '@sprinklr/stories/widget/Widget';
import { Theme } from 'models/Theme/Theme';
import { Geography } from 'models/Geo/Geography';
import { Region } from 'models/Geo/types';
import { WorldHeatMapWidgetOptions, WorldHeatMapWidgetOptionsImpl } from './options';
import { states as statesIndia } from 'components/_charts/WorldHeatMap/regions/india';
import { states as statesUS } from 'components/_charts/WorldHeatMap/regions/unitedStates';
import ActionSliderGroup from 'components/_UI/Forms/Actions/ActionSliderGroup';
import ActionCheckboxGroup from 'components/_UI/Forms/Actions/ActionCheckboxGroup';
import ActionCheckboxSliderGroup from 'components/_UI/Forms/Actions/ActionCheckboxSliderGroup';
import { AnalyticsFilter, AnalyticsRequest } from '@sprinklr/stories/analytics/AnalyticsRequest';
import LabelGroup from 'components/_UI/Forms/LabelGroup/LabelGroup';
import ActionColorSwatchGroup from 'components/_UI/Forms/Actions/ActionColorSwatchGroup';
import { ColorResult, Flexbox, LabeledRadio, LabeledSelect, Text } from 'components/spaceKit';
import ActionSelectGroup from 'components/_UI/Forms/Actions/ActionSelectGroup';
import { widgetDimsLength } from 'utils/Widget/WidgetUtils';
import AuthService, { FEATURE_FLAG } from 'services/Auth/AuthService';
import ActionCheckboxSliderColorSwatchGroup from 'components/_UI/Forms/Actions/ActionCheckboxSliderColorSwatchGroup';
import ActionSegmentedGroup from 'components/_UI/Forms/Actions/ActionSegmentedGroup';
import { generateColorPalette } from 'utils/GenerateColors/GenerateColors';
import { PostsFormatRequest } from '@sprinklr/stories/post/PostsFormatRequest';
import { WidgetType } from 'models/Widget/WidgetType';
import { StoryboardVersion } from 'models/Storyboard/Storyboard';

export const onRegionChange = action(
    (
        event: any,
        widget: Widget,
        request?: AnalyticsRequest | PostsFormatRequest,
        updateGroupby = true
    ) => {
        const region: Region = event && event.value;

        if (region) {
            const options = widget.options as WorldHeatMapWidgetOptions;

            // update option
            options.region = region;
            options.regionState = 'all';
            options.regionCounty = 'none';
            regionChanged(
                widget,
                region,
                options.cityMarkers.enabled,
                options.regionMapEnabled,
                request || widget?.analyticsRequests?.[0],
                updateGroupby
            );
        }
    }
);

const onRegionStateChange = (event: any, widget: Widget) => {
    const regionState: string = event && event.value;
    const options = widget.options as WorldHeatMapWidgetOptions;

    // update option
    options.regionState = regionState;
    options.regionCounty = 'none';
};

const onRegionCountyChange = (event: any, widget: Widget) => {
    const regionCounty: string = event && event.value;
    const options = widget.options as WorldHeatMapWidgetOptions;

    // update option
    options.regionCounty = regionCounty;
};

const onCitiesEnabledChange = (widget: Widget) => {
    const options = widget.options as WorldHeatMapWidgetOptions;
    options.cityMarkers.enabled = true;
    options.regionMapEnabled = false;
    const request = widget?.analyticsRequests?.[0];
    regionChanged(widget, options.region, true, false, request);
};

const onRegionEnabledChange = (widget: Widget) => {
    const options = widget.options as WorldHeatMapWidgetOptions;
    options.cityMarkers.enabled = false;
    options.regionMapEnabled = true;
    const request = widget?.analyticsRequests?.[0];
    regionChanged(widget, options.region, false, true, request);
};

const onBothEnabledChange = (widget: Widget) => {
    const options = widget.options as WorldHeatMapWidgetOptions;
    options.cityMarkers.enabled = true;
    options.regionMapEnabled = true;
    const request = widget?.analyticsRequests?.[0];
    regionChanged(widget, options.region, true, true, request);
};

const regionChanged = action(
    (
        widget: Widget,
        region: Region,
        citiesEnabled: boolean,
        regionEnabled: boolean,
        request,
        updateGroupby = true
    ) => {
        // return early if there is no request to mutate

        if (!request) {
            return;
        }

        if (region) {
            let formattedRegion = 'COUNTRY';
            if (region === 'unitedStates') {
                formattedRegion = 'US_STATE';
            } else if (region === 'india' || region === 'japan') {
                formattedRegion = 'COUNTRY_SUBDIVISION';
            }

            const bothTypesEnabled = citiesEnabled && regionEnabled;

            let dimensionName = citiesEnabled ? 'CITY' : formattedRegion;

            let secondRequest;

            if (citiesEnabled && !regionEnabled) {
                widget.analyticsRequests.splice(1, 1);
            }

            // Special-case for Kerala (currently)
            if (
                (request && request.reportingEngine === 'PLATFORM') ||
                request.reportingEngine === 'UNIFIED_ANALYTICS_REPORTING_ENGINE'
            ) {
                return;
            }

            // update groupby
            if (updateGroupby && request?.groupBys?.length) {
                request.groupBys[0].heading = dimensionName;
                request.groupBys[0].dimensionName = dimensionName;
                if (request.groupBys[0].details) {
                    request.groupBys[0].details.alternateHeading = dimensionName;
                }

                if (bothTypesEnabled) {
                    secondRequest = JSON.parse(JSON.stringify(request));
                    secondRequest.groupBys[0].heading = formattedRegion;
                    secondRequest.groupBys[0].dimensionName = formattedRegion;
                    secondRequest.groupBys[0].details.alternateHeading = formattedRegion;
                }
            }

            // find filter
            const geoFilterIndex: number = request.filters.findIndex(
                requestFilter =>
                    requestFilter.dimensionName === 'US_STATE' ||
                    requestFilter.dimensionName === 'COUNTRY' ||
                    requestFilter.dimensionName === 'COUNTRY_SUBDIVISION'
            );

            // remove old filter
            if (geoFilterIndex !== -1) {
                request.filters.splice(geoFilterIndex, 1);
                bothTypesEnabled && secondRequest.filters.splice(geoFilterIndex, 1);
            }

            // all regions other than world and united states get filters
            if (region !== 'world') {
                let countries: string[];

                dimensionName = 'COUNTRY';

                if (region === 'unitedStates') {
                    countries = ['US'];
                } else if (region === 'india') {
                    countries = ['IN', 'LK'];
                } else if (region === 'japan') {
                    countries = ['JP'];
                } else {
                    countries = Geography.create(region).getCountries();
                }

                // create new filter
                const countriesFilter: AnalyticsFilter = {
                    dimensionName,
                    filterType: 'IN',
                    values: countries,
                };

                // add new filter
                request.filters.push(countriesFilter);

                if (bothTypesEnabled) {
                    secondRequest.filters.push(countriesFilter);
                }
            }

            // filter out unknown
            if (
                !request.filters.some(
                    filter =>
                        filter.filterType === 'NIN' &&
                        dimensionName === 'COUNTRY' &&
                        filter.values.includes('UN')
                )
            ) {
                const unknownFilter: AnalyticsFilter = {
                    dimensionName: 'COUNTRY',
                    filterType: 'NIN',
                    values: ['UN'],
                };
                request.filters.push(unknownFilter);
            }

            if (bothTypesEnabled) {
                widget.analyticsRequests[1] = secondRequest;
            }
        }
    }
);

const regionStates = {
    unitedStates: statesUS,
    india: statesIndia,
};

export const regionCounties = [
    { label: i18n.t('None'), value: 'none' },
    { label: i18n.t('All'), value: 'all' },
];

export const WorldHeatMapTunables = (
    widget: Widget,
    mergedTheme: Theme,
    authService?: AuthService,
    storyboard?: StoryboardVersion,
    parentType?: WidgetType
): TunablesCollapse[] => {
    const widgetOptions = widget.options as WorldHeatMapWidgetOptionsImpl;
    const isCityEnabled = widgetOptions.cityMarkers?.enabled ?? false;
    const isRegionMapEnabled = widgetOptions.regionMapEnabled ?? !isCityEnabled;
    const isStateCounty =
        authService && authService.isFeatureEnabled(FEATURE_FLAG.FEATURE_MAP_STATE_COUNTY);
    const fallbackColor = !!widget.theme.typography.color
        ? widget.theme.typography.color
        : mergedTheme.typography.color;
    const colors: string[] = generateColorPalette(mergedTheme, 1, false, widget.theme);

    const isGeoStream = parentType?.id === 'geoStream';

    return [
        {
            props: {
                label: i18n.t('World Heat Map'),
                startOpen: true,
            } as CollapseProps,
            children: [
                ...(!isGeoStream
                    ? [
                          ...headerTunables(widget),
                          opacityTunables(widget),
                          smartSizeTunables(widget),
                          <LabeledSelect
                              label={i18n.t('Map Region')}
                              key='widget.options.region'
                              inline
                              value={widgetOptions.region as any}
                              onChange={event => onRegionChange(event, widget)}
                              options={regionOptions}
                          />,
                      ]
                    : []),
                isStateCounty && regionStates[widgetOptions.region] && (
                    <LabeledSelect
                        label={i18n.t('Region State')}
                        key='widget.options.regionState'
                        inline
                        value={widgetOptions.regionState as any}
                        onChange={event => onRegionStateChange(event, widget)}
                        options={regionStates[widgetOptions.region]}
                    />
                ),
                isStateCounty &&
                    regionStates[widgetOptions.region] &&
                    widgetOptions.regionState != 'all' && (
                        <React.Fragment key='widget.options.county'>
                            <LabeledSelect
                                label={i18n.t('Region County')}
                                key='widget.options.regionCounty'
                                inline
                                value={widgetOptions.regionCounty as any}
                                onChange={event => onRegionCountyChange(event, widget)}
                                options={regionCounties}
                            />
                            {widgetOptions.regionCounty === 'all' && (
                                <LabelGroup
                                    key='widget.options.regionsNeighborsColor'
                                    label={i18n.t('Region Neighbors')}>
                                    <ActionColorSwatchGroup
                                        key='widget.options.regionsNeighborsColor'
                                        option={widgetOptions}
                                        value='regionsNeighborsColor'
                                    />
                                </LabelGroup>
                            )}
                        </React.Fragment>
                    ),

                <LabelGroup label={i18n.t('Map By:')} key='widget.options.mapType'>
                    <LabeledRadio
                        checked={!widgetOptions.cityMarkers.enabled}
                        disabled={false}
                        label={'Regions'}
                        onClick={action(() => onRegionEnabledChange(widget))}
                    />
                    <LabeledRadio
                        checked={
                            widgetOptions.cityMarkers.enabled && !widgetOptions.regionMapEnabled
                        }
                        disabled={false}
                        label={'Cities'}
                        onClick={action(() => onCitiesEnabledChange(widget))}
                    />
                    {!isGeoStream && (
                        <LabeledRadio
                            checked={
                                widgetOptions.cityMarkers.enabled && widgetOptions.regionMapEnabled
                            }
                            disabled={false}
                            label={'Both'}
                            onClick={action(() => onBothEnabledChange(widget))}
                        />
                    )}
                </LabelGroup>,
                isRegionMapEnabled && (
                    <ActionCheckboxSliderColorSwatchGroup
                        key='widget.options.mapBorder'
                        label={i18n.t('Border Color')}
                        showValue
                        checkbox={{
                            option: widgetOptions.mapBorder,
                            value: 'enabled',
                        }}
                        slider={{
                            option: widgetOptions.mapBorder,
                            value: 'size',
                            min: 0,
                            max: 100,
                        }}
                        colorSwatch={{
                            option: widgetOptions.mapBorder,
                            value: 'color',
                            onClear: action(
                                () =>
                                    (widgetOptions.mapBorder.color =
                                        mergedTheme.typography && mergedTheme.typography.color)
                            ),
                            onChange: action(
                                ({ rgb: { r, g, b, a } }: ColorResult) =>
                                    (widgetOptions.mapBorder.color = `rgba(${r}, ${g}, ${b}, ${a})`)
                            ),
                            color: !!widgetOptions.mapBorder.color
                                ? widgetOptions.mapBorder.color
                                : mergedTheme.typography && mergedTheme.typography.color,
                            secondaryIcon: widgetOptions.mapBorder.color ? 'icon-undo' : undefined,
                        }}
                    />
                ),
                widgetDimsLength(widget) === 2 && (
                    <React.Fragment key='widget.options.animationOverTime.enabled'>
                        <ActionCheckboxGroup
                            key='widget.options.animationOverTime.enabled'
                            label={i18n.t('Animate Over Time')}
                            option={widgetOptions.animationOverTime}
                            value='enabled'
                        />

                        {widgetOptions.animationOverTime.enabled && (
                            <React.Fragment key='widget.options.animationOverTime.options'>
                                {cumulativeTunables(widget)}
                                <ActionSelectGroup
                                    label={i18n.t('Legend Date Format')}
                                    key='widget.options.animationOverTime.timeFormat'
                                    option={widgetOptions.animationOverTime}
                                    value='timeFormat'
                                    options={endianOptions}
                                />
                                <ActionSliderGroup
                                    key='widget.options.animationOverTime.duration'
                                    label={i18n.t('Duration')}
                                    option={widgetOptions.animationOverTime}
                                    value='duration'
                                    suffix='ms'
                                    showValue
                                    min={1}
                                    max={10000}
                                />
                                <ActionCheckboxSliderGroup
                                    key='widget.options.animationOverTime.pauseOnEnd'
                                    label={i18n.t('Pause on End')}
                                    showValue
                                    suffix='ms'
                                    checkbox={{
                                        option: widgetOptions.animationOverTime.pauseOnEnd,
                                        value: 'enabled',
                                    }}
                                    slider={{
                                        option: widgetOptions.animationOverTime.pauseOnEnd,
                                        value: 'duration',
                                        min: 0,
                                        max: 10000,
                                    }}
                                />
                            </React.Fragment>
                        )}
                    </React.Fragment>
                ),
            ],
        },
        isCityEnabled && {
            props: {
                label: i18n.t('Map by Cities'),
                startOpen: true,
            } as CollapseProps,
            children: [
                <Flexbox vertical gap='xl' key='cities'>
                    <Flexbox vertical gap='m'>
                        <Text.TextSemiBold13>City Markers</Text.TextSemiBold13>
                        <ActionSliderGroup
                            key='options.scaleMultiplier'
                            label={i18n.t('Scale')}
                            option={widget.options}
                            value='scaleMultiplier'
                            showValue
                            min={1}
                            max={100}
                        />
                        <ActionSliderGroup
                            key='options.cityMarkers.opacity'
                            label={i18n.t('Opacity')}
                            option={widgetOptions.cityMarkers}
                            value='opacity'
                            showValue
                            min={1}
                            max={100}
                        />
                        <ActionCheckboxSliderColorSwatchGroup
                            key='widget.options.cityMarkers.border'
                            label={i18n.t('Outline')}
                            showValue
                            checkbox={{
                                option: widgetOptions.cityMarkers.border,
                                value: 'enabled',
                            }}
                            slider={{
                                option: widgetOptions.cityMarkers.border,
                                value: 'size',
                                min: 0,
                                max: 100,
                            }}
                            colorSwatch={{
                                option: widgetOptions.cityMarkers.border,
                                value: 'color',
                                onClear: action(
                                    () =>
                                        (widgetOptions.cityMarkers.border.color =
                                            mergedTheme.typography && mergedTheme.typography.color)
                                ),
                                onChange: action(
                                    ({ rgb: { r, g, b, a } }: ColorResult) =>
                                        (widgetOptions.cityMarkers.border.color = `rgba(${r}, ${g}, ${b}, ${a})`)
                                ),
                                color: !!widgetOptions.cityMarkers.border.color
                                    ? widgetOptions.cityMarkers.border.color
                                    : mergedTheme.typography && mergedTheme.typography.color,
                                secondaryIcon: widgetOptions.cityMarkers.border.color
                                    ? 'icon-undo'
                                    : undefined,
                            }}
                        />
                        {!isGeoStream && (
                            <ActionCheckboxGroup
                                key='widget.options.cityMarkers.fill.enabled'
                                label={i18n.t('Custom Fill Color')}
                                option={widgetOptions.cityMarkers.fill}
                                value='enabled'
                            />
                        )}
                        {widgetOptions.cityMarkers.fill.enabled && (
                            <LabelGroup
                                key='widget.options.cityMarkers.fill'
                                label={i18n.t('Fill Color')}>
                                <ActionColorSwatchGroup
                                    key='widget.options.cityMarkers.fill'
                                    option={widgetOptions.cityMarkers.fill}
                                    value='color'
                                    secondaryIcon={
                                        widgetOptions.cityMarkers.fill.color !== ''
                                            ? 'icon-undo'
                                            : undefined
                                    }
                                    color={
                                        widgetOptions.cityMarkers.fill.color !== ''
                                            ? widgetOptions.cityMarkers.fill.color
                                            : mergedTheme?.colorPalette?.colorBlend?.startColor
                                    }
                                    onClear={action(
                                        () =>
                                            (widgetOptions.cityMarkers.fill.color =
                                                'rgba(255, 255, 255, .5)')
                                    )}
                                    onChange={action(
                                        ({ rgb: { r, g, b, a } }: ColorResult) =>
                                            (widgetOptions.cityMarkers.fill.color = `rgba(${r}, ${g}, ${b}, ${a})`)
                                    )}
                                />
                            </LabelGroup>
                        )}
                    </Flexbox>

                    {!isRegionMapEnabled && (
                        <Flexbox vertical gap='m'>
                            <Text.TextSemiBold13 key='Map header'>
                                Background Map
                            </Text.TextSemiBold13>
                            <ActionCheckboxSliderColorSwatchGroup
                                key='widget.options.mapBorder'
                                label={i18n.t('Border Color')}
                                showValue
                                checkbox={undefined}
                                slider={{
                                    option: widgetOptions.mapBorder,
                                    value: 'size',
                                    min: 1,
                                    max: 100,
                                }}
                                colorSwatch={{
                                    option: widgetOptions.mapBorder,
                                    value: 'color',
                                    onClear: action(
                                        () =>
                                            (widgetOptions.mapBorder.color =
                                                mergedTheme.typography &&
                                                mergedTheme.typography.color)
                                    ),
                                    onChange: action(
                                        ({ rgb: { r, g, b, a } }: ColorResult) =>
                                            (widgetOptions.mapBorder.color = `rgba(${r}, ${g}, ${b}, ${a})`)
                                    ),
                                    color: !!widgetOptions.mapBorder.color
                                        ? widgetOptions.mapBorder.color
                                        : mergedTheme.typography && mergedTheme.typography.color,
                                    secondaryIcon: widgetOptions.mapBorder.color
                                        ? 'icon-undo'
                                        : undefined,
                                }}
                            />
                            <LabelGroup key='widget.options.mapBorder.fill' label={i18n.t('Fill')}>
                                <ActionColorSwatchGroup
                                    key='widget.options.mapBorder.fill'
                                    option={widgetOptions.mapBorder}
                                    value='fill'
                                    secondaryIcon={
                                        widgetOptions.mapBorder.fill !== ''
                                            ? 'icon-undo'
                                            : undefined
                                    }
                                    color={
                                        widgetOptions.mapBorder.fill !== ''
                                            ? widgetOptions.mapBorder.fill
                                            : ''
                                    }
                                    onClear={action(() => (widgetOptions.mapBorder.fill = ''))}
                                    onChange={action(
                                        ({ rgb: { r, g, b, a } }: ColorResult) =>
                                            (widgetOptions.mapBorder.fill = `rgba(${r}, ${g}, ${b}, ${a})`)
                                    )}
                                />
                            </LabelGroup>
                        </Flexbox>
                    )}
                </Flexbox>,
            ],
        },
        !isGeoStream &&
            isStateCounty && {
                props: {
                    label: i18n.t('Region Points'),
                    startOpen: false,
                    isEnabled: (widget.options as any).regionPointsEnabled,
                    option: widget.options,
                    value: 'regionPointsEnabled',
                } as CollapseProps,
                children: [
                    <>
                        <ActionCheckboxSliderColorSwatchGroup
                            key='points.marker'
                            label='Point Markers'
                            showValue
                            checkbox={{
                                option: widgetOptions.regionPoints.marker,
                                value: 'enabled',
                            }}
                            slider={{
                                option: widgetOptions.regionPoints.marker,
                                value: 'size',
                                min: 1,
                                max: 100,
                            }}
                            colorSwatch={{
                                option: null,
                                value: null,
                                onClear: action(
                                    () => (widgetOptions.regionPoints.marker.color = '')
                                ),
                                onChange: action(
                                    ({ rgb: { r, g, b, a } }: any) =>
                                        (widgetOptions.regionPoints.marker.color = `rgba(${r}, ${g}, ${b}, ${a})`)
                                ),
                                color: !!widgetOptions.regionPoints.marker.color
                                    ? widgetOptions.regionPoints.marker.color
                                    : colors[0],
                                secondaryIcon: !!widgetOptions.regionPoints.marker.color
                                    ? 'icon-undo'
                                    : undefined,
                            }}
                        />
                        {widgetOptions.regionPoints.marker.enabled && (
                            <ActionCheckboxSliderColorSwatchGroup
                                key='points.marker.stroke'
                                label='Marker Border'
                                showValue
                                checkbox={{
                                    option: widgetOptions.regionPoints.marker.stroke,
                                    value: 'enabled',
                                }}
                                slider={{
                                    option: widgetOptions.regionPoints.marker.stroke,
                                    value: 'size',
                                    min: 1,
                                    max: 100,
                                }}
                                colorSwatch={{
                                    option: widgetOptions.regionPoints.marker.stroke,
                                    value: 'color',
                                    color: widgetOptions.regionPoints.marker.stroke.color,
                                    onChange: action(
                                        ({ rgb: { r, g, b, a } }) =>
                                            (widgetOptions.regionPoints.marker.stroke.color = `rgba(${r}, ${g}, ${b}, ${a})`)
                                    ),
                                }}
                            />
                        )}
                        <ActionCheckboxSliderColorSwatchGroup
                            key='widget.points.value'
                            label='Point Value'
                            showValue
                            checkbox={{
                                option: widgetOptions.regionPoints.value,
                                value: 'enabled',
                            }}
                            slider={{
                                option: widgetOptions.regionPoints.value,
                                value: 'size',
                                min: 1,
                                max: 100,
                            }}
                            colorSwatch={{
                                option: null,
                                value: null,
                                onClear: action(
                                    () => (widgetOptions.regionPoints.value.color = '')
                                ),
                                onChange: action(
                                    ({ rgb: { r, g, b, a } }: any) =>
                                        (widgetOptions.regionPoints.value.color = `rgba(${r}, ${g}, ${b}, ${a})`)
                                ),
                                color: !!widgetOptions.regionPoints.value.color
                                    ? widgetOptions.regionPoints.value.color
                                    : fallbackColor,
                                secondaryIcon: !!widgetOptions.regionPoints.value.color
                                    ? 'icon-undo'
                                    : undefined,
                            }}
                        />
                        <ActionSegmentedGroup
                            between
                            key='widget.options.metricValueFormat'
                            label={i18n.t('Format')}
                            option={widgetOptions.regionPoints.value}
                            value={'format'}
                            options={metricValueOptions}
                            showOptionLabel
                        />
                    </>,
                ],
            },
        !isGeoStream &&
            legendTunables(widget, {
                header: false,
                orientation: true,
                orientationOpts: ['top', 'right', 'bottom', 'left'],
                ratio: false,
                value: false,
                wrap: false,
                legendSize: true,
                legendMarkerSize: false,
                legendHeaderText: isCityEnabled
                    ? i18n.t('Map By Cities Legend')
                    : i18n.t('Map By Region Legend'),
                legendShowValue: true,
                showMetricName: true,
            }),
        !isGeoStream &&
            isCityEnabled &&
            isRegionMapEnabled &&
            secondLegendTunables(widget, {
                header: false,
                orientation: true,
                orientationOpts: ['top', 'right', 'bottom', 'left'],
                ratio: false,
                value: false,
                wrap: false,
                legendSize: true,
                legendMarkerSize: false,
                legendHeaderText: i18n.t('Map By Region Legend'),
            }),
        zoomTunables(widget, { zoom: true }),
        isStateCounty && !isGeoStream && thresholdsTunables(widget, mergedTheme, 'heatMap'),
    ];
};

export default WorldHeatMapTunables;
