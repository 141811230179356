import { load, Font } from 'opentype.js';
import { GraphQLService } from '../GraphQLService/GraphQLService';
import { Mapper } from '../DataStore/DataStore';
import { SocialMediaAsset } from 'models/SocialMediaAsset/SocialMediaAsset';
import { BaseRecordService } from 'utils/BaseRecordService/BaseRecordService';
import { SocialMediaAssetImpl } from 'models/SocialMediaAsset/SocialMediaAssetImpl';
import { SAMSearch } from './SAMSearch';
import { FontStyle, FontWeight } from 'models/Theme/Theme';

export class SocialMediaAssetService extends BaseRecordService<SocialMediaAsset> {
    constructor(graphQL: GraphQLService, mapper: Mapper<SocialMediaAsset>) {
        super(graphQL, mapper);
        // this.queries = new GraphQLQueries();
    }

    find(id: string): Promise<SocialMediaAsset> {
        return this.queryRaw(
            {
                query: `query findAsset($id: ID!){
                     sam {
                         socialMediaAssets(id:$id) {
                            ${SocialMediaAssetImpl.graphQLFields}
                         }
                     }
                 }`,
                variables: { id: id },
            },
            (data: any) => {
                if (
                    data &&
                    data.sam &&
                    data.sam.socialMediaAssets &&
                    data.sam.socialMediaAssets.length
                ) {
                    return data.sam.socialMediaAssets[0];
                } else {
                    return null;
                }
            }
        );
    }

    search(search: SAMSearch): Promise<Array<SocialMediaAsset>> {
        return this.queryManyRaw(
            {
                query: `query searchAssets($search: samSearch!){
                     sam {
                         socialMediaAssets(search:$search) {
                            ${SocialMediaAssetImpl.graphQLFields}
                         }
                     }
                 }`,
                variables: { search: search },
            },
            (data: any) => {
                if (
                    data &&
                    data.sam &&
                    data.sam.socialMediaAssets &&
                    data.sam.socialMediaAssets.length
                ) {
                    return data.sam.socialMediaAssets;
                } else {
                    return [];
                }
            }
        ).then(this.enrichAssets);
    }

    private enrichAssets(assets: Array<SocialMediaAsset>): Promise<Array<SocialMediaAsset>> {
        const isFont = (asset: SocialMediaAsset) => {
            if (asset.assetType !== 'FONT' || !asset.digitalAsset || !asset.digitalAsset.mediaUrl) {
                return false;
            }

            const mediaType = asset.digitalAsset.mediaMimeType;

            switch (mediaType) {
                case 'application/x-font-otf':
                case 'font/opentype':
                case 'font/truetype':
                    return true;

                default:
                    // console.warn(`Not treating asset '${asset.name}' (${asset.id}) as font, mediaType: ${mediaType}`);
                    return false;
            }
        };

        return Promise.all(
            assets.map(
                asset =>
                    new Promise((resolve, reject) => {
                        // check for the mime type too, SVGs and other crap sneak in here.
                        if (isFont(asset)) {
                            const fontUrl = asset.digitalAsset.mediaUrl;

                            load(fontUrl, (error: any, font?: Font) => {
                                if (error) {
                                    // TODO: use name-based fallback instead? Just log it?
                                    const msg = `opentype.js error for asset '${asset.name}' (${asset.id}) from URL ${fontUrl}`;
                                    console.error(msg, error, asset);
                                    reject(error);
                                }

                                if (font) {
                                    // console.log("Hey, I found a font: ", font);
                                    // font.names.fontFamily.

                                    const family =
                                        font.names.fontFamily && font.names.fontFamily['en']
                                            ? font.names.fontFamily['en']
                                            : font.names.fullName['en'];
                                    const subFamily: string = font.names.fontSubfamily['en'];
                                    let style: FontStyle = 'normal';
                                    let weight: FontWeight = 'normal';

                                    // TODO: support weirdo weights
                                    if (subFamily.toLowerCase().indexOf('bold') !== -1) {
                                        weight = 'bold';
                                    }

                                    if (subFamily.toLowerCase().indexOf('oblique') !== -1) {
                                        style = 'oblique';
                                    }

                                    if (subFamily.toLowerCase().indexOf('italic') !== -1) {
                                        style = 'italic';
                                    }

                                    asset.digitalAsset.font = {
                                        samId: asset.id,
                                        name: asset.name,
                                        description: asset.description,
                                        url: asset.digitalAsset.mediaUrl,
                                        family: family,
                                        subFamily: subFamily,
                                        style: style,
                                        weight: weight,
                                    };

                                    asset.sortKey = family
                                        ? (family + subFamily).toLowerCase()
                                        : asset.name
                                        ? asset.name.toLowerCase()
                                        : '';

                                    resolve(asset);
                                } else {
                                    // skip enrichment
                                    resolve(asset);
                                }
                            });
                        } else {
                            resolve(asset);
                        }
                    }) as Promise<SocialMediaAsset>
            )
        );
    }
}
