import { AnalyticsRequest, Sort } from './PanelTemplatesTheme/PanelTemplatesTheme';
import { Panel } from '../../models/Panel/Panel';
import { WIDGET_LABEL_SIZE } from '../../components/Widget/WidgetLabel/constants';

export var TemplateUserMetrics: Panel = {
    id: 'user-metrics',
    name: 'User Metrics',
    tags: ['Smart', 'User Metrics', 'Channel', 'Account', 'Brand', 'landscape', 'portrait'],
    description: 'Show User Profiles',
    widget: {
        position: {
            width: '1920px',
            height: '1080px',
            top: '0',
            left: '0',
        },
        classes: 'template_user_metrics',
        children: [
            {
                type: 'profile',
                name: 'User Metrics',
                id: '1',
                profileRequests: [
                    // Note: All global variables like reportingEngine, report, filters, and projections
                    // are injected via AnalyticsRequest.getRequests()
                    new AnalyticsRequest({
                        page: 0,
                        pageSize: 20,
                        sorts: [
                            new Sort({
                                order: 'DESC',
                                heading: {
                                    LISTENING: 'REACH_COUNT',
                                    PLATFORM: 'TOTAL_ENGAGEMENT',
                                    BENCHMARKING: 'TOTAL_ENGAGEMENT',
                                    RDB_FIREHOSE: 'SN_CREATED_TIME',
                                    STORY_MESSAGE: 'IMPACT',
                                },
                            }),
                        ],
                        timePeriod: {
                            key: 'last_7_days',
                        },
                    }) as any,
                ],
                position: {
                    height: '100%',
                    width: '100%',
                    top: '0',
                    left: '0',
                },
                options: {
                    player: {
                        playing: true,
                        duration: 15,
                    },
                    showDescription: true,
                    showIcon: true,

                    showMetricValue: true,
                    metricValueSize: 30,

                    showMetricName: true,
                    metricNameSize: 10,
                    smartSize: {
                        enabled: true,
                        mode: 'responsive',
                        size: 'xl',
                    },
                    portrait: {
                        profileDescriptionSize: 30,
                    },
                },
                label: {
                    titleText: 'User Metrics',
                    size: WIDGET_LABEL_SIZE.primary,
                    enabled: true,
                },
            },
        ],
    },
};
