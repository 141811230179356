import { AxiosInstance } from 'axios';
import moment from 'moment';

export interface TwitterTrend {
    name: string;
    url: string;
    promoted_content: null;
    query: string;
    tweet_volume: number | null;
}

export interface YahooLocation {
    name: string;
    woeid: number;
}

export interface TwitterTrendsResponse {
    trends: TwitterTrend[];
    as_of: string;
    created_at: string;
    locations: YahooLocation[];
}

export interface GoogleRelatedSearch {
    query: string;
    safe: boolean;
}

export interface GoogleArticle {
    title: string;
    link: string;
    source: string;
    snippet: string;
}

/*
 {
     "title": "Uber",
     "titleLinkUrl": "//www.google.com/search?q\u003dUber",
     "relatedSearchesList": [
         {
             "query": "Uber Trump",
             "safe": true
         },
         {
             "query": "Delete Uber",
             "safe": true
         },
         {
             "query": "Lyft",
             "safe": true
         }
     ],
     "formattedTraffic": "500,000+",
     "trafficBucketLowerBound": 500000,
     "hotnessLevel": 4,
     "hotnessColor": "#db601e",
     "imgUrl": "//t0.gstatic.com/images?q\u003dtbn:ANd9GcT8mWyx0Q38K0Z1m7QBxjcI1XddipNgIMQq6yUdXZaZb9EaeQmOIo-x-X0QVBKbN-OV-cpPiZtY",
     "imgSource": "WJLA",
     "imgLinkUrl": "http://wjla.com/news/local/uber-ceo-commits-to-giving-3-million-to-help-immigrants-after-deleteuber",
     "newsArticlesList": [
         {
             "title": "\u003cb\u003eUber\u003c/b\u003e CEO commits to giving $3 million to help immigrants after #DeleteUber",
             "link": "http://wjla.com/news/local/uber-ceo-commits-to-giving-3-million-to-help-immigrants-after-deleteuber",
             "source": "WJLA",
             "snippet": "WASHINGTON (ABC7) — \u003cb\u003eUber\u003c/b\u003e found itself in hot water on two fronts related to President Donald Trump\u0026#39;s temporary ban on immigrants from seven Muslim-majority countries: for allegedly exploiting a taxi protest against the ban and for CEO Travis Kalanick \u003cb\u003e...\u003c/b\u003e"
         },
         {
             "title": "\u003cb\u003eUber\u003c/b\u003e pledges $3M to travel ban defense fund as backlash widens, celebrities \u003cb\u003e...\u003c/b\u003e",
             "link": "http://www.cnbc.com/2017/01/29/uber-customers-lash-out-at-ride-sharing-service-amid-anti-travel-ban-protests.html",
             "source": "CNBC",
             "snippet": "\u003cb\u003eUber\u003c/b\u003e found itself at the center of a storm created by the travel ban imposed by President Donald Trump\u0026#39;s executive order, as angry customers accused the company of attempting to profit from a taxi driver work stoppage. In response to a growing \u003cb\u003e...\u003c/b\u003e"
         }
     ],
     "startTime": 1.4857128E9,
     "shareUrl": "https://www.google.com/trends/hottrends?stt\u003dUber\u0026std\u003d20170129\u0026pn\u003dp1#a\u003d20170129-Uber",
     "date": "20170129",
     "exploreUrl": "/trends/explore#q\u003dUber\u0026date\u003dnow+7-d\u0026geo\u003dUS",
     "safe": true
  }
*/

export interface GoogleTrend {
    title: string;
    titleLinkUrl: string;
    relatedSearchesList: GoogleRelatedSearch[];
    formattedTraffic: string;
    trafficBucketLowerBound: number;
    hotnessLevel: number;
    hotnessColor: string;
    imgUrl: string;
    imgSource: string;
    imgLinkUrl: string;
    newsArticlesList: GoogleArticle[];
    startTime: number;
    shareUrl: string;
    date: string;
    exploreUrl: string;
    safe: boolean;
}

export interface GoogleTrendDay {
    date: string;
    formattedDate: string;
    trendsList: GoogleTrend[];
}

interface BaseTrendsRequest {
    limit: number;
}

export interface GoogleTrendsRequest extends BaseTrendsRequest {
    source: 'google';
    country: string;
}

export interface TwitterTrendsRequest extends BaseTrendsRequest {
    source: 'twitter';
    woeid: number;
}

export type TrendRequest = GoogleTrendsRequest | TwitterTrendsRequest;

export type TrendResult = TwitterTrendsResponse | GoogleTrendDay;

export default class TrendService {
    static twitterWorld = 1;

    static twitterCountry = {
        global: 1,
        unitedStates: 23424977,
        italy: 23424853,
        poland: 23424923,
        germany: 23424829,
        spain: 23424950,
        canada: 23424775,
        switzerland: 23424957,
        belgium: 23424757,
        netherlands: 23424909,
        portugal: 23424925,
        australia: 23424748,
        unitedArabEmirates: 23424738,
        hungary: 23424844,
        austria: 23424750,
        ukraine: 23424976,
        southKorea: 23424868,
        argentina: 23424747,
        brazil: 23424768,
        columbia: 23424787,
        mexico: 23424900,
    };

    static twitterCountryOptions = [
        { label: 'Global', value: 1, countryCode: 'GLOB' },
        { label: 'Algeria', value: 23424740, countryCode: 'DZ' },
        { label: 'Argentina', value: 23424747, countryCode: 'AR' },
        { label: 'Australia', value: 23424748, countryCode: 'AU' },
        { label: 'Austria', value: 23424750, countryCode: 'AT' },
        { label: 'Bahrain', value: 23424753, countryCode: 'BH' },
        { label: 'Belarus', value: 23424765, countryCode: 'BY' },
        { label: 'Belgium', value: 23424757, countryCode: 'BE' },
        { label: 'Brazil', value: 23424768, countryCode: 'BR' },
        { label: 'Canada', value: 23424775, countryCode: 'CA' },
        { label: 'Chile', value: 23424782, countryCode: 'CL' },
        { label: 'Colombia', value: 23424787, countryCode: 'CO' },
        { label: 'Denmark', value: 23424796, countryCode: 'DK' },
        { label: 'Dominican Republic', value: 23424800, countryCode: 'DO' },
        { label: 'Ecuador', value: 23424801, countryCode: 'EC' },
        { label: 'Egypt', value: 23424802, countryCode: 'EG' },
        { label: 'France', value: 23424819, countryCode: 'FR' },
        { label: 'Germany', value: 23424829, countryCode: 'DE' },
        { label: 'Ghana', value: 23424824, countryCode: 'GH' },
        { label: 'Greece', value: 23424833, countryCode: 'GR' },
        { label: 'Guatemala', value: 23424834, countryCode: 'GT' },
        { label: 'India', value: 23424848, countryCode: 'IN' },
        { label: 'Indonesia', value: 23424846, countryCode: 'ID' },
        { label: 'Ireland', value: 23424803, countryCode: 'IE' },
        { label: 'Israel', value: 23424852, countryCode: 'IL' },
        { label: 'Italy', value: 23424853, countryCode: 'IT' },
        { label: 'Japan', value: 23424856, countryCode: 'JP' },
        { label: 'Jordan', value: 23424860, countryCode: 'JO' },
        { label: 'Kenya', value: 23424863, countryCode: 'KE' },
        { label: 'Korea', value: 23424868, countryCode: 'KR' },
        { label: 'Kuwait', value: 23424870, countryCode: 'KW' },
        { label: 'Latvia', value: 23424874, countryCode: 'LV' },
        { label: 'Lebanon', value: 23424873, countryCode: 'LB' },
        { label: 'Malaysia', value: 23424901, countryCode: 'MY' },
        { label: 'Mexico', value: 23424900, countryCode: 'MX' },
        { label: 'Netherlands', value: 23424909, countryCode: 'NL' },
        { label: 'New Zealand', value: 23424916, countryCode: 'NZ' },
        { label: 'Nigeria', value: 23424908, countryCode: 'NG' },
        { label: 'Norway', value: 23424910, countryCode: 'NO' },
        { label: 'Oman', value: 23424898, countryCode: 'OM' },
        { label: 'Pakistan', value: 23424922, countryCode: 'PK' },
        { label: 'Panama', value: 23424924, countryCode: 'PA' },
        { label: 'Peru', value: 23424919, countryCode: 'PE' },
        { label: 'Philippines', value: 23424934, countryCode: 'PH' },
        { label: 'Poland', value: 23424923, countryCode: 'PL' },
        { label: 'Portugal', value: 23424925, countryCode: 'PT' },
        { label: 'Puerto Rico', value: 23424935, countryCode: 'PR' },
        { label: 'Qatar', value: 23424930, countryCode: 'QA' },
        { label: 'Russia', value: 23424936, countryCode: 'RU' },
        { label: 'Saudi Arabia', value: 23424938, countryCode: 'SA' },
        { label: 'Singapore', value: 23424948, countryCode: 'SG' },
        { label: 'South Africa', value: 23424942, countryCode: 'ZA' },
        { label: 'Spain', value: 23424950, countryCode: 'ES' },
        { label: 'Sweden', value: 23424954, countryCode: 'SE' },
        { label: 'Switzerland', value: 23424957, countryCode: 'CH' },
        { label: 'Thailand', value: 23424960, countryCode: 'TH' },
        { label: 'Turkey', value: 23424969, countryCode: 'TR' },
        { label: 'Ukraine', value: 23424976, countryCode: 'UA' },
        { label: 'United Arab Emirates', value: 23424738, countryCode: 'AE' },
        { label: 'United Kingdom', value: 23424975, countryCode: 'GB' },
        { label: 'United States', value: 23424977, countryCode: 'US' },
        { label: 'Venezuela', value: 23424982, countryCode: 'VE' },
        { label: 'Vietnam', value: 23424984, countryCode: 'VN' },
    ];

    // "p1": "United States"
    // "p30": "Argentina"
    // "p8": "Australia"
    // "p44": "Austria"
    // "p41": "Belgium"
    // "p18": "Brazil"
    // "p13": "Canada"
    // "p38": "Chile"
    // "p32": "Colombia"
    // "p43": "Czechia"
    // "p49": "Denmark"
    // "p29": "Egypt"
    // "p50": "Finland"
    // "p16": "France"
    // "p15": "Germany"
    // "p48": "Greece"
    // "p10": "Hong Kong"
    // "p45": "Hungary"
    // "p3": "India"
    // "p19": "Indonesia"
    // "p6": "Israel"
    // "p27": "Italy"
    // "p4": "Japan"
    // "p37": "Kenya"
    // "p34": "Malaysia"
    // "p21": "Mexico"
    // "p17": "Netherlands"
    // "p53": "New Zealand"
    // "p52": "Nigeria"
    // "p51": "Norway"
    // "p25": "Philippines"
    // "p31": "Poland"
    // "p47": "Portugal"
    // "p39": "Romania"
    // "p14": "Russia"
    // "p36": "Saudi Arabia"
    // "p5": "Singapore"
    // "p40": "South Africa"
    // "p23": "South Korea"
    // "p26": "Spain"
    // "p42": "Sweden"
    // "p46": "Switzerland"
    // "p12": "Taiwan"
    // "p33": "Thailand"
    // "p24": "Turkey"
    // "p35": "Ukraine"
    // "p9": "United Kingdom"

    axios: AxiosInstance;

    constructor(axios: AxiosInstance) {
        this.axios = axios;
    }

    /**
     * @param request
     * @returns {any}
     * @todo Come up with a normalized response interface
     */
    getTrends(request: TrendRequest): Promise<TrendResult> {
        switch (request.source) {
            case 'google':
                return this.getGoogleHotItems(request.country, request.limit);
            case 'twitter':
                return this.getTwitterTrends(
                    request.woeid || TrendService.twitterWorld,
                    request.limit
                );
        }
    }

    /**
     * @param woeid Yahoo Where on Earth ID 1=Global, 23424977=USA
     * @returns {Promise<TwitterTrendsResponse>}
     */
    getTwitterTrends(woeid?: number, limit?: number): Promise<TwitterTrendsResponse> {
        return this.axios
            .get(`/twitter/trends?id=${woeid || TrendService.twitterWorld}`)
            .then(response => {
                if (!response.data) {
                    return null;
                }

                const data: TwitterTrendsResponse = response.data as TwitterTrendsResponse;
                if (data.trends && limit && data.trends.length > limit) {
                    data.trends.length = limit;
                }

                return data;
            });
    }

    // for a specific date, returns data for the previous day
    // curl --data "ajax=1&htd=20170131&pn=p1&htv=l" http://www.google.com/trends/hottrends/hotItems

    // for last 30 days
    // curl --data "ajax=1&pn=p1&htv=m" http://www.google.com/trends/hottrends/hotItems

    getGoogleHotItems(country?: string, limit?: number): Promise<GoogleTrendDay> {
        const now: moment.Moment = moment();
        const today = now.startOf('day');
        const todayFormatted = today.format('YYYYMMDD');

        return this.axios
            .get(`/google/trends/hottrends?date=${todayFormatted || ''}&country=${country || ''}`)
            .then(response => {
                if (
                    !response.data ||
                    !(response.data.trendsByDateList instanceof Array) ||
                    response.data.trendsByDateList.length === 0
                ) {
                    return {
                        date: todayFormatted,
                        formattedDate: todayFormatted,
                        trendsList: [],
                    };
                }

                const data: GoogleTrendDay = response.data.trendsByDateList[0] as GoogleTrendDay;
                if (data.trendsList && limit && data.trendsList.length > limit) {
                    data.trendsList.length = limit;
                }

                return data;
            });
    }
}
