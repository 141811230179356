import { PostEntities, Post, UrlEntities, UrlAttributes } from '@sprinklr/stories/post/Post';
import cloneDeep from 'lodash/cloneDeep';
import { stripHtmlTags } from '../StringUtils/StringUtils';

type GenerateFragmentProps = {
    text: any;
    textEntities: PostEntities[];
    maxCharacters: number;
    channel: string;
};

export type Fragment = {
    type?: 'url' | 'screenName' | 'hashtag' | 'emoji';
    value: string;
    replace?: string;
    url?: string;
};
function generateFragments({
    text,
    textEntities = [],
    maxCharacters = 100,
    channel,
}: GenerateFragmentProps) {
    const fragments = [];

    if ((!textEntities || !textEntities.length) && text) {
        fragments.push({
            type: null,
            value: removeTags(
                escapeToUnicode(text.replace(/(?:https?|ftp):\/\/[\n\S]+/g, '')),
                channel
            ).slice(0, maxCharacters),
        });

        // If text contains an url, create an url fragment.
        const textUrl = text.match(/(?:https?|ftp):\/\/[\n\S]+/g);
        if (textUrl !== null) {
            textUrl.forEach(item => {
                fragments.push({
                    type: 'url',
                    value: item.toString().includes('//t.co') ? '' : item.toString(),
                });
            });
        }
    }

    // Sort textEntities according to entity types containing the most elements (words, links, etc.) first.
    const sortedtextEntities = textEntities
        ? textEntities.sort((b, a) => b.indices[0] - a.indices[0])
        : null;

    sortedtextEntities &&
        sortedtextEntities.forEach((entity, index) => {
            const type = getEntityType(entity);

            // get value of the text between the current entity start and the end of the previous entity
            const previousEnd =
                (sortedtextEntities[index - 1] && sortedtextEntities[index - 1].indices[1]) || -1;
            const currentStart: number = entity.indices[0];

            if (currentStart - 1 > previousEnd) {
                if (maxCharacters > currentStart || maxCharacters === -1) {
                    var value = text.substring(
                        previousEnd === 0 ? previousEnd : previousEnd + 1,
                        currentStart - 1
                    );
                } else if (maxCharacters > previousEnd || maxCharacters === -1) {
                    var value = text.substring(
                        previousEnd + 1,
                        maxCharacters === -1 ? text.length : maxCharacters
                    );
                } else {
                    return;
                }

                if (value !== null) {
                    value = removeTags(escapeToUnicode(value), channel);
                }

                fragments.push({
                    type: null,
                    value,
                    replace: null,
                    url: null,
                });
            }

            // get the entity value only if the start of the entity is in range
            // We are detecting the presence of "@[" in the screen name to help us determine if the text should be replaced with the value provided via textEntities
            // This format if for a specific Facebook ID. e.g.:  @[12345:User Name]
            if (maxCharacters > currentStart || maxCharacters === -1) {
                const textSlice = text.substring(entity.indices[0], entity.indices[1]);
                fragments.push({
                    type,
                    value: type === 'url' ? entity.url : textSlice,
                    replace:
                        type === 'screenName' && textSlice.indexOf('@[') === 0
                            ? entity[type]
                            : null,
                    url: type === 'emoji' ? entity[type] : null,
                });
            }

            // Add any text after the last entity
            if (index + 1 === sortedtextEntities.length && maxCharacters > entity.indices[1]) {
                fragments.push({
                    type: null,
                    value: text.substring(entity.indices[1], maxCharacters),
                    replace: null,
                    url: type === 'emoji' ? entity[type] : null,
                });
            }
        });
    return fragments;
}

export const getEntityType = entity => {
    const types: string[] = ['url', 'screenName', 'hashtag', 'emoji'];
    let result;

    types.forEach((type, index) => {
        if (entity[type]) {
            result = type;
            return;
        }
    });
    return result;
};

export const removeTags = (text: string, channel: string): string => {
    const doc = new DOMParser().parseFromString(text, 'text/html');
    const element = doc.documentElement;

    // On IE11, if input text is empty, then documentElement is null
    if (!element) {
        return '';
    }

    if (channel === 'wordpress') {
        // Removes shortcodes from wordpress data (i.e. "[this_is_an_ad]")
        return element.textContent.replace(/\[.*\]/, '');
    }

    return element.textContent;
};

export const escapeToUnicode = (text: string): string => {
    const escapedUnicodeRegExp = new RegExp('((?:[&s]?#x|&[#s]x|&#x?)[0-9a-fA-F]{1,5};?)s?', 'g');
    const hexaUnicodeRegExp = new RegExp('[0-9a-fA-F]{1,5}');
    let escaped = [];

    // Find all escaped characters that are unicode (i.e. "&#x1F3FC;").
    escaped = text.match(escapedUnicodeRegExp);

    if (escaped !== null && escaped !== undefined) {
        // For each escaped unicode character found...
        for (const escapedChar of escaped) {
            const radix = escapedChar.includes('x') ? 16 : 10;
            const value = parseInt(hexaUnicodeRegExp.exec(escapedChar)[0], radix); // Extract hexadecimal value.

            try {
                if (value >= 0x10000) {
                    // If the unicode is for a character from an astral plane.
                    // Generate surrogates.
                    const higherSurrogate = Math.floor((value - 0x10000) / 0x400) + 0xd800;
                    const lowerSurrogate = ((value - 0x10000) % 0x400) + 0xdc00;

                    // Replace the old unicode value with the new.
                    text = text.replace(
                        escapedChar,
                        String.fromCodePoint(higherSurrogate, lowerSurrogate)
                    );
                } else {
                    text = text.replace(escapedChar, String.fromCodePoint(value));
                }
            } catch (err) {
                console.log(
                    'String.fromCodePoint() has received an invalid unicode value',
                    err.message
                );
                text = text.replace(escapedChar, '');
            }
        }
    }
    return text;
};

type UrlIntityKey = 'url' | 'display_url' | 'expanded_url';
type GetTextEntitiesArgs = {
    textEntities: Post['textEntities'];
    urlEntities: Post['urlEntities'];
    showExpandedEntityUrls: boolean;
    inputKey?: UrlIntityKey;
    outputKey?: UrlIntityKey;
};

export function getTextEntities({
    textEntities,
    urlEntities,
    showExpandedEntityUrls,
    inputKey = 'display_url',
    outputKey = 'expanded_url',
}: GetTextEntitiesArgs): Post['textEntities'] {
    if (!showExpandedEntityUrls) {
        return textEntities;
    } else {
        const resolvedEntities = cloneDeep(textEntities);
        const replacements = {};

        // create a map of expandable urls
        urlEntities?.message?.forEach(entity => {
            replacements[`${entity?.[inputKey]}`] = entity?.[outputKey];
        });

        // replace the urls in the textEntities if they match
        resolvedEntities?.message?.forEach(entity => {
            if (entity?.url && Object.keys(replacements)?.includes(entity?.url)) {
                entity.url = replacements[entity.url];
            }
        });
        return resolvedEntities;
    }
}

export function formatTextEntities(text: string, urlEntities: any): string {
    const urlTest = /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/gi;
    const regUrl = new RegExp(urlTest);

    if (text) {
        text = text.replace(/#(\w+)#/g, '#$1 #'); // e.g. #foo#bar -> #foo #bar
        text = text.replace(/(.)\s+(,|:)/g, '$1$2'); // e.g. herpderp , foobar -> herpderp, foobar
        text = text.replace(/\bnull\b/g, ''); // e.g. _space_ (null) _space_ -> nothing.
        text = text.replace(/[\(\)\"]+/g, ''); // e.g. Remove parenthesis and double quotes.
        text = text.replace(/\@\[[0-9]+\:(.*)\]/g, '$1'); // Remove strange @[33234234:text] links
        text = text.replace(/\@\[[0-9]+π(.+)π.*]/g, '$1'); // Remove strange LinkedIn formatting (for Leidos)

        if (urlEntities) {
            const words = text.split(/\s+/);
            let tempSentence = '';
            let wordLower;
            let result = '';

            for (let i = 0; i < words.length; i++) {
                wordLower = words[i].toLowerCase();

                // Is this a url?
                if (wordLower.match(regUrl)) {
                    if (tempSentence) {
                        result += tempSentence;
                        tempSentence = '';
                    }
                    const url = stripHtmlTags(words[i].replace(/[\,]$/g, ''));
                    const urlAttributes = getUrlAttributes(url, urlEntities);

                    if (urlAttributes) {
                        const { href, anchorText } = urlAttributes;
                        result += ` ${href} ${anchorText}`;
                    } else {
                        result += ' ';
                    }
                } else {
                    tempSentence += ' ' + words[i];

                    // end of loop, last item
                    if (i == words.length - 1 && tempSentence) {
                        result += tempSentence;
                    }
                }
            }

            text = result;
        }
    }
    return text;
}

// resolve t.co links
function getUrlAttributes(url: string, urlEntities: UrlEntities): UrlAttributes {
    let displayUrl = '';
    let expandedUrl = '';

    if (url.indexOf('http') === -1) {
        url = 'http://' + url;
    }

    url = url.replace(',', '');

    const match = urlEntities?.message.find(urlEntity => urlEntity.url === url);
    if (match) {
        displayUrl = `http://${match.display_url}`;
        expandedUrl = match.expanded_url;
    } else if (url.includes('//t.co')) {
        console.log('we have a t.co link and no replacement');
        return;
    }

    return {
        href: match ? expandedUrl : url,
        anchorText: match ? displayUrl : url,
    };
}

export default generateFragments;
