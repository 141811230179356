import { observable } from 'mobx';
import { Source } from './Source';
import { PostsFormatRequest } from '@sprinklr/stories/post/PostsFormatRequest';
import { ArrayDiff } from '../../utils/ArrayDiff/ArrayDiff';
import SignalService from '../../services/SignalService/SignalService';
import ObjectUtils from '../../utils/ObjectUtils/ObjectUtils';

export abstract class Format<T> {
    request: PostsFormatRequest;
    sources: Source<T, any>[];
    abstract items: T[];
    @observable protected _offset = 0;
    protected uniqueKey: string;
    protected itemsInternal: T[][] = [[], []];
    protected diffs: ArrayDiff<T>[];
    protected signalService: SignalService;
    @observable protected itemsChanged = 0;

    abstract destroy(): void;
    abstract isActive(offset: number): boolean;
    abstract offsetDecrement(): void;
    abstract offsetIncrement(): void;
    abstract setSource(whichSource: number, source: Source<any, any>): void;

    constructor(
        request: PostsFormatRequest,
        sources: Source<T, any>[],
        signalService?: SignalService
    ) {
        const source = sources[0];

        this.request = request;
        this.uniqueKey = source.uniqueKey;
        this.sources = sources;
        this.diffs = [
            new ArrayDiff<T>(this.uniqueKey, source.diff.ordered),
            new ArrayDiff<T>(this.uniqueKey, source.diff.ordered),
        ];
        this.signalService = signalService;
    }

    get offset(): number {
        return this._offset;
    }

    get overflowed(): boolean {
        return true;
    }

    setOverflow(): void {}

    equalsRequest(request: PostsFormatRequest): boolean {
        const requestA = ObjectUtils.copy(this.request);
        const requestB = ObjectUtils.copy(request);

        requestA.sources && requestA.sources.forEach(source => (source.data = null));
        requestB.sources && requestB.sources.forEach(source => (source.data = null));

        return JSON.stringify(requestA) === JSON.stringify(requestB);
    }

    update() {
        this.sources.forEach((source: Source<any, any>) => {
            source.update();
        });
    }

    cancelUpdate(): void {
        this.sources.forEach((source: Source<any, any>) => {
            source.cancelUpdate();
        });
    }

    protected isShuffle(): boolean {
        for (let x = 0; x < this.sources.length; x++) {
            if (this.sources[x].isShuffle()) {
                return true;
            }
        }

        return false;
    }

    /**
     * Shuffles array in place. Uses https://en.wikipedia.org/wiki/Fisher%E2%80%93Yates_shuffle#The_modern_algorithm
     * @see https://stackoverflow.com/questions/6274339/how-can-i-shuffle-an-array
     * @param {Array} a items An array containing the items.
     */
    protected shuffle(a: any[]): void {
        let j, x, i;
        for (i = a.length - 1; i > 0; i--) {
            j = Math.floor(Math.random() * (i + 1));
            x = a[i];
            a[i] = a[j];
            a[j] = x;
        }
    }
}
