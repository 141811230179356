import { WidgetOptions } from '@sprinklr/stories/widget/WidgetOptions';
import { WidgetOptionsImpl } from 'models/Widget/WidgetOptions';
import { LegendOrientation, SmartSize } from '@sprinklr/stories/widget/types';
import { observable } from 'mobx';
import { DecimalFormat } from 'utils/Number/NumberUtils';
import { FontCase } from 'models/Theme/Theme';

export interface SmallMultiplesPieChartWidgetOptions extends WidgetOptions {
    data?: any;
    emptyWedgeColor?: string;
    blacklist?: string[];
    legendOrientation?: LegendOrientation;
    maxItems?: number;
    minWedgeRatio?: number;
    legendSize?: number;
    legendWrap?: boolean;
}

export class SmallMultiplesPieChartWidgetOptionsImpl extends WidgetOptionsImpl
    implements SmallMultiplesPieChartWidgetOptions {
    @observable labelSize = 20;
    @observable showMetricValue = true;
    @observable metricValueSize = 25;
    @observable metricValueFormat: DecimalFormat = '1k';

    @observable showInnerRadius = true;
    @observable innerRadiusSize = 41;

    @observable zoom = 50;
    @observable offsetX = 0;
    @observable offsetY = 0;

    @observable showTimeRemainder = true;
    @observable emptyWedgeColor = 'rgba(255, 255, 255, .3)';
    @observable blacklist: string[] = [];
    @observable showOtherItems = false;
    @observable maxItems = 9;

    @observable showLegend = true;
    @observable legendOrientation: LegendOrientation = 'bottom';
    @observable legendWidth = 25;
    @observable legendHeight = 25;
    @observable showLegendRatio = true;
    @observable legendPercentageSize = 32;
    @observable legendPercentageFormat: DecimalFormat = '1.2k';
    @observable showLegendValue = false;
    @observable legendValueSize = 32;
    @observable legendValueFormat: DecimalFormat = '1k';
    @observable legendWrap = false;
    @observable showLegendHeader = false;
    @observable showLegendName = true;
    @observable legendNameSize = 20;
    @observable smartSize: SmartSize = {
        enabled: false,
        mode: 'responsive',
        size: 'xl',
        baseline: {
            labelSize: 20,
            metricValueSize: 25,
            legendValueSize: 32,
            legendPercentageSize: 32,
            legendHeight: 25,
            legendWidth: 25,
            legendNameSize: 20,
        },
        optionDefaults: {},
        breakpoints: {
            xs: {
                options: {},
            },
            s: {
                options: {},
            },
        },
    };
}
