import { DefaultPosition } from '../PanelTemplatesTheme/constants';
import { Panel } from 'models/Panel/Panel';
import { WidgetDataTablePivot } from './WidgetDataTablePivot';

export const PanelDataTablePivot: Panel = {
    id: 'data-table-pivot',
    name: 'Two Dimensional Table',
    description: '',
    tags: ['Standard', 'Data Table', 'landscape', 'portrait'],
    widget: {
        id: '1',
        position: {
            width: '1920px',
            height: '1080px',
            top: '0',
            left: '0',
        },
        classes: 'template_data_table_pivot',
        children: [
            {
                ...WidgetDataTablePivot,
                position: DefaultPosition,
                options: {
                    ...WidgetDataTablePivot.options,
                    smartSize: {
                        enabled: true,
                        mode: 'responsive',
                        size: 'xl',
                    },
                },
            },
        ],
    },
};
