import * as React from 'react';
import i18n from 'i18next';
import {
    headerTunables,
    opacityTunables,
    zoomTunables,
    imageAdjustmentTunables,
} from 'components/Sidebar/RenderTunables/SharedTunables';
import { TunablesCollapse, CollapseProps } from 'components/Sidebar/RenderTunables/RenderTunables';
import ActionSegmentedGroup from '../../components/_UI/Forms/Actions/ActionSegmentedGroup';
import {
    imageBackgroundSizeOptions,
    verticalAlignOptions,
    horizontalAlignOptions,
} from 'components/Panel/PanelEditorSidebar/PanelDesign/PanelDesignOptions';
import { Widget } from '@sprinklr/stories/widget/Widget';
import LabelGroup from '../../components/_UI/Forms/LabelGroup/LabelGroup';
import ActionImageSwatch from '../../components/_UI/Forms/Actions/ActionImageSwatch';
import { Flexbox } from 'components/spaceKit';
import ActionColorSwatchGroup from '../../components/_UI/Forms/Actions/ActionColorSwatchGroup';
import ActionCheckboxGroup from 'components/_UI/Forms/Actions/ActionCheckboxGroup';

const CustomContentImageTunables = (widget: Widget): TunablesCollapse[] => [
    {
        props: {
            label: i18n.t('Image'),
            startOpen: true,
        } as CollapseProps,
        children: [
            ...headerTunables(widget, { header: true }),
            opacityTunables(widget),
            <LabelGroup label={i18n.t('Image Content')} key='widget.options.contentImageUrl'>
                <Flexbox gap='m'>
                    <ActionImageSwatch
                        title='image'
                        option={widget.options}
                        value='contentImageUrl'
                    />
                    <ActionColorSwatchGroup
                        option={widget.options}
                        value='contentBackgroundColor'
                    />
                </Flexbox>
            </LabelGroup>,
            <ActionSegmentedGroup
                key='widget.options.contentImageFit'
                label={i18n.t('Image Fit')}
                option={widget.options}
                value='contentImageFit'
                options={imageBackgroundSizeOptions}
            />,
            <ActionSegmentedGroup
                key='widget.options.verticalAlign'
                label={i18n.t('Vertical Position')}
                option={widget.options}
                value='verticalAlign'
                options={verticalAlignOptions}
            />,
            <ActionSegmentedGroup
                key='widget.options.horizontalAlign'
                label={i18n.t('Horizontal Position')}
                option={widget.options}
                value='horizontalAlign'
                options={horizontalAlignOptions}
            />,
            <ActionCheckboxGroup
                key='widget.options.image.optimize'
                label={i18n.t('Optimize Image')}
                option={widget.options}
                value='contentImageOptimized'
            />,
        ],
    },
    imageAdjustmentTunables(widget),
    zoomTunables(widget, { zoom: true, clip: true }),
];

export default CustomContentImageTunables;
