import { AnalyticsRequest, GroupBy, Sort } from './PanelTemplatesTheme/PanelTemplatesTheme';
import { DefaultWidgetPosition } from './PanelTemplatesTheme/constants';
import { Widget } from '@sprinklr/stories/widget/Widget';
import { WIDGET_LABEL_SIZE } from '../../components/Widget/WidgetLabel/constants';
import ListeningDimensions from '@sprinklr/stories-services/AnalyticsService/ListeningDimensions';
import { TEXT_SHADOW_DEFAULT } from 'models/Widget/Widget';

export const WidgetEmojiCloud: Widget = {
    type: 'wordCloud',
    id: 'emoji-cloud',
    name: 'Emoji Cloud',
    useGlobalTimePeriod: true,
    analyticsRequests: [
        // Note: All global variables like reportingEngine, report, filters, and projections
        // are injected via AnalyticsRequest.getRequests()
        new AnalyticsRequest({
            limit: 25,
            groupBys: [
                new GroupBy({
                    LISTENING: { source: ListeningDimensions.emoticon },
                    INBOUND_MESSAGE: null, // not supported
                    PLATFORM: null, // not supported
                    BENCHMARKING: null, // not supported
                    PAID: null, // not supported
                    ADVOCACY: null, // not supported
                    TWITTER: null, // not supported
                    RDB_FIREHOSE: null, // not supported
                }),
            ],
            sorts: [
                new Sort({ order: 'DESC', projections: 0 }),
                new Sort({ order: 'ASC', groupBys: 0 }),
            ],
            timePeriods: [{ key: 'last_7_days' }],
            additional: {
                TARGET_LANG_CODE: '',
            },
        }) as any,
    ],
    position: DefaultWidgetPosition,
    label: {
        overlap: false,
        titleText: 'Emoji Cloud',
        size: WIDGET_LABEL_SIZE.primary,
        enabled: false,
        textShadow: TEXT_SHADOW_DEFAULT,
    },
    options: {
        player: {
            playing: true,
            duration: 15,
        },
        spacing: 1,
        showSentiment: false,
        smartSize: {
            enabled: true,
            mode: 'responsive',
            size: 'm',
        },
        portrait: {
            widgetOrientation: 'portrait',
        },
    },
};
