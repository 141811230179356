import { action, computed, observable, toJS } from 'mobx';
import {
    AnalyticsGroupBy,
    AnalyticsGroupType,
    AnalyticsRequest,
    AnalyticsTimeInterval,
} from '@sprinklr/stories/analytics/AnalyticsRequest';
import MetaInfoService from '../../../services/MetaInfoService/MetaInfoService';
import { WidgetDimensionKey } from '../WidgetDimensionKey';
import { WidgetRequest } from '../WidgetRequest';
import { WidgetRequestAnalytics } from '../WidgetRequestAnalytics';
import { WidgetJSON } from '../WidgetWrapper';
import { setObservable } from '../../../utils/Mobx/Utils';

// export interface AnalyticsGroupBy {
//     heading: string;
//     dimensionName: DimensionName;
//     groupType: "DATE_HISTOGRAM" | "TIME_OF_DAY" | "DAY_OF_WEEK" | "MONTH_OF_YEAR" | "FIELD";
//     field?: AnalyticsFieldType;
//     details?: {
//         srcType?: "CUSTOM";
//         fieldName?: string;
//         interval?: "1m" | "1h" | "1d" | "1w" | "1M" | "1y"
//     };
// }

/**
 * Wraps a raw AnalyticsGroupBy, providing business logic for form interaction
 */
export class WidgetGroupBy implements WidgetJSON {
    @observable
    isLoading = true;

    @observable
    dimensionLabel: string;

    @observable
    translateLanguage: string;

    @observable
    groupBy: AnalyticsGroupBy;

    private parent: WidgetRequestAnalytics;

    constructor(parent: WidgetRequest, groupBy?: AnalyticsGroupBy) {
        if (!groupBy) {
            groupBy = {
                heading: null,
                dimensionName: null,
                groupType: 'FIELD',
                details: undefined,
            };
        }

        this.parent = parent as WidgetRequestAnalytics;
        this.groupBy = groupBy;

        const request: any = parent.getRequest();
        this.translateLanguage =
            request.additional && request.additional.TARGET_LANG_CODE
                ? request.additional.TARGET_LANG_CODE
                : null;

        this.setDimensionLabel();
    }

    static clear(groupBy: AnalyticsGroupBy): void {
        groupBy.heading = '';
        groupBy.dimensionName = '' as any;
        groupBy.groupType = '' as any;
        groupBy.details = null;
    }

    @computed
    get isIncomplete(): boolean {
        return !this.dimension || !this.dimension.name || !this.dimension.name.length;
    }

    @computed
    get isDate(): boolean {
        switch (this.groupBy?.groupType) {
            case 'DATE_HISTOGRAM':
            case 'TIME_OF_DAY':
            case 'DAY_OF_WEEK':
            case 'MONTH_OF_YEAR':
                return true;
        }

        const name = this.groupBy?.dimensionName;
        if (name?.length) {
            if (name === 'POST_PUBLISHED_DATE') {
                return true;
            } else if (name?.indexOf('DAY_') === 0) {
                return true;
            } else if (name?.indexOf('MONTH_') === 0) {
                return true;
            } else if (name?.indexOf('WEEK_') === 0) {
                return true;
            } else if (name?.indexOf('YEAR_') === 0) {
                return true;
            } else if (name?.indexOf('TIME_') === 0) {
                return true;
            }
        }

        return false;
    }

    @computed
    get heading(): string {
        return this.groupBy.heading;
    }

    @computed
    get dimension(): WidgetDimensionKey {
        // HACKTOWN: Force mobx to re-compute when *anything* changes in GroupBy.
        // Required if select Custom in GroupBy UI and then hit Revert.
        // Doesn't recompute without this line.
        const checker = toJS(this.groupBy);

        return WidgetDimensionKey.create(this.groupBy);
    }

    @computed
    get interval(): AnalyticsTimeInterval {
        if (this.groupBy.details && this.groupBy.details.interval) {
            return this.groupBy.details.interval;
        } else {
            return null;
        }
    }

    @computed
    get groupType(): AnalyticsGroupType {
        if (this.groupBy.groupType) {
            return this.groupBy.groupType;
        } else {
            return null;
        }
    }

    @computed
    get limitType(): string {
        return this.groupBy?.limitType || null;
    }

    @computed
    get groupByLimit(): number {
        return this.groupBy.limit || null;
    }

    get alternateHeading(): string {
        return this.groupBy?.details?.alternateHeading ?? this.groupBy.heading;
    }

    @action
    setDimension(
        dimension: WidgetDimensionKey,
        dataType?: string,
        forMetrics?: string[],
        report?: string
    ): void {
        // If this was a custom filter, then remove fields we don't want
        this.groupBy.details = undefined;

        setObservable(this.groupBy, 'dimensionName', dimension.name);
        setObservable(this.groupBy, 'details', 'displayName', dimension.displayName);

        // Is this a custom dimension?
        if (dimension.isCustom) {
            setObservable(this.groupBy, 'details', 'srcType', 'CUSTOM');
            setObservable(this.groupBy, 'details', 'fieldName', dimension.customName);
            setObservable(this.groupBy, 'details', 'clientId', dimension.clientId);

            this.groupBy.heading = this.groupBy.details.fieldName;

            // Just a built-in dimension
        } else {
            this.groupBy.heading = dimension.displayName || this.groupBy.dimensionName;

            // Sprinklr backend doesn't like [,],<,> chars in heading but is ok with other punctuation
            if (this.groupBy.heading) {
                this.groupBy.heading = this.groupBy.heading.replace(/[\[\]\<\>]/g, '');
            }
        }

        if (dataType === 'DATE_HISTOGRAM') {
            this.groupBy.groupType = dataType as any;

            if (!this.groupBy.details || !this.groupBy.details.interval) {
                this.setInterval('1d');
            }
        } else {
            this.groupBy.groupType = 'FIELD';

            if (this.groupBy.details && this.groupBy.details.interval) {
                this.groupBy.details.interval = undefined;
            }
        }

        this.parent.setMetricReports(forMetrics, report);
        this.parent.setSort();
        this.setDimensionLabel();
    }

    @action
    setInterval(interval: string): void {
        setObservable(this.groupBy, 'details', 'interval', interval);

        if (this.groupBy.heading) {
            if (this.groupBy.heading.lastIndexOf('_1') === this.groupBy.heading.length - 3) {
                this.groupBy.heading = this.groupBy.heading.substr(
                    0,
                    this.groupBy.heading.length - 3
                );
            }

            if (interval) {
                this.groupBy.heading += '_' + interval;
            }
        }

        this.parent.setSort();
    }

    @action
    setTranslateLanguage(language: string): void {
        const request: AnalyticsRequest = this.parent.getRequest() as AnalyticsRequest;

        this.translateLanguage = language;

        setObservable(request, 'additional', 'TARGET_LANG_CODE', language);

        // HACKTOWN: "You cannot observe not yet existing properties and be notified about them, except when using maps."
        // Need this hack to fire a mobx update when a new property is added to JSON.  And yes, I'm using extendObservable
        // for the new property, but it doesn't fire an update.
        const saved = request.reportingEngine;
        request.reportingEngine = 'FORCE UPDATE' as any;
        request.reportingEngine = saved;
    }

    toJSON(): any {
        return this.groupBy;
    }

    private async setDimensionLabel() {
        const engine = this.parent.engine;

        const label = this.parent.metaInfoService.getDimensionLabel(
            this.parent.engine,
            this.parent.report,
            this.dimension
        );

        action((label) => {
            this.dimensionLabel = label;
            this.isLoading = false;
        });
    }

    setLimitType(limitType: string): void {
        setObservable(this.groupBy, 'limitType', limitType);
    }

    setGroupByLimit(limit: number): void {
        setObservable(this.groupBy, 'limit', limit);
    }

    setAlternateHeading(alternateHeading: string): void {
        setObservable(this.groupBy, 'details', 'alternateHeading', alternateHeading);
    }
}
