import { Mapper } from '../DataStore/DataStore';
import { GraphQLService } from '../GraphQLService/GraphQLService';
import { GraphQLQueries, ModelName } from '../GraphQLQueries/GraphQLQueries';
import { BaseRecordService } from 'utils/BaseRecordService/BaseRecordService';
import { Layout } from 'models/Layout/Layout';
import { EnvironmentConfig } from '../../config';

const MODELNAME: ModelName = 'layout';
/**
 * Created by dstelter on 12/13/16.
 */
export class LayoutService extends BaseRecordService<Layout> {
    private queries: GraphQLQueries;
    private config: EnvironmentConfig;

    constructor(graphQL: GraphQLService, mapper: Mapper<Layout>, config: EnvironmentConfig) {
        super(graphQL, mapper);
        this.queries = new GraphQLQueries();
        this.config = config;
    }

    find(id: string | number): Promise<Layout> {
        return this.query(this.queries.queryDescriptor('layout', MODELNAME, { id: id }));
    }

    findAll(query?: any): Promise<Array<Layout>> {
        query = query || {};

        const queryParams = {
            query: this.queries.clientLayoutsQuery,
            variables: query,
        };

        const extractor = result => {
            return result.client.layouts;
        };

        return this.graphQL
            .query(queryParams)
            .then(extractor)
            .then(result => this.mapper.createMany(result));
    }

    create(layout: Layout): Promise<Layout> {
        return this.mutate(
            this.queries.mutationDescriptor('createLayout', MODELNAME, { layout: layout })
        );
    }

    rename(layout: Layout | string, name: string): Promise<Layout> {
        return this.mutate(
            this.queries.mutationDescriptor('renameLayout', MODELNAME, {
                layoutId: this.layoutId(layout),
                name: name,
            })
        );
    }

    findLastScreenIndex(layout: Layout) {
        if (!layout) {
            return;
        }

        let lastIndex = 1;
        let max = 0;

        layout.screens.forEach((screen, index) => {
            const { h, w, x, y } = screen;

            const bottomRight = (w + x) * (h + y);
            if (bottomRight > max) {
                max = bottomRight;
                lastIndex = index;
            }
        });

        return lastIndex;
    }

    getLayoutTemplates(): Layout[] {
        if (this.config.applicationMode === 'PRESENTATIONS') {
            return [
                {
                    name: 'Single',
                    id: '0',
                    description: 'Single Screen',
                    screens: [
                        {
                            h: 1080,
                            w: 1920,
                            x: 0,
                            y: 0,
                        },
                    ],
                },
            ];
        }

        return [
            {
                name: 'Single',
                id: '0',
                description: 'Single Screen',
                screens: [
                    {
                        h: 1080,
                        w: 1920,
                        x: 0,
                        y: 0,
                    },
                ],
            },
            {
                name: 'Two Up',
                id: '1',
                description: 'Two screens side by side ',
                screens: [
                    {
                        h: 1080,
                        w: 1920,
                        x: 0,
                        y: 0,
                    },
                    {
                        h: 1080,
                        w: 1920,
                        x: 1920,
                        y: 0,
                    },
                ],
            },
            {
                name: 'Double Stack',
                id: '2',
                description: 'Two screens stacked on top of each other',
                screens: [
                    {
                        h: 1080,
                        w: 1920,
                        x: 0,
                        y: 0,
                    },
                    {
                        h: 1080,
                        w: 1920,
                        x: 0,
                        y: 1080,
                    },
                ],
            },
            {
                name: 'Triple Stack',
                id: '3',
                description: 'Three screens stacked on top of each other',
                screens: [
                    {
                        h: 1080,
                        w: 1920,
                        x: 0,
                        y: 0,
                    },
                    {
                        h: 1080,
                        w: 1920,
                        x: 0,
                        y: 1080,
                    },
                    {
                        h: 1080,
                        w: 1920,
                        x: 0,
                        y: 2160,
                    },
                ],
            },
            {
                name: 'Triptic',
                id: '4',
                description: 'Three screens side by side ',
                screens: [
                    {
                        h: 1080,
                        w: 1920,
                        x: 0,
                        y: 0,
                    },
                    {
                        h: 1080,
                        w: 1920,
                        x: 1920,
                        y: 0,
                    },
                    {
                        h: 1080,
                        w: 1920,
                        x: 3840,
                        y: 0,
                    },
                ],
            },
            {
                name: '4up',
                id: '5',
                description: '4 screens arranged 2 wide by 2 high',
                screens: [
                    {
                        h: 1080,
                        w: 1920,
                        x: 0,
                        y: 0,
                    },
                    {
                        h: 1080,
                        w: 1920,
                        x: 1920,
                        y: 0,
                    },
                    {
                        h: 1080,
                        w: 1920,
                        x: 0,
                        y: 1080,
                    },
                    {
                        h: 1080,
                        w: 1920,
                        x: 1920,
                        y: 1080,
                    },
                ],
            },
            {
                name: '3x2',
                id: '6',
                description: 'Six Screens arranged 3 wide by 2 high',
                screens: [
                    {
                        x: 0,
                        y: 0,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 1920,
                        y: 0,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 3840,
                        y: 0,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 0,
                        y: 1080,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 1920,
                        y: 1080,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 3840,
                        y: 1080,
                        h: 1080,
                        w: 1920,
                    },
                ],
            },
            {
                name: '4x2',
                id: '7',
                description: 'Eight Screens arranged 4 wide by 2 high',
                screens: [
                    {
                        x: 0,
                        y: 0,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 1920,
                        y: 0,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 3840,
                        y: 0,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 5760,
                        y: 0,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 0,
                        y: 1080,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 1920,
                        y: 1080,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 3840,
                        y: 1080,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 5760,
                        y: 1080,
                        h: 1080,
                        w: 1920,
                    },
                ],
            },
            {
                name: 'Single - Portrait',
                id: '8',
                description: 'Single Portrait Screen',
                screens: [
                    {
                        h: 1920,
                        w: 1080,
                        x: 0,
                        y: 0,
                    },
                ],
            },
            {
                name: 'Tiefighter',
                id: '9',
                description: "It's a TRAP",
                screens: [
                    {
                        x: 0,
                        y: 540,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 1920,
                        y: 0,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 3840,
                        y: 0,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 1920,
                        y: 1080,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 3840,
                        y: 1080,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 5760,
                        y: 540,
                        h: 1080,
                        w: 1920,
                    },
                ],
            },
            {
                name: '3x3',
                id: '10',
                description: 'Nine Screens arranged 3 wide by 3 high',
                screens: [
                    {
                        x: 0,
                        y: 0,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 1920,
                        y: 0,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 3840,
                        y: 0,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 0,
                        y: 1080,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 1920,
                        y: 1080,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 3840,
                        y: 1080,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 0,
                        y: 2160,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 1920,
                        y: 2160,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 3840,
                        y: 2160,
                        h: 1080,
                        w: 1920,
                    },
                ],
            },
            {
                name: '3x4',
                id: '11',
                description: 'Twelve Screens arranged 4 wide by 3 high',
                screens: [
                    {
                        x: 0,
                        y: 0,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 1920,
                        y: 0,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 3840,
                        y: 0,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 5760,
                        y: 0,
                        h: 1080,
                        w: 1920,
                    },

                    {
                        x: 0,
                        y: 1080,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 1920,
                        y: 1080,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 3840,
                        y: 1080,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 5760,
                        y: 1080,
                        h: 1080,
                        w: 1920,
                    },

                    {
                        x: 0,
                        y: 2160,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 1920,
                        y: 2160,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 3840,
                        y: 2160,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 5760,
                        y: 2160,
                        h: 1080,
                        w: 1920,
                    },
                ],
            },
            {
                name: '6x1 vertical',
                id: '12',
                description: '6 Vertical Screens in a Row',
                screens: [
                    {
                        h: 1920,
                        w: 1080,
                        x: 0,
                        y: 0,
                    },
                    {
                        h: 1920,
                        w: 1080,
                        x: 1080,
                        y: 0,
                    },
                    {
                        h: 1920,
                        w: 1080,
                        x: 2160,
                        y: 0,
                    },
                    {
                        h: 1920,
                        w: 1080,
                        x: 3240,
                        y: 0,
                    },
                    {
                        h: 1920,
                        w: 1080,
                        x: 4320,
                        y: 0,
                    },
                    {
                        h: 1920,
                        w: 1080,
                        x: 5400,
                        y: 0,
                    },
                ],
            },
            {
                name: '7x1 vertical',
                id: '13',
                description: '7 Vertical Screens in a Row',
                screens: [
                    {
                        h: 1920,
                        w: 1080,
                        x: 0,
                        y: 0,
                    },
                    {
                        h: 1920,
                        w: 1080,
                        x: 1080,
                        y: 0,
                    },
                    {
                        h: 1920,
                        w: 1080,
                        x: 2160,
                        y: 0,
                    },
                    {
                        h: 1920,
                        w: 1080,
                        x: 3240,
                        y: 0,
                    },
                    {
                        h: 1920,
                        w: 1080,
                        x: 4320,
                        y: 0,
                    },
                    {
                        h: 1920,
                        w: 1080,
                        x: 5400,
                        y: 0,
                    },
                    {
                        h: 1920,
                        w: 1080,
                        x: 6480,
                        y: 0,
                    },
                ],
            },
            {
                name: '3x1 vertical',
                id: '14',
                description: '3 Vertical Screens in a Row',
                screens: [
                    {
                        h: 1920,
                        w: 1080,
                        x: 0,
                        y: 0,
                    },
                    {
                        h: 1920,
                        w: 1080,
                        x: 1080,
                        y: 0,
                    },
                    {
                        h: 1920,
                        w: 1080,
                        x: 2160,
                        y: 0,
                    },
                ],
            },
            {
                name: '8x1 vertical',
                id: '15',
                description: '8 Vertical Screens in a Row',
                screens: [
                    {
                        h: 1920,
                        w: 1080,
                        x: 0,
                        y: 0,
                    },
                    {
                        h: 1920,
                        w: 1080,
                        x: 1080,
                        y: 0,
                    },
                    {
                        h: 1920,
                        w: 1080,
                        x: 2160,
                        y: 0,
                    },
                    {
                        h: 1920,
                        w: 1080,
                        x: 3240,
                        y: 0,
                    },
                    {
                        h: 1920,
                        w: 1080,
                        x: 4320,
                        y: 0,
                    },
                    {
                        h: 1920,
                        w: 1080,
                        x: 5400,
                        y: 0,
                    },
                    {
                        h: 1920,
                        w: 1080,
                        x: 6480,
                        y: 0,
                    },
                    {
                        h: 1920,
                        w: 1080,
                        x: 7560,
                        y: 0,
                    },
                ],
            },
            {
                name: '4x1',
                id: '16',
                description: 'Four screens side by side ',
                screens: [
                    {
                        h: 1080,
                        w: 1920,
                        x: 0,
                        y: 0,
                    },
                    {
                        h: 1080,
                        w: 1920,
                        x: 1920,
                        y: 0,
                    },
                    {
                        h: 1080,
                        w: 1920,
                        x: 3840,
                        y: 0,
                    },
                    {
                        h: 1080,
                        w: 1920,
                        x: 5760,
                        y: 0,
                    },
                ],
            },
            {
                name: 'Dual - Portrait',
                id: '17',
                description: 'Two Portrait Screens',
                screens: [
                    {
                        h: 1920,
                        w: 1080,
                        x: 0,
                        y: 0,
                    },
                    {
                        h: 1920,
                        w: 1080,
                        x: 1080,
                        y: 0,
                    },
                ],
            },
            {
                name: '5x2',
                id: '18',
                description: 'Tens Screens arranged 5 wide by 2 high',
                screens: [
                    {
                        x: 0,
                        y: 0,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 1920,
                        y: 0,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 3840,
                        y: 0,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 5760,
                        y: 0,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 7680,
                        y: 0,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 0,
                        y: 1080,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 1920,
                        y: 1080,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 3840,
                        y: 1080,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 5760,
                        y: 1080,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 7680,
                        y: 1080,
                        h: 1080,
                        w: 1920,
                    },
                ],
            },
            {
                name: '4x4',
                id: '19',
                description: '16 screens arranged 4 wide by 4 high ',
                screens: [
                    {
                        h: 1080,
                        w: 1920,
                        x: 0,
                        y: 0,
                    },
                    {
                        h: 1080,
                        w: 1920,
                        x: 1920,
                        y: 0,
                    },
                    {
                        h: 1080,
                        w: 1920,
                        x: 3840,
                        y: 0,
                    },
                    {
                        h: 1080,
                        w: 1920,
                        x: 5760,
                        y: 0,
                    },

                    {
                        h: 1080,
                        w: 1920,
                        x: 0,
                        y: 1080,
                    },
                    {
                        h: 1080,
                        w: 1920,
                        x: 1920,
                        y: 1080,
                    },
                    {
                        h: 1080,
                        w: 1920,
                        x: 3840,
                        y: 1080,
                    },
                    {
                        h: 1080,
                        w: 1920,
                        x: 5760,
                        y: 1080,
                    },

                    {
                        h: 1080,
                        w: 1920,
                        x: 0,
                        y: 2160,
                    },
                    {
                        h: 1080,
                        w: 1920,
                        x: 1920,
                        y: 2160,
                    },
                    {
                        h: 1080,
                        w: 1920,
                        x: 3840,
                        y: 2160,
                    },
                    {
                        h: 1080,
                        w: 1920,
                        x: 5760,
                        y: 2160,
                    },

                    {
                        h: 1080,
                        w: 1920,
                        x: 0,
                        y: 3240,
                    },
                    {
                        h: 1080,
                        w: 1920,
                        x: 1920,
                        y: 3240,
                    },
                    {
                        h: 1080,
                        w: 1920,
                        x: 3840,
                        y: 3240,
                    },
                    {
                        h: 1080,
                        w: 1920,
                        x: 5760,
                        y: 3240,
                    },
                ],
            },
            {
                name: '5x1',
                id: '20',
                description: '5 screens arranged 5 wide by 1 high ',
                screens: [
                    {
                        h: 1080,
                        w: 1920,
                        x: 0,
                        y: 0,
                    },
                    {
                        h: 1080,
                        w: 1920,
                        x: 1920,
                        y: 0,
                    },
                    {
                        h: 1080,
                        w: 1920,
                        x: 3840,
                        y: 0,
                    },
                    {
                        h: 1080,
                        w: 1920,
                        x: 5760,
                        y: 0,
                    },
                    {
                        h: 1080,
                        w: 1920,
                        x: 7680,
                        y: 0,
                    },
                ],
            },
            {
                name: '8x2',
                id: '21',
                description: '16 Screens arranged 8 wide by 2 high',
                screens: [
                    {
                        x: 0,
                        y: 0,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 1920,
                        y: 0,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 3840,
                        y: 0,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 5760,
                        y: 0,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 7680,
                        y: 0,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 9600,
                        y: 0,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 11520,
                        y: 0,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 13440,
                        y: 0,
                        h: 1080,
                        w: 1920,
                    },
                    // row 2
                    {
                        x: 0,
                        y: 1080,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 1920,
                        y: 1080,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 3840,
                        y: 1080,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 5760,
                        y: 1080,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 7680,
                        y: 1080,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 9600,
                        y: 1080,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 11520,
                        y: 1080,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 13440,
                        y: 1080,
                        h: 1080,
                        w: 1920,
                    },
                ],
            },
            {
                name: '6x2',
                id: '22',
                description: '12 Screens arranged 6 wide by 2 high',
                screens: [
                    {
                        x: 0,
                        y: 0,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 1920,
                        y: 0,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 3840,
                        y: 0,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 5760,
                        y: 0,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 7680,
                        y: 0,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 9600,
                        y: 0,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 0,
                        y: 1080,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 1920,
                        y: 1080,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 3840,
                        y: 1080,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 5760,
                        y: 1080,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 7680,
                        y: 1080,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 9600,
                        y: 1080,
                        h: 1080,
                        w: 1920,
                    },
                ],
            },
            {
                name: '5x1',
                id: '23',
                description: '5x1 mixed orientation',
                screens: [
                    {
                        x: 0,
                        y: 0,
                        h: 1920,
                        w: 1080,
                    },
                    {
                        x: 1080,
                        y: 0,
                        h: 1920,
                        w: 1080,
                    },
                    {
                        x: 2160,
                        y: 420,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 4080,
                        y: 0,
                        h: 1920,
                        w: 1080,
                    },
                    {
                        x: 5160,
                        y: 0,
                        h: 1920,
                        w: 1080,
                    },
                ],
            },
            {
                name: 'Mixed Two Up',
                id: '24',
                description: 'One portrait and one landscape',
                screens: [
                    {
                        h: 1920,
                        w: 1080,
                        x: 0,
                        y: 0,
                    },
                    {
                        h: 1080,
                        w: 1920,
                        x: 1080,
                        y: 840,
                    },
                ],
            },
            {
                name: '7x1',
                id: '25',
                description: '7x1',
                screens: [
                    {
                        h: 1080,
                        w: 1920,
                        x: 0,
                        y: 0,
                    },
                    {
                        h: 1080,
                        w: 1920,
                        x: 1920,
                        y: 0,
                    },
                    {
                        h: 1080,
                        w: 1920,
                        x: 3840,
                        y: 0,
                    },
                    {
                        h: 1080,
                        w: 1920,
                        x: 5760,
                        y: 0,
                    },
                    {
                        h: 1080,
                        w: 1920,
                        x: 7680,
                        y: 0,
                    },
                    {
                        h: 1080,
                        w: 1920,
                        x: 9600,
                        y: 0,
                    },
                    {
                        h: 1080,
                        w: 1920,
                        x: 11520,
                        y: 0,
                    },
                ],
            },
            {
                name: '9x1 Vertical',
                id: '26',
                description: '9x1 Vertical',
                screens: [
                    {
                        h: 1920,
                        w: 1080,
                        x: 0,
                        y: 0,
                    },
                    {
                        h: 1920,
                        w: 1080,
                        x: 1080,
                        y: 0,
                    },
                    {
                        h: 1920,
                        w: 1080,
                        x: 2160,
                        y: 0,
                    },
                    {
                        h: 1920,
                        w: 1080,
                        x: 3240,
                        y: 0,
                    },
                    {
                        h: 1920,
                        w: 1080,
                        x: 4320,
                        y: 0,
                    },
                    {
                        h: 1920,
                        w: 1080,
                        x: 5400,
                        y: 0,
                    },
                    {
                        h: 1920,
                        w: 1080,
                        x: 6480,
                        y: 0,
                    },
                    {
                        h: 1920,
                        w: 1080,
                        x: 7560,
                        y: 0,
                    },
                    {
                        h: 1920,
                        w: 1080,
                        x: 8640,
                        y: 0,
                    },
                ],
            },
            {
                name: '5x3',
                id: '27',
                description: 'Fifteen screens arranged 5 wide by 3 high',
                screens: [
                    {
                        x: 0,
                        y: 0,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 1920,
                        y: 0,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 3840,
                        y: 0,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 5760,
                        y: 0,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 7680,
                        y: 0,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 0,
                        y: 1080,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 1920,
                        y: 1080,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 3840,
                        y: 1080,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 5760,
                        y: 1080,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 7680,
                        y: 1080,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 0,
                        y: 2160,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 1920,
                        y: 2160,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 3840,
                        y: 2160,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 5760,
                        y: 2160,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 7680,
                        y: 2160,
                        h: 1080,
                        w: 1920,
                    },
                ],
            },
            {
                name: '8x1',
                id: '28',
                description: '8 Screens arranged 8 wide by 1 high',
                screens: [
                    {
                        x: 0,
                        y: 0,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 1920,
                        y: 0,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 3840,
                        y: 0,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 5760,
                        y: 0,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 7680,
                        y: 0,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 9600,
                        y: 0,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 11520,
                        y: 0,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 13440,
                        y: 0,
                        h: 1080,
                        w: 1920,
                    },
                ],
            },
            {
                name: '12x1',
                id: '29',
                description: '12 Screens arranged 12 wide by 1 high',
                screens: [
                    {
                        x: 0,
                        y: 0,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 1920,
                        y: 0,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 3840,
                        y: 0,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 5760,
                        y: 0,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 7680,
                        y: 0,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 9600,
                        y: 0,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 11520,
                        y: 0,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 13440,
                        y: 0,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 15360,
                        y: 0,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 17280,
                        y: 0,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 19200,
                        y: 0,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 21120,
                        y: 0,
                        h: 1080,
                        w: 1920,
                    },
                ],
            },
            {
                name: '6x1',
                id: '30',
                description: '6 Screens arranged 6 wide by 1 high',
                screens: [
                    {
                        x: 0,
                        y: 0,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 1920,
                        y: 0,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 3840,
                        y: 0,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 5760,
                        y: 0,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 7680,
                        y: 0,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 9600,
                        y: 0,
                        h: 1080,
                        w: 1920,
                    },
                ],
            },
            {
                name: '4x1 vertical',
                id: '31',
                description: '4 Vertical Screens in a Row',
                screens: [
                    {
                        h: 1920,
                        w: 1080,
                        x: 0,
                        y: 0,
                    },
                    {
                        h: 1920,
                        w: 1080,
                        x: 1080,
                        y: 0,
                    },
                    {
                        h: 1920,
                        w: 1080,
                        x: 2160,
                        y: 0,
                    },
                    {
                        h: 1920,
                        w: 1080,
                        x: 3240,
                        y: 0,
                    },
                ],
            },
            {
                name: 'X Wing',
                id: '32',
                description: '2 stacked, single, 2 stacked',
                screens: [
                    {
                        h: 1080,
                        w: 1920,
                        x: 0,
                        y: 0,
                    },
                    {
                        h: 1080,
                        w: 1920,
                        x: 0,
                        y: 1080,
                    },
                    {
                        h: 1080,
                        w: 1920,
                        x: 1920,
                        y: 540,
                    },
                    {
                        h: 1080,
                        w: 1920,
                        x: 3840,
                        y: 0,
                    },
                    {
                        h: 1080,
                        w: 1920,
                        x: 3840,
                        y: 1080,
                    },
                ],
            },
            {
                name: '8x4',
                id: '33',
                description: '32 Screens arranged 8 wide by 4 high',
                screens: [
                    {
                        x: 0,
                        y: 0,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 1920,
                        y: 0,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 3840,
                        y: 0,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 5760,
                        y: 0,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 7680,
                        y: 0,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 9600,
                        y: 0,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 11520,
                        y: 0,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 13440,
                        y: 0,
                        h: 1080,
                        w: 1920,
                    },
                    // row 2
                    {
                        x: 0,
                        y: 1080,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 1920,
                        y: 1080,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 3840,
                        y: 1080,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 5760,
                        y: 1080,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 7680,
                        y: 1080,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 9600,
                        y: 1080,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 11520,
                        y: 1080,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 13440,
                        y: 1080,
                        h: 1080,
                        w: 1920,
                    },
                    // row 3
                    {
                        x: 0,
                        y: 2160,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 1920,
                        y: 2160,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 3840,
                        y: 2160,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 5760,
                        y: 2160,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 7680,
                        y: 2160,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 9600,
                        y: 2160,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 11520,
                        y: 2160,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 13440,
                        y: 2160,
                        h: 1080,
                        w: 1920,
                    },
                    // row 4
                    {
                        x: 0,
                        y: 3240,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 1920,
                        y: 3240,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 3840,
                        y: 3240,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 5760,
                        y: 3240,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 7680,
                        y: 3240,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 9600,
                        y: 3240,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 11520,
                        y: 3240,
                        h: 1080,
                        w: 1920,
                    },
                    {
                        x: 13440,
                        y: 3240,
                        h: 1080,
                        w: 1920,
                    },
                ],
            },
        ];
    }
}
