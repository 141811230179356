import loadable from '@loadable/component';

// options
import { PieChartWidgetOptionsImpl } from '../../widgets/PieChartWidget/options';
import { StoryCardWidgetOptionsImpl } from 'src/widgets/PostsWidget/StoryCard/StoryCardWidgetOptionsImpl';
import { PostsWidgetTypePosterOptionsImpl } from '../../widgets/PostsWindowWidget/options';
import { PostsWidgetCinematicOptionsImpl } from '../../widgets/PostsWindowWidget/Cinematic/options';
import { PostsWidgetFilmStripOptionsImpl } from '../../widgets/PostsWindowWidget/FilmStrip/options';
import { PostsWidgetSingleOptionsImpl } from '../../widgets/PostsWindowWidget/SinglePost/options';
import { MetricWidgetOptionsImpl } from '../../widgets/MetricWidget/options';
import { MultiMetricWidgetOptionsImpl } from '../../widgets/MultiMetricWidget/options';
import { RankedListWidgetOptionsImpl } from '../../widgets/RankedListWidget/options';
import { ProfileWidgetOptionsImpl } from '../../widgets/ProfileWidget/options';
import { MetricRollupWidgetOptionsImpl } from '../../widgets/MetricRollupWidget/options';
import { AutoAlertsWidgetOptionsImpl } from '../../widgets/AutoAlertsWidget/options';
import { CustomTableChartWidgetOptionsImpl } from 'components/_charts/CustomTableChartWidget/options';
import { AxisBubbleChartWidgetOptionsImpl } from 'src/widgets/AxisBubbleChartWidget/options';
import { BarChartWidgetOptionsImpl } from 'src/widgets/BarChartWidget/options';
import { BubbleChartWidgetOptionsImpl } from 'src/widgets/BubbleChartWidget/options';
import { BubblePlotChartWidgetOptionsImpl } from 'src/widgets/BubblePlotChartWidget/options';
import {
    ComboChartWidgetOptionsImpl,
    DualAxisWidgetOptionsImpl,
} from 'src/widgets/ComboChartWidget/options';
import {
    CustomContentIframeOptionsImpl,
    CustomContentImageOptionsImpl,
    CustomContentRichTextOptionsImpl,
    CustomContentSocialPostOptionsImpl,
    CustomContentVideoOptionsImpl,
} from 'src/widgets/CustomContentWidget/options';
import { DataTableWidgetOptionsImpl } from 'src/widgets/DataTableWidget/options';
import { DynamicImageWidgetOptionsImpl } from 'src/widgets/DynamicImageWidget/options';
import { FunnelChartWidgetOptionsImpl } from 'src/widgets/FunnelChartWidget/options';
import { HeatMapTableChartWidgetOptionsImpl } from '../../widgets/HeatMapTableChartWidget/options';
import { LineChartWidgetOptionsImpl } from '../../widgets/LineChartWidget/options';
import { PostCardWidgetOptionsImpl } from '../../widgets/PostsWidget/PostCard/options';
import {
    PostsWidgetGridOptionsImpl,
    PostsWidgetGridV2OptionsImpl,
} from '../../widgets/PostsWidget/GridPost/options';
import { PostsWidgetOptionsImpl } from '../../widgets/PostsWidget/options';
import { RunOfShowWidgetOptionsImpl } from '../../widgets/PostsWidget/RunOfShow/options';
import { PostsWidgetCollageOptionsImpl } from '../../widgets/PostsWidget/Collage/options';
import { RankedTableWidgetOptionsImpl } from '../../widgets/RankedTableWidget/options';
import { ShapeWidgetOptionsImpl } from '../../widgets/ShapeWidget/options';
import { SmallMultiplesPieChartWidgetOptionsImpl } from '../../widgets/SmallMultiplesPieChartWidget/options';
import { StackedBarChartWidgetOptionsImpl } from '../../widgets/StackedBarChartWidget/options';
import { TickerWidgetOptionsImpl } from '../../widgets/TickerWidget/options';
import { SunburstWidgetOptionsImpl } from '../../widgets/SunburstWidget/options';
import { TreeMapWidgetOptionsImpl } from '../../widgets/TreeMapWidget/options';
import { WordCloudWidgetOptionsImpl } from '../../widgets/WordCloudWidget/options';
import { WaterfallWidgetOptionsImpl } from '../../widgets/WaterfallWidget/options';
import { TwitterTrendsWidgetOptionsImpl } from '../../widgets/TwitterTrendsWidget/options';
import { WorldHeatMapWidgetOptionsImpl } from '../../widgets/WorldHeatMapWidget/options';
import { GeoStreamWidgetOptionsImpl } from '../../widgets/GeoStreamWidget/options';
import { MetricValueWidgetOptionsImpl } from '../../widgets/MetricValueWidget/options';
import { MetricValueLockupWidgetOptionsImpl } from '../../widgets/MetricValueLockupWidget/options';
import { GoogleTrendsWidgetOptionsImpl } from '../../widgets/GoogleTrendsWidget/options';
import { CountUpWidgetOptionsImpl } from '../../widgets/CountUpWidget/options';
import { HyperlinkButtonWidgetOptionsImpl } from 'src/widgets/HyperlinkButtonWidget/options';
import { SpeechBubbleWidgetOptionsImpl } from '../../widgets/SpeechBubbleWidget/options';
import { DynamicTitleWidgetOptionsImpl } from '../../widgets/DynamicTitleWidget/options';

// types
import IndexedList from '../../models/Collections/IndexedList';

// helpers
import { customTableChartOptionStyles } from 'components/_charts/CustomTableChartWidget/helpers';
import { getPieChartCss } from '../../widgets/PieChartWidget/helpers';
import { getPostCss, postOptionStyles } from '../../widgets/PostsWidget/helpers';
import { getSingleMetricCss, metricWidgetOptionStyles } from '../../widgets/MetricWidget/helpers';
import {
    getMultiMetricCss,
    multiMetricWidgetOptionStyles,
} from '../../widgets/MultiMetricWidget/helpers';
import { getMetricRollupCss } from '../../widgets/MetricRollupWidget/helpers';
import {
    axisBubbleChartCss,
    axisBubbleChartOptionStyles,
} from 'components/_charts/AxisBubbleChart/AxisBubbleChart';
import { alertOptionStyles } from 'src/widgets/AutoAlertsWidget/helpers';
import { barChartOptionStyles } from 'src/widgets/BarChartWidget/helpers';
import { BubbleChartCss, bubbleChartOptionStyles } from 'src/widgets/BubbleChartWidget/helpers';
import { comboChartOptionStyles, getComboChartCss } from 'src/widgets/ComboChartWidget/helpers';
import {
    customContentImageOptionStyles,
    customContentRichTextOptionStyles,
    customContentSocialPostOptionStyles,
    customContentVideoOptionStyles,
    getCustomContentCss,
} from 'src/widgets/CustomContentWidget/helpers';
import { dataTableOptionStyles, getDataTableCss } from 'src/widgets/DataTableWidget/helpers';
import {
    heatMapTableCss,
    heatMapTableOptionStyles,
} from '../../widgets/HeatMapTableChartWidget/helpers';
import { getLineChartCss, lineChartOptionStyles } from '../../widgets/LineChartWidget/helpers';
import {
    getRankedTableCss,
    rankedTableOptionStyles,
} from '../../widgets/RankedTableWidget/helpers';
import { shapeOptionStyles } from '../../widgets/ShapeWidget/helpers';
import { getSmallMultiplesPieChartCss } from '../../widgets/SmallMultiplesPieChartWidget/helpers';
import { getStackedBarChartCss } from '../../widgets/StackedBarChartWidget/helpers';
import { getSunburstCss } from '../../widgets/SunburstWidget/helpers';
import { getWordCloudCss } from '../../widgets/WordCloudWidget/helpers';
import { treeMapOptionStyles } from '../../widgets/TreeMapWidget/helpers';
import { worldHeatMapOptionStyles } from '../../widgets/WorldHeatMapWidget/helpers';
import { geoStreamWidgetOptionStyles } from '../../widgets/GeoStreamWidget/helpers';
import {
    getHyperlinkCss,
    hyperlinkWidgetOptionStyles,
} from 'src/widgets/HyperlinkButtonWidget/helpers';
import { speechBubbleOptionStyles } from '../../widgets/SpeechBubbleWidget/helpers';
import {
    dynamicTitleCss,
    dynamicTitleOptionStyles,
} from '../../widgets/DynamicTitleWidget/helpers';

// Compositions
import filmStripCompositions from '../../components/Post/FilmstripCompositions';
import cinematicCompositions from '../../components/Post/CinematicCompositions';
import singlePostCompositions from '../../components/Post/SinglePostCompositions';
import collageCompositions from '../../components/Post/CollageCompositions';
import gridCompositions from '../../components/Post/GridCompositions';
import tickerCompositions from '../../components/Post/TickerCompositions';
import waterfallCompositions from '../../components/Post/WaterfallCompositions';
import postCardCompositions from '../../components/Post/PostCardCompositions';

// Tunables
import { MultiMetricWidgetTunables } from '../../widgets/MultiMetricWidget/MultiMetricWidgetTunables';
import TreeMapTunables from '../../widgets/TreeMapWidget/TreeMapTunables';
import CustomContentIframeTunables from '../../widgets/CustomContentWidget/CustomContentIframeTunables';
import CustomContentImageTunables from '../../widgets/CustomContentWidget/CustomContentImageTunables';
import CustomContentVideoTunables from '../../widgets/CustomContentWidget/CustomContentVideoTunables';
import CustomContentSocialPostTunables from '../../widgets/CustomContentWidget/CustomContentSocialPostTunables';
import CustomContentRichTextTunables from '../../widgets/CustomContentWidget/CustomContentRichTextTunables';
import HyperlinkButtonTunables from '../../widgets/HyperlinkButtonWidget/HyperlinkButtonTunables';
import WordCloudTunables from '../../widgets/WordCloudWidget/WordCloudTunables';
import MetricWidgetTunables from '../../widgets/MetricWidget/MetricWidgetTunables';
import PieChartTunables from '../../widgets/PieChartWidget/PieChartTunables';
import BarChartTunables from '../../widgets/BarChartWidget/BarChartTunables';
import StackedBarChartTunables from './../../widgets/StackedBarChartWidget/StackedBarChartTunables';
import WorldHeatMapTunables from './../../widgets/WorldHeatMapWidget/WorldHeatMapTunables';
import GeoStreamTunables from './../../widgets/GeoStreamWidget/GeoStreamTunables';
import BubbleChartTunables from './../../widgets/BubbleChartWidget/BubbleChartTunables';
import LineChartTunables from '../../widgets/LineChartWidget/LineChartTunables';
import SmallMultiplesPieChartTunables from '../../widgets/SmallMultiplesPieChartWidget/SmallMultiplesPieChartTunables';
import FilmStripTunables from '../../widgets/PostsWindowWidget/FilmStrip/FilmStripTunables';
import SinglePostTunables from '../../widgets/PostsWindowWidget/SinglePost/SinglePostTunables';
import TickerTunables from '../../widgets/TickerWidget/TickerTunables';
import SmartTrendsTunables from '../../widgets/SmartTrendsWidget/SmartTrendsTunables';
import WaterfallTunables from '../../widgets/WaterfallWidget/WaterfallTunables';
import CinematicTunables from '../../widgets/PostsWindowWidget/Cinematic/CinematicTunables';
import CollageTunables from '../../widgets/PostsWidget/Collage/CollageTunables';
import GridPostTunables, {
    GridPostV2Tunables,
} from '../../widgets/PostsWidget/GridPost/GridPostTunables';
import PostCardTunables from '../../widgets/PostsWidget/PostCard/PostCardTunables';
import RunOfShowTunables from '../../widgets/PostsWidget/RunOfShow/RunOfShowTunables';
import AutoAlertsTunables from '../../widgets/AutoAlertsWidget/AutoAlertsTunables';
import ProfileTunables from '../../widgets/ProfileWidget/ProfileTunables';
import RankedTableTunables from '../../widgets/RankedTableWidget/RankedTableTunables';
import HeatMapTableChartTunables from '../../widgets/HeatMapTableChartWidget/HeatMapTableChartTunables';
import TwitterWidgetTunables from '../../widgets/TwitterTrendsWidget/TwitterTrendsTunables';
import SunburstTunables from '../../widgets/SunburstWidget/SunburstTunables';
import ComboChartTunables from '../../widgets/ComboChartWidget/ComboChartTunables';
import AxisBubbleChartTunables from '../../widgets/AxisBubbleChartWidget/AxisBubbleChartTunables';
import BubblePlotChartTunables from '../../widgets/BubblePlotChartWidget/BubblePlotChartTunables';
import { DynamicImageWidgetTunables } from 'src/widgets/DynamicImageWidget/DynamicImageWidgetTunables';
import CustomTableChartTunables from 'components/_charts/CustomTableChartWidget/CustomTableChartTunables';
import CustomTableRichTextTunables from '../../widgets/CustomContentWidget/CustomTableRichTextTunables';
import FunnelChartTunables from '../../widgets/FunnelChartWidget/FunnelChartTunables';
import ShapeTunables from '../../widgets/ShapeWidget/ShapeTunables';
import StoryCardTunables from '../../widgets/PostsWidget/StoryCard/StoryCardTunables';
import storyCardCompositions from 'components/Post/StoryCard/StoryCardCompositions';
import DataTableTunables from '../../widgets/DataTableWidget/DataTableWidgetTunables';
import SpeechBubbleTunables from '../../widgets/SpeechBubbleWidget/SpeechBubbleTunables';
import DynamicTitleTunables from '../../widgets/DynamicTitleWidget/DynamicTitleTunables';

// constants
import { FALLBACK_EMPTY } from 'utils/constants';
import { WidgetTypeWrapper } from 'services/WidgetTypes/WidgetTypeWrapper';

const AxisBubbleChartWidget = loadable(
    () =>
        import(
            /* webpackChunkName: "AxisBubbleChartWidget" */ '../../widgets/AxisBubbleChartWidget/AxisBubbleChartWidget'
        ),
    FALLBACK_EMPTY
);
const CustomTableChartWidget = loadable(
    () =>
        import(
            /* webpackChunkName: "CustomTableChartWidget" */ 'components/_charts/CustomTableChartWidget/CustomTableChartWidget'
        ),
    FALLBACK_EMPTY
);
const ComboChartWidget = loadable(
    () =>
        import(
            /* webpackChunkName: "ComboChartWidget" */ '../../widgets/ComboChartWidget/ComboChartWidget'
        ),
    FALLBACK_EMPTY
);
const ShapeWidget = loadable(
    () => import(/* webpackChunkName: "ShapeWidget" */ '../../widgets/ShapeWidget/ShapeWidget'),
    FALLBACK_EMPTY
);
const MetricRollupWidget = loadable(
    () =>
        import(
            /* webpackChunkName: "MetricRollupWidget" */ '../../widgets/MetricRollupWidget/MetricRollupWidget'
        ),
    FALLBACK_EMPTY
);
const AutoAlertsWidget = loadable(
    () =>
        import(
            /* webpackChunkName: "AutoAlertsWidget" */ '../../widgets/AutoAlertsWidget/AutoAlertsWidget'
        ),
    FALLBACK_EMPTY
);
const FunnelChartWidget = loadable(
    () =>
        import(
            /* webpackChunkName: "FunnelChartWidget" */ '../../widgets/FunnelChartWidget/FunnelChartWidget'
        ),
    FALLBACK_EMPTY
);
const DynamicImageWidget = loadable(
    () =>
        import(
            /* webpackChunkName: "DynamicImageWidget" */ '../../widgets/DynamicImageWidget/DynamicImageWidget'
        ),
    FALLBACK_EMPTY
);
const SunburstWidget = loadable(
    () =>
        import(
            /* webpackChunkName: "SunburstWidget" */ '../../widgets/SunburstWidget/SunburstWidget'
        ),
    FALLBACK_EMPTY
);
const WaterfallWidget = loadable(
    () =>
        import(
            /* webpackChunkName: "WaterfallWidget" */ '../../widgets/WaterfallWidget/WaterfallWidget'
        ),
    FALLBACK_EMPTY
);
const TickerWidget = loadable(
    () => import(/* webpackChunkName: "TickerWidget" */ '../../widgets/TickerWidget/TickerWidget'),
    FALLBACK_EMPTY
);
const CustomContentWidget = loadable(
    () =>
        import(
            /* webpackChunkName: "CustomContentWidget" */ '../../widgets/CustomContentWidget/CustomContentWidget'
        ),
    FALLBACK_EMPTY
);
const HeatMapTableChartWidget = loadable(
    () =>
        import(
            /* webpackChunkName: "HeatMapTableChartWidget" */ '../../widgets/HeatMapTableChartWidget/HeatMapTableChartWidget'
        ),
    FALLBACK_EMPTY
);
const WorldHeatMapWidget = loadable(
    () =>
        import(
            /* webpackChunkName: "WorldHeatMapWidget" */ '../../widgets/WorldHeatMapWidget/WorldHeatMapWidget'
        ),
    FALLBACK_EMPTY
);
const GeoStreamWidget = loadable(
    () =>
        import(
            /* webpackChunkName: "GeoStreamWidget" */ '../../widgets/GeoStreamWidget/GeoStreamWidget'
        ),
    FALLBACK_EMPTY
);
const TreeMapWidget = loadable(
    () =>
        import(/* webpackChunkName: "TreeMapWidget" */ '../../widgets/TreeMapWidget/TreeMapWidget'),
    FALLBACK_EMPTY
);
const ProfileWidget = loadable(
    () =>
        import(/* webpackChunkName: "ProfileWidget" */ '../../widgets/ProfileWidget/ProfileWidget'),
    FALLBACK_EMPTY
);
const DataTableWidget = loadable(
    () =>
        import(
            /* webpackChunkName: "DataTableWidget" */ '../../widgets/DataTableWidget/DataTableWidget'
        ),
    FALLBACK_EMPTY
);
const RankedTableWidget = loadable(
    () =>
        import(
            /* webpackChunkName: "RankedTableWidget" */ '../../widgets/RankedTableWidget/RankedTableWidget'
        ),
    FALLBACK_EMPTY
);
const RankedListWidget = loadable(
    () =>
        import(
            /* webpackChunkName: "RankedListWidget" */ '../../widgets/RankedListWidget/RankedListWidget'
        ),
    FALLBACK_EMPTY
);
const MultiMetricWidget = loadable(
    () =>
        import(
            /* webpackChunkName: "MultiMetricWidget" */ '../../widgets/MultiMetricWidget/MultiMetricWidget'
        ),
    FALLBACK_EMPTY
);
const MetricWidget = loadable(
    () => import(/* webpackChunkName: "MetricWidget" */ '../../widgets/MetricWidget/MetricWidget'),
    FALLBACK_EMPTY
);
const BubbleChartWidget = loadable(
    () =>
        import(
            /* webpackChunkName: "BubbleChartWidget" */ '../../widgets/BubbleChartWidget/BubbleChartWidget'
        ),
    FALLBACK_EMPTY
);
const WordCloudWidget = loadable(
    () =>
        import(
            /* webpackChunkName: "WordCloudWidget" */ '../../widgets/WordCloudWidget/WordCloudWidget'
        ),
    FALLBACK_EMPTY
);
const LineChartWidget = loadable(
    () =>
        import(
            /* webpackChunkName: "LineChartWidget" */ '../../widgets/LineChartWidget/LineChartWidget'
        ),
    FALLBACK_EMPTY
);
const BarChartWidget = loadable(
    () =>
        import(
            /* webpackChunkName: "BarChartWidget" */ '../../widgets/BarChartWidget/BarChartWidget'
        ),
    FALLBACK_EMPTY
);
const PostsWindowWidget = loadable(
    () =>
        import(
            /* webpackChunkName: "PostsWindowWidget" */ '../../widgets/PostsWindowWidget/PostsWindowWidget'
        ),
    FALLBACK_EMPTY
);
const PostsWidget = loadable(
    () => import(/* webpackChunkName: "PostsWidget" */ '../../widgets/PostsWidget/PostsWidget'),
    FALLBACK_EMPTY
);
const StackedBarChartWidget = loadable(
    () =>
        import(
            /* webpackChunkName: "StackedBarChartWidget" */ '../../widgets/StackedBarChartWidget/StackedBarChartWidget'
        ),
    FALLBACK_EMPTY
);
const PieChartWidget = loadable(
    () =>
        import(
            /* webpackChunkName: "PieChartWidget" */ '../../widgets/PieChartWidget/PieChartWidget'
        ),
    FALLBACK_EMPTY
);
const SmallMultiplesPieChartWidget = loadable(
    () =>
        import(
            /* webpackChunkName: "SmallMultiplesPieChartWidget" */ '../../widgets/SmallMultiplesPieChartWidget/SmallMultiplesPieChartWidget'
        ),
    FALLBACK_EMPTY
);
const BubblePlotChartWidget = loadable(
    () =>
        import(
            /* webpackChunkName: "BubblePlotChartWidget" */ '../../widgets/BubblePlotChartWidget/BubblePlotChartWidget'
        ),
    FALLBACK_EMPTY
);
const MetricValueWidget = loadable(
    () =>
        import(
            /* webpackChunkName: "MetricValueWidget" */ '../../widgets/MetricValueWidget/MetricValueWidget'
        ),
    FALLBACK_EMPTY
);
const MetricValueLockupWidget = loadable(
    () =>
        import(
            /* webpackChunkName: "MetricValueLockupWidget" */ '../../widgets/MetricValueLockupWidget/MetricValueLockupWidget'
        ),
    FALLBACK_EMPTY
);
const TwitterTrendsWidget = loadable(
    () =>
        import(
            /* webpackChunkName: "TwitterTrendsWidget" */ '../../widgets/TwitterTrendsWidget/TwitterTrendsWidget'
        ),
    FALLBACK_EMPTY
);
const GoogleTrendsWidget = loadable(
    () =>
        import(
            /* webpackChunkName: "GoogleTrendsWidget" */ '../../widgets/GoogleTrendsWidget/GoogleTrendsWidget'
        ),
    FALLBACK_EMPTY
);
const CountUpWidget = loadable(
    () =>
        import(/* webpackChunkName: "CountUpWidget" */ '../../widgets/CountUpWidget/CountUpWidget'),
    FALLBACK_EMPTY
);
const LegendWidget = loadable(
    () => import(/* webpackChunkName: "LegendWidget" */ '../../widgets/LegendWidget/LegendWidget'),
    FALLBACK_EMPTY
);
const HyperlinkButtonWidget = loadable(
    () =>
        import(
            /* webpackChunkName: "HyperlinkButtonWidget" */ '../../widgets/HyperlinkButtonWidget/HyperlinkButtonWidget'
        ),
    FALLBACK_EMPTY
);
const SpeechBubbleWidget = loadable(
    () =>
        import(
            /* webpackChunkName: "SpeechBubbleWidget" */ '../../widgets/SpeechBubbleWidget/SpeechBubbleWidget'
        ),
    FALLBACK_EMPTY
);
const DynamicTitleWidget = loadable(
    () =>
        import(
            /* webpackChunkName: "DynamicTitleWidget" */ '../../widgets/DynamicTitleWidget/DynamicTitleWidget'
        ),
    FALLBACK_EMPTY
);

export default class WidgetTypeService extends IndexedList<WidgetTypeWrapper> {
    constructor() {
        super();

        // POSTS
        this.add(
            new WidgetTypeWrapper({
                id: 'posts',
                name: 'Posts',
                dataType: 'posts',
                component: PostsWidget,
                supportsWidgetLabelDateRange: true,
                tags: ['posts'],
                minProjections: 0,
                maxProjections: 15,
                minGroupBys: 0,
                maxGroupBys: 0,
                supportsPanelPadding: true,
                optionsType: PostsWidgetOptionsImpl,
                supportsWidgetLabel: true,
                supportsPreviousPeriod: false,
                supportsAnimationTypes: ['fade', 'drop', 'lift', 'slide_in_out', 'scale_in_out'],
                supportsAnimationTunables: true,
                supportsAnimation: true,
                supportsAnimationDelay: true,
                supportsAnimationLength: true,
                supportsEngagement: true,
                supportsUserGroupControls: true,
                supportsUserGroupPosition: true,
                supportsTimeStamp: true,
                supportsImagePosts: true,
                supportsImageBackgroundSize: true,
                supportsTextPosts: true,
                supportsTextFormat: true,
                supportsTextEntity: true,
                supportsTextOnlyBackgroundColor: true,
                supportsMainColor: false,
                supportsThemeTypography: true,
                thumbSrc: 'template-post-engagement-6.jpg',
                computeOptionStyles: postOptionStyles,
                getStyles: getPostCss,
                supportsShufflePosts: true,
                supportsRemoveDuplicates: true,
                supportsRemoveSensitivePosts: true,
                supportsUseImagePlaceholder: true,
                supportsLinkPreview: false,
                supportsIncludeQuoteTweets: true,
                supportsQuotedTweetView: false,
            })
        );

        // POSTS - Collage
        this.add(
            new WidgetTypeWrapper({
                id: 'postsCollage',
                idTemplate: 'collage',
                name: 'Posts',
                dataType: 'posts',
                component: PostsWidget,
                optionsType: PostsWidgetCollageOptionsImpl,
                supportsPostCompositionTypes: ['a', 'b'],
                supportsWidgetLabelDateRange: true,
                tags: ['posts'],
                minProjections: 0,
                maxProjections: 15,
                minGroupBys: 0,
                maxGroupBys: 0,
                supportsPanelPadding: true,
                supportsWidgetLabel: true,
                supportsPreviousPeriod: false,
                supportsAnimationTypes: ['fade', 'drop', 'lift', 'slide_in_out', 'scale_in_out'],
                supportsAnimationTunables: true,
                supportsAnimation: true,
                supportsAnimationDelay: true,
                supportsAnimationLength: true,
                supportsEngagement: true,
                supportsUserGroupControls: true,
                supportsUserGroupPosition: true,
                supportsTimeStamp: true,
                supportsImagePosts: true,
                supportsImageBackgroundSize: true,
                supportsTextPosts: true,
                supportsTextFormat: true,
                supportsTextEntity: true,
                supportsTextOnlyBackgroundColor: true,
                supportsMainColor: false,
                supportsThemeTypography: true,
                supportsUserGroupGradient: true,
                thumbSrc: 'template-post-engagement-6.jpg',
                computeOptionStyles: postOptionStyles,
                getStyles: getPostCss,
                // supportsUserGroupLayoutTypes: true,
                getPostComposition: collageCompositions,
                supportsShufflePosts: true,
                supportsRemoveDuplicates: true,
                supportsRemoveSensitivePosts: true,
                supportsUseImagePlaceholder: true,
                supportsLinkPreview: false,
                supportsIncludeQuoteTweets: true,
                supportsQuotedTweetView: false,
                tunables: CollageTunables,
            })
        );

        // POSTS - grid - everything thats uses the post buckets that isn't collage
        this.add(
            new WidgetTypeWrapper({
                id: 'postsGrid',
                idTemplate: 'post-grid',
                name: 'Posts',
                dataType: 'posts',
                component: PostsWidget,
                optionsType: PostsWidgetGridOptionsImpl,
                supportsPostCompositionTypes: ['a', 'b'],
                supportsWidgetLabelDateRange: true,
                tags: ['posts'],
                minProjections: 0,
                maxProjections: 15,
                minGroupBys: 0,
                maxGroupBys: 3,
                supportsPanelPadding: true,
                supportsWidgetLabel: true,
                supportsPreviousPeriod: false,
                supportsAnimationTypes: ['fade', 'drop', 'lift', 'slide_in_out', 'scale_in_out'],
                supportsAnimationTunables: true,
                supportsAnimation: true,
                supportsAnimationDelay: true,
                supportsAnimationLength: true,
                supportsEngagement: true,
                supportsUserGroupControls: true,
                supportsUserGroupPosition: true,
                supportsTimeStamp: true,
                supportsImagePosts: true,
                supportsImageBackgroundSize: true,
                supportsTextPosts: true,
                supportsTextFormat: true,
                supportsTextEntity: true,
                supportsTextOnlyBackgroundColor: true,
                supportsMainColor: false,
                supportsThemeTypography: true,
                thumbSrc: 'template-post-engagement-6.jpg',
                computeOptionStyles: postOptionStyles,
                getStyles: getPostCss,
                // supportsUserGroupLayoutTypes: true,
                getPostComposition: gridCompositions,
                supportsShufflePosts: true,
                supportsRemoveDuplicates: true,
                supportsRemoveSensitivePosts: true,
                supportsUseImagePlaceholder: true,
                supportsLinkPreview: true,
                supportsIncludeQuoteTweets: true,
                supportsQuotedTweetView: true,
                tunables: GridPostTunables,
            })
        );

        // POSTS - grid - everything thats uses the post buckets that isn't collage
        this.add(
            new WidgetTypeWrapper({
                id: 'postsGridV2',
                idTemplate: 'post-grid-v2',
                name: 'Posts Grid',
                dataType: 'posts',
                component: PostsWidget,
                optionsType: PostsWidgetGridV2OptionsImpl,
                supportsPostCompositionTypes: ['a', 'b'],
                supportsWidgetLabelDateRange: true,
                tags: ['posts'],
                minProjections: 0,
                maxProjections: 15,
                minGroupBys: 0,
                maxGroupBys: 3,
                supportsPanelPadding: true,
                supportsWidgetLabel: true,
                supportsPreviousPeriod: false,
                supportsAnimationTypes: ['fade', 'drop', 'lift', 'slide_in_out', 'scale_in_out'],
                supportsAnimationTunables: true,
                supportsAnimation: true,
                supportsAnimationDelay: true,
                supportsAnimationLength: true,
                supportsEngagement: true,
                supportsUserGroupControls: true,
                supportsUserGroupPosition: true,
                supportsTimeStamp: true,
                supportsImagePosts: true,
                supportsImageBackgroundSize: true,
                supportsTextPosts: true,
                supportsTextFormat: true,
                supportsTextEntity: true,
                supportsTextOnlyBackgroundColor: true,
                supportsMainColor: false,
                supportsThemeTypography: true,
                thumbSrc: 'template-post-engagement-6.jpg',
                computeOptionStyles: postOptionStyles,
                getStyles: getPostCss,
                // supportsUserGroupLayoutTypes: true,
                getPostComposition: gridCompositions,
                supportsShufflePosts: true,
                supportsRemoveDuplicates: true,
                supportsRemoveSensitivePosts: true,
                supportsUseImagePlaceholder: true,
                supportsLinkPreview: true,
                supportsIncludeQuoteTweets: true,
                supportsQuotedTweetView: true,
                tunables: GridPostV2Tunables,
            })
        );

        // POSTS FILM STRIP
        this.add(
            new WidgetTypeWrapper({
                id: 'postsFilmStrip',
                idTemplate: 'filmstrip',
                name: 'Posts',
                dataType: 'posts',
                component: PostsWindowWidget,
                supportsWidgetLabelDateRange: true,
                tags: ['posts'],
                minProjections: 0,
                maxProjections: 15,
                minGroupBys: 0,
                maxGroupBys: 3,
                supportsPanelPadding: true,
                supportsPostCompositionTypes: ['a', 'b', 'c'],
                optionsType: PostsWidgetFilmStripOptionsImpl,
                supportsWidgetLabel: true,
                supportsPreviousPeriod: false,
                supportsAnimationTypes: [
                    'slide_in_out',
                    'slide_reverse',
                    'in_out',
                    'carousel',
                    'continuous_in_out',
                    'continuous_reverse',
                ],
                supportsAnimationTunables: true,
                supportsAnimation: true,
                supportsAnimationDelay: true,
                supportsAnimationLength: true,
                supportsEngagement: true,
                supportsUserGroupControls: true,
                supportsUserGroupPosition: true,
                supportsTimeStamp: true,
                supportsImagePosts: true,
                supportsImageBackgroundSize: true,
                supportsTextPosts: true,
                supportsSpacingMax: 25,
                supportsTextFormat: true,
                supportsTextEntity: true,
                supportsTextOnlyBackgroundColor: true,
                supportsImagePostBackgroundColor: true,
                supportsSecondaryImageOverlay: true,
                supportsMainColor: false,
                supportsThemeTypography: true,
                thumbSrc: 'template-filmstrip.jpg',
                computeOptionStyles: postOptionStyles,
                getStyles: getPostCss,
                supportsUserGroupLayoutTypes: true,
                getPostComposition: filmStripCompositions,
                supportsShufflePosts: true,
                supportsRemoveDuplicates: true,
                supportsRemoveSensitivePosts: true,
                supportsUseImagePlaceholder: true,
                supportsLinkPreview: true,
                supportsIncludeQuoteTweets: true,
                supportsQuotedTweetView: true,
                tunables: FilmStripTunables,
                // Used to override formatting options
                requestOverrides: (data: any) => {
                    if (!data || !data.postRequests || !data.postRequests.length) {
                        return;
                    }

                    const request = data.postRequests[0];
                    if (request.format) {
                        const options = request.format.options;
                        if (options && options.padding && options.padding >= 3) {
                            options.offsetCenter = options.padding - 1;
                        }
                    }
                },
            })
        );

        // POSTS CINEMATIC
        this.add(
            new WidgetTypeWrapper({
                id: 'postsCinematic',
                idTemplate: 'cinematic',
                name: 'Posts',
                dataType: 'posts',
                component: PostsWindowWidget,
                supportsWidgetLabelDateRange: true,
                tags: ['posts'],
                minProjections: 0,
                maxProjections: 15,
                minGroupBys: 0,
                maxGroupBys: 0,
                supportsPanelPadding: true,
                supportsPostCompositionTypes: ['a', 'b', 'c'],
                optionsType: PostsWidgetCinematicOptionsImpl,
                supportsWidgetLabel: true,
                supportsPreviousPeriod: false,
                supportsAnimation: true,
                supportsAnimationTypes: [
                    'ken_burns',
                    'wipe_rectangle',
                    'wipe_rectangle_legacy',
                    'wipe_circle',
                    'wipe_polygon',
                    'adamwest',
                    'itsatrap',
                ],
                supportsAnimationTunables: true,
                supportsAnimationDelay: true,
                supportsAnimationLength: true,
                supportsAnimationLengthMax: 5000,
                supportsEngagement: true,
                supportsUserGroupControls: true,
                supportsUserGroupPosition: true,
                supportsTimeStamp: true,
                supportsImagePosts: true,
                supportsImageBackgroundSize: true,
                supportsTextPosts: true,
                supportsTextFormat: true,
                supportsTextEntity: true,
                supportsSecondaryImageOverlay: true,
                supportsTextOnlyBackgroundColor: true,
                supportsMainColor: false,
                supportsThemeTypography: true,
                thumbSrc: 'template-cinematic.jpg',
                computeOptionStyles: postOptionStyles,
                getStyles: getPostCss,
                supportsUserGroupLayoutTypes: true,
                getPostComposition: cinematicCompositions,
                supportsShufflePosts: true,
                supportsRemoveDuplicates: true,
                supportsRemoveSensitivePosts: true,
                supportsUseImagePlaceholder: true,
                supportsLinkPreview: false,
                supportsIncludeQuoteTweets: true,
                supportsQuotedTweetView: true,
                tunables: CinematicTunables,
            })
        );

        // POSTS TYPE POSTER
        this.add(
            new WidgetTypeWrapper({
                id: 'postsTypePoster',
                idTemplate: 'typeposter',
                name: 'Posts',
                dataType: 'posts',
                component: PostsWindowWidget,
                supportsWidgetLabelDateRange: true,
                // tags: ['posts'],
                minProjections: 0,
                maxProjections: 15,
                minGroupBys: 0,
                maxGroupBys: 0,
                supportsPanelPadding: true,
                supportsPostCompositionTypes: ['a', 'b'],
                optionsType: PostsWidgetTypePosterOptionsImpl,
                supportsWidgetLabel: true,
                supportsPreviousPeriod: false,
                supportsAnimationTypes: ['fade', 'drop', 'lift', 'slide_in_out', 'scale_in_out'],
                supportsAnimationTunables: true,
                supportsAnimation: true,
                supportsAnimationDelay: true,
                supportsAnimationLength: true,
                supportsEngagement: true,
                supportsUserGroupControls: true,
                supportsUserGroupPosition: true,
                supportsTimeStamp: true,
                supportsImagePosts: true,
                supportsImageBackgroundSize: true,
                supportsTextPosts: true,
                supportsTextFormat: true,
                supportsTextEntity: true,
                supportsMainColor: true,
                supportsThemeTypography: true,
                computeOptionStyles: postOptionStyles,
                getStyles: getPostCss,
                supportsShufflePosts: true,
                supportsRemoveDuplicates: true,
                supportsRemoveSensitivePosts: true,
                supportsUseImagePlaceholder: true,
                supportsIncludeQuoteTweets: true,
                supportsQuotedTweetView: true,
            })
        );

        // POSTS SINGLE POST
        this.add(
            new WidgetTypeWrapper({
                id: 'postsSinglePost',
                idTemplate: 'single-post',
                name: 'Posts',
                dataType: 'posts',
                component: PostsWindowWidget,
                supportsWidgetLabelDateRange: true,
                tags: ['posts'],
                minProjections: 0,
                maxProjections: 15,
                minGroupBys: 0,
                maxGroupBys: 0,
                supportsPanelPadding: true,
                supportsPostCompositionTypes: ['a', 'b', 'c'],
                optionsType: PostsWidgetSingleOptionsImpl,
                supportsWidgetLabel: true,
                supportsPreviousPeriod: false,
                supportsAnimationTypes: ['slide_in_out', 'rotate', 'slide_left_right'],
                supportsAnimationTunables: true,
                supportsAnimation: true,
                supportsAnimationDelay: true,
                supportsAnimationLength: true,
                supportsEngagement: true,
                supportsUserGroupControls: true,
                supportsUserGroupPosition: true,
                supportsTimeStamp: true,
                supportsImagePosts: true,
                supportsImageBackgroundSize: true,
                supportsTextPosts: true,
                supportsTextFormat: true,
                supportsTextEntity: true,
                supportsTextOnlyBackgroundColor: true,
                supportsImagePostBackgroundColor: true,
                supportsMainColor: false,
                supportsThemeTypography: true,
                supportsComments: true,
                thumbSrc: 'template-single-post.jpg',
                computeOptionStyles: postOptionStyles,
                getStyles: getPostCss,
                supportsUserGroupLayoutTypes: true,
                getPostComposition: singlePostCompositions,
                supportsShufflePosts: true,
                supportsRemoveDuplicates: true,
                supportsRemoveSensitivePosts: true,
                supportsUseImagePlaceholder: true,
                supportsLinkPreview: true,
                supportsIncludeQuoteTweets: true,
                supportsQuotedTweetView: true,
                tunables: SinglePostTunables,
            })
        );
        // SMARTTRENDS
        this.add(
            new WidgetTypeWrapper({
                id: 'smartTrends',
                idTemplate: 'smart-trends',
                name: 'Smart Trends',
                dataType: 'posts',
                component: TickerWidget,
                supportsWidgetLabelDateRange: true,
                tags: ['posts'],
                minProjections: 0,
                maxProjections: 0,
                minGroupBys: 0,
                maxGroupBys: 1,
                supportsPanelPadding: true,
                optionsType: TickerWidgetOptionsImpl,
                supportsWidgetLabel: true,
                supportsPreviousPeriod: false,
                supportsAnimation: true,
                supportsAnimationDelay: false,
                supportsAnimationLength: false,
                supportsAnimationTunables: true,
                supportsAnimationTypes: ['slide_in_out', 'continuous_in_out'],
                supportsTextOnlyBackgroundColor: false,
                supportsImagePostBackgroundColor: false,
                supportsMainColor: false,
                supportsThemeTypography: false,
                supportsPostCycling: false,
                supportsColorPalette: false,
                supportsStyles: false,
                thumbSrc: 'template-ticker.jpg',
                // computeOptionStyles: postOptionStyles,
                // getStyles: getPostCss,
                supportsShufflePosts: false,
                supportsRemoveDuplicates: false,
                supportsRemoveSensitivePosts: false,
                supportsUseImagePlaceholder: false,
                supportsLinkPreview: true,
                supportsIncludeQuoteTweets: false,
                supportsQuotedTweetView: false,
                tunables: SmartTrendsTunables,
            })
        );

        // TICKER
        this.add(
            new WidgetTypeWrapper({
                id: 'postsTicker',
                idTemplate: 'ticker',
                name: 'Ticker',
                dataType: 'posts',
                component: TickerWidget,
                supportsWidgetLabelDateRange: true,
                tags: ['posts'],
                minProjections: 0,
                maxProjections: 0,
                minGroupBys: 0,
                maxGroupBys: 1,
                supportsPanelPadding: true,
                optionsType: TickerWidgetOptionsImpl,
                supportsWidgetLabel: true,
                supportsPreviousPeriod: false,
                supportsAnimation: true,
                supportsAnimationDelay: false,
                supportsAnimationLength: false,
                supportsAnimationTunables: true,
                supportsAnimationTypes: ['slide_in_out', 'continuous_in_out'],
                supportsEngagement: true,
                supportsUserGroupControls: true,
                supportsUserGroupPosition: true,
                supportsTimeStamp: true,
                supportsImagePosts: true,
                supportsImageBackgroundSize: true,
                supportsTextPosts: true,
                supportsTextFormat: true,
                supportsTextEntity: true,
                supportsTextOnlyBackgroundColor: true,
                supportsImagePostBackgroundColor: true,
                supportsMainColor: false,
                supportsThemeTypography: true,
                supportsPostCycling: true,
                thumbSrc: 'template-ticker.jpg',
                computeOptionStyles: postOptionStyles,
                getStyles: getPostCss,
                getPostComposition: tickerCompositions,
                supportsUserGroupLayoutTypes: false,
                supportsShufflePosts: true,
                supportsRemoveDuplicates: true,
                supportsRemoveSensitivePosts: true,
                supportsUseImagePlaceholder: true,
                supportsLinkPreview: true,
                supportsIncludeQuoteTweets: true,
                supportsQuotedTweetView: false,
                tunables: TickerTunables,
            })
        );

        // WATERFALL
        this.add(
            new WidgetTypeWrapper({
                id: 'postsWaterfall',
                idTemplate: 'waterfall',
                name: 'Waterfall',
                dataType: 'posts',
                component: WaterfallWidget,
                supportsWidgetLabelDateRange: true,
                tags: ['posts'],
                minProjections: 0,
                maxProjections: 0,
                minGroupBys: 0,
                maxGroupBys: 0,
                supportsPanelPadding: true,
                optionsType: WaterfallWidgetOptionsImpl,
                supportsWidgetLabel: true,
                supportsPreviousPeriod: false,
                supportsAnimation: true,
                supportsAnimationDelay: true,
                supportsAnimationLength: false,
                supportsAnimationTunables: false,
                supportsEngagement: true,
                supportsUserGroupControls: true,
                supportsUserGroupPosition: true,
                supportsTimeStamp: true,
                supportsImagePosts: true,
                supportsImageBackgroundSize: true,
                supportsTextPosts: true,
                supportsTextFormat: true,
                supportsTextEntity: true,
                supportsTextOnlyBackgroundColor: true,
                supportsImagePostBackgroundColor: true,
                supportsMainColor: false,
                supportsThemeTypography: true,
                supportsPostCycling: true,
                thumbSrc: 'template-waterfall.jpg',
                computeOptionStyles: postOptionStyles,
                getStyles: getPostCss,
                getPostComposition: waterfallCompositions,
                supportsUserGroupLayoutTypes: false,
                supportsShufflePosts: true,
                supportsRemoveDuplicates: true,
                supportsRemoveSensitivePosts: true,
                supportsUseImagePlaceholder: true,
                supportsLinkPreview: false,
                supportsIncludeQuoteTweets: true,
                supportsQuotedTweetView: true,
                tunables: WaterfallTunables,
            })
        );
        // POST CARD
        this.add(
            new WidgetTypeWrapper({
                id: 'postCard',
                idTemplate: 'post-card',
                name: 'Post Card',
                dataType: 'posts',
                component: PostsWidget,
                supportsWidgetLabelDateRange: true,
                tags: ['posts'],
                minProjections: 0,
                maxProjections: 6,
                minGroupBys: 0,
                maxGroupBys: 6,
                supportsPanelPadding: true,
                optionsType: PostCardWidgetOptionsImpl,
                supportsWidgetLabel: true,
                supportsPreviousPeriod: false,
                supportsAnimationDelay: true,
                supportsAnimationLength: false,
                supportsAnimationTypes: [
                    'fade',
                    'drop',
                    'lift',
                    'slide_in_out',
                    'scale_in_out',
                    'continuous_in_out',
                    'continuous_reverse',
                ],
                supportsAnimationTunables: true,
                supportsAnimation: true,
                supportsAnimationOrientation: true,
                supportsEngagement: true,
                supportsUserGroupControls: true,
                supportsUserGroupPosition: true,
                supportsTimeStamp: true,
                supportsImagePosts: true,
                supportsImageBackgroundSize: true,
                supportsTextPosts: true,
                supportsTextFormat: true,
                supportsTextEntity: true,
                supportsTextOnlyBackgroundColor: true,
                supportsImagePostBackgroundColor: true,
                supportsMainColor: false,
                supportsThemeTypography: true,
                thumbSrc: 'template-post-card.jpg',
                computeOptionStyles: postOptionStyles,
                getStyles: getPostCss,
                getPostComposition: postCardCompositions,
                supportsUserGroupLayoutTypes: false,
                supportsShufflePosts: true,
                supportsRemoveDuplicates: true,
                supportsRemoveSensitivePosts: true,
                supportsUseImagePlaceholder: true,
                supportsLinkPreview: true,
                supportsIncludeQuoteTweets: true,
                supportsQuotedTweetView: true,
                tunables: PostCardTunables,
            })
        );

        // STORY CARD
        this.add(
            new WidgetTypeWrapper({
                id: 'storyCard',
                idTemplate: 'story-card',
                name: 'Story Card',
                dataType: 'posts',
                component: PostsWidget,
                supportsWidgetLabelDateRange: true,
                tags: ['posts'],
                minProjections: 0,
                maxProjections: 6,
                minGroupBys: 0,
                maxGroupBys: 0,
                supportsPanelPadding: true,
                optionsType: StoryCardWidgetOptionsImpl,
                supportsWidgetLabel: true,
                supportsPreviousPeriod: false,
                supportsAnimationDelay: true,
                supportsAnimationLength: false,
                supportsAnimationTypes: [
                    'fade',
                    'drop',
                    'lift',
                    'slide_in_out',
                    'scale_in_out',
                    'continuous_in_out',
                    'continuous_reverse',
                ],
                supportsAnimationTunables: true,
                supportsAnimation: true,
                supportsAnimationOrientation: true,
                supportsEngagement: true,
                supportsUserGroupControls: true,
                supportsUserGroupPosition: true,
                supportsTimeStamp: true,
                supportsImagePosts: true,
                supportsImageBackgroundSize: true,
                supportsTextPosts: true,
                supportsTextFormat: true,
                supportsTextEntity: true,
                supportsTextOnlyBackgroundColor: true,
                supportsImagePostBackgroundColor: true,
                supportsMainColor: false,
                supportsThemeTypography: true,
                thumbSrc: 'template-post-card.jpg',
                computeOptionStyles: postOptionStyles,
                getStyles: getPostCss,
                getPostComposition: storyCardCompositions,
                supportsUserGroupLayoutTypes: false,
                supportsShufflePosts: true,
                supportsRemoveDuplicates: true,
                supportsUseImagePlaceholder: true,
                tunables: StoryCardTunables,
            })
        );

        // Run of show
        this.add(
            new WidgetTypeWrapper({
                id: 'runOfShow',
                idTemplate: 'run-of-show',
                name: 'Run of Show',
                dataType: 'posts',
                component: PostsWidget,
                supportsWidgetLabelDateRange: true,
                tags: ['posts'],
                minProjections: 0,
                maxProjections: 6,
                minGroupBys: 0,
                maxGroupBys: 6,
                supportsPanelPadding: true,
                optionsType: RunOfShowWidgetOptionsImpl,
                supportsWidgetLabel: true,
                supportsPreviousPeriod: false,
                supportsAnimation: true,
                supportsAnimationDelay: true,
                supportsAnimationLength: false,
                supportsAnimationTypes: ['fade', 'drop', 'lift', 'slide_in_out', 'scale_in_out'],
                supportsAnimationTunables: true,
                supportsEngagement: true,
                supportsUserGroupControls: true,
                supportsUserGroupPosition: true,
                supportsTimeStamp: true,
                supportsImagePosts: true,
                supportsImageBackgroundSize: true,
                supportsTextPosts: true,
                supportsTextFormat: true,
                supportsTextEntity: true,
                supportsTextOnlyBackgroundColor: true,
                supportsImagePostBackgroundColor: true,
                supportsMainColor: false,
                supportsThemeTypography: true,
                thumbSrc: 'template-post-card.jpg',
                computeOptionStyles: postOptionStyles,
                getStyles: getPostCss,
                getPostComposition: postCardCompositions,
                supportsUserGroupLayoutTypes: false,
                supportsShufflePosts: true,
                supportsRemoveDuplicates: true,
                supportsRemoveSensitivePosts: true,
                supportsUseImagePlaceholder: true,
                supportsLinkPreview: true,
                supportsIncludeQuoteTweets: true,
                supportsQuotedTweetView: true,
                tunables: RunOfShowTunables,
            })
        );

        // AUTO - Alerts
        this.add(
            new WidgetTypeWrapper({
                id: 'autoAlerts',
                idTemplate: 'auto-alerts',
                name: 'AutoAlerts',
                dataType: 'posts',
                component: AutoAlertsWidget,
                optionsType: AutoAlertsWidgetOptionsImpl,
                // supportsPostCompositionTypes: ['a', 'b'],
                supportsWidgetLabelDateRange: true,
                tags: ['alerts'],
                minProjections: 0,
                maxProjections: 0,
                minGroupBys: 0,
                maxGroupBys: 0,
                supportsWidgetLabel: true,
                supportsAnimationTypes: ['drop'],
                supportsAnimationTunables: true,
                supportsAnimation: true,
                supportsAnimationDelay: true,
                supportsAnimationLength: true,
                supportsTimeStamp: true,
                supportsThemeTypography: true,
                supportsAutoAlerts: true,
                thumbSrc: 'template-post-engagement-6.jpg',
                computeOptionStyles: alertOptionStyles,
                getStyles: getPostCss,
                tunables: AutoAlertsTunables,
            })
        );

        // ComboChart
        this.add(
            new WidgetTypeWrapper({
                id: 'comboChart',
                idTemplate: 'combo-chart',
                name: 'Combo',
                dataType: 'analytics',
                component: ComboChartWidget,
                supportsWidgetLabelDateRange: true,
                tags: ['analytics'],
                minProjections: 1,
                maxProjections: 5,
                minGroupBys: 1,
                maxGroupBys: 2,
                supportsPanelPadding: true,
                supportsColorPalette: true,
                optionsType: ComboChartWidgetOptionsImpl,
                supportsWidgetLabel: true,
                supportsPreviousPeriod: false,
                supportsAnimationTypes: ['fade', 'drop', 'lift', 'slide_in_out', 'scale_in_out'],
                supportsAnimationTunables: true,
                supportsAnimation: true,
                supportsAnimationDelay: true,
                supportsAnimationLength: true,
                supportsMainColor: false,
                supportsThemeTypography: true,
                thumbSrc: 'template-combo-chart.jpg',
                computeOptionStyles: comboChartOptionStyles,
                getStyles: getComboChartCss,
                tunables: ComboChartTunables,
                supportsSortBy: true,
            })
        );

        // dualAxis
        this.add(
            new WidgetTypeWrapper({
                id: 'dualAxis',
                idTemplate: 'dual-axis',
                name: 'Dual axis',
                dataType: 'analytics',
                component: ComboChartWidget,
                supportsColorPalette: true,
                supportsWidgetLabelDateRange: true,
                tags: ['analytics'],
                minProjections: 2,
                maxProjections: 2,
                minGroupBys: 1,
                maxGroupBys: 1,
                supportsPanelPadding: true,
                optionsType: DualAxisWidgetOptionsImpl,
                supportsWidgetLabel: true,
                supportsPreviousPeriod: false,
                supportsAnimationTypes: ['fade', 'drop', 'lift', 'slide_in_out', 'scale_in_out'],
                supportsAnimationTunables: true,
                supportsAnimation: true,
                supportsAnimationDelay: true,
                supportsAnimationLength: true,
                supportsMainColor: false,
                supportsThemeTypography: true,
                thumbSrc: 'template-dual-axis.jpg',
                computeOptionStyles: comboChartOptionStyles,
                getStyles: getComboChartCss,
                tunables: ComboChartTunables,
                supportsSortBy: true,
            })
        );

        // axis , bubble chart
        this.add(
            new WidgetTypeWrapper({
                id: 'axisBubbleChart',
                idTemplate: 'axis-bubble',
                name: 'Axis bubble',
                dataType: 'analytics',
                component: AxisBubbleChartWidget,
                supportsColorPalette: true,
                supportsWidgetLabelDateRange: true,
                tags: ['analytics'],
                groupByLabels: ['series'],
                metricLabels: ['x', 'y', 'z'],
                minProjections: 1,
                maxProjections: 3,
                minGroupBys: 0,
                maxGroupBys: 1,
                supportsPanelPadding: true,
                optionsType: AxisBubbleChartWidgetOptionsImpl,
                supportsWidgetLabel: true,
                supportsPreviousPeriod: false,
                supportsAnimationDelay: true,
                supportsAnimationLength: true,
                supportsMainColor: false,
                supportsThemeTypography: true,
                thumbSrc: 'template-axis-bubble.jpg',
                computeOptionStyles: axisBubbleChartOptionStyles,
                getStyles: axisBubbleChartCss,
                tunables: AxisBubbleChartTunables,
                styleSeriesRecipe: [
                    {
                        incrementor: '.bubble',
                        property: 'backgroundColor',
                    },
                    {
                        incrementor: '.legend_item',
                        specifier: '.vx-glyph-dot',
                        property: 'fill',
                    },
                ],
                supportsSortBy: true,
            })
        );

        // bubble plot chart
        this.add(
            new WidgetTypeWrapper({
                id: 'bubblePlotChart',
                idTemplate: 'bubble-plot',
                name: 'Bubble Plot',
                dataType: 'analytics',
                component: BubblePlotChartWidget,
                supportsColorPalette: true,
                supportsWidgetLabelDateRange: true,
                tags: ['analytics'],
                groupByLabels: ['x', 'series'],
                metricLabels: ['y', 'z'],
                minProjections: 1,
                maxProjections: 2,
                minGroupBys: 1,
                maxGroupBys: 2,
                supportsPanelPadding: true,
                optionsType: BubblePlotChartWidgetOptionsImpl,
                supportsWidgetLabel: true,
                supportsPreviousPeriod: false,
                supportsAnimationDelay: true,
                supportsAnimationLength: true,
                supportsMainColor: false,
                supportsThemeTypography: true,
                thumbSrc: 'template-bubble-plot.jpg',
                computeOptionStyles: axisBubbleChartOptionStyles,
                getStyles: axisBubbleChartCss,
                tunables: BubblePlotChartTunables,
                styleSeriesRecipe: [
                    {
                        incrementor: '.bubble',
                        property: 'backgroundColor',
                        specifier: '.bubble',
                        joinSpecifier: true,
                    },
                    {
                        incrementor: '.legend_item',
                        specifier: '.vx-glyph-dot',
                        property: 'fill',
                    },
                ],
                supportsSortBy: true,
            })
        );

        // PIE CHART
        this.add(
            new WidgetTypeWrapper({
                id: 'pieChart',
                idTemplate: 'pie-chart',
                name: 'Pie Chart',
                dataType: 'analytics',
                component: PieChartWidget,
                supportsWidgetLabelDateRange: true,
                tags: ['analytics'],
                minProjections: 1,
                maxProjections: 1,
                minGroupBys: 1,
                maxGroupBys: 1,
                supportsPanelPadding: true,
                supportsPreviousPeriod: false,
                optionsType: PieChartWidgetOptionsImpl,
                supportsWidgetLabel: true,
                groupByLabels: ['x', 'series'],
                supportsDisableColorPalette: true,
                supportsColorPalette: true,
                supportsAnimationTypes: ['fade', 'drop', 'lift', 'slide_in_out', 'scale_in_out'],
                supportsAnimationTunables: true,
                supportsAnimation: true,
                supportsAnimationDelay: true,
                supportsAnimationLength: true,
                supportsLegend: true,
                supportsLegendOrientation: true,
                supportsLegendWidth: true,
                supportsLegendHeight: true,
                supportsLegendPercentage: true,
                supportsLegendValue: true,
                supportsLegendHeader: true,
                supportsLegendItemName: true,
                supportsDisableLegend: true,
                supportsLegendWrap: true,
                supportsLineWidth: true,
                supportsZoom: true,

                supportsMaxItems: true,
                supportsMaxItemsMax: 15,
                supportsMaxItemsMin: 2,
                supportsMaxItemsName: 'Max Slices',
                supportsOtherItems: true,

                supportsTotalValue: true,
                supportsTotalValueMetric: true,

                supportsInnerRadius: true,

                supportsCustomRatio: true,
                supportsCustomRatioName: 'Slice Percentage',

                supportsCustomLabel: true,
                supportsCustomLabelName: 'Slice Labels',
                supportsLabelSpacing: true,
                supportsMainColor: false,
                supportsThemeTypography: true,
                supportsBlacklist: true,
                supportsTotalRequest: true,
                thumbSrc: 'template-pie-chart.jpg',
                styleSeriesRecipe: [
                    {
                        incrementor: '.wedge',
                        property: 'fill',
                    },
                    {
                        incrementor: '.label',
                        specifier: '.label_line',
                        property: 'stroke',
                    },
                    {
                        incrementor: '.legend_item',
                        specifier: '.legend_item_swatch',
                        property: 'background',
                    },
                ],
                supportsThemeColor: true,
                getStyles: getPieChartCss,
                tunables: PieChartTunables,
                supportsSortBy: true,
            })
        );

        // BAR CHART / COLUMN CHART
        this.add(
            new WidgetTypeWrapper({
                id: 'barChart',
                idTemplate: 'bar-chart',
                name: 'Bar Chart',
                dataType: 'analytics',
                component: BarChartWidget,
                supportsWidgetLabelDateRange: true,
                tags: ['analytics'],
                minProjections: 1,
                maxProjections: 12,
                minGroupBys: 0,
                maxGroupBys: 1,
                supportsPanelPadding: true,
                supportsPreviousPeriod: true,
                groupByLabels: ['x', 'series'],
                optionsType: BarChartWidgetOptionsImpl,
                supportsWidgetLabel: true,
                supportsLabel: true,
                supportsColorPalette: true,
                supportsDisableColorPalette: true,
                supportsSpacingMax: 25,
                supportsPadding: true,
                supportsPaddingMin: 15,
                supportsPaddingMax: 35,
                supportsAnimationTypes: ['fade', 'drop', 'lift', 'slide_in_out', 'scale_in_out'],
                supportsAnimationTunables: true,
                supportsAnimation: true,
                supportsAnimationDelay: true,
                supportsAnimationLength: true,
                supportsThresholds: true,
                supportsHistory: true,
                supportsBlacklist: true,
                thresholdType: 'multipleLine',
                supportsMainColor: true,
                supportsThemeTypography: true,
                supportsSentiment: true,
                thumbSrc: 'template-bar-chart.jpg',
                computeOptionStyles: barChartOptionStyles,
                styleSeriesRecipe: [
                    {
                        incrementor: '.bar',
                        specifier: '.primary_background.bar_ratio_calc',
                        property: 'backgroundColor',
                    },
                    {
                        incrementor: '.legend_item',
                        specifier: '.legend_item_swatch',
                        property: 'backgroundColor',
                    },
                ],
                supportsThemeColor: true,
                supportsThemeImage: true,
                supportsThemeImageOpacity: false,
                supportsThemeImageCircleMask: true,
                supportsThemeImageSize: true,
                supportsThemeImagePosition: true,
                tunables: BarChartTunables,
                supportsSortBy: true,
            })
        );

        // STACKED BAR CHART / COLUMN CHART
        this.add(
            new WidgetTypeWrapper({
                id: 'stackedBarChart',
                idTemplate: 'stacked-bar-chart',
                name: 'Stacked Bar Chart',
                dataType: 'analytics',
                component: StackedBarChartWidget,
                supportsWidgetLabelDateRange: true,
                tags: ['analytics'],
                minProjections: 1,
                maxProjections: 1,
                minGroupBys: 2,
                maxGroupBys: 2,
                supportsPanelPadding: true,
                supportsPreviousPeriod: false,
                optionsType: StackedBarChartWidgetOptionsImpl,
                supportsWidgetLabel: true,
                groupByLabels: ['x', 'series'],
                supportsLabel: true,
                supportsColorPalette: true,
                supportsDisableColorPalette: true,
                supportsSpacing: true,
                supportsSpacingMax: 25,
                // supportsPadding: true,
                // supportsPaddingMin: 15,
                // supportsPaddingMax: 35,
                supportsDisableLegend: true,
                supportsAnimationTypes: ['fade', 'drop', 'lift', 'slide_in_out', 'scale_in_out'],
                supportsAnimationTunables: true,
                supportsAnimation: true,
                supportsAnimationDelay: true,
                supportsAnimationLength: true,
                supportsStackedTypes: true,
                supportsLegend: true,
                supportsLegendOrientation: true,
                supportsShowYAxis: true,
                supportsMainColor: true,
                supportsHistory: true,
                supportsThemeTypography: true,
                thumbSrc: 'template-stacked-bar-chart.jpg',
                getStyles: getStackedBarChartCss,
                computeOptionStyles: barChartOptionStyles,
                styleSeriesRecipe: [
                    {
                        incrementor: '.segment',
                        property: 'backgroundColor',
                    },
                    {
                        incrementor: '.legend_item',
                        specifier: '.legend_item_swatch',
                        property: 'backgroundColor',
                    },
                ],
                supportsThemeColor: true,
                tunables: StackedBarChartTunables,
                supportsSortBy: true,
            })
        );

        // SUNBURST
        this.add(
            new WidgetTypeWrapper({
                id: 'sunburst',
                idTemplate: 'sunburst',
                name: 'Sunburst',
                dataType: 'analytics',
                component: SunburstWidget,
                supportsWidgetLabelDateRange: true,
                tags: ['analytics'],
                minProjections: 1,
                maxProjections: 1,
                minGroupBys: 1,
                maxGroupBys: 3,
                supportsPreviousPeriod: false,
                optionsType: SunburstWidgetOptionsImpl,
                supportsColorPalette: true,
                supportsDisableColorPalette: true,
                supportsAnimationTypes: ['sequential', 'alternating'],
                supportsAnimationTunables: true,
                supportsAnimation: true,
                supportsAnimationDelay: true,
                supportsAnimationLength: true,
                supportsThemeTypography: true,
                thumbSrc: 'template-sunburst.jpg',
                getStyles: getSunburstCss,
                styleSeriesRecipe: [
                    {
                        incrementor: '.wedge',
                        property: 'fill',
                    },
                ],
                tunables: SunburstTunables,
                supportsSortBy: true,
            })
        );

        // SMALL MULTIPLES PIE CHART
        this.add(
            new WidgetTypeWrapper({
                id: 'smallMultiplesPieChart',
                idTemplate: 'small-multiples-pie-chart',
                name: 'Small Multiples Pie Chart',
                dataType: 'analytics',
                component: SmallMultiplesPieChartWidget,
                supportsWidgetLabelDateRange: true,
                tags: ['analytics'],
                minProjections: 1,
                maxProjections: 1,
                minGroupBys: 1,
                maxGroupBys: 2,
                supportsPanelPadding: true,
                supportsPreviousPeriod: false,
                optionsType: SmallMultiplesPieChartWidgetOptionsImpl,
                supportsWidgetLabel: true,
                groupByLabels: ['x', 'series'],
                supportsLabel: true,
                supportsColorPalette: true,
                supportsDisableColorPalette: true,
                supportsAnimationTypes: ['fade', 'drop', 'lift', 'slide_in_out', 'scale_in_out'],
                supportsAnimationTunables: true,
                supportsAnimation: true,
                supportsAnimationDelay: true,
                supportsAnimationLength: true,
                supportsInnerRadius: true,
                supportsZoom: true,
                supportsMainColor: true,
                supportsThemeTypography: true,
                supportsWedgeFillColor: true,
                supportsBlacklist: true,
                supportsTotalRequest: true,
                thumbSrc: 'template-small-multiples-pie-chart.jpg',
                getStyles: getSmallMultiplesPieChartCss,
                styleSeriesRecipe: [
                    {
                        incrementor: '.wedge',
                        // specifier: '.',
                        property: 'fill',
                    },
                    {
                        incrementor: '.legend_item',
                        specifier: '.legend_item_swatch',
                        property: 'background',
                    },
                ],
                supportsThemeColor: true,
                supportsDisableLegend: true,
                supportsLegend: true,
                supportsLegendOrientation: true,
                supportsLegendItemName: true,
                supportsLegendWrap: true,
                tunables: SmallMultiplesPieChartTunables,
                supportsSortBy: true,
            })
        );

        // LINE CHART
        this.add(
            new WidgetTypeWrapper({
                id: 'lineChart',
                idTemplate: 'line-chart',
                name: 'Line Chart',
                dataType: 'analytics',
                component: LineChartWidget,
                supportsWidgetLabelDateRange: true,
                tags: ['analytics'],
                minProjections: 1,
                maxProjections: 5,
                minGroupBys: 1,
                maxGroupBys: 2,
                supportsPanelPadding: true,
                supportsPreviousPeriod: false,
                optionsType: LineChartWidgetOptionsImpl,
                supportsWidgetLabel: true,
                groupByLabels: ['x', 'series'],
                metricLabels: ['y'],
                supportsInterpolation: true,
                supportsScaleType: true,
                supportsShowAxes: true,
                supportsTopPoint: true,
                supportsPointMarkers: true,
                supportsLineWidth: true,
                supportsColorPalette: true,
                supportsDisableColorPalette: true,
                supportsMaxItems: true,
                supportsAnimationTypes: ['fade', 'drop', 'lift', 'slide_in_out', 'scale_in_out'],
                supportsAnimationTunables: true,
                supportsAnimation: true,
                supportsAnimationDelay: true,
                supportsAnimationLength: true,
                supportsThresholds: true,
                thresholdType: 'multipleLine',
                maxThresholds: 5,
                supportsMainColor: true,
                supportsThemeTypography: true,
                thumbSrc: 'template-line-chart.jpg',
                computeOptionStyles: lineChartOptionStyles,
                getStyles: getLineChartCss,
                styleSeriesRecipe: [
                    {
                        incrementor: '.line',
                        specifier: '.line.primary_stroke',
                        joinSpecifier: true,
                        property: 'stroke',
                    },
                    {
                        incrementor: '.prev_period_line',
                        specifier: '.line.primary_stroke',
                        joinSpecifier: true,
                        property: 'stroke',
                    },
                    {
                        incrementor: '.points',
                        specifier: '.point_inner',
                        property: 'backgroundColor',
                    },
                    {
                        incrementor: '.point_label_link',
                        specifier: '.point_label_link',
                        joinSpecifier: true,
                        property: 'stroke',
                    },
                    {
                        incrementor: '.legend_item',
                        specifier: '.legend_item_swatch',
                        property: 'backgroundColor',
                    },
                ],
                supportsThemeColor: true,
                supportsThemeImage: true,
                supportsThemeImageOpacity: true,
                supportsShowFullTimePeriod: true,
                supportsWholeYAxisLabels: true,

                supportsDisableLegend: true,
                supportsLegend: true,
                supportsLegendOrientation: true,
                supportsLegendItemName: true,
                supportsLegendWrap: true,

                tunables: LineChartTunables,
                supportsSortBy: true,
            })
        );

        // WORDCLOUD
        this.add(
            new WidgetTypeWrapper({
                id: 'wordCloud',
                idTemplate: 'word-cloud',
                name: 'Word Cloud',
                dataType: 'analytics',
                component: WordCloudWidget,
                supportsWidgetLabelDateRange: true,
                tags: ['analytics'],
                minProjections: 1,
                maxProjections: 1, // For sentiment metrics
                minGroupBys: 1,
                maxGroupBys: 1,
                supportsPanelPadding: true,
                optionsType: WordCloudWidgetOptionsImpl,
                supportsWidgetLabel: true,
                supportsPreviousPeriod: false,
                supportsColorPalette: true,
                supportsDisableColorPalette: true,
                supportsMinSize: true,
                supportsMaxSize: true,
                supportsSpacing: true,
                supportsSpacingMax: 50,
                supportsAnimationTypes: ['fade'],
                supportsAnimationTunables: true,
                supportsAnimation: true,
                supportsAnimationDelay: false,
                supportsAnimationLength: false,
                supportsSentiment: true,
                supportsMainColor: true,
                supportsThemeTypography: true,
                supportsBlacklist: true,
                thumbSrc: 'template-word-cloud.jpg',
                getStyles: getWordCloudCss,
                styleSeriesRecipe: [
                    {
                        incrementor: '.word',
                        property: 'fill',
                    },
                ],
                supportsThemeColor: true,
                tunables: WordCloudTunables,
            })
        );

        // BUBBLE CHART
        this.add(
            new WidgetTypeWrapper({
                id: 'bubbleChart',
                idTemplate: 'bubble-chart',
                name: 'Bubble Chart Widget',
                dataType: 'analytics',
                component: BubbleChartWidget,
                supportsWidgetLabelDateRange: true,
                tags: ['analytics'],
                minProjections: 1,
                maxProjections: 1,
                minGroupBys: 1,
                maxGroupBys: 2,
                supportsPanelPadding: true,
                optionsType: BubbleChartWidgetOptionsImpl,
                supportsWidgetLabel: true,
                supportsPreviousPeriod: true,
                supportsColorPalette: true,
                supportsDisableColorPalette: true,
                supportsLabel: true,
                supportsZoom: true,
                supportsAnimationTypes: ['fade', 'drop', 'lift', 'slide_in_out', 'scale_in_out'],
                supportsAnimationTunables: true,
                supportsAnimation: true,
                supportsAnimationDelay: true,
                supportsAnimationLength: true,
                supportsMainColor: true,
                supportsThemeTypography: true,
                supportsTextFormat: true,
                supportsBlacklist: true,
                thumbSrc: 'template-bubble-chart.jpg',
                computeOptionStyles: bubbleChartOptionStyles,
                getStyles: BubbleChartCss,
                styleSeriesRecipe: [
                    {
                        incrementor: '.bubble',
                        specifier: '.primary_background.bubble_inner',
                        property: 'backgroundColor',
                    },
                ],
                supportsThemeColor: true,
                supportsThemeImage: true,
                supportsThemeImageOpacity: true,
                tunables: BubbleChartTunables,
                supportsSortBy: true,
            })
        );

        // METRIC
        this.add(
            new WidgetTypeWrapper({
                id: 'metric',
                name: 'Metric Widget',
                dataType: 'analytics',
                component: MetricWidget,
                supportsWidgetLabelDateRange: true,
                tags: ['analytics'],
                minProjections: 1,
                maxProjections: 10,
                minGroupBys: 0,
                maxGroupBys: 0,
                supportsPanelPadding: true,
                optionsType: MetricWidgetOptionsImpl,
                supportsWidgetLabel: true,
                supportsPreviousPeriod: true,
                supportsThresholds: true,
                thresholdType: 'stackedBar',
                supportsAnimationTypes: ['fade'],
                supportsAnimationTunables: true,
                supportsAnimation: true,
                supportsAnimationDelay: true,
                supportsAnimationLength: true,
                supportsMainColor: true,
                supportsThemeTypography: true,
                supportsColorPalette: true,
                thumbSrc: 'template-single-metric.jpg',
                getStyles: getSingleMetricCss,
                computeOptionStyles: metricWidgetOptionStyles,
                tunables: MetricWidgetTunables,
            })
        );

        // MULTI METRIC
        this.add(
            new WidgetTypeWrapper({
                id: 'multiMetric',
                name: 'Metric Widget',
                dataType: 'analytics',
                component: MultiMetricWidget,
                supportsWidgetLabelDateRange: true,
                tags: ['analytics'],
                minProjections: 1,
                maxProjections: 24,
                minGroupBys: 0,
                maxGroupBys: 0,
                supportsPanelPadding: true,
                optionsType: MultiMetricWidgetOptionsImpl,
                supportsWidgetLabel: true,
                supportsPreviousPeriod: true,
                supportsAnimationTypes: ['fade'],
                supportsAnimationTunables: true,
                supportsAnimation: true,
                supportsAnimationDelay: true,
                supportsAnimationLength: true,
                supportsMainColor: true,
                supportsThemeTypography: true,
                supportsColorPalette: true,
                thumbSrc: 'template-single-metric.jpg',
                getStyles: getMultiMetricCss,
                computeOptionStyles: multiMetricWidgetOptionStyles,
                tunables: MultiMetricWidgetTunables,
            })
        );

        // RANKED LIST
        this.add(
            new WidgetTypeWrapper({
                id: 'rankedList',
                name: 'Ranked List Widget',
                dataType: 'analytics',
                component: RankedListWidget,
                supportsWidgetLabelDateRange: true,
                tags: ['analytics'],
                minProjections: 1,
                maxProjections: 3,
                minGroupBys: 0,
                maxGroupBys: 1,
                supportsPanelPadding: true,
                optionsType: RankedListWidgetOptionsImpl,
                supportsWidgetLabel: true,
                supportsPreviousPeriod: true,
                supportsThemeTypography: true,
                supportsColorPalette: true,
                supportsDisableColorPalette: true,
                supportsLabel: true,
                supportsAnimationTypes: ['fade', 'drop', 'lift', 'slide_in_out', 'scale_in_out'],
                supportsAnimationTunables: true,
                supportsAnimation: true,
                supportsAnimationDelay: true,
                supportsAnimationLength: true,
                supportsMainColor: true,
                thumbSrc: 'template-ranked-list.jpg',
                styleSeriesRecipe: [
                    {
                        incrementor: '.item',
                        specifier: '.primary_background.item_rank',
                        property: 'backgroundColor',
                    },
                ],
                supportsSortBy: true,
            })
        );

        // DYNAMIC IMAGE
        this.add(
            new WidgetTypeWrapper({
                id: 'dynamicImage',
                idTemplate: 'dynamic-image',
                name: 'Dynamic Image Widget',
                dataType: 'analytics',
                component: DynamicImageWidget,
                supportsWidgetLabelDateRange: true,
                tags: ['analytics'],
                minProjections: 1,
                maxProjections: 3,
                minGroupBys: 0,
                maxGroupBys: 1,
                supportsPanelPadding: true,
                optionsType: DynamicImageWidgetOptionsImpl,
                supportsWidgetLabel: true,
                supportsPreviousPeriod: true,
                supportsThemeTypography: true,
                supportsColorPalette: true,
                supportsDisableColorPalette: true,
                supportsLabel: true,
                supportsAnimationTypes: ['fade', 'drop', 'lift', 'slide_in_out', 'scale_in_out'],
                supportsAnimationTunables: true,
                supportsAnimationDelay: true,
                supportsAnimationLength: true,
                supportsMainColor: true,
                thumbSrc: 'template-ranked-list.jpg',
                tunables: DynamicImageWidgetTunables,
                supportsSortBy: true,
            })
        );

        // DYNAMIC TITLE
        this.add(
            new WidgetTypeWrapper({
                id: 'dynamicTitle',
                idTemplate: 'dynamic-title',
                name: 'Dynamic Title Widget',
                dataType: 'none',
                component: DynamicTitleWidget,
                computeOptionStyles: dynamicTitleOptionStyles,
                getStyles: dynamicTitleCss,
                supportsWidgetLabelDateRange: true,
                tags: ['custom'],
                supportsPanelPadding: true,
                optionsType: DynamicTitleWidgetOptionsImpl,
                supportsThemeTypography: true,
                supportsColorPalette: true,
                supportsDisableColorPalette: true,
                supportsLabel: true,
                supportsAnimationTypes: ['fade', 'drop', 'lift', 'slide_in_out', 'scale_in_out'],
                supportsAnimationTunables: true,
                supportsAnimationDelay: true,
                supportsAnimationLength: true,
                supportsMainColor: true,
                thumbSrc: 'template-ranked-list.jpg',
                tunables: DynamicTitleTunables,
                supportsSortBy: true,
            })
        );

        // USED IN BRAND HEALTH - BASICALLY JUST A DUPLICATE OF RANKED LIST?
        this.add(
            new WidgetTypeWrapper({
                id: 'metricRollup',
                name: 'Metric Rollup',
                dataType: 'analytics',
                component: MetricRollupWidget,
                supportsWidgetLabelDateRange: true,
                minProjections: 1,
                maxProjections: 5,
                minGroupBys: 1,
                maxGroupBys: 1,
                supportsPanelPadding: true,
                optionsType: MetricRollupWidgetOptionsImpl,
                supportsWidgetLabel: true,
                supportsPreviousPeriod: true,
                supportsLabel: true,
                supportsAnimationTypes: ['fade', 'drop', 'lift', 'slide_in_out', 'scale_in_out'],
                supportsAnimationTunables: true,
                supportsAnimation: true,
                supportsAnimationDelay: true,
                supportsAnimationLength: true,
                supportsMainColor: true,
                supportsThemeTypography: true,
                getStyles: getMetricRollupCss,
                supportsSortBy: true,
            })
        );

        // WORLD HEAT MAP
        this.add(
            new WidgetTypeWrapper({
                id: 'worldHeatMap',
                idTemplate: 'world-heat-map',
                name: 'World Heat Map Widget',
                dataType: 'analytics',
                component: WorldHeatMapWidget,
                supportsWidgetLabelDateRange: true,
                tags: ['analytics'],
                minProjections: 1,
                maxProjections: 1,
                minGroupBys: 1,
                maxGroupBys: 2,
                supportsPanelPadding: true,
                optionsType: WorldHeatMapWidgetOptionsImpl,
                supportsWidgetLabel: true,
                supportsPreviousPeriod: false,
                supportsLegend: true,
                supportsLegendSize: true,
                supportsDisableLegend: true,
                supportsColorPalette: true,
                supportsDisableColorPalette: true,
                supportsZoom: true,
                supportsAnimationTypes: ['fade'],
                supportsAnimationTunables: true,
                supportsAnimation: true,
                supportsAnimationDelay: false,
                supportsAnimationLength: false,
                supportsMainColor: true,
                supportsThemeTypography: true,
                // getStyles: getWorldHeatMapCss,
                computeOptionStyles: worldHeatMapOptionStyles,
                supportsRegion: true,
                reverseSeries: true,
                tunables: WorldHeatMapTunables,
                // styleSeriesRecipe: [
                //     {
                //         incrementor: '.legend_color_tile',
                //         specifier: '',
                //         property: 'backgroundColor',
                //     },
                // ],
                supportsSortBy: true,
            })
        );
        // GEO STREAM
        this.add(
            new WidgetTypeWrapper({
                id: 'geoStream',
                idTemplate: 'geo-stream',
                name: 'Gea Stream Widget',
                dataType: 'analytics',
                component: GeoStreamWidget,
                supportsWidgetLabelDateRange: true,
                tags: ['analytics'],
                minProjections: 0,
                maxProjections: 0,
                minGroupBys: 1,
                maxGroupBys: 2,
                supportsPanelPadding: true,
                optionsType: GeoStreamWidgetOptionsImpl,
                supportsWidgetLabel: true,
                supportsPreviousPeriod: false,
                supportsLegend: true,
                supportsLegendSize: true,
                supportsDisableLegend: true,
                supportsColorPalette: true,
                supportsDisableColorPalette: true,
                supportsZoom: true,
                supportsAnimationTunables: true,
                supportsAnimation: true,
                supportsAnimationDelay: false,
                supportsAnimationLength: false,
                supportsMainColor: true,
                supportsThemeTypography: true,
                // getStyles: getWorldHeatMapCss,
                computeOptionStyles: geoStreamWidgetOptionStyles,
                supportsRegion: true,
                reverseSeries: true,
                tunables: GeoStreamTunables,
                // styleSeriesRecipe: [
                //     {
                //         incrementor: '.legend_color_tile',
                //         specifier: '',
                //         property: 'backgroundColor',
                //     },
                // ],
                supportsSortBy: true,
            })
        );

        // TICKER
        this.add(
            new WidgetTypeWrapper({
                id: 'geoStreamTicker',
                idTemplate: 'geo-stream-ticker',
                name: 'Ticker',
                dataType: 'posts',
                component: TickerWidget,
                supportsWidgetLabelDateRange: true,
                tags: ['posts'],
                minProjections: 0,
                maxProjections: 0,
                minGroupBys: 0,
                maxGroupBys: 1,
                supportsPanelPadding: true,
                optionsType: TickerWidgetOptionsImpl,
                supportsWidgetLabel: true,
                supportsPreviousPeriod: false,
                supportsAnimation: true,
                supportsAnimationDelay: false,
                supportsAnimationLength: false,
                supportsAnimationTunables: true,
                supportsAnimationTypes: ['slide_in_out', 'continuous_in_out'],
                supportsEngagement: true,
                supportsUserGroupControls: true,
                supportsUserGroupPosition: true,
                supportsTimeStamp: true,
                supportsImagePosts: true,
                supportsImageBackgroundSize: true,
                supportsTextPosts: true,
                supportsTextFormat: true,
                supportsTextEntity: true,
                supportsTextOnlyBackgroundColor: true,
                supportsImagePostBackgroundColor: true,
                supportsMainColor: false,
                supportsThemeTypography: true,
                supportsPostCycling: true,
                thumbSrc: 'template-ticker.jpg',
                computeOptionStyles: postOptionStyles,
                getStyles: getPostCss,
                getPostComposition: tickerCompositions,
                supportsUserGroupLayoutTypes: false,
                supportsShufflePosts: true,
                supportsRemoveDuplicates: true,
                supportsRemoveSensitivePosts: true,
                supportsUseImagePlaceholder: true,
                supportsLinkPreview: true,
                supportsIncludeQuoteTweets: true,
                supportsQuotedTweetView: false,
                tunables: TickerTunables,
            })
        );

        // PROFILE
        this.add(
            new WidgetTypeWrapper({
                id: 'profile',
                idTemplate: 'profile-1-by-3',
                name: 'Profile Widget',
                dataType: 'posts',
                component: ProfileWidget,
                supportsWidgetLabelDateRange: true,
                tags: ['profile'],
                minProjections: 0,
                maxProjections: 0,
                minGroupBys: 0,
                maxGroupBys: 0,
                supportsPanelPadding: true,
                optionsType: ProfileWidgetOptionsImpl,
                supportsWidgetLabel: true,
                supportsPreviousPeriod: false,
                supportsAnimationTypes: ['fade', 'drop', 'lift', 'slide_in_out', 'scale_in_out'],
                supportsAnimationTunables: true,
                supportsAnimation: true,
                supportsAnimationDelay: true,
                supportsAnimationLength: true,
                supportsUserGroupControls: true,
                computeOptionStyles: postOptionStyles,
                supportsUserGroupPosition: false,
                supportsTimeStamp: false,
                supportsUserGroupDescription: true,
                supportsMainColor: true,
                supportsThemeTypography: true,
                thumbSrc: 'template-profile-1-by-3.jpg',
                tunables: ProfileTunables,
                engineOverrides: {
                    LISTENING: {
                        id: null, // These are just required props for WidgetType
                        name: null,
                        dataType: null,
                        optionsType: null,
                        component: null,
                        maxGroupBys: 1, // These are the real overrides
                        filterDimensionGroup: 'Who',
                    },
                },
            })
        );

        // DNA - BUBBLE GRID
        this.add(
            new WidgetTypeWrapper({
                id: 'heatMapTableChart',
                idTemplate: 'dna-chart',
                name: 'Heat Map Table Chart',
                dataType: 'analytics',
                component: HeatMapTableChartWidget,
                supportsWidgetLabelDateRange: true,
                tags: ['analytics'],
                minProjections: 1,
                maxProjections: 1,
                minGroupBys: 2,
                maxGroupBys: 3,
                supportsPanelPadding: true,
                supportsPreviousPeriod: false,
                optionsType: HeatMapTableChartWidgetOptionsImpl,
                supportsWidgetLabel: true,
                groupByLabels: ['row', 'column', 'color'],
                supportsDisableLegend: true,
                supportsLegend: true,
                supportsLegendSize: true,
                supportsColorPalette: true,
                supportsDisableColorPalette: true,
                supportsOrbSize: true,
                supportsAnimationTypes: ['fade', 'drop', 'lift', 'slide_in_out', 'scale_in_out'],
                supportsAnimationTunables: true,
                supportsAnimation: true,
                supportsAnimationDelay: true,
                supportsAnimationLength: true,
                supportsTimeFormatting: true,
                supportsMainColor: true,
                supportsThemeTypography: true,
                supportsSentiment: true,
                computeOptionStyles: heatMapTableOptionStyles,
                getStyles: heatMapTableCss,
                tunables: HeatMapTableChartTunables,
                supportsSortBy: true,
            })
        );

        // LEADERBOAD
        this.add(
            new WidgetTypeWrapper({
                id: 'rankedTable',
                idTemplate: 'ranked-table',
                name: 'Ranked Table Widget',
                dataType: 'analytics',
                component: RankedTableWidget,
                supportsWidgetLabelDateRange: true,
                tags: ['analytics'],
                minProjections: 1,
                maxProjections: 20,
                minGroupBys: 1,
                maxGroupBys: 10,
                supportsPanelPadding: true,
                optionsType: RankedTableWidgetOptionsImpl,
                supportsWidgetLabel: true,
                supportsPreviousPeriod: true,
                supportsLabel: true,
                supportsColumnName: true,
                computeOptionStyles: rankedTableOptionStyles,
                supportsAnimationTypes: ['fade', 'drop', 'lift', 'slide_in_out', 'scale_in_out'],
                supportsAnimationTunables: true,
                supportsAnimation: true,
                supportsAnimationDelay: true,
                supportsAnimationLength: true,
                supportsMainColor: true,
                supportsThemeTypography: true,
                supportsColorPalette: true,
                supportsThemeColor: true,
                supportsThemeImage: true,
                supportsThemeImageCircleMask: true,
                supportsThemeImageSize: true,
                supportsInvertedDataSet: true,
                thumbSrc: 'template-top-metrics.jpg',
                styleSeriesRecipe: [
                    {
                        incrementor: '.row',
                        specifier: '.primary_background.icon__container',
                        property: 'backgroundColor',
                    },
                ],
                getStyles: getRankedTableCss,
                tunables: RankedTableTunables,
                supportsSortBy: true,
            })
        );

        // DATA TABLE PIVOT - TWO DIMENSIONAL
        this.add(
            new WidgetTypeWrapper({
                id: 'dataTablePivot',
                idTemplate: 'data-table-pivot',
                name: 'Two Dimensional Table',
                dataType: 'analytics',
                component: DataTableWidget,
                tags: ['analytics'],
                minProjections: 1,
                maxProjections: 1,
                minGroupBys: 2,
                maxGroupBys: 2,
                groupByLabels: ['Column', 'Row'],
                metricLabels: ['Value'],
                supportsPanelPadding: true,
                optionsType: DataTableWidgetOptionsImpl,
                computeOptionStyles: dataTableOptionStyles,
                supportsAnimationTypes: ['fade', 'drop', 'lift', 'slide_in_out', 'scale_in_out'],
                supportsAnimationTunables: true,
                supportsAnimation: true,
                supportsAnimationDelay: true,
                supportsAnimationLength: true,
                supportsMainColor: true,
                supportsThemeTypography: true,
                thumbSrc: 'template-top-metrics.jpg',
                getStyles: getDataTableCss,
                tunables: DataTableTunables,
                supportsSortBy: true,
            })
        );
        // DATA TABLE CROSS TAB
        this.add(
            new WidgetTypeWrapper({
                id: 'dataTableCrossTab',
                idTemplate: 'data-table-cross-tab',
                name: 'Cross Tab Table',
                dataType: 'analytics',
                component: DataTableWidget,
                tags: ['analytics'],
                minProjections: 2,
                maxProjections: 2,
                minGroupBys: 2,
                maxGroupBys: 2,
                groupByLabels: ['Column', 'Row'],
                metricLabels: ['Primary Value', 'Secondary Value'],
                supportsPanelPadding: true,
                optionsType: DataTableWidgetOptionsImpl,
                computeOptionStyles: dataTableOptionStyles,
                supportsAnimationTypes: ['fade', 'drop', 'lift', 'slide_in_out', 'scale_in_out'],
                supportsAnimationTunables: true,
                supportsAnimation: true,
                supportsAnimationDelay: true,
                supportsAnimationLength: true,
                supportsMainColor: true,
                supportsThemeTypography: true,
                thumbSrc: 'template-top-metrics.jpg',
                getStyles: getDataTableCss,
                tunables: DataTableTunables,
                supportsSortBy: true,
            })
        );
        // DATA TABLE SUMMARY
        this.add(
            new WidgetTypeWrapper({
                id: 'dataTableSummary',
                idTemplate: 'data-table-summary',
                name: 'Summary Table',
                dataType: 'analytics',
                component: DataTableWidget,
                tags: ['analytics'],
                minProjections: 1,
                maxProjections: 20,
                minGroupBys: 0,
                maxGroupBys: 0,
                supportsPanelPadding: true,
                optionsType: DataTableWidgetOptionsImpl,
                computeOptionStyles: dataTableOptionStyles,
                supportsAnimationTypes: ['fade', 'drop', 'lift', 'slide_in_out', 'scale_in_out'],
                supportsAnimationTunables: true,
                supportsAnimation: true,
                supportsAnimationDelay: true,
                supportsAnimationLength: true,
                supportsMainColor: true,
                supportsThemeTypography: true,
                thumbSrc: 'template-top-metrics.jpg',
                getStyles: getDataTableCss,
                tunables: DataTableTunables,
                supportsSortBy: true,
            })
        );
        // DATA TABLE GROUPED SUMMARY
        this.add(
            new WidgetTypeWrapper({
                id: 'dataTableGroupedSummary',
                idTemplate: 'data-table-grouped-summary',
                name: 'Grouped Summary Table',
                dataType: 'analytics',
                component: DataTableWidget,
                tags: ['analytics'],
                minProjections: 1,
                maxProjections: 1,
                minGroupBys: 1,
                maxGroupBys: 1,
                supportsPanelPadding: true,
                optionsType: DataTableWidgetOptionsImpl,
                computeOptionStyles: dataTableOptionStyles,
                supportsAnimationTypes: ['fade', 'drop', 'lift', 'slide_in_out', 'scale_in_out'],
                supportsAnimationTunables: true,
                supportsAnimation: true,
                supportsAnimationDelay: true,
                supportsAnimationLength: true,
                supportsMainColor: true,
                supportsThemeTypography: true,
                thumbSrc: 'template-top-metrics.jpg',
                getStyles: getDataTableCss,
                tunables: DataTableTunables,
                supportsSortBy: true,
            })
        );

        // custom table chart
        this.add(
            new WidgetTypeWrapper({
                id: 'customTable',
                name: 'Custom Table',
                dataType: 'none',
                component: CustomTableChartWidget,
                supportsColorPalette: true,
                supportsWidgetLabelDateRange: true,
                tags: ['custom'],
                minProjections: 2,
                maxProjections: 2,
                minGroupBys: 1,
                maxGroupBys: 1,
                supportsPanelPadding: true,
                optionsType: CustomTableChartWidgetOptionsImpl,
                supportsWidgetLabel: true,
                supportsPreviousPeriod: false,
                supportsAnimationTypes: ['fade', 'drop', 'lift', 'slide_in_out', 'scale_in_out'],
                supportsAnimationTunables: true,
                supportsAnimation: true,
                supportsAnimationDelay: true,
                supportsAnimationLength: true,
                supportsMainColor: false,
                supportsThemeTypography: true,
                thumbSrc: 'template-dual-axis.jpg',
                computeOptionStyles: customTableChartOptionStyles,
                // getStyles: getComboChartCss,
                tunables: CustomTableChartTunables,
            })
        );

        // Custom Table Chart Rich Text
        this.add(
            new WidgetTypeWrapper({
                id: 'customTableRichText',
                name: 'Rich Text',
                icon: 'content-rich-text',
                dataType: 'none',
                component: CustomContentWidget,
                tags: ['custom'],
                optionsType: CustomContentRichTextOptionsImpl,
                supportsWidgetLabel: false,
                supportsRichText: true,
                supportsThemeTypography: false,
                computeOptionStyles: customContentRichTextOptionStyles,
                tunables: CustomTableRichTextTunables,
                getStyles: getCustomContentCss,
                supportsAnimationDelay: false,
                supportsAnimationLength: false,
                supportsAnimation: false,
            })
        );

        // TREEMAP
        this.add(
            new WidgetTypeWrapper({
                id: 'treeMap',
                idTemplate: 'tree-map',
                name: 'Tree Map Widget',
                dataType: 'analytics',
                component: TreeMapWidget,
                supportsWidgetLabelDateRange: true,
                thumbSrc: 'template-tree-map.jpg',
                tunables: TreeMapTunables,
                tags: ['analytics'],
                minProjections: 1,
                maxProjections: 1, // For sentiment metrics
                minGroupBys: 1,
                maxGroupBys: 1,
                supportsPanelPadding: true,
                supportsPreviousPeriod: true,
                optionsType: TreeMapWidgetOptionsImpl,
                supportsWidgetLabel: true,
                computeOptionStyles: treeMapOptionStyles,
                supportsColorPalette: true,
                supportsDisableColorPalette: true,
                supportsAnimationTypes: ['fade', 'drop', 'lift', 'slide_in_out', 'scale_in_out'],
                supportsAnimationTunables: true,
                supportsAnimation: true,
                supportsAnimationDelay: true,
                supportsAnimationLength: true,
                supportsMainColor: true,
                supportsThemeTypography: true,
                supportsTextFormat: true,
                supportsBlacklist: true,
                supportsSentiment: true,
                styleSeriesRecipe: [
                    {
                        incrementor: '.node',
                        specifier: '.primary_background.node_inner',
                        property: 'backgroundColor',
                    },
                ],
                supportsSortBy: true,
            })
        );

        // Funnel Chart
        this.add(
            new WidgetTypeWrapper({
                id: 'funnelChart',
                idTemplate: 'funnel-chart',
                name: 'Funnel Chart Widget',
                dataType: 'analytics',
                component: FunnelChartWidget,
                optionsType: FunnelChartWidgetOptionsImpl,
                tunables: FunnelChartTunables,
                thumbSrc: 'template-tree-map.jpg',
                supportsWidgetLabelDateRange: true,
                tags: ['analytics'],
                minProjections: 1,
                maxProjections: 10,
                minGroupBys: 0,
                maxGroupBys: 0,
                supportsPanelPadding: true,
                supportsPreviousPeriod: false,
                supportsWidgetLabel: true,
                supportsColorPalette: true,
                supportsAnimationTypes: ['fade', 'drop', 'lift', 'slide_in_out', 'scale_in_out'],
                supportsAnimationTunables: true,
                supportsAnimationDelay: true,
                supportsAnimationLength: true,
                supportsThemeTypography: true,
                supportsSentiment: true,
                supportsAnimation: true,
                styleSeriesRecipe: [
                    {
                        incrementor: '.bar',
                        specifier: '.primary_background.bar_ratio_fill',
                        property: 'fill',
                        joinSpecifier: true,
                    },
                ],
                supportsSortBy: true,
            })
        );

        // Custom Rich Text
        this.add(
            new WidgetTypeWrapper({
                id: 'customContentRichText',
                name: 'Rich Text',
                icon: 'content-rich-text',
                dataType: 'none',
                component: CustomContentWidget,
                tags: ['custom'],
                optionsType: CustomContentRichTextOptionsImpl,
                supportsWidgetLabel: false,
                supportsRichText: true,
                supportsThemeTypography: false,
                computeOptionStyles: customContentRichTextOptionStyles,
                tunables: CustomContentRichTextTunables,
                getStyles: getCustomContentCss,
                supportsAnimationLength: true,
                supportsAnimationDelay: true,
                supportsAnimationTypes: ['fade', 'drop', 'lift', 'slide_in_out', 'scale_in_out'],
                supportsAnimationTunables: true,
                supportsAnimation: true,
            })
        );

        // Custom Content Image
        this.add(
            new WidgetTypeWrapper({
                id: 'customContentImage',
                name: 'Image',
                icon: 'content-image',
                dataType: 'none',
                component: CustomContentWidget,
                tags: ['custom'],
                optionsType: CustomContentImageOptionsImpl,
                supportsWidgetLabel: true,
                supportsPanelPadding: true,
                supportsContentZoom: true,
                supportsContentImage: true,
                supportsVerticalAlign: true,
                supportsHorizontalAlign: true,
                supportsImageAdjustments: true,
                supportsThemeTypography: false,
                computeOptionStyles: customContentImageOptionStyles,
                tunables: CustomContentImageTunables,
                getStyles: getCustomContentCss,
                supportsAnimationLength: true,
                supportsAnimationDelay: true,
                supportsAnimationTypes: ['fade', 'drop', 'lift', 'slide_in_out', 'scale_in_out'],
                supportsAnimationTunables: true,
                supportsAnimation: true,
            })
        );

        // Custom Content Video
        this.add(
            new WidgetTypeWrapper({
                id: 'customContentVideo',
                name: 'Video',
                icon: 'content-video',
                dataType: 'none',
                component: CustomContentWidget,
                tags: ['custom'],
                optionsType: CustomContentVideoOptionsImpl,
                supportsWidgetLabel: true,
                supportsPanelPadding: true,
                supportsContentBackground: true,
                supportsContentVideo: true,
                supportsImageBackgroundSize: true,
                supportsImageAdjustments: true,
                supportsContentZoom: true,
                supportsThemeTypography: false,
                computeOptionStyles: customContentVideoOptionStyles,
                tunables: CustomContentVideoTunables,
                getStyles: getCustomContentCss,
                supportsAnimationLength: true,
                supportsAnimationDelay: true,
                supportsAnimationTypes: ['fade', 'drop', 'lift', 'slide_in_out', 'scale_in_out'],
                supportsAnimationTunables: true,
                supportsAnimation: true,
            })
        );

        // Custom Content SocialPost
        this.add(
            new WidgetTypeWrapper({
                id: 'customContentSocialPost',
                name: 'Social Post',
                icon: 'content-social-post',
                dataType: 'none',
                component: CustomContentWidget,
                tags: ['custom'],
                optionsType: CustomContentSocialPostOptionsImpl,
                supportsWidgetLabel: true,
                supportsContentZoom: true,
                supportsSocialPostUrl: true,
                supportsThemeTypography: false,
                computeOptionStyles: customContentSocialPostOptionStyles,
                supportsPostArrange: true,
                tunables: CustomContentSocialPostTunables,
                getStyles: getCustomContentCss,
                supportsAnimationLength: true,
                supportsAnimationDelay: true,
                supportsAnimationTypes: ['fade', 'drop', 'lift', 'slide_in_out', 'scale_in_out'],
                supportsAnimationTunables: true,
                supportsAnimation: true,
            })
        );

        // Custom Content Iframe
        this.add(
            new WidgetTypeWrapper({
                id: 'customContentIframe',
                name: 'iFrame',
                icon: 'content-iframe',
                dataType: 'none',
                component: CustomContentWidget,
                tags: ['custom'],
                optionsType: CustomContentIframeOptionsImpl,
                supportsWidgetLabel: true,
                supportsPanelPadding: true,
                supportsContentIframe: true,
                supportsContentZoom: true,
                supportsThemeTypography: false,
                tunables: CustomContentIframeTunables,
                getStyles: getCustomContentCss,
                supportsAnimationLength: true,
                supportsAnimationDelay: true,
                supportsAnimationTypes: ['fade', 'drop', 'lift', 'slide_in_out', 'scale_in_out'],
                supportsAnimationTunables: true,
                supportsAnimation: true,
            })
        );

        // TWITTER TRENDS - mirrors treemap design settings as it uses
        // the same treemap component that treeMapWidget uses.
        this.add(
            new WidgetTypeWrapper({
                id: 'twitterTrends',
                idTemplate: 'twitter-trends',
                name: 'Twitter Trends',
                dataType: 'trends',
                component: TwitterTrendsWidget,
                supportsWidgetLabelDateRange: true,
                tags: ['analytics'],
                minProjections: 0,
                maxProjections: 0,
                minGroupBys: 0,
                maxGroupBys: 0,
                supportsPanelPadding: true,
                supportsPreviousPeriod: false,
                optionsType: TwitterTrendsWidgetOptionsImpl,
                supportsWidgetLabel: true,
                groupByLabels: [],
                supportsLabel: true,
                supportsColorPalette: true,
                supportsDisableColorPalette: true,
                supportsSpacing: true,
                supportsSpacingMax: 15,
                supportsPadding: true,
                supportsPaddingMin: 5,
                supportsPaddingMax: 20,
                supportsAnimationTypes: ['fade', 'drop', 'lift', 'slide_in_out', 'scale_in_out'],
                supportsAnimationTunables: true,
                supportsAnimation: true,
                supportsAnimationDelay: true,
                supportsAnimationLength: true,
                supportsMainColor: true,
                supportsThemeTypography: true,
                supportsTextFormat: true,
                thumbSrc: 'template-twitter-trends.jpg',
                computeOptionStyles: treeMapOptionStyles,
                tunables: TwitterWidgetTunables,
                styleSeriesRecipe: [
                    {
                        incrementor: '.node',
                        specifier: '.node_inner.primary_background',
                        property: 'backgroundColor',
                    },
                ],
            })
        );

        // Shapes
        this.add(
            new WidgetTypeWrapper({
                id: 'shape',
                name: 'Shape',
                dataType: 'none',
                tags: ['shape'],
                component: ShapeWidget,
                minProjections: 0,
                maxProjections: 0,
                minGroupBys: 0,
                maxGroupBys: 0,
                supportsPreviousPeriod: false,
                optionsType: ShapeWidgetOptionsImpl,
                thumbSrc: 'template-rectangle.jpg',
                tunables: ShapeTunables,
                computeOptionStyles: shapeOptionStyles,
            })
        );

        // Speech Bubble
        this.add(
            new WidgetTypeWrapper({
                id: 'speechBubble',
                idTemplate: 'speech-bubble',
                name: 'Speech Bubble',
                dataType: 'none',
                tags: ['speech bubble'],
                component: SpeechBubbleWidget,
                minProjections: 0,
                maxProjections: 0,
                minGroupBys: 0,
                maxGroupBys: 0,
                supportsPreviousPeriod: false,
                computeOptionStyles: speechBubbleOptionStyles,
                optionsType: SpeechBubbleWidgetOptionsImpl,
                tunables: SpeechBubbleTunables,
                thumbSrc: 'template-rectangle.jpg',
            })
        );

        // ------------- below here are all Deprecated -----------
        // We need these for backwards compatibility or else the app errors out when
        // the widget renderer tries to find a type that is no longer defined.
        // At some point we may just want to force people using
        // the beta incarnations of these templates to just create their panel again.

        // Renamed sparkline to Linechart since we removed the victory charts version of the linechart.
        this.add(
            new WidgetTypeWrapper({
                id: 'sparkline',
                name: 'Line Chart',
                dataType: 'analytics',
                component: LineChartWidget,
                minProjections: 1,
                maxProjections: 1,
                minGroupBys: 1,
                maxGroupBys: 2,
                supportsPreviousPeriod: true,
                groupByLabels: ['x', 'series'],
                optionsType: LineChartWidgetOptionsImpl,
            })
        );

        // earlier incarnation of the Metric widget
        this.add(
            new WidgetTypeWrapper({
                id: 'metricValue',
                name: 'Metric Value Widget',
                dataType: 'analytics',
                component: MetricValueWidget,
                minProjections: 1,
                maxProjections: 1,
                minGroupBys: 0,
                maxGroupBys: 1,
                supportsPreviousPeriod: true,
                optionsType: MetricValueWidgetOptionsImpl,
            })
        );

        // another earlier incarnation of the Metric widget
        this.add(
            new WidgetTypeWrapper({
                id: 'metricValueLockup',
                name: 'Metric Value Lockup Widget',
                dataType: 'analytics',
                component: MetricValueLockupWidget,
                minProjections: 1,
                maxProjections: 1,
                minGroupBys: 0,
                maxGroupBys: 1,
                supportsPreviousPeriod: true,
                optionsType: MetricValueLockupWidgetOptionsImpl,
            })
        );

        // not sure if this was ever even put in a template - probably safe to remove
        this.add(
            new WidgetTypeWrapper({
                id: 'countUp',
                name: 'Count Up',
                dataType: 'analytics',
                component: CountUpWidget,
                minProjections: 1,
                maxProjections: 1,
                minGroupBys: 0,
                maxGroupBys: 0,
                supportsPreviousPeriod: true,
                optionsType: CountUpWidgetOptionsImpl,
            })
        );

        // legend was going to be something that displayed misc meta data like topic filters / time range.
        this.add(
            new WidgetTypeWrapper({
                id: 'legend',
                name: 'Legend Widget',
                dataType: 'none',
                component: LegendWidget,
                minProjections: 0,
                maxProjections: 0,
                minGroupBys: 0,
                maxGroupBys: 0,
                supportsPreviousPeriod: false,
                optionsType: LineChartWidgetOptionsImpl,
            })
        );

        // GOOGLE TRENDS - mirrors treemap design settings as it uses the same treemap component that treeMapWidget uses
        this.add(
            new WidgetTypeWrapper({
                id: 'googleTrends',
                idTemplate: 'google-trends',
                name: 'Google Trends',
                dataType: 'trends',
                component: GoogleTrendsWidget,
                supportsWidgetLabelDateRange: true,
                tags: ['analytics'],
                minProjections: 0,
                maxProjections: 0,
                minGroupBys: 0,
                maxGroupBys: 0,
                supportsPanelPadding: true,
                supportsPreviousPeriod: false,
                optionsType: GoogleTrendsWidgetOptionsImpl,
                supportsWidgetLabel: true,
                groupByLabels: [],
                supportsLabel: true,
                supportsColorPalette: true,
                supportsDisableColorPalette: true,
                supportsSpacing: true,
                supportsSpacingMax: 15,
                supportsPadding: true,
                supportsPaddingMin: 5,
                supportsPaddingMax: 20,
                supportsAnimationTypes: ['fade', 'drop', 'lift', 'slide_in_out', 'scale_in_out'],
                supportsAnimationTunables: true,
                supportsAnimation: true,
                supportsAnimationDelay: true,
                supportsAnimationLength: true,
                supportsMainColor: true,
                supportsThemeTypography: true,
                supportsTextFormat: true,
                thumbSrc: 'template-google-trends.jpg',
                computeOptionStyles: treeMapOptionStyles,
                styleSeriesRecipe: [
                    {
                        incrementor: '.node',
                        specifier: '.node_inner.primary_background',
                        property: 'backgroundColor',
                    },
                ],
            })
        );

        // HYPERLINK BUTTON
        this.add(
            new WidgetTypeWrapper({
                id: 'hyperlinkButton',
                idTemplate: 'hyperlink-button',
                name: 'Hyperlink Button',
                dataType: 'none',
                component: HyperlinkButtonWidget,
                tags: ['custom'],
                optionsType: HyperlinkButtonWidgetOptionsImpl,
                supportsWidgetLabel: false,
                supportsThemeTypography: false,
                supportsButtonText: true,
                computeOptionStyles: hyperlinkWidgetOptionStyles,
                tunables: HyperlinkButtonTunables,
                getStyles: getHyperlinkCss,
                supportsAnimationLength: true,
                supportsAnimationDelay: true,
                supportsAnimationTypes: ['fade', 'drop', 'lift', 'slide_in_out', 'scale_in_out'],
                supportsAnimationTunables: true,
                supportsAnimation: true,
            })
        );
    }
}
