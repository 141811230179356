import * as React from 'react';
import i18n from 'i18next';
import { headerTunables, opacityTunables } from 'components/Sidebar/RenderTunables/SharedTunables';
import { CollapseProps, TunablesCollapse } from 'components/Sidebar/RenderTunables/RenderTunables';
import { Widget } from '@sprinklr/stories/widget/Widget';
import ActionInput from '../../components/_UI/Forms/Actions/ActionInput';
import ActionSegmentedGroup from '../../components/_UI/Forms/Actions/ActionSegmentedGroup';
import { imageBackgroundSizeOptions } from 'components/Panel/PanelEditorSidebar/PanelDesign/PanelDesignOptions';
import LabelGroup from '../../components/_UI/Forms/LabelGroup/LabelGroup';
import { DynamicImageWidgetOptions } from './options';
import ActionCheckboxSliderGroup from '../../components/_UI/Forms/Actions/ActionCheckboxSliderGroup';
import ActionCheckboxGroup from '../../components/_UI/Forms/Actions/ActionCheckboxGroup';
import ActionSliderGroup from '../../components/_UI/Forms/Actions/ActionSliderGroup';

export const DynamicImageWidgetTunables = (widget: Widget): TunablesCollapse[] => [
    {
        props: {
            label: i18n.t('Dynamic Image'),
            startOpen: true,
        } as CollapseProps,
        children: [...headerTunables(widget), opacityTunables(widget)],
    },
    {
        props: {
            label: i18n.t('Tunables'),
            startOpen: true,
        } as CollapseProps,
        children: [
            <ActionCheckboxGroup
                key='Background.showImage'
                label={i18n.t('Background Image')}
                value='enabled'
                option={(widget.options as DynamicImageWidgetOptions).richImage.image}
            />,
            (widget.options as DynamicImageWidgetOptions).richImage.image.enabled && (
                <>
                    <ActionSegmentedGroup
                        key='widget.options.background.size'
                        label={i18n.t('Fit')}
                        option={(widget.options as DynamicImageWidgetOptions).richImage.image}
                        value='size'
                        options={imageBackgroundSizeOptions}
                    />
                    <ActionSliderGroup
                        label={i18n.t('Padding')}
                        key='widget.options.background.image.margin'
                        option={(widget.options as DynamicImageWidgetOptions).richImage.image}
                        value='padding'
                    />
                    <ActionSliderGroup
                        label={i18n.t('Opacity')}
                        key='widget.options.background.image.opacity'
                        option={(widget.options as DynamicImageWidgetOptions).richImage.image}
                        value='opacity'
                    />
                </>
            ),
            <ActionCheckboxGroup
                key='Background.fill.enabled'
                label={i18n.t('Background Color')}
                value='enabled'
                option={(widget.options as DynamicImageWidgetOptions).richImage.fill}
            />,
            <ActionSliderGroup
                label={i18n.t('Corner Radius')}
                key='widget.options.background.radius'
                option={(widget.options as DynamicImageWidgetOptions).richImage}
                value='radius'
            />,
            <ActionCheckboxSliderGroup
                key='widget.options.background'
                label={i18n.t('Border')}
                showValue
                checkbox={{
                    option: (widget.options as DynamicImageWidgetOptions).richImage.border,
                    value: 'enabled',
                }}
                slider={{
                    option: (widget.options as DynamicImageWidgetOptions).richImage.border,
                    value: 'size',
                    min: 0,
                    max: 100,
                }}
            />,
            <LabelGroup label={i18n.t('Rank')}>
                <ActionInput type='number' option={widget.options} value='rank' min={1} />
            </LabelGroup>,
        ],
    },
];

export default DynamicImageWidgetTunables;
