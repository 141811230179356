import Color from 'color';
import { action, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { SocialMediaAsset } from '../../../../models/SocialMediaAsset/SocialMediaAsset';
import { Theme } from '../../../../models/Theme/Theme';
import { WidgetFont, Widget } from '@sprinklr/stories/widget/Widget';
import { SAMSearchFonts } from '../../../../services/SocialMediaAssetService/SAMSearch';
import { SocialMediaAssetService } from '../../../../services/SocialMediaAssetService/SocialMediaAssetService';
import { fontFamilyOptions, horizontalAlignOptions, InlineStyles } from './PanelDesignOptions';
import TypographyFontControl from './TypographyFontControl';
import LabelGroup from '../../../_UI/Forms/LabelGroup/LabelGroup';
import ActionColorSwatchGroup from '../../../_UI/Forms/Actions/ActionColorSwatchGroup';
import i18n from '../../../../i18n';
import { LabeledSlider } from 'components/spaceKit';
import ActionSegmentedGroup from 'components/_UI/Forms/Actions/ActionSegmentedGroup';
const debounce: any = require('debounce');

export interface SupportsButtonTextProps {
    widget: Widget;
    socialMediaAssetService?: SocialMediaAssetService;
    mergedTheme?: Theme;
}

class SupportsButtonText extends React.Component<SupportsButtonTextProps, any> {
    @observable
    private assets: SocialMediaAsset[] = [];

    constructor(props: any) {
        super(props);
    }

    componentDidMount() {
        this.props.socialMediaAssetService
            .search(SAMSearchFonts)
            .then(this.handleSamFonts)
            .catch(error => {
                console.error('asset load error', error);
            });
    }

    render() {
        const { mergedTheme, widget } = this.props;

        const mergedFont = mergedTheme && mergedTheme.typography && mergedTheme.typography.primary;
        const mergedColor =
            (mergedTheme && mergedTheme.typography && mergedTheme.typography.color) || '#fff';

        return (
            <>
                {this.assets && (
                    <TypographyFontControl
                        key='fontFamily'
                        label='Font Family'
                        createFontFaces={false}
                        enableDeselect={false}
                        activeFont={widget.options.font.family ? widget.options.font : mergedFont}
                        builtinFonts={fontFamilyOptions}
                        samFonts={this.assets}
                        onSamFontSelected={this.samFontSelected}
                        onBuiltinFontSelected={this.builtinFontSelected}
                    />
                )}
                <LabeledSlider
                    key='widget.options.fontSize'
                    label='Font Size'
                    showValue
                    min={0}
                    max={100}
                    value={parseInt(widget.options.fontSize, 10)}
                    onChange={this.setFontSize}
                />

                <LabelGroup label={i18n.t('Text')} key='label widget.options.colorFont'>
                    <ActionColorSwatchGroup
                        key='widget.options.colorFont'
                        value={null}
                        option={null}
                        color={widget.options.colorFont || mergedColor}
                        onChange={this.setColor}
                        onClear={() => this.setColor(mergedColor)}
                    />
                </LabelGroup>
                <ActionSegmentedGroup
                    key='widget.options.buttonTextAlignment'
                    label={i18n.t('Align')}
                    option={widget.options}
                    value={'buttonTextAlignment'}
                    options={horizontalAlignOptions}
                />
            </>
        );
    }

    @action
    private handleSamFonts = (results: SocialMediaAsset[]) => {
        this.assets = results
            .filter(asset => !!asset.digitalAsset.font)
            .sort((a, b) => {
                // paranoia
                const keyA = a.sortKey ? a.sortKey : a.name ? a.name : '';
                const keyB = b.sortKey ? b.sortKey : b.name ? b.name : '';

                if (keyA < keyB) {
                    return -1;
                }
                if (keyA > keyB) {
                    return 1;
                }
                return 0;
            });
    };

    @action
    private samFontSelected = (font: WidgetFont) => {
        const { widget } = this.props;
        const { family, weight, style } = font;

        widget.options.font.family = family;
        widget.options.font.style = style;
        widget.options.font.weight = weight;
    };

    @action
    private builtinFontSelected = (font: WidgetFont) => {
        const { widget } = this.props;
        const { family, weight, style } = font;

        widget.options.font.family = family;
        widget.options.font.style = style;
        widget.options.font.weight = weight;
    };

    @action
    private setColor = value => {
        const { widget } = this.props;

        if (!value) {
            return;
        }

        const fontColor = this.getColorValue(value);

        if (fontColor) {
            widget.options.colorFont = fontColor;
        }
    };

    private getColorValue(value) {
        let result = value;
        if (value.rgb) {
            const rgba = [];
            for (const prop in value.rgb) {
                if (value.rgb.hasOwnProperty(prop)) {
                    rgba.push(value.rgb[prop]);
                }
            }
            result = Color(rgba).string();
        } else if (value.hex) {
            result = value.hex;
        }
        return result;
    }

    @action
    private setFontSize = event => {
        this.props.widget.options.fontSize = event.target.value;
    };
}

export default inject('socialMediaAssetService')(observer(SupportsButtonText));
