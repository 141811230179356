import { VideoPlayer, VideoPlayerOptions } from './Video';
import { VideoBase } from './VideoBase';
import ObjectUtils from '../../utils/ObjectUtils/ObjectUtils';
import { BreakableRefCallback } from '../../components/Video/BreakableRefCallback';

export class VideoYoutube extends VideoBase implements VideoPlayer {
    private static loading: Promise<any>;
    private stopped = false;
    private breakableOnPlayerReady: any;
    private breakableOnPlayerStateChange: any;

    constructor(muted = false) {
        super(muted);
    }

    start(container: any, options: VideoPlayerOptions, callbackStopped: () => boolean) {
        const url = this.getUrl(options.url);

        this.id = ObjectUtils.hashCode(url);
        this.container = container;
        this.options = options;

        const onPlayerReady = event => {
            if (this.player) {
                if (this.muted && this.player.mute) {
                    this.player.mute();
                }

                if (this.lastTime[this.id] && this.player.seekTo) {
                    this.player.seekTo(this.lastTime[this.id], false);
                }
            }
        };

        const onPlayerStateChange = event => {
            const YT = (window as any).YT;

            switch (event.data) {
                case YT.PlayerState.PLAYING:
                    // Hide placeholder after video is playing so that we don't see the ugly loading spinner
                    if (options.hideMarkerAfterPlay) {
                        this.placeholder.style.display = 'none';
                    }
                    break;

                case YT.PlayerState.ENDED:
                    // Implement manual looping since the "loop=1" parameters does not work
                    // https://developers.google.com/youtube/js_api_reference#getPlayerState
                    if (!callbackStopped || callbackStopped()) {
                        if (this.player && this.player.playVideo) {
                            this.player.playVideo();

                            if (this.muted && this.player.mute) {
                                this.player.mute();
                            }
                        }
                    }
                    break;

                default:
                    break;
            }
        };

        this.breakableOnPlayerReady = new BreakableRefCallback(onPlayerReady);
        this.breakableOnPlayerStateChange = new BreakableRefCallback(onPlayerStateChange);

        this.placeholder = this.findVideoPlaceholder(container);
        if (this.placeholder) {
            if (!options.hideMarkerAfterPlay) {
                this.placeholder.style.display = 'none';
            }

            this.loadPlayer().then(() => {
                const YT = (window as any).YT;

                const placeholder = this.placeholder;
                if (placeholder) {
                    // Remove our old player instance, if present, and replace with a
                    // brand new version.  This is because it never works right or
                    // reliabily when you try to reuse the player object.  Videos
                    // don't always play, or the sound plays but the video doesn't, etc.
                    const target = this.injectTargetDiv();
                    const dimensions = this.getDimensions();

                    // http://stackoverflow.com/questions/3452546/javascript-regex-how-to-get-youtube-video-id-from-url
                    const match = url.match(
                        /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/
                    );
                    if (match && match[2].length === 11) {
                        this.videoId = match[2];

                        this.stopped = false;

                        this.player = new YT.Player(target, {
                            width: dimensions.width,
                            height: dimensions.height,
                            videoId: this.videoId,
                            playerVars: {
                                autoplay: this.options.autoplay ? 1 : 0,
                                modestbranding: 0,
                                iv_load_policy: 3,
                                showinfo: 0,
                                controls: options.controls ? 1 : 0,
                                rel: 0,
                                origin: window.location.protocol + '//' + window.location.hostname,
                            },
                            events: {
                                onReady: this.breakableOnPlayerReady.invoke,
                                onStateChange: this.breakableOnPlayerStateChange.invoke,
                            },
                        });
                    }
                }
            });
        }
    }

    // Stop and destroy the video player instance
    stop(): void {
        const YT = (window as any).YT;

        // Show our marker div again
        if (this.placeholder) {
            this.placeholder.style.display = '';
        }

        if (this.player && !this.stopped) {
            this.stopped = true;
            // Save the time so we can seek to it on the next play
            if (
                this.options.playFromLastTime &&
                this.player.getPlayerState() === YT.PlayerState.PLAYING
            ) {
                this.lastTime[this.id] = this.player.getCurrentTime();
            } else {
                delete this.lastTime[this.id];
            }
        }

        if (this.player) {
            // always do cleanup regardless of "stopped" status
            // try manual listener cleanup first - before player.stop()
            if (this.player.removeEventListener) {
                // not always defined it seems?
                this.player.removeEventListener('onReady', this.breakableOnPlayerReady.invoke);
                this.player.removeEventListener(
                    'onStateChange',
                    this.breakableOnPlayerStateChange.invoke
                );
            }

            // this shuts down the player, only to be used when we're done with it
            if (this.player.stopVideo) {
                this.player.stopVideo();
            }

            // break callback refs allowing things to be collected even if YT cleanup goes awry
            this.breakableOnPlayerReady.breakRef();
            this.breakableOnPlayerStateChange.breakRef();

            // cleans up the iframe, but maybe not event listeners? docs are not clear
            this.player.destroy();
            this.player = null;
        }
    }

    // Pause the video
    pause(): void {
        // Show our marker div
        if (this.placeholder) {
            this.placeholder.style.display = '';
        }

        if (this.player && this.player.stopVideo) {
            this.player.stopVideo();
        }
    }

    // Play the video
    play(): void {
        if (this.player && this.player.playVideo) {
            this.player.playVideo();

            if (this.muted && this.player.mute) {
                this.player.mute();
            }
        }

        // Hide our marker div
        if (this.placeholder) {
            this.placeholder.style.display = 'none';
        }
    }

    private getUrl(url: string): string {
        let result: string;

        if (url.indexOf('http://') === 0) {
            url = 'https://' + url.substring(7);
        }

        if (url.indexOf('?') !== -1) {
            result =
                url +
                '&enablejsapi=1&controls=0&loop=0&showinfo=0&rel=0&modestbranding=1&iv_load_policy=3';
        } else {
            result =
                url +
                '?enablejsapi=1&controls=0&loop=0&showinfo=0&rel=0&modestbranding=1&iv_load_policy=3';
        }

        return result;
    }

    // Load the YouTube player API dynamically to cut down on initial overall app size
    private loadPlayer(): Promise<any> {
        if (VideoYoutube.loading) {
            return VideoYoutube.loading;
        }

        VideoYoutube.loading = new Promise<void>((resolve, reject) => {
            function load() {
                const tag = document.createElement('script');

                tag.src = 'https://www.youtube.com/iframe_api';
                const firstScriptTag = document.getElementsByTagName('script')[0];
                firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
            }

            (window as any).onYouTubeIframeAPIReady = function() {
                resolve();
            };

            load();
        });

        return VideoYoutube.loading;
    }

    private injectTargetDiv(): Element {
        const div = document.createElement('div');

        this.placeholder.parentNode.insertBefore(div, this.placeholder);

        // Remove any iframes already created
        this.destroyChildren('iframe');

        return div;
    }

    private destroyChildren(tag: string): void {
        // Remove any iframes already created
        const elements = this.placeholder.getElementsByTagName(tag);
        for (let i = 0; i < elements.length; i++) {
            this.placeholder.removeChild(elements[i]);
        }
    }
}
