import { ProfileMetricsImpl } from '../../../widgets/ProfileWidget/options';

export const profileMetrics: ProfileMetricsImpl = {
    enabled: true,
    hideZero: false,
    items: [
        {
            label: 'Followers',
            value: 'followers',
            alternateLabel: {
                enabled: false,
                value: 'Followers',
            },
        },
        {
            label: 'Influencer Score',
            value: 'influencerIndex',
            alternateLabel: {
                enabled: false,
                value: 'Influencer Score',
            },
        },
        {
            label: 'Favorites',
            value: 'favCount',
            alternateLabel: {
                enabled: false,
                value: 'Favorites',
            },
        },
        {
            label: 'Following',
            value: 'following',
            alternateLabel: {
                enabled: false,
                value: 'Following',
            },
        },
    ],
};
