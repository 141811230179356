import * as React from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { Threshold } from './Thresholds';
import { getPrettyNumber, getPrettyTimeInterval } from '../../utils/Number/NumberUtils';
import moment from 'moment';

interface ThresholdAxisProps {
    // the array of threshold objects from the widget config
    thresholds: Threshold[];
    metricType?: string;
    // orientation of the chart
    orientation: 'vertical' | 'horizontal';
}

const ThresholdAxis: React.FC<ThresholdAxisProps> = ({
    metricType = 'NUMBER',
    orientation,
    thresholds,
}) => {
    const axisClasses = classNames(`thresholds__axis no-grow flex rel`, {
        'w-100': orientation === 'vertical',
    });

    const spacerClasses = classNames(`threshold__axis invisible`, {
        'pt-05': orientation === 'vertical',
        'pl-05': orientation === 'horizontal',
    });

    return (
        <div className={axisClasses}>
            {thresholds.map((threshold, i) => {
                let style: React.CSSProperties = {};
                const value =
                    metricType === 'NUMBER'
                        ? getPrettyNumber(threshold.value, 0, false)
                        : getPrettyTimeInterval(
                              convertThresholdTimeInterval(threshold.value, threshold.timeInterval),
                              false
                          );

                if (orientation === 'horizontal') {
                    style = {
                        right: '0',
                        position: 'absolute',
                        paddingTop: '0.2em',
                    };
                    if (threshold.value >= 1) {
                        style.top = `${100 - threshold.ratio}%`;
                    } else {
                        style.bottom = `${100 - threshold.ratio}%`;
                    }
                }

                if (orientation === 'vertical') {
                    style = {
                        top: '0',
                        position: 'absolute',
                        paddingRight: '0.5em',
                    };
                    if (threshold.value >= 1) {
                        style.right = `${100 - threshold.ratio}%`;
                    } else {
                        style.left = `${100 - threshold.ratio}%`;
                    }
                }

                const axisStyle: React.CSSProperties = {
                    fontSize: '1em',
                    fontWeight: 'bold',
                };
                return (
                    <div className='metric-position' style={style} key={i}>
                        <span className='threshold__axis' style={axisStyle}>
                            {`${isNaN(value.value) ? 0 : value.value}${value.suffix} ${
                                metricType === 'TIME_INTERVAL' && threshold.timeInterval
                                    ? threshold.timeInterval
                                    : ''
                            }`}
                        </span>
                    </div>
                );
            })}

            {/* Invisible Spacer */
            thresholds.length > 0 && <span className={spacerClasses}>{thresholds[0].value}</span>}
        </div>
    );
};

export const convertThresholdTimeInterval = (value: number, type: any) => {
    return Math.round(moment.duration(value).as(type));
};

export default observer(ThresholdAxis);
