import { Widget } from '@sprinklr/stories/widget/Widget';
import { WIDGET_LABEL_SIZE } from '../../components/Widget/WidgetLabel/constants';
import { DefaultWidgetPosition } from './PanelTemplatesTheme/constants';
import { WidgetWorldHeatMap } from './WidgetWorldHeatMap';
import { WidgetTicker } from './WidgetTicker';
import { TEXT_SHADOW_DEFAULT } from 'models/Widget/Widget';
import merge from 'deepmerge';
import { FormatOrderedOptions, SourcePost } from '@sprinklr/stories/post/PostsFormatRequest';
import { PostSource, Sort } from './PanelTemplatesTheme/PanelTemplatesTheme';

const getPostsRequests = () => {
    const raw = WidgetTicker.postRequests[0];

    (raw.format.options as FormatOrderedOptions).fixedList = 5;
    const source = (raw.sources[0] as any).id as PostSource;
    source.setFilters([
        {
            dimensionName: 'COUNTRY',
            filterType: 'NIN',
            values: ['UN'],
        },
    ]);

    source.setSorts([
        new Sort({
            order: 'DESC',
            heading: {
                LISTENING: 'REACH_COUNT',
                RDB_FIREHOSE: 'SN_CREATED_TIME',
                TRENDING_EVENT: 'TREND_MENTIONS_COUNT',
            },
        }),
    ]);

    return [raw];
};

export const WidgetGeoStream: Widget = {
    type: 'geoStream',
    id: 'geo-stream',
    name: 'Geo Stream',
    children: [
        {
            ...WidgetTicker,
            type: 'geoStreamTicker',
            id: 'stream',
            position: { top: '0', left: '0', width: '40%', height: '100%' },
            options: merge(WidgetTicker.options, {
                player: null,
                nativeStyling: { enabled: true },
            }),
            postRequests: getPostsRequests(),
        },
        {
            ...WidgetWorldHeatMap,
            id: 'geo',
            position: { top: '0', left: '40%', width: '60%', height: '100%' },
            options: merge(WidgetWorldHeatMap.options, {
                player: null,
                regionMapEnabled: true,
                secondLegend: {
                    showLegend: false,
                },
                showLegend: false,
                mapBorder: {
                    enabled: true,
                },
            }),
            analyticsRequests: [],
        },
    ],
    label: {
        titleText: 'Geo Stream',
        enabled: false,
        size: WIDGET_LABEL_SIZE.primary,
        textShadow: TEXT_SHADOW_DEFAULT,
    },
    position: DefaultWidgetPosition,
    options: {
        player: {
            playing: true,
            duration: 15,
        },
        smartSize: {
            enabled: true,
            mode: 'responsive',
            size: 'xl',
        },
        portrait: {
            widgetOrientation: 'portrait',
        },
    },
};
