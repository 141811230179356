import * as React from 'react';
import { action } from 'mobx';
import { observer } from 'mobx-react';
import { LabeledCheckbox } from 'components/spaceKit';

type ActionLabeledCheckboxProps = {
    value: string; // key on object to modify
    option: {}; // object to modify
    label?: string;
    disabled?: boolean;
    readOnly?: boolean;
    title?: string;
    onClick?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const ActionCheckboxGroup: React.FC<ActionLabeledCheckboxProps> = ({
    label,
    disabled,
    readOnly,
    title,
    option,
    value,
    onClick,
}: ActionLabeledCheckboxProps) => {
    const onChange = action((event: React.ChangeEvent<HTMLInputElement>) => {
        option[value] = event.target.checked;
        if (onClick) {
            onClick(event);
        }
    });
    return (
        <LabeledCheckbox
            label={label}
            checked={!!option[value]}
            disabled={disabled}
            readOnly={readOnly}
            title={title}
            onChange={onChange}
        />
    );
};

ActionCheckboxGroup.displayName = 'ActionLabeledCheckboxSlider';
export default observer(ActionCheckboxGroup);
