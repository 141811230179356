import { observable } from 'mobx';
export interface SAMSearch {
    start: number;
    rows: number;
    keywordSearch?: string;
    onlyAvailable?: boolean;
    rangeCondition?: RangeCondition;
    sortList?: Array<SearchRequestSort>;
    filters?: SocialAssetFacetAndFilter;
    withFacets?: boolean;
}

export interface SearchRequestSort {
    order: SAMSortOrder;
    key: SAMSortKey;
}

export type SAMSortKey = 'createdTime' | 'updatedTime' | 'name' | 'description';
export type SAMSortOrder = 'ASC' | 'DESC';

export const SAMSearchFonts: SAMSearch = {
    start: 0,
    rows: 200,
    keywordSearch: null,
    onlyAvailable: true,
    rangeCondition: null,
    sortList: null,
    filters: { assetTypes: ['FONT'] },
};

export interface RangeCondition {}

interface SocialAssetFacetAndFilter {
    assetTypes?: Array<MediaAssetType>;
    authorIds?: Array<number>;
    clientIds?: Array<number>;
    accountIds?: Array<number>;
    campaignIds?: Array<string>;
    tags?: Array<string>;
    statuses?: Array<AssetStatus>;
}

export type MediaAssetType =
    | 'PHOTO'
    | 'VIDEO'
    | 'PDF'
    | 'DOC'
    | 'FONT'
    | 'EXCEL'
    | 'PRESENTATION'
    | 'PSD'
    | 'TEXT'
    | 'RICH_TEXT'
    | 'LINK'
    | 'POST';

export type AssetStatus = 'DRAFT' | 'APPROVED' | 'EXPIRED';

export class SAMSearchImpl implements SAMSearch {
    @observable start = 0;
    @observable rows = 500;
    @observable keywordSearch = '';
    @observable onlyAvailable = true;
    @observable rangeCondition: RangeCondition;
    @observable sortList: Array<SearchRequestSort> = [new SearchRequestSortImpl()];
    @observable filters: SocialAssetFacetAndFilter = new SocialAssetFacetAndFilterImpl();
}

export class SocialAssetFacetAndFilterImpl implements SocialAssetFacetAndFilter {
    @observable assetTypes: Array<MediaAssetType> = [];
    @observable authorIds: Array<number> = [];
    @observable clientIds: Array<number> = [];
    @observable accountIds: Array<number> = [];
    @observable campaignIds: Array<string> = [];
    @observable tags: Array<string> = [];
    @observable statuses: Array<AssetStatus> = [];

    static get assetTypes(): Array<MediaAssetType> {
        return [
            'PHOTO',
            'VIDEO',
            'PDF',
            'DOC',
            'FONT',
            'EXCEL',
            'PRESENTATION',
            'PSD',
            'TEXT',
            'RICH_TEXT',
            'LINK',
            'POST',
        ];
    }
}

export class SearchRequestSortImpl implements SearchRequestSort {
    @observable order: SAMSortOrder = 'DESC';
    @observable key: SAMSortKey = 'createdTime';

    static get keys(): { [key: string]: string } {
        return {
            createdTime: 'Date Created',
            // updatedTime: "Date Modified",
            name: 'Asset Name',
            // description: "Asset Description"
        };
    }
}
