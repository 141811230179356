import { AnalyticsRequest, Metric } from './PanelTemplatesTheme/PanelTemplatesTheme';
import { Panel } from '../../models/Panel/Panel';
import { WIDGET_LABEL_SIZE } from '../../components/Widget/WidgetLabel/constants';
import ListeningMetrics from '@sprinklr/stories-services/AnalyticsService/ListeningMetrics';

// eslint-disable-next-line no-var
export var TemplateMultiMetric: Panel = {
    id: 'multi-metric',
    name: 'Multi Metric',
    description: '',
    tags: ['Standard', 'Multi Metric', 'landscape', 'portrait'],
    widget: {
        id: '1',
        position: {
            width: '1920px',
            height: '1080px',
            top: '0',
            left: '0',
        },
        classes: 'template_multi_metric',
        children: [
            {
                type: 'multiMetric',
                id: '1',
                name: 'Multi Metric',
                position: {
                    width: '100%',
                    height: '100%',
                    top: '0',
                    left: '0',
                },
                useGlobalTimePeriod: true,
                analyticsRequests: [
                    // Note: All global variables like reportingEngine, report, filters, and projections
                    // are injected via AnalyticsRequest.getRequests()
                    new AnalyticsRequest({
                        limit: 1,
                        groupBys: [],
                        sorts: [],
                        timePeriods: [{ key: 'last_7_days' }, { previousPeriod: true }],
                        projections: [
                            new Metric({
                                LISTENING: { source: ListeningMetrics.mentions },
                                INBOUND_MESSAGE: {
                                    dimensionName: 'OUTBOUND_COUNT',
                                    heading: 'Outbound Count',
                                },
                                PLATFORM: {
                                    dimensionName: 'PUBLISHED_MESSAGE_COUNT',
                                    heading: 'Volume Of Published Messages',
                                },
                                BENCHMARKING: {
                                    dimensionName: 'PUBLISHED_MESSAGE_COUNT',
                                    heading: 'Volume Of Published Messages',
                                },
                                PAID: { dimensionName: 'spent', heading: 'Spent' },
                                ADVOCACY: {
                                    dimensionName: 'ADVOCACY_ACTIVE_USERS',
                                    heading: 'Total Active Users',
                                },
                                RDB_FIREHOSE: {
                                    dimensionName: 'MENTIONS_COUNT',
                                    heading: 'Mentions',
                                },
                                STORY_MESSAGE: {
                                    dimensionName: 'STORY_ACTIVE_COUNT',
                                    heading: 'Story_Active_Count',
                                },
                            }),
                            new Metric({
                                LISTENING: { source: ListeningMetrics.distinctUsers },
                                INBOUND_MESSAGE: {
                                    dimensionName: 'INBOUND_COUNT',
                                    heading: 'Inbound Count',
                                },
                                PLATFORM: {
                                    dimensionName: 'TOTAL_ENGAGEMENT',
                                    heading: 'Total Engagement',
                                },
                                BENCHMARKING: {
                                    dimensionName: 'TOTAL_ENGAGEMENT',
                                    heading: 'Total Engagement',
                                },
                                PAID: { dimensionName: 'clicks', heading: 'Clicks' },
                                ADVOCACY: {
                                    dimensionName: 'APPROVED',
                                    heading: 'Total Approved Users',
                                    origReport: 'COMMUNITY_USER',
                                },
                                RDB_FIREHOSE: {
                                    dimensionName: 'DISTINCT_USER_COUNT',
                                    heading: 'Distinct Users',
                                },
                                STORY_MESSAGE: {
                                    dimensionName: 'NEWS_COUNT',
                                    heading: 'News_Mentions',
                                },
                            }),
                            new Metric({
                                LISTENING: { source: ListeningMetrics.reach },
                                INBOUND_MESSAGE: { dimensionName: 'LIKE_COUNT' },
                                PLATFORM: { dimensionName: 'POST_REACH_COUNT' },
                                BENCHMARKING: { dimensionName: 'PUBLISHED_MESSAGE_COUNT' },
                                PAID: { dimensionName: 'clicks' },
                                ADVOCACY: {
                                    dimensionName: 'ADVOCACY_TOTAL_USER_LOGIN',
                                    heading: 'Total_Logins',
                                    origReport: 'ADVOCACY_ACTIVITY',
                                },
                                RDB_FIREHOSE: {
                                    dimensionName: 'DISTINCT_USER_COUNT',
                                    heading: 'Distinct Users',
                                },
                                STORY_MESSAGE: {
                                    dimensionName: 'WEB_REACH',
                                    heading: 'Reach',
                                },
                            }),
                        ],
                    }) as any,
                ],
                label: {
                    titleText: 'Multi Metric',
                    overlap: false,
                    size: WIDGET_LABEL_SIZE.primary,
                    enabled: true,
                },
                options: {
                    player: {
                        playing: true,
                        duration: 15,
                    },

                    showMetricValue: true,
                    metricValueSize: 60,
                    showMetricName: true,
                    metricNameSize: 15,
                    showMetricChange: true,
                    metricPercentChangeSize: 15,
                    highlightPercentChange: false,
                    smartSize: {
                        enabled: true,
                        mode: 'responsive',
                        size: 'xl',
                    },
                    portrait: {
                        widgetOrientation: 'portrait',
                        metricValueSize: 45,
                    },
                },
            },
        ],
    },
};
