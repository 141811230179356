import { SourceInterstitials } from './SourceInterstitials';
import { SourcePosts } from './SourcePosts';
import { ArrayChanged, ArrayDiff } from '../../utils/ArrayDiff/ArrayDiff';

const jsep: any = require('jsep');

export class Bucket<T> {
    items: T[] = [];
    private diff: ArrayDiff<T>;
    private tree: any;
    private filter: string;
    private loop: boolean;
    private offsetPrevious = 0;
    private offsetNext = 0;
    private stepBy = 1;

    constructor(uniqueKey: string, filter: string, loop: boolean, ordered: boolean) {
        this.diff = new ArrayDiff<T>(uniqueKey, ordered);
        this.filter = filter;
        this.loop = loop === undefined ? true : loop;

        jsep.removeBinaryOp('||');
        jsep.removeBinaryOp('&&');
        jsep.removeBinaryOp('===');
        jsep.removeBinaryOp('!==');

        // Use Sprinklr-like binary operators
        jsep.addBinaryOp('OR', 1);
        jsep.addBinaryOp('AND', 2);

        // Create the abstract syntax tree from filter specified
        try {
            this.tree = jsep(filter);
        } catch (error) {
            this.tree = null;
            console.log(
                'ERROR: Bucket cannot parse this filter: "' + filter + '", reason: ' + error.message
            );
        }
    }

    isLoop(): boolean {
        return this.loop;
    }

    match(posts: ArrayChanged<T>, interstitials: ArrayChanged<T>): void {
        if (this.tree) {
            const match = (changed: ArrayChanged<T>, areInterstitials: boolean) => {
                if (changed) {
                    const wasEmpty = this.items.length === 0;

                    if (changed.added.length) {
                        // Filter out non-matching posts from the added array
                        const added = changed.added.filter((item: T) => {
                            return this.evaluateSubTree(this.tree, item, areInterstitials);
                        });

                        // Make a shallow copy so we can safely change the added array
                        changed = Object.assign({}, changed);
                        changed.added = added;
                    }

                    // Sync it with our bucket posts array
                    this.diff.sync(this.items, changed, this.offsetNext, 1);

                    // Make sure our offset is still valid after potential deletes
                    if (wasEmpty || this.offsetPrevious >= this.getTotal()) {
                        this.offsetPrevious = this.getTotal() - 1;
                    }

                    if (this.offsetNext >= this.getTotal()) {
                        this.offsetNext = 0;
                    }
                }
            };

            match(interstitials, true);
            match(posts, false);
        }
    }

    getItemPrevious(): T {
        let item: T = null;
        const total = this.getTotal();

        if (this.offsetPrevious < total) {
            item = this.items[this.offsetPrevious];

            if (--this.offsetPrevious < 0) {
                if (this.loop) {
                    this.offsetPrevious = total - 1;
                } else {
                    this.offsetPrevious = 0;
                }
            }
        }

        return item;
    }

    getItemNext(): T {
        let item: T = null;
        const total = this.getTotal();

        if (this.offsetNext < total) {
            item = this.items[this.offsetNext];

            if (++this.offsetNext >= total) {
                if (this.loop) {
                    this.offsetNext = 0;
                } else {
                    this.offsetNext = total;
                }
            }
        }

        return item;
    }

    // Increment offset by a value > 1.
    setStepBy(stepBy: number) {
        this.stepBy = stepBy;
    }

    // Used only if "ordered" is true
    getOffset(): number {
        return this.offsetNext;
    }

    // Used only if "ordered" is true
    setOffset(offset: number): void {
        const total = this.getTotal();
        if (offset >= total) {
            offset = total - 1;
        }

        this.offsetNext = offset;
    }

    stepOffset(direction: number) {
        if (direction === -1) {
            this.offsetNext -= this.stepBy;
        } else {
            this.offsetNext += this.stepBy;
        }

        const total = this.getTotal();
        if (this.offsetNext < 0) {
            this.offsetNext = total - 1;
        } else if (this.offsetNext >= total) {
            this.offsetNext = 0;
        }
    }

    reset(): void {
        this.offsetNext = 0;
    }

    getTotal(): number {
        return this.items.length;
    }

    shuffle(): void {
        let j, x, i;
        for (i = this.items.length - 1; i > 0; i--) {
            j = Math.floor(Math.random() * (i + 1));
            x = this.items[i];
            this.items[i] = this.items[j];
            this.items[j] = x;
        }
    }

    /* tslint:disable:triple-equals */
    private evaluateSubTree(tree: any, item: T, interstitial: boolean): any {
        switch (tree.type) {
            case 'BinaryExpression':
                var left = this.evaluateSubTree(tree.left, item, interstitial);
                var right = this.evaluateSubTree(tree.right, item, interstitial);

                switch (tree.operator) {
                    case 'AND':
                        return left && right;

                    case 'OR':
                        return left || right;

                    case '==':
                        return left == right;

                    case '!=':
                        return left != right;

                    case '>':
                        return left > right;

                    case '>=':
                        return left >= right;

                    case '<':
                        return left < right;

                    case '<=':
                        return left <= right;

                    case '+':
                        return left + right;

                    case '-':
                        return left - right;

                    case '*':
                        return left * right;

                    case '/':
                        return left / right;

                    case '%':
                        return left % right;

                    case '|':
                        return left | right;

                    case '^':
                        return left ^ right;

                    case '&':
                        return left & right;

                    case '<<':
                        return left << right;

                    case '>>':
                        return left >> right;

                    case '>>>':
                        return left >>> right;

                    default:
                        console.log('ERROR: Missing switch for BinaryExpression ' + tree.operator);
                }

                return false;

            case 'CallExpression':
                var args = [];

                for (let x = 0; x < tree.arguments.length; x++) {
                    const arg = this.evaluateSubTree(tree.arguments[x], item, interstitial);
                    args.push(arg);
                }

                switch (tree.callee.name) {
                    case 'match':
                        if (args[0] && args[1]) {
                            try {
                                return new RegExp(
                                    args[1],
                                    args.length > 2 ? args[2] : undefined
                                ).test(args[0]);
                            } catch (error) {
                                console.log('ERROR: Invalid regexp: ' + error);
                            }
                        }
                        break;

                    default:
                        console.log('ERROR: Invalid function ' + tree.callee);
                }

                return false;

            case 'Identifier':
                return tree.name;

            case 'Literal':
                return tree.value;

            case 'MemberExpression':
                var object = this.evaluateSubTree(tree.object, item, interstitial);
                var property = this.evaluateSubTree(tree.property, item, interstitial);

                if (interstitial && object === 'interstitial') {
                    const result = SourceInterstitials.evaluate(property, item);
                    if (result) {
                        return result;
                    } else {
                        return item[property];
                    }
                } else if (!interstitial && (object === 'post' || object === 'profile')) {
                    let result: any;
                    if (object === 'post') {
                        result = SourcePosts.evaluate(property, item);
                    } else {
                        result = item[property];
                    }

                    if (result) {
                        return result;
                    } else {
                        return item[property];
                    }
                } else if (object) {
                    return object[property];
                }

                return null;

            case 'UnaryExpression':
                var value = this.evaluateSubTree(tree.argument, item, interstitial);

                switch (tree.operator) {
                    case '-':
                        return -value;

                    case '!':
                        return !value;

                    case '~':
                        return ~value;

                    case '+':
                        return +value;

                    default:
                        console.log('ERROR: Missing switch for UnaryExpression ' + tree.operator);
                }
                break;

            default:
                break;
        }
    }
}
