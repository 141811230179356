import { Widget } from '@sprinklr/stories/widget/Widget';
import { TEXT_SHADOW_DEFAULT } from 'models/Widget/Widget';
import { WIDGET_LABEL_SIZE } from '../../components/Widget/WidgetLabel/constants';

export var WidgetCustomRichText: Widget = {
    type: 'customContentRichText',
    id: '1',
    name: 'Rich Text',
    position: {
        width: '50%',
        height: '20%',
        top: '25%',
        left: '25%',
    },
    label: {
        titleText: 'Rich Text',
        enabled: false,
        size: WIDGET_LABEL_SIZE.primary,
        textShadow: TEXT_SHADOW_DEFAULT,
    },
    options: {
        player: {
            playing: true,
            duration: 15,
        },
        contentRichText: {
            entityMap: {},
            blocks: [
                {
                    key:
                        'key_' +
                        Math.random()
                            .toString(36)
                            .substr(2, 9),
                    text: 'Rich Text',
                    type: 'unstyled',
                    depth: 0,
                    inlineStyleRanges: [
                        {
                            offset: 0,
                            length: 9,
                            style: 'CUSTOM_FONT_SIZE_20' as any,
                        },
                    ],
                    entityRanges: [],
                    data: {},
                },
            ],
        },
    },
};
