import moment from 'moment';
import BulkItem from './BulkItem';
import TopicTheme from './TopicTheme';
import SLAFrequency from './SLAFrequency';
import AccountItem from './AccountItem';
import BenchmarkBrand from './BenchmarkBrand';
import CommunityUser from './CommunityUser';
import PostItem from './PostItem';
import SourceItem from './SourceItem';
import { CurrencyFormat, XAxisDateFormats } from '../widget/types';
import LookupItem from './LookupItem';
import { FieldDataType, FieldType } from '../reporting/types';
import { FontCase } from 'models/Theme/Theme';

// TODO: these @sprinklr/display-builder imports need to go away
import { DecimalFormat, getPrettyNumberString, getPrettyTimestamp } from '@sprinklr/display-builder/utils/Number/NumberUtils';

export default class Field<T> {
    name: T;
    type: FieldType;
    dataType?: FieldDataType;

    constructor(name: T, type: FieldType, dataType?: FieldDataType) {
        this.name = name;

        if (typeof type === 'undefined' || type === null) {
            throw new Error('Field type is required!');
        }

        this.type = type;
        this.dataType = dataType;
    }

    getName(): T {
        return this.name;
    }

    cast(value: any): any {
        if (value === null || typeof value === 'undefined') {
            return null;
        }

        // Do not clobber BulkItem objects
        if (value instanceof BulkItem) {
            return value;
        }

        if (typeof value === 'object' && !(value instanceof Date)) {
            // Attempt to cast objects to TopicTheme
            const newTopicTheme = TopicTheme.fromObject(value);
            if (newTopicTheme) {
                return newTopicTheme;
            }

            const benchmarkBrand = BenchmarkBrand.fromObject(value);
            if (benchmarkBrand) {
                return benchmarkBrand;
            }

            const newSlaFrequency = SLAFrequency.fromObject(value);
            if (newSlaFrequency) {
                return newSlaFrequency;
            }

            const newAccountItem = AccountItem.fromObject(value);
            if (newAccountItem) {
                return newAccountItem;
            }

            const newSourceItem = SourceItem.fromObject(value);
            if (newSourceItem) {
                return newSourceItem;
            }

            const newCommunityUser = CommunityUser.fromObject(value);
            if (newCommunityUser) {
                return newCommunityUser;
            }

            const newPostItem = PostItem.fromObject(value);
            if (newPostItem) {
                return newPostItem;
            }

            const lookupItem = LookupItem.fromObject(value);
            if (lookupItem) {
                return lookupItem;
            }
        }

        let intVal: number;

        switch (this.type) {
            case 'INTEGER':
                if (value === '' || value === 0 || value === null || value === undefined) {
                    return 0;
                }

                intVal = parseInt(value, 10);
                if (isNaN(intVal)) {
                    throw new Error('"' + value + '" is not a valid integer');
                }

                return intVal;

            case 'TIME_INTERVAL':
            case 'NUMBER':
            case 'DECIMAL':
                if (value === '' || value === 0 || value === null || value === undefined) {
                    return 0;
                }

                const floatVal: number = parseFloat(value);
                if (isNaN(floatVal)) {
                    throw new Error('"' + value + '" is not a valid decimal');
                }

                return floatVal;

            case 'STRING':
                return value === 0 || value ? value + '' : '';

            case 'DATE':
            case 'TIMESTAMP':
                if (value instanceof Date) {
                    return value;
                }

                if (value === '' || value === null || value === undefined) {
                    return null;
                }

                if (typeof value === 'string') {
                    const ts = Date.parse(value);
                    if (!isNaN(ts)) {
                        return new Date(ts);
                    }

                    const m = moment(value);
                    if (m.isValid()) {
                        return m.toDate();
                    }

                    intVal = parseInt(value, 10);
                    if (isNaN(intVal)) {
                        throw new Error('"' + value + '" is not a valid timestamp');
                    }

                    value = intVal;
                }

                if (typeof value === 'number') {
                    return new Date(value);
                }

                throw new Error('"' + value + '" is not a valid timestamp');

            default:
                throw new Error('Unable to cast type ' + this.type);
        }
    }
}

type FieldTypeValueOpts = {
    dateFormat?: XAxisDateFormats;
    decimalFormat?: DecimalFormat;
    currencyFormat?: CurrencyFormat;
    isPercentage?: boolean;
    valueCase?: FontCase;
};

export const getFieldTypeValue = (value, type: FieldType, options: FieldTypeValueOpts) => {
    const { dateFormat, currencyFormat = '', isPercentage, decimalFormat, valueCase } = options;
    switch (type) {
        case 'TIMESTAMP':
        case 'TIME_INTERVAL':
        case 'DATE':
            const dateValue =
                value.toString() && !moment.isDate(value) ? parseInt(value.toString()) : value;

            return dateFormat === 'auto'
                ? getPrettyTimestamp(moment(dateValue).valueOf())
                : moment(dateValue).format(dateFormat);
        case 'STRING':
            return value;
        default:
            return `${currencyFormat}${getPrettyNumberString(value, decimalFormat, valueCase)}${
                isPercentage ? '%' : ''
            }`;
    }
};
