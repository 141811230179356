import { AnalyticsRequest, GroupBy, Metric, Sort } from './PanelTemplatesTheme/PanelTemplatesTheme';
import { WIDGET_LABEL_SIZE } from '../../components/Widget/WidgetLabel/constants';
import { Panel } from '../../models/Panel/Panel';
import ListeningDimensions from '@sprinklr/stories-services/AnalyticsService/ListeningDimensions';

export var TemplateDNAChart: Panel = {
    id: 'dna-chart',
    name: 'DNA Chart',
    description: '',
    tags: ['Standard', 'DNA Chart', 'landscape', 'portrait'],
    widget: {
        id: '0',
        position: {
            width: '1920px',
            height: '1080px',
            top: '0',
            left: '0',
        },
        classes: 'template_dna_chart',
        children: [
            {
                type: 'heatMapTableChart',
                id: '1',
                name: 'DNA Chart',
                useGlobalTimePeriod: true,
                analyticsRequests: [
                    // Note: All global variables like reportingEngine, report, filters, and projections
                    // are injected via AnalyticsRequest.getRequests()
                    new AnalyticsRequest({
                        limit: 2000,
                        projections: [
                            new Metric({
                                STORY_MESSAGE: { dimensionName: 'NEWS_COUNT', heading: 'Mentions' },
                            }),
                        ],
                        groupBys: [
                            // y = rows
                            new GroupBy({
                                LISTENING: { source: ListeningDimensions.day },
                                INBOUND_MESSAGE: {
                                    source: ListeningDimensions.day,
                                    dimensionName: 'snCTm',
                                },
                                PLATFORM: {
                                    source: ListeningDimensions.day,
                                    dimensionName: 'date',
                                },
                                BENCHMARKING: {
                                    source: ListeningDimensions.day,
                                    dimensionName: 'date',
                                },
                                PAID: {
                                    source: ListeningDimensions.day,
                                    dimensionName: 'measurementTime',
                                },
                                ADVOCACY: {
                                    source: ListeningDimensions.day,
                                    dimensionName: 'date',
                                },
                                STORY_MESSAGE: {
                                    dimensionName: 'STORY_CATEGORY',
                                    limitType: 'NONE',
                                },
                            }),
                            // x = columns
                            new GroupBy({
                                LISTENING: { source: ListeningDimensions.hour },
                                INBOUND_MESSAGE: {
                                    source: ListeningDimensions.hour,
                                    dimensionName: 'snCTm',
                                },
                                PLATFORM: {
                                    source: ListeningDimensions.hour,
                                    dimensionName: 'date',
                                },
                                BENCHMARKING: {
                                    source: ListeningDimensions.hour,
                                    dimensionName: 'date',
                                },
                                PAID: {
                                    source: ListeningDimensions.hour,
                                    dimensionName: 'measurementTime',
                                },
                                ADVOCACY: {
                                    source: ListeningDimensions.hour,
                                    dimensionName: 'TIME_OF_DAY',
                                    groupType: 'FIELD',
                                },
                                STORY_MESSAGE: {
                                    source: ListeningDimensions.day,
                                    dimensionName: 'CREATED_TIME',
                                },
                            }),
                        ],
                        sorts: [new Sort({ order: 'ASC', groupBys: 1 })],
                        timePeriods: [{ key: 'last_7_days' }],
                    }) as any,
                ],
                position: {
                    width: '100%',
                    height: '100%',
                    top: '0',
                    left: '0',
                },
                label: {
                    titleText: 'DNA Chart',
                    enabled: true,
                    size: WIDGET_LABEL_SIZE.primary,
                },
                options: {
                    player: {
                        playing: true,
                        duration: 15,
                    },
                    showLegend: true,
                    legendSize: 20,
                    subType: 'dna',
                    showSentiment: false,
                    smartSize: {
                        enabled: true,
                        mode: 'responsive',
                        size: 'xl',
                    },
                    portrait: {
                        widgetOrientation: 'portrait',
                        showLegend: false,
                        orbSize: 50,
                    },
                },
            },
        ],
    },
};
