export class WidgetDimensionKey {
    name: string;
    isCustom: boolean;
    customName?: string;
    clientId?: number;
    displayName?: string;

    constructor(
        name?: string,
        customName?: string,
        srcType?: string,
        clientId?: number,
        displayName?: string
    ) {
        this.name = name || ('' as any);
        this.isCustom = srcType === 'CUSTOM';
        this.customName = customName;
        this.clientId = clientId;
        this.displayName = displayName;
    }

    static create(json: any) {
        const details = json.details ? json.details : {};
        return new WidgetDimensionKey(
            json.dimensionName,
            details.fieldName,
            details.srcType,
            details.clientId,
            details.displayName
        );
    }

    static customUniquelyIndentify(item: any) {
        // HACKTOWN: This is to handle custom fields that have no childName defined
        if (!item.childName && item.type === 'CUSTOM') {
            item.childName = item.name;
        }

        // For custom fields, create a new unique field because there can be duplicate
        // "childNames" having different "name".  ie. for Dubai Tourism
        item.unique = item.name + ':' + item.childName;
    }

    isEmpty(): boolean {
        return !(
            (this.name && this.name.length !== 0) ||
            (this.customName && this.customName.length !== 0)
        );
    }

    getKey(): string {
        if (this.isCustom) {
            return 'unique'; // This meta-key is generated in customUniquelyIndentify() above
        } else {
            return 'name';
        }
    }

    getValue(): string {
        if (this.isCustom) {
            return this.name + ':' + this.customName;
        } else {
            return this.name;
        }
    }

    getUnique(): string {
        if (this.isCustom) {
            return "C_" + this.customName + "_" + this.name;  // Not all uppercase
        } else {
            return ("D_" + this.name).toUpperCase(); // ALl uppercase
        }
    }

    equals(source: WidgetDimensionKey): boolean {
        return this.name === source.name && this.customName === source.customName;
    }

    equalsArgs(name: string, customName: string): boolean {
        return this.name === name && this.customName === customName;
    }

    compareTo(item: any): number {
        const match = this.getValue();
        const compare = item.name + (item.childName ? ':' + item.childName : '');
        // console.log(match, " compared to ", compare)
        return match > compare ? 1 : compare > match ? -1 : 0;
    }

    toString(): string {
        return this.displayName ? this.displayName : this.name + ':' + this.customName;
    }
}
