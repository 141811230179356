import * as React from 'react';
import { action } from 'mobx';
import { observer } from 'mobx-react';
import { ColorResult, ColorPickerPopover, convertRgba } from 'components/spaceKit';
import { UsePresetColors } from '../../../../hooks/UsePresetColors';

export type ActionColorSwatchGroupProps = {
    value: string | null;
    option: any;
    icon?: string;
    title?: string;
    secondaryIcon?: string;
    color?: string;
    disabled?: boolean;
    onChange?: (rbg: ColorResult) => void;
    onClear?: () => void;
};

// little bit of a sneaky API
// if you pass in a color then it will call the onChange and no mutation will take place
// if you don't pass a color you don't get the onChange callback
//  - instead you must provide and `option` object and a `value` which is the key of the object to mutate

const ActionColorSwatchGroup: React.FC<ActionColorSwatchGroupProps> = ({
    value,
    option,
    icon,
    disabled,
    title,
    secondaryIcon,
    color,
    onChange,
    onClear,
}) => {
    const currentColor = color ? color : option && option[value];
    const [presetColors, setPresetColors, canSave] = UsePresetColors(currentColor);

    return (
        <ColorPickerPopover
            icon={icon}
            disabled={disabled}
            toolTip={title}
            secondaryIcon={secondaryIcon}
            onClear={onClear}
            presetColors={presetColors}
            value={color ? color : (option && option[value]) || ''}
            onChange={
                color
                    ? onChange
                    : option && action((obj: ColorResult) => (option[value] = convertRgba(obj)))
            }
            canSavePreset={canSave}
            onSavePresetColor={(presetColor: ColorResult) => {
                !!setPresetColors ? setPresetColors(convertRgba(presetColor)) : undefined;
            }}
        />
    );
};

ActionColorSwatchGroup.displayName = 'ActionColorSwatchGroup';
export default observer(ActionColorSwatchGroup);
