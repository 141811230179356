import * as React from 'react';
import { timeIntervals } from '../../PanelDesign/PanelDesignOptions';
import Moment from 'moment';
import { action, computed, observable } from 'mobx';
import LabelGroup from '../../../../_UI/Forms/LabelGroup/LabelGroup';
import { observer } from 'mobx-react';
import { Widget } from '@sprinklr/stories/widget/Widget';
import { Theme } from 'models/Theme/Theme';
import { Threshold } from 'src/components/Threshold/Thresholds';
import ActionSelectGroup from '../../../../_UI/Forms/Actions/ActionSelectGroup';
import { convertThresholdTimeInterval } from 'components/Threshold/ThresholdAxis';
import i18n from 'i18next';
import ActionColorSwatchGroup from '../../../../_UI/Forms/Actions/ActionColorSwatchGroup';
import { Flexbox, IconButton, Input } from 'components/spaceKit';

interface ThresholdToolProps {
    widget: Widget;
    theme: Theme;
    colorPickerValue?: string;
    thresholdType: 'multipleLine' | 'stackedBar' | 'heatMap';
}

class ThresholdTool extends React.Component<ThresholdToolProps, any> {
    @observable editing = true;
    @observable newThreshold: {
        newColor: string;
        newName: string;
        newValue: number;
    } = {
        newColor: '#999',
        newName: '',
        newValue: undefined,
    };

    @computed get metricType(): string {
        const widget = this.props.widget;
        // Determines if the threshold will be measured against an amount ('NUMBER') or a time duration ('TIME_INTERVAL')
        let type = 'NUMBER';
        if (widget.analyticsRequests?.[0]?.projections?.length > 0) {
            widget.analyticsRequests[0].projections.forEach(projection => {
                if (projection?.details?.dataType === 'TIME_DIFFERENCE') {
                    type = 'TIME_INTERVAL';
                }
            });
        }
        return type;
    }

    @computed get validateNewThreshold(): boolean {
        return !!(
            this.newThreshold.newColor &&
            this.newThreshold.newName !== '' &&
            this.newThreshold.newValue
        );
    }

    render() {
        // tool
        const { widget, thresholdType } = this.props;
        console.log('widget.options.thresholds', widget.options?.thresholds);
        if (!widget || !widget.options || !widget.options.thresholds) {
            return null;
        }

        // threshold
        const maxThresholds = (widget.options as any).maxThresholds;
        const thresholds = widget.options.thresholds;
        // computed?
        const hasThresholds = thresholds && thresholds.length > 0;
        const canAddThresholds = thresholds.length < maxThresholds;
        const hasMultipleThresholds = thresholds.length > 1;

        return (
            <Flexbox vertical gap key='threshold__tunables'>
                {/* Per Threshold */}
                {thresholds.map((threshold: Threshold, i: number) => {
                    const lastThreshold = thresholds.length - 1 === i;
                    let value = threshold.value;

                    if (this.metricType === 'TIME_INTERVAL' && threshold.timeInterval) {
                        value = convertThresholdTimeInterval(value, threshold.timeInterval);
                    }

                    return (
                        <React.Fragment key={`threshold-tool_${i}`}>
                            <Flexbox middle gap key='threshold-name-value'>
                                {thresholdType === 'stackedBar' && (
                                    <>
                                        <Input
                                            key={`threshold-stacked-name-${i}`}
                                            placeholder={i18n.t('Threshold Name')}
                                            value={threshold.name}
                                            onChange={action(
                                                (event: any) =>
                                                    (threshold.name = event.target.value)
                                            )}
                                        />
                                        <Input
                                            key={`threshold-stacked-value-${i}`}
                                            type='number'
                                            placeholder={i18n.t('Value')}
                                            value={value}
                                            onChange={e => this.updateThreshholdValue(e, i)}
                                        />
                                    </>
                                )}

                                <ActionColorSwatchGroup
                                    key={`thresholdColor-${i}`}
                                    value='color'
                                    option={threshold}
                                />

                                {thresholdType !== 'stackedBar' && (
                                    <>
                                        <Input
                                            key={`threshold-name-${i}`}
                                            placeholder={i18n.t('Threshold Name')}
                                            value={threshold.name}
                                            onChange={action(
                                                (event: any) =>
                                                    (threshold.name = event.target.value)
                                            )}
                                        />
                                        <Input
                                            key={`threshold-value-${i}`}
                                            type='number'
                                            placeholder={i18n.t('Value')}
                                            value={value}
                                            onChange={e => this.updateThreshholdValue(e, i)}
                                        />
                                    </>
                                )}

                                {/* Remove */}
                                {hasMultipleThresholds && (
                                    <IconButton
                                        icon='icon-minus'
                                        onClick={event => this.removeThreshold(event, i)}
                                    />
                                )}

                                {/* Add Button */}
                                {lastThreshold && canAddThresholds && !this.editing && (
                                    <IconButton icon='icon-plus' onClick={this.startNewThreshold} />
                                )}
                            </Flexbox>

                            {/* Time Interval */}
                            {this.metricType === 'TIME_INTERVAL' && (
                                <div className='flex between middle mb-05'>
                                    <LabelGroup label='Threshold Time Interval'>
                                        <ActionSelectGroup
                                            name='threshold-time-interval'
                                            options={timeIntervals}
                                            option={threshold}
                                            value='timeInterval'
                                        />
                                    </LabelGroup>
                                </div>
                            )}
                        </React.Fragment>
                    );
                })}

                {/* New Threshold */}
                {this.editing && canAddThresholds && (
                    <Flexbox middle gap key='threshold-add-new'>
                        <ActionColorSwatchGroup
                            key={`threshold-color-new`}
                            value='newColor'
                            option={this.newThreshold}
                        />
                        <Input
                            key={`threshold-name-new`}
                            placeholder='Threshold Name'
                            value={this.newThreshold.newName}
                            onChange={action(
                                (event: any) => (this.newThreshold.newName = event.target.value)
                            )}
                        />
                        <Input
                            key={`threshold-value-new`}
                            type='number'
                            placeholder='Value'
                            value={this.newThreshold.newValue}
                            onChange={this.updateValue}
                        />

                        {/* Cancel */}
                        {!this.validateNewThreshold && hasThresholds && (
                            <IconButton icon='x' onClick={this.cancelThreshold} />
                        )}

                        {/* Confirm */}
                        {this.validateNewThreshold && (
                            <IconButton icon='check' onClick={this.addNewThreshold} />
                        )}
                    </Flexbox>
                )}
            </Flexbox>
        );
    }

    @action
    private addNewThreshold = () => {
        // move threshold in state, to the widget
        this.props.widget.options.thresholds.push({
            name: this.newThreshold.newName,
            value: this.newThreshold.newValue,
            color: this.newThreshold.newColor,
            timeInterval: 'hours',
        });
        // reset state
        this.newThreshold.newName = '';
        this.newThreshold.newValue = undefined;
        this.newThreshold.newColor = '#999';
        this.editing = false;
    };

    @action
    private startNewThreshold = () => {
        // do i need this safety check?
        if (
            this.props.widget.options.thresholds.length <
            (this.props.widget.options as any).maxThresholds
        ) {
            this.editing = true;
        }
    };

    @action
    private cancelThreshold = () => {
        this.editing = false;
    };

    private calcValue = (event, index?: number): number => {
        let value = event.target.value;
        if (index !== undefined && this.metricType === 'TIME_INTERVAL') {
            value = this.convertIntervalValue(index, value);
        }
        return value;
    };
    @action
    private updateThreshholdValue = (event, index?: number) => {
        this.props.widget.options.thresholds[index].value = this.calcValue(event, index);
    };
    @action
    private updateValue = (event, index?) => {
        this.newThreshold.newValue = this.calcValue(event, index);
    };

    private convertIntervalValue(index, value) {
        const widget = this.props.widget;
        const timeInterval = widget.options.thresholds[index].timeInterval; // seconds, hours, days, etc.
        const time = Moment.duration(Math.round(value), timeInterval); // generates a Moment (duration) from the value/interval.
        const convertedValue = time.asMilliseconds(); // translates the Moment into a millisecond value.
        return convertedValue;
    }

    @action
    private removeThreshold = (event, i) => {
        this.props.widget.options.thresholds.splice(i, 1);
    };
}

export default observer(ThresholdTool);
