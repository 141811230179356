import { CommonDocFields } from '../CommonDoc/CommonDoc';
import { CommonDoc } from '@sprinklr/stories/common/CommonDoc';

export interface PdfPage extends Completable {
    number?: number;
    panelId?: string;
    panelVersionId?: string;
}

export interface Pdf extends CommonDoc, Completable {
    storyboardVersionId?: string;
    downloadUrl?: string;
    storyboardId?: string;
    size?: number;
    filename?: string;
    pageCount?: number;
    creationDuration?: number;
    pages?: Array<PdfPage>;
}

export interface Completable {
    failure?: boolean;
    complete?: boolean;
    errorMessage?: string;
}

export class CompletableFields {
    public static readonly fields = `failure complete errorMessage`;
    public static readonly toString = () => CompletableFields.fields;
}

export class PdfPageFields {
    public static readonly fields = `
        ${CompletableFields}
        number
        panelId
        panelVersionId
    `;
    public static readonly toString = () => PdfPageFields.fields;
}

export class PdfFields {
    public static readonly fields = `
        ${CommonDocFields}
        filename
        storyboardId
        storyboardVersionId
        downloadUrl
        ${CompletableFields}
        pages {
            ${PdfPageFields}
        }
    `;
    public static readonly toString = () => PdfFields.fields;
}
