import { BaseRecordService } from 'utils/BaseRecordService/BaseRecordService';
import { GraphQLQueries, ModelName } from '../GraphQLQueries/GraphQLQueries';
import { GraphQLService } from '../GraphQLService/GraphQLService';
import { Mapper } from '../DataStore/DataStore';
import { ScheduledStoryboard } from 'models/ScheduledStoryboard/ScheduledStoryboard';

const MODELNAME: ModelName = 'scheduledStoryboard';

export class ScheduledStoryboardService extends BaseRecordService<ScheduledStoryboard> {
    private queries: GraphQLQueries;

    constructor(graphQL: GraphQLService, mapper: Mapper<ScheduledStoryboard>) {
        super(graphQL, mapper);
        this.queries = new GraphQLQueries();
    }

    addScheduledStoryboard(scheduledStoryboard: ScheduledStoryboard) {
        return this.mutate(
            this.queries.mutationDescriptor('addScheduledStoryboard', MODELNAME, {
                inputScheduledStoryboard: scheduledStoryboard,
            })
        );
    }

    updateScheduledStoryboard(scheduledStoryboard: ScheduledStoryboard) {
        return this.mutate(
            this.queries.mutationDescriptor('updateScheduledStoryboard', MODELNAME, {
                inputScheduledStoryboard: scheduledStoryboard,
            })
        );
    }

    deleteScheduledStoryboard(scheduledStoryboard: ScheduledStoryboard) {
        console.log('deleting', scheduledStoryboard);
        return this.mutate(
            this.queries.mutationDescriptor('deleteScheduledStoryboard', MODELNAME, {
                inputScheduledStoryboard: scheduledStoryboard,
            })
        );
    }
}
