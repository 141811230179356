import * as React from 'react';
import i18n from 'i18next';
import {
    headerTunables,
    opacityTunables,
    smartSizeTunables,
    themeTunables,
} from 'components/Sidebar/RenderTunables/SharedTunables';
import { CollapseProps, TunablesCollapse } from 'components/Sidebar/RenderTunables/RenderTunables';
import { Widget } from '@sprinklr/stories/widget/Widget';
import ActionCheckboxGroup from '../../components/_UI/Forms/Actions/ActionCheckboxGroup';
import { Theme } from 'models/Theme/Theme';
import { DataTableOptions } from 'components/_charts/DataTable/DataTable';
import ActionSliderGroup from 'components/_UI/Forms/Actions/ActionSliderGroup';
import {
    endianOptions,
    horizontalAlignOptions,
} from 'components/Panel/PanelEditorSidebar/PanelDesign/PanelDesignOptions';
import ActionSelectGroup from 'components/_UI/Forms/Actions/ActionSelectGroup';
import ActionSegmentedGroup from 'components/_UI/Forms/Actions/ActionSegmentedGroup';
import ActionInput from 'components/_UI/Forms/Actions/ActionInput';
import LabelGroup from 'components/_UI/Forms/LabelGroup/LabelGroup';
import ActionCheckboxSliderColorSwatchGroup from 'components/_UI/Forms/Actions/ActionCheckboxSliderColorSwatchGroup';
import { action } from 'mobx';
import { BASE_BORDER_COLOR } from './helpers';

const getFallbackColor = (options, mergedTheme: Theme): string => {
    return options?.rows?.borders?.base?.color ?? mergedTheme?.typography?.color ?? '';
};
const DataTableTunables = (widget: Widget, mergedTheme: Theme): TunablesCollapse[] => {
    const options = widget.options as DataTableOptions;

    const showClearBorder = options.rows.borders.base.color !== BASE_BORDER_COLOR;
    return [
        {
            props: {
                label: i18n.t('Table'),
                startOpen: true,
            } as CollapseProps,
            children: [
                ...headerTunables(widget),
                opacityTunables(widget),
                <ActionCheckboxGroup
                    key='widget.options.autoRowHeight'
                    label={i18n.t('Auto Distribute Row Height')}
                    option={options.layout}
                    value='autoRowHeight'
                />,
                <ActionSliderGroup
                    key='widget.options.layout.padding'
                    label={i18n.t('Cell Padding')}
                    option={options.layout}
                    value='padding'
                    showValue
                    min={0}
                    max={100}
                />,
                <ActionSliderGroup
                    key='widget.options.default.size'
                    label={i18n.t('Font Size')}
                    option={options.base}
                    value='size'
                    showValue
                    min={0}
                    max={100}
                />,
                <ActionSegmentedGroup
                    key='widget.options.alignment'
                    label={i18n.t('Alignment')}
                    option={options.base}
                    value={'alignment'}
                    options={horizontalAlignOptions}
                />,
                smartSizeTunables(widget),
            ],
        },
        {
            props: {
                label: i18n.t('Formatting'),
                startOpen: true,
            } as CollapseProps,
            children: [
                <ActionCheckboxGroup
                    key='widget.options.base.usePretty'
                    label={i18n.t('Shortened Number (K,M,B)')}
                    option={options.base.numberFormat}
                    value='usePretty'
                />,
                <LabelGroup key='widget.options.base.decimals' label={i18n.t('Decimals')}>
                    <ActionInput
                        min={0}
                        max={10}
                        type='number'
                        option={options.base.numberFormat}
                        value='decimals'
                    />
                </LabelGroup>,

                <ActionSelectGroup
                    label={i18n.t('Date Format')}
                    key='widget.options.base.timeFormat'
                    option={options.base}
                    value='timeFormat'
                    options={endianOptions}
                />,
            ],
        },
        {
            props: {
                label: i18n.t('Borders'),
                startOpen: true,
            } as CollapseProps,
            children: [
                <ActionCheckboxSliderColorSwatchGroup
                    key='widget.options.border'
                    label={i18n.t('Row Borders')}
                    showValue
                    checkbox={{
                        checked: options.rows.borders.base.enabled,
                        value: 'enabled',
                        option: options.rows.borders.base,
                    }}
                    slider={{
                        min: 10,
                        max: 100,
                        value: 'size',
                        option: options.rows.borders.base,
                    }}
                    colorSwatch={{
                        option: options.rows.borders.base,
                        value: 'color',
                        onClear: showClearBorder
                            ? action(() => (options.rows.borders.base.color = BASE_BORDER_COLOR))
                            : undefined,
                        secondaryIcon: showClearBorder ? 'icon-undo' : undefined,
                        color:
                            options.rows.borders.base.color ||
                            getFallbackColor(options, mergedTheme),
                        onChange: action(
                            ({ rgb: { r, g, b, a } }) =>
                                (options.rows.borders.base.color = `rgba(${r}, ${g}, ${b}, ${a})`)
                        ),
                    }}
                />,
                <ActionCheckboxSliderColorSwatchGroup
                    key='widget.options.top.borders'
                    label={i18n.t('Top Border')}
                    showValue
                    checkbox={{
                        checked: options.rows.borders.top.enabled,
                        value: 'enabled',
                        option: options.rows.borders.top,
                    }}
                    slider={{
                        min: 10,
                        max: 100,
                        value: 'size',
                        option: options.rows.borders.top,
                    }}
                    colorSwatch={{
                        option: options.rows.borders.top,
                        value: 'color',
                        onClear: action(() => (options.rows.borders.top.color = '')),
                        secondaryIcon: options.rows.borders.top.color ? 'icon-undo' : undefined,
                        color:
                            options.rows.borders.top.color ||
                            getFallbackColor(options, mergedTheme),
                        onChange: action(
                            ({ rgb: { r, g, b, a } }) =>
                                (options.rows.borders.top.color = `rgba(${r}, ${g}, ${b}, ${a})`)
                        ),
                    }}
                />,
                <ActionCheckboxSliderColorSwatchGroup
                    key='widget.options.bottom.borders'
                    label={i18n.t('Bottom Border')}
                    showValue
                    checkbox={{
                        checked: options.rows.borders.bottom.enabled,
                        value: 'enabled',
                        option: options.rows.borders.bottom,
                    }}
                    slider={{
                        min: 10,
                        max: 100,
                        value: 'size',
                        option: options.rows.borders.bottom,
                    }}
                    colorSwatch={{
                        option: options.rows.borders.bottom,
                        value: 'color',
                        onClear: action(() => (options.rows.borders.bottom.color = '')),
                        secondaryIcon: options.rows.borders.bottom.color ? 'icon-undo' : undefined,
                        color:
                            options.rows.borders.bottom.color ||
                            getFallbackColor(options, mergedTheme),
                        onChange: action(
                            ({ rgb: { r, g, b, a } }) =>
                                (options.rows.borders.bottom.color = `rgba(${r}, ${g}, ${b}, ${a})`)
                        ),
                    }}
                />,
            ],
        },
        themeTunables(widget, { color: true, image: true, email: true, imageSize: true }),
    ];
};

export default DataTableTunables;
