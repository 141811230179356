import * as React from 'react';
import { observer } from 'mobx-react';
import styled, { css, ColorScheme } from 'components/_UI/designSystem';
import { Flexbox, Text, getFormLabel, getPx, StyledColorSwatch } from 'components/spaceKit';

export const StyledLabelGroup = styled(Flexbox)`
    ${Text.TextReg12} {
        ${getFormLabel}
    }
`;
const StyledFlexbox = styled(Flexbox)`
    ${({ swatchSize }: LabelGroupProps) =>
        swatchSize &&
        swatchSize === 's' &&
        css`
            ${StyledColorSwatch} {
                height: ${getPx(6)};
                width: ${getPx(6)};
            }
        `}
`;

const StyledLabel = styled(Text.TextReg12)`
    word-break: unset;
`;
StyledLabel.displayName = 'StyledLabel';

type LabeSizes = 's' | 'm' | 'l' | 'sm';
type SwatchSizes = 's' | 'm';
export interface LabelGroupProps {
    label?: string;
    labelClasses?: string;
    labelDirection?: 'left' | 'right';
    labelSize?: LabeSizes;
    swatchSize?: SwatchSizes;
    className?: string;
    theme?: ColorScheme;
}

const getLabelSize = (size: LabeSizes) => {
    switch (size) {
        case 's':
            return '15%';
        case 'sm':
            return '18%';
        case 'm':
            return '35%';
        case 'l':
            return '50%';
        default:
            break;
    }
};

const LabelGroup: React.FC<LabelGroupProps> = ({
    className,
    labelSize,
    label,
    children,
    labelDirection,
    swatchSize,
}) => (
    <StyledLabelGroup middle className={className} gap='m'>
        {label && labelDirection !== 'right' && (
            <Flexbox
                style={{
                    minWidth: getLabelSize(labelSize),
                    flex: `0 1 ${getLabelSize(labelSize)}`,
                }}>
                <StyledLabel>{label}</StyledLabel>
            </Flexbox>
        )}

        <StyledFlexbox swatchSize={swatchSize} style={{ width: '50%' }} gap>
            {children}
        </StyledFlexbox>

        {label && labelDirection === 'right' && (
            <Flexbox style={{ width: '50%' }}>
                <StyledLabel>{label}</StyledLabel>
            </Flexbox>
        )}
    </StyledLabelGroup>
);

LabelGroup.displayName = 'LabelGroup';
LabelGroup.defaultProps = {
    labelDirection: 'left',
    labelSize: 'm',
};

export default observer(LabelGroup);
