import { BarChartData } from 'components/_charts/BarChart/BarChart';
import { FieldType } from '@sprinklr/stories/reporting/types';
import moment from 'moment';
import DataSet from '@sprinklr/stories/analytics/DataSet';
import { normalizeLabel } from 'utils/NormalizeLabel/NormalizeLabel';
import { BarChartWidgetOptions, BarChartWidgetOptionsImpl } from './options';
import { Theme } from 'models/Theme/Theme';
import { ComputedStyle, styler } from 'utils/GenerateStyles/GenerateStyles';
import Color from 'color';
import _sum from 'lodash/sum';
import { Threshold } from 'components/Threshold/Thresholds';
import { SortOption } from '@sprinklr/stories/widget/types';
import { Aggregation, DataItem } from './types';
import { extent } from 'd3';

export const getDimensionGroupedData = ({
    currentDataSet,
    previousDataSet,
    blacklist,
    firstMetricIndex,
    firstDimensionIndex,
    requestTimeZone,
    endianTime,
}) => {
    return currentDataSet.rows
        .filter(row => blacklist.indexOf(row[0]) === -1)
        .map(
            (row): BarChartData => {
                let percentChange = previousDataSet ? 0 : null;
                const total: number = row[firstMetricIndex] || 0;
                const type: FieldType =
                    currentDataSet.metrics[
                        currentDataSet.metrics && currentDataSet.metrics.length < 2
                            ? 0
                            : firstMetricIndex
                    ].type;

                const labelRaw = row[firstDimensionIndex] ? row[firstDimensionIndex] : '';
                const label =
                    labelRaw instanceof Date
                        ? currentDataSet.dimensions[firstDimensionIndex].stringify(
                              row[firstDimensionIndex],
                              endianTime,
                              requestTimeZone
                          )
                        : labelRaw;
                const rowKey = currentDataSet.rowKey(row);

                if (previousDataSet) {
                    const previousRows = previousDataSet.rows.filter((prevPeriodRow: any[]) => {
                        const prevRowKey = previousDataSet.rowKey(prevPeriodRow);

                        // if it's a date the keys won't match so pass it through
                        if (moment.isDate(prevPeriodRow[firstDimensionIndex])) {
                            return true;
                        }
                        return prevRowKey === rowKey;
                    });

                    if (previousRows.length > 0) {
                        const previousRow = previousRows[0];
                        const previousValue: number = previousRow[firstMetricIndex] || 0;
                        if (previousValue === 0 && total > 0) {
                            percentChange = 100;
                        } else if (previousValue === 0 && total === 0) {
                            percentChange = 0;
                        } else {
                            percentChange = (total / previousValue - 1) * 100;
                        }
                    } else if (total > 0) {
                        percentChange = 100;
                    }
                }

                if (currentDataSet.metrics.length > 2) {
                    // let positive: number = row[2] || 0;
                    const negative: number = row[firstMetricIndex + 2] || 0;
                    // let neutral: number = row[4] || 0;

                    // if negative, there are more negative posts than positive
                    // let sentiment: number = (positive + neutral * .5)  - negative; // HACK
                    const sentiment: number = !total ? 0 : 0.5 - negative / total; // DAN'S BEAUTIFUL MATH
                    // let sentiment: number = positive - negative;

                    return {
                        label,
                        value: total,
                        type,
                        sentiment:
                            sentiment < 0 ? 'negative' : sentiment > 0 ? 'positive' : 'neutral',
                        percentChange,
                    };
                }
                return {
                    label,
                    value: total,
                    type,
                    percentChange,
                    labelRaw,
                };
            }
        );
};

export const getMetricGroupedData = (
    currentDataSet: DataSet,
    previousDataSet: DataSet
): BarChartData[] => {
    return currentDataSet.metrics.map((metric, index) => {
        const currentValue = currentDataSet.rows[0][index];
        const previousValue = previousDataSet?.rows[0][index];
        return {
            label: normalizeLabel(metric.name + ''),
            labelRaw: metric.name + '',
            value: currentDataSet.rows[0][index],
            type: metric.type,
            percentChange: DataSet.percentChanged(currentValue, previousValue),
        };
    });
};

export const barChartOptionStyles = (
    options: BarChartWidgetOptionsImpl & BarChartWidgetOptions,
    theme: Theme,
    inheritedTheme: Theme
) => {
    const {
        padding,
        spacing,
        labelSize,
        borderRadius,
        borderRadiusMode,
        borderRadiusStart,
        borderRadiusEnd,
        percentChangeColor,
        orientation,
        centerAxis,
        showYAxis,
        xAxis,
    } = options;

    const { typography: { color: textColor } = null } = inheritedTheme;

    const computedStyles: ComputedStyle[] = [
        {
            selector: '.bar_chart .meta_group',
            styles: {
                paddingRight: styler(padding, 0.1, 'em', ''),
                paddingLeft: styler(padding, 0.1, 'em', ''),
            },
        },
        {
            selector: '.bar_chart_outer',
            styles: {
                writingMode: 'vertical-lr',
                transform: 'rotate(-180deg)',
                textAlign: 'center',
                fontSize: `${xAxis.label.size * 0.75}px`,
                padding: '0.5em',
            },
        },
        {
            selector: '.bar_chart_inner.vertical .bar',
            styles: {
                margin: styler(spacing, 0.1, 'em 0', ''),
            },
        },
        {
            selector: '.bar_chart_inner.horizontal .bar',
            styles: {
                margin: styler(spacing, 0.1, 'em', '0em'),
            },
        },
        {
            selector: '.bar_chart_inner.horizontal .bar',
            styles: {
                fontSize: styler(labelSize, 0.1, 'em', '0'),
            },
        },
    ];

    if (centerAxis?.enabled) {
        computedStyles.push(
            {
                selector: '.center_axis_vertical',
                styles: {
                    width: styler(centerAxis?.size, 0.01, 'em', ''),
                    margin: `0 ${styler(centerAxis?.spacing, 0.1, 'em', '')}`,
                },
            },
            {
                selector: '.center_axis_horizontal',
                styles: {
                    height: styler(centerAxis?.size, 0.01, 'em', ''),
                    margin: `${styler(centerAxis?.spacing, 0.1, 'em', '')} 0`,
                },
            },
            {
                selector: '.center_axis',
                styles: {
                    background: !!centerAxis.color
                        ? centerAxis.color
                        : Color(textColor)
                              .alpha(0.5)
                              .string(),
                },
            }
        );
    }

    if (xAxis.enabled) {
        computedStyles.push({
            selector: '.bar_chart',
            styles: {
                paddingRight: '1em',
                paddingLeft: '1em',
            },
        });
        computedStyles.push({
            selector: '.bar_chart:not(.stacked_bar_chart) + .bar_axis',
            styles: {
                width: 'calc(100% - 2em)',
                marginLeft: '1em',
            },
        });
        if (xAxis.ticks.label.enabled) {
            computedStyles.push({
                selector: '.bar_chart.horizontal .bar_name_group',
                styles: {
                    display: 'none',
                },
            });
            computedStyles.push({
                selector: '.bar_chart  .horizontal .bar_image',
                styles: {
                    bottom: '10%',
                },
            });
        }
    }

    if (borderRadiusMode === 'all') {
        computedStyles.push({
            selector: '.bar_chart .bar_ratio_fill',
            styles: {
                borderRadius: styler(borderRadius, 0.1, 'em', ''),
            },
        });
    } else if (borderRadiusMode === 'ends') {
        computedStyles.push({
            selector: '.bar_chart .bar_ratio_fill',
            styles: {
                borderTopLeftRadius: styler(
                    orientation === 'vertical' ? borderRadiusStart : borderRadiusEnd,
                    0.1,
                    'em',
                    ''
                ),
                borderBottomLeftRadius: styler(
                    orientation === 'vertical' ? borderRadiusStart : borderRadiusStart,
                    0.1,
                    'em',
                    ''
                ),
                borderTopRightRadius: styler(
                    orientation === 'vertical' ? borderRadiusEnd : borderRadiusEnd,
                    0.1,
                    'em',
                    ''
                ),
                borderBottomRightRadius: styler(
                    orientation === 'vertical' ? borderRadiusEnd : borderRadiusStart,
                    0.1,
                    'em',
                    ''
                ),
            },
        });
        if (orientation === 'vertical') {
            computedStyles.push({
                selector: '.bar_chart .bipolar_first .bar_ratio_fill',
                styles: {
                    borderTopLeftRadius: styler(borderRadiusStart, 0.1, 'em', ''),
                    borderBottomLeftRadius: styler(borderRadiusStart, 0.1, 'em', ''),
                    borderTopRightRadius: styler(borderRadiusEnd, 0.1, 'em', ''),
                    borderBottomRightRadius: styler(borderRadiusEnd, 0.1, 'em', ''),
                },
            });
        }
        if (orientation === 'horizontal') {
            computedStyles.push({
                selector: '.bar_chart .bipolar_second .bar_ratio_fill',
                styles: {
                    borderTopLeftRadius: styler(borderRadiusStart, 0.1, 'em', ''),
                    borderTopRightRadius: styler(borderRadiusStart, 0.1, 'em', ''),
                    borderBottomRightRadius: styler(borderRadiusEnd, 0.1, 'em', ''),
                    borderBottomLeftRadius: styler(borderRadiusEnd, 0.1, 'em', ''),
                },
            });
        }
    }

    if (percentChangeColor && percentChangeColor.enabled) {
        computedStyles.push(
            {
                selector: '.metric_ratio.change_positive',
                styles: {
                    color: percentChangeColor.up,
                    opacity: 1,
                },
            },
            {
                selector: '.metric_ratio.change_negative',
                styles: {
                    color: percentChangeColor.down,
                },
            },
            {
                selector: '.metric_ratio:not(.change_negative):not(.change_positive)',
                styles: {
                    color: !!percentChangeColor.zero ? percentChangeColor.zero : textColor,
                },
            }
        );
    }

    return computedStyles;
};

export const isBipolar = (data: DataItem[]): boolean => {
    const bipolarItems =
        data &&
        data.length &&
        data.some(item => item.value < 0) &&
        data.some(item => item.value >= 0);

    const flattenedSegments = data.reduce((prev, current) => {
        return [...prev, ...(current?.data ? current?.data?.map(item => item.value) : [])];
    }, []);
    const bipolarSegments =
        flattenedSegments.some(item => item < 0) && flattenedSegments.some(item => item >= 0);

    return bipolarItems || bipolarSegments;
};

// get the array of values
export const getValues = (data: DataItem[]): number[] => {
    return data?.map(item => (isNaN(item.value) ? 0 : item.value));
};
// get the array of values
export const getAbsValues = (data: DataItem[]): number[] => {
    return data?.map(item => (isNaN(item.value) ? 0 : Math.abs(item.value)));
};

// get the abs max value
export const getAbsMaxValue = (data: DataItem[]): number => {
    return data ? Math.max(...getAbsValues(data)) : 0;
};

// get the abs max sum
export const getAbsSum = (data: DataItem[]): number => {
    return data && _sum(getAbsValues(data));
};

// gets an array of the min and max values (will preserve negative numbers)
export const getExtentValues = (data: DataItem[]): number[] => {
    return data && data.length && extent(getValues(data));
};

// sum up the values of the segment values
export const sumSegments = (data: DataItem[]): DataItem[] => {
    return data?.map(bar => {
        return {
            ...bar,
            value: _sum(getValues(bar.data)),
        };
    });
};

// sum up the values of the segment values
export const getSegmentsAbsMax = (data: DataItem[]): number => {
    return Math.max(...data.map(item => getAbsMaxValue(item.data)));
};

export const sortSegments = (data: DataItem[], keys: any[]): DataItem[] => {
    if (!keys) {
        return data;
    }

    // if the key is a Theme Tag object, this changes it to just the name
    if (keys[0]?.name) {
        keys.map((key, i) => {
            keys[i] = key.displayName ?? key.name;
        });
    }
    return data?.map(item => {
        return {
            ...item,
            data: keys
                .map(key =>
                    item.data.find(
                        segment => (segment?.label?.displayName || segment?.label) + '' === key.toString()
                    )
                )
                .map(key => (key === undefined ? { label: '', value: null, ratio: null } : key)), // undefined become placeholder
        };
    });
};

export const getValueRatio = (value: number, relativeTo: number): number => {
    if (relativeTo === 0 || value === 0) {
        return 0;
    }
    return parseFloat(Math.abs((value / relativeTo) * 100).toFixed(2));
};

export const getPolarizedData = (data: DataItem[]): DataItem[][] => {
    return data && [getPositiveData(data), getNegativeData(data)];
};

export const setPolarizedValueRatios = (data: DataItem[][], relativeTo: number): DataItem[][] => {
    return data.map(set => setValueRatios(set, relativeTo));
};

export const sumPolarizedSegmentRatios = (
    data: DataItem[][],
    aggregation: Aggregation,
    absMaxValue?: number,
    stacked100Percent?: boolean
): DataItem[][] => {
    return data.map(set => setSegmentRatios(set, aggregation, absMaxValue, stacked100Percent));
};

export const sumPolarizedSegments = (data: DataItem[][]): DataItem[][] => {
    return data.map(set => sumSegments(set));
};

export const sortBipolarSegments = (data: DataItem[][], keys: string[]): DataItem[][] => {
    return data.map(set => sortSegments(set, keys));
};

const getEmptyDataItem = (index: number) => {
    return {
        label: '',
        value: null,
        ratio: null,
        data: null,
        unique: index,
    };
};
export const getPositive = (data: DataItem[]): DataItem[] => {
    return data.map((item, index) =>
        item?.value >= 0 ? item : (getEmptyDataItem(index) as DataItem)
    );
};
export const getNegative = (data: DataItem[]): DataItem[] => {
    return data.map((item, index) =>
        item?.value < 0 ? item : (getEmptyDataItem(index) as DataItem)
    );
};

export const getData = (data: DataItem[], isPositive: boolean): DataItem[] => {
    const segmented = data.some(item => item?.data?.length);
    if (segmented) {
        return data.map(item => {
            return {
                ...item,
                data: isPositive ? getPositive(item.data) : getNegative(item.data),
            };
        });
    } else {
        return isPositive ? getPositive(data) : getNegative(data);
    }
};
export const getPositiveData = (data: DataItem[]): DataItem[] => {
    return getData(data, true);
};
export const getNegativeData = (data: DataItem[]): DataItem[] => {
    return getData(data, false);
};

export const setValueRatios = (data: DataItem[], relativeTo: number): DataItem[] => {
    return data?.map(item => {
        return {
            ...item,
            ratio: relativeTo === null ? 100 : getValueRatio(item.value, relativeTo),
        };
    });
};

export const setSegmentRatios = (
    data: DataItem[],
    aggregation: Aggregation = 'MAX',
    absMaxValue?: number,
    stacked100Percent?: boolean
): DataItem[] => {
    return data.map(item => {
        const absMax =
            absMaxValue && aggregation === 'MAX' && !stacked100Percent
                ? absMaxValue
                : getAbsMaxValue(item.data);
        const absSum = getAbsSum(item.data);
        const relativeTo = aggregation === 'MAX' ? absMax : absSum;
        return {
            ...item,
            data: setValueRatios(item.data, relativeTo),
        };
    });
};

export const getPolarizedThresholds = (
    thresholds: Threshold[],
    orientation: string
): Threshold[][] => {
    const polarized = [
        thresholds.filter(threshold => threshold.value >= 0),
        thresholds.filter(threshold => threshold.value < 1),
    ];
    return orientation === 'vertical' ? [polarized[0], polarized[1]] : [polarized[1], polarized[0]];
};

export const calculateRatio = (max: number, value: number): number => {
    if (max === 0 || value === 0) {
        return 0;
    }
    return parseFloat(Math.abs((value / max) * 100).toFixed(2));
};

export const sorter = (
    data: BarChartData[],
    sortOption: SortOption,
    direction: 'ASC' | 'DESC'
): BarChartData[] => {
    const sortKey = sortOption === 'label' ? 'label' : 'value';
    if (sortOption === 'none') {
        return data.slice();
    } else {
        return direction === 'ASC'
            ? data.slice().sort((a: any, b: any) => a[sortKey] - b[sortKey])
            : data.slice().sort((a: any, b: any) => b[sortKey] - a[sortKey]);
    }
};
