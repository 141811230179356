import * as React from 'react';
import {
    Flexbox,
    Icon,
    Text,
    getPx,
    getFormLabel,
    StatusDot,
    TooltipPopper,
} from 'components/spaceKit';
import { reset, SpacingSizes } from '@sprinklr/space-kit/lib/designSystem/style';
import styled, { ColorScheme } from 'components/_UI/designSystem';

// LabeledFormElement

type ToolTip = {
    icon?: string;
    content: string | JSX.Element;
};

export type LabeledFormElementProps = {
    label?: string;
    required?: boolean;
    className?: any;
    theme?: ColorScheme;
    toolTip?: ToolTip;
    gap?: SpacingSizes;
    error?: string;
};

const StyledLabeledFormElement = styled(Flexbox)<{ disabled?: boolean }>`
    ${reset}
`;

export const StyledLabel = styled(Text.TextReg12)`
    ${getFormLabel};
`;
const StyledError = styled(Text.TextReg12)`
    margin-top: ${getPx(1)};
`;

const LabeledFormElement: React.FC<LabeledFormElementProps> = ({
    children,
    label,
    required,
    className,
    toolTip,
    error,
    gap = 's',
}) => {
    return (
        <StyledLabeledFormElement className={className} vertical gap={gap}>
            {label && (
                <Flexbox middle noGrow gap='m'>
                    {label && <StyledLabel>{label}</StyledLabel>}
                    {required && <StatusDot size='xs' type='error' />}
                    {toolTip?.content && (
                        <TooltipPopper
                            wrappedElement={<Icon value={toolTip.icon || 'info'} size='s' />}>
                            {typeof toolTip.content === 'string' ? (
                                <Text.TextReg12>{toolTip.content}</Text.TextReg12>
                            ) : (
                                toolTip.content
                            )}
                        </TooltipPopper>
                    )}
                </Flexbox>
            )}

            {children}

            {error && <StyledError contrast='error'>{error}</StyledError>}
        </StyledLabeledFormElement>
    );
};

StyledLabeledFormElement.displayName = 'StyledLabeledFormElement';
LabeledFormElement.displayName = 'LabeledFormElement';
export default LabeledFormElement;
