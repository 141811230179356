import { AnalyticsRequest, GroupBy, Sort, Metric } from './PanelTemplatesTheme/PanelTemplatesTheme';
import { DefaultWidgetPosition } from './PanelTemplatesTheme/constants';
import { Widget } from '@sprinklr/stories/widget/Widget';
import { WIDGET_LABEL_SIZE } from '../../components/Widget/WidgetLabel/constants';
import ListeningDimensions from '@sprinklr/stories-services/AnalyticsService/ListeningDimensions';
import ListeningMetrics from '@sprinklr/stories-services/AnalyticsService/ListeningMetrics';
import { TEXT_SHADOW_DEFAULT } from 'models/Widget/Widget';

export var WidgetBubblePlotChart: Widget = {
    type: 'bubblePlotChart',
    id: 'bubble-plot',
    name: 'Bubble Plot Chart',
    useGlobalTimePeriod: true,
    analyticsRequests: [
        // Note: All global variables like reportingEngine, report, filters, and projections
        // are injected via AnalyticsRequest.getRequests()
        new AnalyticsRequest({
            limit: 2000,
            groupBys: [
                new GroupBy({
                    LISTENING: { source: ListeningDimensions.day },
                    INBOUND_MESSAGE: { source: ListeningDimensions.day, dimensionName: 'snCTm' },
                    PLATFORM: { source: ListeningDimensions.day, dimensionName: 'date' },
                    BENCHMARKING: { dimensionName: 'DAY_OF_WEEK' },
                    PAID: { source: ListeningDimensions.day, dimensionName: 'measurementTime' },
                    ADVOCACY: { source: ListeningDimensions.day, dimensionName: 'date' },
                    RDB_FIREHOSE: {
                        source: ListeningDimensions.day,
                        dimensionName: 'SN_CREATED_TIME',
                    },
                    STORY_MESSAGE: {
                        dimensionName: 'COUNTRY',
                        limitType: 'NONE',
                    },
                }),
                new GroupBy({
                    LISTENING: {
                        source: ListeningDimensions.topicName,
                        limitType: 'TOP',
                        limit: 8,
                    },
                    INBOUND_MESSAGE: { dimensionName: 'ACCOUNT_ID', limitType: 'TOP', limit: 8 },
                    PLATFORM: { dimensionName: 'ACCOUNT_ID', limitType: 'TOP', limit: 8 },
                    BENCHMARKING: { dimensionName: 'BRAND_ID', limitType: 'TOP', limit: 8 },
                    PAID: { dimensionName: 'accountIds', limitType: 'TOP', limit: 8 },
                    ADVOCACY: {
                        dimensionName: 'AUDIENCE_ACTIVITY_TYPE',
                        limitType: 'TOP',
                        limit: 8,
                    },
                    RDB_FIREHOSE: { dimensionName: 'RDB_QUERY_IDS', limitType: 'TOP', limit: 8 },
                    STORY_MESSAGE: {
                        dimensionName: 'PUBLICATION_NAME',
                        limitType: 'TOP',
                        limit: 8,
                    },
                }),
            ],
            projections: [
                new Metric({
                    LISTENING: { source: ListeningMetrics.mentions },
                    INBOUND_MESSAGE: { dimensionName: 'OUTBOUND_COUNT' },
                    PLATFORM: { dimensionName: 'ENGAGEMENT_RATE' },
                    BENCHMARKING: { dimensionName: 'ENGAGEMENT_RATE' },
                    PAID: { dimensionName: 'impressions' },
                    ADVOCACY: {
                        dimensionName: 'ADVOCACY_TOTAL_USER_ENGAGEMENTS',
                        heading: 'Total_Engagements',
                    },
                    RDB_FIREHOSE: {
                        dimensionName: 'REACH_COUNT',
                        heading: 'Reach',
                    },
                    STORY_MESSAGE: {
                        dimensionName: 'NEWS_COUNT',
                        heading: 'News_Mentions',
                    },
                }),
            ],
            sorts: [new Sort({ order: 'DESC', projections: 0 })],
            timePeriods: [{ key: 'last_7_days' }, { previousPeriod: true }],
        }) as any,
    ],
    label: {
        titleText: 'Bubble Plot Chart',
        enabled: false,
        size: WIDGET_LABEL_SIZE.primary,
        textShadow: TEXT_SHADOW_DEFAULT,
    },
    position: DefaultWidgetPosition,
    options: {
        player: {
            playing: true,
            duration: 15,
        },
        maxItems: 10,
        useThemeColor: false,
        smartSize: {
            enabled: true,
            mode: 'responsive',
            size: 'm',
        },
        portrait: {
            widgetOrientation: 'portrait',
        },
    },
};
