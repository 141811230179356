import { action, computed } from 'mobx';
import { ImagePreload } from 'utils/ImagePreload/ImagePreload';
import { Post } from '@sprinklr/stories/post/Post';
import { PostsRequest } from '@sprinklr/stories/post/PostsRequest';
import { Source } from './Source';
import { InterstitialItem, SourceInterstitial } from '@sprinklr/stories/post/PostsFormatRequest';
import { PromiseWithCancel } from 'services/SprinklrAPIService/SprinklrAPIService';
import SignalService from 'services/SignalService/SignalService';

export interface InterstitialOptions {
    imagePreload: boolean; // Should image preload happen?
}

export class SourceInterstitials extends Source<Post, PostsRequest> {
    options: InterstitialOptions = { imagePreload: false };
    private data: InterstitialItem[];
    private preload = new ImagePreload<Post>();
    private settingInterstitials = false;

    constructor(
        signalService: SignalService,
        data: InterstitialItem[],
        private disableImagePreload: boolean = false
    ) {
        super(signalService, 'snMsgId');

        this.data = data;

        this.setData(null);
    }

    static create(
        signalService: SignalService,
        source: SourceInterstitial,
        disableImagePreload = false
    ): SourceInterstitials {
        return new SourceInterstitials(signalService, source.data, disableImagePreload);
    }

    isLoaded(): boolean {
        return true;
    }

    isShuffle(): boolean {
        return false;
    }

    needsUpdate(): boolean {
        return false;
    }

    updateItems(): PromiseWithCancel<Post[]> {
        return null;
    }

    @computed get items(): Post[] {
        return this.itemsInternal;
    }

    @action setDataS3(posts: Post[]) {
        this.setData(posts);
    }

    @action setData(posts: Post[]) {
        // Guard against recursive entry.  Image preload below is async, and this can
        // be called recursively before it returns and that can cause data sync issues via ArrayDiff.
        //   https://sprinklr.atlassian.net/browse/DISPLAY-1377
        if (this.settingInterstitials) {
            return;
        }

        this.settingInterstitials = true;

        posts = [];

        for (let x = 0; x < this.data.length; x++) {
            const interstitial = this.data[x];

            const post: Post = {
                snMsgId: x.toString(),
                unique: x.toString(),
                partnerId: 0,
                clientId: 0,
                sourceType: 'LISTENING',
                snType: interstitial.type,
                snCreatedTime: 1,
                permalink: '',
                message: interstitial.text,
                textEntities: null,
                senderProfile: null,
                workflowProperties: null,
                location: null,
                language: 'EN',
                images: [],
                videos: [],
                products: [],
            };

            switch (interstitial.type) {
                case 'interstitial_image':
                    post.images.push({ url: interstitial.url });
                    break;

                case 'interstitial_video':
                    post.videos.push({ url: interstitial.url, poster: interstitial.poster });
                    break;

                default:
                    break;
            }

            posts.push(post);
        }

        const changed = this.diff.merge(this.itemsInternal, posts);
        if (changed) {
            if (!this.disableImagePreload && this.options.imagePreload) {
                this.preload.run(changed.added, true).then(
                    action(() => {
                        this.diff.sync(this.itemsInternal, changed);
                        this.signalService.dispatch(Source.changed, this);

                        this.settingInterstitials = false;
                    })
                );
            } else {
                // Wrap in setTimeout to avoid mobx errors about changing observable values within computed func
                setTimeout(() => {
                    this.diff.sync(this.itemsInternal, changed);
                    this.signalService.dispatch(Source.changed, this);

                    this.settingInterstitials = false;
                });
            }
        } else {
            this.settingInterstitials = false;
        }
    }

    // Called by  evaluateSubTree() in Bucket.ts
    static evaluate(property: string, post: any): string {
        switch (property) {
            case 'type':
                return post.snType;

            default:
                return null;
        }
    }
}
