import { generateID } from './PanelTemplatesTheme/helpers';
import { Panel } from '../../models/Panel/Panel';
import { DefaultWidgetPosition } from './PanelTemplatesTheme/constants';

export var TemplateHyperlinkButton: Panel = {
    id: 'hyperlink-button',
    name: 'Hyperlink Button',
    description: '',
    tags: ['blank', 'landscape', 'portrait', 'interstitial'],
    widget: {
        id: generateID(),
        classes: 'template_hyperlink_button',
        children: [
            {
                type: 'hyperlinkButton',
                id: 'hyperlink-button',
                name: 'Hyperlink Button',
                options: {
                    url: 'https://www.yourlink.com',
                    slideNumber: 1,
                    text: 'Click Here',
                    fontSize: 40,
                    buttonBorder: {
                        enabled: false,
                        radius: 15,
                    },
                    font: {},
                },
                position: {
                    width: '50%',
                    height: '30%',
                    top: '25%',
                    left: '25%',
                },
            },
        ],
    },
};
