import { GraphQLService } from '../GraphQLService/GraphQLService';
import { AuditEventConnection, AuditEventFields } from 'models/AuditEvent/AuditEvent';

export interface AuditEventConnectionSearch {
    where?: Clause[];
    filters?: AuditEventFilter[];
    orderBy?: AuditEventSort[];
    after?: string;
    first?: number;
}

export interface AuditEventFilter {
    field?: AuditEventSortField;
    direction: SortDirection;
}

export interface AuditEventSort {
    field?: AuditEventSortField;
    direction?: SortDirection;
}

export interface Clause {
    field?: string;
    operator?: Operator;
    val?: any;
}

type SortDirection = 'ASC' | 'DESC';
type Operator = 'EQ';
type AuditEventSortField = 'ID' | 'CREATED_DATE' | 'ACTION' | 'RESOURCE_TYPE';

const clientAuditEventQuery = `query clientAuditEvents($search: AuditEventSearch) {
    client {
        auditEvents(search: $search) {
            edges {
                cursor
                node {
                    ${AuditEventFields}
                }
            }
            pageInfo {
                startCursor
                endCursor
                hasNextPage
                hasPreviousPage
            }
        }
    }
}
`;

export class AuditEventService {
    constructor(private graphQL: GraphQLService) {}

    public search(searchQuery?: AuditEventConnectionSearch): Promise<AuditEventConnection> {
        const search = searchQuery || {
            first: 20,
            orderBy: {
                direction: 'DESC',
                field: 'ID',
            },
            // where: [
            //     {field: 'action', operator: 'IN', val:['CREATE','DESTROY', 'UPDATE']}
            // ]
        };

        return this.graphQL
            .query({
                query: clientAuditEventQuery,
                variables: { search },
            })
            .then((results: any) => {
                console.log(results);
                return results.client.auditEvents;
            });
    }
}
