import { generateID } from './PanelTemplatesTheme/helpers';
import { WIDGET_LABEL_SIZE } from '../../components/Widget/WidgetLabel/constants';
import { Panel } from '../../models/Panel/Panel';

export var TemplateEditorial6: Panel = {
    id: 'editorial-6',
    name: 'Image 3-up',
    description: '',
    tags: ['Editorial', 'text', 'image', 'landscape', 'portrait', 'interstitial'],
    widget: {
        id: generateID(),
        position: {
            width: '1920px',
            height: '1080px',
            top: '0',
            left: '0',
        },
        classes: 'template_custom',
        label: {
            titleText: 'Panel',
            enabled: false,
            size: WIDGET_LABEL_SIZE.primary,
        },
        children: [
            {
                type: 'customContentImage',
                id: '1',
                name: 'Image',
                position: {
                    width: '50%',
                    height: '100%',
                    top: '0',
                    left: '0',
                    portrait: {
                        width: '100%',
                        height: '60%',
                        top: '0',
                        left: '0',
                    },
                },
                label: {
                    titleText: 'Header',
                    enabled: false,
                    size: WIDGET_LABEL_SIZE.primary,
                },
                options: {
                    player: {
                        playing: false,
                        duration: 15,
                    },
                    contentImageUrl: '/public/assets/MomentofHappiness2.jpg',
                    contentImageFit: 'cover',
                    horizontalAlign: 'right',
                },
            },
            {
                type: 'customContentImage',
                id: '2',
                name: 'Image',
                position: {
                    width: '50%',
                    height: '50%',
                    top: '0',
                    left: '50%',
                    portrait: {
                        width: '50%',
                        height: '40%',
                        top: '60%',
                        left: '0',
                    },
                },
                label: {
                    titleText: 'Header',
                    enabled: false,
                    size: WIDGET_LABEL_SIZE.primary,
                },
                options: {
                    player: {
                        playing: false,
                        duration: 15,
                    },
                    contentImageUrl: '/public/assets/MomentofFriendship1.jpg',
                    contentImageFit: 'cover',
                    verticalAlign: 'bottom',
                },
            },
            {
                type: 'customContentImage',
                id: '3',
                name: 'Image',
                position: {
                    width: '50%',
                    height: '50%',
                    top: '50%',
                    left: '50%',
                    portrait: {
                        width: '50%',
                        height: '40%',
                        top: '60%',
                        left: '50%',
                    },
                },
                label: {
                    titleText: 'Header',
                    enabled: false,
                    size: WIDGET_LABEL_SIZE.primary,
                },
                options: {
                    player: {
                        playing: false,
                        duration: 15,
                    },
                    contentImageUrl: '/public/assets/MomentofTeamwork2.jpg',
                    contentImageFit: 'cover',
                    verticalAlign: 'bottom',
                },
            },
        ],
    },
};
