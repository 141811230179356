import * as React from 'react';
import { observer } from 'mobx-react';
import getPrettyNumber, { getPrettyTimeAgo } from '../../utils/Number/NumberUtils';
import styled, { css } from 'components/_UI/designSystem';
import NativeProfileLockup, {
    StyledUserName,
    StyledSecondaryName,
} from './Parts/NativeProfileLockup';
import NativeText, { StyledNativeText } from './Parts/NativeText';
import NativeMedia, { StyledMedia } from './Parts/NativeMedia';
import {
    useNativePost,
    getNativeMediaOverrides,
    baseStyles,
    getEntityStyles,
    StyledMeta,
    getNativeColor,
} from './Helpers';
import { NativePostProps, StyledPostProps } from './DefaultPost';
import NativeIcon from './Bits/NativeIcon';
import NativeFlex from './Bits/NativeFlex';

const colors = {
    blue: '#013469',
    lightGray: 'rgb(153, 153, 153)',
    black: '#000',
};

const theme = {
    light: {
        link: colors.blue,
        text: colors.black,
        secondary: colors.lightGray,
    },
    dark: {
        link: colors.blue,
        text: colors.lightGray,
        secondary: colors.lightGray,
    },
};

const StyledSmallText = styled.span`
    ${({ colorMode }: StyledPostProps) =>
        css`
            color: ${getNativeColor(theme, colorMode, 'text')};
        `}
    font-size: .7em;
    span {
        font-weight: bold;
    }
`;

const StyledLightText = styled.span`
    ${({ colorMode }: StyledPostProps) =>
        css`
            color: ${getNativeColor(theme, colorMode, 'secondary')};
        `}
    font-size: .7em;
    text-transform: uppercase;
`;

const StyledText = styled.span`
    font-size: 0.85em;
    ${({ colorMode }: StyledPostProps) =>
        css`
            color: ${getNativeColor(theme, colorMode, 'secondary')};
        `}
`;

const StyledPost: any = styled(NativeFlex)`
    ${(props: NativePostProps) => baseStyles(props.options)}
    ${({ ratio }: any) => getNativeMediaOverrides(ratio > 2.3 ? 40 : 60, 60)}

    ${({ colorMode }: StyledPostProps) => getEntityStyles(getNativeColor(theme, colorMode, 'link'))}

    ${StyledMedia} {
        border-radius: 0;
    }
    ${StyledUserName} {
    }
    ${StyledSecondaryName} {
    }
    ${StyledNativeText} {
    }
    ${({ colorMode }: StyledPostProps) =>
        css`
            .extra_post_data_value {
                width: auto;
                color: ${getNativeColor(theme, colorMode, 'secondary')};
            }
        `}
`;

const NativeFlexEngagement = styled(NativeFlex)`
    ${NativeIcon} {
    }
`;

const InstagramPost: React.FC<NativePostProps> = ({
    post,
    playerInterval,
    options,
    extraPostDataTags,
}) => {
    const {
        postRef,
        screenName,
        profileImgUrl,
        images,
        videos,
        snCreatedTime,
        snStats,
        unique,
        snType,
        textEntities,
        location,
        hasMedia,
        text,
        rtl,
        language,
        orientation,
        ratio,
    } = useNativePost(post);

    const { lineClamp, maxCharacters, colorMode, removeUrls, emoji } = options;

    if (!post) {
        return <div key={unique} />;
    }

    const { value, suffix } = getPrettyNumber(snStats.numLikes, 2, false);
    const { value: commentsValue, suffix: commentsSuffix } = getPrettyNumber(
        snStats.numComments,
        2,
        false
    );

    const likes = !!snStats.numLikes && `${value}${suffix}`;
    const comments = !!snStats.numComments && `${commentsValue}${commentsSuffix}`;
    return (
        <StyledPost
            options={options}
            key={unique}
            ref={postRef}
            hasMedia={hasMedia}
            ratio={ratio}
            vertical={orientation === 'vertical'}
            orientation={orientation}>
            <>
                {orientation === 'vertical' && (
                    <>
                        {(profileImgUrl || screenName || snType || (location && location.text)) && (
                            <NativeProfileLockup
                                colorMode={colorMode}
                                pad
                                name={screenName}
                                secondary={
                                    location &&
                                    location.text &&
                                    location.text.toLowerCase() !== 'unknown' &&
                                    location.text
                                }
                                snType={snType}
                                profileImgUrl={profileImgUrl}
                            />
                        )}
                        {hasMedia && (
                            <NativeMedia
                                snType={snType}
                                playerInterval={playerInterval}
                                images={images}
                                videos={videos}
                            />
                        )}
                        <NativeFlex pad vertical gap='m'>
                            <NativeFlexEngagement gap='m' noGrow>
                                <NativeIcon size='l' value='native_twitter_like' />
                                <NativeIcon size='l' value='native_twitter_comment' />
                            </NativeFlexEngagement>

                            {snStats && likes && (
                                <StyledSmallText colorMode={colorMode}>
                                    Liked by <span>{likes} people</span>
                                </StyledSmallText>
                            )}
                            <NativeText
                                removeUrls={removeUrls}
                                emoji={emoji}
                                colorMode={colorMode}
                                hasMedia={hasMedia}
                                lineClamp={lineClamp}
                                maxCharacters={maxCharacters}
                                channel={snType}
                                text={text}
                                rtl={rtl}
                                language={language}
                                textEntities={textEntities && textEntities.message}
                            />

                            <NativeFlex vertical bottom gap='s'>
                                {comments && (
                                    <StyledText colorMode={colorMode}>
                                        {comments} Comments
                                    </StyledText>
                                )}
                                <StyledLightText colorMode={colorMode}>
                                    {getPrettyTimeAgo(snCreatedTime)}
                                </StyledLightText>

                                {extraPostDataTags}
                            </NativeFlex>
                        </NativeFlex>
                    </>
                )}

                {orientation === 'horizontal' && (
                    <>
                        {hasMedia && (
                            <NativeMedia
                                snType={snType}
                                playerInterval={playerInterval}
                                images={images}
                                videos={videos}
                            />
                        )}
                        <StyledMeta pad vertical gap='m'>
                            {(profileImgUrl ||
                                screenName ||
                                snType ||
                                (location && location.text)) && (
                                <NativeProfileLockup
                                    colorMode={colorMode}
                                    name={screenName}
                                    secondary={
                                        location &&
                                        location.text &&
                                        location.text.toLowerCase() !== 'unknown' &&
                                        location.text
                                    }
                                    snType={snType}
                                    profileImgUrl={profileImgUrl}
                                />
                            )}

                            <NativeFlex vertical between gap='m'>
                                <NativeFlex vertical gap='m'>
                                    <NativeText
                                        removeUrls={removeUrls}
                                        emoji={emoji}
                                        colorMode={colorMode}
                                        hasMedia={hasMedia}
                                        lineClamp={lineClamp}
                                        maxCharacters={maxCharacters}
                                        channel={snType}
                                        text={text}
                                        textEntities={textEntities && textEntities.message}
                                    />
                                    {comments && (
                                        <StyledText colorMode={colorMode}>
                                            {comments} Comments
                                        </StyledText>
                                    )}
                                </NativeFlex>
                                <NativeFlex vertical bottom gap='s'>
                                    <NativeFlexEngagement gap='m' noGrow>
                                        <NativeIcon size='m' value='native_twitter_like' />
                                        <NativeIcon size='m' value='native_twitter_comment' />
                                    </NativeFlexEngagement>

                                    {snStats && likes && (
                                        <StyledSmallText colorMode={colorMode}>
                                            Liked by <span>{likes} people</span>
                                        </StyledSmallText>
                                    )}

                                    {extraPostDataTags ? (
                                        <NativeFlex bottom between noGrow>
                                            <StyledLightText colorMode={colorMode}>
                                                {getPrettyTimeAgo(snCreatedTime)}
                                            </StyledLightText>
                                            {extraPostDataTags}
                                        </NativeFlex>
                                    ) : (
                                        <StyledLightText colorMode={colorMode}>
                                            {getPrettyTimeAgo(snCreatedTime)}
                                        </StyledLightText>
                                    )}
                                </NativeFlex>
                            </NativeFlex>
                        </StyledMeta>
                    </>
                )}
            </>
        </StyledPost>
    );
};

InstagramPost.displayName = 'InstagramPost';
export default observer(InstagramPost);
