import { action, computed, observable } from 'mobx';
import MetaInfoService from '../../../services/MetaInfoService/MetaInfoService';
import { WidgetJSON } from '../WidgetWrapper';
import { WidgetRequest } from '../WidgetRequest';
import { WidgetRequestAnalytics } from '../WidgetRequestAnalytics';
import { AnalyticsProjection } from '@sprinklr/stories/analytics/AnalyticsRequest';
import { setObservable } from '../../../utils/Mobx/Utils';

// export interface AnalyticsProjection {
//     heading: string;
//     measurementName: string;
//     aggregateFunction: "SUM" | "AVG" | "MIN" | "MAX" | "STATS";
//     details?: any;
// }

export class WidgetMetric implements WidgetJSON {
    @observable
    isLoading = true;

    @observable
    metricLabel: string;

    @observable
    public projection: AnalyticsProjection;

    private parent: WidgetRequestAnalytics;

    constructor(parent: WidgetRequest, metric?: AnalyticsProjection) {
        if (!metric) {
            metric = {
                heading: null,
                measurementName: null,
                aggregateFunction: 'SUM',
            };
        }

        this.parent = parent as WidgetRequestAnalytics;
        this.projection = metric;

        this.setMetricLabel();
    }

    static clear(projection: AnalyticsProjection): void {
        projection.heading = projection.measurementName = '';
        projection.details = {};
    }

    @computed
    get isIncomplete(): boolean {
        return !this.metric || !this.metric.length;
    }

    @computed
    get heading(): string {
        return this.projection.heading;
    }

    @computed
    get alternateHeading(): string {
        return this.projection.details?.alternateHeading ?? this.projection.heading;
    }

    @computed
    get unique(): string {
        const report = this.report;
        if (report) {
            return ("M_" + report + "_" + this.projection.measurementName).toUpperCase();
        }

        return null;
    }

    @computed
    get metric(): string {
        return this.projection.measurementName;
    }

    @computed
    get aggregateFunction(): string {
        return this.projection.aggregateFunction;
    }

    @computed
    get report(): string {
        if (this.projection.measurementName) {
            if (this.projection.details?.origReport) {
                return this.projection.details.origReport;
            } else {
                return this.parent.report;
            }
        }

        return null;
    }

    setAlternateHeading(alternateHeading: string): void {
        setObservable(this.projection, 'details', 'alternateHeading', alternateHeading);
    }

    setMetric(
        report: string,
        metric: string,
        label?: string,
        dataType?: string,
        additional?: any,
        alternateHeading?: string
    ): void {
        let heading = label || metric;

        // Note users can supply whatever they want for the displayName on the Sprinklr side, but Sprinklr is sensitive
        // to many characters including -!? etc. and will silently fail in some cases, returning zeros for metric values
        // see https://sprinklr.atlassian.net/browse/DISPLAY-1033
        this.projection.heading = heading.replace(/[^\w]/g, '_');
        this.projection.measurementName = metric;

        this.setReport(report);

        if (dataType) {
            // Merge the two values together into one unique dataType.
            // "currency" has values like "USD", "EUR", etc
            if (dataType === 'CURRENCY' && additional && additional.currency) {
                dataType += '_' + additional.currency;
            }

            setObservable(this.projection, 'details', 'dataType', dataType);
        } else {
            delete this.projection.details.dataType;
        }

        this.parent.setSort();
        if (label) {
            this.metricLabel = label;
        } else {
            this.setMetricLabel();
        }
    }

    setAggregateFunction(aggregateFunction: string): void {
        this.projection.aggregateFunction = aggregateFunction as any;
    }

    setPercentile(percentile: number): void {
        setObservable(this.projection, 'details', 'percentiles', [percentile]);
    }

    setReport(report: string): void {
        setObservable(this.projection, 'details', 'origReport', report);
    }

    toJSON(): any {
        return this.projection;
    }

    @action
    private setMetricLabel(): void {
        const engine = this.parent.engine;

        try {
            this.parent.metaInfoService
                .lookupFields(engine, this.parent.report, [ this.metric ])
                .then(data => {
                    if (data?.length) {
                        this.metricLabel = data[0].field?.displayName;
                    } else {
                        console.error(`Failed to lookup fields`, {
                            metric: this.metric,
                            engine: engine,
                        });
                    }
                });
        } catch (err) {
            console.error(`Metadata lookup for ${this.metric} failed`, err);
        } finally {
            this.isLoading = false;
        }
    }
}
