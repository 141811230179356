import { WidgetCustomTableRichText } from './WidgetCustomTableRichText';
import { DefaultPosition } from './PanelTemplatesTheme/constants';
import { Panel } from '../../models/Panel/Panel';
import { WIDGET_LABEL_SIZE } from '../../components/Widget/WidgetLabel/constants';
import { CustomTableChartWidgetOptions } from '../../components/_charts/CustomTableChartWidget/options';

export var TemplateCustomTableChart: Panel = {
    id: 'customTable',
    name: 'Custom Table Chart',
    description: '',
    tags: ['Standard', 'table', 'landscape', 'portrait'],
    widget: {
        id: 'customTableRoot1',
        position: {
            width: '1920px',
            height: '1080px',
            top: '0',
            left: '0',
        },
        classes: 'template_custom_table',
        children: [
            {
                type: 'customTable',
                id: 'customTable1',
                name: 'Custom Table Chart',
                children: [
                    { ...WidgetCustomTableRichText, id: 'richText1' },
                    { ...WidgetCustomTableRichText, id: 'richText2' },
                    { ...WidgetCustomTableRichText, id: 'richText3' },
                    { ...WidgetCustomTableRichText, id: 'richText4' },
                    { ...WidgetCustomTableRichText, id: 'richText5' },
                    { ...WidgetCustomTableRichText, id: 'richText6' },
                    { ...WidgetCustomTableRichText, id: 'richText7' },
                    { ...WidgetCustomTableRichText, id: 'richText8' },
                    { ...WidgetCustomTableRichText, id: 'richText9' },
                ],
                label: {
                    titleText: 'Custom Table Chart',
                    enabled: false,
                    size: WIDGET_LABEL_SIZE.primary,
                },
                position: DefaultPosition,
                options: {
                    player: {
                        playing: true,
                        duration: 15,
                    },
                    maxItems: 10,
                    useThemeColor: false,
                    smartSize: {
                        enabled: true,
                        mode: 'responsive',
                        size: 'xl',
                    },
                    tableData: {
                        rows: [
                            {
                                height: 0.3333,
                                cells: [
                                    { id: 'richText1' },
                                    { id: 'richText2' },
                                    { id: 'richText3' },
                                ],
                            },
                            {
                                height: 0.3333,
                                cells: [
                                    { id: 'richText4' },
                                    { id: 'richText5' },
                                    { id: 'richText6' },
                                ],
                            },
                            {
                                height: 0.3333,
                                cells: [
                                    { id: 'richText7' },
                                    { id: 'richText8' },
                                    { id: 'richText9' },
                                ],
                            },
                        ],
                        columns: [{ width: 0.3333 }, { width: 0.3333 }, { width: 0.3333 }],
                    },
                    portrait: {
                        widgetOrientation: 'portrait',
                    },
                } as CustomTableChartWidgetOptions,
            },
        ],
    },
};
