import { BrandColors } from './PanelTemplatesTheme/constants';
import { generateID } from './PanelTemplatesTheme/helpers';
import { WIDGET_LABEL_SIZE } from '../../components/Widget/WidgetLabel/constants';
import { Panel } from '../../models/Panel/Panel';

export var TemplateEditorial7: Panel = {
    id: 'editorial-7',
    name: 'Single Image',
    description: '',
    tags: ['Editorial', 'text', 'image', 'landscape', 'portrait', 'interstitial'],
    widget: {
        id: generateID(),
        position: {
            width: '1920px',
            height: '1080px',
            top: '0',
            left: '0',
        },
        classes: 'template_custom',
        theme: {
            colorPrimary: BrandColors.lightBlue,
            colorSecondary: BrandColors.red,
            colorNegative: BrandColors.negative,
            colorPostive: BrandColors.lightBlue,
            colorBackground: BrandColors.grey,
            fontFamilyPrimary: 'OpenSansRegular',
            fontFamilySecondary: 'OpenSansBold',
            fontSizePrimary: '20px',
            backgroundImage: { url: '' },
            ext: {
                padding: 0,
            },
        },
        label: {
            titleText: 'Panel',
            enabled: false,
            size: WIDGET_LABEL_SIZE.primary,
        },
        children: [
            {
                type: 'customContentImage',
                id: generateID(),
                name: 'Image',
                position: {
                    width: '100%',
                    height: '100%',
                    top: '0',
                    left: '0',
                    portrait: {
                        width: '100%',
                        height: '100%',
                        top: '0',
                        left: '0',
                    },
                },
                label: {
                    titleText: 'Header',
                    enabled: false,
                    size: WIDGET_LABEL_SIZE.primary,
                },
                options: {
                    player: {
                        playing: false,
                        duration: 15,
                    },
                    contentImageFit: 'cover',
                    contentImageUrl: '/public/assets/MomentofHappiness5.jpg',
                    verticalAlign: 'bottom',
                },
            },
        ],
    },
};
