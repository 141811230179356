import * as React from 'react';
import { action } from 'mobx';
import { observer } from 'mobx-react';
import SAMSearchComponent from '../../../SAMSearch/SAMSearchComponent';
import { SocialMediaAsset } from '../../../../models/SocialMediaAsset/SocialMediaAsset';
import { ImageSwatch, FullPane, Flexbox } from 'components/spaceKit';

type ActionImageSwatchProps = {
    value: string | null;
    option: any;
    hasError?: boolean;
    active?: boolean;
    disabled?: boolean;
    placeholder?: string;
    className?: any;
    title?: string;
};

const ActionImageSwatch: React.FC<ActionImageSwatchProps> = props => {
    const [modalOpen, setModalOpen] = React.useState<boolean>(false);
    const { value, option, className, ...rest } = props;

    const assetSelected = action((asset: SocialMediaAsset) => {
        const url =
            asset &&
            asset.digitalAsset &&
            (asset.digitalAsset.mediaUrl || asset.digitalAsset.previewUrl);
        option[value] = url;
        setModalOpen(false);
    });

    return (
        <>
            <ImageSwatch
                imageUrl={option[value] || ''}
                className={className}
                onPickImage={() => setModalOpen(true)}
                onClearImage={action(() => (option[value] = ''))}
                {...rest}
            />
            <FullPane
                isOpen={modalOpen}
                title='Choose Asset'
                subTitle='Select an asset from the Sprinklr Asset Manager or upload an image'
                onClose={() => setModalOpen(false)}>
                <Flexbox h100>
                    <SAMSearchComponent
                        prepopulate
                        assetTypes={['PHOTO']}
                        onSamAssetSelected={assetSelected}
                    />
                </Flexbox>
            </FullPane>
        </>
    );
};

ActionImageSwatch.displayName = 'ActionImageSwatch';
export default observer(ActionImageSwatch);
