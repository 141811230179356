import { AnalyticsRequest, GroupBy, Metric, Sort } from './PanelTemplatesTheme/PanelTemplatesTheme';
import { Panel } from '../../models/Panel/Panel';
import { WIDGET_LABEL_SIZE } from '../../components/Widget/WidgetLabel/constants';
import ListeningDimensions from '@sprinklr/stories-services/AnalyticsService/ListeningDimensions';

export var TemplateBubbleChart: Panel = {
    id: 'bubble-chart',
    name: 'Bubble Chart',
    description: '',
    tags: ['Standard', 'Bubble Chart', 'landscape', 'portrait'],
    widget: {
        id: '1',
        position: {
            width: '1920px',
            height: '1080px',
            top: '0',
            left: '0',
        },
        classes: 'template_bubble_chart',
        children: [
            {
                type: 'bubbleChart',
                id: '1',
                name: 'bubble',
                useGlobalTimePeriod: true,
                analyticsRequests: [
                    // Note: All global variables like reportingEngine, report, filters, and projections
                    // are injected via AnalyticsRequest.getRequests()
                    new AnalyticsRequest({
                        limit: 6,
                        projections: [
                            new Metric({
                                STORY_MESSAGE: { dimensionName: 'NEWS_COUNT', heading: 'Mentions' },
                            }),
                        ],
                        groupBys: [
                            new GroupBy({
                                LISTENING: { source: ListeningDimensions.wordCloudMessage },
                                INBOUND_MESSAGE: { dimensionName: 'ACCOUNT_ID' },
                                PLATFORM: { dimensionName: 'ACCOUNT_ID' },
                                BENCHMARKING: { dimensionName: 'BENCHMARKING_ACCOUNT_ID' },
                                PAID: { dimensionName: 'accountIds' },
                                ADVOCACY: { dimensionName: 'COMMUNITY_USER_ID' },
                                RDB_FIREHOSE: { dimensionName: 'RDB_QUERY_IDS' },
                                STORY_MESSAGE: { dimensionName: 'COUNTRY' },
                                TWITTER: { dimensionName: 'TRENDING_TOPIC' },
                            }),
                        ],
                        sorts: [
                            new Sort({ order: 'DESC', projections: 0 }),
                            new Sort({ order: 'ASC', groupBys: 0 }),
                        ],
                        timePeriods: [{ key: 'last_7_days' }, { previousPeriod: true }],
                    }) as any,
                ],
                position: {
                    width: '100%',
                    height: '100%',
                    top: '0',
                    left: '0',
                },
                label: {
                    titleText: 'Bubble Chart',
                    overlap: true,
                    enabled: true,
                    size: WIDGET_LABEL_SIZE.primary,
                },
                options: {
                    player: {
                        playing: true,
                        duration: 15,
                    },
                    labelSize: 25,
                    metricValueSize: 48,
                    metricPercentChangeSize: 20,
                    metricNameSize: 15,
                    highlightPercentChange: false,
                    showMetricValue: true,
                    showMetricName: true,
                    showMetricChange: false,
                    smartSize: {
                        enabled: true,
                        mode: 'responsive',
                        size: 'xl',
                    },
                    portrait: {
                        zoom: 55,
                    },
                },
            },
        ],
    },
};
