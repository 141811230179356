import * as React from 'react';
import i18n from 'i18next';
import {
    adsTunables,
    engagementTunables,
    extraPostDataTunables,
    headerTunables,
    imageAdjustmentTunables,
    imagePostTunables,
    nativeStylingTunables,
    onChangeComposition,
    opacityTunables,
    postTitleTunables,
    profanityTunables,
    reviewPostTunables,
    smartSizeTunables,
    textEntityTunables,
    textPostTunables,
    userGroupTunables,
} from 'components/Sidebar/RenderTunables/SharedTunables';
import { CollapseProps, TunablesCollapse } from 'components/Sidebar/RenderTunables/RenderTunables';
import { postCompositionConfigFilmStrip } from 'components/Panel/PanelEditorSidebar/PanelDesign/PanelDesignOptions';
import { Widget } from '@sprinklr/stories/widget/Widget';
import { Theme } from 'models/Theme/Theme';
import ActionSegmentedGroup from 'components/_UI/Forms/Actions/ActionSegmentedGroup';
import { PostCardWidgetOptions } from '../../PostsWidget/options';

const filmStripCompositions = [
    { value: 'a', label: i18n.t('Default'), icon: 'filmstrip_a' },
    { value: 'b', label: i18n.t('Alternate'), icon: 'filmstrip_b' },
    { value: 'c', label: i18n.t('Special'), icon: 'filmstrip_c' },
];

export const getNonNativeTunables = (widget, mergedTheme) => {
    const isNative =
        (widget.options as PostCardWidgetOptions).nativeStyling &&
        (widget.options as PostCardWidgetOptions).nativeStyling.enabled;
    if (!isNative) {
        return [
            {
                props: {
                    label: i18n.t('Post Composition'),
                    startOpen: true,
                } as CollapseProps,
                children: [
                    <ActionSegmentedGroup
                        key='widget.options.postCompositionType'
                        label={i18n.t('Composition')}
                        option={widget.options}
                        value='postCompositionType'
                        options={filmStripCompositions}
                        handleSideEffects={selection =>
                            onChangeComposition(widget, selection, postCompositionConfigFilmStrip)
                        }
                    />,
                ],
            },
            postTitleTunables(widget, mergedTheme),
            textPostTunables(widget, mergedTheme),
            imagePostTunables(widget, mergedTheme),
            reviewPostTunables(widget, mergedTheme),
            imageAdjustmentTunables(widget),
            userGroupTunables(widget),
            engagementTunables(widget),
            adsTunables(widget),
            textEntityTunables(widget),
            profanityTunables(widget),
        ];
    } else {
        return [];
    }
};

const FilmStripTunables = (widget: Widget, mergedTheme: Theme): TunablesCollapse[] => [
    {
        props: {
            label: i18n.t('Film Strip'),
            startOpen: true,
        } as CollapseProps,
        children: [...headerTunables(widget), opacityTunables(widget), smartSizeTunables(widget)],
    },
    nativeStylingTunables(widget),
    extraPostDataTunables(widget, mergedTheme),
    ...getNonNativeTunables(widget, mergedTheme),
];

export default FilmStripTunables;
