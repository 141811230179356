import { AnalyticsRequest, Metric, GroupBy, Sort } from './PanelTemplatesTheme/PanelTemplatesTheme';
import { Panel } from '../../models/Panel/Panel';
import { WIDGET_LABEL_SIZE } from '../../components/Widget/WidgetLabel/constants';
import ListeningDimensions from '@sprinklr/stories-services/AnalyticsService/ListeningDimensions';

export var TemplateStackedColumnChart: Panel = {
    id: 'stacked-column-chart',
    name: 'Stacked Column Chart',
    description: '',
    tags: ['Standard', 'Stacked Column Chart', 'landscape', 'portrait'],
    widget: {
        id: '1',
        position: {
            width: '1920px',
            height: '1080px',
            top: '0',
            left: '0',
        },
        classes: 'template_stacked_colunm_chart',
        children: [
            {
                type: 'stackedBarChart',
                id: '1',
                name: 'Bar Chart',
                useGlobalTimePeriod: true,
                analyticsRequests: [
                    // Note: All global variables like reportingEngine, report, filters, and projections
                    // are injected via AnalyticsRequest.getRequests()
                    new AnalyticsRequest({
                        limit: 2000,
                        projections: [
                            new Metric({
                                ADVOCACY: { dimensionName: 'POINTS' },
                                STORY_MESSAGE: { dimensionName: 'NEWS_COUNT', heading: 'Mentions' },
                            }),
                        ],
                        groupBys: [
                            new GroupBy({
                                LISTENING: {
                                    source: ListeningDimensions.topicName,
                                    limitType: 'TOP',
                                    limit: 16,
                                },
                                INBOUND_MESSAGE: {
                                    dimensionName: 'ACCOUNT_ID',
                                    limitType: 'TOP',
                                    limit: 16,
                                },
                                PLATFORM: {
                                    dimensionName: 'ACCOUNT_ID',
                                    limitType: 'TOP',
                                    limit: 16,
                                },
                                BENCHMARKING: {
                                    dimensionName: 'BRAND_ID',
                                    limitType: 'TOP',
                                    limit: 16,
                                }, //BENCHMARKING_ACCOUNT_ID
                                PAID: { dimensionName: 'accountIds', limitType: 'TOP', limit: 16 },
                                ADVOCACY: {
                                    dimensionName: 'COMMUNITY_USER_ID',
                                    limitType: 'TOP',
                                    limit: 16,
                                },
                                RDB_FIREHOSE: {
                                    dimensionName: 'DAY_OF_WEEK',
                                    limitType: 'TOP',
                                    limit: 8,
                                },
                                STORY_MESSAGE: {
                                    dimensionName: 'PUBLICATION_NAME',
                                    limitType: 'TOP',
                                    limit: 16,
                                },
                            }),
                            new GroupBy({
                                LISTENING: {
                                    source: ListeningDimensions.socialNetwork,
                                    limitType: 'TOP',
                                    limit: 8,
                                },
                                INBOUND_MESSAGE: {
                                    dimensionName: 'SN_TYPE',
                                    limitType: 'TOP',
                                    limit: 8,
                                },
                                PLATFORM: { dimensionName: 'SN_TYPE', limitType: 'TOP', limit: 8 },
                                BENCHMARKING: {
                                    dimensionName: 'BENCHMARKING_SN_TYPE',
                                    limitType: 'TOP',
                                    limit: 8,
                                },
                                PAID: {
                                    dimensionName: 'socialChannelTypes',
                                    limitType: 'TOP',
                                    limit: 8,
                                },
                                ADVOCACY: {
                                    dimensionName: 'AUDIENCE_ACTIVITY_TYPE',
                                    limitType: 'TOP',
                                    limit: 8,
                                },
                                RDB_FIREHOSE: {
                                    dimensionName: 'RDB_QUERY_IDS',
                                    limitType: 'TOP',
                                    limit: 8,
                                },
                                STORY_MESSAGE: {
                                    dimensionName: 'SENTIMENT',
                                    limitType: 'TOP',
                                    limit: 8,
                                },
                            }),
                        ],
                        sorts: [
                            new Sort({ order: 'DESC', projections: 0 }),
                            new Sort({ order: 'ASC', groupBys: 0 }),
                        ],
                        timePeriods: [{ key: 'last_7_days' }],
                    }) as any,
                ],
                position: {
                    width: '100%',
                    height: '100%',
                    top: '0',
                    left: '0',
                },
                label: {
                    titleText: 'Stacked Column Chart',
                    enabled: true,
                    size: WIDGET_LABEL_SIZE.primary,
                },
                options: {
                    player: {
                        playing: true,
                        duration: 15,
                    },
                    orientation: 'horizontal',

                    stacked100Percent: true,
                    stackedInline: true,
                    stackedType: 'a',

                    legendOrientation: 'top',
                    showLegend: true,
                    legendNameSize: 13,
                    labelEnable: false,
                    deprecated: {
                        labelEnable: true,
                    },
                    spacing: 6,

                    minSegmentRatio: 3,
                    smartSize: {
                        enabled: true,
                        mode: 'responsive',
                        size: 'xl',
                    },
                    portrait: {
                        widgetOrientation: 'portrait',
                        stackedType: 'c',
                        labelSize: 20,
                    },
                    barLabelAlignment: 'right',
                    xAxis: {
                        enabled: true,
                        label: {
                            enabled: true,
                            size: 50,
                            padding: 20,
                        },
                        ticks: {
                            enabled: true,
                            label: {
                                enabled: true,
                                size: 50,
                                numberFormat: '1.2k',
                                timeFormat: 'ddd ha',
                                angle: 0,
                            },
                            line: {
                                enabled: false,
                            },
                        },
                    },
                    yAxis: {
                        enabled: false,
                        label: {
                            enabled: true,
                            size: 50,
                            padding: 20,
                        },
                        ticks: {
                            enabled: true,
                            label: {
                                enabled: false,
                                size: 50,
                                numberFormat: '1.2k',
                                timeFormat: 'ddd ha',
                            },
                            line: {
                                enabled: true,
                            },
                        },
                    },
                },
            },
        ],
    },
};
