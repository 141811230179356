import { DefaultPosition } from '../PanelTemplatesTheme/constants';

import { Panel } from 'models/Panel/Panel';
import { WidgetDataTableGroupedSummary } from './WidgetDataTableGroupedSummary';

export const PanelDataTableGroupedSummary: Panel = {
    name: 'Grouped Summary Table',
    id: 'data-table-grouped-summary',
    description: '',
    tags: ['Standard', 'Data Table', 'landscape', 'portrait'],
    widget: {
        id: '1',
        position: {
            width: '1920px',
            height: '1080px',
            top: '0',
            left: '0',
        },
        classes: 'template_data_table_grouped_summary',
        children: [
            {
                ...WidgetDataTableGroupedSummary,
                position: DefaultPosition,
                options: {
                    ...WidgetDataTableGroupedSummary.options,
                    smartSize: {
                        enabled: true,
                        mode: 'responsive',
                        size: 'xl',
                    },
                },
            },
        ],
    },
};
