import {
    PanelPublishResult,
    PanelPublishResultFields,
} from 'models/PublishResult/PanelPublishResult';
import { BaseRecordService } from 'utils/BaseRecordService/BaseRecordService';
import { GraphQLService } from '../GraphQLService/GraphQLService';
import { Mapper } from '../DataStore/DataStore';
import { Panel } from 'models/Panel/Panel';
import { IdentifiedModel } from '@sprinklr/stories/common/IdentifiedModel';

const publishPanelData = `
mutation PublishPanelData($input: PublishPanelDataInput!) {
    publishPanelData(input: $input) {
        panelPublishResult {
            ${PanelPublishResultFields}
        }
        clientMutationId
    }
}
`;

export class PublishingService extends BaseRecordService<PanelPublishResult> {
    constructor(graphQL: GraphQLService, mapper: Mapper<PanelPublishResult>) {
        super(graphQL, mapper);
    }

    publishPanelData(panel: Panel | string): Promise<PanelPublishResult> {
        return this.mutate({
            name: 'publishPanelData',
            queryParams: {
                query: publishPanelData,
                variables: {
                    input: { panelId: this.id(panel) },
                },
            },
            extractor: result => result['publishPanelData']['panelPublishResult'],
            model: 'panelPublishResult',
        }).then((result: PanelPublishResult) => {
            if (result.status !== 'SUCCESS') {
                // if status is non-success, reject!
                return Promise.reject(result);
            }

            return result;
        });
    }

    // TODO: lift up
    id(ident: IdentifiedModel | string) {
        if (typeof ident === 'string') {
            return ident;
        }

        return (ident as IdentifiedModel).id;
    }
}
