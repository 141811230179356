import * as React from 'react';
import { action } from 'mobx';
import { observer } from 'mobx-react';
import { LabeledCheckboxSlider } from 'components/spaceKit';

type ActionCheckboxSliderGroupProps = {
    label?: string;
    title?: string;
    disabled?: boolean;
    showValue?: boolean;
    suffix?: string;
    checkbox: {
        checked?: boolean;
        readOnly?: boolean;
        value: string;
        option: any;
        onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    };
    slider: {
        min?: number;
        max?: number;
        value: string;
        option: any;
        disabled?: boolean;
    };
};

const ActionCheckboxSliderGroup: React.FC<ActionCheckboxSliderGroupProps> = ({
    label,
    showValue,
    suffix,
    checkbox,
    slider,
}: ActionCheckboxSliderGroupProps) => (
    <LabeledCheckboxSlider
        label={label}
        slider={{
            value: slider.option[slider.value],
            min: slider && slider.min,
            max: slider && slider.max,
            disabled: slider && slider.disabled,
            onChange: action(
                (e: React.ChangeEvent<HTMLInputElement>) =>
                    slider && (slider.option[slider.value] = parseFloat(e.target.value))
            ),
        }}
        checkbox={{
            checked: checkbox.option[checkbox.value],
            onChange: action((e: React.ChangeEvent<HTMLInputElement>) => {
                if (checkbox?.onChange) {
                    checkbox.onChange(e);
                }
                checkbox.option[checkbox.value] = e.target.checked;
            }),
        }}
        showValue={showValue}
        suffix={suffix}
    />
);

ActionCheckboxSliderGroup.displayName = 'ActionCheckboxSliderGroup';
export default observer(ActionCheckboxSliderGroup);
