// these 2 scripts need to be first loaded since
// they contain the polyfills for older browsers
import 'core-js/stable';
import 'core-js/features/url';
import 'core-js/features/url-search-params';
import 'regenerator-runtime/runtime';
import 'mobx-react/batchingForReactDom';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import serviceContext from './serviceContext';
import versionConfig from './version.json';

const HOSTNAME_EXCLUSIONS = ['localhost', 'local.sprinklr.com'];
const mode: ApplicationMode = serviceContext.config.applicationMode;
const shouldLogException =
    serviceContext.config.developmentMode === false &&
    !HOSTNAME_EXCLUSIONS.includes(window?.location?.hostname) &&
    !window?.location?.pathname?.includes('/render/panel');

if (shouldLogException) {
    Sentry.init({
        dsn: 'https://78c7a2ebf9fd4cc78f7b196beaa712b7@o590478.ingest.sentry.io/5740128',
        release: `${mode}@${versionConfig?.version}`,
        environment: process.env.TARGET_ENV,
        integrations: [new Integrations.BrowserTracing()],

        tracesSampleRate: 0.5,
    });
}

import App from './containers/App/App';
import { Provider } from 'mobx-react';
import { ThemeProvider, ColorScheme, ColorMode } from 'components/_UI/designSystem';
import { ApplicationMode } from './config';
import { IconContext, colorSchemes } from 'components/spaceKit';
import icons from './components/_UI/designSystem/icons';
import { merge } from 'lodash';
import SpacewebProvider from '@sprinklr/spaceweb/spacewebProvider';
import light from '@sprinklr/spaceweb-themes/lib/hyperspace-light';
import dark from '@sprinklr/spaceweb-themes/lib/hyperspace-dark';

import './utils/localStorage';
import './i18n';

import './assets/apple-touch-icon.png';
import './assets/favicon-32x32.png';
import './assets/favicon-16x16.png';

import GlobalStyle from './utils/Storybook/GlobalStyle';
import { customTheme } from './theme/theme';
import './scss/main.scss';

const colorScheme: ColorMode =
    (!serviceContext.config.sandboxed && (localStorage.getItem('colorScheme') as ColorMode)) ||
    'dark';
const colorMode: ColorMode = mode === 'EMBED' || mode === 'PRESENTATIONS' ? 'light' : colorScheme;
const activeTheme = colorSchemes[colorMode];

const mergedTheme: ColorScheme = merge({}, activeTheme, customTheme[colorMode]);
const spaceWebTheme = { dark, light };

const render = () => {
    ReactDOM.render(
        <Provider {...serviceContext}>
            <SpacewebProvider direction='ltr' theme={spaceWebTheme[colorMode]}>
                <ThemeProvider theme={mergedTheme}>
                    <IconContext.Provider value={icons}>
                        <Router>
                            <>
                                <GlobalStyle theme={mergedTheme} />
                                <App />
                            </>
                        </Router>
                    </IconContext.Provider>
                </ThemeProvider>
            </SpacewebProvider>
        </Provider>,
        document.getElementById('root')
    );
};

if (
    'fetch' in window &&
    'Intl' in window &&
    'URL' in window &&
    'Map' in window &&
    'forEach' in NodeList.prototype &&
    'startsWith' in String.prototype &&
    'endsWith' in String.prototype &&
    'includes' in String.prototype &&
    'includes' in Array.prototype &&
    'assign' in Object &&
    'entries' in Object &&
    'keys' in Object
) {
    render();
} else {
    import('./polyfills').then(render);
}
