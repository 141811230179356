import BulkItem from './BulkItem';
import { AnalyticsGroupBy } from './AnalyticsRequest';

export default class LookupItem extends BulkItem {
    iconUrl: string;
    imageUrl?: string;

    constructor(id: string, name: string) {
        super(id, name);
    }

    toString() {
        return this.name ? this.name : this.id;
    }

    toIconUrl() {
        return this.iconUrl;
    }

    toImageUrl() {
        return this.imageUrl;
    }

    setIconUrl(iconUrl: string): void {
        this.iconUrl = iconUrl || 'circle';
    }

    setImageUrl(imageUrl: string): void {
        this.imageUrl = imageUrl || null;
    }

    static hasIcon(groupBy: AnalyticsGroupBy): boolean {
        switch (groupBy?.dimensionName) {
            case 'PUBLICATION_NAME':
                return true;
        }

        return false;
    }

    static hasImage(groupBy: AnalyticsGroupBy): boolean {
        switch (groupBy?.dimensionName) {
            case 'creativeImage':
            case 'universalImage':
                return true;
        }
        return false;
    }

    // Construct a BulkRenameMe from an object, checking for properties first.
    public static fromObject(object: any) {
        const allProps = ['id', 'name'].every(prop => object.hasOwnProperty(prop));

        if (allProps) {
            const result = new LookupItem(object.id, object.name);

            if (object.iconUrl) {
                result.setIconUrl(object.iconUrl);
            }

            if (object.socialAsset?.mediaUrl) {
                result.setImageUrl(object.socialAsset.mediaUrl);
            }

            return result;
        }
    }

    get sortValue() {
        return this.name;
    }
}
