import { action, computed, observable } from 'mobx';
import {
    AnalyticsEngine,
    AnalyticsFilter,
    AnalyticsRequest,
    AnalyticsSort,
} from '@sprinklr/stories/analytics/AnalyticsRequest';
import MetaInfoService from 'services/MetaInfoService/MetaInfoService';
import { WidgetFilter } from './Filter/WidgetFilter';
import { WidgetFilterAnalytics } from './Filter/WidgetFilterAnalytics';
import WidgetFilterSources from './Filter/WidgetFilterSources';
import { WidgetGroupBy } from './GroupBy/WidgetGroupBy';
import { WidgetMetric } from './Metric/WidgetMetric';
import { WidgetSLAPreset } from './SLAPreset/WidgetSLAPreset';
import { WidgetSort } from './Sort/WidgetSort';
import { WidgetTimePeriod } from './TimePeriod/WidgetTimePeriod';
import { WidgetDimensionKey } from './WidgetDimensionKey';
import { WidgetRequest, WidgetRequestType } from './WidgetRequest';
import { WidgetJSON } from './WidgetWrapper';
import { WidgetDataRequest } from '@sprinklr/stories/widget/Widget';
import { ProfileRequest } from '@sprinklr/stories/profile/ProfileRequest';
import { PostsRequest } from '@sprinklr/stories/post/PostsRequest';
import { PostsFormatRequest } from '@sprinklr/stories/post/PostsFormatRequest';
import { setObservable } from 'utils/Mobx/Utils';
import { DataSource } from 'models/DataSource/DataSource';
import TimePeriod from 'models/TimePeriod/TimePeriod';

export type WidgetReportingRequest =
    | AnalyticsRequest
    | ProfileRequest
    | PostsRequest
    | PostsFormatRequest;

export class WidgetRequestAnalytics extends WidgetRequest {

    constructor(metaInfoService: MetaInfoService, request?: WidgetReportingRequest) {
        super(metaInfoService, request);

        this.createDataIfNeeded(request);
        this.metaInfoService.loadSLAPresets(this.engine);
        this.initialize();
    }

    get type(): WidgetRequestType {
        return 'analytics';
    }

    get engine(): AnalyticsEngine {
        return (this.getRequest() as any).reportingEngine as any;
    }

    @action
    setEngine(value: AnalyticsEngine): void {
        const request: AnalyticsRequest = this.getRequest() as any;

        if (value !== request.reportingEngine) {
            request.reportingEngine = value as any;
            this.metaInfoService.loadSLAPresets(value);

            // Special case for these engines.  There's only one report type for them.
            if (value === 'LISTENING') {
                this.setReport('SPRINKSIGHTS');
            } else if (value === 'INBOUND_MESSAGE') {
                this.setReport('INBOUND_MESSAGE');
            } else if (value === 'TWITTER') {
                this.setReport('TRENDS');
            }
            if (value === 'RDB_FIREHOSE') {
                this.setReport('RDB_FIREHOSE');
            }

            // Clear out values when the engine changes
            if (value !== 'PLATFORM') {
                request.slaConfig = null;
            }

            if (request.projections && request.projections.length) {
                request.projections.length = 1;
                WidgetMetric.clear(request.projections[0]);
            }

            if (request.groupBys && request.groupBys.length) {
                request.groupBys.length = 1;
                WidgetGroupBy.clear(request.groupBys[0]);
            }

            if (value === 'TWITTER') {
                request.projections.length = 0;
                request.groupBys.length = 0;
            }

            if (request.filters) {
                request.filters.length = 0;
            }

            this.sync(true);

            if (value === 'TWITTER') {
                this.addMetric(
                    new WidgetMetric(this, {
                        heading: 'Mentions',
                        measurementName: 'MENTIONS',
                        aggregateFunction: 'SUM',
                    })
                );
                this.addGroupBy(
                    new WidgetGroupBy(this, {
                        heading: 'Trending Topic',
                        dimensionName: 'TRENDING_TOPIC',
                        groupType: 'FIELD',
                    })
                );
            }
        }
    }

    @action
    setSLAPreset(value: WidgetSLAPreset): void {
        const request: AnalyticsRequest = this.getRequest() as AnalyticsRequest;

        setObservable(request, 'additional', 'slaConfig', value);

        // HACKTOWN: cycle update
        const saved = request.reportingEngine;
        request.reportingEngine = 'FORCE UPDATE' as any;
        request.reportingEngine = saved;

        this.slaPreset = value;
    }

    @action
    setTimeField(timeField: string): void {
        const request: AnalyticsRequest = this.getRequest() as AnalyticsRequest;
        setObservable(request, 'timeField', timeField);

        const saved = request.reportingEngine;
        request.reportingEngine = 'FORCE UPDATE' as any;
        request.reportingEngine = saved;

        this.timeField = timeField;
    }

    setTimePeriod(timePeriods: TimePeriod[]): void {
        if (timePeriods && timePeriods.length) {
            this.timePeriods.forEach((period, offset) => {
                if (timePeriods[offset]) {
                    period.copyFrom(timePeriods[offset]);
                }
            });
        }
    }

    get report(): string {
        // TODO: this method is inherited by other widget request types and isn't guaranteed to have an analytics request
        const request: AnalyticsRequest = this.getRequest() as any;
        return request.report;
    }

    @action
    setReport(value: string): void {
        // TODO: this method is inherited by other widget request types and isn't guaranteed to have an analytics request
        const request: AnalyticsRequest = this.getRequest() as any;
        request.report = value as any;
    }

    @action
    setSourceGroup(dimension: string): void {
        const request: AnalyticsRequest = this.getRequest() as any;

        WidgetFilterSources.setFilterDefaults(
            this.engine,
            this.sourceGroup,
            dimension,
            request.filters
        );

        this.sourceGroup = dimension;

        if (dimension === 'HIERARCHY_ID') {
            let appliedHierarchyPath = false;
            this.groupBys.forEach((groupBy: WidgetGroupBy) => {
                if (appliedHierarchyPath) {
                    return;
                }

                if (groupBy.groupType === 'FIELD') {
                    appliedHierarchyPath = true;
                    groupBy.setDimension(
                        new WidgetDimensionKey(
                            'HIERARCHY_PATH',
                            undefined,
                            undefined,
                            undefined,
                            'Hierarchy Path'
                        )
                    );
                }
            });
        }

        this.sync(false, true);
    }

    get limit(): number {
        // TODO: this method is inherited by other widget request types and isn't guaranteed to have an analytics request
        const request: AnalyticsRequest = this.getRequest() as any;
        return request.limit || 100;
    }

    @action
    setLimit(value: number): void {
        // TODO: this method is inherited by other widget request types and isn't guaranteed to have an analytics request
        const request: AnalyticsRequest = this.getRequest() as any;
        request.limit = value;
    }

    get currency(): string {
        const request: AnalyticsRequest = this.getRequest() as any;
        return (request.additional && request.additional.Currency) || '';
    }

    @action
    setCurrency(currency: string): void {
        const request: AnalyticsRequest = this.getRequest() as AnalyticsRequest;

        setObservable(request, 'additional', 'Currency', currency);
    }

    @computed
    get percentChanged(): boolean {
        return this.timePeriods.length === 2 && this.timePeriods[1].previousPeriod;
    }

    setPercentChanged(value: boolean): void {
        // TODO: this method is inherited by other widget request types and isn't guaranteed to have an analytics request
        const request: AnalyticsRequest = this.getRequest() as any;

        if (value && this.timePeriods.length === 1) {
            const period = new WidgetTimePeriod(this);
            period.setTimeZone(this.timePeriods[0].timeZone);
            period.setPreviousPeriod(true);
            this.addElement(this.timePeriods, period, request.timePeriods);
        } else if (!value && this.timePeriods.length === 2) {
            this.removeElement(this.timePeriods, this.timePeriods[1], request.timePeriods);
        }
    }

    @computed
    get sentiment(): boolean {
        const map = this.getMetricMap();
        return map.POS_SEN !== undefined && map.NEG_SEN !== undefined && map.NEU_SEN !== undefined;
    }

    setSentiment(value: boolean): void {
        // TODO: this method is inherited by other widget request types and isn't guaranteed to have an analytics request
        const request: AnalyticsRequest = this.getRequest() as any;
        const map = this.getMetricMap();
        let metric;

        const addMetric = (dimensionName: string) => {
            if (map[dimensionName] === undefined) {
                metric = new WidgetMetric(this);
                metric.setMetric(dimensionName);
                metric.setAggregateFunction('SUM');
                this.addElement(this.metrics, metric, request.projections);
            }
        };

        if (value) {
            addMetric('POS_SEN');
            addMetric('NEG_SEN');
            addMetric('NEU_SEN');
        } else {
            this.removeElement(this.metrics, map.POS_SEN, request.projections);
            this.removeElement(this.metrics, map.NEG_SEN, request.projections);
            this.removeElement(this.metrics, map.NEU_SEN, request.projections);
        }
    }

    canAddFilter(): boolean {
        return true;
    }

    canDeleteFilter(): boolean {
        return true;
    }

    @action
    addMetric(metric?: WidgetMetric): void {
        if (!metric) {
            metric = new WidgetMetric(this);
        }

        if (!this.metrics) {
            this.metrics = [];
        }

        // TODO: this method is inherited by other widget request types and isn't guaranteed to have an analytics request
        const request: AnalyticsRequest = this.getRequest() as any;

        if (!request.projections) {
            request.projections = [];
        }

        this.addElement(this.metrics, metric, request.projections);
        this.setSort();
    }

    @action
    setMetric(origMetric: string, metric: any): void {
        const offset = this.metrics.findIndex((item: WidgetMetric) => {
            return origMetric === item.metric;
        });

        if (offset !== -1) {
            this.metrics[offset].setMetric(metric.name, metric.displayName, metric.dataType);
        }
    }

    @action
    deleteMetric(metric: WidgetMetric): void {
        // TODO: this method is inherited by other widget request types and isn't guaranteed to have an analytics request
        const request: AnalyticsRequest = this.getRequest() as any;
        this.removeElement(this.metrics, metric, request.projections);

        this.setSort();

        // Set the report in case the new first projection has a different one
        if (request.projections && request.projections.length) {
            if (request.projections[0].details && request.projections[0].details.origReport) {
                this.setReport(request.projections[0].details.origReport);
            }
        }
    }

    getExcludedMetrics(metric: WidgetMetric): string[] {
        return null;
        // const result: string[] = [];
        // const offset = this.metrics.indexOf(metric);
        //
        // for (let x = 0; x < this.metrics.length; x++) {
        //     if (x !== offset) {
        //         result.push(this.metrics[x].metric);
        //     }
        // }
        //
        // return result;
    }

    addGroupBy(groupBy?: WidgetGroupBy): void {
        if (!groupBy) {
            groupBy = new WidgetGroupBy(this);
        }

        // TODO: this method is inherited by other widget request types and isn't guaranteed to have an analytics request
        const request: AnalyticsRequest = this.getRequest() as any;
        this.addElement(this.groupBys, groupBy, request.groupBys);
        this.setSort();
    }

    deleteGroupBy(groupBy: WidgetGroupBy): void {
        // TODO: this method is inherited by other widget request types and isn't guaranteed to have an analytics request
        const request: AnalyticsRequest = this.getRequest() as any;
        this.removeElement(this.groupBys, groupBy, request.groupBys);
        this.setSort();
    }

    // For PAID, there can be metrics with many duplicate values across different reports.  If you select
    // a groupby with a different report that the current one, we need to set it to that one for the request.
    // See https://sprinklr.atlassian.net/browse/DISPLAY-2826
    setMetricReports(forMetrics: string[], report: string): void {
        if (!forMetrics || !report) {
            return;
        }

        this.metrics.forEach((metric, offset) => {
            if (forMetrics.indexOf(metric.metric) !== -1) {
                if (offset === 0) {
                    this.setReport(report);
                }
                metric.setReport(report);
            }
        });
    }

    // "sorts": [
    //     {"order": "DESC", "heading": ListeningMetrics.mentions.heading},
    //     {"order": "ASC", "heading": ListeningDimensions.wordCloudMessage.heading}
    // ]

    // "sorts": [
    //     {"order": "ASC", "heading": ListeningMetrics.mentions.heading},
    //     {"order": "ASC", "heading": ListeningDimensions.topicName.heading}
    // ]

    // "sorts": [
    //     {"order": "DESC", "heading": ListeningMetrics.mentions.heading},
    //     {"order": "ASC", "heading": ListeningDimensions.country.heading}
    // ]

    // "sorts": [
    //     {"order": "DESC", "heading": ListeningMetrics.mentions.heading},
    //     {"order": "ASC", "heading": ListeningDimensions.socialNetwork.heading}
    // ]

    // "sorts": [
    //     {"order": "ASC", "heading": ListeningDimensions.day.heading}
    // ]
    setSort(): void {
        const sorts: AnalyticsSort[] = [];
        const count = this.sorts.length;

        // first sort by any metrics DESC
        if (count >= 1 && this.metrics.length) {
            if (!this.metrics[0].heading) {
                return;
            }

            sorts.push({
                order: 'DESC',
                heading: this.metrics[0].heading,
            });
        }

        // then sort by any date dimensions ASC
        if (count >= 2 && this.groupBys.length) {
            if (/* dimension.groupType !== 'DATE_HISTOGRAM' || */ !this.groupBys[0].heading) {
                return;
            }

            sorts.push({
                order: 'ASC',
                heading: this.groupBys[0].heading,
            });
        }

        // TODO: this method is inherited by other widget request types and isn't guaranteed to have an analytics request
        const request: AnalyticsRequest = this.getRequest() as any;

        sorts.forEach((sort: AnalyticsSort, index) => {
            if (this.sorts.length < index + 1) {
                this.addElement(this.sorts, new WidgetSort(this), request.sorts);
            }

            this.sorts[index].setOrder(sort.order);
            this.sorts[index].setHeading(sort.heading);
        });

        this.sorts.length = sorts.length;
    }

    getExcludedGroupBys(groupBy: WidgetGroupBy): WidgetDimensionKey[] {
        const result: WidgetDimensionKey[] = [];
        const offset = this.groupBys.indexOf(groupBy);

        for (let x = 0; x < this.groupBys.length; x++) {
            if (x !== offset && this.groupBys[x].groupType !== 'DATE_HISTOGRAM') {
                result.push(this.groupBys[x].dimension);
            }
        }

        return result;
    }

    @action
    addSort(sort?: WidgetSort): WidgetSort {
        const request: AnalyticsRequest = this.getRequest() as any;
        this.addElement(this.sorts, sort, request.sorts);

        return sort;
    }

    @action
    deleteSorts(): void {
        const request: AnalyticsRequest = this.getRequest() as any;
        this.sorts = [];
        request.sorts = [];
    }

    @action
    addFilter(filter?: WidgetFilter): WidgetFilter {
        // TODO: this method is inherited by other widget request types and isn't guaranteed to have an analytics request
        const request: AnalyticsRequest = this.getRequest() as any;

        if (!filter) {
            filter = new WidgetFilterAnalytics(this);
        }

        this.addElement(this.filters, filter, request.filters);

        return filter;
    }

    @action
    deleteFilter(filter: WidgetFilter): void {
        // TODO: this method is inherited by other widget request types and isn't guaranteed to have an analytics request
        const request: AnalyticsRequest = this.getRequest() as any;

        this.removeElement(this.filters, filter, request.filters);
    }

    /**
     * Merge source filters into the request's filters array
     */
    @action
    setFiltersFromSources(): void {
        const request: AnalyticsRequest = this.getRequest() as any;
        const filters = request.filters;
        let filter: WidgetFilter;
        let values: string[];
        const mapped = {};
        let insertAt = 0;

        // index all source filters by dimension name.  if a filter name comes up twice, combine the value arrays
        for (let x = 0; x < this.sources.length; x++) {
            filter = this.sources[x];

            let entry = mapped[filter.dimension.name];
            if (!entry) {
                entry = mapped[filter.dimension.name] = {
                    filter,
                    values: [],
                    offset: x,
                };
            }

            entry.values.push.apply(entry.values, filter.values);

            // Remove any duplicate values
            entry.values = entry.values.filter((item, pos) => {
                return entry.values.indexOf(item) == pos;
            });
        }

        for (const name in mapped) {
            const source = mapped[name];

            filter = source.filter;
            values = source.values;

            // check if the source filter is already in the filters array
            const found = filters.findIndex((item: AnalyticsFilter) => {
                return name === item.dimensionName && item.filterType === 'IN';
            });

            // Second part of OR is so "Topic Tags" for LISTENING will stay selected
            // when you remove the last value.
            if (source.values.length || (source.offset === 0 && this.sourceGroups.length > 1)) {
                // Not found, insert it at the top
                if (found === -1) {
                    const json = JSON.parse(JSON.stringify(filter.getJSON())); // Make deep copy
                    json.values = values;
                    filters.splice(insertAt, 0, json);

                    // Found it, so update the values
                } else {
                    filters[found].values.length = 0;
                    filters[found].values.push.apply(filters[found].values, values);
                }

                insertAt++;
                // Found it but there aren't any values for it.  Remove it.
            } else if (found !== -1) {
                filters.splice(found, 1);
            }

            insertAt++;
        }
    }

    /**
     * Get list of dimensions that should be omitted from the filter dimension dropdown
     *
     * @param filter
     * @returns {Array<WidgetDimensionKey>}
     */
    getExcludedFilters(filter: WidgetFilter): WidgetDimensionKey[] {
        const result: WidgetDimensionKey[] = [];

        // exclude the fixed data source dimensions
        let x;
        if (this.sources) {
            for (x = 0; x < this.sources.length; x++) {
                result.push(this.sources[x].dimension);
            }
        }

        // exclude filters that are already in use
        // disable, because some customers want to filter some dimensions with different operators
        // const offset = this.filters.indexOf(filter);
        // for (x = 0; x < this.filters.length; x++) {
        //     if (x !== offset) {
        //         result.push(this.filters[x].dimension);
        //     }
        // }

        return result;
    }

    @action
    public applyGlobalDataSource(dataSource: DataSource) {
        super.applyGlobalDataSource(dataSource);

        if (this.engine === dataSource.reportingEngine) {
            const request: AnalyticsRequest = this.getRequest() as any;

            dataSource.filters.forEach(dsf => {
                const existingFilterIdx = request.filters.findIndex(
                    reqFilter =>
                        reqFilter.filterType === 'IN' &&
                        reqFilter.dimensionName === dsf.filter.dimensionName
                );
                if (existingFilterIdx !== -1) {
                    request.filters[existingFilterIdx].values = dsf.filter.values.slice();
                } else {
                    request.filters.push(JSON.parse(JSON.stringify(dsf.filter)));
                }
            });

            dataSource.ancillaryFilters.forEach(dsf => {
                request.filters.push(JSON.parse(JSON.stringify(dsf.filter)));
            });

            if (!this.filters) {
                this.filters = []; // prep for sync - it checks for presence of filters array, empty or not.
            }

            this.sync();
        }
    }

    // This is used when setting "Change" or "% Change".  Zach and Andy wanted the limit to be
    // auto-set in this case so the result would match what Sprinklr UI produces.
    setRecommendedConvertLimit() {
        if (this.metrics.length && this.timePeriods.length) {
            const metric = this.metrics[0];

            switch (metric.aggregateFunction) {
                case 'CHANGE':
                case 'PERCENTAGE_CHANGE':
                    const timePeriod = this.timePeriods[0].calculated;
                    let duration = Math.round(
                        timePeriod.endDate.diff(timePeriod.startDate) / (60 * 1000)
                    );
                    let limit: number;

                    if (duration > 24 * 60) {
                        duration = Math.round(duration / (24 * 60));
                    } else if (duration > 60) {
                        duration = Math.round(duration / 60);
                    }

                    this.setLimit(duration - 1);
                    break;
            }
        }
    }

    /**
     * @param setEngine
     * @param setSourceGroup
     */
    @action
    sync(setEngine?: boolean, setSourceGroup?: boolean) {
        const request: AnalyticsRequest = this.getRequest() as any;

        // Clear out values when the engine changes

        if (
            !setSourceGroup &&
            request.projections &&
            request.projections.length !== this.metrics.length
        ) {
            this.metrics.length = 0;

            for (var x = 0; x < request.projections.length; x++) {
                this.metrics.push(new WidgetMetric(this, request.projections[x]));
            }
        }

        if (
            !setSourceGroup &&
            request.groupBys &&
            request.groupBys.length !== this.groupBys.length
        ) {
            this.groupBys.length = 0;

            for (var x = 0; x < request.groupBys.length; x++) {
                this.groupBys.push(new WidgetGroupBy(this, request.groupBys[x]));
            }
        }

        if (!this.sources) {
            this.sources = [];
        }

        // Clear out sources and filters, then recreate for this new engine
        if (request.filters && this.filters) {
            this.sources.length = this.filters.length = 0;
            WidgetFilterAnalytics.create(this, request.filters);
        }

        if (
            !setSourceGroup &&
            request.timePeriods &&
            (!this.timePeriods || request.timePeriods.length !== this.timePeriods.length)
        ) {
            if (!this.timePeriods) {
                this.timePeriods = [];
            }
            this.timePeriods.length = 0;

            for (var x = 0; x < request.timePeriods.length; x++) {
                this.timePeriods.push(new WidgetTimePeriod(this, request.timePeriods[x]));
            }
        }
    }

    @action
    protected initialize() {
        // TODO: this method is inherited by other widget request types and isn't guaranteed to have an analytics request
        const request = this.getRequest() as any;

        if (!request.projections) {
            setObservable(request, 'projections', []);
        }

        const metrics: WidgetMetric[] = [];

        for (var x = 0; request.projections && x < request.projections.length; x++) {
            metrics.push(new WidgetMetric(this, request.projections[x]));
        }

        this.metrics = metrics;

        if (!request.groupBys) {
            setObservable(request, 'groupBys', []);
        }

        const groupBys: WidgetGroupBy[] = [];

        for (var x = 0; request.groupBys && x < request.groupBys.length; x++) {
            groupBys.push(new WidgetGroupBy(this, request.groupBys[x]));
        }

        this.groupBys = groupBys;

        if (request.additional && request.additional.slaConfig) {
            //makes sure SLA is up-to-date with core -AR
            MetaInfoService.loaderSLAPresets.then(
                action(() => {
                    const preset = this.metaInfoService
                        .getSLAPresets(request.reportingEngine)
                        .find(preset => preset.id === request.additional.slaConfig.id);
                    this.slaPreset = request.additional.slaConfig;
                })
            );
        }

        WidgetFilterAnalytics.create(this, request.filters);

        const sorts: WidgetSort[] = [];
        if (request.sorts) {
            for (var x = 0; x < request.sorts.length; x++) {
                sorts.push(new WidgetSort(this, request.sorts[x]));
            }
        }

        this.sorts = sorts;

        if (request.timePeriod || request.timePeriods) {
            const timePeriods: WidgetTimePeriod[] = [];

            if (request.timePeriod) {
                timePeriods.push(new WidgetTimePeriod(this, request.timePeriod));
            } else {
                for (var x = 0; x < request.timePeriods.length; x++) {
                    timePeriods.push(new WidgetTimePeriod(this, request.timePeriods[x]));
                }
            }

            this.timePeriods = timePeriods;
        }

        this.timeField = request.timeField;
    }

    private getMetricMap(): any {
        const result = {};

        let x = this.metrics.length;
        while (x--) {
            result[this.metrics[x].metric] = this.metrics[x];
        }

        return result;
    }

    // I tried element approach of extending Array to do this first, but turns out mobx can't handle
    // extending Arrays due to limitations of ES5.
    protected addElement(elements: any[], element: WidgetJSON, source: any[]) {
        elements.push(element);
        source.push(element.toJSON());
    }

    // I tried element approach of extending Array to do this first, but turns out mobx can't handle
    // extending Arrays due to limitations of ES5.
    protected removeElement(elements: any[], element: WidgetJSON, source: any[]) {
        const offset = elements.indexOf(element);
        return this.removeElementOffset(elements, offset, source);
    }

    @action
    protected createDataIfNeeded(request: WidgetDataRequest) {
        if (!request) {
            request = {
                reportingEngine: 'LISTENING',
                report: 'SPRINKSIGHTS',
                projections: [],
                groupBys: [],
                filters: [
                    {
                        dimensionName: 'TOPIC_GROUP_IDS',
                        filterType: 'IN',
                        values: [],
                    },
                    {
                        dimensionName: 'TOPIC_IDS',
                        filterType: 'IN',
                        values: [],
                    },
                ],
                timePeriods: [],
            } as AnalyticsRequest;

            this.request = observable(request);
        }

        const analyticsRequest: AnalyticsRequest = request as any;
        if (!analyticsRequest.projections) {
            analyticsRequest.projections = [];
        }

        if (!analyticsRequest.groupBys) {
            analyticsRequest.groupBys = [];
        }
    }

    private removeElementOffset(elements: any[], offset: number, source: any[]) {
        const element = elements[offset];

        if (offset !== -1) {
            elements.splice(offset, 1);

            const json = element.toJSON();
            offset = source.indexOf(json);
            if (offset !== -1) {
                source.splice(offset, 1);
            }
        }
    }
}
