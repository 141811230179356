import {
    AnalyticsRequest,
    GroupBy,
    Metric,
    Sort,
    Label,
} from './PanelTemplatesTheme/PanelTemplatesTheme';
import { Panel } from '../../models/Panel/Panel';
import { WIDGET_LABEL_SIZE } from '../../components/Widget/WidgetLabel/constants';

export var TemplateProfile2by3: Panel = {
    id: 'profile-2-by-3',
    name: 'Profiles (3 x 2)',
    tags: ['Content', 'Profile', 'Smart', 'landscape', 'portrait'],
    description: '',
    widget: {
        position: {
            width: '1920px',
            height: '1080px',
            top: '0',
            left: '0',
        },
        classes: 'template_profile template_profile_2_by_3',
        children: [
            {
                type: 'profile',
                id: '1',
                name: 'profile 1',
                useGlobalTimePeriod: true,
                profileRequests: [
                    // Note: All global variables like reportingEngine, report, filters, and projections
                    // are injected via AnalyticsRequest.getRequests()
                    new AnalyticsRequest({
                        page: 0,
                        pageSize: 50,
                        groupBys: [
                            new GroupBy({
                                LISTENING: { dimensionName: 'ADVOCATES' },
                            }),
                        ],
                        projections: [
                            new Metric({
                                STORY_MESSAGE: {
                                    dimensionName: 'MENTIONS_COUNT',
                                    heading: 'Mentions',
                                },
                            }),
                        ],
                        sorts: [
                            new Sort({
                                order: 'DESC',
                                heading: {
                                    LISTENING: 'MENTIONS_COUNT',
                                    PLATFORM: 'TOTAL_ENGAGEMENT',
                                    BENCHMARKING: 'TOTAL_ENGAGEMENT',
                                    RDB_FIREHOSE: 'SN_CREATED_TIME',
                                    STORY_MESSAGE: 'MENTIONS_COUNT',
                                },
                            }),
                        ],
                        timePeriod: {
                            key: 'last_7_days',
                        },
                    }) as any,
                ],
                position: {
                    width: '100%',
                    height: '50%',
                    left: '0',
                    top: '0',
                },
                options: {
                    player: {
                        playing: true,
                        duration: 15,
                    },
                    numbProfiles: 3,
                    showIcon: true,

                    showMetricValue: true,
                    metricValueSize: 60,

                    showMetricName: true,
                    metricNameSize: 15,
                    showProfileDescription: false,
                    smartSize: {
                        enabled: true,
                        mode: 'responsive',
                        size: 'xl',
                    },
                },
                label: {
                    titleText: new Label({
                        LISTENING: 'Top Posts by Reach',
                        PLATFORM: 'Top Posts by Total Engagements',
                        BENCHMARKING: 'Top Posts by Total Engagements',
                    }) as any,
                    size: WIDGET_LABEL_SIZE.secondary,
                    enabled: true,
                },
            },
            {
                type: 'profile',
                id: '2',
                name: 'profile 2',
                profileRequests: [
                    // Note: All global variables like reportingEngine, report, filters, and projections
                    // are injected via AnalyticsRequest.getRequests()
                    new AnalyticsRequest({
                        page: 0,
                        pageSize: 50,
                        sorts: [
                            new Sort({
                                order: 'DESC',
                                heading: {
                                    LISTENING: 'INFLUENCER_SCORE',
                                    PLATFORM: 'POST_SHARE_COUNT',
                                    BENCHMARKING: 'POST_SHARE_COUNT',
                                    RDB_FIREHOSE: 'SN_CREATED_TIME',
                                    STORY_MESSAGE: 'IMPACT',
                                },
                            }),
                        ],
                        timePeriod: {
                            key: 'last_7_days',
                        },
                    }) as any,
                ],
                position: {
                    width: '100%',
                    height: '50%',
                    left: '0',
                    top: '50%',
                },
                options: {
                    player: {
                        playing: true,
                        duration: 15,
                    },
                    numbProfiles: 3,
                    verticalAlign: 'top',
                    showIcon: true,

                    showMetricValue: true,
                    metricValueSize: 60,

                    showMetricName: true,
                    metricNameSize: 15,

                    showMetricChange: false,
                    metricPercentChangeSize: 20,
                    showProfileDescription: false,
                    smartSize: {
                        enabled: true,
                        mode: 'responsive',
                        size: 'xl',
                    },
                },
                label: {
                    titleText: new Label({
                        LISTENING: 'Top Posts by Influencer Score',
                        PLATFORM: 'Top Posts by Shares',
                        BENCHMARKING: 'Top Posts by Shares',
                    }) as any,
                    size: WIDGET_LABEL_SIZE.secondary,
                    enabled: true,
                },
            },
        ],
        label: {
            titleText: 'Profiles (3 x 2)',
            enabled: true,
            size: WIDGET_LABEL_SIZE.primary,
        },
    },
};
