import { WidgetOptions } from '@sprinklr/stories/widget/WidgetOptions';
import { WidgetOptionsImpl } from 'models/Widget/WidgetOptions';
import { PostTemplate } from 'components/Post/PostGroup';
import {
    BlastDelimiterTypes,
    Emoji,
    PanelOrientation,
    PositionTypesInterface,
    PostCompositionTypeValues,
    PostTextFormat,
    ReviewOptions,
    SmartSize,
    TimeStampOptions,
    TitleOptions,
} from '@sprinklr/stories/widget/types';
import { ProfanityFilter, RetweetIndicatorOptions } from '../PostsWidget/types';
import { observable } from 'mobx';
import { Palette, PostOptionLocation } from './types';

export interface PostsWindowWidgetOptions extends WidgetOptions {
    classNames?: string;
    postConfig?: any;
    // new
    numbPosts?: number;
    postTemplate?: PostTemplate;
    postTransition?: 'scale' | 'fade';
    postDirection?: 'horizontal' | 'vertical' | null;
    userGroupEffect?: 'bottom' | 'top' | 'none';
    // theme?: 'primary' | 'secondary' | null;
    showText?: boolean;
    showSentiment?: boolean;
    showKlout?: boolean;
    showCenterAvatar?: boolean;
    showCenterChannel?: boolean;
    animationLength?: number;
    animationDelay?: number;
    imageStyle?: string;
    showEngagementMetrics?: boolean;
    engagementMetricsPosition?: string;
    showImageStyles?: boolean;
    showUserGroup?: boolean;
    postLineClamp?: boolean;
    postLineClampValue?: number;
    imagePostLineClamp?: boolean;
    imagePostLineClampValue?: number;
    userGroupPosition?: string;
    activePostsInWindow?: number;
    secondaryOverlayColor?: string;
    panelOrientation?: PanelOrientation;
    endianTime?: string;
    timeStampFormat?: TimeStampOptions;

    metaOffsetTotal?: number;
    themeOffsetTotal?: number;
    postComposition?: string;
    smartSize?: SmartSize;
    reviews?: ReviewOptions;
    postTitleOptions?: TitleOptions;
    retweetIndicator?: RetweetIndicatorOptions;
}

export class PostsWindowWidgetOptionsImpl extends WidgetOptionsImpl
    implements PostsWindowWidgetOptions {
    @observable showImageStyles = true; // this shouldn't be in the widget options  - it's just a UI concern

    @observable imageFilterBlur = false;
    @observable imageFilterBrightness = false;
    @observable imageFilterContrast = false;
    @observable imageFilterGrayscale = false;
    @observable imageFilterSaturate = false;
    @observable imageFilterHueRotate = false;
    @observable imageFilterOpacity = false;
    @observable imageFilterInvert = false;
    @observable imageFilterSepia = false;
    @observable imageFilterBlurValue = 0;
    @observable imageFilterBrightnessValue = 100;
    @observable imageFilterContrastValue = 100;
    @observable imageFilterGrayscaleValue = 0;
    @observable imageFilterSaturateValue = 100;
    @observable imageFilterHueRotateValue = 0;
    @observable imageFilterOpacityValue = 100;
    @observable imageFilterInvertValue = 0;
    @observable imageFilterSepiaValue = 0;
    @observable imageBlendMode = 'normal';
    @observable imageSecondaryBlendMode = '';
    @observable imageBlendColor = 'rgba(0,0,0,.0)';
    @observable imageSecondaryBlendColor = '';
    @observable presetImageFilters = '';
    @observable postImageTextSize = 10;
    @observable postImageTextFormat: PostTextFormat = 'fit';
    @observable postImageTeleprompterSpeed = 50;
    @observable showReplyLabels = true;
    @observable showReplyLabelsSize = 20;
    @observable showReplyBorder = true;
    @observable replyBorderColor = 'rgba(255,255,255,0.5)';

    @observable imagePostLineClamp = true;
    @observable imagePostLineClampValue = 5;
    @observable imageTextColor = '';
    @observable imageBackgroundSize = 'cover';
    @observable imagePostHideText = false;
    @observable imageFaceDetection = false;

    @observable showEngagementMetrics = true;
    @observable engagementMetricsSize = 30;
    @observable engagementMetricsPosition = 'bottom_left';

    @observable showTextStyles = true; // this shouldn't be in the widget options  - it's just a UI concern

    @observable textOnlyColor = '';
    @observable textOnlyTextFormat: PostTextFormat = 'fit';
    @observable textOnlyTeleprompterSpeed = 50;

    @observable showUserGroup = true;
    @observable postLineClamp = true;
    @observable postLineClampValue = 5;
    @observable hashtagTextColor = '';
    @observable hashtagBackgroundColor = '';
    @observable mentionTextColor = '';
    @observable mentionBackgroundColor = '';
    @observable urlTextColor = '';
    @observable urlBackgroundColor = '';
    @observable showTextEntityUrls = false;
    @observable showExpandedEntityUrls = false;

    @observable showUserAvatar = true;
    @observable showUserName = true;
    @observable showUserScreenName = true;
    @observable userGroupIconSize = 10;
    @observable postTextOnlySize = 10;
    @observable userGroupAvatarSize = 40;
    @observable userGroupNameSize = 15;
    @observable userGroupScreenNameSize = 12;
    @observable userGroupPosition = 'top';
    @observable userGroupLayoutType = 'a';

    @observable showIconInUserGroup = true;
    @observable socialIconBgColor = false;
    @observable socialIconPosition: PositionTypesInterface = { x: 'left', y: 'top' };
    @observable removeGenericPII = true;

    @observable showText = true;
    @observable showTimeStamp = false;
    @observable timeStampSize = 13;
    @observable timeStampFormat: TimeStampOptions = 'timeAgo';
    @observable endianTime = 'D MMM YYYY, h:mm a';
    @observable textFitMin = 20;

    @observable activePostsInWindow = 1;
    @observable stripLinks?: boolean = true;
    @observable postLayoutOrientation = 'vertical';
    @observable postCompositionType: PostCompositionTypeValues = 'a';
    @observable blastDelimiter: BlastDelimiterTypes = 'words'; // words, letters, sentences, lines
    @observable metaThemePalette: Palette[] = [];
    @observable showSecondaryImageOverlay?: boolean = false;

    @observable showZeroEngagementValues?: boolean = false;

    @observable showLinkPreview?: boolean = false;
    @observable linkPreviewSize?: number = 12;

    @observable showQuotedTweet?: boolean = false;
    @observable quotedTweetSize?: number = 7;
    // @observable secondaryOverlayOpacity: number = 50;

    // offsets
    @observable metaOffsetTotal?: number = 8;
    @observable themeOffsetTotal?: number = 2;
    @observable imageFiltersOpen?: boolean = true;

    @observable profanityFilter: ProfanityFilter = {
        enabled: false,
        profanityList: [],
        obscureSymbol: '*',
        replace: false,
        replaceList: {},
    };

    @observable location: PostOptionLocation = {
        enabled: false,
        size: 13,
    };

    @observable customFields: { fieldId: string; size: number } = {
        fieldId: '',
        size: 12,
    };

    @observable retweetIndicator: RetweetIndicatorOptions = {
        enabled: false,
        opacity: 50,
        size: 13,
    };

    // ratings/reviews
    @observable reviews: ReviewOptions = {
        enabled: false,
        // title/name
        title: {
            enabled: true,
            color: '',
            size: 35,
            format: {
                type: 'clamp',
                clamp: {
                    enabled: true,
                    lines: 3,
                },
                fit: {
                    min: 20,
                    max: 400,
                },
            },
        },
        rating: {
            enabled: true,
            filledColor: '',
            emptyColor: '',
            size: 35,
        },
        product: {
            enabled: true,
            color: '',
            size: 15,
        },
        message: {
            enabled: true,
            size: 20,
            color: '',
            format: {
                type: 'clamp',
                clamp: {
                    enabled: true,
                    lines: 5,
                },
                fit: {
                    min: 20,
                    max: 400,
                },
            },
        },
        domain: {
            enabled: true,
            color: '',
            size: 20,
        },
    };
    @observable postTitleOptions: TitleOptions = {
        enabled: false,
        size: 25,
        color: '',
        format: {
            type: 'clamp',
            clamp: {
                enabled: true,
                lines: 3,
            },
            fit: {
                min: 20,
                max: 400,
            },
        },
    };
    @observable smartSize: SmartSize = {
        enabled: false,
        mode: 'responsive',
        size: 'xl',
    };
    @observable postComposition = 'a';
    @observable emoji: Emoji = {
        platform: 'default',
    };
}

export class AutoAlertsOptionsImpl extends PostsWindowWidgetOptionsImpl
    implements PostsWindowWidgetOptions {}

export class PostsWidgetTypePosterOptionsImpl extends PostsWindowWidgetOptionsImpl
    implements PostsWindowWidgetOptions {}
