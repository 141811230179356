import Dimension from '@sprinklr/stories/analytics/Dimension';
import { TimeFormat } from '@sprinklr/stories/widget/types';
import * as d3 from 'd3';
import { Widget } from '@sprinklr/stories/widget/Widget';
import { WidgetDaysOfWeek, WidgetMonthsOfYear } from 'models/Widget/Widget';
import { generateColorPalette } from 'utils/GenerateColors/GenerateColors';
import { HeatMapTablePoint } from 'components/_charts/HeatMapTableChart/HeatMapTableChart';
import { sanitizeString } from 'utils/NormalizeLabel/NormalizeLabel';
import { ComputedStyle, computedStyleToString, styler } from 'utils/GenerateStyles/GenerateStyles';
import DataSet from '@sprinklr/stories/analytics/DataSet';
import { FieldType } from '@sprinklr/stories/reporting/types';
import { GetWidgetTypeStyles } from 'models/Widget/WidgetType';
import { Theme } from 'models/Theme/Theme';
import { GenerateTransitionStyles } from 'utils/GenerateTransitionStyles/GenerateTransitionStyles';
import { HeatMapTableChartWidgetOptions } from 'src/widgets/HeatMapTableChartWidget/options';

export const getFormatter = (dimension: Dimension, timeFormat: TimeFormat): string => {
    switch (dimension.type) {
        case 'DATE':
        case 'TIMESTAMP':
            if (dimension.name === 'minute' || dimension.name.indexOf('_1m') !== -1) {
                return 'HH:mm';
            }
            if (dimension.name === 'hour' || dimension.name.indexOf('_1h') !== -1) {
                return 'HH';
            }
            if (dimension.name === 'day' || dimension.name.indexOf('_1d') !== -1) {
                switch (timeFormat) {
                    case 'a':
                        return 'ddd MMM Do';
                    case 'b':
                    default:
                        return 'MMM Do';
                    case 'c':
                        return 'ddd';
                    case 'd':
                        return 'dddd';
                    case 'e':
                        return 'D/M';
                    case 'f':
                        return 'D/M/gg';
                    case 'g':
                        return "MMMM Do, 'gg";
                }
            }
            if (dimension.name === 'month' || dimension.name.indexOf('_1M') !== -1) {
                return 'MMM';
            }

            return 'ddd MMM Do';

        default:
            return null;
    }
};

export const colorMapper = (value, min, max, colors) =>
    d3.scale
        .quantile()
        .domain([min, max])
        .range(colors)(value);

export const additionalSort = (dimension: Dimension, values: any[]) => {
    if (
        ((dimension.type === 'TIMESTAMP' || dimension.type === 'DATE') &&
            (dimension.name === 'minute' ||
                dimension.name.indexOf('_1m') !== -1 ||
                dimension.name === 'hour' ||
                dimension.name.indexOf('_1h') !== -1)) ||
        dimension.name.includes('Time of Day')
    ) {
        values.sort();
        return;
    }

    if (dimension.name === 'Day Of Week' || dimension.name === 'DAY_OF_WEEK') {
        values.sort((a, b) => {
            return (
                WidgetDaysOfWeek.indexOf(a.toLowerCase()) -
                WidgetDaysOfWeek.indexOf(b.toLowerCase())
            );
        });
        return;
    }

    if (dimension.name === 'Month Of Year' || dimension.name === 'MONTH_OF_YEAR') {
        values.sort((a, b) => {
            return WidgetMonthsOfYear.indexOf(a) - WidgetMonthsOfYear.indexOf(b);
        });
        return;
    }
};

export const generateHeatMapStyles = (computedData, widget, theme) => {
    const output = [];

    if (!theme || theme.length === 0 || !widget.options) {
        return output;
    }

    const { options } = widget;
    const { subType, xAxis } = options;
    const {
        data,
        yAxisKeys,
        xAxisKeys,
        overallMax,
        overallMin,
        rowMin,
        rowMax,
        legendLength,
    } = computedData;
    const totalItems = Math.max(Object.keys(yAxisKeys).length, Object.keys(xAxisKeys).length);

    if (totalItems < 1) {
        console.log('no data');
        return output;
    }

    const legendColors: string[] =
        generateColorPalette(theme, legendLength.length, true, widget.theme, true) || [];

    // cell styles
    yAxisKeys.map((yKey, rowIndex) => {
        const yData = data[yKey] || {};
        xAxisKeys.map((xKey, idx) => {
            const point: HeatMapTablePoint = yData[xKey] || {
                value: 0,
                className: 'none',
            };

            if (subType === 'orbs') {
                if (point.value) {
                    output.push({
                        selector: `.row_index_${rowIndex + 1} .cell_key_${sanitizeString(
                            xKey + ''
                        )}.cell_index_${idx + 1} .orb`,
                        styles: {
                            backgroundColor: colorMapper(
                                point.value,
                                rowMin[rowIndex],
                                rowMax[rowIndex],
                                getColorsArray(theme, data[yKey], widget.theme)
                            ),
                        },
                    });
                }
            }
            if (subType === 'dna') {
                output.push({
                    selector: `.row_index_${rowIndex + 1} .cell_key_${sanitizeString(
                        xKey + ''
                    )}.cell_index_${idx + 1} .dna_cell`,
                    styles: {
                        backgroundColor: colorMapper(
                            point.value,
                            overallMin,
                            overallMax,
                            getColorsArray(theme, data, widget.theme)
                        ),
                    },
                });
            }
        });
    });

    // legend styles
    legendColors.map((color, index) => {
        output.push({
            selector: `.legend_index_${index}`,
            styles: {
                backgroundColor: color,
            },
        });
    });

    {
        xAxis.enabled &&
            xAxis.label.enabled &&
            output.push({
                selector: `.x_axis_key .cell`,
                styles: {
                    fontSize: styler(xAxis.label.size, 0.05, 'em', ''),
                },
            });
    }

    return output;
};

export const getColorsArray = (mergedTheme, data, theme) => {
    return generateColorPalette(mergedTheme, Object.keys(data).length, true, theme, true) || [];
};

export const transformData = (firstDataSet: DataSet, options: HeatMapTableChartWidgetOptions) => {
    const { timeFormat, subType } = options;
    const firstFormat = firstDataSet.getStringFormatter(
        firstDataSet.dimensions[0],
        getFormatter(firstDataSet.dimensions[0], timeFormat)
    );
    const secondFormat = firstDataSet.getStringFormatter(
        firstDataSet.dimensions[1],
        getFormatter(firstDataSet.dimensions[1], timeFormat)
    );

    const data = {};

    if (firstDataSet.dimensions.length > 2) {
        firstDataSet = firstDataSet.collapseDimension(firstDataSet.dimensions[2]);
    }

    // Zero fill any temporal values like TIME_OF_DAY
    firstDataSet = firstDataSet.zeroFillDimensions();

    const firstDimension = firstDataSet.dimensions[0];
    const secondDimension = firstDataSet.dimensions[1];

    const yAxisKeys = firstDataSet.categories(firstDimension, firstFormat);
    additionalSort(firstDimension, yAxisKeys);

    const xAxisKeys = firstDataSet.categories(secondDimension, secondFormat);
    additionalSort(secondDimension, xAxisKeys);

    firstDataSet.rows.forEach((row: any[]) => {
        const yKey: string = firstFormat(row[0], firstDimension);
        const xKey: string = secondFormat(row[1], secondDimension);
        const type: FieldType = firstDataSet.metrics[0].type;
        const value = row[firstDataSet.getMetricIndex(firstDataSet.getFirstMetric())];

        const classValue = firstDataSet.dimensions.length > 2 && row[2] ? row[2] + '' : '';
        const className = classValue
            .replace(/[^\w\-_\s]/gi, '') // keep all alphanumeric, dashes and spaces
            .replace(/[_\s]/gi, '-') // convert spaces and underscores to dashes
            .toLowerCase(); // to lower case

        let point: HeatMapTablePoint;

        data[yKey] = data[yKey] || {};
        if (data[yKey][xKey]) {
            point = data[yKey][xKey];

            if (value > point.max) {
                point.className = className;
                point.max = value;
            }

            data[yKey][xKey].value += value; // TODO: Do not aggregate (note: fixed bug with aggregation)
            data[yKey][xKey].type = type;
        } else {
            point = {
                value,
                type,
                className,
                max: value,
            };

            data[yKey][xKey] = point;
        }
    });

    const rowMin: number[] = [];
    yAxisKeys.forEach(yKey => {
        rowMin.push(
            d3.min(xAxisKeys, (xKey: string) => {
                const yData = data[yKey] || {};
                const point: HeatMapTablePoint = yData[xKey];
                return point ? point.value : 0;
            })
        );
    });

    const rowMax: number[] = [];
    yAxisKeys.forEach(yKey => {
        rowMax.push(
            d3.max(xAxisKeys, (xKey: string) => {
                const yData = data[yKey] || {};
                const point: HeatMapTablePoint = yData[xKey];
                return point ? point.value : 0;
            })
        );
    });

    let xAxisKeysAdjusted;
    if (xAxisKeys[0] === '00') {
        xAxisKeysAdjusted = xAxisKeys.slice(0);
        xAxisKeysAdjusted.shift();
        xAxisKeysAdjusted.push(xAxisKeys.length.toString());
    } else {
        xAxisKeysAdjusted = xAxisKeys;
    }

    // Max from entire data set
    const overallMax = d3.max(yAxisKeys, (yAxisKey: string) => {
        const yData = data[yAxisKey] || {};
        return d3.max(xAxisKeys, (xAxisKey: string) => {
            const point: HeatMapTablePoint = yData[xAxisKey];
            return point ? point.value : 0;
        });
    });

    const overallMin = d3.min(yAxisKeys, (yAxisKey: string) => {
        const yData = data[yAxisKey] || {};
        return d3.min(xAxisKeys, (xAxisKey: string) => {
            const point: HeatMapTablePoint = yData[xAxisKey];
            return point ? point.value : 0;
        });
    });

    // TODO: JT refactor
    const totalItems = Math.max(Object.keys(yAxisKeys).length, Object.keys(xAxisKeys).length);
    const cellItems = Object.keys(yAxisKeys).length * Object.keys(xAxisKeys).length;

    const lLength: number = subType === 'dna' ? cellItems : totalItems;

    const legendLength: number[] = [];
    for (let index = 0; index < lLength; index++) {
        legendLength.push(index);
    }

    return {
        data,
        yAxisKeys,
        xAxisKeys,
        overallMax,
        overallMin,
        rowMin,
        rowMax,
        xAxisKeysAdjusted,
        legendLength,
    };
};

export const heatMapTableOptionStyles = options => {
    const { orbSize } = options;

    const computedStyles: ComputedStyle[] = [
        {
            selector: '.orb',
            styles: {
                fontSize: styler(orbSize, 0.015, 'em', ''),
            },
        },
    ];

    return computedStyles;
};

export const heatMapTableCss: GetWidgetTypeStyles = (
    mergedTheme: Theme,
    dataSet: DataSet,
    widget: Widget,
    prefix: string
): string => {
    let output = '';

    if (!mergedTheme || !dataSet || !widget || !(dataSet?.dimensions?.length >= 2)) {
        return output;
    }
    const { animationDelay, animationLength } = widget.options;
    const computedData = transformData(dataSet, widget.options as HeatMapTableChartWidgetOptions);

    const styles = generateHeatMapStyles(computedData, widget, mergedTheme);
    const prefixConfig = { prefix };

    // tslint:disable-next-line:no-unused-expression
    styles &&
        styles.map((style: ComputedStyle) => {
            output += computedStyleToString(style, prefixConfig);
        });

    output += GenerateTransitionStyles(
        '.row',
        '.row',
        computedData.yAxisKeys.length,
        animationLength,
        animationDelay,
        prefix
    );

    return output;
};
