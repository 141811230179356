import { WidgetDataRequest } from '@sprinklr/stories/widget/Widget';
import ObjectUtils from 'utils/ObjectUtils/ObjectUtils';

export const inflateTemplate = (
    parent: any,
    engine: string,
    rawRequest: WidgetDataRequest | null,
    result: any,
    isPresentations: boolean
) => {
    // LEGZ - I needed the inflate process to not modify the incoming request object so i could preserve the timeperiods on it
    const request = ObjectUtils.copy(rawRequest);

    // tslint:disable-next-line:forin
    for (var key in parent) {
        const value = parent[key];

        // Handle simple types
        if (
            value === undefined ||
            value === null ||
            value instanceof Date ||
            typeof value !== 'object'
        ) {
            result[key] = value;

            // Handle Array
        } else if (value instanceof Array) {
            result[key] = [];

            value.forEach(value2 => {
                if (
                    value2 === undefined ||
                    value2 === null ||
                    value2 instanceof Date ||
                    typeof value2 !== 'object'
                ) {
                    result[key].push(value2);
                } else {
                    if (value2.getJSON) {
                        const json = value2.getJSON(engine, request, result, isPresentations);
                        if (json !== undefined) {
                            result[key].push(json);
                        }
                    } else {
                        const copy = {};
                        inflateTemplate(value2, engine, request, copy, isPresentations);
                        result[key].push(copy);
                    }
                }
            });
            // Handle Object
        } else if (value instanceof Object) {
            if (value.getJSON) {
                const json = value.getJSON(engine, request, result, isPresentations);
                if (json !== undefined) {
                    result[key] = json;
                }
            } else {
                result[key] = {};
                inflateTemplate(value, engine, request, result[key], isPresentations);
            }
        } else {
            throw new Error('Unrecognized key ' + key + ' in inflateTemplate()');
        }
    }
};

export function generateID() {
    return Math.random()
        .toString(36)
        .substr(2, 9);
}
