import { PostSource, Sort } from './PanelTemplatesTheme/PanelTemplatesTheme';
import { paddedDefaultPosition } from './PanelTemplatesTheme/constants';
import { Panel } from '../../models/Panel/Panel';
import { postCompositionConfigGrid } from '../../components/Panel/PanelEditorSidebar/PanelDesign/PanelDesignOptions';
import { WidgetOptions } from '@sprinklr/stories/widget/WidgetOptions';

export const TemplatePostGrid: Panel = {
    id: 'post-grid-v2',
    name: 'Grid',
    description: '',
    tags: ['Content', 'Posts', 'Post Engagement', '6 Posts', 'landscape', 'portrait'],
    widget: {
        position: {
            width: '1920px',
            height: '1080px',
            top: '0',
            left: '0',
        },
        classes: 'template_engagement template_grid',
        children: [
            {
                type: 'postsGridV2',
                id: '1',
                name: 'Grid',
                postRequests: [
                    {
                        sources: [
                            {
                                type: 'posts',
                                // Note: All global variables like reportingEngine, report, and filters
                                // are injected via PostRequest.getRequests()
                                id: new PostSource({
                                    pageSize: 36,
                                    sorts: [
                                        new Sort({
                                            order: 'DESC',
                                            heading: {
                                                MONITORING_DASHBOARD: 'REACH_COUNT',
                                                LISTENING: 'REACH_COUNT',
                                                INBOUND_MESSAGE: 'INBOUND_COUNT',
                                                PLATFORM: 'TOTAL_ENGAGEMENT',
                                                BENCHMARKING: 'TOTAL_ENGAGEMENT',
                                                STORY_MESSAGE: 'IMPACT',
                                            },
                                        }),
                                    ],
                                    timePeriod: {
                                        key: 'last_7_days',
                                    },
                                }) as any,
                                options: {
                                    count: 300,
                                    imagePreload: true,
                                    removeDuplicates: true,
                                    shufflePosts: false,
                                    removeSensitivePosts: true,
                                    includeQuoteTweets: false,
                                },
                            },
                        ],
                        format: {
                            type: 'buckets',
                            options: {
                                padding: 1,
                                buckets: {
                                    any: { filter: 'post.type' },
                                },
                                panels: [{ total: 6, buckets: ['any', -1] }],
                            },
                        },
                    },
                ],
                position: paddedDefaultPosition,
                options: Object.assign(
                    {
                        player: {
                            playing: true,
                            duration: 15,
                        },
                        userGroupEffect: 'top',
                        theme: 'primary',
                        showSentiment: true,
                        smartSize: {
                            enabled: true,
                            mode: 'responsive',
                            size: 'xl',
                        },
                        portrait: {
                            widgetOrientation: 'portrait',
                        },
                        postVersion: 2,
                        postTemplate: 'gridV2',
                        gridLayout: {
                            columns: 3,
                            rows: 2,
                            gap: 10,
                        },
                    } as WidgetOptions,
                    postCompositionConfigGrid.a
                ),
                // label: {
                //     titleText: 'Grid (Image Only)',
                //     size: LabelSize.primary,
                //     enabled: true
                // }
            },
        ],
        theme: {
            ext: {
                padding: 0,
            },
        },
    },
};
