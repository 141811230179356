import { ReportingResponse, SpaceRequest, SpaceResponse } from '@sprinklr/stories/reporting/types';
import { Projection as SpaceProjection } from '@sprinklr/modules/research/widget/types/index';

const toReportingResponse = (request: SpaceRequest, response: SpaceResponse): ReportingResponse => {
    // TODO: can we get away without special data transformations?

    // if (SprinklrCollectionUtils.isEmpty(request.getGroupBys())) {
    //     return adaptNoGroupProjections(request, response);
    // }
    // if (Boolean.valueOf(request.getAdditionalParam("TOPIC_CLUSTER"))) {
    //     return adaptTopicClusterResponse(request, response);
    // }
    // if (Boolean.valueOf(request.getAdditionalParam("STREAM"))) {
    //     return adaptConversationStreamResponse(request, response);
    // }

    const groups = [];
    let globalProjections: SpaceProjection[] = [];
    let projections: SpaceProjection[] = [];
    let requiredDepth = 0;

    const traverseGroupBys = (groupBys = [], depth) => {
        requiredDepth = depth;

        groupBys.forEach(groupBy => {
            groups.push(groupBy); // This may need to change to a depth-based k/v store.

            if (groupBy.childrenGroupBys && groupBy.childrenGroupBys.length > 0) {
                traverseGroupBys(groupBy.childrenGroupBys, depth + 1);
            } else if (groupBy.projections) {
                projections = projections.concat(groupBy.projections);
            }
        });
    };

    if (request.projections) {
        globalProjections = request.projections;
    }

    traverseGroupBys(request.groupBys, 1);

    const totals = [];
    let flattenedRows: any[][] = [];
    if (globalProjections.length > 0 && response.projections) {
        globalProjections.forEach(projection => {
            totals.push(response.projections[projection.key]);
            if (request.projectionDecorations) {
                request.projectionDecorations.forEach(projectionDecoration => {
                    totals.push(response.projections[`${projection.key}_${projectionDecoration}`]);
                });
            }
        });
    }

    // Not all requests have groupBys
    if (request.groupBys && request.groupBys.length > 0) {
        const groupedData = response.groupedData;
        const traverseGroupedData = (groupedData, dimensions) => {
            groups.forEach(groupBy => {
                if (groupedData[groupBy.key]) {
                    groupedData[groupBy.key].forEach(data => {
                        dimensions.push(data.key); // Add dimension keys as we traverse down

                        if (dimensions.length === requiredDepth) {
                            const row = [...dimensions];

                            projections.forEach(projection => {
                                row.push(data.projections[projection.key]);
                                if (request.projectionDecorations) {
                                    request.projectionDecorations.forEach(projectionDecoration => {
                                        row.push(
                                            data.projections[
                                                `${projection.key}_${projectionDecoration}`
                                            ]
                                        );
                                    });
                                }
                            });

                            flattenedRows.push(row);
                        }

                        if (data.groupedData) {
                            traverseGroupedData(data.groupedData, dimensions);
                        }

                        dimensions.pop(); // Remove as we traverse up
                    });
                }
            });
        };

        traverseGroupedData(groupedData, []);
    } else {
        projections = globalProjections;
        flattenedRows = [totals];
    }

    const combinedHeadings = groups.map(group => group.key);

    projections.forEach(projection => {
        combinedHeadings.push(projection.key);
        if (request.projectionDecorations) {
            request.projectionDecorations.forEach(projectionDecoration => {
                combinedHeadings.push(`${projection.key}_${projectionDecoration}`);
            });
        }
    });

    if (
        groups.length === 1 &&
        request.page?.size > 0 &&
        flattenedRows.length > request.page?.size
    ) {
        console.warn(
            `API Limit Failure: page.size is ${request.page?.size} but responses had ${flattenedRows.length}`
        );
        flattenedRows.length = request.page?.size;
    }
    if (
        groups.length === 1 &&
        groups[0].page?.size > 0 &&
        flattenedRows.length > groups[0].page?.size
    ) {
        console.warn(
            `API Limit Failure: groupBy[0].page.size is ${groups[0].page?.size} but responses had ${flattenedRows.length}`
        );
        flattenedRows.length = groups[0].page?.size;
    }

    return {
        rows: flattenedRows,
        headings: combinedHeadings,
        totals,
    };
};

export default toReportingResponse;
