import * as React from 'react';
import { observer } from 'mobx-react';
import styled, { css } from 'components/_UI/designSystem';
import { PostMedia, PostNetworkType, ImageEnrichment } from '@sprinklr/stories/post/Post';
import Video from '../../Video/Video';
import { PlayerInterval } from '../../../utils/PlayerInterval/PlayerInterval';
import { meanPlacement } from '../../../utils/MathUtils/LinearMeasurement';
import NativeFlex from '../Bits/NativeFlex';
// NativeMedia

export type NativeMediaProps = {
    images: PostMedia[];
    videos: PostMedia[];
    snType: PostNetworkType;
    playerInterval?: PlayerInterval;
    aspectRatio?: number[];
};
export const StyledMedia = styled(NativeFlex)<{ percentage: number }>`
    position: relative;
    overflow: hidden;
    border-radius: 12px;

    .video-js {
        height: 100%;
        width: 100%;
    }
    ${({ percentage }) =>
        percentage &&
        css`
            padding-top: ${percentage}%;
            max-height: 0;
        `};
`;

export const StyledImage = styled(NativeFlex)<{ enrichment: any; url: string }>`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    ${({ url }) =>
        url &&
        css`
        background-image: url('${url}');
    `};
`;

const NativeMedia: React.FC<NativeMediaProps> = ({
    aspectRatio,
    images,
    videos,
    snType,
    playerInterval,
}) => {
    const imageUrl = getImageUrl(images);
    const enrichment = getEnrichment(images);
    const videoUrl = getVideoUrl(videos);
    const percentage = aspectRatio && (aspectRatio[1] / aspectRatio[0]) * 100;
    return (
        <StyledMedia percentage={percentage}>
            <>
                {imageUrl && (
                    <StyledImage
                        key='image'
                        url={imageUrl}
                        enrichment={enrichment}
                        css={css`
                            background-position: ${getBackgroundOffset(enrichment)};
                        `}
                    />
                )}
                {videoUrl && (
                    <Video
                        playerInterval={playerInterval}
                        type={snType}
                        key='postVideo'
                        url={videoUrl}
                        poster={videos[0].poster || imageUrl || null}
                        width='100%'
                        height='100%'
                        play
                        hasOverlay={false}
                        hidePosterAfterPlay
                        showControls={false}
                        autoplay
                    />
                )}
            </>
        </StyledMedia>
    );
};

NativeMedia.displayName = 'NativeMedia';
export default observer(NativeMedia);

const getBackgroundOffset = (enrichment: ImageEnrichment) => {
    if (enrichment) {
        const { faces, height, width } = enrichment;
        const imageOffsetX: number =
            meanPlacement(width, [faces.boundingBox.x0, faces.boundingBox.x1]) * 100;
        const imageOffsetY: number =
            meanPlacement(height, [faces.boundingBox.y0, faces.boundingBox.y1]) * 100;
        return `${imageOffsetX}% ${imageOffsetY}%`;
    } else {
        return 'center center';
    }
};
const getImageUrl = images => {
    if (images && images[0] && images[0].url) {
        return images[0].url;
    } else if (images && images[0] && images[0].picture) {
        return images[0]?.picture;
    }
};
const getEnrichment = images => {
    return images && images[0] && images[0].enrichment;
};

const getVideoUrl = videos => {
    return videos && videos[0] && videos[0].url;
};
export const hasMedia = (images, videos): boolean => {
    return !!getImageUrl(images) || !!getVideoUrl(videos);
};
