import { intersect, union } from 'utils/ArrayUtils/ArrayUtils';
import { WidgetRequest } from 'utils/Widget/WidgetRequest';
import { inflateTemplate } from './PanelTemplatesTheme/helpers';
// smart
import { TemplateMarketConversation } from './TemplateMarketConversation';
import { TemplateSocialChannelPerformance } from './TemplateSocialChannelPerformance';
import { TemplateBrandHealth } from './TemplateBrandHealth';
import { TemplateChannelMetrics } from './TemplateChannelMetrics';
import { TemplateUserMetrics } from './TemplateUserMetrics';
import { TemplateTopMetrics } from './TemplateTopMetrics';
import { TemplateConversationTopics } from './TemplateConversationTopics';
// standard
import { TemplateWordCloud } from './TemplateWordCloud';
import { TemplateTreeMap } from './TemplateTreeMap';
import { TemplateSingleMetric } from './TemplateSingleMetric';
import { TemplateMultiMetric } from './TemplateMultiMetric';
import { TemplateBubbleChart } from './TemplateBubbleChart';
import { TemplateWorldHeatMap } from './TemplateWorldHeatMap';
import { TemplateGeoStream } from './TemplateGeoStream';
import { TemplateDNAChart } from './TemplateDNAChart';
import { TemplateBubbleGrid } from './TemplateBubbleGrid';
import { TemplatePieChart } from './TemplatePieChart';
import { TemplateBarChart } from './TemplateBarChart';
import { TemplateStackedBarChart } from './TemplateStackedBarChart';
import { TemplateStackedColumnChart } from './TemplateStackedColumnChart';
import { TemplateColumnChart } from './TemplateColumnChart';
import { TemplateLineChart } from './TemplateLineChart';
import { TemplateSmallMultiplesPieChart } from './TemplateSmallMultiplesPieChart';
import { TemplateComboChart } from './TemplateComboChart';
import { TemplateDualAxis } from './TemplateDualAxis';
import { TemplateDynamicImage } from './TemplateDynamicImage';
import { TemplateFunnelChart } from './TemplateFunnelChart';
import { TemplateBubblePlotChart } from './TemplateBubblePlotChart';
import { TemplateAxisBubbleChart } from './TemplateAxisBubbleChart';

// content
import { TemplateCollage } from './TemplateCollage';
import { TemplatePostEngagement10 } from './TemplatePostEngagement10';
import { TemplatePostEngagement4 } from './TemplatePostEngagement4';
import { TemplatePostEngagement2 } from './TemplatePostEngagement2';
import { TemplatePostEngagement3by2 } from './TemplatePostEngagement3by2';
import { TemplatePostEngagement2by4 } from './TemplatePostEngagement2by4';
import { TemplateProfile1by3 } from './TemplateProfile1by3';
import { TemplateProfile2by3 } from './TemplateProfile2by3';
import { TemplateProfile6 } from './TemplateProfile6';

import { TemplateFilmStrip } from './TemplateFilmStrip';
import { TemplateCinematic } from './TemplateCinematic';
import { TemplateSinglePost } from './TemplateSinglePost';
import { TemplateTicker } from './TemplateTicker';
import { TemplateWaterfall } from './TemplateWaterfall';
import { TemplatePostCard } from './TemplatePostCard';
import { TemplateRunOfShow } from './TemplateRunOfShow';

import { TemplateEditorial1 } from './TemplateEditorial1';
import { TemplateEditorial2 } from './TemplateEditorial2';
import { TemplateEditorial3 } from './TemplateEditorial3';
import { TemplateEditorial4 } from './TemplateEditorial4';
import { TemplateEditorial5 } from './TemplateEditorial5';
import { TemplateEditorial6 } from './TemplateEditorial6';
import { TemplateEditorial7 } from './TemplateEditorial7';
import { TemplateHyperlinkButton } from './TemplateHyperlinkButton';
import { TemplateDynamicTitle } from './TemplateDynamicTitle';
import { TemplateClosingSlide } from './TemplateClosingSlide';

import { TemplateAutoAlerts } from './TemplateAutoAlerts';
import { TemplateSunburst } from './TemplateSunburst';
import { TemplateSmartTrends } from './TemplateSmartTrends';

// Widgets
// Custom content
import { WidgetCustomIFrame } from './WidgetCustomIFrame';
import { WidgetCustomImage } from './WidgetCustomImage';
import { WidgetCustomRichText } from './WidgetCustomRichText';
import { WidgetCustomSocialPost } from './WidgetCustomSocialPost';
import { WidgetCustomVideo } from './WidgetCustomVideo';
import { WidgetHyperlinkButton } from './WidgetHyperlinkButton';
import { WidgetGridV2 } from './WidgetGridV2';
import { WidgetCollage } from './WidgetCollage';
import { WidgetPostsCinematic } from './WidgetPostsCinematic';
import { WidgetPostsFilmStrip } from './WidgetPostsFilmStrip';
import { WidgetPostsSinglePost } from './WidgetPostsSinglePost';
import { WidgetTicker } from './WidgetTicker';
import { WidgetWaterfall } from './WidgetWaterfall';
import { WidgetPostCard } from './WidgetPostCard';
import { WidgetRunOfShow } from './WidgetRunOfShow';

import { WidgetWordCloud } from './WidgetWordCloud';
import { WidgetTreeMap } from './WidgetTreeMap';
import { WidgetSingleMetric } from './WidgetSingleMetric';
import { WidgetMultiMetric } from './WidgetMultiMetric';
import { WidgetBubbleChart } from './WidgetBubbleChart';
import { WidgetWorldHeatMap } from './WidgetWorldHeatMap';
import { WidgetGeoStream } from './WidgetGeoStream';
import { WidgetDNAChart } from './WidgetDNAChart';
import { WidgetBubbleGrid } from './WidgetBubbleGrid';
import { WidgetLineChart } from './WidgetLineChart';
import { WidgetPieChart } from './WidgetPieChart';
import { WidgetProfile } from './WidgetProfile';
import { WidgetRankedTable } from './WidgetRankedTable';
import { WidgetSmartTrends } from './WidgetSmartTrends';

import { WidgetDataTablePivot } from './DataTable/WidgetDataTablePivot';
import { WidgetDataTableCrossTab } from './DataTable/WidgetDataTableCrossTab';
import { WidgetDataTableSummary } from './DataTable/WidgetDataTableSummary';
import { WidgetDataTableGroupedSummary } from './DataTable/WidgetDataTableGroupedSummary';

import { PanelDataTablePivot } from './DataTable/PanelDataTablePivot';
import { PanelDataTableCrossTab } from './DataTable/PanelDataTableCrossTab';
import { PanelDataTableSummary } from './DataTable/PanelDataTableSummary';
import { PanelDataTableGroupedSummary } from './DataTable/PanelDataTableGroupedSummary';

import { WidgetBarChart } from './WidgetBarChart';
import { WidgetColumnChart } from './WidgetColumnChart';
import { WidgetStackedBarChart } from './WidgetStackedBarChart';
import { WidgetStackedColumnChart } from './WidgetStackedColumnChart';
import { WidgetSmallMultiplesPieChart } from './WidgetSmallMultiplesPieChart';

import { WidgetComboChart } from './WidgetComboChart';
import { WidgetDualAxis } from './WidgetDualAxis';
import { WidgetAutoAlerts } from './widgetAutoAlerts';
import { WidgetSunburst } from './WidgetSunburst';
import { WidgetAxisBubbleChart } from './WidgetAxisBubbleChart';
import { WidgetBubblePlotChart } from './WidgetBubblePlotChart';
import { PostsEngine } from '@sprinklr/stories/post/PostsRequest';
import { AnalyticsEngine } from '@sprinklr/stories/analytics/AnalyticsRequest';
import { WidgetDynamicImage } from './WidgetDynamicImage';
import { TemplateCustomTableChart } from './TemplateCustomTableChart';
import { WidgetCustomTableChart } from './WidgetCustomTableChart';
import { WidgetFunnelChart } from './WidgetFunnelChart';

// Shapes
import { WidgetShape } from './WidgetShape';
import { TemplatePostGrid } from './TemplatePostGrid';
import AuthService, { FEATURE_FLAG } from 'services/Auth/AuthService';
import { TemplateEmojiCloud } from './TemplateEmojiCloud';
import { WidgetEmojiCloud } from './WidgetEmojiCloud';
import { TemplateStoryCard } from './TemplateStoryCard';
import { WidgetStoryCard } from './WidgetStoryCard';
import { WidgetSpeechBubble } from './WidgetSpeechBubble';

const allEngines: AnalyticsEngine[] = [
    'MONITORING_DASHBOARD',
    'LISTENING',
    'INBOUND_MESSAGE',
    'PLATFORM',
    'BENCHMARKING',
    'PAID',
    'UNIFIED_ANALYTICS_REPORTING_ENGINE',
    'CAMPAIGN',
    'ADVOCACY',
    'TWITTER',
    'RDB_FIREHOSE',
    'STORY_MESSAGE',
    'TRENDING_EVENT',
];

export class PanelTemplatesStore {
    templates: any[] = [
        // standard
        TemplateWordCloud,
        TemplateEmojiCloud,
        TemplateTreeMap,
        TemplateSingleMetric,
        TemplateMultiMetric,
        TemplateBubbleChart,
        TemplateWorldHeatMap,
        TemplateGeoStream,
        TemplateDNAChart,
        TemplateBubbleGrid,
        TemplatePieChart,
        TemplateBarChart,
        TemplateStackedBarChart,
        TemplateColumnChart,
        TemplateStackedColumnChart,
        TemplateLineChart,
        TemplateDualAxis,
        TemplateComboChart,
        TemplateSunburst,
        // TemplateGoogleTrends,
        // TemplateTwitterTrends,
        TemplateSmallMultiplesPieChart,
        TemplateAxisBubbleChart,
        TemplateBubblePlotChart,
        TemplateDynamicImage,
        TemplateCustomTableChart,
        TemplateFunnelChart,
        PanelDataTablePivot,
        PanelDataTableCrossTab,
        PanelDataTableSummary,
        PanelDataTableGroupedSummary,

        // content
        TemplateFilmStrip,
        TemplateCinematic,
        TemplateSinglePost,
        TemplateCollage,
        TemplateTicker,
        TemplateWaterfall,
        TemplatePostCard,
        TemplateStoryCard,
        TemplateRunOfShow,
        // TemplateCollageMixed,
        TemplatePostEngagement10,
        // TemplatePostEngagement6,
        // TemplatePostEngagement6Text,
        TemplatePostGrid,
        TemplatePostEngagement4,
        TemplatePostEngagement2,
        TemplatePostEngagement3by2,
        TemplatePostEngagement2by4,

        // smart
        TemplateMarketConversation,
        TemplateSocialChannelPerformance,
        TemplateBrandHealth,
        TemplateChannelMetrics,
        TemplateUserMetrics,
        TemplateTopMetrics,
        TemplateConversationTopics,

        // Auto Alerts
        TemplateAutoAlerts,

        // SMart Trends
        TemplateSmartTrends,

        // profiles
        TemplateProfile1by3,
        TemplateProfile2by3,
        TemplateProfile6,

        // editorial
        TemplateHyperlinkButton,
        TemplateEditorial1,
        TemplateEditorial2,
        TemplateEditorial3,
        TemplateEditorial4,
        TemplateEditorial5,
        TemplateEditorial6,
        TemplateEditorial7,
        TemplateDynamicTitle,
        TemplateClosingSlide,
    ];

    widgets = {
        analytics: [
            WidgetWordCloud,
            WidgetEmojiCloud,
            WidgetTreeMap,
            WidgetSingleMetric,
            WidgetMultiMetric,
            WidgetBubbleChart,
            WidgetWorldHeatMap,
            WidgetDNAChart,
            WidgetBubbleGrid,
            WidgetPieChart,
            WidgetBarChart,
            WidgetStackedColumnChart,
            WidgetColumnChart,
            WidgetStackedBarChart,
            WidgetLineChart,
            WidgetSunburst,
            WidgetDualAxis,
            WidgetComboChart,
            // WidgetGoogleTrends,
            // WidgetTwitterTrends,
            WidgetSmallMultiplesPieChart,
            WidgetRankedTable,

            WidgetDataTablePivot,
            WidgetDataTableCrossTab,
            WidgetDataTableSummary,
            WidgetDataTableGroupedSummary,

            WidgetAxisBubbleChart,
            WidgetBubblePlotChart,
            WidgetCustomTableChart,
            WidgetFunnelChart,
            WidgetDynamicImage,
        ],
        posts: [
            WidgetPostsFilmStrip,
            WidgetPostsCinematic,
            WidgetPostsSinglePost,
            WidgetCollage,
            WidgetGeoStream,
            // WidgetGrid,
            WidgetGridV2,
            WidgetProfile,
            WidgetTicker,
            WidgetWaterfall,
            WidgetPostCard,
            WidgetStoryCard,
            WidgetRunOfShow,
            WidgetSmartTrends,
        ],
        autoAlerts: WidgetAutoAlerts,
        customRichText: WidgetCustomRichText,
        customImage: WidgetCustomImage,
        customVideo: WidgetCustomVideo,
        customSocialPost: WidgetCustomSocialPost,
        hyperlinkButton: WidgetHyperlinkButton,
        customIFrame: WidgetCustomIFrame,
        shapes: WidgetShape,
        speechBubble: WidgetSpeechBubble,
    };

    static get(template: any, input: WidgetRequest, isPresentations: boolean): any {
        const result = {};

        inflateTemplate(
            template,
            input ? input.engine : null,
            input ? input.getRequest() : null,
            result,
            isPresentations
        );

        return result;
    }

    // Returns array of engines that should not be included in engine selection
    static getEngineBlacklist(template?: any): AnalyticsEngine[] {
        if (!template) {
            return null;
        }

        switch (template.id) {
            case 'smart-trends':
                return allEngines.filter(engine => engine !== 'TRENDING_EVENT');

            case 'geo-stream':
            case 'geo-stream-ticker':
                return allEngines.filter(engine => {
                    return (
                        engine !== 'LISTENING' &&
                        engine !== 'RDB_FIREHOSE' &&
                        engine !== 'TRENDING_EVENT'
                    );
                });

            case 'dynamic-image':
                return [
                    'MONITORING_DASHBOARD',
                    'INBOUND_MESSAGE',
                    'PLATFORM',
                    'TWITTER',
                    'ADVOCACY',
                    'CAMPAIGN',
                    'UNIFIED_ANALYTICS_REPORTING_ENGINE',
                    'PAID',
                    'RDB_FIREHOSE',
                    'TRENDING_EVENT',
                ];

            case 'post-card':
                return ['MONITORING_DASHBOARD', 'TWITTER', 'ADVOCACY', 'TRENDING_EVENT'];

            case 'story-card':
                return allEngines.filter(engine => engine !== 'STORY_MESSAGE');

            case 'run-of-show':
                return [
                    'MONITORING_DASHBOARD',
                    'LISTENING',
                    'INBOUND_MESSAGE',
                    'PLATFORM',
                    'BENCHMARKING',
                    'PAID',
                    'UNIFIED_ANALYTICS_REPORTING_ENGINE',
                    'TWITTER',
                    'ADVOCACY',
                    'RDB_FIREHOSE',
                    'STORY_MESSAGE',
                    'TRENDING_EVENT',
                ];

            // Following cases for templates
            case 'post-engagement-2-by-4': // Dashboards don't allow sorting
                return ['MONITORING_DASHBOARD', 'TWITTER', 'ADVOCACY', 'TRENDING_EVENT'];

            case 'post-engagement-3-by-2': // Dashboards don't allow sorting
                return [
                    'MONITORING_DASHBOARD',
                    'TWITTER',
                    'ADVOCACY',
                    'STORY_MESSAGE',
                    'TRENDING_EVENT',
                ];

            case 'word-cloud':
            case 'conversation-topics': // Contains word cloud
                return ['PLATFORM', 'CAMPAIGN', 'TRENDING_EVENT'];

            case 'emoji-cloud':
                return [
                    'MONITORING_DASHBOARD',
                    'INBOUND_MESSAGE',
                    'PLATFORM',
                    'BENCHMARKING',
                    'PAID',
                    'UNIFIED_ANALYTICS_REPORTING_ENGINE',
                    'TWITTER',
                    'ADVOCACY',
                    'RDB_FIREHOSE',
                    'CAMPAIGN',
                    'STORY_MESSAGE',
                    'TRENDING_EVENT',
                ];

            case 'world-heat-map':
            case 'social-channel-performance': // Contains heat map
                return [
                    'INBOUND_MESSAGE',
                    'PLATFORM',
                    'BENCHMARKING',
                    'CAMPAIGN',
                    'TWITTER',
                    'ADVOCACY',
                    'UNIFIED_ANALYTICS_REPORTING_ENGINE',
                    'TRENDING_EVENT',
                ];

            case 'auto-alerts':
                return [
                    'LISTENING',
                    'INBOUND_MESSAGE',
                    'PLATFORM',
                    'BENCHMARKING',
                    'UNIFIED_ANALYTICS_REPORTING_ENGINE',
                    'CAMPAIGN',
                    'TWITTER',
                    'ADVOCACY',
                    'PAID',
                    'RDB_FIREHOSE',
                    'STORY_MESSAGE',
                    'TRENDING_EVENT',
                ];

            case 'bar-chart':
            case 'bubble-chart':
            case 'column-chart':
            case 'tree-map':
                return ['CAMPAIGN', 'TRENDING_EVENT'];

            case 'axis-bubble':
            case 'brand-health':
            case 'bubble-grid':
            case 'bubble-plot':
            case 'channel-metrics':
            case 'combo-chart':
            case 'dna-chart':
            case 'dual-axis':
            case 'line-chart':
            case 'pie-chart':
            case 'single-metric':
            case 'multi-metric':
            case 'small-multiples-pie-chart':
            case 'stacked-bar-chart':
            case 'stacked-column-chart':
            case 'sunburst':
            case 'ranked-table':
            case 'top-metrics':
                return ['CAMPAIGN', 'TWITTER', 'TRENDING_EVENT'];

            case 'twitter-trends':
                return ['CAMPAIGN', 'ADVOCACY', 'TRENDING_EVENT'];

            case 'editorial-1':
            case 'editorial-2':
            case 'editorial-3':
            case 'editorial-4':
            case 'editorial-5':
            case 'editorial-6':
            case 'editorial-7':
            case 'hyperlink-button':
            case 'market-conversation':
            case 'user-metrics':
            case 'waterfall':
                return ['CAMPAIGN', 'TWITTER', 'ADVOCACY', 'TRENDING_EVENT'];

            case 'profile-1-by-3':
            case 'profile-2-by-3':
            case 'profile-1-by-6':
                return [
                    'CAMPAIGN',
                    'TWITTER',
                    'ADVOCACY',
                    'PAID',
                    'RDB_FIREHOSE',
                    'TRENDING_EVENT',
                ];

            case 'filmstrip':
            case 'cinematic':
            case 'single-post':
            case 'collage':
            case 'post-engagement-10':
            case 'post-engagement-6-text':
            case 'post-engagement-4':
            case 'post-engagement-2':
            case 'post-grid':
            case 'post-grid-v2':
            case 'ticker':
                return ['TWITTER', 'ADVOCACY', 'TRENDING_EVENT'];

            case 'data-table-pivot':
            case 'data-table-cross-tab':
            case 'data-table-grouped-summary':
            case 'data-table-summary':
                return ['CAMPAIGN', 'TWITTER', 'TRENDING_EVENT'];
        }

        return [];
    }

    getUnifiedBlacklist(id: string, authService: AuthService) {
        const blacklist = id ? PanelTemplatesStore.getEngineBlacklist({ id }) : [];
        const partnerBlacklist = authService.engineBlacklist;
        const specialPartner = authService.getSpecialPartner();
        const isStateCounty =
            authService && authService.isFeatureEnabled(FEATURE_FLAG.FEATURE_MAP_STATE_COUNTY);

        // Add support for PLATFORM for WorldHeatMap in special case for Kerala project.  Corie authorized 4/18/20
        switch (id) {
            case 'world-heat-map':
            case 'social-channel-performance': // Contains heat map
                if (isStateCounty) {
                    const found = blacklist.indexOf('UNIFIED_ANALYTICS_REPORTING_ENGINE');
                    if (found !== -1) {
                        blacklist.splice(found, 1);
                    }
                }

                if (specialPartner === 'kerala') {
                    const found = blacklist.indexOf('PLATFORM');
                    if (found !== -1) {
                        blacklist.splice(found, 1);
                    }
                }
                break;
        }

        return union(partnerBlacklist, blacklist);
    }

    getWidgetsBlacklistByEngine(engine: AnalyticsEngine | PostsEngine): any {
        const templateMap: string[] = [];
        const getWidgets = widgetTemplate => {
            if (PanelTemplatesStore.getEngineBlacklist(widgetTemplate).indexOf(engine) !== -1) {
                templateMap.push(widgetTemplate.id);
            }
        };

        // check the engine based widgets
        this.widgets.analytics.forEach(getWidgets);
        this.widgets.posts.forEach(getWidgets);
        getWidgets({ id: 'autoAlerts' });

        return templateMap;
    }

    filterWidgetsNotSupported(notSupported: string[], specialPartner: string): any {
        const result = {};

        const getWidgets = widgetTemplate => {
            const blacklist = PanelTemplatesStore.getEngineBlacklist(widgetTemplate);

            // Add support for PLATFORM for WorldHeatMap in special case for Kerala project.  Corie authorized 4/18/20
            switch (widgetTemplate.id) {
                case 'world-heat-map':
                case 'social-channel-performance': // Contains heat map
                    if (specialPartner === 'kerala') {
                        const found = blacklist.indexOf('PLATFORM');
                        if (found !== -1) {
                            blacklist.splice(found, 1);
                        }
                    }
                    break;
            }

            const whitelist = this.getEngineWhitelist(blacklist);

            if (intersect(whitelist, notSupported).length === whitelist.length) {
                result[widgetTemplate.id] = true;
            }
        };

        // check the engine based widgets
        this.widgets.analytics.forEach(getWidgets);
        this.widgets.posts.forEach(getWidgets);
        getWidgets({ id: 'autoAlerts' });

        return result;
    }

    // Returns array of engines that should be included in engine selection
    getEngineWhitelist(blacklist: string[]): string[] {
        if (blacklist) {
            return allEngines.filter((item: string) => blacklist.indexOf(item) === -1);
        } else {
            return allEngines;
        }
    }
}
