import { PostSource, Sort } from './PanelTemplatesTheme/PanelTemplatesTheme';
import { DefaultWidgetPosition } from './PanelTemplatesTheme/constants';
import { Widget } from '@sprinklr/stories/widget/Widget';
import { WIDGET_LABEL_SIZE } from '../../components/Widget/WidgetLabel/constants';

export const WidgetSmartTrends: Widget = {
    type: 'smartTrends',
    id: 'smart-trends',
    name: 'Trend Cards',
    useGlobalTimePeriod: true,
    postRequests: [
        {
            sources: [
                {
                    type: 'posts',
                    // Note: All global variables like reportingEngine, report, and filters
                    // are injected via PostRequest.getRequests()
                    id: new PostSource({
                        pageSize: 6,
                        sorts: [
                            new Sort({
                                order: 'DESC',
                                heading: {
                                    MONITORING_DASHBOARD: 'REACH_COUNT',
                                    LISTENING: 'REACH_COUNT',
                                    INBOUND_MESSAGE: 'INBOUND_COUNT',
                                    PLATFORM: 'TOTAL_ENGAGEMENT',
                                    BENCHMARKING: 'TOTAL_ENGAGEMENT',
                                    CAMPAIGN: 'OUTBOUND_STATUS',
                                    RDB_FIREHOSE: 'SN_CREATED_TIME',
                                    STORY_MESSAGE: 'IMPACT',
                                    TRENDING_EVENT: 'TREND_MENTIONS_COUNT',
                                },
                            }),
                        ],
                        includeComments: false,
                        includeFaceDetection: false,
                        timePeriod: {
                            key: 'last_7_days',
                        },
                    }) as any,
                    options: {
                        count: 200,
                        imagePreload: false,
                        removeDuplicates: false,
                        includeFaceDetection: false,
                        shufflePosts: false,
                        removeSensitivePosts: true,
                        includeQuoteTweets: false,
                    },
                },
            ],
            format: {
                type: 'ordered',
                options: {
                    fixedList: 3,
                    loop: true,
                },
            },
        },
    ],
    position: DefaultWidgetPosition,
    options: {
        player: {
            playing: true,
            duration: 6,
        },
        smartSize: {
            enabled: true,
            mode: 'responsive',
            size: 'xl',
        },
        portrait: {
            widgetOrientation: 'portrait',
        },
    },
    label: {
        titleText: 'Smart Trends',
        enabled: false,
        size: WIDGET_LABEL_SIZE.primary,
    },
};
