import { Widget } from '@sprinklr/stories/widget/Widget';
import { Theme } from 'models/Theme/Theme';
import { CollapseProps, TunablesCollapse } from 'components/Sidebar/RenderTunables/RenderTunables';
import {
    headerTunables,
    imageAdjustmentTunables,
    opacityTunables,
    profanityTunables,
    smartSizeTunables,
} from 'components/Sidebar/RenderTunables/SharedTunables';
import { Flexbox, LabeledSelect } from 'components/spaceKit';

import { gridLayoutOptions } from 'components/Panel/PanelEditorSidebar/PanelDesign/PanelDesignOptions';
import ActionSliderGroup from 'components/_UI/Forms/Actions/ActionSliderGroup';
import * as React from 'react';
import { updateGridLayout } from '../PostCard/PostCardTunables';
import i18n from 'i18next';
import LabelGroup from 'components/_UI/Forms/LabelGroup/LabelGroup';
import ActionColorSwatchGroup from 'components/_UI/Forms/Actions/ActionColorSwatchGroup';
import { action } from 'mobx';
import { getPostMergedColors } from 'utils/GenerateStyles/GenerateStyles';
import ActionCheckboxSliderGroup from 'components/_UI/Forms/Actions/ActionCheckboxSliderGroup';
import ActionStoryOption from './ActionStoryOption';
import { WidgetOptions } from '@sprinklr/stories/widget/WidgetOptions';
import { StoryCardWidgetOptionsImpl } from './StoryCardWidgetOptionsImpl';

type Options = StoryCardWidgetOptionsImpl & WidgetOptions;

const StoryCardTunables = (widget: Widget, mergedTheme: Theme): TunablesCollapse[] => {
    const options = widget.options as Options;
    const fallbackColor = mergedTheme?.typography?.color || '#fff';
    return [
        {
            props: {
                label: i18n.t('Story Card'),
                startOpen: true,
            } as CollapseProps,
            children: [
                ...headerTunables(widget),
                opacityTunables(widget),
                smartSizeTunables(widget),
            ],
        },
        {
            props: {
                label: i18n.t('Layout'),
                startOpen: true,
            } as CollapseProps,
            children: [
                <LabeledSelect
                    label={i18n.t('Columns/Rows')}
                    key='widget.options.gridLayout'
                    onChange={event => updateGridLayout(event as any, widget)}
                    value={options.gridLayout.columns as any}
                    options={gridLayoutOptions}
                />,
                <ActionSliderGroup
                    key='widget.options.gridLayout.gap'
                    label={i18n.t('Gap')}
                    option={options.gridLayout}
                    value='gap'
                    showValue
                    min={0}
                    max={100}
                />,
                <ActionSliderGroup
                    key='widget.options.storyOptions.space'
                    label={i18n.t('Spacing')}
                    option={options.storyOptions}
                    value='space'
                    showValue
                    min={0}
                    max={100}
                />,
            ],
        },
        {
            props: {
                label: i18n.t('Story Headline'),
                startOpen: true,
            } as CollapseProps,
            children: [
                <Flexbox key='overlay group' vertical gap>
                    <ActionStoryOption
                        fallbackColor='#fff'
                        label={i18n.t('Title')}
                        options={options.storyOptions.title}
                    />

                    {options.storyOptions.title.enabled && (
                        <ActionCheckboxSliderGroup
                            key='widget.options.imagePostLineClamp'
                            label={i18n.t('Line Clamp')}
                            showValue
                            checkbox={{
                                option: widget.options,
                                value: 'imagePostLineClamp',
                            }}
                            slider={{
                                option: widget.options,
                                value: 'imagePostLineClampValue',
                                min: 0,
                                max: 10,
                            }}
                        />
                    )}
                    <ActionStoryOption
                        fallbackColor='#fff'
                        label={i18n.t('Meta')}
                        options={options.storyOptions.meta}
                    />

                    <LabelGroup
                        key='widget.options.secondaryOverlayColor'
                        label={i18n.t('Gradient')}>
                        <ActionColorSwatchGroup
                            option={null}
                            value={null}
                            onChange={action(
                                ({ rgb: { r, g, b, a } }) =>
                                    (options.secondaryOverlayColor = `rgba(${r}, ${g}, ${b}, ${a})`)
                            )}
                            color={
                                options.secondaryOverlayColor ||
                                new StoryCardWidgetOptionsImpl().secondaryOverlayColor
                            }
                        />
                    </LabelGroup>
                </Flexbox>,
            ],
        },
        {
            props: {
                label: i18n.t('Story Body'),
                startOpen: true,
            } as CollapseProps,
            children: [
                <Flexbox key='widget.options.imageTextColor.palettes' vertical gap>
                    <LabelGroup
                        key='widget.options.imagePostBackgroundColor'
                        label={i18n.t('Background')}>
                        <ActionColorSwatchGroup
                            option={null}
                            value={null}
                            onChange={action(
                                ({ rgb: { r, g, b, a } }: any) =>
                                    (options.imagePostBackgroundColor = `rgba(${r}, ${g}, ${b}, ${a})`)
                            )}
                            onClear={action(() => (options.imagePostBackgroundColor = ''))}
                            color={
                                !options.imagePostBackgroundColor
                                    ? getPostMergedColors(widget.theme, mergedTheme)
                                          .imagePostBackgroundColor || 'rgb(50,50,50)'
                                    : options.imagePostBackgroundColor
                            }
                            secondaryIcon={
                                !!options.imagePostBackgroundColor ? 'icon-undo' : undefined
                            }
                        />
                    </LabelGroup>
                    <Flexbox key='options.imageTextColor.other' vertical gap>
                        <ActionStoryOption
                            fallbackColor={fallbackColor}
                            label={i18n.t('Summary')}
                            options={options.storyOptions.summary}
                        />
                        <ActionStoryOption
                            fallbackColor={fallbackColor}
                            label={i18n.t('Publications')}
                            options={options.storyOptions.publications}
                        />

                        <ActionStoryOption
                            fallbackColor={fallbackColor}
                            label={i18n.t('Divider')}
                            options={options.storyOptions.divider}
                        />
                        <ActionStoryOption
                            fallbackColor={fallbackColor}
                            label={i18n.t('Metrics')}
                            options={options.storyOptions.metrics}
                        />
                    </Flexbox>
                </Flexbox>,
            ],
        },
        imageAdjustmentTunables(widget),
        profanityTunables(widget),
    ];
};

export default StoryCardTunables;
