import * as React from 'react';
import i18n from 'i18next';
import { action, toJS } from 'mobx';
import moment from 'moment';

// components
import LabelGroup from 'components/_UI/Forms/LabelGroup/LabelGroup';
import ThresholdTool from 'components/Panel/PanelEditorSidebar/PanelDesignPalette/Tools/ThresholdTool';
import ActionCustomProperties from 'components/_UI/Forms/Actions/ActionCustomProperties';
import ActionCheckboxSliderGroup from 'components/_UI/Forms/Actions/ActionCheckboxSliderGroup';
import ActionInput from 'components/_UI/Forms/Actions/ActionInput';
import ActionSelectGroup from 'components/_UI/Forms/Actions/ActionSelectGroup';
import ActionSliderGroup from 'components/_UI/Forms/Actions/ActionSliderGroup';
import ActionCheckboxGroup from 'components/_UI/Forms/Actions/ActionCheckboxGroup';
import ActionSegmentedGroup from 'components/_UI/Forms/Actions/ActionSegmentedGroup';
import ActionRadioGroup from 'components/_UI/Forms/Actions/ActionRadioGroup';
import ActionColorSwatchGroup from 'components/_UI/Forms/Actions/ActionColorSwatchGroup';
import ActionTextAreaBlacklist from 'components/_UI/Forms/Actions/ActionTextAreaBlacklist';
import ActionImageFilterPresets from 'components/_UI/Forms/Actions/ActionImageFilterPresets';
import ActionImageSwatch from 'components/_UI/Forms/Actions/ActionImageSwatch';
import { ColorResult, Flexbox, LabeledCheckbox } from 'components/spaceKit';
import FaceDetectionTool from '../../Panel/PanelEditorSidebar/PanelDesignPalette/Tools/FaceDetectionTool';
import ActionCheckboxSliderColorSwatchGroup from 'components/_UI/Forms/Actions/ActionCheckboxSliderColorSwatchGroup';
import ActionMultiRadioGroup from 'components/_UI/Forms/Actions/ActionMultiRadioGroup';
import LabeledFormElement from 'components/_UI/Forms/LabeledFormElement/LabeledFormElement';

// types
import { CollapseProps, TunablesCollapse } from './RenderTunables';
import { Widget } from '@sprinklr/stories/widget/Widget';
import { FontCase, Theme } from 'models/Theme/Theme';
import { BubbleChartWidgetOptionsImpl } from 'src/widgets/BubbleChartWidget/options';
import { PostsWindowWidgetOptionsImpl } from '../../../widgets/PostsWindowWidget/options';
import { PostsWidgetCinematicOptionsImpl } from '../../../widgets/PostsWindowWidget/Cinematic/options';
import { AutoAlertsWidgetOptionsImpl } from '../../../widgets/AutoAlertsWidget/options';
import { ComboChartWidgetOptions } from '../../../widgets/ComboChartWidget/options';
import { AxisBubbleChartWidgetOptionsImpl } from '../../../widgets/AxisBubbleChartWidget/options';
import { BubblePlotChartWidgetOptionsImpl } from '../../../widgets/BubblePlotChartWidget/options';
import { WidgetOptionsImpl } from 'models/Widget/WidgetOptions';
import { MetricWidgetOptionsImpl } from 'src/widgets/MetricWidget/options';
import { ShapeWidgetOptionsImpl } from 'src/widgets/ShapeWidget/options';
import { CustomContentRichTextOptionsImpl } from '../../../widgets/CustomContentWidget/options';
import {
    PostCardWidgetOptions,
    PostsWidgetOptions,
    PostsWidgetOptionsImpl,
} from '../../../widgets/PostsWidget/options';
import {
    BarChartWidgetOptions,
    BarChartWidgetOptionsImpl,
} from '../../../widgets/BarChartWidget/options';
import { MultiMetricWidgetOptionsImpl } from 'src/widgets/MultiMetricWidget/options';
import { ProfileWidgetOptionsImpl } from '../../../widgets/ProfileWidget/options';

// helpers
import {
    blendModeOptions,
    colorModeOptions,
    emojiPlatformOptions,
    endianOptions,
    fontCaseOptions,
    fontFamilyPresets,
    horizontalAlignOptions,
    imageBackgroundSizeOptions,
    labelDateOptions,
    labelFormatOptions,
    metricRatioOptions,
    metricValueCaseOptions,
    metricValueOptions,
    textFormatOptions,
    tickLabelAngleOptions,
    userGroupLayoutTypeOptions,
    vAlignOptions,
    xAxisOptions,
} from '../../Panel/PanelEditorSidebar/PanelDesign/PanelDesignOptions';

import {
    getMergedBackgroundColor,
    getMergedTextColor,
    getPostMergedColors,
} from 'utils/GenerateStyles/GenerateStyles';
import { isMetricTimeDiff, widgetHasDim } from 'utils/Widget/WidgetUtils';
import { normalizeLabel, stringCase } from 'utils/NormalizeLabel/NormalizeLabel';
import { getSmartSizeOptions } from 'models/Widget/helpers';

const emptySwatchColor = 'rgb(50, 50, 50)';

type HeaderTunableOpts = {
    header?: boolean;
    dateRange?: boolean;
};

export const headerTunables = (widget: Widget, options?: HeaderTunableOpts) => {
    if (!widget.label) {
        return [];
    }

    const header =
        widget.label.hasOwnProperty('enabled') && (!options || options.header)
            ? [
                  <ActionCheckboxSliderGroup
                      key='widget.label.enabled'
                      label={i18n.t('Header')}
                      showValue
                      checkbox={{
                          option: widget.label,
                          value: 'enabled',
                      }}
                      slider={{
                          option: widget.label,
                          value: 'size',
                          min: 0,
                          max: 100,
                      }}
                  />,
                  widget.name === 'Single Metric' &&
                      widget.label.enabled && [
                          <LabeledCheckbox
                              key='widget.options.useMetricAsHeader'
                              label={i18n.t('Use Metric Name As Header')}
                              onChange={e => setMetricAsHeader(e, widget)}
                              checked={(widget.options as any).useMetricAsHeader}
                              disabled={(widget.options as any).useSourceAsHeader}
                          />,
                          <LabeledCheckbox
                              key='widget.options.useSourceAsHeader'
                              label={i18n.t('Use Source Name As Header')}
                              onChange={e => setSourceAsHeader(e, widget)}
                              checked={(widget.options as any).useSourceAsHeader}
                              disabled={(widget.options as any).useMetricAsHeader}
                          />,
                      ],
                  !(widget.options as any).useMetricAsHeader &&
                      !(widget.options as any).useSourceAsHeader &&
                      widget.label.hasOwnProperty('titleText') &&
                      widget.label.enabled && (
                          <ActionInput
                              key='widget.options.titleText'
                              option={widget.label}
                              value='titleText'
                          />
                      ),
              ]
            : [];

    const setMetricAsHeader = action((e, currWidget: Widget) => {
        (currWidget.options as any).useMetricAsHeader = e.target.checked;

        if ((currWidget.options as any).useMetricAsHeader) {
            const projection = toJS(currWidget.analyticsRequests)[0].projections[0];
            currWidget.label.titleText = stringCase(
                normalizeLabel(projection?.measurementName),
                'capitalize'
            );
        } else {
            currWidget.label.titleText = currWidget.name;
        }
    });

    const setSourceAsHeader = action((e, currWidget: Widget) => {
        (currWidget.options as any).useSourceAsHeader = e.target.checked;
    });

    const timeZoneList = (moment as any).tz.names();

    const now = moment();

    const timeZoneOptions = [
        {
            value: '',
            label: 'Match Data',
        },
    ];
    timeZoneList.map(name => {
        timeZoneOptions.push({ value: name, label: name + ' ' + now.tz(name).format('Z') });
    });

    const dateRange =
        widget.options.hasOwnProperty('labelTimeRangeEnabled') &&
        (!options || options.dateRange) &&
        widget.label.enabled
            ? [
                  widget.options.hasOwnProperty('labelTimeRangeSize') && (
                      <ActionCheckboxSliderGroup
                          key='widget.options.labelTimeRangeEnabled'
                          label={i18n.t('Date Range')}
                          showValue
                          checkbox={{
                              option: widget.options,
                              value: 'labelTimeRangeEnabled',
                          }}
                          slider={{
                              option: widget.options,
                              value: 'labelTimeRangeSize',
                              min: 0,
                              max: 100,
                          }}
                      />
                  ),
                  widget.options.hasOwnProperty('labelTimeRangeFormat') &&
                      widget.options.labelTimeRangeEnabled && (
                          <ActionSelectGroup
                              label={i18n.t('Date Format')}
                              key='widget.options.labelTimeRangeFormat'
                              option={widget.options}
                              value='labelTimeRangeFormat'
                              options={endianOptions}
                          />
                      ),
                  widget.options.hasOwnProperty('labelTimeZone') &&
                      widget.options.labelTimeRangeEnabled && (
                          <ActionSelectGroup
                              label={i18n.t('Label Time Zone')}
                              key='widget.options.labelTimeZone'
                              option={widget.options}
                              value='labelTimeZone'
                              options={timeZoneOptions}
                          />
                      ),
              ]
            : [];

    const textShadow =
        (!options || options.header) && widget.label.enabled
            ? [
                  <ActionCheckboxSliderColorSwatchGroup
                      key='widget.options.textShadow tunable'
                      label={i18n.t('Text Shadow')}
                      showValue
                      checkbox={{
                          checked: widget.label.textShadow?.enabled,
                          value: 'enabled',
                          option: widget.label.textShadow,
                      }}
                      slider={{
                          min: 0,
                          max: 100,
                          value: 'blur',
                          option: widget.label.textShadow,
                      }}
                      colorSwatch={{
                          option: widget.label.textShadow,
                          value: 'color',
                          color: widget.label.textShadow?.color,
                          onChange: action(
                              ({ rgb: { r, g, b, a } }) =>
                                  (widget.label.textShadow.color = `rgba(${r}, ${g}, ${b}, ${a})`)
                          ),
                      }}
                  />,
                  widget.label.textShadow?.enabled && [
                      <ActionSliderGroup
                          key='widget.label.textShadow.x'
                          label={i18n.t('Text Shadow x')}
                          option={widget.label.textShadow}
                          value='x'
                          showValue
                          suffix={'px'}
                          min={-50}
                          max={50}
                      />,
                      <ActionSliderGroup
                          key='widget.label.textShadow.y'
                          label={i18n.t('Text Shadow y')}
                          option={widget.label.textShadow}
                          value='y'
                          showValue
                          suffix={'px'}
                          min={-50}
                          max={50}
                      />,
                  ],
              ]
            : [];

    return [...header, ...dateRange, ...textShadow];
};

export const opacityTunables = (widget: Widget) =>
    widget.options.opacity !== undefined ? (
        <ActionSliderGroup
            key='widget.options.opacity'
            label={i18n.t('Opacity')}
            option={widget.options}
            value='opacity'
            showValue
            min={0}
            max={100}
        />
    ) : null;

const setCumulative = action((e, widget: Widget) => {
    widget.options.cumulative = e.target.checked;
});

export const cumulativeTunables = (widget: Widget) => (
    <LabeledCheckbox
        key='widget.options.cumulative'
        label={i18n.t('Cumulative')}
        onChange={e => setCumulative(e, widget)}
        checked={widget.options.cumulative}
    />
);

const setSmartSize = action((e, widget: Widget) => {
    widget.options.smartSize.enabled = e.target.checked;

    if (widget.options.smartSize && widget.options.smartSize.enabled) {
        widget.options = getSmartSizeOptions(widget, widget.options.smartSize.size);
    }
});

export const smartSizeTunables = (widget: Widget) =>
    !!widget.options.smartSize ? (
        <LabeledCheckbox
            key='widget.options.smartSize'
            label={i18n.t('Smart Size')}
            onChange={e => setSmartSize(e, widget)}
            checked={widget.options.smartSize.enabled}
        />
    ) : null;

export const nativeStylingTunables = (widget: Widget): TunablesCollapse => ({
    props: {
        label: i18n.t('Native Styling'),
        startOpen: true,
        isEnabled:
            (widget.options as PostCardWidgetOptions).nativeStyling &&
            (widget.options as PostCardWidgetOptions).nativeStyling.enabled,
        option: (widget.options as PostCardWidgetOptions).nativeStyling,
        value: 'enabled',
    } as CollapseProps,
    children:
        (widget.options as PostCardWidgetOptions).nativeStyling &&
        (widget.options as PostCardWidgetOptions).nativeStyling.enabled !== null
            ? [
                  [
                      <ActionSliderGroup
                          key='widget.options.fontScale'
                          label={i18n.t('Sizing')}
                          option={(widget.options as PostCardWidgetOptions).nativeStyling}
                          value={'fontScale'}
                          showValue
                          min={0}
                          max={100}
                      />,
                      <LabelGroup label='Color Mode' key='widget.nativeStyling.colorMode'>
                          <ActionSelectGroup
                              key='widget.nativeStyling.colorMode.select'
                              options={colorModeOptions}
                              option={(widget.options as PostCardWidgetOptions).nativeStyling}
                              value='colorMode'
                          />
                      </LabelGroup>,
                      <ActionCheckboxSliderColorSwatchGroup
                          key='widget.options.border.tunable'
                          label={i18n.t('Border')}
                          showValue
                          checkbox={{
                              checked: (widget.options as PostCardWidgetOptions).nativeStyling
                                  .border.enabled,
                              value: 'enabled',
                              option: (widget.options as PostCardWidgetOptions).nativeStyling
                                  .border,
                          }}
                          slider={{
                              min: 0,
                              max: 100,
                              value: 'size',
                              option: (widget.options as PostCardWidgetOptions).nativeStyling
                                  .border,
                          }}
                          colorSwatch={{
                              option: (widget.options as PostCardWidgetOptions).nativeStyling
                                  .border,
                              value: 'color',
                              color: (widget.options as PostCardWidgetOptions).nativeStyling.border
                                  .color,
                              onChange: action(
                                  ({ rgb: { r, g, b, a } }) =>
                                      ((widget.options as PostCardWidgetOptions).nativeStyling.border.color = `rgba(${r}, ${g}, ${b}, ${a})`)
                              ),
                              secondaryIcon: (widget.options as PostCardWidgetOptions).nativeStyling
                                  .border.color
                                  ? 'icon-undo'
                                  : undefined,
                          }}
                      />,
                      <ActionCheckboxSliderColorSwatchGroup
                          key='widget.options.shadow.tunable'
                          label={i18n.t('Drop Shadow')}
                          showValue
                          checkbox={{
                              checked: (widget.options as PostCardWidgetOptions).nativeStyling
                                  .shadow.enabled,
                              value: 'enabled',
                              option: (widget.options as PostCardWidgetOptions).nativeStyling
                                  .shadow,
                          }}
                          slider={{
                              min: 0,
                              max: 100,
                              value: 'size',
                              option: (widget.options as PostCardWidgetOptions).nativeStyling
                                  .shadow,
                          }}
                          colorSwatch={{
                              option: (widget.options as PostCardWidgetOptions).nativeStyling
                                  .shadow,
                              value: 'color',
                              secondaryIcon: (widget.options as PostCardWidgetOptions).nativeStyling
                                  .shadow.color
                                  ? 'icon-undo'
                                  : undefined,
                              color: (widget.options as PostCardWidgetOptions).nativeStyling.shadow
                                  .color,
                              onChange: action(
                                  ({ rgb: { r, g, b, a } }) =>
                                      ((widget.options as PostCardWidgetOptions).nativeStyling.shadow.color = `rgba(${r}, ${g}, ${b}, ${a})`)
                              ),
                          }}
                      />,
                  ],
                  <ActionSliderGroup
                      key='widget.options.lineClamp'
                      label={i18n.t('Text Post Lines')}
                      option={(widget.options as PostCardWidgetOptions).nativeStyling.lineClamp}
                      value={'lines'}
                      showValue
                      min={1}
                      max={20}
                  />,
                  <ActionSliderGroup
                      key='widget.options.lineClamp.hasMedia'
                      label={i18n.t('Image Post Lines')}
                      option={
                          (widget.options as PostCardWidgetOptions).nativeStyling.lineClamp.hasMedia
                      }
                      value={'lines'}
                      showValue
                      min={1}
                      max={20}
                  />,
                  <LabelGroup label='Emoji Set' key='widget.nativeStyling.emoji'>
                      <ActionSelectGroup
                          key='widget.nativeStyling.emoji.platform'
                          options={emojiPlatformOptions}
                          option={(widget.options as PostCardWidgetOptions).emoji}
                          value='platform'
                      />
                  </LabelGroup>,
                  <ActionCheckboxGroup
                      key='nativeStyling.removeUrls'
                      label='Hide links'
                      option={(widget.options as PostCardWidgetOptions).nativeStyling}
                      value='removeUrls'
                  />,
                  !(widget.options as PostCardWidgetOptions).nativeStyling.removeUrls && (
                      <ActionCheckboxGroup
                          key='nativeStyling.showExpandedEntityUrls'
                          label={i18n.t('Display Expanded URLs')}
                          option={(widget.options as PostCardWidgetOptions).nativeStyling}
                          value='showExpandedEntityUrls'
                      />
                  ),
                  <ActionCheckboxGroup
                      key='nativeStyling.showTitle'
                      label='Show Title'
                      option={(widget.options as PostCardWidgetOptions).nativeStyling}
                      value='showTitle'
                  />,
                  <ActionCheckboxGroup
                      key='(widget.options as PostCardWidgetOptions).nativeStyling.profanityFilter.enabled'
                      label={i18n.t('Profanity Filter')}
                      option={
                          (widget.options as PostCardWidgetOptions).nativeStyling.profanityFilter
                      }
                      value='enabled'
                  />,
                  (widget.options as PostCardWidgetOptions).nativeStyling.profanityFilter
                      ?.enabled && (
                      <React.Fragment key='(widget.options as PostCardWidgetOptions).nativeStyling.profanityFilter.options'>
                          <LabelGroup
                              label={i18n.t('Replacement Character')}
                              key='(widget.options as PostCardWidgetOptions).nativeStyling.profanityFilter.obscureSymbol'>
                              <ActionInput
                                  key='(widget.options as PostCardWidgetOptions).nativeStyling.profanityFilter.obscureSymbol input'
                                  option={
                                      (widget.options as PostCardWidgetOptions).nativeStyling
                                          .profanityFilter
                                  }
                                  value='obscureSymbol'
                                  placeholder={i18n.t('Replacement Character')}
                              />
                          </LabelGroup>
                          <LabeledFormElement
                              label={i18n.t('Profanity List')}
                              key='(widget.options as PostCardWidgetOptions).nativeStyling.profanityFilter.profanityList'>
                              <ActionTextAreaBlacklist
                                  key='(widget.options as PostCardWidgetOptions).nativeStyling.profanityFilter.profanityList'
                                  option={
                                      (widget.options as PostCardWidgetOptions).nativeStyling
                                          .profanityFilter
                                  }
                                  value='profanityList'
                                  placeholder={i18n.t('comma separated list')}
                              />
                          </LabeledFormElement>
                      </React.Fragment>
                  ),
                  <ActionCheckboxGroup
                      key='(widget.options as PostCardWidgetOptions).nativeStyling.retweetIndicator'
                      label={i18n.t('Show Retweet Indicator')}
                      option={(widget.options as PostCardWidgetOptions).nativeStyling}
                      value='retweetIndicator'
                  />,
                  supportsLinkPreview(widget) && (
                      <ActionCheckboxGroup
                          key='widget.options.showLinkPreview'
                          label={i18n.t('Link Preview')}
                          option={widget.options}
                          value='showLinkPreview'
                      />
                  ),
                  supportsQuotedTweetView(widget) && (
                      <ActionCheckboxSliderGroup
                          key='widget.options.showQuotedTweet'
                          label={i18n.t('Quoted Tweet')}
                          showValue
                          checkbox={{
                              option: widget.options,
                              value: 'showQuotedTweet',
                          }}
                          slider={{
                              option: widget.options,
                              value: 'quotedTweetSize',
                              min: 0,
                              max: 10,
                          }}
                      />
                  ),
              ]
            : [],
});

export const extraPostDataTunables = (widget: Widget, mergedTheme: Theme): TunablesCollapse => ({
    props: {
        label: 'Additional Data',
        startOpen: true,
    } as CollapseProps,
    children:
        (widget.options as PostCardWidgetOptions).extraPostDataOptions &&
        (widget.options as PostCardWidgetOptions).extraPostDataOptions.enabled !== null
            ? [
                  (widget.options as PostCardWidgetOptions).extraPostDataOptions.header ? (
                      <>
                          <ActionCheckboxSliderColorSwatchGroup
                              key='extraPostDataOptions.header'
                              label='Header'
                              showValue
                              checkbox={{
                                  option: (widget.options as PostCardWidgetOptions)
                                      .extraPostDataOptions.header,
                                  value: 'enabled',
                              }}
                              slider={{
                                  option: (widget.options as PostCardWidgetOptions)
                                      .extraPostDataOptions.header,
                                  value: 'size',
                                  min: 1,
                                  max: 100,
                              }}
                              colorSwatch={{
                                  option: null,
                                  value: null,
                                  onClear: action(
                                      () =>
                                          ((widget.options as PostCardWidgetOptions).extraPostDataOptions.header.color =
                                              '')
                                  ),
                                  onChange: action(
                                      ({ rgb: { r, g, b, a } }: ColorResult) =>
                                          ((widget.options as PostCardWidgetOptions).extraPostDataOptions.header.color = `rgba(${r}, ${g}, ${b}, ${a})`)
                                  ),
                                  color: !!(widget.options as PostCardWidgetOptions).nativeStyling
                                      .enabled
                                      ? 'rgba(0, 0, 0, 100)'
                                      : !!(widget.options as PostCardWidgetOptions)
                                            .extraPostDataOptions.header.color
                                      ? (widget.options as PostCardWidgetOptions)
                                            .extraPostDataOptions.header.color
                                      : mergedTheme.typography && mergedTheme.typography.color,
                                  secondaryIcon: !!(widget.options as PostCardWidgetOptions)
                                      .extraPostDataOptions.header.color
                                      ? 'icon-undo'
                                      : undefined,
                              }}
                          />
                          {(widget.options as PostCardWidgetOptions).extraPostDataOptions.header
                              .enabled && (
                              <LabelGroup label='Header Text' key='header.text'>
                                  <ActionInput
                                      key='widget.options.additional.details'
                                      option={
                                          (widget.options as PostCardWidgetOptions)
                                              .extraPostDataOptions.header
                                      }
                                      value='text'
                                  />
                              </LabelGroup>
                          )}
                      </>
                  ) : null,

                  <ActionCheckboxSliderColorSwatchGroup
                      key='extraPostDataOptions'
                      label='Text'
                      showValue
                      checkbox={undefined}
                      slider={{
                          option: (widget.options as PostCardWidgetOptions).extraPostDataOptions,
                          value: 'size',
                          min: 1,
                          max: 100,
                      }}
                      colorSwatch={{
                          option: null,
                          value: null,
                          onClear: action(
                              () =>
                                  ((widget.options as PostCardWidgetOptions).extraPostDataOptions.color =
                                      '')
                          ),
                          onChange: action(
                              ({ rgb: { r, g, b, a } }: ColorResult) =>
                                  ((widget.options as PostCardWidgetOptions).extraPostDataOptions.color = `rgba(${r}, ${g}, ${b}, ${a})`)
                          ),
                          color: !!(widget.options as PostCardWidgetOptions).nativeStyling.enabled
                              ? 'rgba(0, 0, 0, 100)'
                              : !!(widget.options as PostCardWidgetOptions).extraPostDataOptions
                                    .color
                              ? (widget.options as PostCardWidgetOptions).extraPostDataOptions.color
                              : mergedTheme.typography && mergedTheme.typography.color,
                          secondaryIcon: !!(widget.options as PostCardWidgetOptions)
                              .extraPostDataOptions.color
                              ? 'icon-undo'
                              : undefined,
                      }}
                  />,
                  <ActionCheckboxGroup
                      key='widget.options.border'
                      label='Divider'
                      option={(widget.options as PostCardWidgetOptions).extraPostDataOptions.border}
                      value='enabled'
                  />,
                  (widget.options as PostCardWidgetOptions).extraPostDataOptions.border.enabled && (
                      <LabelGroup label='Divider Color' key='divider.color'>
                          <ActionColorSwatchGroup
                              value={null}
                              option={null}
                              secondaryIcon={
                                  (widget.options as PostCardWidgetOptions).extraPostDataOptions
                                      .border.color !== ''
                                      ? 'icon-undo'
                                      : undefined
                              }
                              color={
                                  (widget.options as PostCardWidgetOptions).extraPostDataOptions
                                      .border.color !== ''
                                      ? (widget.options as PostCardWidgetOptions)
                                            .extraPostDataOptions.border.color
                                      : mergedTheme.typography && mergedTheme.typography.color
                              }
                              onClear={action(
                                  () =>
                                      ((widget.options as PostCardWidgetOptions).extraPostDataOptions.border.color =
                                          '')
                              )}
                              onChange={action(
                                  ({ rgb: { r, g, b, a } }: any) =>
                                      ((widget.options as PostCardWidgetOptions).extraPostDataOptions.border.color = `rgba(${r}, ${g}, ${b}, ${a})`)
                              )}
                          />
                      </LabelGroup>
                  ),
                  (widget.options as PostCardWidgetOptions).extraPostDataOptions && (
                      <ActionSelectGroup
                          label={i18n.t('Date Format')}
                          key='widget.options.additional.details'
                          option={(widget.options as PostCardWidgetOptions).extraPostDataOptions}
                          value='dateFormat'
                          options={endianOptions}
                      />
                  ),
              ]
            : [],
});

type ZoomTunablesOpts = {
    zoom?: boolean;
    clip?: boolean;
};

export const zoomTunables = (widget: Widget, options?: ZoomTunablesOpts): TunablesCollapse => {
    const zoom =
        !options || options.zoom
            ? [
                  widget.options.hasOwnProperty('zoom') && (
                      <ActionSliderGroup
                          key='widget.options.zoom'
                          label={i18n.t('Zoom')}
                          option={widget.options}
                          value='zoom'
                          showValue
                          min={0}
                          max={100}
                      />
                  ),
                  widget.options.hasOwnProperty('offsetX') && (
                      <ActionSliderGroup
                          key='widget.options.offsetX'
                          label={i18n.t('Offset X')}
                          option={widget.options}
                          value='offsetX'
                          showValue
                          min={-100}
                          max={100}
                      />
                  ),
                  widget.options.hasOwnProperty('offsetY') && (
                      <ActionSliderGroup
                          key='widget.options.offsetY'
                          label={i18n.t('Offset Y')}
                          option={widget.options}
                          value='offsetY'
                          showValue
                          min={-100}
                          max={100}
                      />
                  ),
              ]
            : [];

    const clip =
        !options || options.clip
            ? widget.options.hasOwnProperty('zoomClipping') && (
                  <ActionCheckboxGroup
                      key='widget.options.zoomClipping'
                      label={i18n.t('Clip Zoom')}
                      option={widget.options}
                      value='zoomClipping'
                  />
              )
            : null;

    return {
        props: {
            label: 'Zoom',
            startOpen: true,
        } as CollapseProps,
        children: [...zoom, clip],
    };
};

type TextFormatTunablesOpts = {
    fit?: boolean;
    clamp?: boolean;
    wrap?: boolean;
};

const labelTextFormatSegment = widget => (
    <ActionSegmentedGroup
        key='widget.options.labelTextFormat'
        label={i18n.t('Text Format')}
        option={widget.options}
        value={'labelTextFormat'}
        options={labelFormatOptions}
    />
);

export const textFormatTunables = (widget: Widget, options?: TextFormatTunablesOpts) => {
    if (!options) {
        return undefined;
    }
    const clampEnabled: boolean = widget.options.labelTextFormat === 'clamp';
    const fitEnabled: boolean = widget.options.labelTextFormat === 'fit';

    const clamp =
        options.clamp && clampEnabled ? (
            <ActionCheckboxSliderGroup
                key='widget.options.labelLineClamp'
                label={i18n.t('Line Clamp')}
                showValue
                checkbox={{
                    option: widget.options,
                    value: 'labelLineClamp',
                }}
                slider={{
                    option: widget.options,
                    value: 'labelLineClampValue',
                    min: 0,
                    max: 10,
                }}
            />
        ) : null;

    const fit =
        options.fit && fitEnabled
            ? [
                  <ActionSliderGroup
                      key='widget.options.textFitMin'
                      label={i18n.t('Label Size Min')}
                      option={widget.options}
                      value={'textFitMin'}
                      showValue
                      min={0}
                      max={100}
                  />,
                  <ActionSliderGroup
                      key='widget.options.textFitMax'
                      label={i18n.t('Label Size Max')}
                      option={widget.options}
                      value={'textFitMax'}
                      showValue
                      min={0}
                      max={100}
                  />,
              ]
            : [];

    const wrap = options.wrap ? (
        <ActionCheckboxGroup
            key='widget.options.labelWrapText'
            label={i18n.t('Wrap Label Characters')}
            option={widget.options}
            value={'labelWrapText'}
        />
    ) : null;

    return [labelTextFormatSegment(widget), clamp, ...fit, wrap];
};

export const childTextFormatTunables = (widget: Widget, options?: TextFormatTunablesOpts) => {
    if (!options) {
        return undefined;
    }

    const clampEnabled: boolean =
        (widget.options as BubbleChartWidgetOptionsImpl).childLabelTextFormat === 'clamp';
    const fitEnabled: boolean =
        (widget.options as BubbleChartWidgetOptionsImpl).childLabelTextFormat === 'fit';

    const clamp =
        options.clamp && clampEnabled ? (
            <ActionCheckboxSliderGroup
                key='widget.options.labelLineClamp'
                label={i18n.t('Child Line Clamp')}
                showValue
                checkbox={{
                    option: widget.options,
                    value: 'childLabelLineClamp',
                }}
                slider={{
                    option: widget.options,
                    value: 'childLabelLineClampValue',
                    min: 0,
                    max: 10,
                }}
            />
        ) : null;

    const fit =
        options.fit && fitEnabled
            ? [
                  <ActionSliderGroup
                      key='widget.options.textFitMin'
                      label={i18n.t('Child Label Size Min')}
                      option={widget.options}
                      value={'childTextFitMin'}
                      showValue
                      min={0}
                      max={100}
                  />,
                  <ActionSliderGroup
                      key='widget.options.textFitMax'
                      label={i18n.t('Child Label Size Max')}
                      option={widget.options}
                      value={'childTextFitMax'}
                      showValue
                      min={0}
                      max={100}
                  />,
              ]
            : [];

    const wrap = options.wrap ? (
        <ActionCheckboxGroup
            key='widget.options.labelWrapText'
            label={i18n.t('Wrap Label Characters')}
            option={widget.options}
            value={'childLabelWrapText'}
        />
    ) : null;

    return [labelTextFormatSegment(widget), clamp, ...fit, wrap];
};

export const labelTunables = (widget: Widget) =>
    widget.options.hasOwnProperty('labelEnable') && (
        <ActionCheckboxSliderGroup
            key='widget.options.labelEnable'
            label={i18n.t('Label')}
            showValue
            checkbox={{
                option: widget.options,
                value: 'labelEnable',
            }}
            slider={{
                option: widget.options,
                value: 'labelSize',
                min: 0,
                max: 100,
                disabled: widget.options.labelTextFormat === 'fit',
            }}
        />
    );

export const childLabelTunables = (widget: Widget) =>
    widget.options.hasOwnProperty('childLabelEnable') && (
        <ActionCheckboxSliderGroup
            key='widget.options.childLabelEnable'
            label={i18n.t('Label')}
            showValue
            checkbox={{
                option: widget.options,
                value: 'childLabelEnable',
            }}
            slider={{
                option: widget.options,
                value: 'childLabelSize',
                min: 0,
                max: 100,
            }}
        />
    );

export const customLabelTunables = (widget: Widget, labelName: string) => (
    <ActionCheckboxSliderGroup
        key='widget.options.showCustomLabel'
        label={labelName}
        showValue
        checkbox={{
            option: widget.options,
            value: 'showCustomLabel',
        }}
        slider={{
            option: widget.options,
            value: 'customLabelSize',
            min: 0,
            max: 100,
        }}
    />
);

export const emptyDataTunables = (widget: Widget) => ({
    props: {
        label: 'Empty Data',
        startOpen: true,
        isEnabled: (widget.options as AutoAlertsWidgetOptionsImpl).emptyData.enabled,
        option: (widget.options as AutoAlertsWidgetOptionsImpl).emptyData,
        value: 'enabled',
    } as CollapseProps,
    children: [
        <Flexbox gap='l' key='widget.options.emptyData.image.url'>
            <ActionCheckboxGroup
                key='widget.options.emptyData.image'
                label={i18n.t('Image')}
                option={(widget.options as AutoAlertsWidgetOptionsImpl).emptyData.image}
                value='enabled'
            />
            <ActionImageSwatch
                key='widget.options.emptyData.image.url'
                title='background image'
                option={(widget.options as AutoAlertsWidgetOptionsImpl).emptyData.image}
                value='url'
            />
        </Flexbox>,
        (widget.options as AutoAlertsWidgetOptionsImpl).emptyData.image.enabled && (
            <LabelGroup label={i18n.t('Image Fit')} key='widget.options.emptyData.image.fit'>
                <ActionSegmentedGroup
                    key='widget.options.emptyData.image.fit'
                    options={imageBackgroundSizeOptions}
                    option={(widget.options as AutoAlertsWidgetOptionsImpl).emptyData.image}
                    value='fit'
                />
            </LabelGroup>
        ),
        <ActionCheckboxSliderGroup
            key='widget.options.emptyData.message'
            label={i18n.t('Message')}
            showValue
            checkbox={{
                option: (widget.options as AutoAlertsWidgetOptionsImpl).emptyData.message,
                value: 'enabled',
            }}
            slider={{
                option: (widget.options as AutoAlertsWidgetOptionsImpl).emptyData.message,
                value: 'size',
                min: 0,
                max: 100,
            }}
        />,
        (widget.options as AutoAlertsWidgetOptionsImpl).emptyData.message.enabled && [
            <ActionInput
                key='widget.options.emptyData.message.text'
                option={(widget.options as AutoAlertsWidgetOptionsImpl).emptyData.message}
                value='text'
            />,
            <LabelGroup
                label={i18n.t('Message Color')}
                key='widget.options.emptyData.message.color'>
                <ActionColorSwatchGroup
                    secondaryIcon={
                        (widget.options as AutoAlertsWidgetOptionsImpl).emptyData.message.color
                            ? 'icon-undo'
                            : undefined
                    }
                    option={(widget.options as AutoAlertsWidgetOptionsImpl).emptyData.message}
                    value='color'
                />
            </LabelGroup>,
        ],
    ],
});

export const customRatioTunables = (widget: Widget, ratioName: string) => [
    <ActionCheckboxGroup
        key='widget.options.showCustomRatio'
        label={ratioName}
        option={widget.options}
        value='showCustomRatio'
    />,
    widget.options.showCustomRatio && (
        <React.Fragment key='widget.options.customRatioOptions'>
            <ActionSegmentedGroup
                key='widget.options.customRatioFormat'
                label={i18n.t('Format')}
                option={widget.options}
                value='customRatioFormat'
                options={metricRatioOptions}
                showOptionLabel
            />
            <ActionSliderGroup
                key='widget.options.customRatioOpacity'
                label={i18n.t('Slice Opacity')}
                option={widget.options}
                value='customRatioOpacity'
                showValue
                min={0}
                max={100}
            />
        </React.Fragment>
    ),
];

export const countupDurationTunable = widget => (
    <ActionCheckboxSliderGroup
        key='widget.options.metricValueCountup'
        label={i18n.t('Countup Duration')}
        showValue
        checkbox={{
            option: (widget.options as WidgetOptionsImpl).metricValueCountup,
            value: 'enabled',
        }}
        slider={{
            option: (widget.options as WidgetOptionsImpl).metricValueCountup,
            value: 'duration',
            min: 0,
            max: 50,
        }}
    />
);

type MetricValueTunablesOpts = {
    value?: {
        enable?: boolean;
        size?: boolean;
        hideZero?: boolean;
    };
    format?: boolean;
    name?: boolean;
    change?: boolean;
    case?: boolean;
};

export const metricValueTunables = (
    widget: Widget,
    options?: MetricValueTunablesOpts
): JSX.Element[] => {
    let value = null;

    // We need to know if any metric is of type `TIME_DIFFERENCE`
    const isSLA =
        widget.analyticsRequests &&
        widget.analyticsRequests[0] &&
        widget?.analyticsRequests[0]?.projections?.some((metric, index) =>
            isMetricTimeDiff(widget, index)
        );

    if (options && !!options.value) {
        if (options.value.enable && !options.value.size) {
            value = (
                <ActionCheckboxGroup
                    key='widget.options.showMetricValue'
                    label={i18n.t('Show Metric Value')}
                    option={widget.options}
                    value='showMetricValue'
                />
            );
        } else {
            value = (
                <ActionCheckboxSliderGroup
                    key='widget.options.metricValueSize'
                    label={i18n.t('Metric Value')}
                    showValue
                    checkbox={{
                        option: widget.options,
                        value: 'showMetricValue',
                    }}
                    slider={{
                        option: widget.options,
                        value: 'metricValueSize',
                        min: 0,
                        max: 100,
                    }}
                />
            );
        }
    }

    const hideZero =
        options && options.value?.hideZero && widget.options.showMetricValue ? (
            <React.Fragment key='widget.options.hideZero'>
                <ActionCheckboxGroup
                    label={i18n.t('Hide Zeros')}
                    option={(widget.options as ProfileWidgetOptionsImpl).profileMetrics}
                    value='hideZero'
                />
            </React.Fragment>
        ) : null;

    const timeFormat =
        options && options.format && widget.options.showMetricValue ? (
            <React.Fragment key='widget.options.showTimeRemainder'>
                <ActionCheckboxGroup
                    key='widget.options.showTimeRemainder'
                    label={i18n.t('Show Time Remainder')}
                    option={widget.options}
                    value='showTimeRemainder'
                />
                <ActionCheckboxGroup
                    key='widget.options.metricTimeRoundSeconds'
                    label={i18n.t('Round Seconds')}
                    option={widget.options}
                    value='metricTimeRoundSeconds'
                />
            </React.Fragment>
        ) : null;

    const format =
        options && options.format && widget.options.showMetricValue ? (
            <>
                <ActionSegmentedGroup
                    between
                    key='widget.options.metricValueFormat'
                    label={i18n.t('Format')}
                    option={widget.options}
                    value={'metricValueFormat'}
                    options={metricValueOptions}
                    showOptionLabel
                />
                {widget.options.metricValueCase && widget.options.metricValueFormat !== '1,234' && (
                    <ActionSelectGroup
                        label={i18n.t('Unit Case')}
                        key='widget.options.metricValueCase'
                        options={metricValueCaseOptions}
                        option={widget.options}
                        value='metricValueCase'
                    />
                )}
                {countupDurationTunable(widget)}
            </>
        ) : null;

    const name =
        options && options.name ? (
            <ActionCheckboxSliderGroup
                key='widget.options.showMetricName'
                label={i18n.t('Metric Name')}
                showValue
                checkbox={{
                    option: widget.options,
                    value: 'showMetricName',
                }}
                slider={{
                    option: widget.options,
                    value: 'metricNameSize',
                    min: 0,
                    max: 50,
                }}
            />
        ) : null;

    const metricNameCase =
        options && options.case ? (
            <ActionSelectGroup
                label={i18n.t('Case')}
                key='widget.options.metricNameCase'
                options={fontCaseOptions}
                option={widget.options}
                value='metricNameCase'
            />
        ) : null;

    const change =
        options && options.change ? (
            <ActionCheckboxSliderGroup
                key='widget.options.showMetricChange'
                label={i18n.t('Metric Change')}
                showValue
                checkbox={{
                    option: widget.options,
                    value: 'showMetricChange',
                }}
                slider={{
                    option: widget.options,
                    value: 'metricPercentChangeSize',
                    min: 0,
                    max: 100,
                }}
            />
        ) : null;

    return [value, hideZero, isSLA ? timeFormat : format, name, metricNameCase, change];
};

type PercentChangeTunablesOpts = {
    highlight?: boolean;
    format?: boolean;
    color?: boolean;
};

export const percentChangeTunables = (
    widget: Widget,
    mergedTheme?: Theme,
    options?: PercentChangeTunablesOpts
): JSX.Element[] => {
    const highlight =
        !options || options.highlight ? (
            <ActionCheckboxGroup
                key='widget.options.highlightPercentChange'
                label={i18n.t('Highlight Percent Change')}
                option={widget.options}
                value='highlightPercentChange'
            />
        ) : null;

    const format =
        !options || options.format ? (
            <ActionSegmentedGroup
                between
                key='widget.options.percentChangeFormat'
                label={i18n.t('Format')}
                option={widget.options}
                value='percentChangeFormat'
                options={metricValueOptions}
                showOptionLabel
            />
        ) : null;

    const colors =
        options &&
        options.color &&
        !!(widget.options as MetricWidgetOptionsImpl).percentChangeColor ? (
            <React.Fragment key='percentChange color options'>
                <ActionCheckboxGroup
                    key='widget.options.percentChangeColor.enabled'
                    label={i18n.t('Color Percent Change')}
                    option={(widget.options as MetricWidgetOptionsImpl).percentChangeColor}
                    value='enabled'
                />
                {(widget.options as MetricWidgetOptionsImpl).percentChangeColor.enabled && (
                    <Flexbox key='widget.options.percentChangeColor.colors' gap='s'>
                        <LabelGroup
                            key='widget.options.percentChangeColor.down'
                            label={i18n.t('Down')}>
                            <ActionColorSwatchGroup
                                option={
                                    (widget.options as MetricWidgetOptionsImpl).percentChangeColor
                                }
                                value={'down'}
                            />
                        </LabelGroup>
                        <LabelGroup
                            key='widget.options.percentChangeColor.zero'
                            label={i18n.t('Zero')}>
                            <ActionColorSwatchGroup
                                option={null}
                                value={null}
                                onChange={action(
                                    ({ rgb: { r, g, b, a } }: any) =>
                                        ((widget.options as MetricWidgetOptionsImpl).percentChangeColor.zero = `rgba(${r}, ${g}, ${b}, ${a})`)
                                )}
                                onClear={action(
                                    () =>
                                        ((widget.options as MetricWidgetOptionsImpl).percentChangeColor.zero =
                                            '')
                                )}
                                color={
                                    !(widget.options as MetricWidgetOptionsImpl).percentChangeColor
                                        .zero
                                        ? getMergedTextColor(widget.theme, mergedTheme)
                                        : (widget.options as MetricWidgetOptionsImpl)
                                              .percentChangeColor.zero
                                }
                                secondaryIcon={
                                    !!(widget.options as MetricWidgetOptionsImpl).percentChangeColor
                                        .zero
                                        ? 'icon-undo'
                                        : undefined
                                }
                            />
                        </LabelGroup>
                        <LabelGroup key='widget.options.percentChangeColor.up' label={i18n.t('Up')}>
                            <ActionColorSwatchGroup
                                option={
                                    (widget.options as MetricWidgetOptionsImpl).percentChangeColor
                                }
                                value={'up'}
                            />
                        </LabelGroup>
                    </Flexbox>
                )}
            </React.Fragment>
        ) : null;

    return widget.options.showMetricChange ? [highlight, format, colors] : [];
};

export const previousValueTunables = (widget: Widget): JSX.Element[] => [
    <ActionCheckboxSliderGroup
        key='widget.options.showMetricPreviousValue'
        label={i18n.t('Previous Value')}
        showValue
        checkbox={{
            option: widget.options,
            value: 'showMetricPreviousValue',
        }}
        slider={{
            option: widget.options,
            value: 'metricPreviousValueSize',
            min: 0,
            max: 100,
        }}
    />,
    widget.options.showMetricPreviousValue ? (
        <ActionSegmentedGroup
            between
            key='widget.options.previousValueFormat'
            label={i18n.t('Format')}
            option={widget.options}
            value={'previousValueFormat'}
            options={metricValueOptions}
            showOptionLabel
        />
    ) : null,
];

export const totalMetricValueTunables = (widget: Widget): JSX.Element[] => [
    <ActionCheckboxSliderGroup
        key='widget.options.showTotalValue'
        label={i18n.t('Total Value')}
        showValue
        checkbox={{
            option: widget.options,
            value: 'showTotalValue',
        }}
        slider={{
            option: widget.options,
            value: 'totalValueSize',
            min: 0,
            max: 100,
        }}
    />,
    <ActionSegmentedGroup
        key='widget.options.metricValueFormat'
        label={i18n.t('Format')}
        option={widget.options}
        value='metricValueFormat'
        showOptionLabel
        options={metricValueOptions}
    />,
    widget.options.metricValueCase &&
        widget.options.metricValueFormat &&
        widget.options.metricValueFormat !== '1,234' && (
            <ActionSelectGroup
                label={i18n.t('Unit Case')}
                key='widget.options.metricValueCase'
                options={metricValueCaseOptions}
                option={widget.options}
                value='metricValueCase'
            />
        ),
    countupDurationTunable(widget),
    <ActionCheckboxSliderGroup
        key='widget.options.showTotalValueMetric'
        label={i18n.t('Metric Name')}
        showValue
        checkbox={{
            option: widget.options,
            value: 'showTotalValueMetric',
        }}
        slider={{
            option: widget.options,
            value: 'totalValueMetricSize',
            min: 0,
            max: 30,
        }}
    />,
];

type LegendTunablesOpts = {
    header?: boolean;
    smartLegendHeader?: boolean;
    orientation?: boolean;
    orientationOpts?: ('top' | 'right' | 'bottom' | 'left')[];
    labelSize?: boolean;
    legendDateFormat?: boolean;
    ratio?: boolean;
    value?: boolean;
    wrap?: boolean;
    legendSize?: boolean;
    legendMarkerSize?: boolean;
    secondLegend?: boolean;
    legendHeaderText?: string;
    legendShowValue?: boolean;
    headerFontFamily?: boolean;
    legendValueFontFamily?: boolean;
    legendValueCase?: FontCase;
    legendRatioFontFamily?: boolean;
    legendNameFontFamily?: boolean;
    showMetricName?: boolean;
};

export const legendTunables = (
    widget: Widget,
    options: LegendTunablesOpts,
    handleSideEffects?
): TunablesCollapse => {
    const header = options.header ? (
        <ActionCheckboxGroup
            key='widget.options.header'
            label={i18n.t('Header')}
            option={widget.options}
            value='showLegendHeader'
        />
    ) : null;

    const metricName = options.showMetricName ? (
        <ActionCheckboxGroup
            key='widget.options.showMetricName'
            label={i18n.t('Metric Name')}
            option={widget.options}
            value='showMetricName'
        />
    ) : null;

    const smartLegendHeader =
        options.smartLegendHeader && widget.options.showLegendHeader ? (
            <ActionCheckboxGroup
                key='widget.options.showLegendHeader'
                label={i18n.t('Smart Header')}
                option={widget.options}
                value='smartLegendHeader'
            />
        ) : null;

    const headerFontFamily = options.headerFontFamily ? (
        <ActionMultiRadioGroup
            key='widget.options.headerFontFamily'
            label={i18n.t('Font')}
            option={widget.options}
            value='headerFontFamily'
            options={fontFamilyPresets}
        />
    ) : null;

    const { orientationOpts } = options;
    const top =
        orientationOpts && orientationOpts.includes('top')
            ? [{ value: 'top', label: i18n.t('Top') }]
            : [];
    const bottom =
        orientationOpts && orientationOpts.includes('bottom')
            ? [{ value: 'bottom', label: i18n.t('Bottom') }]
            : [];
    const right =
        orientationOpts && orientationOpts.includes('right')
            ? [{ value: 'right', label: i18n.t('Right') }]
            : [];
    const left =
        orientationOpts && orientationOpts.includes('left')
            ? [{ value: 'left', label: i18n.t('Left') }]
            : [];

    const orientation = options.orientation ? (
        <ActionSelectGroup
            label={i18n.t('Orientation')}
            key='widget.options.legendOrientation'
            option={widget.options}
            value='legendOrientation'
            options={[...top, ...bottom, ...right, ...left]}
        />
    ) : null;

    const labelSize = options.labelSize ? (
        <ActionSliderGroup
            key='widget.options.legendNameSize'
            label={i18n.t('Label Size')}
            option={widget.options}
            value='legendNameSize'
            showValue
            min={0}
            max={50}
        />
    ) : null;

    const labelDateFormat = options.legendDateFormat ? (
        <ActionSelectGroup
            key='widget.options.legendDateFormat'
            label={i18n.t('Label Date Format')}
            option={widget.options}
            value='legendDateFormat'
            options={labelDateOptions}
        />
    ) : null;

    const labelShowValue = options.legendShowValue ? (
        <ActionCheckboxGroup
            key='widget.options.legendShowValue'
            label={i18n.t('Show Value')}
            option={widget.options}
            value='legendShowValue'
        />
    ) : null;

    const legendValueFontFamily = options.legendValueFontFamily ? (
        <ActionMultiRadioGroup
            key='widget.options.legendValueFontFamily'
            label={i18n.t('Font')}
            option={widget.options}
            value='legendValueFontFamily'
            options={fontFamilyPresets}
        />
    ) : null;

    const legendRatioFontFamily = options.legendRatioFontFamily ? (
        <ActionMultiRadioGroup
            key='widget.options.legendRatioFontFamily'
            label={i18n.t('Font')}
            option={widget.options}
            value='legendRatioFontFamily'
            options={fontFamilyPresets}
        />
    ) : null;

    const legendNameFontFamily = options.legendNameFontFamily ? (
        <ActionMultiRadioGroup
            key='widget.options.legendNameFontFamily'
            label={i18n.t('Font')}
            option={widget.options}
            value='legendNameFontFamily'
            options={fontFamilyPresets}
        />
    ) : null;

    const ratio = options.ratio
        ? [
              <ActionCheckboxSliderGroup
                  key='widget.options.showLegendRatio'
                  label={i18n.t('Percentage')}
                  showValue
                  checkbox={{
                      option: widget.options,
                      value: 'showLegendRatio',
                  }}
                  slider={{
                      option: widget.options,
                      value: 'legendPercentageSize',
                      min: 0,
                      max: 50,
                  }}
              />,
              widget.options.showLegendRatio && (
                  <>
                      {legendRatioFontFamily}
                      <ActionSegmentedGroup
                          key='widget.options.legendPercentageFormat'
                          label={i18n.t('Format')}
                          option={widget.options}
                          value='legendPercentageFormat'
                          showOptionLabel
                          options={metricValueOptions}
                      />
                  </>
              ),
          ]
        : [];

    const value = options.value
        ? [
              <ActionCheckboxSliderGroup
                  key='widget.options.showLegendValue'
                  label={i18n.t('Value')}
                  showValue
                  checkbox={{
                      option: widget.options,
                      value: 'showLegendValue',
                  }}
                  slider={{
                      option: widget.options,
                      value: 'legendValueSize',
                      min: 0,
                      max: 50,
                  }}
              />,
              widget.options.showLegendValue && (
                  <>
                      {legendValueFontFamily}
                      <ActionSegmentedGroup
                          key='widget.options.legendValueFormat'
                          label={i18n.t('Format')}
                          option={widget.options}
                          value='legendValueFormat'
                          showOptionLabel
                          options={metricValueOptions}
                      />
                      {widget.options.metricValueCase &&
                          widget.options.metricValueFormat &&
                          widget.options.metricValueFormat !== '1,234' && (
                              <ActionSelectGroup
                                  label={i18n.t('Unit Case')}
                                  key='widget.options.legendValueCase'
                                  options={metricValueCaseOptions}
                                  option={widget.options}
                                  value='legendValueCase'
                              />
                          )}
                  </>
              ),
          ]
        : [];

    const wrap = options.wrap ? (
        <ActionCheckboxGroup
            key='widget.options.legendWrap'
            label={i18n.t('Wrap')}
            option={widget.options}
            value='legendWrap'
        />
    ) : null;

    const legendSize = options.legendSize ? (
        <ActionSliderGroup
            key='widget.options.legendSize'
            label={i18n.t('Legend Size')}
            option={widget.options}
            value='legendSize'
            showValue
            min={0}
            max={50}
        />
    ) : null;

    const legendMarkerSize = options.legendMarkerSize ? (
        <ActionSliderGroup
            key='widget.options.legendMarkerSize'
            label={i18n.t('Legend Marker Size')}
            option={widget.options}
            value='legendMarkerSize'
            showValue
            min={0}
            max={100}
        />
    ) : null;

    const legendHeaderText = options.legendHeaderText ? options.legendHeaderText : i18n.t('Legend');

    return {
        props: {
            label: legendHeaderText,
            startOpen: true,
            isEnabled: widget.options.showLegend,
            option: widget.options,
            value: 'showLegend',
            handleSideEffects,
        } as CollapseProps,
        children: [
            orientation,
            header,
            smartLegendHeader,
            headerFontFamily,
            labelSize,
            legendNameFontFamily,
            labelDateFormat,
            labelShowValue,
            ...ratio,
            ...value,
            metricName,
            wrap,
            legendSize,
            legendMarkerSize,
        ],
    };
};

export const secondLegendTunables = (
    widget: Widget,
    options: LegendTunablesOpts,
    handleSideEffects?
): TunablesCollapse => {
    // second legend options can be expanded if more widgets use a second legend - right now, this is only supporting world heat map
    const { orientationOpts } = options;
    const top =
        orientationOpts && orientationOpts.includes('top')
            ? [{ value: 'top', label: i18n.t('Top') }]
            : [];
    const bottom =
        orientationOpts && orientationOpts.includes('bottom')
            ? [{ value: 'bottom', label: i18n.t('Bottom') }]
            : [];
    const right =
        orientationOpts && orientationOpts.includes('right')
            ? [{ value: 'right', label: i18n.t('Right') }]
            : [];
    const left =
        orientationOpts && orientationOpts.includes('left')
            ? [{ value: 'left', label: i18n.t('Left') }]
            : [];

    const legendOrientationTunables = options.orientation ? (
        <ActionSelectGroup
            label={i18n.t('Orientation')}
            key='widget.options.secondLegend.legendOrientation'
            option={(widget.options as any).secondLegend}
            value='legendOrientation'
            options={[...top, ...bottom, ...right, ...left]}
        />
    ) : null;

    const legendSizeTunables = options.legendSize ? (
        <ActionSliderGroup
            key='widget.options.secondLegend.legendSize'
            label={i18n.t('Legend Size')}
            option={(widget.options as any).secondLegend}
            value='legendSize'
            showValue
            min={0}
            max={50}
        />
    ) : null;

    const legendHeaderText = options.legendHeaderText ? options.legendHeaderText : i18n.t('Legend');

    return {
        props: {
            label: legendHeaderText,
            startOpen: true,
            isEnabled: (widget.options as any).secondLegend.showLegend,
            option: (widget.options as any).secondLegend,
            value: 'showLegend',
            handleSideEffects,
        } as CollapseProps,
        children: [legendOrientationTunables, legendSizeTunables],
    };
};

type SizingTunablesOpts = {
    spacing?: {
        max?: number;
    };
    padding?: {
        min?: number;
        max?: number;
    };
};

export const sizingTunables = (widget: Widget, options: SizingTunablesOpts): TunablesCollapse => {
    const spacing = !!options.spacing ? (
        <ActionSliderGroup
            key='widget.options.spacing'
            label={i18n.t('Spacing')}
            option={widget.options}
            value='spacing'
            showValue
            min={0}
            max={options.spacing.max}
        />
    ) : null;

    const padding = !!options.padding ? (
        <ActionSliderGroup
            key='widget.options.padding'
            label={i18n.t('Padding')}
            option={widget.options}
            value='padding'
            showValue
            min={options.padding.min}
            max={options.padding.max}
        />
    ) : null;

    return {
        props: {
            label: 'Sizing',
            startOpen: true,
        },
        children: [spacing && spacing, padding && padding],
    };
};

type ThemeTunableOpts = {
    color?: boolean;
    image?: boolean;
    imagePosition?: boolean;
    imageSize?: boolean;
    circleMask?: boolean;
    email?: boolean;
    border?: boolean;
};

export const themeTunables = (widget: Widget, options: ThemeTunableOpts): TunablesCollapse => {
    let dimensionName = null;
    let filterName = null;

    const colorBlacklist = ['ADVOCACY', 'BENCHMARKING'];

    const engine = widget.analyticsRequests && widget.analyticsRequests[0].reportingEngine;

    widget.analyticsRequests[0].filters &&
        widget.analyticsRequests[0].filters.map(filter => {
            const name = filter.dimensionName;
            if (name && name.indexOf('HIERARCHY_ID') === 0) {
                filterName = filter.dimensionName;
            }
        });

    widget.analyticsRequests[0].groupBys.map(groupBy => {
        const name = groupBy.dimensionName ? groupBy.dimensionName.toString() : null;

        if (
            name &&
            (name.indexOf('SPECIFIC_THEME_TAG_') === 0 ||
                name.indexOf('SPECIFIC_TOPIC_TAG_') === 0 ||
                name.indexOf('SPECIFIC_TOPIC_GROUP_') === 0 ||
                name === 'TOPIC_IDS' ||
                name === 'BRAND_ID' ||
                name === 'COMMUNITY_USER_ID')
        ) {
            dimensionName = groupBy.dimensionName;
        }
    });

    const color =
        !colorBlacklist.includes(engine) && options.color ? (
            <ActionCheckboxGroup
                key='widget.options.useThemeColor'
                label={i18n.t('Color')}
                option={widget.options}
                value='useThemeColor'
            />
        ) : null;

    const image = options.image
        ? [
              <ActionCheckboxGroup
                  key='widget.options.useThemeImage'
                  label={i18n.t('Image')}
                  option={widget.options}
                  value='useThemeImage'
              />,
              widget.options.useThemeImage && (
                  <ActionSliderGroup
                      key='widget.options.themeImageOpacity'
                      label={i18n.t('Opacity')}
                      option={widget.options}
                      value='themeImageOpacity'
                      showValue
                      min={0}
                      max={100}
                  />
              ),
          ]
        : [];

    const imagePosition =
        options.imagePosition && widget.options.useThemeImage
            ? [
                  <LabelGroup
                      label={i18n.t('Image Position')}
                      key="'widget.options.themeImagePosition">
                      <ActionRadioGroup
                          key='widget.options.themeImagePosition.left'
                          label={i18n.t('Start')}
                          option={widget.options}
                          value='themeImagePosition'
                          valueCheck='left'
                      />
                      <ActionRadioGroup
                          key='widget.options.themeImagePosition.right'
                          label={i18n.t('End')}
                          option={widget.options}
                          value='themeImagePosition'
                          valueCheck='right'
                      />
                  </LabelGroup>,
                  widget.options.themeImagePosition === 'right' && (
                      <ActionSliderGroup
                          key='widget.options.barChartScale'
                          label={i18n.t('Bar Scale')}
                          option={widget.options}
                          value='barChartScale'
                          min={0}
                          max={100}
                      />
                  ),
              ]
            : [];

    const imageSize =
        options.imageSize &&
        widget.options.useThemeImage &&
        widget.options.themeImageSize !== undefined ? (
            <ActionSliderGroup
                key='widget.options.themeImageSize'
                label={i18n.t('Image Size')}
                option={widget.options}
                value='themeImageSize'
                min={0}
                max={100}
            />
        ) : null;

    const circleMask =
        options.circleMask && widget.options.useThemeImage ? (
            <ActionCheckboxGroup
                key='widget.options.themeImageCircleMask'
                label={i18n.t('Circle Mask')}
                option={widget.options}
                value='themeImageCircleMask'
            />
        ) : null;

    const borderColor =
        (options.border &&
            widget.type !== 'axisBubbleChart' &&
            widget.type !== 'bubblePlotChart' && (
                <ActionCheckboxGroup
                    key='widget.options.showBorderColor'
                    label={i18n.t('Show Image Border')}
                    option={widget.options as AxisBubbleChartWidgetOptionsImpl}
                    value='showBorderColor'
                />
            )) ||
        null;

    const email =
        engine === 'ADVOCACY' && options.email ? (
            <ActionCheckboxSliderGroup
                key='widget.options.useThemeEmail'
                label={i18n.t('Email')}
                showValue
                checkbox={{
                    option: widget.options,
                    value: 'useThemeEmail',
                }}
                slider={{
                    option: widget.options,
                    value: 'themeEmailSize',
                    min: 0,
                    max: 100,
                }}
            />
        ) : null;

    let label: string;
    switch (engine) {
        case 'ADVOCACY':
            label = i18n.t('Community User Attributes');
            break;
        case 'BENCHMARKING':
            label = i18n.t('Benchmarking Attributes');
            break;
        case 'LISTENING':
            label = i18n.t('Topic/Theme Attributes');
            break;
        default:
            if (filterName) {
                label = i18n.t('Insight Attributes');
                break;
            } else {
                label = i18n.t('Topic/Theme Attributes');
                break;
            }
    }

    let children = [];
    if (dimensionName) {
        children = [color, ...image, ...imagePosition, imageSize, circleMask, borderColor, email];
    }
    if (filterName) {
        children = [color];
    }

    return {
        props: {
            label: i18n.t(label),
            startOpen: true,
        } as CollapseProps,
        children,
    };
};

type imagePostTunablesOpts = {
    imageBackgroundSize?: boolean;
    imageFaceDetection?: boolean;
    imagePostHideText?: boolean;
    postImageTextFormat?: boolean;
};

export const imagePostTunables = (
    widget: Widget,
    mergedTheme?: Theme,
    options?: imagePostTunablesOpts
): TunablesCollapse => ({
    props: {
        label: i18n.t('Image Posts'),
        startOpen: true,
    } as CollapseProps,
    children: [
        (!options || options.imageBackgroundSize) && (
            <ActionSegmentedGroup
                key='widget.options.imageBackgroundSize'
                label={i18n.t('Image Size')}
                option={widget.options}
                value='imageBackgroundSize'
                options={imageBackgroundSizeOptions}
            />
        ),
        (!options || options.imageFaceDetection) && (
            <FaceDetectionTool key='faceDetectionTool' widget={widget} />
        ),
        (!options || options.imagePostHideText) && (
            <ActionCheckboxGroup
                key='widget.options.imagePostHideText'
                label={i18n.t('Hide Text')}
                option={widget.options}
                value='imagePostHideText'
            />
        ),
        <Flexbox key='widget.options.imageTextColor.palettes'>
            <LabelGroup key='widget.options.imageTextColor' label={i18n.t('Text')}>
                <ActionColorSwatchGroup
                    option={null}
                    value={null}
                    onChange={action(
                        ({ rgb: { r, g, b, a } }: any) =>
                            (widget.options.imageTextColor = `rgba(${r}, ${g}, ${b}, ${a})`)
                    )}
                    onClear={action(() => (widget.options.imageTextColor = ''))}
                    color={
                        !widget.options.imageTextColor
                            ? getPostMergedColors(widget.theme, mergedTheme).textColor ||
                              'rgb(50,50,50)'
                            : widget.options.imageTextColor
                    }
                    secondaryIcon={!!widget.options.imageTextColor ? 'icon-undo' : undefined}
                />
            </LabelGroup>
            {widget.type !== 'postsCinematic' && (
                <LabelGroup key='widget.options.imagePostBackgroundColor' label={i18n.t('Back')}>
                    <ActionColorSwatchGroup
                        option={null}
                        value={null}
                        onChange={action(
                            ({ rgb: { r, g, b, a } }: any) =>
                                (widget.options.imagePostBackgroundColor = `rgba(${r}, ${g}, ${b}, ${a})`)
                        )}
                        onClear={action(() => (widget.options.imagePostBackgroundColor = ''))}
                        color={
                            !widget.options.imagePostBackgroundColor
                                ? getPostMergedColors(widget.theme, mergedTheme)
                                      .imagePostBackgroundColor || 'rgb(50,50,50)'
                                : widget.options.imagePostBackgroundColor
                        }
                        secondaryIcon={
                            !!widget.options.imagePostBackgroundColor ? 'icon-undo' : undefined
                        }
                    />
                </LabelGroup>
            )}
        </Flexbox>,
        widget.type === 'postsCinematic' && widget.options.postCompositionType === 'a' && (
            <React.Fragment key='cinematic secondary overlay option'>
                <LabelGroup key='widget.options.secondaryOverlayColor' label={i18n.t('Gradient')}>
                    <ActionColorSwatchGroup
                        option={widget.options as PostsWidgetCinematicOptionsImpl}
                        value='secondaryOverlayColor'
                        secondaryIcon={
                            !!(widget.options as PostsWidgetCinematicOptionsImpl)
                                .secondaryOverlayColor
                                ? 'icon-undo'
                                : undefined
                        }
                    />
                </LabelGroup>
                <ActionCheckboxGroup
                    key='widget.options.showSecondaryOverlayColorInUser'
                    label={i18n.t('User Background Gradient')}
                    option={widget.options}
                    value='showSecondaryOverlayColorInUser'
                />
            </React.Fragment>
        ),
        (!options || options.postImageTextFormat) && (
            <ActionSegmentedGroup
                key='widget.options.postImageTextFormat'
                label={i18n.t('Text Format')}
                option={widget.options}
                value={'postImageTextFormat'}
                options={textFormatOptions}
            />
        ),
        widget.options.postImageTextFormat !== 'fit' && (
            <ActionSliderGroup
                key='widget.options.postImageTextSize'
                label={i18n.t('Text Size')}
                option={widget.options}
                value='postImageTextSize'
                showValue
                min={0}
                max={100}
            />
        ),
        widget.options.postImageTextFormat === 'clamp' && (
            <ActionCheckboxSliderGroup
                key='widget.options.imagePostLineClamp'
                label={i18n.t('Line Clamp')}
                showValue
                checkbox={{
                    option: widget.options,
                    value: 'imagePostLineClamp',
                }}
                slider={{
                    option: widget.options,
                    value: 'imagePostLineClampValue',
                    min: 0,
                    max: 10,
                }}
            />
        ),
        widget.options.postImageTextFormat === 'teleprompter' && (
            <ActionSliderGroup
                key='widget.options.postImageTeleprompterSpeed'
                label={i18n.t('Speed')}
                option={widget.options}
                value='postImageTeleprompterSpeed'
                showValue
                suffix='%'
                min={0}
                max={100}
            />
        ),
    ],
});

type textPostTunablesOpts = {
    textOnlyTextFormat?: boolean;
};

const supportsLinkPreview = (widget: Widget) => {
    switch (widget.type) {
        case 'postCard':
        case 'postsSinglePost':
        case 'postsTicker':
        case 'postsGrid':
        case 'postsGridV2':
        case 'postsFilmStrip':
        case 'runOfShow':
        case 'autoAlerts':
            return true;
        default:
            return false;
    }
};

const supportsQuotedTweetView = (widget: Widget) => {
    switch (widget.type) {
        case 'postCard':
        case 'postsSinglePost':
        case 'postsFilmStrip':
        case 'runOfShow':
        case 'postsCinematic':
        case 'postsWaterfall':
        case 'postsGrid':
        case 'postsGridV2':
            return true;
        default:
            return false;
    }
};

export const textPostTunables = (
    widget: Widget,
    mergedTheme?: Theme,
    options?: textPostTunablesOpts
): TunablesCollapse => ({
    props: {
        label: i18n.t('Text Posts'),
        startOpen: true,
    } as CollapseProps,
    children: [
        <Flexbox key='widget.options.textOnlyPalettes'>
            <LabelGroup key='widget.options.textOnlyColor' label={i18n.t('Text')}>
                <ActionColorSwatchGroup
                    option={null}
                    value={null}
                    onChange={action(
                        ({ rgb: { r, g, b, a } }: any) =>
                            (widget.options.textOnlyColor = `rgba(${r}, ${g}, ${b}, ${a})`)
                    )}
                    onClear={action(() => (widget.options.textOnlyColor = ''))}
                    color={
                        !widget.options.textOnlyColor
                            ? getPostMergedColors(widget.theme, mergedTheme).textColor ||
                              'rgb(50,50,50)'
                            : widget.options.textOnlyColor
                    }
                    secondaryIcon={!!widget.options.textOnlyColor ? 'icon-undo' : undefined}
                />
            </LabelGroup>
            <LabelGroup key='widget.options.textOnlyBackgroundColor' label={i18n.t('Back')}>
                <ActionColorSwatchGroup
                    option={null}
                    value={null}
                    onChange={action(
                        ({ rgb: { r, g, b, a } }: any) =>
                            (widget.options.textOnlyBackgroundColor = `rgba(${r}, ${g}, ${b}, ${a})`)
                    )}
                    onClear={action(() => (widget.options.textOnlyBackgroundColor = ''))}
                    color={
                        !widget.options.textOnlyBackgroundColor
                            ? getPostMergedColors(widget.theme, mergedTheme)
                                  .textOnlyBackgroundColor || 'rgb(50,50,50)'
                            : widget.options.textOnlyBackgroundColor
                    }
                    secondaryIcon={
                        !!widget.options.textOnlyBackgroundColor ? 'icon-undo' : undefined
                    }
                />
            </LabelGroup>
        </Flexbox>,
        (!options || options.textOnlyTextFormat) && (
            <ActionSegmentedGroup
                key='widget.options.textOnlyTextFormat'
                label={i18n.t('Text Format')}
                option={widget.options}
                value={'textOnlyTextFormat'}
                options={textFormatOptions}
            />
        ),
        widget.options.textOnlyTextFormat !== 'fit' && (
            <ActionSliderGroup
                key='widget.options.postTextOnlySize'
                label={i18n.t('Text Size')}
                option={widget.options}
                value='postTextOnlySize'
                showValue
                min={0}
                max={100}
            />
        ),
        widget.options.textOnlyTextFormat === 'clamp' && (
            <ActionCheckboxSliderGroup
                key='widget.options.postLineClamp'
                label={i18n.t('Line Clamp')}
                showValue
                checkbox={{
                    option: widget.options,
                    value: 'postLineClamp',
                }}
                slider={{
                    option: widget.options,
                    value: 'postLineClampValue',
                    min: 0,
                    max: 10,
                }}
            />
        ),
        widget.options.textOnlyTextFormat === 'teleprompter' && (
            <ActionSliderGroup
                key='widget.options.textOnlyTeleprompterSpeed'
                label={i18n.t('Speed')}
                option={widget.options}
                value='textOnlyTeleprompterSpeed'
                showValue
                suffix='%'
                min={0}
                max={100}
            />
        ),
        supportsLinkPreview(widget) && (
            <ActionCheckboxSliderGroup
                key='widget.options.linkPreview'
                label={i18n.t('Link Preview')}
                showValue
                checkbox={{
                    option: widget.options,
                    value: 'showLinkPreview',
                }}
                slider={{
                    option: widget.options,
                    value: 'linkPreviewSize',
                    min: 0,
                    max: 50,
                }}
            />
        ),
        supportsQuotedTweetView(widget) && (
            <ActionCheckboxSliderGroup
                key='widget.options.showQuotedTweet'
                label={i18n.t('Quoted Tweet')}
                showValue
                checkbox={{
                    option: widget.options,
                    value: 'showQuotedTweet',
                }}
                slider={{
                    option: widget.options,
                    value: 'quotedTweetSize',
                    min: 0,
                    max: 10,
                }}
            />
        ),
    ],
});

export const postTitleTunables = (widget: Widget, mergedTheme: Theme): TunablesCollapse => ({
    props: {
        label: 'Post Title',
        startOpen:
            (widget.options as PostsWindowWidgetOptionsImpl).postTitleOptions.enabled || false,
        isEnabled: (widget.options as PostsWindowWidgetOptionsImpl).postTitleOptions.enabled,
        option: (widget.options as PostsWindowWidgetOptionsImpl).postTitleOptions,
        value: 'enabled',
    } as CollapseProps,
    children: [
        <LabelGroup label={i18n.t('Title')} key='widget.options.postTitleOptions.color'>
            <ActionColorSwatchGroup
                key='widget.options.postTitleOptions.color'
                option={null}
                value={null}
                onClear={action(
                    () =>
                        ((widget.options as PostsWindowWidgetOptionsImpl).postTitleOptions.color =
                            '')
                )}
                secondaryIcon={
                    (widget.options as PostsWindowWidgetOptionsImpl).postTitleOptions.color
                        ? 'icon-undo'
                        : undefined
                }
                onChange={action(({ rgb: { r, g, b, a } }: any) => {
                    (widget.options as PostsWindowWidgetOptionsImpl).postTitleOptions.color = `rgba(${r}, ${g}, ${b}, ${a})`;
                })}
                color={
                    !(widget.options as PostsWindowWidgetOptionsImpl).postTitleOptions.color
                        ? mergedTheme.typography?.color || 'rgb(50,50,50)'
                        : (widget.options as PostsWindowWidgetOptionsImpl).postTitleOptions.color
                }
            />
        </LabelGroup>,
        <ActionSegmentedGroup
            key='widget.options.postTitleOptions.format'
            label={i18n.t('Title Format')}
            option={(widget.options as PostsWindowWidgetOptionsImpl).postTitleOptions.format}
            value={'type'}
            options={labelFormatOptions}
        />,
        (widget.options as PostsWindowWidgetOptionsImpl).postTitleOptions.format.type ===
            'clamp' && (
            <ActionSliderGroup
                key='widget.options.postTitleOptions.size'
                label={i18n.t('Title Size')}
                option={(widget.options as PostsWindowWidgetOptionsImpl).postTitleOptions}
                value='size'
                showValue
                min={0}
                max={100}
            />
        ),
        (widget.options as PostsWindowWidgetOptionsImpl).postTitleOptions.format.type ===
            'clamp' && (
            <ActionCheckboxSliderGroup
                key='widget.options.postTitleOptions.format.clamp'
                label={i18n.t('Title Clamp')}
                showValue
                checkbox={{
                    option: (widget.options as PostsWindowWidgetOptionsImpl).postTitleOptions.format
                        .clamp,
                    value: 'enabled',
                }}
                slider={{
                    option: (widget.options as PostsWindowWidgetOptionsImpl).postTitleOptions.format
                        .clamp,
                    value: 'lines',
                    min: 0,
                    max: 10,
                }}
            />
        ),
    ],
});

export const reviewPostTunables = (widget: Widget, mergedTheme: Theme): TunablesCollapse => ({
    props: {
        label: 'Review Posts',
        startOpen: (widget.options as PostsWindowWidgetOptionsImpl).reviews.enabled || false,
        isEnabled: (widget.options as PostsWindowWidgetOptionsImpl).reviews.enabled,
        option: (widget.options as PostsWindowWidgetOptionsImpl).reviews,
        value: 'enabled',
    },
    children: [
        <ActionCheckboxGroup
            key='widget.options.reviews.title.enabled'
            label={i18n.t('Show Title')}
            option={(widget.options as PostsWindowWidgetOptionsImpl).reviews.title}
            value='enabled'
        />,
        (widget.options as PostsWindowWidgetOptionsImpl).reviews.title.enabled && [
            <LabelGroup label={i18n.t('Title')} key='widget.options.reviews.title.color'>
                <ActionColorSwatchGroup
                    key='widget.options.reviews.title.color'
                    option={null}
                    value={null}
                    onClear={action(
                        () =>
                            ((widget.options as PostsWindowWidgetOptionsImpl).reviews.title.color =
                                '')
                    )}
                    secondaryIcon={
                        (widget.options as PostsWindowWidgetOptionsImpl).reviews.title.color
                            ? 'icon-undo'
                            : undefined
                    }
                    onChange={action(({ rgb: { r, g, b, a } }) => {
                        (widget.options as PostsWindowWidgetOptionsImpl).reviews.title.color = `rgba(${r}, ${g}, ${b}, ${a})`;
                    })}
                    color={
                        !(widget.options as PostsWindowWidgetOptionsImpl).reviews.title.color
                            ? mergedTheme.typography
                                ? mergedTheme.typography.color
                                : ''
                            : (widget.options as PostsWindowWidgetOptionsImpl).reviews.title.color
                    }
                />
            </LabelGroup>,
            <ActionSegmentedGroup
                key='widget.options.reviews.title.format'
                label={i18n.t('Title Format')}
                option={(widget.options as PostsWindowWidgetOptionsImpl).reviews.title.format}
                value={'type'}
                options={labelFormatOptions}
            />,
            (widget.options as PostsWindowWidgetOptionsImpl).reviews.title.format.type ===
                'clamp' && (
                <ActionSliderGroup
                    key='widget.options.reviews.title.size'
                    label={i18n.t('Title Size')}
                    option={(widget.options as PostsWindowWidgetOptionsImpl).reviews.title}
                    value='size'
                    showValue
                    min={0}
                    max={100}
                />
            ),
            (widget.options as PostsWindowWidgetOptionsImpl).reviews.title.format.type ===
                'clamp' && (
                <ActionCheckboxSliderGroup
                    key='widget.options.reviews.title.format.clamp'
                    label={i18n.t('Title Clamp')}
                    showValue
                    checkbox={{
                        option: (widget.options as PostsWindowWidgetOptionsImpl).reviews.title
                            .format.clamp,
                        value: 'enabled',
                    }}
                    slider={{
                        option: (widget.options as PostsWindowWidgetOptionsImpl).reviews.title
                            .format.clamp,
                        value: 'lines',
                        min: 0,
                        max: 10,
                    }}
                />
            ),
        ],
        <ActionCheckboxGroup
            key='widget.options.reviews.message.enabled'
            label={i18n.t('Show Message')}
            option={(widget.options as PostsWindowWidgetOptionsImpl).reviews.message}
            value='enabled'
        />,
        (widget.options as PostsWindowWidgetOptionsImpl).reviews.message.enabled && [
            <LabelGroup label={i18n.t('Message')} key='widget.options.reviews.message.color'>
                <ActionColorSwatchGroup
                    key='widget.options.reviews.message.color'
                    option={null}
                    value={null}
                    onClear={action(
                        () =>
                            ((widget.options as PostsWindowWidgetOptionsImpl).reviews.message.color =
                                '')
                    )}
                    secondaryIcon={
                        (widget.options as PostsWindowWidgetOptionsImpl).reviews.message.color
                            ? 'icon-undo'
                            : undefined
                    }
                    onChange={action(({ rgb: { r, g, b, a } }: any) => {
                        (widget.options as PostsWindowWidgetOptionsImpl).reviews.message.color = `rgba(${r}, ${g}, ${b}, ${a})`;
                    })}
                    color={
                        !(widget.options as PostsWindowWidgetOptionsImpl).reviews.message.color
                            ? mergedTheme.typography
                                ? mergedTheme.typography.color
                                : ''
                            : (widget.options as PostsWindowWidgetOptionsImpl).reviews.message.color
                    }
                />
            </LabelGroup>,
            <ActionSegmentedGroup
                key='widget.options.reviews.message.format'
                label={i18n.t('Message Format')}
                option={(widget.options as PostsWindowWidgetOptionsImpl).reviews.message.format}
                value={'type'}
                options={labelFormatOptions}
            />,
            (widget.options as PostsWindowWidgetOptionsImpl).reviews.message.format.type ===
                'clamp' && (
                <ActionSliderGroup
                    key='widget.options.reviews.message.size'
                    label={i18n.t('Message Size')}
                    option={(widget.options as PostsWindowWidgetOptionsImpl).reviews.message}
                    value='size'
                    showValue
                    min={0}
                    max={100}
                />
            ),
            (widget.options as PostsWindowWidgetOptionsImpl).reviews.message.format.type ===
                'clamp' && (
                <ActionCheckboxSliderGroup
                    key='widget.options.reviews.message.format.clamp'
                    label={i18n.t('Message Clamp')}
                    showValue
                    checkbox={{
                        option: (widget.options as PostsWindowWidgetOptionsImpl).reviews.message
                            .format.clamp,
                        value: 'enabled',
                    }}
                    slider={{
                        option: (widget.options as PostsWindowWidgetOptionsImpl).reviews.message
                            .format.clamp,
                        value: 'lines',
                        min: 0,
                        max: 10,
                    }}
                />
            ),
        ],
        <ActionCheckboxGroup
            key='widget.options.reviews.rating.enabled'
            label={i18n.t('Show Rating')}
            option={(widget.options as PostsWindowWidgetOptionsImpl).reviews.rating}
            value='enabled'
        />,
        (widget.options as PostsWindowWidgetOptionsImpl).reviews.rating.enabled && (
            <ActionSliderGroup
                key='widget.options.reviews.rating.size'
                label={i18n.t('Rating Size')}
                option={(widget.options as PostsWindowWidgetOptionsImpl).reviews.rating}
                value='size'
                showValue
                min={0}
                max={100}
            />
        ),
        <Flexbox key='widget.options.reviews.rating.color'>
            <LabelGroup
                label={i18n.t('Rating Filled')}
                key='widget.options.reviews.rating.filledColor'>
                <ActionColorSwatchGroup
                    key='widget.options.reviews.rating.filledColor'
                    option={null}
                    value={null}
                    onClear={action(
                        () =>
                            ((widget.options as PostsWindowWidgetOptionsImpl).reviews.rating.filledColor =
                                '')
                    )}
                    secondaryIcon={
                        (widget.options as PostsWindowWidgetOptionsImpl).reviews.rating.filledColor
                            ? 'icon-undo'
                            : undefined
                    }
                    onChange={action(({ rgb: { r, g, b, a } }: any) => {
                        (widget.options as PostsWindowWidgetOptionsImpl).reviews.rating.filledColor = `rgba(${r}, ${g}, ${b}, ${a})`;
                    })}
                    color={
                        !(widget.options as PostsWindowWidgetOptionsImpl).reviews.rating.filledColor
                            ? mergedTheme.typography
                                ? mergedTheme.typography.color
                                : ''
                            : (widget.options as PostsWindowWidgetOptionsImpl).reviews.rating
                                  .filledColor
                    }
                />
            </LabelGroup>
            <LabelGroup
                label={i18n.t('Rating Empty')}
                key='widget.options.reviews.rating.emptyColor'>
                <ActionColorSwatchGroup
                    key='widget.options.reviews.rating.emptyColor'
                    option={null}
                    value={null}
                    onClear={action(
                        () =>
                            ((widget.options as PostsWindowWidgetOptionsImpl).reviews.rating.emptyColor =
                                '')
                    )}
                    secondaryIcon={
                        (widget.options as PostsWindowWidgetOptionsImpl).reviews.rating.emptyColor
                            ? 'icon-undo'
                            : undefined
                    }
                    onChange={action(({ rgb: { r, g, b, a } }: any) => {
                        (widget.options as PostsWindowWidgetOptionsImpl).reviews.rating.emptyColor = `rgba(${r}, ${g}, ${b}, ${a})`;
                    })}
                    color={
                        !(widget.options as PostsWindowWidgetOptionsImpl).reviews.rating.emptyColor
                            ? mergedTheme.typography
                                ? mergedTheme.typography.color
                                : ''
                            : (widget.options as PostsWindowWidgetOptionsImpl).reviews.rating
                                  .emptyColor
                    }
                />
            </LabelGroup>
        </Flexbox>,
        <ActionCheckboxGroup
            key='widget.options.reviews.product.enabled'
            label={i18n.t('Show Product')}
            option={(widget.options as PostsWindowWidgetOptionsImpl).reviews.product}
            value='enabled'
        />,
        (widget.options as PostsWindowWidgetOptionsImpl).reviews.product.enabled && [
            <ActionSliderGroup
                key='widget.options.reviews.product.size'
                label={i18n.t('Product Size')}
                option={(widget.options as PostsWindowWidgetOptionsImpl).reviews.product}
                value='size'
                showValue
                min={0}
                max={100}
            />,
            <LabelGroup label={i18n.t('Product Color')} key='widget.options.reviews.product.color'>
                <ActionColorSwatchGroup
                    key='widget.options.reviews.product.color'
                    option={null}
                    value={null}
                    onClear={action(
                        () =>
                            ((widget.options as PostsWindowWidgetOptionsImpl).reviews.product.color =
                                '')
                    )}
                    secondaryIcon={
                        (widget.options as PostsWindowWidgetOptionsImpl).reviews.product.color
                            ? 'icon-undo'
                            : undefined
                    }
                    onChange={action(({ rgb: { r, g, b, a } }: any) => {
                        (widget.options as PostsWindowWidgetOptionsImpl).reviews.product.color = `rgba(${r}, ${g}, ${b}, ${a})`;
                    })}
                    color={
                        !(widget.options as PostsWindowWidgetOptionsImpl).reviews.product.color
                            ? mergedTheme.typography
                                ? mergedTheme.typography.color
                                : ''
                            : (widget.options as PostsWindowWidgetOptionsImpl).reviews.product.color
                    }
                />
            </LabelGroup>,
        ],
        <ActionCheckboxGroup
            key='widget.options.reviews.domain.enabled'
            label={i18n.t('Show Domain')}
            option={(widget.options as PostsWindowWidgetOptionsImpl).reviews.domain}
            value='enabled'
        />,
        (widget.options as PostsWindowWidgetOptionsImpl).reviews.domain.enabled && [
            <ActionSliderGroup
                key='widget.options.reviews.domain.size'
                label={i18n.t('Domain Size')}
                option={(widget.options as PostsWindowWidgetOptionsImpl).reviews.domain}
                value='size'
                showValue
                min={0}
                max={100}
            />,
            <LabelGroup label={i18n.t('Domain Color')} key='widget.options.reviews.domain.color'>
                <ActionColorSwatchGroup
                    key='widget.options.reviews.domain.color'
                    option={null}
                    value={null}
                    onClear={action(
                        () =>
                            ((widget.options as PostsWindowWidgetOptionsImpl).reviews.domain.color =
                                '')
                    )}
                    secondaryIcon={
                        (widget.options as PostsWindowWidgetOptionsImpl).reviews.domain.color
                            ? 'icon-undo'
                            : undefined
                    }
                    onChange={action(({ rgb: { r, g, b, a } }: any) => {
                        (widget.options as PostsWindowWidgetOptionsImpl).reviews.domain.color = `rgba(${r}, ${g}, ${b}, ${a})`;
                    })}
                    color={
                        !(widget.options as PostsWindowWidgetOptionsImpl).reviews.domain.color
                            ? mergedTheme.typography
                                ? mergedTheme.typography.color
                                : ''
                            : (widget.options as PostsWindowWidgetOptionsImpl).reviews.domain.color
                    }
                />
            </LabelGroup>,
        ],
    ],
});

export const userGroupTunables = (widget: Widget, isProfile?: boolean): TunablesCollapse => ({
    props: {
        label: i18n.t('User Group'),
        startOpen: true,
    } as CollapseProps,
    children: [
        widget.type !== 'postsWaterfall' && (widget.options as any).userGroupLayoutType && (
            <ActionSegmentedGroup
                key='widget.options.userGroupLayoutType'
                label={i18n.t('Composition')}
                option={widget.options}
                value='userGroupLayoutType'
                options={userGroupLayoutTypeOptions}
                handleSideEffects={selection =>
                    handleExtras(selection, widget.options as PostsWindowWidgetOptionsImpl, {
                        a: { showIconInUserGroup: true },
                        b: { showIconInUserGroup: false },
                        c: { showIconInUserGroup: false },
                        d: { showIconInUserGroup: false },
                    })
                }
            />
        ),
        <ActionSliderGroup
            key='widget.options.userGroupIconSize'
            label={i18n.t('Social Icon')}
            option={widget.options}
            value='userGroupIconSize'
            showValue
            min={0}
            max={30}
        />,
        <ActionCheckboxGroup
            key='widget.options.socialIconBgColor'
            label={i18n.t('Social Icon Background')}
            option={widget.options}
            value='socialIconBgColor'
        />,
        widget.type === 'profile' && (
            <ActionSliderGroup
                key='widget.options.userGroupAvatarSize'
                label={i18n.t('Avatar')}
                value='userGroupAvatarSize'
                option={widget.options}
                showValue
                min={0}
                max={100}
            />
        ),
        widget.type !== 'profile' && (
            <ActionCheckboxSliderGroup
                key='widget.options.showUserAvatar'
                label={i18n.t('Avatar')}
                showValue
                checkbox={{
                    option: widget.options,
                    value: 'showUserAvatar',
                }}
                slider={{
                    option: widget.options,
                    value: 'userGroupAvatarSize',
                    min: 0,
                    max: 100,
                }}
            />
        ),
        <ActionCheckboxSliderGroup
            key='widget.options.showUserName'
            label={i18n.t('User Name')}
            showValue
            checkbox={{
                option: widget.options,
                value: 'showUserName',
            }}
            slider={{
                option: widget.options,
                value: 'userGroupNameSize',
                min: 0,
                max: 100,
            }}
        />,
        <ActionCheckboxSliderGroup
            key='widget.options.showUserScreenName'
            label={i18n.t('Screen Name')}
            showValue
            checkbox={{
                option: widget.options,
                value: 'showUserScreenName',
            }}
            slider={{
                option: widget.options,
                value: 'userGroupScreenNameSize',
                min: 0,
                max: 100,
            }}
        />,
        (widget.options as any).location && (
            <ActionCheckboxSliderGroup
                key='widget.options.location'
                label={i18n.t('Location')}
                showValue
                checkbox={{
                    option: (widget.options as PostsWidgetOptionsImpl).location,
                    value: 'enabled',
                }}
                slider={{
                    option: (widget.options as PostsWidgetOptionsImpl).location,
                    value: 'size',
                    min: 0,
                    max: 100,
                }}
            />
        ),
        (widget.options as any).retweetIndicator && (
            <ActionCheckboxSliderGroup
                key='widget.options.retweetIndicator'
                label={i18n.t('Retweet Indicator')}
                showValue
                checkbox={{
                    option: (widget.options as PostsWidgetOptions).retweetIndicator,
                    value: 'enabled',
                }}
                slider={{
                    option: (widget.options as PostsWidgetOptions).retweetIndicator,
                    value: 'size',
                    min: 0,
                    max: 100,
                }}
            />
        ),
        (widget.options as any).retweetIndicator && (
            <ActionSliderGroup
                key='widget.options.retweetIndicator.opacity'
                label={i18n.t('Retweet Opacity')}
                option={(widget.options as PostsWidgetOptions).retweetIndicator}
                value='opacity'
                showValue
                min={0}
                max={100}
            />
        ),
        !isProfile && (
            <ActionCheckboxSliderGroup
                key='widget.options.showTimeStamp'
                label={i18n.t('Time Stamp')}
                showValue
                checkbox={{
                    option: widget.options,
                    value: 'showTimeStamp',
                }}
                slider={{
                    option: widget.options,
                    value: 'timeStampSize',
                    min: 0,
                    max: 100,
                }}
            />
        ),
        !isProfile && (widget.options as any).showTimeStamp && (
            <Flexbox gap key='showTimeStamp'>
                <ActionRadioGroup
                    key='widget.options.timeStampFormat.timeAgo'
                    label={i18n.t('Time Ago')}
                    option={widget.options}
                    value='timeStampFormat'
                    valueCheck='timeAgo'
                />
                <ActionRadioGroup
                    key='widget.options.timeStampFormat.timePosted'
                    label={i18n.t('Time Posted')}
                    option={widget.options}
                    value='timeStampFormat'
                    valueCheck='timePosted'
                />
            </Flexbox>
        ),
        !isProfile && widget.options.timeStampFormat === 'timePosted' && (
            <ActionSelectGroup
                label={i18n.t('Date Format')}
                key='widget.options.endianTime'
                option={widget.options}
                value='endianTime'
                options={endianOptions}
            />
        ),
        (widget.options as PostsWindowWidgetOptionsImpl).customFields && (
            <ActionCustomProperties key='widget.options.customFields' widget={widget} />
        ),
        <ActionCheckboxGroup
            key='widget.options.removeGenericPII'
            label={i18n.t('Remove PII when generic (ie "Instagram User")')}
            option={widget.options}
            value='removeGenericPII'
        />,
    ],
});

export const engagementTunables = (widget): TunablesCollapse => ({
    props: {
        label: i18n.t('Engagement'),
        startOpen: true,
    } as CollapseProps,
    children: [
        <ActionCheckboxSliderGroup
            key='widget.options.showEngagementMetrics'
            label={i18n.t('Engagement')}
            showValue
            checkbox={{
                option: widget.options,
                value: 'showEngagementMetrics',
            }}
            slider={{
                option: widget.options,
                value: 'engagementMetricsSize',
                min: 0,
                max: 50,
            }}
        />,
        widget.options.showEngagementMetrics && (
            <ActionCheckboxGroup
                key='widget.options.showZeroEngagementValues'
                label={i18n.t('Show Zeros')}
                option={widget.options}
                value='showZeroEngagementValues'
            />
        ),
        widget.postRequests && // kinda ugly, including the exclusion based on a report type
            !!widget.postRequests[0] &&
            widget.postRequests[0].sources[0] &&
            widget.postRequests[0].sources[0].id.report !== 'SPRINKSIGHTS' && (
                // ...sources[0].id is used to map the boolean to widget.postRequests[0].showInReplyToPost
                <React.Fragment key='widget.options.showInReplyToPost'>
                    <ActionCheckboxGroup
                        key='showInReplyToPost'
                        label={i18n.t('Show In-Reply-To Post')}
                        option={widget.postRequests[0].sources[0].id}
                        value='showInReplyToPost'
                    />
                    {!!widget.postRequests[0].sources[0].id.showInReplyToPost && (
                        <ActionCheckboxSliderGroup
                            key='widget.options.showReplyLabels'
                            label={i18n.t('Labels')}
                            showValue
                            checkbox={{
                                option: widget.options,
                                value: 'showReplyLabels',
                            }}
                            slider={{
                                option: widget.options,
                                value: 'showReplyLabelsSize',
                                min: 0,
                                max: 100,
                            }}
                        />
                    )}
                    <Flexbox key='widget.options.hashtagTextColor' gap='m'>
                        <ActionCheckboxGroup
                            key='showInReplyToPost'
                            label={i18n.t('Border')}
                            option={widget.options}
                            value='showReplyBorder'
                        />
                        <ActionColorSwatchGroup
                            secondaryIcon={
                                !!widget.options.replyBorderColor ? 'icon-undo' : undefined
                            }
                            key='options.replyBorderColor'
                            option={widget.options}
                            value='replyBorderColor'
                        />
                    </Flexbox>
                </React.Fragment>
            ),
    ],
});

export const adsTunables = (widget): TunablesCollapse => ({
    props: {
        label: 'Ads',
        startOpen: true,
    } as CollapseProps,
    children: [
        widget.postRequests &&
            widget.postRequests[0] &&
            widget.postRequests[0].sources &&
            widget.postRequests[0].sources[0] &&
            widget.postRequests[0].sources[0].id &&
            widget.postRequests[0].sources[0].id.reportingEngine === 'PAID' && (
                <ActionCheckboxSliderGroup
                    key='widget.options.showAdsMetrics'
                    label={i18n.t('Ads Metrics')}
                    showValue
                    checkbox={{
                        option: widget.options,
                        value: 'showAdsMetrics',
                    }}
                    slider={{
                        option: widget.options,
                        value: 'adsMetricsSize',
                        min: 0,
                        max: 50,
                    }}
                />
            ),
    ],
});

export const textEntityTunables = (widget): TunablesCollapse => ({
    props: {
        label: i18n.t('Text Entities'),
        startOpen: true,
    } as CollapseProps,
    children: [
        <LabelGroup label={i18n.t('#hashtags')} key='widget.options.hashtags'>
            <LabelGroup label={i18n.t('Text')} key='widget.options.hashtagTextColor'>
                <ActionColorSwatchGroup
                    secondaryIcon={!!widget.options.hashtagTextColor ? 'icon-undo' : undefined}
                    key='options.hashtagTextColor'
                    option={widget.options}
                    value='hashtagTextColor'
                />
            </LabelGroup>
            <LabelGroup label={i18n.t('Back')} key='widget.options.hashtagBackgroundColor'>
                <ActionColorSwatchGroup
                    secondaryIcon={
                        !!widget.options.hashtagBackgroundColor ? 'icon-undo' : undefined
                    }
                    key='options.hashtagBackgroundColor'
                    option={widget.options}
                    value='hashtagBackgroundColor'
                />
            </LabelGroup>
        </LabelGroup>,
        <LabelGroup label={i18n.t('@mentions')} key='widget.options.mentions'>
            <LabelGroup label={i18n.t('Text')} key='widget.options.mentionTextColor'>
                <ActionColorSwatchGroup
                    secondaryIcon={!!widget.options.mentionTextColor ? 'icon-undo' : undefined}
                    key='options.mentionTextColor'
                    option={widget.options}
                    value='mentionTextColor'
                />
            </LabelGroup>
            <LabelGroup label={i18n.t('Back')} key='widget.options.mentionBackgroundColor'>
                <ActionColorSwatchGroup
                    secondaryIcon={
                        !!widget.options.mentionBackgroundColor ? 'icon-undo' : undefined
                    }
                    key='options.mentionBackgroundColor'
                    option={widget.options}
                    value='mentionBackgroundColor'
                />
            </LabelGroup>
        </LabelGroup>,
        <LabelGroup label={i18n.t('urls')} key='widget.options.urls'>
            <LabelGroup label={i18n.t('Text')} key='widget.options.urlTextColor'>
                <ActionColorSwatchGroup
                    secondaryIcon={!!widget.options.urlTextColor ? 'icon-undo' : undefined}
                    key='options.urlTextColor'
                    option={widget.options}
                    value='urlTextColor'
                />
            </LabelGroup>
            <LabelGroup label={i18n.t('Back')} key='widget.options.urlBackgroundColor'>
                <ActionColorSwatchGroup
                    secondaryIcon={!!widget.options.urlBackgroundColor ? 'icon-undo' : undefined}
                    key='options.urlBackgroundColor'
                    option={widget.options}
                    value='urlBackgroundColor'
                />
            </LabelGroup>
        </LabelGroup>,
        <ActionCheckboxGroup
            key='widget.options.showTextEntityUrls'
            label={i18n.t('Show Links')}
            option={widget.options}
            value='showTextEntityUrls'
        />,
        widget.options.showTextEntityUrls && (
            <ActionCheckboxGroup
                key='widget.options.showExpandedEntityUrls'
                label={i18n.t('Display Expanded URLs')}
                option={widget.options}
                value='showExpandedEntityUrls'
            />
        ),
        widget.options?.emoji && (
            <LabelGroup label='Emoji Set' key='widget.emoji'>
                <ActionSelectGroup
                    key='widget.emoji.platform'
                    options={emojiPlatformOptions}
                    option={widget.options.emoji}
                    value='platform'
                />
            </LabelGroup>
        ),
    ],
});

export const contentBackgroundColorTunable = (widget: Widget) => (
    <LabelGroup label={i18n.t('Background')} key='options.contentBackgroundColor'>
        <ActionColorSwatchGroup
            key='options.contentBackgroundColor'
            option={widget.options}
            value='contentBackgroundColor'
        />
    </LabelGroup>
);

export const profanityTunables = (widget: Widget) => {
    // waiting on Twitter review
    return null;

    const widgetOptions = (widget.options as any)?.profanityFilter;
    if (!widgetOptions) {
        return null;
    }

    return {
        props: {
            label: i18n.t('Profanity Filter'),
            startOpen: false,
            isEnabled: widgetOptions?.enabled,
            option: widgetOptions,
            value: 'enabled',
        } as CollapseProps,
        children: [
            <LabelGroup
                label={i18n.t('Replacement Character')}
                key='widgetOptions.profanityFilter.obscureSymbol'>
                <ActionInput
                    key='widgetOptions.obscureSymbol input'
                    option={widgetOptions}
                    value='obscureSymbol'
                    placeholder={i18n.t('Replacement Character')}
                />
            </LabelGroup>,
            <LabeledFormElement label={i18n.t('Profanity List')} key='widget.options.profanityList'>
                <ActionTextAreaBlacklist
                    key='widget.options.profanityList'
                    option={widgetOptions}
                    value='profanityList'
                    placeholder={i18n.t('comma separated list')}
                />
            </LabeledFormElement>,
        ],
    };
};

export const blackListTunables = (widget: Widget) => {
    const wordCloudDimNames = ['WORD_CLOUD_MESSAGE', 'TOP_TERMS', 'EMOTICONS'];
    const wordCloudCheck: boolean = widgetHasDim(widget, wordCloudDimNames);

    return (
        wordCloudCheck && (
            <ActionTextAreaBlacklist
                key='widget.options.blacklist'
                option={widget.options}
                value='blacklist'
                placeholder={i18n.t('comma separated list')}
            />
        )
    );
};

export const imageAdjustmentTunables = (widget: Widget) => {
    return {
        props: {
            label: i18n.t('Image Adjustments'),
            startOpen: false,
        } as CollapseProps,
        children: [
            <Flexbox key='wrapper widget.options.imageFiltersOpen' vertical gap='m'>
                <Flexbox gap>
                    <ActionRadioGroup
                        key='widget.options.imageFiltersOpen'
                        label={i18n.t('Filters')}
                        option={widget.options}
                        value='imageFiltersOpen'
                        valueCheck={true}
                    />
                    <ActionRadioGroup
                        key='!widget.options.imageFiltersOpen'
                        label={i18n.t('Advanced')}
                        option={widget.options}
                        value='imageFiltersOpen'
                        valueCheck={false}
                    />
                </Flexbox>
                {widget.options.imageFiltersOpen && (
                    <ActionImageFilterPresets
                        key='widget.options.presetImageFilters'
                        option={widget.options}
                        value='presetImageFilters'
                    />
                )}
            </Flexbox>,
            !widget.options.imageFiltersOpen && [
                <LabelGroup key='widget.options.imageBlendColor' label={i18n.t('Blend Color')}>
                    <ActionColorSwatchGroup
                        key='widget.options.imageBlendColor'
                        option={widget.options}
                        value='imageBlendColor'
                    />
                </LabelGroup>,
                <ActionSelectGroup
                    label={i18n.t('Blend Mode')}
                    key='widget.options.imageBlendMode'
                    option={widget.options}
                    value='imageBlendMode'
                    options={blendModeOptions}
                />,
                <ActionCheckboxSliderGroup
                    key='widget.options.imageFilterBlur'
                    label={i18n.t('Blur')}
                    showValue
                    checkbox={{
                        option: widget.options,
                        value: 'imageFilterBlur',
                    }}
                    slider={{
                        option: widget.options,
                        value: 'imageFilterBlurValue',
                        min: 0,
                        max: 50,
                    }}
                />,
                <ActionCheckboxSliderGroup
                    key='widget.options.imageFilterBrightness'
                    label={i18n.t('Brightness')}
                    showValue
                    suffix='%'
                    checkbox={{
                        option: widget.options,
                        value: 'imageFilterBrightness',
                    }}
                    slider={{
                        option: widget.options,
                        value: 'imageFilterBrightnessValue',
                        min: 0,
                        max: 200,
                    }}
                />,
                <ActionCheckboxSliderGroup
                    key='widget.options.imageFilterContrast'
                    label={i18n.t('Contrast')}
                    showValue
                    suffix='%'
                    checkbox={{
                        option: widget.options,
                        value: 'imageFilterContrast',
                    }}
                    slider={{
                        option: widget.options,
                        value: 'imageFilterContrastValue',
                        min: 0,
                        max: 200,
                    }}
                />,
                <ActionCheckboxSliderGroup
                    key='widget.options.imageFilterGrayscale'
                    label={i18n.t('Grayscale')}
                    showValue
                    suffix='%'
                    checkbox={{
                        option: widget.options,
                        value: 'imageFilterGrayscale',
                    }}
                    slider={{
                        option: widget.options,
                        value: 'imageFilterGrayscaleValue',
                        min: 0,
                        max: 100,
                    }}
                />,
                <ActionCheckboxSliderGroup
                    key='widget.options.imageFilterSaturate'
                    label={i18n.t('Saturation')}
                    showValue
                    suffix='%'
                    checkbox={{
                        option: widget.options,
                        value: 'imageFilterSaturate',
                    }}
                    slider={{
                        option: widget.options,
                        value: 'imageFilterSaturateValue',
                        min: 0,
                        max: 200,
                    }}
                />,
                <ActionCheckboxSliderGroup
                    key='widget.options.imageFilterOpacity'
                    label={i18n.t('Opacity')}
                    showValue
                    suffix='%'
                    checkbox={{
                        option: widget.options,
                        value: 'imageFilterOpacity',
                    }}
                    slider={{
                        option: widget.options,
                        value: 'imageFilterOpacityValue',
                        min: 0,
                        max: 100,
                    }}
                />,
                <ActionCheckboxSliderGroup
                    key='widget.options.imageFilterHueRotate'
                    label={i18n.t('Hue')}
                    showValue
                    suffix='º'
                    checkbox={{
                        option: widget.options,
                        value: 'imageFilterHueRotate',
                    }}
                    slider={{
                        option: widget.options,
                        value: 'imageFilterHueRotateValue',
                        min: 0,
                        max: 360,
                    }}
                />,
                <ActionCheckboxSliderGroup
                    key='widget.options.imageFilterInvert'
                    label={i18n.t('Invert')}
                    showValue
                    suffix='%'
                    checkbox={{
                        option: widget.options,
                        value: 'imageFilterInvert',
                    }}
                    slider={{
                        option: widget.options,
                        value: 'imageFilterInvertValue',
                        min: 0,
                        max: 100,
                    }}
                />,
                <ActionCheckboxSliderGroup
                    key='widget.options.imageFilterSepia'
                    label={i18n.t('Sepia')}
                    showValue
                    suffix='%'
                    checkbox={{
                        option: widget.options,
                        value: 'imageFilterSepia',
                    }}
                    slider={{
                        option: widget.options,
                        value: 'imageFilterSepiaValue',
                        min: 0,
                        max: 100,
                    }}
                />,
            ],
        ],
    };
};

export const thresholdsTunables = (
    widget: Widget,
    mergedTheme: Theme,
    thresholdType: 'multipleLine' | 'stackedBar' | 'heatMap'
) => ({
    props: {
        label: i18n.t('Thresholds'),
        startOpen: true,
        isEnabled: (widget.options as any).thresholdsEnabled,
        option: widget.options,
        value: 'thresholdsEnabled',
    } as CollapseProps,
    children: [
        <ThresholdTool
            widget={widget}
            theme={mergedTheme}
            thresholdType={thresholdType}
            key='thresholdsTool'
        />,
        thresholdType === 'multipleLine' && (
            <ActionCheckboxGroup
                key='widget.options.thresholdsStacked'
                label={i18n.t('Stacked Thresholds')}
                option={widget.options}
                value='thresholdsStacked'
            />
        ),
        !widget.options.thresholdsStacked &&
            thresholdType === 'multipleLine' && [
                <ActionSliderGroup
                    key='widget.options.thresholdLineWidth'
                    label={i18n.t('Line Width')}
                    option={widget.options}
                    value='thresholdLineWidth'
                    showValue
                    min={0}
                    max={10}
                />,
                <ActionCheckboxGroup
                    key='widget.options.thresholdsDashed'
                    label={i18n.t('Dashed Thresholds')}
                    option={widget.options}
                    value='thresholdsDashed'
                />,
            ],
        thresholdType === 'multipleLine' && [
            <ActionCheckboxGroup
                key='widget.options.thresholdsAxis'
                label={i18n.t('Show Axis')}
                option={widget.options}
                value='thresholdsAxis'
            />,
        ],
        !widget.options.thresholdsStacked &&
            thresholdType === 'stackedBar' && [
                <ActionSliderGroup
                    key='widget.options.thresholdBarHeight'
                    label={i18n.t('Bar Height')}
                    option={widget.options}
                    value='thresholdBarHeight'
                    showValue
                    min={0}
                    max={10}
                />,
                <ActionSliderGroup
                    key='widget.options.thresholdBarLength'
                    label={i18n.t('Bar Length')}
                    option={widget.options}
                    value='thresholdBarLength'
                    showValue
                    min={0}
                    max={100}
                />,
            ],
        thresholdType === 'multipleLine' && [
            <ActionCheckboxGroup
                key='widget.options.thresholdsLegend'
                label='Show Legend'
                option={widget.options}
                value='thresholdsLegend'
            />,
            <ActionCheckboxGroup
                key='widget.options.thresholdsAxis'
                label='Show Axis'
                option={widget.options}
                value='thresholdsAxis'
            />,
        ],
        !widget.options.thresholdsStacked &&
            thresholdType === 'stackedBar' && [
                <ActionSliderGroup
                    key='widget.options.thresholdBarHeight'
                    label='Bar Height'
                    option={widget.options}
                    value='thresholdBarHeight'
                    showValue
                    min={0}
                    max={10}
                />,
                <ActionSliderGroup
                    key='widget.options.thresholdBarLength'
                    label='Bar Length'
                    option={widget.options}
                    value='thresholdBarLength'
                    showValue
                    min={0}
                    max={100}
                />,
            ],
    ],
});

type XAxisTunablesConfig = {
    widget: Widget;
    padding?: boolean;
    allPadding?: boolean;
    ticks?: boolean;
    line?: boolean;
    numTicks?: boolean;
    label?: boolean;
    format?: boolean;
    tickLabelName?: string;
};

export const xAxisTunables = ({
    widget,
    padding = true,
    allPadding = true,
    ticks = true,
    label = true,
    format = true,
    line,
    numTicks,
    tickLabelName,
}: XAxisTunablesConfig): TunablesCollapse => {
    const isTimeDim: boolean = widgetHasDim(widget, [
        'SN_CREATED_TIME',
        'measurementTime',
        'CREATED_DATE',
        'date',
    ]);
    return {
        props: {
            label: 'X Axis',
            startOpen: true,
            isEnabled: (widget.options as ComboChartWidgetOptions).xAxis.enabled,
            option: (widget.options as ComboChartWidgetOptions).xAxis,
            value: 'enabled',
        } as CollapseProps,
        children: [
            line && (
                <ActionCheckboxGroup
                    key='widget.options.xAxis.ticks.line'
                    label={i18n.t('Line')}
                    option={(widget.options as ComboChartWidgetOptions).xAxis.ticks.line}
                    value='enabled'
                />
            ),
            label && (
                <>
                    <ActionCheckboxSliderGroup
                        key='widget.options.xAxis.label'
                        label={i18n.t('Label')}
                        showValue
                        checkbox={{
                            option: (widget.options as ComboChartWidgetOptions).xAxis.label,
                            value: 'enabled',
                        }}
                        slider={{
                            option: (widget.options as ComboChartWidgetOptions).xAxis.label,
                            value: 'size',
                            min: 0,
                            max: 100,
                        }}
                    />
                    {(widget.options as ComboChartWidgetOptions).xAxis.label.enabled && (
                        <ActionSliderGroup
                            key='widget.options.xAxis.padding'
                            label={i18n.t('Label Padding')}
                            option={(widget.options as ComboChartWidgetOptions).xAxis.label}
                            value='padding'
                            showValue
                            min={0}
                            max={100}
                        />
                    )}
                </>
            ),
            ticks && (
                <ActionCheckboxSliderGroup
                    key='widget.options.xAxis.ticks'
                    label={i18n.t(tickLabelName || 'Tick')}
                    showValue
                    checkbox={{
                        option: (widget.options as ComboChartWidgetOptions).xAxis.ticks.label,
                        value: 'enabled',
                        onChange: e => {
                            if (
                                (widget.options as BarChartWidgetOptions).orientation ===
                                'horizontal'
                            ) {
                                widget.options.labelEnable = !e.target.checked;
                            }
                        },
                    }}
                    slider={{
                        option: (widget.options as ComboChartWidgetOptions).xAxis.ticks.label,
                        value: 'size',
                        min: 0,
                        max: 100,
                    }}
                />
            ),
            ticks && (widget.options as ComboChartWidgetOptions).xAxis.ticks.label.enabled && (
                <ActionSelectGroup
                    label={i18n.t('Angle')}
                    key='widget.options.Tick Label Angle'
                    option={(widget.options as ComboChartWidgetOptions).xAxis.ticks.label}
                    value='angle'
                    options={tickLabelAngleOptions}
                />
            ),
            ticks && !isTimeDim && format && (
                <>
                    <ActionSegmentedGroup
                        between
                        key='widget.options.xAxis.ticks.label.format'
                        label={i18n.t('Format')}
                        option={(widget.options as ComboChartWidgetOptions).xAxis.ticks.label}
                        value={'numberFormat'}
                        options={metricValueOptions}
                        showOptionLabel
                    />
                    {(widget.options as ComboChartWidgetOptions).xAxis.ticks.label.numberCase &&
                        (widget.options as ComboChartWidgetOptions).xAxis.ticks.label
                            .numberFormat !== '1,234' && (
                            <ActionSelectGroup
                                label={i18n.t('Unit Case')}
                                key='widget.options.xAxis.ticks.label.case'
                                options={metricValueCaseOptions}
                                option={
                                    (widget.options as ComboChartWidgetOptions).xAxis.ticks.label
                                }
                                value='numberCase'
                            />
                        )}
                </>
            ),
            ticks && numTicks && (
                <ActionSliderGroup
                    key='widget.options.xAxis.ticks.numTicks'
                    label={i18n.t('Number of Ticks')}
                    option={(widget.options as ComboChartWidgetOptions).xAxis.ticks}
                    value='numTicks'
                    min={0}
                    max={30}
                />
            ),
            isTimeDim && (
                <ActionSelectGroup
                    key='widget.options.xAxisDateFormat'
                    label='Time Format'
                    option={(widget.options as ComboChartWidgetOptions).xAxis.ticks.label}
                    value='timeFormat'
                    options={xAxisOptions && xAxisOptions}
                />
            ),
            padding && [
                (widget.options as BubblePlotChartWidgetOptionsImpl).xAxis.hasOwnProperty(
                    'padding'
                ) &&
                    (widget.options as BubblePlotChartWidgetOptionsImpl).xAxis.padding.hasOwnProperty(
                        'start'
                    ) && (
                        <ActionSliderGroup
                            key='widget.options.xAxis.padding.start'
                            label='Left Padding'
                            showValue
                            option={
                                (widget.options as BubblePlotChartWidgetOptionsImpl).xAxis.padding
                            }
                            value='start'
                            suffix='%'
                            min={0}
                            max={100}
                        />
                    ),
                (widget.options as BubblePlotChartWidgetOptionsImpl).xAxis.hasOwnProperty(
                    'padding'
                ) &&
                    (widget.options as BubblePlotChartWidgetOptionsImpl).xAxis.padding.hasOwnProperty(
                        'end'
                    ) && (
                        <ActionSliderGroup
                            key='widget.options.xAxis.padding.end'
                            label='Right Padding'
                            showValue
                            option={
                                (widget.options as BubblePlotChartWidgetOptionsImpl).xAxis.padding
                            }
                            value='end'
                            suffix='%'
                            min={0}
                            max={100}
                        />
                    ),
            ],
            allPadding && (widget.options as ComboChartWidgetOptions).hasOwnProperty('padding') && (
                <ActionSliderGroup
                    key='widget.options.padding'
                    label='Padding'
                    option={widget.options as ComboChartWidgetOptions}
                    value='padding'
                    showValue
                    min={0}
                    max={100}
                />
            ),
        ],
    };
};

export const yAxisTunables = (
    widget: Widget,
    ticks = true,
    format = true,
    numTicks = true,
    line = false,
    label = true
): TunablesCollapse => {
    const yAxisZeroStartOption: boolean =
        widget.type === 'lineChart' ||
        widget.type === 'bubblePlotChart' ||
        widget.type === 'axisBubbleChart';
    return {
        props: {
            label: 'Y Axis',
            startOpen: true,
            isEnabled: (widget.options as ComboChartWidgetOptions).yAxis.enabled,
            option: (widget.options as ComboChartWidgetOptions).yAxis,
            value: 'enabled',
        } as CollapseProps,
        children: [
            label && (
                <>
                    <ActionCheckboxSliderGroup
                        key='widget.options.yAxis.label'
                        label={i18n.t('Label')}
                        showValue
                        checkbox={{
                            option: (widget.options as ComboChartWidgetOptions).yAxis.label,
                            value: 'enabled',
                        }}
                        slider={{
                            option: (widget.options as ComboChartWidgetOptions).yAxis.label,
                            value: 'size',
                            min: 0,
                            max: 100,
                        }}
                    />
                    {(widget.options as ComboChartWidgetOptions).yAxis.label.enabled && (
                        <ActionSliderGroup
                            key='widget.options.yAxis.label.padding'
                            label={i18n.t('Label Padding')}
                            option={(widget.options as ComboChartWidgetOptions).yAxis.label}
                            value='padding'
                            showValue
                            min={0}
                            max={100}
                        />
                    )}
                </>
            ),
            line && (
                <ActionCheckboxGroup
                    key='widget.options.xAxis.ticks.line'
                    label={i18n.t('Line')}
                    option={(widget.options as ComboChartWidgetOptions).yAxis.ticks.line}
                    value='enabled'
                />
            ),
            ticks && [
                <ActionCheckboxSliderGroup
                    key='widget.options.yAxis.ticks'
                    label={i18n.t('Ticks')}
                    showValue
                    checkbox={{
                        option: (widget.options as ComboChartWidgetOptions).yAxis.ticks.label,
                        value: 'enabled',
                    }}
                    slider={{
                        option: (widget.options as ComboChartWidgetOptions).yAxis.ticks.label,
                        value: 'size',
                        min: 0,
                        max: 100,
                    }}
                />,
                <ActionSegmentedGroup
                    between
                    key='widget.options.yAxis.ticks.label'
                    label={i18n.t('Format')}
                    option={(widget.options as ComboChartWidgetOptions).yAxis.ticks.label}
                    value={'numberFormat'}
                    options={metricValueOptions}
                    showOptionLabel
                />,
                (widget.options as ComboChartWidgetOptions).yAxis.ticks.label.numberCase &&
                    (widget.options as ComboChartWidgetOptions).yAxis.ticks.label.numberFormat !==
                        '1,234' && (
                        <ActionSelectGroup
                            label={i18n.t('Unit Case')}
                            key='widget.options.yAxis.ticks.label.case'
                            options={metricValueCaseOptions}
                            option={(widget.options as ComboChartWidgetOptions).yAxis.ticks.label}
                            value='numberCase'
                        />
                    ),
                numTicks && (
                    <ActionSliderGroup
                        key='widget.options.yAxis.ticks.numTicks'
                        label={i18n.t('Number of Ticks')}
                        option={(widget.options as ComboChartWidgetOptions).yAxis.ticks}
                        value='numTicks'
                        min={0}
                        max={30}
                    />
                ),
            ],
            (widget.options as BubblePlotChartWidgetOptionsImpl).yAxis.hasOwnProperty('padding') &&
                (widget.options as BubblePlotChartWidgetOptionsImpl).yAxis.padding.hasOwnProperty(
                    'start'
                ) && (
                    <ActionSliderGroup
                        key='widget.options.yAxis.padding.start'
                        label='Top Padding'
                        showValue
                        option={(widget.options as BubblePlotChartWidgetOptionsImpl).yAxis.padding}
                        value='start'
                        suffix='%'
                        min={0}
                        max={100}
                    />
                ),
            (widget.options as BubblePlotChartWidgetOptionsImpl).yAxis.hasOwnProperty('padding') &&
                (widget.options as BubblePlotChartWidgetOptionsImpl).yAxis.padding.hasOwnProperty(
                    'end'
                ) && (
                    <ActionSliderGroup
                        key='widget.options.yAxis.padding.end'
                        label='Bottom Padding'
                        showValue
                        option={(widget.options as BubblePlotChartWidgetOptionsImpl).yAxis.padding}
                        value='end'
                        suffix='%'
                        min={0}
                        max={100}
                    />
                ),
            yAxisZeroStartOption && (
                <ActionCheckboxGroup
                    key='widget.options.yAxisZeroStart'
                    label={i18n.t('Start Y-Axis from Zero')}
                    option={widget.options}
                    value='yAxisZeroStart'
                />
            ),
        ],
    };
};

export const comboLegendTunables = (widget: Widget): TunablesCollapse => ({
    props: {
        label: 'Legend',
        startOpen: true,
        isEnabled: (widget.options as ComboChartWidgetOptions).legend.enabled,
        option: (widget.options as ComboChartWidgetOptions).legend,
        value: 'enabled',
    } as CollapseProps,
    children: [
        <ActionSliderGroup
            key='widget.legend'
            label='Label'
            showValue
            option={(widget.options as ComboChartWidgetOptions).legend}
            value='size'
            min={0}
            max={100}
        />,
        <ActionCheckboxSliderGroup
            key='widget.legend.points'
            label='Marker'
            showValue
            checkbox={{
                option: (widget.options as ComboChartWidgetOptions).legend.points,
                value: 'enabled',
            }}
            slider={{
                option: (widget.options as ComboChartWidgetOptions).legend.points,
                value: 'size',
                min: 0,
                max: 100,
            }}
        />,
        <ActionSelectGroup
            label='Orientation'
            key='widget.options.legend'
            option={(widget.options as ComboChartWidgetOptions).legend}
            value='alignment'
            options={horizontalAlignOptions}
        />,
    ],
});

export const zAxisTunables = (widget: Widget): TunablesCollapse => {
    const hasZMetric: boolean =
        (widget.type === 'axisBubbleChart' &&
            widget.analyticsRequests &&
            widget.analyticsRequests[0].projections.length === 3) ||
        (widget.type === 'bubblePlotChart' &&
            widget.analyticsRequests &&
            widget.analyticsRequests[0].projections.length === 2);

    return {
        props: {
            label: 'Z Axis',
            startOpen: true,
            isEnabled: (widget.options as BubblePlotChartWidgetOptionsImpl).zAxis.enabled,
            option: (widget.options as BubblePlotChartWidgetOptionsImpl).zAxis,
            value: 'enabled',
        } as CollapseProps,
        children: [
            hasZMetric ? (
                <React.Fragment key='widget.options.zAxis.radius.minMax'>
                    <ActionSliderGroup
                        key='widget.options.zAxis.radius.min'
                        label='Min'
                        showValue
                        option={(widget.options as BubblePlotChartWidgetOptionsImpl).zAxis.radius}
                        value='min'
                        min={0}
                        max={200}
                    />
                    <ActionSliderGroup
                        key='widget.options.zAxis.radius.max'
                        label='Max'
                        showValue
                        option={(widget.options as BubblePlotChartWidgetOptionsImpl).zAxis.radius}
                        value='max'
                        min={0}
                        max={200}
                    />
                </React.Fragment>
            ) : (
                <ActionSliderGroup
                    key='widget.options.zAxis.radius.size'
                    label='Size'
                    showValue
                    option={(widget.options as BubblePlotChartWidgetOptionsImpl).zAxis.radius}
                    value='size'
                    min={0}
                    max={200}
                />
            ),
        ],
    };
};

export const bubbleValuesTunables = (widget: Widget, mergedTheme: Theme): TunablesCollapse => {
    const { zAxisValues } = widget.options as AxisBubbleChartWidgetOptionsImpl;
    return {
        props: {
            label: 'Bubble Values',
            startOpen: false,
            isEnabled: zAxisValues.enabled,
            option: zAxisValues,
            value: 'enabled',
        } as CollapseProps,
        children: [
            <React.Fragment key='widget.options.zAxisValues.size'>
                <ActionCheckboxGroup
                    key='widget.options.zAxisValues.autoSize'
                    label='Auto Size'
                    option={zAxisValues}
                    value='autoSize'
                />
            </React.Fragment>,
            !zAxisValues.autoSize && (
                <ActionSliderGroup
                    key='widget.options.zAxisValues.size'
                    label={i18n.t('Size')}
                    option={zAxisValues}
                    value='size'
                    showValue
                    min={0}
                    max={100}
                />
            ),
            <LabelGroup label={i18n.t('Text')} key='widget.options.zAxisValues.color'>
                <ActionColorSwatchGroup
                    secondaryIcon={!!zAxisValues.color ? 'icon-undo' : undefined}
                    value={zAxisValues.color ? zAxisValues.color : mergedTheme.typography?.color}
                    option={null}
                    color={zAxisValues.color || mergedTheme.typography?.color}
                    onClear={action(() => (zAxisValues.color = ''))}
                    onChange={action(
                        ({ rgb: { r, g, b, a } }: any) =>
                            (zAxisValues.color = `rgba(${r}, ${g}, ${b}, ${a})`)
                    )}
                />
            </LabelGroup>,
            <ActionSegmentedGroup
                between
                key='widget.options.zAxisValues.format'
                label={i18n.t('Format')}
                option={zAxisValues}
                value={'format'}
                options={metricValueOptions}
                showOptionLabel
            />,
        ],
    };
};

export const gridTunable = (widget: Widget) => (
    <ActionCheckboxGroup
        key='widget.options.grid'
        label='Grid'
        option={(widget.options as ComboChartWidgetOptions).grid}
        value='enabled'
    />
);

export const gridLayoutTunables = (widget: Widget, mergedTheme): TunablesCollapse => ({
    props: {
        label: 'Layout',
        startOpen: true,
    } as CollapseProps,
    children: [
        <ActionSliderGroup
            key='widget.options.gridLayout.columns'
            label={i18n.t('Columns')}
            option={(widget.options as MultiMetricWidgetOptionsImpl).gridLayout}
            value='columns'
            showValue
            min={1}
            max={10}
        />,
        <ActionCheckboxGroup
            key='widget.options.stretchColumnsToFit'
            label={i18n.t('Stretch Columns to Fit')}
            option={(widget.options as MultiMetricWidgetOptionsImpl).gridLayout}
            value='stretchColumnsToFit'
        />,
        <ActionSliderGroup
            key='widget.options.gridLayout.gap'
            label={i18n.t('Gap')}
            option={(widget.options as MultiMetricWidgetOptionsImpl).gridLayout}
            value='gap'
            showValue
            min={0}
            max={100}
        />,
        <ActionSegmentedGroup
            key='widget.options.horizontalAlign'
            label={i18n.t('Horizontal Align')}
            option={widget.options}
            value={'alignment'}
            options={horizontalAlignOptions}
        />,
        (widget.options as MultiMetricWidgetOptionsImpl).alignment !== 'center' && (
            <ActionSliderGroup
                key='widget.options.paddings'
                label={i18n.t('Cell Padding')}
                option={(widget.options as MultiMetricWidgetOptionsImpl).cell}
                value='padding'
                showValue
                min={0}
                max={50}
            />
        ),
        <LabelGroup label={i18n.t('Cell Background')} key='widget.options.cell.background'>
            <ActionColorSwatchGroup
                title={i18n.t('Cell Background')}
                secondaryIcon={
                    !!(widget.options as MultiMetricWidgetOptionsImpl).cell.background
                        ? 'icon-undo'
                        : undefined
                }
                value={null}
                option={null}
                color={
                    (widget.options as MultiMetricWidgetOptionsImpl).cell.background ||
                    emptySwatchColor
                }
                onClear={action(
                    () => ((widget.options as MultiMetricWidgetOptionsImpl).cell.background = '')
                )}
                onChange={action(
                    ({ rgb: { r, g, b, a } }: any) =>
                        ((widget.options as MultiMetricWidgetOptionsImpl).cell.background = `rgba(${r}, ${g}, ${b}, ${a})`)
                )}
            />
        </LabelGroup>,
        <ActionCheckboxSliderColorSwatchGroup
            key='widget.options.cell'
            label={i18n.t('Cell Border')}
            showValue
            checkbox={{
                checked: (widget.options as MultiMetricWidgetOptionsImpl).cell.border.enabled,
                value: 'enabled',
                option: (widget.options as MultiMetricWidgetOptionsImpl).cell.border,
            }}
            slider={{
                min: 0,
                max: 100,
                value: 'size',
                option: (widget.options as MultiMetricWidgetOptionsImpl).cell.border,
            }}
            colorSwatch={{
                option: (widget.options as MultiMetricWidgetOptionsImpl).cell.border,
                value: 'color',
                onClear: action(
                    () => ((widget.options as MultiMetricWidgetOptionsImpl).cell.border.color = '')
                ),
                secondaryIcon: (widget.options as MultiMetricWidgetOptionsImpl).cell.border.color
                    ? 'icon-undo'
                    : undefined,
                color:
                    (widget.options as MultiMetricWidgetOptionsImpl).cell.border.color ||
                    widget.theme?.typography?.color ||
                    mergedTheme?.typography?.color,
                onChange: action(
                    ({ rgb: { r, g, b, a } }) =>
                        ((widget.options as MultiMetricWidgetOptionsImpl).cell.border.color = `rgba(${r}, ${g}, ${b}, ${a})`)
                ),
            }}
        />,
    ],
});

export const bubbleBorderTunable = (widget: Widget) => (
    <ActionCheckboxGroup
        key='widget.options.showBorderColor'
        label='Show Image Border'
        option={widget.options as AxisBubbleChartWidgetOptionsImpl}
        value='showBorderColor'
    />
);

type BorderRadiusTunableOptions = {
    borderMode?: boolean;
    borderAll?: boolean;
    borderEnds?: boolean;
};

export const borderRadiusTunable = (widget: Widget, options?: BorderRadiusTunableOptions) => {
    const borderMode =
        options && options.borderMode ? (
            <LabelGroup label={i18n.t('Border Radius')} key='widget.options.borderRadiusMode'>
                <ActionRadioGroup
                    key='widget.options.borderRadiusMode'
                    label={i18n.t('All')}
                    option={widget.options}
                    value='borderRadiusMode'
                    valueCheck='all'
                />
                <ActionRadioGroup
                    key='widget.options.borderRadiusMode.borderEnds'
                    label={i18n.t('Ends')}
                    option={widget.options}
                    value='borderRadiusMode'
                    valueCheck='ends'
                />
            </LabelGroup>
        ) : null;

    const borderAll =
        !options ||
        (options.borderMode &&
            (widget.options as BarChartWidgetOptionsImpl).borderRadiusMode === 'all') ? (
            <ActionSliderGroup
                key='widget.options.borderRadius'
                label={i18n.t('Radius')}
                option={widget.options}
                value='borderRadius'
                showValue
                min={0}
                max={50}
            />
        ) : null;

    const borderEnds =
        options &&
        options.borderEnds &&
        (widget.options as BarChartWidgetOptionsImpl).borderRadiusMode === 'ends' ? (
            <React.Fragment key='widget.options.borderRadiusStartEnd'>
                <ActionSliderGroup
                    key='widget.options.borderRadiusStart'
                    label={i18n.t('Start')}
                    option={widget.options}
                    value='borderRadiusStart'
                    showValue
                    min={0}
                    max={50}
                />
                <ActionSliderGroup
                    key='widget.options.borderRadiusEnd'
                    label={i18n.t('End')}
                    option={widget.options}
                    value='borderRadiusEnd'
                    showValue
                    min={0}
                    max={50}
                />
            </React.Fragment>
        ) : null;

    return [borderMode, borderAll, borderEnds];
};

export const borderTunables = (widget: Widget) => (
    <ActionCheckboxSliderColorSwatchGroup
        key='widget.options.border tunable'
        label={i18n.t('Border')}
        showValue
        checkbox={{
            checked: (widget.options as CustomContentRichTextOptionsImpl).border.enabled,
            value: 'enabled',
            option: (widget.options as CustomContentRichTextOptionsImpl).border,
        }}
        slider={{
            min: 0,
            max: 100,
            value: 'size',
            option: (widget.options as CustomContentRichTextOptionsImpl).border,
        }}
        colorSwatch={{
            option: (widget.options as CustomContentRichTextOptionsImpl).border,
            value: 'color',
            onClear: action(() => (widget.options.border.color = '')),
            secondaryIcon: (widget.options as CustomContentRichTextOptionsImpl).border.color
                ? 'icon-undo'
                : undefined,
            color: (widget.options as CustomContentRichTextOptionsImpl).border.color,
            onChange: action(
                ({ rgb: { r, g, b, a } }) =>
                    ((widget.options as CustomContentRichTextOptionsImpl).border.color = `rgba(${r}, ${g}, ${b}, ${a})`)
            ),
        }}
    />
);

export const handleExtras = action(
    (selection: string, options: PostsWindowWidgetOptionsImpl, extras: any): void => {
        // replace with portrait options if portrait
        const resolvedExtras =
            options.widgetOrientation === 'portrait'
                ? {
                      ...extras,
                      ...extras.portrait,
                  }
                : extras;

        const extrasCase: string = Object.keys(resolvedExtras).find(val => val === selection) || '';
        if (extrasCase !== '') {
            // special case for reviews
            const reviewsEnabled = options.reviews && options.reviews.enabled;
            if (reviewsEnabled && resolvedExtras[extrasCase].reviews) {
                resolvedExtras[extrasCase].reviews.enabled = true;
            }

            Object.keys(resolvedExtras[extrasCase]).forEach(item => {
                options[item] = resolvedExtras[extrasCase][item];
            });
        }
    }
);

export const onChangeComposition = action((widget, selection, composition) => {
    handleExtras(selection, widget.options as PostsWindowWidgetOptionsImpl, composition);
    if (widget.options.smartSize && widget.options.smartSize.enabled) {
        widget.options = getSmartSizeOptions(widget, widget.options.smartSize.size);
    }
});

export const shapeImageTunables = (widget: Widget, mergedTheme: Theme) => ({
    props: {
        label: i18n.t('Image'),
        startOpen: true,
    } as CollapseProps,
    children: [
        <LabelGroup label={i18n.t('Image Content')} key='widget.options.shape.background.color'>
            <Flexbox gap='m'>
                <ActionImageSwatch
                    title={i18n.t('image')}
                    option={(widget.options as ShapeWidgetOptionsImpl).shape.background}
                    value='image'
                />
                <ActionColorSwatchGroup
                    key='widget.options.shape.border.color'
                    title={i18n.t('shape color')}
                    secondaryIcon={
                        !!(widget.options as ShapeWidgetOptionsImpl).shape.background.color
                            ? 'icon-undo'
                            : undefined
                    }
                    value={null}
                    option={null}
                    color={
                        (widget.options as ShapeWidgetOptionsImpl).shape.background.color &&
                        (widget.options as ShapeWidgetOptionsImpl).shape.background.color !== ''
                            ? (widget.options as ShapeWidgetOptionsImpl).shape.background.color
                            : getMergedBackgroundColor(mergedTheme)
                    }
                    onClear={action(
                        () =>
                            ((widget.options as ShapeWidgetOptionsImpl).shape.background.color = '')
                    )}
                    onChange={action(
                        ({ rgb: { r, g, b, a } }: any) =>
                            ((widget.options as ShapeWidgetOptionsImpl).shape.background.color = `rgba(${r}, ${g}, ${b}, ${a})`)
                    )}
                />
            </Flexbox>
        </LabelGroup>,
        <ActionSegmentedGroup
            key='widget.options.shape.background.imageFit'
            label={i18n.t('Image Fit')}
            option={(widget.options as ShapeWidgetOptionsImpl).shape.background}
            value='imageFit'
            options={imageBackgroundSizeOptions}
        />,
        <ActionSegmentedGroup
            key='widget.options.shape.background.verticalAlign'
            label={i18n.t('Vertical Position')}
            option={(widget.options as ShapeWidgetOptionsImpl).shape.background}
            value='verticalAlign'
            options={vAlignOptions}
        />,
        <ActionSegmentedGroup
            key='widget.options.shape.background.horizontalAlign'
            label={i18n.t('Horizontal Position')}
            option={(widget.options as ShapeWidgetOptionsImpl).shape.background}
            value='horizontalAlign'
            options={horizontalAlignOptions}
        />,
    ],
});

export const shapeBorderTunables = (widget: Widget) => {
    const applyColor = action((colorResult: ColorResult) => {
        console.group('applyColor');
        if (!colorResult) {
            return;
        }
        console.log('colorResult', colorResult);
        const {
            rgb: { r, g, b, a = 1 },
        } = colorResult;
        (widget.options as ShapeWidgetOptionsImpl).shape.border.color = `rgba(${r}, ${g}, ${b}, ${a})`;
        console.groupEnd();
    });

    return (
        <ActionCheckboxSliderColorSwatchGroup
            key='widget.options.shape.border tunable'
            label={i18n.t('Border')}
            showValue
            checkbox={{
                option: (widget.options as ShapeWidgetOptionsImpl).shape.border,
                value: 'enabled',
            }}
            slider={{
                option: (widget.options as ShapeWidgetOptionsImpl).shape.border,
                value: 'size',
                min: 1,
                max: 100,
            }}
            colorSwatch={{
                option: (widget.options as ShapeWidgetOptionsImpl).shape.border,
                value: 'color',
                color: (widget.options as ShapeWidgetOptionsImpl).shape.border.color,
                secondaryIcon: (widget.options as ShapeWidgetOptionsImpl).shape.border.color
                    ? 'icon-undo'
                    : undefined,
                onChange: applyColor,
            }}
        />
    );
};

export const centerAxis = (widget: Widget, mergedTheme: Theme) => {
    const optionValue = (widget.options as BarChartWidgetOptionsImpl).centerAxis?.color;
    const widgetValue = widget?.theme?.typography?.color;
    const merged = !!widgetValue ? widgetValue : mergedTheme?.typography?.color;
    const currentColor = !!optionValue ? optionValue : merged;
    return (
        <>
            <ActionCheckboxSliderColorSwatchGroup
                key='widget.options.centerAxis'
                label={i18n.t('Center Axis')}
                showValue
                checkbox={{
                    option: (widget.options as BarChartWidgetOptionsImpl).centerAxis,
                    value: 'enabled',
                }}
                slider={{
                    option: (widget.options as BarChartWidgetOptionsImpl).centerAxis,
                    value: 'size',
                    min: 1,
                    max: 100,
                }}
                colorSwatch={{
                    option: (widget.options as BarChartWidgetOptionsImpl).centerAxis,
                    value: 'color',
                    color: currentColor,
                    secondaryIcon: (widget.options as BarChartWidgetOptionsImpl).centerAxis?.color
                        ? 'icon-undo'
                        : undefined,
                    onClear: action(
                        () => ((widget.options as BarChartWidgetOptionsImpl).centerAxis.color = '')
                    ),
                    onChange: action(
                        ({ rgb: { r, g, b, a } }) =>
                            ((widget.options as BarChartWidgetOptionsImpl).centerAxis.color = `rgba(${r}, ${g}, ${b}, ${a})`)
                    ),
                }}
            />
            <ActionSliderGroup
                key='widget.options.centerAxis.spacing'
                label={i18n.t('Spacing')}
                option={(widget.options as BarChartWidgetOptionsImpl).centerAxis}
                value='spacing'
                showValue
                min={0}
                max={100}
            />
        </>
    );
};
