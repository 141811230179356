import { AxiosInstance } from 'axios';

export default class InstagramImageService {
    private axios: AxiosInstance;

    constructor(axios: AxiosInstance) {
        this.axios = axios;
    }

    public async isValidImageUrl(url: string): Promise<boolean> {
        if (typeof Image === 'undefined') {
            try {
                await this.axios.get(url);
                return true;
            } catch (e) {
                return false;
            }
        }

        return new Promise<boolean>(resolve => {
            const img = new Image();
            img.onload = () => {
                resolve(true);
            };

            img.onerror = () => {
                resolve(false);
            };

            img.src = url;
        });
    }
}
