import {
    AnalyticsRequest,
    Metric,
    GroupBy,
    Sort,
} from '../PanelTemplatesTheme/PanelTemplatesTheme';
import { DefaultWidgetPosition } from '../PanelTemplatesTheme/constants';
import { Widget } from '@sprinklr/stories/widget/Widget';
import { WIDGET_LABEL_SIZE } from 'components/Widget/WidgetLabel/constants';
import ListeningDimensions from '@sprinklr/stories-services/AnalyticsService/ListeningDimensions';
import ListeningMetrics from '@sprinklr/stories-services/AnalyticsService/ListeningMetrics';

export const WidgetDataTableGroupedSummary: Widget = {
    type: 'dataTableGroupedSummary',
    id: 'data-table-grouped-summary',
    name: 'Grouped Summary Table',
    useGlobalTimePeriod: true,
    analyticsRequests: [
        // Note: All global variables like reportingEngine, report, filters, and projections
        // are injected via AnalyticsRequest.getRequests()
        new AnalyticsRequest({
            limit: 7,
            projections: [
                new Metric({
                    LISTENING: { source: ListeningMetrics.distinctUsers },
                    INBOUND_MESSAGE: { dimensionName: 'INBOUND_COUNT' },
                    PLATFORM: { dimensionName: 'TOTAL_ENGAGEMENT' },
                    BENCHMARKING: { dimensionName: 'TOTAL_ENGAGEMENT' },
                    PAID: { dimensionName: 'spent' },
                    ADVOCACY: {
                        dimensionName: 'TOTAL_POINTS_EARNED',
                        heading: 'Total_Points_Earned',
                        report: 'COMMUNITY_USER',
                    },
                    TWITTER: { dimensionName: 'MENTIONS' },
                    RDB_FIREHOSE: {
                        dimensionName: 'MENTIONS_COUNT',
                        heading: 'Mentions',
                    },
                }),
            ],
            groupBys: [
                new GroupBy({
                    LISTENING: { source: ListeningDimensions.day },
                    INBOUND_MESSAGE: { source: ListeningDimensions.day, dimensionName: 'snCTm' },
                    PLATFORM: { source: ListeningDimensions.day, dimensionName: 'date' },
                    BENCHMARKING: { source: ListeningDimensions.day, dimensionName: 'date' },
                    PAID: { source: ListeningDimensions.day, dimensionName: 'measurementTime' },
                    ADVOCACY: { source: ListeningDimensions.day, dimensionName: 'date' },
                    RDB_FIREHOSE: {
                        source: ListeningDimensions.day,
                        dimensionName: 'SN_CREATED_TIME',
                    },
                }),
            ],
            sorts: [new Sort({ order: 'DESC', projections: 0 })],
            timePeriods: [{ key: 'last_7_days' }, { previousPeriod: true }],
        }) as any,
    ],
    position: DefaultWidgetPosition,
    label: {
        titleText: 'Grouped Summary Table',
        size: WIDGET_LABEL_SIZE.primary,
        enabled: false,
    },
    options: {
        player: {
            playing: true,
            duration: 15,
        },
    },
};
