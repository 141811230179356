import { AxiosInstance, CancelTokenSource } from 'axios';
import { EnvironmentConfig } from '../../config';
import { GeoData } from 'models/Geo/GeoData';
import { Projection } from 'models/Geo/Projection';

export interface CityRequest {
    name: string;
    states?: string[];
    countries?: string[];
    regions?: string[];
}

export interface CityResponse {
    [name: string]: {
        state: string;
        country: string;
        lat: number;
        lng: number;
    };
}

// const esriTokenUrl = 'https://www.arcgis.com/sharing/rest/oauth2/token';
// const esriUnitedStates =
//     'https://services.arcgis.com/P3ePLMYs2RVChkJx/arcgis/rest/services/USA_Boundaries_2018/FeatureServer/2/query?f=geojson';
// const countiesWA = {
//     53001: 'Adams',
//     53003: 'Asotin',
//     53005: 'Benton',
//     53007: 'Chelan',
//     53009: 'Clallam',
//     53011: 'Clark',
//     53013: 'Columbia',
//     53015: 'Cowlitz',
//     53017: 'Douglas',
//     53019: 'Ferry',
//     53021: 'Franklin',
//     53023: 'Garfield',
//     53025: 'Grant',
//     53027: 'Grays Harbor',
//     53029: 'Island',
//     53031: 'Jefferson',
//     53033: 'King',
//     53035: 'Kitsap',
//     53037: 'Kittitas',
//     53039: 'Klickitat',
//     53041: 'Lewis',
//     53043: 'Lincoln',
//     53045: 'Mason',
//     53047: 'Okanogan',
//     53049: 'Pacific',
//     53051: 'Pend Oreille',
//     53053: 'Pierce',
//     53055: 'San Juan',
//     53057: 'Skagit',
//     53059: 'Skamania',
//     53061: 'Snohomish',
//     53063: 'Spokane',
//     53065: 'Stevens',
//     53067: 'Thurston',
//     53069: 'Wahkiakum',
//     53071: 'Walla Walla',
//     53073: 'Whatcom',
//     53075: 'Whitman',
//     53077: 'Yakima',
// };

export default class GeoService {
    // private cache = new Cache<Promise<Geography>>();
    // private esriToken: { token: string; expires: number };

    constructor(private readonly axios: AxiosInstance, private readonly env: EnvironmentConfig) {
        //this.getToken();
    }

    // getGeography(country: string, state: string, county: string): Promise<Geography> {
    //     const key = country + ':' + state + ':' + county;
    //     let entry = this.cache.get(key);

    //     if (!entry) {
    //         entry = this.geography();
    //         this.cache.set(key, entry);
    //     }

    //     return entry;
    // }

    async cities(cities: CityRequest[], cancelSource?: CancelTokenSource): Promise<CityResponse[]> {
        if (!cities || !cities.length) {
            return Promise.resolve([]);
        }

        const requestConfig = {
            method: 'POST' as const,
            url: this.env.geoRoot + 'cities?q',
            withCredentials: false,
            data: cities,
            cancelToken: cancelSource ? cancelSource.token : null,
        };

        return this.axios.request(requestConfig).then((response): any => {
            const data = response && response.data;
            return data;
        });
    }

    async resolveCityCoords(
        data: GeoData,
        projection: Projection,
        regions: string[],
        regionState: string,
        filterStatesUS: string[],
        filterCountries: string[],
        cancelSource?: CancelTokenSource
    ): Promise<any[]> {
        const args: CityRequest[] = data.items
            .filter(city => typeof city.x === 'string')
            .map(city => {
                const request: CityRequest = { name: city.x };

                if (regionState && regionState != 'all') {
                    request.states = [regionState];
                } else if (filterStatesUS && filterStatesUS.length) {
                    request.states = filterStatesUS;
                }

                if (filterCountries && filterCountries.length) {
                    request.countries = filterCountries;
                }

                if (regions.length) {
                    request.regions = regions;
                }

                return request;
            });

        return this.cities(args);
    }

    // private async geography(cancelSource?: CancelTokenSource): Promise<Geography> {
    //     let url = esriUnitedStates;

    //     const countyFips = Object.keys(countiesWA).join(',');
    //     const filter = `FIPS IN ( ${countyFips} )`;
    //     url += `&where=${encodeURIComponent(filter)}`;
    //     url += `&token=${this.esriToken.token}`;

    //     const requestConfig = {
    //         method: 'GET',
    //         url: url,
    //         withCredentials: false,
    //         cancelToken: cancelSource ? cancelSource.token : null,
    //     };

    //     return this.axios.request(requestConfig).then((response): any => {
    //         const data = response && response.data;
    //         if (data?.features) {
    //             data.features.forEach(feature => {
    //                 feature.properties.county = countiesWA[feature.properties.FIPS];
    //             });
    //         }

    //         return new Geography('' as any, 'county', data);
    //     });
    // }

    // private async getToken(cancelSource?: CancelTokenSource) {
    //     const args = new FormData();

    //     args.set('client_id', '9YZbUISMBgAuXwDL');
    //     args.set('client_secret', '0d364bc0e8c042dcbb0d5dcf246f823c');
    //     args.set('grant_type', 'client_credentials');

    //     const requestConfig = {
    //         method: 'POST',
    //         url: esriTokenUrl,
    //         withCredentials: false,
    //         data: args,
    //         cancelToken: cancelSource ? cancelSource.token : null,
    //     };

    //     this.axios.request(requestConfig).then((response): any => {
    //         const data = response && response.data;
    //         if (data?.access_token) {
    //             const expiresMilli = data.expires_in * 1000;
    //             this.esriToken = { token: data.access_token, expires: Date.now() + expiresMilli };
    //         }
    //     });
    // }
}
