import { WidgetOptions } from '@sprinklr/stories/widget/WidgetOptions';
import { WidgetOptionsImpl } from 'models/Widget/WidgetOptions';
import {
    LabelTextFormat,
    LegendOrientation,
    Orientation,
    SmartSize,
} from '@sprinklr/stories/widget/types';
import { IAxis, ITicks } from 'components/_charts/Primitives/XAxis';
import { AlignmentOptions, LabelDateFormats } from './types';
import { observable } from 'mobx';
import { DecimalFormat } from 'utils/Number/NumberUtils';
import { FontCase } from 'models/Theme/Theme';
import { CenterAxis } from '../BarChartWidget/types';

export interface StackedBarChartWidgetOptions extends WidgetOptions {
    data?: any;
    limit?: number;
    classes?: string;
    orientation?: Orientation;
    stacked100Percent?: boolean;
    stackedInline?: boolean;
    legendOrientation?: LegendOrientation;
    stackedType?: string;
    minSegmentRatio?: number;
    showLegend?: boolean;
    legendNameSize?: number;
    legendWrap?: boolean;
    xAxis?: IAxis;
    yAxis?: IAxis;
    barLabelAlignment?: AlignmentOptions;
    showZeroSegments?: boolean;
}

class AxisImpl implements IAxis {
    @observable enabled = false;
    @observable label = {
        enabled: true,
        size: 50,
        padding: 0,
    };
    @observable ticks: ITicks = {
        enabled: true,
        label: {
            enabled: false,
            size: 50,
            numberFormat: '1.2k',
            numberCase: 'lowercase',
            timeFormat: 'ddd ha',
            angle: 0,
        },
        line: {
            enabled: false,
        },
        numTicks: 10,
    };
}

export class StackedBarChartWidgetOptionsImpl extends WidgetOptionsImpl
    implements StackedBarChartWidgetOptions {
    @observable labelSize = 13;

    @observable barLabelAlignment: AlignmentOptions = 'left';

    @observable xAxis: IAxis = new AxisImpl();
    @observable yAxis: IAxis = new AxisImpl();

    @observable stacked100Percent = true;
    @observable stackedInline = true;
    @observable stackedType = 'a';
    @observable showZeroSegments = false;

    @observable legendOrientation: LegendOrientation = 'right';
    @observable showLegend = true;
    @observable legendDateFormat: LabelDateFormats = 'MMM Do';

    @observable yAxisValueFormat: DecimalFormat = '1.2k';
    @observable spacing = 6;
    @observable borderRadius = 0;

    @observable minSegmentRatio = 3;
    @observable maxThresholds?: number = 5;

    @observable labelTextFormat: LabelTextFormat = 'clamp';
    @observable labelLineClamp = true;
    @observable labelLineClampValue = 1;
    @observable labelWrapText = false;
    @observable labelDateFormat: LabelDateFormats = 'MMM Do';

    @observable showMetricValue = true;
    @observable showSegmentRatio = true;
    @observable metricValueFormat: DecimalFormat = '1.2k';
    @observable metricValueCase: FontCase = 'lowercase';
    @observable metricNameCase: FontCase = 'lowercase';
    @observable metricValueSize = 15;
    @observable metricValueSizeEnabled = false;
    @observable metricValueOpacity = 80;

    @observable centerAxis: CenterAxis = {
        enabled: true,
        color: '',
        size: 10,
        spacing: 15,
    };

    @observable smartSize: SmartSize = {
        enabled: false,
        mode: 'responsive',
        size: 'xl',
        baseline: {
            labelSize: 13,
            metricValueSize: 15,
            spacing: 6,
        },
        optionDefaults: {
            showLegend: true,
            showMetricName: true,
            showMetricValue: true,
        },
        breakpoints: {
            xs: {
                options: {
                    showLegend: false,
                    showMetricName: false,
                    showMetricValue: false,
                },
            },
            s: {
                options: {
                    showMetricName: false,
                    showMetricChange: false,
                    showTimeRemainder: false,
                },
            },
        },
    };
}
