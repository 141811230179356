// Note: The Panel interface and "panels" variables preceeded the idea of Panels elsewhere in the
// application.  Here, they simply are groups of posts used for widgets like Collage.
import { action, computed, observable } from 'mobx';
import { ArrayChanged } from '../../utils/ArrayDiff/ArrayDiff';
import { Source } from './Source';
import { Format } from './Format';
import { Bucket } from './Bucket';
import {
    FormatBucketsOptions,
    PanelOption,
    PostsFormatRequest,
} from '@sprinklr/stories/post/PostsFormatRequest';
import SignalService from '../../services/SignalService/SignalService';
import { PlayerInterval } from '../../utils/PlayerInterval/PlayerInterval';
import ObjectUtils from '../../utils/ObjectUtils/ObjectUtils';

const PRIMARY = 0;
const INTERSTITIAL = 1;

export interface BucketPanel<T> {
    id: number;
    items: T[];
    css: string;
}

export class FormatBuckets<T> extends Format<T> {
    private options: FormatBucketsOptions;
    private padding: number;
    private buckets: { [bucket: string]: Bucket<T> } = {};
    private panels: BucketPanel<T>[] = [null];
    private unique = 1;
    private orderedBucket: Bucket<T>;
    private orderedOffset: number;

    constructor(
        request: PostsFormatRequest,
        sources: Source<T, any>[],
        options: FormatBucketsOptions,
        signalService: SignalService
    ) {
        super(request, sources, signalService);

        // Formatter can modify options, so keep copy for that possibility
        this.options = ObjectUtils.copy(options);

        this.padding = options.padding && options.padding > 0 ? options.padding : 1;

        // Initialize panels with nulls for each slot
        for (let x = 0; x < this.padding * 2; x++) {
            this.panels.push(null);
        }

        for (const bucket in options.buckets) {
            if (options.buckets.hasOwnProperty(bucket)) {
                this.buckets[bucket] = new Bucket<T>(
                    this.uniqueKey,
                    options.buckets[bucket].filter,
                    options.buckets[bucket].loop,
                    sources[0].diff.ordered
                );
            }
        }

        // If "ordered" option is true, we need to massage the bucket offset
        // There can only be one bucket named "any" in this case.
        if (
            this.options.ordered &&
            this.options.panels.length === 1 &&
            Object.keys(this.buckets).length === 1 &&
            this.buckets.any
        ) {
            this.orderedBucket = this.buckets.any;
            this.orderedBucket.setStepBy(this.options.panels[0].total);
            this.orderedOffset = 0;
        }

        this.checkPanels();

        this.signalService.add(Source.changed, this.onSourceChanged);
    }

    destroy() {
        this.signalService.remove(Source.changed, this.onSourceChanged);
    }

    isActive(offset: number): boolean {
        return offset === this.padding;
    }

    set offset(offset: number) {
        this._offset = offset;

        if (this._offset < 0) {
            this._offset = this.options.panels.length - 1;
        } else if (this._offset >= this.options.panels.length) {
            this._offset = 0;
        }

        // Flush all panels
        this.panels.fill(null);
    }

    @action offsetDecrement() {
        this._offset--;
        if (this._offset < 0) {
            if (this.options.shouldFireEvents) {
                this.signalService.dispatch(PlayerInterval.sequenceBegin);
            }
            this._offset = !this.options.panels.length ? 0 : this.options.panels.length - 1;
        }

        // Insert new null element at top of panels array and trim back to proper length
        this.panels.unshift(null);
        this.panels.length--;

        // If "ordered" is true, then move our bucket offset
        if (this.orderedBucket) {
            this.orderedBucket.stepOffset(-1);
        }
    }

    @action offsetIncrement() {
        this._offset++;

        // Due to duplicated posts, we may want to transition to the next slide before reaching the last panel
        const itemCount = this.buckets.any ? this.buckets.any.getTotal() : 0;

        // Count how many panels it would take to hold all of the items in the "any" bucket...
        const itemsPerPanel = this.options.panels[0].total;
        const transitionOffset = Math.ceil(itemCount / itemsPerPanel);

        // ...and dispatch end of sequence signal if we've exceeded it.
        if (this.options.shouldFireEvents && itemCount && this._offset >= transitionOffset) {
            this.signalService.dispatch(PlayerInterval.sequenceEnd);
        }

        if (this._offset >= transitionOffset) {
            this._offset = 0;
        }

        // Remove first element in panels and pad back to proper length
        this.panels.shift();
        this.panels[this.panels.length] = null;

        // If "ordered" is true, then move our bucket offset
        if (this.orderedBucket) {
            this.orderedBucket.stepOffset(1);
        }
    }

    // TODO: This actually returns Array<Panel<T>>
    @computed get items(): T[] {
        // Get mobx to react to changes in these variables
        if (this.itemsChanged && this._offset >= 0) {
            const allLoaded = this.sources.every(source => source.isLoaded());
            if (allLoaded) {
                this.buildPanels(this.panels);
            }
        }

        if (!this.panels[0]) {
            return [];
        } else {
            // I've found that @computed likes a fresh object each time to work right.  Return shallow copy.
            return Object.assign([], this.panels) as any;
        }
    }

    @action setSource(whichSource: number, source: Source<any, any>): void {
        if (whichSource >= 0 && whichSource < this.sources.length) {
            this.panels.fill(null);

            // Generate the diff from the old source to the new source so that the formatter syncs correctly
            source.diff.merge(this.sources[whichSource].items, source.items);
            this.sources[whichSource] = source;

            for (const bucket in this.buckets) {
                if (this.buckets.hasOwnProperty(bucket)) {
                    this.buckets[bucket].reset();
                }
            }
        } else {
            console.log('ERROR: Source ' + whichSource + " isn't defined for FormatBuckets");
        }
    }

    private buildPanels(panels: BucketPanel<T>[]): void {
        let offset = this._offset;
        const length = this.options.panels.length;
        let saveOffset: number;

        if (this.orderedBucket) {
            saveOffset = this.orderedBucket.getOffset();
        }

        const bucketOffsetRestore = () => {
            if (this.orderedBucket) {
                this.orderedBucket.setOffset(saveOffset);
            }
        };

        const bucketOffsetStep = (direction: number) => {
            if (this.orderedBucket) {
                this.orderedBucket.stepOffset(direction);
            }
        };

        const getPanel = (
            direction: number,
            panelOrig: BucketPanel<T>,
            panelPrev?: BucketPanel<T>
        ): BucketPanel<T> => {
            offset += direction;

            if (offset < 0) {
                offset = length - 1;
            } else if (offset >= length) {
                offset = 0;
            }

            // If we don't have a panel, create one
            if (!panelOrig) {
                const panel = { id: 0, css: this.options.panels[offset].css, items: [] };

                this.buildPanel(direction, this.options.panels[offset], panel.items);

                return panel;
                // Otherwise just return the panel we already have
            } else {
                return panelOrig;
            }
        };

        // Add the panel for our current array
        panels[this.padding] = getPanel(0, panels[this.padding]);
        bucketOffsetRestore();

        // Add panels backwards through the panels array, looping around as we do
        let x = this.padding;
        while (x--) {
            bucketOffsetStep(-1);
            panels[x] = getPanel(-1, panels[x]);
        }

        // Add panel objects forwards through the panels array, looping around as we do
        offset = this._offset;
        bucketOffsetRestore();

        for (x = this.padding + 1; x <= this.padding * 2; x++) {
            bucketOffsetStep(1);
            panels[x] = getPanel(1, panels[x], panels[x - 1]);
        }

        // Generate the ids so that they are sequential from top-to-bottom
        for (x = 0; x <= this.padding * 2; x++) {
            if (panels[x].id === 0) {
                panels[x].id = this.unique++;
            }
        }

        // If "ordered" is true, restore our bucket offset to what it was
        bucketOffsetRestore();
    }

    private buildPanel(direction: number, panel: PanelOption, result: T[]) {
        let item: T;
        const uniqueMap: { [id: number]: boolean } = {};
        let uniqueBucketMap: { [id: number]: boolean };
        let total = panel.total;

        for (let x = 0; x < panel.buckets.length; x += 2) {
            const name = panel.buckets[x];
            let count = panel.buckets[x + 1];

            // If the count is an array, then generate a random value between the two provided numbers
            if (count.constructor === Array) {
                const range: number[] = count as any;
                if (range.length === 2) {
                    count = Math.floor(Math.random() * (range[1] + 1 - range[0]) + range[0]);
                }
            } else if (count === -1) {
                count = total;
            }

            uniqueBucketMap = {};

            const bucket = this.buckets[name];

            if (this.isShuffle()) {
                bucket.shuffle();
            }

            for (let y = 0; y < count; y++) {
                item = direction === -1 ? bucket.getItemPrevious() : bucket.getItemNext();

                // Don't allow duplicate posts to be put in
                if (item && !uniqueMap[item[this.uniqueKey]]) {
                    uniqueMap[item[this.uniqueKey]] = true;
                    result.push(item);
                    total--;
                    // This allows us to cycle through all posts in the bucket
                    // looking for non-duplicates, but at the same time, prevent an endless loop!
                } else if (item && !uniqueBucketMap[item[this.uniqueKey]]) {
                    uniqueBucketMap[item[this.uniqueKey]] = true;
                    y--; // Try again
                } else {
                    break;
                }
            }

            if (direction === -1) {
                result.reverse();
            }

            // If loop is false, then reset the offset to 0 after the
            if (!bucket.isLoop()) {
                bucket.reset();
            }
        }
    }

    @action
    private checkPanels(): void {
        const windowCount = this.padding * 2 + 1;
        const panelCount = this.options.panels.length;

        // If we have fewer panels than our window size, duplicate our panels to match
        if (panelCount < windowCount) {
            let multiplier = Math.floor(windowCount / panelCount);
            if (windowCount % panelCount) {
                multiplier++;
            }

            // Start at 1, because we already have the first one with the existing panel size
            const newPanels: PanelOption[] = [];
            for (let x = 1; x < multiplier; x++) {
                const panels = Object.assign([], this.options.panels); // NOTE: Shallow copy is OK here
                newPanels.push.apply(newPanels, panels);
            }

            this.options.panels.push.apply(this.options.panels, newPanels);
        }
    }

    private getRandom(offset: number, range: number, items: T[], previous: T[]): number {
        let newOffset: number;
        let tries = 10;

        while (tries--) {
            newOffset = Math.floor(Math.random() * range);
            if (
                !previous ||
                (items[newOffset] !== previous[offset] && items[offset] !== previous[newOffset])
            ) {
                return newOffset;
            }
        }

        return newOffset;
    }

    // Fired when a source's data changes
    private onSourceChanged = (type: string, source: Source<any, any>) => {
        const offset = this.sources.indexOf(source);
        if (offset !== -1) {
            const changed: ArrayChanged<T>[] = [null, null];

            for (let x = 0; x < this.sources.length; x++) {
                // Find out what posts were added and/or removed, if any
                changed[x] = this.diffs[x].compare(this.sources[x].diff);
                if (changed[x]) {
                    this.diffs[x].sync(this.itemsInternal[x], changed[x]);
                }
            }

            // Put our posts and interstitials into their correct buckets
            for (const bucket in this.buckets) {
                if (this.buckets.hasOwnProperty(bucket)) {
                    this.buckets[bucket].match(changed[PRIMARY], changed[INTERSTITIAL]);
                    // if (this.buckets[bucket].posts.length) {
                    //     console.log("Bucket: " + bucket + ", count: " + this.buckets[bucket].posts.length);
                    // }
                }
            }

            // Trigger mobx recomputation for items()
            this.itemsChanged++;
        }
    };
}
