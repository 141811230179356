/**
 * Wraps a GeoJSON or TopoJSON geometry.  Adds helper functions
 *
 * Created by dboyd on 4/23/20
 */
import * as topojson from 'topojson';
import uniq from 'lodash/uniq';
import { GeographyInterface, Region, RegionType } from 'models/Geo/types';

import world from 'components/_charts/WorldHeatMap/regions/world';
import unitedStates from 'components/_charts/WorldHeatMap/regions/unitedStates';
import westernEurope from 'components/_charts/WorldHeatMap/regions/westernEurope';
import europeWithRussia from 'components/_charts/WorldHeatMap/regions/europeWithRussia';
import asia from 'components/_charts/WorldHeatMap/regions/asia.json';
import southAmerica from 'components/_charts/WorldHeatMap/regions/southAmerica';
import africa from 'components/_charts/WorldHeatMap/regions/africa.json';
import centralAmerica from 'components/_charts/WorldHeatMap/regions/centralAmerica';
import northAmerica from 'components/_charts/WorldHeatMap/regions/northAmerica';
import mexico from 'components/_charts/WorldHeatMap/regions/mexico';
import india from 'components/_charts/WorldHeatMap/regions/india';
import japan from 'components/_charts/WorldHeatMap/regions/japan';

export class Geography implements GeographyInterface {
    topoJSON: boolean;
    private geoJSON: any; // Result of converting to geoJSON from topoJSON

    constructor(public region: Region, public type: RegionType, public json: any) {
        this.topoJSON = !!json.objects;
    }

    static create(region: Region): GeographyInterface {
        let json: any;

        switch (region) {
            case 'world':
                json = world;
                break;
            case 'unitedStates':
                json = unitedStates;
                break;
            case 'westernEurope':
                json = westernEurope;
                break;
            case 'europeWithRussia':
                json = europeWithRussia;
                break;
            case 'asia':
                json = asia;
                break;
            case 'southAmerica':
                json = southAmerica;
                break;
            case 'africa':
                json = africa;
                break;
            case 'centralAmerica':
                json = centralAmerica;
                break;
            case 'northAmerica':
                json = northAmerica;
                break;
            case 'mexico':
                json = mexico;
                break;
            case 'india':
                json = india;
                break;
            case 'japan':
                json = japan;
                break;
            default:
                json = null;
                break;
        }

        if (json) {
            return new Geography(region, Geography.getType(region), json);
        }

        return null;
    }

    getScaleBy(region: Region, regionState: string, scale: number): number {
        let scaleBy = 8.0;

        switch (region) {
            case 'africa':
                scaleBy = 2.25;
                break;
            case 'asia':
                scaleBy = 3.0;
                break;
            case 'centralAmerica':
                scaleBy = 4.0;
                break;
            case 'europeWithRussia':
                scaleBy = 4.0;
                break;
            case 'india':
                scaleBy = 8.0;
                break;
            case 'japan':
                scaleBy = 10.0;
                break;
            case 'mexico':
                scaleBy = 8.0;
                break;
            case 'northAmerica':
                scaleBy = 1.5;
                break;
            case 'southAmerica':
                scaleBy = 3.0;
                break;
            case 'unitedStates':
                scaleBy = 8.0;
                break;
            case 'westernEurope':
                scaleBy = 5.0;
                break;
            case 'world':
                scaleBy = 2.25;
                break;
        }

        // If we are zoomed-in to a state, reduce the scaleBy
        if (regionState && regionState !== 'all') {
            scaleBy /= scale;
        }

        return scaleBy;
    }

    static getType(region: Region): RegionType {
        let type: RegionType = 'country';

        switch (region) {
            case 'india':
            case 'japan':
            case 'unitedStates':
                type = 'state';
                break;
        }

        return type;
    }

    get features(): any[] {
        let json = this.topoJSON ? this.geoJSON : this.json;

        if (!json) {
            json = this.geoJSON = this.convertGeoJSON();
        }

        return json.features;
    }

    // Reduces the topography to a single GetJSON MultiLineString.  Prevents overdraw on borders
    getMesh(): any {
        return this.topoJSON ? topojson.mesh(this.json, this.getObjects()) : null;
    }

    getCountry(offset: number): string {
        const features = this.getFeatures();
        return features[offset].properties.country;
    }

    getCountries(): string[] {
        return this.getFeatureIds('country');
    }

    getFeatureId(feature: any, type?: RegionType): string {
        if (!type) {
            type = this.type;
        }

        return feature.id ? feature.id : feature.properties[type];
    }

    // Returns the list of ids appropriate to the type
    getFeatureIds(type?: string): string[] {
        const geography = this.json;
        const features = this.getFeatures();

        if (!type) {
            type = this.type;
        }

        const featureIds =
            features?.map(feature =>
                feature.properties[this.type] ? feature.properties[this.type] : feature.id
            ) || [];

        return uniq(featureIds);
    }

    findFeature(value: string): any {
        const found = this.features.filter(feature => feature.properties[this.type] === value);
        if (found.length) {
            return found[0];
        }

        return null;
    }

    findState(value: string): any {
        const found = this.features.filter(feature => feature.properties.state === value);
        if (found.length) {
            return found[0];
        }

        return null;
    }

    private getFeatures(): any {
        const objects = this.getObjects();
        return this.topoJSON ? objects.geometries : objects.features;
    }

    private getObjects(): any {
        if (this.topoJSON) {
            return this.json.objects?.countries || this.json.objects?.states;
        } else {
            return this.json;
        }
    }

    private convertGeoJSON(): any {
        return topojson.feature(this.json, this.getObjects());
    }
}
