import { CollapseProps, TunablesCollapse } from 'components/Sidebar/RenderTunables/RenderTunables';
import { Widget } from '@sprinklr/stories/widget/Widget';
import { Theme } from 'models/Theme/Theme';
import i18n from 'i18next';
import ActionSliderGroup from 'components/_UI/Forms/Actions/ActionSliderGroup';
import * as React from 'react';

import LabelGroup from 'components/_UI/Forms/LabelGroup/LabelGroup';
import ActionSelectGroup from 'components/_UI/Forms/Actions/ActionSelectGroup';
import {
    colorModeOptions,
    endianOptions,
} from 'components/Panel/PanelEditorSidebar/PanelDesign/PanelDesignOptions';
import { Flexbox, LabeledSlider } from 'components/spaceKit';
import { action } from 'mobx';
import { FormatOrderedOptions } from '@sprinklr/stories/post/PostsFormatRequest';
import { SourcePost } from '@sprinklr/stories/post/PostsFormatRequest';
import ActionCheckboxGroup from 'components/_UI/Forms/Actions/ActionCheckboxGroup';
import { measurementNameMap } from 'components/SmartTrend/constants';
import ActionInput from 'components/_UI/Forms/Actions/ActionInput';

const SmartTrendsTunables = (widget: Widget): TunablesCollapse[] => {
    const updateCount = action(event => {
        (widget.postRequests[0].format.options as FormatOrderedOptions).fixedList =
            event.target.value;
    });
    const postCount = (widget?.postRequests?.[0].format?.options as FormatOrderedOptions)
        ?.fixedList;
    const maxItems = (widget?.postRequests?.[0].sources?.[0] as SourcePost)?.id?.pageSize;

    const bullets = widget.options.smartTrends?.bullets;

    const measurementOptions = Object.keys(measurementNameMap).map(measurement => ({
        label: measurementNameMap[measurement],
        value: measurement,
    }));

    return [
        {
            props: {
                label: i18n.t('Trend Cards'),
                startOpen: true,
            } as CollapseProps,
            children: [
                <ActionSliderGroup
                    key='widget.options.fontScale'
                    label={i18n.t('Sizing')}
                    option={widget.options.smartTrends}
                    value={'fontScale'}
                    showValue
                    min={0}
                    max={100}
                />,
                <LabelGroup label={i18n.t('Color Mode')} key='widget.colorMode'>
                    <ActionSelectGroup
                        key='widget.colorMode'
                        options={colorModeOptions}
                        option={widget.options.smartTrends}
                        value='colorMode'
                    />
                </LabelGroup>,
                <Flexbox key='widget.timeStamp' gap>
                    <ActionCheckboxGroup
                        label={i18n.t('Time Stamp')}
                        option={widget.options.smartTrends.timeStamp}
                        value='enabled'
                    />
                    <ActionSelectGroup
                        option={widget.options.smartTrends.timeStamp}
                        value='timeFormat'
                        options={endianOptions}
                    />
                </Flexbox>,
                <ActionCheckboxGroup
                    key='widget.Severity'
                    label={i18n.t('Severity')}
                    option={widget.options.smartTrends.criticality}
                    value='enabled'
                />,
                <ActionCheckboxGroup
                    key='widget.categories'
                    label={i18n.t('Categories')}
                    option={widget.options.smartTrends.categories}
                    value='enabled'
                />,
                <ActionCheckboxGroup
                    key='widget.Metric'
                    label={i18n.t('Metric')}
                    option={widget.options.smartTrends.metric}
                    value='enabled'
                />,
                widget.options.smartTrends.metric?.enabled ? (
                    <>
                        <LabelGroup label={i18n.t('Metric Type')} key='widget.Metric.type'>
                            <ActionSelectGroup
                                options={measurementOptions}
                                option={widget.options.smartTrends.metric}
                                value='measurement'
                            />
                        </LabelGroup>
                        <ActionCheckboxGroup
                            key='widget.options.usePretty'
                            label={i18n.t('Shortened Number')}
                            option={widget.options.smartTrends.metric.numberFormat}
                            value='usePretty'
                        />
                        <LabelGroup key='widget.options.decimals' label={i18n.t('Decimals')}>
                            <ActionInput
                                min={0}
                                max={10}
                                type='number'
                                option={widget.options.smartTrends.metric.numberFormat}
                                value='decimals'
                            />
                        </LabelGroup>
                    </>
                ) : null,
                <ActionCheckboxGroup
                    key='widget.bullets'
                    label={i18n.t('Bullet Points')}
                    option={bullets}
                    value='enabled'
                />,
                bullets?.enabled
                    ? [
                          <ActionCheckboxGroup
                              key='widget.bullets.country'
                              label={i18n.t('Location')}
                              option={bullets?.country}
                              value='enabled'
                          />,
                          <ActionCheckboxGroup
                              key='widget.bullets.person'
                              label={i18n.t('People')}
                              option={bullets?.person}
                              value='enabled'
                          />,
                          <ActionCheckboxGroup
                              key='widget.bullets.org'
                              label={i18n.t('Organization(s)')}
                              option={bullets?.org}
                              value='enabled'
                          />,
                          <ActionCheckboxGroup
                              key='widget.bullets.rank'
                              label={i18n.t('Rank')}
                              option={bullets?.rank}
                              value='enabled'
                          />,
                      ]
                    : null,
                <LabeledSlider
                    key={'post count'}
                    label='Card Count'
                    min={1}
                    max={maxItems}
                    value={postCount}
                    showValue
                    onChange={updateCount}
                />,
            ],
        },
    ];
};

export default SmartTrendsTunables;
