import { AnalyticsRequest, GroupBy, Sort } from './PanelTemplatesTheme/PanelTemplatesTheme';
import { Panel } from '../../models/Panel/Panel';
import { WIDGET_LABEL_SIZE } from '../../components/Widget/WidgetLabel/constants';
import ListeningDimensions from '@sprinklr/stories-services/AnalyticsService/ListeningDimensions';

export var TemplateSmallMultiplesPieChart: Panel = {
    id: 'small-multiples-pie-chart',
    name: 'Small Multiples Pie Chart',
    description: '',
    tags: ['Standard', 'Small Multiples', 'Pie Chart', 'landscape', 'portrait'],
    widget: {
        id: '1',
        position: {
            width: '1920px',
            height: '1080px',
            top: '0',
            left: '0',
        },
        classes: 'template_small_multiples_pie_chart',
        children: [
            {
                type: 'smallMultiplesPieChart',
                id: '1',
                name: 'Small Multiples',
                analyticsRequests: [
                    // Note: All global variables like reportingEngine, report, filters, and projections
                    // are injected via AnalyticsRequest.getRequests()
                    new AnalyticsRequest({
                        limit: 9,
                        groupBys: [
                            new GroupBy({
                                LISTENING: { source: ListeningDimensions.topicName },
                                INBOUND_MESSAGE: { dimensionName: 'ACCOUNT_ID' },
                                PLATFORM: { dimensionName: 'ACCOUNT_ID' },
                                BENCHMARKING: { dimensionName: 'BRAND_ID' }, //BENCHMARKING_ACCOUNT_ID
                                PAID: { dimensionName: 'accountIds' },
                                ADVOCACY: { dimensionName: 'COMMUNITY_USER_ID' },
                                TWITTER: { dimensionName: 'TRENDING_TOPIC' },
                                RDB_FIREHOSE: { dimensionName: 'RDB_QUERY_IDS' },
                                STORY_MESSAGE: { dimensionName: 'STORY_CATEGORY' },
                            }),
                        ],
                        sorts: [
                            new Sort({ order: 'DESC', projections: 0 }),
                            new Sort({ order: 'ASC', groupBys: 0 }),
                        ],
                        timePeriods: [{ key: 'last_7_days' }, { previousPeriod: true }],
                        includeTotal: false,
                    }) as any,
                ],
                useGlobalTimePeriod: true,
                position: {
                    width: '100%',
                    height: '100%',
                    top: '0',
                    left: '0',
                },
                label: {
                    titleText: 'Small Multiples Pie Chart',
                    enabled: true,
                    size: WIDGET_LABEL_SIZE.primary,
                },
                options: {
                    player: {
                        playing: true,
                        duration: 15,
                    },
                    smartSize: {
                        enabled: true,
                        mode: 'responsive',
                        size: 'xl',
                    },
                    portrait: {
                        widgetOrientation: 'portrait',
                    },
                },
            },
        ],
    },
};
