import BulkItem from './BulkItem';

export default class CommunityUser extends BulkItem {
    private fullName: string;
    private email: string;
    private profileImageUrl: string;

    constructor(id: string, fullName: string, email?: string, profileImageUrl?: string) {
        super(id);

        this.fullName = fullName;
        this.email = email;
        this.profileImageUrl = profileImageUrl;
    }

    toString() {
        return this.fullName || this.id; // In some bulklookup results, there is no fullName
    }

    toImageUrl(): string {
        return this.profileImageUrl;
    }

    toEmail(): string {
        return this.email;
    }

    // Construct a CommunityUser from an object, checking for properties first.
    public static fromObject(object: any) {
        const allProps = ['id', 'experienceType', 'fullName'].every(prop =>
            object.hasOwnProperty(prop)
        );

        if (allProps) {
            return new CommunityUser(
                object.id,
                object.fullName,
                object.email,
                object.profileImageUrl
            );
        }
    }

    get sortValue() {
        return this.fullName;
    }
}
