import { Panel } from 'models/Panel/Panel';
import { WidgetGeoStream } from '../PanelTemplatesStore/WidgetGeoStream';
import merge from 'deepmerge';
import { SmartTrendsWidgetOptionsImpl } from 'src/widgets/SmartTrendsWidget/options';

const getMergedWidgetGeoStream = () => {
    const ticker = WidgetGeoStream.children[0];
    ticker.options = merge(ticker.options, {
        classOverrides: {
            postOuter: {
                default: 'm-05',
            },
        },
        smartTrends: { ...new SmartTrendsWidgetOptionsImpl(), fontScale: 17 },
        nativeStyling: {
            lineClamp: {
                lines: 2,
                hasMedia: {
                    lines: 2,
                },
            },
            fontScale: 15,
        },
    });

    return WidgetGeoStream;
};
export const TemplateGeoStream: Panel = {
    id: 'geo-stream',
    name: 'Geo Stream',
    description: '',
    tags: ['Content', 'Geo Stream', 'Geo', 'landscape', 'portrait'],
    widget: {
        id: '1',
        position: {
            width: '1920px',
            height: '1080px',
            top: '0',
            left: '0',
        },
        classes: 'template_geo_stream',
        children: [
            {
                ...getMergedWidgetGeoStream(),
                position: {
                    width: '100%',
                    height: '100%',
                    top: '0',
                    left: '0',
                },
            },
        ],
    },
};
