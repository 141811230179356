/**
 * Created by dstelter on 12/15/16.
 */
import { Mapper } from '../DataStore/DataStore';
import { GraphQLService } from '../GraphQLService/GraphQLService';
import {
    ClientMutationId,
    GraphQLQueries,
    ModelName,
    MutationDescriptor,
    QueryDescriptor,
} from '../GraphQLQueries/GraphQLQueries';
import { BaseRecordService } from 'utils/BaseRecordService/BaseRecordService';
import {
    ContentOverridesSnippet,
    DashboardImportConfigFields,
    Storyboard,
    StoryboardActiveVersionFirstSceneFields,
    StoryboardActiveVersionLayout,
    StoryboardFields,
    StoryboardLightPublishedFields,
    StoryboardVersion,
    WatermarkSnippet,
} from 'models/Storyboard/Storyboard';
import { IdentifiedModel } from '@sprinklr/stories/common/IdentifiedModel';
import { WidgetFragments } from 'models/Widget/Widget';
import { ChildThemeFragment, ThemeSnippet } from 'models/Theme/Theme';
import { AccessControlledFields, ShareableComponent } from '../Auth/AuthService';
import { Watermark } from 'models/Watermark/Watermark';
import config from '../../config';
import { DataSourceFragment, DataSources, DataSourcesSnippet } from 'models/DataSource/DataSource';
import { CommonDocFields } from 'models/CommonDoc/CommonDoc';
import { VersionCapableFields } from 'models/VersionCapable/VersionCapable';
import { SingleTimePeriodFields } from 'models/TimePeriod/SingleTimePeriod';
import { StoryboardVersionLightFieldsOnlyFirstScene } from 'models/Storyboard/StoryboardVersion';
import { SceneFields, SceneTransitionSnippet } from 'models/Scene/Scene';
import { LayoutFields } from 'models/Layout/Layout';
import { SceneVersionFields } from 'models/Scene/SceneVersion';
import { ScheduledStoryboardFields } from 'models/ScheduledStoryboard/ScheduledStoryboard';
import { HeaderSnippet } from 'components/Storyboard/StoryboardHeaderFooter/Header/options';
import { PageNumberSnippet } from 'components/Storyboard/StoryboardHeaderFooter/PageNumber/options';

const MODELNAME: ModelName = 'storyboard';

export interface StoryboardSharingInfo extends ShareableComponent {
    id: string;
}

export class StoryboardService extends BaseRecordService<Storyboard> {
    private queries: GraphQLQueries;

    constructor(graphQL: GraphQLService, mapper: Mapper<Storyboard>) {
        super(graphQL, mapper);
        this.queries = new GraphQLQueries();
    }

    static setBoardType(storyboard: Storyboard): void {
        if (config.applicationMode === 'PRESENTATIONS') {
            storyboard.boardType = 'presentations';
        } else if (config.applicationMode === 'DISPLAY') {
            storyboard.boardType = 'display';
        }
    }

    static setAuthorizationType(storyboard: Storyboard): void {
        if (config.applicationMode === 'PRESENTATIONS') {
            //Default auth for presentations
            storyboard.loginRequired = true;
        }
    }

    findConnectedLocationSchedules(id: string): Promise<Storyboard> {
        const queryDescriptor: QueryDescriptor = {
            queryParams: {
                query: `
                    query RetrieveStoryboard($id: ID!) {
                        storyboard(id: $id) {
                            ${CommonDocFields}
                            locations {
                                ${CommonDocFields}
                                activeStoryboardId
                                scheduledStoryboards {
                                    ${ScheduledStoryboardFields}
                                }
                                lastUserActiveStoryboardChange
                            }
                        }
                    }
                `,
                variables: { id },
            },
            extractor: result => result[MODELNAME],
        };
        return this.query(queryDescriptor);
    }

    /**
     * Special version of find that brings in a reduced set of fields for Scenes and SceneVersions, except for the
     * designated "featured scene" which gets a fuller query for both its Scene and SceneVersion records (in active ver).
     * @param id
     * @param sceneId
     */
    findWithFeaturedScene(id: string, sceneId: string): Promise<Storyboard> {
        const queryDescriptor: QueryDescriptor = {
            queryParams: {
                query: `
                    query RetrieveStoryboard($id: ID!, $sceneId: ID!) {
                        storyboard(id: $id) {
                            ${CommonDocFields}
                            ${VersionCapableFields}
                            sceneDuration
                            createdByUserId
                            sharedWithUserIds
                            sharedWithGroupIds
                            restricted
                            publishedVersionId
                            views
                            ${AccessControlledFields}
                            boardType
                            dashboardId
                            importComplete
                            timePeriod {
                                ${SingleTimePeriodFields}
                            }
                            activeVersion {
                                id versionId masterId treeDirty
                                sceneDuration
                                scenes {
                                    name id masterId versionId treeDirty
                                    duration disabled
                                    panels {
                                        id masterId versionId
                                        previewImageUrl
                                    }
                                    ${SceneTransitionSnippet}
                                }
                                timePeriod {
                                    ${SingleTimePeriodFields}
                                }
                                featuredScene(sceneId: $sceneId) {
                                    ${SceneVersionFields}
                                }
                                ${DataSourcesSnippet}
                                ${ContentOverridesSnippet}
                                ${PageNumberSnippet}
                                ${HeaderSnippet}
                                ${WatermarkSnippet}
                                ${ThemeSnippet}
                            }
                            previousVersion {
                                ${StoryboardVersionLightFieldsOnlyFirstScene}
                            }
                            scenes {
                                id versionId name
                                panels {
                                    id versionId
                                }
                            }
                            featuredScene(sceneId: $sceneId) {
                                ${SceneFields}
                            }
                            layout {
                                ${LayoutFields}
                            }
                            locations {
                                ${CommonDocFields}
                                activeStoryboardId
                                address {
                                    address1
                                    address2
                                    floor
                                    room
                                    city
                                    state
                                    zipcode
                                    country
                                }
                            }
                            ${DataSourcesSnippet}
                            ${ContentOverridesSnippet}
                            ${WatermarkSnippet}
                            ${PageNumberSnippet}
                            ${HeaderSnippet}
                        }
                    }
                    ${ChildThemeFragment}
                    ${DataSourceFragment}
                    ${WidgetFragments}
                `,
                variables: { id, sceneId },
            },
            extractor: result => result[MODELNAME],
        };

        return this.query(queryDescriptor);
    }

    /**
     * Special version of find() that also includes the dashboard export config.
     * Used when a dashboard is exported from space to presentations.
     *
     * @param id
     */
    findWithDashboardImportConfig(id: string): Promise<Storyboard> {
        const queryDescriptor: QueryDescriptor = {
            queryParams: {
                query: `
                    query RetrieveStoryboard($id: ID!) {
                        storyboard(id: $id) {
                           ${StoryboardFields}
                           ${DashboardImportConfigFields}
                           ${WatermarkSnippet}
                           ${PageNumberSnippet}
                           ${HeaderSnippet}
                        }
                    }
                    ${WidgetFragments}
                    ${ChildThemeFragment}
                    ${DataSourceFragment}
                `,
                variables: { id },
            },
            extractor: result => result[MODELNAME],
        };

        return this.query(queryDescriptor);
    }

    find(id: string): Promise<Storyboard> {
        const queryDescriptor: QueryDescriptor = {
            queryParams: {
                query: `
                    query RetrieveStoryboard($id: ID!) {
                        storyboard(id: $id) {
                           ${StoryboardFields}
                           ${WatermarkSnippet}
                           ${PageNumberSnippet}
                           ${HeaderSnippet}
                        }
                    }
                    ${WidgetFragments}
                    ${ChildThemeFragment}
                    ${DataSourceFragment}
                `,
                variables: { id },
            },
            extractor: result => result[MODELNAME],
        };

        return this.query(queryDescriptor);
    }

    getSharingInfo(storyboardId: string): Promise<StoryboardSharingInfo> {
        const queryDescriptor: QueryDescriptor = {
            queryParams: {
                query: `
                    query RetrieveStoryboard($id: ID!) {
                        storyboard(id: $id) {
                           id
                           createdByUserId
                           sharedWithUserIds
                           sharedWithGroupIds
                           restricted
                        }
                    }
                `,
                variables: { id: storyboardId },
            },
            extractor: result => result.storyboard,
        };

        return this.query(queryDescriptor) as any;
    }

    create(storyboard: Storyboard): Promise<Storyboard> {
        const layoutId = !!storyboard.layout ? storyboard.layout.id : null;
        StoryboardService.setBoardType(storyboard);
        StoryboardService.setAuthorizationType(storyboard);
        return this.mutate(
            this.queries.mutationDescriptor('createStoryboard', MODELNAME, {
                storyboard,
                layoutId,
                populateFirstScene: true,
            })
        );
    }

    promoteDraft(storyboard: StoryboardVersion, syncPublish = false): Promise<Storyboard> {
        return this.mutate(
            this.queries.mutationDescriptor('promoteStoryboardVersion', MODELNAME, {
                storyboardId: storyboard.masterId,
                versionId: storyboard.id,
                syncPublish,
            })
        );
    }

    saveDraft(storyboard: StoryboardVersion, name?: string): Promise<Storyboard> {
        return this.mutate(
            this.queries.mutationDescriptor('saveStoryboardVersion', MODELNAME, {
                storyboardId: storyboard.masterId,
                versionId: storyboard.id,
                versionName: name,
            })
        );
    }

    abandonDraft(storyboard: StoryboardVersion): Promise<Storyboard> {
        return this.mutate(
            this.queries.mutationDescriptor('abandonStoryboardVersion', MODELNAME, {
                storyboardId: storyboard.masterId,
                versionId: storyboard.id,
            })
        );
    }

    destroy(storyboard: Storyboard | string): Promise<boolean> {
        return this.mutate(
            this.queries.mutationDescriptor('deleteStoryboard', MODELNAME, {
                id: this.id(storyboard),
            })
        ).then(() => {
            return true;
        });
    }

    restore(storyboardId: string): Promise<Storyboard> {
        return this.mutate(
            {
                name: 'restoreStoryboard',
                queryParams: {
                    query: `
                    mutation RestoreStoryboard($input: RestoreStoryboardInput!) {
                        restoreStoryboard(input: $input) {
                            storyboard {
                                ${StoryboardFields}
                                ${WatermarkSnippet}
                                ${PageNumberSnippet}
                                ${HeaderSnippet}
                                ${DataSourcesSnippet}
                                ${ContentOverridesSnippet}
                            }
                            clientMutationId
                        }
                    }
                    ${WidgetFragments}
                    ${ChildThemeFragment}
                    ${DataSourceFragment}
                    `,
                    variables: { input: { id: storyboardId } },
                },
                extractor: r => r.restoreStoryboard.storyboard,
            },
            { rejectOnError: true }
        );
    }

    importDashboard(dashboardId, dashboardName): Promise<Storyboard> {
        return this.mutate(
            this.queries.mutationDescriptor('importDashboard', MODELNAME, {
                dashboardId: dashboardId,
                dashboardName: dashboardName,
            })
        );
    }

    setImportComplete(storyboardId: string): Promise<Storyboard> {
        return this.mutate(
            this.queries.mutationDescriptor('setStoryboardImportComplete', MODELNAME, {
                storyboardId: storyboardId,
            })
        );
    }

    findAllActiveVersionFirstSceneOnly(query?: any): Promise<Storyboard[]> {
        const queryDescriptor: QueryDescriptor = {
            queryParams: {
                query: `
                {
                    client {
                        storyboards(accessibleToUser: true) {
                            ${StoryboardActiveVersionFirstSceneFields}
                        }
                    }
                }
                `,
                variables: query || {},
            },
            extractor: result => result.client.storyboards,
        };

        return this.queryMany(queryDescriptor);
    }

    findPaginatedActiveVersionFirstSceneOnly(args: any, query?: any): Promise<Storyboard[]> {
        let argString = `accessibleToUser: true`;
        ['name', 'tags', 'page', 'size', 'orderBy', 'dir'].forEach(k => {
            if (args[k] !== null && args[k] !== undefined) {
                argString += `, ${k}: ${typeof args[k] === 'number' ? args[k] : `"${args[k]}"`}`;
            }
        });
        if (args.boardType) {
            argString += `, boardType: ${args.boardType}`;
        }
        const queryDescriptor: QueryDescriptor = {
            queryParams: {
                query: `
                {
                    client {
                        storyboards(${argString}) {
                            ${StoryboardActiveVersionFirstSceneFields}
                        }
                    }
                }
                `,
                variables: query || {},
            },
            extractor: result => result.client.storyboards,
        };

        return this.queryMany(queryDescriptor);
    }

    findAllActiveVersionWithThemesMerge(): Promise<Storyboard[]> {
        const queryDescriptor: QueryDescriptor = {
            queryParams: {
                query: `
                {
                    client {
                        storyboards {
                            ${StoryboardActiveVersionLayout}
                            ${ThemeSnippet}
                        }
                    }
                }
                ${ChildThemeFragment}
                ${DataSourceFragment}
                `,
                variables: {},
            },
            extractor: result => result.client.storyboards,
        };

        return this.queryMany(queryDescriptor);
    }

    findAllLightPublished(query?: any): Promise<Storyboard[]> {
        const queryDescriptor: QueryDescriptor = {
            queryParams: {
                query: `
                    {
                        client {
                            storyboards {
                                ${StoryboardLightPublishedFields}
                            }
                        }
                    }
                `,
                variables: query || {},
            },
            extractor: result => result.client.storyboards,
        };

        return this.queryMany(queryDescriptor);
    }

    findAll(query?: any): Promise<Storyboard[]> {
        const queryDescriptor: QueryDescriptor = {
            queryParams: {
                query: `
                    {
                        client {
                            storyboards {
                                ${StoryboardFields}
                            }
                        }
                    }
                    ${WidgetFragments}
                    ${DataSourceFragment}
                    ${ChildThemeFragment}
                `,
                variables: query || {},
            },
            extractor: result => result.client.storyboards,
        };

        return this.queryMany(queryDescriptor);
    }

    rename(storyboard: StoryboardVersion | Storyboard | string, name: string): Promise<Storyboard> {
        let storyboardId: string;

        if ((storyboard as StoryboardVersion).masterId) {
            storyboardId = (storyboard as StoryboardVersion).masterId;
        } else if (storyboard instanceof Object) {
            storyboardId = (storyboard as Storyboard).id;
        } else if (typeof storyboard === 'string') {
            storyboardId = storyboard as string;
        }

        if (!storyboardId) {
            throw new Error('Cannot rename storyboard without an id.');
        }

        return this.mutate(
            this.queries.mutationDescriptor('renameStoryboard', MODELNAME, { storyboardId, name })
        );
    }

    clone(
        storyboard: Storyboard | string,
        name: string,
        newClientId?: string,
        destination?: 'display' | 'presentations'
    ): Promise<Storyboard> {
        return this.mutate(
            this.queries.mutationDescriptor('cloneStoryboard', MODELNAME, {
                storyboardId: this.id(storyboard),
                name,
                newClientId,
                destination,
            })
        );
    }

    id(ident: IdentifiedModel | string) {
        if (typeof ident === 'string') {
            return ident;
        }

        return (ident as IdentifiedModel).id;
    }

    copyVersionToNewVersion(storyboardVersion: StoryboardVersion | string): Promise<Storyboard> {
        const versionId = this.id(storyboardVersion);
        return this.mutate(
            this.queries.mutationDescriptor('copyStoryboardVersionToNewVersion', MODELNAME, {
                versionId,
            })
        );
    }

    setSharedWithUserIds(
        storyboardId: string,
        userIds: number[],
        groupIds: string[],
        restricted: boolean
    ): Promise<Storyboard> {
        return this.mutate(
            this.queries.mutationDescriptor('setStoryboardSharedWithUserIds', MODELNAME, {
                storyboardId,
                sharedWithUserIds: userIds,
                sharedWithGroupIds: groupIds,
                restricted,
            })
        );
    }

    /**
     * Archives the specified StoryboardVersion. Does not allow archiving the current version.
     *
     * @param {Storyboard | string} storyboard
     * @param {StoryboardVersion | string} storyboardVersion
     * @returns {Promise<Storyboard>}
     */
    archiveVersion(
        storyboard: Storyboard | string,
        storyboardVersion: StoryboardVersion | string
    ): Promise<Storyboard> {
        return this.mutate({
            queryParams: {
                query: `mutation ArchiveStoryboardVersion($input: ArchiveStoryboardVersionInput!) {
                    archiveStoryboardVersion(input: $input) {
                        storyboard {
                            ${StoryboardFields}
                        }
                        ${ClientMutationId}
                    }
                }
                ${WidgetFragments}
                ${DataSourceFragment}
                ${ChildThemeFragment}
                `,
                variables: {
                    input: {
                        storyboardId: this.storyboardId(storyboard),
                        versionId: this.storyboardId(storyboardVersion),
                    },
                },
            },
            extractor: result => result.archiveStoryboardVersion.storyboard,
        });
    }

    /**
     * Archives the specified StoryboardVersion and all versions before it. Does not allow archiving the current version.
     *
     * @param {Storyboard | string} storyboard
     * @param {StoryboardVersion | string} storyboardVersion
     * @returns {Promise<Storyboard>}
     */
    archiveVersionsAsOf(
        storyboard: Storyboard | string,
        storyboardVersion: StoryboardVersion | string
    ): Promise<Storyboard> {
        return this.mutate({
            queryParams: {
                query: `mutation ArchiveStoryboardVersionsAsOf($input: ArchiveStoryboardVersionsAsOfInput!) {
                    archiveStoryboardVersionsAsOf(input: $input) {
                        storyboard {
                            ${StoryboardFields}
                        }
                        ${ClientMutationId}
                    }
                }
                ${WidgetFragments}
                ${DataSourceFragment}
                ${ChildThemeFragment}
                `,
                variables: {
                    input: {
                        storyboardId: this.storyboardId(storyboard),
                        versionId: this.storyboardId(storyboardVersion),
                    },
                },
            },
            extractor: result => result.archiveStoryboardVersionsAsOf.storyboard,
        });
    }

    /**
     * Unarchives the specified StoryboardVersion.
     *
     * @param {Storyboard | string} storyboard
     * @param {StoryboardVersion | string} storyboardVersion
     * @returns {Promise<Storyboard>}
     */
    unarchiveVersion(
        storyboard: Storyboard | string,
        storyboardVersion: StoryboardVersion | string
    ): Promise<Storyboard> {
        return this.mutate({
            queryParams: {
                query: `mutation UnarchiveStoryboardVersion($input: UnarchiveStoryboardVersionInput!) {
                    unarchiveStoryboardVersion(input: $input) {
                        storyboard {
                            ${StoryboardFields}
                        }
                        ${ClientMutationId}
                    }
                }
                ${WidgetFragments}
                ${DataSourceFragment}
                ${ChildThemeFragment}
                `,
                variables: {
                    input: {
                        storyboardId: this.storyboardId(storyboard),
                        versionId: this.storyboardId(storyboardVersion),
                    },
                },
            },
            extractor: result => result.unarchiveStoryboardVersion.storyboard,
        });
    }

    findAllTags(): Promise<string[]> {
        const argString = `accessibleToUser: true`;
        const queryDescriptor: QueryDescriptor = {
            queryParams: {
                query: `
                {
                    client {
                        storyboards(${argString}) {
                            id
                            tags
                        }
                    }
                }
                `,
                variables: {},
            },
            extractor: result => result.client.storyboards,
        };

        return this.queryMany(queryDescriptor).then((storyboards: Storyboard[]) => {
            const tags = [];
            storyboards.forEach(storyboard => tags.push.apply(tags, storyboard.tags || []));
            // Return unique tags, sorted
            return tags.filter((tag, offset, self) => self.indexOf(tag) === offset).sort();
        });
    }

    addTags(storyboardId: string, tags: string[]): Promise<Storyboard> {
        return this.mutate({
            queryParams: {
                query: `
                    mutation AddStoryboardTags($input: AddStoryboardTagsInput!) {
                        addStoryboardTags(input: $input) {
                            storyboard {
                               ${StoryboardFields}
                            }
                        }
                    }
                    ${WidgetFragments}
                    ${DataSourceFragment}
                    ${ChildThemeFragment}
                `,
                variables: {
                    input: { storyboardId, tags },
                },
            },
            extractor: result => result.addStoryboardTags.storyboard,
        });
    }

    removeTags(storyboardId: string, tags: string[]): Promise<Storyboard> {
        return this.mutate({
            queryParams: {
                query: `
                    mutation RemoveStoryboardTags($input: RemoveStoryboardTagsInput!) {
                        removeStoryboardTags(input: $input) {
                            storyboard {
                               ${StoryboardFields}
                            }
                        }
                    }
                    ${WidgetFragments}
                    ${DataSourceFragment}
                    ${ChildThemeFragment}
                `,
                variables: {
                    input: { storyboardId, tags },
                },
            },
            extractor: result => result.removeStoryboardTags.storyboard,
        });
    }

    updateWatermark(storyboardId: string, watermark: Watermark): Promise<Storyboard> {
        const mutation: MutationDescriptor = {
            queryParams: {
                query: `
                    mutation ($input: UpdateStoryboardWatermarkInput!) {
                        updateStoryboardWatermark(input: $input) {
                            storyboard {
                                ${StoryboardFields}
                                ${WatermarkSnippet}
                            }
                            ${ClientMutationId}
                        }
                    }
                ${WidgetFragments}
                ${DataSourceFragment}
                ${ChildThemeFragment}
                `,
                variables: {
                    input: {
                        storyboardId,
                        watermark,
                    },
                },
            },
            extractor: result => result.updateStoryboardWatermark.storyboard,
        };

        return this.mutate(mutation);
    }

    updateAccessControl(
        storyboard: Storyboard | string,
        loginRequired: boolean,
        passwordRequired: boolean,
        ipRestricted: boolean,
        ipWhitelist: string[]
    ) {
        return this.mutate(
            this.queries.mutationDescriptor('setStoryboardAccessControl', MODELNAME, {
                storyboardId: this.id(storyboard),
                loginRequired: loginRequired,
                passwordRequired: passwordRequired,
                ipRestricted: ipRestricted,
                ipWhitelist: ipWhitelist,
            })
        );
    }

    setStoryboardPasssword(storyboard: Storyboard, password: string): Promise<Storyboard> {
        return this.mutate(
            this.queries.mutationDescriptor('setStoryboardPassword', MODELNAME, {
                storyboardId: this.id(storyboard),
                password: password,
            })
        );
    }

    setDataSources(storyboard: Storyboard | string, dataSources: DataSources): Promise<Storyboard> {
        return this.mutate({
            queryParams: {
                query: `
                    mutation ($input: SetStoryboardDataSourcesInput!) {
                        setStoryboardDataSources(input: $input) {
                            storyboard {
                                ${StoryboardFields}
                                ${WatermarkSnippet}
                                ${DataSourcesSnippet}
                            }
                            ${ClientMutationId}
                        }
                    }
                ${WidgetFragments}
                ${ChildThemeFragment}
                ${DataSourceFragment}
                `,
                variables: {
                    input: {
                        storyboardId: this.id(storyboard),
                        dataSources,
                    },
                },
            },
            extractor: result => result.setStoryboardDataSources.storyboard,
        });
    }

    importStoryboards(storyboard: Storyboard) {
        return this.mutate(
            this.queries.createMutationDescriptor(
                'importStoryboards',
                {
                    storyboards: [storyboard],
                },
                MODELNAME,
                result => result.importStoryboard.storyboards[0]
            )
        );
    }

    importStoryboardFromTemplate(storyboard: Storyboard) {
        return this.mutate(
            this.queries.createMutationDescriptor(
                'importStoryboardFromTemplate',
                {
                    storyboards: [storyboard],
                },
                MODELNAME,
                result => result.importStoryboardFromTemplate.storyboards[0]
            )
        );
    }

    public fetchStoryboardForImport(id: string): Promise<Storyboard> {
        const query = `query RetrieveStoryboard($id: ID!)
            ${this.getStoryboardImportQuery()}
        `;

        const queryDescriptor: QueryDescriptor = {
            queryParams: {
                query: query,
                variables: { id },
            },
            extractor: result => result[MODELNAME],
        };

        return this.query(queryDescriptor);
    }

    // TODO: Build this query properly
    public getStoryboardImportQuery(): string {
        return `{
          storyboard(id: $id) {
            id
            clientId
            partnerId
            name
            sceneDuration
            sharedWithUserIds
            sharedWithGroupIds
            restricted
            loginRequired
            externalSsoLoginRequired
            passwordRequired
            passwordLocked
            ipRestricted
            ipWhitelist
            boardType
            ${HeaderSnippet}
            theme {
              ...themeFrag
            }
            timePeriod {
              duration
              startTime
              endTime
              timeZone
              key
              wholePeriods
              xValue
            }
            activeVersion {
              ${HeaderSnippet}
              theme {
                ...themeFrag
              }
              scenes {
                id
                name
                panels {
                  id
                  name
                  previewImageUrl
                  widget {
                    ...widgetRootNodeType
                  }
                  theme {
                    ...themeFrag
                  }
                }
                duration
                disabled
                theme {
                  ...themeFrag
                }
                sceneTransition {
                  type
                  easing
                  duration
                  enabled
                }
                ${HeaderSnippet}
              }
              dataSources {
                    benchmarking {
                        ...dataSource
                    }
                    inboundAnalytics {
                        ...dataSource
                    }
                    listeningTags {
                        ...dataSource
                    }
                    listeningTopics {
                        ...dataSource
                    }
                    socialAnalytics {
                        ...dataSource
                    }
                    paid {
                        ...dataSource
                    }
                    storyQuery {
                        ...dataSource
                    }
                    storyTags {
                        ...dataSource
                    }
                    storyQueryTags {
                        ...dataSource
                    }
                    listeningExplorer {
                        ...dataSource
                    }
                }
            }
            layout {
              id
              name
              screens {
                x
                y
                h
                w
              }
              layoutType
              layoutCustomId
            }
            dataSources {
              benchmarking {
                ...dataSource
              }
              inboundAnalytics {
                ...dataSource
              }
              listeningTags {
                ...dataSource
              }
              listeningTopics {
                ...dataSource
              }
              socialAnalytics {
                ...dataSource
              }
              paid {
                ...dataSource
              }
              listeningExplorer {
                ...dataSource
              }
            }
            imagesEnabled
            videosEnabled
            watermark {
              panelIndex
              watermarkRendered
              imageUrl
            }
            pageNumber {
              enabled
              alignment
              color
              size
              showTotal
            }
          }
        }

        fragment themeFrag on Theme {
          typography {
            color
            size
            sizeLiteral
            primary {
              family
              url
              color
              size
              sizeLiteral
              samId
              name
              description
              subFamily
              weight
              style
              textTransform
            }
            secondary {
              family
              url
              color
              size
              sizeLiteral
              samId
              name
              description
              subFamily
              weight
              style
              textTransform
            }
          }
          background {
            url
            size
            repeat
            color
            position
          }
          colorPalette {
            type
            solid
            monochrome {
              startColor
              endOffset
            }
            colorBlend {
              startColor
              endColor
            }
            ranked {
              colors
              contrast
            }
            overrides
          }
          sourceId
        }

        fragment dataSource on DataSource {
          report
          reportingEngine
          sourceGroup
          filters {
            dimensionLabel
            filter {
              dimensionName
              filterType
              values
              allValues
            }
            filterValueLabels {
              id
              name
              displayName
            }
          }
          ancillaryFilters {
            dimensionLabel
            filter {
              dimensionName
              filterType
              values
              allValues
              details
            }
            filterValueLabels {
              id
              name
              displayName
            }
          }
        }

        fragment widgetRootNodeType on Widget {
          children {
            ...widgetNodeType1
          }
          ...widgetLeafType
        }

        fragment widgetNodeType1 on Widget {
          children {
            ...widgetNodeType2
          }
          ...widgetLeafType
        }

        fragment widgetNodeType2 on Widget {
          children {
            ...widgetNodeType3
          }
          ...widgetLeafType
        }

        fragment widgetNodeType3 on Widget {
          children {
            ...widgetNodeType4
          }
          ...widgetLeafType
        }

        fragment widgetNodeType4 on Widget {
          children {
            ...widgetLeafType
          }
          ...widgetLeafType
        }

        fragment widgetLeafType on Widget {
          id
          name
          type
          css
          js
          userAssetId
          userAssetContentUrl
          classes
          useGlobalTimePeriod
          useGlobalDataSources
          globalDataSourceName
          options
          theme {
            ...legacyThemeType
          }
          position {
            ...positionType
          }
          analyticsRequests {
            ...analyticsRequestType
          }
          profileRequests {
            ...profileRequestType
          }
          postRequests
          trendRequests {
            ...trendRequestType
          }
          label {
            ...widgetLabelType
          }
        }

        fragment analyticsRequestType on analyticsRequest {
          report
          reportingEngine
          limit
          sorts {
            heading
            order
          }
          includeTotal
          timeField
          timePeriods {
            duration
            startTime
            endTime
            timeZone
            key
            previousPeriod
            wholePeriods
            xValue
          }
          groupBys {
            heading
            limit
            limitType
            dimensionName
            groupType
            details
          }
          filters {
            dimensionName
            filterType
            values
            details
          }
          projections {
            heading
            measurementName
            aggregateFunction
            details
          }
          additional {
            TARGET_LANG_CODE
            Currency
            slaConfig {
              id
              name
              assetClass
              assetId
              slaIntervals {
                from
                to
              }
              slaObjective
              complianceTarget
              tzOffset
              country
              timezone
              workingHours {
                MONDAY
                TUESDAY
                WEDNESDAY
                THURSDAY
                FRIDAY
                SATURDAY
                SUNDAY
              }
              inactiveDays {
                name
                inactiveHours
              }
              applyWorkingHours
              deleted
              locked
            }
          }
        }

        fragment profileRequestType on profileRequest {
          reportingEngine
          report
          page
          pageSize
          sorts {
            heading
            order
          }
          timePeriod {
            duration
            startTime
            endTime
            timeZone
            key
            previousPeriod
            wholePeriods
            xValue
          }
          filters {
            dimensionName
            filterType
            values
            details
          }
        }

        fragment trendRequestType on trendRequest {
          source
          woeid
          limit
          country
        }

        fragment positionType on WidgetPosition {
          left
          right
          top
          bottom
          width
          height
          portrait {
            left
            top
            width
            height
          }
          landscape {
            left
            top
            width
            height
          }
          backgroundColor
          display
          maxWidth
          maxHeight
        }

        fragment legacyThemeType on WidgetTheme {
          colorPrimary
          colorSecondary
          colorBackground
          colorNegative
          colorPostive
          colorFont
          fontFamilyPrimary
          fontFamilySecondary
          fontSizePrimary
          fontSizeSecondary
          ext
          backgroundImage {
            url
            previewUrl
            samId
            name
            description
            backgroundRepeat
            backgroundSize
          }
          fontPrimary {
            samId
            name
            description
            family
            subFamily
            weight
            style
            url
          }
          fontSecondary {
            samId
            name
            description
            family
            subFamily
            weight
            style
            url
          }
          typography {
            color
            size
            sizeLiteral
            primary {
              family
              url
              color
              size
              sizeLiteral
              samId
              name
              description
              subFamily
              weight
              style
              textTransform
            }
            secondary {
              family
              url
              color
              size
              sizeLiteral
              samId
              name
              description
              subFamily
              weight
              style
              textTransform
            }
          }
          background {
            url
            size
            repeat
            color
            position
          }
          colorPalette {
            type
            solid
            monochrome {
              startColor
              endOffset
            }
            colorBlend {
              startColor
              endColor
            }
            ranked {
              colors
              contrast
            }
            overrides
          }
        }

        fragment widgetLabelType on WidgetLabel {
          titleText
          subTitleText
          classes
          overlap
          layout
          size
          enabled
          icon
        }`;
    }
}
