import { PostSource, Sort } from './PanelTemplatesTheme/PanelTemplatesTheme';
import { DefaultWidgetPosition } from './PanelTemplatesTheme/constants';
import { Widget } from '@sprinklr/stories/widget/Widget';
import { WIDGET_LABEL_SIZE } from '../../components/Widget/WidgetLabel/constants';
import { postCompositionConfigCollage } from '../../components/Panel/PanelEditorSidebar/PanelDesign/PanelDesignOptions';
import { WidgetOptions } from '@sprinklr/stories/widget/WidgetOptions';
import { TEXT_SHADOW_DEFAULT } from 'models/Widget/Widget';

export var WidgetCollage: Widget = {
    type: 'postsCollage',
    id: 'collage',
    name: 'Collage',
    thumbSrc: 'template-collage.jpg',
    useGlobalTimePeriod: true,
    postRequests: [
        {
            sources: [
                {
                    type: 'posts',
                    // Note: All global variables like reportingEngine, report, and filters
                    // are injected via PostRequest.getRequests()
                    id: new PostSource({
                        pageSize: 50,
                        sorts: [
                            new Sort({
                                order: 'DESC',
                                heading: {
                                    MONITORING_DASHBOARD: 'REACH_COUNT',
                                    LISTENING: 'REACH_COUNT',
                                    INBOUND_MESSAGE: 'INBOUND_COUNT',
                                    PLATFORM: 'TOTAL_ENGAGEMENT',
                                    BENCHMARKING: 'TOTAL_ENGAGEMENT',
                                    CAMPAIGN: 'OUTBOUND_STATUS',
                                    RDB_FIREHOSE: 'SN_CREATED_TIME',
                                    STORY_MESSAGE: 'IMPACT',
                                },
                            }),
                        ],
                        timePeriod: {
                            key: 'last_7_days',
                        },
                    }) as any,
                    options: {
                        count: 200,
                        imagePreload: true,
                        removeDuplicates: true,
                        shufflePosts: false,
                        removeSensitivePosts: true,
                        includeQuoteTweets: false,
                    },
                },
            ],
            format: {
                type: 'buckets',
                options: {
                    padding: 1,
                    buckets: {
                        text: { filter: "post.type == 'text'" },
                        image: { filter: "post.type == 'image'" },
                        any: { filter: 'post.type' },
                    },
                    panels: [{ total: 10, buckets: ['text', 2, 'image', 8, 'any', -1] }],
                },
            },
        },
    ],
    position: DefaultWidgetPosition,
    options: Object.assign(
        {
            player: {
                playing: true,
                duration: 6,
            },
            postTemplate: 'collage',
            postTransition: 'scale',
            userGroupEffect: 'bottom',
            theme: 'primary',
            showText: true,
            showSentiment: true,
            smartSize: {
                enabled: true,
                mode: 'responsive',
                size: 'm',
            },
            portrait: {
                widgetOrientation: 'portrait',
            },
            postVersion: 2,
        } as WidgetOptions,
        postCompositionConfigCollage.a
    ),
    label: {
        titleText: 'Collage (Mixed)',
        enabled: false,
        size: WIDGET_LABEL_SIZE.primary,
        textShadow: TEXT_SHADOW_DEFAULT,
    },
};
