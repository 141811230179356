import { PostSource, Sort } from './PanelTemplatesTheme/PanelTemplatesTheme';
import { paddedDefaultPosition } from './PanelTemplatesTheme/constants';
import { Panel } from '../../models/Panel/Panel';
import { WIDGET_LABEL_SIZE } from '../../components/Widget/WidgetLabel/constants';
import { postCompositionConfigSinglePost } from '../../components/Panel/PanelEditorSidebar/PanelDesign/PanelDesignOptions';

export const TemplateSinglePost: Panel = {
    id: 'single-post',
    name: 'Single Post',
    description: '',
    tags: ['Content', 'Posts', 'Single Post', 'landscape', 'portrait'],
    widget: {
        id: '1',
        position: {
            width: '1920px',
            height: '1080px',
            top: '0',
            left: '0',
        },
        classes: 'template_single_post',
        children: [
            {
                type: 'postsSinglePost',
                id: '1',
                name: 'single post',
                useGlobalTimePeriod: true,
                postRequests: [
                    {
                        sources: [
                            {
                                type: 'posts',
                                // Note: All global variables like reportingEngine, report, and filters
                                // are injected via PostRequest.getRequests()
                                id: new PostSource({
                                    pageSize: 6,
                                    sorts: [
                                        new Sort({
                                            order: 'DESC',
                                            heading: {
                                                MONITORING_DASHBOARD: 'REACH_COUNT',
                                                LISTENING: 'REACH_COUNT',
                                                INBOUND_MESSAGE: 'INBOUND_COUNT',
                                                PLATFORM: 'TOTAL_ENGAGEMENT',
                                                BENCHMARKING: 'TOTAL_ENGAGEMENT',
                                                CAMPAIGN: 'OUTBOUND_STATUS',
                                                RDB_FIREHOSE: 'SN_CREATED_TIME',
                                                STORY_MESSAGE: 'IMPACT',
                                            },
                                        }),
                                    ],
                                    includeComments: true,
                                    showInReplyToPost: false,
                                    includeFaceDetection: false,
                                    timePeriod: {
                                        key: 'last_7_days',
                                    },
                                }) as any,
                                options: {
                                    count: 200,
                                    imagePreload: true,
                                    removeDuplicates: true,
                                    includeFaceDetection: false,
                                    shufflePosts: false,
                                    removeSensitivePosts: true,
                                    includeQuoteTweets: false,
                                },
                            },
                        ],
                        format: {
                            type: 'window',
                            options: {
                                padding: 1,
                            },
                        },
                    },
                ],
                position: paddedDefaultPosition,
                options: {
                    player: {
                        playing: true,
                        duration: 6,
                    },
                    postVersion: 2,
                    smartSize: {
                        enabled: true,
                        mode: 'responsive',
                        size: 'xl',
                    },
                    nativeStyling: {
                        retweetIndicator: true,
                    },
                    ...postCompositionConfigSinglePost.a,
                    portrait: {
                        widgetOrientation: 'portrait',
                    },
                    imageFaceDetection: false,
                },
                label: {
                    titleText: 'Single Post',
                    enabled: false,
                    size: WIDGET_LABEL_SIZE.primary,
                },
            },
        ],
        theme: {
            ext: {
                padding: 0,
            },
        },
    },
};
