import * as React from 'react';
import i18n from 'i18next';
import {
    adsTunables,
    engagementTunables,
    headerTunables,
    imageAdjustmentTunables,
    imagePostTunables,
    onChangeComposition,
    opacityTunables,
    postTitleTunables,
    profanityTunables,
    smartSizeTunables,
    textEntityTunables,
    textPostTunables,
    userGroupTunables,
} from 'components/Sidebar/RenderTunables/SharedTunables';
import { CollapseProps, TunablesCollapse } from 'components/Sidebar/RenderTunables/RenderTunables';
import { postCompositionConfigCollage } from 'components/Panel/PanelEditorSidebar/PanelDesign/PanelDesignOptions';
import { Widget } from '@sprinklr/stories/widget/Widget';
import { Theme } from 'models/Theme/Theme';
import ActionSegmentedGroup from 'components/_UI/Forms/Actions/ActionSegmentedGroup';

const collageCompositions = [
    { value: 'a', label: i18n.t('Default'), icon: 'collage_a' },
    { value: 'b', label: i18n.t('Alternate'), icon: 'collage_b' },
];

const CollageTunables = (widget: Widget, mergedTheme: Theme): TunablesCollapse[] => [
    {
        props: {
            label: i18n.t('Collage'),
            startOpen: true,
        } as CollapseProps,
        children: [...headerTunables(widget), opacityTunables(widget), smartSizeTunables(widget)],
    },
    {
        props: {
            label: i18n.t('Post Composition'),
            startOpen: true,
        } as CollapseProps,
        children: [
            <ActionSegmentedGroup
                key='widget.options.postCompositionType'
                label={i18n.t('Composition')}
                option={widget.options}
                value='postCompositionType'
                options={collageCompositions}
                handleSideEffects={selection =>
                    onChangeComposition(widget, selection, postCompositionConfigCollage)
                }
            />,
        ],
    },
    postTitleTunables(widget, mergedTheme),
    textPostTunables(widget, mergedTheme),
    imagePostTunables(widget, mergedTheme),
    imageAdjustmentTunables(widget),
    userGroupTunables(widget),
    engagementTunables(widget),
    adsTunables(widget),
    textEntityTunables(widget),
    profanityTunables(widget),
];

export default CollageTunables;
