import DataSet from '@sprinklr/stories/analytics/DataSet';
import { WorldHeatMapWidgetOptions } from './options';
import { Geography } from 'models/Geo/Geography';
import { GeoDataSlices } from 'models/Geo/GeoDataSlices';
import { GeoData } from 'models/Geo/GeoData';
import { ComputedStyle, fadedFontColor } from 'utils/GenerateStyles/GenerateStyles';

// How much to scale-in when a state is zoomed-in
export const STATE_SCALE_BY = 6;

export const shouldScaleIn = options => {
    const { regionState, region } = options;
    return regionState && regionState !== 'all';
};

export const countWorldHeatMap = (dataSet: DataSet, options: WorldHeatMapWidgetOptions): number => {
    const regionType = Geography.getType(options.region);

    if (options.animationOverTime.enabled && dataSet.dimensions.length === 2) {
        return GeoDataSlices.create(dataSet, regionType).length;
    } else {
        return GeoData.create(dataSet, regionType).length;
    }
};

export const worldHeatMapOptionStyles = (options, theme, mergedTheme) => {
    const { offsetX, offsetY, zoom, cityMarkers } = options;
    const { opacity, border } = cityMarkers;
    const { enabled: strokeEnabled, color: strokeColor, size } = border;

    let scale = 1.0;

    // If we're zoomed into a state, reduce our border size
    if (shouldScaleIn(options)) {
        scale *= STATE_SCALE_BY;
    }
    const { typography: { color = null } = {} } = mergedTheme;
    const colorFontFaded = fadedFontColor(color);

    const computedStyles: ComputedStyle[] = [
        {
            selector: '.world_heat_map svg',
            styles: {
                transform: `translate(${offsetX}%, ${offsetY}%) scale(${zoom * 0.02 * scale})`,
                position: 'absolute',
            },
        },
        {
            selector: '.world_heat_map .legend_item_swatch',
            styles: {
                border: strokeEnabled && `${size * 0.0025}em solid ${strokeColor}`,
            },
        },
    ];

    return computedStyles;
};
