import * as React from 'react';
import { observer } from 'mobx-react';
import { Post } from '@sprinklr/stories/post/Post';
import { getEndianTime } from 'utils/Number/NumberUtils';
import styled, { css } from 'components/_UI/designSystem';
import NativeProfileLockup, { StyledTimeStamp, StyledLocation } from './Parts/NativeProfileLockup';
import NativeEngagement from './Parts/NativeEngagement';
import NativeText from './Parts/NativeText';
import NativeMedia from './Parts/NativeMedia';
import NativeTitle from './Parts/NativeTitle';
import { PlayerInterval } from 'utils/PlayerInterval/PlayerInterval';
import {
    getNativeMediaOverrides,
    useNativePost,
    baseStyles,
    getEntityStyles,
    StyledMeta,
    getNativeColor,
} from './Helpers';
import NativeFlex from './Bits/NativeFlex';
import { NativeStylingOptions, NativeColorMode } from '../../widgets/PostsWidget/types';

export type NativePostProps = {
    post: Post;
    playerInterval?: PlayerInterval;
    options?: NativeStylingOptions;
    extraPostDataTags?: React.ReactNode;
};
const Colors = {
    blue: '#2d8dc2',
    mediumGray: '#777',
};
const theme = {
    light: {
        link: Colors.blue,
        secondary: Colors.mediumGray,
    },
    dark: {
        link: Colors.blue,
        secondary: Colors.mediumGray,
    },
};
export type StyledPostProps = {
    colorMode: NativeColorMode;
};
const StyledPost = styled(NativeFlex)`
    ${(props: NativePostProps) => baseStyles(props.options)}
    ${({ ratio }: any) => getNativeMediaOverrides(ratio > 2.3 ? 40 : 60, 60)}
    ${({ colorMode }: StyledPostProps) => getEntityStyles(getNativeColor(theme, colorMode, 'link'))}
    ${({ colorMode }: StyledPostProps) =>
        css`
            .extra_post_data_value {
                width: auto;
                color: ${getNativeColor(theme, colorMode, 'secondary')};
            }
        `}
`;

const DefaultPost: React.FC<NativePostProps> = ({
    post,
    playerInterval,
    options,
    extraPostDataTags,
}) => {
    const {
        postRef,
        screenName,
        rssSource,
        profileImgUrl,
        images,
        videos,
        name,
        snCreatedTime,
        snStats,
        unique,
        snType,
        textEntities,
        verified,
        hasMedia,
        text,
        rtl,
        language,
        orientation,
        ratio,
        location,
        title,
    } = useNativePost(post);
    const { lineClamp, maxCharacters, colorMode, removeUrls, emoji, hideZero, showTitle } = options;

    if (!post) {
        return <div key={unique} />;
    }

    const displayName = rssSource ?? (screenName ? `@${screenName}` : undefined);

    const ProfileAndText = (
        <>
            {(profileImgUrl || name || screenName || snType) && (
                <NativeProfileLockup
                    colorMode={colorMode}
                    verified={verified}
                    name={name}
                    secondary={displayName}
                    snType={snType}
                    profileImgUrl={profileImgUrl}
                />
            )}
            {showTitle && !hasMedia && (
                <NativeTitle title={title} colorMode={colorMode} emoji={emoji} />
            )}
            {text && (
                <NativeText
                    removeUrls={removeUrls}
                    emoji={emoji}
                    colorMode={colorMode}
                    hasMedia={hasMedia}
                    lineClamp={lineClamp}
                    maxCharacters={maxCharacters}
                    channel={snType}
                    text={text}
                    rtl={rtl}
                    language={language}
                    textEntities={textEntities && textEntities.message}
                />
            )}
        </>
    );
    const timeStampAndEngagement = (
        <NativeFlex bottom vertical gap='s'>
            <NativeFlex middle gap='s' noGrow>
                {snCreatedTime && (
                    <StyledTimeStamp colorMode={colorMode}>
                        {getEndianTime(snCreatedTime, 'D MMM YYYY, h:mm a')}
                    </StyledTimeStamp>
                )}
                <StyledLocation colorMode={colorMode} location={location} />
            </NativeFlex>
            <NativeEngagement snStats={snStats} snType={snType} hideZero={hideZero} />
        </NativeFlex>
    );

    return (
        <StyledPost
            ratio={ratio}
            key={unique}
            ref={postRef}
            hasMedia={hasMedia}
            vertical={orientation === 'vertical'}
            orientation={orientation}
            options={options}
            colorMode={colorMode}
            gap
            pad>
            <>
                {orientation === 'vertical' && (
                    <>
                        {(profileImgUrl || name || screenName || snType) && (
                            <NativeProfileLockup
                                colorMode={colorMode}
                                verified={verified}
                                name={name}
                                secondary={displayName}
                                snType={snType}
                                profileImgUrl={profileImgUrl}
                            />
                        )}
                        {showTitle && !hasMedia && (
                            <NativeTitle title={title} colorMode={colorMode} emoji={emoji} />
                        )}
                        {hasMedia && (
                            <NativeMedia
                                snType={snType}
                                playerInterval={playerInterval}
                                images={images}
                                videos={videos}
                            />
                        )}
                        {text && (
                            <NativeText
                                removeUrls={removeUrls}
                                emoji={emoji}
                                colorMode={colorMode}
                                hasMedia={hasMedia}
                                lineClamp={lineClamp}
                                maxCharacters={maxCharacters}
                                channel={snType}
                                text={text}
                                textEntities={textEntities && textEntities.message}
                            />
                        )}
                        <NativeFlex gap='s' bottom between>
                            {timeStampAndEngagement}
                            {extraPostDataTags}
                        </NativeFlex>
                    </>
                )}

                {orientation === 'horizontal' && (
                    <>
                        {hasMedia && (
                            <NativeMedia
                                snType={snType}
                                playerInterval={playerInterval}
                                images={images}
                                videos={videos}
                            />
                        )}

                        <StyledMeta vertical gap>
                            {ProfileAndText}
                            <NativeFlex bottom gap='s' between>
                                {timeStampAndEngagement}
                                {extraPostDataTags}
                            </NativeFlex>
                        </StyledMeta>
                    </>
                )}
            </>
        </StyledPost>
    );
};

DefaultPost.displayName = 'DefaultPost';
export default observer(DefaultPost);
