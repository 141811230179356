import { BaseRecordService } from 'utils/BaseRecordService/BaseRecordService';
import { Pdf, PdfFields } from 'models/Pdf/Pdf';
import { GraphQLService } from '../GraphQLService/GraphQLService';
import { Mapper } from '../DataStore/DataStore';

const fetchPdf = `
query fetchPdf($id: ID!) {
    pdf(id: $id) {
        ${PdfFields}
    }
}`;

const createPdf = `
mutation CreateStoryboardVersionPdf($input: CreateStoryboardVersionPdfInput!) {
    createStoryboardVersionPdf(input: $input) {
        pdf {
            ${PdfFields}
        }
        clientMutationId
    }
}`;

export class PdfService extends BaseRecordService<Pdf> {
    constructor(graphQL: GraphQLService, mapper: Mapper<Pdf>) {
        super(graphQL, mapper);
    }

    find(id: string): Promise<Pdf> {
        return this.query({
            name: 'pdf',
            queryParams: {
                query: fetchPdf,
                variables: { id },
            },
            model: 'pdf',
            extractor: result => result['pdf'],
        });
    }

    createStoryboardVersionPdf(storyboardVersionId: string): Promise<Pdf> {
        return this.mutate({
            name: 'createStoryboardVersionPdf',
            queryParams: {
                query: createPdf,
                variables: { input: { storyboardVersionId } },
            },
            extractor: result => result['createStoryboardVersionPdf']['pdf'],
            model: 'pdf',
        });
    }
}
