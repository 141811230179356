import { WidgetOptions } from '@sprinklr/stories/widget/WidgetOptions';
import { WidgetOptionsImpl } from 'models/Widget/WidgetOptions';
import { observable } from 'mobx';
import {
    AnimationValues,
    BlastDelimiterTypes,
    Emoji,
    PositionTypesInterface,
    PostCompositionTypeValues,
    PostTextFormat,
    SmartSize,
    TimeStampOptions,
    TitleOptions,
} from '@sprinklr/stories/widget/types';
import { Palette, PostOptionLocation } from '../PostsWindowWidget/types';
import { ProfanityFilter, RetweetIndicatorOptions } from '../PostsWidget/types';
import { postCompositionConfigTicker } from 'components/Panel/PanelEditorSidebar/PanelDesign/PanelDesignOptions';
import { NativeStylingOptionsDefaultImpl } from '../PostsWidget/options';
import { ExtraPostDataOptionsImpl } from '../PostsWidget/PostCard/options';
import { SmartTrendsOptions } from 'components/SmartTrend/types';
import { SmartTrendsWidgetOptionsImpl } from 'src/widgets/SmartTrendsWidget/options';

export interface TickerWidgetOptions extends WidgetOptions {
    animationLength?: number;
    smartTrends?: SmartTrendsOptions;
}

export class TickerWidgetOptionsImpl extends WidgetOptionsImpl implements TickerWidgetOptions {
    @observable smartTrends = new SmartTrendsWidgetOptionsImpl();
    @observable showImageStyles = true; // this shouldn't be in the widget options  - it's just a UI concern
    @observable imageFilterBlur = false;
    @observable imageFilterBrightness = false;
    @observable imageFilterContrast = false;
    @observable imageFilterGrayscale = false;
    @observable imageFilterSaturate = false;
    @observable imageFilterHueRotate = false;
    @observable imageFilterOpacity = false;
    @observable imageFilterInvert = false;
    @observable imageFilterSepia = false;
    @observable imageFilterBlurValue = 0;
    @observable imageFilterBrightnessValue = 100;
    @observable imageFilterContrastValue = 100;
    @observable imageFilterGrayscaleValue = 0;
    @observable imageFilterSaturateValue = 100;
    @observable imageFilterHueRotateValue = 0;
    @observable imageFilterOpacityValue = 100;
    @observable imageFilterInvertValue = 0;
    @observable imageFilterSepiaValue = 0;
    @observable imageBlendMode: string = null;
    @observable imageSecondaryBlendMode = '';
    @observable imageBlendColor = 'rgba(0,0,0,.0)';
    @observable imageSecondaryBlendColor = '';
    @observable presetImageFilters = '';
    @observable postImageTextFormat: PostTextFormat = 'clamp';
    @observable postImageTeleprompterSpeed = 50;
    @observable imagePostLineClamp = true;
    @observable imagePostLineClampValue = 3;
    @observable imageTextColor = '';
    @observable imageBackgroundSize = 'cover';
    @observable imagePostHideText = false;
    @observable imageFaceDetection = false;
    @observable showEngagementMetrics = true;
    @observable engagementMetricsSize = 12;
    @observable textOnlyColor = '';
    @observable textOnlyTextFormat: PostTextFormat = 'clamp';
    @observable textOnlyTeleprompterSpeed = 50;
    @observable showUserGroup = true;
    @observable postLineClamp = true;
    @observable postLineClampValue = 3;
    @observable hashtagTextColor = '';
    @observable hashtagBackgroundColor = '';
    @observable mentionTextColor = '';
    @observable mentionBackgroundColor = '';
    @observable urlTextColor = '';
    @observable urlBackgroundColor = '';
    @observable showTextEntityUrls = false;
    @observable showExpandedEntityUrls = false;
    @observable showUserAvatar = true;
    @observable showUserName = true;
    @observable showUserScreenName = true;
    @observable userGroupIconSize = 9;
    @observable userGroupNameSize = 13;
    @observable userGroupScreenNameSize = 11;
    @observable socialIconPosition: PositionTypesInterface = { x: 'left', y: 'top' };
    @observable showText = true;
    @observable showTimeStamp = true;
    @observable timeStampSize = 11;
    @observable timeStampFormat: TimeStampOptions = 'timeAgo';
    @observable endianTime = 'D MMM YYYY, h:mm a';
    @observable textFitMin = 20;
    @observable activePostsInWindow = 1;
    @observable stripLinks?: boolean = true;
    @observable blastDelimiter: BlastDelimiterTypes = 'words'; // words, letters, sentences, lines
    @observable metaThemePalette: Palette[] = [];
    @observable showSecondaryImageOverlay?: boolean = false;
    @observable showZeroEngagementValues?: boolean = false;
    @observable metaOffsetTotal?: number = 8;
    @observable themeOffsetTotal?: number = 2;
    @observable imageFiltersOpen?: boolean = true;
    @observable postLayoutType = 'd';
    @observable postTextOnlySize = 15;
    @observable postImageTextSize = 15;
    @observable postLayoutOrientation = 'horizontal';
    @observable animationType: AnimationValues = 'rotate';
    @observable showIconInUserGroup = true;
    @observable removeGenericPII = true;
    @observable textOnlyBackgroundColor = '';
    @observable imagePostBackgroundColor = '';
    @observable secondaryOverlayColor = '';
    @observable engagementMetricsPosition = 'top_right';
    @observable maxCharacters?: number = 400;
    @observable userGroupLayoutType = 'a';
    @observable postCompositionType: PostCompositionTypeValues = 'a';
    @observable userGroupAvatarSize = 15;
    @observable showLinkPreview? = false;
    @observable linkPreviewSize? = 12;
    @observable emoji: Emoji = {
        platform: 'default',
    };
    @observable location: PostOptionLocation = {
        enabled: false,
        size: 11,
    };
    @observable extraPostDataOptions = { ...new ExtraPostDataOptionsImpl(), mode: 'tags' };
    @observable postTitleOptions: TitleOptions = {
        enabled: false,
        size: 20,
        color: '',
        format: {
            type: 'clamp',
            clamp: {
                enabled: true,
                lines: 3,
            },
            fit: {
                min: 20,
                max: 400,
            },
        },
    };

    @observable retweetIndicator: RetweetIndicatorOptions = {
        enabled: false,
        opacity: 50,
        size: 11,
    };

    @observable smartSize: SmartSize = {
        enabled: false,
        mode: 'responsive',
        size: 'xl',
        baseline: {
            engagementMetricsSize: 12,
            postLineClampValue: 3,
            userGroupIconSize: 9,
            userGroupNameSize: 13,
            userGroupScreenNameSize: 11,
            timeStampSize: 11,
            textFitMin: 20,
            postTextOnlySize: 15,
            postImageTextSize: 15,
            userGroupAvatarSize: 15,
            nativeStyling: {
                fontScale: 30,
            },
            retweetIndicator: {
                size: 11,
            },
        },
        optionDefaults: {
            classOverrides: {
                ...postCompositionConfigTicker.a.classOverrides,
                postOuter: {
                    default: 'm-1',
                },
            },
        },
        breakpoints: {
            xs: {
                options: {
                    classOverrides: {
                        postOuter: {
                            default: 'm-0',
                        },
                        userGroup: {
                            default: 'p-0 pb-025',
                        },
                        engagementMetric: {
                            default: 'm-reset ml-05',
                        },
                    },
                },
            },
            s: {
                options: {
                    classOverrides: {
                        postOuter: {
                            default: 'm-025',
                        },
                        userGroup: {
                            default: 'p-0 pb-05',
                        },
                        engagementMetric: {
                            default: 'm-reset ml-05',
                        },
                    },
                },
            },
            m: {
                options: {
                    classOverrides: {
                        postOuter: {
                            default: 'm-05',
                        },
                        userGroup: {
                            default: 'p-0 pb-05',
                        },
                        engagementMetric: {
                            default: 'm-reset ml-1',
                        },
                    },
                },
            },
        },
    };

    @observable profanityFilter: ProfanityFilter = {
        enabled: false,
        profanityList: [],
        obscureSymbol: '*',
        replace: false,
        replaceList: {},
    };

    @observable nativeStyling = {
        ...new NativeStylingOptionsDefaultImpl(),
        ...{
            lineClamp: {
                enabled: true,
                lines: 1,
                hasMedia: {
                    enabled: true,
                    lines: 2,
                },
            },
        },
    };
}
