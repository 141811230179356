import {
    AnalyticsFilter,
    AnalyticsGroupBy,
    AnalyticsProjection,
    AnalyticsRequest,
    AnalyticsSort,
    AnalyticsTimePeriod,
} from '@sprinklr/stories/analytics/AnalyticsRequest';

// TODO move display-builder dependencies
import TimePeriod from '@sprinklr/display-builder/models/TimePeriod/TimePeriod';
import TimePeriodService from '@sprinklr/display-builder/services/TimePeriodService/TimePeriodService';

import { ReportingRequest } from '@sprinklr/stories/reporting/types';

export const invalidTimePeriodMsg = 'Must provide valid time period key or startTime and endTime.';
export const noPreviousPeriodMsg = 'Failed to retrieve previous time period.';

export const formatHeading = (heading: string, aggregateFunction: string): string => {
    return !heading.includes('_CHANGE') &&
        (aggregateFunction === 'CHANGE' || aggregateFunction === 'PERCENTAGE_CHANGE')
        ? `${heading}_${aggregateFunction}`
        : heading;
};

export const getTimePeriod = (
    timePeriod: AnalyticsTimePeriod,
    allTimePeriods: AnalyticsTimePeriod[],
    index: number
): TimePeriod => {
    if (!timePeriod || (!timePeriod.previousPeriod && !timePeriod.key && !timePeriod.startTime)) {
        throw new Error(invalidTimePeriodMsg);
    }

    if (timePeriod.previousPeriod) {
        if (index === 0) {
            throw new Error(noPreviousPeriodMsg);
        }

        const currentPeriod = getTimePeriod(allTimePeriods[index - 1], allTimePeriods, index - 1);
        return TimePeriodService.previousPeriod(currentPeriod);
    }

    return TimePeriodService.createFromAnalyticsTimePeriod(timePeriod);
};

// Metric aggregateFunctions that are "CHANGE", "PERCENTAGE_CHANGE" or "PERCENTAGE" need
// to be handled by adding a projectionDecorations array to the ReportingRequest
export const decorators = ['CHANGE', 'PERCENTAGE_CHANGE', 'PERCENTAGE', 'CUMULATIVE_SUM'];

export const getProjectionDecorations = (
    projections: AnalyticsProjection[],
    projectionDecorations?: string[]
) => {
    projectionDecorations = projectionDecorations || [];

    projections
        .filter((projection: AnalyticsProjection) => {
            return decorators.includes(projection.aggregateFunction);
        })
        .map(
            projection => projection.aggregateFunction
            // Rows come back in this order: "CHANGE", "PERCENTAGE_CHANGE", "PERCENTAGE", no
            // matter what order the decorations are in.  Pre-sorting decorations makes
            // post-processing more efficient.
        )
        .forEach(projectionDecoration => {
            if (!projectionDecorations.includes(projectionDecoration)) {
                projectionDecorations.push(projectionDecoration);
            }
        });

    projectionDecorations.sort((a, b) => {
        let result = a.localeCompare(b);
        // PERCENTAGE_CHANGE comes before PERCENTAGE
        if (a.charAt(0) === 'P' && b.charAt(0) === 'P') {
            result = -result;
        }
        return result;
    });

    return projectionDecorations;
};

export const toReportingProjection = projection => {
    if (decorators.includes(projection.aggregateFunction)) {
        const newProjection = Object.assign({}, projection);
        newProjection.aggregateFunction = 'SUM';
        return newProjection;
    }
    return projection;
};

export const toReportingGroupBy = (groupBy: AnalyticsGroupBy) => {
    // strip resolveName from each groupBy and make sure date dimensions have interval details

    const newGroupBy: any = {};
    let gKey;
    for (gKey in groupBy) {
        if (gKey === 'resolveName') {
            continue;
        }
        newGroupBy[gKey] = groupBy[gKey];
    }

    // TODO: is this still necessary?
    if (groupBy.heading === 'day' && !groupBy.details) {
        newGroupBy.details = {
            interval: '1d',
        };
    }
    if (groupBy.heading === 'hour' && !groupBy.details) {
        newGroupBy.details = {
            interval: '1h',
        };
    }
    if (groupBy.heading === 'week' && !groupBy.details) {
        newGroupBy.details = {
            interval: '1w',
        };
    }
    if (groupBy.heading === 'month' && !groupBy.details) {
        newGroupBy.details = {
            interval: '1M',
        };
    }

    // Remove clientId from groupBy details if there are none present - don't send an empty object.
    // Remove limit and limitType - these fields do not exist
    if (
        newGroupBy.details?.clientId &&
        typeof newGroupBy.details.clientId === 'object' &&
        Object.keys(newGroupBy.details.clientId as any).length === 0
    ) {
        delete newGroupBy.details.clientId;
    }
    if (newGroupBy.limitType) {
        delete newGroupBy.limitType;
        delete newGroupBy.limit;
    }

    return newGroupBy;
};

const toReportingRequests = (analyticsRequest: AnalyticsRequest): ReportingRequest[] => {
    if (!analyticsRequest.timePeriods) {
        return [];
    }

    const wordCloudFilter: boolean =
        analyticsRequest.groupBys.filter((groupBy: AnalyticsGroupBy) => {
            return 'WORD_CLOUD_MESSAGE' === groupBy.dimensionName;
        }).length > 0;

    if (!analyticsRequest.additional) {
        analyticsRequest.additional = {};
    }

    // PRESERVE THIS - this just (?) stopped being necessary/working, but who knows what tomorrow holds? -- DCBS, 2018-08022
    // const lstKeywordList = analyticsRequest.filters.filter((filter: AnalyticsFilter) => {    // Keyword List 'Not Containing' filter special rules
    //         return (wordCloudFilter && filter.filterType === "NIN" && filter.dimensionName === "LST_KEYWORD_LIST");
    //     }).map((filter: AnalyticsFilter) => {
    //         return filter.values.filter((value) => (!!value)).join(',');
    //     }).join(',');
    //
    // if (lstKeywordList.length) {
    //     analyticsRequest.additional.lst_keyword_list = lstKeywordList;
    // }
    // PRESERVE THIS - this just (?) stopped being necessary/working, but who knows what tomorrow holds? -- DCBS, 2018-08022

    // We want to remove the topic groups filter if a topic ids filter is present
    const topicIdsFilter = analyticsRequest.filters.find(
        (filter: AnalyticsFilter) =>
            filter.dimensionName === 'TOPIC_IDS' &&
            filter.filterType === 'IN' &&
            filter.values.length > 0
    );

    const projectionDecorations = getProjectionDecorations(analyticsRequest.projections);

    const build = (timePeriod: AnalyticsTimePeriod, index: number): ReportingRequest => {
        const newRequest: ReportingRequest = {
            reportingEngine: analyticsRequest.reportingEngine,
            report: analyticsRequest.report,
            skipResolve: true,
            timeField: analyticsRequest.timeField,
            groupBys: analyticsRequest.groupBys
                .filter((groupBy: AnalyticsGroupBy) => {
                    return !!groupBy.dimensionName;
                })
                .map(toReportingGroupBy),

            sorts:
                analyticsRequest.sorts
                    ?.filter((sort: AnalyticsSort) => {
                        return !!sort.heading;
                    })
                    .map(sort => {
                        const matchingProjection = analyticsRequest.projections.find(
                            projection => projection.heading === sort.heading
                        );
                        if (matchingProjection) {
                            sort.heading = formatHeading(
                                sort.heading,
                                matchingProjection.aggregateFunction
                            );
                        }
                        return sort;
                    }) ?? [],
            startTime: null,
            endTime: null,
            page: 0,
            pageSize: analyticsRequest.limit || 100,

            filters: analyticsRequest.filters
                ? analyticsRequest.filters.filter((filter: AnalyticsFilter) => {
                      // "details" cannot be null with measurement filters
                      // or the request will fail
                      if (!filter.details) {
                          filter.details = {};
                      }
                      return (
                          filter.dimensionName &&
                          filter.filterType &&
                          filter.values &&
                          filter.values.length > 0 &&
                          !(
                              topicIdsFilter &&
                              filter.dimensionName === 'TOPIC_GROUP_IDS' &&
                              filter.filterType === 'IN'
                          )
                      );

                      // PRESERVE THIS - this just (?) stopped being necessary/working, but who knows what tomorrow holds? -- DCBS, 2018-08022
                      // && !(wordCloudFilter && filter.filterType === "NIN" && filter.dimensionName === "LST_KEYWORD_LIST");   // Keyword List 'Not Containing' filter special rules
                      // PRESERVE THIS - this just (?) stopped being necessary/working, but who knows what tomorrow holds? -- DCBS, 2018-08022
                  })
                : [],
            additional: analyticsRequest.additional,
            projectionDecorations,
            // Remap any decorator aggregate functions to "SUM"
            projections: analyticsRequest.projections.map(toReportingProjection),
        };

        const period = getTimePeriod(timePeriod, analyticsRequest.timePeriods, index);

        newRequest.startTime = period.startDate ? period.startDate.valueOf() : null;
        newRequest.endTime = period.endDate ? period.endDate.valueOf() : null;

        newRequest.timeZone = timePeriod.timeZone;

        // Adding this just in case it helps speed up the response generation
        newRequest.additional = analyticsRequest.additional || {};
        newRequest.additional.IGNORE_DRILL_DOWN = 'true';

        if (analyticsRequest.additional && analyticsRequest.additional.TARGET_LANG_CODE) {
            newRequest.additional.TARGET_LANG_CODE = analyticsRequest.additional.TARGET_LANG_CODE;
        }

        // The API can return non-us state values (numbers, weird three-digit codes, etc.). Add a Country == US filter to correct this.
        if (
            analyticsRequest.groupBys.some(groupBy => groupBy.dimensionName === 'US_STATE') &&
            !analyticsRequest.filters.some(filter => filter.dimensionName === 'COUNTRY')
        ) {
            newRequest.filters.push({
                dimensionName: 'COUNTRY',
                filterType: 'IN',
                values: ['US'],
            });
        }

        return newRequest;
    };

    return analyticsRequest.timePeriods.map(build);
};

export default toReportingRequests;
