import React from 'react';

// types
import { Widget } from '@sprinklr/stories/widget/Widget';
import { Theme } from 'models/Theme/Theme';

// utils
import i18n from '../../i18n';
import { action } from 'mobx';

// components
import { borderTunables } from 'components/Sidebar/RenderTunables/SharedTunables';
import { CollapseProps, TunablesCollapse } from 'components/Sidebar/RenderTunables/RenderTunables';
import LabelGroup from 'components/_UI/Forms/LabelGroup/LabelGroup';
import ActionColorSwatchGroup from 'components/_UI/Forms/Actions/ActionColorSwatchGroup';
import ActionSliderGroup from 'components/_UI/Forms/Actions/ActionSliderGroup';
import ActionSegmentedGroup from 'components/_UI/Forms/Actions/ActionSegmentedGroup';
import ActionCheckboxSliderGroup from 'components/_UI/Forms/Actions/ActionCheckboxSliderGroup';
import SupportsRichText from 'components/Panel/PanelEditorSidebar/PanelDesign/SupportsRichText';

// constants
import {
    topRightBottomLeft,
    verticalAlignOptions,
} from 'components/Panel/PanelEditorSidebar/PanelDesign/PanelDesignOptions';
import ActionCheckboxSliderColorSwatchGroup from 'components/_UI/Forms/Actions/ActionCheckboxSliderColorSwatchGroup';

const SpeechBubbleTunables = (widget: Widget, mergedTheme: Theme): TunablesCollapse[] => {
    return [
        {
            props: {
                label: i18n.t('Speech Bubble'),
                startOpen: true,
            } as CollapseProps,
            children: [
                <ActionSliderGroup
                    key='widget.options.contentPadding'
                    label={i18n.t('Padding')}
                    option={widget.options}
                    value='contentPadding'
                    showValue
                    min={0}
                    max={100}
                />,
                <ActionSegmentedGroup
                    key='widget.options.verticalAlign'
                    label={i18n.t('Vertical Align')}
                    option={widget.options}
                    value='verticalAlign'
                    options={verticalAlignOptions}
                />,
                borderTunables(widget),
                <ActionSliderGroup
                    key='widget.options.border.radius'
                    label={i18n.t('Rounded Corners')}
                    option={widget.options.border}
                    value='radius'
                    showValue
                    min={0}
                    max={100}
                />,
                <ActionCheckboxSliderGroup
                    key='widget.options.arrow'
                    label={i18n.t('Arrow')}
                    showValue
                    checkbox={{
                        option: widget.options.arrow,
                        value: 'enabled',
                    }}
                    slider={{
                        option: widget.options.arrow,
                        value: 'size',
                        min: 0,
                        max: 100,
                    }}
                />,
                ...(widget.options.arrow.enabled
                    ? [
                          <ActionSegmentedGroup
                              key='widget.options.arrow.placement'
                              label={i18n.t('Placement')}
                              option={widget.options.arrow}
                              value='placement'
                              options={topRightBottomLeft}
                          />,
                          <ActionSliderGroup
                              key='widget.options.arrow.offset'
                              label={i18n.t('Offset')}
                              option={widget.options.arrow}
                              value='offset'
                              showValue
                              min={0}
                              max={100}
                          />,
                      ]
                    : []),
                <LabelGroup label={i18n.t('Background')} key='widget.options.background'>
                    <ActionColorSwatchGroup
                        option={widget.options}
                        value='background'
                        onClear={action(() => (widget.options.background = ''))}
                        secondaryIcon={widget.options.background !== '' ? 'icon-undo' : undefined}
                    />
                </LabelGroup>,
                <ActionCheckboxSliderColorSwatchGroup
                    key='widget.options.shadow'
                    label={i18n.t('Drop Shadow')}
                    showValue
                    checkbox={{
                        checked: widget.options.shadow?.enabled,
                        value: 'enabled',
                        option: widget.options.shadow,
                    }}
                    slider={{
                        min: 0,
                        max: 100,
                        value: 'blur',
                        option: widget.options.shadow,
                    }}
                    colorSwatch={{
                        option: widget.options.shadow,
                        value: 'color',
                        color: widget.options.shadow?.color,
                        onClear: action(() => (widget.options.shadow.color = '')),
                        secondaryIcon: widget.options.shadow.color !== '' ? 'icon-undo' : undefined,
                        onChange: action(
                            ({ rgb: { r, g, b, a } }) =>
                                (widget.options.shadow.color = `rgba(${r}, ${g}, ${b}, ${a})`)
                        ),
                    }}
                />,
            ],
        },
        {
            props: {
                label: i18n.t('Typography'),
                startOpen: true,
            } as CollapseProps,
            children: [
                <SupportsRichText
                    key='supportsRichText'
                    widget={widget}
                    mergedTheme={mergedTheme}
                />,
            ],
        },
    ];
};

export default SpeechBubbleTunables;
