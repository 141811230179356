import { action, observable } from 'mobx';
import { WidgetJSON } from '../WidgetWrapper';
import { WidgetDimensionKey } from '../WidgetDimensionKey';
import { AnalyticsFilter, AnalyticsFilterType } from '@sprinklr/stories/analytics/AnalyticsRequest';
import { ChannelType } from '@sprinklr/stories/post/PostsRequest';
import { ReportingLookupFilter } from 'services/SprinklrAPIService/SprinklrAPIService';

export abstract class WidgetFilter implements WidgetJSON {
    @observable
    isLoading = true;

    @observable
    dimensionLabel: string;

    constructor() {}

    abstract get isIncomplete(): boolean;

    abstract get dimension(): WidgetDimensionKey;
    abstract get condition(): AnalyticsFilterType;
    abstract get values(): Array<string>;

    abstract setDimension(dimension: WidgetDimensionKey): void;
    abstract setCondition(condition: AnalyticsFilterType): void;
    abstract setValues(values: Array<string>, filter?: ReportingLookupFilter): void;
    abstract selectAllValues(allValues: boolean): void;
    abstract get all(): boolean;

    get additional(): any {
        return null;
    }

    getJSON(): AnalyticsFilter {
        return null;
    }

    get type(): ChannelType {
        return null;
    }

    setType(type: ChannelType): void {}

    get conditionLabel(): string {
        switch (this.condition) {
            case 'IN':
                return 'containing';

            case 'NIN':
                return 'not containing';

            default:
                return '[MISSING CONDITION]';
        }
    }

    get inputDate(): boolean {
        return false;
    }

    get inputText(): boolean {
        return false;
    }

    get inputNumeric(): boolean {
        return false;
    }

    get requiresNumericValue(): boolean {
        return this.inputNumeric;
    }

    get numericMinValue(): number {
        return undefined;
    }

    // Special-case needed for BRAND_ID as data source
    get brandCompetitor(): boolean {
        return false;
    }

    setValue(value: string, label: string) {
    }

    toJSON(): any {
        return null;
    }
}
