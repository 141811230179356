import axios, { AxiosRequestConfig } from 'axios';
import { toJS } from 'mobx';
import { PanelTemplatesStore } from './stores/PanelTemplatesStore/PanelTemplatesStore';
import MetaInfoService from 'services/MetaInfoService/MetaInfoService';
import SocketService from 'services/SocketService/SocketService';
import ClockService from 'services/ClockService/ClockService';
import { GraphQLService } from 'services/GraphQLService/GraphQLService';
import PostsService from '@sprinklr/stories-services/PostsService/PostsService';
import PostsFormatService from 'services/PostsFormatService/PostsFormatService';
import AnalyticsService from '@sprinklr/stories-services/AnalyticsService/AnalyticsService';
import AuthService from 'services/Auth/AuthService';
import WidgetTypeService from 'services/WidgetTypes/WidgetTypeService';
import SyncService from 'services/SyncService/SyncService';
import { LayoutComparisonService } from 'services/LayoutComparisonService/LayoutComparisonService';
import { DataStoreFactory } from 'services/DataStore/DataStore';
import { DisplayService } from 'services/DisplayService/DisplayService';
import { LayoutService } from 'services/LayoutService/LayoutService';
import { LayoutCustomService } from 'services/LayoutCustomService/LayoutCustomService';
import { StoryboardService } from 'services/StoryboardService/StoryboardService';
import WidgetService from 'services/WidgetService/WidgetService';
import { DisplayTokenService } from 'services/DisplayTokenService/DisplayTokenService';
import { PartnerService } from 'services/PartnerService/PartnerService';
import SprinklrAPIService from 'services/SprinklrAPIService/SprinklrAPIService';
import { PartnerLimitService } from 'services/PartnerLimitService/PartnerLimitService';
import ProfileService from 'services/ProfileService/ProfileService';
import TrendService from 'services/TrendService/TrendService';
import VersionService from 'services/VersionService/VersionService';
import SignalService from 'services/SignalService/SignalService';
import JSContextService from 'services/JSContextService/JSContextService';
import EditorStateService from 'services/EditorStateService/EditorStateService';
import config from './config';
import { EmbedService } from 'services/EmbedService/EmbedService';
import HeartbeatService from 'services/HeartbeatService/HeartbeatService';
import { SocialMediaAssetService } from 'services/SocialMediaAssetService/SocialMediaAssetService';
import { UserService } from 'services/UserService/UserService';
import { StoryboardVersionService } from 'services/StoryboardVersionService/StoryboardVersionService';
import { PanelVersionService } from 'services/PanelVersionService/PanelVersionService';
import { SceneVersionService } from 'services/SceneVersionService/SceneVersionService';
import RenderSettingsService from 'services/RenderSettingsService/RenderSettingsService';
import { PdfService } from 'services/PdfService/PdfService';
import { PptxService } from 'services/PptxService/PptxService';
import LocalSettingsService from 'services/LocalSettingsService/LocalSettingsService';
import UiStateService from 'services/UiStateService/UiStateService';
import { ThemeService } from 'services/ThemeService/ThemeService';
import InstagramImageService from '@sprinklr/stories-services/PostsService/InstagramImageService';
import { UserAssetService } from 'services/UserAssetService/UserAssetService';
import { PublishingService } from 'services/PublishingService/PublishingService';
import { ScheduledStoryboardService } from 'services/ScheduledStoryboardService/ScheduledStoryboardService';
import { IntuitionService } from 'services/IntuitionService/IntuitionService';
import { AuditEventService } from 'services/AuditEventService/AuditEventService';
import { checkWebpAlphaSupport } from 'utils/Thumbnailer/Thumbnailer';
import TouchScreenService from 'services/TouchScreenService/TouchScreenService';
import GeoService from 'services/GeoService/GeoService';

// Simple non-exhaustive check for running in a sandboxed iframe. There are various sandbox configs
// but the main one we're concerned with is no-same-origin, self.origin === "null" detects that.
// no-same-origin means no access to storage APIs and no network requests are treated as same-origin.
config.sandboxed = window.self.origin === 'null' && window.self !== window.top;

if (!config.sandboxed) {
    // in some browsers and security contexts the above sandbox detection fails so try reading cookies too
    try {
        const isCookies = !!document.cookie;
    } catch (e) {
        config.sandboxed = true;
    }
}
// MUST run in try/catch - some security controls will result in DOMException just on read of window.sessionStorage
if (!config.sandboxed) {
    try {
        if (sessionStorage) {
            checkWebpAlphaSupport();
        }
    } catch (e) {
        config.sandboxed = true;
    }
}

const axiosConfig: AxiosRequestConfig = {
    baseURL: config.apiRoot,
    withCredentials: !config.sandboxed, // keep axios from accessing cookies, etc.
};

if (config.sandboxed) {
    // Weird hacky way to get axios to avoid a cookie-handling error when sandboxed.
    axiosConfig.xsrfCookieName = '';
}

const applicationIdentifier = config.applicationIdentifier;

const axiosInstance = axios.create(axiosConfig);

const graphQLService = new GraphQLService(axiosInstance);
const socketService = new SocketService(config.apiRoot + 'websocket');
const widgetTypeService = new WidgetTypeService();
const dsFactory = new DataStoreFactory();
const dcbsDataStore = dsFactory.create(widgetTypeService);
const panelVersionService = new PanelVersionService(
    graphQLService,
    dcbsDataStore.getMapper('panelVersion')
);
const authService = new AuthService(axiosInstance, graphQLService, config);

authService.onClientChange(clientId => graphQLService.setClientId(clientId));

const intuitionService = new IntuitionService(graphQLService);
const auditEventService = new AuditEventService(graphQLService);
const sprinklrAPIService: SprinklrAPIService = new SprinklrAPIService(axiosInstance, applicationIdentifier, authService);
const instagramImageService = new InstagramImageService(axiosInstance);
const metaInfoService = new MetaInfoService(axiosInstance, graphQLService);
const trendService = new TrendService(axiosInstance);
const analyticsService = new AnalyticsService(sprinklrAPIService, metaInfoService, trendService);
const postsService = new PostsService(
    sprinklrAPIService,
    graphQLService,
    axiosInstance,
    intuitionService,
    instagramImageService,
    metaInfoService,
    analyticsService
);
const profileService = new ProfileService(sprinklrAPIService, postsService);
const uiStateService = new UiStateService(config);
const signalService = new SignalService();
const renderSettingsService = new RenderSettingsService();
const storyboardService = new StoryboardService(
    graphQLService,
    dcbsDataStore.getMapper('storyboard')
);
const widgetService = new WidgetService(widgetTypeService);
const storyboardVersionService = new StoryboardVersionService(
    graphQLService,
    dcbsDataStore.getMapper('storyboardVersion')
);
const panelTemplatesStore = new PanelTemplatesStore();
const layoutService = new LayoutService(graphQLService, dcbsDataStore.getMapper('layout'), config);

if (config.useSkuLimits === false) {
    console.info('sku limits are disabled.');
    authService.allowAllPartners = true;
}

const isPresentations: boolean = config.applicationMode === 'PRESENTATIONS';

const services = {
    config,
    dcbsds: dcbsDataStore,
    displayTokenService: new DisplayTokenService(axiosInstance, authService),
    displayService: new DisplayService(graphQLService, dcbsDataStore.getMapper('location')),
    scheduledStoryboardService: new ScheduledStoryboardService(
        graphQLService,
        dcbsDataStore.getMapper('scheduledStoryboard')
    ),
    socialMediaAssetService: new SocialMediaAssetService(
        graphQLService,
        dcbsDataStore.getMapper('socialMediaAsset')
    ),
    userService: new UserService(graphQLService, dcbsDataStore.getMapper('user')),
    partnerService: new PartnerService(graphQLService, dcbsDataStore.getMapper('partner')),
    partnerLimitService: new PartnerLimitService(
        graphQLService,
        dcbsDataStore.getMapper('partnerLimit')
    ),
    layoutService,
    layoutCustomService: new LayoutCustomService(
        graphQLService,
        dcbsDataStore.getMapper('layout'),
        config
    ),
    storyboardService,
    storyboardVersionService,
    panelVersionService,
    sceneVersionService: new SceneVersionService(
        graphQLService,
        dcbsDataStore.getMapper('sceneVersion')
    ),
    embedService: new EmbedService(graphQLService, dcbsDataStore.getMapper('embed')),
    themeService: new ThemeService(
        graphQLService,
        dcbsDataStore.getMapper('theme'),
        isPresentations
    ),
    pdfService: new PdfService(graphQLService, dcbsDataStore.getMapper('pdf')),
    pptxService: new PptxService(graphQLService, dcbsDataStore.getMapper('pptx')),
    publishingService: new PublishingService(
        graphQLService,
        dcbsDataStore.getMapper('panelPublishResult')
    ),
    clockService: new ClockService(config.apiRoot, config.sandboxed),
    sprinklrAPIService,
    analyticsService,
    postsService,
    profileService,
    postsFormatService: new PostsFormatService(
        postsService,
        profileService,
        new SyncService(),
        signalService,
        renderSettingsService,
        sprinklrAPIService
    ),
    trendService,
    layoutComparisonService: new LayoutComparisonService(),
    panelTemplatesStore,
    graphQLService,
    authService,
    metaInfoService,
    signalService,
    widgetTypeService: new WidgetTypeService(),
    widgetService,
    jsContextService: new JSContextService(),
    axios: axiosInstance,
    versionService: new VersionService(axiosInstance),
    socketService,
    renderSettingsService,
    editorStateService: new EditorStateService(),
    heartbeatService: new HeartbeatService(axiosInstance),
    uiStateService,
    userAssetService: new UserAssetService(
        axios,
        graphQLService,
        dcbsDataStore.getMapper('userAsset')
    ),
    intuitionService: new IntuitionService(graphQLService),
    auditEventService,
    localSettingsService: new LocalSettingsService(authService),
    touchScreenService: new TouchScreenService(signalService, widgetTypeService),
    geoService: new GeoService(axiosInstance, config),
    applicationIdentifier,
};

if (config.developmentMode) {
    (window as any).services = services;
    (window as any).toJS = toJS; // Debugging method from mobx to convert observable members to readable state
    // console.info("Dev mode: window.services and window.toJS are available");
}

export default services;
