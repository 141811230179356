import moment from 'moment';

export type TickHandler = (adjustedNow: moment.Moment, offset: number) => void;

export type TimeUnit = 'second' | 'minute' | 'hour' | 'day' | 'week';

const DEFAULT_API_ROOT = 'https://display-api.sprinklr.com/';

export default class ClockService {
    private readonly apiRoot: string;
    private readonly noNetwork: boolean;

    constructor(apiRoot?: string, sandboxed?: boolean) {
        this.apiRoot = apiRoot ? apiRoot : DEFAULT_API_ROOT;
        this.noNetwork = !!sandboxed;
    }

    handlers: TickHandler[] = [];

    timeoutId = null;

    offsetCalculated = false;

    offset = 0;

    syncFrequencyMinutes = 10;

    private getSingleOffset(): Promise<number> {
        let url = `${this.apiRoot}time/now`;

        return new Promise<number>((resolve, reject) => {
            const startTime: number = new Date().getTime();
            const xhr = new XMLHttpRequest();

            url += '?cachebuster=' + startTime;

            xhr.onload = () => {
                const localTime: number = new Date().getTime();

                const serverTime = parseInt(xhr.responseText, 10);
                if (isNaN(serverTime) || serverTime < 1000) {
                    throw new Error(xhr.responseText + ' is not a valid timestamp');
                }

                // let latency = localTime - startTime;
                // console.log('latency', latency);
                // let middleTime: number = startTime + latency / 2;

                const offset = serverTime - localTime;

                // console.log('offset', offset);

                resolve(offset);
            };

            xhr.onabort = xhr.onerror = reject;

            xhr.open('GET', url);
            xhr.send();
        });
    }

    private average(offsets: number[]) {
        const sum: number = offsets.reduce((a, b) => a + b, 0);
        const count: number = offsets.length;
        return sum / count;
    }

    private median(values: number[]) {
        if (values.length === 0) {
            return 0;
        }
        values = values.slice();

        values.sort((a, b) => {
            return a - b;
        });

        const half = Math.floor(values.length / 2);

        if (values.length % 2) {
            return values[half];
        }

        return (values[half - 1] + values[half]) / 2.0;
    }

    updateOffset(): Promise<number> {
        if (this.noNetwork) {
            return Promise.resolve().then(() => {
                this.offset = 0;
                this.offsetCalculated = true;
                return 0;
            });
        }
        const offsets: number[] = [];
        const handler = offset => offsets.push(offset);
        const getOffset = () => this.getSingleOffset().then(handler);
        let promise = getOffset();

        // 4 more times for good measure
        for (let x = 0; x < 4; ++x) {
            promise = promise.then(getOffset);
        }

        return promise.then(() => {
            this.offset = Math.round(this.median(offsets));
            this.offsetCalculated = true;
            return this.offset;
        });
    }

    getAdjustedTimestamp(): number {
        return new Date().getTime() + this.offset;
    }

    getAdjustedDate(): Date {
        return new Date(this.getAdjustedTimestamp());
    }

    getAdjustedMoment(): moment.Moment {
        return moment(this.getAdjustedTimestamp());
    }

    startTicks() {
        if (!this.offsetCalculated) {
            this.updateOffset();
        }
        this.scheduleTick();
    }

    stopTicks() {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
            this.timeoutId = null;
        }
    }

    private scheduleTick = () => {
        this.stopTicks();

        const adjustedNow = this.getAdjustedMoment();
        const next = adjustedNow
            .clone()
            .endOf('second')
            .add(1, 'millisecond');
        const delay = next.diff(adjustedNow);

        requestAnimationFrame(() => {
            this.timeoutId = setTimeout(() => {
                this.tick(next);

                this.scheduleTick();
            }, delay);
        });
    };

    tick = (adjustedNow: moment.Moment) => {
        const timestamp = adjustedNow.valueOf();
        const minutes = Math.round(timestamp / 1000) / 60;

        if (minutes % this.syncFrequencyMinutes === 0) {
            this.updateOffset();
        }

        this.handlers.forEach((handler: TickHandler) => {
            try {
                handler(adjustedNow, this.offset);
            } catch (e) {
                console.error(e);
            }
        });
    };

    handlersChanged() {
        if (this.handlers.length === 0) {
            this.stopTicks();
        } else {
            this.startTicks();
        }
    }

    onTick(handle: TickHandler) {
        if (this.handlers.indexOf(handle) === -1) {
            this.handlers.push(handle);
            this.handlersChanged();
        }
    }

    offTick(handle: TickHandler) {
        const index = this.handlers.indexOf(handle);
        if (index !== -1) {
            this.handlers.splice(index, 1);
            this.handlersChanged();
        }
    }
}
