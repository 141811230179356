import * as React from 'react';
import {
    headerTunables,
    opacityTunables,
    xAxisTunables,
    yAxisTunables,
    comboLegendTunables,
    gridTunable,
    themeTunables,
    smartSizeTunables,
} from '../../components/Sidebar/RenderTunables/SharedTunables';
import {
    CollapseProps,
    TunablesCollapse,
} from '../../components/Sidebar/RenderTunables/RenderTunables';
import {
    ComboChartWidgetOptions,
    ComboColumnOptionsImpl,
    ComboLineOptionsImpl,
    ComboScatterPlotOptionsImpl,
    ComboAreaOptionsImpl,
    ComboAreaOptions,
} from '../../widgets/ComboChartWidget/options';
import { ChartTypes, ComboLayer } from '@sprinklr/stories/widget/WidgetOptions';
import { Curves } from '../../components/_charts/ComboChart/Primitives/ComboCurves/ComboCurves';
import { Theme } from '../../models/Theme/Theme';
import ActionColorSwatchGroup from '../../components/_UI/Forms/Actions/ActionColorSwatchGroup';
import ActionSelectGroup from '../../components/_UI/Forms/Actions/ActionSelectGroup';
import { Flexbox, LabeledSelect, Collapse } from 'components/spaceKit';
import LabelGroup from '../../components/_UI/Forms/LabelGroup/LabelGroup';
import { action, toJS } from 'mobx';
import { Widget } from '@sprinklr/stories/widget/Widget';
import styled from 'components/_UI/designSystem';
import { getPx } from 'components/spaceKit';
import ActionCheckboxSliderColorSwatchGroup from '../../components/_UI/Forms/Actions/ActionCheckboxSliderColorSwatchGroup';
import { generateColorPalette } from '../../utils/GenerateColors/GenerateColors';
import { scrubber } from '../../utils/GenerateStyles/GenerateStyles';
import { ComboLineOptions } from 'components/_charts/ComboChart/Primitives/ComboLine/ComboLine';
import { ComboColumnOptions } from 'components/_charts/ComboChart/Primitives/ComboColumn/ComboColumn';
import { ComboScatterPlotOptions } from 'components/_charts/ComboChart/Primitives/ComboScatterPlot/ComboScatterPlot';
import {
    metricValueCaseOptions,
    metricValueOptions,
} from 'components/Panel/PanelEditorSidebar/PanelDesign/PanelDesignOptions';
import i18n from 'i18next';
import ActionSegmentedGroup from 'components/_UI/Forms/Actions/ActionSegmentedGroup';

import merge from 'deepmerge';
import ActionCheckboxGroup from 'components/_UI/Forms/Actions/ActionCheckboxGroup';
import ActionCheckboxSliderGroup from 'components/_UI/Forms/Actions/ActionCheckboxSliderGroup';
import ActionSliderGroup from 'components/_UI/Forms/Actions/ActionSliderGroup';

const StyledCollapse = styled(Collapse)`
    [data-id='collapse-content'] > ${Flexbox} {
        > * + * {
            margin-top: ${getPx(3)};
        }
    }
`;

export type CurveOptions = {
    value: Curves;
    label: string;
};

const curveOptions: CurveOptions[] = [
    {
        value: 'curveLinear',
        label: 'Linear',
    },
    {
        value: 'curveCardinal',
        label: 'Cardinal',
    },
    {
        value: 'curveStep',
        label: 'Step',
    },
    {
        value: 'curveMonotoneY',
        label: 'Monotone',
    },
];

export type ChartTypeOption = {
    value: ChartTypes;
    label: string;
};

const chartTypeOptions: ChartTypeOption[] = [
    {
        value: 'comboArea',
        label: 'Area Chart',
    },
    {
        value: 'comboLine',
        label: 'Line Chart',
    },
    {
        value: 'comboColumn',
        label: 'Column Chart',
    },
    {
        value: 'comboScatterPlot',
        label: 'Scatter Plot',
    },
];

const setChartType = action((selection, comboLayer: ComboLayer) => {
    const newType = selection.value;
    comboLayer.chartType = newType;
    switch (comboLayer.chartType) {
        case 'comboArea':
            (comboLayer.options as ComboAreaOptions) = new ComboAreaOptionsImpl();
            break;
        case 'comboLine':
            (comboLayer.options as ComboLineOptions) = new ComboLineOptionsImpl();
            break;
        case 'comboColumn':
            (comboLayer.options as ComboColumnOptions) = new ComboColumnOptionsImpl();
            break;
        case 'comboScatterPlot':
            (comboLayer.options as ComboScatterPlotOptions) = new ComboScatterPlotOptionsImpl();
            break;
    }
});

const typeSwitch = comboLayer => {
    return (
        <LabeledSelect
            label='Chart Type'
            key={comboLayer.chartType + comboLayer.displayName}
            value={comboLayer.chartType}
            onChange={b => setChartType(b, comboLayer)}
            options={chartTypeOptions}
        />
    );
};

const yAxisZeroStart = comboLayer => {
    return (
        <ActionCheckboxGroup
            key='widget.options.yAxisZeroStart'
            label={i18n.t('Start Y-Axis from Zero')}
            option={comboLayer.options}
            value='yAxisZeroStart'
        />
    );
};

const valueTunables = (comboLayer, mergedTheme, widget) => {
    const fallbackColor = !!widget.theme.typography.color
        ? widget.theme.typography.color
        : mergedTheme.typography.color;
    return (
        <>
            <ActionCheckboxSliderColorSwatchGroup
                key='widget.points.value'
                label='Point Value'
                showValue
                checkbox={{
                    option: comboLayer.options.points.value,
                    value: 'enabled',
                }}
                slider={{
                    option: comboLayer.options.points.value,
                    value: 'size',
                    min: 1,
                    max: 100,
                }}
                colorSwatch={{
                    option: null,
                    value: null,
                    onClear: action(() => (comboLayer.options.points.value.color = '')),
                    onChange: action(
                        ({ rgb: { r, g, b, a } }: any) =>
                            (comboLayer.options.points.value.color = `rgba(${r}, ${g}, ${b}, ${a})`)
                    ),
                    color: !!comboLayer.options.points.value.color
                        ? comboLayer.options.points.value.color
                        : fallbackColor,
                    secondaryIcon: !!comboLayer.options.points.value.color
                        ? 'icon-undo'
                        : undefined,
                }}
            />
            <ActionSegmentedGroup
                between
                key='widget.options.metricValueFormat'
                label={i18n.t('Format')}
                option={comboLayer.options.points.value}
                value={'format'}
                options={metricValueOptions}
                showOptionLabel
            />
            {comboLayer.options.points.value.case &&
                comboLayer.options.points.value.format !== '1,234' && (
                    <ActionSelectGroup
                        label={i18n.t('Unit Case')}
                        key='widget.options.metricValueCase'
                        options={metricValueCaseOptions}
                        option={comboLayer.options.points.value}
                        value='case'
                    />
                )}
        </>
    );
};

const markerTunables = (comboLayer, colors, index) => {
    return (
        <>
            <ActionCheckboxSliderColorSwatchGroup
                key='points.marker'
                label='Point Markers'
                showValue
                checkbox={{
                    option: comboLayer.options.points.marker,
                    value: 'enabled',
                }}
                slider={{
                    option: comboLayer.options.points.marker,
                    value: 'size',
                    min: 1,
                    max: 100,
                }}
                colorSwatch={{
                    option: null,
                    value: null,
                    onClear: action(() => (comboLayer.options.points.marker.color = '')),
                    onChange: action(
                        ({ rgb: { r, g, b, a } }: any) =>
                            (comboLayer.options.points.marker.color = `rgba(${r}, ${g}, ${b}, ${a})`)
                    ),
                    color: !!comboLayer.options.points.marker.color
                        ? comboLayer.options.points.marker.color
                        : colors[index],
                    secondaryIcon: !!comboLayer.options.points.marker.color
                        ? 'icon-undo'
                        : undefined,
                }}
            />
            {comboLayer.options.points.marker.enabled && (
                <ActionCheckboxSliderColorSwatchGroup
                    key='points.marker.stroke'
                    label='Marker Border'
                    showValue
                    checkbox={{
                        option: comboLayer.options.points.marker.stroke,
                        value: 'enabled',
                    }}
                    slider={{
                        option: comboLayer.options.points.marker.stroke,
                        value: 'size',
                        min: 1,
                        max: 100,
                    }}
                    colorSwatch={{
                        option: comboLayer.options.points.marker.stroke,
                        value: 'color',
                        color: comboLayer.options.points.marker.stroke.color,
                        onChange: action(
                            ({ rgb: { r, g, b, a } }) =>
                                (comboLayer.options.points.marker.stroke.color = `rgba(${r}, ${g}, ${b}, ${a})`)
                        ),
                    }}
                />
            )}
        </>
    );
};

const metricLayerTunables = (widget: Widget, mergedTheme: Theme) => {
    const comboLayers = (widget.options as ComboChartWidgetOptions).comboLayers;
    let columnCount = 0;
    return (
        <Flexbox gap='l' vertical key='metricLayerTunables'>
            {comboLayers &&
                widget.analyticsRequests[0].projections.map((metric, index) => {
                    const comboLayer = comboLayers.length && comboLayers[index];
                    if (comboLayer) {
                        if (comboLayer.chartType === 'comboColumn') {
                            columnCount++;
                        }
                        const merged = merge.all([mergedTheme, scrubber(toJS(widget.theme))], {});
                        const colors: string[] = generateColorPalette(
                            merged,
                            comboLayers.length,
                            false,
                            widget.theme
                        );
                        return (
                            <StyledCollapse
                                label={`${comboLayer.displayName}`}
                                key={`layer-${comboLayer.displayName}-${index}`}>
                                {comboLayer.chartType === 'comboArea' && (
                                    <>
                                        {typeSwitch(comboLayer)}
                                        <LabelGroup
                                            swatchSize='s'
                                            key='comboArea.fill.color'
                                            label='Area Fill'>
                                            <ActionColorSwatchGroup
                                                key='comboArea.comboLayer.options'
                                                value={null}
                                                option={null}
                                                color={
                                                    !!comboLayer.options.color
                                                        ? comboLayer.options.color
                                                        : colors[index]
                                                }
                                                onClear={action(
                                                    () => (comboLayer.options.color = '')
                                                )}
                                                onChange={action(
                                                    ({ rgb: { r, g, b, a } }: any) =>
                                                        (comboLayer.options.color = `rgba(${r}, ${g}, ${b}, ${a})`)
                                                )}
                                                secondaryIcon={
                                                    !!comboLayer.options.color
                                                        ? 'icon-undo'
                                                        : undefined
                                                }
                                            />
                                        </LabelGroup>
                                        <ActionSliderGroup
                                            key={'widget.options.combolayer.options.opacity'}
                                            label={i18n.t('Opacity')}
                                            option={comboLayer.options}
                                            value='opacity'
                                            showValue
                                            suffix='%'
                                            min={0}
                                            max={100}
                                        />
                                        <ActionSelectGroup
                                            label='Curves'
                                            key='comboArea.comboLayer.curve'
                                            option={comboLayer.options}
                                            value='curve'
                                            options={curveOptions}
                                        />
                                        {markerTunables(comboLayer, colors, index)}
                                        {valueTunables(comboLayer, mergedTheme, widget)}
                                        {yAxisZeroStart(comboLayer)}
                                    </>
                                )}
                                {comboLayer.chartType === 'comboLine' && (
                                    <>
                                        {typeSwitch(comboLayer)}
                                        <ActionCheckboxSliderColorSwatchGroup
                                            key='comboLine.stroke.size.color'
                                            label='Line'
                                            showValue
                                            checkbox={undefined}
                                            slider={{
                                                option: comboLayer.options.stroke,
                                                value: 'size',
                                                min: 1,
                                                max: 100,
                                            }}
                                            colorSwatch={{
                                                option: null,
                                                value: null,
                                                onClear: action(
                                                    () => (comboLayer.options.stroke.color = '')
                                                ),
                                                onChange: action(
                                                    ({ rgb: { r, g, b, a } }: any) =>
                                                        (comboLayer.options.stroke.color = `rgba(${r}, ${g}, ${b}, ${a})`)
                                                ),
                                                color: !!comboLayer.options.stroke.color
                                                    ? comboLayer.options.stroke.color
                                                    : colors[index],
                                                secondaryIcon: !!comboLayer.options.stroke.color
                                                    ? 'icon-undo'
                                                    : undefined,
                                            }}
                                        />
                                        <ActionSelectGroup
                                            label='Curves'
                                            key='comboLine.comboLayer.curve'
                                            option={comboLayer.options}
                                            value='curve'
                                            options={curveOptions}
                                        />
                                        {markerTunables(comboLayer, colors, index)}
                                        {valueTunables(comboLayer, mergedTheme, widget)}
                                        {yAxisZeroStart(comboLayer)}
                                    </>
                                )}
                                {comboLayer.chartType === 'comboColumn' && (
                                    <>
                                        {typeSwitch(comboLayer)}
                                        <LabelGroup
                                            swatchSize='s'
                                            key='comboColumn.fill'
                                            label='Column Fill'>
                                            <ActionColorSwatchGroup
                                                key='comboColumn.comboLayer.options'
                                                value={null}
                                                option={null}
                                                color={
                                                    !!comboLayer.options.color
                                                        ? comboLayer.options.color
                                                        : colors[index]
                                                }
                                                onClear={action(
                                                    () => (comboLayer.options.color = '')
                                                )}
                                                onChange={action(
                                                    ({ rgb: { r, g, b, a } }: any) =>
                                                        (comboLayer.options.color = `rgba(${r}, ${g}, ${b}, ${a})`)
                                                )}
                                                secondaryIcon={
                                                    !!comboLayer.options.color
                                                        ? 'icon-undo'
                                                        : undefined
                                                }
                                            />
                                        </LabelGroup>
                                        {columnCount < 2 &&
                                            valueTunables(comboLayer, mergedTheme, widget)}
                                        {yAxisZeroStart(comboLayer)}
                                    </>
                                )}
                                {comboLayer.chartType === 'comboScatterPlot' && (
                                    <>
                                        {typeSwitch(comboLayer)}
                                        {markerTunables(comboLayer, colors, index)}
                                        {valueTunables(comboLayer, mergedTheme, widget)}
                                        {yAxisZeroStart(comboLayer)}
                                    </>
                                )}
                            </StyledCollapse>
                        );
                    }
                })}
        </Flexbox>
    );
};

function multiDimensionTunables(widget: Widget): JSX.Element {
    const comboLayer = (widget.options as ComboChartWidgetOptions).comboLayers[0];

    return (
        <>
            {typeSwitch(comboLayer)}
            {comboLayer.options.points?.marker && (
                <ActionCheckboxSliderGroup
                    key='points.marker'
                    label={i18n.t('Point Markers')}
                    showValue
                    checkbox={{
                        option: comboLayer.options.points.marker,
                        value: 'enabled',
                    }}
                    slider={{
                        option: comboLayer.options.points.marker,
                        value: 'size',
                        min: 1,
                        max: 100,
                    }}
                />
            )}
            {comboLayer.options.points?.value && (
                <>
                    <ActionCheckboxSliderGroup
                        key='widget.points.value'
                        label={i18n.t('Point Value')}
                        showValue
                        checkbox={{
                            option: comboLayer.options.points.value,
                            value: 'enabled',
                        }}
                        slider={{
                            option: comboLayer.options.points.value,
                            value: 'size',
                            min: 1,
                            max: 100,
                        }}
                    />
                    <ActionSegmentedGroup
                        between
                        key='widget.options.metricValueFormat'
                        label={i18n.t('Format')}
                        option={comboLayer.options.points.value}
                        value={'format'}
                        options={metricValueOptions}
                        showOptionLabel
                    />
                    {widget.options.metricValueCase &&
                        widget.options.metricValueFormat &&
                        widget.options.metricValueFormat !== '1,234' && (
                            <ActionSelectGroup
                                label={i18n.t('Unit Case')}
                                key='widget.options.metricValueCase'
                                options={metricValueCaseOptions}
                                option={widget.options}
                                value='metricValueCase'
                            />
                        )}
                </>
            )}
            {comboLayer.options.opacity != null && (
                <ActionSliderGroup
                    key={'widget.options.combolayer.options.opacity'}
                    label={i18n.t('Opacity')}
                    option={comboLayer.options}
                    value='opacity'
                    showValue
                    suffix='%'
                    min={0}
                    max={100}
                />
            )}
            {yAxisZeroStart(comboLayer)}
        </>
    );
}

export const ComboChartTunables = (widget: Widget, mergedTheme: Theme): TunablesCollapse[] => {
    const multiDimension = widget.analyticsRequests?.[0]?.groupBys?.length > 1;

    return [
        {
            props: {
                label: 'Tunables',
                startOpen: true,
            } as CollapseProps,
            children: [
                ...headerTunables(widget),
                opacityTunables(widget),
                smartSizeTunables(widget),
                gridTunable(widget),
                <ActionCheckboxGroup
                    key='widget.options.zeroFill'
                    label={i18n.t('Zero Fill')}
                    option={widget.options}
                    value={'zeroFill'}
                />,
            ],
        },
        {
            props: {
                label: 'Charts',
                startOpen: true,
            } as CollapseProps,
            children: [
                multiDimension
                    ? multiDimensionTunables(widget)
                    : metricLayerTunables(widget, mergedTheme),
            ],
        },
        yAxisTunables(widget),
        xAxisTunables({ widget, padding: false }),
        themeTunables(widget, { color: true, image: true, email: true, imageSize: true }),
        comboLegendTunables(widget),
    ];
};

export default ComboChartTunables;
