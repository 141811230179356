import React from 'react';

// types
import { AdditionalDetailProps } from './types';
import Metric from 'components/_charts/Metric/Metric';
import { getCurrencyString } from 'utils/Currency/Currency';
import { FieldDataType } from '@sprinklr/stories/reporting/types';
import moment from 'moment';

const DATE_HEADINGS = [
    'Created Time_1d',
    'Created Time_1h',
    'Date',
    'date',
    'Published_Date',
    'SN_CREATED_TIME',
    'CREATED_DATE',
    'measurementTime',
];

function AdditionalDetail({ field, dateFormat, format }: AdditionalDetailProps) {
    const isDate = DATE_HEADINGS.includes(field.heading);
    const isNumber = typeof field.value === 'number';
    const formattedDate = isDate && moment(new Date(parseInt(field.value, 10))).format(dateFormat);

    return (
        <>
            {!isDate && isNumber && (
                <Metric
                    isPercentage={
                        field.dataType === 'PERCENTAGE' || field.dataType === 'PERCENTAGE_CHANGE'
                    }
                    countUp={false}
                    metricClasses='extra_post_data_value'
                    layoutType='valueOnly'
                    alignment='left'
                    value={Number(field.value)}
                    isPrettyNumber={format !== '1,234'}
                    valueFormat={format}
                    valuePrefix={getCurrencyString(field.dataType as FieldDataType)}
                />
            )}
            {isDate && <div className='extra_post_data_value ta-r w-100'>{formattedDate}</div>}
            {!isDate && !isNumber && (
                <div className='extra_post_data_value ta-r w-100'>
                    {field.value instanceof Array ? field.value.join(', ') : field.value}
                </div>
            )}
        </>
    );
}

export { AdditionalDetail };
