import * as React from 'react';
import i18n from 'i18next';
import { action, extendObservable } from 'mobx';
import {
    borderRadiusTunable,
    centerAxis,
    handleExtras,
    headerTunables,
    labelTunables,
    legendTunables,
    metricValueTunables,
    opacityTunables,
    percentChangeTunables,
    sizingTunables,
    smartSizeTunables,
    textFormatTunables,
    themeTunables,
    xAxisTunables,
    yAxisTunables,
} from 'components/Sidebar/RenderTunables/SharedTunables';
import { CollapseProps, TunablesCollapse } from 'components/Sidebar/RenderTunables/RenderTunables';
import {
    horizontalAlignOptions,
    labelDateOptions,
    metricValueOptions,
    stackedBarTypeOptions,
    stackedColumnTypeOptions,
} from 'components/Panel/PanelEditorSidebar/PanelDesign/PanelDesignOptions';
import { StackedBarChartWidgetOptions, StackedBarChartWidgetOptionsImpl } from './options';
import { Widget } from '@sprinklr/stories/widget/Widget';
import ActionSelectGroup from 'components/_UI/Forms/Actions/ActionSelectGroup';
import ActionSegmentedGroup from 'components/_UI/Forms/Actions/ActionSegmentedGroup';
import ActionCheckboxGroup from 'components/_UI/Forms/Actions/ActionCheckboxGroup';
import ActionCheckboxSliderGroup from 'components/_UI/Forms/Actions/ActionCheckboxSliderGroup';
import { WidgetStackedBarChart } from '../../stores/PanelTemplatesStore/WidgetStackedBarChart';
import { widgetDimIsDate } from 'utils/Widget/WidgetUtils';
import { Theme } from 'models/Theme/Theme';
import ActionSliderGroup from 'components/_UI/Forms/Actions/ActionSliderGroup';

export type TunableExtraChanges = {
    [key: string]: {
        [key: string]: boolean | number | string | object;
    };
};

const extraChangesBar: TunableExtraChanges = {
    a: {
        stacked100Percent: true,
        stackedInline: true,
        xAxis: {
            enabled: false,
            label: {
                enabled: true,
                size: 50,
                padding: 0,
            },
            ticks: {
                enabled: true,
                label: {
                    enabled: false,
                    size: 50,
                    numberFormat: '1.2k',
                    timeFormat: 'ddd ha',
                },
                line: {
                    enabled: true,
                },
            },
        },
    },
    b: {
        stacked100Percent: false,
        stackedInline: true,
        xAxis: {
            enabled: true,
            label: {
                enabled: false,
                size: 50,
                padding: 0,
            },
            ticks: {
                enabled: true,
                label: {
                    enabled: true,
                    size: 50,
                    numberFormat: '1.2k',
                    timeFormat: 'ddd ha',
                },
                line: {
                    enabled: false,
                },
            },
        },
    },
    c: {
        stacked100Percent: false,
        stackedInline: false,
        xAxis: {
            enabled: true,
            label: {
                enabled: false,
                size: 50,
                padding: 0,
            },
            ticks: {
                enabled: true,
                label: {
                    enabled: true,
                    size: 50,
                    numberFormat: '1.2k',
                    timeFormat: 'ddd ha',
                },
                line: {
                    enabled: false,
                },
            },
        },
    },
};

const extraChangesColumn: TunableExtraChanges = {
    a: {
        stacked100Percent: true,
        stackedInline: true,
        yAxis: {
            enabled: false,
            label: {
                enabled: true,
                size: 50,
                padding: 0,
            },
            ticks: {
                enabled: true,
                label: {
                    enabled: false,
                    size: 50,
                    numberFormat: '1.2k',
                    timeFormat: 'ddd ha',
                },
                line: {
                    enabled: true,
                },
            },
        },
    },
    b: {
        stacked100Percent: false,
        stackedInline: true,
        yAxis: {
            enabled: true,
            label: {
                enabled: false,
                size: 50,
                padding: 0,
            },
            ticks: {
                enabled: true,
                label: {
                    enabled: true,
                    size: 50,
                    numberFormat: '1.2k',
                    timeFormat: 'ddd ha',
                },
                line: {
                    enabled: false,
                },
            },
        },
    },
    c: {
        stacked100Percent: false,
        stackedInline: false,
        yAxis: {
            enabled: true,
            label: {
                enabled: false,
                size: 50,
                padding: 0,
            },
            ticks: {
                enabled: true,
                label: {
                    enabled: true,
                    size: 50,
                    numberFormat: '1.2k',
                    timeFormat: 'ddd ha',
                },
                line: {
                    enabled: false,
                },
            },
        },
    },
};

export const StackedBarChartTunables = (widget: Widget, mergedTheme: Theme): TunablesCollapse[] => {
    const firstDimCheck: boolean = widgetDimIsDate(widget);
    const secondDimCheck: boolean = widgetDimIsDate(widget, 1);

    const widgetOptions = widget.options as StackedBarChartWidgetOptionsImpl &
        StackedBarChartWidgetOptions;

    const { orientation, stacked100Percent } = widgetOptions;

    const isColumn = orientation === 'horizontal';
    const supportsAxisLabel = widget?.analyticsRequests?.[0]?.projections.length === 1;
    const metricAxisEnabled =
        (!isColumn && widgetOptions.xAxis.enabled) || (isColumn && widgetOptions.yAxis.enabled);

    return [
        {
            props: {
                label:
                    widget.options.orientation !== 'horizontal'
                        ? i18n.t('Stacked Bar Chart')
                        : i18n.t('Stacked Column Chart'),
                startOpen: true,
            } as CollapseProps,
            children: [
                ...headerTunables(widget),
                opacityTunables(widget),
                borderRadiusTunable(widget),
                smartSizeTunables(widget),
            ],
        },
        {
            props: {
                label: i18n.t('Label'),
                startOpen: true,
            } as CollapseProps,
            children: [
                labelTunables(widget),
                !isColumn && (
                    <ActionSegmentedGroup
                        key='widget.options.barLabelAlignment'
                        label={i18n.t('Label Align')}
                        option={widget.options}
                        value={'barLabelAlignment'}
                        options={horizontalAlignOptions}
                    />
                ),
                firstDimCheck && (
                    <ActionSelectGroup
                        key='widget.options.labelDateFormat'
                        label={i18n.t('Label Date Format')}
                        option={widget.options}
                        value='labelDateFormat'
                        options={labelDateOptions}
                    />
                ),
                ...textFormatTunables(widget, { clamp: true }),
                ...metricValueTunables(widget, { value: { enable: true }, format: true }),
                widgetOptions.showMetricValue && (
                    <React.Fragment key='widget.options.metricValueSize'>
                        <ActionSliderGroup
                            key='widget.options.metricValueOpacity'
                            label={i18n.t('Metric Opacity')}
                            option={widget.options}
                            value='metricValueOpacity'
                            showValue
                            min={0}
                            max={100}
                        />
                        <ActionCheckboxSliderGroup
                            key='widget.options.metricValueSize'
                            label={i18n.t('Metric Size')}
                            showValue
                            checkbox={{
                                option: widget.options,
                                value: 'metricValueSizeEnabled',
                            }}
                            slider={{
                                option: widget.options,
                                value: 'metricValueSize',
                                min: 0,
                                max: 100,
                            }}
                        />
                        <ActionCheckboxGroup
                            key='widget.options.showSegmentRatio'
                            label={i18n.t('Show Segment as Percentage')}
                            option={widget.options}
                            value='showSegmentRatio'
                        />
                    </React.Fragment>
                ),
                ...percentChangeTunables(widget),
                !stacked100Percent && (
                    <ActionCheckboxGroup
                        key='widget.options.showZeroSegments'
                        label={i18n.t('Enforce Min Segment Size')}
                        option={widget.options}
                        value='showZeroSegments'
                    />
                ),
            ],
        },
        legendTunables(
            widget,
            {
                legendDateFormat: secondDimCheck,
                orientation: true,
                orientationOpts: ['top', 'bottom', 'right', 'left'],
                labelSize: !!widgetOptions.legendNameSize || widgetOptions.legendNameSize === 0,
                wrap:
                    widgetOptions.legendWrap !== undefined &&
                    widgetOptions.legendOrientation === 'bottom',
            },
            () => handleSideEffects(widget)
        ),
        !isColumn &&
            xAxisTunables({
                widget,
                padding: false,
                allPadding: false,
                ticks: !stacked100Percent,
                line: true,
                numTicks: true,
                label: supportsAxisLabel,
            }),
        !isColumn && yAxisTunables(widget, false, true, false, supportsAxisLabel),

        isColumn &&
            xAxisTunables({
                widget,
                padding: false,
                allPadding: false,
                line: false,
                numTicks: false,
                label: supportsAxisLabel,
                tickLabelName: 'Column Label',
            }),
        isColumn && yAxisTunables(widget, !stacked100Percent, true, true, supportsAxisLabel),

        themeTunables(widget, { color: true, email: true }),
        sizingTunables(widget, { spacing: { max: 25 } }),
        {
            props: {
                label: i18n.t('Tunables'),
                startOpen: true,
            } as CollapseProps,
            children: [
                <ActionSegmentedGroup
                    key='widget.options.stackedType'
                    label={i18n.t('Stack Type')}
                    option={widget.options}
                    value={'stackedType'}
                    handleSideEffects={selection =>
                        handleExtras(
                            selection,
                            widget.options as any,
                            isColumn ? extraChangesColumn : extraChangesBar
                        )
                    }
                    options={
                        widgetOptions.orientation === 'vertical'
                            ? stackedBarTypeOptions
                            : stackedColumnTypeOptions
                    }
                />,
                !metricAxisEnabled && typeof (widgetOptions as any).showYAxis !== 'undefined' && (
                    <React.Fragment key='widget.options.showYAxis'>
                        <ActionCheckboxGroup
                            key='widget.options.showYAxis'
                            label={i18n.t('Show Y Axis')}
                            option={widget.options}
                            value='showYAxis'
                        />
                        {(widgetOptions as any).showYAxis && (
                            <ActionSegmentedGroup
                                between
                                key='widget.options.yAxisValueFormat'
                                label={i18n.t('Format')}
                                option={widget.options}
                                value={'yAxisValueFormat'}
                                options={metricValueOptions}
                                showOptionLabel
                            />
                        )}
                    </React.Fragment>
                ),
                centerAxis(widget, mergedTheme),
            ],
        },
    ];
};

// this is like a legend impl tied to the legend collapse turning on
// if we have more cases like this we can make this more generic
const handleSideEffects = action((widget: Widget): void => {
    if (
        !widget.options.hasOwnProperty('legendNameSize') &&
        !widget.options.hasOwnProperty('legendWrap')
    ) {
        extendObservable(widget.options, {
            legendNameSize: WidgetStackedBarChart.options.legendNameSize,
            legendWrap: WidgetStackedBarChart.options.legendWrap,
        });
    }
});

export default StackedBarChartTunables;
