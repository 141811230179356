import BulkItem from './BulkItem';

export default class TemporalDimension extends BulkItem {
    private readonly dimensionName: string = null;

    public static sortOrders: any = {
        MONTH_OF_YEAR: [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
        ],
        DAY_OF_WEEK: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        TIME_OF_DAY: [
            '00:00',
            '01:00',
            '02:00',
            '03:00',
            '04:00',
            '05:00',
            '06:00',
            '07:00',
            '08:00',
            '09:00',
            '10:00',
            '11:00',
            '12:00',
            '13:00',
            '14:00',
            '15:00',
            '16:00',
            '17:00',
            '18:00',
            '19:00',
            '20:00',
            '21:00',
            '22:00',
            '23:00',
        ],
    };

    constructor(id: string, name: string, dimensionName: string) {
        super(id, name);

        this.dimensionName = dimensionName;
    }

    toString(): string {
        return this.name;
    }

    get sortValue(): any {
        if (TemporalDimension.sortOrders[this.dimensionName]) {
            return '' + TemporalDimension.sortOrders[this.dimensionName].indexOf(this.name);
        }
        return this.name;
    }
}
