import { Panel } from '../../models/Panel/Panel';
import { generateID } from './PanelTemplatesTheme/helpers';


export const TemplateClosingSlide: Panel = {
    id: 'closing-slide',
    name: 'Closing Slide',
    description: '',
    tags: ['Editorial', 'image', 'landscape', 'portrait', 'interstitial'],
    widget: {
        id: generateID(),
        position: {
            width: '1920px',
            height: '1080px',
            top: '0',
            left: '0',
        },
        classes: 'template_custom',
        label: {
            titleText: 'Panel header',
            enabled: false,
        },
        children: [
            {
                children: null,
                id: generateID(),
                name: 'Image',
                type: 'customContentImage',
                css: null,
                js: null,
                options: {
                    player: {
                        playing: false,
                        duration: 15,
                    },
                    labelEnable: false,
                    labelTimeRangeSize: 16,
                    labelTimeRangeEnabled: false,
                    labelTimeRangeFormat: 'Pretty Time',
                    animationLength: 1000,
                    animationDelay: 0,
                    animationType: 'fade',
                    animationEnabled: false,
                    animationVariation: 6,
                    widgetOrientation: 'landscape',
                    opacity: 100,
                    zIndex: 0,
                    locked: false,
                    sort: 'auto',
                    percentChangeFormat: '1k',
                    fontFamilySecondary: null,
                    fontFamilyPrimary: null,
                    fontSize: null,
                    colorFont: null,
                    showSocialColors: true,
                    metricThreshold: null,
                    showFullTimePeriod: false,
                    useThemeColor: false,
                    useThemeImage: false,
                    useThemeEmail: false,
                    themeEmailSize: 20,
                    themeImagePosition: null,
                    themeImageOpacity: 100,
                    themeImageCircleMask: true,
                    themeImageSize: 100,
                    barChartScale: 100,
                    classOverrides: {},
                    postVersion: 1,
                    zeroFill: false,
                    zeroKill: false,
                    autoSizeWords: false,
                    invertDataSet: false,
                    showAdsMetrics: false,
                    contentPadding: 0,
                    zoom: 50,
                    offsetX: 0,
                    offsetY: 0,
                    zoomClipping: false,
                    contentSocialPostUrl: '',
                    contentSocialPostUrls: [],
                    contentImageUrl:
                        'https://thumb.sprinklr.com/?d=1920x1080&url=https://sprcdn-assets.sprinklr.com/349/f36ddf0f-00b0-4bde-8c2c-782193be7535-2142407636/splash-black_p.png',
                    contentImageFit: 'contain',
                    contentVideoUrl: '',
                    contentIframeUrl: '',
                    contentTextAlign: 'left',
                    verticalAlign: 'middle',
                    horizontalAlign: 'center',
                    imageFilterBlur: false,
                    imageFilterBrightness: false,
                    imageFilterContrast: false,
                    imageFilterGrayscale: false,
                    imageFilterSaturate: false,
                    imageFilterHueRotate: false,
                    imageFilterOpacity: false,
                    imageFilterInvert: false,
                    imageFilterSepia: false,
                    imageFilterBlurValue: 0,
                    imageFilterBrightnessValue: 100,
                    imageFilterContrastValue: 100,
                    imageFilterGrayscaleValue: 0,
                    imageFilterSaturateValue: 100,
                    imageFilterHueRotateValue: 0,
                    imageFilterOpacityValue: 100,
                    imageFilterInvertValue: 0,
                    imageFilterSepiaValue: 0,
                    imageBlendMode: '',
                    imageSecondaryBlendMode: '',
                    imageBlendColor: null,
                    imageSecondaryBlendColor: '',
                    presetImageFilters: '',
                },
                postRequests: null,
                classes: null,
                theme: null,
                position: {
                    left: '37.5%',
                    right: null,
                    top: '37.5%',
                    bottom: null,
                    width: '426.6px',
                    height: '216.6px',
                    portrait: null,
                    landscape: null,
                    backgroundColor: null,
                    display: null,
                    maxWidth: null,
                    maxHeight: null,
                },
                analyticsRequests: null,
                analyticsResults: null,
                profileRequests: null,
                profileResults: null,
                trendRequests: null,
                label: {
                    titleText: 'Header',
                    subTitleText: null,
                    classes: null,
                    overlap: false,
                    enabled: false,
                    size: 20,
                },
            },
        ],
    },
};
