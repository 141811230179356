import { generateID } from './PanelTemplatesTheme/helpers';
import { WIDGET_LABEL_SIZE } from '../../components/Widget/WidgetLabel/constants';
import { Panel } from '../../models/Panel/Panel';

export var TemplateEditorial1: Panel = {
    id: 'editorial-1',
    name: 'Blank',
    description: '',
    tags: ['Editorial', 'blank', 'landscape', 'portrait', 'interstitial'],
    widget: {
        id: generateID(),
        position: {
            width: '1920px',
            height: '1080px',
            top: '0',
            left: '0',
        },
        classes: 'template_custom',
        label: {
            titleText: 'Panel',
            enabled: false,
            size: WIDGET_LABEL_SIZE.primary,
        },
        children: [],
    },
};
