import { PostSource, Sort } from './PanelTemplatesTheme/PanelTemplatesTheme';
import { DefaultWidgetPosition } from './PanelTemplatesTheme/constants';
import { Widget } from '@sprinklr/stories/widget/Widget';
import { WIDGET_LABEL_SIZE } from 'components/Widget/WidgetLabel/constants';
import { postCompositionConfigWaterfall } from 'components/Panel/PanelEditorSidebar/PanelDesign/PanelDesignOptions';
import { TEXT_SHADOW_DEFAULT } from 'models/Widget/Widget';

export var WidgetWaterfall: Widget = {
    type: 'postsWaterfall',
    id: 'waterfall',
    name: 'Waterfall',
    useGlobalTimePeriod: true,
    postRequests: [
        {
            sources: [
                {
                    type: 'posts',
                    // Note: All global variables like reportingEngine, report, and filters
                    // are injected via PostRequest.getRequests()
                    id: new PostSource({
                        pageSize: 50,
                        sorts: [
                            new Sort({
                                order: 'DESC',
                                heading: {
                                    MONITORING_DASHBOARD: 'REACH_COUNT',
                                    LISTENING: 'REACH_COUNT',
                                    INBOUND_MESSAGE: 'INBOUND_COUNT',
                                    PLATFORM: 'TOTAL_ENGAGEMENT',
                                    BENCHMARKING: 'TOTAL_ENGAGEMENT',
                                    RDB_FIREHOSE: 'SN_CREATED_TIME',
                                    STORY_MESSAGE: 'IMPACT',
                                },
                            }),
                        ],
                        includeComments: false,
                        timePeriod: {
                            key: 'last_7_days',
                        },
                    }) as any,
                    options: {
                        count: 200,
                        imagePreload: true,
                        removeDuplicates: true,
                        shufflePosts: false,
                        removeSensitivePosts: true,
                        includeQuoteTweets: false,
                    },
                },
            ],
            format: {
                type: 'ordered',
                options: {
                    fixedList: 25,
                    loop: true,
                },
            },
        },
    ],
    position: {
        ...DefaultWidgetPosition,
        portrait: {
            top: '25%',
            left: '5%',
            height: '50%',
            width: '90%',
        },
    },
    options: Object.assign(
        {
            player: {
                playing: true,
                duration: 6,
            },
            postVersion: 2,
            smartSize: {
                enabled: true,
                mode: 'responsive',
                size: 'm',
            },
        },
        postCompositionConfigWaterfall.a
    ),
    label: {
        titleText: 'Waterfall',
        enabled: false,
        size: WIDGET_LABEL_SIZE.primary,
        textShadow: TEXT_SHADOW_DEFAULT,
    },
};
