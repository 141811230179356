import * as React from 'react';
import i18n from 'i18next';
import {
    headerTunables,
    opacityTunables,
    blackListTunables,
    smartSizeTunables,
} from 'components/Sidebar/RenderTunables/SharedTunables';
import { CollapseProps, TunablesCollapse } from 'components/Sidebar/RenderTunables/RenderTunables';
import { Widget } from '@sprinklr/stories/widget/Widget';
import LabelGroup from 'components/_UI/Forms/LabelGroup/LabelGroup';
import { emojiPlatformOptions } from 'components/Panel/PanelEditorSidebar/PanelDesign/PanelDesignOptions';
import ActionSelectGroup from 'components/_UI/Forms/Actions/ActionSelectGroup';
import { widgetDimIsEmoji } from 'utils/Widget/WidgetUtils';
const ActionSliderGroup = require('components/_UI/Forms/Actions/ActionSliderGroup').default;

const WordCloudTunables = (widget: Widget): TunablesCollapse[] => [
    {
        props: {
            label: i18n.t('Word Cloud'),
            startOpen: true,
        } as CollapseProps,
        children: [
            ...headerTunables(widget),
            opacityTunables(widget),
            smartSizeTunables(widget),
            widgetDimIsEmoji(widget) && (
                <LabelGroup label='Emoji Set' key='widget.emoji'>
                    <ActionSelectGroup
                        key='widget.emoji.platform'
                        options={emojiPlatformOptions}
                        option={widget.options?.emoji}
                        value='platform'
                    />
                </LabelGroup>
            ),
        ],
    },
    {
        props: {
            label: i18n.t('Sizing'),
            startOpen: true,
        } as CollapseProps,
        children: [
            <ActionSliderGroup
                key='widget.options.minFontSize'
                label={i18n.t('Min Word Size')}
                option={widget.options}
                value='minFontSize'
                showValue
                min={0}
                max={200}
            />,
            <ActionSliderGroup
                key='widget.options.maxFontSize'
                label={i18n.t('Max Word Size')}
                option={widget.options}
                value='maxFontSize'
                showValue
                min={0}
                max={300}
            />,
            <ActionSliderGroup
                key='widget.options.spacing'
                label={i18n.t('Spacing')}
                option={widget.options}
                value='spacing'
                showValue
                min={0}
                max={50}
            />,
        ],
    },
    {
        props: {
            label: i18n.t('Exclude List'),
            startOpen: false,
        } as CollapseProps,
        children: [blackListTunables(widget)],
    },
];

export default WordCloudTunables;
