import { AxiosInstance, AxiosRequestConfig } from 'axios';
import AuthService from '../Auth/AuthService';
import { action, observable } from 'mobx';

const querystring = require('querystring');

/**
 * Created by dstelter on 12/28/16.
 */

export interface DisplayTokenResponse {
    payloadUrl: string;
    token: string;
    urlExpires: number;
    tokenExpires: number;
    ok: boolean;
    status:
        | 'SUCCESS'
        | 'AUTHENTICATION_REQUIRED'
        | 'AUTHENTICATION_REQUIRES_REDIRECT'
        | 'INVALID_REQUEST_FOR_TOKEN'
        | 'TOKEN_EXPIRED'
        | 'AUTHENTICATION_REQUIRES_PASSWORD'
        | string; // or, like, whatever
    message: string;
    redirectUrl?: string;
}

export interface DisplayAuthQueryParams {
    ssoToken?: string;
    hmacSignature?: string;
    signatureExpires?: string | number;
}

export class DisplayTokenService {
    protected storedPath: string;
    protected authorizePromise: Promise<any>;
    protected ephemeralToken;

    @observable passwordRequired = false;
    @observable passwordEndpoint: string;
    @observable passwordEntered = false;

    constructor(private ax: AxiosInstance, private auth: AuthService) {}

    public authorize(
        clientId: number | string,
        locationId: string,
        screenId: number | string,
        authQuery?: DisplayAuthQueryParams
    ): Promise<any> {
        const now = new Date();
        const path = this.keyPath(clientId, locationId, screenId);
        const { ssoToken, hmacSignature, signatureExpires } = authQuery || {};
        this.storedPath = path;

        const stored: DisplayTokenResponse = this.storedToken(path);
        let requestOpts: AxiosRequestConfig = {
            method: 'GET',
        };

        if (stored && stored.payloadUrl && stored.ok) {
            // console.debug("have stored token...");
            if (stored.urlExpires > now.getTime() + 3600 * 1000) {
                return Promise.resolve(stored.payloadUrl);
            }

            // need fresh URL...
            requestOpts = {
                headers: {
                    'X-Spr-Display-Token': stored.token,
                },
            };
        }

        // if we've got it, include the SsoToken param
        if (ssoToken) {
            requestOpts.params = {
                SsoToken: ssoToken,
            };
        }

        // if there's an HMAC sig, the back end will need the original signed URL for verification.
        if (hmacSignature) {
            requestOpts.url = `${path}?signedUrl=${encodeURIComponent((window as any).location)}`;
        } else {
            requestOpts.url = path;
        }

        this.authorizePromise = this.generateAuthorizePromise(requestOpts, path).then(
            resp => resp.data.payloadUrl
        );

        return this.authorizePromise;
    }

    public authorizePresentation(
        clientId: number | string,
        storyboardId: string,
        password: string
    ): Promise<any> {
        const now = new Date();
        const path = this.presentPath(clientId, storyboardId);
        this.storedPath = path;

        const stored: DisplayTokenResponse = this.storedToken(path);
        let requestOpts: AxiosRequestConfig = {
            method: 'GET',
        };

        if (stored && stored.payloadUrl && stored.ok) {
            // console.debug("have stored token...");
            if (stored.urlExpires > now.getTime() + 3600 * 1000) {
                return Promise.resolve(stored.payloadUrl);
            }

            // need fresh URL...
            requestOpts = {
                headers: {
                    'X-Spr-Display-Token': stored.token,
                },
            };
        }

        // Access control via password.
        if (password) {
            const data = { password };
            requestOpts.method = 'POST';
            requestOpts.headers = { 'content-type': 'application/x-www-form-urlencoded' };
            requestOpts.data = querystring.stringify(data);
        }

        requestOpts.url = path;

        this.authorizePromise = this.generateAuthorizePromise(requestOpts, path, password);

        return this.authorizePromise;
    }

    protected generateAuthorizePromise(
        requestOpts: any,
        path: string,
        password?: string
    ): Promise<any> {
        return this.ax.request(requestOpts).then(
            action((response: any) => {
                if (response.data.ok) {
                    // Do not populate localstorage if a password was used.
                    if (password) {
                        this.passwordEntered = true;
                        this.ephemeralToken = response.data;
                    } else {
                        window.localStorage.setItem(path, JSON.stringify(response.data));
                        // console.log("Setting localStorage item ", path, response.data);
                    }

                    // Look for the SsoToken in the URL bar - if it's there, hide it. Mostly to avoid user confusion.
                    if (
                        window.location.search &&
                        window.location.search.indexOf('SsoToken=') !== -1
                    ) {
                        if (window.history) {
                            const newUrl = window.location.toString().replace(/\?.*/, '');
                            window.history.pushState({}, 'Sprinklr Display', newUrl);
                        }
                    }

                    return response;
                } else {
                    console.error('Non-ok response for ', path, response.data);
                    const resp: DisplayTokenResponse = response.data;

                    if (resp.status === 'AUTHENTICATION_REQUIRED') {
                        console.debug('Will go to login page');
                        this.auth.goToLoginPage(window.location + '');
                        return Promise.reject(resp.message);
                    }

                    if (resp.status === 'AUTHENTICATION_REQUIRES_REDIRECT') {
                        // we're being told where to go to perform authentication e.g. external SSO
                        if (resp.redirectUrl) {
                            const destination =
                                resp.redirectUrl +
                                '?returnTo=' +
                                encodeURIComponent(window.location.href);
                            console.debug('auth requires redirect, will go to ' + destination);

                            window.location.href = destination;
                            return Promise.reject(resp);
                        } else {
                            console.error('No redirectUrl present', resp);
                        }
                    }

                    if (resp.status === 'AUTHENTICATION_REQUIRES_PASSWORD') {
                        // Prompt user to post a password to the redirect url.
                        this.passwordRequired = true;
                        this.passwordEntered = false;
                        this.passwordEndpoint = resp.redirectUrl;
                        return Promise.reject(resp);
                    }

                    if (resp.status === 'PASSWORD_INCORRECT') {
                        this.passwordRequired = true;
                        this.passwordEntered = false;
                        return Promise.reject(resp);
                    }

                    if (resp.status === 'IP_RESTRICTED' || resp.status === 'FAILURE') {
                        return Promise.reject(resp);
                    }

                    return Promise.reject(resp.message);
                }
            })
        );
    }

    protected keyPath(
        clientId: number | string,
        locationId: string,
        screenId: number | string
    ): string {
        return `/authorize/${clientId}/${locationId}/${screenId}`;
    }

    protected presentPath(clientId: number | string, storyboardId: string): string {
        return `/present/${clientId}/${storyboardId}`;
    }

    private storedToken(path?: string): DisplayTokenResponse {
        // Check for non-localstorage version of auth token
        if (this.ephemeralToken) {
            return this.ephemeralToken;
        }

        // console.debug("Checking for stored token at ", path);
        if (!path) {
            return JSON.parse(window.localStorage.getItem(this.storedPath)) as DisplayTokenResponse;
        }
        return JSON.parse(window.localStorage.getItem(path)) as DisplayTokenResponse;
    }

    loadToken = (): Promise<any> => {
        const stored = this.storedToken();
        if (stored && stored.ok) {
            return Promise.resolve(stored.token);
        }

        if (this.authorizePromise) {
            return this.authorizePromise.then(() => this.storedToken().token);
        } else {
            return Promise.reject('No stored token and no pending authorize call');
        }
    };

    public payloadUrl(
        clientId: number | string,
        locationId: string,
        screenId: number | string
    ): string {
        const item: DisplayTokenResponse = JSON.parse(
            window.localStorage.getItem(this.keyPath(clientId, locationId, screenId))
        );

        return item.payloadUrl;
    }
}
