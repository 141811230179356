import { Widget } from '@sprinklr/stories/widget/Widget';
import { TEXT_SHADOW_DEFAULT } from 'models/Widget/Widget';
import { WIDGET_LABEL_SIZE } from '../../components/Widget/WidgetLabel/constants';

export var WidgetShape: Widget = {
    type: 'shape',
    id: 'shape',
    name: 'Shape',
    position: {
        width: '400px',
        height: '400px',
        top: '30%',
        left: '40%',
    },
    label: {
        titleText: 'Shape',
        enabled: false,
        size: WIDGET_LABEL_SIZE.primary,
        textShadow: TEXT_SHADOW_DEFAULT,
    },
    options: {
        player: {
            playing: true,
            duration: 15,
        },
    },
};
