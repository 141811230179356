import classNames from 'classnames';
import * as React from 'react';
import { observer } from 'mobx-react';
import { Post as PostModel } from '@sprinklr/stories/post/Post';
import {
    BlastDelimiterTypes,
    ClassOverrides,
    Emoji,
    ExtraPostDataOptions,
    Orientation,
    PanelOrientation,
    PositionTypesInterface,
    PostCompositionTypeValues,
    PostLayoutTypes,
    PostTextFormat,
    TimeStampOptions,
    TitleOptions,
    UserGroupLayoutTypes,
} from '@sprinklr/stories/widget/types';
import {
    GenerateLabelClasses,
    GenerateSafeString,
    purifyString,
    titleCase,
} from 'utils/StringUtils/StringUtils';
import CornerTear from '../CornerTear/CornerTear';
import EngagementMetrics from '../EngagementMetrics/EngagementMetrics';
import PostImage from '../PostImage/PostImage';
import PostText from '../PostText/PostText';
import UserGroup from '../UserGroup/UserGroup';
import Video from '../Video/Video';
import PostComment, { CommentsOptions } from './PostComment';
import { WidgetType } from 'models/Widget/WidgetType';
import TimeStamp from '../TimeStamp/TimeStamp';
import MetaGroup from '../MetaGroup/MetaGroup';
import Icon from '../Icon/Icon';
import { PlayerInterval } from 'utils/PlayerInterval/PlayerInterval';
import Metric from '../_charts/Metric/Metric';
import AdsMetrics from '../AdsMetrics/AdsMetrics';
import { BoundingBox } from 'models/Intuition/Intuition';
import NativePostWrapper from '../NativePost/NativePostWrapper';
import {
    NativeStylingOptions,
    ProfanityFilter,
    RetweetIndicatorOptions,
} from '../../widgets/PostsWidget/types';
import { getCurrencyString } from 'utils/Currency/Currency';
import { FieldDataType } from '@sprinklr/stories/reporting/types';
import { PostOptionLocation } from 'src/widgets/PostsWindowWidget/types';
import { PostTemplate } from './PostGroup';
import PostTitle from '../PostTitle/PostTitle';
import { getTextEntities } from 'utils/EntityUtils/EntityUtils';
import RetweetIndicator from './RetweetIndicator';
import rtlDetect from 'rtl-detect';
import moment from 'moment';
import { Summary } from 'components/Post/StoryCard/Summary';
import { Publications } from 'components/Post/StoryCard/Publications';
import { Metrics } from 'components/Post/StoryCard/Metrics';
import { Meta } from 'components/Post/StoryCard/Meta';
import { StoryOptions } from '../../widgets/PostsWidget/StoryCard/types';

import './Post.scss';
import './StoryCard/StoryCard.scss';
import { PROFANITY_FILTER_DEFAULT } from 'components/Post/constants';
import { AdditionalDetail } from 'components/Post/AdditionalDetail';

export type PostCompositionConfig = {
    isInterstitialMedia: boolean;
    postOuterClasses: string;
    postInnerClasses: string;
    showIconInUserGroup: boolean;
    hasMedia: boolean;
    noMedia: boolean;
    showTitle: boolean;
    isPanelPortrait: boolean;
    postLayoutOrientation: Orientation;
    textFormat: PostTextFormat;
    showPostTextOverlayAsGradient: boolean;
    showComments: boolean;
    showReplyLabels: boolean;
};

export interface PostProps {
    playerInterval: PlayerInterval;
    offset?: number;
    data?: PostModel;
    userGroupEffect?: 'bottom' | 'top' | 'none';
    // theme: 'primary' | 'secondary' | null; // pretty sure we don't need this notion anymoree
    postLayoutType?: PostLayoutTypes;
    postLayoutOrientation?: Orientation;
    userGroupLayoutType?: UserGroupLayoutTypes;
    postCompositionType?: PostCompositionTypeValues;
    showText?: boolean;
    showSentiment?: boolean;
    showKlout?: boolean;
    showCenterAvatar?: boolean;
    showCenterChannel?: boolean;
    isHidden?: boolean; // Needed to not start videos if off-screen
    showEngagementMetrics?: boolean;
    showAdsMetrics?: boolean;
    engagementMetricsPosition?: string;
    engagementMetricsSize?: number;
    showUserGroup?: boolean;
    postLineClamp?: boolean;
    imagePostLineClamp?: boolean;
    postLineClampValue?: number;
    imagePostLineClampValue?: number;
    imagePostHideText?: boolean;
    showImageStyles?: boolean;
    userGroupPosition?: string;
    userGroupGradientEnabled?: boolean;
    userGroupGradientDirection?: string;
    imageStyle?: string;
    showUserAvatar?: boolean;
    showUserName?: boolean;
    showUserScreenName?: boolean;
    showTimeStamp?: boolean;
    timeStampFormat?: TimeStampOptions;
    textFormat?: string;
    postImageTextFormat?: PostTextFormat;
    textOnlyTextFormat?: PostTextFormat;
    blastDelimiter?: BlastDelimiterTypes;
    maxCharacters?: number;
    stripLinks?: boolean;
    classes?: string;
    endianTime?: string;
    location?: PostOptionLocation;

    imageBackgroundSize?: string;
    showUrls?: boolean;
    showExpandedEntityUrls?: boolean;
    showIconInUserGroup?: boolean;
    socialIconBgColor?: boolean;
    removeGenericPII?: boolean;
    panelOrientation?: PanelOrientation;
    socialIconPosition?: PositionTypesInterface;
    secondaryOverlayColor?: string;
    showSecondaryImageOverlay?: boolean;
    showComments?: boolean;
    comments?: CommentsOptions;

    metaOffset?: number;
    themeOffset?: number;
    animationOffset?: number;
    showZeroEngagementValues?: boolean;
    showPostTextOverlay?: boolean;
    showPostTextOverlayAsGradient?: boolean;
    activePostsInWindow?: number;
    animationLength?: number;
    animationDelay?: number;
    postTextOnlySize?: number;
    postImageTextSize?: number;
    inGroup?: boolean;
    textOnlyTeleprompterSpeed?: number;
    postImageTeleprompterSpeed?: number;
    textFitMin?: number;
    postChannelClasses?: string;
    classOverrides?: ClassOverrides;
    widgetType?: WidgetType;
    postVersion?: number;

    postIsActive?: boolean;
    animationDuration?: number;
    inReplyToPost?: JSX.Element;
    showReplyLabels?: boolean;

    customFields?: { enabled: boolean; fieldId: string };
    widgetOrientation?: string;
    unique?: string;
    extraPostDataOptions?: ExtraPostDataOptions;
    nativeStyling?: NativeStylingOptions;
    emoji?: Emoji;
    postTemplate?: PostTemplate;
    postTitleOptions?: TitleOptions;
    profanityFilter?: ProfanityFilter;
    retweetIndicator?: RetweetIndicatorOptions;

    linkPreviewSize?: number;
    showLinkPreview?: boolean;

    showQuotedTweet?: boolean;
    quotedTweetSize?: number;

    onPostClick?: (event: React.MouseEvent | React.TouchEvent) => void;
    storyOptions?: StoryOptions;
}

export interface PostCompositionsProps {
    compositionType: string;
    parts: React.ReactNode[];
}

const Post: React.FC<PostProps> = (props): React.ReactElement => {
    const {
        playerInterval,
        // theme
        showText,
        showEngagementMetrics,
        showAdsMetrics,
        engagementMetricsPosition,
        engagementMetricsSize,
        showUserGroup,
        postLineClampValue,
        imagePostLineClampValue,
        userGroupPosition,
        userGroupLayoutType,
        userGroupGradientEnabled,
        userGroupGradientDirection,
        showUserAvatar,
        showUserName,
        showUserScreenName,
        showTimeStamp,
        timeStampFormat,
        postImageTextFormat,
        textOnlyTextFormat,
        blastDelimiter,
        postLayoutType,
        postCompositionType,
        postLayoutOrientation,
        maxCharacters,
        stripLinks,
        classes,
        imageBackgroundSize,
        showIconInUserGroup,
        socialIconBgColor,
        removeGenericPII,
        showUrls,
        panelOrientation,
        socialIconPosition,
        secondaryOverlayColor,
        showSecondaryImageOverlay,
        showPostTextOverlay,
        showPostTextOverlayAsGradient,
        showZeroEngagementValues,
        endianTime,
        activePostsInWindow,
        animationLength,
        animationDelay,
        postTextOnlySize,
        postImageTextSize,
        inGroup,
        textOnlyTeleprompterSpeed,
        postImageTeleprompterSpeed,
        textFitMin,
        imagePostHideText,
        postLineClamp,
        imagePostLineClamp,
        classOverrides,
        widgetType,
        postChannelClasses,
        postVersion,
        offset,
        unique,
        widgetOrientation,
        comments: commentsOpts,
        customFields,
        inReplyToPost,
        extraPostDataOptions,
        showReplyLabels,
        nativeStyling,
        onPostClick,
        emoji,
        location: locationOptions,
        postTemplate,
        postTitleOptions,
        profanityFilter: {
            obscureSymbol,
            profanityList,
            enabled: profanityFilterEnabled,
        } = PROFANITY_FILTER_DEFAULT,
        showExpandedEntityUrls,
        retweetIndicator,
        storyOptions,
        linkPreviewSize,
        showLinkPreview,
        showQuotedTweet,
        quotedTweetSize,
    } = props;

    // Post Data Stuff
    const {
        images,
        message,
        title,
        language,
        senderProfile,
        snCreatedTime,
        snType,
        workflowProperties,
        videos,
        snStats,
        textEntities,
        comments,
        projectionStats,
        snMsgId,
        extraPostData,
        urlEntities,
        itemName,
        itemRating,
        maxRating,
        domain,
        additionalInformation,
        messageType,
        location: postLocation,
        orgTweetScreenName,
        orgSenderProfile,
        summary,
        publicationEntity,
        category,
        quotedMessage,
    } = props.data;

    const isRetweet = messageType === 8;
    const resolvedEntities = getTextEntities({ textEntities, urlEntities, showExpandedEntityUrls });

    const showComments: boolean =
        commentsOpts && commentsOpts.enabled && comments && comments.length > 0;
    const postVersion2: boolean =
        postVersion && postVersion > 1 && widgetType && !!widgetType.getPostComposition;

    // Sentiment stuff
    const sentimentScore: number = (workflowProperties && workflowProperties.sentiment) || null;

    // Client Custom Properties
    const clientCustomProperties: { [key: string]: any[] } | null =
        (workflowProperties && workflowProperties.clientCustomProperties) || null;
    const partnerCustomProperties: { [key: string]: string[] } | null =
        (workflowProperties && workflowProperties.partnerCustomProperties) || null;

    let customProperties = '';
    if (clientCustomProperties) {
        Object.keys(clientCustomProperties).forEach((property: string) => {
            if (clientCustomProperties[property].length > 0) {
                clientCustomProperties[property].map(
                    (value: string) =>
                        (customProperties += `${GenerateSafeString(value, 'post_custom_prop')}`)
                );
            }
        });
    }

    const { location, name, screenName } = senderProfile || ({} as any);

    // Image stuff
    const hasVideo: boolean = videos && videos.length ? true : false;
    const hasImage: boolean = !hasVideo && images && images.length ? true : false;
    const isInterstitialMedia: boolean =
        snType === 'interstitial_image' || snType === 'interstitial_video';
    const isPortrait: boolean = widgetOrientation === 'portrait';

    const rtl = rtlDetect.isRtlLang(language);

    /* Setup Post Classes */
    const postClasses = classNames({
        // Post Hooks
        [`post`]: true, // default
        [`post_container flex`]: true, // default flex, transition, transform
        post_container_native_post: nativeStyling?.enabled,
        vertical: !showComments || (showComments && isPortrait),
        [`post_snId_${snMsgId}`]: !!snMsgId,
        rel: postTemplate === 'gridV2',
        ['post_rtl']: rtl,

        // Offsets
        [`post_index_${offset + 1}`]: offset !== null, // post count in context of window || group
        [`post_meta_${props.metaOffset}`]: props.metaOffset ? true : false,
        [`post_animation_${props.animationOffset}`]: props.animationOffset ? true : false,
        [`post_theme_${props.themeOffset}`]: props.themeOffset ? true : false,
        post_active: props.postIsActive,

        // Data Hooks
        [`${GenerateLabelClasses(location, 'post_location')}`]: true,
        [`${GenerateLabelClasses(name, 'post_name')}`]: true,
        [`${GenerateLabelClasses(screenName, 'post_screen_name')}`]: true,

        // Artisinal Classes
        [`${classes}`]: true, // are we using this anywhere?

        // Sentiment
        post_sentiment_negative: sentimentScore < 0,
        post_sentiment_positive: sentimentScore > 0,
        post_sentiment_neutral: sentimentScore !== null && sentimentScore === 0,
        [`post_sentiment_score_${sentimentScore}`]: sentimentScore !== null,

        // Image, Video, Text, Type
        [`post_type_${snType}`]: snType !== null && snType !== undefined,
        post_text_only: !hasVideo && !hasImage,
        post_has_video: hasVideo,
        post_has_image: hasImage,
        post_has_comments: showComments,
        post_has_reply: !!inReplyToPost,
        [`${customProperties}`]: customProperties !== '',

        // Engagement
        engagement_metrics: showEngagementMetrics === true,
        [`engagement_position_${engagementMetricsPosition}`]:
            !postVersion2 && engagementMetricsPosition !== '',

        // User Bar && Layout
        user_position_bottom: userGroupPosition === 'bottom',
        user_position_top: userGroupPosition === 'top',
        [`post_layout_type_${postLayoutType}`]: true,

        // Theme?
        primary_background: true,
        primary_font_family: true,

        // DEPRECATED
        [`meta_post_container_${props.offset + 1}`]: !postVersion2, // Legacy Postano Hook; REMOVE
        // [`${ props.theme }_background`]: !hasImage
        // 'meta_text_only': !post.image,
        // 'meta_has_video': post.videos.length // no video yet
    });

    // Line clamping
    const imageClamp = imagePostLineClamp ? imagePostLineClampValue : null;
    const textClamp = postLineClamp ? postLineClampValue : null;

    /* Set All Post Classes */
    // var postInnerClasses = classNames(`post_inner flex rel w-100 h-100`, {})
    // var postChannelIconClasses = classNames(`post_channel_icon_center absolute center origin-middle z-top`);
    // var postAvatarCenterClasses = classNames('post_avatar_center absolute center bottom origin-middle z-top');

    // Grab the engagement metrics
    const numLikes = snStats
        ? snStats.totalLikesAndReacts
            ? snStats.totalLikesAndReacts
            : snStats.numLikes
        : null;
    const numComments = snStats ? snStats.numComments : null;
    const numShares = snStats ? snStats.numShares : null;
    const numRetweets = snStats ? snStats.numRetweets : null;
    const numViews = snStats ? snStats.viewCount : null;

    const hasMedia = hasImage || hasVideo;
    const noMedia = !hasImage && !hasVideo;
    const showTitle = postTitleOptions?.enabled;

    const textFormat: PostTextFormat =
        hasImage || hasVideo ? postImageTextFormat : textOnlyTextFormat;

    const showEngagement =
        showEngagementMetrics &&
        (showZeroEngagementValues ||
            !!numLikes ||
            !!numComments ||
            !!numShares ||
            !!numRetweets ||
            !!numViews);

    const getClassOverrides = (value: string) => {
        let output = '';
        if (!classOverrides || !classOverrides[value]) {
            return output;
        }
        if (showTitle && classOverrides[value].showTitle) {
            output = classOverrides[value].showTitle;
        } else if (classOverrides[value].default) {
            output = classOverrides[value].default;
        } else {
            output = hasMedia ? classOverrides[value].hasMedia : classOverrides[value].noMedia;
        }
        return output || '';
    };

    const lineClampVerticalAlign = getClassOverrides('lineClampVerticalAlign');

    const firstImage = images && images.length && images[0] && images[0].url ? images[0] : null;

    const firstEnrichment = firstImage ? firstImage.enrichment : null;

    const noCrop: BoundingBox =
        firstEnrichment && firstEnrichment.faces ? firstImage.enrichment.faces.boundingBox : null;

    const retweetMessage =
        isRetweet && retweetIndicator?.enabled ? (
            <RetweetIndicator name={senderProfile?.name} />
        ) : null;

    const postImage = hasImage ? (
        <PostImage
            key='postImage'
            src={firstImage && firstImage.url}
            postVersion2={postVersion2}
            imageBackgroundSize={imageBackgroundSize}
            secondaryOverlayColor={isInterstitialMedia ? undefined : secondaryOverlayColor}
            showSecondaryImageOverlay={showSecondaryImageOverlay}
            noCropRegion={noCrop}
            width={firstEnrichment && firstEnrichment.width}
            height={firstEnrichment && firstEnrichment.height}
            hasOverlay={isInterstitialMedia ? false : true}
        />
    ) : null;

    const postVideo = hasVideo ? (
        <Video
            playerInterval={playerInterval}
            type={snType}
            key='postVideo'
            url={videos[0].url}
            poster={videos[0].poster || (images.length && images[0].url) || null}
            width={400}
            height={400}
            play={!props.isHidden}
            hasOverlay={isInterstitialMedia ? false : true}
            hidePosterAfterPlay={true}
            showControls={false}
            autoplay={true}
            showSecondaryImageOverlay={showSecondaryImageOverlay}
            imageBackgroundSize={imageBackgroundSize}
        />
    ) : null;

    const linkPreviewEnabled: boolean =
        showLinkPreview &&
        widgetType.supportsLinkPreview &&
        !hasMedia &&
        !!urlEntities?.message?.length;

    const quotedTweetEnabled: boolean =
        showQuotedTweet &&
        widgetType.supportsQuotedTweetView &&
        quotedMessage?.message &&
        snType === 'twitter';

    const media = postImage || postVideo;
    const clamp = hasImage || hasVideo ? imageClamp : textClamp;

    const text = profanityFilterEnabled
        ? purifyString(message || title, { obscureSymbol, profanityList })
        : message || title;

    const hideStoryCardText = storyOptions?.title?.enabled === false;
    const postText =
        ((hasImage || hasVideo) && imagePostHideText) || hideStoryCardText ? null : (
            <PostText
                key='postText'
                classOverrides={getClassOverrides('postText')}
                classes={classNames('', {
                    'ta-c': postLayoutType === 'd',
                    'h-100': postLayoutType === 'c',
                    'no-grow': showPostTextOverlay,
                })}
                postVersion2={postVersion2}
                textSize={hasImage || hasVideo ? postImageTextSize : postTextOnlySize}
                text={text}
                rtl={rtl}
                language={language}
                inGroup={inGroup}
                teleprompterSpeed={
                    hasImage || hasVideo ? postImageTeleprompterSpeed : textOnlyTeleprompterSpeed
                }
                animationLength={animationLength}
                animationDelay={animationDelay}
                postOffset={props.offset + 1}
                textFormat={textFormat}
                textEntities={resolvedEntities}
                maxCharacters={maxCharacters}
                activePostsInWindow={activePostsInWindow}
                stripLinks={stripLinks}
                blastDelimiter={blastDelimiter}
                showPostTextOverlay={showPostTextOverlay}
                showUrls={showUrls}
                engagementMetricsSize={engagementMetricsSize}
                showEngagementMetrics={showEngagementMetrics}
                clamp={clamp}
                channel={snType}
                lineClampVerticalAlign={lineClampVerticalAlign}
                showPostTextOverlayAsGradient={showPostTextOverlayAsGradient}
                textFitMin={textFitMin}
                emoji={emoji}
                showLinkPreview={linkPreviewEnabled}
                linkPreviewSize={linkPreviewSize}
                urlEntities={urlEntities}
                showQuotedTweet={quotedTweetEnabled}
                quotedTweet={quotedMessage}
                quotedTweetSize={quotedTweetSize}
                postLayoutOrientation={postLayoutOrientation}
            />
        );

    const postTitle =
        title && postTitleOptions?.enabled ? (
            <PostTitle
                key='postTitle'
                classes={getClassOverrides('postTitle')}
                format={postTitleOptions.format}>
                {profanityFilterEnabled
                    ? purifyString(title, { obscureSymbol, profanityList })
                    : title}
            </PostTitle>
        ) : null;

    const rssSource = snType === 'rss_feeds' && props.data.rssSource;

    const displayName = rssSource || senderProfile?.screenName;

    const userSubtext = isRetweet
        ? orgSenderProfile?.screenName ?? orgTweetScreenName
        : displayName;

    const missingUserInfo: boolean =
        (senderProfile?.screenName === 'Instagram User' && !senderProfile?.profileImgUrl) ||
        senderProfile?.screenName === 'Facebook User';

    const customFieldValue =
        customFields && partnerCustomProperties && partnerCustomProperties[customFields.fieldId];
    const customProperty = customFields && customFieldValue;

    const userGroup = showUserGroup ? (
        <UserGroup
            key='userGroup'
            icon={isRetweet ? orgSenderProfile?.profileImgUrl : senderProfile?.profileImgUrl}
            full_name={isRetweet ? orgSenderProfile?.name : senderProfile?.name}
            screen_name={userSubtext}
            isRss={!!rssSource}
            time={snCreatedTime}
            timeStampFormat={timeStampFormat}
            channel={snType}
            effect={showText || !hasImage ? 'empty' : props.userGroupEffect}
            layoutType={userGroupLayoutType}
            postLayoutType={postLayoutType}
            showAvatar={showUserAvatar}
            showIcon={showIconInUserGroup}
            showTimeStampInUserGroup={showIconInUserGroup}
            showScreenName={showUserScreenName}
            showFullName={showUserName}
            socialIconBgColor={socialIconBgColor}
            userGroupPosition={userGroupPosition}
            classOverrides={getClassOverrides('userGroup')}
            showPostTextOverlay={showPostTextOverlay}
            showTimeStamp={showTimeStamp}
            endianTime={endianTime}
            userGroupGradientDirection={userGroupGradientDirection}
            userGroupGradientEnabled={userGroupGradientEnabled}
            customProperty={customProperty}
            location={postLocation}
            locationOptions={locationOptions}
            retweetIndicator={retweetMessage}
            missingUserInfo={missingUserInfo}
            removeGenericPII={removeGenericPII}
        />
    ) : (
        <UserGroup
            key='userGroup'
            channel={snType}
            time={snCreatedTime}
            showTimeStamp={showTimeStamp}
            socialIconBgColor={socialIconBgColor}
            userGroupGradientDirection={userGroupGradientDirection}
            userGroupGradientEnabled={userGroupGradientEnabled}
            classOverrides={getClassOverrides('userGroup')}
            effect='empty'
        />
    );

    const engagementMetrics = showEngagement && (
        <EngagementMetrics
            channel={snType}
            metricClasses={getClassOverrides('engagementMetric')}
            classes={getClassOverrides('engagementGroup')}
            key='engagementMetrics'
            likes={numLikes}
            showZeroEngagementValues={showZeroEngagementValues}
            views={numViews}
            comments={numComments}
            shares={numShares}
            retweets={numRetweets}
        />
    );

    const adsMetrics = showAdsMetrics && (
        <AdsMetrics key='adsMetrics' projectionStats={projectionStats} />
    );

    const metaComp = storyOptions?.meta?.enabled ? (
        <Meta
            key='meta'
            category={category}
            timestamp={{
                value: snCreatedTime,
            }}
        />
    ) : null;
    const summaryComp = storyOptions?.summary?.enabled ? (
        <Summary key='summary' summary={summary} />
    ) : null;
    const metricsComp = storyOptions?.metrics?.enabled ? (
        <Metrics key='metrics' metrics={extraPostData} />
    ) : null;
    const publicationEntitysComp = storyOptions?.publications?.enabled ? (
        <Publications publicationEntity={publicationEntity} />
    ) : null;

    const timestamp = showTimeStamp && (
        <TimeStamp
            key='posts_timeStamp'
            classes={socialIconBgColor ? 'mr-1 ml-1' : ''}
            time={snCreatedTime}
            timeStampFormat={timeStampFormat}
            endianTime={endianTime}
        />
    );

    const icon = (
        <Icon
            key='posts_icon'
            value={snType}
            size={socialIconBgColor ? 4 : 2}
            reset={!socialIconBgColor}
            wrap={socialIconBgColor}
            classes={`${postChannelClasses} post_channel_icon social_icon ${getClassOverrides(
                'socialIcon'
            )}`}
        />
    );

    const wrappedIcon = (
        <div className={`social_icon_container ${getClassOverrides('socialIconContainer')}`}>
            {icon}
        </div>
    );

    const extraPostDataMode = extraPostDataOptions?.mode || 'table';
    const showExtraPostData = extraPostDataOptions?.enabled && extraPostData?.length;

    const extraPostDataTags =
        showExtraPostData && extraPostDataMode === 'tags' ? (
            <>
                {extraPostData.map((tag, index) => (
                    <AdditionalDetail
                        key={`${tag.value}${index}`}
                        field={tag}
                        dateFormat={extraPostDataOptions?.dateFormat}
                        format={extraPostDataOptions?.value?.format}
                    />
                ))}
            </>
        ) : null;

    const metaGroup = (!showIconInUserGroup ||
        showEngagementMetrics ||
        showAdsMetrics ||
        extraPostDataTags) && (
        <MetaGroup
            key='metaGroup'
            showTimeStamp={showTimeStamp}
            icon={icon}
            timestamp={userGroupLayoutType !== 'a' && timestamp}
            engagement={engagementMetrics}
            ads={adsMetrics}
            showIcon={!showIconInUserGroup}
            showEngagement={showEngagement}
            showAdsMetrics={showAdsMetrics}
            engagementClasses={getClassOverrides('metaGroup')}
            location={userGroupLayoutType !== 'a' && postLocation}
            locationOptions={locationOptions}
            extraPostDataTags={extraPostDataTags}
        />
    );

    const commentDuration =
        commentsOpts && commentsOpts.animation && commentsOpts.animation.enabled
            ? commentsOpts.animation.duration
            : props.animationDuration;

    const postComments =
        showComments && comments ? (
            <PostComment
                key='sampleComment'
                playerInterval={playerInterval}
                comments={comments.slice().sort((a, b) => a.snCreatedTime - b.snCreatedTime)}
                animationDuration={commentDuration}
                channel={snType}
                options={commentsOpts}
                isActive={props.postIsActive}
                showEngagement={showEngagement}
                showZeroEngagementValues={showZeroEngagementValues}
                originalScreenName={senderProfile && senderProfile.screenName}
            />
        ) : null;

    const extraPostDataTable =
        showExtraPostData && extraPostDataMode === 'table' ? (
            <div
                key='postExtraPostData'
                className={`extra_post_data_group flex vertical w-100 no-grow pb-05 pt-05 ${
                    !getClassOverrides('extraPostData') ? 'pl-1 pr-1' : ''
                } ${getClassOverrides('extraPostData')}`}>
                {extraPostDataOptions?.header?.enabled && (
                    <div className='extra_post_data_header font_family_secondary'>
                        {extraPostDataOptions.header.text}
                    </div>
                )}
                {extraPostData.map((field, index) => {
                    if (field.heading === 'Message') {
                        return;
                    }
                    return (
                        <div
                            key={field.heading}
                            className='extra_post_data_item flex middle between'>
                            <div className='extra_post_data_label font_family_secondary w-50'>
                                {field.alternateHeading ?? titleCase(field.heading)}
                            </div>
                            <AdditionalDetail
                                field={field}
                                dateFormat={extraPostDataOptions?.dateFormat}
                                format={extraPostDataOptions?.value?.format}
                            />
                        </div>
                    );
                })}
            </div>
        ) : null;

    const compC = postCompositionType === 'c';

    const parts: { [key: string]: React.ReactNode } = {
        userGroup,
        postText,
        media,
        metaGroup,
        icon,
        wrappedIcon,
        timestamp,
        engagementMetrics,
        postComments,
        inReplyToPost,
        postExtraPostData: extraPostDataTable,
        extraPostDataTags,
        postTitle,
        summaryComp,
        publicationEntitysComp,
        metricsComp,
        metaComp,
    };

    const postOuterClasses = `${postClasses} ${getClassOverrides(
        'postOuter'
    )} post_composition_${postCompositionType} post_version_${postVersion} `;
    const postInnerClasses: string = getClassOverrides('postInner');

    const config: PostCompositionConfig = {
        isInterstitialMedia,
        postOuterClasses,
        postInnerClasses,
        showIconInUserGroup,
        hasMedia,
        noMedia,
        showTitle,
        isPanelPortrait: isPortrait,
        postLayoutOrientation,
        textFormat,
        showPostTextOverlayAsGradient,
        showComments,
        showReplyLabels,
    };
    // we return the native styled post if nativeStyling is enabled
    if (nativeStyling && nativeStyling.enabled) {
        return (
            <NativePostWrapper
                postExtraPostData={extraPostDataTable}
                extraPostDataTags={extraPostDataTags}
                postOuterClasses={postOuterClasses}
                postInnerClasses={postInnerClasses}
                post={props.data}
                options={nativeStyling}
                emoji={emoji}
                onPostClick={onPostClick}
                playerInterval={playerInterval}
                showLinkPreview={linkPreviewEnabled}
                showQuotedTweet={quotedTweetEnabled}
                quotedTweetSize={quotedTweetSize}
            />
        );
    }
    // Use widget type defined post composition if it's a version 2 widget
    if (postVersion2 && widgetType && !!widgetType.getPostComposition) {
        return widgetType.getPostComposition({
            compositionType: postCompositionType,
            parts,
            config,
            unique: props.data.unique,
            onPostClick,
        });
    }

    switch (postLayoutType) {
        case 'a':
            return (
                <div
                    className={`${postClasses} post_version_1`}
                    key={unique}
                    onClick={onPostClick}
                    onTouchEnd={onPostClick}>
                    {!isInterstitialMedia && !showComments && (
                        <div className='post_inner flex rel w-100 h-100 vertical'>
                            {((hasImage || hasVideo) && postImage) || postVideo}
                            {userGroup}
                            {!hasImage && !hasVideo && postTitle}
                            {postText}
                            {engagementMetrics}
                        </div>
                    )}

                    {!isInterstitialMedia &&
                        showComments && [
                            <div
                                className='post_inner flex rel w-50 h-100 vertical'
                                key='post_inner'>
                                {((hasImage || hasVideo) && postImage) || postVideo}
                                {userGroup}
                                {!hasImage && !hasVideo && postTitle}
                                {postText}
                                {engagementMetrics}
                            </div>,
                            <div className='flex w-50' key='postCommentWrap'>
                                {postComments}
                            </div>,
                        ]}

                    {isInterstitialMedia && (
                        <div className='post_inner rel w-100 h-100'>{postImage || postVideo}</div>
                    )}
                </div>
            );
        case 'b':
            return (
                <div className={`${postClasses} post_version_1`} key={unique} onClick={onPostClick}>
                    <div
                        className={classNames(`post_inner flex rel h-100`, {
                            vertical: !isPortrait,
                            'w-100': !showComments,
                            'w-50': showComments && comments && comments.length > 0,
                        })}>
                        {isPortrait && (hasImage || hasVideo) && !isInterstitialMedia && (
                            <div className='flex'>
                                <div className='rel w-50 h100'>{postImage || postVideo}</div>
                                <div className='flex vertical w-50 h100'>
                                    {userGroup}
                                    {!hasImage && !hasVideo && postTitle}
                                    {postText}
                                    {engagementMetrics && <div className='post_spacer flex'></div>}
                                    {engagementMetrics}
                                </div>
                            </div>
                        )}

                        {!isPortrait && [
                            userGroup,
                            (hasImage || hasVideo) && !isInterstitialMedia && (
                                <div className='flex rel'>
                                    <div className='fullbleed flex vertical'>
                                        <div className='rel h-50'>{postImage || postVideo}</div>

                                        <div className='h-50 flex vertical'>
                                            {!hasImage && !hasVideo && postTitle}
                                            {postText}
                                            {engagementMetrics && (
                                                <div className='post_spacer flex'></div>
                                            )}
                                            {engagementMetrics}
                                        </div>
                                    </div>
                                </div>
                            ),
                        ]}

                        {isInterstitialMedia && (postImage || postVideo)}

                        {!hasImage && !hasVideo && [postText, engagementMetrics]}
                    </div>
                    {showComments && comments && comments.length > 0 && postComments}
                </div>
            );
        case 'c':
            return (
                <div className={`${postClasses} post_version_1`} key={unique} onClick={onPostClick}>
                    <div
                        className={classNames(`post_inner fullbleed flex`, {
                            [`${
                                isPortrait && (hasImage || hasVideo) ? 'horizontal' : 'vertical'
                            }`]: true,
                            'w-50': showComments,
                        })}>
                        {(hasImage || hasVideo) &&
                            !isInterstitialMedia && [
                                <div
                                    key='1'
                                    className={classNames(`rel`, {
                                        [`${
                                            userGroupPosition === 'bottom' ? 'flex bottom' : ''
                                        }`]: true,
                                        [`${isPortrait ? 'w-50 h100' : 'h-50'}`]: true,
                                    })}>
                                    {((hasImage || hasVideo) && postImage) || postVideo}
                                    {userGroup}
                                </div>,
                                <div
                                    key='2'
                                    className={`${
                                        isPortrait ? 'w-50 h100 pt-6' : 'h-50'
                                    } flex vertical between`}>
                                    {[postText, engagementMetrics]}
                                </div>,
                            ]}

                        {isInterstitialMedia && (postImage || postVideo)}

                        {!hasImage &&
                            !hasVideo && [postTitle, postText, engagementMetrics, userGroup]}
                    </div>
                    {showComments && <div className='flex w-50'>{postComments}</div>}
                </div>
            );
        case 'd':
            // this is the horizontal layout
            return (
                <div className={`${postClasses} post_version_1`} key={unique} onClick={onPostClick}>
                    <div
                        className={classNames(`post_inner flex rel ${postLayoutOrientation}`, {
                            'vertical p2': !hasImage && !hasVideo && !isInterstitialMedia,
                            'vertical h-100': isPortrait && !showComments,
                            'vertical w-50': showComments && !isPortrait,
                            'vertical h-50': showComments && isPortrait,
                        })}>
                        {(hasImage || hasVideo) &&
                            !isInterstitialMedia && [
                                !compC && (
                                    <div
                                        className={classNames(`media_group flex rel`, {
                                            'w-50': !isPortrait && !showComments,
                                            'h-50': isPortrait || showComments,
                                        })}
                                        key='mediaContainer'>
                                        {postImage || postVideo}
                                    </div>
                                ),
                                <div
                                    className={classNames(`text_group flex vertical`, {
                                        'w-50': !compC && !isPortrait && !showComments,
                                        'w-40': compC && !isPortrait,
                                        'h-50': (!compC && isPortrait) || showComments,
                                        'h-40': compC && isPortrait,
                                    })}
                                    key='textContainer'>
                                    {isPortrait
                                        ? [userGroup, postText, engagementMetrics]
                                        : [postText, userGroup, engagementMetrics]}
                                </div>,
                                compC && (
                                    <div
                                        className={classNames(`media_group flex rel`, {
                                            'w-60': !isPortrait && !showComments,
                                            'h-60': isPortrait || showComments,
                                        })}
                                        key='mediaContainer'>
                                        {postImage || postVideo}
                                    </div>
                                ),
                            ]}

                        {!hasImage &&
                            !hasVideo &&
                            !isInterstitialMedia && [
                                postTitle,
                                postText,
                                userGroup,
                                engagementMetrics,
                            ]}

                        {isInterstitialMedia && (postImage || postVideo)}

                        {!showIconInUserGroup && (
                            <CornerTear
                                iconClasses={`abs post_channel_icon ${socialIconPosition.x} ${socialIconPosition.y}`}
                                xPosition={socialIconPosition.x}
                                yPosition={socialIconPosition.y}
                                socialIconBgColor={socialIconBgColor}
                                channel={snType}
                            />
                        )}
                    </div>
                    {showComments && (
                        <div
                            className={`flex ${isPortrait ? 'h-50' : 'w-50'}`}
                            key='postCommentWrap'>
                            {postComments}
                        </div>
                    )}
                </div>
            );

        default:
            return (
                <div>
                    please talk to legz
                    <br />
                </div>
            );
        // else do this
    }
};

Post.defaultProps = {
    playerInterval: null,
    offset: 0,
    userGroupEffect: 'none',
    // theme: 'primary',
    postLayoutType: 'a',
    showText: false,
    showSentiment: true,
    showKlout: false,
    showCenterAvatar: false,
    showCenterChannel: false,
    showEngagementMetrics: false,
    showAdsMetrics: false,
    engagementMetricsPosition: '',
    showUserGroup: true,
    postLineClamp: true,
    postLineClampValue: 3,
    imagePostLineClamp: true,
    imagePostLineClampValue: 3,
    showImageStyles: true,
    userGroupPosition: '',
    imageStyle: '',
    showUserAvatar: true,
    showUserName: true,
    showUserScreenName: true,
    textFormat: 'clamp',
    userGroupLayoutType: 'a',
    postLayoutOrientation: 'vertical',
    classes: '',
    blastDelimiter: 'words',
    imageBackgroundSize: 'cover',
    showIconInUserGroup: true,
    inGroup: false,
};

export default observer(Post);
