import { AnalyticsRequest, Metric, Sort } from '../PanelTemplatesTheme/PanelTemplatesTheme';
import { DefaultWidgetPosition } from '../PanelTemplatesTheme/constants';
import { Widget } from '@sprinklr/stories/widget/Widget';
import { WIDGET_LABEL_SIZE } from 'components/Widget/WidgetLabel/constants';
import ListeningMetrics from '@sprinklr/stories-services/AnalyticsService/ListeningMetrics';

export const WidgetDataTableSummary: Widget = {
    type: 'dataTableSummary',
    id: 'data-table-summary',
    name: 'Summary Table',
    useGlobalTimePeriod: true,
    analyticsRequests: [
        // Note: All global variables like reportingEngine, report, filters, and projections
        // are injected via AnalyticsRequest.getRequests()
        new AnalyticsRequest({
            limit: 10,
            projections: [
                new Metric({
                    LISTENING: { source: ListeningMetrics.distinctUsers },
                    INBOUND_MESSAGE: { dimensionName: 'INBOUND_COUNT' },
                    PLATFORM: { dimensionName: 'TOTAL_ENGAGEMENT' },
                    BENCHMARKING: { dimensionName: 'TOTAL_ENGAGEMENT' },
                    PAID: { dimensionName: 'spent' },
                    ADVOCACY: {
                        dimensionName: 'TOTAL_POINTS_EARNED',
                        heading: 'Total_Points_Earned',
                        report: 'COMMUNITY_USER',
                    },
                    TWITTER: { dimensionName: 'MENTIONS' },
                    RDB_FIREHOSE: {
                        dimensionName: 'MENTIONS_COUNT',
                        heading: 'Mentions',
                    },
                }),
                new Metric({
                    LISTENING: { source: ListeningMetrics.mentions },
                    INBOUND_MESSAGE: { dimensionName: 'OUTBOUND_COUNT' },
                    PLATFORM: { dimensionName: 'ENGAGEMENT_RATE' },
                    BENCHMARKING: { dimensionName: 'ENGAGEMENT_RATE' },
                    PAID: { dimensionName: 'impressions' },
                    ADVOCACY: {
                        dimensionName: 'ADVOCACY_TOTAL_USER_ENGAGEMENTS',
                        heading: 'Total_Engagements',
                        origReport: 'ADVOCACY_ACTIVITY',
                    },
                    RDB_FIREHOSE: {
                        dimensionName: 'REACH_COUNT',
                        heading: 'Reach',
                    },
                }),
                new Metric({
                    LISTENING: { source: ListeningMetrics.reach },
                    INBOUND_MESSAGE: { dimensionName: 'LIKE_COUNT' },
                    PLATFORM: {
                        dimensionName: 'POST_REACH_COUNT',
                        heading: 'Post_Reach_Trend',
                        details: {
                            origReport: 'POST_INSIGHTS_TREND',
                            dataType: 'NUMERIC',
                        },
                    },
                    BENCHMARKING: { dimensionName: 'PUBLISHED_MESSAGE_COUNT' },
                    PAID: { dimensionName: 'clicks' },
                    ADVOCACY: {
                        dimensionName: 'ADVOCACY_TOTAL_USER_LOGIN',
                        heading: 'Total_Logins',
                        origReport: 'ADVOCACY_ACTIVITY',
                    },
                    RDB_FIREHOSE: {
                        dimensionName: 'DISTINCT_USER_COUNT',
                        heading: 'Distinct Users',
                    },
                }),
            ],
            groupBys: [],
            sorts: [new Sort({ order: 'DESC', projections: 0 })],
            timePeriods: [{ key: 'last_7_days' }, { previousPeriod: true }],
        }) as any,
    ],
    position: DefaultWidgetPosition,
    label: {
        titleText: 'Summary Table',
        size: WIDGET_LABEL_SIZE.primary,
        enabled: false,
    },
    options: {
        player: {
            playing: true,
            duration: 15,
        },
    },
};
