import { observable, action, computed, toJS } from 'mobx';
import _cloneDeep from 'lodash/cloneDeep';
import _merge from 'lodash/merge';

// types
import { DrilldownContext } from 'components/TouchScreen/ExternalOverlayContainer/types';
import { SegmentDataType, WidgetSegment, Segment } from 'components/Widget/WidgetComponent/types';
import BulkItem from '@sprinklr/stories/analytics/BulkItem';
import AccountItem from '@sprinklr/stories/analytics/AccountItem';
import { AnalyticsTimePeriod, AnalyticsEngine } from '@sprinklr/stories/analytics/AnalyticsRequest';
import { PanelVersion } from 'models/Panel/PanelVersion';
import { Widget } from '@sprinklr/stories/widget/Widget';
import { LegacyThemeImpl } from 'models/Widget/Widget';
import { FormatBucketsOptions, SourcePost } from '@sprinklr/stories/post/PostsFormatRequest';
import { WidgetOptionsImpl } from 'models/Widget/WidgetOptions';
import { Post, PostProfile } from '@sprinklr/stories/post/Post';

// utils
import { getSmartSizeOptions } from 'models/Widget/helpers';

// services
import SignalService from 'services/SignalService/SignalService';
import WidgetTypeService from 'services/WidgetTypes/WidgetTypeService';

// constants
import ListeningTemplate from 'components/TouchScreen/Templates/ListeningTemplate';

// options
import { PostCardWidgetOptions } from '../../widgets/PostsWidget/options';
import { profileMetrics } from 'components/TouchScreen/ExternalOverlayContainer/options';

export function getBasePanel(id: string) {
    return {
        id,
        widget: {
            position: {
                top: '0',
                left: '0',
                width: '1080px',
                height: '1920px',
            },
            theme: {
                ...new LegacyThemeImpl(),
                ext: {
                    padding: 0,
                },
            },
            children: [],
        },
        storyboard: {
            id: 'touchScreen_storyboard_id',
            name: 'Sidebar',
            timePeriod: {
                key: 'last_7_days',
                duration: null,
                timeZone: 'America/Los_Angeles',
                startTime: 1575964800000,
                endTime: 1576569599999,
            } as AnalyticsTimePeriod,
        },
        scene: {
            id: 'touchScreen_scene_id',
            name: 'Listening',
            duration: 0,
            disabled: false,
            theme: null,
            sceneTransition: null,
        },
    };
}

const SUPPORTED_ENGINES: AnalyticsEngine[] = ['LISTENING'];

const fullSizeWidget = { left: '0', top: '0', width: '100%', height: '100%' };

export default class TouchScreenService {
    @observable drilldownContext: DrilldownContext<'analytics'>[] = [];
    @observable postViewerContext: DrilldownContext<'post'>[] = [];
    @observable profileViewerContext: DrilldownContext<'profile'>[] = [];
    @observable postViewerPanel: PanelVersion = getBasePanel('current_post_preview');
    @observable profileViewerPanel: PanelVersion = getBasePanel('current_profile_preview');
    @observable activeTimePeriod: AnalyticsTimePeriod = {
        key: 'last_7_days',
        duration: null,
        timeZone: 'America/Los_Angeles',
        startTime: 1575964800000,
        endTime: 1576569599999,
    };
    private signalService: SignalService;
    private widgetTypeService: WidgetTypeService;
    private lastEvent = null;

    @computed get isModalOpen(): boolean {
        return this.drilldownContext.length > 0;
    }

    @computed get isPostViewerOpen(): boolean {
        return this.profileViewerContext.length > 0 || this.postViewerContext.length > 0;
    }

    @computed
    get activeAnalyticDrillContext(): DrilldownContext<'analytics'> {
        return this.drilldownContext[this.drilldownContext.length - 1];
    }

    @computed
    get activeAnalyticSegmentIndex(): number {
        return this.activeAnalyticDrillContext.activeIndex;
    }

    @computed
    get activeAnalyticSegment(): Segment {
        return (
            this.activeAnalyticDrillContext?.segment.segments[this.activeAnalyticSegmentIndex] ??
            null
        );
    }

    @computed
    get activeAnalyticEngine() {
        return (
            this.activeAnalyticDrillContext?.segment?.widget?.analyticsRequests?.[0]
                ?.reportingEngine ?? null
        );
    }

    @computed
    get activeAnalyticSegmentId(): string {
        const name = (this.activeAnalyticSegment as Segment)?.name;

        if (!name) {
            return '';
        }

        if (name instanceof BulkItem) {
            if (name instanceof AccountItem) {
                return name.accountId;
            } else {
                return name.id;
            }
        } else {
            return name.toString();
        }
    }

    @computed
    get isActiveDrillContextTrend(): boolean {
        return (
            this.activeAnalyticDrillContext?.segment.widget?.type === 'barChart' &&
            this.drilldownContext.length > 1
        );
    }

    constructor(signalService: SignalService, widgetTypeService: WidgetTypeService) {
        this.signalService = signalService;
        this.widgetTypeService = widgetTypeService;
    }

    addSegment = (
        segment: WidgetSegment<SegmentDataType>,
        event: React.MouseEvent | React.TouchEvent | MouseEvent | TouchEvent
    ) => {
        // return early if it was a double click or touch and click
        if (this.lastEvent?.target === event?.target) {
            return;
        }

        this.lastEvent = event;

        if (segment.dataType === 'analytics') {
            if (!SUPPORTED_ENGINES.includes(segment.widget.analyticsRequests[0].reportingEngine)) {
                return;
            }
            this.addAnalyticSegment({
                segment: segment as WidgetSegment<'analytics'>,
                activeIndex: segment.segmentIndex,
            });
            if (segment.widget?.analyticsRequests?.[0]?.timePeriods?.[0] ?? false) {
                this.updateTimePeriod(
                    toJS(segment.widget?.analyticsRequests?.[0]?.timePeriods?.[0], {
                        recurseEverything: true,
                    })
                );
            }
        } else if (segment.dataType === 'post') {
            if (this.postViewerContext.length > 0) {
                return;
            }

            // make sure postViewer stops at 5 in drillDown since post padding can't be zero
            let resolvedSegment = segment;
            if (this.drilldownContext?.length > 0) {
                resolvedSegment = {
                    ...segment,
                    segments: segment.segments.slice(0, 5),
                };
            }

            this.addPostSegment({
                segment: resolvedSegment as WidgetSegment<'post'>,
                activeIndex: segment.segmentIndex,
            });
            this.initializePostViewerPanel();
            if (
                (segment.widget?.postRequests?.[0]?.sources?.[0] as SourcePost)?.id?.timePeriod ??
                false
            ) {
                this.updateTimePeriod(
                    toJS(
                        (segment.widget?.postRequests?.[0]?.sources?.[0] as SourcePost)?.id
                            ?.timePeriod,
                        { recurseEverything: true }
                    )
                );
            }
        } else {
            if (this.profileViewerContext.length > 0) {
                return;
            }
            this.addProfileSegment({
                segment: segment as WidgetSegment<'profile'>,
                activeIndex: segment.segmentIndex,
            });
            this.initializeProfileViewerPanel();
            if (segment.widget?.profileRequests?.[0]?.timePeriod ?? false) {
                this.updateTimePeriod(
                    toJS(segment.widget?.profileRequests?.[0]?.timePeriod, {
                        recurseEverything: true,
                    })
                );
            }
        }
    };

    @action
    updateTimePeriod(timePeriod: AnalyticsTimePeriod) {
        this.activeTimePeriod = { ...timePeriod };
    }

    @action
    onNavSegmentClick = (segment: WidgetSegment<SegmentDataType>) => {
        this.setDrillDownSegmentIndex(segment.segmentIndex);
    };

    @action
    addAnalyticSegment(segment: DrilldownContext<'analytics'>) {
        this.drilldownContext.push(segment);
    }

    @action
    addPostSegment(segment: DrilldownContext<'post'>) {
        this.postViewerContext.push(segment);
    }

    @action
    addProfileSegment(segment: DrilldownContext<'profile'>) {
        this.profileViewerContext.push(segment);
    }

    @action
    setDrillDownSegmentIndex = (newIndex: number) => {
        this.drilldownContext[this.drilldownContext.length - 1].activeIndex = newIndex;
    };

    @action
    setDrillDownIndex = (index: number) => {
        this.drilldownContext = [...this.drilldownContext.slice(0, index + 1)];

        // set the timePeriod to last used timePeriod
        const timePeriods = this.drilldownContext
            .filter((context, idx) => !context.segment.widget.useGlobalTimePeriod || idx === 0)
            .map(context => context.segment.widget.analyticsRequests[0].timePeriods[0]);
        this.activeTimePeriod = timePeriods[timePeriods.length - 1];
    };

    @action
    closeDrillDownModal = () => {
        this.drilldownContext = [];
        this.lastEvent = null;
        this.closePostViewer();
    };

    @action
    closePostViewer = (event?: React.MouseEvent) => {
        // stop event bubbling
        event?.stopPropagation();

        this.lastEvent = null;
        this.postViewerContext = [];
        this.profileViewerContext = [];
    };

    @action
    onNextSegment = () => {
        const activeContext = this.drilldownContext[this.drilldownContext.length - 1];
        if (
            this.activeAnalyticSegmentIndex <
            this.activeAnalyticDrillContext.segment.segments.length - 1
        ) {
            activeContext.activeIndex++;
        } else {
            activeContext.activeIndex = 0;
        }
    };

    @action
    onPreviousSegment = () => {
        const activeContext = this.drilldownContext[this.drilldownContext.length - 1];
        if (this.activeAnalyticSegmentIndex > 0) {
            activeContext.activeIndex--;
        } else {
            activeContext.activeIndex = this.activeAnalyticDrillContext.segment.segments.length - 1;
        }
    };

    applyInitialWidgetOptions(widget: Widget): Widget {
        widget.position = fullSizeWidget;
        widget.useGlobalTimePeriod = false;
        widget.options = getSmartSizeOptions(widget, 'xl');
        widget.options.player.playing = false;
        widget.label.enabled = false;
        return widget;
    }

    @action
    initializePostViewerPanel() {
        const postGridWidget = toJS(
            ListeningTemplate.widget.children.find(child => child.type === 'postsGrid') as Widget,
            { recurseEverything: true }
        );

        const targetWidget = this.applyInitialWidgetOptions(postGridWidget);

        this.postViewerPanel.widget.position = fullSizeWidget;
        if (!!this.postViewerPanel.widget.theme?.ext?.padding) {
            this.postViewerPanel.widget.theme.ext.padding = 0;
        }
        this.postViewerPanel.widget.children = [targetWidget];
        this.munger(this.postViewerPanel.widget);
        this.updatePostViewerPanel();
    }

    @action
    updatePostViewerPanel() {
        if (this.postViewerContext.length === 0) {
            return;
        }
        const postGridWidget = toJS(
            ListeningTemplate.widget.children.find(child => child.type === 'postsGrid') as Widget,
            { recurseEverything: true }
        );

        const sourceWidget: Widget = toJS(this.postViewerContext[0].segment.widget, {
            recurseEverything: true,
        });
        (sourceWidget.postRequests[0].sources[0] as SourcePost).id.timePeriod = {
            ...this.activeTimePeriod,
        };
        (sourceWidget.postRequests[0]
            .sources[0] as SourcePost).id.pageSize = this.postViewerContext[0].segment.segments.length;
        if (!(sourceWidget.postRequests[0].format.options as FormatBucketsOptions)?.panels) {
            sourceWidget.postRequests[0].format = { ...postGridWidget.postRequests[0].format };
        }
        sourceWidget.postRequests[0].format.options.padding = 0;
        (sourceWidget.postRequests[0].format
            .options as FormatBucketsOptions).panels[0].total = this.postViewerContext[0].segment.segments.length;
        (sourceWidget.postRequests[0].sources[0] as SourcePost).data = [
            ...(this.postViewerContext[0].segment.segments as Post[]),
        ];

        this.postViewerPanel.widget.children[0].postRequests = [...sourceWidget.postRequests];

        this.munger(this.postViewerPanel.widget);
    }

    applySmartSize(widget: Widget): Widget {
        widget.options = getSmartSizeOptions(widget, 'xl');
        return widget;
    }

    @action
    initializeProfileViewerPanel() {
        if (this.profileViewerContext.length === 0) {
            return;
        }

        // profile widget
        const profileWidgetRaw: Widget = toJS(this.profileViewerContext[0].segment.widget, {
            recurseEverything: true,
        });

        const profileWidget = this.applySmartSize(profileWidgetRaw);

        profileWidget.position = { left: '0', top: '0', width: '100%', height: '47.5%' };

        // this needs to be at least 5 so the correct profile classes apply
        const numbProfiles =
            this.profileViewerContext[0].segment.segments.length > 5
                ? this.profileViewerContext[0].segment.segments.length
                : 5;

        const updatedProfileOptions: any = {
            numbProfiles,
            showDescription: true,
            showProfileDescription: true,
            userGroupAvatarSize: 140,
            userGroupNameSize: 25,
            userGroupIconSize: 15,
            userGroupScreenNameSize: 19,
            metricValueSize: 25,
            profileDescriptionSize: 18,
            player: {
                playing: false,
                duration: 15,
            },
            showIcon: true,
            verticalAlign: 'top',
            showMetricValue: true,
            showMetricName: true,
            metricNameSize: 15,
            smartSize: {
                enabled: true,
                mode: 'responsive',
                size: 'xl',
            },
            portrait: {
                widgetOrientation: 'portrait',
            },
            profileMetrics,
        };

        profileWidget.options = _merge({}, profileWidget.options, updatedProfileOptions);
        profileWidget.label.enabled = false;

        this.munger(this.profileViewerPanel.widget);

        profileWidget.profileResults = [
            [...(this.profileViewerContext[0].segment.segments as PostProfile[])],
        ];

        // profile drilldown posts widget
        const postsWidgetRaw = toJS(
            ListeningTemplate.widget.children.find(child => child.type === 'postsGrid') as Widget,
            { recurseEverything: true }
        );
        const postsWidget = this.applySmartSize(postsWidgetRaw);
        postsWidget.type = 'postsGridV2';
        postsWidget.position = { left: '0', top: '50%', width: '100%', height: '50%' };
        (postsWidget.options as PostCardWidgetOptions).gridLayout = {
            columns: 3,
            rows: 1,
            gap: 10,
        };
        (postsWidget.options as PostCardWidgetOptions).postTemplate = 'gridV2';
        (postsWidget.options as PostCardWidgetOptions).nativeStyling.enabled = true;
        postsWidget.label.enabled = false;

        (postsWidget.postRequests[0].format.options as FormatBucketsOptions).panels[0].total = 3;
        const postSource = (postsWidget.postRequests[0].sources[0] as SourcePost).id;
        const profileRequest = profileWidget.profileRequests[0];
        const { filters, sorts, timePeriod } = toJS(profileRequest, { recurseEverything: true });
        (postsWidget.postRequests[0].sources[0] as SourcePost).id = {
            ...postSource,
            filters,
            sorts,
            timePeriod,
        };

        // panel setup
        this.profileViewerPanel.widget.position = fullSizeWidget;
        if (!!this.profileViewerPanel.widget.theme?.ext?.padding) {
            this.profileViewerPanel.widget.theme.ext.padding = 0;
        }

        this.profileViewerPanel.widget.children = [profileWidget, postsWidget];
    }

    @action
    updateProfileFilters = (profileId: string) => {
        const postsWidget = this.profileViewerPanel.widget.children[1];
        const filters = (postsWidget.postRequests?.[0].sources?.[0] as SourcePost)?.id?.filters;

        // Check to see if we have a profile filter
        const existingProfileFilter = filters.find(
            filter => filter.dimensionName === 'SN_PROFILE_ID'
        );

        // Remove existing profile filter
        if (existingProfileFilter) {
            filters.pop();
        }

        // Add new profile filter
        filters.push({
            dimensionName: 'SN_PROFILE_ID',
            filterType: 'IN',
            values: [profileId],
        });

        // sort by earned engagements
        (postsWidget.postRequests[0].sources[0] as SourcePost).id.sorts = [
            {
                order: 'DESC',
                heading: 'EARNED_ENGAGEMENT',
                details: {
                    origReport: 'SPRINKSIGHTS',
                },
                isDimension: false,
            },
        ];

        this.profileViewerPanel.widget.children[1] = postsWidget;
    };

    onPreviousPostViewerPost = () => {
        this.signalService.dispatch('prev', this.postViewerPanel.widget.children[0]);
    };

    onNextPostViewerPost = () => {
        this.signalService.dispatch('next', this.postViewerPanel.widget.children[0]);
    };

    @action
    munger = (widget: Widget) => {
        if (!widget) {
            return;
        }
        let Options: typeof WidgetOptionsImpl = WidgetOptionsImpl;
        const widgetType = this.widgetTypeService.get(widget.type);
        if (widgetType) {
            Options = widgetType.type.optionsType || WidgetOptionsImpl;
        }
        widget.options = new Options().setValues(widget.options);
        if (widget.children) {
            widget.children.forEach(this.munger);
        }
    };
}
