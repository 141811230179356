import * as React from 'react';
import { action } from 'mobx';
import { observer } from 'mobx-react';
import { Flexbox, LabeledRadio } from 'components/spaceKit';
import { StyledLabel } from 'components/_UI/Forms/LabeledFormElement/LabeledFormElement';
import styled from '@sprinklr/space-kit/lib/designSystem';

const ActionMultiRadioGroupLabel = styled(StyledLabel)`
    min-width: 35%;
`;
export type Option = {
    label?: string;
    value: string;
};

export type ActionMultiRadioGroupProps = {
    label?: string;
    value: string;
    option: any;
    options: Option[];
};

const ActionMultiRadioGroup: React.FC<ActionMultiRadioGroupProps> = ({
    option,
    label,
    value,
    options,
}) => {
    return (
        <Flexbox gap middle>
            {label && <ActionMultiRadioGroupLabel>{label}</ActionMultiRadioGroupLabel>}
            <Flexbox gap middle>
                {options.map((item, index) => (
                    <LabeledRadio
                        key={index}
                        checked={option[value] === item.value}
                        label={item.label}
                        onClick={action(() => (option[value] = item.value))}
                    />
                ))}
            </Flexbox>
        </Flexbox>
    );
};

ActionMultiRadioGroup.displayName = 'ActionMultiRadioGroup';
export default observer(ActionMultiRadioGroup);
