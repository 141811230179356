import { AnalyticsRequest, GroupBy, Sort } from './PanelTemplatesTheme/PanelTemplatesTheme';
import { Panel } from '../../models/Panel/Panel';
import { WIDGET_LABEL_SIZE } from '../../components/Widget/WidgetLabel/constants';
import ListeningDimensions from '@sprinklr/stories-services/AnalyticsService/ListeningDimensions';

export var TemplateMarketConversation: Panel = {
    id: 'market-conversation',
    name: 'Market Conversation',
    description: '',
    tags: ['Smart', 'Market Conversation', 'Top Markets', 'Bubble Chart', 'landscape', 'portrait'],
    widget: {
        id: '1',
        position: {
            width: '1920px',
            height: '1080px',
            top: '0',
            left: '0',
        },
        classes: 'template_market_conversation',
        children: [
            {
                type: 'rankedList',
                id: '1',
                name: 'Top Markets',
                useGlobalTimePeriod: true,
                analyticsRequests: [
                    // Note: All global variables like reportingEngine, report, filters, and projections
                    // are injected via AnalyticsRequest.getRequests()
                    new AnalyticsRequest({
                        limit: 3,
                        groupBys: [
                            new GroupBy({
                                LISTENING: { source: ListeningDimensions.topicName },
                                INBOUND_MESSAGE: { dimensionName: 'ACCOUNT_ID' },
                                PLATFORM: { dimensionName: 'ACCOUNT_ID' },
                                BENCHMARKING: { dimensionName: 'BRAND_ID' }, //BENCHMARKING_ACCOUNT_ID
                                PAID: { dimensionName: 'accountIds' },
                                STORY_MESSAGE: { dimensionName: 'STORY_CATEGORY' },
                            }),
                        ],
                        sorts: [
                            new Sort({ order: 'DESC', projections: 0 }),
                            new Sort({ order: 'ASC', groupBys: 0 }),
                        ],
                        timePeriods: [{ key: 'last_7_days' }, { previousPeriod: true }],
                    }) as any,
                ],
                position: {
                    width: '60%',
                    height: '50%',
                    top: '0',
                    left: '0',
                    portrait: {
                        width: '100%',
                        height: '20%',
                        top: '55%',
                        left: '0',
                    },
                },
                label: {
                    titleText: 'Top Markets',
                    enabled: true,
                    size: WIDGET_LABEL_SIZE.secondary,
                },
                options: {
                    player: {
                        playing: true,
                        duration: 15,
                    },
                    themeClass: 'primary_fill',
                    countryCodeMap: {
                        'Australia 2': 'AU',
                        'Germany 2': 'DE',
                        'France 2': 'FR',
                        'Poland 2': 'PL',
                        'Italy 2': 'IT',
                        'Netherlands 2': 'NL',
                        'China 2': 'CN',
                        'Russia 2': 'RU',
                        'Canada 2': 'CA',
                        'Korea 2': 'KR',
                        'Spain 2': 'ES',
                        'Switzerland 2': 'CH',
                        'US 2': 'US',
                        'UK 2': 'GB',
                    },
                    labelSize: 12,

                    showMetricValue: true,
                    metricValueSize: 45,

                    showMetricName: true,
                    metricNameSize: 12,

                    showMetricChange: false,
                    metricPercentChangeSize: 14,
                    highlightPercentChange: false,

                    colorRanking: true,
                    animationDelay: 0,
                },
            },
            {
                type: 'rankedList',
                id: '2',
                name: 'bottom_markets',
                useGlobalTimePeriod: true,
                analyticsRequests: [
                    // Note: All global variables like reportingEngine, report, filters, and projections
                    // are injected via AnalyticsRequest.getRequests()
                    new AnalyticsRequest({
                        limit: 3,
                        groupBys: [
                            new GroupBy({
                                LISTENING: { source: ListeningDimensions.topicName },
                                INBOUND_MESSAGE: { dimensionName: 'ACCOUNT_ID' },
                                PLATFORM: { dimensionName: 'ACCOUNT_ID' },
                                BENCHMARKING: { dimensionName: 'BRAND_ID' }, //BENCHMARKING_ACCOUNT_ID
                                PAID: { dimensionName: 'accountIds' },
                                STORY_MESSAGE: { dimensionName: 'STORY_CATEGORY' },
                            }),
                        ],
                        sorts: [
                            new Sort({ order: 'ASC', projections: 0 }),
                            new Sort({ order: 'ASC', groupBys: 0 }),
                        ],
                        timePeriods: [{ key: 'last_7_days' }, { previousPeriod: true }],
                    }) as any,
                ],
                position: {
                    width: '60%',
                    height: '50%',
                    top: '50%',
                    left: '0',
                    portrait: {
                        width: '100%',
                        height: '20%',
                        top: '75%',
                        left: '0',
                    },
                },
                label: {
                    titleText: 'Bottom Markets',
                    enabled: true,
                    size: WIDGET_LABEL_SIZE.secondary,
                },
                options: {
                    player: {
                        playing: true,
                        duration: 15,
                    },
                    themeClass: 'secondary_fill secondary_color',
                    countryCodeMap: {
                        'Australia 2': 'AU',
                        'Germany 2': 'DE',
                        'France 2': 'FR',
                        'Poland 2': 'PL',
                        'Italy 2': 'IT',
                        'Netherlands 2': 'NL',
                        'China 2': 'CN',
                        'Russia 2': 'RU',
                        'Canada 2': 'CA',
                        'Korea 2': 'KR',
                        'Spain 2': 'ES',
                        'Switzerland 2': 'CH',
                        'US 2': 'US',
                        'UK 2': 'GB',
                    },
                    labelSize: 12,

                    showMetricValue: true,
                    metricValueSize: 45,

                    showMetricName: true,
                    metricNameSize: 12,

                    showMetricChange: false,
                    metricPercentChangeSize: 14,
                    highlightPercentChange: false,

                    colorRanking: true,
                    animationDelay: 300,
                },
            },
            {
                type: 'bubbleChart',
                id: '3',
                name: 'bubble_chart',
                useGlobalTimePeriod: true,
                analyticsRequests: [
                    // Note: All global variables like reportingEngine, report, filters, and projections
                    // are injected via AnalyticsRequest.getRequests()
                    new AnalyticsRequest({
                        limit: 3,
                        groupBys: [
                            new GroupBy({
                                LISTENING: { source: ListeningDimensions.wordCloudMessage },
                                INBOUND_MESSAGE: { dimensionName: 'ACCOUNT_ID' },
                                PLATFORM: { dimensionName: 'ACCOUNT_ID' },
                                BENCHMARKING: { dimensionName: 'BRAND_ID' }, //BENCHMARKING_ACCOUNT_ID
                                PAID: { dimensionName: 'accountIds' },
                                STORY_MESSAGE: { dimensionName: 'WORD_CLOUD_MESSAGE' },
                            }),
                        ],
                        sorts: [
                            new Sort({ order: 'DESC', projections: 0 }),
                            new Sort({ order: 'ASC', groupBys: 0 }),
                        ],
                        timePeriods: [{ key: 'last_7_days' }, { previousPeriod: true }],
                    }) as any,
                ],
                position: {
                    width: '40%',
                    height: '100%',
                    top: '0',
                    right: '0',
                    portrait: {
                        width: '100%',
                        height: '50%',
                        top: '0',
                        left: '0',
                    },
                },
                label: {
                    titleText: 'Top Keywords',
                    overlap: true,
                    enabled: true,
                    size: WIDGET_LABEL_SIZE.secondary,
                },
                options: {
                    player: {
                        playing: true,
                        duration: 15,
                    },
                    labelSize: 25,
                    metricValueSize: 48,
                    metricPercentChangeSize: 14,
                    metricNameSize: 15,
                    highlightPercentChange: false,
                    showMetricValue: true,
                    showMetricName: true,
                    showMetricChange: false,
                    animationDelay: 700,
                },
            },
        ],
    },
};
