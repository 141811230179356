import { action, computed, observable } from 'mobx';
import { AnalyticsFilterType } from '@sprinklr/stories/analytics/AnalyticsRequest';
import { ChannelType, PostsRequest } from '@sprinklr/stories/post/PostsRequest';
import { ReportingLookupFilter } from '../../../services/SprinklrAPIService/SprinklrAPIService';
import { WidgetDimensionKey } from '../WidgetDimensionKey';
import { WidgetRequest } from '../WidgetRequest';
import { WidgetRequestPosts } from '../WidgetRequestPosts';
import { WidgetFilter } from './WidgetFilter';

/**
 * Wraps a PostsRequest.  Used to manage monitoring dashboard post requests.
 * For reporting post requests, see WidgetFilterAnalytics.
 */
export class WidgetFilterPosts extends WidgetFilter {
    @observable
    private id: PostsRequest;

    @observable
    private _values: string[] = [];

    private offset: number;
    private options = { metaParent: false, metaChild: false, oneToOne: true };
    private parent: WidgetRequestPosts;

    @observable
    allValues = false;

    constructor(parent: WidgetRequest, id: PostsRequest, offset: number) {
        super();

        this.parent = parent as WidgetRequestPosts;
        this.id = id;
        this.offset = offset;

        this.initialize();
    }

    static create(parent: WidgetRequestPosts, id: PostsRequest): void {
        parent.sourceGroup = 'DASHBOARD';
        parent.sourceGroups = [];

        const sources: WidgetFilter[] = [];
        for (let x = 0; x < 2; x++) {
            const filter = new WidgetFilterPosts(parent as any, id, x);

            if (x === 0) {
                filter.options.metaParent = true;
            } else {
                filter.options.metaChild = true;
            }

            if (filter.id.columnId) {
                filter._values.push(filter.id.columnId);
            }

            sources.push(filter);
        }

        parent.sources = sources;
        parent.filters = [];
    }

    @computed
    get isIncomplete(): boolean {
        return this._values.length === 0;
    }

    @computed
    get dimension(): WidgetDimensionKey {
        switch (this.offset) {
            case 0:
                return new WidgetDimensionKey('dashboard');

            default:
                return new WidgetDimensionKey('column');
        }
    }

    get additional(): any {
        return this.options ? this.options : null;
    }

    @computed
    get condition(): AnalyticsFilterType {
        return 'IN';
    }

    get type(): ChannelType {
        return this.id.columnType;
    }

    setType(type: ChannelType): void {
        this.id.columnType = type;
    }

    @computed
    get values(): string[] {
        return this._values;
    }

    setDimension(dimension: WidgetDimensionKey): void {}

    setCondition(condition: AnalyticsFilterType): void {}

    setValues(values: string[], filter?: ReportingLookupFilter): void {
        this._values.length = 0;
        this._values.push.apply(this._values, values);

        if (this.offset === 1 && values[0] && !this.parent.isLoading) {
            this.id.columnId = values[0];

            const dashboard = this.parent.getDashboard(this.id.columnId);
            if (dashboard) {
                this.parent.sources[0].setValue(
                    dashboard.id,
                    dashboard.name
                );

                const column = this.parent.getColumn(dashboard, this.id.columnId);
                if (column) {
                    this.setValue(column.id, column.name);
                }
            }
        }
    }

    @action
    public setValue(value: string, label: string) {
        this._values[0] = value;
    }

    @action
    private initialize() {
        switch (this.offset) {
            case 0:
                this.dimensionLabel = 'Dashboard';
                break;

            default:
                this.dimensionLabel = 'Column';
                break;
        }
    }

    @action
    selectAllValues(value: boolean) {
        this.allValues = value;
    }

    get all(): boolean {
        return this.allValues;
    }
}
