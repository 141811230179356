export default {
    happy: require('../../svgs/happy.svg'), // in both?
    sad: require('../../svgs/sad.svg'), // in both?

    contain: require('../../svgs/contain.svg'), //remove
    none: require('../../svgs/none.svg'), //remove
    cover: require('../../svgs/cover.svg'), //remove
    // 'postssinglepost_c': require('../../svgs/postsSinglePost_c.svg'), //remove

    // New UCG Stuff; No SVG manual edits
    cinematic_a: require('../../svgs/cinematic_a.svg'),
    cinematic_b: require('../../svgs/cinematic_b.svg'), //remove
    cinematic_c: require('../../svgs/cinematic_c.svg'), //remove
    filmstrip_a: require('../../svgs/filmstrip_a.svg'), //remove
    filmstrip_b: require('../../svgs/filmstrip_b.svg'), //remove
    filmstrip_c: require('../../svgs/filmstrip_c.svg'), //remove
    singlepost_a: require('../../svgs/singlepost_a.svg'), //remove
    singlepost_b: require('../../svgs/singlepost_b.svg'), //remove
    singlepost_c: require('../../svgs/singlepost_c.svg'), //remove

    collage_a: require('../../svgs/singlepost_b.svg'), //remove
    collage_b: require('../../svgs/singlepost_c.svg'), //remove

    user_type_a: require('../../svgs/user_type_a.svg'), //remove
    user_type_c: require('../../svgs/user_type_c.svg'), //remove
    user_type_d: require('../../svgs/user_type_d.svg'), //remove

    grid_a: require('../../svgs/singlepost_b.svg'), //remove
    grid_b: require('../../svgs/singlepost_c.svg'), //remove

    // Post tunables
    text_fit: require('../../svgs/text_fit.svg'), //remove
    text_clamp: require('../../svgs/text_clamp.svg'), //remove
    text_teleprompter: require('../../svgs/text_teleprompter.svg'), //remove

    // used by posts
    advocacy: require('../../svgs/social/advocacy.svg'),
    articles: require('../../svgs/social/articles.svg'),
    auctions: require('../../svgs/social/auctions.svg'),
    bazaarvoice: require('../../svgs/social/bazaarvoice.svg'),
    bing: require('../../svgs/social/bing.svg'),
    blog_comments: require('../../svgs/social/blog_comments.svg'),
    blogs: require('../../svgs/social/blogs.svg'),
    blogswebsites: require('../../svgs/social/blogs.svg'),
    case_management: require('../../svgs/social/case_management.svg'),
    clarabridge: require('../../svgs/social/clarabridge.svg'),
    clavis: require('../../svgs/social/clavis.svg'),
    crimson_hexagon: require('../../svgs/social/crimson_hexagon.svg'),
    disqus: require('../../svgs/social/disqus.svg'),
    exacttarget: require('../../svgs/social/exacttarget.svg'),
    facebook: require('../../svgs/social/facebook.svg'),
    facebook_filled: require('../../svgs/social/facebook_filled.svg'),
    facebook_ads: require('../../svgs/social/facebook_ads.svg'),
    facebook_comments: require('../../svgs/social/facebook_comments.svg'),
    facebook_domain: require('../../svgs/social/facebook_domain.svg'),
    facebook_business_manager: require('../../svgs/social/facebook_business_manager.svg'),
    facebook_group: require('../../svgs/social/facebook_group.svg'),
    facebook_likes: require('../../svgs/social/facebook_likes.svg'),
    facebook_page: require('../../svgs/social/facebook_page.svg'),
    facebook_share: require('../../svgs/social/facebook_share.svg'),
    facebook_unfilled: require('../../svgs/social/facebook_unfilled.svg'),
    flickr: require('../../svgs/social/flickr.svg'),
    forums: require('../../svgs/social/forums.svg'),
    foursquare: require('../../svgs/social/foursquare.svg'),
    foursquare_page: require('../../svgs/social/foursquare_page.svg'),
    foursquare_venue: require('../../svgs/social/foursquare_venue.svg'),
    friends_feed: require('../../svgs/social/friends_feed.svg'),
    get_satisfaction: require('../../svgs/social/get_satisfaction.svg'),
    google: require('../../svgs/social/google.svg'),
    google_adwords: require('../../svgs/social/google_adwords.svg'),
    google_ads_manager: require('../../svgs/social/google_ads_manager.svg'),
    google_ads: require('../../svgs/social/google_ads.svg'),
    google_plus: require('../../svgs/social/google_plus.svg'),
    google_plus_filled: require('../../svgs/social/google_plus_filled.svg'),
    google_plus_unfilled: require('../../svgs/social/google_plus_unfilled.svg'),
    gowalla: require('../../svgs/social/gowalla.svg'),
    heart: require('../../svgs/heart.svg'),
    instagram: require('../../svgs/social/instagram.svg'),
    instagram_comments: require('../../svgs/social/instagram_comments.svg'),
    instagram_likes: require('../../svgs/social/instagram_likes.svg'),
    jive: require('../../svgs/social/jive.svg'),
    jive_space: require('../../svgs/social/jive_space.svg'),
    jive_unfilled: require('../../svgs/social/jive_unfilled.svg'),
    linkedin: require('../../svgs/social/linkedin.svg'),
    linkedin_ads: require('../../svgs/social/linkedin_ads.svg'),
    linkedin_company: require('../../svgs/social/linkedin_company.svg'),
    linkedin_group: require('../../svgs/social/linkedin_group.svg'),
    listening: require('../../svgs/social/listening.svg'),
    livechat: require('../../svgs/social/livechat.svg'),
    lines: require('../../svgs/social/lines.svg'),
    line: require('../../svgs/social/line.svg'),
    xing: require('../../svgs/social/xing.svg'),
    marketo: require('../../svgs/social/marketo.svg'),
    mozilla: require('../../svgs/social/mozilla.svg'),
    news: require('../../svgs/social/news.svg'),
    outbound: require('../../svgs/social/outbound.svg'),
    outlook: require('../../svgs/social/outlook.svg'),
    paid: require('../../svgs/social/paid.svg'),
    pinterest: require('../../svgs/social/pinterest.svg'),
    pinterest_filled: require('../../svgs/social/pinterest_filled.svg'),
    pinterest_ads: require('../../svgs/social/pinterest_ads.svg'),
    reddit: require('../../svgs/social/reddit.svg'),
    renren: require('../../svgs/social/renren.svg'),
    retweet: require('../../svgs/social/retweet.svg'),
    reviews: require('../../svgs/social/reviews.svg'),
    rss_feeds: require('../../svgs/social/rss_feeds.svg'),
    salesforce: require('../../svgs/social/salesforce.svg'),
    sina_weibo: require('../../svgs/social/sina_weibo.svg'),
    site: require('../../svgs/social/site.svg'),
    slideshare: require('../../svgs/social/slideshare.svg'),
    social_asset: require('../../svgs/social/social_asset.svg'),
    suggestion: require('../../svgs/social/suggestion.svg'),
    survey_monkey: require('../../svgs/social/survey_monkey.svg'),
    tencent_weibo: require('../../svgs/social/tencent_weibo.svg'),
    'thumbs-up': require('../../svgs/thumbs-up.svg'),
    tumblr: require('../../svgs/social/tumblr.svg'),
    tumblr_unfilled: require('../../svgs/social/tumblr_unfilled.svg'),
    twitter: require('../../svgs/social/twitter.svg'),
    twitter_ads: require('../../svgs/social/twitter_ads.svg'),
    twitter_mentions: require('../../svgs/social/twitter.svg'),
    twitter_retweet: require('../../svgs/social/twitter_retweet.svg'),
    ugc: require('../../svgs/social/ugc.svg'),
    user_feedback: require('../../svgs/social/user_feedback.svg'),
    videos: require('../../svgs/social/videos.svg'),
    videos_comment: require('../../svgs/social/videos_comment.svg'),
    vk: require('../../svgs/social/vk.svg'),
    web: require('../../svgs/social/web.svg'),
    wechat: require('../../svgs/social/wechat.svg'),
    wordpress: require('../../svgs/social/wordpress.svg'),
    workflow: require('../../svgs/social/workflow.svg'),
    youtube: require('../../svgs/social/youtube.svg'),
    zendesk: require('../../svgs/social/zendesk.svg'),
    zimbra: require('../../svgs/social/zimbra.svg'),
    zimbra_forum: require('../../svgs/social/zimbra_forum.svg'),
    zimbra_community: require('../../svgs/social/zimbra_community.svg'),
    lithium: require('../../svgs/social/lithium.svg'),
    fpx: require('../../svgs/social/fpx.svg'),
    print: require('../../svgs/social/print.svg'),
    radio: require('../../svgs/social/radio.svg'),
    tv: require('../../svgs/social/tv.svg'),
    press_release: require('../../svgs/social/press_release.svg'),
    webinar: require('../../svgs/social/webinar.svg'),
    whitepaper: require('../../svgs/social/whitepaper.svg'),
    spr_task: require('../../svgs/social/spr_task.svg'),
    nike: require('../../svgs/social/nike.svg'),
    pluck: require('../../svgs/social/pluck.svg'),
    email: require('../../svgs/social/email.svg'),
    benchmarking: require('../../svgs/social/benchmarking.svg'),
    intranet: require('../../svgs/social/intranet.svg'),
    yahoo: require('../../svgs/social/yahoo.svg'),
    yahoo_ads: require('../../svgs/social/yahoo_ads.svg'),
    in_product_comm: require('../../svgs/social/in_product_comm.svg'),
    sap_hybris: require('../../svgs/social/sap_hybris.svg'),
    affiliate: require('../../svgs/social/affiliate.svg'),
    display: require('../../svgs/social/display.svg'),
    'display-chart': require('../../svgs/display-chart.svg'),
    in_store_event: require('../../svgs/social/in_store_event.svg'),
    live_event: require('../../svgs/social/live_event.svg'),
    mail: require('../../svgs/social/mail.svg'),
    mobile_advertising: require('../../svgs/social/mobile_advertising.svg'),
    mobile_app: require('../../svgs/social/mobile_app.svg'),
    mobile: require('../../svgs/social/mobile.svg'),
    out_of_home: require('../../svgs/social/out_of_home.svg'),
    partnership: require('../../svgs/social/partnership.svg'),
    sms: require('../../svgs/social/sms.svg'),
    website: require('../../svgs/social/website.svg'),
    under_armour: require('../../svgs/social/under_armour.svg'),
    blog: require('../../svgs/social/blog.svg'),
    phone: require('../../svgs/social/phone.svg'),
    search: require('../../svgs/social/search.svg'),
    gmail: require('../../svgs/social/gmail.svg'),
    email_draft: require('../../svgs/social/email_draft.svg'),
    sprinklr_commerce: require('../../svgs/social/sprinklr_commerce.svg'),
    profile: require('../../svgs/social/profile.svg'),
    feed: require('../../svgs/social/feed.svg'),
    assets_notification: require('../../svgs/social/assets_notification.svg'),
    generic_sms: require('../../svgs/social/generic_sms.svg'),
    twilio: require('../../svgs/social/twilio.svg'),
    cpgram: require('../../svgs/social/cpgram.svg'),
    snapchat_ads: require('../../svgs/social/snapchat_ads.svg'),
    snapchat: require('../../svgs/social/snapchat.svg'),
    google_apps_store: require('../../svgs/social/google_apps_store.svg'),
    community: require('../../svgs/social/community.svg'),
    kakao: require('../../svgs/social/kakao.svg'),
    kakao_story: require('../../svgs/social/kakao_story.svg'),
    sprinklr_alert: require('../../svgs/social/sprinklr_alert.svg'),
    microsoft_dynamics_crm: require('../../svgs/social/microsoft_dynamics_crm.svg'),
    linkedin_likes: require('../../svgs/social/linkedin_likes.svg'),
    linkedin_comments: require('../../svgs/social/linkedin_comments.svg'),
    linkedin_shares: require('../../svgs/social/linkedin_shares.svg'),
    twitter_replies: require('../../svgs/social/twitter_replies.svg'),
    youtube_comments: require('../../svgs/social/youtube_comments.svg'),
    youtube_likes: require('../../svgs/social/youtube_likes.svg'),

    'content-iframe': require('../../svgs/content/content-iframe.svg'), //remove
    'content-image': require('../../svgs/content/content-image.svg'), //remove
    'content-rich-text': require('../../svgs/content/content-rich-text.svg'), //remove
    'content-social-post': require('../../svgs/content/content-social-post.svg'), //remove
    'content-fit-text': require('../../svgs/content/content-fit-text.svg'), //remove
    'content-video': require('../../svgs/content/content-video.svg'), //remove

    'widget-asset': require('../../svgs/widget-asset.svg'), //remove
    'widget-chart': require('../../svgs/widget-chart.svg'), //remove
    'widget-composition': require('../../svgs/widget-composition.svg'), //remove
    'widget-link': require('../../svgs/widget-link.svg'), //remove
    'widget-link-button': require('../../svgs/widget-link-button.svg'), //remove
    'widget-text': require('../../svgs/widget-text.svg'), //remove
    'widget-ugc': require('../../svgs/widget-ugc.svg'), //remove
    'widget-shapes': require('../../svgs/widget-shapes.svg'), //remove
    'shape-rectangle': require('../../svgs/shape-rectangle.svg'), //remove
    'shape-ellipse': require('../../svgs/shape-ellipse.svg'), //remove
    'shape-line': require('../../svgs/shape-line.svg'), //remove
    'shape-polygon': require('../../svgs/shape-polygon.svg'), //remove
    'shape-triangle': require('../../svgs/shape-triangle.svg'), //remove

    'horizontal-align-center': require('../../svgs/content/horizontal-align-center.svg'), //remove
    'horizontal-align-left': require('../../svgs/content/horizontal-align-left.svg'), //remove
    'horizontal-align-right': require('../../svgs/content/horizontal-align-right.svg'), //remove
    'vertical-align-bottom': require('../../svgs/content/vertical-align-bottom.svg'), //remove
    'vertical-align-middle': require('../../svgs/content/vertical-align-middle.svg'), //remove
    'vertical-align-top': require('../../svgs/content/vertical-align-top.svg'), //remove

    // New
    'text-align-center': require('../../svgs/content/text-align-center.svg'), //remove
    'text-align-left': require('../../svgs/content/text-align-left.svg'), //remove
    'text-align-right': require('../../svgs/content/text-align-right.svg'), //remove
    'format-ordered-list': require('../../svgs/content/text-format-ordered-list.svg'), //remove
    'format-unordered-list': require('../../svgs/content/text-format-unordered-list.svg'), //remove
    'vert-align-bottom': require('../../svgs/content/text-format-vert-align-bottom.svg'), //remove
    'vert-align-middle': require('../../svgs/content/text-format-vert-align-center.svg'), //remove
    'vert-align-top': require('../../svgs/content/text-format-vert-align-top.svg'), //remove
    'text-style-bold': require('../../svgs/content/text-style-bold.svg'), //remove
    'text-style-italic': require('../../svgs/content/text-style-italic.svg'), //remove
    'text-style-quote': require('../../svgs/content/text-style-quote.svg'), //remove

    'size-full-page': require('../../svgs/size-full-page.svg'), //remove
    'size-left-half': require('../../svgs/size-left-half.svg'), //remove
    'size-right-half': require('../../svgs/size-right-half.svg'), //remove
    'size-top-half': require('../../svgs/size-top-half.svg'), //remove
    'size-bottom-half': require('../../svgs/size-bottom-half.svg'), //remove

    'size-centered-quarter': require('../../svgs/size-centered-quarter.svg'), //remove
    'size-top-right-quarter': require('../../svgs/size-top-right-quarter.svg'), //remove
    'size-top-left-quarter': require('../../svgs/size-top-left-quarter.svg'), //remove
    'size-bottom-right-quarter': require('../../svgs/size-bottom-right-quarter.svg'), //remove
    'size-bottom-left-quarter': require('../../svgs/size-bottom-left-quarter.svg'), //remove

    'size-centered-eighth': require('../../svgs/size-centered-eighth.svg'), //remove
    'size-top-right-eighth': require('../../svgs/size-top-right-eighth.svg'), //remove
    'size-top-left-eighth': require('../../svgs/size-top-left-eighth.svg'), //remove
    'size-bottom-right-eighth': require('../../svgs/size-bottom-right-eighth.svg'), //remove
    'size-bottom-left-eighth': require('../../svgs/size-bottom-left-eighth.svg'), //remove

    'arrange-backward': require('../../svgs/arrange-backward.svg'), //remove
    'arrange-forward': require('../../svgs/arrange-forward.svg'), //remove
    'arrange-back': require('../../svgs/arrange-back.svg'), //remove
    'arrange-front': require('../../svgs/arrange-front.svg'), //remove

    kr: require('../../svgs/kr.svg'),
    ca: require('../../svgs/ca.svg'),
    us: require('../../svgs/us.svg'),
    gb: require('../../svgs/gb.svg'),
    au: require('../../svgs/au.svg'),
    de: require('../../svgs/de.svg'),
    fr: require('../../svgs/fr.svg'),
    pl: require('../../svgs/pl.svg'),
    it: require('../../svgs/it.svg'),
    es: require('../../svgs/es.svg'),
    nl: require('../../svgs/nl.svg'),
    ru: require('../../svgs/ru.svg'),
    cn: require('../../svgs/cn.svg'),
    ch: require('../../svgs/ch.svg'),

    launchpad: require('../../svgs/launchpad.svg'), //remove
    // 'code': require('../../svgs/code.svg'),//remove
    chart: require('../../svgs/data.svg'), //remove
    eyedropper0: require('../../svgs/design.svg'), //remove
    notification: require('../../svgs/notification.svg'), //remove
    settings: require('../../svgs/settings.svg'), //remove
    search3: require('../../svgs/search3.svg'), //remove
    hamburger: require('../../svgs/hamburger.svg'), //remove
    'burger-check': require('../../svgs/burger-check.svg'), //remove
    logout: require('../../svgs/logout.svg'), //remove
    'icon-planner': require('../../svgs/icon-planner.svg'),

    'chevron-right': require('../../svgs/chevron-right.svg'), //remove
    'chevron-left': require('../../svgs/chevron-left.svg'), //remove
    'chevron-down': require('../../svgs/chevron-down.svg'), //remove
    'chevron-up': require('../../svgs/chevron-up.svg'), //remove
    'chevron-double-left': require('../../svgs/chevron-double-left.svg'), //remove
    'chevron-double-right': require('../../svgs/chevron-double-right.svg'), //remove
    'chevron-double-up': require('../../svgs/chevron-double-up.svg'), //remove
    'chevron-double-down': require('../../svgs/chevron-double-down.svg'), //remove
    export: require('../../svgs/export.svg'), //remove

    // builder ui icons
    add: require('../../svgs/add.svg'), //remove
    arrange: require('../../svgs/arrange.svg'), //remove
    'arrow-left': require('../../svgs/arrow-left.svg'), //remove
    'arrow-right': require('../../svgs/arrow-right.svg'), //remove
    'arrow-up': require('../../svgs/arrow-up.svg'), //remove
    'arrow-down': require('../../svgs/arrow-down.svg'), //remove
    'background-image': require('../../svgs/background-image.svg'), //remove
    edit: require('../../svgs/edit.svg'), //remove
    minus: require('../../svgs/minus.svg'), //remove
    'minus-alt': require('../../svgs/minus-alt.svg'), //remove
    plus: require('../../svgs/plus.svg'), //remove
    'plus-alt': require('../../svgs/plus-alt.svg'), //remove
    check: require('../../svgs/check.svg'), //remove
    'check-alt': require('../../svgs/check-alt.svg'), //remove
    bell: require('../../svgs/bell.svg'), //remove
    calendar: require('../../svgs/calendar.svg'), //remove
    camera: require('../../svgs/camera.svg'), //remove
    clock: require('../../svgs/clock.svg'), //remove
    cog: require('../../svgs/cog.svg'), //remove
    clone: require('../../svgs/clone.svg'), //remove
    filter: require('../../svgs/filter.svg'), //remove
    frown: require('../../svgs/frown.svg'), //remove
    hidden: require('../../svgs/hidden.svg'), //remove
    key: require('../../svgs/key.svg'), //remove
    lock: require('../../svgs/lock.svg'), //remove
    movie: require('../../svgs/movie.svg'), //remove
    paintcan: require('../../svgs/paintcan.svg'), //remove
    pen: require('../../svgs/pen.svg'), //remove
    pin: require('../../svgs/pin.svg'), //remove
    preview: require('../../svgs/preview.svg'), //remove
    resize: require('../../svgs/resize.svg'), //remove
    search2: require('../../svgs/search2.svg'), //remove
    share: require('../../svgs/share.svg'), //remove
    smile: require('../../svgs/smile.svg'), //remove
    splash: require('../../svgs/splash.svg'), //remove
    star: require('../../svgs/star.svg'), //remove
    switch_client: require('../../svgs/switch_client.svg'), //remove
    tag: require('../../svgs/tag.svg'), //remove
    unlock: require('../../svgs/unlock.svg'), //remove
    user: require('../../svgs/user.svg'), //remove
    versions: require('../../svgs/versions.svg'), //remove
    'versions-alt': require('../../svgs/versions-alt.svg'), //remove
    wondering: require('../../svgs/wondering.svg'), //remove
    pdf: require('../../svgs/pdf.svg'), //remove
    'pdf-file': require('../../svgs/pdf-file.svg'), //remove
    'pptx-file': require('../../svgs/pptx-file.svg'), //remove

    'color-blend': require('../../svgs/color-blend.svg'),
    monochrome: require('../../svgs/monochrome.svg'),
    solid: require('../../svgs/solid.svg'),
    ranked: require('../../svgs/ranked.svg'),

    bars: require('../../svgs/bars.svg'), //remove
    'bars-alt': require('../../svgs/bars-alt.svg'), //remove
    equalizer: require('../../svgs/equalizer.svg'), //remove
    grid: require('../../svgs/grid.svg'), //remove
    image: require('../../svgs/image.svg'), //remove
    x: require('../../svgs/x.svg'), //remove
    'x-alt': require('../../svgs/x-alt.svg'), //remove
    // 'animation':    require('../../svgs/animation.svg'),//remove
    order: require('../../svgs/order.svg'), //remove
    time: require('../../svgs/time.svg'), //remove
    trash: require('../../svgs/trash.svg'), //remove
    growth: require('../../svgs/growth.svg'), //remove

    'mc-content': require('../../svgs/mc-content.svg'), //remove
    'mc-growth': require('../../svgs/mc-growth.svg'), //remove
    'mc-reaction': require('../../svgs/mc-reaction.svg'), //remove

    'grid-view': require('../../svgs/grid-view.svg'), //remove
    link: require('../../svgs/link.svg'), //remove
    'thumb-view': require('../../svgs/thumb-view.svg'), //remove
    list: require('../../svgs/list.svg'), //remove
    '4k': require('../../svgs/4k.svg'), //remove
    hd: require('../../svgs/hd.svg'), //remove

    'single-display': require('../../svgs/display-single.svg'), //remove
    'display-wall': require('../../svgs/display-wall.svg'), //remove
    'distributed-display': require('../../svgs/display-single-distributed.svg'), //remove
    'distributed-display-wall': require('../../svgs/display-wall-distributed.svg'), //remove
    custom: require('../../svgs/display-custom.svg'), //remove

    iphone: require('../../svgs/embed/iphone.svg'), //remove
    ipad: require('../../svgs/embed/ipad.svg'), //remove
    laptop: require('../../svgs/embed/laptop.svg'), //remove
    desktop: require('../../svgs/embed/desktop.svg'), //remove

    'carousel-vertical': require('../../svgs/embed/carousel-vertical.svg'), //remove
    'carousel-horizontal': require('../../svgs/embed/carousel-horizontal.svg'), //remove
    'slider-horizontal': require('../../svgs/embed/slider-horizontal.svg'), //remove
    cascade: require('../../svgs/embed/cascade.svg'), //remove
    tile: require('../../svgs/embed/tile.svg'), //remove
    editorial: require('../../svgs/embed/editorial.svg'), //remove
    simple: require('../../svgs/embed/simple.svg'), //remove

    'gallery-carousel': require('../../svgs/embed/gallery-carousel.svg'), //remove
    'gallery-thumbs': require('../../svgs/embed/gallery-thumbs.svg'), //remove

    'card-basic': require('../../svgs/embed/card-basic.svg'),
    'card-stylish': require('../../svgs/embed/card-stylish.svg'),
    'card-vertical': require('../../svgs/embed/card-vertical.svg'),
    'color-group-preview': require('../../svgs/color-group-preview.svg'),
    'sprinklr-logo': require('../../svgs/sprinklr-logo.svg'),
    'sprinklr-logo-powered': require('../../svgs/sprinklr-logo-powered-by.svg'),
    'sprinklr-wordmark': require('../../svgs/sprinklr-wordmark.svg'),
    fullscreen: require('../../svgs/fullscreen.svg'),

    // added for sidebar
    animation: require('../../svgs/animation-icon.svg'), //remove
    avatar: require('../../svgs/avatar-icon.svg'), //remove
    code: require('../../svgs/code-icon.svg'), //remove
    compostion: require('../../svgs/compostion-icon.svg'), //remove
    'clear-theme-value': require('../../svgs/clear-theme-value.svg'), //remove
    design: require('../../svgs/design-icon.svg'), //remove
    engagement: require('../../svgs/engagement.svg'), //remove
    'image-adjustment': require('../../svgs/image-adjustment.svg'), //remove
    'image-fallback': require('../../svgs/space/image-fallback.svg'),
    'inherit-theme': require('../../svgs/inherit-theme.svg'), //remove
    layout: require('../../svgs/layout-icon.svg'), //remove
    legend: require('../../svgs/legend-icon.svg'), //remove
    'line-chart-cardinal': require('../../svgs/line-chart-cardinal.svg'), //remove
    'line-chart-linear': require('../../svgs/line-chart-linear.svg'), //remove
    'line-chart-step-after': require('../../svgs/line-chart-step-after.svg'), //remove
    'placement-bottom-left': require('../../svgs/placement-bottom-left.svg'), //remove
    'placement-bottom-right': require('../../svgs/placement-bottom-right.svg'), //remove
    'placement-top-left': require('../../svgs/placement-top-left.svg'), //remove
    'placement-top-right': require('../../svgs/placement-top-right.svg'), //remove
    'revert-clear': require('../../svgs/revert-clear.svg'), //remove
    'revert-history': require('../../svgs/revert-history.svg'), //remove
    'revert-theme-overwrite': require('../../svgs/revert-theme-overwrite.svg'), //remove
    'scene-duration': require('../../svgs/scene-duration.svg'), //remove
    'stacked-bar-chart-series': require('../../svgs/stacked-bar-chart-series.svg'), //remove
    'stacked-bar-chart-stacked-100': require('../../svgs/stacked-bar-chart-stacked-100.svg'), //remove
    'stacked-bar-chart-stacked': require('../../svgs/stacked-bar-chart-stacked.svg'), //remove
    'stacked-column-chart-series': require('../../svgs/stacked-column-chart-series.svg'), //remove
    'stacked-column-chart-stacked-100': require('../../svgs/stacked-column-chart-stacked-100.svg'), //remove
    'stacked-column-chart-stacked': require('../../svgs/stacked-column-chart-stacked.svg'), //remove
    'text-entities': require('../../svgs/text-entities.svg'), //remove
    themes: require('../../svgs/themes-icon.svg'), //remove
    thresholds: require('../../svgs/thresholds.svg'), //remove
    tunables: require('../../svgs/tunables.svg'), //remove
    typography: require('../../svgs/typography.svg'), //remove
    layers: require('../../svgs/layers.svg'), //remove
    layouts: require('../../svgs/layouts.svg'), //remove
    eyedropper: require('../../svgs/eyedropper.svg'), //remove
    grid2: require('../../svgs/grid2.svg'), //remove
    // 'grid': require('../../svgs/grid.svg'),//remove

    'post-comp': require('../../svgs/post-comp.svg'), //remove
    'add-widget': require('../../svgs/add-widget.svg'), //remove
    duration: require('../../svgs/duration.svg'), //remove
    'widget-error': require('../../svgs/widget-error.svg'), //remove
    'widget-no-data': require('../../svgs/widget-no-data.svg'), //remove
};
