import { ComboLayer } from '@sprinklr/stories/widget/WidgetOptions';
import { AnalyticsRequest, Metric, GroupBy, Sort } from './PanelTemplatesTheme/PanelTemplatesTheme';
import { DefaultWidgetPosition } from './PanelTemplatesTheme/constants';
import { Widget } from '@sprinklr/stories/widget/Widget';
import { WIDGET_LABEL_SIZE } from 'components/Widget/WidgetLabel/constants';
import ListeningDimensions from '@sprinklr/stories-services/AnalyticsService/ListeningDimensions';
import ListeningMetrics from '@sprinklr/stories-services/AnalyticsService/ListeningMetrics';
import { ComboLineOptionsImpl } from '../../widgets/ComboChartWidget/options';
import { TEXT_SHADOW_DEFAULT } from 'models/Widget/Widget';

export var WidgetDualAxis: Widget = {
    type: 'dualAxis',
    id: 'dual-axis',
    name: 'Dual Axis',
    useGlobalTimePeriod: true,
    analyticsRequests: [
        // Note: All global variables like reportingEngine, report, filters, and projections
        // are injected via AnalyticsRequest.getRequests()
        new AnalyticsRequest({
            limit: 100,
            projections: [
                new Metric({
                    LISTENING: { source: ListeningMetrics.mentions },
                    INBOUND_MESSAGE: { dimensionName: 'OUTBOUND_COUNT', heading: 'Inbound Count' },
                    PLATFORM: {
                        dimensionName: 'PUBLISHED_MESSAGE_COUNT',
                        heading: 'Volume Of Published Messages',
                    },
                    BENCHMARKING: {
                        dimensionName: 'PUBLISHED_MESSAGE_COUNT',
                        heading: 'Volume Of Published Messages',
                    },
                    PAID: { dimensionName: 'spent', heading: 'Spent' },
                    ADVOCACY: {
                        dimensionName: 'ADVOCACY_TOTAL_USER_ENGAGEMENTS',
                        heading: 'Total_Engagements',
                    },
                    RDB_FIREHOSE: {
                        dimensionName: 'REACH_COUNT',
                        heading: 'Reach',
                    },
                    STORY_MESSAGE: {
                        dimensionName: 'WEB_SHARES_OVERALL',
                        aggregateFunction: 'AVG',
                    },
                }),
                new Metric({
                    LISTENING: { source: ListeningMetrics.reach },
                    INBOUND_MESSAGE: { dimensionName: 'INBOUND_COUNT', heading: 'Inbound Count' },
                    PLATFORM: { dimensionName: 'TOTAL_ENGAGEMENT', heading: 'Total Engagement' },
                    BENCHMARKING: {
                        dimensionName: 'TOTAL_ENGAGEMENT',
                        heading: 'Total Engagement',
                    },
                    PAID: { dimensionName: 'clicks', heading: 'Clicks' },
                    ADVOCACY: {
                        dimensionName: 'TOTAL_POINTS_EARNED',
                        heading: 'Total_Points_Earned',
                        origReport: 'COMMUNITY_USER',
                    },
                    RDB_FIREHOSE: {
                        dimensionName: 'MENTIONS_COUNT',
                        heading: 'Mentions',
                    },
                    STORY_MESSAGE: {
                        dimensionName: 'WEB_REACH',
                        heading: 'Web_Reach',
                    },
                }),
            ],
            groupBys: [
                new GroupBy({
                    LISTENING: { source: ListeningDimensions.day },
                    INBOUND_MESSAGE: { source: ListeningDimensions.day, dimensionName: 'snCTm' },
                    PLATFORM: { source: ListeningDimensions.day, dimensionName: 'date' },
                    BENCHMARKING: { source: ListeningDimensions.day, dimensionName: 'date' },
                    PAID: { source: ListeningDimensions.day, dimensionName: 'measurementTime' },
                    ADVOCACY: { source: ListeningDimensions.day, dimensionName: 'date' },
                    RDB_FIREHOSE: {
                        source: ListeningDimensions.day,
                        dimensionName: 'SN_CREATED_TIME',
                    },
                    STORY_MESSAGE: {
                        source: ListeningDimensions.day,
                        dimensionName: 'CREATED_TIME',
                    },
                }),
            ],
            sorts: [new Sort({ order: 'DESC', projections: 0 })],
            timePeriods: [{ key: 'last_7_days' }, { previousPeriod: true }],
        }) as any,
    ],
    label: {
        titleText: 'Dual Axis',
        enabled: false,
        size: WIDGET_LABEL_SIZE.primary,
        textShadow: TEXT_SHADOW_DEFAULT,
    },
    position: DefaultWidgetPosition,
    options: {
        player: {
            playing: true,
            duration: 15,
        },
        smartSize: {
            enabled: true,
            mode: 'responsive',
            size: 'm',
        },
        comboLayers: [
            {
                name: '',
                metrics: [''],
                displayName: '',
                chartType: 'comboLine',
                options: new ComboLineOptionsImpl(),
            },
            {
                name: '',
                metrics: [''],
                displayName: '',
                chartType: 'comboLine',
                options: new ComboLineOptionsImpl(),
            },
        ] as ComboLayer[],
    },
};
