import { AnalyticsRequest, GroupBy, Metric, Sort } from './PanelTemplatesTheme/PanelTemplatesTheme';
import { Panel } from '../../models/Panel/Panel';
import { WIDGET_LABEL_SIZE } from '../../components/Widget/WidgetLabel/constants';
import ListeningDimensions from '@sprinklr/stories-services/AnalyticsService/ListeningDimensions';

export var TemplateColumnChart: Panel = {
    id: 'column-chart',
    name: 'Column Chart',
    description: '',
    tags: ['Standard', 'Column Chart', 'landscape', 'portrait'],
    widget: {
        id: '1',
        position: {
            width: '1920px',
            height: '1080px',
            top: '0',
            left: '0',
        },
        classes: 'template_column_chart',
        children: [
            {
                type: 'barChart',
                id: '1',
                name: 'Column Chart',
                useGlobalTimePeriod: true,
                analyticsRequests: [
                    // Note: All global variables like reportingEngine, report, filters, and projections
                    // are injected via AnalyticsRequest.getRequests()
                    new AnalyticsRequest({
                        limit: 10,
                        projections: [
                            new Metric({
                                STORY_MESSAGE: { dimensionName: 'WEB_REACH', heading: 'Web_Reach' },
                            }),
                        ],
                        groupBys: [
                            new GroupBy({
                                LISTENING: { source: ListeningDimensions.topicName },
                                INBOUND_MESSAGE: { dimensionName: 'ACCOUNT_ID' },
                                PLATFORM: { dimensionName: 'ACCOUNT_ID' },
                                BENCHMARKING: { dimensionName: 'BRAND_ID' }, //BENCHMARKING_ACCOUNT_ID
                                PAID: { dimensionName: 'accountIds' },
                                ADVOCACY: { dimensionName: 'COMMUNITY_USER_ID' },
                                TWITTER: { dimensionName: 'TRENDING_TOPIC' },
                                RDB_FIREHOSE: { dimensionName: 'RDB_QUERY_IDS' },
                                STORY_MESSAGE: { dimensionName: 'PUBLICATION_NAME' },
                            }),
                        ],
                        sorts: [
                            new Sort({ order: 'DESC', projections: 0 }),
                            new Sort({ order: 'ASC', groupBys: 0 }),
                        ],
                        timePeriods: [{ key: 'last_7_days' }, { previousPeriod: true }],
                    }) as any,
                ],
                position: {
                    width: '100%',
                    height: '100%',
                    top: '0',
                    left: '0',
                },
                label: {
                    titleText: 'Column Chart',
                    enabled: true,
                    size: WIDGET_LABEL_SIZE.primary,
                },
                options: {
                    player: {
                        playing: true,
                        duration: 15,
                    },
                    orientation: 'horizontal',
                    labelSize: 50,
                    labelEnable: false,
                    deprecated: {
                        labelEnable: true,
                    },
                    metricValueSize: 50,
                    metricPercentChangeSize: 17,
                    metricNameSize: 15,
                    highlightPercentChange: false,
                    showMetricValue: true,
                    showMetricName: true,
                    showMetricChange: false,
                    showSentiment: false,
                    smartSize: {
                        enabled: true,
                        mode: 'responsive',
                        size: 'xl',
                    },
                    portrait: {
                        widgetOrientation: 'portrait',
                        limit: 3,
                        metricNameSize: 15,
                        labelSize: 20,
                    },
                    xAxis: {
                        enabled: true,
                        label: {
                            enabled: false,
                            size: 50,
                            padding: 20,
                        },
                        ticks: {
                            enabled: true,
                            label: {
                                enabled: true,
                                size: 50,
                                numberFormat: '1.2k',
                                timeFormat: 'ddd ha',
                                angle: 0,
                            },
                            line: {
                                enabled: false,
                            },
                        },
                    },
                    yAxis: {
                        enabled: false,
                        label: {
                            enabled: true,
                            size: 50,
                            padding: 20,
                        },
                        ticks: {
                            enabled: true,
                            label: {
                                enabled: false,
                                size: 50,
                                numberFormat: '1.2k',
                                timeFormat: 'ddd ha',
                            },
                            line: {
                                enabled: true,
                            },
                        },
                    },
                },
            },
        ],
    },
};
