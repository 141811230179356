import * as React from 'react';
import i18n from 'i18next';
import {
    headerTunables,
    opacityTunables,
    zoomTunables,
    contentBackgroundColorTunable,
} from 'components/Sidebar/RenderTunables/SharedTunables';
import { TunablesCollapse, CollapseProps } from 'components/Sidebar/RenderTunables/RenderTunables';
import { videoBackgroundSizeOptions } from 'components/Panel/PanelEditorSidebar/PanelDesign/PanelDesignOptions';
import LabelGroup from '../../components/_UI/Forms/LabelGroup/LabelGroup';
import { Widget } from '@sprinklr/stories/widget/Widget';
import ActionInput from 'components/_UI/Forms/Actions/ActionInput';
import ActionSegmentedGroup from 'components/_UI/Forms/Actions/ActionSegmentedGroup';
import ActionCheckboxGroup from 'components/_UI/Forms/Actions/ActionCheckboxGroup';

const CustomContentVideoTunables = (widget: Widget): TunablesCollapse[] => [
    {
        props: {
            label: i18n.t('Video'),
            startOpen: true,
        } as CollapseProps,
        children: [...headerTunables(widget, { header: true }), opacityTunables(widget)],
    },
    {
        props: {
            label: i18n.t('Content'),
            startOpen: true,
        },
        children: [
            contentBackgroundColorTunable(widget),
            <LabelGroup label={i18n.t('Video Url')} key='widget.options.contentVideoUrl'>
                <ActionInput option={widget.options} value='contentVideoUrl' />
            </LabelGroup>,
            <ActionSegmentedGroup
                key='widget.options.contentImageFit'
                label={i18n.t('Video Fit')}
                option={widget.options}
                value={'contentImageFit'}
                options={videoBackgroundSizeOptions}
            />,
            <ActionCheckboxGroup
                key='widget.options.loop'
                label={i18n.t('Loop')}
                option={widget.options}
                value='contentVideoLoop'
            />,
        ],
    },
    zoomTunables(widget, { zoom: true, clip: true }),
];

export default CustomContentVideoTunables;
