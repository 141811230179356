import { Version, VersionFields } from '../Version/Version';
import { Panel, PanelImpl } from './Panel';
import { CommonDocFields } from '../CommonDoc/CommonDoc';
import { ThemeImpl } from '../Theme/Theme';
import { observable } from 'mobx';
import { UserAssetFields } from '../UserAsset/UserAsset';

export interface PanelVersion extends Panel, Version {
    previewHtml?: string;
    previewHtmlUrl?: string;
}

export class PanelVersionImpl extends PanelImpl implements PanelVersion {
    @observable masterId?: string;
    @observable treeDirty: boolean;
    @observable immutable?: boolean;
    @observable savedByUserId?: number;
    @observable previewHtml?: string;
    @observable previewHtmlUrl?: string;
    @observable saved?: number;
    @observable theme?: ThemeImpl;
    @observable overrideGlobalTimePeriod?: boolean;
}

export const PanelVersionFields = `
    ${CommonDocFields}
    ${VersionFields}
    previewImageUrl
    previewImageDirty
    previewHtmlUrl
    storyboardId
    sceneId
    theme {
        ...themeFrag
    }
    widget {
        ...widgetRootNodeType
    }
    userAssets {
        ${UserAssetFields}
    }
`;

export const PanelVersionLightFields = `
    id
    clientId
    partnerId
    masterId
    sceneId
    storyboardId
    previewImageUrl
    previewImageDirty
    created
    updated
    userUpdated
    treeDirty
`;
