import { Mapper } from '../DataStore/DataStore';
import { GraphQLService } from '../GraphQLService/GraphQLService';
import { BaseRecordService } from '../../utils/BaseRecordService/BaseRecordService';
import { GraphQLQueries, ModelName } from '../GraphQLQueries/GraphQLQueries';
import { PanelVersion, PanelVersionFields } from '../../models/Panel/PanelVersion';
import { UserAsset, UserAssetFields } from '../../models/UserAsset/UserAsset';
import { CommonDoc } from '@sprinklr/stories/common/CommonDoc';

const MODELNAME: ModelName = 'panelVersion';

// note this is a partial update of the PanelVersion record, this is deliberate. Avoids problem in PanelEditor where
// we've not yet saved with the new user asset widget. Also lighter - all we're doing is updating the panelVersion's
// userAsset list anyway.
const addUserAssetToPanelVersion = `
mutation AddUserAssetToPanelVersion($input: AddUserAssetToPanelVersionInput!) {
    addUserAssetToPanelVersion(input: $input) {
        panelVersion {
            id updated
            userAssets {
                ${UserAssetFields}
            }
        }
        clientMutationId
    }
}
`;

export class PanelVersionService extends BaseRecordService<PanelVersion> {
    private queries: GraphQLQueries;

    constructor(graphQL: GraphQLService, mapper: Mapper<PanelVersion>) {
        super(graphQL, mapper);
        this.queries = new GraphQLQueries();
    }

    inject(panel: PanelVersion) {
        this.mapper.create(panel);
    }

    find(id: string | number): Promise<PanelVersion> {
        return this.query(this.queries.queryDescriptor('panelVersion', MODELNAME, { id }));
    }

    update(panelVersion: PanelVersion): Promise<PanelVersion> {
        if (!panelVersion) {
            throw new Error('PanelVersion parameter is required.');
        }

        if (!panelVersion.id) {
            throw new Error('PanelVersion must have id.');
        }

        if (!panelVersion.masterId) {
            throw new Error('PanelVersion must have masterId.');
        }

        if (!panelVersion.storyboardId) {
            throw new Error('Panel must have storyboardId.');
        }

        return this.mutate(
            this.queries.mutationDescriptor('updatePanelVersion', MODELNAME, { panelVersion })
        );
    }

    abandonDraft(panelVersion: PanelVersion): Promise<PanelVersion> {
        return this.mutate(
            this.queries.mutationDescriptor('abandonPanelVersion', MODELNAME, { panelVersion })
        );
    }

    addUserAsset(
        panelVersion: PanelVersion | string,
        userAsset: UserAsset | string
    ): Promise<PanelVersion> {
        return this.mutate({
            queryParams: {
                query: addUserAssetToPanelVersion,
                variables: {
                    input: {
                        panelVersionId: this.id(panelVersion),
                        userAssetId: this.id(userAsset),
                    },
                },
            },
            extractor: (result: any) => result.addUserAssetToPanelVersion.panelVersion,
        });
    }

    private id(record: CommonDoc | string): string {
        if (typeof record === 'string') {
            return record;
        }

        return record && record.id;
    }
}
