import { AnalyticsRequest, Metric, GroupBy, Sort } from './PanelTemplatesTheme/PanelTemplatesTheme';
import { Panel } from '../../models/Panel/Panel';
import { WIDGET_LABEL_SIZE } from '../../components/Widget/WidgetLabel/constants';
import ListeningDimensions from '@sprinklr/stories-services/AnalyticsService/ListeningDimensions';
import ListeningMetrics from '@sprinklr/stories-services/AnalyticsService/ListeningMetrics';

export var TemplateBrandHealth: Panel = {
    id: 'brand-health',
    name: 'Brand Health',
    description: '',
    tags: ['Smart', 'Brand Health', 'landscape', 'portrait'],
    widget: {
        id: '1',
        position: {
            width: '1920px',
            height: '1080px',
            top: '0',
            left: '0',
        },
        classes: 'template_brand_health',
        children: [
            {
                type: 'metric',
                id: '1',
                name: 'metric',
                position: {
                    width: '33.33%',
                    height: '30%',
                    top: '0',
                    left: '0',
                },
                useGlobalTimePeriod: true,
                analyticsRequests: [
                    // Note: All global variables like reportingEngine, report, and filters
                    // are injected via AnalyticsRequest.getRequests()
                    new AnalyticsRequest({
                        limit: 1,
                        projections: [
                            new Metric({
                                LISTENING: { source: ListeningMetrics.distinctUsers },
                                INBOUND_MESSAGE: { dimensionName: 'INBOUND_COUNT' },
                                PLATFORM: { dimensionName: 'TOTAL_ENGAGEMENT' },
                                BENCHMARKING: { dimensionName: 'TOTAL_ENGAGEMENT' },
                                PAID: { dimensionName: 'ENGAGEMENT' },
                                ADVOCACY: {
                                    dimensionName: 'ADVOCACY_TOTAL_USER_ENGAGEMENTS',
                                    heading: 'Total_Engagements',
                                },
                                RDB_FIREHOSE: {
                                    dimensionName: 'DISTINCT_USER_COUNT',
                                    heading: 'Distinct Users',
                                },
                                STORY_MESSAGE: {
                                    dimensionName: 'STORY_ACTIVE_COUNT',
                                    heading: 'Story Count',
                                },
                            }),
                        ],
                        groupBys: [],
                        sorts: [],
                        timePeriods: [{ key: 'last_7_days' }, { previousPeriod: true }],
                    }) as any,
                ],
                label: {
                    titleText: '',
                    enabled: false,
                    size: WIDGET_LABEL_SIZE.secondary,
                },
                options: {
                    player: {
                        playing: true,
                        duration: 15,
                    },
                    showIcon: true,

                    showMetricValue: true,
                    metricValueSize: 45,

                    showMetricName: true,
                    metricNameSize: 15,

                    showMetricChange: true,
                    metricPercentChangeSize: 10,
                    highlightPercentChange: false,
                    animationDelay: 0,
                },
            },
            {
                type: 'metric',
                id: '2',
                name: 'metric',
                position: {
                    width: '33.33%',
                    height: '30%',
                    top: '0',
                    left: '33.33%',
                    portrait: {
                        width: '33.33%',
                        height: '30%',
                        top: '33.33%',
                        left: '0',
                    },
                },
                useGlobalTimePeriod: true,
                analyticsRequests: [
                    // Note: All global variables like reportingEngine, report, and filters
                    // are injected via AnalyticsRequest.getRequests()
                    new AnalyticsRequest({
                        limit: 1,
                        projections: [
                            new Metric({
                                LISTENING: { source: ListeningMetrics.mentions },
                                INBOUND_MESSAGE: { dimensionName: 'OUTBOUND_COUNT' },
                                PLATFORM: { dimensionName: 'POST_REACH_COUNT' },
                                BENCHMARKING: {
                                    dimensionName: 'MESSAGE_COUNT',
                                    report: 'BENCHMARKING_SIGNAL',
                                },
                                PAID: {
                                    dimensionName: 'uniqueReach',
                                    report: 'PAID_UNIQUE_MEASUREMENTS_STAT',
                                },
                                ADVOCACY: {
                                    dimensionName: 'TOTAL_POINTS_EARNED',
                                    heading: 'Total_Points_Earned',
                                    report: 'COMMUNITY_USER',
                                },
                                STORY_MESSAGE: {
                                    dimensionName: 'MENTIONS_COUNT',
                                    heading: 'Mentions',
                                },
                            }),
                        ],
                        groupBys: [],
                        sorts: [],
                        timePeriods: [{ key: 'last_7_days' }, { previousPeriod: true }],
                    }) as any,
                ],
                label: {
                    titleText: '',
                    enabled: false,
                    size: WIDGET_LABEL_SIZE.secondary,
                },
                options: {
                    player: {
                        playing: true,
                        duration: 15,
                    },
                    showIcon: true,

                    showMetricValue: true,
                    metricValueSize: 45,

                    showMetricName: true,
                    metricNameSize: 15,

                    showMetricChange: true,
                    metricPercentChangeSize: 10,
                    highlightPercentChange: false,
                    animationDelay: 150,
                },
            },
            {
                type: 'metric',
                id: '3',
                name: 'metric',
                position: {
                    width: '33.33%',
                    height: '30%',
                    top: '0',
                    left: '66.66%',
                    portrait: {
                        width: '33.33%',
                        height: '30%',
                        top: '66.66%',
                        left: '0',
                    },
                },
                useGlobalTimePeriod: true,
                analyticsRequests: [
                    // Note: All global variables like reportingEngine, report, and filters
                    // are injected via AnalyticsRequest.getRequests()
                    new AnalyticsRequest({
                        limit: 1,
                        projections: [
                            new Metric({
                                LISTENING: { source: ListeningMetrics.reach },
                                INBOUND_MESSAGE: { dimensionName: 'LIKE_COUNT' },
                                PLATFORM: {
                                    dimensionName: 'FOLLOWERS_COUNT',
                                    report: 'ACCOUNT_INSIGHTS',
                                },
                                BENCHMARKING: {
                                    dimensionName: 'subscriberCountTotal',
                                    report: 'ACCOUNT_FACTS',
                                },
                                PAID: { dimensionName: 'positiveSentimentCount' },
                                ADVOCACY: {
                                    dimensionName: 'ADVOCACY_TOTAL_USER_LOGIN',
                                    heading: 'Total_Logins',
                                },
                                STORY_MESSAGE: {
                                    dimensionName: 'REACH',
                                    heading: 'Reach',
                                },
                            }),
                        ],
                        groupBys: [],
                        sorts: [],
                        timePeriods: [{ key: 'last_7_days' }, { previousPeriod: true }],
                    }) as any,
                ],
                label: {
                    titleText: '',
                    enabled: false,
                    size: WIDGET_LABEL_SIZE.secondary,
                },
                options: {
                    player: {
                        playing: true,
                        duration: 15,
                    },
                    showIcon: true,

                    showMetricValue: true,
                    metricValueSize: 45,

                    showMetricName: true,
                    metricNameSize: 15,

                    showMetricChange: true,
                    metricPercentChangeSize: 10,
                    highlightPercentChange: false,
                    animationDelay: 250,
                },
            },
            {
                type: 'metricRollup',
                id: '4',
                name: 'rollup 1',
                useGlobalTimePeriod: true,
                analyticsRequests: [
                    // Note: All global variables like reportingEngine, report, and filters
                    // are injected via AnalyticsRequest.getRequests()
                    new AnalyticsRequest({
                        limit: 4,
                        projections: [
                            new Metric({
                                LISTENING: { source: ListeningMetrics.distinctUsers },
                                INBOUND_MESSAGE: { dimensionName: 'INBOUND_COUNT' },
                                PLATFORM: { dimensionName: 'TOTAL_ENGAGEMENT' },
                                BENCHMARKING: { dimensionName: 'TOTAL_ENGAGEMENT' },
                                PAID: { dimensionName: 'ENGAGEMENT' },
                                ADVOCACY: {
                                    dimensionName: 'ADVOCACY_TOTAL_USER_ENGAGEMENTS',
                                    heading: 'Total_Engagements',
                                },
                                STORY_MESSAGE: {
                                    dimensionName: 'STORY_ACTIVE_COUNT',
                                    heading: 'Story Count',
                                },
                            }),
                        ],
                        groupBys: [
                            new GroupBy({
                                LISTENING: { source: ListeningDimensions.topicName },
                                INBOUND_MESSAGE: { dimensionName: 'ACCOUNT_ID' },
                                PLATFORM: { dimensionName: 'ACCOUNT_ID' },
                                BENCHMARKING: { dimensionName: 'BRAND_ID' }, //BENCHMARKING_ACCOUNT_ID
                                PAID: { dimensionName: 'accountIds' },
                                ADVOCACY: { dimensionName: 'COMMUNITY_USER_ID' },
                                STORY_MESSAGE: { dimensionName: 'STORY_CATEGORY' },
                            }),
                        ],
                        sorts: [new Sort({ order: 'DESC', projections: 0 })],
                        timePeriods: [{ key: 'last_7_days' }, { previousPeriod: true }],
                    }) as any,
                ],
                label: {
                    titleText: 'Brand Health 1',
                    classes: 'flex center',
                    enabled: true,
                    size: WIDGET_LABEL_SIZE.secondary,
                },
                position: {
                    width: '33.33%',
                    height: '70%',
                    top: '30%',
                    left: '0',
                    portrait: {
                        width: '66.66%',
                        height: '30%',
                        top: '0',
                        left: '33.33%',
                    },
                },
                options: {
                    player: {
                        playing: true,
                        duration: 15,
                    },
                    labelSize: 13,
                    showMetricValue: true,
                    metricValueSize: 30,

                    showMetricName: true,
                    metricNameSize: 10,

                    showMetricChange: false,
                    metricPercentChangeSize: 10,
                    highlightPercentChange: false,
                    animationDelay: 50,
                },
            },
            {
                type: 'metricRollup',
                id: '5',
                name: 'rollup 2',
                useGlobalTimePeriod: true,
                analyticsRequests: [
                    // Note: All global variables like reportingEngine, report, and filters
                    // are injected via AnalyticsRequest.getRequests()
                    new AnalyticsRequest({
                        limit: 4,
                        projections: [
                            new Metric({
                                LISTENING: { source: ListeningMetrics.mentions },
                                INBOUND_MESSAGE: { dimensionName: 'OUTBOUND_COUNT' },
                                PLATFORM: { dimensionName: 'POST_REACH_COUNT' },
                                BENCHMARKING: {
                                    dimensionName: 'MESSAGE_COUNT',
                                    report: 'BENCHMARKING_SIGNAL',
                                },
                                PAID: {
                                    dimensionName: 'uniqueReach',
                                    report: 'PAID_UNIQUE_MEASUREMENTS_STAT',
                                },
                                ADVOCACY: {
                                    dimensionName: 'TOTAL_POINTS_EARNED',
                                    heading: 'Total_Points_Earned',
                                    report: 'COMMUNITY_USER',
                                },
                                STORY_MESSAGE: {
                                    dimensionName: 'MENTIONS_COUNT',
                                    heading: 'Mentions',
                                },
                            }),
                        ],
                        groupBys: [
                            new GroupBy({
                                LISTENING: { source: ListeningDimensions.topicName },
                                INBOUND_MESSAGE: { dimensionName: 'ACCOUNT_ID' },
                                PLATFORM: { dimensionName: 'ACCOUNT_ID' },
                                BENCHMARKING: { dimensionName: 'BRAND_ID' }, //BENCHMARKING_ACCOUNT_ID
                                PAID: { dimensionName: 'accountIds' },
                                ADVOCACY: { dimensionName: 'COMMUNITY_USER_ID' },
                                STORY_MESSAGE: { dimensionName: 'STORY_CATEGORY' },
                            }),
                        ],
                        sorts: [new Sort({ order: 'DESC', projections: 0 })],
                        timePeriods: [{ key: 'last_7_days' }, { previousPeriod: true }],
                    }) as any,
                ],
                label: {
                    titleText: 'Brand Health 2',
                    classes: 'flex center',
                    enabled: true,
                    size: WIDGET_LABEL_SIZE.secondary,
                },
                position: {
                    width: '33.33%',
                    height: '70%',
                    top: '30%',
                    left: '33.33%',
                    portrait: {
                        width: '66.66%',
                        height: '30%',
                        top: '33.33%',
                        left: '33.33%',
                    },
                },
                options: {
                    player: {
                        playing: true,
                        duration: 15,
                    },
                    labelSize: 13,
                    showMetricValue: true,
                    metricValueSize: 30,

                    showMetricName: true,
                    metricNameSize: 10,

                    showMetricChange: false,
                    metricPercentChangeSize: 10,
                    highlightPercentChange: false,
                    animationDelay: 200,
                },
            },
            {
                type: 'metricRollup',
                id: '6',
                name: 'rollup 3',
                useGlobalTimePeriod: true,
                analyticsRequests: [
                    // Note: All global variables like reportingEngine, report, and filters
                    // are injected via AnalyticsRequest.getRequests()
                    new AnalyticsRequest({
                        limit: 4,
                        projections: [
                            new Metric({
                                LISTENING: { source: ListeningMetrics.reach },
                                INBOUND_MESSAGE: { dimensionName: 'LIKE_COUNT' },
                                PLATFORM: {
                                    dimensionName: 'FOLLOWERS_COUNT',
                                    report: 'ACCOUNT_INSIGHTS',
                                },
                                BENCHMARKING: {
                                    dimensionName: 'subscriberCountTotal',
                                    report: 'ACCOUNT_FACTS',
                                },
                                PAID: { dimensionName: 'positiveSentimentCount' },
                                ADVOCACY: {
                                    dimensionName: 'ADVOCACY_TOTAL_USER_LOGIN',
                                    heading: 'Total_Logins',
                                },
                                STORY_MESSAGE: {
                                    dimensionName: 'REACH',
                                    heading: 'Reach',
                                },
                            }),
                        ],
                        groupBys: [
                            new GroupBy({
                                LISTENING: { source: ListeningDimensions.topicName },
                                INBOUND_MESSAGE: { dimensionName: 'ACCOUNT_ID' },
                                PLATFORM: { dimensionName: 'ACCOUNT_ID' },
                                BENCHMARKING: { dimensionName: 'BRAND_ID' }, //BENCHMARKING_ACCOUNT_ID
                                PAID: { dimensionName: 'accountIds' },
                                ADVOCACY: { dimensionName: 'COMMUNITY_USER_ID' },
                                STORY_MESSAGE: { dimensionName: 'STORY_CATEGORY' },
                            }),
                        ],
                        sorts: [new Sort({ order: 'DESC', projections: 0 })],
                        timePeriods: [{ key: 'last_7_days' }, { previousPeriod: true }],
                    }) as any,
                ],
                label: {
                    titleText: 'Brand Health 3',
                    classes: 'flex center',
                    enabled: true,
                    size: WIDGET_LABEL_SIZE.secondary,
                },
                position: {
                    width: '33.33%',
                    height: '70%',
                    top: '30%',
                    left: '66.66%',
                    portrait: {
                        width: '66.66%',
                        height: '30%',
                        top: '66.66%',
                        left: '33.33%',
                    },
                },
                options: {
                    player: {
                        playing: true,
                        duration: 15,
                    },
                    labelSize: 13,
                    showMetricValue: true,
                    metricValueSize: 30,

                    showMetricName: true,
                    metricNameSize: 10,

                    showMetricChange: false,
                    metricPercentChangeSize: 10,
                    highlightPercentChange: false,
                    animationDelay: 300,
                },
            },
        ],
    },
};
