import { PostSource, Metric, Sort } from './PanelTemplatesTheme/PanelTemplatesTheme';
import { DefaultWidgetPosition } from './PanelTemplatesTheme/constants';
import { Widget } from '@sprinklr/stories/widget/Widget';
import { WIDGET_LABEL_SIZE } from '../../components/Widget/WidgetLabel/constants';
import { postCompositionConfigStoryCard } from '../../components/Panel/PanelEditorSidebar/PanelDesign/PanelDesignOptions';
import { TEXT_SHADOW_DEFAULT } from 'models/Widget/Widget';

export const WidgetStoryCard: Widget = {
    type: 'storyCard',
    id: 'story-card',
    name: 'Story Card',
    thumbSrc: 'template-post-card.jpg',
    useGlobalTimePeriod: true,
    postRequests: [
        {
            sources: [
                {
                    type: 'posts',
                    // Note: All global variables like reportingEngine, report, and filters
                    // are injected via PostRequest.getRequests()
                    id: new PostSource({
                        pageSize: 50,
                        postDimension: 'STORY_CREATED',
                        projections: [
                            new Metric({
                                STORY_MESSAGE: {
                                    dimensionName: 'STORY_IMPACT',
                                    heading: 'Impact',
                                    aggregateFunction: 'MAX',
                                },
                            }),
                            new Metric({
                                STORY_MESSAGE: {
                                    dimensionName: 'EMV',
                                    heading: 'EMV',
                                },
                            }),
                            new Metric({
                                STORY_MESSAGE: {
                                    dimensionName: 'INFLUENCE',
                                    heading: 'Influcence',
                                },
                            }),
                            new Metric({
                                STORY_MESSAGE: {
                                    dimensionName: 'OVERALL_REACH',
                                    heading: 'Overall_Reach',
                                },
                            }),
                        ],
                        groupBys: [],
                        sorts: [
                            new Sort({
                                order: 'DESC',
                                heading: {
                                    STORY_MESSAGE: 'REACH',
                                },
                            }),
                        ],
                        timePeriod: {
                            key: 'last_7_days',
                        },
                    }) as any,
                    options: {
                        count: 200,
                        ordered: true,
                        imagePreload: true,
                        removeDuplicates: true,
                        shufflePosts: false,
                    },
                },
            ],
            format: {
                type: 'buckets',
                options: {
                    padding: 1,
                    buckets: {
                        any: { filter: 'post.type' },
                    },
                    panels: [{ total: 3, buckets: ['any', -1] }],
                },
            },
        },
    ],
    position: DefaultWidgetPosition,
    options: Object.assign(
        {
            player: {
                playing: true,
                duration: 6,
            },
            postTemplate: 'card',
            smartSize: {
                enabled: true,
                mode: 'responsive',
                size: 'm',
            },
            portrait: {
                widgetOrientation: 'portrait',
            },
            postVersion: 2,
        },
        postCompositionConfigStoryCard.a
    ),
    label: {
        titleText: 'Story Card',
        enabled: false,
        size: WIDGET_LABEL_SIZE.primary,
        textShadow: TEXT_SHADOW_DEFAULT,
    },
};
