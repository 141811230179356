import { Widget } from '@sprinklr/stories/widget/Widget';

export var WidgetHyperlinkButton: Widget = {
    type: 'hyperlinkButton',
    id: 'hyperlink-button',
    name: 'Hyperlink Button',
    position: {
        width: '40%',
        height: '25%',
        top: '25%',
        left: '25%',
    },
    options: {
        url: 'https://www.yourlink.com',
        slideNumber: 1,
        text: 'Click Here',
        fontSize: 40,
        buttonBorder: {
            enabled: false,
            radius: 15,
        },
        font: {},
    },
};
