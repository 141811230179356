import { Widget } from '@sprinklr/stories/widget/Widget';
import { WidgetTypeId } from 'models/Widget/Widget';
import { SmartSizeSizes } from '@sprinklr/stories/widget/types';
import { toJS } from 'mobx';
import merge from 'deepmerge';
import {
    postCompositionConfigCinematic,
    postCompositionConfigCollage,
    postCompositionConfigFilmStrip,
    postCompositionConfigGrid,
    postCompositionConfigPostCard,
    postCompositionConfigRunOfShow,
    postCompositionConfigSinglePost,
    postCompositionConfigStoryCard,
    postCompositionConfigTicker,
    postCompositionConfigWaterfall,
} from 'components/Panel/PanelEditorSidebar/PanelDesign/PanelDesignOptions';

export const getSmartSizeOptions = (widget: Widget, size: SmartSizeSizes) => {
    const widgetCopy = JSON.parse(JSON.stringify(toJS(widget)));
    if (!widgetCopy.options.smartSize) {
        return widgetCopy.options;
    }
    const { smartSize } = widgetCopy.options;
    smartSize.size = size;

    const breakpoint = smartSize.enabled && smartSize.breakpoints && smartSize.breakpoints[size];
    const factor = (breakpoint && breakpoint.factor) || defaultFactorScale[size];
    const { baseline, optionDefaults } = smartSize;

    if (widgetCopy.options.postComposition && !!getPostCompositions(widgetCopy.type)) {
        widgetCopy.options = mergePostSmartSizeValues(widgetCopy, factor, size);
    } else if (factor && !!baseline) {
        widgetCopy.options = mergeSmartSizeValues(
            widgetCopy,
            factor,
            baseline,
            breakpoint,
            optionDefaults
        );
    }
    return widgetCopy.options;
};

const getOverrides = (baseline, factor) => {
    const baselineCopy = baseline && JSON.parse(JSON.stringify(baseline));
    return baselineCopy && JSON.parse(JSON.stringify(deepFake(baselineCopy, factor, '')));
};

const mergePostSmartSizeValues = (widget, factor, size) => {
    const compositionOptions = getPostCompositions(widget.type);
    const current = compositionOptions && compositionOptions[widget.options.postComposition];
    const smartSize = current.smartSizeCompositions;
    const { baseline, optionDefaults } = current && smartSize;
    const breakpoint =
        current &&
        smartSize.breakpoints &&
        !!smartSize.breakpoints[size] &&
        smartSize.breakpoints[size];
    const extras = breakpoint && breakpoint.options;

    const overrides = getOverrides(baseline, factor);
    return merge.all([
        JSON.parse(JSON.stringify(toJS(widget.options))),
        !!overrides ? overrides : {},
        !!optionDefaults ? optionDefaults : {},
        !!extras ? extras : {},
    ]);
};

const mergeSmartSizeValues = (widget, factor, baseline, breakpoint, optionDefaults) => {
    const overrides = getOverrides(baseline, factor);
    const extras = breakpoint && !!breakpoint.options ? breakpoint.options : {};
    const result = merge.all([
        JSON.parse(JSON.stringify(toJS(widget.options))),
        !!overrides ? overrides : {},
        !!optionDefaults ? optionDefaults : {},
        !!extras ? extras : {},
    ]);

    if (widget.options.widgetOrientation === 'portrait') {
        const portraitOverrides = baseline.portrait && getOverrides(baseline.portrait, factor);
        const portraitOptionDefaults = !!optionDefaults && optionDefaults.portrait;
        const portraitExtras =
            !!breakpoint && !!breakpoint.options && (breakpoint.options as any).portrait;
        return merge.all([
            result,
            !!portraitOverrides ? portraitOverrides : {},
            !!portraitOptionDefaults ? portraitOptionDefaults : {},
            !!portraitExtras ? portraitExtras : {},
        ]);
    } else {
        return result;
    }
};

const defaultFactorScale = {
    xs: 0.3,
    s: 0.4,
    m: 0.6,
    ml: 0.75,
    l: 0.9,
    xl: 1,
};

const getPostCompositions = (type: WidgetTypeId) => {
    switch (type) {
        case 'postsFilmStrip':
            return postCompositionConfigFilmStrip;
        case 'postsCinematic':
            return postCompositionConfigCinematic;
        case 'postsSinglePost':
            return postCompositionConfigSinglePost;
        case 'postsGrid':
            return postCompositionConfigGrid;
        case 'postsCollage':
            return postCompositionConfigCollage;
        case 'postsTicker':
            return postCompositionConfigTicker;
        case 'postsWaterfall':
            return postCompositionConfigWaterfall;
        case 'postCard':
            return postCompositionConfigPostCard;
        case 'storyCard':
            return postCompositionConfigStoryCard;
        case 'runOfShow':
            return postCompositionConfigRunOfShow;
        default:
            return undefined;
    }
};

// LEGZ TODO this guy is copied from storyboard editor - beak out add test
const setPath = (options, path, value) => {
    path.split('.').reduce((prev, current, index, array) => {
        if (index === array.length - 1) {
            prev[current] = value;
        } else {
            return prev[current];
        }
    }, options);
};

// LEGZ TODO break out add test
const deepFake = (options, factor, path) => {
    const deepState = JSON.parse(JSON.stringify(options));
    const recursiveObjectModify = (options, factor, path) => {
        Object.keys(options).forEach(key => {
            const option = options[key];
            const newPath = path !== '' ? `${path}.${key}` : key;
            if (typeof option === 'object' && !Array.isArray(option)) {
                recursiveObjectModify(option, factor, newPath);
            } else if (typeof option === 'number') {
                setPath(deepState, newPath, Math.round(option * factor));
            } else {
                // console.warn('recursiveObjectModify received type of ', typeof option);
            }
        });
        return deepState;
    };
    return recursiveObjectModify(options, factor, path);
};
