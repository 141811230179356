import { action, computed } from 'mobx';
import { State } from '@sprinklr/stories/post/Post';
import { Source } from './Source';
import { SourcePosts } from './SourcePosts';
import { Format } from './Format';
import {
    FormatOrderedOptions,
    PostsFormatRequest,
} from '@sprinklr/stories/post/PostsFormatRequest';
import SignalService from 'services/SignalService/SignalService';
import { PlayerInterval } from 'utils/PlayerInterval/PlayerInterval';
import ObjectUtils from 'utils/ObjectUtils/ObjectUtils';

const PRIMARY = 0;
const INTERLEAVE = 1;

// Always preserves order from server
// Always starts with the top of the list
// Rotates through from top to bottom
// Ideally will immediately jump straight to top when a new items/items are brought in

const enum Direction {
    Previous,
    Next,
}

const debug = false;
const debugAmount = 5;

export class FormatOrdered<T extends State> extends Format<T> {
    private options: FormatOrderedOptions;
    private itemsUnique: T[][] = [null, null]; // Only used if not enough items for window size... or shuffling
    private itemsInterleaved: T[] = null;
    private itemsWindow: T[] = [];
    private interleave = false;
    private adjustedOffset = -1; // Need because can't both read and write _offset within @computed method
    private adjustedBeyondEnd = false; // If all items were replaced with new ones, our current offset is beyond end of array
    private resetToStart = false;
    private loopedOnce = true;
    private duplicatedCount: number; // Number of duplicated items created in buildUniqueItems()
    private overflow = false; // Let rendered component
    // private resetAtTEST: number = 1;
    private debugTotal = 100;

    constructor(
        request: PostsFormatRequest,
        sources: Source<any, any>[],
        options: FormatOrderedOptions,
        signalService: SignalService
    ) {
        super(request, sources, signalService);

        // Formatter can modify options, so keep copy for that possibility
        this.options = ObjectUtils.copy(options);

        if (sources.length === 2) {
            this.interleave = true;
            this.itemsInterleaved = [];
        }

        this.options.padding = options.padding || 2;
        this.options.fixedList = options.fixedList || (debug ? debugAmount : 0);
        this.options.guard = options.guard || 0;
        this.options.interleave = options.interleave || 0;
        this.options.loop = options.loop != undefined ? options.loop : true;
        this.options.resetOnNew = options.resetOnNew != undefined ? options.resetOnNew : true;
        this.options.bottomToTop = options.bottomToTop != undefined ? options.bottomToTop : false;

        if (options.fixedList) {
            this.options.padding = 0;
            this.options.bottomToTop = true;
            this.options.loop = options.loop != undefined ? options.loop : false;
        } else {
            this.overflow = true;
        }

        // Used for presentations so that all items will loop once
        this.loopedOnce = !this.options.loop;

        this.signalService.add(Source.changed, this.onSourceChanged);
    }

    destroy() {
        this.signalService.remove(Source.changed, this.onSourceChanged);
    }

    get overflowed(): boolean {
        return this.overflow;
    }

    // If underflow:
    //   - Don't show duplicate posts
    //   - Don't allow cycling
    // If overflow:
    //   - Render duplicate posts
    //   - Allow cycling
    setOverflow(): void {
        if (!this.overflow) {
            this.overflow = true;
        }
    }

    // Set overflow if true # of posts is > than fixedList option
    setOverflowFixedList(): void {
        if (this.options.fixedList) {
            const items = this.getPrimary();
            const count = items.length - this.duplicatedCount;

            if (count > this.options.fixedList) {
                this.setOverflow();
            }
        }
    }

    testPostsAdd(dest: any[]) {
        this.debugTotal = SourcePosts.testPostsAdd(dest, this.debugTotal, debugAmount);
    }

    isActive(offset: number): boolean {
        return offset === this.options.padding;
    }

    set offset(offset: number) {
        const items = this.getPrimary();

        this._offset = offset;
        if (this._offset < 0) {
            this._offset = 0;
        } else if (this._offset >= items.length) {
            this._offset = items.length - 1;
        }
    }

    offsetDecrement() {
        const direction = this.options.fixedList ? Direction.Next : Direction.Previous;
        const value = this.options.bottomToTop ? 1 : -1;

        this.setOffset(direction, value);
    }

    offsetIncrement() {
        const direction = this.options.fixedList ? Direction.Previous : Direction.Next;
        const value = this.options.bottomToTop ? -1 : 1;

        this.setOffset(direction, value);
    }

    @computed get items(): any[] {
        // Get mobx to react to changes in these variables
        if (this.itemsChanged && this._offset >= 0) {
            const allLoaded = this.sources.every(source => source.isLoaded());
            if (allLoaded) {
                // Return a shallow copy of our existing itemsWindow
                return this.itemsWindow.slice();
            }
        }

        return [];
    }

    @action setSource(whichSource: number, source: Source<any, any>): void {
        if (whichSource >= 0 && whichSource < this.sources.length) {
            this.sources[whichSource] = source;
            this.itemsInternal[whichSource].length = 0;
            this.offset = 0;
        } else {
            console.log('ERROR: Source ' + whichSource + " isn't defined for FormatOrdered");
        }
    }

    private getAdjustedCount(): number {
        const items = this.getPrimary();

        // If we're not looping, then removing duplicate count prevents the duplicate items from appearing
        return this.options.loop ? items.length : items.length - this.duplicatedCount;
    }

    private getAdjustedOffset(): number {
        return this.adjustedOffset != -1 ? this.adjustedOffset : this._offset;
    }

    private getStartOffset(count: number): number {
        // Offset is the end of the items array
        if (this.options.bottomToTop && !this.options.fixedList) {
            return count ? count - 1 - this.duplicatedCount : 0;
            // Offset is the start of the items array
        } else {
            return 0;
        }
    }

    private getItems(offset: number): T[] {
        return this.itemsUnique[offset] ? this.itemsUnique[offset] : this.itemsInternal[offset];
    }

    private getPrimary(): T[] {
        return this.interleave ? this.itemsInterleaved : this.getItems(PRIMARY);
    }

    private constrainOffset(offset: number, count: number, loop?: boolean): number {
        if (loop === undefined) {
            if (!this.options.loop) {
                loop = false;
            } else {
                loop = this.options.loop && this.overflow;
            }
        }

        if (offset >= count) {
            if (this.options.shouldFireEvents && this.loopedOnce) {
                // We've hit the end.
                this.signalService.dispatch(
                    this.options.bottomToTop
                        ? PlayerInterval.sequenceBegin
                        : PlayerInterval.sequenceEnd
                );
            }

            if (loop) {
                offset = offset - count; // This rolls us around correctly if padding > 1
            } else {
                offset = count - 1;
            }

            this.loopedOnce = true;
        } else if (offset < 0) {
            if (this.options.shouldFireEvents && this.loopedOnce) {
                // We've hit the beginning.
                this.signalService.dispatch(
                    this.options.bottomToTop
                        ? PlayerInterval.sequenceEnd
                        : PlayerInterval.sequenceBegin
                );
            }

            if (loop) {
                offset = !count ? 0 : count + offset; // This rolls us around correctly if padding > 1
            } else {
                offset = 0;
            }

            this.loopedOnce = true;
        }

        return offset;
    }

    @action
    private setOffset(direction: Direction, value: number) {
        const items = this.getPrimary();
        const count = this.getAdjustedCount();

        if (this.adjustedOffset != -1) {
            this._offset = this.adjustedOffset;
            if (this.adjustedBeyondEnd) {
                this._offset++;
                this.adjustedBeyondEnd = false;
            }

            this.adjustedOffset = -1;
        }

        const oldOffset = this._offset;
        let newOffset = oldOffset + value;

        // TEST for resetOnNew functionality
        // if (this.options.resetOnNew && newOffset === this.resetAtTEST) {
        //     this.resetToStart = true;
        // }

        newOffset = this.constrainOffset(newOffset, count);
        if (newOffset != oldOffset && this.shiftWindow(items, newOffset, direction, value)) {
            this._offset = newOffset;
        }
    }

    // If we have less items than our overall window size, then duplicate the items,
    // each with distinct "unique" values, and use that array within
    // buildWindow()
    private buildUniqueItems(sources: T[][]) {
        let uniqueMap: { [id: number]: number };
        let item: T;
        let offset;
        const isShuffle = this.isShuffle();

        this.duplicatedCount = 0;

        const getItem = (items: T[], markDuplicates: boolean) => {
            if (offset < 0) {
                offset = length - 1;
            } else if (offset >= items.length) {
                offset = 0;
            }

            item = items[offset];

            // If we have a duplicate already in our window array, make a shallow copy of the
            // item and give it a different unique value.
            if (uniqueMap[item[this.uniqueKey]]) {
                item = Object.assign({}, item);
                item.duplicated = markDuplicates;
                item.unique += '_' + uniqueMap[item[this.uniqueKey]]++;
                this.duplicatedCount++;
            } else {
                uniqueMap[item[this.uniqueKey]] = 1;
            }

            offset++;

            return item;
        };

        let count: number, baseCount: number;
        count = baseCount = !this.options.fixedList
            ? this.options.padding << 1
            : this.options.fixedList;

        // +2 more than window size.  This allows Video components to get destroyed
        //   https://sprinklr.atlassian.net/browse/DISPLAY-1743
        if (this.options.fixedList) {
            count += 2;
        }

        for (let x = 0; x < sources.length; x++) {
            if (sources[x].length && sources[x].length < count) {
                offset = 0;
                uniqueMap = {};
                this.itemsUnique[x] = [];

                for (let y = 0; y < count; y++) {
                    // Don't mark duplicated for padding posts
                    const markDuplicates = y < baseCount;
                    this.itemsUnique[x].push(getItem(sources[x], markDuplicates));
                }
            } else {
                this.itemsUnique[x] = null;
            }

            if (isShuffle) {
                // Always shuffle itemsUnique because itemsInternal must stay in the same
                // order for sync() above
                if (!this.itemsUnique[x]) {
                    this.itemsUnique[x] = [];
                    this.itemsUnique[x].push.apply(
                        this.itemsUnique[x],
                        this.itemsInternal[x].slice()
                    );
                }
                this.shuffle(this.itemsUnique[x]);
            }
        }
    }

    private interleaveSources() {
        let item: T;
        let offset = 0;
        const interstitials = this.itemsInternal[INTERLEAVE];

        const getInterstitial = (direction: number): T => {
            if (interstitials.length) {
                item = interstitials[offset];

                offset += direction;

                const count = interstitials.length;
                if (offset < 0) {
                    offset = count - 1;
                } else if (offset >= count) {
                    offset = 0;
                }

                return item;
            } else {
                return null;
            }
        };

        // Add the item object for our current array
        let injectInterstitial = this.options.interleave;

        this.itemsInterleaved.length = 0;

        const count = this.getItems(PRIMARY).length;
        let multiplier = 1;

        // If we have less pimary items then we have per the interleave interval,
        // pad out our interleaved array with dups so we can do the interleave correctly
        if (interstitials && count && interstitials.length >= count) {
            multiplier = Math.floor(interstitials.length / count);
        }

        // Build out our interleaved item array
        for (let x = 0; x < count * multiplier; x++) {
            item = this.getItems(PRIMARY)[x % count];
            this.itemsInterleaved.push(item);

            if (--injectInterstitial === 0) {
                item = getInterstitial(1);
                if (item) {
                    this.itemsInterleaved.push(item);
                }

                injectInterstitial = this.options.interleave;
            }
        }
    }

    // This shifts the itemsWindow array either up or down, depending on direction,
    // and inserts the next/previous item in the empty slot.
    private shiftWindow(items: T[], offset: number, direction: Direction, value: number): boolean {
        const count = items.length;
        let next = -1;

        // This will be true if new items came in and resetOnNew option is true
        // In that case, cycle back to the starting offset
        if (this.resetToStart) {
            const start = this.getStartOffset(count);

            // Make sure there's enough distance between our current offset and
            // the starting offset.  Otherwise, the animations can get screwed up
            const distance = Math.abs(this._offset - start);
            if (distance > this.options.padding + 1) {
                next = this.adjustedOffset = start;
                this.resetToStart = false;
            }
        }

        if (next === -1) {
            let loop: boolean;
            let newOffset: number;

            if (!this.options.fixedList) {
                loop = true;

                if (value === 1) {
                    newOffset = offset + this.options.padding;
                } else {
                    newOffset = offset - this.options.padding;
                }
            } else {
                loop = false;

                if (value === 1) {
                    newOffset = offset + this.options.fixedList - 1;
                } else {
                    newOffset = offset;
                }
            }

            next = this.constrainOffset(newOffset, count, loop);

            // We've reached the end of our fixed list, either top or bottom, so abort
            if (this.options.fixedList && next != newOffset) {
                return false;
            }
        }

        // Next item goes at the bottom of the window
        if (direction === Direction.Next) {
            this.itemsWindow.splice(0, 1);
            this.itemsWindow.push(items[next]);

            // Next item goes at the top of the window
        } else {
            this.itemsWindow.unshift(items[next]);
            this.itemsWindow.length--;
        }

        return true;
    }

    // Create the initial itemsWindow with the first set of items.  Called once at first load.
    private buildWindow(offset: number) {
        const items = this.getPrimary();
        const length = items.length;
        const baseOffset = offset;

        const getItem = (direction: number) => {
            offset += direction;

            if (offset < 0) {
                offset = length - 1;
            } else if (offset >= length) {
                offset = 0;
            }

            return items[offset];
        };

        this.itemsWindow.length = 0;
        this.itemsWindow.push(items[offset]);

        if (!this.options.fixedList) {
            const previous = this.options.bottomToTop ? 1 : -1;

            for (var x = 0; x < this.options.padding; x++) {
                this.itemsWindow.unshift(getItem(previous));
            }

            offset = baseOffset;

            for (x = 0; x < this.options.padding; x++) {
                this.itemsWindow.push(getItem(-previous));
            }
        } else {
            for (var x = 1; x < this.options.fixedList; x++) {
                this.itemsWindow.push(getItem(1));
            }
        }
    }

    // Fired when a source's data changes
    private onSourceChanged = (type: string, source: Source<any, any>) => {
        const offset = this.sources.indexOf(source);
        if (offset != -1) {
            const allLoaded = true;
            let sourcesChanged = false;
            let itemOffset = this._offset;

            let items = this.getPrimary();
            const currentOffset = this.getAdjustedOffset();
            let currentUnique = items.length ? items[currentOffset].unique : null;

            // Find out what posts were added and/or removed, if any
            const changed = this.diffs[offset].compare(source.diff);
            if (changed) {
                if (debug) {
                    if (this.itemsInternal[offset].length) {
                        this.testPostsAdd(changed.added);
                    }
                }

                if (changed.added.length && this.itemsInternal[offset].length) {
                    // If new items have arrived, reset the offset back to the start
                    if (!this.options.fixedList && this.options.resetOnNew) {
                        this.resetToStart = true;
                    }
                }

                let newOffset = this.diffs[offset].sync(
                    this.itemsInternal[offset],
                    changed,
                    currentOffset
                );
                this.buildUniqueItems(this.itemsInternal);
                sourcesChanged = true;

                if (currentUnique) {
                    // If all items were replaced, then we will be pointing at the end
                    // of the array, so adjust to the end
                    if (newOffset >= this.itemsInternal[offset].length) {
                        newOffset = this.itemsInternal[offset].length - 1;
                        this.adjustedBeyondEnd = true;
                    }

                    // Adding and removing can move or remove our existing "currentUnique"
                    // item.  Set the currentUnique on where the newOffset is.
                    currentUnique = this.itemsInternal[offset]?.[newOffset]?.unique;
                }

                items = this.getPrimary();

                if (this.interleave) {
                    this.interleaveSources();
                    items = this.getPrimary();
                }

                // Find the offset of our previous current item within the new set
                let found = -1;
                if (currentUnique) {
                    found = items.findIndex((item: T) => item.unique === currentUnique);
                    if (found != -1) {
                        // Set our current offset here
                        itemOffset = this.adjustedOffset = found;
                    } else {
                        itemOffset = this.adjustedOffset = 0;
                    }
                }

                if (found === -1) {
                    // Nothing there yet?
                    itemOffset = this.adjustedOffset = this.getStartOffset(items.length);
                }

                // If itemsWindow hasn't been created, do so
                if (!this.itemsWindow.length) {
                    this.buildWindow(itemOffset);
                }

                // Trigger mobx recomputation for items()
                this.itemsChanged++;
            }
        }
    };
}
