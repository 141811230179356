import config from './config';

import axios, { AxiosRequestConfig } from 'axios';
import { GraphQLService } from 'services/GraphQLService/GraphQLService';
import AuthService from 'services/Auth/AuthService';

// Lifted from ServiceContext -- Set up a standalone authService
const axiosConfig: AxiosRequestConfig = {
    baseURL: config.apiRoot,
    withCredentials: !config.sandboxed, // keep axios from accessing cookies, etc.
};

if (config.sandboxed) {
    // Weird hacky way to get axios to avoid a cookie-handling error when sandboxed.
    axiosConfig.xsrfCookieName = '';
}

const axiosInstance = axios.create(axiosConfig);
const graphQLService = new GraphQLService(axiosInstance);
const authService = new AuthService(axiosInstance, graphQLService, config);

// Initialize authService;
authService.getSessionContext();

export default authService;
