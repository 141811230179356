import React from 'react';
import { action } from 'mobx';
import {
    opacityTunables,
    shapeBorderTunables,
    shapeImageTunables,
} from 'components/Sidebar/RenderTunables/SharedTunables';
import { CollapseProps, TunablesCollapse } from 'components/Sidebar/RenderTunables/RenderTunables';
import { Widget } from '@sprinklr/stories/widget/Widget';
import { Theme } from 'models/Theme/Theme';
import { ShapeWidgetOptionsImpl } from './options';
import LabelGroup from 'components/_UI/Forms/LabelGroup/LabelGroup';
import ActionColorSwatchGroup from 'components/_UI/Forms/Actions/ActionColorSwatchGroup';
import ActionSliderGroup from 'components/_UI/Forms/Actions/ActionSliderGroup';
import ActionSegmentedGroup from 'components/_UI/Forms/Actions/ActionSegmentedGroup';
import { getMergedBackgroundColor } from 'utils/GenerateStyles/GenerateStyles';
import { SegmentedControlOption } from 'components/Panel/PanelEditorSidebar/PanelDesign/PanelDesignOptions';
import i18n from '../../i18n';
import ActionCheckboxGroup from 'components/_UI/Forms/Actions/ActionCheckboxGroup';

const shapeTypeOptions: SegmentedControlOption[] = [
    {
        value: 'rectangle',
        label: i18n.t('Rectangle'),
        icon: 'shape-rectangle',
    },
    {
        value: 'ellipse',
        label: i18n.t('Ellipse'),
        icon: 'shape-ellipse',
    },
    {
        value: 'triangle',
        label: i18n.t('Triangle'),
        icon: 'shape-triangle',
    },
    {
        value: 'polygon',
        label: i18n.t('Polygon'),
        icon: 'shape-polygon',
    },
    {
        value: 'line',
        label: i18n.t('Line'),
        icon: 'shape-line',
    },
];

const ShapeTunables = (widget: Widget, mergedTheme: Theme): TunablesCollapse[] => {
    const options = widget.options as ShapeWidgetOptionsImpl;
    const type = options.shape.type;
    const supportsImageTunables = type === 'ellipse' || type === 'rectangle';

    const lineTunables =
        type === 'line'
            ? [
                  <LabelGroup label={i18n.t('Color')} key='widget.options.shape.border.color'>
                      <ActionColorSwatchGroup
                          key='widget.options.shape.border.color'
                          title={i18n.t('shape color')}
                          secondaryIcon={!!options.shape.background.color ? 'icon-undo' : undefined}
                          value={null}
                          option={null}
                          color={
                              options.shape.background.color
                                  ? options.shape.background.color
                                  : (mergedTheme && getMergedBackgroundColor(mergedTheme)) ||
                                    'white'
                          }
                          onClear={action(() => (options.shape.background.color = ''))}
                          onChange={action(
                              ({ rgb: { r, g, b, a } }: any) =>
                                  (options.shape.background.color = `rgba(${r}, ${g}, ${b}, ${a})`)
                          )}
                      />
                  </LabelGroup>,
                  <ActionSliderGroup
                      key='widget.options.shape.line.size'
                      label={i18n.t('Size')}
                      option={options.shape.line}
                      value='size'
                      showValue
                      min={0}
                      max={100}
                  />,
                  <ActionSliderGroup
                      key='widget.options.shape.line.orientation'
                      label={i18n.t('Rotation')}
                      option={options.shape.line}
                      value='rotation'
                      showValue
                      min={0}
                      max={180}
                  />,
                  <ActionCheckboxGroup
                      key='widget.options.shape.line.marker.start'
                      label={i18n.t('Arrow Start')}
                      option={options.shape.line.marker}
                      value='start'
                  />,
                  <ActionCheckboxGroup
                      key='widget.options.shape.line.marker.end'
                      label={i18n.t('Arrow End')}
                      option={options.shape.line.marker}
                      value='end'
                  />,
                  (options.shape.line.marker.end || options.shape.line.marker.start) && (
                      <ActionSliderGroup
                          key='widget.options.shape.line.marker.size'
                          label={i18n.t('Arrow Size')}
                          option={options.shape.line.marker}
                          value='size'
                          showValue
                          min={0}
                          max={50}
                      />
                  ),
              ]
            : [];

    const polySides =
        type === 'polygon'
            ? [
                  <ActionSliderGroup
                      key='widget.options.shape'
                      label={i18n.t('Sides')}
                      option={options.shape}
                      value='points'
                      showValue
                      min={5}
                      max={10}
                  />,
              ]
            : [];

    const polygonTunables =
        type === 'polygon' || type === 'triangle'
            ? [
                  ...polySides,
                  shapeBorderTunables(widget),
                  <LabelGroup label={i18n.t('Fill')} key='widget.options.shape.border.color'>
                      <ActionColorSwatchGroup
                          key='widget.options.shape.border.color'
                          title={i18n.t('shape color')}
                          secondaryIcon={!!options.shape.background.color ? 'icon-undo' : undefined}
                          value={null}
                          option={null}
                          color={
                              options.shape.background.color &&
                              options.shape.background.color !== ''
                                  ? options.shape.background.color
                                  : getMergedBackgroundColor(mergedTheme)
                          }
                          onClear={action(() => (options.shape.background.color = ''))}
                          onChange={action(
                              ({ rgb: { r, g, b, a } }: any) =>
                                  (options.shape.background.color = `rgba(${r}, ${g}, ${b}, ${a})`)
                          )}
                      />
                  </LabelGroup>,
              ]
            : [];

    return [
        {
            props: {
                label: i18n.t('Shape'),
                startOpen: true,
            } as CollapseProps,
            children: [
                <ActionSegmentedGroup
                    key='widget.options.shape.type'
                    label={i18n.t('Composition')}
                    option={options.shape}
                    value='type'
                    options={shapeTypeOptions}
                />,
                ,
                opacityTunables(widget),
                supportsImageTunables && shapeBorderTunables(widget),
                ...lineTunables,
                ...polygonTunables,
            ],
        },
        supportsImageTunables && shapeImageTunables(widget, mergedTheme),
    ];
};

export default ShapeTunables;
