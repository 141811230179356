//
// Massages the post format from the Sprinklr API into a format we expect within the builder
//
import moment from 'moment';
import { AlertDetail, Post, PostMedia, ExtraPostDataField } from '@sprinklr/stories/post/Post';
import { LineChartSeries } from '@sprinklr/display-builder/components/_charts/LineChart/types';

const engagementProjectionsToUnifiedNames = {
    POST_LIKE_COUNT: { common: 'numLikes' },
    POST_SHARE_COUNT: { common: 'numShares', twitter: 'numRetweets' }, // thanks twitter
    POST_COMMENT_COUNT: { common: 'numComments' },
    TOTAL_ENGAGEMENT: { common: 'totalEngagement' },
};

// Only used for Gallery
export const customFieldLink = 'Link URL';

export default class PostsTransform {
    // Remove and transform the posts object.  There are many things we don't need in there.
    // Eventually this should be on the server side.
    static transform(posts: any[], projections?: any[], filters?: any[]): Post[] {
        posts.forEach(post => {
            PostsTransform.transformPost(post, projections, filters);
        });

        return posts.filter((post: Post) => {
            return (
                post.snMsgId &&
                (post.message ||
                    (post.images && post.images.length > 0) ||
                    (post.videos && post.videos.length > 0))
            );
        });
    }

    // tslint:disable-next-line:memberTransformng
    static transformPost(post: any, projections?: any[], filters?: any[]) {
        // If PAID, post can have this child/secondary post linked to it
        // ie.  Parent is FACEBOOK, child is INSTAGRAM
        // If the social network of the secondary post is selected as a filter,
        // user wants to see details for child post, so copy
        // that info into the parent. This is replicating space behavior.
        if (filters && post.secondaryPost) {
            const snFilter = filters.find(filter => {
                return filter.dimensionName === 'adChannel' && filter.filterType === 'IN';
            });

            const parentPostFilter =
                snFilter &&
                snFilter.values.find(value => {
                    return value === post.secondaryPost.channelType;
                });

            if (parentPostFilter) {
                post.id = post.secondaryPost.id || post.id;
                post.name = post.secondaryPost.name || post.name;
                post.accountType = post.secondaryPost.accountType || post.accountType;
                post.channelType = post.secondaryPost.channelType || post.channelType;
                post.createdTime = post.secondaryPost.createdTime || post.createdTime;
                post.publishedTime = post.secondaryPost.publishedTime || post.publishedTime;
                post.permalink = post.secondaryPost.permalink || post.permalink;
                post.content = post.secondaryPost.content || post.content;
            }
        }

        // begin partner published posts format from reporting
        if (post.processedContent || post.content) {
            const content = { ...post.processedContent, ...post.content };

            if (!post.message) {
                post.message = content.message || content.title || content.postName || '';
            }

            if (content.languageCode && !post.language) {
                post.language = content.languageCode;
            }

            if (content.attachment) {
                const attachment = content.attachment;

                if (!!attachment.type) {
                    post.mediaType = attachment.type;
                }

                if (attachment.mediaList) {
                    if (!post.mediaList) {
                        post.mediaList = [];
                    }
                    post.mediaList = post.mediaList.concat(attachment.mediaList);
                }
            }

            if (content.textEntities) {
                post.textEntities = content.textEntities;
            }
        }

        // For STORY_MESSAGE engine
        if (!post.message) {
            post.message = post.title || post.description || '';
        }

        if (!post.snStats) {
            post.snStats = {};
        }

        const fbReactions = ['HAHAS', 'LIKES', 'LOVES', 'SADS', 'WOWS'];
        const fbReactionsSnStats = ['nLove', 'nWow', 'nHaha', 'nSad', 'nAnger'];

        if (!post.postStatistics) {
            if (post.snType === 'FACEBOOK') {
                const totalReactions = fbReactionsSnStats.reduce((total, reaction) => {
                    const sum = total + post.snStats[reaction];
                    return isNaN(sum) ? total : sum;
                }, 0);
                post.snStats.totalLikesAndReacts = !isNaN(post.numberofLikes)
                    ? totalReactions
                    : totalReactions + post.numberOfLikes;
            }
        }

        if (post.postStatistics && post.postStatistics.channelInsights) {
            if (post.postStatistics.channelInsights.LIKES) {
                if (post.channelType === 'FACEBOOK') {
                    const totalReactions = fbReactions.reduce((total, reaction) => {
                        const sum = total + post.postStatistics.channelInsights[`FB_${reaction}`];
                        return isNaN(sum) ? total : sum;
                    }, 0);
                    post.snStats.numLikes = totalReactions;
                } else {
                    post.snStats.numLikes = post.postStatistics.channelInsights.LIKES;
                }
            }
            if (post.postStatistics.channelInsights.COMMENTS) {
                post.snStats.numComments = post.postStatistics.channelInsights.COMMENTS;
            }
            if (post.postStatistics.channelInsights.SHARES) {
                post.snStats.numShares = post.postStatistics.channelInsights.SHARES;
            }
            if (post.postStatistics.channelInsights.RETWEET) {
                post.snStats.numRetweets = post.postStatistics.channelInsights.RETWEET;
            }
            if (post.postStatistics.channelInsights.FOLLOWERS) {
                post.snStats.numFollowers = post.postStatistics.channelInsights.FOLLOWERS;
            }
            if (post.postStatistics.channelInsights.REACH) {
                post.snStats.reachCount = post.postStatistics.channelInsights.REACH;
            }
            if (post.postStatistics.channelInsights.TOTAL_ENGAGEMENT) {
                post.snStats.totalEngagement = post.postStatistics.channelInsights.TOTAL_ENGAGEMENT;
            }
            if (post.postStatistics.channelInsights.YOUTUBE_VIDEO_VIEWS) {
                post.snStats.viewCount = post.postStatistics.channelInsights.YOUTUBE_VIDEO_VIEWS;
            }
            delete post.postStatistics;
        }

        if (!post.snMsgId) {
            if (post.statusID) {
                post.snMsgId = post.statusID;
                delete post.statusID;
            } else if (post.universalId) {
                post.snMsgId = post.universalId;
                delete post.universalId;
            } else if (post.id) {
                post.snMsgId = post.id;
                delete post.id;
            }
        }

        if (post.publishedDate && !post.snCreatedTime) {
            post.snCreatedTime = post.publishedDate;
        }

        // For UGC posts, snCreatedTime is a formatted string like "Aug 14, 2017 1:55:46 AM".
        // This converts it to a timestamp. No timezone is specified for it so assume it's UTC.
        if (post.snCreatedTime && typeof post.snCreatedTime === 'string') {
            post.snCreatedTime = moment(post.snCreatedTime + ' Z').valueOf();
        }

        if (!post.snCreatedTime && typeof post.publishedTime === 'number') {
            post.snCreatedTime = post.publishedTime;
        } else if (!post.snCreatedTime && typeof post.scheduledTime === 'number') {
            post.snCreatedTime = post.scheduledTime;
        }

        // For STORY_MESSAGE engine
        if (post.seedUm?.snCreatedTime) {
            post.snCreatedTime = post.seedUm.snCreatedTime;
        }

        delete post.approval;
        delete post.accountType;
        delete post.status;
        delete post.publishedDate;
        delete post.publishedTime;
        delete post.scheduleDate;
        delete post.createdDate;
        delete post.additional;
        delete post.version;
        delete post.messageId;
        delete post.authorId;
        delete post.postId;
        // delete post.content;
        delete post.processedContent;
        delete post.autoImported;
        delete post.ruleEngineExecuted;
        delete post.childPost;
        delete post.rePublishable;
        delete post.reCallable;
        delete post.reSchedulable;
        delete post.documentType;
        delete post.universalId;
        delete post.hasAccountAccess;
        delete post.modifiedDate;
        delete post.scheduledTime;
        delete post.lockedUntil;
        delete post.apiStatus;
        // end partner published posts format from reporting

        // All of our css rules are lowercase, so force it lowercase here.
        if (post.snType) {
            post.snType = post.snType.toLowerCase();
        } else if (post.channelType) {
            post.snType = post.channelType.toLowerCase();
            delete post.channelType;
        }
        post?.textEntities?.message?.map((textEntity, index) => {
            const match = post?.urlEntities?.message.find(
                urlEntity => urlEntity.url === textEntity.url
            );
            if (match) {
                textEntity.url = match.display_url || match.expanded_url;
            } else if (textEntity?.url && textEntity.url.includes('//t.co')) {
                // textEntity.url = "\u00A0";
                textEntity.url = ' ';
            }

            return textEntity;
        });

        if (post.projections) {
            const extraPostData: ExtraPostDataField[] = [];

            const keys = Object.keys(post.projections);
            for (const key of keys) {
                const projection = post.projections[key];
                const value = projection.value;
                const origOrder = projection.origOrder != undefined ? projection.origOrder : 0;

                const mapping = engagementProjectionsToUnifiedNames[key];
                if (mapping) {
                    if (mapping[post.snType]) {
                        post.snStats[mapping[post.snType]] = value;
                    } else {
                        post.snStats[mapping.common] = value;
                    }
                }

                // If this is a user-supplied metric or groupBy, then add it to our extraPostData array.
                if (projection.additional && key !== 'Outbound_Post' && key !== 'Inbound_Message') {
                    // HACKTOWN:  This is required because alternateHeading was being forced-set by a
                    // change back in 10/20.  Now it is back to being set or null.  However, for backwards
                    // compatibiliy, we need to check for the forced-set case
                    const reallySet = key !== projection.alternateHeading;
                    const extra = {
                        heading: key,
                        alternateHeading: reallySet ? projection.alternateHeading : null,
                        value: value,
                    };

                    if (projection.dataType) {
                        (extra as any).dataType = projection.dataType;
                    }
                    if (origOrder) {
                        extraPostData[origOrder] = extra;
                    } else {
                        extraPostData.push(extra);
                    }
                }
            }

            post.extraPostData = extraPostData.filter(item => !!item);

            if (projections) {
                post.projectionStats = [];

                projections.forEach(projection => {
                    post.projectionStats.push({
                        label: projection.heading,
                        value: post.projections[projection.heading],
                        projection: projection,
                    });
                });
            }
        }

        if (post.snStats) {
            post.snStats.contentLength = post.snStats.conLen;
            delete post.snStats.conLen;
            post.snStats.numRetweets =
                post.snStats.nR !== undefined ? post.snStats.nR : post.snStats.numRetweets;
            delete post.snStats.nR;
            post.snStats.reachCount =
                post.snStats.reC !== undefined ? post.snStats.reC : post.snStats.reachCount;
            delete post.snStats.reC;
            post.snStats.numLikes =
                post.snStats.fC ||
                post.snStats.numLikes ||
                post.numberOfLikes ||
                post.projections?.LIKES_COUNT?.value;
            delete post.snStats.nL;
            post.snStats.numComments =
                post.snStats.nr || post.snStats.numComments || post.numberOfComments;
            delete post.snStats.nC;
            post.snStats.numShares =
                post.snStats.nS !== undefined ? post.snStats.nS : post.snStats.numShares;
            delete post.snStats.nS;
            post.snStats.numImpressions = post.snStats.nI;
            delete post.snStats.nI;
            post.snStats.favoritesCount = post.snStats.fC;
            delete post.snStats.fC;
            post.snStats.numBrandComments = post.snStats.nBC;
            delete post.snStats.nBC;
            post.snStats.numVotes = post.snStats.nV;
            delete post.snStats.nV;
            post.snStats.numFollowers =
                post.snStats.nF !== undefined ? post.snStats.nF : post.snStats.numFollowers;
            delete post.snStats.nF;
            post.snStats.numHelpfulVotes = post.snStats.nH;
            delete post.snStats.nH;
            post.snStats.numUnhelpfulVotes = post.snStats.nU;
            delete post.snStats.nU;
            post.snStats.numDislikes = post.snStats.nDL;
            delete post.snStats.nDL;
            post.snStats.liveViewCount = post.snStats.lvC;
            delete post.snStats.lvC;
            post.snStats.viewCount =
                post.snStats.vC !== undefined
                    ? post.snStats.vC
                    : post.numOfViews !== undefined
                    ? post.numOfViews
                    : post.snStats.viewCount;
            delete post.snStats.vC;
            post.snStats.kudosCount = post.snStats.kC;
            delete post.snStats.kC;
            post.snStats.answerCount = post.snStats.ansC;
            delete post.snStats.ansC;
            post.snStats.suggestedAnswerCount = post.snStats.saC;
            delete post.snStats.saC;
            post.snStats.replyCount = post.snStats.nr;
            delete post.snStats.nr;
            post.snStats.numOfSlides = post.snStats.nSl;
            delete post.snStats.nSl;
            post.snStats.numOfDownloads = post.snStats.nDw;
            delete post.snStats.nDw;
            post.snStats.numPhotos = post.snStats.nP;
            delete post.snStats.nP;
            post.snStats.numSubscribers = post.snStats.nSub;
            delete post.snStats.nSub;
            post.snStats.numMembers = post.snStats.nM;
            delete post.snStats.nM;
            post.snStats.numAttending = post.snStats.nA;
            delete post.snStats.nA;
            post.snStats.numDeclined = post.snStats.nD;
            delete post.snStats.nD;
            post.snStats.numNotReplied = post.snStats.nNR;
            delete post.snStats.nNR;
            post.snStats.linksCount = post.snStats.lCnt;
            delete post.snStats.lCnt;
            post.snStats.postsInThreadCount = post.snStats.pITC;
            delete post.snStats.pITC;
            post.snStats.postSize = post.snStats.pSize;
            delete post.snStats.pSize;
            post.snStats.numOfPositiveFeedbacks = post.snStats.nOPF;
            delete post.snStats.nOPF;
            post.snStats.numOfNegativeFeedbacks = post.snStats.nONF;
            delete post.snStats.nONF;
            post.snStats.numOfFeedbacks = post.snStats.nOF;
            delete post.snStats.nOF;
            post.snStats.numOfAnswers = post.snStats.nOA;
            delete post.snStats.nOA;
            post.snStats.numPoints = post.snStats.nOp;
            delete post.snStats.nOp;
            post.snStats.numExternalMentions = post.snStats.eM;
            delete post.snStats.eM;
            post.snStats.repinCount = post.snStats.repin_count;
            delete post.snStats.repin_count;
            post.snStats.domainRank = post.snStats.dR;
            delete post.snStats.dR;
            post.snStats.spamScore = post.snStats.spSc;
            delete post.snStats.spSc;
            post.snStats.performanceScore = post.snStats.pSc;
            delete post.snStats.pSc;
            post.snStats.socialShare = post.snStats.social_share;
            delete post.snStats.social_share;
            post.snStats.numReportAbuse = post.snStats.nRa;
            delete post.snStats.nRa;

            delete post.snStats.rC;
            delete post.snStats._d_evRTm_t;
            delete post.snStats._d_evPTm_t;
            delete post.snStats._d_uTm_t;
            delete post.snStats.nAC;

            // "/external" data generated doesn't create snStats if nothing to create for it.
            // however, native-styling widgets require that something exists
        } else if (!post.snStats) {
            post.snStats = {};
        }

        delete post.favorite;
        delete post.hidden;
        delete post.likeFlag;
        delete post.hasConversation;
        // delete post.sourceId;
        // delete post.messageType;
        delete post.messageSubType;
        delete post.universalMessageId;
        // delete post.urlEntities;
        delete post.replyStatusId;
        // delete post.parentSnMsgId;
        delete post.parentSnCreatedTimeYearMonth;

        if (post.senderProfile) {
            delete post.senderProfile.snType;
            // delete post.senderProfile.snId;
            delete post.senderProfile.profileWorkflowProperties;
            delete post.senderProfile.universalProfileId;
            delete post.senderProfile.accountsFollowedByUser;
            delete post.senderProfile.accountsFollowingUser;
            delete post.senderProfile.accountsUnFollowingUser;
            delete post.senderProfile.accountsUnFollowedByUser;
            delete post.senderProfile.accountsBlockingUser;
            delete post.senderProfile.profileTags;
            delete post.senderProfile.urlEntities;
        }

        delete post.receiverProfile;
        delete post.createdTime;
        delete post.modifiedTime;
        delete post.snCreatedTimeYearMonth;
        delete post.actionTime;

        if (post.workflowProperties) {
            delete post.workflowProperties.clientQueues;
            delete post.workflowProperties.partnerQueues;
            delete post.workflowProperties.status;
        } else if (post.taxonomy) {
            post.workflowProperties = {
                clientCustomProperties: post.taxonomy.clientCustomProperties,
                partnerCustomProperties: post.taxonomy.partnerCustomProperties,
            };
            delete post.taxonomy;
        }

        // delete post.conversationId;
        delete post.parentMsgType;
        delete post.deleted;
        delete post.archived;
        delete post.brandPost;
        delete post.parentBrandPost;
        delete post.hasBrandComment;

        if (post.rating) {
            post.itemRating = post.rating;
        }

        if (post.ratingRange) {
            post.maxRating = post.ratingRange;
        }

        if (post.channelCustomProperties) {
            const key = Object.keys(post.channelCustomProperties).find(property =>
                property.includes('product')
            );
            post.ratingProduct =
                post.channelCustomProperties[key] && post.channelCustomProperties[key][0];
        }

        if (!post.images) {
            post.images = [];
        }

        if (!post.videos) {
            post.videos = [];
        }

        if (post.dynamicAdPostDetails?.postVariations?.length) {
            // Use First Variation, if available.
            const variant = post.dynamicAdPostDetails.postVariations[0];

            if (variant.postText?.length) {
                post.content.message = variant.postText;
            }

            if ((variant.type === 'PHOTO' || variant.type === 'LINK') && variant.imageUrl?.length) {
                post.images.unshift({
                    mediaType: variant.type,
                    url: variant.imageUrl,
                });
            }

            if (variant.type === 'VIDEO' && variant.imageUrl?.length) {
                post.videos.unshift({
                    mediaType: variant.type,
                    url: variant.imageUrl,
                });
            }
        }

        // Convert mediaurl/mediatype to url and type, for suggestion column posts.
        post.images = post.images.map
            ? post.images.map(image => {
                  if (image.mediaUrl && !image.url) {
                      image.url = image.mediaUrl;
                  }
                  if (image.mediaType && !image.type) {
                      image.type = image.mediaType;
                  }
                  return image;
              })
            : post.images;

        // For STORY_MESSAGE engine
        if (post.imageUrl) {
            post.images.push({
                type: 'PHOTO',
                url: post.imageUrl,
                poster: null,
                caption: null,
                description: null,
            });
        }

        if (post.mediaList) {
            if (post.mediaList.length > 1) {
                PostsTransform.sortMediaList(post.mediaList);
            }

            PostsTransform.processMedia(post, post.mediaList);

            delete post.mediaList;
        } else if (post.permalink) {
            if (post.snType === 'youtube') {
                post.videos.push({
                    type: 'VIDEO',
                    url: post.permalink,
                    poster: null,
                    caption: null,
                    description: null,
                });
            }
        }

        // NOTE: KEEP THESE!!  For debugging purposes

        // delete post.snMsgId;
        // delete post.partnerId;
        // delete post.clientId;
        // delete post.sourceType;
        // delete post.snType;
        // delete post.permalink;
        // delete post.title;
        // delete post.message;
        // delete post.textEntities;
        // delete post.senderProfile;
        // delete post.snCreatedTime;
        // delete post.snModifiedTime;
        // delete post.workflowProperties;
        // delete post.location;
        // delete post.language;
        // delete post.numberOfComments;
        // if (!post.images.length) {
        //     delete post.images;
        // }
        // if (!post.videos.length) {
        //     delete post.videos;
        // }

        if (post.actualTweet) {
            PostsTransform.transformPost(post.actualTweet);
        }

        if (post.alertDetails) {
            post.alertDetails = PostsTransform.normalizeAlertDetails(post.alertDetails);
        }
    }

    private static setCustomFieldLinkUrl(post: any, url: string): void {}

    private static normalizeAlertDetails(alertDetails: AlertDetail[]): LineChartSeries[] {
        return [
            {
                name: 'alertSeries',
                type: 'NUMBER',
                data: alertDetails.map(series => {
                    return {
                        x: series.tS,
                        y: series.val,
                        isAnomaly: series.isAnomaly,
                    };
                }),
            },
        ];
    }

    private static getMediaTypeFromUrl(url: string): string {
        if (/.*\.(jpg|jpeg|gif|png).*/gi.test(url)) {
            return 'PHOTO';
        } else if (/.*\.(mp4|m4v).*/gi.test(url)) {
            return 'VIDEO';
        } else if (/.*\/(youtu\.be|youtube\.com)\/.*/gi.test(url)) {
            return 'VIDEO';
        }
        return null;
    }

    static transformMedia(media: any): PostMedia {
        switch (media.type) {
            case 'LINK':
            case 'PHOTO':
            case 'GIF':
                const url = media.picture || media.source;
                return {
                    type: media.type,
                    url,
                    caption: media.caption,
                    description: media.description,
                };

            case 'VIDEO':
                const result = {
                    type: media.type,
                    url: PostsTransform.findFBMediaLink(media),
                    poster: media.picture || media.previewImageUrl,
                    caption: media.caption,
                    description: media.description,
                };

                PostsTransform.setYouTubePoster(result);
                return result;

            default:
                return undefined;
        }
    }

    // Look at known media properties and try to find a facebook.com url to use as the media url.
    private static findFBMediaLink(media: any) {
        let link = media.source;

        if (!link) {
            ['imageUrl', 'previewImageUrl', 'picture'].some(mediaLink => {
                if (media[mediaLink] && media[mediaLink].indexOf('facebook.com') !== -1) {
                    link = media[mediaLink];
                    return true; // Aborts the loop
                }

                return false;
            });
        }

        return link;
    }

    // Overwrites YouTube poster image with a better one, if found
    private static setYouTubePoster(media: PostMedia) {
        let url = media.url;

        if (url) {
            const youtube1 = /youtube.com/.test(url);
            const youtube2 = /youtu\.be/.test(url);

            if (youtube1 || youtube2) {
                const replacer = 'www.youtube.com/embed/';
                url = url
                    .replace('http://', '//')
                    .replace('www.youtube.com/v/', replacer)
                    .replace('www.youtube.com/watch?v=', replacer);

                let id: string;
                if (youtube1) {
                    id = url.replace(/.*(v=|embed\/|v%3D)([a-z0-9-_]{8,})&?.*/i, '$2');
                } else {
                    id = url.replace(/.*\/\/youtu\.be\/([A-Za-z0-9-_]*).?/i, '$1');
                }

                media.poster = `https://img.youtube.com/vi/${id}/0.jpg`;
            }
        }
    }

    static sortMediaList(mediaList: any[]) {
        mediaList.sort((a, b) => {
            return a.name === 'normal' && b.name === 'full'
                ? 1
                : a.name === 'full' && b.name === 'normal'
                ? -1
                : 0;
        });
    }

    static processMedia(post: Post, mediaList: any[]) {
        mediaList.forEach((media, index) => {
            let type = media.type;

            // LINKs can contain contain images or videos.  This is incorrect.  Sprinklr backend
            // should be not flagging them as LINKs.
            // In any case, converting LINKs to images was added as a fix to DISPLAY-952.
            // However, this caused side-effect for DISPLAY-1285.
            // Do some deeper inspection and set the right media type.

            if (type === 'LINK') {
                const url = media.source || media.picture;

                let newType = PostsTransform.getMediaTypeFromUrl(url);
                if (null === newType && media.previewImageUrl) {
                    //Preview Image URL may not have an obvious suffix, so default to photo, if possible.
                    newType =
                        PostsTransform.getMediaTypeFromUrl(media.previewImageUrl) || 'PHOTO';
                    media.source = media.previewImageUrl;
                }

                if (newType) {
                    type = newType;
                }
            }

            switch (type) {
                case 'CAROUSEL':
                    if (media.childMedias) {
                        if (!post.mediaType) {
                            post.mediaType = type;
                        }

                        PostsTransform.processMedia(post, media.childMedias);
                    }
                    break;

                case 'PHOTO':
                case 'GIF':
                    if (media.picture || media.source) {
                        const resolvedImage = PostsTransform.transformMedia(media);
                        if (resolvedImage) {
                            post.images.push(PostsTransform.transformMedia(media));
                        }
                    }
                    break;

                case 'VIDEO':
                    // NOTE as per VT-1176 we don't want the videos to be added to the videos array when they are not in the fist position of the media list array
                    // This may change when we fully support the media list carousel type
                    if (index !== 0) {
                        break;
                    }
                    if (media.source) {
                        // Video elements can have source being images.  Convert in this case.
                        if (media.source.indexOf('.jpg') !== -1) {
                            media.type = 'PHOTO';
                            media.picture = media.source;
                            const resolvedMedia = PostsTransform.transformMedia(media);
                            if (resolvedMedia) {
                                post.images.push(resolvedMedia);
                            }
                        } else {
                            const resolvedMedia = PostsTransform.transformMedia(media);
                            if (resolvedMedia) {
                                post.videos.push(resolvedMedia);
                            }
                        }
                    } else if (media.picture) {
                        media.type = 'PHOTO';
                        const resolvedMedia = PostsTransform.transformMedia(media);
                        if (resolvedMedia) {
                            post.images.push(resolvedMedia);
                        }
                    }
                    break;

                // Other types that are ignored for the moment:
                // PDF, DOCUMENT, PRESENTATION, GRAFFITI, AUDIO, EVENT, FILE, FB_DOC, STICKER, FEEDBACK, TEXT_HTML, TEXT_PLAIN
                default:
                    break;
            }

            // Defined when product tagging is done from Space UI.  This is used for Gallery only
            if (media.mediaProperties) {
                if (media.mediaProperties.shoppableLink) {
                    if (!post.workflowProperties) {
                        post.workflowProperties = { partnerCustomProperties: {} } as any;
                    } else if (!post.workflowProperties.partnerCustomProperties) {
                        post.workflowProperties.partnerCustomProperties = {};
                    }

                    post.workflowProperties.partnerCustomProperties[customFieldLink] = [
                        media.mediaProperties.shoppableLink,
                        'shoppable',
                    ];
                } else if (media.mediaProperties.productTags) {
                    // Calculate the aspect ratio because x,y are calcualted against the source image
                    let ratio: number;
                    if (media.mediaDetails?.imageDetails) {
                        ratio =
                            media.mediaDetails.imageDetails.height /
                            media.mediaDetails.imageDetails.width;
                    } else {
                        ratio = 1.0;
                    }

                    media.mediaProperties.productTags.forEach(tag => {
                        const product = {
                            id: '',
                            name: tag.name,
                            medias: [{ url: null }], // Needed to avoid exceptions
                            productUrl: tag.redirectUrl,
                            price: tag.price,
                            currency: tag.currency,
                            currencySymbol: tag.currencySymbol,
                            coordinates: {
                                x: tag.x,
                                y: tag.y,
                                ratio: ratio,
                            },
                        };

                        if (!post.products) {
                            post.products = [];
                        }

                        post.products.push(product);

                        // Gallery publishing needs to know where products originated from.
                        // Either from old-school custom fields or this, productTags.
                        post.fromProductTags = true;
                    });
                }
            }
        });
    }

    private static getThumbUrl(url, maxWidth, maxHeight) {
        const domain = 'thumb.sprinklr.com';
        return `//${domain}/?d=${maxWidth}x${maxHeight}&url=${encodeURIComponent(url)}`;
    }

    static addThumbsToImages(posts: Post[]): Post[] {
        return posts.map((post: Post) => {
            if (post.images && post.images.length) {
                post.images.forEach((media: PostMedia, i: number) => {
                    switch (media.type) {
                        case 'LINK':
                        case 'PHOTO':
                        case 'GIF':
                            //some twitter GIFs come through as mp4s. This makes sure the thumbnail is taken from the image preview so that the thumbnails are not broken links. -AMR
                            const url = media.url.includes('.mp4')
                                ? (post as any).content?.attachment?.mediaList?.[i]
                                      ?.previewImageUrl ?? ''
                                : media.url;
                            media.thumbs = {
                                small: PostsTransform.getThumbUrl(url, 240, 240),
                                medium: PostsTransform.getThumbUrl(url, 640, 640),
                                large: PostsTransform.getThumbUrl(url, 1280, 1280),
                                hd: PostsTransform.getThumbUrl(url, 1920, 1920),
                                uhd: PostsTransform.getThumbUrl(url, 3840, 3840),
                            };
                            break;
                        default:
                        //Only add thumbs to images
                    }
                });
            }

            return post;
        });
    }

    static updateImageUrl(post: Post, newUrl: string) {
        if (newUrl && post && post.images?.length) {
            post.unique += '1';
            post.images[0].url = newUrl;
            PostsTransform.addThumbsToImages([post]);
        }
    }
}
