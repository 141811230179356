import { WidgetOptions } from '@sprinklr/stories/widget/WidgetOptions';
import { WidgetOptionsImpl } from 'models/Widget/WidgetOptions';
import { EmptyDataMessage, SmartSize, TimeStampOptions } from '@sprinklr/stories/widget/types';
import { observable } from 'mobx';
import { AnomalyLineChart, Enabled, EnabledSize, SourcePost } from './types';

export interface AutoAlertsWidgetOptions extends WidgetOptions {
    lineChart?: AnomalyLineChart;
}

export class AutoAlertsWidgetOptionsImpl extends WidgetOptionsImpl
    implements AutoAlertsWidgetOptions {
    @observable showText = true;
    @observable showTimeStamp = false;
    @observable timeStampSize = 13;
    @observable timeStampFormat: TimeStampOptions = 'timeAgo';
    @observable endianTime = 'D MMM YYYY, h:mm a';
    @observable animationType = 'drop';

    @observable pad: EnabledSize = {
        enabled: true,
        size: 30,
    };
    @observable title: EnabledSize = {
        enabled: true,
        size: 30,
    };
    @observable description: EnabledSize = {
        enabled: true,
        size: 40,
    };

    @observable altertTypeIcon: Enabled = {
        enabled: true,
    };
    @observable detectedTime: EnabledSize = {
        enabled: true,
        size: 30,
    };

    @observable lineChart: AnomalyLineChart = {
        enabled: true,
        cumulative: false,
        line: {
            color: '',
            size: 50,
        },
        points: {
            enabled: true,
            size: 34,
            color: '',
        },
        segments: {
            enabled: true,
            size: 50,
        },
        fill: {
            enabled: true,
            color: '',
        },
        spike: {
            enabled: true,
            metric: {
                enabled: true,
                size: 60,
            },
            metricName: {
                enabled: true,
                size: 50,
            },
        },
    };

    @observable sourcePost: SourcePost = {
        enabled: true,
        useNativeEmbed: true,
        options: {},
        dropshadow: {
            enabled: true,
            color: 'rgba(0, 0, 0, 0.5)',
        },
        multi: {
            direction: 'vertical',
            slideDuration: 4000,
        },
    };

    @observable emptyData: EmptyDataMessage = {
        enabled: false,
        image: {
            enabled: true,
            fit: 'contain',
            url: '',
        },
        message: {
            enabled: true,
            text: 'No Data Found',
            color: null,
            size: 30,
        },
    };

    @observable smartSize: SmartSize = {
        enabled: false,
        mode: 'responsive',
        size: 'xl',
        baseline: {
            title: {
                size: 40,
            },
            description: {
                size: 40,
            },
            detectedTime: {
                size: 30,
            },
            pad: {
                size: 30,
            },
            lineChart: {
                line: {
                    size: 50,
                },
                points: {
                    size: 34,
                },
                spike: {
                    metric: {
                        size: 60,
                    },
                    metricName: {
                        size: 50,
                    },
                },
            },
            sourcePost: {
                options: {
                    userGroupAvatarSize: 25,
                    userGroupIconSize: 10,
                    userGroupNameSize: 15,
                    userGroupScreenNameSize: 12,
                    engagementMetricsSize: 15,
                    postImageTextSize: 10,
                    textFitMin: 10,
                    postLineClampValue: 8,
                    postTextOnlySize: 15,
                    imagePostLineClampValue: 4,
                },
            },
        },
        optionDefaults: {
            sourcePost: {
                options: {
                    classOverrides: {
                        postText: {
                            default: 'no-grow p-reset pl-05 pr-05 pt-05 pb-05',
                        },
                    },
                    textOnlyTextFormat: 'clamp',
                },
            },
        },
        breakpoints: {
            xs: {
                factor: 0.35,
                options: {
                    title: {
                        size: 10,
                    },
                    sourcePost: {
                        options: {
                            userGroupAvatarSize: 5,
                            postLineClampValue: 3,
                            imagePostLineClampValue: 1,
                            postTextOnlySize: 7.5,
                        },
                    },
                },
            },
            s: {
                factor: 0.6,
                options: {
                    title: {
                        size: 15,
                    },
                    sourcePost: {
                        options: {
                            userGroupAvatarSize: 10,
                            postLineClampValue: 3,
                            imagePostLineClampValue: 1,
                            postTextOnlySize: 10,
                        },
                    },
                },
            },
            m: {
                factor: 0.8,
                options: {
                    title: {
                        size: 20,
                    },
                    sourcePost: {
                        options: {
                            postLineClampValue: 4,
                            imagePostLineClampValue: 2,
                            postTextOnlySize: 12,
                        },
                    },
                },
            },
        },
    };
}
