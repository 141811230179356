export default class IndexedList<T extends { id: any }> {
    public index: { [s: string]: T } = {};
    public list: T[] = [];
    private idProperty: string | Function;

    constructor(itemsOrIdProperty?: T[] | string | Function, idProperty?: string | Function) {
        if (
            arguments.length === 1 &&
            (typeof itemsOrIdProperty === 'string' || typeof itemsOrIdProperty === 'function')
        ) {
            idProperty = itemsOrIdProperty as string | Function;
            itemsOrIdProperty = null;
        }

        this.idProperty = idProperty || 'id';
        this.addAll(itemsOrIdProperty as T[]);
    }

    setIdProperty(idProperty?: string | Function) {
        this.idProperty = idProperty || 'id';
    }

    public get = function(id): T {
        return this.index[id] || null;
    };

    public add(...items: T[]) {
        return this.addAll(items);
    }

    public addAll(items: T[]) {
        if (!items) {
            return;
        }

        items.map(item => {
            const id =
                typeof this.idProperty === 'string' ? item[this.idProperty] : this.idProperty(item);
            this.index[id] = item;
            this.list.push(item);
        });
    }

    public remove(...items: T[]) {
        return this.removeAll(items);
    }

    public removeAll(items: T[]) {
        if (!items) {
            return;
        }

        items.map(item => {
            const index = this.list.indexOf(item);
            if (index !== -1) {
                this.list.splice(index, 1);
            }

            const id =
                typeof this.idProperty === 'string' ? item[this.idProperty] : this.idProperty(item);
            delete this.index[id];
        });
    }
}
