import { AnalyticsRequest, Metric, GroupBy, Sort } from './PanelTemplatesTheme/PanelTemplatesTheme';
import { DefaultPosition } from './PanelTemplatesTheme/constants';
import { Panel } from '../../models/Panel/Panel';
import { WIDGET_LABEL_SIZE } from '../../components/Widget/WidgetLabel/constants';
import ListeningDimensions from '@sprinklr/stories-services/AnalyticsService/ListeningDimensions';

export var TemplateLineChart: Panel = {
    id: 'line-chart',
    name: 'Line Chart',
    description: '',
    tags: ['Standard', 'Line Chart', 'landscape', 'portrait'],
    widget: {
        id: '1',
        position: {
            width: '1920px',
            height: '1080px',
            top: '0',
            left: '0',
        },
        classes: 'template_line_chart',
        children: [
            {
                type: 'lineChart',
                id: '1',
                name: 'linechart',
                useGlobalTimePeriod: true,
                analyticsRequests: [
                    // Note: All global variables like reportingEngine, report, filters, and projections
                    // are injected via AnalyticsRequest.getRequests()
                    new AnalyticsRequest({
                        limit: 100,
                        projections: [
                            new Metric({
                                ADVOCACY: { dimensionName: 'ADVOCACY_ACTIVE_USERS' },
                            }),
                        ],
                        groupBys: [
                            new GroupBy({
                                LISTENING: { source: ListeningDimensions.day },
                                INBOUND_MESSAGE: {
                                    source: ListeningDimensions.day,
                                    dimensionName: 'snCTm',
                                },
                                PLATFORM: {
                                    source: ListeningDimensions.day,
                                    dimensionName: 'date',
                                },
                                BENCHMARKING: {
                                    source: ListeningDimensions.day,
                                    dimensionName: 'date',
                                },
                                PAID: {
                                    source: ListeningDimensions.day,
                                    dimensionName: 'measurementTime',
                                },
                                ADVOCACY: {
                                    source: ListeningDimensions.day,
                                    dimensionName: 'date',
                                },
                                STORY_MESSAGE: {
                                    source: ListeningDimensions.day,
                                    dimensionName: 'CREATED_TIME',
                                },
                            }),
                            new GroupBy({
                                LISTENING: {
                                    source: ListeningDimensions.topicName,
                                    limitType: 'TOP',
                                    limit: 8,
                                },
                                INBOUND_MESSAGE: {
                                    dimensionName: 'ACCOUNT_ID',
                                    limitType: 'TOP',
                                    limit: 8,
                                },
                                PLATFORM: {
                                    dimensionName: 'ACCOUNT_ID',
                                    limitType: 'TOP',
                                    limit: 8,
                                },
                                BENCHMARKING: {
                                    dimensionName: 'BRAND_ID',
                                    limitType: 'TOP',
                                    limit: 8,
                                }, //BENCHMARKING_ACCOUNT_ID
                                PAID: { dimensionName: 'accountIds', limitType: 'TOP', limit: 8 },
                                ADVOCACY: {
                                    dimensionName: 'AUDIENCE_ACTIVITY_TYPE',
                                    limitType: 'TOP',
                                    limit: 8,
                                },
                                RDB_FIREHOSE: {
                                    dimensionName: 'RDB_QUERY_IDS',
                                    limitType: 'TOP',
                                    limit: 8,
                                },
                                STORY_MESSAGE: {
                                    dimensionName: 'RDB_DATA_SOURCE',
                                    limitType: 'TOP',
                                    limit: 8,
                                },
                            }),
                        ],
                        sorts: [new Sort({ order: 'DESC', projections: 0 })],
                        timePeriods: [{ key: 'last_7_days' }, { previousPeriod: true }],
                    }) as any,
                ],
                label: {
                    titleText: 'Line Chart',
                    enabled: true,
                    size: WIDGET_LABEL_SIZE.primary,
                },
                position: DefaultPosition,
                options: {
                    player: {
                        playing: true,
                        duration: 15,
                    },
                    showXAxis: true,
                    showYAxis: true,
                    xAxisTickAngle: 0,
                    interpolation: 'linear',
                    scaleType: 'linear',
                    showTopPointMarker: false,
                    showTopPointLabel: true,
                    showTopPointValue: true,
                    showTopPointIcon: true,
                    showTopPointOrb: true,
                    showPointMarkers: false,
                    labelSize: 12,
                    valueSize: 60,
                    iconSize: 10,
                    orbSize: 10,
                    maxTopPoints: 10,
                    lineWidth: 5,
                    channelColoredStroke: true,
                    smartSize: {
                        enabled: true,
                        mode: 'responsive',
                        size: 'xl',
                    },
                    portrait: {
                        widgetOrientation: 'portrait',
                    },
                },
            },
        ],
    },
};
