import React, { ReactElement } from 'react';
import i18n from 'i18next';
import {
    DividerOptions,
    MetaOptions,
    MetricsOptions,
    PublicationsOptions,
    SummaryOptions,
    SummaryTitleOptions,
} from './types';
import { action } from 'mobx';
import ActionCheckboxSliderColorSwatchGroup from 'components/_UI/Forms/Actions/ActionCheckboxSliderColorSwatchGroup';

// ActionStoryOption
export interface ActionStoryOptionProps {
    label: string;
    fallbackColor: string;
    options:
        | SummaryOptions
        | MetricsOptions
        | PublicationsOptions
        | DividerOptions
        | SummaryTitleOptions
        | MetaOptions;
}

export default function ActionStoryOption({
    options,
    label,
    fallbackColor,
}: ActionStoryOptionProps): ReactElement {
    return (
        <ActionCheckboxSliderColorSwatchGroup
            label={i18n.t(label)}
            showValue
            checkbox={{
                checked: options.enabled,
                value: 'enabled',
                option: options,
            }}
            slider={{
                min: 0,
                max: 100,
                value: 'size',
                option: options,
            }}
            colorSwatch={{
                option: null,
                value: null,
                onClear: action(() => (options.color = '')),
                color: options.color || fallbackColor,
                onChange: action(
                    ({ rgb: { r, g, b, a } }) => (options.color = `rgba(${r}, ${g}, ${b}, ${a})`)
                ),
                secondaryIcon: options.color ? 'icon-undo' : undefined,
            }}
        />
    );
}
