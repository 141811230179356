import * as React from 'react';
import i18n from 'i18next';
import {
    blackListTunables,
    headerTunables,
    labelTunables,
    legendTunables,
    metricValueTunables,
    opacityTunables,
    percentChangeTunables,
    smartSizeTunables,
    textFormatTunables,
    zoomTunables,
} from 'components/Sidebar/RenderTunables/SharedTunables';
import { CollapseProps, TunablesCollapse } from 'components/Sidebar/RenderTunables/RenderTunables';
import { Widget } from '@sprinklr/stories/widget/Widget';
import LabelGroup from 'components/_UI/Forms/LabelGroup/LabelGroup';
import { SmallMultiplesPieChartWidgetOptions } from 'src/widgets/SmallMultiplesPieChartWidget/options';
import ActionCheckboxSliderGroup from 'components/_UI/Forms/Actions/ActionCheckboxSliderGroup';
import ActionColorSwatchGroup from 'components/_UI/Forms/Actions/ActionColorSwatchGroup';
import ActionSliderGroup from 'components/_UI/Forms/Actions/ActionSliderGroup';
import ActionCheckboxGroup from 'components/_UI/Forms/Actions/ActionCheckboxGroup';

const SmallMultiplesPieChartTunables = (widget: Widget): TunablesCollapse[] => [
    {
        props: {
            label: i18n.t('Small Multiples'),
            startOpen: true,
        } as CollapseProps,
        children: [...headerTunables(widget), opacityTunables(widget), smartSizeTunables(widget)],
    },
    {
        props: {
            label: i18n.t('Labels'),
            startOpen: true,
        } as CollapseProps,
        children: [
            labelTunables(widget),
            ...textFormatTunables(widget, { clamp: true }),
            ...metricValueTunables(widget, { value: { enable: true, size: true }, format: true }),
            ...percentChangeTunables(widget),
        ],
    },
    legendTunables(widget, {
        header: false,
        orientation: true,
        orientationOpts: ['bottom', 'right'],
        labelSize: true,
        ratio: false,
        value: false,
        wrap: true,
    }),
    {
        props: {
            label: i18n.t('Tunables'),
            startOpen: true,
        } as CollapseProps,
        children: [
            <ActionSliderGroup
                key='widget.options.maxItems'
                label={i18n.t('Max Items')}
                option={widget.options}
                value='maxItems'
                showValue
                min={2}
                max={15}
            />,
            <ActionCheckboxGroup
                key='widget.options.showOtherItems'
                label={i18n.t('Group Remaining as Other')}
                option={widget.options}
                value='showOtherItems'
            />,
            <ActionCheckboxSliderGroup
                key='widget.options.showInnerRadius'
                label={i18n.t('Inner Radius')}
                showValue
                checkbox={{
                    option: widget.options,
                    value: 'showInnerRadius',
                }}
                slider={{
                    option: widget.options,
                    value: 'innerRadiusSize',
                    min: 0,
                    max: 49,
                }}
            />,
            <LabelGroup label={i18n.t('Empty Wedge Color')} key='widget.options.emptyWedgeColor'>
                <ActionColorSwatchGroup
                    key='widget.options.emptyWedgeColor'
                    option={widget.options as SmallMultiplesPieChartWidgetOptions}
                    value='emptyWedgeColor'
                />
            </LabelGroup>,
            blackListTunables(widget),
        ],
    },
    zoomTunables(widget, { zoom: true }),
];

export default SmallMultiplesPieChartTunables;
