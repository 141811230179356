import SockJS from 'sockjs-client';
const Stomp: any = (require('stompjs/lib/stomp') as any).Stomp;

export interface StompStringMessage {
    body: string;
    command: string;
    headers: any;
}

export interface StompMessage {
    body: any;
    command: string;
    headers: any;
}

export interface DisplayEvent {
    action: 'CREATED' | 'UPDATED' | 'DELETED' | 'PUBLISHED';
    objectId: string;
    objectType:
        | 'Panel'
        | 'Widget'
        | 'Location'
        | 'Layout'
        | 'Storyboard'
        | 'PanelVersion'
        | 'SceneVersion'
        | 'StoryboardVersion';
    details?: any;
    timestamp: number;
}

export interface StompEventMessage extends StompMessage {
    body: DisplayEvent;
}

export type StompMessageHandler = (message: StompMessage | StompEventMessage) => any;

export interface TopicHandler {
    topic: string;
    handler: StompMessageHandler;
}

export default class SocketService {
    url: string;

    connected = false;
    sockJsClient: any = null;
    stompClient: any = null;

    subscriptions: { [channel: string]: { unsubscribe: () => any }[] } = {};
    handlers: { [channel: string]: StompMessageHandler[] } = {};

    constructor(url) {
        this.url = url;
    }

    disconnect() {
        this.connected = false;

        // clear our handler references because those will need to be recreated if we reconnect
        this.subscriptions = {};

        // kill existing connections and remove references
        if (this.stompClient) {
            this.stompClient.disconnect();
            this.stompClient = null;
            this.sockJsClient = null;
        }
    }

    connect() {
        console.log('Connecting...');

        this.disconnect();

        this.sockJsClient = new SockJS(this.url);

        this.stompClient = Stomp.over(this.sockJsClient);

        this.stompClient.debug = function(message: string) {
            // suppress logging
        };

        this.stompClient.connect({}, this.onConnected, this.onConnectionFailure);
    }

    onConnected = frame => {
        console.log('Connected');

        this.connected = true;

        // console.log('Websocket connected', this.sockJsClient);
        // console.log('Stomp client connected', this.stompClient);

        // register subscriptions for all the handlers
        for (var channel in this.handlers) {
            this.handlers[channel].forEach((handler: StompMessageHandler) => {
                this.createSubscription(channel, handler);
            });
        }
    };

    onConnectionFailure = () => {
        console.log('Connection failure');

        this.disconnect();

        requestAnimationFrame(() => {
            setTimeout(() => {
                if (!this.sockJsClient) {
                    this.connect();
                }
            }, 5000);
        });
    };

    send(channel: string, message: string, priority?: number) {
        this.stompClient.send(channel, { priority: priority || 9 }, message);
    }

    subscribe(channel: string, handler: StompMessageHandler): void {
        if (!this.handlers[channel]) {
            this.handlers[channel] = [];
        }

        const currentIndex = this.handlers[channel].indexOf(handler);
        if (currentIndex === -1) {
            this.handlers[channel].push(handler);
            this.createSubscription(channel, handler);
        }
    }

    unsubscribe(channel: string, handler: StompMessageHandler): void {
        if (!this.handlers[channel]) {
            return;
        }

        const index = this.handlers[channel].indexOf(handler);
        if (index !== -1) {
            console.log('Unsubscribing from', channel);

            if (this.subscriptions[channel] && this.subscriptions[channel][index]) {
                this.subscriptions[channel][index].unsubscribe();
                this.subscriptions[channel].splice(index, 1);
            }

            this.handlers[channel].splice(index, 1);
        }
    }

    private createSubscription(channel: string, handler: StompMessageHandler) {
        if (!this.subscriptions[channel]) {
            this.subscriptions[channel] = [];
        }

        if (this.connected) {
            console.log('Subscribing to', channel);

            const subscription = this.stompClient.subscribe(
                channel,
                (message: StompStringMessage) => {
                    handler({
                        headers: message.headers,
                        body: JSON.parse(message.body),
                        command: message.command,
                    });
                }
            );
            this.subscriptions[channel].push(subscription);
        } else {
            if (!this.sockJsClient) {
                console.log('No SockJS client found, auto connecting...');
                this.connect();
            }

            console.log('Will subscribe to', channel, 'when connected');
        }
    }
}
