import { AnalyticsRequest, Metric } from './PanelTemplatesTheme/PanelTemplatesTheme';
import { DefaultWidgetPosition } from './PanelTemplatesTheme/constants';
import { Widget } from '@sprinklr/stories/widget/Widget';
import { WIDGET_LABEL_SIZE } from '../../components/Widget/WidgetLabel/constants';
import { TEXT_SHADOW_DEFAULT } from 'models/Widget/Widget';

export var WidgetSingleMetric: Widget = {
    type: 'metric',
    id: 'single-metric',
    name: 'Single Metric',
    position: DefaultWidgetPosition,
    useGlobalTimePeriod: true,
    analyticsRequests: [
        // Note: All global variables like reportingEngine, report, filters, and projections
        // are injected via AnalyticsRequest.getRequests()
        new AnalyticsRequest({
            limit: 1,
            projections: [
                new Metric({
                    STORY_MESSAGE: { dimensionName: 'STORY_ACTIVE_COUNT' },
                }),
            ],
            groupBys: [],
            sorts: [],
            timePeriods: [{ key: 'last_7_days' }, { previousPeriod: true }],
        }) as any,
    ],
    label: {
        titleText: 'Single Metric',
        overlap: true,
        size: WIDGET_LABEL_SIZE.primary,
        enabled: false,
        textShadow: TEXT_SHADOW_DEFAULT,
    },
    options: {
        player: {
            playing: true,
            duration: 15,
        },
        showBackgroundCircle: false,
        metricValueType: 'primary',
        primaryClasses: 'middle right',

        showMetricValue: true,
        metricValueSize: 60,

        showMetricName: true,
        metricNameSize: 15,

        showMetricChange: true,
        metricPercentChangeSize: 15,
        highlightPercentChange: false,
        smartSize: {
            enabled: true,
            mode: 'responsive',
            size: 'm',
        },
        portrait: {
            widgetOrientation: 'portrait',
            metricValueSize: 45,
        },
    },
};
