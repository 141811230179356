import * as React from 'react';
import { action } from 'mobx';
import { observer } from 'mobx-react';
import { LabeledRadio } from 'components/spaceKit';

export type ActionRadioGroupProps = {
    value: string;
    option: any;
    valueCheck: boolean | string;
    disabled?: boolean;
    label?: string;
};

const ActionRadioGroup: React.FC<ActionRadioGroupProps> = ({
    value,
    option,
    disabled,
    label,
    valueCheck,
}) => (
    <LabeledRadio
        checked={option[value] === valueCheck}
        disabled={disabled}
        label={label}
        onClick={action(() => (option[value] = valueCheck))}
    />
);

ActionRadioGroup.displayName = 'ActionRadioGroup';
export default observer(ActionRadioGroup);
