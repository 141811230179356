import * as React from 'react';
import i18n from 'i18next';
import {
    adsTunables,
    engagementTunables,
    headerTunables,
    imageAdjustmentTunables,
    imagePostTunables,
    opacityTunables,
    profanityTunables,
    smartSizeTunables,
    textEntityTunables,
    textPostTunables,
    userGroupTunables,
} from 'components/Sidebar/RenderTunables/SharedTunables';
import { CollapseProps, TunablesCollapse } from 'components/Sidebar/RenderTunables/RenderTunables';
import { Widget } from '@sprinklr/stories/widget/Widget';
import ActionSliderGroup from 'components/_UI/Forms/Actions/ActionSliderGroup';
import { Theme } from 'models/Theme/Theme';
import { LabeledSlider } from 'components/spaceKit';

const waterfallCompositions = [{ value: 'a', label: i18n.t('Default'), icon: 'singlePost_a' }];

const WaterfallTunables = (widget: Widget, mergedTheme: Theme): TunablesCollapse[] => [
    {
        props: {
            label: i18n.t('Waterfall'),
            key: 'waterfall',
            startOpen: true,
        } as CollapseProps,
        children: [
            ...headerTunables(widget),
            opacityTunables(widget),
            smartSizeTunables(widget),

            <LabeledSlider
                key='widget.options.columnCount'
                label={i18n.t('Columns')}
                value={widget.options['columnCount']}
                showValue
                min={1}
                max={10}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    widget.options['columnCount'] = parseFloat(e.target.value);

                    // The options formatter needs to have its "fixedList" option
                    // set the correct # of total posts, otherwise it won't work
                    // correctly if not large enough
                    widget.postRequests.forEach(request => {
                        if (request.format.options && request.format.type === 'ordered') {
                            request.format.options['fixedList'] = widget.options['columnCount'] * 9;
                        }
                    });
                }}
            />,
            <ActionSliderGroup
                key='widget.options.spacing'
                label={i18n.t('Post Spacing')}
                option={widget.options}
                value='spacing'
                showValue
                min={0}
                max={100}
            />,
            <ActionSliderGroup
                key='widget.options.padding'
                label={i18n.t('Post Padding')}
                option={widget.options}
                value='padding'
                showValue
                min={0}
                max={100}
            />,
        ],
    },
    // {
    //     props: {
    //         label: i18n.t('Post Composition'),
    //         startOpen: true,
    //     } as CollapseProps,
    //     children: [
    //         <ActionSegmentedGroup
    //             key='widget.options.postCompositionType'
    //             label={i18n.t('Composition')}
    //             option={ widget.options }
    //             value='postCompositionType'
    //             options={ waterfallCompositions }
    //             extraChanges={ postCompositionConfigWaterfall } />,
    //     ]
    // },
    textPostTunables(widget, mergedTheme, {
        textOnlyTextFormat: false,
    }),
    imagePostTunables(widget, mergedTheme, {
        imageBackgroundSize: false,
        imagePostHideText: false,
        postImageTextFormat: false,
    }),
    imageAdjustmentTunables(widget),
    userGroupTunables(widget),
    engagementTunables(widget),
    adsTunables(widget),
    textEntityTunables(widget),
    profanityTunables(widget),
];

export default WaterfallTunables;
