export class ArrayChanged<T> {
    added: Array<T> = [];
    removed: Array<T> = [];
    modified: Array<T> = [];

    isEmpty() {
        return !(this.added.length || this.removed.length || this.modified.length);
    }
}

export class ArrayDiff<T> {
    private added: Array<T> = [];
    private removed: Array<T> = [];
    private removedPending: Array<T> = [];
    private modified: Array<T> = [];

    constructor(public field: string, public ordered = false) {}

    // Compares two ArrayDiffs and returns items which were added and/or removed.  Returns null if nothing different
    compare(source: ArrayDiff<T>): ArrayChanged<T> {
        const result: ArrayChanged<T> = new ArrayChanged<T>();
        let x;

        // Quick size compare
        if (this.added.length !== source.added.length) {
            result.added = source.added;
            // Deep comparison based on field id
        } else {
            x = this.added.length;
            while (x--) {
                if (!this.isSame(this.added[x], source.added[x])) {
                    result.added = source.added;
                    break;
                }
            }
        }

        // Quick size compare
        if (this.removed.length !== source.removed.length) {
            result.removed = source.removed;
            // Deep comparison based on field id
        } else {
            x = this.removed.length;
            while (x--) {
                if (!this.isSame(this.removed[x], source.removed[x])) {
                    result.removed = source.removed;
                    break;
                }
            }
        }

        // Quick size compare
        if (this.modified.length !== source.modified.length) {
            result.modified = source.modified;
            // Deep comparison based on field id
        } else {
            x = this.modified.length;
            while (x--) {
                if (!this.isSame(this.modified[x], source.modified[x])) {
                    result.modified = source.modified;
                    break;
                }
            }
        }

        // After determining what's changed, sync the state of the diff with the source
        this.added = source.added;
        this.removed = source.removed;
        this.modified = source.modified;

        return !result.isEmpty() ? result : null;
    }

    // Compares two item arrays and returns items which were added and/or removed.  Returns null if nothing different
    merge(itemsOld: Array<T>, itemsNew: Array<T>, debugKey?: string): ArrayChanged<T> {
        const result: ArrayChanged<T> = new ArrayChanged<T>();
        let itemOld: T, itemNew: T;
        let y: number;

        if (debugKey) {
            console.log(
                '--> merge(' +
                    debugKey +
                    '): started.  old: ' +
                    itemsOld.length +
                    ', new: ' +
                    itemsNew.length
            );
        }

        // Ordered arrays take each item's position into account
        if (this.ordered) {
            let changed = itemsNew.length !== itemsOld.length;
            if (!changed) {
                var x = itemsNew.length;
                while (x--) {
                    if (!this.isSame(itemsNew[x], itemsOld[x])) {
                        changed = true;
                        break;
                    }
                }
            }

            // Remove all old items and add all new ones so item position is retained
            if (changed) {
                result.added = itemsNew;
            }
            // Regular diffs just look for differentials
        } else {
            // Cycle through our new posts and compare them to our old posts
            // looking for new posts or posts that were removed.
            var x = itemsNew.length;
            while (x--) {
                itemNew = itemsNew[x];

                y = itemsOld.length;
                while (y--) {
                    itemOld = itemsOld[y];

                    // Found a match!
                    if (this.isSame(itemNew, itemOld)) {
                        // Check to see if anything in this post has changed.
                        // ie. Instagram image urls can expire and get renewed
                        // with new ones on the backend.
                        if (JSON.stringify(itemNew) !== JSON.stringify(itemOld)) {
                            result.modified.unshift(itemNew); // Unshift so the postsModified is newest first
                        }
                        break;
                    }
                }

                // Didn't find this new post in our old post array
                if (y < 0) {
                    result.added.unshift(itemNew); // Unshift so the postsAdded is newest first
                }
            }

            // Cycle through our old posts and compare them to our new posts
            // looking for old posts that were removed.
            x = itemsOld.length;
            while (x--) {
                itemOld = itemsOld[x];

                y = itemsNew.length;
                while (y--) {
                    itemNew = itemsNew[y];

                    // Found a match!
                    if (this.isSame(itemOld, itemNew)) {
                        break;
                    }
                }

                // Didn't find this old post in our new post array
                if (y < 0) {
                    result.removed.push(itemOld);
                }
            }
        }

        if (debugKey) {
            if (result.added.length) {
                console.log(
                    '--> merge(' + debugKey + '): ' + result.added.length + ' items were added!'
                );
            }

            if (result.removed.length) {
                console.log(
                    '--> merge(' + debugKey + '): ' + result.removed.length + ' items were removed!'
                );
            }
        }

        this.added = result.added;
        this.removed = result.removed;
        this.modified = result.modified;

        return !result.isEmpty() ? result : null;
    }

    // Apply the "added/removed/modified" changes for this ArrayDiff to the provided array argument
    //
    // current: Optional current location in the itemsOld array that the following two arguments use
    // insertAt: Optional offset from "current" to inject new posts
    // removeGuard: Optional offset from before and after "current", to prevent deletion from.
    //              If deletion is prevented, this method returns the array of T that the guard test prevented.
    //
    // Returns new current offset after additions and deletions
    sync(
        itemsOld: Array<T>,
        changed: ArrayChanged<T>,
        current?: number,
        insertAt?: number,
        removeGuard?: number
    ): number {
        let itemOld: T, itemRemoved: T;
        let y: number;
        let newCurrent = current != undefined ? current : -1;

        // If this is ordered, the entire posts array will be replaced
        if (this.ordered) {
            if (changed.added.length) {
                itemsOld.length = 0;
                itemsOld.unshift.apply(itemsOld, changed.added);
            }
        } else {
            // Update any posts that have different content but the same snMsgId
            if (changed.modified.length) {
                changed.modified.forEach((post: T) => {
                    const found = itemsOld.findIndex((post2: T) => {
                        return this.isSame(post, post2);
                    });

                    if (found != -1) {
                        itemsOld[found] = post;
                    }
                });
            }

            // Prepend our new post data to the start of our posts array
            if (changed.added.length) {
                if (!insertAt || insertAt === -1) {
                    itemsOld.unshift.apply(itemsOld, changed.added);
                    if (newCurrent != -1) {
                        newCurrent += changed.added.length;
                    }
                } else {
                    const insertOffset = this.getInjectionPoint(itemsOld, current, insertAt);
                    itemsOld.splice.apply(itemsOld, [insertOffset, 0].concat(changed.added as any));
                    if (newCurrent != -1 && insertOffset <= newCurrent) {
                        newCurrent += changed.added.length;
                    }
                }
            }

            const removeItems = (removed: Array<T>, pending: Array<T>) => {
                let x = removed.length;
                while (x--) {
                    itemRemoved = removed[x];

                    y = itemsOld.length;
                    while (y--) {
                        itemOld = itemsOld[y];

                        // Found a match!
                        if (this.isSame(itemRemoved, itemOld)) {
                            // TODO: Can't delete post if it happens to be the one at the current offset.
                            //       Filmstrip might need a padding on either side as well.

                            if (!removeGuard || removeGuard === -1) {
                                // Remove the array row
                                itemsOld.splice.call(itemsOld, y, 1);
                                if (newCurrent != -1 && y < newCurrent) {
                                    newCurrent--;
                                }
                            } else {
                                // Check to see if the offset is within our removeGuard protection window
                                if (this.isWithinGuard(itemsOld, y, current, removeGuard)) {
                                    pending.push(itemRemoved);
                                } else {
                                    // Remove the array row
                                    itemsOld.splice.call(itemsOld, y, 1);
                                    if (newCurrent != -1 && y < newCurrent) {
                                        newCurrent--;
                                    }
                                }
                            }
                            break;
                        }
                    }
                }
            };

            const pending: Array<T> = [];

            // Re-process any deletes that were blocked by a guard window previously
            // and if they fail again, put them on our pending list
            removeItems(this.removedPending, pending);

            // Cycle through our old posts and compare them to our new posts
            // looking for old posts that were removed.
            removeItems(changed.removed, pending);

            // Set our new pending list
            this.removedPending = pending;
        }

        return newCurrent;
    }

    private isSame(array1: T, array2: T) {
        return array1[this.field] === array2[this.field];
    }

    // Find out where to inject our added item in the array so they appear ASAP to the user
    private getInjectionPoint(items: Array<T>, offset: number, count: number): number {
        for (let x = 0; x < count; x++) {
            if (++offset >= items.length) {
                offset = 0;
            }
        }

        return offset;
    }

    // Returns true if the offset is within current +/-= guard
    private isWithinGuard(
        items: Array<T>,
        offset: number,
        current: number,
        guard: number
    ): boolean {
        let check = current;
        let x;

        // Check backwards
        for (x = 0; x <= guard; x++) {
            if (check === offset) {
                return true;
            }

            if (--check < 0) {
                check = items.length - 1;
            }
        }

        // Already checked at current offset, so bump by one
        check = current + 1;

        // Check forwards
        for (x = 1; x <= guard; x++) {
            if (check === offset) {
                return true;
            }

            if (++check >= items.length) {
                check = 0;
            }
        }

        return false;
    }
}
