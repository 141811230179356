import { BrandColorsArray } from '../stores/PanelTemplatesStore/PanelTemplatesTheme/constants';
import Color from 'color';
import useLocalStorage from './useLocalStorage';

type UsePresetColorsHook = [string[], (newColor: string) => void, boolean];

export const UsePresetColors = (currentColor?: string): UsePresetColorsHook => {
    const rgbaBrandColors = BrandColorsArray.map(brandColor => {
        const { r, g, b } = Color(brandColor).object();
        return `rgba(${r}, ${g}, ${b}, 1)`;
    });
    const [storedColors, setStoredColors] = useLocalStorage('stored-colors', []);
    const presetColors = [...rgbaBrandColors, ...storedColors];
    const canSave = !!currentColor && !presetColors.includes(currentColor);
    function setPresetColors(newColor: string) {
        setStoredColors([...storedColors, newColor]);
    }
    return [presetColors, setPresetColors, canSave];
};
