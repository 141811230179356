import Field from './Field';
import { FieldType, FieldDataType } from '../reporting/types';
import TemporalDimension from './TemporalDimension';
import BulkItem from './BulkItem';
import moment from 'moment';

export default class Dimension extends Field<string> {
    dimensionName: string;

    constructor(name: string, type?: FieldType, dataType?: FieldDataType) {
        super(
            name as any,
            typeof type !== 'undefined' && type !== null ? type : 'STRING',
            dataType
        );
    }

    isDate(): boolean {
        return this.type === 'DATE' || this.type === 'TIMESTAMP';
    }

    cast(value: any): any {
        switch (this.dimensionName || this.name) {
            case 'MONTH_OF_YEAR':
            case 'DAY_OF_WEEK':
            case 'TIME_OF_DAY':
                const stringValue: string = value && value.toString ? value.toString() : value;
                return new TemporalDimension(stringValue, stringValue, this.dimensionName);
            default:
            // No action
        }

        return super.cast(value);
    }

    stringify(value: any, timeFormat?: string, timeZone?: string): string {
        if (value === undefined || value === null || value === '') {
            return '';
        }

        if (value instanceof BulkItem) {
            return value.toString();
        }

        // Override format for quarters and years
        if (timeFormat) {
            if (this.name.indexOf('_1q') !== -1) {
                timeFormat = '[Q] Q, YY';
            } else if (this.name.indexOf('_1y') !== -1) {
                timeFormat = 'YYYY';
            }
        }

        switch (this.type) {
            case 'DATE':
            case 'TIMESTAMP': // Must be handled as date string for linechart, groupby weekly
                if (value instanceof Date || moment.isMoment(value)) {
                    if (!timeFormat) {
                        return value.toISOString();
                    } else {
                        return moment.tz(value, timeZone).format(timeFormat);
                    }
                }

            // fall through
            default:
            case 'TIME_INTERVAL':
            case 'NUMBER':
            case 'DECIMAL':
            case 'INTEGER':
            case 'STRING':
                return value + '';
        }
    }

    isTimeSeries(): boolean {
        if (this.type === 'DATE' || this.type === 'TIMESTAMP') {
            return true;
        }

        return false;
    }

    isClientSortDimension(): boolean {
        const name = this.dimensionName || this.name;

        switch (name.toUpperCase()) {
            case 'MONTH_OF_YEAR':
            case 'DAY_OF_WEEK':
            case 'MONTH OF YEAR':
            case 'DAY OF WEEK':
                return true;
            default:
                return false;
        }
    }
}
