import { AnalyticsRequest, GroupBy, Metric, Sort } from './PanelTemplatesTheme/PanelTemplatesTheme';
import { DefaultWidgetPosition } from './PanelTemplatesTheme/constants';
import { Widget } from '@sprinklr/stories/widget/Widget';
import { WIDGET_LABEL_SIZE } from '../../components/Widget/WidgetLabel/constants';
import ListeningDimensions from '@sprinklr/stories-services/AnalyticsService/ListeningDimensions';
import { TEXT_SHADOW_DEFAULT } from 'models/Widget/Widget';

export var WidgetBubbleGrid: Widget = {
    type: 'heatMapTableChart',
    id: 'bubble-grid',
    name: 'Bubble Grid',
    thumbSrc: 'template-bubble-grid.jpg',
    useGlobalTimePeriod: true,
    analyticsRequests: [
        // Note: All global variables like reportingEngine, report, filters, and projections
        // are injected via AnalyticsRequest.getRequests()
        new AnalyticsRequest({
            limit: 2000,
            projections: [
                new Metric({
                    STORY_MESSAGE: { dimensionName: 'NEWS_COUNT', heading: 'Mentions' },
                }),
            ],
            groupBys: [
                // y = rows
                new GroupBy({
                    LISTENING: { source: ListeningDimensions.day },
                    INBOUND_MESSAGE: { source: ListeningDimensions.day, dimensionName: 'snCTm' },
                    PLATFORM: { source: ListeningDimensions.day, dimensionName: 'date' },
                    BENCHMARKING: { source: ListeningDimensions.day, dimensionName: 'date' },
                    PAID: { source: ListeningDimensions.day, dimensionName: 'measurementTime' },
                    RDB_FIREHOSE: {
                        source: ListeningDimensions.day,
                        dimensionName: 'SN_CREATED_TIME',
                    },
                    STORY_MESSAGE: {
                        dimensionName: 'STORY_CATEGORY',
                        limitType: 'NONE',
                    },
                }),
                // x = columns
                new GroupBy({
                    LISTENING: { source: ListeningDimensions.hour },
                    INBOUND_MESSAGE: { source: ListeningDimensions.hour, dimensionName: 'snCTm' },
                    PLATFORM: { source: ListeningDimensions.hour, dimensionName: 'date' },
                    BENCHMARKING: { source: ListeningDimensions.hour, dimensionName: 'date' },
                    PAID: { source: ListeningDimensions.hour, dimensionName: 'measurementTime' },
                    ADVOCACY: {
                        source: ListeningDimensions.hour,
                        dimensionName: 'TIME_OF_DAY',
                        groupType: 'FIELD',
                    },
                    RDB_FIREHOSE: { dimensionName: 'RDB_QUERY_IDS' },
                    STORY_MESSAGE: {
                        source: ListeningDimensions.day,
                        dimensionName: 'CREATED_TIME',
                    },
                }),
                // z = color
                new GroupBy({
                    LISTENING: {
                        source: ListeningDimensions.socialNetwork,
                        limitType: 'TOP',
                        limit: 8,
                    },
                    INBOUND_MESSAGE: { dimensionName: 'SN_TYPE', limitType: 'TOP', limit: 8 },
                    PLATFORM: { dimensionName: 'SN_TYPE', limitType: 'TOP', limit: 8 },
                    BENCHMARKING: {
                        dimensionName: 'BENCHMARKING_SN_TYPE',
                        limitType: 'TOP',
                        limit: 8,
                    },
                    PAID: { dimensionName: 'socialChannelTypes', limitType: 'TOP', limit: 8 },
                    ADVOCACY: { dimensionName: 'SN_TYPE', limitType: 'TOP', limit: 8 },
                    RDB_FIREHOSE: { dimensionName: 'AGE_CATEGORY', limitType: 'TOP', limit: 8 },
                    STORY_MESSAGE: {
                        dimensionName: 'SENTIMENT',
                        limitType: 'NONE',
                    },
                }),
            ],
            sorts: [new Sort({ order: 'ASC', groupBys: 1 })],
            timePeriods: [{ key: 'last_7_days' }],
        }) as any,
    ],
    position: DefaultWidgetPosition,
    label: {
        titleText: 'Bubble Grid',
        overlap: false,
        enabled: false,
        size: WIDGET_LABEL_SIZE.primary,
        textShadow: TEXT_SHADOW_DEFAULT,
    },
    options: {
        player: {
            playing: true,
            duration: 15,
        },
        subType: 'orbs',
        scaleBy: 'set',
        showSentiment: false,
        smartSize: {
            enabled: true,
            mode: 'responsive',
            size: 'm',
        },
        portrait: {
            widgetOrientation: 'portrait',
            showLegend: false,
            orbSize: 50,
        },
    },
};
