import Axios, { CancelTokenSource } from 'axios';
import moment from 'moment';
import 'moment-timezone';
import {
    AnalyticsEngine,
    AnalyticsFilter,
    AnalyticsGroupBy,
    AnalyticsProjection,
    AnalyticsRequest,
} from '@sprinklr/stories/analytics/AnalyticsRequest';
import { AnalyticsResult } from '@sprinklr/stories/analytics/AnalyticsResult';
import DataSet from '@sprinklr/stories/analytics/DataSet';
import Dimension from '@sprinklr/stories/analytics/Dimension';
import {
    FieldType,
    FieldDataType,
    ReportingRequest,
    ReportingResponse,
} from '@sprinklr/stories/reporting/types';
import Metric from '@sprinklr/stories/analytics/Metric';
import BulkItem from '@sprinklr/stories/analytics/BulkItem';
import SLAFrequency from '@sprinklr/stories/analytics/SLAFrequency';
import LookupItem from '@sprinklr/stories/analytics/LookupItem';
import PostItem, { BulkLookup } from '@sprinklr/stories/analytics/PostItem';
import { toSpaceBatchRequest } from '@sprinklr/stories-convert/request/toSpaceRequest';
import toReportingRequests from '@sprinklr/stories-convert/request/toReportingRequests';
import { PostsRequest } from '@sprinklr/stories/post/PostsRequest';
import { SpaceBatchRequest, SpaceRequest } from '@sprinklr/stories/reporting/types';
import {
    Projection as SpaceProjection,
    WidgetRequestGroupBy as SpaceGroupBy,
} from '@sprinklr/modules/research/widget/types';

// TODO: these @sprinklr/display-builder imports need to go away
import TimePeriod from '@sprinklr/display-builder/models/TimePeriod/TimePeriod';
import { WidgetDimensionKey } from '@sprinklr/display-builder/utils/Widget/WidgetDimensionKey';
import SprinklrAPIService, {
    BulkLookupValues,
    PromiseWithCancel,
} from '@sprinklr/display-builder/services/SprinklrAPIService/SprinklrAPIService';
import MetaInfoService from '@sprinklr/display-builder/services/MetaInfoService/MetaInfoService';
import TrendService, {
    TwitterTrend,
    TwitterTrendsResponse,
} from '@sprinklr/display-builder/services/TrendService/TrendService';
import { groupByOrProjectionAlternateHeading } from '@sprinklr/display-builder/utils/Widget/WidgetUtils';

interface DimensionLookup {
    index: number;
    groupBy: AnalyticsGroupBy;
    values: string[];
}

export default class AnalyticsService {
    // TODO: figure out which dimensions can't be filtered
    static cantFilter = [
        'SEM_CUSTOM_PHRASES_SEM_PHRASES_SENTIMENT',
        'WORD_CLOUD_MESSAGE',
        'WORD_CLOUD_TITLE',
        'SEM_CATEGORIES',
        'ACTIONS',
        'THINGS',
        'TOP_TERMS',
        'EMOTIONS',
        'PHRASE_COLLOCATIONS',
        'CITY_INSTA_TEST',
        'TITLE_TOPIC_CLUSTER',
        'MESSAGE_TOPIC_CLUSTER',
        'TOPIC_CLUSTER',
    ];

    static canFilter(engine: AnalyticsEngine, groupBy: AnalyticsGroupBy) {
        // Special-case for STORY_MESSAGe.  This function needs to return
        // false but canLookup needs to return true
        if (engine === 'STORY_MESSAGE') {
            switch (groupBy.dimensionName) {
                case 'ES_MESSAGE_ID':
                case 'WEB_TITLE_ES_MESSAGE_ID':
                    return false;
            }
        }

        return (
            groupBy.groupType === 'FIELD' &&
            AnalyticsService.cantFilter.indexOf(groupBy.dimensionName) === -1
        );
    }

    static canLookup(groupBy: AnalyticsGroupBy) {
        return (
            groupBy.groupType === 'FIELD' &&
            AnalyticsService.cantFilter.indexOf(groupBy.dimensionName) === -1
        );
    }

    sprinklrAPIService: SprinklrAPIService;
    metaInfoService: MetaInfoService;
    trendService: TrendService;

    constructor(
        sprinklrAPIService: SprinklrAPIService,
        metaInfoService: MetaInfoService,
        trendService: TrendService
    ) {
        this.sprinklrAPIService = sprinklrAPIService;
        this.metaInfoService = metaInfoService;
        this.trendService = trendService;
    }

    getDimensionLookups(dataSets: DataSet[], request: AnalyticsRequest): DimensionLookup[] {
        const dimensionLookups: DimensionLookup[] = [];

        request.groupBys.forEach((groupBy: AnalyticsGroupBy, index) => {
            if (!AnalyticsService.canLookup(groupBy)) {
                return;
            }

            dimensionLookups.push({
                index,
                groupBy,
                // TODO: use Set?
                values: [],
            });
        });

        if (dimensionLookups.length === 0) {
            return dimensionLookups;
        }

        // gather all the possible values that need to be looked up
        dataSets.forEach((dataSet: DataSet) => {
            if (dataSet.dimensions.length === 0) {
                return;
            }

            dimensionLookups.forEach((dimensionLookup: DimensionLookup) => {
                dataSet
                    .categories(dataSet.dimensions[dimensionLookup.index])
                    .forEach((value: string) => {
                        // TODO: use Set?
                        if (dimensionLookup.values.indexOf(value) === -1) {
                            dimensionLookup.values.push(value);
                        }
                    });
            });
        });

        return dimensionLookups;
    }

    applyDimensionLookups(
        dataSets: DataSet[],
        dimensionLookups: DimensionLookup[],
        request: AnalyticsRequest,
        cancelSource: CancelTokenSource
    ): Promise<AnalyticsResult> {
        // make all the lookup requests
        const promises = dimensionLookups
            .filter((dimensionLookup: DimensionLookup) => {
                return dimensionLookup.values.length > 0;
            })
            .map((dimensionLookup: DimensionLookup) => {
                return this.sprinklrAPIService.bulkLookup(
                    request.reportingEngine,
                    request.report,
                    WidgetDimensionKey.create(dimensionLookup.groupBy),
                    dimensionLookup.values,
                    request.timePeriods && request.timePeriods[0]
                        ? this.sprinklrAPIService.getTimePeriod(request.timePeriods[0])
                        : null,
                    cancelSource
                );
            });

        const experienceScoreMetrics = [
            'Experience_Score',
            'Experience_Score___Message',
            'Product_Insights_Experience_Score',
        ];

        const postLookup: BulkLookup = {};
        let postValues;

        // after all lookup requests resolve, create derived datasets with the replacement name values
        return Axios.all(promises)
            .then(async (results: BulkLookupValues[]) => {
                // For posts, do additional bulklookup for any needed dimensions
                results.forEach((lookupValues: BulkLookupValues, index) => {
                    const dimensionLookup = dimensionLookups[index];
                    switch (dimensionLookup?.groupBy?.dimensionName) {
                        case 'POST_ID':
                        case 'REPLY_POST_ID':
                            for (const id in lookupValues) {
                                PostItem.addToLookup(lookupValues[id], postLookup);
                            }
                            break;
                    }
                });

                postValues = await this.applyPostLookups(request, postLookup, cancelSource);

                return results;
            })
            .then(
                (results: BulkLookupValues[]): AnalyticsResult => {
                    return {
                        request,
                        dataSets: dataSets.map(
                            (dataSet: DataSet): DataSet => {
                                return dataSet.derivedDataset((rows): any[][] => {
                                    return rows.map((row: any[]): any[] => {
                                        const isDeleted = false;

                                        row = row.slice();
                                        results.forEach((lookupValues: BulkLookupValues, index) => {
                                            const dimensionLookup = dimensionLookups[index];
                                            const id = row[dimensionLookup.index];
                                            let name = lookupValues[id] || id;

                                            // Bulklookup now sends a "__deleted" mapping with an
                                            // array of input ids that were either ignored or
                                            // are records that were deleted from database.  In either
                                            // case we filter out these rows from result
                                            const isDeleted =
                                                lookupValues?.__deleted?.length &&
                                                lookupValues.__deleted.indexOf(id) !== -1;

                                            if (typeof name === 'object') {
                                                if (name instanceof SLAFrequency) {
                                                    // Populate SLA objective
                                                    name.objective = JSON.parse(
                                                        request.additional.slaConfig
                                                    ).slaObjective;
                                                } else if (name instanceof BulkItem) {
                                                    // No action -- use as is.
                                                } else {
                                                    /**
                                                     *  @see MetaInfoService.normalizeBulkItem
                                                     */
                                                    if (
                                                        PostItem.canCreate(dimensionLookup?.groupBy)
                                                    ) {
                                                        name = PostItem.create(
                                                            name?.id ?? id,
                                                            name,
                                                            postValues
                                                        );
                                                    } else if (
                                                        name.contactInfo &&
                                                        name.contactInfo.fullName
                                                    ) {
                                                        name = name.contactInfo.fullName;
                                                    } else if (name.fullName) {
                                                        name = name.fullName;
                                                    } else if (name.title) {
                                                        name = name.title;
                                                    } else if (name.label) {
                                                        name = name.label;
                                                    } else if ('name' in name) {
                                                        const icon = name.thumbnail;
                                                        const image = name.socialAsset?.mediaUrl;

                                                        name = new LookupItem(
                                                            // paid can have multiple name.id so we use id
                                                            request.reportingEngine === 'PAID'
                                                                ? id
                                                                : name?.id ?? id,
                                                            name.displayName ?? name.name
                                                        );

                                                        if (
                                                            LookupItem.hasIcon(
                                                                dimensionLookup?.groupBy
                                                            )
                                                        ) {
                                                            name.setIconUrl(icon);
                                                        }

                                                        if (
                                                            LookupItem.hasImage(
                                                                dimensionLookup?.groupBy
                                                            )
                                                        ) {
                                                            name.setImageUrl(image);
                                                        }
                                                    } else if (id === '1') {
                                                        name = new LookupItem(id, name);
                                                    } else {
                                                        name = id;
                                                    }
                                                }
                                            } else if (typeof name === 'string') {
                                                if (
                                                    isDeleted &&
                                                    PostItem.canCreate(dimensionLookup?.groupBy)
                                                ) {
                                                    name = PostItem.createUnknown(id);
                                                }
                                            }

                                            row[dimensionLookup.index] = name;
                                        });
                                        return row;
                                    });
                                });
                            }
                        ),
                    };
                }
            );
    }

    private async applyPostLookups(
        request: AnalyticsRequest,
        lookup: BulkLookup,
        cancelSource?: CancelTokenSource
    ) {
        const keys = Object.keys(lookup);

        if (keys.length) {
            return await this.sprinklrAPIService.bulkLookupMulti(
                request.reportingEngine as any,
                request.report,
                keys.map(key => new WidgetDimensionKey(key as any)),
                keys.map(key => lookup[key]),
                null,
                cancelSource
            );
        }

        return {};
    }

    async applyGroupLimits(
        request: AnalyticsRequest,
        cancelSource?: CancelTokenSource
    ): Promise<AnalyticsRequest> {
        let groupBys = request.groupBys;
        const timePeriods = request.timePeriods;
        const projections = request.projections;
        if (
            !groupBys ||
            groupBys.length === 0 ||
            !projections ||
            projections.length === 0 ||
            !timePeriods ||
            timePeriods.length === 0
        ) {
            return request;
        }

        groupBys = groupBys.filter(groupBy => {
            return (
                groupBy.limitType &&
                groupBy.limitType !== 'NONE' &&
                groupBy.groupType !== 'DATE_HISTOGRAM' &&
                groupBy.limit
            );
        });

        if (groupBys.length === 0) {
            return request;
        }

        const firstMetric = projections[0];
        const timePeriod = timePeriods[0];

        const filters: AnalyticsFilter[] = request.filters;
        for (const groupBy of groupBys) {
            const groupByClone: AnalyticsGroupBy = Object.assign({}, groupBy);
            const requestClone: AnalyticsRequest = Object.assign({}, request);

            requestClone.projections = [firstMetric];
            requestClone.groupBys = [groupByClone];
            requestClone.timePeriods = [timePeriod];
            requestClone.filters = requestClone.filters.concat(filters);
            groupByClone.limitType = 'NONE';

            let sorts = requestClone.sorts;
            if (sorts === null) {
                sorts = [];
            }
            sorts = sorts.slice();
            sorts.unshift({
                heading: firstMetric.heading,
                order: groupBy.limitType === 'TOP' ? 'DESC' : 'ASC',
            });

            requestClone.sorts = sorts;
            requestClone.limit = groupBy.limit;

            const result = await this.getData(requestClone, cancelSource, null, true);

            // get the dimension value from each row, and flatten
            let values = result.dataSets[0].rows.map(row => row[0].toString());

            // sometimes the limit doesn't seem to work, so we will force it
            if (values.length > groupBy.limit) {
                values = values.slice(groupBy.limit, values.length);
            }

            // Created Time values aren't working when using "IN".
            // so use "BETWEEN" instead
            if (
                groupByClone.dimensionName === 'SN_CREATED_TIME' ||
                groupByClone.dimensionName === 'snCTm'
            ) {
                // Sort ASC by date
                const sorted = values.sort((a, b) => {
                    return new Date(a).getTime() - new Date(b).getTime();
                });

                const beginTime = sorted.length ? moment(sorted[0]) : null;
                let endTime = sorted.length ? moment(sorted[sorted.length - 1]) : null;

                // Make sure that endTime is at the end of its interval
                endTime = TimePeriod.getEndOf(endTime, groupByClone.details?.interval);

                filters.push({
                    dimensionName: groupByClone.dimensionName,
                    filterType: 'BETWEEN',
                    values: beginTime ? [beginTime.valueOf(), endTime.valueOf()] : [],
                    details: groupByClone.details,
                });
            } else {
                filters.push({
                    dimensionName: groupByClone.dimensionName,
                    filterType: 'IN',
                    values,
                    details: groupByClone.details,
                });
            }
        }

        const modifiedRequest: AnalyticsRequest = Object.assign({}, request);
        modifiedRequest.filters = filters;
        return modifiedRequest;
    }

    async getTwitterData(
        request: AnalyticsRequest
        // @ts-ignore
    ): PromiseWithCancel<AnalyticsResult> {
        let woeid = TrendService.twitterWorld;
        if (request.filters && request.filters.length > 0) {
            request.filters.forEach(filter => {
                if (filter.dimensionName === 'WOEID' && filter.values && filter.values.length > 0) {
                    woeid = filter.values[0];
                }
            });
        }

        const p: any = this.trendService.getTwitterTrends(woeid, request.limit).then(
            (trends: TwitterTrendsResponse): AnalyticsResult => {
                return {
                    request,
                    dataSets: [
                        DataSet.create({
                            dimensions: request.groupBys.map((groupBy: AnalyticsGroupBy) => {
                                return {
                                    name: groupBy.heading,
                                    type: 'STRING',
                                };
                            }),
                            metrics: request.projections.map((projection: AnalyticsProjection) => {
                                return {
                                    name: projection.heading,
                                    type: 'NUMBER',
                                };
                            }),
                            rows: trends.trends.map((trend: TwitterTrend) => {
                                return [trend.name, trend.tweet_volume || 10000];
                            }),
                        }),
                    ],
                };
            }
        );
        // tslint:disable-next-line:no-empty
        p.cancel = () => {};
        return p;
    }

    async applyFilters(request: AnalyticsRequest, cancelSource?: CancelTokenSource): Promise<any> {
        request.filters = request.filters.filter(
            filter => !('ACCOUNT_ID' === filter.dimensionName && filter.details?.allValues)
        ); // Remove legacy all accounts filter behavior
        const filterPromises = request.filters.map(filter => {
            if (filter.filterType === 'RANK') {
                return this.rankedFilter(filter, request, cancelSource);
            } else {
                return new Promise(resolve => {
                    resolve(filter);
                });
            }
        });

        return Promise.all(filterPromises);
    }

    applySlaConfig(request: AnalyticsRequest, slaConfig: any) {
        if (!request.additional) {
            request.additional = {};
        }

        if (request?.additional?.slaConfig && slaConfig) {
            request.additional.slaConfig = slaConfig;

            if (!request.projections) {
                request.projections = [
                    {
                        heading: 'SLA',
                        measurementName: 'SLA',
                        aggregateFunction: 'SUM',
                        details: null,
                    },
                ];
            }

            if (
                request.additional.slaConfig.workingHours &&
                request.additional.slaConfig.applyWorkingHours
            ) {
                [
                    'MONDAY',
                    'TUESDAY',
                    'WEDNESDAY',
                    'THURSDAY',
                    'FRIDAY',
                    'SATURDAY',
                    'SUNDAY',
                ].forEach(day => {
                    if (
                        !request.additional.slaConfig.workingHours[day] ||
                        request.additional.slaConfig.workingHours[day].length < 2
                    ) {
                        request.additional.slaConfig.workingHours[day] = [0, 0];
                    }
                });
            }

            const holidayHours = [];

            if (request.additional.slaConfig.inactiveDays) {
                request.additional.slaConfig.inactiveDays
                    .filter(inactiveDay => {
                        return (
                            inactiveDay.inactiveHours[0] !== null &&
                            inactiveDay.inactiveHours[1] !== null
                        );
                    })
                    .forEach(inactiveDay => {
                        holidayHours.push(inactiveDay.inactiveHours[0]);
                        holidayHours.push(inactiveDay.inactiveHours[1]);
                    });
            }

            request.projections.forEach(projection => {
                projection.details = Object.assign(projection.details || {}, {
                    workingHours: request.additional.slaConfig.workingHours,
                    holidayHours,
                    tzOffset: request.additional.slaConfig.tzOffset,
                    slaObjective: request.additional.slaConfig.slaObjective,
                    configTzLocation: request.additional.slaConfig.timezone,
                });
            });

            request.groupBys.forEach(groupBy => {
                groupBy.details = Object.assign(groupBy.details || {}, {
                    workingHours: request.additional.slaConfig.workingHours,
                    holidayHours,
                    tzOffset: request.additional.slaConfig.tzOffset,
                    slaObjective: request.additional.slaConfig.slaObjective,
                    configTzLocation: request.additional.slaConfig.timezone,
                    ranges: request.additional.slaConfig.slaIntervals,
                });
            });

            request.additional.slaConfig = JSON.stringify(request.additional.slaConfig); // Convert JSON to string of JSON.
        }
    }

    async getDataFromSpace(
        request: AnalyticsRequest,
        cancelSource?: CancelTokenSource
        // @ts-ignore
    ): PromiseWithCancel<AnalyticsResult> {
        if (!cancelSource) {
            cancelSource = Axios.CancelToken.source();
        }

        if (request.reportingEngine === 'TWITTER') {
            return this.getTwitterData(request);
        }

        // Account and client filter lookups and other ranked filter queries
        await this.applyFilters(request, cancelSource);

        if (request?.additional?.slaConfig?.id) {
            const slaConfig = await this.getSlaConfig(request);
            this.applySlaConfig(request, slaConfig);
        }

        const batchRequest = toSpaceBatchRequest(request);
        const responses = await this.sprinklrAPIService.spaceBatchQuery(batchRequest, cancelSource);

        const dataSets = this.mergeDataSets(
            Object.keys(responses).reduce((sets, key) => {
                const spaceRequest = batchRequest.requests[key];
                sets[key] = this.processData(request, spaceRequest, responses[key]);
                return sets;
            }, {}),
            batchRequest
        );

        const dimensionLookups: DimensionLookup[] = this.getDimensionLookups(dataSets, request);
        let promise: Promise<AnalyticsResult>;

        if (dimensionLookups.length === 0) {
            // no dimension lookups needed, resolve a promise with our result
            promise = Promise.resolve({
                request,
                dataSets,
            });
        } else {
            promise = this.applyDimensionLookups(dataSets, dimensionLookups, request, cancelSource);
        }

        (promise as any).cancel = function(message?: string) {
            cancelSource.cancel(message);
        };

        return promise as PromiseWithCancel<AnalyticsResult>;
    }

    async getData(
        request: AnalyticsRequest,
        cancelSource?: CancelTokenSource,
        dataSetOverride?: DataSet,
        skipDimensionLookups?: boolean
        // @ts-ignore
    ): PromiseWithCancel<AnalyticsResult> {
        if (!cancelSource) {
            cancelSource = Axios.CancelToken.source();
        }

        request = await this.applyGroupLimits(request, cancelSource);

        if (request.reportingEngine === 'TWITTER') {
            return this.getTwitterData(request);
        }

        // Account and client filter lookups and other ranked filter queries
        await this.applyFilters(request, cancelSource);

        const additionalRequests = dataSetOverride ? null : AnalyticsService.splitRequests(request);

        if (request?.additional?.slaConfig?.id) {
            const slaConfig = await this.getSlaConfig(request);
            this.applySlaConfig(request, slaConfig);
        }

        const requests: ReportingRequest[] = toReportingRequests(request);
        if (!requests || !requests.length) {
            return null;
        }

        let promises: Array<PromiseWithCancel<DataSet>>;

        if (!requests[0].groupBys || requests[0].groupBys.length === 0) {
            promises = requests.map(requestData => {
                return this.requestData(requestData, request, cancelSource);
            });
        } else {
            // If there are additional time periods, we should restrict the previous periods to the dimension values
            // returned by the initial time period request

            let firstRequest = requests[0];

            // dataSetOverride is set when processing 2nd, 3rd, etc. projections that use different report types.
            // In this case, set the filters for the first request as well 2nd.
            if (dataSetOverride) {
                firstRequest = JSON.parse(JSON.stringify(firstRequest));
                this.setFilters(firstRequest, request, dataSetOverride);
            }

            const originalPromise: PromiseWithCancel<DataSet> = this.requestData(
                firstRequest,
                request,
                cancelSource
            );
            promises = [originalPromise];

            let i,
                l = requests.length;
            for (i = 1; i < l; ++i) {
                ((requestData: ReportingRequest) => {
                    promises.push(
                        originalPromise.then((dataSet: DataSet):
                            | DataSet
                            | PromiseWithCancel<DataSet> => {
                            if (dataSet.rows.length === 0) {
                                return new DataSet(
                                    dataSet.dimensions,
                                    dataSet.metrics,
                                    [],
                                    dataSet.totals
                                );
                            }

                            requestData = JSON.parse(JSON.stringify(requestData));

                            this.setFilters(requestData, request, dataSetOverride || dataSet);

                            return this.requestData(requestData, request, cancelSource).then(
                                (dataSet2: DataSet) => {
                                    // console.log('previous dataSet', dataSet2);
                                    return dataSet2;
                                }
                            );
                        })
                    );
                })(requests[i]);
            }
        }

        const dataSets: DataSet[] = await Axios.all(promises);

        const dimensionLookups: DimensionLookup[] = this.getDimensionLookups(dataSets, request);
        let promise: Promise<AnalyticsResult>;

        if (dimensionLookups.length === 0 || skipDimensionLookups === true) {
            // no dimension lookups needed, resolve a promise with our result
            promise = Promise.resolve({
                request,
                dataSets,
            });
        } else {
            promise = this.applyDimensionLookups(dataSets, dimensionLookups, request, cancelSource);
        }

        if (additionalRequests) {
            // Copy the DataSet before dimension lookups are applied
            const dataSetRaw = new DataSet(
                dataSets[0].dimensions,
                dataSets[0].metrics,
                dataSets[0].rows,
                dataSets[0].totals
            );

            promise = promise.then(
                (result: AnalyticsResult): AnalyticsResult => {
                    let promises: Array<PromiseWithCancel<AnalyticsResult>>;

                    promises = additionalRequests.map(request => {
                        return this.getData(request, cancelSource, dataSetRaw);
                    });

                    const promise: any = Axios.all(promises);

                    return promise.then(
                        (results2: AnalyticsResult[]): AnalyticsResult => {
                            // Need to integrate results2 values into result
                            result.dataSets.forEach((dataSet: DataSet, offset: number) => {
                                results2.forEach((result2: AnalyticsResult) => {
                                    const orderAdditional = result2.request.projections.map(
                                        (projection: AnalyticsProjection) => {
                                            return projection.details.origOrder;
                                        }
                                    );

                                    // Note: don't use dataSet as arg because we're overwriting it and with
                                    // multiple datasets, you'll get stale result
                                    result.dataSets[offset] = DataSet.merge(
                                        result.dataSets[offset],
                                        result2.dataSets[offset],
                                        orderAdditional
                                    );
                                });
                            });

                            return result;
                        }
                    );
                }
            );
        }

        if (request.includeTotal) {
            promise = promise.then(
                (result: AnalyticsResult): AnalyticsResult => {
                    const dataSetRaw = new DataSet(
                        dataSets[0].dimensions,
                        dataSets[0].metrics,
                        dataSets[0].rows,
                        dataSets[0].totals
                    );
                    const totalRequests: AnalyticsRequest[] = [
                        { ...request, groupBys: [], includeTotal: false },
                    ];
                    const totalPromises: Array<PromiseWithCancel<
                        AnalyticsResult
                    >> = totalRequests.map(totalRequest => {
                        return this.getData(totalRequest, cancelSource, dataSetRaw);
                    });
                    const totalPromise: any = Axios.all(totalPromises);
                    return totalPromise.then(
                        (totalResults: AnalyticsResult[]): AnalyticsResult => {
                            result.dataSets.forEach((dataSet: DataSet, offset) => {
                                totalResults.forEach((totalResult: AnalyticsResult) => {
                                    const newDataSet = new DataSet(
                                        result.dataSets[offset].dimensions,
                                        result.dataSets[offset].metrics,
                                        result.dataSets[offset].rows,
                                        totalResult.dataSets[0].rows[0]
                                    );
                                    result.dataSets[offset] = newDataSet;
                                    result.request.includeTotal = false;
                                });
                            });
                            return result;
                        }
                    );
                }
            );
        }

        (promise as any).cancel = function(message?: string) {
            cancelSource.cancel(message);
        };

        return promise as PromiseWithCancel<AnalyticsResult>;
    }

    rankedFilter = (filter, request: AnalyticsRequest, cancelSource) => {
        // We want to copy the request, but grouped by the RANK dimension, and then find the corresponding value
        // for the dimension at the rank position, and use that as a filter on the primary request.
        // Main use-case is for correlating with another chart that's showing a grouping by the same dim for
        // similar data with different visualization - e.g. bar chart of mention count grouped by country,
        // then word clouds that correspond to each of the top three bars.
        const rank = parseInt(filter.values?.[0]); // gets turned into a string somewhere...
        if (rank < 1) {
            return Promise.reject(`Rank must be 1 or greater, given value ${rank} is invalid.`);
        }

        const requestCopy: AnalyticsRequest = JSON.parse(JSON.stringify(request));
        requestCopy.includeTotal = false;

        if (
            (!requestCopy.timePeriods || !requestCopy.timePeriods.length) &&
            ((request as any) as PostsRequest).timePeriod
        ) {
            requestCopy.timePeriods = [{ ...((request as any) as PostsRequest).timePeriod }];
        }
        if (requestCopy.timePeriods.length > 1) {
            requestCopy.timePeriods.length = 1;
        }

        requestCopy.groupBys.length = 0;

        let groupBy: AnalyticsGroupBy;

        if (filter.details?.srcType === 'CUSTOM' && filter.details?.fieldName) {
            groupBy = {
                heading: filter.details.fieldName,
                dimensionName: filter.dimensionName,
                groupType: 'FIELD',
                details: {
                    fieldName: filter.details.fieldName,
                    srcType: filter.details.srcType,
                },
            };
        } else {
            groupBy = {
                heading: 'dim_' + filter.dimensionName,
                dimensionName: filter.dimensionName,
                groupType: 'FIELD',
                details: null,
            };
        }

        requestCopy.groupBys.push(groupBy);

        const sorts = requestCopy.sorts.filter(s =>
            requestCopy.projections.some(p => p.heading === s.heading)
        );
        requestCopy.sorts.length = 0;
        requestCopy.sorts.push(...sorts);

        const filters = requestCopy.filters.filter(f => f.filterType !== 'RANK');
        requestCopy.filters.length = 0;
        requestCopy.filters.push(...filters);

        if (filter.dimensionName === 'COUNTRY') {
            // Special implicit COUNTRY NIN "UN" filter for parity with other places this is implicitly applied.
            requestCopy.filters.push({
                dimensionName: 'COUNTRY',
                filterType: 'NIN',
                values: ['UN'], // think this is abbreviation of "unknown", not "United Nations"
                details: null,
            });
        }

        return this.getData(requestCopy, cancelSource).then(result => {
            // Must resolve the concrete values using a separate request - RANK requires a subquery.
            filter.values.length = 0;
            filter.filterType = 'IN';

            if (result?.dataSets?.length > 0) {
                const rankRow = result.dataSets[0].rows[rank - 1]; // rank is 1-based
                if (!rankRow) {
                    throw Error(`No values present for rank ${rank}`);
                }

                const dimIdx = result.dataSets[0].fields.findIndex(f => f.name === groupBy.heading);

                const dimVal = rankRow[dimIdx];

                if (typeof dimVal === 'string' || typeof dimVal === 'number') {
                    filter.values.push(dimVal);
                } else if (dimVal?.id) {
                    filter.values.push(dimVal.id);
                } else {
                    throw Error(`Malformed rank dimension ${dimVal}`);
                }
                // console.log("here", {rankRow, dimIdx, rank, filter, groupBy, dimVal});
            }
            return filter;
        });
    };

    getDatas(
        requests: AnalyticsRequest[],
        cancelSource?: CancelTokenSource
    ): PromiseWithCancel<AnalyticsResult[]> {
        if (!cancelSource) {
            cancelSource = Axios.CancelToken.source();
        }

        // copying request for WorldHeatMap displaying both city and region data
        if (
            requests[1]?.groupBys[0].heading === 'US_STATE' ||
            requests[1]?.groupBys[0].heading === 'COUNTRY' ||
            requests[1]?.groupBys[0].heading === 'COUNTRY_SUBDIVISION'
        ) {
            const heading = requests[1]?.groupBys[0].heading;

            const copiedRequest = JSON.parse(JSON.stringify(requests[0]));

            copiedRequest.groupBys[0].heading = heading;
            copiedRequest.groupBys[0].dimensionName = heading;
            copiedRequest.groupBys[0].details.alternateHeading = heading;

            requests[1] = copiedRequest;
        }

        const promises: Array<PromiseWithCancel<AnalyticsResult>> = requests.map(request => {
            return this.getData(request, cancelSource);
        });

        const promise: any = Axios.all(promises);

        promise.cancel = function(message?: string) {
            cancelSource.cancel(message);
        };

        return promise as PromiseWithCancel<AnalyticsResult[]>;
    }

    getSlaConfig(request: AnalyticsRequest) {
        this.metaInfoService.loadSLAPresets(request.reportingEngine);

        return MetaInfoService.loaderSLAPresets.then(() =>
            this.metaInfoService
                .getSLAPresets(request.reportingEngine)
                .find(preset => preset.id === request.additional.slaConfig.id)
        );
    }

    private mergeDataSets = (
        dataSets: { [key: string]: DataSet },
        batchRequest: SpaceBatchRequest
    ): DataSet[] => {
        let primary: DataSet;
        let previousPeriod: DataSet;

        Object.keys(dataSets).forEach((key, index) => {
            const request = batchRequest.requests[key];
            if (!request) {
                throw new Error(
                    `Request key ${key} not found in [${Object.keys(batchRequest).join(', ')}]`
                );
            }
            const orderAdditional = request.projections.map(projection => {
                return projection.details.origOrder;
            });
            const dataSet = dataSets[key];
            if (key.endsWith('_PREV')) {
                if (!previousPeriod) {
                    previousPeriod = dataSet;
                } else {
                    previousPeriod = DataSet.merge(previousPeriod, dataSet, orderAdditional);
                }
                return;
            }
            if (!primary) {
                primary = dataSet;
            } else {
                primary = DataSet.merge(primary, dataSet, orderAdditional);
            }
        });

        return [primary, previousPeriod].filter(dataSet => !!dataSet);
    };

    private processData = (
        request: AnalyticsRequest,
        apiRequest: SpaceRequest | ReportingRequest,
        data: ReportingResponse
    ): DataSet => {
        const dimensions: Dimension[] = [];
        const metrics: Metric[] = [];

        data.headings = data.headings || [];
        data.rows = data.rows || [];

        // If any of our metrics used aggregate functions
        // CHANGE, PERCENTAGE_CHANGE, or PERCENTAGE,
        // massage the headings and rows to remove the redundant data
        //
        // Note: moved up here, with redundant looping because the code
        // directly below breaks the decorator truncation.
        if (apiRequest.projectionDecorations && apiRequest.projectionDecorations.length) {
            data.headings.forEach(heading => {
                request.projections.forEach((projection: AnalyticsProjection) => {
                    if (projection.heading === heading) {
                        this.truncateForDecorators(
                            projection,
                            apiRequest.projectionDecorations,
                            data
                        );
                    }
                });
            });
        }

        const groupBys = JSON.parse(JSON.stringify(request.groupBys));
        const projections = JSON.parse(JSON.stringify(request.projections));

        data.headings.forEach(heading => {
            let groupByIndex = null;
            groupBys.forEach((groupBy: AnalyticsGroupBy, index) => {
                if (groupBy.heading === heading || groupBy.dimensionName === heading) {
                    groupByIndex = index;
                    let resolvedHeading = groupByOrProjectionAlternateHeading(groupBy);

                    // Don't use the alternate heading if the groupby is CITY - we need to check for the CITY dimension name in World Heat Map Widget.
                    if (groupBy.heading === 'CITY') {
                        resolvedHeading = groupBy.heading;
                    }

                    const dimension = new Dimension(resolvedHeading);

                    if (groupBy.groupType === 'DATE_HISTOGRAM') {
                        dimension.type = 'TIMESTAMP';
                    }

                    if (groupBy.details?.displayName) {
                        dimension.name = groupBy.details.displayName;
                    }

                    dimensions.push(dimension);
                }
            });

            if (groupByIndex !== null) {
                groupBys.splice(groupByIndex, 1);
            }

            let projectionIndex = -1;
            projections.forEach((projection: AnalyticsProjection, index) => {
                if (projectionIndex === -1 && projection.heading === heading) {
                    projectionIndex = index;

                    let type: FieldType = 'NUMBER';
                    let dataType: FieldDataType;

                    const details = projection.details;
                    if (details) {
                        if (details.dataType) {
                            dataType = details.dataType as FieldDataType;
                            if (details.dataType === 'TIME_DIFFERENCE') {
                                type = 'TIME_INTERVAL';
                            }
                        }
                    }

                    const percentageBasedAggregateFunction = ['PERCENTAGE_CHANGE', 'PERCENTAGE'];
                    if (percentageBasedAggregateFunction.includes(projection.aggregateFunction)) {
                        dataType = 'PERCENTAGE';
                    }

                    const altHeading =
                        projection.details?.alternateHeading &&
                        projection.details?.alternateHeading.length
                            ? projection.details.alternateHeading
                            : null;
                    const metricHeading = altHeading ?? projection.heading;

                    metrics.push(
                        new Metric(
                            metricHeading,
                            type,
                            dataType,
                            projection.aggregateFunction,
                            !!projection.details?.alternateHeading
                        )
                    );
                }
            });

            if (projectionIndex !== -1) {
                projections.splice(projectionIndex, 1);
            }
        });

        return new DataSet(dimensions, metrics, data.rows, data.totals);
    };

    private requestData(
        requestData: ReportingRequest,
        request: AnalyticsRequest,
        cancelSource?: CancelTokenSource
    ): PromiseWithCancel<DataSet> {
        if (!cancelSource) {
            cancelSource = Axios.CancelToken.source();
        }

        let promise: PromiseWithCancel<DataSet> | PromiseWithCancel<ReportingResponse> = null;

        promise = this.sprinklrAPIService.query(requestData, cancelSource);

        promise = promise.then(
            (data: ReportingResponse): DataSet => {
                const dimensions: Dimension[] = [];
                const metrics: Metric[] = [];

                data.headings = data.headings || [];
                data.rows = data.rows || [];

                // If any of our metrics used aggregate functions
                // CHANGE, PERCENTAGE_CHANGE, or PERCENTAGE,
                // massage the headings and rows to remove the redundant data
                //
                // Note: moved up here, with redundant looping because the code
                // directly below breaks the decorator truncation.
                if (requestData.projectionDecorations && requestData.projectionDecorations.length) {
                    data.headings.forEach(heading => {
                        request.projections.forEach((projection: AnalyticsProjection) => {
                            if (projection.heading === heading) {
                                this.truncateForDecorators(
                                    projection,
                                    requestData.projectionDecorations,
                                    data
                                );
                            }
                        });
                    });
                }

                // Transform data if the headings exceed the
                if (data.headings.length > request.projections.length + request.groupBys.length) {
                    const requestHeadings = [];
                    const requestHeadingMapping = [];

                    const headingLookup = groupByOrProjection => {
                        if (data.headings.indexOf(groupByOrProjection.heading) >= 0) {
                            requestHeadings.push(groupByOrProjection.heading);
                            requestHeadingMapping.push(
                                data.headings.indexOf(groupByOrProjection.heading)
                            );
                        }
                    };

                    request.groupBys.forEach(headingLookup);
                    request.projections.forEach(headingLookup);

                    data.headings = requestHeadings;

                    data.rows = data.rows.map(row => {
                        return requestHeadingMapping.map(index => {
                            return row[index];
                        });
                    });
                }

                // Determine if we are in a topic cluster, and transform appropriately
                for (var i = 0; i < data.headings.length; i++) {
                    const heading = data.headings[i];

                    if (
                        heading.indexOf('TOPIC_CLUSTER') !== -1 ||
                        heading.indexOf('Topic Cluster') !== -1
                    ) {
                        dimensions.push(new Dimension(heading));

                        const newHeadings = [
                            heading,
                            'TOPIC_CLUSTER',
                            requestData.projections[0].heading,
                        ];
                        const newRows = [];

                        for (let j = 0; j < data.rows.length && j < requestData.pageSize; j++) {
                            const row = data.rows[j];
                            if (row.length > 1) {
                                for (var i = 1; i < row.length; i++) {
                                    newRows.push([row[i], row[0], 101 - i]);
                                }
                            } else {
                                newRows.push(['', row[0], 100]);
                            }
                        }

                        data.headings = newHeadings;
                        data.rows = newRows;
                        break;
                    }
                }

                const groupBys = JSON.parse(JSON.stringify(request.groupBys));
                const projections = JSON.parse(JSON.stringify(request.projections));

                data.headings.forEach(heading => {
                    let groupByIndex = null;
                    groupBys.forEach((groupBy: AnalyticsGroupBy, index) => {
                        if (groupBy.heading === heading || groupBy.dimensionName === heading) {
                            groupByIndex = index;
                            let resolvedHeading = groupByOrProjectionAlternateHeading(groupBy);

                            // Don't use the alternate heading if the groupby is CITY - we need to check for the CITY dimension name in World Heat Map Widget.
                            if (groupBy.heading === 'CITY') {
                                resolvedHeading = groupBy.heading;
                            }

                            const dimension = new Dimension(resolvedHeading);

                            if (groupBy.groupType === 'DATE_HISTOGRAM') {
                                dimension.type = 'TIMESTAMP';
                            }

                            if (groupBy.details?.displayName) {
                                dimension.name = groupBy.details.displayName;
                            }

                            dimensions.push(dimension);
                        }
                    });

                    if (groupByIndex !== null) {
                        groupBys.splice(groupByIndex, 1);
                    }

                    let projectionIndex = -1;
                    projections.forEach((projection: AnalyticsProjection, index) => {
                        if (projectionIndex === -1 && projection.heading === heading) {
                            projectionIndex = index;

                            let type: FieldType = 'NUMBER';
                            let dataType: FieldDataType;

                            const details = projection.details;
                            if (details) {
                                if (details.dataType) {
                                    dataType = details.dataType as FieldDataType;
                                    if (details.dataType === 'TIME_DIFFERENCE') {
                                        type = 'TIME_INTERVAL';
                                    }
                                }
                            }

                            const percentageBasedAggregateFunction = [
                                'PERCENTAGE_CHANGE',
                                'PERCENTAGE',
                            ];
                            if (
                                percentageBasedAggregateFunction.includes(
                                    projection.aggregateFunction
                                )
                            ) {
                                dataType = 'PERCENTAGE';
                            }

                            const altHeading =
                                projection.details?.alternateHeading &&
                                projection.details?.alternateHeading.length
                                    ? projection.details.alternateHeading
                                    : null;
                            const metricHeading = altHeading ?? projection.heading;

                            metrics.push(
                                new Metric(
                                    metricHeading,
                                    type,
                                    dataType,
                                    projection.aggregateFunction,
                                    !!projection.details?.alternateHeading
                                )
                            );
                        }
                    });

                    if (projectionIndex !== -1) {
                        projections.splice(projectionIndex, 1);
                    }
                });

                return new DataSet(dimensions, metrics, data.rows);
            }
        ) as PromiseWithCancel<DataSet>;

        promise.cancel = (message?: string) => {
            cancelSource.cancel(message);
        };

        return promise as PromiseWithCancel<DataSet>;
    }

    /**
     * @param displayRequest
     * @param cancelSource
     */
    private requestSpaceData(displayRequest, cancelSource) {
        const request = this.transformDisplayRequestToSpace(displayRequest);

        return this.sprinklrAPIService.spaceQuery(request, cancelSource);
    }

    /**
     * @param displayRequest
     */
    private transformDisplayRequestToSpace(displayRequest): SpaceRequest {
        const requestData = JSON.parse(JSON.stringify(displayRequest));

        const request: Partial<SpaceRequest> = {
            filters: [],
            postFilters: null,
            projectionFilters: null,
            streamRequestInfo: null,
            projections: null,
            sorts: null,
        };

        request.key = requestData.additional?.widgetId;

        request.reportingEngine = requestData.reportingEngine;
        request.report = requestData.report;

        request.timezone = requestData.timeZone;
        request.tzOffset = requestData.tzOffset;

        request.timeFilter = {
            sinceTime: requestData.startTime,
            untilTime: requestData.endTime,
        };

        request.previousTimeFilter = null;
        // request.previousTimeFilter = {
        //     sinceTime: 0,
        //     untilTime: 0,
        // };

        request.additional = requestData.additional;

        // when true, the outer page object applies to the first groupBy, and all the groupBy page objects are ignored
        // request.additional.STACKED = true;

        // When true, the result is returned as flattened "rows" instead of a nested tree
        // request.additional.TABULAR = true;

        // request.additional.API_QUERY = 'true'; // Not sure why this is string true.

        // if (requestData.skipResolve) {
        //     request.additional.SKIP_RESOLVER = true;
        // }

        request.filters = requestData.filters.map(displayFilter => {
            // if ('GLOBAL_BRAND_ID' === displayFilter.dimensionName) {
            //     request.additional.global = true;
            // }
            //
            // if ('PARTNER_BRAND_ID' === displayFilter.dimensionName) {
            //     request.additional.partner = true;
            // }

            return {
                heading: displayFilter.dimensionName,
                filterType: displayFilter.filterType,
                values: displayFilter.values,
                details: displayFilter.details,
            };
        });

        // addDefaultFilterForPaid(externalRequest, request);

        let lastGroup = null;
        let firstGroup = null;

        requestData.groupBys.forEach(groupBy => {
            const group: SpaceGroupBy = {
                key: groupBy.heading,
                field: groupBy.dimensionName,
                groupType: groupBy.groupType,
                details: groupBy.details,
                childrenGroupBys: [],
                filters: null,
                projections: null,
                sort: null,
                page: null,
                namedFilters: null,
            };

            // if ('GLOBAL_BRAND_ID' === groupBy.dimensionName) {
            //     request.additional.global = true;
            // }
            //
            // if ('PARTNER_BRAND_ID' === groupBy.dimensionName) {
            //     request.additional.partner = true;
            // }
            //
            // if (
            //     'TOPIC_CLUSTER' === groupBy.dimensionName ||
            //     'TITLE_TOPIC_CLUSTER' === groupBy.dimensionName ||
            //     'MESSAGE_TOPIC_CLUSTER' === groupBy.dimensionName
            // ) {
            //     request.additional.TOPIC_CLUSTER = 'true';
            // }

            // Nest groupbys
            if (null === firstGroup) {
                firstGroup = group;
            } else {
                lastGroup.childrenGroupBys = [group];
            }
            lastGroup = group;
        });

        if (firstGroup !== null) {
            request.groupBys = [firstGroup];
        }

        requestData.projections.forEach(proj => {
            const projection: SpaceProjection = {
                key: proj.heading,
                measurement: proj.measurementName,
                aggregateFunction: proj.aggregateFunction,
                details: proj.details,
                filters: null,
            };

            // Add projections to last groupby, or to the request if no group bys
            if (lastGroup !== null) {
                if (!lastGroup.projections) {
                    lastGroup.projections = [];
                }
                lastGroup.projections.push(projection);
            } else {
                if (!request.projections) {
                    request.projections = [];
                }
                request.projections.push(projection);
            }
        });

        if (lastGroup !== null) {
            if (requestData.sorts && requestData.projections) {
                requestData.sorts.forEach(sort => {
                    requestData.projections.forEach(projection => {
                        if (projection.key === sort.heading) {
                            if (lastGroup.sort) {
                                // This seems like a bug -- seems like this will always be false.
                                lastGroup.sort = {
                                    sort: sort.heading,
                                    order: sort.order,
                                };
                            }

                            request.sorts.push({
                                key: sort.heading,
                                order: sort.order,
                                additional: null,
                            });
                        }
                    });
                });
            }

            // TODO: Page
            // if (requestData.page !== -1 && requestData.pageSize > 0) {
            //     request.page = {
            //         page: requestData.page,
            //         pageSize: requestData.pageSize,
            //     };
            // }

            if (requestData.sorts && requestData.sorts.length > 0) {
                const sort = requestData.sorts[0];
                lastGroup.sort = {
                    sort: sort.heading,
                    order: sort.order,
                };
            }
        }

        request.projectionDecorations = requestData.projectionDecorations;

        // SprinklrCollectionUtils.nullSafeList(externalRequest.getFetchFields()).forEach(request::addIncludeField);
        // UIRequestAdapter.adapt(reportingEngine, request);
        // if (requestValidator != null) {
        //     requestValidator.validateReportingRequest(request);
        // }

        // String reqJson = SprinklrUtils.toJson(request);
        // request.addAdditionalParam(ReportingEngineUtils.REQ_JSON_HASH, Md5Utils.md5(reqJson)); //can be used to identify same request - do blacklist or so
        // return request;

        return request as SpaceRequest;
    }

    // Used when metrics use CHANGE, PERCENTAGE_CHANGE, or PERCENTAGE.
    // This will truncate the data.headings and rows to remove extraneous
    // data for each metric
    private truncateForDecorators(
        projection: AnalyticsProjection,
        decorators: string[],
        data: any
    ) {
        const aggregate = projection.aggregateFunction;
        const convert = decorators.indexOf(aggregate) != -1;
        const heading = projection.heading + (convert ? '_' + aggregate : '');
        const found = data.headings.indexOf(projection.heading + (convert ? '_' + aggregate : ''));
        if (found != -1) {
            // No matter what order the projectionDecorations are in, the
            // results come back in this pre-defined order:
            //   CHANGE, PERCENTAGE_CHANGE, PERCENTAGE
            const removeBefore = convert ? decorators.indexOf(aggregate) + 1 : 0;
            const removeAfter = decorators.length - removeBefore;

            data.headings.splice(found + 1, removeAfter);
            data.headings.splice(found - removeBefore, removeBefore);
            data.rows.forEach((row: any[]) => {
                row.splice(found + 1, removeAfter);
                row.splice(found - removeBefore, removeBefore);
            });

            // Overwrite the existing non-decorated heading with the new decorated one
            projection.heading = heading;
        }
    }

    private setFilters(
        requestData: ReportingRequest,
        request: AnalyticsRequest,
        dataSet: DataSet
    ): AnalyticsFilter[] {
        let dimension: Dimension;
        let dimValues: string[];
        request.groupBys.forEach((groupBy: AnalyticsGroupBy, index: number) => {
            // WORD_CLOUD_MESSAGE and TOPIC_IDS filters don't behave correctly right now
            if (!AnalyticsService.canFilter(request.reportingEngine, groupBy)) {
                requestData.pageSize = Math.max(requestData.pageSize, 100);
                return null;
            }

            dimension = dataSet.dimensions[index];
            dimValues = dataSet.categories(dimension);

            let found = false;
            requestData.filters = requestData.filters.map((filter: AnalyticsFilter) => {
                filter = {
                    dimensionName: filter.dimensionName,
                    filterType: filter.filterType,
                    values: filter.values,
                    details: filter.details,
                };

                if (
                    filter.dimensionName === groupBy.dimensionName &&
                    filter.filterType === 'IN' &&
                    filter.details &&
                    groupBy.details &&
                    filter.details.fieldName == groupBy.details.fieldName
                ) {
                    filter.values = dimValues;
                    found = true;
                }

                return filter;
            });

            if (!found) {
                requestData.filters.push({
                    dimensionName: groupBy.dimensionName as any,
                    details: groupBy.details,
                    filterType: 'IN',
                    values: dimValues,
                });
            }
        });

        return requestData.filters;
    }

    // Split off requests that have reports that are different than main report.
    // This is used to support automatically splittling a single request into
    // multiple requests, each with a different report type.
    static splitRequests(request: AnalyticsRequest): AnalyticsRequest[] {
        let additional: AnalyticsRequest[] = null;

        if (request.projections) {
            const projections: AnalyticsProjection[] = [];
            let projection: AnalyticsProjection;
            const baseReport = request.report;
            let reportRequest: AnalyticsRequest;
            let report: string;
            const map: { [report: string]: AnalyticsRequest } = {};

            let x = request.projections.length;
            while (x--) {
                projection = request.projections[x];

                if (!projection.details) {
                    projection.details = {};
                }

                // If this metric has a different report, then clone this
                // request for that metric and add to our requests array
                if (projection.details.origReport && projection.details.origReport !== baseReport) {
                    // Remove this from the main request's projection list
                    request.projections.splice(x, 1);

                    // Find the request for this different report
                    report = projection.details.origReport;

                    reportRequest = map[report];
                    if (!reportRequest) {
                        // Not found?  Duplicate the first request
                        reportRequest = map[report] = JSON.parse(JSON.stringify(request));
                        reportRequest.report = report;
                        reportRequest.projections.length = 0;

                        if (!additional) {
                            additional = [];
                        }

                        additional.unshift(reportRequest);
                    }

                    // Append the projection to report's request
                    reportRequest.projections.unshift(projection);
                }

                // Need to store the order with the projection so the DataSet results
                // can be merged correctly after requests are fetched.
                projection.details.origOrder = x;
            }
        }

        return additional;
    }
}
