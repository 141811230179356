import { AxiosInstance } from 'axios';
import { observable, action } from 'mobx';

// ensures that the version.json file is copied during build
import '../../version.json';

export type VersionChangedHandler = (newVersion: any, oldVersion: any) => void;

export default class VersionService {
    static checkInterval = 15000;

    @observable protected version;

    protected axios: AxiosInstance;

    protected handlers: VersionChangedHandler[] = [];

    protected intervalId = null;

    constructor(axios: AxiosInstance) {
        this.axios = axios;
    }

    getVersion(): Promise<any> {
        if (this.version) {
            return new Promise(resolve => {
                resolve(this.version);
            });
        }

        return this.checkVersion().then(
            action((version: any) => {
                return (this.version = version);
            })
        );
    }

    checkVersion(): Promise<any> {
        return this.axios
            .get('/version.json?ts=' + new Date().getTime(), {
                baseURL: '',
            })
            .then(
                action((response: any) => {
                    return response.data;
                })
            );
    }

    onVersionChange(handler: VersionChangedHandler) {
        if (this.handlers.indexOf(handler) === -1) {
            this.handlers.push(handler);
            this.handlersChanged();
        }
    }

    offVersionChange(handler: VersionChangedHandler) {
        const index = this.handlers.indexOf(handler);
        if (index !== -1) {
            this.handlers.splice(index, 1);
            this.handlersChanged();
        }
    }

    handlersChanged() {
        if (this.handlers.length === 0) {
            this.stopChecking();
        } else if (!this.intervalId) {
            this.startChecking();
        }
    }

    startChecking() {
        this.getVersion().catch(err => console.warn('Version check request failed', err));

        this.intervalId = setInterval(() => {
            this.checkVersion().then(this.compareVersion);
        }, VersionService.checkInterval);
    }

    @action
    compareVersion = (newVersion: any) => {
        if (
            this.handlers &&
            this.handlers.length &&
            this.version &&
            newVersion &&
            newVersion.version &&
            newVersion.version != this.version.version
        ) {
            this.handlers.forEach((handler: VersionChangedHandler) => {
                try {
                    handler(newVersion, this.version);
                } catch (e) {
                    console.error(e);
                }
            });

            this.version = newVersion;
        }
    };

    stopChecking() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
            this.intervalId = null;
        }
    }
}
