import {
    ScheduledStoryboard,
    ScheduledStoryboardFields,
    ScheduledStoryboardUtil,
} from './../ScheduledStoryboard/ScheduledStoryboard';
import {
    Storyboard,
    StoryboardFields,
    StoryboardLight,
    StoryboardLightPublishedFields,
} from '../Storyboard/Storyboard';
import { CommonDocFields } from '../CommonDoc/CommonDoc';
import { CommonDoc } from '@sprinklr/stories/common/CommonDoc';
import { Address } from '../Address/Address';
import { LayoutConstrained } from '../LayoutConstrained/LayoutConstrained';
import { Layout, LayoutFields } from '../Layout/Layout';
import { observable } from 'mobx';
import {
    PublishTierOverride,
    PublishTierOverrideFields,
} from '../PublishTierOverride/PublishTierOverride';
import {
    AccessControlImpl,
    AccessControlledComponent,
    AccessControlledFields,
} from 'services/Auth/AuthService';
import moment, { Moment } from 'moment';
import 'moment-timezone';
import { SharedSecretKeyFields } from 'models/SharedSecretKey/SharedSecretKey';

export type DisplayType =
    | 'SINGLE'
    | 'MULTI'
    | 'DISTRIBUTED' // these three are deprecated
    | 'SINGLE_DISPLAY'
    | 'DISPLAY_WALL'
    | 'DISTRIBUTED_DISPLAY'
    | 'DISTRIBUTED_DISPLAY_WALL'
    | 'GALLERY'
    | 'CUSTOM';

export type DisplayUseCase =
    | 'COMMAND_CENTER'
    | 'RETAIL'
    | 'LIVE_EVENT'
    | 'LOBBY'
    | 'DOOH'
    | 'PUBLIC_EMBED';

export type Remote = {
    css: string;
};

export type ScaleMode = 'COVER' | 'FIT' | 'ACTUAL';

/**
 * Created by dstelter on 10/3/16.
 */
export interface Location extends CommonDoc, LayoutConstrained, AccessControlledComponent {
    shortId?: string;
    storyboards?: Storyboard[];
    activeStoryboardId?: string;
    activeStoryboard?: Storyboard;
    displayType?: DisplayType;
    displayUseCase?: DisplayUseCase;
    remote?: Remote;
    muteVideo?: boolean;
    address?: Address;
    pausedAtIndex?: number;
    startTime?: number;
    publishTierOverride?: PublishTierOverride;
    lastActive?: number;
    lastUserActiveStoryboardChange?: number;
    scheduledStoryboards?: ScheduledStoryboard[];
    enableTouch?: boolean;
    scaleMode?: ScaleMode;
}

export const LocationFields = `
    ${CommonDocFields}
    shortId
    displayType
    displayUseCase
    scaleMode
    activeStoryboardId
    ${AccessControlledFields}
    hmacSignatureRequired
    hmacKeys {
       ${SharedSecretKeyFields}
    }
    muteVideo
    ipRestricted
    ipWhitelist
    pausedAtIndex
    startTime
    createdByUserId
    sharedWithUserIds
    sharedWithGroupIds
    restricted
    lastActive
    publishTierOverride {
        ${PublishTierOverrideFields}
    }
    activeStoryboard {
        ${StoryboardFields}
    }
    storyboards {
        ${StoryboardFields}
    }
    layout {
        ${LayoutFields}
    }
    address {
        address1
        address2
        floor
        room
        city
        state
        zipcode
        country
    }
    scheduledStoryboards {
        ${ScheduledStoryboardFields}
    }
    lastUserActiveStoryboardChange
    remote {
        css
    }
    enableTouch
`;

export interface LocationLight extends CommonDoc, LayoutConstrained, AccessControlledComponent {
    name: string;
    shortId?: string;
    displayType?: DisplayType;
    displayUseCase?: DisplayUseCase;
    activeStoryboardId?: string;
    muteVideo?: boolean;
    ipRestricted?: boolean;
    ipWhitelist?: string[];
    pausedAtIndex?: number;
    startTime?: number;
    storyboards: StoryboardLight[];
    address?: Address;
    lastActive?: number;
    publishTierOverride?: PublishTierOverride;
    lastUserActiveStoryboardChange?: number;
    scheduledStoryboards?: ScheduledStoryboard[];
    createdByUserId?: number;
    remote?: Remote;
    enableTouch?: boolean;
    scaleMode?: ScaleMode;
}

export const LocationAndHmacKeyFields = `
    ${CommonDocFields}
    shortId
    ${AccessControlledFields}
    hmacSignatureRequired
    hmacKeys {
       ${SharedSecretKeyFields}
    }
    muteVideo
    ipRestricted
    ipWhitelist
    pausedAtIndex
    startTime
    createdByUserId
    lastActive
`;

export const LocationLightFields = `
    ${CommonDocFields}
    shortId
    displayType
    displayUseCase
    scaleMode
    activeStoryboardId
    ${AccessControlledFields}
    hmacSignatureRequired
    hmacKeys {
       ${SharedSecretKeyFields}
    }
    muteVideo
    ipRestricted
    ipWhitelist
    pausedAtIndex
    startTime
    createdByUserId
    lastActive
    publishTierOverride {
        ${PublishTierOverrideFields}
    }
    storyboards {
        ${StoryboardLightPublishedFields}
    }
    layout {
        ${LayoutFields}
    }
    address {
        address1
        address2
        floor
        room
        city
        state
        zipcode
        country
    }
    scheduledStoryboards {
        ${ScheduledStoryboardFields}
    }
    lastUserActiveStoryboardChange
    remote {
        css
    }
    enableTouch
`;

export const LocationSchedulesFields = `
    ${CommonDocFields}
    activeStoryboardId
    layout {
        ${LayoutFields}
    }
    scheduledStoryboards {
        ${ScheduledStoryboardFields}
    }
    lastUserActiveStoryboardChange
`;

export class LocationImpl extends AccessControlImpl implements Location {
    @observable shortId?: string;
    @observable layout?: Layout;
    @observable displayType?: DisplayType;
    @observable activeStoryboardId?: string;
    @observable activeStoryboard?: Storyboard;
    @observable address?: Address;
    @observable storyboards: Storyboard[] = [];
    @observable pausedAtIndex: number;
    @observable startTime: number;
    @observable createdByUserId: number;
    @observable sharedWithUserIds: number[];
    @observable sharedWithGroupIds: string[];
    @observable restricted: boolean;
    @observable lastActive?: number;
    @observable publishTierOverride?: PublishTierOverride;
    @observable enableTouch?: boolean = false;
    @observable scaleMode?: ScaleMode = 'COVER';
}

const dayAbbreviationsToIsoWeekday = {
    mon: 1,
    tue: 2,
    wed: 3,
    thu: 4,
    fri: 5,
    sat: 6,
    sun: 7,
};

export interface LocationSchedule extends CommonDoc, LayoutConstrained {
    activeStoryboardId?: string;
    scheduledStoryboards?: ScheduledStoryboard[];
    lastUserActiveStoryboardChange?: number;
}

export class LocationUtil {
    public static calculateActiveStoryboardId(now: Moment, location: LocationSchedule): string {
        if (!location) {
            return null;
        }

        if (!location.scheduledStoryboards || location.scheduledStoryboards.length === 0) {
            return location.activeStoryboardId;
        }

        // in the presence of a schedule, we must find the last applicable storyboard selection.
        // Candidates include:
        // 1. manual activeStoryboardId
        // 2. fixed-datetime schedule (1-shot)
        // 3. daily recurring schedules
        // 4. weekly recurring schedules
        //
        // an applicable schedule entry must satisfy the following:
        // manual activeStoryboardId - always applicable
        // fixed-datetime - only applicable after the set datetime
        // daily - applicable after first-valid datetime
        // weekly - applicable after first-valid datetime
        // once all valid contenders are identified, simply must sort by the most recent, it is the winner!

        type ScheduleEvent = { timestamp: number; storyboardId: string };

        const candidates: ScheduleEvent[] = location.scheduledStoryboards
            .filter((schedule: ScheduledStoryboard) => schedule.enabled)
            .filter((schedule: ScheduledStoryboard) => {
                const notValidBefore =
                    schedule.recurring && schedule.recurring.enabled
                        ? schedule.recurring.notValidBefore
                        : // fixed-datetime one-time schedule event implicitly not valid before schedule.date
                          schedule.date;
                return moment(notValidBefore).isSameOrBefore(now);
            })
            .map((schedule: ScheduledStoryboard) => {
                if (!schedule.recurring || !schedule.recurring.enabled) {
                    // can have a disabled recurring - its absence alone is not enough!
                    return { storyboardId: schedule.storyboardId, timestamp: schedule.date };
                }

                const [hour, minute] = ScheduledStoryboardUtil.getHoursMinutes(schedule);

                // find latest event time - this will be in the past.
                const scheduledTime = now
                    .clone()
                    .tz(schedule.timeZone)
                    .hour(hour)
                    .minute(minute)
                    .second(0)
                    .millisecond(0);

                if (schedule.recurring.frequency === 'DAY') {
                    if (scheduledTime.isAfter(now)) {
                        // if we're here, last time this applied was yesterday.
                        scheduledTime.subtract(1, 'day');
                    }

                    return {
                        storyboardId: schedule.storyboardId,
                        timestamp: scheduledTime.valueOf(),
                    };
                } else {
                    // find last-applicable weekly time...
                    const eventTimes = Object.keys(schedule.recurring.days)
                        .filter(day => schedule.recurring.days[day]) // day enabled?
                        .map(day => dayAbbreviationsToIsoWeekday[day])
                        .map(isoWeekday => scheduledTime.clone().isoWeekday(isoWeekday))
                        .map(evt => (evt.isBefore(now) ? evt : evt.subtract(7, 'days')))
                        .map(evt => evt.valueOf());

                    return {
                        storyboardId: schedule.storyboardId,
                        timestamp: Math.max(...eventTimes),
                    };
                }
            });

        const manualCandidate: ScheduleEvent = {
            storyboardId: location.activeStoryboardId,
            timestamp: location.lastUserActiveStoryboardChange,
        };

        candidates.push(manualCandidate);

        const scheduleEvents = candidates.sort(
            (a: ScheduleEvent, b: ScheduleEvent) => b.timestamp - a.timestamp
        );

        return scheduleEvents[0].storyboardId;
    }
}
