// Brain-dead cache in Typescript I wrote.  In the future, could steal other cool features from:
//
// https://github.com/monsur/jscache
// https://www.npmjs.com/package/tiny-cache
// https://github.com/jmdobry/angular-cache
// ...etc

interface LifoItem<T> {
    key: string;
    value: T;
}

export class Cache<T> {
    private cache = {};
    private lifo: Array<LifoItem<T>> = null;
    private maxItems: number;
    private localStorePrefix: string;

    constructor(maxItems?: number, localStorePrefix?: string) {
        this.maxItems = maxItems || -1;
        if (this.maxItems > 0) {
            this.lifo = [];
        }

        this.localStorePrefix = localStorePrefix;
    }

    isEmpty(): boolean {
        return Object.keys(this.cache).length === 0;
    }

    get(key: string): T {
        if (this.localStorePrefix) {
            const item = window.localStorage[this.localStorePrefix + key];
            if (item) {
                return JSON.parse(item);
            } else {
                return undefined;
            }
        } else {
            return this.cache[key];
        }
    }

    set(key: string, value: T): void {
        if (this.localStorePrefix) {
            window.localStorage[this.localStorePrefix + key] = JSON.stringify(value);
        } else {
            this.cache[key] = value;
        }

        if (this.lifo) {
            // Clear out our oldest item to make room for the new one in cache
            if (this.lifo.length > this.maxItems) {
                // Delete the oldest record from our lifo tracking queue
                const last = this.lifo.pop();

                // Delete this from the cache
                if (this.localStorePrefix) {
                    delete window.localStorage[this.localStorePrefix + key];
                } else {
                    delete this.cache[last.key];
                }
            }

            // Add the new item at the top of the lifo queue
            this.lifo.unshift({ key: key, value: value });
        }
    }

    iterate(callback: (key: string, value: T) => void) {
        for (const key in this.cache) {
            if (this.cache.hasOwnProperty(key)) {
                callback(key, this.cache[key]);
            }
        }
    }

    remove(key: string): void {
        if (this.localStorePrefix) {
            delete window.localStorage[this.localStorePrefix + key];
        } else {
            delete this.cache[key];
        }

        // NOTE: This is a little slow, keeping as-is for the moment unless it proves to be a problem
        if (this.lifo) {
            let x = this.lifo.length;
            while (x--) {
                if (this.lifo[x].key === key) {
                    this.lifo.splice(x, 1);
                    break;
                }
            }
        }
    }
}
