import * as React from 'react';
import { observer } from 'mobx-react';
import getPrettyNumber, { getEndianTime } from '../../utils/Number/NumberUtils';
import styled, { css } from 'components/_UI/designSystem';
import NativeProfileLockup, {
    StyledTimeStamp,
    StyledUserName,
    StyledSecondaryName,
    StyledLocation,
} from './Parts/NativeProfileLockup';
import NativeEngagement from './Parts/NativeEngagement';
import NativeText, { StyledNativeText } from './Parts/NativeText';
import NativeMedia, { StyledMedia } from './Parts/NativeMedia';
import {
    YoutubeLogo,
    useNativePost,
    getNativeMediaOverrides,
    baseStyles,
    getEntityStyles,
    StyledMeta,
    getNativeColor,
} from './Helpers';
import { NativePostProps, StyledPostProps } from './DefaultPost';
import NativeFlex from './Bits/NativeFlex';

const colors = {
    grey: '#606060',
    lightGrey: '#e0e0e0',
    black: '#0d0d0d',
    blue: '#065fd4',
    brand: '#cc0000',
    mediumGray: '#777',
};

const theme = {
    light: {
        link: colors.blue,
        text: colors.black,
        border: colors.lightGrey,
        secondary: colors.mediumGray,
    },
    dark: {
        link: colors.blue,
        text: colors.lightGrey,
        border: colors.grey,
        secondary: colors.mediumGray,
    },
};

const StyledPost = styled(NativeFlex)`
    ${(props: NativePostProps) => baseStyles(props.options)}
    ${({ ratio }: any) => getNativeMediaOverrides(ratio > 2.3 ? 40 : 60, 60, false)}
    ${StyledMedia} {
        border-radius: 0;
    }
    ${StyledUserName} {
    }
    ${StyledSecondaryName} {
    }
    ${({ colorMode }: StyledPostProps) =>
        css`
            ${StyledNativeText} {
                color: ${getNativeColor(theme, colorMode, 'text')};
            }
            .extra_post_data_value {
                width: auto;
                color: ${getNativeColor(theme, colorMode, 'secondary')};
            }
            ${getEntityStyles(getNativeColor(theme, colorMode, 'link'))}
        `}
`;

const StyledTitle = styled.span`
    font-weight: bold;
    font-size: 0.85em;
    line-height: 1.1;
    ${({ colorMode }: StyledPostProps) =>
        css`
            color: ${getNativeColor(theme, colorMode, 'text')};
        `}
`;

const StyledProfileBlock = styled(NativeFlex)`
    ${({ colorMode }: StyledPostProps) =>
        css`
            border-top: 1px solid ${getNativeColor(theme, colorMode, 'border')};
        `}
    padding-top: 1em;
`;
const StyledTitleBlock = styled(NativeFlex)`
    max-width: 75%;
`;

const YoutubePost: React.FC<NativePostProps> = ({
    post,
    playerInterval,
    options,
    extraPostDataTags,
}) => {
    const {
        postRef,
        screenName,
        name,
        profileImgUrl,
        verified,
        images,
        videos,
        title,
        snCreatedTime,
        snStats,
        unique,
        snType,
        textEntities,
        hasMedia,
        text,
        rtl,
        language,
        orientation,
        ratio,
        location,
    } = useNativePost(post);
    const { lineClamp, maxCharacters, colorMode, removeUrls, emoji, hideZero } = options;

    if (!post) {
        return <div key={unique} />;
    }

    const { value, suffix } = getPrettyNumber(snStats.numFollowers, 2, false);
    const subscribers = snStats.numFollowers && `${value}${suffix}`;
    const profileAndText = (
        <>
            {(profileImgUrl || name || screenName || snType) && (
                <NativeProfileLockup
                    colorMode={colorMode}
                    verified={verified}
                    name={name}
                    secondary={subscribers ? `${subscribers} subscribers` : ''}
                    profileImgUrl={profileImgUrl}
                />
            )}
            {text && (
                <NativeText
                    removeUrls={removeUrls}
                    emoji={emoji}
                    colorMode={colorMode}
                    hasMedia={hasMedia}
                    lineClamp={lineClamp}
                    maxCharacters={maxCharacters}
                    channel={snType}
                    text={text}
                    rtl={rtl}
                    language={language}
                    textEntities={textEntities && textEntities.message}
                />
            )}
        </>
    );
    const timeStampAndEngagement = (
        <>
            <NativeEngagement snStats={snStats} snType={snType} hideZero={hideZero} />
        </>
    );

    return (
        <StyledPost
            key={unique}
            ref={postRef}
            hasMedia={hasMedia}
            options={options}
            orientation={orientation}
            ratio={ratio}
            colorMode={colorMode}
            vertical={orientation === 'vertical'}>
            <>
                {orientation === 'vertical' && (
                    <>
                        {hasMedia && (
                            <NativeMedia
                                aspectRatio={[16, 9]}
                                snType={snType}
                                playerInterval={playerInterval}
                                images={images}
                                videos={videos}
                            />
                        )}
                        <StyledMeta pad noGrow gap='m' vertical>
                            <NativeFlex middle noGrow gap>
                                <StyledTitleBlock middle noGrow vertical gap='s'>
                                    {title && (
                                        <StyledTitle colorMode={colorMode}>{title}</StyledTitle>
                                    )}
                                    {snCreatedTime && (
                                        <StyledTimeStamp colorMode={colorMode}>
                                            Published on{' '}
                                            {getEndianTime(snCreatedTime, 'MMM D, YYYY')}
                                        </StyledTimeStamp>
                                    )}
                                    <StyledLocation colorMode={colorMode} location={location} />
                                </StyledTitleBlock>
                                <div style={{ width: '25%' }}>{<YoutubeLogo />}</div>
                            </NativeFlex>

                            <StyledProfileBlock vertical gap='m' colorMode={colorMode}>
                                {profileAndText}
                                {timeStampAndEngagement}
                                {extraPostDataTags}
                            </StyledProfileBlock>
                        </StyledMeta>
                    </>
                )}

                {orientation === 'horizontal' && (
                    <>
                        {hasMedia && (
                            <NativeMedia
                                snType={snType}
                                playerInterval={playerInterval}
                                images={images}
                                videos={videos}
                            />
                        )}
                        <NativeFlex vertical gap='m' pad>
                            <NativeFlex noGrow vertical gap='m'>
                                {(profileImgUrl || name || screenName) && (
                                    <NativeProfileLockup
                                        colorMode={colorMode}
                                        verified={verified}
                                        name={name}
                                        snType={snType}
                                        secondary={value ? `${subscribers} subscribers` : ''}
                                        profileImgUrl={profileImgUrl}
                                    />
                                )}
                                <StyledTitle colorMode={colorMode}>{title}</StyledTitle>
                                {text && (
                                    <NativeText
                                        removeUrls={removeUrls}
                                        emoji={emoji}
                                        colorMode={colorMode}
                                        hasMedia={hasMedia}
                                        lineClamp={lineClamp}
                                        maxCharacters={maxCharacters}
                                        channel={snType}
                                        text={text}
                                        textEntities={textEntities && textEntities.message}
                                    />
                                )}
                            </NativeFlex>
                            <NativeFlex bottom gap='s'>
                                {snCreatedTime && (
                                    <StyledTimeStamp colorMode={colorMode}>
                                        {getEndianTime(snCreatedTime, 'MMM D, YYYY')}
                                    </StyledTimeStamp>
                                )}
                                <StyledLocation colorMode={colorMode} location={location} />
                                {extraPostDataTags && (
                                    <NativeFlex bottom right>
                                        {extraPostDataTags}
                                    </NativeFlex>
                                )}
                            </NativeFlex>
                        </NativeFlex>
                    </>
                )}
            </>
        </StyledPost>
    );
};

YoutubePost.displayName = 'YoutubePost';
export default observer(YoutubePost);
