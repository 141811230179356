import * as React from 'react';
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom';
import { computed, toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import AuthService, { FEATURE_FLAG } from 'services/Auth/AuthService';
import AppVersion from 'components/AppVersion/AppVersion';
import { ClearUrlPlayer } from 'components/ClearUrlPlayer/ClearUrlPlayer';
import i18n from '../../i18n';
import loadable from '@loadable/component';
import { FALLBACK_EMPTY, FALLBACK_SPINNER } from 'utils/constants';
import config, { EnvironmentConfig } from '../../config';
import { GlobalStyles } from '@sprinklr/gallery-builder/src/GlobalStyles';
import { AuthProvider } from '@sprinklr/display-builder/containers/Auth/AuthContext';
import { RecoilRoot } from 'recoil';

const queryString = require('query-string');

const Auth = loadable(
    () => import(/* webpackChunkName: "Auth" */ '../Auth/Auth'),
    FALLBACK_SPINNER
);
const DisplaysManager = loadable(
    () =>
        import(
            /* webpackChunkName: "DisplaysManager" */ '../../components/Location/Displays/DisplaysManager'
        ),
    FALLBACK_SPINNER
);
const UpdateLocation = loadable(
    () =>
        import(
            /* webpackChunkName: "UpdateLocation" */ '../../components/Location/UpdateLocation/UpdateLocation'
        ),
    FALLBACK_SPINNER
);
const StoryboardsManager = loadable(
    () =>
        import(
            /* webpackChunkName: "StoryboardsManager" */ '../../components/Storyboard/StoryboardsManager/StoryboardsManager'
        ),
    FALLBACK_SPINNER
);
const StoryboardEditor = loadable(
    () =>
        import(
            /* webpackChunkName: "StoryboardEditor" */ '../../components/Storyboard/StoryboardEditor/StoryboardEditor'
        ),
    FALLBACK_SPINNER
);
const PanelEditor = loadable(
    () =>
        import(
            /* webpackChunkName: "PanelEditor" */ '../../components/Panel/PanelEditor/PanelEditor'
        ),
    FALLBACK_SPINNER
);
const External = loadable(
    () => import(/* webpackChunkName: "External" */ '../External/External'),
    FALLBACK_EMPTY
);
const EmbedEditor = loadable(
    () =>
        import(
            /* webpackChunkName: "EmbedEditor" */ '../../components/Embed/EmbedEditor/EmbedEditor'
        ),
    FALLBACK_SPINNER
);
const EmbedsManager = loadable(
    () =>
        import(
            /* webpackChunkName: "EmbedsManager" */ '../../components/Embed/EmbedsManager/EmbedsManager'
        ),
    FALLBACK_SPINNER
);

const GalleryEditor = loadable(
    () =>
        import(
            /* webpackChunkName: "GalleryEditor" */ '@sprinklr/gallery-builder/src/components/galleryEditor/GalleryEditor'
        ),
    FALLBACK_SPINNER
);
const GalleriesManager = loadable(
    () =>
        import(
            /* webpackChunkName: "GalleriesManager" */ '@sprinklr/gallery-builder/src/components/galleriesManager/GalleriesManager'
        ),
    FALLBACK_SPINNER
);

const PanelRenderer = loadable(
    () => import(/* webpackChunkName: "PanelRenderer" */ '../Renderers/PanelRenderer'),
    FALLBACK_SPINNER
);
const StoryboardPlayer = loadable(
    () => import(/* webpackChunkName: "StoryboardPlayer" */ '../Storyboards/StoryboardPlayer'),
    FALLBACK_EMPTY
);
const PrivateRoute = loadable(
    () => import(/* webpackChunkName: "PrivateRoute" */ './PrivateRoute'),
    FALLBACK_SPINNER
);
const Remote = loadable(
    () => import(/* webpackChunkName: "Remote" */ '../Remote/Remote'),
    FALLBACK_SPINNER
);
const RemoteIndex = loadable(
    () => import(/* webpackChunkName: "RemoteIndex" */ '../Remote/RemoteIndex'),
    FALLBACK_SPINNER
);
const PanelPreviewLink = loadable(
    () => import(/* webpackChunkName: "PanelPreviewLink" */ '../PanelPreviewLink/PanelPreviewLink'),
    FALLBACK_SPINNER
);
const PublicURLRunner = loadable(
    () => import(/* webpackChunkName: "PublicURLRunner" */ '../External/PublicURLRunner'),
    FALLBACK_EMPTY
);
const FaceDetector = loadable(
    () => import(/* webpackChunkName: "FaceDetector" */ '../../components/intuition/FaceDetector'),
    FALLBACK_SPINNER
);

export type AppProps = RouteComponentProps<any> & {
    authService?: AuthService;
    config?: EnvironmentConfig;
    location?: any;
    match?: any;
    history?: any;
};

const client = new ApolloClient({
    uri: `${config.apiRoot}graphql/`,
    cache: new InMemoryCache(),
    credentials: 'include',
});

class App extends React.Component<AppProps, {}> {
    @computed get isPresentations(): boolean {
        return this.props.config.applicationMode === 'PRESENTATIONS';
    }

    @computed get storyboardPath(): string {
        return this.isPresentations ? '/presentations' : '/storyboards';
    }

    @computed get developmentMode(): boolean {
        return !!this.props.config.developmentMode;
    }

    @computed get galleryV2Enabled(): boolean {
        const flagEnabled = this.props.authService?.isFeatureEnabled(
            FEATURE_FLAG.GALLERY_V2_ENABLED
        );
        const appModeIsEmbed = 'EMBED' === this.props.config.applicationMode;
        return flagEnabled && appModeIsEmbed;
    }

    @computed get appMode(): { classRoot: string; siteTitle: string; route: string } {
        switch (this.props.config.applicationMode) {
            case 'EMBED':
                return {
                    classRoot: 'ui-builder-embed',
                    siteTitle: 'Gallery',
                    route: '/embeds',
                };
            case 'DISPLAY':
            default:
                return {
                    classRoot: 'ui-builder',
                    siteTitle: 'Display',
                    route: '/storyboards',
                };
            case 'PRESENTATIONS':
                return {
                    classRoot: 'ui-builder-presentations',
                    siteTitle: 'Presentations',
                    route: '/presentations',
                };
        }
    }

    @computed get locale() {
        return this.props.authService?.userInfo?.locale;
    }

    private updateComponentTree = () => {
        this.forceUpdate();
    };

    componentDidUpdate() {
        const currentI18nLang = i18n.language;
        const parsedParams = queryString.parse(this.props.location.search.replace('?', ''));

        // set language based on query param `lang=ja-JP` with fallback of user language
        if (!!parsedParams?.lang && currentI18nLang !== parsedParams.lang) {
            i18n.changeLanguage(parsedParams.lang, this.updateComponentTree);
        } else if (
            !parsedParams?.lang &&
            this.locale &&
            currentI18nLang !== this.locale.replace('_', '-')
        ) {
            // set language; i18n expects - instead of _
            i18n.changeLanguage(toJS(this.locale).replace('_', '-'), this.updateComponentTree);
        }
    }

    render() {
        return (
            <>
                <Switch>
                    <Route path='/resolve-client' component={Auth} />

                    <PrivateRoute
                        exact
                        path={`/clients/:clientId${this.storyboardPath}`}
                        component={StoryboardsManager}
                    />
                    <PrivateRoute
                        exact
                        path={`/clients/:clientId${this.storyboardPath}/:storyboardId`}
                        component={StoryboardEditor}
                    />
                    <PrivateRoute
                        exact
                        path={`/clients/:clientId${this.storyboardPath}/:storyboardId/scenes/:sceneId`}
                        component={StoryboardEditor}
                    />
                    <PrivateRoute
                        exact
                        path={`/clients/:clientId${this.storyboardPath}/:storyboardId/scenes/:sceneId/panels/:panelId`}
                        component={PanelEditor}
                    />

                    <PrivateRoute
                        exact
                        path='/clients/:clientId/embeds'
                        component={EmbedsManager}
                    />
                    <PrivateRoute
                        exact
                        path='/clients/:clientId/embeds/:embedId'
                        component={EmbedEditor}
                    />
                    {this.galleryV2Enabled && (
                        <AuthProvider>
                            <ApolloProvider client={client}>
                                <RecoilRoot>
                                    <PrivateRoute
                                        exact
                                        path='/clients/:clientId/galleries'
                                        component={GalleriesManager}
                                    />
                                    <PrivateRoute
                                        exact
                                        path='/clients/:clientId/gallery/:embedId'
                                        component={GalleryEditor}
                                    />
                                    <GlobalStyles />
                                </RecoilRoot>
                            </ApolloProvider>
                        </AuthProvider>
                    )}

                    <PrivateRoute
                        exact
                        path='/clients/:clientId/locations'
                        component={DisplaysManager}
                    />
                    <PrivateRoute
                        exact
                        path='/clients/:clientId/locations/:locationId'
                        component={DisplaysManager}
                    />
                    <PrivateRoute
                        exact
                        path='/clients/:clientId/locations/:locationId/rename'
                        component={UpdateLocation}
                    />

                    <PrivateRoute exact path='/clients/:clientId/remote' component={RemoteIndex} />
                    <PrivateRoute
                        exact
                        path='/clients/:clientId/remote/:locationId'
                        component={Remote}
                    />
                    <PrivateRoute
                        exact
                        path='/:clientId/:locationId/remote'
                        suppressNavigation={true}
                        component={Remote}
                    />

                    <PrivateRoute
                        exact
                        path={`/clients/:clientId/preview${this.storyboardPath}/:storyboardId/scenes/:sceneId/panels/:panelId`}
                        component={PanelPreviewLink}
                    />
                    {this.developmentMode && (
                        <PrivateRoute
                            exact
                            path='/clients/:clientId/intuition/face'
                            component={FaceDetector}
                        />
                    )}
                    <Route
                        exact
                        path={`/clients/:clientId${this.storyboardPath}/:storyboardId/player`}
                        component={StoryboardPlayer}
                    />

                    {/* Uncomment for dev if you want to explore the API */}
                    {/*{ dev ? <Route exact path="/graphql" component={ require("spr-display-runtime/GraphiQL") }/> : null }*/}
                    <Route exact path='/render/panel' component={PanelRenderer} />
                    <Route exact path='/clear' component={ClearUrlPlayer} />
                    <Route
                        exact
                        path='/external/:clientId/:locationId/:screenId'
                        component={External}
                    />
                    <Route
                        exact
                        path='/pub/:clientId/:locationId/:screenId'
                        component={PublicURLRunner}
                    />
                    <Route exact path='/:clientId/:locationId/:screenId' component={External} />
                    <Route
                        exact
                        path='/multi/:clientId/:locationId/:screenId'
                        component={External}
                    />
                    <Route path='/version' component={AppVersion} />

                    <Route path='/' component={Auth} />
                </Switch>
            </>
        );
    }
}

export default withRouter(inject('authService', 'config')(observer(App)));
