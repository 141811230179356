import * as React from 'react';
import i18n from 'i18next';
import {
    countupDurationTunable,
    cumulativeTunables,
    headerTunables,
    legendTunables,
    opacityTunables,
    smartSizeTunables,
    themeTunables,
    thresholdsTunables,
} from 'components/Sidebar/RenderTunables/SharedTunables';
import { CollapseProps, TunablesCollapse } from 'components/Sidebar/RenderTunables/RenderTunables';
import {
    endianOptions,
    interpolationTypeOptions,
    metricValueCaseOptions,
    metricValueOptions,
    scaleTypeOptions,
    tickLabelAngleOptions,
    xAxisOptions,
    yAxisPositionOptions,
} from 'components/Panel/PanelEditorSidebar/PanelDesign/PanelDesignOptions';
import { hasSocialInDataRequest } from 'utils/GenerateStyles/GenerateStyles';
import { Widget } from '@sprinklr/stories/widget/Widget';
import { LineChartWidgetOptions, LineChartWidgetOptionsImpl } from './options';
import { Theme } from 'models/Theme/Theme';
import { isMetricTimeDiff } from 'utils/Widget/WidgetUtils';
import ActionCheckboxSliderGroup from '../../components/_UI/Forms/Actions/ActionCheckboxSliderGroup';
import ActionSegmentedGroup from '../../components/_UI/Forms/Actions/ActionSegmentedGroup';
import ActionCheckboxGroup from '../../components/_UI/Forms/Actions/ActionCheckboxGroup';
import ActionSliderGroup from '../../components/_UI/Forms/Actions/ActionSliderGroup';
import ActionSelectGroup from '../../components/_UI/Forms/Actions/ActionSelectGroup';

const topPointTypeOptions = [
    {
        value: 'value',
        label: i18n.t('Metric Value'),
    },
    {
        value: 'timeAgo',
        label: i18n.t('Time Ago'),
    },
    {
        value: 'timePosted',
        label: i18n.t('Time Posted'),
    },
];
export const LineChartTunables = (widget: Widget, mergedTheme: Theme): TunablesCollapse[] => {
    // checking for social data
    const analyticsRequests = widget.analyticsRequests;
    const groupBy = analyticsRequests && analyticsRequests.length && analyticsRequests[0].groupBys;

    const hasSocial: boolean = hasSocialInDataRequest(groupBy);
    const topicIcon = hasSocial ? (
        <ActionCheckboxSliderGroup
            key='widget.options.showTopPointIcon'
            label={i18n.t('Icon')}
            showValue
            checkbox={{
                option: widget.options,
                value: 'showTopPointIcon',
            }}
            slider={{
                option: widget.options,
                value: 'iconSize',
                min: 0,
                max: 20,
            }}
        />
    ) : null;

    const isSLA = isMetricTimeDiff(widget);

    return [
        {
            props: {
                label: i18n.t('Line Chart'),
                startOpen: true,
            } as CollapseProps,
            children: [
                ...headerTunables(widget),
                cumulativeTunables(widget),
                opacityTunables(widget),
                smartSizeTunables(widget),
            ],
        },
        legendTunables(widget, {
            orientation: true,
            orientationOpts: ['top', 'right', 'bottom'],
            labelSize: true,
            wrap: true,
        }),
        themeTunables(widget, { color: true, image: true, email: true }),
        {
            props: {
                label: i18n.t('Spikes'),
                startOpen: true,
                isEnabled: (widget.options as LineChartWidgetOptions).showTopPointMarker,
                option: widget.options,
                value: 'showTopPointMarker',
            } as CollapseProps,
            children: [
                <ActionSliderGroup
                    key='widget.options.maxTopPoints'
                    label={i18n.t('Max Spikes')}
                    option={widget.options}
                    value='maxTopPoints'
                    showValue
                    min={1}
                    max={15}
                />,
                <ActionCheckboxSliderGroup
                    key='widget.options.showTopPointLabel'
                    label={i18n.t('Spike Label')}
                    showValue
                    checkbox={{
                        option: widget.options,
                        value: 'showTopPointLabel',
                    }}
                    slider={{
                        option: widget.options,
                        value: 'labelSize',
                        min: 0,
                        max: 30,
                    }}
                />,
                <ActionSelectGroup
                    key='widget.options.topPointType'
                    label={i18n.t('Spike Type')}
                    option={widget.options}
                    value='topPointType'
                    options={topPointTypeOptions}
                />,
                (widget.options as LineChartWidgetOptions).topPointType === 'timePosted' ||
                (widget.options as LineChartWidgetOptions).topPointType === 'timeAgo' ? (
                    <ActionCheckboxSliderGroup
                        key='widget.options.showTopPointValue'
                        label={i18n.t('Spike Time')}
                        showValue
                        checkbox={{
                            option: widget.options,
                            value: 'showTopPointValue',
                        }}
                        slider={{
                            option: widget.options,
                            value: 'valueSize',
                            min: 0,
                            max: 100,
                        }}
                    />
                ) : (
                    undefined
                ),
                (widget.options as LineChartWidgetOptions).topPointType === 'timePosted' ? (
                    <ActionSelectGroup
                        key='widget.options.topPointEndianTime'
                        label={i18n.t('Spike Time Format')}
                        option={widget.options}
                        value='topPointEndianTime'
                        options={endianOptions}
                    />
                ) : (
                    undefined
                ),
                (widget.options as LineChartWidgetOptions).topPointType === 'value' ? (
                    <React.Fragment key='widget.options.showTopPointValue'>
                        <ActionCheckboxSliderGroup
                            key='widget.options.showTopPointValue'
                            label={i18n.t('Spike Value')}
                            showValue
                            checkbox={{
                                option: widget.options,
                                value: 'showTopPointValue',
                            }}
                            slider={{
                                option: widget.options,
                                value: 'valueSize',
                                min: 0,
                                max: 100,
                            }}
                        />
                        <ActionSegmentedGroup
                            key='widget.options.showMetricValue'
                            label={i18n.t('Format')}
                            option={widget.options}
                            value='metricValueFormat'
                            options={metricValueOptions}
                            showOptionLabel
                        />
                        {widget.options.metricValueCase &&
                            widget.options.metricValueFormat &&
                            widget.options.metricValueFormat !== '1,234' && (
                                <ActionSelectGroup
                                    label={i18n.t('Unit Case')}
                                    key='widget.options.metricValueCase'
                                    options={metricValueCaseOptions}
                                    option={widget.options}
                                    value='metricValueCase'
                                />
                            )}
                        {countupDurationTunable(widget)}
                    </React.Fragment>
                ) : (
                    undefined
                ),
                <ActionCheckboxSliderGroup
                    key='widget.options.showTopPointDot'
                    label={i18n.t('Spike Marker')}
                    showValue
                    checkbox={{
                        option: widget.options,
                        value: 'showTopPointDot',
                    }}
                    slider={{
                        option: widget.options,
                        value: 'topPointMarkerSize',
                        min: 0,
                        max: 20,
                    }}
                />,
                topicIcon,
                <ActionCheckboxSliderGroup
                    key='widget.options.showTopPointOrb'
                    label={i18n.t('Spike Highlight')}
                    showValue
                    checkbox={{
                        option: widget.options,
                        value: 'showTopPointOrb',
                    }}
                    slider={{
                        option: widget.options,
                        value: 'orbSize',
                        min: 0,
                        max: 25,
                    }}
                />,
                <ActionCheckboxGroup
                    key='widget.options.separateLabels'
                    label={i18n.t('Separate Labels')}
                    option={widget.options}
                    value='separateLabels'
                />,
            ],
        },
        {
            props: {
                label: i18n.t('Tunables'),
                startOpen: true,
            } as CollapseProps,
            children: [
                <ActionSliderGroup
                    key='widget.options.maxItems'
                    label={i18n.t('Max Items')}
                    option={widget.options}
                    value='maxItems'
                    showValue
                    min={1}
                    max={15}
                />,
                <ActionSliderGroup
                    key='widget.options.lineWidth'
                    label={i18n.t('Line Width')}
                    option={widget.options}
                    value='lineWidth'
                    showValue
                    min={0}
                    max={15}
                />,
                <ActionCheckboxSliderGroup
                    key='widget.options.showPointMarkers'
                    label={i18n.t('Point Markers')}
                    showValue
                    checkbox={{
                        option: widget.options,
                        value: 'showPointMarkers',
                    }}
                    slider={{
                        option: widget.options,
                        value: 'pointMarkersSize',
                        min: 0,
                        max: 20,
                    }}
                />,

                <ActionSegmentedGroup
                    key='widget.options.interpolation'
                    label={i18n.t('Interpolation')}
                    option={widget.options}
                    value='interpolation'
                    options={interpolationTypeOptions}
                />,
                <ActionSelectGroup
                    key='widget.options.scaleType'
                    label={i18n.t('Scale Type')}
                    option={widget.options}
                    value='scaleType'
                    options={scaleTypeOptions}
                />,
                <ActionCheckboxGroup
                    key='widget.options.zeroFill'
                    label={i18n.t('Zero Fill')}
                    option={widget.options}
                    value={'zeroFill'}
                />,
            ],
        },

        {
            props: {
                label: i18n.t('X Axis'),
                startOpen: true,
            } as CollapseProps,
            children: [
                <ActionCheckboxGroup
                    key='widget.options.showXAxis'
                    label={i18n.t('Show Scale')}
                    option={widget.options}
                    value='showXAxis'
                />,
                <ActionSelectGroup
                    label={i18n.t('Scale Label Angle')}
                    key='widget.options.xAxisTickAngle'
                    option={widget.options}
                    value='xAxisTickAngle'
                    options={tickLabelAngleOptions}
                />,
                <ActionCheckboxSliderGroup
                    key='widget.options.xAxisTicksNum'
                    label={i18n.t('Number of Ticks')}
                    showValue
                    checkbox={{
                        option: (widget.options as LineChartWidgetOptionsImpl).xAxisTicksNum,
                        value: 'enabled',
                    }}
                    slider={{
                        option: (widget.options as LineChartWidgetOptionsImpl).xAxisTicksNum,
                        value: 'value',
                        min: 0,
                        max: 100,
                    }}
                />,
                <ActionSelectGroup
                    key='widget.options.xAxisDateFormat'
                    label={i18n.t('Date Format')}
                    option={widget.options}
                    value='xAxisDateFormat'
                    options={xAxisOptions}
                />,

                <ActionCheckboxSliderGroup
                    key='widget.options.xAxis.label'
                    label='Label'
                    showValue
                    checkbox={{
                        option: (widget.options as LineChartWidgetOptionsImpl).xAxisLabel,
                        value: 'enabled',
                    }}
                    slider={{
                        option: (widget.options as LineChartWidgetOptionsImpl).xAxisLabel,
                        value: 'size',
                        min: 0,
                        max: 100,
                    }}
                />,
                (widget.options as LineChartWidgetOptionsImpl).xAxisLabel && (
                    <ActionSliderGroup
                        key='widget.options.xAxis.padding'
                        label='Label Padding'
                        option={(widget.options as LineChartWidgetOptionsImpl).xAxisLabel}
                        value='padding'
                        showValue
                        min={0}
                        max={100}
                    />
                ),
            ],
        },
        {
            props: {
                label: i18n.t('Y Axis'),
                startOpen: true,
            } as CollapseProps,
            children: [
                <ActionSelectGroup
                    label='Position'
                    key='widget.options.yAxisPosition'
                    option={widget.options as LineChartWidgetOptions}
                    value='yAxisPosition'
                    options={yAxisPositionOptions}
                />,
                <ActionCheckboxGroup
                    key='widget.options.showYAxis'
                    label={i18n.t('Show Scale')}
                    option={widget.options}
                    value='showYAxis'
                />,
                <ActionSliderGroup
                    key='widget.options.yAxis.ticks.numTicks'
                    label={i18n.t('Number of Ticks')}
                    option={widget.options as LineChartWidgetOptionsImpl}
                    value='yAxisTicksNum'
                    min={0}
                    max={30}
                />,
                (widget.options as LineChartWidgetOptions).showYAxis && isSLA && (
                    <ActionCheckboxGroup
                        key='widget.options.yAxisShowTimeRemainder'
                        label={i18n.t('Show Time Remainder')}
                        option={widget.options}
                        value='yAxisShowTimeRemainder'
                    />
                ),
                (widget.options as LineChartWidgetOptions).showYAxis && !isSLA && (
                    <>
                        <ActionSegmentedGroup
                            between
                            key='widget.options.yAxisValueFormat'
                            label={i18n.t('Tick Format')}
                            option={widget.options}
                            value={'yAxisValueFormat'}
                            options={metricValueOptions}
                            showOptionLabel
                        />
                        {widget.options.yAxisValueCase &&
                            widget.options.yAxisValueFormat !== '1,234' && (
                                <ActionSelectGroup
                                    label={i18n.t('Unit Case')}
                                    key='widget.options.yAxisValueCase'
                                    options={metricValueCaseOptions}
                                    option={widget.options}
                                    value='yAxisValueCase'
                                />
                            )}
                    </>
                ),
                <ActionCheckboxSliderGroup
                    key='widget.options.yAxis.label'
                    label='Label'
                    showValue
                    checkbox={{
                        option: (widget.options as LineChartWidgetOptionsImpl).yAxisLabel,
                        value: 'enabled',
                    }}
                    slider={{
                        option: (widget.options as LineChartWidgetOptionsImpl).yAxisLabel,
                        value: 'size',
                        min: 0,
                        max: 100,
                    }}
                />,

                (widget.options as LineChartWidgetOptionsImpl).yAxisLabel && (
                    <ActionSliderGroup
                        key='widget.options.yAxis.padding'
                        label='Label Padding'
                        option={(widget.options as LineChartWidgetOptionsImpl).yAxisLabel}
                        value='padding'
                        showValue
                        min={0}
                        max={100}
                    />
                ),
                <ActionCheckboxGroup
                    key='widget.options.zeroStart'
                    label={i18n.t('Start Y-Axis from Zero')}
                    option={widget.options}
                    value='zeroStart'
                />,
            ],
        },
        thresholdsTunables(widget, mergedTheme, 'multipleLine'),
    ];
};

export default LineChartTunables;
