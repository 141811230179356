import { generateID } from './PanelTemplatesTheme/helpers';
import { WIDGET_LABEL_SIZE } from '../../components/Widget/WidgetLabel/constants';
import { Panel } from '../../models/Panel/Panel';

export var LabelSize = {
    primary: 20,
    secondary: 15,
};

export var TemplateDynamicTitle: Panel = {
    id: 'dynamic-title',
    name: 'Dynamic Title',
    description: '',
    tags: ['Editorial', 'text', 'Dynamic Title', 'portrait', 'interstitial'],
    widget: {
        id: generateID(),
        position: {
            width: '1920px',
            height: '1080px',
            top: '0',
            left: '0',
        },
        classes: 'template_dynamic_title',
        label: {
            titleText: 'Panel header',
            enabled: false,
            size: WIDGET_LABEL_SIZE.primary,
        },
        children: [
            {
                type: 'dynamicTitle',
                name: 'Dynamic Title',
                id: generateID(),
                useGlobalDataSources: true,
                position: {
                    left: '3.8%',
                    top: '35%',
                    width: '91%',
                    height: '40%',
                },
                label: {
                    enabled: false,
                    size: 40,
                },
                options: {
                    fontSize: 40,
                    inputTitleText: 'Title',
                    date: {
                        enabled: true,
                        format: 'Pretty Time',
                    },
                },
            },
        ]
            
    },
};
