import { PostsWidgetOptions } from '../../widgets/PostsWidget/options';
import { PostSource, Sort } from './PanelTemplatesTheme/PanelTemplatesTheme';
import { Panel } from '../../models/Panel/Panel';
import { postCompositionConfigGrid } from '../../components/Panel/PanelEditorSidebar/PanelDesign/PanelDesignOptions';

export var TemplatePostEngagement4: Panel = {
    id: 'post-engagement-4',
    name: 'Feature 4',
    description: '',
    tags: ['Content', 'Posts', 'Post Engagement', '4 Posts', 'landscape', 'portrait'],
    widget: {
        position: {
            width: '1920px',
            height: '1080px',
            top: '0',
            left: '0',
        },
        classes: 'template_engagement template_feature_4',
        children: [
            {
                type: 'postsGrid',
                id: '3',
                name: 'engagement',
                useGlobalTimePeriod: true,
                postRequests: [
                    {
                        sources: [
                            {
                                type: 'posts',
                                // Note: All global variables like reportingEngine, report, and filters
                                // are injected via PostRequest.getRequests()
                                id: new PostSource({
                                    pageSize: 24,
                                    sorts: [
                                        new Sort({
                                            order: 'DESC',
                                            heading: {
                                                MONITORING_DASHBOARD: 'REACH_COUNT',
                                                LISTENING: 'REACH_COUNT',
                                                INBOUND_MESSAGE: 'INBOUND_COUNT',
                                                PLATFORM: 'TOTAL_ENGAGEMENT',
                                                BENCHMARKING: 'TOTAL_ENGAGEMENT',
                                                CAMPAIGN: 'OUTBOUND_STATUS',
                                                RDB_FIREHOSE: 'SN_CREATED_TIME',
                                                STORY_MESSAGE: 'IMPACT',
                                            },
                                        }),
                                    ],
                                    includeFaceDetection: false,
                                    timePeriod: {
                                        key: 'last_7_days',
                                    },
                                }) as any,
                                options: {
                                    count: 300,
                                    imagePreload: true,
                                    removeDuplicates: true,
                                    includeFaceDetection: false,
                                    shufflePosts: false,
                                    removeSensitivePosts: true,
                                    includeQuoteTweets: false,
                                },
                            },
                        ],
                        format: {
                            type: 'buckets',
                            options: {
                                padding: 1,
                                buckets: {
                                    text: { filter: "post.type == 'text'" },
                                    image: { filter: "post.type == 'image'" },
                                    any: { filter: 'post.type' },
                                },
                                panels: [{ total: 4, buckets: ['any', -1] }],
                            },
                        },
                    },
                ],
                position: {
                    top: '3.72%',
                    left: '12.5%',
                    height: '92.56%',
                    width: '75%',
                },
                options: Object.assign(
                    {
                        player: {
                            playing: true,
                            duration: 15,
                        },
                        postTemplate: '',
                        postDirection: 'vertical',
                        userGroupEffect: 'top',
                        theme: 'primary',
                        showSentiment: true,
                        showText: true,
                        smartSize: {
                            enabled: true,
                            mode: 'responsive',
                            size: 'xl',
                        },
                        portrait: {
                            widgetOrientation: 'portrait',
                        },
                        imageFaceDetection: false,
                        postVersion: 2,
                    } as PostsWidgetOptions,
                    postCompositionConfigGrid.a
                ),
                // label: {
                //     titleText: 'Feature 4',
                //     size: LabelSize.primary,
                //     enabled: true
                // }
            },
        ],
        theme: {
            ext: {
                padding: 0,
            },
        },
    },
};
