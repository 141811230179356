import * as React from 'react';
import i18n from 'i18next';
import {
    blackListTunables,
    borderRadiusTunable,
    centerAxis,
    headerTunables,
    labelTunables,
    metricValueTunables,
    opacityTunables,
    percentChangeTunables,
    sizingTunables,
    smartSizeTunables,
    themeTunables,
    thresholdsTunables,
    xAxisTunables,
    yAxisTunables,
} from 'components/Sidebar/RenderTunables/SharedTunables';
import { CollapseProps, TunablesCollapse } from 'components/Sidebar/RenderTunables/RenderTunables';
import { Widget } from '@sprinklr/stories/widget/Widget';
import { Theme } from 'models/Theme/Theme';
import { BarChartProps } from 'components/_charts/BarChart/BarChart';
import { BarChartWidgetOptions } from './options';

export const BarChartTunables = (widget: Widget, mergedTheme: Theme): TunablesCollapse[] => {
    const { orientation } = widget.options as BarChartWidgetOptions;

    return [
        {
            props: {
                label:
                    widget.options.orientation !== 'horizontal'
                        ? i18n.t('Bar Chart')
                        : i18n.t('Column Chart'),
                startOpen: true,
            } as CollapseProps,
            children: [
                ...headerTunables(widget),
                opacityTunables(widget),
                borderRadiusTunable(widget, {
                    borderAll: true,
                    borderEnds: true,
                    borderMode: true,
                }),
                smartSizeTunables(widget),
            ],
        },
        {
            props: {
                label: i18n.t('Label'),
                startOpen: true,
            } as CollapseProps,
            children: [
                widget.options.deprecated?.hasOwnProperty('labelEnable') && labelTunables(widget),
                ...metricValueTunables(widget, {
                    value: { enable: true, size: true },
                    format: true,
                    name: true,
                    change: true,
                }),
                ...percentChangeTunables(widget, mergedTheme, {
                    highlight: true,
                    format: true,
                    color: true,
                }),
            ],
        },
        //bar chart
        orientation === 'vertical' &&
            xAxisTunables({
                widget,
                padding: false,
                allPadding: false,
                line: true,
                numTicks: true,
                format: true,
            }),
        orientation === 'vertical' && yAxisTunables(widget, false, true, false),

        //column chart
        orientation === 'horizontal' &&
            xAxisTunables({
                widget,
                padding: false,
                allPadding: false,
                numTicks: false,
                format: false,
                tickLabelName: 'Column Label',
            }),
        orientation === 'horizontal' && yAxisTunables(widget, true, true, true),

        themeTunables(widget, {
            color: true,
            image: true,
            imagePosition: (widget.options as BarChartProps).orientation === 'vertical',
            circleMask: true,
            email: true,
        }),
        sizingTunables(widget, { spacing: { max: 25 }, padding: { min: 5, max: 35 } }),
        {
            props: {
                label: i18n.t('Tunables'),
                startOpen: false,
            } as CollapseProps,
            children: [blackListTunables(widget), centerAxis(widget, mergedTheme)],
        },
        thresholdsTunables(widget, mergedTheme, 'multipleLine'),
    ];
};

export default BarChartTunables;
