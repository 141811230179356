const interval = 30; // Sync interval in seconds

export type SyncCallback = () => void;

export default class SyncService {
    private callbacks: SyncCallback[] = [];
    private timer: any;

    constructor() {
        this.start();
    }

    register(callback: SyncCallback): SyncCallback {
        const found = this.callbacks.indexOf(callback);
        if (found === -1) {
            this.callbacks.push(callback);
        }
        return callback;
    }

    unregister(callback: SyncCallback): void {
        const found = this.callbacks.indexOf(callback);
        if (found !== -1) {
            this.callbacks.splice(found, 1);
        }
    }

    private notify() {
        let x = this.callbacks.length;
        while (x--) {
            try {
                this.callbacks[x]();
            } catch (err) {
                console.log('isolated sync callback error', err);
            }
        }
    }

    private start() {
        // Sync the update to happen on clock time.  Needed by Command Centers.
        const seconds = new Date().getSeconds();
        const remaining = seconds % interval;
        const delay = interval - remaining;
        clearTimeout(this.timer);

        this.timer = setTimeout(() => {
            window.requestAnimationFrame(() => {
                this.notify();
                // Start timer again
                this.start();
            });
        }, delay * 1000);
    }
}
