import { PostSource, Sort } from './PanelTemplatesTheme/PanelTemplatesTheme';
import { paddedDefaultPosition } from './PanelTemplatesTheme/constants';
import { Panel } from '../../models/Panel/Panel';
import { WIDGET_LABEL_SIZE } from '../../components/Widget/WidgetLabel/constants';
import { postCompositionConfigWaterfall } from '../../components/Panel/PanelEditorSidebar/PanelDesign/PanelDesignOptions';

export const TemplateWaterfall: Panel = {
    id: 'waterfall',
    name: 'Waterfall',
    description: '',
    tags: ['Content', 'Posts', 'Waterfall', 'landscape', 'portrait'],
    widget: {
        id: '1',
        position: {
            width: '1920px',
            height: '1080px',
            top: '0',
            left: '0',
        },
        classes: 'template_waterfall',
        children: [
            {
                type: 'postsWaterfall',
                id: '1',
                name: 'Waterfall',
                useGlobalTimePeriod: true,
                postRequests: [
                    {
                        sources: [
                            {
                                type: 'posts',
                                // Note: All global variables like reportingEngine, report, and filters
                                // are injected via PostRequest.getRequests()
                                id: new PostSource({
                                    pageSize: 50,
                                    sorts: [
                                        new Sort({
                                            order: 'DESC',
                                            heading: {
                                                MONITORING_DASHBOARD: 'REACH_COUNT',
                                                LISTENING: 'REACH_COUNT',
                                                INBOUND_MESSAGE: 'INBOUND_COUNT',
                                                PLATFORM: 'TOTAL_ENGAGEMENT',
                                                BENCHMARKING: 'TOTAL_ENGAGEMENT',
                                                RDB_FIREHOSE: 'SN_CREATED_TIME',
                                                STORY_MESSAGE: 'IMPACT',
                                            },
                                        }),
                                    ],
                                    includeComments: false,
                                    includeFaceDetection: false,
                                    timePeriod: {
                                        key: 'last_7_days',
                                    },
                                }) as any,
                                options: {
                                    count: 200,
                                    imagePreload: true,
                                    removeDuplicates: true,
                                    includeFaceDetection: false,
                                    shufflePosts: false,
                                    removeSensitivePosts: true,
                                    includeQuoteTweets: false,
                                },
                            },
                        ],
                        format: {
                            type: 'ordered',
                            options: {
                                fixedList: 25,
                                loop: true,
                            },
                        },
                    },
                ],
                position: paddedDefaultPosition,
                options: Object.assign(
                    {
                        player: {
                            playing: true,
                            duration: 6,
                        },
                        retweetIndicator: {
                            enabled: true,
                            opacity: 50,
                            size: 9,
                        },
                        postVersion: 2,
                        columnCount: 3,
                        smartSize: {
                            enabled: true,
                            mode: 'responsive',
                            size: 'xl',
                        },
                        portrait: {
                            columnCount: 2,
                        },
                        imageFaceDetection: false,
                    },
                    postCompositionConfigWaterfall.a
                ),
                label: {
                    titleText: 'Waterfall',
                    enabled: false,
                    size: WIDGET_LABEL_SIZE.primary,
                },
            },
        ],
        theme: {
            ext: {
                padding: 0,
            },
        },
    },
};
