import { Panel } from 'models/Panel/Panel';
import { Scene } from 'models/Scene/Scene';
import { Storyboard } from 'models/Storyboard/Storyboard';
import { Layout } from 'models/Layout/Layout';
import { Location } from 'models/Location/Location';
import { MutationDescriptor, QueryDescriptor } from 'services/GraphQLQueries/GraphQLQueries';
import { GraphQLQuery, GraphQLService, QueryOptions } from 'services/GraphQLService/GraphQLService';
import { Mapper } from 'services/DataStore/DataStore';
import { IdentifiedModel } from '@sprinklr/stories/common/IdentifiedModel';
import { Embed } from 'models/Embed/Embed';

/**
 * Created by dstelter on 12/14/16.
 */
export class BaseRecordService<T> {
    constructor(
        protected graphQL: GraphQLService,
        protected mapper: Mapper<T>,
        protected isPresentations?: boolean
    ) {}

    updateCache(id: string, values: any): any {
        // console.log('updating ' + this.mapper.name + ' id ' + id, values);
        const cached: any = this.mapper.create({ id }, null);

        if (cached.updated && values.updated && cached.updated > values.updated) {
            // console.log('The in-memory ' + this.mapper.name + ' we have is newer. Aborting merge.');
            return;
        }

        const object = this.mapper.create(values, null);
        // console.log(this.mapper.name, object);
        return object;
    }

    /**
     * Run the described mutation, returning the result as a promise.
     * @param mutation
     * @param options
     */
    public mutate(mutation: MutationDescriptor, options?: QueryOptions): Promise<T> {
        return this.graphQL
            .mutate(mutation.queryParams, options)
            .then(mutation.extractor)
            .then(result => this.mapper.create(result, this.recordLoaded));
    }

    /**
     * Run the described mutation, returning the result as a promise.
     * @param mutation
     * @param options
     */
    public mutateComplexResult(mutation: MutationDescriptor, options?: QueryOptions): Promise<any> {
        return this.graphQL
            .mutate(mutation.queryParams, options)
            .then(mutation.extractor)
            .then(result => this.mapper.create(result, this.recordLoaded));
    }

    /**
     * Use for a mutation which returns an array of mutated records.
     * @param {MutationDescriptor} mutation
     * @returns {Promise<Array<T>>}
     */
    public mutateMany(mutation: MutationDescriptor): Promise<T[]> {
        return this.graphQL
            .mutate(mutation.queryParams)
            .then(mutation.extractor)
            .then(result => this.mapper.createMany(result, this.recordLoaded));
    }

    public query(query: QueryDescriptor): Promise<T> {
        // console.log("query got query ", query);
        return this.graphQL
            .query(query.queryParams)
            .then(query.extractor)
            .then(result => {
                if (!result || typeof result !== 'object') {
                    const variables =
                        query.queryParams && query.queryParams.variables
                            ? ` with params: ${JSON.stringify(query.queryParams.variables)}`
                            : '';
                    const message = `Failed to load ${this.mapper.name}${variables}`;
                    console.error(message);
                    // return Promise.reject(message) as any;
                }
                // console.log(this.mapper.name, result);
                return this.mapper.create(result, this.recordLoaded);
            });
    }

    public queryRaw(query: GraphQLQuery, extractor: (data: any) => T): Promise<T> {
        // console.log("query got query ", query);
        return this.graphQL
            .query(query)
            .then(extractor)
            .then(result => this.mapper.create(result, this.recordLoaded));
    }

    public queryMany(query: QueryDescriptor): Promise<T[]> {
        return this.graphQL
            .query(query.queryParams)
            .then(query.extractor)
            .then(result => {
                if (!result || typeof result !== 'object') {
                    const variables =
                        query.queryParams && query.queryParams.variables
                            ? ` with params: ${JSON.stringify(query.queryParams.variables)}`
                            : '';
                    const message = `Failed to load ${this.mapper.name}s${variables}`;
                    console.error(message);
                    // return Promise.reject(message) as any;
                }
                return this.mapper.createMany(result, this.recordLoaded);
            });
    }

    public queryManyRaw(query: GraphQLQuery, extractor: (data: any) => T[]): Promise<T[]> {
        // console.log("queryManyRaw got query ", query);
        return this.graphQL
            .query(query)
            .then(extractor)
            .then(result => this.mapper.createMany(result, this.recordLoaded));
    }

    protected layoutId(layout: Layout | string): string {
        let layoutId: string;

        if (typeof layout === 'string') {
            layoutId = layout;
        } else {
            layoutId = layout.id;
        }

        if (!layoutId) {
            throw new Error('layout must be an id or layout object with id');
        }

        return layoutId;
    }

    protected locationId(location: Location | string): string {
        let locationId: string;

        if (typeof location === 'string') {
            locationId = location;
        } else {
            locationId = location.id;
        }

        if (!locationId) {
            throw new Error('location must be an id or location object with id');
        }

        return locationId;
    }

    protected storyboardId(storyboard: Storyboard | string): string {
        let storyboardId;

        if (typeof storyboard === 'string') {
            storyboardId = storyboard;
        } else {
            storyboardId = storyboard.id;
        }

        if (!storyboardId) {
            throw new Error('storyboard must be an id or storyboard object with id');
        }

        return storyboardId;
    }

    protected sceneId(scene: Scene | string): string {
        let sceneId;

        if (typeof scene === 'string') {
            sceneId = scene;
        } else {
            sceneId = scene.id;
        }

        if (!sceneId) {
            throw new Error('scene must be an id or scene object with id');
        }

        return sceneId;
    }

    protected panelId(panel: Panel | string): string {
        let panelId;

        if (typeof panel === 'string') {
            panelId = panel;
        } else {
            panelId = panel.id;
        }

        if (!panelId) {
            throw new Error('panel must be an id or panel object with id');
        }

        return panelId;
    }

    protected embedId(embed: Embed | string): string {
        let embedId;

        if (typeof embed === 'string') {
            embedId = embed;
        } else {
            embedId = embed.id;
        }

        if (!embedId) {
            throw new Error('embed must be an id or embed object with id');
        }

        return embedId;
    }

    /**
     * Accepts an array of strings or objects with id properties, and returns an array of strings - either the original
     * array, or the id property in the case of objects.
     *
     * @param records
     * @returns {string[]}
     */
    protected ids(records: (IdentifiedModel | string)[]): string[] {
        return (records || []).map(record => (typeof record === 'string' ? record : record.id));
    }

    protected panelIds(panels: (Panel | string)[]): string[] {
        return (panels || []).map(panel => (typeof panel === 'string' ? panel : panel.id));
    }

    /**
     * Derived classes can override this if they need to change the record before caller gets it
     * (ie. like upgrading the record format)
     *
     * @param record
     * @returns {void}
     */
    protected recordLoaded(record: T): void {}
}
