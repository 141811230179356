import { toJS } from 'mobx';
import { Cache } from '../../utils/Cache/Cache';
import AuthService from '../Auth/AuthService';
import { WidgetRequest } from '../../utils/Widget/WidgetRequest';
import ObjectUtils from '../../utils/ObjectUtils/ObjectUtils';
import TimePeriod from '../../models/TimePeriod/TimePeriod';
import {
    DataSourceDefaultTimePeriod,
    DataSourceFilter,
    DataSourceName,
} from 'models/DataSource/DataSource';
import { WidgetDataRequest } from '@sprinklr/stories/widget/Widget';
import { WidgetTimePeriod } from '../../utils/Widget/TimePeriod/WidgetTimePeriod';
import TimePeriodService from 'services/TimePeriodService/TimePeriodService';

export type UserDataSourceSelections = WidgetDataRequest & {
    timePeriods: WidgetTimePeriod[];
    dataSourceSelections: DataSourceFilter[];
    sourceGroup?: string;
};

export default class LocalSettingsService {
    private authService: AuthService;
    private cacheDataSource = new Cache<any>(-1, 'dataSource:');
    private cacheLastDSN = new Cache<any>(-1, 'lastDSN:');

    constructor(authService: AuthService) {
        this.authService = authService;
    }

    setLastUsedDataSourceName(storyboardId: string, name: DataSourceName | 'custom'): void {
        if (!storyboardId) {
            console.warn('no storyboardId provided');
            return;
        }

        const key = this.dataSourceKey(storyboardId);
        this.cacheLastDSN.set(key, name);
    }

    getLastUsedDataSourceName(storyboardId: string): DataSourceName | 'custom' {
        return this.cacheLastDSN.get(this.dataSourceKey(storyboardId));
    }

    // Retrieve any cached data source and set the provided request with that data
    // engine blacklist is an array of engines that are not allowed.
    applyRequestDataSource(
        storyboardId: string,
        request: WidgetRequest,
        blacklist?: string[]
    ): void {
        const key = this.dataSourceKey(storyboardId);

        const cached = this.cacheDataSource.get(key);
        if (cached) {
            const data = request.getRequest() as any;

            if (
                (request.type === 'posts' || cached.source !== 'LISTENING_COLUMN') &&
                (!blacklist || blacklist.indexOf(cached.reportingEngine) === -1)
            ) {
                request.setEngine(cached.reportingEngine);
                request.setReport(cached.report);
                request.setSourceGroup(cached.sourceGroup);

                for (const field in data) {
                    if (cached[field] !== undefined) {
                        ObjectUtils.copyChanged(data, field, cached);
                    }
                }

                request.sync();
            }
        }
    }

    applyDefaultWidgetCreationTimePeriod(
        storyboardId: string,
        request: WidgetRequest,
        templateTimePeriods: TimePeriod[]
    ): void {
        const key = this.dataSourceKey(storyboardId);
        const cached = this.cacheDataSource.get(key);
        const engine = request.engine;

        if (request.timePeriods && templateTimePeriods) {
            const newTimePeriods = templateTimePeriods.map((timePeriod, index) => {
                const cachedTimePeriod: any =
                    cached && cached.timePeriods && cached.timePeriods[index];
                const mergedTimePeriod: any = cachedTimePeriod && {
                    dateStart: cachedTimePeriod.startTime,
                    dateStop: cachedTimePeriod.endTime,
                    duration: cachedTimePeriod.duration,
                    key: DataSourceDefaultTimePeriod[engine]
                        ? DataSourceDefaultTimePeriod[engine]
                        : cachedTimePeriod.key,
                    timeZone: cachedTimePeriod.timeZone,
                    wholePeriods: cachedTimePeriod.wholePeriods,
                };
                // TODO: defaulting EVERYONE to America/Los_Angeles is weird.
                return {
                    timeZone: 'America/Los_Angeles',
                    ...timePeriod,
                    ...(mergedTimePeriod ? mergedTimePeriod : {}),
                };
            });

            request.setTimePeriod(newTimePeriods);
        }
    }

    getLastUsedDataSource(storyboardId: string): UserDataSourceSelections {
        return this.cacheDataSource.get(this.dataSourceKey(storyboardId));
    }

    // Saved the data source for this request
    async setDataSource(storyboardId: string, request: WidgetRequest) {
        const dataSourceSelections = await Promise.all(
            request.sources.map(async source => {
                const lookup = await request.metaInfoService.getValueLabels(
                    request.engine,
                    request.report,
                    source.dimension,
                    null,
                    null
                );

                const selectedValues = (source as any)?.filter?.values?.slice?.();
                const filterValueLabels = selectedValues?.map(id => {
                    const found = lookup.values?.filter(entry => entry.id === id);
                    return found.length === 1 ? found[0] : found;
                });

                const dsf: DataSourceFilter = {
                    dimensionLabel: source.dimensionLabel,
                    filter: {
                        dimensionName: source.dimension.name,
                        filterType: 'IN',
                        values: selectedValues,
                    },
                    filterValueLabels,
                };

                console.log('mapped readable values', { dsf, selectedValues, lookup });
                return dsf;
            })
        );

        const { timePeriods, sourceGroup } = request;

        const userSelections: UserDataSourceSelections = {
            ...toJS(request.getRequest()),
            dataSourceSelections,
            timePeriods,
            sourceGroup,
        };

        this.cacheDataSource.set(this.dataSourceKey(storyboardId), userSelections);
    }

    private dataSourceKey(storyboardId: string): string {
        const partnerId = this.authService.userInfo.partnerId;
        const clientId = this.authService.activeClientId;

        return `${partnerId}/${clientId}/${storyboardId}`;
    }
}
