import { DefaultWidgetPosition } from './PanelTemplatesTheme/constants';
import { Widget } from '@sprinklr/stories/widget/Widget';
import { WIDGET_LABEL_SIZE } from '../../components/Widget/WidgetLabel/constants';
import { TEXT_SHADOW_DEFAULT } from 'models/Widget/Widget';

export var WidgetCustomSocialPost: Widget = {
    type: 'customContentSocialPost',
    id: '1',
    name: 'Social Post',
    position: DefaultWidgetPosition,
    label: {
        titleText: 'Social Post',
        enabled: false,
        size: WIDGET_LABEL_SIZE.primary,
        textShadow: TEXT_SHADOW_DEFAULT,
    },
    options: {
        player: {
            playing: true,
            duration: 15,
        },
        portrait: {
            widgetOrientation: 'portrait',
        },
    },
};
