import { AnalyticsRequest, Sort, Metric } from './PanelTemplatesTheme/PanelTemplatesTheme';
import { DefaultWidgetPosition } from './PanelTemplatesTheme/constants';
import { Widget } from '@sprinklr/stories/widget/Widget';
import { WIDGET_LABEL_SIZE } from '../../components/Widget/WidgetLabel/constants';
import ListeningMetrics from '@sprinklr/stories-services/AnalyticsService/ListeningMetrics';
import { TEXT_SHADOW_DEFAULT } from 'models/Widget/Widget';

export const WidgetFunnelChart: Widget = {
    type: 'funnelChart',
    id: 'funnel-chart',
    name: 'Funnel Chart',
    analyticsRequests: [
        // Note: All global variables like reportingEngine, report, filters, and projections
        // are injected via AnalyticsRequest.getRequests()
        new AnalyticsRequest({
            limit: 10,
            groupBys: [],
            projections: [
                new Metric({
                    LISTENING: { source: ListeningMetrics.mentions },
                    INBOUND_MESSAGE: { dimensionName: 'OUTBOUND_COUNT' },
                    PLATFORM: { dimensionName: 'ENGAGEMENT_RATE' },
                    BENCHMARKING: { dimensionName: 'ENGAGEMENT_RATE' },
                    PAID: { dimensionName: 'impressions' },
                    ADVOCACY: {
                        dimensionName: 'AUDIENCE_ACTIVITY_COUNT',
                        heading: 'Activity_Count',
                    },
                    RDB_FIREHOSE: {
                        dimensionName: 'REACH_COUNT',
                        heading: 'Reach',
                    },
                    STORY_MESSAGE: {
                        dimensionName: 'MENTIONS_COUNT',
                        heading: 'Mentions',
                    },
                }),
                new Metric({
                    LISTENING: { source: ListeningMetrics.reach },
                    INBOUND_MESSAGE: { dimensionName: 'INBOUND_COUNT' },
                    PLATFORM: { dimensionName: 'TOTAL_ENGAGEMENT' },
                    BENCHMARKING: { dimensionName: 'TOTAL_ENGAGEMENT' },
                    PAID: { dimensionName: 'spent' },
                    ADVOCACY: { dimensionName: 'POINTS' },
                    RDB_FIREHOSE: {
                        dimensionName: 'MENTIONS_COUNT',
                        heading: 'Mentions',
                    },
                    STORY_MESSAGE: { dimensionName: 'REACH' },
                }),
                new Metric({
                    LISTENING: { source: ListeningMetrics.distinctUsers },
                    INBOUND_MESSAGE: { dimensionName: 'INBOUND_COUNT' },
                    PLATFORM: {
                        dimensionName: 'POST_REACH_COUNT',
                        heading: 'Post_Reach_Trend',
                        details: {
                            origReport: 'POST_INSIGHTS_TREND',
                            dataType: 'NUMERIC',
                        },
                    },
                    BENCHMARKING: { dimensionName: 'TOTAL_LIKES' },
                    PAID: { dimensionName: 'spent' },
                    ADVOCACY: {
                        dimensionName: 'ADVOCACY_TOTAL_USER_ENGAGEMENTS',
                        heading: 'Total_Engagements',
                    },
                    RDB_FIREHOSE: {
                        dimensionName: 'DISTINCT_USER_COUNT',
                        heading: 'Distinct User',
                    },
                    STORY_MESSAGE: {
                        dimensionName: 'STORY_ACTIVE_COUNT',
                        heading: 'Story Active Count',
                    },
                }),
            ],
            sorts: [new Sort({ order: 'DESC', projections: 0 })],
            timePeriods: [{ key: 'last_7_days' }, { previousPeriod: true }],
        }) as any,
    ],
    position: DefaultWidgetPosition,
    label: {
        titleText: 'Funnel Chart',
        enabled: true,
        size: WIDGET_LABEL_SIZE.primary,
        textShadow: TEXT_SHADOW_DEFAULT,
    },
    options: {
        player: {
            playing: true,
            duration: 15,
        },
        orientation: 'vertical',
        smartSize: {
            enabled: true,
            mode: 'responsive',
            size: 'xl',
        },
        portrait: {
            widgetOrientation: 'portrait',
        },
    },
};
