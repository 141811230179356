import * as React from 'react';
import { AnalyticsRequest, GroupBy, Sort } from './PanelTemplatesTheme/PanelTemplatesTheme';
import { Panel } from '../../models/Panel/Panel';
import { WIDGET_LABEL_SIZE } from '../../components/Widget/WidgetLabel/constants';
import ListeningDimensions from '@sprinklr/stories-services/AnalyticsService/ListeningDimensions';

export var TemplateSocialChannelPerformance: Panel = {
    id: 'social-channel-performance',
    name: 'Social Channel Performance',
    description: '',
    tags: [
        'Smart',
        'Social Channel Performance',
        'Map',
        'Geo',
        'Heat Map',
        'Trend Line',
        'Line Chart',
        'landscape',
        'portrait',
    ],
    widget: {
        id: '1',
        position: {
            width: '1920px',
            height: '1080px',
            top: '0',
            left: '0',
        },
        classes: 'template_social_channel_performance',
        children: [
            {
                type: 'worldHeatMap',
                name: 'World Heat Map',
                id: '1',
                useGlobalTimePeriod: true,
                analyticsRequests: [
                    // Note: All global variables like reportingEngine, report, filters, and projections
                    // are injected via AnalyticsRequest.getRequests()
                    new AnalyticsRequest({
                        limit: 100,
                        groupBys: [
                            new GroupBy({
                                LISTENING: { source: ListeningDimensions.country },
                                INBOUND_MESSAGE: null, // Not supported: No geo
                                PLATFORM: null, // Not supported: No geo
                                BENCHMARKING: null, // Not supported: No geo
                                PAID: { dimensionName: 'adCountry' },
                                RDB_FIREHOSE: { dimensionName: 'RDB_QUERY_IDS' },
                                STORY_MESSAGE: { dimensionName: 'COUNTRY' },
                            }),
                        ],
                        sorts: [
                            new Sort({ order: 'DESC', projections: 0 }),
                            new Sort({ order: 'ASC', groupBys: 0 }),
                        ],
                        timePeriods: [{ key: 'last_7_days' }],
                    }) as any,
                ],
                label: {
                    titleText: 'Social Channel Performance',
                    overlap: true,
                    size: WIDGET_LABEL_SIZE.secondary,
                    enabled: true,
                },
                position: {
                    width: '100%',
                    height: '85%',
                    top: '0%',
                    left: '0%',
                    portrait: {
                        width: '100%',
                        height: '75%',
                        top: '0%',
                        left: '0%',
                    },
                },
                options: {
                    player: {
                        playing: true,
                        duration: 15,
                    },
                    legendPosition: 'top',
                    portrait: {
                        zoom: 173,
                        offsetX: 87,
                        offsetY: 100,
                    },
                },
            },
            {
                type: 'lineChart',
                name: 'Line Chart',
                id: '2',
                useGlobalTimePeriod: true,
                analyticsRequests: [
                    // Note: All global variables like reportingEngine, report, filters, and projections
                    // are injected via AnalyticsRequest.getRequests()
                    new AnalyticsRequest({
                        limit: 100,
                        groupBys: [
                            new GroupBy({
                                LISTENING: { source: ListeningDimensions.day },
                                INBOUND_MESSAGE: null, // Not supported because World Heat Map in template
                                PLATFORM: null, // Not supported because World Heat Map in template
                                BENCHMARKING: null, // Not supported because World Heat Map in template
                                PAID: {
                                    source: ListeningDimensions.day,
                                    dimensionName: 'measurementTime',
                                },
                                STORY_MESSAGE: {
                                    source: ListeningDimensions.day,
                                    dimensionName: 'CREATED_TIME',
                                },
                            }),
                            new GroupBy({
                                LISTENING: { source: ListeningDimensions.socialNetwork },
                                INBOUND_MESSAGE: null, // Not supported because World Heat Map in template
                                PLATFORM: null, // Not supported because World Heat Map in template
                                BENCHMARKING: null, // Not supported because World Heat Map in template
                                PAID: { dimensionName: 'socialChannelTypes' },
                                STORY_MESSAGE: {
                                    dimensionName: 'RDB_DATA_SOURCE',
                                },
                            }),
                        ],
                        sorts: [new Sort({ order: 'ASC', groupBys: 0 })],
                        timePeriods: [{ key: 'last_7_days' }],
                    }) as any,
                ],
                label: {
                    titleText: 'Daily Mentions',
                    size: WIDGET_LABEL_SIZE.secondary,
                    enabled: true,
                },
                position: {
                    width: '100%',
                    height: '25%',
                    top: '75%',
                    left: '0',
                },
                options: {
                    player: {
                        playing: true,
                        duration: 15,
                    },
                    showXAxis: true,
                    showYAxis: false,
                    interpolation: 'linear',
                    scaleType: 'linear',
                    showTopPointMarker: true,
                    showTopPointLabel: true,
                    showTopPointValue: true,
                    showTopPointIcon: true,
                    showTopPointOrb: true,
                    showPointMarkers: true,
                    labelSize: 10,
                    valueSize: 40,
                    iconSize: 10,
                    orbSize: 10,
                    maxTopPoints: 10,
                    lineWidth: 5,
                },
            },
            {
                type: 'rankedList',
                name: 'Metrics By Network',
                id: '3',
                useGlobalTimePeriod: true,
                analyticsRequests: [
                    // Note: All global variables like reportingEngine, report, filters, and projections
                    // are injected via AnalyticsRequest.getRequests()
                    new AnalyticsRequest({
                        limit: 3,
                        groupBys: [
                            new GroupBy({
                                LISTENING: { source: ListeningDimensions.socialNetwork },
                                INBOUND_MESSAGE: null, // Not supported because World Heat Map in template
                                PLATFORM: null, // Not supported because World Heat Map in template
                                BENCHMARKING: null, // Not supported because World Heat Map in template
                                PAID: { dimensionName: 'socialChannelTypes' },
                                STORY_MESSAGE: { dimensionName: 'RDB_DATA_SOURCE' },
                            }),
                        ],
                        sorts: [
                            new Sort({ order: 'DESC', projections: 0 }),
                            new Sort({ order: 'ASC', groupBys: 0 }),
                        ],
                        timePeriods: [{ key: 'last_7_days' }, { previousPeriod: true }],
                    }) as any,
                ],
                position: {
                    width: '25%',
                    height: '65%',
                    top: '10%',
                    left: '75%',
                },
                label: {
                    // titleText: 'Mentions by Network',
                    titleText: '',
                    classes: 'flex no-grow vertical right mb1',
                },
                options: {
                    player: {
                        playing: true,
                        duration: 15,
                    },
                    subType: 'metricValueLockup',

                    showMetricValue: true,
                    metricValueSize: 35,

                    showMetricName: true,
                    metricNameSize: 13,

                    showMetricChange: false,
                    metricPercentChangeSize: 10,
                    highlightPercentChange: false,
                },
            },
            {
                type: 'metric',
                name: 'Total Mentions',
                id: '4',
                position: {
                    width: '25%',
                    height: '20%',
                    top: '5%',
                    left: '0%',
                },
                useGlobalTimePeriod: true,
                analyticsRequests: [
                    // Note: All global variables like reportingEngine, report, filters, and projections
                    // are injected via AnalyticsRequest.getRequests()
                    new AnalyticsRequest({
                        limit: 100,
                        groupBys: [],
                        sorts: [],
                        timePeriods: [{ key: 'last_60_minutes' }],
                    }) as any,
                ],
                label: {
                    classes: 'flex no-grow vertical right',
                    // titleText: 'Total Mentions/Hour',
                    size: WIDGET_LABEL_SIZE.secondary,
                    enabled: true,
                },
                options: {
                    player: {
                        playing: true,
                        duration: 15,
                    },
                    showBackgroundCircle: false,
                    showMetricValue: true,
                    metricValueSize: 45,

                    showMetricName: true,
                    metricNameSize: 12,
                    alignment: 'left',

                    showMetricChange: false,
                    metricPercentChangeSize: 10,
                    highlightPercentChange: false,
                },
            },
        ],
    },
};
