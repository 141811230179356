import { getEmptyTheme } from 'models/Theme/Theme';
import i18n from 'src/i18n';

const theme = getEmptyTheme();

const ListeningTemplate = {
    id: 'cGFuZWw6NWUwZTVmYjQ4ZTZmMjcxYjA5MzI0MWNk',
    partnerId: 50400,
    clientId: 734,
    name: 'Blank',
    description: '',
    publishTier: 'ONE',
    theme,
    storyboard: {
        id: 'c3Rvcnlib2FyZDo1ZGY3ZmE4ODhlNmYyNzdkNDIzM2I4MmY=',
        name: 'Touchscreen Test',
        pageNumber: {
            enabled: false,
            color: 'white',
            size: 10,
            alignment: 'right',
            showTotal: null,
        },
        watermark: {
            panelIndex: null,
            watermarkRendered: null,
            imageUrl: null,
        },
        timePeriod: {
            key: 'last_7_days',
            duration: null,
            timeZone: 'America/Los_Angeles',
            startTime: 1575964800000,
            endTime: 1576569599999,
        },
    },
    scene: {
        id: 'c2NlbmU6NWUwZTVmYjQ4ZTZmMjcxYjA5MzI0MWNj',
        name: 'Listening',
        duration: 0,
        disabled: false,
        theme: null,
        sceneTransition: null,
    },
    widget: {
        children: [
            {
                type: 'metric',
                id: 'id_fgrpweh67',
                name: 'Single Metric',
                position: {
                    width: '15%',
                    height: '10%',
                    top: '0%',
                    left: '0%',
                },
                useGlobalTimePeriod: true,
                analyticsRequests: [
                    {
                        reportingEngine: 'LISTENING',
                        projections: [],
                        report: 'SPRINKSIGHTS',
                        limit: 1,
                        groupBys: [],
                        sorts: [],
                        timePeriods: null,
                        filters: [],
                    },
                ],
                label: {
                    titleText: 'Mentions',
                    overlap: true,
                    size: 10,
                    enabled: true,
                },
                options: {
                    player: {
                        playing: true,
                        duration: 15,
                    },
                    labelEnable: true,
                    labelTimeRangeSize: 16,
                    labelTimeRangeEnabled: false,
                    labelTimeRangeFormat: 'Pretty Time',
                    labelTimeZone: '',
                    animationLength: 1000,
                    animationDelay: 0,
                    animationType: 'fade',
                    animationEnabled: true,
                    animationVariation: 6,
                    widgetOrientation: 'landscape',
                    opacity: 100,
                    zIndex: 19,
                    locked: false,
                    sort: 'auto',
                    percentChangeFormat: '1k',
                    metricTimeRoundSeconds: false,
                    fontFamilySecondary: null,
                    fontFamilyPrimary: null,
                    fontSize: null,
                    colorFont: null,
                    showSocialColors: true,
                    metricThreshold: null,
                    showFullTimePeriod: false,
                    textFormat: '',
                    useThemeColor: false,
                    useThemeImage: false,
                    useThemeEmail: false,
                    themeEmailSize: 20,
                    themeImagePosition: null,
                    themeImageOpacity: 100,
                    themeImageCircleMask: true,
                    themeImageSize: 100,
                    barChartScale: 100,
                    showPostTextOverlayAsGradient: false,
                    classOverrides: {},
                    postVersion: 1,
                    zeroFill: false,
                    zeroKill: false,
                    autoSizeWords: false,
                    invertDataSet: false,
                    showAdsMetrics: false,
                    showMetricName: false,
                    metricNameSize: 5,
                    metricNameCase: 'lowercase',
                    showMetricValue: true,
                    metricValueSize: 10,
                    metricValueFormat: '1.23k',
                    metricValueType: 'primary',
                    showMetricChange: false,
                    metricPercentChangeSize: 6,
                    highlightPercentChange: false,
                    showMetricPreviousValue: false,
                    metricPreviousValueSize: 8,
                    previousValueFormat: '1k',
                    showTimeRemainder: true,
                    thresholds: [],
                    thresholdsEnabled: false,
                    thresholdBarHeight: 2,
                    thresholdBarLength: 50,
                    thresholdsPercent: false,
                    maxThresholds: 1,
                    percentChangeColor: {
                        enabled: false,
                        up: '#33CC99',
                        zero: '',
                        down: '#FF6060',
                    },
                    showBackgroundCircle: false,
                    smartSize: {
                        enabled: true,
                        mode: 'responsive',
                        size: 'xs',
                        baseline: {
                            metricValueSize: 60,
                            metricNameSize: 15,
                            metricPercentChangeSize: 20,
                        },
                        optionDefaults: {
                            showBackgroundCircle: true,
                        },
                        breakpoints: {
                            xs: {
                                options: {
                                    showBackgroundCircle: false,
                                },
                            },
                            s: {
                                options: {
                                    showBackgroundCircle: false,
                                },
                            },
                            m: {
                                options: {
                                    showBackgroundCircle: false,
                                },
                            },
                            l: {
                                options: {
                                    showBackgroundCircle: false,
                                },
                            },
                        },
                    },
                    primaryClasses: 'middle right',
                    portrait: {
                        widgetOrientation: 'portrait',
                        metricValueSize: 45,
                    },
                },
            },
            {
                children: null,
                id: 'id_6x3omsbv6',
                name: 'Profile',
                type: 'profile',
                useGlobalTimePeriod: true,
                css: null,
                js: null,
                options: {
                    player: {
                        playing: false,
                        duration: 15,
                    },
                    labelEnable: true,
                    labelTimeRangeSize: 16,
                    labelTimeRangeEnabled: false,
                    labelTimeRangeFormat: 'Pretty Time',
                    labelTimeZone: '',
                    animationLength: 1000,
                    animationDelay: 0,
                    animationType: 'fade',
                    animationEnabled: false,
                    animationVariation: 6,
                    widgetOrientation: 'portrait',
                    opacity: 100,
                    zIndex: 6,
                    locked: false,
                    sort: 'auto',
                    percentChangeFormat: '1k',
                    metricTimeRoundSeconds: false,
                    fontFamilySecondary: null,
                    fontFamilyPrimary: null,
                    fontSize: null,
                    colorFont: null,
                    showSocialColors: true,
                    metricThreshold: null,
                    showFullTimePeriod: false,
                    textFormat: '',
                    useThemeColor: false,
                    useThemeImage: false,
                    useThemeEmail: false,
                    themeEmailSize: 20,
                    themeImagePosition: null,
                    themeImageOpacity: 100,
                    themeImageCircleMask: true,
                    themeImageSize: 100,
                    barChartScale: 100,
                    showPostTextOverlayAsGradient: false,
                    classOverrides: {
                        profileInfo: 'p-05',
                        profileMetrics: 'p-05',
                        profileOuter: 'middle',
                        profileDescription: 'mt-05',
                    },
                    postVersion: 1,
                    zeroFill: false,
                    zeroKill: false,
                    autoSizeWords: false,
                    invertDataSet: false,
                    showAdsMetrics: false,
                    labelSize: 6,
                    showMetricName: true,
                    metricNameSize: 7,
                    showMetricValue: true,
                    metricValueSize: 15,
                    metricValueFormat: '1.23k',
                    showMetricChange: true,
                    metricPercentChangeSize: 6,
                    showUserGroup: true,
                    showUserAvatar: true,
                    showUserName: true,
                    showUserScreenName: true,
                    rank: {
                        enabled: false,
                        size: 6,
                    },
                    userGroupIconSize: 2,
                    userGroupAvatarSize: 10,
                    userGroupNameSize: 8,
                    userGroupScreenNameSize: 8,
                    showProfileDescription: true,
                    profileDescriptionSize: 6,
                    clamp: {
                        enabled: false,
                        lines: 3,
                    },
                    showTimeStamp: false,
                    timeStampSize: 5,
                    timeStampFormat: 'timeAgo',
                    endianTime: 'D MMM YYYY, h:mm a',
                    socialIconBgColor: true,
                    smartSize: {
                        enabled: false,
                        mode: 'responsive',
                        size: 'xs',
                        baseline: {
                            metricPercentChangeSize: 20,
                            metricValueSize: 50,
                            metricNameSize: 15,
                            timeStampSize: 15,
                            labelSize: 20,
                            userGroupIconSize: 10,
                            userGroupAvatarSize: 70,
                            userGroupNameSize: 14,
                            userGroupScreenNameSize: 10,
                            profileDescriptionSize: 19,
                            rank: {
                                size: 20,
                            },
                        },
                        optionDefaults: {
                            clamp: {
                                lines: 5,
                                enabled: true,
                            },
                            classOverrides: {
                                profileInfo: 'p-1 pl-2',
                                profileMetrics: 'pb-1 pt-2',
                                profileDescription: 'mt-1',
                            },
                        },
                        breakpoints: {
                            xs: {
                                options: {
                                    clamp: {
                                        lines: 3,
                                        enabled: true,
                                    },
                                    classOverrides: {
                                        profileInfo: 'p-05',
                                        profileMetrics: 'p-05',
                                        profileDescription: 'mt-05',
                                    },
                                },
                            },
                            s: {
                                options: {
                                    clamp: {
                                        lines: 3,
                                        enabled: true,
                                    },
                                    classOverrides: {
                                        profileInfo: 'p-05',
                                        profileMetrics: 'p-05',
                                        profileDescription: 'mt-05',
                                    },
                                },
                            },
                            m: {
                                options: {
                                    clamp: {
                                        lines: 4,
                                        enabled: true,
                                    },
                                    classOverrides: {
                                        profileInfo: 'p-05 pl-1',
                                        profileMetrics: 'pb-05 pt-1',
                                        profileDescription: 'mt-05',
                                    },
                                },
                            },
                            ml: {
                                options: {
                                    clamp: {
                                        lines: 4,
                                        enabled: true,
                                    },
                                },
                            },
                        },
                    },
                    portrait: {
                        widgetOrientation: 'portrait',
                    },
                    numbProfiles: 5,
                    showDescription: true,
                    showIcon: true,
                    userGroupLayoutType: 'a',
                    showIconInUserGroup: true,
                },
                userAssetContentUrl: null,
                postRequests: null,
                classes: null,
                theme,
                position: {
                    left: '65%',
                    right: null,
                    top: '0%',
                    bottom: null,
                    width: '35%',
                    height: '55%',
                    portrait: null,
                    landscape: null,
                    backgroundColor: null,
                    display: null,
                    maxWidth: null,
                    maxHeight: null,
                },
                analyticsRequests: null,
                analyticsResults: null,
                profileRequests: [
                    {
                        reportingEngine: 'LISTENING',
                        report: 'SPRINKSIGHTS',
                        page: 0,
                        pageSize: 10,
                        groupBys: [
                            {
                                heading: 'ADVOCATES',
                                dimensionName: 'ADVOCATES',
                                groupType: 'FIELD',
                            },
                        ],
                        sorts: [
                            {
                                heading: 'MENTIONS_COUNT',
                                order: 'DESC',
                                isDimension: false,
                            },
                        ],
                        timePeriod: null,
                        filters: [],
                    },
                ],
                profileResults: null,
                trendRequests: null,
                label: {
                    titleText: i18n.t('Top Advocates'),
                    subTitleText: null,
                    classes: null,
                    overlap: false,
                    layout: 'flex no-grow vertical left',
                    icon: null,
                    enabled: true,
                    size: 9,
                },
            },
            {
                children: null,
                id: 'id_gw0f65m82',
                name: 'Grid',
                type: 'postsGrid',
                useGlobalTimePeriod: true,
                css: null,
                js: null,
                options: {
                    player: {
                        playing: false,
                        duration: 15,
                    },
                    labelEnable: true,
                    labelTimeRangeSize: 16,
                    labelTimeRangeEnabled: false,
                    labelTimeRangeFormat: 'Pretty Time',
                    labelTimeZone: '',
                    animationLength: 1000,
                    animationDelay: 0,
                    animationType: 'fade',
                    animationEnabled: false,
                    animationVariation: 6,
                    widgetOrientation: 'landscape',
                    opacity: 100,
                    zIndex: 8,
                    locked: false,
                    sort: 'auto',
                    percentChangeFormat: '1k',
                    metricTimeRoundSeconds: false,
                    fontFamilySecondary: null,
                    fontFamilyPrimary: null,
                    fontSize: null,
                    colorFont: null,
                    showSocialColors: true,
                    metricThreshold: null,
                    showFullTimePeriod: false,
                    textFormat: '',
                    useThemeColor: false,
                    useThemeImage: false,
                    useThemeEmail: false,
                    themeEmailSize: 20,
                    themeImagePosition: null,
                    themeImageOpacity: 100,
                    themeImageCircleMask: true,
                    themeImageSize: 100,
                    barChartScale: 100,
                    showPostTextOverlayAsGradient: false,
                    classOverrides: {
                        postInner: {
                            default: 'between',
                            review: '',
                        },
                        postText: {
                            default: 'p-reset pl-05 pr-05 pt-05 pb-05 ta-c',
                            review: 'p-reset pl-05 pr-05 pt-05 pb-05 h-50',
                        },
                        userGroup: {
                            default: 'p-reset p-05',
                            review: 'p-reset pl-05 w-a',
                        },
                        metaGroup: {
                            default: 'p-reset pt-05 pl-05 pr-05 pb-0',
                        },
                        reviewDomain: {
                            default: 'mr-05',
                        },
                        reviewRating: {
                            default: 'pl-05 pb-05 pt-05',
                        },
                        engagementMetric: {
                            default: 'm-reset ml-05',
                        },
                    },
                    postVersion: 2,
                    zeroFill: false,
                    zeroKill: false,
                    autoSizeWords: false,
                    invertDataSet: false,
                    showAdsMetrics: true,
                    showImageStyles: true,
                    imageFiltersOpen: true,
                    imageFilterBlur: false,
                    imageFilterBrightness: false,
                    imageFilterContrast: false,
                    imageFilterGrayscale: false,
                    imageFilterSaturate: false,
                    imageFilterHueRotate: false,
                    imageFilterOpacity: false,
                    imageFilterInvert: false,
                    imageFilterSepia: false,
                    imageFilterBlurValue: 0,
                    imageFilterBrightnessValue: 100,
                    imageFilterContrastValue: 100,
                    imageFilterGrayscaleValue: 0,
                    imageFilterSaturateValue: 100,
                    imageFilterHueRotateValue: 0,
                    imageFilterOpacityValue: 100,
                    imageFilterInvertValue: 0,
                    imageFilterSepiaValue: 0,
                    imageBlendMode: null,
                    imageSecondaryBlendMode: '',
                    imageBlendColor: 'rgba(0,0,0,.0)',
                    imageSecondaryBlendColor: '',
                    presetImageFilters: 'none',
                    imageTextColor: '#FFF',
                    postImageTextSize: 7,
                    imagePostLineClamp: true,
                    imagePostLineClampValue: 4,
                    imagePostHideText: true,
                    imageFaceDetection: false,
                    hashtagTextColor: '',
                    hashtagBackgroundColor: '',
                    mentionTextColor: '',
                    mentionBackgroundColor: '',
                    urlTextColor: '',
                    urlBackgroundColor: '',
                    showTextEntityUrls: false,
                    showEngagementMetrics: true,
                    engagementMetricsSize: 7,
                    engagementMetricsPosition: 'bottom_left',
                    adsMetricsSize: 30,
                    showTextStyles: false,
                    showText: true,
                    textOnlyColor: '#FFF',
                    postTextOnlySize: 7,
                    postLineClamp: true,
                    postLineClampValue: 4,
                    textFitMin: 20,
                    showPostTextOverlay: false,
                    showUserGroup: true,
                    showUserAvatar: true,
                    showUserName: true,
                    showUserScreenName: true,
                    userGroupIconSize: 4,
                    userGroupAvatarSize: 12,
                    userGroupNameSize: 6,
                    userGroupScreenNameSize: 4,
                    userGroupPosition: 'bottom',
                    userGroupGradientColor: 'rgba(0,0,0,.3)',
                    userGroupGradientEnabled: false,
                    userGroupGradientDirection: 'bottomToTop',
                    showTimeStamp: false,
                    timeStampSize: 15,
                    timeStampFormat: 'timeAgo',
                    endianTime: 'D MMM YYYY, h:mm a',
                    textOnlyBackgroundColor: '',
                    textOnlyTextFormat: 'clamp',
                    textOnlyTeleprompterSpeed: 50,
                    postImageTextFormat: 'clamp',
                    postImageTeleprompterSpeed: 50,
                    imageBackgroundSize: 'cover',
                    showZeroEngagementValues: false,
                    maxCharacters: 180,
                    teleprompterSpeed: 50,
                    userGroupLayoutType: 'd',
                    postCompositionType: 'a',
                    socialIconBgColor: true,
                    customFields: {
                        fieldId: '',
                        size: 12,
                    },
                    reviews: {
                        enabled: false,
                        title: {
                            enabled: true,
                            color: '',
                            size: 17,
                            format: {
                                type: 'clamp',
                                clamp: {
                                    enabled: true,
                                    lines: 2,
                                },
                                fit: {
                                    min: 10,
                                    max: 400,
                                },
                            },
                        },
                        rating: {
                            enabled: true,
                            filledColor: '',
                            emptyColor: '',
                            size: 12,
                        },
                        product: {
                            enabled: true,
                            color: '',
                            size: 9,
                        },
                        message: {
                            enabled: true,
                            size: 13,
                            color: '',
                            format: {
                                type: 'clamp',
                                clamp: {
                                    enabled: true,
                                    lines: 5,
                                },
                                fit: {
                                    min: 20,
                                    max: 400,
                                },
                            },
                        },
                        domain: {
                            enabled: true,
                            color: '',
                            size: 9,
                        },
                    },
                    smartSize: {
                        enabled: false,
                        mode: 'responsive',
                        size: 's',
                    },
                    postComposition: 'a',
                    extraPostDataOptions: {
                        enabled: null,
                        color: '',
                        size: 15,
                        value: {
                            format: '1.23k',
                        },
                        border: {
                            enabled: true,
                            color: '',
                        },
                        header: {
                            enabled: false,
                            size: 30,
                            text: '',
                            color: '',
                        },
                    },
                    nativeStyling: {
                        enabled: true,
                        colorMode: 'light',
                        lineClamp: {
                            enabled: true,
                            lines: 8,
                            hasMedia: {
                                enabled: true,
                                lines: 3,
                            },
                        },
                        maxCharacters: 400,
                        border: {
                            enabled: false,
                            color: 'rgba(0,0,0, .3)',
                            size: 5,
                        },
                        shadow: {
                            enabled: false,
                            color: 'rgba(0,0,0, .5)',
                            size: 25,
                        },
                        fontScale: 16,
                    },
                    showSentiment: true,
                    portrait: {
                        widgetOrientation: 'portrait',
                    },
                    postTemplate: '',
                    userGroupEffect: 'top',
                    theme: 'primary',
                    showIconInUserGroup: false,
                    smartSizeCompositions: {
                        baseline: {
                            postTextOnlySize: 17,
                            postImageTextSize: 17,
                            engagementMetricsSize: 20,
                            userGroupIconSize: 14,
                            userGroupAvatarSize: 20,
                            userGroupNameSize: 14,
                            userGroupScreenNameSize: 11,
                            nativeStyling: {
                                fontScale: 40,
                            },
                        },
                        optionDefaults: {
                            optionDefaults: {
                                imagePostLineClampValue: 5,
                                postLineClampValue: 5,
                            },
                            classOverrides: {
                                postInner: {
                                    default: 'between',
                                    review: '',
                                },
                                postText: {
                                    default: 'p-reset pl-15 pr-15 pt-15 pb-05 ta-c',
                                    review: 'p-reset pl-2 pr-2 pt-05 pb-05 h-50',
                                },
                                userGroup: {
                                    default: 'p-reset p-15',
                                    review: 'p-reset pl-2 w-a',
                                },
                                metaGroup: {
                                    default: 'p-reset pt-15 pl-15 pr-15 pb-0',
                                },
                                reviewDomain: {
                                    default: 'mr-2',
                                },
                                reviewRating: {
                                    default: 'pl-2 pb-2 pt-2',
                                },
                            },
                        },
                        breakpoints: {
                            m: {
                                options: {
                                    classOverrides: {
                                        postInner: {
                                            default: 'between',
                                            review: '',
                                        },
                                        postText: {
                                            default: 'p-reset pl-1 pr-1 pt-1 pb-05 ta-c',
                                            review: 'p-reset pl-1 pr-1 pt-05 pb-05 h-50',
                                        },
                                        userGroup: {
                                            default: 'p-reset p-1',
                                            review: 'p-reset pl-1 w-a',
                                        },
                                        metaGroup: {
                                            default: 'p-reset pt-1 pl-1 pr-1 pb-0',
                                        },
                                        reviewDomain: {
                                            default: 'mr-1',
                                        },
                                        reviewRating: {
                                            default: 'pl-1 pb-1 pt-1',
                                        },
                                        engagementMetric: {
                                            default: 'm-reset ml-1',
                                        },
                                    },
                                },
                            },
                            s: {
                                options: {
                                    imagePostLineClampValue: 4,
                                    postLineClampValue: 4,
                                    classOverrides: {
                                        postInner: {
                                            default: 'between',
                                            review: '',
                                        },
                                        postText: {
                                            default: 'p-reset pl-05 pr-05 pt-05 pb-05 ta-c',
                                            review: 'p-reset pl-05 pr-05 pt-05 pb-05 h-50',
                                        },
                                        userGroup: {
                                            default: 'p-reset p-05',
                                            review: 'p-reset pl-05 w-a',
                                        },
                                        metaGroup: {
                                            default: 'p-reset pt-05 pl-05 pr-05 pb-0',
                                        },
                                        reviewDomain: {
                                            default: 'mr-05',
                                        },
                                        reviewRating: {
                                            default: 'pl-05 pb-05 pt-05',
                                        },
                                        engagementMetric: {
                                            default: 'm-reset ml-05',
                                        },
                                    },
                                },
                            },
                            xs: {
                                options: {
                                    imagePostLineClampValue: 4,
                                    postLineClampValue: 4,
                                    classOverrides: {
                                        postInner: {
                                            default: 'between',
                                            review: '',
                                        },
                                        postText: {
                                            default: 'p-reset pl-05 pr-05 pt-05 pb-05 ta-c',
                                            review: 'p-reset pl-05 pr-05 pt-05 pb-05 h-50',
                                        },
                                        userGroup: {
                                            default: 'p-reset p-05',
                                            review: 'p-reset pl-05 w-a',
                                        },
                                        metaGroup: {
                                            default: 'p-reset pt-05 pl-05 pr-05 pb-0',
                                        },
                                        reviewDomain: {
                                            default: 'mr-05',
                                        },
                                        reviewRating: {
                                            default: 'pl-05 pb-05 pt-05',
                                        },
                                        engagementMetric: {
                                            default: 'm-reset ml-05',
                                        },
                                    },
                                },
                            },
                        },
                    },
                    optionDefaults: {
                        imagePostLineClampValue: 5,
                        postLineClampValue: 5,
                    },
                },
                userAssetContentUrl: null,
                postRequests: [
                    {
                        sources: [
                            {
                                type: 'posts',
                                id: {
                                    columnType: null,
                                    columnId: null,
                                    reportingEngine: 'LISTENING',
                                    report: 'SPRINKSIGHTS',
                                    projections: [],
                                    sorts: [],
                                    filters: [],
                                    timePeriods: null,
                                    source: 'REPORTING',
                                    pageSize: 5,
                                    timePeriod: null,
                                    groupBys: [],
                                    showInReplyToPost: false,
                                },
                                options: {
                                    count: 5,
                                    imagePreload: true,
                                    removeDuplicates: true,
                                    shufflePosts: false,
                                    includeFaceDetection: false,
                                },
                            },
                        ],
                        format: {
                            type: 'buckets',
                            options: {
                                padding: 1,
                                buckets: {
                                    any: {
                                        filter: 'post.type',
                                    },
                                },
                                panels: [
                                    {
                                        total: 5,
                                        buckets: ['any', -1],
                                    },
                                ],
                                ordered: true,
                            },
                        },
                        showInReplyToPost: false,
                    },
                ],
                classes: null,
                theme,
                position: {
                    left: '0%',
                    right: null,
                    top: '55%',
                    bottom: null,
                    width: '100%',
                    height: '45%',
                    portrait: null,
                    landscape: null,
                    backgroundColor: null,
                    display: null,
                    maxWidth: null,
                    maxHeight: null,
                },
                analyticsRequests: null,
                analyticsResults: null,
                profileRequests: null,
                profileResults: null,
                trendRequests: null,
                label: {
                    titleText: i18n.t('Top Posts'),
                    subTitleText: null,
                    classes: null,
                    overlap: false,
                    layout: 'flex no-grow vertical left',
                    icon: null,
                    enabled: true,
                    size: 10,
                },
            },
            // Note: disabling the word cloud until we can resolve the multiscreen sizing issue
            // {
            //     children: null,
            //     id: 'id_87d7aj32e',
            //     name: 'Word Cloud',
            //     type: 'wordCloud',
            //     useGlobalTimePeriod: true,
            //     css: null,
            //     js: null,
            //     options: {
            //         player: {
            //             playing: false,
            //             duration: 15,
            //         },
            //         labelEnable: true,
            //         labelTimeRangeSize: 16,
            //         labelTimeRangeEnabled: false,
            //         labelTimeRangeFormat: 'Pretty Time',
            //         labelTimeZone: '',
            //         animationLength: 1000,
            //         animationDelay: 0,
            //         animationType: 'fade',
            //         animationEnabled: false,
            //         animationVariation: 6,
            //         widgetOrientation: 'landscape',
            //         opacity: 100,
            //         zIndex: 9,
            //         locked: false,
            //         sort: 'auto',
            //         percentChangeFormat: '1k',
            //         metricTimeRoundSeconds: false,
            //         fontFamilySecondary: null,
            //         fontFamilyPrimary: null,
            //         fontSize: null,
            //         colorFont: null,
            //         showSocialColors: true,
            //         metricThreshold: null,
            //         showFullTimePeriod: false,
            //         textFormat: '',
            //         useThemeColor: false,
            //         useThemeImage: false,
            //         useThemeEmail: false,
            //         themeEmailSize: 20,
            //         themeImagePosition: null,
            //         themeImageOpacity: 100,
            //         themeImageCircleMask: true,
            //         themeImageSize: 100,
            //         barChartScale: 100,
            //         showPostTextOverlayAsGradient: false,
            //         classOverrides: {},
            //         postVersion: 1,
            //         zeroFill: false,
            //         zeroKill: false,
            //         autoSizeWords: false,
            //         invertDataSet: false,
            //         showAdsMetrics: false,
            //         minFontSize: 12,
            //         maxFontSize: 25,
            //         showSentiment: false,
            //         spacing: 6,
            //         blacklist: [],
            //         smartSize: {
            //             enabled: true,
            //             mode: 'responsive',
            //             size: 'xs',
            //             baseline: {
            //                 minFontSize: 12,
            //                 maxFontSize: 25,
            //                 spacing: 6,
            //             },
            //         },
            //         portrait: {
            //             widgetOrientation: 'portrait',
            //         },
            //     },
            //     userAssetContentUrl: null,
            //     postRequests: null,
            //     classes: null,
            //     theme,
            //     position: {
            //         left: '0%',
            //         right: null,
            //         top: '29%',
            //         bottom: null,
            //         width: '62.5%',
            //         height: '26%',
            //         portrait: null,
            //         landscape: null,
            //         backgroundColor: null,
            //         display: null,
            //         maxWidth: null,
            //         maxHeight: null,
            //     },
            //     analyticsRequests: [
            //         {
            //             timeField: null,
            //             includeTotal: null,
            //             additional: {
            //                 TARGET_LANG_CODE: 'en',
            //                 Currency: null,
            //                 slaConfig: null,
            //             },
            //             reportingEngine: 'LISTENING',
            //             report: 'SPRINKSIGHTS',
            //             limit: 25,
            //             sorts: [
            //                 {
            //                     heading: 'Mentions',
            //                     order: 'DESC',
            //                 },
            //                 {
            //                     heading: 'WORD_CLOUD_MESSAGE',
            //                     order: 'ASC',
            //                 },
            //             ],
            //             timePeriods: null,
            //             groupBys: [
            //                 {
            //                     heading: 'WORD_CLOUD_MESSAGE',
            //                     dimensionName: 'WORD_CLOUD_MESSAGE',
            //                     groupType: 'FIELD',
            //                     resolveName: false,
            //                     details: null,
            //                 },
            //             ],
            //             filters: [],
            //             projections: [],
            //         },
            //     ],
            //     analyticsResults: null,
            //     profileRequests: null,
            //     profileResults: null,
            //     trendRequests: null,
            //     label: {
            //         titleText: 'Top Words',
            //         subTitleText: null,
            //         classes: null,
            //         overlap: false,
            //         layout: 'flex no-grow vertical left',
            //         icon: null,
            //         enabled: false,
            //         size: 10,
            //     },
            // },
            {
                children: null,
                id: '11yavkmo4',
                name: 'Bar Chart',
                type: 'barChart',
                useGlobalTimePeriod: false,
                css: null,
                js: null,
                options: {
                    player: {
                        playing: false,
                        duration: 15,
                    },
                    labelEnable: true,
                    labelTimeRangeSize: 16,
                    labelTimeRangeEnabled: false,
                    labelTimeRangeFormat: 'Pretty Time',
                    labelTimeZone: '',
                    animationLength: 1000,
                    animationDelay: 0,
                    animationType: 'fade',
                    animationEnabled: true,
                    animationVariation: 6,
                    widgetOrientation: 'landscape',
                    opacity: 100,
                    zIndex: 19,
                    locked: false,
                    sort: 'label',
                    sortDirection: 'DESC',
                    sortHeading: 'Created Time_1d',
                    percentChangeFormat: '1k',
                    metricTimeRoundSeconds: false,
                    fontFamilySecondary: null,
                    fontFamilyPrimary: null,
                    fontSize: null,
                    colorFont: null,
                    showSocialColors: true,
                    metricThreshold: null,
                    showFullTimePeriod: false,
                    textFormat: '',
                    useThemeColor: false,
                    useThemeImage: false,
                    useThemeEmail: false,
                    themeEmailSize: 20,
                    themeImagePosition: null,
                    themeImageOpacity: 100,
                    themeImageCircleMask: true,
                    themeImageSize: 100,
                    barChartScale: 100,
                    showPostTextOverlayAsGradient: false,
                    classOverrides: {},
                    postVersion: 1,
                    zeroFill: true,
                    zeroKill: false,
                    autoSizeWords: false,
                    invertDataSet: false,
                    showAdsMetrics: false,
                    labelSize: 15,
                    showMetricName: true,
                    metricNameSize: 9,
                    metricNameCase: 'lowercase',
                    showMetricValue: true,
                    metricValueSize: 30,
                    metricValueFormat: '1.23k',
                    showMetricChange: false,
                    metricPercentChangeSize: 10,
                    highlightPercentChange: false,
                    stacked100Percent: false,
                    stackedInline: false,
                    showTimeRemainder: false,
                    spacing: 4,
                    padding: 13,
                    borderRadiusMode: 'all',
                    borderRadius: 0,
                    borderRadiusStart: 0,
                    borderRadiusEnd: 0,
                    thresholds: [],
                    thresholdsEnabled: false,
                    thresholdsLegend: false,
                    thresholdsStacked: false,
                    thresholdLineWidth: 0.5,
                    thresholdsPercent: false,
                    thresholdsAxis: true,
                    thresholdsDashed: false,
                    maxThresholds: 5,
                    labelTextFormat: 'clamp',
                    labelLineClamp: true,
                    labelLineClampValue: 1,
                    labelWrapText: false,
                    endianTime: 'MMM DD',
                    blacklist: [],
                    requestSort: true,
                    smartSize: {
                        enabled: true,
                        mode: 'responsive',
                        size: 'm',
                        baseline: {
                            spacing: 6,
                            padding: 21,
                            labelSize: 25,
                            metricValueSize: 50,
                            metricPercentChangeSize: 17,
                            metricNameSize: 15,
                        },
                        optionDefaults: {
                            labelEnable: true,
                            showMetricName: true,
                            showMetricValue: true,
                            showMetricChange: true,
                            showTimeRemainder: true,
                        },
                        breakpoints: {
                            xs: {
                                options: {
                                    labelEnable: false,
                                    showMetricName: false,
                                    showMetricValue: false,
                                    showMetricChange: false,
                                    showTimeRemainder: false,
                                },
                            },
                            s: {
                                options: {
                                    showMetricName: false,
                                    showMetricChange: false,
                                    showTimeRemainder: false,
                                },
                            },
                            m: {
                                options: {
                                    showMetricChange: false,
                                    showTimeRemainder: false,
                                },
                            },
                        },
                    },
                    percentChangeColor: {
                        enabled: false,
                        up: '#33CC99',
                        zero: '',
                        down: '#FF6060',
                    },
                    orientation: 'horizontal',
                    showSentiment: false,
                    portrait: {
                        widgetOrientation: 'portrait',
                        limit: 3,
                        metricNameSize: 15,
                        labelSize: 20,
                    },
                },
                userAssetContentUrl: null,
                postRequests: null,
                classes: null,
                theme,
                position: {
                    left: '0%',
                    right: null,
                    top: '12.5%',
                    bottom: null,
                    width: '62.5%',
                    height: '39%',
                    portrait: null,
                    landscape: null,
                    backgroundColor: null,
                    display: null,
                    maxWidth: null,
                    maxHeight: null,
                },
                analyticsRequests: [
                    {
                        timeField: null,
                        includeTotal: null,
                        additional: {
                            TARGET_LANG_CODE: null,
                            Currency: null,
                            slaConfig: null,
                        },
                        reportingEngine: 'LISTENING',
                        report: 'SPRINKSIGHTS',
                        limit: 100,
                        sorts: [
                            {
                                order: 'ASC',
                                heading: 'Created Time_1d',
                            },
                        ],
                        timePeriods: [
                            {
                                duration: 86400000,
                                startTime: 1579248000000,
                                endTime: 1579852799999,
                                timeZone: 'America/Los_Angeles',
                                key: 'last_7_days',
                                previousPeriod: false,
                                wholePeriods: false,
                                xValue: null,
                            },
                        ],
                        groupBys: [
                            {
                                heading: 'Created Time_1d',
                                dimensionName: 'SN_CREATED_TIME',
                                groupType: 'DATE_HISTOGRAM',
                                details: {
                                    interval: '1d',
                                },
                            },
                        ],
                        filters: [],
                        projections: [],
                    },
                ],
                analyticsResults: null,
                profileRequests: null,
                profileResults: null,
                trendRequests: null,
                label: {
                    titleText: 'Total Mentions',
                    subTitleText: null,
                    classes: null,
                    overlap: false,
                    layout: 'flex no-grow vertical left',
                    icon: null,
                    enabled: false,
                    size: 10,
                },
            },
        ],
        id: 'wbz4kz3u2',
        name: null,
        type: null,
        useGlobalTimePeriod: true,
        css: `

        .widget_single_metric .single_metric {
            align-items: flex-start!important;
        }

        .widget_single_metric .widget_inner {
            margin-top: 1em;
        }

        .widget_bar_chart .bar_chart {
            top: -1em;
        }

        .widget_bar_chart .bar_chart .meta_group {
            justify-content: flex-end;
            padding: 0;
        }

        .widget_bar_chart .bar_chart .bar_name {
            transform: translate3d(0, 130%, 0)
        }

        .widget_bar_chart .bar:not(.bar_rank_1) .metric-wrapper {
            display: none;
        }

        .widget_bar_chart .bar.bar_rank_1 {
            z-index: 1;
        }

        .widget_bar_chart .bar_chart_inner.horizontal .metric {
            margin-top: auto;
        }

        /*  label spacing */
        .widget_label {
            margin-bottom: -.75em;
        }

        /* Post Group */

        .widget_grid.widget .post_group {
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            gap: 1em;
        }

        .widget_grid.widget .post {
            position: relative;
            width: auto!important;
            height: 100%!important;
            left: initial!important;
            top: initial!important;
            pointer-events: auto;
        }

        .widget_grid.widget .post > div {
            border-radius: 0.5em!important;
        }

        /* Word Cloud */
        .word_cloud {
            padding: 0;
        }

        /* Profile stuff */
        .profile_description {
            display: none;
        }

        .widget .profile {
            position: relative;
            display: block;
            left: auto;
            top: auto;
            width: 100%;
            height: 12%;
            margin-bottom: 1.75em;
            padding: .5em;
        }

        .widget .profile .metric_name {
            margin: 0;
        }

        .widget .profile .user_screen_name {
            margin-top: -.3em;
        }

        .widget .profile .metric .group {
            align-items: flex-end;
        }

        .widget .profile .profile_metrics {
            padding: .4em 0 0 0;
        }

        .widget .profile_info {
            padding: 0 0 0 .75em;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }

        /* wedge */
        .wedge_ratio {
            font-size: 20px;
        }

        /*Adding verification
        .profile .user_full_name:before {
            content: url(https://upload.wikimedia.org/wikipedia/commons/e/e4/Twitter_Verified_Badge.svg);
        } */

        .profile .user_full_name:after {
            position: absolute;
             /* no size or position settings */
            background: url(https://upload.wikimedia.org/wikipedia/commons/e/e4/Twitter_Verified_Badge.svg) no-repeat;
            width: 1em;
            height: 1em;
            margin-left: .15em;
            content: "";
        }

        .native_post > div {
            height: 100%;
        }
        `,
        js: null,
        options: {
            player: {
                playing: false,
                duration: 15,
            },
            labelEnable: true,
            labelTimeRangeSize: 16,
            labelTimeRangeEnabled: false,
            labelTimeRangeFormat: 'Pretty Time',
            labelTimeZone: '',
            animationLength: 1000,
            animationDelay: 0,
            animationType: 'fade',
            animationEnabled: false,
            animationVariation: 6,
            widgetOrientation: 'landscape',
            opacity: 100,
            zIndex: 0,
            locked: false,
            sort: 'auto',
            percentChangeFormat: '1k',
            metricTimeRoundSeconds: false,
            fontFamilySecondary: null,
            fontFamilyPrimary: null,
            fontSize: null,
            colorFont: null,
            showSocialColors: true,
            metricThreshold: null,
            showFullTimePeriod: false,
            textFormat: '',
            useThemeColor: false,
            useThemeImage: false,
            useThemeEmail: false,
            themeEmailSize: 20,
            themeImagePosition: null,
            themeImageOpacity: 100,
            themeImageCircleMask: true,
            themeImageSize: 100,
            barChartScale: 100,
            showPostTextOverlayAsGradient: false,
            classOverrides: {},
            postVersion: 1,
            zeroFill: false,
            zeroKill: false,
            autoSizeWords: false,
            invertDataSet: false,
            showAdsMetrics: false,
        },
        userAssetContentUrl: null,
        postRequests: null,
        classes: 'template_custom',
        theme: {
            ...theme,
            ext: {
                padding: 20,
            },
        },
        position: {
            left: '0',
            right: null,
            top: '0',
            bottom: null,
            width: '100%',
            height: '100%',
            portrait: null,
            landscape: null,
            backgroundColor: null,
            display: null,
            maxWidth: null,
            maxHeight: null,
        },
        analyticsRequests: null,
        analyticsResults: null,
        profileRequests: null,
        profileResults: null,
        trendRequests: null,
        label: {
            titleText: 'Panel',
            subTitleText: null,
            classes: null,
            overlap: false,
            layout: 'flex no-grow vertical left',
            icon: null,
            enabled: false,
            size: 20,
        },
    },
    screenIndex: 0,
    sceneIndex: 1,
    dataLastModified: 1578517370516,
};

export default ListeningTemplate;
