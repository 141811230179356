import * as React from 'react';
import i18n from 'i18next';
import { action, toJS } from 'mobx';
import { TunablesCollapse, CollapseProps } from '../../Sidebar/RenderTunables/RenderTunables';
import {
    smartSizeTunables,
    opacityTunables,
    headerTunables,
} from '../../Sidebar/RenderTunables/SharedTunables';
import { Widget } from '@sprinklr/stories/widget/Widget';
import LabelGroup from '../../_UI/Forms/LabelGroup/LabelGroup';
import ActionColorSwatchGroup from '../../_UI/Forms/Actions/ActionColorSwatchGroup';
import { CustomTableChartWidgetOptionsImpl } from './options';
import { getThemeColors } from './helpers';
import { Theme } from '../../../models/Theme/Theme';
import ActionSliderGroup from '../../_UI/Forms/Actions/ActionSliderGroup';
import ActionSegmentedGroup from '../../_UI/Forms/Actions/ActionSegmentedGroup';
import { verticalAlignOptions } from '../../Panel/PanelEditorSidebar/PanelDesign/PanelDesignOptions';
import { mergeThemes } from '../../../utils/GenerateStyles/GenerateStyles';
import { Row, LabeledSlider } from 'components/spaceKit';

const CustomTableChartTunables = (widget: Widget, mergedTheme: Theme): TunablesCollapse[] => {
    const { header, body, border } = getThemeColors(
        mergeThemes(toJS(widget.theme), toJS(mergedTheme))
    );

    return [
        {
            props: {
                label: i18n.t('Table Chart'),
                startOpen: true,
            } as CollapseProps,
            children: [
                ...headerTunables(widget),
                opacityTunables(widget),
                smartSizeTunables(widget),
            ],
        },
        {
            props: {
                label: i18n.t('Table'),
                startOpen: true,
            },
            children: [
                <Row gap='m' maxColumns={2} key='table chart colors'>
                    <LabelGroup
                        label={i18n.t('Header Fill')}
                        key='label widget.options.fills.header'>
                        <ActionColorSwatchGroup
                            key='widget.options.fills.header'
                            value={null}
                            option={null}
                            color={
                                (widget.options as CustomTableChartWidgetOptionsImpl).fills
                                    .header || header
                            }
                            secondaryIcon={
                                (widget.options as CustomTableChartWidgetOptionsImpl).fills.header
                            }
                            onChange={rgb =>
                                updateColor(
                                    rgb,
                                    (widget.options as CustomTableChartWidgetOptionsImpl).fills,
                                    'header'
                                )
                            }
                            onClear={() =>
                                clearColor(
                                    (widget.options as CustomTableChartWidgetOptionsImpl).fills,
                                    'header'
                                )
                            }
                        />
                    </LabelGroup>
                    <LabelGroup label={i18n.t('Body Fill')} key='label widget.options.fills.body'>
                        <ActionColorSwatchGroup
                            key='widget.options.fills.body'
                            value={null}
                            option={null}
                            color={
                                (widget.options as CustomTableChartWidgetOptionsImpl).fills.body ||
                                body
                            }
                            onChange={rgb =>
                                updateColor(
                                    rgb,
                                    (widget.options as CustomTableChartWidgetOptionsImpl).fills,
                                    'body'
                                )
                            }
                            secondaryIcon={
                                (widget.options as CustomTableChartWidgetOptionsImpl).fills.body
                            }
                            onClear={() =>
                                clearColor(
                                    (widget.options as CustomTableChartWidgetOptionsImpl).fills,
                                    'body'
                                )
                            }
                        />
                    </LabelGroup>
                    <LabelGroup label={i18n.t('Border')} key='label widget.options.fills.border'>
                        <ActionColorSwatchGroup
                            value={null}
                            option={null}
                            color={
                                (widget.options as CustomTableChartWidgetOptionsImpl).fills
                                    .border || border
                            }
                            onChange={rgb =>
                                updateColor(
                                    rgb,
                                    (widget.options as CustomTableChartWidgetOptionsImpl).fills,
                                    'border'
                                )
                            }
                            secondaryIcon={
                                (widget.options as CustomTableChartWidgetOptionsImpl).fills.border
                            }
                            onClear={() =>
                                clearColor(
                                    (widget.options as CustomTableChartWidgetOptionsImpl).fills,
                                    'border'
                                )
                            }
                        />
                    </LabelGroup>
                </Row>,
                <ActionSliderGroup
                    key='widget.options.borderSize'
                    label={i18n.t('Border Size')}
                    option={widget.options}
                    value='borderSize'
                    showValue
                    min={0}
                    max={50}
                />,
                <LabeledSlider
                    key='widget.options.contentPadding'
                    label={i18n.t('Padding')}
                    value={widget.options.contentPadding}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setContentPadding(e.target.value, widget)
                    }
                    showValue
                    min={0}
                    max={100}
                />,
                <ActionSegmentedGroup
                    key='widget.options.verticalAlign'
                    label={i18n.t('Vertical Align')}
                    option={widget.options}
                    value='verticalAlign'
                    options={verticalAlignOptions}
                    handleSideEffects={selection => handleExtras(selection, widget)}
                />,
            ],
        },
    ];
};

const setContentPadding = action((value, widget: Widget) => {
    if (!widget || !value) {
        return;
    }

    widget.options.contentPadding = value;
    widget.children &&
        widget.children.forEach(child => {
            child.options.contentPadding = value;
        });
});

const handleExtras = action((selection, widget: Widget) => {
    if (!widget || !selection) {
        return;
    }

    widget.children &&
        widget.children.forEach(child => {
            child.options.verticalAlign = selection;
        });
});

const clearColor = action((object: object, key: string) => {
    object[key] = '';
});

const updateColor = action(({ rgb: { r, g, b, a = 1 } }, object: object, key: string) => {
    object[key] = `rgba(${r}, ${g}, ${b}, ${a})`;
});

export default CustomTableChartTunables;
