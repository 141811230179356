import merge from 'deepmerge';
// Base interface for all widget options.  These are options that all widgets have
import { action, observable, toJS } from 'mobx';
import { DecimalFormat } from 'utils/Number/NumberUtils';
import { overwriteMerge } from 'utils/ArrayUtils/ArrayUtils';

import { WidgetOptions } from '@sprinklr/stories/widget/WidgetOptions';
import {
    ClassOverrides,
    MetricValueCountup,
    PanelOrientation,
    PostTextFormat,
    SortOption,
} from '@sprinklr/stories/widget/types';

export class WidgetOptionsImpl implements WidgetOptions {
    @observable player = {
        playing: false,
        duration: 15,
        waitVideoEnd: false,
    };

    @observable labelEnable = true;
    @observable labelTimeRangeSize = 16;
    @observable labelTimeRangeEnabled = false;
    @observable labelTimeRangeFormat = 'Pretty Time';
    @observable labelTimeZone = '';
    @observable animationLength = 1000;
    @observable animationDelay = 0;
    @observable animationType = 'fade';
    @observable animationEnabled = true;
    @observable animationVariation = 6;
    @observable widgetOrientation: PanelOrientation = 'landscape';
    @observable opacity = 100;
    @observable zIndex = 0;
    @observable locked = false;
    @observable sort: SortOption = 'auto';
    @observable sortDirection: 'ASC' | 'DESC' = 'DESC';
    @observable sortHeading = '';
    @observable requestSort: true;
    @observable percentChangeFormat: DecimalFormat = '1k';
    @observable metricTimeRoundSeconds = false;

    @observable fontFamilySecondary: string = null;
    @observable fontFamilyPrimary: string = null;
    @observable fontSize: number = null;
    @observable colorFont: string = null;
    @observable showSocialColors = true;
    @observable metricThreshold: number = null;
    @observable showFullTimePeriod = false;
    @observable textFormat: PostTextFormat = '';

    @observable useThemeColor = false;
    @observable useThemeImage = false;
    @observable useThemeEmail = false;
    @observable themeEmailSize = 20;
    @observable themeImagePosition: string = null;
    @observable themeImageOpacity = 100;
    @observable themeImageCircleMask = true;
    @observable themeImageSize = 100;
    @observable barChartScale = 100;

    @observable showPostTextOverlayAsGradient = false;
    @observable classOverrides: ClassOverrides = {};
    @observable postVersion = 1;

    @observable zeroFill = false;
    @observable zeroKill = false;
    @observable cumulative = false;
    @observable autoSizeWords = false;

    @observable invertDataSet = false;
    @observable showAdsMetrics = false;
    @observable enabledSocialColorOverrides = false;

    @observable metricValueCountup: MetricValueCountup = {
        enabled: true,
        duration: 30,
    };

    @observable showAccountIcon = false;
    @observable includeQuoteTweets = false;

    @action
    setValues(opts?: any): this {
        const options = toJS(opts);
        const implValues = toJS(this);
        if (options) {
            const result: any = merge(implValues, options, { arrayMerge: overwriteMerge });
            return result;
        } else {
            return implValues;
        }
    }
}
