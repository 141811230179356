import { Mapper } from '../DataStore/DataStore';
import { GraphQLService } from '../GraphQLService/GraphQLService';
import { BaseRecordService } from 'utils/BaseRecordService/BaseRecordService';
import {
    ClientMutationId,
    GraphQLQueries,
    ModelName,
    MutationDescriptor,
    QueryDescriptor,
} from '../GraphQLQueries/GraphQLQueries';
import { StoryboardVersionFields } from 'models/Storyboard/StoryboardVersion';
import { SceneVersion } from 'models/Scene/SceneVersion';
import { PanelVersion } from 'models/Panel/PanelVersion';
import { IdentifiedModel } from '@sprinklr/stories/common/IdentifiedModel';
import { Theme, ChildThemeFragment, ThemeFragment } from 'models/Theme/Theme';
import { WidgetFragments } from 'models/Widget/Widget';
import {
    ContentOverridesSnippet,
    Storyboard,
    StoryboardVersion,
} from 'models/Storyboard/Storyboard';
import { PageNumber } from 'components/Storyboard/StoryboardHeaderFooter/PageNumber/types';
import { SingleTimePeriod } from 'models/TimePeriod/SingleTimePeriod';
import { DataSourceFragment, DataSources, DataSourcesSnippet } from 'models/DataSource/DataSource';
import { CommonDocFields } from 'models/CommonDoc/CommonDoc';
import { HeaderSnippet } from 'components/Storyboard/StoryboardHeaderFooter/Header/options';
import {
    HeaderModel,
    HeaderProps,
} from 'components/Storyboard/StoryboardHeaderFooter/Header/types';
import { PageNumberSnippet } from 'components/Storyboard/StoryboardHeaderFooter/PageNumber/options';

const MODELNAME: ModelName = 'storyboardVersion';

export class StoryboardVersionService extends BaseRecordService<StoryboardVersion> {
    private queries: GraphQLQueries;

    constructor(graphQL: GraphQLService, mapper: Mapper<StoryboardVersion>) {
        super(graphQL, mapper);
        this.queries = new GraphQLQueries();
    }
    findForStoryboardLite(storyboardId: string): Promise<StoryboardVersion[]> {
        const queryDescriptor: QueryDescriptor = {
            name: 'clientStoryboardVersions',
            queryParams: {
                query: this.queries.storyboardVersionsLite,
                variables: { masterId: storyboardId },
            },
            model: MODELNAME,
            extractor: result => result.client.storyboardVersions,
        };

        return this.queryMany(queryDescriptor);
    }

    setScenes(
        storyboard: StoryboardVersion,
        scenes: (SceneVersion | string)[]
    ): Promise<StoryboardVersion> {
        return this.mutate(
            this.queries.mutationDescriptor('setStoryboardVersionSceneVersionIds', MODELNAME, {
                storyboardVersionId: storyboard.id,
                sceneVersionIds: this.ids(scenes),
            })
        );
    }

    setScenePanels(
        storyboard: StoryboardVersion | string,
        scene: SceneVersion | string,
        panels: (PanelVersion | string)[]
    ): Promise<StoryboardVersion> {
        return this.mutate(
            this.queries.mutationDescriptor(
                'setStoryboardVersionSceneVersionPanelVersionIds',
                MODELNAME,
                {
                    storyboardVersionId: this.id(storyboard),
                    sceneVersionId: this.id(scene),
                    panelVersionIds: this.ids(panels),
                }
            )
        );
    }

    // TODO: lift up
    id(ident: IdentifiedModel | string) {
        if (typeof ident === 'string') {
            return ident;
        }

        return (ident as IdentifiedModel).id;
    }

    removeScene(sbv: StoryboardVersion, scv: SceneVersion) {
        return this.mutate(
            this.queries.mutationDescriptor('removeStoryboardVersionSceneVersionId', MODELNAME, {
                storyboardVersionId: sbv.id,
                sceneVersionId: scv.id,
            })
        );
    }

    /**
     * Sets the StoryboardVersion.sceneDuration property, which also clears the SceneVersion.duration property on all
     * connected SceneVersion records.
     *
     * @param {StoryboardVersion} storyboard
     * @param {number} duration
     * @returns {Promise<StoryboardVersion>}
     */
    setSceneDuration(storyboard: StoryboardVersion, duration: number): Promise<StoryboardVersion> {
        return this.mutate(
            this.queries.mutationDescriptor('setStoryboardVersionSceneDuration', MODELNAME, {
                storyboardVersionId: storyboard.id,
                sceneDuration: duration,
            })
        );
    }

    /**
     * Sets the SceneVersion.duration property on a single SceneVersion record.
     *
     * @param {StoryboardVersion} storyboard
     * @param {SceneVersion} scene
     * @param {number} duration
     * @returns {Promise<StoryboardVersion>}
     */
    setSceneVersionDuration(
        storyboard: StoryboardVersion,
        scene: SceneVersion,
        duration: number
    ): Promise<StoryboardVersion> {
        return this.mutate(
            this.queries.mutationDescriptor('setStoryboardVersionSceneVersionDuration', MODELNAME, {
                storyboardVersionId: storyboard.id,
                sceneVersionId: scene.id,
                duration,
            })
        );
    }

    /**
     * Sets the SceneVersion.disabled property on a single SceneVersion record.
     *
     * @param {StoryboardVersion} storyboard
     * @param {SceneVersion} scene
     * @param {boolean} disabled
     * @returns {Promise<StoryboardVersion>}
     */
    setSceneVersionDisabled(
        storyboard: StoryboardVersion,
        scene: SceneVersion,
        disabled: boolean
    ): Promise<StoryboardVersion> {
        return this.mutate(
            this.queries.mutationDescriptor('setStoryboardVersionSceneVersionDisabled', MODELNAME, {
                storyboardVersionId: storyboard.id,
                sceneVersionId: scene.id,
                disabled,
            })
        );
    }

    /**
     * Note this changes the storyboardVersion.versionName, not storyboardVersion.name.
     *
     * @param {StoryboardVersion} storyboard
     * @param {string} versionName
     * @returns {Promise<StoryboardVersion>}
     */
    rename(storyboard: StoryboardVersion, versionName: string): Promise<StoryboardVersion> {
        return this.mutate(
            this.queries.mutationDescriptor('renameStoryboardVersion', MODELNAME, {
                storyboardVersionId: storyboard.id,
                versionName,
            })
        );
    }

    unAssignPanel(
        storyboard: StoryboardVersion,
        sceneVersion: SceneVersion,
        panel: PanelVersion
    ): Promise<StoryboardVersion> {
        return this.mutate(
            this.queries.mutationDescriptor(
                'replaceStoryboardVersionPanelVersionWithEmpty',
                MODELNAME,
                {
                    storyboardVersionId: storyboard.id,
                    sceneVersionId: sceneVersion.id,
                    panelVersionId: panel.id,
                }
            )
        );
    }

    createScene(storyboard: StoryboardVersion): Promise<StoryboardVersion> {
        return this.mutate(
            this.queries.mutationDescriptor('addStoryboardVersionSceneVersion', MODELNAME, {
                storyboardVersionId: storyboard.id,
            })
        );
    }

    cloneScene(
        storyboard: StoryboardVersion,
        scene: SceneVersion,
        name?: string
    ): Promise<StoryboardVersion> {
        return this.mutate(
            this.queries.mutationDescriptor('cloneSceneVersion', MODELNAME, {
                storyboardVersionId: storyboard.id,
                sceneVersionId: scene.id,
                name,
            })
        );
    }

    setTheme(
        storyboardVersion: StoryboardVersion | string,
        theme: Theme
    ): Promise<StoryboardVersion> {
        const mutation: MutationDescriptor = {
            queryParams: {
                query: `
                    mutation ($input: SetStoryboardVersionThemeInput!) {
                        setStoryboardVersionTheme(input: $input) {
                            storyboardVersion {
                                ${StoryboardVersionFields}
                            }
                            ${ClientMutationId}
                        }
                    }
                    ${WidgetFragments}
                    ${ChildThemeFragment}
                `,
                variables: {
                    input: {
                        storyboardVersionId: this.id(storyboardVersion),
                        theme,
                    },
                },
            },
            extractor: result => result.setStoryboardVersionTheme.storyboardVersion,
        };

        return this.mutate(mutation);
    }

    setImagesEnabled(
        storyboardVersion: StoryboardVersion | string,
        imagesEnabled: boolean
    ): Promise<StoryboardVersion> {
        const mutation: MutationDescriptor = {
            queryParams: {
                query: `
                    mutation ($input: SetStoryboardVersionImagesEnabledInput!) {
                        setStoryboardVersionImagesEnabled(input: $input) {
                            storyboardVersion {
                                ${CommonDocFields}
                                imagesEnabled
                            }
                            ${ClientMutationId}
                        }
                    }
                `,
                variables: {
                    input: {
                        storyboardVersionId: this.id(storyboardVersion),
                        imagesEnabled,
                    },
                },
            },
            extractor: result => result.setStoryboardVersionImagesEnabled.storyboardVersion,
        };

        return this.mutate(mutation);
    }

    setVideosEnabled(
        storyboardVersion: StoryboardVersion | string,
        videosEnabled: boolean
    ): Promise<StoryboardVersion> {
        const mutation: MutationDescriptor = {
            queryParams: {
                query: `
                    mutation ($input: SetStoryboardVersionVideosEnabledInput!) {
                        setStoryboardVersionVideosEnabled(input: $input) {
                            storyboardVersion {
                                ${CommonDocFields}
                                videosEnabled
                            }
                            ${ClientMutationId}
                        }
                    }
                `,
                variables: {
                    input: {
                        storyboardVersionId: this.id(storyboardVersion),
                        videosEnabled,
                    },
                },
            },
            extractor: result => result.setStoryboardVersionVideosEnabled.storyboardVersion,
        };

        return this.mutate(mutation);
    }

    /**
     * Does not include updated records in the query, meaning it's not going to result in a mobx update on any
     * observables. It is up to the caller to synchronize in-memory records. Return value is the raw response, from
     * which the clientMutationId can be obtained.
     *
     * @param {StoryboardVersion} storyboardVersion
     * @param {Theme} theme
     * @returns {Promise<StoryboardVersion>}
     */
    setThemeOptimistic(storyboardVersion: StoryboardVersion, theme: Theme): Promise<any> {
        const mutation: MutationDescriptor = {
            queryParams: {
                query: `
                    mutation ($input: SetStoryboardVersionThemeInput!) {
                        setStoryboardVersionTheme(input: $input) {
                            ${ClientMutationId}
                        }
                    }
                `,
                variables: {
                    input: {
                        storyboardVersionId: this.id(storyboardVersion),
                        theme,
                    },
                },
            },
            extractor: result => result,
        };

        return this.mutate(mutation);
    }

    setSceneTheme(
        storyboard: StoryboardVersion | string,
        scene: SceneVersion | string,
        theme: Theme
    ): Promise<StoryboardVersion> {
        const mutation: MutationDescriptor = {
            queryParams: {
                query: `
                    mutation ($input: SetStoryboardVersionSceneVersionThemeInput!) {
                        setStoryboardVersionSceneVersionTheme(input: $input) {
                            storyboardVersion {
                                ${StoryboardVersionFields}
                            }
                            ${ClientMutationId}
                        }
                    }
                    ${WidgetFragments}
                    ${ChildThemeFragment}
                `,
                variables: {
                    input: {
                        storyboardVersionId: this.id(storyboard),
                        sceneVersionId: this.id(scene),
                        theme,
                    },
                },
            },
            extractor: result => result.setStoryboardVersionSceneVersionTheme.storyboardVersion,
        };

        return this.mutate(mutation);
    }

    /**
     * Does not include updated records in the query, meaning it's not going to result in a mobx update on any
     * observables. It is up to the caller to synchronize in-memory records. Return value is the raw response, from
     * which the clientMutationId can be obtained.
     *
     * @param {StoryboardVersion | string} storyboard
     * @param {SceneVersion | string} scene
     * @param {Theme} theme
     * @returns {Promise<any>}
     */
    setSceneThemeOptimistic(
        storyboard: StoryboardVersion | string,
        scene: SceneVersion | string,
        theme: Theme
    ): Promise<any> {
        const mutation: MutationDescriptor = {
            queryParams: {
                query: `
                    mutation ($input: SetStoryboardVersionSceneVersionThemeInput!) {
                        setStoryboardVersionSceneVersionTheme(input: $input) {
                            ${ClientMutationId}
                        }
                    }
                `,
                variables: {
                    input: {
                        storyboardVersionId: this.id(storyboard),
                        sceneVersionId: this.id(scene),
                        theme,
                    },
                },
            },
            extractor: result => result,
        };

        return this.mutate(mutation);
    }

    deepThemeReset(storyboard: StoryboardVersion | string): Promise<StoryboardVersion> {
        return this.mutate(
            this.queries.mutationDescriptor('storyboardVersionDeepThemeReset', MODELNAME, {
                storyboardVersionId: this.id(storyboard),
            })
        );
    }

    updateStoryboardPageNumber(
        storyboardVersionId: string,
        pageNumber: PageNumber
    ): Promise<Storyboard> {
        const mutation: MutationDescriptor = {
            queryParams: {
                query: `
                    mutation ($input: UpdateStoryboardVersionPageNumberInput!) {
                        updateStoryboardVersionPageNumber(input: $input) {
                            storyboardVersion {
                                ${StoryboardVersionFields}
                                ${PageNumberSnippet}
                            }
                            ${ClientMutationId}
                        }
                    }
                ${WidgetFragments}
                ${ThemeFragment}
                `,
                variables: {
                    input: {
                        storyboardVersionId,
                        pageNumber,
                    },
                },
            },
            extractor: result => result.updateStoryboardVersionPageNumber.storyboardVersion,
        };

        return this.mutate(mutation);
    }

    updateScenePageNumber(
        storyboard: StoryboardVersion | string,
        scene: SceneVersion | string,
        pageNumber: PageNumber
    ): Promise<StoryboardVersion> {
        const mutation: MutationDescriptor = {
            queryParams: {
                query: `
                    mutation ($input: UpdateStoryboardVersionScenePageNumberInput!) {
                        updateStoryboardVersionScenePageNumber(input: $input) {
                            storyboardVersion {
                                ${StoryboardVersionFields}
                            }
                            ${ClientMutationId}
                        }
                    }
                    ${WidgetFragments}
                    ${ChildThemeFragment}
                `,
                variables: {
                    input: {
                        storyboardVersionId: this.id(storyboard),
                        sceneVersionId: this.id(scene),
                        pageNumber,
                    },
                },
            },
            extractor: result => result.updateStoryboardVersionScenePageNumber.storyboardVersion,
        };

        return this.mutate(mutation);
    }

    updateStoryboardHeader(storyboardVersionId: string, header: HeaderModel): Promise<Storyboard> {
        const mutation: MutationDescriptor = {
            queryParams: {
                query: `
                    mutation ($input: UpdateStoryboardVersionHeaderInput!) {
                        updateStoryboardVersionHeader(input: $input) {
                            storyboardVersion {
                                ${StoryboardVersionFields}
                                ${HeaderSnippet}
                            }
                            ${ClientMutationId}
                        }
                    }
                ${WidgetFragments}
                ${ThemeFragment}
                `,
                variables: {
                    input: {
                        storyboardVersionId,
                        header,
                    },
                },
            },
            extractor: result => result.updateStoryboardVersionHeader.storyboardVersion,
        };

        return this.mutate(mutation);
    }

    updateSceneHeader(
        storyboard: StoryboardVersion | string,
        scene: SceneVersion | string,
        header: HeaderProps
    ): Promise<StoryboardVersion> {
        const mutation: MutationDescriptor = {
            queryParams: {
                query: `
                    mutation ($input: UpdateStoryboardVersionSceneHeaderInput!) {
                        updateStoryboardVersionSceneHeader(input: $input) {
                            storyboardVersion {
                                ${StoryboardVersionFields}
                            }
                            ${ClientMutationId}
                        }
                    }
                    ${WidgetFragments}
                    ${ChildThemeFragment}
                `,
                variables: {
                    input: {
                        storyboardVersionId: this.id(storyboard),
                        sceneVersionId: this.id(scene),
                        header,
                    },
                },
            },
            extractor: result => result.updateStoryboardVersionSceneHeader.storyboardVersion,
        };

        return this.mutate(mutation);
    }

    setTimePeriod(
        storyboard: StoryboardVersion | string,
        timePeriod: SingleTimePeriod
    ): Promise<StoryboardVersion> {
        const mutation: MutationDescriptor = {
            queryParams: {
                query: `
                    mutation ($input: StoryboardVersionSetTimePeriodInput!) {
                        storyboardVersionSetTimePeriod(input: $input) {
                            storyboardVersion {
                                ${StoryboardVersionFields}
                            }
                            ${ClientMutationId}
                        }
                    }
                    ${WidgetFragments}
                    ${ThemeFragment}
                `,
                variables: {
                    input: {
                        storyboardVersionId: this.id(storyboard),
                        timePeriod,
                    },
                },
            },
            extractor: result => result.storyboardVersionSetTimePeriod.storyboardVersion,
        };

        return this.mutate(mutation);
    }

    setDataSources(
        storyboard: StoryboardVersion | string,
        dataSources: DataSources
    ): Promise<StoryboardVersion> {
        return this.mutate({
            queryParams: {
                query: `
                    mutation ($input: StoryboardVersionSetDataSourcesInput!) {
                        storyboardVersionSetDataSources(input: $input) {
                            storyboardVersion {
                                ${CommonDocFields}
                                ${DataSourcesSnippet}
                                ${ContentOverridesSnippet}
                                treeDirty
                            }
                            ${ClientMutationId}
                        }
                    }
                ${DataSourceFragment}
                `,
                variables: {
                    input: {
                        storyboardVersionId: this.id(storyboard),
                        dataSources,
                    },
                },
            },
            extractor: result => result.storyboardVersionSetDataSources.storyboardVersion,
        });
    }
}
