import { GroupBy, Metric, PostSource, Sort } from './PanelTemplatesTheme/PanelTemplatesTheme';
import { paddedDefaultPosition } from './PanelTemplatesTheme/constants';
import { Panel } from '../../models/Panel/Panel';
import { postCompositionConfigPostCard } from '../../components/Panel/PanelEditorSidebar/PanelDesign/PanelDesignOptions';
import { WIDGET_LABEL_SIZE } from '../../components/Widget/WidgetLabel/constants';

export var TemplatePostCard: Panel = {
    id: 'post-card',
    name: 'Post Card',
    description: '',
    tags: ['Content', 'Posts', 'Card', 'landscape', 'portrait'],
    widget: {
        id: '1',
        position: {
            width: '1920px',
            height: '1080px',
            top: '0',
            left: '0',
        },
        classes: 'template_post_card',
        children: [
            {
                type: 'postCard',
                id: '1',
                name: 'Post Card',
                useGlobalTimePeriod: true,
                postRequests: [
                    {
                        sources: [
                            {
                                type: 'posts',
                                // Note: All global variables like reportingEngine, report, and filters
                                // are injected via PostRequest.getRequests()
                                id: new PostSource({
                                    pageSize: 60,
                                    projections: [
                                        new Metric({
                                            LISTENING: {
                                                dimensionName: 'REACH_COUNT',
                                                heading: 'Reach',
                                            },
                                            INBOUND_MESSAGE: {
                                                dimensionName: 'netLikes',
                                                heading: 'Overall_Likes',
                                            },
                                            PLATFORM: {
                                                dimensionName: 'TOTAL_ENGAGEMENT',
                                                heading: 'Total Engagements',
                                            },
                                            BENCHMARKING: {
                                                dimensionName: 'totalReach',
                                                heading: 'Estimated_Post_Reach',
                                            },
                                            CAMPAIGN: {
                                                dimensionName: 'CAMPAIGN_ID',
                                                heading: 'Campaign',
                                            },
                                            PAID: { dimensionName: 'spent', heading: 'Spent' },
                                            RDB_FIREHOSE: {
                                                dimensionName: 'REACH_COUNT',
                                                heading: 'Reach',
                                            },
                                            STORY_MESSAGE: {
                                                dimensionName: 'OVERALL_REACH',
                                                heading: 'Overall_Reach',
                                            },
                                        }),
                                        new Metric({
                                            LISTENING: { dimensionName: 'INFLUENCER_SCORE' },
                                            INBOUND_MESSAGE: {
                                                dimensionName: 'COMMENT_COUNT',
                                                heading: 'Comment_Count',
                                            },
                                            PLATFORM: {
                                                dimensionName: 'POST_REACH_COUNT',
                                                heading: 'Post Reach',
                                            },
                                            BENCHMARKING: {
                                                dimensionName: 'TOTAL_ENGAGEMENT',
                                                heading: 'Total_Engagements',
                                            },
                                            CAMPAIGN: {
                                                dimensionName: 'OUTBOUND_STATUS',
                                                heading: 'Status',
                                            },
                                            PAID: { dimensionName: 'clicks', heading: 'Clicks' },
                                            RDB_FIREHOSE: {
                                                dimensionName: 'MENTIONS_COUNT',
                                                heading: 'Mentions',
                                            },
                                            STORY_MESSAGE: {
                                                dimensionName: 'IMPACT',
                                                heading: 'Web_Impact',
                                                aggregateFunction: 'MAX',
                                            },
                                        }),
                                    ],
                                    groupBys: [
                                        new GroupBy({
                                            LISTENING: { dimensionName: 'SEM_SENTIMENT' },
                                            INBOUND_MESSAGE: { dimensionName: 'SENTIMENT' },
                                            PLATFORM: { dimensionName: 'CAMPAIGN_ID' },
                                            BENCHMARKING: { dimensionName: 'BRAND_ID' },
                                            CAMPAIGN: { dimensionName: 'USER_ID' },
                                            PAID: { dimensionName: 'paidInitiativeId' },
                                            RDB_FIREHOSE: { dimensionName: 'ES_MESSAGE_ID' },
                                            STORY_MESSAGE: { dimensionName: 'SENTIMENT' },
                                        }),
                                    ],
                                    sorts: [
                                        new Sort({
                                            order: 'DESC',
                                            heading: {
                                                MONITORING_DASHBOARD: 'REACH_COUNT',
                                                LISTENING: 'REACH_COUNT',
                                                INBOUND_MESSAGE: 'INBOUND_COUNT',
                                                PLATFORM: 'TOTAL_ENGAGEMENT',
                                                BENCHMARKING: 'TOTAL_ENGAGEMENT',
                                                CAMPAIGN: 'SCHEDULE_DATE',
                                                RDB_FIREHOSE: 'SN_CREATED_TIME',
                                                STORY_MESSAGE: 'REACH',
                                            },
                                        }),
                                    ],
                                    timePeriod: {
                                        key: 'last_7_days',
                                    },
                                }) as any,
                                options: {
                                    count: 200,
                                    ordered: true,
                                    imagePreload: true,
                                    removeDuplicates: true,
                                    shufflePosts: false,
                                    removeSensitivePosts: true,
                                    includeQuoteTweets: false,
                                },
                            },
                        ],
                        format: {
                            type: 'buckets',
                            options: {
                                padding: 1,
                                buckets: {
                                    any: { filter: 'post.type' },
                                },
                                panels: [{ total: 3, buckets: ['any', -1] }],
                            },
                        },
                    },
                ],
                position: paddedDefaultPosition,
                options: Object.assign(
                    {
                        player: {
                            playing: true,
                            duration: 6,
                        },
                        postTemplate: 'card',
                        smartSize: {
                            enabled: true,
                            mode: 'responsive',
                            size: 'xl',
                        },
                        portrait: {
                            widgetOrientation: 'portrait',
                        },
                        postVersion: 2,
                    },
                    postCompositionConfigPostCard.a
                ),
                label: {
                    titleText: 'Post Card',
                    enabled: false,
                    size: WIDGET_LABEL_SIZE.primary,
                },
            },
        ],
        theme: {
            ext: {
                padding: 0,
            },
        },
    },
};
