import { observable } from 'mobx';
import { CurrencyFormat } from '@sprinklr/stories/widget/types';
import { NumberType } from 'components/Widget/WidgetComponent/types';
import { FieldType } from '@sprinklr/stories/reporting/types';

export type MetricNamePosition = 'top' | 'bottom';

export type GridLayout = {
    gap: number;
    columns: number;
    stretchColumnsToFit?: boolean;
};

export class GridLayoutImpl implements GridLayout {
    @observable gap = 20;
    @observable columns = 3;
    @observable stretchColumnsToFit = true;
}

export type GridCell = {
    padding: number;
    background: string;
    border: {
        enabled: boolean;
        color: string;
        size: number;
    };
};

export type MetricExtended = {
    metricName: any;
    currencyFormat: CurrencyFormat;
    numberType: NumberType;
    type: FieldType;
    metricValue: any;
    metricLabel: string;
    percentChange: any;
    previousValue: any;
};
