import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { observable, action } from 'mobx';
import VersionService from '../../services/VersionService/VersionService';

class AppVersion extends React.Component<{ versionService: VersionService }, any> {
    @observable version;

    componentDidMount() {
        this.props.versionService.getVersion().then(action(version => (this.version = version)));
    }

    render() {
        const style = { background: '#14141d', height: '100vh' };

        if (!this.version) {
            return <div style={style}></div>;
        }

        return <div style={style}>Version: {this.version.version}</div>;
    }
}

export default inject('versionService')(observer(AppVersion));
