import * as React from 'react';
import i18n from 'i18next';
import {
    headerTunables,
    opacityTunables,
    smartSizeTunables,
} from 'components/Sidebar/RenderTunables/SharedTunables';
import { CollapseProps, TunablesCollapse } from 'components/Sidebar/RenderTunables/RenderTunables';
import { Widget } from '@sprinklr/stories/widget/Widget';
import ActionSliderGroup from '../../components/_UI/Forms/Actions/ActionSliderGroup';
import ActionSelectGroup from '../../components/_UI/Forms/Actions/ActionSelectGroup';
import {
    scaleByOptions,
    timeFormatOptions,
} from 'components/Panel/PanelEditorSidebar/PanelDesign/PanelDesignOptions';
import { HeatMapTableChartWidgetOptionsImpl } from './options';

export const HeatMapTableChartTunables = (widget: Widget): TunablesCollapse[] => [
    {
        props: {
            label: i18n.t('Heat Map'),
            startOpen: true,
        } as CollapseProps,
        children: [...headerTunables(widget), opacityTunables(widget), smartSizeTunables(widget)],
    },
    {
        props: {
            label: i18n.t('X Axis'),
            startOpen: true,
            isEnabled: (widget.options as HeatMapTableChartWidgetOptionsImpl).xAxis.enabled,
            option: (widget.options as HeatMapTableChartWidgetOptionsImpl).xAxis,
            value: 'enabled',
        } as CollapseProps,
        children: [
            <ActionSliderGroup
                key='widget.options.xAxis'
                label={i18n.t('X Axis Size')}
                option={(widget.options as HeatMapTableChartWidgetOptionsImpl).xAxis.label}
                value='size'
                showValue
                min={0}
                max={50}
            />,
        ],
    },
    {
        props: {
            label: i18n.t('Legend'),
            startOpen: true,
            isEnabled: widget.options.showLegend,
            option: widget.options,
            value: 'showLegend',
        } as CollapseProps,
        children: [
            <ActionSliderGroup
                key='widget.options.legendSize'
                label={i18n.t('Legend Size')}
                option={widget.options}
                value='legendSize'
                showValue
                min={0}
                max={50}
            />,
        ],
    },
    {
        props: {
            label: i18n.t('Tunables'),
            startOpen: true,
        } as CollapseProps,
        children: [
            widget.options.subType === 'orbs' && (
                <ActionSliderGroup
                    key='widget.options.orbSize'
                    label={i18n.t('Orb Size')}
                    option={widget.options}
                    value='orbSize'
                    showValue
                    min={0}
                    max={100}
                />
            ),
            widget.options.subType === 'orbs' && (
                <ActionSelectGroup
                    label={i18n.t('Scale By')}
                    key='widget.options.scaleBy'
                    option={widget.options}
                    value='scaleBy'
                    options={scaleByOptions}
                />
            ),
            <ActionSelectGroup
                label={i18n.t('Axis Format')}
                key='widget.options.timeFormat'
                option={widget.options}
                value='timeFormat'
                options={timeFormatOptions}
            />,
        ],
    },
];

export default HeatMapTableChartTunables;
