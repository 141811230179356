import * as React from 'react';
import i18n from 'i18next';
import {
    blackListTunables,
    headerTunables,
    labelTunables,
    metricValueTunables,
    opacityTunables,
    smartSizeTunables,
    textFormatTunables,
    themeTunables,
    zoomTunables,
} from 'components/Sidebar/RenderTunables/SharedTunables';
import { CollapseProps, TunablesCollapse } from 'components/Sidebar/RenderTunables/RenderTunables';
import { Widget } from '@sprinklr/stories/widget/Widget';
import {
    labelFormatOptions,
    metricValueOptions,
} from 'components/Panel/PanelEditorSidebar/PanelDesign/PanelDesignOptions';
import ActionSegmentedGroup from 'components/_UI/Forms/Actions/ActionSegmentedGroup';
import ActionCheckboxSliderGroup from 'components/_UI/Forms/Actions/ActionCheckboxSliderGroup';
import ActionSliderGroup from 'components/_UI/Forms/Actions/ActionSliderGroup';
import ActionCheckboxGroup from 'components/_UI/Forms/Actions/ActionCheckboxGroup';

export const BubbleChartTunables = (widget: Widget): TunablesCollapse[] => [
    {
        props: {
            label: i18n.t('Bubble Chart'),
            startOpen: true,
        } as CollapseProps,
        children: [...headerTunables(widget), opacityTunables(widget), smartSizeTunables(widget)],
    },
    {
        props: {
            label: i18n.t('Labels'),
            startOpen: true,
        } as CollapseProps,
        children: [
            labelTunables(widget),
            ...textFormatTunables(widget, { fit: true, clamp: true, wrap: true }),
            ...metricValueTunables(widget, {
                value: { enable: true, size: true },
                format: true,
                name: true,
                change: true,
            }),
        ],
    },
    {
        props: {
            label: i18n.t('Child Labels'),
            startOpen: true,
        } as CollapseProps,
        children: [
            <ActionSegmentedGroup
                key='widget.options.childLabelTextFormat'
                label={i18n.t('Text Format')}
                option={widget.options}
                value={'childLabelTextFormat'}
                options={labelFormatOptions}
            />,
            widget.options.labeltextFormat === 'clamp' && (
                <>
                    <ActionCheckboxSliderGroup
                        key='widget.options.childLabelLineClamp'
                        label={i18n.t('Line Clamp')}
                        showValue
                        checkbox={{
                            option: widget.options,
                            value: 'childLabelLineClamp',
                        }}
                        slider={{
                            option: widget.options,
                            value: 'labechildLabelLineClampValuelLineClampValue',
                            min: 0,
                            max: 10,
                        }}
                    />
                    <ActionSliderGroup
                        key='widget.options.childLabelSize'
                        label={i18n.t('Label Size')}
                        option={widget.options}
                        value='childLabelSize'
                        showValue
                        min={0}
                        max={100}
                    />
                </>
            ),
            <ActionCheckboxGroup
                key='widget.options.childLabelWrapText'
                label={i18n.t('Wrap Label Characters')}
                option={widget.options}
                value={'childLabelWrapText'}
            />,
            <ActionCheckboxSliderGroup
                key='widget.options.showChildMetricValue'
                label={i18n.t('Metric Value')}
                showValue
                checkbox={{
                    option: widget.options,
                    value: 'showChildMetricValue',
                }}
                slider={{
                    option: widget.options,
                    value: 'childMetricValueSize',
                    min: 0,
                    max: 100,
                }}
            />,
            <ActionSegmentedGroup
                key='widget.options.childMetricValueFormat'
                label={i18n.t('Format')}
                option={widget.options}
                value='childMetricValueFormat'
                options={metricValueOptions}
                showOptionLabel
            />,
            <ActionCheckboxSliderGroup
                key='widget.options.showChildMetricName'
                label={i18n.t('Metric Name')}
                showValue
                checkbox={{
                    option: widget.options,
                    value: 'showChildMetricName',
                }}
                slider={{
                    option: widget.options,
                    value: 'childMetricNameSize',
                    min: 0,
                    max: 50,
                }}
            />,
            <ActionSliderGroup
                key='widget.options.childOpacity'
                label={i18n.t('Child Opacity')}
                option={widget.options}
                value='childOpacity'
                showValue
                min={10}
                max={100}
            />,
            <ActionSliderGroup
                key='widget.options.childVibrance'
                label={i18n.t('Vibrance')}
                option={widget.options}
                value='childVibrance'
                showValue
                min={0}
                max={100}
            />,
            <ActionSliderGroup
                key='widget.options.childContrast'
                label={i18n.t('Contrast')}
                option={widget.options}
                value='childContrast'
                showValue
                min={0}
                max={100}
            />,
        ],
    },
    zoomTunables(widget, { zoom: true }),
    themeTunables(widget, { color: true, image: true, email: true }),
    {
        props: {
            label: i18n.t('Tunables'),
            startOpen: false,
        } as CollapseProps,
        children: [blackListTunables(widget)],
    },
];

export default BubbleChartTunables;
