// Simple signal implementation to enable cross-component notifications
//
// See for background: http://millermedeiros.github.io/js-signal/

export type SignalCallback = (type: string, ...args: any[]) => void;

export default class SignalService {
    public signals: { [type: string]: SignalCallback[] } = {};

    add(type: string, callback: SignalCallback): SignalCallback {
        let found = this.signals[type];
        if (!found) {
            found = this.signals[type] = [];
        }

        const foundCallback = found.indexOf(callback);
        if (foundCallback === -1) {
            found.push(callback);
        }

        return callback;
    }

    addAll(types: string[], callback: SignalCallback): void {
        let x = types.length;
        while (x--) {
            this.add(types[x], callback);
        }
    }

    remove(type: string, callback: SignalCallback): void {
        const found = this.signals[type];
        if (found) {
            const foundCallback = found.indexOf(callback);
            if (foundCallback !== -1) {
                found.splice(foundCallback, 1);
            }

            if (!found.length) {
                delete this.signals[type];
            }
        }
    }

    removeAll(types: string[], callback: SignalCallback): void {
        let x = types.length;
        while (x--) {
            this.remove(types[x], callback);
        }
    }

    dispatch(type: string, ...args: any[]): void {
        const found = this.signals[type];
        if (found) {
            args.unshift(type);

            let x = found.length;
            while (x--) {
                if (found[x]) {
                    found[x].apply(this, args);
                }
            }
        }
    }
}
