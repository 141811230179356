import { PartnerLimit } from '../../models/PartnerLimit/PartnerLimit';
import { BaseRecordService } from '../../utils/BaseRecordService/BaseRecordService';
import { GraphQLQueries, ModelName } from '../GraphQLQueries/GraphQLQueries';
import { GraphQLService } from '../GraphQLService/GraphQLService';
import { Mapper } from '../DataStore/DataStore';

const MODELNAME: ModelName = 'partnerLimit';

export class PartnerLimitService extends BaseRecordService<PartnerLimit> {
    private queries: GraphQLQueries;

    constructor(graphQL: GraphQLService, mapper: Mapper<PartnerLimit>) {
        super(graphQL, mapper);
        this.queries = new GraphQLQueries();
    }

    find(): Promise<PartnerLimit> {
        return this.query(this.queries.queryDescriptor('partnerLimit', MODELNAME, {}));
    }

    findAll(): Promise<PartnerLimit[]> {
        return;
    }
}
