import { AnalyticsRequest, Metric, GroupBy, Sort } from './PanelTemplatesTheme/PanelTemplatesTheme';
import { Panel } from '../../models/Panel/Panel';
import { WIDGET_LABEL_SIZE } from '../../components/Widget/WidgetLabel/constants';
import ListeningDimensions from '@sprinklr/stories-services/AnalyticsService/ListeningDimensions';
import ListeningMetrics from '@sprinklr/stories-services/AnalyticsService/ListeningMetrics';

export var TemplateTopMetrics: Panel = {
    id: 'top-metrics',
    name: 'Table',
    tags: ['Standard', 'User Metrics', 'Channel', 'Account', 'Brand', 'landscape', 'portrait'],
    description: '',
    widget: {
        position: {
            width: '1920px',
            height: '1080px',
            top: '0',
            left: '0',
        },
        classes: 'template_top_metrics',
        children: [
            {
                type: 'rankedTable',
                id: '0',
                name: 'Table',
                useGlobalTimePeriod: true,
                analyticsRequests: [
                    // Note: All global variables like reportingEngine, report, filters, and projections
                    // are injected via AnalyticsRequest.getRequests()
                    new AnalyticsRequest({
                        limit: 3,
                        projections: [
                            new Metric({
                                LISTENING: { source: ListeningMetrics.distinctUsers },
                                INBOUND_MESSAGE: { dimensionName: 'INBOUND_COUNT' },
                                PLATFORM: { dimensionName: 'TOTAL_ENGAGEMENT' },
                                BENCHMARKING: { dimensionName: 'TOTAL_ENGAGEMENT' },
                                PAID: { dimensionName: 'spent' },
                                ADVOCACY: {
                                    dimensionName: 'TOTAL_POINTS_EARNED',
                                    heading: 'Total_Points_Earned',
                                    report: 'COMMUNITY_USER',
                                },
                                TWITTER: { dimensionName: 'MENTIONS' },
                                RDB_FIREHOSE: {
                                    dimensionName: 'DISTINCT_USER_COUNT',
                                    heading: 'Distinct Users',
                                },
                                STORY_MESSAGE: {
                                    dimensionName: 'WEB_REACH',
                                    heading: 'Web_Reach',
                                },
                            }),
                            new Metric({
                                LISTENING: { source: ListeningMetrics.mentions },
                                INBOUND_MESSAGE: { dimensionName: 'OUTBOUND_COUNT' },
                                PLATFORM: { dimensionName: 'ENGAGEMENT_RATE' },
                                BENCHMARKING: { dimensionName: 'ENGAGEMENT_RATE' },
                                PAID: { dimensionName: 'impressions' },
                                ADVOCACY: {
                                    dimensionName: 'ADVOCACY_TOTAL_USER_ENGAGEMENTS',
                                    heading: 'Total_Engagements',
                                    origReport: 'ADVOCACY_ACTIVITY',
                                },
                                RDB_FIREHOSE: {
                                    dimensionName: 'REACH_COUNT',
                                    heading: 'Reach',
                                },
                                STORY_MESSAGE: {
                                    dimensionName: 'IMPACT',
                                    heading: 'Web_Impact',
                                    aggregateFunction: 'AVG',
                                },
                            }),
                            new Metric({
                                LISTENING: { source: ListeningMetrics.reach },
                                INBOUND_MESSAGE: { dimensionName: 'LIKE_COUNT' },
                                PLATFORM: { dimensionName: 'POST_REACH_COUNT' },
                                BENCHMARKING: { dimensionName: 'PUBLISHED_MESSAGE_COUNT' },
                                PAID: { dimensionName: 'clicks' },
                                ADVOCACY: {
                                    dimensionName: 'ADVOCACY_TOTAL_USER_LOGIN',
                                    heading: 'Total_Logins',
                                    origReport: 'ADVOCACY_ACTIVITY',
                                },
                                RDB_FIREHOSE: {
                                    dimensionName: 'MENTIONS_COUNT',
                                    heading: 'Mentions',
                                },
                                STORY_MESSAGE: {
                                    dimensionName: 'WEB_SHARES_OVERALL',
                                    heading: 'Web_shares_overall',
                                },
                            }),
                        ],
                        groupBys: [
                            new GroupBy({
                                LISTENING: { source: ListeningDimensions.topicName },
                                INBOUND_MESSAGE: { dimensionName: 'ACCOUNT_ID' },
                                PLATFORM: { dimensionName: 'ACCOUNT_ID' },
                                BENCHMARKING: { dimensionName: 'BRAND_ID' }, //BENCHMARKING_ACCOUNT_ID
                                PAID: { dimensionName: 'accountIds' },
                                ADVOCACY: { dimensionName: 'COMMUNITY_USER_ID' },
                                TWITTER: { dimensionName: 'TRENDING_TOPIC' },
                                RDB_FIREHOSE: { dimensionName: 'RDB_QUERY_IDS' },
                                STORY_MESSAGE: { dimensionName: 'STORY_CATEGORY' },
                            }),
                        ],
                        sorts: [new Sort({ order: 'DESC', projections: 0 })],
                        timePeriods: [{ key: 'last_7_days' }, { previousPeriod: true }],
                    }) as any,
                ],
                position: {
                    width: '100%',
                    height: '100%',
                    top: '0',
                    left: '0',
                },
                label: {
                    titleText: 'Top Metrics',
                    size: WIDGET_LABEL_SIZE.primary,
                    enabled: true,
                },
                options: {
                    player: {
                        playing: true,
                        duration: 15,
                    },
                    labelSize: 25,
                    showMetricValue: true,
                    metricValueSize: 25,
                    showMetricName: false,
                    metricNameSize: 13,
                    alignment: {
                        rowName: 'left',
                        columnName: 'left',
                        metric: 'right',
                    },
                    showMetricChange: false,
                    metricPercentChangeSize: 16,
                    highlightPercentChange: false,
                    autoRowHeight: true,
                    legacyMode: false,
                },
            },
        ],
    },
};
