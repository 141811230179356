import Axios, { AxiosInstance, AxiosRequestConfig, CancelTokenSource } from 'axios';
import { AnalyticsEngine, AnalyticsTimePeriod } from '@sprinklr/stories/analytics/AnalyticsRequest';
import TimePeriod from 'models/TimePeriod/TimePeriod';
import { WidgetDimensionKey } from 'utils/Widget/WidgetDimensionKey';
import MetaInfoService from '../MetaInfoService/MetaInfoService';
import moment from 'moment';
import { Post } from '../../embed/types';
import TimePeriodService from 'services/TimePeriodService/TimePeriodService';
import { InstagramResponse } from 'models/Instagram/Instagram';
import { SpaceBatchRequest } from '@sprinklr/stories/reporting/types';
import { ReportingRequest, ReportingResponse } from '@sprinklr/stories/reporting/types';
import toReportingResponse from '@sprinklr/stories-convert/request/toReportingResponse';
import { ApplicationIdentifier } from '@sprinklr/display-builder/config';

export interface ReportingLookupPage {
    lookupField: string;
    hasMore: boolean;
    result: BulkLookupValue[];
}

export interface ReportingLookupFilter {
    field: string;
    filterType: 'IN';
    values: any[];
}

export interface BulkLookupValue {
    id: string;
    name?: string;
    displayName?: string;
    screenName?: string;
    accountType?: string;
    accountId?: number;
    clientId?: number;
    partnerId?: number;
    snType?: string;
    report?: string;
}

export interface BulkLookupValues {
    [x: string]: any;
}

export interface CustomFieldSearch {
    clientIds?: string[];
    fieldNames?: string[];
    categories?: string[];
    keyword?: string;
    isGlobal?: boolean;
    isEnabled?: boolean;
}

export interface CustomField {
    id: string;
    createdTime?: number;
    modifiedTime: number;
    fieldName: string;
    clientId: number;
    globalAsset: boolean;
    fieldType: string;
    name: string;
    description: string;
    helpText: string;
    options: string[];
    defaultValue;

    optionsLabels: string[];
    category;
    additional: any;

    facetEnabled: boolean;
    adhocSearchEnabled: boolean;
    enabled: boolean;

    contentReplacementEnabled: boolean;
    preferred: boolean;
    orderOptionsAlphabetically: boolean;

    required: boolean;
    isHidden: boolean;
    isHiddenFromMonitoring: boolean;

    order: number;
    optionKey: string;
}

export type Module = 'REPORTING' | 'LISTENING' | 'BENCHMARKING' | 'PAID';

export interface PromiseWithCancel<T> extends Promise<T> {
    cancel?();
}

interface CacheItem {
    key: string;
    payload: any;
    fetched: number;
}

type DynamicPropertyName = 'LST_THEME_DISPLAYNAME_ENABLED' | 'REPORTING_CUMULATIVE_SUM_ENABLED';

export default class SprinklrAPIService {
    private listeningMetadataPromises = {};

    constructor(
        private axios: AxiosInstance,
        private applicationIdentifier: ApplicationIdentifier,
        private authService?: { userInfo: { partnerId: number; clientId: number } },
    ) {}

    loadDynamicProperty(propName: DynamicPropertyName): Promise<any> {
        const requestConfig = {
            method: 'GET' as const,
            url: '/api/v3.1/util/dynamicproperty/' + propName + '/value',
        } as AxiosRequestConfig;

        return this.axios.request(requestConfig);
    }

    private createBulkLookupRequest(
        engine: AnalyticsEngine,
        report: string,
        type: WidgetDimensionKey,
        keys: string[],
        timePeriod?: TimePeriod
    ): any {
        keys = keys || [];

        if (keys.length === 0) {
            return null;
        }

        let dimensionName: string = type.name;

        if (dimensionName.indexOf('SPECIFIC_THEME_TAG_') === 0) {
            dimensionName = 'LST_THEME_DISPLAY_NAME';
        }

        if (
            dimensionName.indexOf('SPECIFIC_TOPIC_TAG_') === 0 ||
            dimensionName.indexOf('SPECIFIC_TOPIC_GROUP_') === 0
        ) {
            dimensionName = 'TOPIC_IDS';
        }

        // For keyword list tags, look up the keyword list itself
        if (dimensionName.indexOf('LST_KEYWORD_LIST_TAG_') === 0) {
            dimensionName = 'LST_KEYWORD_LIST';
        }

        if (dimensionName.indexOf('BENCHMARKING_KEYWORD_LIST_TAG_') === 0) {
            dimensionName = 'BENCHMARKING_KEYWORD_LIST';
        }

        const requestData = {
            lookupType: dimensionName,
            keys,
            extraParams: {
                displayName: dimensionName === 'TOPIC_IDS',
                engine,
                reportName: report,
                deleted: true,
                inaccessible: true,
                complete: true,
            } as any,
        };

        if (dimensionName === 'STATUS_ID') {
            requestData.extraParams.SEND_SECONDARY_POST = 'true'; // Has to be quoted to work
        }

        if (timePeriod) {
            const startTime: moment.Moment = timePeriod.startDate;
            const endTime: moment.Moment = timePeriod.endDate;

            requestData.extraParams.timeFilter = {};

            if (startTime) {
                requestData.extraParams.sinceTime = requestData.extraParams.timeFilter.sinceTime = startTime.valueOf();
            }
            if (endTime) {
                requestData.extraParams.untilTime = requestData.extraParams.timeFilter.untilTime = endTime.valueOf();
            }
        }

        // Add this if this is a custom field
        if (type.customName) {
            requestData.extraParams.fieldName = type.customName;
        }

        return requestData;
    }

    // Massage the bulk lookup results, if needed. Otherwise just return what was passed.
    private bulklookConvert(key: string, engine: string, data: any): any {
        switch (key) {
            // For SLA Frequency, we normalize to objects.
            case 'SLA_FREQUENCY':
                return MetaInfoService.normalizeSLAFrequencyResponse(data);

            case 'accountIds':
            case 'ACCOUNT_ID':
                return MetaInfoService.normalizeAccountIds(data);

            case 'LISTENING_MEDIA_TYPE':
            case 'REVIEW_SOURCE':
                return MetaInfoService.normalizeSourceIds(data);

            case 'COMMUNITY_USER_ID':
                return MetaInfoService.normalizeCommunityUsers(data);

            case 'BRAND_ID':
            case 'BENCHMARKING_KEYWORD_LIST':
                if (engine === 'BENCHMARKING') {
                    return MetaInfoService.normalizeBenchmarkingResponse(data);
                }
                break;

            case 'TIME_OF_DAY':
            case 'DAY_OF_WEEK':
            case 'MONTH_OF_YEAR':
                return MetaInfoService.normalizeTemporalResponse(data, key);
        }

        for (const id in data) {
            MetaInfoService.normalizeBulkItem(data[id]);
        }

        return data;
    }

    async bulkLookup(
        engine: AnalyticsEngine,
        report: string,
        type: WidgetDimensionKey,
        keys: string[],
        timePeriod?: TimePeriod,
        cancelSource?: CancelTokenSource
    ): Promise<BulkLookupValues> {
        const dimensionName: string = type.name;

        if (dimensionName.indexOf('SPECIFIC_THEME_TAG_') === 0) {
            return this.getListeningMetadata('themes', cancelSource);
            // dimensionName = 'LST_THEME_DISPLAY_NAME';
        }

        if (
            dimensionName === 'TOPIC_IDS' ||
            dimensionName.indexOf('SPECIFIC_TOPIC_TAG_') === 0 ||
            dimensionName.indexOf('SPECIFIC_TOPIC_GROUP_') === 0
        ) {
            return this.getListeningMetadata('topic', cancelSource);
        }

        const requestData = this.createBulkLookupRequest(engine, report, type, keys, timePeriod);
        if (!requestData) {
            return Promise.resolve({});
        }

        const requestConfig = {
            method: 'POST' as const,
            url: 'api/v3.1/reporting/insights/bulklookup',
            data: requestData,
            cancelToken: cancelSource ? cancelSource.token : null,
        };

        return this.axios.request(requestConfig).then(
            (response): BulkLookupValues => {
                let lookupDimensionName = dimensionName;
                if (dimensionName.startsWith('BENCHMARKING_KEYWORD_LIST_TAG_')) {
                    lookupDimensionName = 'BENCHMARKING_KEYWORD_LIST';
                }
                if (dimensionName.startsWith('LST_KEYWORD_LIST_TAG_')) {
                    lookupDimensionName = 'LST_KEYWORD_LIST';
                }
                if (!response.data) {
                    return {};
                }

                let result = {};
                // avoid relying on dimensionName for this because it's unpredictable
                // see the KEYWORD_LIST lines right above this for proof
                for (const key in response.data) {
                    if ('lookupValues' in response.data[key]) {
                        result = response.data[key].lookupValues;
                        break;
                    }
                }

                // Massage the results, if needed
                return this.bulklookConvert(lookupDimensionName, engine, result);
            }
        );
    }

    // Look up multiple dimensions in a single requset
    async bulkLookupMulti(
        engine: AnalyticsEngine,
        report: string,
        types: WidgetDimensionKey[],
        keys: string[][],
        timePeriod?: TimePeriod,
        cancelSource?: CancelTokenSource
    ): Promise<any> {
        if (types.length != keys.length) {
            return Promise.resolve({});
        }

        const dataList = [];
        for (let x = 0; x < types.length; x++) {
            const data = this.createBulkLookupRequest(
                engine,
                report,
                types[x],
                keys[x],
                timePeriod
            );
            if (data) {
                dataList.push(data);
            }
        }

        if (!dataList.length) {
            return Promise.resolve({});
        }

        const requestConfig = {
            method: 'POST' as const,
            url: 'api/v3.1/reporting/insights/bulklookup/list',
            data: dataList,
            cancelToken: cancelSource ? cancelSource.token : null,
        };

        return this.axios.request(requestConfig).then(
            (response): BulkLookupValues => {
                const result = response.data;

                Object.keys(result).forEach((dimensionName: any) => {
                    const data = result[dimensionName] ? result[dimensionName].lookupValues : {};

                    // Massage the results, if needed
                    result[dimensionName] = this.bulklookConvert(dimensionName, engine, data);
                });

                return result;
            }
        );
    }

    private partnerClientScopedCacheKey(unscopedKey: string): string | null {
        if (!unscopedKey || !this.authService || !this.authService.userInfo) {
            return null;
        }
        const { userInfo } = this.authService;

        return userInfo.partnerId && userInfo.clientId
            ? `${unscopedKey}?partnerId=${userInfo.partnerId}&clientId=${userInfo.clientId}`
            : null;
    }

    getListeningMetadata(type: string, cancelSource?: CancelTokenSource) {
        const requestConfig = {
            method: 'GET' as const,
            url: 'api/v3.1/listening/' + type + '/',
            cancelToken: cancelSource ? cancelSource.token : null,
        };

        const key = this.partnerClientScopedCacheKey(requestConfig.url);

        if (key && typeof sessionStorage !== 'undefined') {
            // console.log('requesting listening themes...');
            try {
                const item = sessionStorage.getItem(key);
                if (item) {
                    const cached: CacheItem = JSON.parse(item);
                    const cacheTTL = 15 * 60 * 1000;
                    // const cacheTTL = 15 * 60 * 1000;
                    if (
                        cached &&
                        cached.fetched &&
                        cached.fetched + cacheTTL > new Date().getTime()
                    ) {
                        // console.log("retrieving item ", key);
                        const normalizedResponse = MetaInfoService.normalizeThemeResponse(
                            cached.payload
                        );

                        return Promise.resolve(normalizedResponse);
                        // } else {
                        //     console.log("not using cached item, fetched", {
                        //         fetched: cached.fetched,
                        //         cacheTTL,
                        //         now: new Date().getTime(),
                        //         expire: cached.fetched + cacheTTL
                        //     });
                    }
                }
            } catch (error) {
                console.log('failed to get listening metadata from session storage');
            }
        }

        if (!this.listeningMetadataPromises[type]) {
            this.listeningMetadataPromises[type] = this.axios
                .request(requestConfig)
                .catch(reason => {
                    console.log(
                        `error getting listening metadata type ${type}, clearing promise`,
                        reason
                    );
                    this.listeningMetadataPromises[type] = null;
                });
        }

        return this.listeningMetadataPromises[type].then((response): any => {
            const normalizedResponse =
                response.data && MetaInfoService.normalizeThemeResponse(response.data);

            if (typeof sessionStorage === 'undefined') {
                return normalizedResponse;
            }

            try {
                // will get a DOMException if we go over quota
                if (key) {
                    const item: CacheItem = {
                        key,
                        payload: response.data,
                        fetched: new Date().getTime(),
                    };
                    // console.log("storing item ", key);
                    sessionStorage.setItem(key, JSON.stringify(item));
                }
            } catch (error) {
                console.log('could not save listening metadata in session storage', error);
            }

            return normalizedResponse;
        });
    }

    getPostComments(post: any, start: number, rows: number): any {
        if (post && post.sourceId && post.snType && post.snMsgId) {
            return this.axios
                .post('/api/v3.1/hierarchical/conversations/messages', {
                    conversationId: post.conversationId ? post.conversationId : post.snMsgId,
                    snMsgId: post.snMsgId,
                    snType: post.snType.toUpperCase(),
                    sourceType: 'ACCOUNT',
                    sourceId: post.sourceId,
                    messageType: post.messageType,
                    sortOrder: 'ASC',
                    rows,
                })
                .then(response => {
                    return response.data.data;
                });
        }
    }

    getTimePeriod(timePeriod: AnalyticsTimePeriod): TimePeriod {
        if (!timePeriod.previousPeriod && !timePeriod.key && !timePeriod.startTime) {
            throw new Error('Must provide valid time period key or startTime and endTime');
        }

        return TimePeriodService.createFromAnalyticsTimePeriod(timePeriod, {}, moment());
    }

    public query(
        requestData: ReportingRequest,
        cancelSource?: CancelTokenSource
    ): PromiseWithCancel<ReportingResponse> {
        if (!cancelSource) {
            cancelSource = Axios.CancelToken.source();
        }

        const maxPageSize = requestData.reportingEngine === 'RDB_FIREHOSE' ? 100 : 1000;
        const goal = requestData.pageSize || maxPageSize;

        const request: ReportingRequest = JSON.parse(JSON.stringify(requestData));

        if (!request.additional) {
            request.additional = {};
        }

        // This should usually be handled by WidgetData, but just in case we ensure it's present here.
        if (!request.additional?.originatingApp) {
            request.additional.originatingApp = this.applicationIdentifier;
        }

        // If the request contains filters that match groupbys, enforce client side.
        const valueMap = SprinklrAPIService.generateGroupByFilterValueMap(request);

        request.page = requestData.page || 0;
        request.pageSize = Math.min(goal, maxPageSize);

        let promise: any = this.axios.request({
            method: 'POST',
            url: 'api/v3.1/reports/v6/query',
            data: request,
            cancelToken: cancelSource.token,
        });

        let rows = [];
        let headings = [];

        const recursiveRequest = (
            response: any
        ): ReportingResponse | Promise<ReportingResponse> => {
            const data: ReportingResponse = response.data;

            rows = rows.concat(data.rows || []);
            headings = data.headings ? data.headings : headings;

            if (
                data.rows &&
                data.rows.length === maxPageSize &&
                rows.length < goal &&
                !SprinklrAPIService.groupbyFiltersSatisfied(rows, data.headings, valueMap)
            ) {
                request.page++;

                const remaining = goal - rows.length;
                request.pageSize = Math.min(remaining, maxPageSize);

                return this.axios
                    .post('api/v3.1/reports/v6/query', request, { cancelToken: cancelSource.token })
                    .then(recursiveRequest);
            } else {
                rows = rows.filter(row =>
                    SprinklrAPIService.validateRowAgainstGroupByFilters(
                        row,
                        data.headings,
                        valueMap
                    )
                );

                if (rows.length > goal) {
                    rows = rows.slice(0, goal);
                }

                return {
                    rows,
                    headings,
                };
            }
        };

        promise = promise.then(recursiveRequest);

        promise.cancel = function(message?: string) {
            cancelSource.cancel(message);
        };

        return promise as PromiseWithCancel<ReportingResponse>;
    }

    // Special mapping for response values that shift from query filter values.
    // Not locked to a specific dimension name, as custom dimensions may also have this behavior
    static specialFilterValues = {
        positive: 'pos',
        negative: 'neg',
        neutral: 'neu',
        Uncategorized: 'uncat', // capitalization correct
    };

    public static generateGroupByFilterValueMap(request) {
        if (!request.groupBys || !request.filters) {
            return null;
        }

        const valueMap = {};

        request.groupBys.forEach(groupBy => {
            if (groupBy.details?.srcType === 'CUSTOM') {
                // alternatively we could solve by matching on dimensionName and {filter,groupBy}.details.fieldName
                // OUTBOUND_CUSTOM_PROPERTY needs to be wired this way anyway.
                // see example: https://presentations.sprinklr.com/clients/6934/presentations/c3Rvcnlib2FyZDo2MGQxZGM4OTE2MjNhZTAxNDVmNmIxZmE=/scenes/c2NlbmU6NjBmOTk2MTk2ODRkZmU0OTlmOGU5ZWJi/panels/cGFuZWw6NjBmOTk2MTk2ODRkZmU0OTlmOGU5ZWJj
                return;
            }

            request.filters.forEach(filter => {
                if (filter.dimensionName === groupBy.dimensionName && 'IN' === filter.filterType) {
                    valueMap[groupBy.heading] = {};
                    filter.values.forEach(value => {
                        valueMap[groupBy.heading][value] = true;
                    });
                }
            });
        });

        // Skip this behavior if not all groupbys have a matching filter.
        if (Object.keys(valueMap).length !== request.groupBys.length) {
            return null;
        }

        return valueMap;
    }

    // Check that a row's values for groupby dimensions exist in the groupby filter values.
    public static validateRowAgainstGroupByFilters(
        row: any[],
        headings: any[],
        filterValues: any
    ): boolean {
        // Don't remove rows if there's no map.
        if (!filterValues || Object.keys(filterValues).length === 0) {
            return true;
        }

        let allValid = true;

        headings.forEach((heading, index) => {
            if (filterValues[heading]) {
                const value = row[index];
                if (!filterValues[heading][value]) {
                    if (this.specialFilterValues[value]) {
                        if (!filterValues[heading][this.specialFilterValues[value]]) {
                            allValid = false;
                        }
                    } else {
                        allValid = false;
                    }
                }
            }
        });

        return allValid;
    }

    // Check that all combinations of groupby filter dimensions exist in the rows.
    public static groupbyFiltersSatisfied(
        rows: any[],
        headings: any[],
        filterValues: any
    ): boolean {
        // Bail early with previous behavior
        if (!filterValues || Object.keys(filterValues).length === 0) {
            return false;
        }

        // Flatten all combos of filterValues
        const combineValues = (current, next) => {
            const output = [];
            current.forEach(c => {
                next.forEach(n => {
                    output.push(`${c}_${n}`);
                });
            });
            return output;
        };

        let combos = [];

        for (const dim in filterValues) {
            if (combos.length === 0) {
                combos = Object.keys(filterValues[dim]);
            } else {
                combos = combineValues(combos, Object.keys(filterValues[dim]));
            }
        }

        const comboIndex = {};
        combos.forEach(combo => {
            comboIndex[combo] = true;
        });

        const matches = {};

        // Search across dataset for all keys/values
        rows.forEach(row => {
            let combined = null;
            let altCombined = null;
            headings.forEach((heading, index) => {
                const value = row[index];
                if (filterValues[heading]) {
                    combined = combined ? `${combined}_${value}` : value;

                    if (this.specialFilterValues[value]) {
                        const altValue = this.specialFilterValues[value];
                        altCombined = altCombined ? `${altCombined}_${altValue}` : altValue;
                    } else {
                        altCombined = altCombined ? `${altCombined}_${value}` : value;
                    }
                }
            });
            if (combined && (comboIndex[combined] || comboIndex[altCombined])) {
                matches[combined] = true;
            }
        });

        if (Object.keys(matches).length !== combos.length) {
            return false;
        }

        return true;
    }

    public spaceQuery(
        requestData: any,
        cancelSource?: CancelTokenSource
    ): PromiseWithCancel<ReportingResponse> {
        if (!cancelSource) {
            cancelSource = Axios.CancelToken.source();
        }

        const widgetId = requestData.key;

        const batchRequest: SpaceBatchRequest = {
            requests: {},
        };

        batchRequest.requests[widgetId] = JSON.parse(JSON.stringify(requestData));

        const promise: any = this.spaceBatchQuery(batchRequest, cancelSource).then(
            responses => responses[widgetId]
        );

        promise.cancel = function(message?: string) {
            cancelSource.cancel(message);
        };

        return promise as PromiseWithCancel<ReportingResponse>;
    }

    public spaceBatchQuery(
        batchRequest: SpaceBatchRequest,
        cancelSource?: CancelTokenSource
    ): PromiseWithCancel<{ [key: string]: ReportingResponse }> {
        if (!cancelSource) {
            cancelSource = Axios.CancelToken.source();
        }
        const promise: any = this.axios
            .request({
                method: 'POST',
                url: 'api/v3.1/reports/query',
                data: batchRequest,
                cancelToken: cancelSource.token,
            })
            .then((data: any) => {
                const keys = Object.keys(batchRequest.requests);
                return keys.reduce((responses, key) => {
                    const response = data.data.responses[key];
                    if (!response) {
                        throw new Error(
                            `Response key ${key} not found. Available keys: ['${Object.keys(
                                data.data.responses
                            ).join("', '")}']`
                        );
                    }
                    responses[key] = toReportingResponse(batchRequest.requests[key], response);
                    return responses;
                }, {});
            });

        promise.cancel = function(message?: string) {
            cancelSource.cancel(message);
        };

        return promise as PromiseWithCancel<{ [key: string]: ReportingResponse }>;
    }

    searchCustomFields(
        search?: CustomFieldSearch,
        cancelSource?: CancelTokenSource
    ): Promise<CustomField[]> {
        return this.axios
            .post('api/v3.1/customField/search', search || {}, {
                cancelToken: cancelSource ? cancelSource.token : undefined,
            })
            .then(response => {
                return response.data.customFields;
            });
    }

    search(request: any, cancelSource?: CancelTokenSource): PromiseWithCancel<Post[]> {
        return this.axios
            .post('/api/v3.1/content/search', request, {
                cancelToken: cancelSource ? cancelSource.token : undefined,
            })
            .then(response => {
                const hits =
                    response.data &&
                    response.data.responses &&
                    response.data.responses &&
                    response.data.responses.post.hits;

                return hits;
            });
    }

    getDashboards(module: Module): PromiseWithCancel<any> {
        return this.axios.get('api/v3.1/dashboard/module/' + module).then(response => {
            return response.data;
        });
    }

    getDashboard(dashboardId: string): PromiseWithCancel<any> {
        return this.axios.get('api/v3.1/dashboard/' + dashboardId).then(response => {
            return response.data;
        });
    }

    getAllWidgetsForDashboard(dashboardId: string): PromiseWithCancel<any> {
        return this.axios
            .get(
                'api/v3.1/dashboard/' +
                    dashboardId +
                    '/widget/module/REPORTING?includeWidgetFiltersAndSortInfo=true&includeDashboardFilters=true'
            )
            .then(response => {
                return response.data;
            });
    }

    getDashboardWidget(dashboardId: string, widgetId: string): PromiseWithCancel<any> {
        return this.axios
            .get(
                'api/v3.1/dashboard/' +
                    dashboardId +
                    '/widget/' +
                    widgetId +
                    '?includeWidgetFiltersAndSortInfo=true&includeDashboardFilters=true'
            )
            .then(response => {
                return response.data;
            });
    }

    getFacets(): Promise<any> {
        return new Promise((resolve, reject) => {
            const url = 'api/v3.1/content/allFacets?contentType=POST';

            this.axios
                .get(url)
                .then((result: any) => {
                    if (!result.data || !result.data.POST) {
                        reject();
                    }

                    resolve(result.data.POST);
                })
                .catch((error: any) => {
                    reject(error);
                });
        });
    }

    getInstagramOEmbed(accountId: number, permalink: string): Promise<InstagramResponse> {
        return new Promise((resolve, reject) => {
            if (!accountId || permalink?.length == 0) {
                reject('Invalid parameters');
                return;
            }

            const url = 'api/v3.1/instagram/oembed?accountId=' + accountId;

            this.axios
                .post(url, { url: permalink })
                .then(response => {
                    if (!response.data) {
                        reject();
                    }

                    resolve(response.data);
                })
                .catch((error: any) => {
                    reject(error);
                });
        });
    }
}
