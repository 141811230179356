import { action, computed, observable } from 'mobx';
import { Widget } from '@sprinklr/stories/widget/Widget';
import { EnvironmentConfig } from '../../config';
import {
    ActiveOverlay,
    BreakPointSize,
    currentObject,
    Environment,
    ModalItem,
    ValidModalStrings,
    WindowLayout,
} from 'services/UiStateService/types';
import fscreen from 'fscreen';

const emptyCurrentObject: currentObject = {
    display: null,
    storyboard: null,
    scene: null,
    panel: null,
    widget: null,
    theme: null,
};

export default class UiStateService {
    @observable public current: currentObject = emptyCurrentObject;
    @observable public colorPickerValue: string = null;
    @observable public activeModal: ValidModalStrings = '';
    @observable public activeModalItem: ModalItem = undefined;
    @observable public activeOverlay: ActiveOverlay = null;
    @observable public isFullscreen = false;
    @observable public sidebarMinimized = false;
    @observable public tabOffset = 0;
    @observable public environment: Environment;
    @observable public sidebarExpanded: boolean;
    @observable public windowDims = { width: window.innerWidth, height: window.innerHeight };
    @observable public revertPanelHistory = false;
    @observable public trackPanelChanges: boolean = null;
    @observable public panelHistory: any = [];
    public modalParent: HTMLElement = document.getElementById('root');
    private timeout: any;
    private sidebarMinimizeDelay = 500;

    // define outside of service
    @observable public layers2: Widget[] = [];

    @computed get layers() {
        return this.current.panel?.widget?.children
            .slice()
            .sort((a, b) => b.options.zIndex - a.options.zIndex);
    }

    @computed get windowBreakPoint(): BreakPointSize {
        const width = this.windowDims.width;
        let bp: BreakPointSize;
        if (width < 768) {
            bp = 'small';
        } else if (width > 769 && width < 1024) {
            bp = 'medium';
        } else {
            bp = 'desktop';
        }
        return bp;
    }

    @computed get windowLayout(): WindowLayout {
        return this.windowDims.width < this.windowDims.height ? 'portrait' : 'landscape';
    }

    constructor(config: EnvironmentConfig) {
        this.current = emptyCurrentObject;
        this.environment = config.applicationMode;

        if (config.sandboxed) {
            this.sidebarExpanded = true;
        } else {
            this.sidebarExpanded = localStorage.getItem('sidebarExpanded') !== 'false';
        }

        window.addEventListener('resize', this.windowDimsChanged);
    }

    private windowDimsChanged = () => {
        this.windowDims.width = window.innerWidth;
        this.windowDims.height = window.innerHeight;
    };

    // Do we have an active child widget selected?
    @computed get hasActiveChildWidget(): boolean {
        return !!this.current.widget;
    }

    // Returns active child widget or root widget
    @computed get activeWidget() {
        if (this.hasActiveChildWidget) {
            return this.current.widget;
        }

        return this.current.panel.widget;
    }

    @computed get theme() {
        return this.current.panel.widget.theme;
    }

    @computed get isModalActive(): boolean {
        return this.activeModal?.length > 0;
    }

    @computed get isOverlayActive(): boolean {
        return !!this.activeOverlay;
    }

    @action
    public emptyWidget() {
        this.current.widget = null;
    }

    /**
     * ColorPickers
     */
    @action
    openColorPicker(valueToChange) {
        if (this.colorPickerValue === valueToChange) {
            this.closePickers();
        } else {
            this.colorPickerValue = valueToChange;
        }
    }

    @action
    closePickers() {
        this.colorPickerValue = null;
    }

    /* Close modal by clearing activeModal observable */
    @action
    closeModal = () => {
        this.activeModal = '';
    };

    ////////////////////////////////////
    /* Fullscreen                     */
    ////////////////////////////////////
    @action
    enterFullscreen = () => {
        if (this.modalParent) {
            fscreen.requestFullscreen(this.modalParent);
        } else {
            console.log('something went wrong w/ fullscreen');
        }
    };

    @action
    exitFullscreen = () => {
        console.log('exit fullscreen');
        if (fscreen.fullscreenEnabled) {
            fscreen.exitFullscreen();
            this.clearFullscreenValues();
        }
    };

    toggleFullscreen() {
        this.isFullscreen ? this.exitFullscreen() : this.enterFullscreen();
    }

    public restoreSidebarTimeout() {
        console.log('restoreSidebarTimeout');
        if (!this.isFullscreen) {
            return;
        }
        clearTimeout(this.timeout);
        this.timeout = setTimeout(
            action(() => {
                this.sidebarMinimized = true;
            }),
            this.sidebarMinimizeDelay
        );
    }

    @action
    public clearSidebarTimeout() {
        console.log('clearSidebarTimeout');
        if (!this.isFullscreen) {
            return;
        }
        clearTimeout(this.timeout);
        this.sidebarMinimized = false;
    }

    @action
    trackFullscreen = () => {
        if (fscreen.fullscreenElement !== null) {
            console.log('checkFullscreen: TRUE');
            this.isFullscreen = true;
            clearTimeout(this.timeout);
            this.timeout = setTimeout(
                action(() => {
                    this.sidebarMinimized = true;
                }),
                this.sidebarMinimizeDelay
            );
        } else {
            console.log('checkFullscreen: FALSE');
            this.clearFullscreenValues();
        }
    };

    private clearFullscreenValues() {
        clearTimeout(this.timeout);
        this.isFullscreen = false;
        this.sidebarMinimized = false;
    }
}
