import classNames from 'classnames';
import * as React from 'react';
import { PostCompositionConfig } from './Post';
import { PostCompositionTypeValues } from '@sprinklr/stories/widget/types';
import { PostComposition, Parts } from 'models/Widget/WidgetType';
import useDimensions from 'src/hooks/UseDimensions';

interface PostCardCompProps {
    type: PostCompositionTypeValues;
    parts: Parts;
    config: PostCompositionConfig;
    unique: string;
    onPostClick: (event: React.MouseEvent | React.TouchEvent) => void;
}

const postCardCompositions = ({
    compositionType: type,
    parts,
    config,
    unique,
    onPostClick,
}: PostComposition) => {
    return (
        <PostCardComp
            unique={unique}
            type={type}
            parts={parts}
            config={config}
            onPostClick={onPostClick}
        />
    );
};

export default postCardCompositions;

const PostCardComp: React.FunctionComponent<PostCardCompProps> = ({
    parts,
    config,
    type,
    unique,
    onPostClick,
}) => {
    const { userGroup, postText, media, metaGroup, postExtraPostData, postTitle } = parts;
    const { isInterstitialMedia, postOuterClasses, hasMedia, noMedia, postInnerClasses } = config;

    const containerRef = React.useRef();
    const { height, width } = useDimensions({ ref: containerRef, liveMeasure: true });

    const isPortrait = React.useCallback(() => {
        return height > width;
    }, [height, width]);

    switch (type) {
        case 'a':
            return (
                <React.Fragment key={unique}>
                    <div
                        className={classNames(`${postOuterClasses}`, {
                            // post_size_small: area < threshold,
                            // post_size_tiny: area < 500
                        })}
                        ref={containerRef}
                        onClick={onPostClick}
                        onTouchEnd={onPostClick}>
                        <div
                            className={classNames(
                                `post_inner flex noGrow rel w-100 h-100 ${postInnerClasses}`,
                                {
                                    vertical:
                                        (noMedia && !isInterstitialMedia) ||
                                        (!noMedia && isPortrait() && !isInterstitialMedia),
                                }
                            )}>
                            {!isPortrait() ? (
                                <>
                                    {hasMedia &&
                                        !isInterstitialMedia && [
                                            <div
                                                key='mediaContainer'
                                                className={classNames(
                                                    `media_group flex rel w-50 w-100`,
                                                    {}
                                                )}>
                                                {media}
                                            </div>,
                                            <div
                                                key='textContainer'
                                                className={classNames(
                                                    `text_group flex vertical h-100 w-50`,
                                                    {
                                                        'pl-1': !isInterstitialMedia,
                                                    }
                                                )}>
                                                {userGroup}

                                                {noMedia &&
                                                    !isInterstitialMedia && [
                                                        postTitle,
                                                        postText,
                                                        metaGroup,
                                                        postExtraPostData,
                                                    ]}
                                                <div className='post_text_engagement_group flex vertical bottom left'>
                                                    {[postText, metaGroup, postExtraPostData]}
                                                </div>
                                            </div>,
                                        ]}
                                    {noMedia &&
                                        !isInterstitialMedia && [
                                            userGroup,
                                            postText,
                                            metaGroup,
                                            postExtraPostData,
                                        ]}
                                </>
                            ) : (
                                <>
                                    {userGroup}

                                    {hasMedia &&
                                        !isInterstitialMedia && [
                                            <div
                                                key='mediaContainer'
                                                className={classNames(
                                                    `media_group flex rel h-40 w-100`,
                                                    {}
                                                )}
                                                style={{ height: height * 0.4 }}>
                                                {media}
                                            </div>,
                                            <div
                                                key='textContainer'
                                                className={classNames(
                                                    `text_group flex vertical h-60 w-100`,
                                                    {}
                                                )}>
                                                <div className='post_text_engagement_group flex vertical bottom left'>
                                                    {[postText, metaGroup, postExtraPostData]}
                                                </div>
                                            </div>,
                                        ]}

                                    {noMedia &&
                                        !isInterstitialMedia && [
                                            postTitle,
                                            postText,
                                            metaGroup,
                                            postExtraPostData,
                                        ]}
                                </>
                            )}

                            {isInterstitialMedia && media}
                        </div>
                    </div>
                </React.Fragment>
            );
        default:
            return <div></div>;
    }
};
