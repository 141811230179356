import moment from 'moment';
import { computed, observable } from 'mobx';
import { WidgetJSON } from '../WidgetWrapper';
import { WidgetRequest } from '../WidgetRequest';
import { WidgetRequestAnalytics } from '../WidgetRequestAnalytics';
import {
    AnalyticsTimeInterval,
    AnalyticsTimeIntervalSuffix,
    AnalyticsTimePeriod,
} from '@sprinklr/stories/analytics/AnalyticsRequest';
import { TimePeriodKey } from 'models/TimePeriod/TimePeriod';
import TimePeriod from 'models/TimePeriod/TimePeriod';
import TimePeriodService from 'services/TimePeriodService/TimePeriodService';

// export interface AnalyticsTimePeriod {
//     key?: TimePeriodKey;
//     previousPeriod?: boolean;
//     startTime?: number;
//     endTime?: number;
//     timeZone?: string;
// }

/**
 * Wraps a raw AnalyticsTimePeriod, providing business logic for form interaction
 */
export class WidgetTimePeriod implements WidgetJSON {
    @observable
    private readonly timePeriod: AnalyticsTimePeriod;

    private parent: WidgetRequestAnalytics;

    constructor(parent: WidgetRequest, timePeriod?: AnalyticsTimePeriod) {
        if (!timePeriod) {
            timePeriod = {
                key: undefined,
                previousPeriod: undefined,
                duration: undefined,
                startTime: undefined,
                endTime: undefined,
                timeZone: undefined,
                wholePeriods: undefined,
                xValue: undefined,
            };
        }

        this.parent = parent as WidgetRequestAnalytics;
        this.timePeriod = timePeriod;
    }

    @computed get isIncomplete() {
        if (!this.key && !this.previousPeriod) {
            return true;
        }

        return false;
    }

    @computed get calculated(): TimePeriod {
        const { timePeriod } = this;
        return TimePeriodService.createFromWidgetTimePeriod(this);
    }

    @computed get allowedIntervals(): AnalyticsTimeIntervalSuffix[] {
        const calculated: TimePeriod = this.calculated;
        const days: number = calculated.days;

        let intervals;
        if (days === null || days > 120) {
            intervals = ['d', 'w', 'M', 'q', 'y'];
        } else if (days > 58) {
            intervals = ['d', 'w', 'M', 'q'];
        } else if (days > 27) {
            intervals = ['d', 'w', 'M'];
        } else if (days > 13) {
            intervals = ['h', 'd', 'w'];
        } else if (days > 6) {
            intervals = ['h', 'd'];
        } else if (days > 1) {
            intervals = ['m', 'h', 'd'];
        } else {
            // 24 hours and lower
            intervals = ['m', 'h'];
        }

        if (calculated.timePeriod === 'custom_to_date') {
            intervals = intervals.filter(interval => interval !== 'm' && interval !== 'h');
        }

        return intervals;
    }

    /**
     * Determine an interval that feels good.
     */
    @computed get naturalInterval(): AnalyticsTimeInterval {
        const days: number = this.calculated.days;

        if (days === null || days > 365) {
            return '1y';
        } else if (days > 28) {
            return '1M';
        } else if (days > 13) {
            return '1w';
        } else if (days > 1) {
            return '1d';
        } else {
            // 24 hours and lower
            return '1h';
        }
    }

    @computed get defaultInterval(): AnalyticsTimeInterval {
        const calculated: TimePeriod = this.calculated;

        return calculated.defaultInterval();
    }

    getRecommendedLimit(interval: AnalyticsTimeInterval): number {
        const calculated: TimePeriod = this.calculated;
        const days: number = calculated.days || 1;
        switch (interval) {
            case '1y':
                return Math.ceil(days / 365);
            case '1q':
                return Math.ceil(days / 120);
            case '1M':
                return Math.ceil(days / 30);
            case '1w':
                return Math.ceil(days / 7);
            case '1d':
                return days;
            case '1h':
                return Math.ceil(days * 24);
            case '1m':
                return Math.ceil(days * 24 * 60);
        }
    }

    @computed
    get key(): TimePeriodKey {
        return this.timePeriod.key;
    }

    @computed
    get duration(): number {
        return this.timePeriod.duration;
    }

    @computed
    get dateStart(): number {
        return this.timePeriod.startTime;
    }

    @computed
    get dateStop(): number {
        return this.timePeriod.endTime;
    }

    @computed
    get timeZone(): string {
        return this.timePeriod.timeZone;
    }

    @computed
    get previousPeriod(): boolean {
        return this.timePeriod.previousPeriod;
    }

    @computed
    get wholePeriods(): boolean {
        return this.timePeriod.wholePeriods;
    }

    @computed
    get xValue(): number {
        return this.timePeriod.xValue;
    }

    set(field: string, value: any) {
        switch (field) {
            case 'timeZone': this.setTimeZone(value); break;
            case 'key': this.setKey(value); break;
            case 'wholePeriods': this.setWholePeriods(value); break;
            case 'duration': this.setDuration(value); break;
            case 'startTime': this.setDateStart(value); break;
            case 'endTime': this.setDateStop(value); break;
            case 'xValue': this.setXValue(value); break;
        }
    }

    setKey(key: TimePeriodKey): void {
        this.timePeriod.key = key;
        this.parent.setRecommendedConvertLimit();
    }

    setDuration(duration: number): void {
        if (duration) {
            this.timePeriod.duration = duration;
            this.parent.setRecommendedConvertLimit();
        }
    }

    setDateStart(date: number): void {
        if (date) {
            const start = moment.tz(date, this.timeZone);

            // Ensure that the start date is less than the stop date
            if (this.timePeriod.endTime) {
                const stop = moment.tz(this.timePeriod.endTime, this.timeZone);

                if (stop <= start) {
                    this.timePeriod.endTime = start
                        .clone()
                        .endOf('day')
                        .valueOf();
                }
            }

            this.timePeriod.startTime = start.valueOf();
        }
    }

    setDateStop(date: number): void {
        if (date) {
            const stop = moment.tz(date, this.timeZone);

            // Ensure that the start date is less than the stop date
            if (this.timePeriod.startTime) {
                const start = moment.tz(this.timePeriod.startTime, this.timeZone);

                if (start >= stop) {
                    this.timePeriod.startTime = stop
                        .clone()
                        .startOf('day')
                        .valueOf();
                }
            }

            this.timePeriod.endTime = stop.valueOf();
        }
    }

    setTimeZone(timeZone: string): void {
        const previousTimeZone = this.timePeriod.timeZone;
        this.timePeriod.timeZone = timeZone;
    }

    setPreviousPeriod(previous: boolean): void {
        this.timePeriod.previousPeriod = previous;
    }

    setWholePeriods(value: boolean): void {
        this.timePeriod.wholePeriods = value;
    }

    setXValue(xValue: number): void {
        this.timePeriod.xValue = xValue;
    }

    toJSON(): any {
        return this.timePeriod;
    }

    copyFrom(timePeriod: TimePeriod) {
        if (timePeriod.key) {
            this.timePeriod.key = timePeriod.key;
        }

        if (timePeriod.timePeriod) {
            this.timePeriod.key = timePeriod.timePeriod;
        }

        if (timePeriod.duration) {
            this.timePeriod.duration = timePeriod.duration;
        }

        if (timePeriod.startDate) {
            this.timePeriod.startTime = moment.tz(timePeriod.startDate, this.timeZone).valueOf();
        }

        if (timePeriod.endDate) {
            this.timePeriod.endTime = moment.tz(timePeriod.endDate, this.timeZone).valueOf();
        }

        if (timePeriod.timeZone) {
            this.timePeriod.timeZone = timePeriod.timeZone;
        }

        if (timePeriod.wholePeriods) {
            this.timePeriod.wholePeriods = timePeriod.wholePeriods;
        }

        if (timePeriod.xValue) {
            this.timePeriod.xValue = timePeriod.xValue;
        }
    }

    copyTo(timePeriod: TimePeriod) {
        if (this.timePeriod.key) {
            timePeriod.key = this.timePeriod.key;
        }

        if (this.timePeriod.duration) {
            timePeriod.duration = this.timePeriod.duration;
        }

        if (this.timePeriod.startTime) {
            timePeriod.startDate = moment(this.timePeriod.startTime);
        }

        if (this.timePeriod.endTime) {
            timePeriod.endDate = moment(this.timePeriod.endTime);
        }

        if (this.timePeriod.timeZone) {
            timePeriod.timeZone = this.timePeriod.timeZone;
        }

        if (this.timePeriod.wholePeriods) {
            timePeriod.wholePeriods = this.timePeriod.wholePeriods;
        }

        if (this.timePeriod.xValue) {
            timePeriod.xValue = this.timePeriod.xValue;
        }
    }

    convertStartStopTimeZone(originalTimeZone, newTimeZone) {
        //converts the start and stop time to same local date and time but in the new time zone -AMR
        const originalStart = moment
            .tz(this.dateStart, originalTimeZone)
            .format('YYYY-MM-DDTHH:mm:ss.SSS');
        const convertedStart = moment.tz(originalStart, newTimeZone).valueOf();

        const originalStop = moment
            .tz(this.dateStop, originalTimeZone)
            .format('YYYY-MM-DDTHH:mm:ss.SSS');
        const convertedStop = moment.tz(originalStop, newTimeZone).valueOf();

        this.setDateStart(convertedStart);
        this.setDateStop(convertedStop);
    }
}
