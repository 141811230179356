import classNames from 'classnames';
import * as React from 'react';
import { PostComposition } from 'models/Widget/WidgetType';

const filmStripCompositions = ({
    compositionType: type,
    parts,
    config,
    onPostClick,
}: PostComposition) => {
    const { userGroup, postText, media, metaGroup, postExtraPostData, postTitle } = parts;
    const {
        isInterstitialMedia,
        postOuterClasses,
        postInnerClasses,
        hasMedia,
        noMedia,
        isPanelPortrait,
    } = config;

    switch (type) {
        case 'a':
            return (
                <div className={postOuterClasses} onClick={onPostClick} onTouchEnd={onPostClick}>
                    {!isInterstitialMedia && (
                        <div
                            className={classNames(`post_inner flex rel w-100 h-100 vertical`, {
                                [postInnerClasses]: true,
                                between: metaGroup,
                                bottom: !metaGroup,
                            })}>
                            {media}
                            {metaGroup}
                            {noMedia && postTitle}
                            {postText}
                            {userGroup}
                            {noMedia && postExtraPostData}
                        </div>
                    )}
                    {isInterstitialMedia && (
                        <div className='post_inner rel w-100 h-100'>{media}</div>
                    )}
                </div>
            );
        case 'b':
            return (
                <div className={postOuterClasses} onClick={onPostClick} onTouchEnd={onPostClick}>
                    <div
                        className={classNames(`post_inner flex rel w-100 h-100`, {
                            vertical: !isPanelPortrait,
                        })}>
                        {isPanelPortrait && hasMedia && !isInterstitialMedia && (
                            <div className='flex'>
                                <div className='rel w-50 h100'>{media}</div>
                                <div className='flex vertical w-50 h100'>
                                    {metaGroup}
                                    {postText}
                                    {userGroup}
                                    {postExtraPostData}
                                </div>
                            </div>
                        )}

                        {isPanelPortrait && noMedia && !isInterstitialMedia && (
                            <div className='flex vertical'>
                                {[metaGroup, postText, postTitle, userGroup, postExtraPostData]}
                            </div>
                        )}

                        {!isPanelPortrait && [
                            userGroup,
                            hasMedia && !isInterstitialMedia && (
                                <div className='flex rel' key='foo'>
                                    <div className='fullbleed flex vertical'>
                                        <div className='rel h-50'>{media}</div>
                                        <div className='h-50 flex vertical'>{postText}</div>
                                    </div>
                                </div>
                            ),
                        ]}
                        {!isPanelPortrait && noMedia && postTitle}
                        {!isPanelPortrait && noMedia && postText}

                        {!isPanelPortrait && metaGroup}
                        {postExtraPostData}

                        {isInterstitialMedia && media}
                    </div>
                </div>
            );
        case 'c':
            return (
                <div className={postOuterClasses} onClick={onPostClick} onTouchEnd={onPostClick}>
                    <div
                        className={classNames(`post_inner fullbleed flex`, {
                            [`${isPanelPortrait && hasMedia ? 'horizontal' : 'vertical'}`]: true,
                        })}>
                        {hasMedia &&
                            !isInterstitialMedia && [
                                <div
                                    key='1'
                                    className={classNames(`rel`, {
                                        [`${isPanelPortrait ? 'w-50 h100' : 'h-50'}`]: true,
                                    })}>
                                    {media}
                                    {!isPanelPortrait && userGroup}
                                </div>,
                                <div
                                    key='2'
                                    className={`${
                                        isPanelPortrait ? 'w-50 h100' : 'h-50'
                                    } flex vertical between`}>
                                    {isPanelPortrait && userGroup}
                                    {postText}
                                    {isPanelPortrait && metaGroup}
                                </div>,
                            ]}

                        {isInterstitialMedia && media}

                        {noMedia && [userGroup, postTitle, postText]}
                        {(!isPanelPortrait || (isPanelPortrait && noMedia)) && metaGroup}
                        {postExtraPostData}
                    </div>
                </div>
            );
        default:
            return <div>hello</div>;
    }
};

export default filmStripCompositions;
