import { generateID } from './PanelTemplatesTheme/helpers';
import { WIDGET_LABEL_SIZE } from '../../components/Widget/WidgetLabel/constants';
import { Panel } from '../../models/Panel/Panel';

export var TemplateEditorial5: Panel = {
    id: 'editorial-5',
    name: 'Title Slide + Image',
    description: '',
    tags: ['Editorial', 'text', 'image', 'landscape', 'portrait', 'interstitial'],
    widget: {
        id: generateID(),
        position: {
            width: '1920px',
            height: '1080px',
            top: '0',
            left: '0',
        },
        classes: 'template_custom',
        label: {
            titleText: 'Panel',
            enabled: false,
            size: WIDGET_LABEL_SIZE.primary,
        },
        theme: {
            ext: {
                padding: 0,
            },
        },
        children: [
            {
                type: 'customContentImage',
                id: generateID(),
                name: 'Image',
                position: {
                    width: '100%',
                    height: '66.66%',
                    top: '0',
                    left: '0',
                },
                label: {
                    titleText: 'Header',
                    enabled: false,
                    size: WIDGET_LABEL_SIZE.primary,
                },
                options: {
                    player: {
                        playing: false,
                        duration: 15,
                    },
                    contentImageUrl: '/public/assets/MomentofDiscovery.jpg',
                    contentImageFit: 'cover',
                    verticalAlign: 'bottom',
                },
            },
            {
                type: 'customContentRichText',
                name: 'Rich Text',
                id: generateID(),
                position: {
                    width: '91%',
                    height: '14%',
                    top: '69%',
                    left: '5.5%',
                    portrait: {
                        width: '80%',
                        height: '7%',
                        top: '78%',
                        left: '10%',
                    },
                },
                label: {
                    titleText: 'Widget Header',
                    enabled: false,
                    size: 20,
                },
                options: {
                    player: {
                        playing: false,
                        duration: 15,
                    },
                    contentRichText: {
                        entityMap: {},
                        blocks: [
                            {
                                key: 'ag6qs',
                                text: 'Main Header',
                                type: 'unstyled',
                                depth: 0,
                                inlineStyleRanges: [
                                    {
                                        offset: 0,
                                        length: 11,
                                        style: 'CUSTOM_FONT_SIZE_60' as any,
                                    },
                                ],
                                entityRanges: [],
                                data: {},
                            },
                        ],
                    },
                    contentTextAlign: 'left',
                    contentPadding: 0,
                    verticalAlign: 'top',
                },
            },
            {
                type: 'customContentRichText',
                name: 'Rich Text',
                id: generateID(),
                position: {
                    width: '93%',
                    height: '10%',
                    top: '82.5%',
                    left: '5.5%',
                    portrait: {
                        width: '80%',
                        height: '7%',
                        top: '84%',
                        left: '10%',
                    },
                },
                label: {
                    titleText: 'Widget Header',
                    enabled: false,
                    size: WIDGET_LABEL_SIZE.primary,
                },
                options: {
                    player: {
                        playing: false,
                        duration: 15,
                    },
                    contentRichText: {
                        entityMap: {},
                        blocks: [
                            {
                                key: 'ag6qs',
                                text: 'Subheader',
                                type: 'unstyled',
                                depth: 0,
                                inlineStyleRanges: [
                                    {
                                        offset: 0,
                                        length: 9,
                                        style: 'CUSTOM_FONT_SIZE_24',
                                    },
                                ],
                                entityRanges: [],
                                data: {},
                            },
                        ],
                    },
                    contentTextAlign: 'left',
                    contentPadding: 0,
                    verticalAlign: 'top',
                },
            },
        ],
    },
};
