import { AnalyticsRequest, GroupBy, Metric, Sort } from './PanelTemplatesTheme/PanelTemplatesTheme';
import { Panel } from '../../models/Panel/Panel';
import { WIDGET_LABEL_SIZE } from '../../components/Widget/WidgetLabel/constants';

export var TemplateProfile6: Panel = {
    id: 'profile-1-by-6',
    name: 'Profiles (6)',
    tags: ['Content', 'Profile', 'Smart', 'landscape', 'portrait'],
    description: '',
    widget: {
        position: {
            width: '1920px',
            height: '1080px',
            top: '0',
            left: '0',
        },
        classes: 'template_profile template_profile_1_by_6',
        children: [
            {
                type: 'profile',
                id: '1',
                name: 'profile',
                useGlobalTimePeriod: true,
                profileRequests: [
                    // Note: All global variables like reportingEngine, report, filters, and projections
                    // are injected via AnalyticsRequest.getRequests()
                    new AnalyticsRequest({
                        page: 0,
                        pageSize: 50,
                        groupBys: [
                            new GroupBy({
                                LISTENING: { dimensionName: 'ADVOCATES' },
                            }),
                        ],
                        projections: [
                            new Metric({
                                STORY_MESSAGE: {
                                    dimensionName: 'MENTIONS_COUNT',
                                    heading: 'Mentions',
                                },
                            }),
                        ],
                        sorts: [
                            new Sort({
                                order: 'DESC',
                                heading: {
                                    LISTENING: 'MENTIONS_COUNT',
                                    PLATFORM: 'TOTAL_ENGAGEMENT',
                                    BENCHMARKING: 'TOTAL_ENGAGEMENT',
                                    RDB_FIREHOSE: 'SN_CREATED_TIME',
                                    STORY_MESSAGE: 'MENTIONS_COUNT',
                                },
                            }),
                        ],
                        timePeriod: {
                            key: 'last_7_days',
                        },
                    }) as any,
                ],
                position: {
                    width: '100%',
                    height: '100%',
                    left: '0',
                    top: '0',
                },
                options: {
                    player: {
                        playing: true,
                        duration: 15,
                    },
                    numbProfiles: 6,
                    showIcon: true,
                    verticalAlign: 'top',
                    showMetricValue: true,
                    metricValueSize: 60,

                    showMetricName: true,
                    metricNameSize: 15,
                    smartSize: {
                        enabled: true,
                        mode: 'responsive',
                        size: 'xl',
                    },
                    portrait: {
                        widgetOrientation: 'portrait',
                    },
                },
                label: {
                    titleText: 'Profiles (6)',
                    size: WIDGET_LABEL_SIZE.primary,
                    enabled: true,
                },
            },
        ],
    },
};
