import moment from 'moment';
import { toJS } from 'mobx';
import { AxiosInstance } from 'axios';
import { fromPromise, IPromiseBasedObservable } from 'mobx-utils';
import { WidgetDimensionKey } from 'utils/Widget/WidgetDimensionKey';
import { GraphQLService } from '../GraphQLService/GraphQLService';
import { MonitoringDashboard, MonitoringColumn } from '@sprinklr/stories-services/PostsService/PostsService';
import { Cache } from 'utils/Cache/Cache';
import { BulkLookupValue, ReportingLookupFilter } from '../SprinklrAPIService/SprinklrAPIService';
import { AnalyticsEngine } from '@sprinklr/stories/analytics/AnalyticsRequest';
import TopicTheme from '@sprinklr/stories/analytics/TopicTheme';
import SLAFrequency from '@sprinklr/stories/analytics/SLAFrequency';
import AccountItem from '@sprinklr/stories/analytics/AccountItem';
import SourceItem from '@sprinklr/stories/analytics/SourceItem';
import CommunityUser from '@sprinklr/stories/analytics/CommunityUser';
import BenchmarkBrand from '@sprinklr/stories/analytics/BenchmarkBrand';
import TrendService from '../TrendService/TrendService';
import TemporalDimension from '@sprinklr/stories/analytics/TemporalDimension';
import MetaSearchRequest, { FieldTypes } from 'models/MetaInfo/MetaSearchRequest';
import { twitterDimensions, twitterMetrics } from './TwitterFields';

export interface MetaField {
    name: string;
    fieldName: string;
    hidden: boolean;
    additional: any;
    uniqueKey: string;
    i18nLabelPrefix: string;
    aliases: string[];
    channelTypes: string[];
    type: string;
    measurementGroups: string[];
    isShared: false;
    shared: false;
    displayName: string;
    rank: number;
    dataType?: string;
    report?: string;
}

export interface MetaFieldResponse {
    uniqueKey: string;
    field: MetaField;
    reports: string[];
    reportVsField: any;
    name: string;
    displayName: string;
    rank: number;
    description: string;
    favorite: boolean;
    disabled: boolean;
    isFieldVisible: boolean;
    fieldType: string;
}

export interface MetaSearchResponse {
    hasMore: boolean;
    fieldResponse: MetaFieldResponse[];
    fieldDetails: any[];
    measurements?: string[];
    dimensions?: string[];
}

export interface BulkLookupResponse {
    hasMore: boolean;
    values: Array<BulkLookupValue>;
}

type FieldsCacheDimension = { [dimension: string]: Promise<MetaSearchResponse> };
type FieldsCacheEngine = { [report: string]: FieldsCacheDimension };
type FieldsCache = { [engine: string]: FieldsCacheEngine };

enum FilterType {
    None,
    Filter,
    Measurement,
}

const hardcodedDimensions = [
    {
        displayName: 'Theme Tag',
        field: {
            name: 'LST_THEME_TAG',
            fieldName: 'LST_THEME_TAG',
            uniqueKey: 'D_LST_THEME_TAG',
            type: 'NORMAL',
            displayName: 'Theme Tag',
            description: 'Theme Tags',
        },
        reports: ['SPRINKSIGHTS'],
        uniqueKey: 'LST_THEME_TAG',
    },
    {
        displayName: 'Keyword Query',
        field: {
            name: 'QUERY',
            fieldName: 'QUERY',
            uniqueKey: 'D_QUERY',
            type: 'NORMAL',
            displayName: 'Keyword Query',
            description: 'Keyword Queries',
            additional: {
                INPUT_TYPE: 'FREE_TEXT',
            },
        },
        reports: ['SPRINKSIGHTS'],
        uniqueKey: 'QUERY',
    },
    {
        displayName: 'Keyword List',
        field: {
            name: 'LST_KEYWORD_LIST',
            fieldName: 'LST_KEYWORD_LIST',
            uniqueKey: 'D_LST_KEYWORD_LIST',
            type: 'NORMAL',
            displayName: 'Keyword List',
            description: 'Keyword Lists',
        },
        reports: ['SPRINKSIGHTS'],
        uniqueKey: 'LST_KEYWORD_LIST',
    },
];

export default class MetaInfoService {
    // Promise for loading persistent lookup values
    static loaderDashboards: IPromiseBasedObservable<any>;
    static loaderSLAPresets: IPromiseBasedObservable<any>;

    private axios: AxiosInstance;
    private graphQLService: GraphQLService;

    private cacheSLAPresets: any = {
        PLATFORM: null,
    };

    private cacheAccounts: any = null;

    private cacheFields: FieldsCache = {
        LISTENING: null,
        BENCHMARKING: null,
        PLATFORM: null,
        PAID: null,
        UNIFIED_ANALYTICS_REPORTING_ENGINE: null,
        CAMPAIGN: null,
        ADVOCACY: null,
        TWITTER: null,
        RDB_FIREHOSE: null,
    };

    private dashboards: Array<MonitoringDashboard> = null;
    private cacheValueLabels = new Cache<Array<BulkLookupValue>>();
    private cacheUnFilterableDimensions: { [key: string]: { [key: string]: BulkLookupValue } } = {};
    private static readonly unFilterableDimensions = {
        LST_THEME: true,
        TOPIC_TAGS: true,
        POST_ID: true,
    };

    constructor(axios: AxiosInstance, graphQLService: GraphQLService) {
        this.axios = axios;
        this.graphQLService = graphQLService;
    }

    static normalizeBulkItem(item: any, dimension?: WidgetDimensionKey): void {
        if (typeof item !== 'object' || item === null) {
            return;
        }

        // BENCHMARKING_ACCOUNT_ID
        if (dimension && dimension.name === 'BENCHMARKING_ACCOUNT_ID') {
            // Try to make BENCHMARKING_ACCOUNT_IDs look like regular account objects
            if (item.type && !item.snType) {
                item.snType = item.type;
            }
            if (item.displayName) {
                item.name = item.displayName;
            }
            return;
        }

        // USER_ID and AFFECTED_USER_ID
        if (item.userId !== undefined) {
            item.id = item.userId.toString();
            // SPRINKLR_CREATIVE_ACCOUNT_DICTIONARY
        } else if (
            dimension?.name === 'SPRINKLR_CREATIVE_ACCOUNT_DICTIONARY' &&
            item.accountUserId !== undefined
        ) {
            item.id = item.accountUserId.toString();
            // ACCOUNT_ID
        } else if (item.accountId !== undefined) {
            item.id = item.accountId.toString();
        }

        if (item.name === undefined || item.name === null) {
            if (item.displayName !== undefined) {
                item.name = item.displayName;
                // USER_ID and AFFECTED_USER_ID
            } else if (item.visibleId !== undefined) {
                item.name = item.visibleId;
                // ACCOUNT_ID
            } else if (item.screenName !== undefined) {
                item.name = item.screenName;
                // ACCOUNT_GROUP_ID
            } else if (item.groupName !== undefined) {
                item.name = item.groupName;
                // Otherwise this record doesn't have a readable name, so use id instead
                //   https://sprinklr.atlassian.net/browse/DISPLAY-1287
            } else {
                item.name = item.id;
            }
        }

        // Cleanup extra crap we don't need.  Reduces the in-memory load on these
        // large data pulls.  DISPLAY-1052
        if (dimension) {
            switch (dimension.name) {
                case 'MEDIA_ASSET_ID':
                    delete item.assetSource;
                    delete item.assetStatus;
                    delete item.assetType;
                    delete item.clientActionStats;
                    delete item.clientDetails;
                    delete item.customFields;
                    delete item.reportingStats;
                    delete item.shareConfigs;
                    delete item.socialAsset;
                    break;
            }
        }
    }

    static normalizeTemporalResponse(result: any[], dimensionName: string) {
        const response = {};

        for (const id in result) {
            response[id] = new TemporalDimension(result[id], id, dimensionName);
        }

        return response;
    }

    static normalizeAccountIds(accountIds: any[]) {
        const response = {};

        for (const id in accountIds) {
            const accountItem = AccountItem.fromObject(accountIds[id]);

            if (accountItem) {
                response[accountItem.accountId] = accountItem;
            }
        }

        return response;
    }

    static normalizeSourceIds(ids: any[]) {
        const response = {};

        for (const id in ids) {
            const item = SourceItem.fromObject(ids[id]);

            if (item) {
                response[item.id] = item;
            }
        }

        return response;
    }

    static normalizeThemeResponse(themes: any[]) {
        const response = {};

        themes.forEach((theme: any) => {
            if (typeof theme !== 'object' || theme === null || !theme.id) {
                return;
            }

            response[theme.id] = new TopicTheme(
                theme.id,
                theme.name,
                theme.displayName,
                theme.imageUrl,
                theme.color
            );
        });

        return response;
    }

    static normalizeBenchmarkingResponse(response: any) {
        if (!response) {
            return;
        }
        const themes = {};

        Object.keys(response).forEach((theme: any) => {
            const item = response[theme];
            if (!item.imageUrl) {
                themes[item.id] = item;
                return;
            }
            themes[item.id] = new BenchmarkBrand(
                item.id,
                item.name,
                item.competitorBrand,
                item.imageUrl
            );
        });
        return themes;
    }

    static normalizeSLAFrequencyResponse(response: any) {
        const frequencies = {};

        for (const key in response) {
            const freq = response[key];

            if (!freq.id || !freq.name) {
                continue;
            }

            // Convert a time interval to a to-from value.
            const interval = freq.id.split('-');
            if (interval.length !== 2) {
                continue;
            }

            frequencies[freq.id] = new SLAFrequency(freq.id, freq.name, interval[0], interval[1]);
        }

        return frequencies;
    }

    static normalizeCommunityUsers(users: any[]) {
        const response = {};

        for (const id in users) {
            const communityUser = CommunityUser.fromObject(users[id]);

            if (communityUser) {
                response[id] = communityUser;
            }
        }

        return response;
    }

    isValidReport(engine: AnalyticsEngine, report: string) {
        return true;
        // return this.getDimensionCache(engine, report) !== null;
    }

    async getDimension(
        engine: AnalyticsEngine,
        report: string,
        dimension: WidgetDimensionKey
    ): Promise<any> {
        const data = await this.lookupFields(engine, report, [
            dimension.isCustom ? dimension.customName : dimension.name,
        ]);

        if (data?.length) {
            return data[0].field;
        }

        return null;
    }

    async getDimensionLabel(
        engine: AnalyticsEngine,
        report: string,
        dimension: WidgetDimensionKey
    ): Promise<string> {
        const data = await this.lookupFields(engine, report, [
            dimension.isCustom ? dimension.customName : dimension.name,
        ]);

        if (data?.length) {
            return data[0].field?.displayName;
        }

        return null;
    }

    async getDimensionReports(
        engine: AnalyticsEngine,
        dimension: WidgetDimensionKey
    ): Promise<string[]> {
        const name = dimension.isCustom ? dimension.customName : dimension.name;
        const data = await this.lookupFieldsInternal(engine, null, [name]);

        if (data) {
            const name = data.dimensions?.[0]; // Use name from response.  ie. "date" comes back as "D_DATE"
            const reports = data.fieldDetails?.[name];
            if (!reports) {
                throw new Error(`ERROR: No reports found for ${name}`);
            }

            return Object.keys(reports);
        }

        return null;
    }

    async areDimensions(
        engine: AnalyticsEngine,
        report: string,
        dimensions: WidgetDimensionKey[]
    ): Promise<boolean[]> {
        const data = await this.lookupFields(
            engine,
            report,
            dimensions.map(item => (item.isCustom ? item.customName : item.name))
        );

        if (data?.length) {
            return data.map((item: any) => item.fieldType === 'DIMENSION');
        }

        return null;
    }

    async getMetric(engine: AnalyticsEngine, report: string, metric: string): Promise<any> {
        const data = await this.lookupFields(engine, report, [metric]);

        if (data?.length) {
            return data[0].field;
        }

        return null;
    }

    getSLAPresets(engine: AnalyticsEngine): Array<any> {
        if (this.cacheSLAPresets[engine]) {
            return this.cacheSLAPresets[engine];
        } else {
            return [];
        }
    }

    getPresetLabel(engine: AnalyticsEngine, preset: string): string {
        return null;
    }

    private byDimensionsPromises = {};

    // Get all value labels for a dimension
    getValueLabels(
        engine: AnalyticsEngine,
        report: string,
        dimension: WidgetDimensionKey,
        additional?: any,
        filters?: Array<ReportingLookupFilter>,
        query?: string,
        limit?: number,
        page?: number
    ): Promise<BulkLookupResponse> {
        // HACKTOWN: /byDimensions doesn't work for CAMPAIGN custom fields.  Have to look them
        // up a separate way
        if (engine === 'CAMPAIGN' && dimension.isCustom && this.cacheValueLabels.isEmpty()) {
            const dimensions = [
                { field: dimension.name, unique: dimension.name + ':' + dimension.name },
            ];
            return this.loadCampaignValueLabels(dimensions).then(response => {
                return this.getValueLabels2(
                    engine,
                    report,
                    dimension,
                    additional,
                    filters,
                    query,
                    limit,
                    page,
                    null,
                    true
                );
            });
        } else if (engine === 'MONITORING_DASHBOARD') {
            return this.getMonitoringDashboardValues(filters, query);
        } else {
            return new Promise(async (resolve, reject) => {
                try {
                    const data = await this.getValueLabels2(
                        engine,
                        report,
                        dimension,
                        additional,
                        filters,
                        query,
                        limit,
                        page,
                        null,
                        true
                    );

                    resolve(data);
                } catch (error) {
                    reject(error);
                }
            });
        }
    }

    // Get all value labels for a dimension
    private async getValueLabels2(
        engine: AnalyticsEngine,
        report: string,
        dimension: WidgetDimensionKey,
        additional?: any,
        lookupFilters?: Array<ReportingLookupFilter>,
        query?: string,
        limit?: number,
        page?: number,
        limitOverride?: number,
        noCache?: boolean
    ): Promise<BulkLookupResponse> {
        const filters = toJS(lookupFilters);

        if (filters?.length) {
            const clientIdFilter = filters.find(
                filter =>
                    filter.field === 'CLIENT_ID' &&
                    filter.values?.length &&
                    filter.values[0] === 'all'
            );

            if (clientIdFilter) {
                // console.log('doing an "all clients" lookup');
                const dimensionKey = WidgetDimensionKey.create(clientIdFilter);
                clientIdFilter.values = await this.getValueLabels(
                    engine,
                    report,
                    dimensionKey
                ).then(data => data.values?.map(label => label.id));
            }
        }

        if (
            MetaInfoService.isDimLookupWhereFiltersAreIgnored(
                engine,
                report,
                dimension,
                filters,
                query
            )
        ) {
            return this.getValueLabelsForUnFilterableDims(engine, report, dimension, filters);
        }

        return new Promise((resolve, reject) => {
            if (engine === 'TWITTER') {
                switch (dimension.name) {
                    case 'WOEID':
                        resolve({
                            hasMore: false,
                            values: TrendService.twitterCountryOptions.map(countryOption => {
                                return {
                                    id: countryOption.value + '',
                                    name: countryOption.label,
                                };
                            }),
                        });
                        return;
                }
            }

            // Custom dimension labels lookup does not work for CAMPAIGN engine
            const bypassCache =
                noCache ||
                (!!filters && engine !== 'CAMPAIGN') ||
                dimension.name === 'FROM_SN_USER';
            const unique = dimension.toString() + (additional ? JSON.stringify(additional) : '');

            const cached = bypassCache ? null : this.cacheValueLabels.get(unique);
            if (cached) {
                resolve({ hasMore: false, values: cached });
                return;
            }

            const lookupType = dimension.name;

            if (!noCache) {
                if (lookupType === 'BENCHMARKING_ACCOUNT_ID') {
                    limit = 1000;
                    // Champagne has ~38k brand ids as of 12/03/19
                } else if (lookupType === 'BRAND_ID') {
                    limit = 50000;
                    // Certain dimensions in BENCHMARKING fail if limit is > 20000
                } else if (engine === 'BENCHMARKING') {
                    limit = 20000;
                }
            } else if (!limit) {
                limit = 5000;
            }

            const args = {
                filters: filters || [],
                dimensionLookupRequests: [
                    {
                        lookupType: lookupType,
                        query: query || '',
                        page: {
                            page: page || 0,
                            size: limit || limitOverride || 150000, // 2021-1-28 -- up to 150K for Jack in the Box CITY lookup
                        },
                        additional: {
                            engine: engine,
                            reportName: report,
                        },
                    },
                ],
            };

            // If clientId is specified for this dimension, add it for the request, otherwise won't work
            //   https://sprinklr.atlassian.net/browse/DISPLAY-911
            if (dimension.clientId) {
                args.dimensionLookupRequests[0].additional['clientId'] = dimension.clientId;
            }

            // If we have an "additional" override, use it as-is
            if (additional) {
                args.dimensionLookupRequests[0].additional = additional;
            }

            // Add this if this is a custom field
            if (dimension.customName) {
                args.dimensionLookupRequests[0].additional['fieldName'] = dimension.customName;
            }

            // I know, using time ranges for value lookups seems retarded, but the Sprinklr API demands
            // at least for benchmarking:
            //  https://sprinklr.atlassian.net/browse/DISPLAY-1161
            // Using end-of-day so it can work as a cache key - if it's a different timestamp every call, no caching!
            const eod = moment(Date.now()).endOf('day');
            args.dimensionLookupRequests[0].additional['sinceTime'] = eod
                .clone()
                .subtract(1, 'month')
                .valueOf();
            args.dimensionLookupRequests[0].additional['untilTime'] = eod.valueOf();

            const promiseKey = JSON.stringify(args);

            if (!this.byDimensionsPromises[promiseKey]) {
                this.byDimensionsPromises[promiseKey] = this.axios.post(
                    'api/v3.1/reporting/insights/bulklookup/byDimensions',
                    args
                );
                // console.log('promise keys', {keys: Object.keys(this.byDimensionsPromises)});
            }

            return this.byDimensionsPromises[promiseKey]
                .then(response => {
                    if (
                        !response.data ||
                        !response.data[lookupType] ||
                        !response.data[lookupType].result
                    ) {
                        resolve({ hasMore: false, values: [] });
                        return;
                    }

                    const hasMore = response.data[lookupType].hasMore;
                    let result = response.data[lookupType].result;

                    // Run a check to see if we need to convert the strange values format
                    // we get for dimensions like ACCOUNT_ID, AFFECTED_USER_ID and USER_ID
                    if (
                        result.length > 0 &&
                        (!result[0].name || dimension.name === 'MEDIA_ASSET_ID')
                    ) {
                        // Convert to the expected format
                        // Note: https://jsperf.com/fast-array-foreach
                        let x = result.length;
                        while (x--) {
                            if (typeof result[x] === 'object') {
                                MetaInfoService.normalizeBulkItem(result[x], dimension);
                                // Special case to deal with emjois response with is array of strings
                                //. https://sprinklr.atlassian.net/browse/DISPLAY-1663
                            } else if (typeof result[x] === 'string') {
                                result[x] = { id: result[x], name: result[x] };
                            }
                        }
                    }

                    result = response.data[lookupType].result.filter(value => {
                        if (typeof value === 'string') {
                            return true;
                        }
                        if (typeof value === 'object') {
                            // Filter out empty ids that Sprinklr sometimes returns
                            // (encountered when using custom fields for Santender)
                            if (value.id === undefined || value.id === null || value.id === '') {
                                return false;
                            }

                            // HACKTOWN: Stupid Sprinklr returns "Update" and "Twitter Update" with same id!
                            // Corie said to filter out the "Update" version,
                            // but that upset McDonalds and there are others, like "Instagram Update"
                            // so we are leaving "Update"
                            return (
                                lookupType !== 'SN_MESSAGE_TYPE' ||
                                value.id !== '2' ||
                                value.name === 'Update'
                            );
                        }
                        return false;
                    });

                    // Run a check to see if we need to convert the strange values format
                    // we get for dimensions like ACCOUNT_ID, AFFECTED_USER_ID and USER_ID
                    if (
                        result.length > 0 &&
                        (!result[0].name ||
                            dimension.name === 'MEDIA_ASSET_ID' ||
                            dimension.name === 'BENCHMARKING_ACCOUNT_ID')
                    ) {
                        // Convert to the expected format
                        // Note: https://jsperf.com/fast-array-foreach
                        let x = result.length;
                        while (x--) {
                            MetaInfoService.normalizeBulkItem(result[x], dimension);
                        }
                    }

                    if (!bypassCache) {
                        this.cacheValueLabels.set(unique, result);

                        // do indexed per-item caching for special non-filterable dimensions
                        // this allows subsequent filtered requests to take advantage of big unfiltered responses, saving
                        // many small network requests for data we've already fetched.
                        if (
                            result.length > 0 &&
                            MetaInfoService.unFilterableDimensions[dimension.name]
                        ) {
                            const dimCache = this.ensureUnFilterableDimensionsCache(dimension.name);
                            let x = result.length;
                            while (x--) {
                                dimCache[result[x].id] = result[x];
                            }
                        }
                    }

                    resolve({ hasMore: hasMore, values: result });
                })
                .catch((error: any) => {
                    this.byDimensionsPromises[promiseKey] = null;
                    reject(error);
                });
        });
    }

    private getMonitoringDashboardValues(
        filters?: Array<ReportingLookupFilter>,
        query?: string
    ): Promise<BulkLookupResponse> {
        return new Promise(async (resolve, reject) => {
            try {
                MetaInfoService.loaderDashboards.then((dashboards: MonitoringDashboard[]) => {
                    // can be dashboards or columns
                    let result: MonitoringDashboard[] | MonitoringColumn[] = dashboards.slice();

                    if (filters?.length) {
                        result = result.filter((dashboard: any) => {
                            return filters[0].values.indexOf(dashboard.id) !== -1;
                        });

                        let x = result.length;
                        if (x) {
                            const columns: MonitoringColumn[] = [];
                            while (x--) {
                                columns.push(...result[x].columns);
                            }

                            const sort = (a: any, b: any) => {
                                const nameA = a.name.toUpperCase();
                                const nameB = b.name.toUpperCase();

                                if (nameA < nameB) {
                                    return -1;
                                } else if (nameA > nameB) {
                                    return 1;
                                } else {
                                    return 0;
                                }
                            };

                            columns.sort(sort);

                            result = columns;
                        }
                    }

                    if (query?.length) {
                        query = query.toLowerCase();
                        result = (result as any[]).filter(
                            (item: MonitoringDashboard | MonitoringColumn) => {
                                return item.name.toLowerCase().indexOf(query) !== -1;
                            }
                        );
                    }

                    resolve({ hasMore: false, values: result });
                });
            } catch (error) {
                reject(error);
            }
        });
    }

    // Handle filtered bulklookup/byDimensions requests that have no filter capability in core.
    // Requests for dim name "LST_THEME" and "TOPIC_TAGS" both fall into this category - for the code paths from
    // bulklookup/byDimensions the filters param is completely ignored and full, unfiltered lists are returned.
    // see these classes in sprinklr main codebase:
    // com.spr.shifu.lookup.impl.listening.TopicTagLookup
    // com.spr.shifu.lookup.impl.listening.ListeningThemeLookup
    // when the filtering is like this:
    // {
    //   "filters": [
    //     {
    //       "field": "TOPIC_TAGS",
    //       "filterType": "IN",
    //       "values": [
    //         "CL - All of Dell - GL",
    //         "ET - All of Dell - GL"
    //       ]
    //     }
    //   ],
    //   "dimensionLookupRequests": [
    //     {
    //       "lookupType": "TOPIC_TAGS",
    //       "query": "",
    //       "page": {
    //         "page": 0,
    //         "size": 20000
    //       },
    //       "additional": {
    //         "engine": "LISTENING",
    //         "reportName": "SPRINKSIGHTS",
    //         "sinceTime": 1563778799999,
    //         "untilTime": 1566457199999
    //       }
    //     }
    //   ]
    // }
    // We're asking for a lookup for the individual listed items, but we'll get back the full list up to page.size and
    // in the case of Dell this can take upwards of 5 seconds (+20K items) and wasn't cached on our side due to presence
    // of the filters - so special-case it, use caching where we can and do the query better when there's a cache miss.
    private getValueLabelsForUnFilterableDims(
        engine: AnalyticsEngine,
        report: string,
        dimension: WidgetDimensionKey,
        filters?: ReportingLookupFilter[]
    ): Promise<BulkLookupResponse> {
        return new Promise((resolve, reject) => {
            const lookupType = dimension.name;
            const keyFilter =
                filters && filters.find(f => f.filterType === 'IN' && f.field === lookupType);

            const keys = keyFilter ? (keyFilter as ReportingLookupFilter).values : [];

            if (this.cacheUnFilterableDimensions[lookupType]) {
                const cachedResults = keys.map(
                    key => this.cacheUnFilterableDimensions[lookupType][key]
                );

                if (keys.length === cachedResults.length) {
                    // found everything we need!
                    return { hasMore: false, values: cachedResults };
                }
            }

            // this must match com.spr.reporting.rest.LookupRequest from sprinklr/shifu/src/main/java/com/spr/reporting/rest/LookupRequest.java
            const args = {
                lookupType: dimension.name,
                keys,
                extraParams: {
                    engine,
                    report,
                    deleted: false, // ??
                    inaccessible: true, // ??
                },
            };

            const promiseKey = JSON.stringify(args);

            // note we're using bulklookup, not bulklookup/byDimensions. Query format is different.

            const lookupPromise = this.axios.post('api/v3.1/reporting/insights/bulklookup', args);

            return lookupPromise.then(response => {
                if (
                    !response.data ||
                    !response.data[lookupType] ||
                    !response.data[lookupType].lookupValues
                ) {
                    resolve({ hasMore: false, values: [] });
                }

                const result = keys.map(key => response.data[lookupType].lookupValues[key]);

                const dimCache = this.ensureUnFilterableDimensionsCache(dimension.name);
                let x = result.length;
                while (x--) {
                    dimCache[result[x].id] = result[x];
                }

                resolve({ hasMore: response.data[lookupType].hasMore, values: result });
            });
        });
    }

    static isDimLookupWhereFiltersAreIgnored(
        engine: AnalyticsEngine,
        report: string,
        dimension: WidgetDimensionKey,
        filters?: Array<ReportingLookupFilter>,
        query?: string
    ): boolean {
        if (query) {
            return false; // don't mess up a text query, like complete-as-you-type list searches
        }

        const valid =
            (engine === 'LISTENING' && report === 'SPRINKSIGHTS') ||
            (engine === 'PLATFORM' && report === 'POST_INSIGHTS') ||
            (engine === 'INBOUND_MESSAGE' && report === 'INBOUND_MESSAGE');

        if (!valid || !dimension || !filters || filters.length > 1) {
            return false;
        }

        // This method is only valid for id-based lookups, so make sure it's an IN filter looking at the dimension name.

        const filter: ReportingLookupFilter = filters[0];
        if (!filter || filter.filterType !== 'IN') {
            return false;
        }

        return (
            MetaInfoService.unFilterableDimensions[dimension.name] &&
            filter.field === dimension.name
        );
    }

    private ensureUnFilterableDimensionsCache(cacheName: string): any {
        if (!this.cacheUnFilterableDimensions[cacheName]) {
            this.cacheUnFilterableDimensions[cacheName] = {};
        }

        return this.cacheUnFilterableDimensions[cacheName];
    }

    private isRequestForPresetNeeded(engine: AnalyticsEngine): boolean {
        // TODO when trying to do this differently, was hitting similar to https://github.com/Microsoft/TypeScript/issues/11533
        if (!engine) {
            return false;
        } else if (this.cacheSLAPresets[engine]) {
            return false;
        } else if (engine === 'PLATFORM') {
            return true;
        } else if (engine === 'BENCHMARKING') {
            return true;
        }

        return false;
    }

    loadSLAPresets(engine: AnalyticsEngine): void {
        const promise = new Promise<void>((resolve, reject) => {
            if (!this.isRequestForPresetNeeded(engine)) {
                resolve();
            } else {
                this.axios
                    .get('api/v3.1/reports/v6/config/sla')
                    .then((result: any) => {
                        this.cacheSLAPresets[engine] = result.data;
                        resolve();
                    })
                    .catch((error: any) => {
                        reject(error);
                    });
            }
        });

        MetaInfoService.loaderSLAPresets = fromPromise(promise);
    }

    loadDashboards(): Promise<MonitoringDashboard[]> {
        const promise: Promise<MonitoringDashboard[]> = new Promise((resolve, reject) => {
            if (this.dashboards) {
                resolve(this.dashboards);
            }

            const q = `
                {
                    client {
                        monitoringDashboards {
                            id
                            name
                            locked
                            shared
                            columnOrder
                            columns {
                                id
                                dashboardId
                                name
                                description
                                ownerUserId
                                channel
                                type
                                sourceType
                            }
                        }
                    }
                }`;

            return this.graphQLService
                .query({
                    query: q,
                    variables: null,
                })
                .then((response: any): void => {
                    this.dashboards = response.client.monitoringDashboards.sort((a, b) => {
                        return a.name
                            ?.trim()
                            .toLowerCase()
                            .localeCompare(b.name?.trim().toLowerCase());
                    });

                    resolve(this.dashboards);
                })
                .catch((error: any) => {
                    if (error && error.data && error.data.errors) {
                        console.error('loadDashboards: ', JSON.stringify(error.data.errors));
                    }

                    reject(error);
                });
        });

        MetaInfoService.loaderDashboards = fromPromise(promise);

        return promise;
    }

    getUserAccessibleAccountIds() {
        if (this.cacheAccounts) {
            return this.cacheAccounts;
        }

        const url = 'api/v3.1/bootstrap/resources?types=USER_ACCESSIBLE_CLIENT_ACCOUNT_IDS';

        this.cacheAccounts = this.axios
            .get(url)
            .then((result: any) => {
                const unique = {};

                const data = result?.data?.USER_ACCESSIBLE_CLIENT_ACCOUNT_IDS;
                if (data) {
                    for (const clientId in data) {
                        const accountIds = data[clientId];
                        accountIds.forEach(accountId => (unique[accountId] = true));
                    }

                    return Object.keys(unique);
                }

                return null;
            })
            .catch((error: any) => {
                console.log('Unable to load user accessible accounts');
            });

        return this.cacheAccounts;
    }

    /**
     * Example Space search for custom dims:
     * {
          "query": "",
          "tags": [],
          "fetchFilterDimensions": false,
          "fetchPivotDimensions": false,
          "chartType": "DUAL_AXIS",
          "fields": [
            "D_DATE",
            "M_C_5D6D33A25E38C8A4DD142AD3_c_5d6d33a25e38c8a4dd142ad3",
            "M_VISTOR_CALCULATED_CARDINALITY_2_VISTOR_CALCULATED_CARDINALITY_2"
          ],
          "fieldTypeEntities": [ // name and format of this is different in Space API
            {
              "id": "CUSTOM_DIMENSION",
              "supportedFieldTypes": "CUSTOM_DIMENSION",
              "groupIds": [
                "NO_GROUP"
              ]
            }
          ],
          "page": {
            "page": 0,
            "size": 40
          }
        }
     * @param query user-supplied search string
     * @param engine
     * @param fields to limit the search
     * @param custom
     * @param page zero-based
     */
    searchDimensions(
        engine: AnalyticsEngine,
        report: string,
        filterDimensions: boolean,
        pivotDimensions: boolean,
        dimensions: string[],
        page: number,
        pageCount: number,
        query = '',
        pickerDimension?: boolean
    ): Promise<MetaSearchResponse> {
        if (engine === 'TWITTER') {
            return this.searchDimensionsTwitter(report);
        }

        const fieldTypes: FieldTypes[] = [
            'DIMENSION',
            'CUSTOM_DIMENSION',
            'MEASUREMENT',
            'CUSTOM_MEASUREMENT',
        ];
        const search = new MetaSearchRequest(
            query,
            fieldTypes,
            page,
            pageCount,
            dimensions,
            report
        );

        // If searchDimensions is being called in PickerDimension, fetchFilterDimensions is set to false so that search will also return metrics
        search.fetchFilterDimensions = pickerDimension ? false : !!filterDimensions; // required - undefined NOT ok with API
        search.fetchPivotDimensions = !!pivotDimensions; // required - undefined NOT ok with API

        const url = `api/v3.1/reports/search/${engine}`;
        return this.axios.post(url, search).then(response => {
            const result = response.data;

            if (result && page === 0 && filterDimensions) {
                // HACKTONW: Special case because search endpoint doesn't return "LST_THEME_TAGS"
                if (engine === 'LISTENING') {
                    this.addHardcodedDimensions(query, result.fieldResponse);
                }
            }

            return result as Promise<MetaSearchResponse>;
        });

        // TODO:  Not sure how to handle these from old approach yet
        // if (engine === 'CAMPAIGN') {
        //     // Add this synthentic metric so we can show in post cards and sort by this
        //     data.push({
        //         name: 'SCHEDULE_DATE',
        //         fieldType: 'NORMAL',
        //         displayName: 'Schedule Date',
        //         filterDimension: false,
        //     });

        //     // Add this synthentic metric.  It's not in /allFacets list but it
        //     // does appear in Additional Properties in Sprinklr UI.
        //     data.push({
        //         name: 'THEME_ID',
        //         fieldType: 'NORMAL',
        //         displayName: 'Theme',
        //         filterDimension: true,
        //     });

        //     const dimensions = this.loadCampaignValueLabels(data.CAMPAIGN.dimensions)
        //         .then(response => {
        //             resolve();
        //         })
        //         .catch((error: any) => {
        //             reject(error);
        //         });
        // }
    }

    private addHardcodedDimensions(query: string, response: any[]) {
        if (!query?.length) {
            response.push.apply(response, hardcodedDimensions);
            return;
        }

        query = query.toLowerCase();

        hardcodedDimensions.forEach(dimension => {
            if (dimension.displayName.toLowerCase().indexOf(query) !== -1) {
                response.push(dimension);
            }
        });
    }

    private searchDimensionsTwitter(report: string) {
        let results = [];

        if (report === 'TRENDS') {
            results = twitterDimensions;
        }

        return Promise.resolve({
            hasMore: false,
            fieldResponse: results,
            fieldDetails: [],
        });
    }

    /**
     * Searches using com.spr.reporting.rest.ReportingRestAPI#search
     * @param query
     * @param engine
     * @param page
     */
    searchMetrics(
        engine: AnalyticsEngine,
        report: string,
        page: number,
        pageCount: number,
        query = ''
    ): Promise<MetaSearchResponse> {
        if (engine === 'TWITTER') {
            return this.searchMetricsTwitter(report);
        }

        const url = `api/v3.1/reports/search/${engine}`;
        const search = new MetaSearchRequest(
            query,
            ['MEASUREMENT', 'CUSTOM_MEASUREMENT'],
            page,
            pageCount,
            [],
            report
        );

        return this.axios.post(url, search).then(res => {
            return res.data;
        }) as Promise<MetaSearchResponse>;
    }

    private searchMetricsTwitter(report: string) {
        let results = [];

        if (report === 'TRENDS') {
            results = twitterMetrics;
        }

        return Promise.resolve({
            hasMore: false,
            fieldResponse: results,
            fieldDetails: [],
        });
    }

    /**
     * Lookup info on dimensions/metrics using com.spr.reporting.rest.ReportingRestAPI#search
     * @param engine
     * @param metrics
     */
    lookupFields(
        engine: AnalyticsEngine,
        report: string,
        fields: string[]
    ): Promise<MetaFieldResponse[]> {
        return this.lookupFieldsInternal(engine, report, fields).then(data => {
            const results = data?.fieldResponse || [];
            if (report && results) {
                return results.filter(item => item.reports?.indexOf(report) !== -1);
            }

            return results;
        });
    }

    /**
     * Lookup info on dimensions/metrics using com.spr.reporting.rest.ReportingRestAPI#search
     * @param engine
     * @param metrics
     */
    private lookupFieldsInternal(
        engine: AnalyticsEngine,
        report: string,
        metrics: string[]
    ): Promise<MetaSearchResponse> {
        const found = this.getFieldsCache(engine, report, metrics[0]);
        if (found) {
            return found;
        }

        const promise = new Promise<MetaSearchResponse>((resolve, reject) => {
            if (!engine || engine?.trim() === '') {
                throw new Error('engine is required');
            }

            if (!metrics || metrics?.length === 0) {
                throw new Error('metrics are required');
            }

            const url = `api/v3.1/reports/lookup/${engine}`;

            this.axios
                .post(url, { fieldNames: metrics })
                .then(response => {
                    resolve(response?.data);
                })
                .catch((error: any) => {
                    reject(error);
                });
        });

        this.setFieldsCache(engine, report, metrics[0], promise);

        return promise;
    }

    private getFieldsCache(
        engine: string,
        report: string,
        key: string
    ): Promise<MetaSearchResponse> {
        return this.cacheFields[engine]?.[report]?.[key];
    }

    private setFieldsCache(
        engine: string,
        report: string,
        key: string,
        data: Promise<MetaSearchResponse>
    ) {
        if (!this.cacheFields[engine]) {
            this.cacheFields[engine] = {};
        }

        if (!this.cacheFields[engine][report]) {
            this.cacheFields[engine][report] = {};
        }

        this.cacheFields[engine][report][key] = data;
    }

    // Combine all metrics across all reports in result as assign each one the report in came from
    private getMetricsCombined(
        engine: string,
        data: any,
        sort: (a: any, b: any) => number
    ): Array<any> {
        const result: Array<any> = [];
        let measurement: any;
        let report: any;
        let x: number;
        const filterDuplicates = engine === 'PAID';
        const duplicateCheck = {};

        const addMeasurement = (key: string) => {
            report = data[key];

            x = report.measurements.length;

            while (x--) {
                measurement = report.measurements[x];

                if (!filterDuplicates || !duplicateCheck[measurement.name]) {
                    measurement.unique = measurement.name + ':' + key;
                    measurement.report = key;
                    result.push(measurement);
                }

                if (filterDuplicates) {
                    if (!duplicateCheck[measurement.name]) {
                        duplicateCheck[measurement.name] = measurement;
                    } else if (!duplicateCheck[measurement.name].reportDups) {
                        duplicateCheck[measurement.name].reportDups = [key];
                    } else {
                        duplicateCheck[measurement.name].reportDups.push(key);
                    }
                }
            }
        };

        // Sort the reports with the lowest priority report first so that duplicate measurements
        // are filtered out.  This is a requirement for PAID.
        const keys = this.sortReportsByPriority(data);

        keys.forEach(key => {
            addMeasurement(key);
        });

        result.sort(sort);

        return result;
    }

    private sortReportsByPriority(data: any): string[] {
        return Object.keys(data).sort((a, b) => {
            const first = data[a].priority;
            const second = data[b].priority;
            return first > second ? 1 : second > first ? -1 : 0;
        });
    }

    // Encountered with client Santender.  They have duplicate custom fields with the same name
    // like "Promotion", which is confusing to see in the UI.  I now append the custom field type
    // like "inbound" and "outbound" to clarify which is which.  Sprinklr's own UI currently
    // just shows the duplicates without any clarification.  Lame!
    private uniquelyIndentify(items: Array<any>): void {
        let x = items.length;
        while (x--) {
            const item = items[x];

            WidgetDimensionKey.customUniquelyIndentify(item);

            if (x > 0 && item.displayName === items[x - 1].displayName) {
                this.renameUnique(item);
                this.renameUnique(items[x - 1]);
            }
        }
    }

    private renameUnique(item: any): void {
        if (item.name.indexOf('INBOUND') === 0) {
            item.displayName += ' (inbound)';
        } else if (item.name.indexOf('OUTBOUND') === 0) {
            item.displayName += ' (outbound)';
        }
    }

    private loadCampaignValueLabels(dimensions: any[]): Promise<any> {
        return new Promise<void>((resolve, reject) => {
            // CAMPAIGN engine requires a different call to get custom dimensions values
            const url = 'api/v3.1/bootstrap/resources?types=OUTBOUND_CUSTOM_FIELDS';

            this.axios
                .get(url)
                .then((result: any) => {
                    const custom = result.data.OUTBOUND_CUSTOM_FIELDS;

                    // Pre-populate the values for these custom dimensions in the cache
                    let x = custom.length;
                    while (x--) {
                        if (custom[x].options) {
                            const found = dimensions.find(
                                item => item.field === custom[x].fieldName
                            );
                            if (found) {
                                const labels = custom[x].options.map(item => {
                                    return { id: item, name: item };
                                });
                                this.cacheValueLabels.set(found.unique, labels);
                            }
                        }
                    }

                    resolve();
                })
                .catch((error: any) => {
                    reject(error);
                });
        });
    }
}
