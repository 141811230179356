import * as React from 'react';
import { observer } from 'mobx-react';
import styled, { css } from 'components/_UI/designSystem';
import { PostEntities } from '@sprinklr/stories/post/Post';
import generateFragments from '../../../utils/EntityUtils/EntityUtils';
import NativeFlex from '../Bits/NativeFlex';
import { NativeColorMode } from '../../../widgets/PostsWidget/types';
import { getNativeColor } from '../Helpers';
import { parse } from 'twemoji-parser';
import { Emoji } from '@sprinklr/stories/widget/types';

// NativeText
type FontSize = 's' | 'm' | 'l';
export type LineClamp = {
    enabled?: boolean;
    lines?: number;
    hasMedia?: {
        enabled?: boolean;
        lines?: number;
    };
};
export type NativeTextProps = {
    text: any;
    language?: string;
    rtl?: boolean;
    colorMode?: NativeColorMode;
    textEntities: PostEntities[];
    maxCharacters: number;
    channel: string;
    removeUrls?: boolean;
    hasMedia?: boolean;
    lineClamp?: LineClamp;
    emoji?: Emoji;
};

type StyledTextProps = {
    size?: FontSize;
    lineClamp?: LineClamp;
};

const getLineClamp = lineClamp => {
    if (lineClamp && lineClamp.enabled) {
        return css`
            -webkit-line-clamp: ${lineClamp.lines};
            display: -webkit-box;
            -webkit-box-orient: vertical;
        `;
    }
};

const colors = {
    darkGrey: '#333',
    lightGrey: '#bababd',
};

const theme = {
    light: {
        text: colors.darkGrey,
    },
    dark: {
        text: colors.lightGrey,
    },
};
type StyledProps = {
    colorMode?: NativeColorMode;
    size?: FontSize;
    lineClamp?: LineClamp;
    maxCharacters?: number;
    rtl?: boolean;
};
export const StyledEmoji = styled.img`
    vertical-align: middle;
`;
const StyledNativeText = styled(NativeFlex)<StyledProps>`
    ${({ size }) =>
        size === 's' &&
        css`
            font-size: 1em;
        `};
    ${({ size }) =>
        size === 'l' &&
        css`
            font-size: 1.5em;
        `};
    ${({ rtl }) =>
        rtl === true &&
        css`
            direction: rtl;
            text-align: right;
        `};

    ${({ colorMode }) =>
        css`
            color: ${getNativeColor(theme, colorMode, 'text')};
        `}
    ${StyledEmoji} {
        height: 1em;
        width: 1em;
    }

    font-weight: 400;
    line-height: 1.2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-word;
    ${props => getLineClamp((props as StyledTextProps).lineClamp)}
`;
StyledNativeText.displayName = 'StyledNativeText';

// These are exposed to per network overrides
export const StyledHashtag = styled.span``;
export const StyledScreenName = styled.span``;
export const StyledUrl = styled.span``;

const NativeText: React.FC<NativeTextProps> = ({
    text,
    textEntities = [],
    rtl,
    language,
    removeUrls = true,
    maxCharacters = 450,
    emoji = {
        platform: 'default',
    },
    channel,
    lineClamp = {
        enabled: true,
        lines: 10,
        hasMedia: {
            enabled: true,
            lines: 7,
        },
    },
    hasMedia,
    colorMode = 'light' as NativeColorMode,
}) => {
    const emojiMap = parse(text);
    const emojiEntities: PostEntities[] =
        emojiMap && emojiMap.length
            ? emojiMap.map(item => {
                  return {
                      indices: item.indices,
                      [item.type]: item.url,
                      source: item.text,
                  };
              })
            : [];
    const entitiesMerged: PostEntities[] =
        emoji && emoji?.platform === 'twitter'
            ? [...(!!textEntities ? textEntities : []), ...emojiEntities]
            : textEntities;
    const fragments = generateFragments({
        text,
        textEntities: entitiesMerged,
        maxCharacters,
        channel,
    });

    return (
        <StyledNativeText
            colorMode={colorMode}
            data-id='native-text'
            noGrow
            dir={rtl ? 'rtl' : undefined}
            rtl={rtl}
            lang={language}
            lineClamp={
                (hasMedia && lineClamp.hasMedia ? lineClamp.hasMedia : lineClamp) as LineClamp
            }
            maxCharacters={maxCharacters}
            size={hasMedia ? 's' : 'l'}>
            {fragments.map((fragment, index) => {
                return (
                    <React.Fragment key={index}>
                        {!fragment.type && <span>{` ${fragment.value} `}</span>}
                        {fragment?.type === 'hashtag' && (
                            <StyledHashtag>{` ${fragment.value}`}</StyledHashtag>
                        )}
                        {fragment?.type === 'emoji' && (
                            <>
                                <span>{` `}</span>
                                <StyledEmoji src={fragment.url} />
                            </>
                        )}
                        {fragment?.type === 'screenName' && (
                            <StyledScreenName>{` ${fragment.value}`}</StyledScreenName>
                        )}
                        {fragment?.type === 'url' && !removeUrls && (
                            <StyledUrl>{` ${fragment.value}`}</StyledUrl>
                        )}
                    </React.Fragment>
                );
            })}
        </StyledNativeText>
    );
};

NativeText.displayName = 'NativeText';
export default observer(NativeText);
export { StyledNativeText };
