import { AnalyticsRequest, GroupBy, Metric, Sort } from './PanelTemplatesTheme/PanelTemplatesTheme';
import { Panel } from '../../models/Panel/Panel';
import { WIDGET_LABEL_SIZE } from '../../components/Widget/WidgetLabel/constants';
import ListeningDimensions from '@sprinklr/stories-services/AnalyticsService/ListeningDimensions';
import ListeningMetrics from '@sprinklr/stories-services/AnalyticsService/ListeningMetrics';

export var TemplateDynamicImage: Panel = {
    id: 'dynamic-image',
    name: 'Dynamic Image',
    description: '',
    tags: ['Standard', 'Dynamic Image', 'landscape', 'portrait'],
    widget: {
        id: '1',
        position: {
            width: '1920px',
            height: '1080px',
            top: '0',
            left: '0',
        },
        theme: {
            ext: {
                padding: 0,
            },
        },
        classes: 'template_dynamic_image',
        children: [
            {
                type: 'dynamicImage',
                id: 'dynamic-image',
                name: 'Dynamic Image',
                analyticsRequests: [
                    // Note: All global variables like reportingEngine, report, filters, and projections
                    // are injected via AnalyticsRequest.getRequests()
                    new AnalyticsRequest({
                        limit: 100,
                        groupBys: [
                            new GroupBy({
                                LISTENING: { source: ListeningDimensions.topicName },
                                INBOUND_MESSAGE: { dimensionName: 'SN_TYPE' },
                                PLATFORM: { dimensionName: 'SN_TYPE' },
                                BENCHMARKING: { dimensionName: 'BENCHMARKING_SN_TYPE' },
                                PAID: { dimensionName: 'accountIds' },
                                ADVOCACY: { dimensionName: 'COMMUNITY_USER_ID' },
                                TWITTER: { dimensionName: 'TRENDING_TOPIC' },
                                RDB_FIREHOSE: { dimensionName: 'RDB_QUERY_IDS' },
                            }),
                        ],
                        projections: [
                            new Metric({
                                LISTENING: { source: ListeningMetrics.mentions },
                                INBOUND_MESSAGE: { dimensionName: 'OUTBOUND_COUNT' },
                                PLATFORM: { dimensionName: 'ENGAGEMENT_RATE' },
                                BENCHMARKING: { dimensionName: 'ENGAGEMENT_RATE' },
                                PAID: { dimensionName: 'impressions' },
                                ADVOCACY: {
                                    dimensionName: 'ADVOCACY_TOTAL_USER_ENGAGEMENTS',
                                    heading: 'Total_Engagements',
                                },
                                RDB_FIREHOSE: {
                                    dimensionName: 'MENTIONS_COUNT',
                                    heading: 'Mentions',
                                },
                            }),
                        ],
                        sorts: [new Sort({ order: 'DESC', projections: 0 })],
                        timePeriods: [{ key: 'last_7_days' }, { previousPeriod: true }],
                    }) as any,
                ],
                label: {
                    titleText: 'Dynamic Image',
                    enabled: false,
                    size: WIDGET_LABEL_SIZE.primary,
                },
                position: {
                    width: '100%',
                    height: '100%',
                    top: '0',
                    left: '0',
                },
                options: {
                    player: {
                        playing: true,
                        duration: 15,
                    },
                    smartSize: {
                        enabled: true,
                        mode: 'responsive',
                        size: 'm',
                    },
                    portrait: {
                        widgetOrientation: 'portrait',
                    },
                },
            },
        ],
    },
};
