import * as React from 'react';
import i18n from 'i18next';
import {
    gridLayoutTunables,
    headerTunables,
    metricValueTunables,
    opacityTunables,
    percentChangeTunables,
    previousValueTunables,
    smartSizeTunables,
} from 'components/Sidebar/RenderTunables/SharedTunables';
import { CollapseProps, TunablesCollapse } from 'components/Sidebar/RenderTunables/RenderTunables';
import { Widget } from '@sprinklr/stories/widget/Widget';
import { Theme } from 'models/Theme/Theme';

export const MultiMetricWidgetTunables = (
    widget: Widget,
    mergedTheme: Theme
): TunablesCollapse[] => [
    {
        props: {
            label: i18n.t('Multi Metric'),
            startOpen: true,
        } as CollapseProps,
        children: [...headerTunables(widget), opacityTunables(widget), smartSizeTunables(widget)],
    },
    gridLayoutTunables(widget, mergedTheme),
    {
        props: {
            label: i18n.t('Labels'),
            startOpen: true,
        } as CollapseProps,
        children: [
            ...metricValueTunables(widget, {
                value: { enable: true, size: true },
                format: true,
                name: true,
                change: true,
                case: true,
            }),
            ...percentChangeTunables(widget, mergedTheme, {
                highlight: true,
                format: true,
                color: true,
            }),
            ...previousValueTunables(widget),
        ],
    },
];

export default MultiMetricWidgetTunables;
