// This holds current Storyboard, Scene, and Panel so that external Javascript code can access
import { Storyboard } from 'models/Storyboard/Storyboard';
import { Scene } from 'models/Scene/Scene';
import { Panel } from 'models/Panel/Panel';
import { Widget } from '@sprinklr/stories/widget/Widget';
import { InstanceStore } from '../../containers/External/InstanceStore';

export default class JSContextService {
    storyboard: Storyboard = null;
    sceneId: string;
    panelId: string;

    private instanceStore: InstanceStore;

    constructor() {}

    getLocation(): Storyboard {
        if (this.instanceStore) {
            return this.instanceStore.location;
        } else {
            null;
        }
    }

    getStoryboard(): Storyboard {
        if (this.instanceStore) {
            return this.instanceStore.activeStoryboard;
        } else {
            return this.storyboard;
        }
    }

    getScene(widget: Widget): Scene {
        if (this.storyboard) {
            return this.findScene(widget);
        } else {
            return null;
        }
    }

    // getSceneOffset(): number {
    //     if (this.instanceStore) {
    //         return this.instanceStore.player.currentIndex;
    //     } else {
    //         return -1;
    //     }
    // }

    // setScenePrevious(): void {
    //     if (this.instanceStore) {
    //         this.instanceStore.next();
    //     }
    // }

    // setSceneNext(): void {
    //     if (this.instanceStore) {
    //         this.instanceStore.next();
    //     }
    // }

    getPanel(widget: Widget): Panel {
        if (this.instanceStore && this.instanceStore.panels) {
            return this.findPanelMap(widget, this.instanceStore.panels);
        } else if (this.storyboard) {
            return this.findPanel(widget);
        } else {
            return null;
        }
    }

    setIds(storyboard: Storyboard, sceneId: string, panelId: string) {
        this.storyboard = storyboard;
        this.sceneId = sceneId;
        this.panelId = panelId;
    }

    setInstanceStore(instanceStore: InstanceStore) {
        this.instanceStore = instanceStore;
    }

    private findScene(widget: Widget): Scene {
        if (this.storyboard.scenes) {
            let x = this.storyboard.scenes.length,
                y;
            let scene: Scene, panel: Panel;

            while (x--) {
                scene = this.storyboard.scenes[x];

                if (scene.panels) {
                    y = scene.panels.length;
                    while (y--) {
                        panel = scene.panels[y];

                        if (panel.widget) {
                            const result = this.findWidget(widget, panel.widget);
                            if (result) {
                                return scene;
                            }
                        }
                    }
                }
            }
        }

        return null;
    }

    private findPanel(widget: Widget): Panel {
        if (this.storyboard.scenes) {
            let x = this.storyboard.scenes.length,
                y;
            let scene: Scene, panel: Panel;

            while (x--) {
                scene = this.storyboard.scenes[x];

                if (scene.panels) {
                    y = scene.panels.length;
                    while (y--) {
                        panel = scene.panels[y];

                        if (panel.widget) {
                            const result = this.findWidget(widget, panel.widget);
                            if (result) {
                                return panel;
                            }
                        }
                    }
                }
            }
        }

        return null;
    }

    private findPanelMap(widget: Widget, panels: any): Panel {
        let panel: Panel;

        for (const id in panels) {
            panel = panels[id];

            if (panel.widget) {
                const result = this.findWidget(widget, panel.widget);
                if (result) {
                    return panel;
                }
            }
        }

        return null;
    }

    private findWidget(widget: Widget, parent: Widget): boolean {
        if (widget === parent) {
            return true;
        } else if (parent.children && parent.children.length) {
            let x = parent.children.length;
            while (x--) {
                if (this.findWidget(widget, parent.children[x])) {
                    return true;
                }
            }
        } else {
            return false;
        }
    }
}
