import * as React from 'react';
import i18n from 'i18next';
import {
    headerTunables,
    opacityTunables,
    emptyDataTunables,
    smartSizeTunables,
} from 'components/Sidebar/RenderTunables/SharedTunables';
import { CollapseProps, TunablesCollapse } from 'components/Sidebar/RenderTunables/RenderTunables';
import { Widget } from '@sprinklr/stories/widget/Widget';
import { AutoAlertsWidgetOptionsImpl } from 'src/widgets/AutoAlertsWidget/options';
import LabelGroup from './../../components/_UI/Forms/LabelGroup/LabelGroup';
import ActionCheckboxSliderGroup from '../../components/_UI/Forms/Actions/ActionCheckboxSliderGroup';
import ActionCheckboxGroup from '../../components/_UI/Forms/Actions/ActionCheckboxGroup';
import { Flexbox } from 'components/spaceKit';
import ActionColorSwatchGroup from '../../components/_UI/Forms/Actions/ActionColorSwatchGroup';
import ActionSliderGroup from 'components/_UI/Forms/Actions/ActionSliderGroup';

const AutoAlertsTunables = (widget: Widget): TunablesCollapse[] => [
    {
        props: {
            label: i18n.t('Auto Alerts'),
            startOpen: true,
        } as CollapseProps,
        children: [...headerTunables(widget), opacityTunables(widget), smartSizeTunables(widget)],
    },
    {
        props: {
            label: i18n.t('Tunables'),
            startOpen: true,
        } as CollapseProps,
        children: [
            <ActionCheckboxSliderGroup
                key='widget.options.title.enabled'
                label={i18n.t('Title')}
                showValue
                checkbox={{
                    option: (widget.options as AutoAlertsWidgetOptionsImpl).title,
                    value: 'enabled',
                }}
                slider={{
                    option: (widget.options as AutoAlertsWidgetOptionsImpl).title,
                    value: 'size',
                    min: 0,
                    max: 100,
                }}
            />,
            <ActionCheckboxGroup
                key='widget.options.altertTypeIcon.enabled'
                label={i18n.t('Icon')}
                option={(widget.options as AutoAlertsWidgetOptionsImpl).altertTypeIcon}
                value='enabled'
            />,
            <ActionCheckboxSliderGroup
                key='widget.options.description.enabled'
                label={i18n.t('Description')}
                showValue
                checkbox={{
                    option: (widget.options as AutoAlertsWidgetOptionsImpl).description,
                    value: 'enabled',
                }}
                slider={{
                    option: (widget.options as AutoAlertsWidgetOptionsImpl).description,
                    value: 'size',
                    min: 0,
                    max: 100,
                }}
            />,
            <ActionCheckboxSliderGroup
                key='widget.options.detectedTime.enabled'
                label={i18n.t('Detected Time')}
                showValue
                checkbox={{
                    option: (widget.options as AutoAlertsWidgetOptionsImpl).detectedTime,
                    value: 'enabled',
                }}
                slider={{
                    option: (widget.options as AutoAlertsWidgetOptionsImpl).detectedTime,
                    value: 'size',
                    min: 0,
                    max: 100,
                }}
            />,
            <ActionCheckboxGroup
                key='widget.options.sourcePost.enabled'
                label={i18n.t('Show Source Post')}
                option={(widget.options as AutoAlertsWidgetOptionsImpl).sourcePost}
                value='enabled'
            />,
            (widget.options as AutoAlertsWidgetOptionsImpl).sourcePost.enabled ? (
                <React.Fragment key='widget.options.sourcePost.dropshadow'>
                    <Flexbox key='widget.options.sourcePost.dropshadow' gap='l'>
                        <ActionCheckboxGroup
                            key='widget.options.sourcePost.dropshadow.enabled'
                            label={i18n.t('Drop Shadow')}
                            option={
                                (widget.options as AutoAlertsWidgetOptionsImpl).sourcePost
                                    .dropshadow
                            }
                            value='enabled'
                        />
                        {(widget.options as AutoAlertsWidgetOptionsImpl).sourcePost.dropshadow
                            .enabled && (
                            <LabelGroup key='widget.options.lineChart.line.color'>
                                <ActionColorSwatchGroup
                                    key='widget.options.sourcePost.dropshadow.color'
                                    option={
                                        (widget.options as AutoAlertsWidgetOptionsImpl).sourcePost
                                            .dropshadow
                                    }
                                    value='color'
                                />
                            </LabelGroup>
                        )}
                        ,
                    </Flexbox>
                    <ActionSliderGroup
                        key='widget.options.sourcePost.multi.slideDuration'
                        option={(widget.options as AutoAlertsWidgetOptionsImpl).sourcePost.multi}
                        value='slideDuration'
                        min={500}
                        max={60000}
                    />
                    <ActionCheckboxGroup
                        key='widget.options.sourcePost.useNativeEmbed'
                        label={i18n.t('Native Embed')}
                        option={(widget.options as AutoAlertsWidgetOptionsImpl).sourcePost}
                        value='useNativeEmbed'
                    />
                </React.Fragment>
            ) : null,
            <ActionCheckboxGroup
                key='widget.options.lineChart.enabled'
                label={i18n.t('Line Chart')}
                option={(widget.options as AutoAlertsWidgetOptionsImpl).lineChart}
                value='enabled'
            />,
            (widget.options as AutoAlertsWidgetOptionsImpl).lineChart.enabled ? (
                <ActionCheckboxGroup
                    key='widget.options.lineChart.cumulative'
                    label={i18n.t('Cumulative Series Data')}
                    option={(widget.options as AutoAlertsWidgetOptionsImpl).lineChart}
                    value='cumulative'
                />
            ) : null,
            <ActionCheckboxGroup
                key='widget.options.lineChart.spike.enabled'
                label={i18n.t('Spike')}
                option={(widget.options as AutoAlertsWidgetOptionsImpl).lineChart.spike}
                value='enabled'
            />,

            <LabelGroup label={i18n.t('Line')} key='widget.options.lineChart.line.color'>
                <ActionColorSwatchGroup
                    key='widget.options.lineChart.line.color'
                    option={(widget.options as AutoAlertsWidgetOptionsImpl).lineChart.line}
                    value='color'
                />
            </LabelGroup>,
            <ActionCheckboxSliderGroup
                key='widget.options.lineChart.points.enabled'
                label={i18n.t('Point Markers')}
                showValue
                checkbox={{
                    option: (widget.options as AutoAlertsWidgetOptionsImpl).lineChart.points,
                    value: 'enabled',
                }}
                slider={{
                    option: (widget.options as AutoAlertsWidgetOptionsImpl).lineChart.points,
                    value: 'size',
                    min: 0,
                    max: 100,
                }}
            />,
            <Flexbox key='widget.options.lineChart.fill' gap='l'>
                <ActionCheckboxGroup
                    key='widget.options.lineChart.fill.enabled'
                    label={i18n.t('Anomaly Fill')}
                    option={(widget.options as AutoAlertsWidgetOptionsImpl).lineChart.fill}
                    value='enabled'
                />
                <LabelGroup key='widget.options.lineChart.fill.color'>
                    <ActionColorSwatchGroup
                        option={(widget.options as AutoAlertsWidgetOptionsImpl).lineChart.fill}
                        value='color'
                    />
                </LabelGroup>
            </Flexbox>,
            <ActionCheckboxGroup
                key='widget.options.lineChart.segments.enabled'
                label={i18n.t('Anomaly Segments')}
                option={(widget.options as AutoAlertsWidgetOptionsImpl).lineChart.segments}
                value='enabled'
            />,
        ],
    },
    emptyDataTunables(widget),
];

export default AutoAlertsTunables;
