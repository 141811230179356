import moment, { Moment } from 'moment';
import Axios, { CancelTokenSource } from 'axios';
import { AnalyticsFilter, AnalyticsSort } from '@sprinklr/stories/analytics/AnalyticsRequest';
import { Post } from '@sprinklr/stories/post/Post';
import { PostsEngine, PostsRequest } from '@sprinklr/stories/post/PostsRequest';
import { WidgetDimensionKey } from 'utils/Widget/WidgetDimensionKey';
import {
    default as SprinklrAPIService,
    PromiseWithCancel,
} from 'services/SprinklrAPIService/SprinklrAPIService';
import ObjectUtils from 'utils/ObjectUtils/ObjectUtils';
import PostsService from '../PostsService';
import { PostsProvider } from './PostsProvider';
import TimePeriodService from 'services/TimePeriodService/TimePeriodService';

export default class PostsProviderCampaign implements PostsProvider {
    constructor(private readonly sprinklrAPIService: SprinklrAPIService) {}

    request(request: PostsRequest, cancelSource?: CancelTokenSource): PromiseWithCancel<Post[]> {
        if (!cancelSource) {
            cancelSource = Axios.CancelToken.source();
        }

        const engine: PostsEngine = request.reportingEngine || 'PLATFORM';
        const engineMeta = PostsService.engines[engine];

        if (!engineMeta) {
            throw new Error('Engine "' + request.reportingEngine + '" not supported');
        }

        const timePeriod = request.timePeriod
            ? this.sprinklrAPIService.getTimePeriod(request.timePeriod)
            : TimePeriodService.createFromTimePeriodKey('last_7_days');

        const filters = request.filters
            .filter((filter: AnalyticsFilter) => {
                return (
                    filter.dimensionName &&
                    filter.filterType &&
                    filter.values &&
                    filter.values.length > 0
                );
            })
            .map((filter: AnalyticsFilter) => {
                // HACKTOWN: Need to tell api that this is a "custom_filter" instead of "filter".  I rely
                // on the observation that custom filters are of the form: "5877ee9fe4b0aae45f8cbdec"
                const isCustom =
                    filter.dimensionName.length === 24 && /[0-9a-z]+/.test(filter.dimensionName);

                return {
                    field: filter.dimensionName,
                    fieldType: isCustom ? 'custom_field' : 'filter',
                    filterType: filter.filterType,
                    values: filter.values,
                };
            });

        let sorts: any[] = request.sorts || [];

        // default sort key
        if (!sorts || sorts.length === 0) {
            sorts = [
                {
                    key: engineMeta.defaultSortKey,
                    order: 'DESC',
                },
            ];
        } else {
            sorts = sorts.map((sort: AnalyticsSort) => {
                return {
                    key: sort.heading,
                    order: sort.order,
                };
            });
        }

        const startTime: Moment = timePeriod.startDate;
        const endTime: Moment = timePeriod.endDate;

        const requestObject: any = {
            requests: {
                post: JSON.stringify({
                    key: 'post',
                    contentType: 'POST',
                    timeZone: request.timePeriod ? request.timePeriod.timeZone : undefined,
                    keyword: null,
                    sinceScheduledTime: startTime ? startTime.valueOf() : null,
                    untilScheduledTime: endTime ? endTime.valueOf() : null,
                    withCompleteObjects: true,
                    // withSprTasks: true,
                    // augmentTasksInPost: true,
                    // withFacets: true,
                    // withGuidelines: true,

                    filters,
                    sorts,

                    page: {
                        page: request.page ? +request.page : 0, // convert to int
                        size: request.pageSize ? +request.pageSize : 20, // pageSize is a String here
                    },
                }),
            },
        };

        // console.log('campaign posts request', JSON.stringify(requestObject, null, 4));

        const promise: any = this.sprinklrAPIService
            .search(requestObject, cancelSource)
            .then((posts: Post[]) => {
                // Take Message shell instances and duplicate them for every channel type within them
                posts = this.expandMessages(posts);

                return this.getLabels(request).then(labels => {
                    return this.addProjections(request, posts, labels, cancelSource);
                });
            });

        promise.cancel = function(message?: string) {
            cancelSource.cancel(message);
        };

        return promise;
    }

    // This takes campaign messages (drafts) and turns them into messages with channel types.
    // This method will create a unique post for each channel within the message.
    expandMessages(posts: any[]): any[] {
        const insert = {};

        const setPostData = (post: any, channel: any, message: string, attachment: any) => {
            post.channelType = channel.channelInfo.channelType;
            post.content.message =
                channel.data && channel.data.message ? channel.data.message : message;
            post.content.attachment =
                channel.data && channel.data.attachment ? channel.data.attachment : attachment;
        };

        posts
            .filter(
                post =>
                    /*post.documentType === "MESSAGE" && post.content &&*/ post.channelSpecificContentList
            )
            .forEach(post => {
                const message = post.content ? post.content.message : null;
                const attachment = post.content ? post.content.attachment : null;
                let first = true;
                let unique = 1;

                post.channelSpecificContentList
                    //.filter(channel => channel.channelInfo && channel.channelInfo.channelType)
                    .forEach(channel => {
                        // Assign the channel type to the first channel found
                        if (first) {
                            setPostData(post, channel, message, attachment);
                            first = false;
                            // Make a copy of the post for this additional channel type
                        } else {
                            const copy = ObjectUtils.copy(post);

                            // Create a new universal id for the copy
                            copy.universalId = post.universalId + '_' + unique++;
                            setPostData(copy, channel, message, attachment);

                            const id = post.universalId;
                            if (!insert[id]) {
                                insert[id] = [copy];
                            } else {
                                insert[id].push(copy);
                            }
                        }
                    });
            });

        const keys = Object.keys(insert);
        if (keys.length) {
            let id: string;

            let x = posts.length;
            while (x--) {
                id = posts[x].universalId;

                // Do we have some duplicate posts to insert in the array?
                if (insert[id]) {
                    posts.splice(x + 1, 0, ...insert[id]);
                }
            }
        }

        return posts;
    }

    // Used to discover displayNames for post card fields
    private getLabels(request: PostsRequest, cancelSource?: CancelTokenSource): Promise<any> {
        return new Promise((resolve, reject) => {
            const dimensionLabels = {};

            this.sprinklrAPIService
                .getFacets()
                .then((lookup: any) => {
                    if (request.projections.length) {
                        request.projections.forEach(projection => {
                            if (projection.details && projection.details.alternateHeading) {
                                dimensionLabels[projection.measurementName] =
                                    projection.details.alternateHeading;
                            } else {
                                const found = lookup.find(
                                    item => item.key === projection.measurementName
                                );
                                if (found && found.displayName) {
                                    dimensionLabels[projection.measurementName] = found.displayName;
                                    // THEME_ID is not in /allFacets list but it does appear in
                                    // Additional Properties in Sprinklr UI.
                                } else if (projection.measurementName === 'THEME_ID') {
                                    dimensionLabels[projection.measurementName] = 'Theme';
                                }
                            }
                        });
                    }

                    resolve(dimensionLabels);
                })
                .catch((error: any) => {
                    reject(error);
                });
        });
    }

    private getAccountId(post: any): number {
        const ids = post.searchDetails && post.searchDetails.accountIds;
        if (ids && ids.length && ids[0] != -1) {
            return ids[0];
        }

        if (post.accountId !== undefined) {
            return post.accountId;
        }

        return -1;
    }

    // This is used support the post cards feature for Campaigns
    private addProjections(
        request: PostsRequest,
        posts: any[],
        dimensionLabels: any,
        cancelSource: CancelTokenSource | undefined
    ): PromiseWithCancel<Post[]> {
        const promise: any = new Promise((resolve, reject) => {
            let doLookup = false;

            // Put your desired lookup dimensions into this map
            const dimensions = {
                ACCOUNT_ID: {},
                BRAND: {},
                CAMPAIGN_ID: {},
                CUSTOMER_JOURNEY_ID: {},
                PERSONA_ID: {},
                SUB_CAMPAIGN_ID: {},
                THEME_ID: {},
            };

            // If we have postcard fields specified in the request, then cycle through the posts
            // and create a "projections" field so that transformPost() will convert them correctly.
            if (request.projections.length) {
                posts.forEach(post => {
                    const data = post.taxonomy;
                    if (data) {
                        const accountId = this.getAccountId(post);
                        if (accountId != -1) {
                            dimensions['ACCOUNT_ID'][accountId] = true;
                            doLookup = true;
                        }

                        request.projections.forEach(projection => {
                            let value: any = null;

                            if (!post.projections) {
                                post.projections = {};
                            }

                            let metric = projection.measurementName;
                            switch (metric) {
                                case 'ACCOUNT_ID':
                                    if (accountId != -1) {
                                        value = accountId;
                                    }
                                    break;

                                case 'CAMPAIGN_ID':
                                    value = data.campaignId;
                                    dimensions['CAMPAIGN_ID'][value] = true;
                                    doLookup = true;
                                    break;

                                case 'OUTBOUND_STATUS':
                                    value = post.status;
                                    break;

                                case 'SCHEDULE_DATE':
                                    value = moment(post.scheduleTime).format('ddd, MMM Do, h:mma');
                                    break;

                                case 'SUB_CAMPAIGN_ID':
                                    value = data.subCampaignId;
                                    dimensions['SUB_CAMPAIGN_ID'][value] = true;
                                    doLookup = true;
                                    break;

                                default:
                                    if (
                                        data.clientCustomProperties &&
                                        data.clientCustomProperties[metric]
                                    ) {
                                        value = data.clientCustomProperties[metric];
                                    } else if (
                                        data.partnerCustomProperties &&
                                        data.partnerCustomProperties[metric]
                                    ) {
                                        value = data.partnerCustomProperties[metric];
                                    } else {
                                        // Check for additional properties
                                        let additional = post.channelSpecificAdditionalProperties;
                                        if (additional && additional instanceof Array) {
                                            additional = additional.filter(
                                                item =>
                                                    item.channelInfo &&
                                                    item.channelInfo.channelType === 'OUTBOUND'
                                            );
                                            if (additional.length) {
                                                additional = additional[0].data;

                                                // Bizarre case where projection is BRAND but additional data comes down as BRAND_ID
                                                const metricAlt =
                                                    metric === 'BRAND' ? 'BRAND_ID' : metric;

                                                if (additional[metricAlt]) {
                                                    value = additional[metricAlt][0];
                                                    if (dimensions[metric]) {
                                                        dimensions[metric][value] = true;
                                                        doLookup = true;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    break;
                            }

                            // Resolve the metric name to a display name
                            metric = dimensionLabels[metric] || metric;

                            // Empty arrays need to resolve to "no-value"
                            if (value instanceof Array && !value.length) {
                                value = null;
                            }

                            post.projections[metric] = {
                                additional: true,
                                value: value,
                            };
                        });
                    }
                });
            }

            // Resolve the campaign ids to full names
            if (doLookup) {
                const keys = Object.keys(dimensions).filter(
                    key => Object.keys(dimensions[key]).length
                );

                this.sprinklrAPIService
                    .bulkLookupMulti(
                        'CAMPAIGN',
                        'CAMPAIGN',
                        keys.map(key => new WidgetDimensionKey(key as any)),
                        keys.map(key => Object.keys(dimensions[key])),
                        null,
                        cancelSource
                    )
                    .then(response => {
                        posts.forEach(post => {
                            keys.forEach(key => {
                                const data = response[key];
                                if (data) {
                                    switch (key) {
                                        case 'ACCOUNT_ID':
                                            const accountId = this.getAccountId(post);
                                            if (accountId != -1) {
                                                const accountInfo = data[accountId];
                                                if (accountInfo) {
                                                    if (!post.senderProfile) {
                                                        post.senderProfile = {};
                                                    }

                                                    post.senderProfile.name =
                                                        accountInfo.displayName;
                                                    post.senderProfile.screenName =
                                                        accountInfo.screenName;
                                                    post.senderProfile.profileImgUrl =
                                                        accountInfo.profileImgUrl;
                                                }
                                            }
                                        // Fallthrough on-purpose

                                        default:
                                            // Resolve the metric name to a display name
                                            const key2 = dimensionLabels[key] || key;

                                            if (post.projections[key2]) {
                                                const id = post.projections[key2].value;

                                                if (data[id]) {
                                                    const value =
                                                        data[id].title ||
                                                        data[id].displayName ||
                                                        data[id].name;
                                                    if (value) {
                                                        post.projections[key2].value = value;

                                                        // Add a press release placeholder image, if it exists, and the post has no other images/videos.
                                                        if (
                                                            key === 'CAMPAIGN_ID' &&
                                                            data[id].gmcImage &&
                                                            post.content
                                                        ) {
                                                            if (!post.content.attachment) {
                                                                post.content.attachment = {};
                                                            }

                                                            if (
                                                                !post.content.attachment
                                                                    .mediaList ||
                                                                !post.content.attachment.mediaList
                                                                    .length
                                                            ) {
                                                                post.content.attachment.mediaList = [
                                                                    {
                                                                        type: 'PHOTO',
                                                                        previewImageUrl:
                                                                            data[id].gmcImage,
                                                                        source: data[id].gmcImage,
                                                                    },
                                                                ];
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                            break;
                                    }
                                }
                            });
                        });

                        resolve(posts);
                    })
                    .catch(thrown => {
                        return reject(thrown);
                    });
            } else {
                resolve(posts);
            }
        });

        promise.cancel = function(message?: string) {
            cancelSource.cancel(message);
        };

        return promise;
    }
}
