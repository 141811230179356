import DataSet, { DimensionDataGroup } from '@sprinklr/stories/analytics/DataSet';
import { AxisBubbleChartWidgetOptionsImpl } from './options';
import { Theme } from 'models/Theme/Theme';
import {
    AxisBubbleDataPoints,
    AxisBubbleSeries,
} from 'components/_charts/AxisBubbleChart/AxisBubbleChart';
import { FieldType } from '@sprinklr/stories/reporting/types';
import BulkItem from '@sprinklr/stories/analytics/BulkItem';
import { normalizeLabel } from 'utils/NormalizeLabel/NormalizeLabel';
import { generateColorPalette } from 'utils/GenerateColors/GenerateColors';
import moment from 'moment';
import AccountItem from '@sprinklr/stories/analytics/AccountItem';

export const getMetricDataSet = (
    dataSet: DataSet,
    options: AxisBubbleChartWidgetOptionsImpl,
    metricIndex: number,
    mergedTheme: Theme,
    theme: Theme
): AxisBubbleSeries[] => {
    const firstDim = dataSet.getFirstDimension();
    let groupByDimensionIndex = 0;
    const type: FieldType = dataSet.metrics[metricIndex].type;

    switch (firstDim && firstDim.type) {
        case 'DATE':
        case 'TIMESTAMP':
        case 'STRING':
            groupByDimensionIndex = 1;
            break;

        case 'NUMBER':
        default:
            groupByDimensionIndex = 0;
            break;
    }

    let data: AxisBubbleSeries[] = [];

    if (dataSet.dimensions.length === 1) {
        data = dataSet.groupBy(dataSet.dimensions[groupByDimensionIndex]).map(
            (series: DimensionDataGroup): AxisBubbleSeries => {
                const bulkItem =
                    series.value instanceof BulkItem ? (series.value as BulkItem) : null;
                return {
                    name: normalizeLabel(bulkItem ? bulkItem.toString() : series.value),
                    data: series.data.toMetricXYZ(),
                    type,
                    snType:
                        series.value instanceof AccountItem && series.value.snType
                            ? series.value.snType
                            : undefined,
                    color: options.useThemeColor && bulkItem ? bulkItem.toColor() || '' : undefined,
                    image:
                        !!options.useThemeImage && bulkItem && bulkItem.toImageUrl()
                            ? bulkItem.toImageUrl()
                            : undefined,
                };
            }
        );
    } else if (dataSet.dimensions.length === 0) {
        const bulkItem =
            dataSet.metrics[metricIndex] instanceof BulkItem
                ? ((dataSet.metrics[metricIndex] as any) as BulkItem)
                : null;

        data = [
            {
                name: normalizeLabel(
                    bulkItem ? bulkItem.toString() : dataSet.metrics[metricIndex].name
                ),
                data: dataSet.toMetricXYZ(),
                type,
                color: options.useThemeColor && bulkItem ? bulkItem.toColor() : undefined,
                image: !!options.useThemeImage && bulkItem ? bulkItem.toImageUrl() : undefined,
            },
        ];
    }

    if (data.length === 0) {
        return [];
    }

    const colorCount = Math.min(data.length, options.maxItems);
    const colors: string[] = generateColorPalette(
        mergedTheme,
        colorCount,
        false,
        theme,
        false,
        true
    );
    data.forEach((datum, index) => {
        if (!datum.color || datum.color === '') {
            datum.color = colors[index];
        }
    });

    data.forEach((series: AxisBubbleSeries) => {
        series.data.sort((a: AxisBubbleDataPoints, b: AxisBubbleDataPoints) => {
            return moment(a.x).valueOf() - moment(b.x).valueOf();
        });
    });

    if ((data[0].data[0].x as any) instanceof BulkItem) {
        data.forEach(series => {
            series.data.map(points => (points.x = points.x + ''));
        });
    }

    if (firstDim && firstDim.type === 'TIMESTAMP') {
        data.forEach(series => {
            series.data.map(points => (points.x = moment(points.x).valueOf()));
        });
    }

    return data.slice(0, options.maxItems);
};

export const transformData = (
    dataSet: DataSet,
    options: AxisBubbleChartWidgetOptionsImpl,
    mergedTheme: Theme,
    theme: Theme
) => {
    if (dataSet.metrics.length === 1) {
        return getMetricDataSet(dataSet, options, 0, mergedTheme, theme);
    } else {
        return dataSet.metrics.map((metric, index) =>
            getMetricDataSet(dataSet, options, index, mergedTheme, theme)
        )[0];
    }
};
