import { observable } from 'mobx';
import { IdentifiedModel } from '@sprinklr/stories/common/IdentifiedModel';
import { IdentifiedModelFields } from 'models/IdentifiedModel/IdentifiedModel';
import { PartnerClientScopedFields } from 'models/CommonDoc/CommonDoc';
import { UserSnapshot, UserSnapshotFields } from 'models/User/UserSnapshot';
import { Connection, Edge, PageInfo } from 'models/Connection/Connection';
import { PartnerClientScoped } from '@sprinklr/stories/common/CommonDoc';

export interface AuditEvent extends IdentifiedModel, PartnerClientScoped {
    id: string;
    resource: ResourceIdentifier;
    relatedResources?: ResourceIdentifier[];
    clientId?: number;
    partnerId?: number;
    description?: string;
    action?: AuditEventAction;
    created?: number;
    user?: UserSnapshot;
    forwardChange?: JsonPatchEntry[];
    reverseChange?: JsonPatchEntry[];
}

export class AuditEventImpl {
    @observable id: string;
    @observable resource: ResourceIdentifier;
    @observable relatedResources?: ResourceIdentifier[];
    @observable clientId?: number;
    @observable partnerId?: number;
    @observable description?: string;
    @observable action?: AuditEventAction;
    @observable created?: number;
    @observable forwardChange?: JsonPatchEntry[];
    @observable reverseChange?: JsonPatchEntry[];
    @observable user?: UserSnapshot;
}

export interface ResourceIdentifier {
    id: string;
    type: string;
    name: string;
}

class ResourceIdentifierImpl implements ResourceIdentifier {
    @observable id: string;
    @observable type: string;
    @observable name: string;
}

export class AuditEventConnection implements Connection<AuditEvent> {
    @observable pageInfo: PageInfo;
    @observable edges: Edge<AuditEvent>[];
}

export type AuditEventAction = 'UPDATE' | 'CREATE' | 'DESTROY';

export const AuditEventFields = `
    ${IdentifiedModelFields} ${PartnerClientScopedFields}
    resource {id type name}
    relatedResources {id type name}
    created
    description
    action
    forwardChange
    reverseChange
    user {
        ${UserSnapshotFields}
    }
`;

export type JsonPatchOperation = 'add' | 'remove' | 'replace' | 'copy' | 'move' | 'test';

export interface JsonPatchEntry {
    op: JsonPatchOperation;
    path: string;
    value?: any;
}

/**
 * The primary resource for the audit event from a user-facing perspective.
 * For example, an event on a ScheduledStoryboard record has a primary resource of the related Display,
 * Scenes and Panels have a primary of their Storyboard.
 * @param auditEvent
 */
export function primaryResource(auditEvent: AuditEvent): ResourceIdentifier {
    const { resource, relatedResources } = auditEvent;

    if (relatedResources) {
        switch (resource.type) {
            case 'scheduledStoryboard':
                const relatedDisplay = relatedResources.find(r => r.type === 'location');
                if (relatedDisplay) return relatedDisplay;
                break;

            case 'sceneVersion':
            case 'panelVersion':
                const relatedStoryboardVersion = relatedResources.find(
                    r => r.type === 'storyboardVersion'
                );
                if (relatedStoryboardVersion) return relatedStoryboardVersion;
            // deliberate fallthrough - use the Storyboard if no StoryboardVersion linked.

            case 'scene':
            case 'panel':
                const relatedStoryboard = relatedResources.find(r => r.type === 'storyboard');
                if (relatedStoryboard) return relatedStoryboard;
                break;
        }
    }

    return resource;
}

/**
 * The effective action on the primary resource. Normally this is just the event.action but in the
 * case of events where the resource is a ScheduledStoryboard, the primary resource is really a
 * related Location record, and all actions are effectively UPDATE.
 * @param auditEvent
 */
export function primaryResourceAction(auditEvent: AuditEvent): AuditEventAction {
    const primaryRes = primaryResource(auditEvent);
    return primaryRes && primaryRes.id !== auditEvent.resource.id ? 'UPDATE' : auditEvent.action;
}
