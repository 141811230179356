import React, { ReactElement, ReactNode } from 'react';
import Metric from 'components/_charts/Metric/Metric';
import { getCurrencyString } from 'utils/Currency/Currency';
import { FieldDataType } from '@sprinklr/stories/reporting/types';
import { MediaInsightMetric } from '@sprinklr/stories/post/Post';
import { DecimalFormat } from 'utils/Number/NumberUtils';
import { ExtraPostDataField } from '@sprinklr/stories/post/Post';

// Metrics
export interface MetricsProps {
    metrics: ExtraPostDataField[];
    options?: {
        numberFormat: DecimalFormat;
    };
}

// heading: string;
// value: any;
// dataType?: string;

export function Metrics({
    metrics,
    options = {
        numberFormat: '1.23k',
    },
}: MetricsProps): ReactElement {
    return (
        <ul key='story-metrics' className={'flex no-grow between story_metrics'}>
            {metrics
                ?.filter(metric => metric.heading !== 'IMPACT') // we filter IMPACT because it is a duplicate entry to STORY_IMPACT
                .map((metric, index) => (
                    <li className={'flex no-grow story_metric'} key={index}>
                        <Metric
                            countUp={false}
                            name={metric.heading?.replace('_', ' ')}
                            layoutType='default'
                            alignment='left'
                            value={metric.value}
                            isPrettyNumber
                            valueFormat={options.numberFormat}
                            valuePrefix={
                                metric.dataType === 'CURRENCY'
                                    ? getCurrencyString(metric.value as FieldDataType)
                                    : ''
                            }
                        />
                    </li>
                ))}
        </ul>
    );
}
