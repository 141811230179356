import { AnalyticsRequest, GroupBy, Metric, Sort } from './PanelTemplatesTheme/PanelTemplatesTheme';
import { Panel } from '../../models/Panel/Panel';
import { WIDGET_LABEL_SIZE } from '../../components/Widget/WidgetLabel/constants';
import ListeningDimensions from '@sprinklr/stories-services/AnalyticsService/ListeningDimensions';

export var TemplateWorldHeatMap: Panel = {
    id: 'world-heat-map',
    name: 'World Heat Map',
    description: '',
    tags: ['Standard', 'World Heat Map', 'Geo', 'landscape', 'portrait'],
    widget: {
        id: '1',
        position: {
            width: '1920px',
            height: '1080px',
            top: '0',
            left: '0',
        },
        classes: 'template_world_heat_map',
        children: [
            {
                type: 'worldHeatMap',
                id: '1',
                name: 'World Heat Map',
                useGlobalTimePeriod: true,
                analyticsRequests: [
                    // Note: All global variables like reportingEngine, report, filters, and projections
                    // are injected via AnalyticsRequest.getRequests()
                    new AnalyticsRequest({
                        limit: 100,
                        groupBys: [
                            new GroupBy({
                                LISTENING: { source: ListeningDimensions.country },
                                INBOUND_MESSAGE: null, // Not supported: No geo
                                PLATFORM: { dimensionName: '5e7a7363f6f3747988885bea_Merged' }, // Special-case: Kerala project
                                BENCHMARKING: null, // Not supported: No geo
                                PAID: { dimensionName: 'adCountry' },
                                RDB_FIREHOSE: { dimensionName: 'COUNTRY' },
                                STORY_MESSAGE: { dimensionName: 'COUNTRY' },
                            }),
                        ],
                        projections: [
                            new Metric({
                                PLATFORM: {
                                    dimensionName: 'SURVEY_RESPONSE_COUNT',
                                    report: 'SURVEY_REPORT',
                                }, // Special-case: Kerala project
                                STORY_MESSAGE: {
                                    dimensionName: 'NEWS_COUNT',
                                    heading: 'News_Mentions',
                                },
                            }),
                        ],
                        sorts: [
                            new Sort({ order: 'DESC', projections: 0 }),
                            new Sort({ order: 'ASC', groupBys: 0 }),
                        ],
                        timePeriods: [{ key: 'last_7_days' }],
                    }) as any,
                ],
                label: {
                    titleText: 'World Heat Map',
                    size: WIDGET_LABEL_SIZE.primary,
                    enabled: true,
                },
                position: {
                    width: '100%',
                    height: '100%',
                    top: '0%',
                    left: '0%',
                },
                options: {
                    player: {
                        playing: true,
                        duration: 15,
                    },
                    legendPosition: 'bottom',
                    showLegend: true,
                    legendSize: 20,
                    smartSize: {
                        enabled: true,
                        mode: 'responsive',
                        size: 'xl',
                    },
                    portrait: {
                        widgetOrientation: 'portrait',
                    },
                },
            },
        ],
    },
};
