import * as React from 'react';
import {
    headerTunables,
    opacityTunables,
    xAxisTunables,
    yAxisTunables,
    comboLegendTunables,
    gridTunable,
    themeTunables,
    zAxisTunables,
    smartSizeTunables,
} from 'components/Sidebar/RenderTunables/SharedTunables';
import { CollapseProps, TunablesCollapse } from 'components/Sidebar/RenderTunables/RenderTunables';
import { Widget } from '@sprinklr/stories/widget/Widget';
import ActionSliderGroup from 'components/_UI/Forms/Actions/ActionSliderGroup';
import i18n from 'src/i18n';
import ActionCheckboxGroup from 'components/_UI/Forms/Actions/ActionCheckboxGroup';
import { AxisBubbleChartWidgetOptionsImpl } from 'src/widgets/AxisBubbleChartWidget/options';

const BubblePlotChartTunables = (widget: Widget): TunablesCollapse[] => [
    {
        props: {
            label: 'Bubble Plot Chart',
            startOpen: true,
        } as CollapseProps,
        children: [
            ...headerTunables(widget),
            opacityTunables(widget),
            smartSizeTunables(widget),
            gridTunable(widget),
            <ActionSliderGroup
                key='widget.options.bubbleOpacity'
                label={i18n.t('Bubble Opacity')}
                option={widget.options}
                value='bubbleOpacity'
                showValue
                min={0}
                max={100}
            />,
            <ActionCheckboxGroup
                key='widget.options.showBorderColor'
                label='Bubble Border'
                option={widget.options as AxisBubbleChartWidgetOptionsImpl}
                value='showBorderColor'
            />,
        ],
    },
    themeTunables(widget, { color: true, image: true, border: true }),
    yAxisTunables(widget),
    xAxisTunables({ widget }),
    zAxisTunables(widget),
    comboLegendTunables(widget),
];

export default BubblePlotChartTunables;
