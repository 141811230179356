import {
    AnalyticsRequest,
    Metric,
    GroupBy,
    Sort,
} from '../PanelTemplatesTheme/PanelTemplatesTheme';
import { DefaultWidgetPosition } from '../PanelTemplatesTheme/constants';
import { Widget } from '@sprinklr/stories/widget/Widget';
import { WIDGET_LABEL_SIZE } from 'components/Widget/WidgetLabel/constants';
import ListeningDimensions from '@sprinklr/stories-services/AnalyticsService/ListeningDimensions';
import ListeningMetrics from '@sprinklr/stories-services/AnalyticsService/ListeningMetrics';

export const WidgetDataTableCrossTab: Widget = {
    type: 'dataTableCrossTab',
    id: 'data-table-cross-tab',
    name: 'Cross Tab Table',
    useGlobalTimePeriod: true,
    analyticsRequests: [
        // Note: All global variables like reportingEngine, report, filters, and projections
        // are injected via AnalyticsRequest.getRequests()
        new AnalyticsRequest({
            limit: 2000,
            projections: [
                new Metric({
                    LISTENING: { source: ListeningMetrics.distinctUsers },
                    INBOUND_MESSAGE: { dimensionName: 'INBOUND_COUNT' },
                    PLATFORM: { dimensionName: 'TOTAL_ENGAGEMENT' },
                    BENCHMARKING: { dimensionName: 'TOTAL_ENGAGEMENT' },
                    PAID: { dimensionName: 'spent' },
                    ADVOCACY: {
                        dimensionName: 'TOTAL_POINTS_EARNED',
                        heading: 'Total_Points_Earned',
                        report: 'COMMUNITY_USER',
                    },
                    TWITTER: { dimensionName: 'MENTIONS' },
                    RDB_FIREHOSE: {
                        dimensionName: 'MENTIONS_COUNT',
                        heading: 'Mentions',
                    },
                }),
                new Metric({
                    LISTENING: { source: ListeningMetrics.mentions },
                    INBOUND_MESSAGE: { dimensionName: 'OUTBOUND_COUNT' },
                    PLATFORM: { dimensionName: 'ENGAGEMENT_RATE' },
                    BENCHMARKING: { dimensionName: 'ENGAGEMENT_RATE' },
                    PAID: { dimensionName: 'impressions' },
                    ADVOCACY: {
                        dimensionName: 'ADVOCACY_TOTAL_USER_ENGAGEMENTS',
                        heading: 'Total_Engagements',
                        origReport: 'ADVOCACY_ACTIVITY',
                    },
                    RDB_FIREHOSE: {
                        dimensionName: 'REACH_COUNT',
                        heading: 'Reach',
                    },
                }),
            ],
            groupBys: [
                new GroupBy({
                    LISTENING: {
                        limit: 8,
                        limitType: 'TOP',
                        dimensionName: 'mSTp',
                        heading: 'Media Type',
                    },
                    INBOUND_MESSAGE: { dimensionName: 'ACCOUNT_ID', limitType: 'TOP', limit: 8 },
                    PLATFORM: { dimensionName: 'ACCOUNT_ID', limitType: 'TOP', limit: 8 },
                    BENCHMARKING: {
                        dimensionName: 'BENCHMARKING_ACCOUNT_ID',
                        limitType: 'TOP',
                        limit: 16,
                    },
                    PAID: { dimensionName: 'accountIds', limitType: 'TOP', limit: 8 },
                    ADVOCACY: { dimensionName: 'COMMUNITY_USER_ID', limitType: 'TOP', limit: 8 },
                    RDB_FIREHOSE: { dimensionName: 'DAY_OF_WEEK', limitType: 'TOP', limit: 8 },
                }),
                new GroupBy({
                    LISTENING: {
                        limit: 5,
                        limitType: 'TOP',
                        dimensionName: 'SEM_SENTIMENT',
                        heading: 'Sentiment',
                    },
                    INBOUND_MESSAGE: { dimensionName: 'SN_TYPE', limitType: 'TOP', limit: 5 },
                    PLATFORM: { dimensionName: 'SN_TYPE', limitType: 'TOP', limit: 5 },
                    BENCHMARKING: {
                        dimensionName: 'BENCHMARKING_SN_TYPE',
                        limitType: 'TOP',
                        limit: 8,
                    },
                    PAID: { dimensionName: 'socialChannelTypes', limitType: 'TOP', limit: 5 },
                    ADVOCACY: {
                        dimensionName: 'AUDIENCE_ACTIVITY_TYPE',
                        limitType: 'TOP',
                        limit: 8,
                    },
                    RDB_FIREHOSE: { dimensionName: 'RDB_QUERY_IDS', limitType: 'TOP', limit: 5 },
                }),
            ],
            sorts: [new Sort({ order: 'DESC', projections: 0 })],
            timePeriods: [{ key: 'last_7_days' }, { previousPeriod: true }],
        }) as any,
    ],
    position: DefaultWidgetPosition,
    label: {
        titleText: 'Cross Tab Table',
        size: WIDGET_LABEL_SIZE.primary,
        enabled: false,
    },
    options: {
        player: {
            playing: true,
            duration: 15,
        },
    },
};
