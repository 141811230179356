import { extendObservable, isObservableArray, toJS } from 'mobx';
import _isEqualWith from 'lodash/isEqualWith';
import _at from 'lodash/at';
import _isObject from 'lodash/isObject';

export default class ObjectUtils {
    // Reversed iteration for better performance, so it's not compatible with Java result
    // http://werxltd.com/wp/2010/05/13/javascript-implementation-of-javas-string-hashcode-method/
    static hashCode(source: string): number {
        let hash = 0;
        if (source.length === 0) {
            return hash;
        }

        let x = source.length;
        while (x--) {
            hash = (hash << 5) - hash + source.charCodeAt(x);
            hash = hash & hash; // Convert to 32bit integer
        }

        return hash;
    }

    // Deep copy.  I ran into an endless-loop issue with toJS() (100%+ CPU), so use this instead. DISPLAY-1052
    static copy(orig: any): any {
        return JSON.parse(JSON.stringify(orig));
    }

    // This copies only properties that are different from latest to orig.  This is needed
    // to avoid triggering mobx with unnecessary property changes and causing overly broad UI updates.
    static copyChanged(dest: any, prop: string, src: any, removeAbsent?: boolean): void {
        removeAbsent = removeAbsent !== false;

        if (src[prop] === undefined) {
            if (removeAbsent) {
                dest[prop] = undefined;
            }

            // Handle simple types
        } else if (
            dest[prop] === undefined ||
            dest[prop] === null ||
            src[prop] === null ||
            src[prop] instanceof Date ||
            typeof src[prop] !== 'object'
        ) {
            if (dest[prop] !== src[prop]) {
                dest[prop] = src[prop];
            }

            // Handle Array
        } else if (src[prop] instanceof Array || isObservableArray(src[prop])) {
            const original = dest[prop].slice(); // Make shallow copy in case of identical object refs
            const origCount: number = dest[prop].length;
            const latestCount: number = src[prop].length;
            let found;

            let x = Math.min(origCount, latestCount);
            while (x--) {
                // See if this element is already in the latest array
                found = src[prop].indexOf(original[x]);

                // If so, then we're looking at an array reordering, so just set the element reference
                if (found !== -1) {
                    if (x !== found) {
                        dest[prop][x] = src[prop][x];
                    }

                    // Otherwise, deep-copy the value over
                } else {
                    ObjectUtils.copyChanged(dest[prop], x.toString(), src[prop]);
                }
            }

            // Array items have been added
            if (origCount < latestCount) {
                for (x = origCount; x < latestCount; x++) {
                    dest[prop].push(src[prop][x]);
                }

                // Array items have been removed
            } else if (origCount > latestCount) {
                for (x = latestCount; x < origCount; x++) {
                    dest[prop].pop();
                }
            }

            // Handle Object
        } else if (src[prop] instanceof Object) {
            // Find out what has changed in the object keys, if any
            const diff = ObjectUtils.diffKeys(dest[prop], src[prop]);

            // If we have new object properties in latest, then do this
            // tracking mechanism so we can copy them to orig when done.
            let latestCloned = null;
            if (diff.added.length) {
                latestCloned = toJS(src[prop]);
            }

            let key;
            for (key in dest[prop]) {
                if (dest[prop].hasOwnProperty(key)) {
                    try {
                        ObjectUtils.copyChanged(dest[prop], key, src[prop], removeAbsent);
                    } catch (e) {
                        console.error(e);
                        throw e;
                    }
                }
            }

            // Add newly-added properties
            if (latestCloned) {
                let x: number = diff.added.length;
                while (x--) {
                    key = diff.added[x];

                    if (latestCloned.hasOwnProperty(key)) {
                        extendObservable(dest[prop], {
                            [key]: latestCloned[key],
                        });
                    }
                }
            }
        } else {
            throw new Error("Unable to copy obj! Its type isn't supported.");
        }
    }

    // http://stackoverflow.com/questions/1187518/javascript-array-difference
    private static diffKeys(orig: any, latest: any): any {
        const keysOrig = Object.keys(orig);
        const keysLatest = Object.keys(latest);
        const result = { added: [], deleted: [] };

        const difference = keysOrig
            .filter(x => keysLatest.indexOf(x) === -1)
            .concat(keysLatest.filter(x => keysOrig.indexOf(x) === -1));

        let x = difference.length;
        while (x--) {
            if (orig[difference[x]] !== undefined) {
                result.deleted.push(difference[x]);
            } else if (latest[difference[x]] !== undefined) {
                result.added.push(difference[x]);
            }
        }

        return result;
    }
}

// compares a and b based on referenceObject structure
export const isEqualByRefObj = (a: object, b: object, referenceObject: object): boolean => {
    const keys = referenceObject && Object.keys(flattenObjectToPathKeys(referenceObject));
    return keys && _isEqualWith(_at(a as any, keys), _at(b as any, keys));
};

export const flattenObjectToPathKeys = (srcObject: object): object => {
    const result = {};
    const flatten = (obj, prefix = '') => {
        Object.keys(obj).forEach(key => {
            const value = obj[key];
            if (_isObject(value)) {
                flatten(value, `${prefix}${key}.`);
            } else {
                result[`${prefix}${key}`] = value;
            }
        });
    };
    flatten(srcObject);
    return result;
};
