import * as React from 'react';
import { action } from 'mobx';
import { observer } from 'mobx-react';
import { Input } from 'components/spaceKit';

type InputTypes = 'text' | 'number' | 'textArea' | 'password' | 'time';
type ActionInputProps = {
    value: string | null;
    option: any;
    hasError?: boolean;
    active?: boolean;
    disabled?: boolean;
    placeholder?: string;
    className?: any;
    focusOnRender?: boolean;
    type?: InputTypes;
    min?: number;
    max?: number;
};

const focus = node => node && node.select();

const ActionInput: React.FC<ActionInputProps> = ({
    value,
    option,
    focusOnRender,
    hasError,
    active,
    disabled,
    placeholder,
    className,
    type,
    min,
    max,
}) => (
    <Input
        ref={focusOnRender ? focus : undefined}
        type={type}
        className={className}
        value={option[value]}
        disabled={disabled}
        hasError={hasError}
        active={active}
        placeholder={placeholder}
        min={min}
        max={max}
        onChange={action(
            (e: React.ChangeEvent<HTMLInputElement>) => (option[value] = e.target.value)
        )}
    />
);

ActionInput.displayName = 'ActionInput';
export default observer(ActionInput);
