import * as React from 'react';
import i18n from 'i18next';
import {
    adsTunables,
    engagementTunables,
    extraPostDataTunables,
    headerTunables,
    imageAdjustmentTunables,
    imagePostTunables,
    nativeStylingTunables,
    onChangeComposition,
    opacityTunables,
    postTitleTunables,
    profanityTunables,
    reviewPostTunables,
    textEntityTunables,
    textPostTunables,
    userGroupTunables,
} from 'components/Sidebar/RenderTunables/SharedTunables';
import { CollapseProps, TunablesCollapse } from 'components/Sidebar/RenderTunables/RenderTunables';
import { postCompositionConfigGrid } from 'components/Panel/PanelEditorSidebar/PanelDesign/PanelDesignOptions';
import { Widget } from '@sprinklr/stories/widget/Widget';
import { Theme } from 'models/Theme/Theme';
import ActionSegmentedGroup from 'components/_UI/Forms/Actions/ActionSegmentedGroup';
import { PostCardWidgetOptions } from '../options';
import { PostsWidgetGridV2OptionsImpl } from './options';
import { FormatBucketsOptions, SourcePost } from '@sprinklr/stories/post/PostsFormatRequest';
import ActionSliderGroup from 'components/_UI/Forms/Actions/ActionSliderGroup';
import { runInAction } from 'mobx';

const gridPostCompositions = [
    { value: 'a', label: i18n.t('Default'), icon: 'collage_a' },
    { value: 'b', label: i18n.t('Alternate'), icon: 'collage_b' },
];

const getNativeTunables = (widget: Widget) => {
    // LEGZ only works with duo - other grid layouts we don't allow the native styling option - small little squares don't work so well
    const postFormatOptions: FormatBucketsOptions = widget.postRequests[0].format
        .options as FormatBucketsOptions;
    const postFormatTotal = postFormatOptions.panels[0].total;
    if (
        postFormatTotal === 2 ||
        (widget.options as PostsWidgetGridV2OptionsImpl).postTemplate === 'gridV2'
    ) {
        return nativeStylingTunables(widget);
    } else {
        return undefined;
    }
};

const getNonNativeTunables = (widget, mergedTheme) => {
    const isNative =
        (widget.options as PostCardWidgetOptions).nativeStyling &&
        (widget.options as PostCardWidgetOptions).nativeStyling.enabled;
    if (!isNative) {
        return [
            {
                props: {
                    label: i18n.t('Post Composition'),
                    startOpen: true,
                } as CollapseProps,
                children: [
                    <ActionSegmentedGroup
                        key='widget.options.postCompositionType'
                        label={i18n.t('Composition')}
                        option={widget.options}
                        value='postCompositionType'
                        options={gridPostCompositions}
                        handleSideEffects={selection =>
                            onChangeComposition(widget, selection, postCompositionConfigGrid)
                        }
                    />,
                ],
            },
            postTitleTunables(widget, mergedTheme),
            textPostTunables(widget, mergedTheme),
            imagePostTunables(widget, mergedTheme),
            extraPostDataTunables(widget, mergedTheme),
            reviewPostTunables(widget, mergedTheme),
            imageAdjustmentTunables(widget),
            userGroupTunables(widget),
            engagementTunables(widget),
            adsTunables(widget),
            textEntityTunables(widget),
            profanityTunables(widget),
        ];
    } else {
        return [];
    }
};
const GridPostTunables = (widget: Widget, mergedTheme: Theme): TunablesCollapse[] => [
    {
        props: {
            label: i18n.t('Engagement Posts'),
            startOpen: true,
        } as CollapseProps,
        children: [...headerTunables(widget), opacityTunables(widget)],
    },
    getNativeTunables(widget),
    ...getNonNativeTunables(widget, mergedTheme),
];

export const GridPostV2Tunables = (widget: Widget, mergedTheme: Theme): TunablesCollapse[] => {
    const gridLayout = (widget.options as PostsWidgetGridV2OptionsImpl).gridLayout;
    const updateWidgetRequestLimit = () => {
        const newLimit = Math.ceil(gridLayout.columns * gridLayout.rows);
        runInAction(() => {
            (widget.postRequests[0].format
                .options as FormatBucketsOptions).panels[0].total = newLimit;
            if (newLimit * 3 > 50) {
                (widget.postRequests[0].sources[0] as SourcePost).id.pageSize = newLimit * 1.5 * 3;
            } else {
                (widget.postRequests[0].sources[0] as SourcePost).id.pageSize = 50;
            }
        });
    };
    const handleColumnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        runInAction(() => {
            gridLayout.columns = parseFloat(event.target.value);
        });
        updateWidgetRequestLimit();
    };
    const handleRowChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        runInAction(() => {
            gridLayout.rows = parseFloat(event.target.value);
        });
        updateWidgetRequestLimit();
    };
    return [
        {
            props: {
                label: i18n.t('Engagement Posts'),
                startOpen: true,
            } as CollapseProps,
            children: [...headerTunables(widget), opacityTunables(widget)],
        },
        {
            props: {
                label: 'Layout',
                startOpen: true,
            } as CollapseProps,
            children: [
                <ActionSliderGroup
                    key='widget.options.gridLayout.columns'
                    label={i18n.t('Columns')}
                    option={gridLayout}
                    value='columns'
                    showValue
                    min={1}
                    max={10}
                    onChange={handleColumnChange}
                />,
                <ActionSliderGroup
                    key='widget.options.gridLayout.rows'
                    label={i18n.t('Max Rows')}
                    option={gridLayout}
                    value='rows'
                    showValue
                    min={1}
                    max={10}
                    onChange={handleRowChange}
                />,
                <ActionSliderGroup
                    key='widget.options.gridLayout.gap'
                    label={i18n.t('Gap')}
                    option={gridLayout}
                    value='gap'
                    showValue
                    min={0}
                    max={100}
                />,
            ],
        },
        getNativeTunables(widget),
        ...getNonNativeTunables(widget, mergedTheme),
    ];
};

export default GridPostTunables;
