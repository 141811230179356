import { toJS } from 'mobx';
import { Post } from '@sprinklr/stories/post/Post';
import {
    PostsFormatRequest,
    SourceInterstitial,
    SourcePost,
    SourceProfile,
    SourceFormat,
} from '@sprinklr/stories/post/PostsFormatRequest';
import { Source } from 'models/PostsFormat/Source';
import { Format } from 'models/PostsFormat/Format';
import { SourcePosts } from 'models/PostsFormat/SourcePosts';
import { SourceProfiles } from 'models/PostsFormat/SourceProfiles';
import { SourceInterstitials } from 'models/PostsFormat/SourceInterstitials';
import { BucketPanel, FormatBuckets } from 'models/PostsFormat/FormatBuckets';
import { FormatWindow } from 'models/PostsFormat/FormatWindow';
import { FormatOrdered } from 'models/PostsFormat/FormatOrdered';
import PostsService from '@sprinklr/stories-services/PostsService/PostsService';
import ProfileService from '../ProfileService/ProfileService';
import SyncService from '../SyncService/SyncService';
import SignalService from '../SignalService/SignalService';
import RenderSettingsService from 'services/RenderSettingsService/RenderSettingsService';
import ObjectUtils from 'utils/ObjectUtils/ObjectUtils';
import SprinklrAPIService from 'services/SprinklrAPIService/SprinklrAPIService';

type SourceSet = SourcePost | SourceProfile | SourceInterstitial;

export interface PostsFormatOptions {
    shouldFireEvents: boolean;
    uniquePostsEndSequence?: boolean; // For presentations, only look at loaded posts, not duplicates, when firing end sequence
}

export default class PostsFormatService {
    private cachedFormats: Format<any>[] = [];

    constructor(
        private postsService: PostsService,
        private profileService: ProfileService,
        private sync: SyncService,
        private signalService: SignalService,
        private renderSettingsService: RenderSettingsService,
        private sprinklrAPIService: SprinklrAPIService
    ) {
        if (this.sync) {
            this.sync.register(() => {
                this.loadFromCache();
            });
        }
    }

    /**
     * Creates keys for all post sources and formats, and registers them to their respective caches
     *
     * @param request
     * @param options
     * @returns {Format}
     */
    create(request: PostsFormatRequest, options?: PostsFormatOptions): Format<any> {
        if (!request || !request.sources || !request.sources.length || !request.format) {
            return null;
        }

        let newSource: Source<any, any>;
        const dataSources: Source<any, any>[] = [];

        for (const item of request.sources) {
            newSource = this.createSource(item);
            if (!newSource) {
                return null;
            }

            dataSources.push(newSource);
        }

        const result = this.createFormat<Post>(request, dataSources, options);
        if (!result) {
            return null;
        }

        this.cachedFormats.push(result);
        this.loadFromCache();

        return result;
    }

    /**
     * Destroys post formatter and removesfrom the cache
     *
     * @param request
     */
    remove(format: Format<any>): void {
        const found = this.cachedFormats.indexOf(format);
        if (found !== -1) {
            format.cancelUpdate();
            format.destroy();
            this.cachedFormats.splice(found, 1);
        }
    }

    removeAll(formats: Format<any>[]) {
        if (formats) {
            // Remove any old format objects we created last time to prevent leakage
            let x = formats.length;
            while (x--) {
                this.remove(formats[x]);
            }
        }
    }

    private createSource(source: SourceSet): Source<any, any> {
        const disableImagePreload = !this.renderSettingsService.imagePreloadEnabled;

        switch (source.type) {
            case 'interstitials':
                return SourceInterstitials.create(this.signalService, source, disableImagePreload);

            case 'posts':
                return SourcePosts.create(
                    this.signalService,
                    this.postsService,
                    this.sprinklrAPIService,
                    source,
                    disableImagePreload
                );

            case 'profiles':
                return SourceProfiles.create(this.signalService, this.profileService, source);

            default:
                console.log('ERROR: Cannot create posts source for type: ' + (source as any).type);
                return null;
        }
    }

    private createFormat<T>(
        request: PostsFormatRequest,
        sources: Source<T, any>[],
        options?: PostsFormatOptions
    ): Format<T> | Format<BucketPanel<T>> {
        const format: SourceFormat = toJS(request.format);
        const formatOptions = ObjectUtils.copy(format.options);
        const uniquePostsEndSequence = options && options.uniquePostsEndSequence;

        // Pass this option down to the formatters
        if (options && options.shouldFireEvents) {
            formatOptions.shouldFireEvents = true;
        }

        switch (format.type) {
            case 'buckets':
                // actually returns Format<Panel<T>>
                return new FormatBuckets<T>(request, sources, formatOptions, this.signalService);

            case 'window':
                return new FormatWindow<T>(
                    request,
                    sources,
                    formatOptions,
                    uniquePostsEndSequence,
                    this.signalService
                );

            case 'ordered':
                return new FormatOrdered<T>(request, sources, formatOptions, this.signalService);

            default:
                console.error(
                    'ERROR: Cannot create posts format for type: ' + (format as any).type
                );
                return null;
        }
    }

    private loadFromCache(): void {
        this.cachedFormats.forEach((format: Format<any>) => {
            format.update();
        });
    }
}
