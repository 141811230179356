import _intersectionBy from 'lodash/intersectionBy';
import _intersection from 'lodash/intersection';
import _unionBy from 'lodash/unionBy';
import _union from 'lodash/union';
import _chunk from 'lodash/chunk';

export const getAdjacentByKey = (raw: object[], key: string): object[][] => {
    const result = [];
    let buffer = [];
    raw.map(item => {
        if (item[key]) {
            buffer.push(item);
        } else {
            buffer.length && result.push(buffer);
            buffer = [];
        }
    });
    if (buffer.length) {
        result.push(buffer);
    }
    return result;
};

export const removeDuplicateByKey = (raw: object[], key: string): object[] => {
    const output: object[] = [];
    const keys: any = {};
    raw.forEach(item => {
        if (!keys[item[key]]) {
            keys[item[key]] = true;
            output.push(item);
        }
    });
    return output;
};

export const acumulateArrayOfObjectsByKey = (data, key: string) => {
    return data.reduce((a, b, i) => {
        // make sure key exists and it's value is a number
        if (!b.hasOwnProperty(key) || typeof b[key] !== 'number') {
            return [...a, b];
        }

        const carryOver = (a[i - 1] && a[i - 1][key]) || 0;
        b[key] = b[key] + carryOver;
        return [...a, b];
    }, []);
};

type SingleDim = { [key: string]: number };
type MultiDim = [{ [key: string]: number }];
export const getMaxValue = (raw: Array<SingleDim | MultiDim>, key = 'value'): number => {
    if (!raw[key]) {
        return raw.slice().reduce((acc, d) => Math.max(d[key], acc), 0);
    } else {
        return raw[key];
    }
};

export const getMaxLength = (raw: object[], key = 'data', zeroBased = false): number => {
    const additive = zeroBased ? 0 : 1;
    if (raw[key]) {
        return raw.slice().reduce((acc, d) => Math.max(d[key].length + additive, acc), 0);
    } else {
        return raw.length + additive;
    }
};

export const intersect = (a: any[], b: any[], key?: string): any[] => {
    if (!a || !b || (key && !(a[0] instanceof Object || b[0] instanceof Object))) {
        return a || b;
    }

    // Leverage lodash for the intersection
    if (key) {
        return _intersectionBy(a, b, key);
    } else {
        return _intersection(a, b);
    }
};

export const union = (a: any[], b: any[], key?: string): any[] => {
    if (!a || !b || (key && !(a[0] instanceof Object || b[0] instanceof Object))) {
        return a || b;
    }

    // Leverage lodash for the union
    if (key) {
        return _unionBy(a, b, key);
    } else {
        return _union(a, b);
    }
};

export const overwriteMerge = (destinationArray, sourceArray, options) => sourceArray;

// Leverage lodash for chunk
export const chunk = _chunk;

export const arrMove = (arr, oldIndex, newIndex) => {
    if (newIndex >= arr.length) {
        let k = newIndex - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
    return arr;
};

// Binary search in sorted array
export const findSorted = (items: any[], compare: (item) => number) => {
    let m = 0;
    let n = items.length - 1;

    while (m <= n) {
        const k = (n + m) >> 1;
        const cmp = compare(items[k]);
        if (cmp > 0) {
            m = k + 1;
        } else if (cmp < 0) {
            n = k - 1;
        } else {
            return k;
        }
    }

    return -1;
};

// Sort array of objects based on given key
export const sortItems = (items: any[], sortBy: string) => {
    return items.sort((a, b) => {
        if (a[sortBy] < b[sortBy]) {
            return -1;
        } else if (a[sortBy] > b[sortBy]) {
            return 1;
        }
        return 0;
    });
};
