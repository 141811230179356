import { AnalyticsRequest, GroupBy, Sort } from './PanelTemplatesTheme/PanelTemplatesTheme';
import { Panel } from '../../models/Panel/Panel';
import { WIDGET_LABEL_SIZE } from '../../components/Widget/WidgetLabel/constants';
import ListeningDimensions from '@sprinklr/stories-services/AnalyticsService/ListeningDimensions';

export const TemplateWordCloud: Panel = {
    id: 'word-cloud',
    name: 'Word Cloud',
    description: '',
    tags: ['Standard', 'Word Cloud', 'landscape', 'portrait'],
    widget: {
        id: '1',
        position: {
            width: '1920px',
            height: '1080px',
            top: '0',
            left: '0',
        },
        classes: 'template_wordcloud',
        children: [
            {
                type: 'wordCloud',
                id: '1',
                name: 'Word Cloud',
                useGlobalTimePeriod: true,
                analyticsRequests: [
                    // Note: All global variables like reportingEngine, report, filters, and projections
                    // are injected via AnalyticsRequest.getRequests()
                    new AnalyticsRequest({
                        limit: 25,
                        groupBys: [
                            new GroupBy({
                                LISTENING: { source: ListeningDimensions.wordCloudMessage },
                                INBOUND_MESSAGE: { dimensionName: 'HASHTAGS' },
                                PLATFORM: null /* This engine type not supported for this template */,
                                BENCHMARKING: { dimensionName: 'HASHTAGS' },
                                PAID: { dimensionName: 'imageTag' },
                                ADVOCACY: { dimensionName: 'COMMUNITY_USER_ID' },
                                TWITTER: { dimensionName: 'TRENDING_TOPIC' },
                                RDB_FIREHOSE: { dimensionName: 'RDB_QUERY_IDS' },
                                STORY_MESSAGE: { dimensionName: 'WORD_CLOUD_MESSAGE' },
                            }),
                        ],
                        sorts: [
                            new Sort({ order: 'DESC', projections: 0 }),
                            new Sort({ order: 'ASC', groupBys: 0 }),
                        ],
                        timePeriods: [{ key: 'last_7_days' }],
                        additional: {
                            TARGET_LANG_CODE: '',
                        },
                    }) as any,
                ],
                position: {
                    width: '100%',
                    height: '100%',
                    top: '0',
                    left: '0',
                },
                label: {
                    overlap: false,
                    titleText: 'Word Cloud',
                    size: WIDGET_LABEL_SIZE.primary,
                    enabled: true,
                },
                options: {
                    player: {
                        playing: true,
                        duration: 15,
                    },
                    spacing: 1,
                    showSentiment: false,
                    smartSize: {
                        enabled: true,
                        mode: 'responsive',
                        size: 'xl',
                    },
                    portrait: {
                        widgetOrientation: 'portrait',
                    },
                },
            },
        ],
    },
};
