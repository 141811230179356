import * as React from 'react';
import i18n from 'i18next';
import { action } from 'mobx';

import { opacityTunables } from 'components/Sidebar/RenderTunables/SharedTunables';
import SupportsButtonText from 'components/Panel/PanelEditorSidebar/PanelDesign/SupportsButtonText';
import { TunablesCollapse, CollapseProps } from 'components/Sidebar/RenderTunables/RenderTunables';
import LabelGroup from '../../components/_UI/Forms/LabelGroup/LabelGroup';
import ActionSliderGroup from '../../components/_UI/Forms/Actions/ActionSliderGroup';
import ActionSegmentedGroup from '../../components/_UI/Forms/Actions/ActionSegmentedGroup';
import ActionColorSwatchGroup from '../../components/_UI/Forms/Actions/ActionColorSwatchGroup';
import { Flexbox, Select } from 'components/spaceKit';
import ActionCheckboxSliderColorSwatchGroup from 'components/_UI/Forms/Actions/ActionCheckboxSliderColorSwatchGroup';
import ActionSelectGroup from 'components/_UI/Forms/Actions/ActionSelectGroup';
import ActionInput from 'components/_UI/Forms/Actions/ActionInput';

import { Theme } from 'models/Theme/Theme';
import { Widget } from '@sprinklr/stories/widget/Widget';
import { verticalAlignOptions } from 'components/Panel/PanelEditorSidebar/PanelDesign/PanelDesignOptions';
import { HyperlinkButtonWidgetOptionsImpl, linkTypes } from './options';
import { getMergedBackgroundColor } from 'utils/GenerateStyles/GenerateStyles';
import { StoryboardVersion } from 'models/Storyboard/Storyboard';

const HyperlinkButtonTunables = (
    widget: Widget,
    mergedTheme: Theme,
    AuthService: any,
    storyboard: StoryboardVersion
): TunablesCollapse[] => {
    const screens = storyboard?.scenes?.map((scene, index) => {
        const id = index + 1;
        return {
            id,
            name: `${id} - ${scene.name}`,
        };
    });

    const onChangeSlideNumber = slide => {
        action((widget.options.slideNumber = slide));
    };

    return [
        {
            props: {
                label: i18n.t('Hyperlink Button'),
                startOpen: true,
            } as CollapseProps,
            children: [
                <LabelGroup label={i18n.t('Link Type')} key='linkType'>
                    <ActionSelectGroup
                        key='widget.options.linkType'
                        options={linkTypes(AuthService.isPresentationsMode)}
                        option={widget.options}
                        value='linkType'
                        placeholder='Select...'
                    />
                </LabelGroup>,
                (widget.options as HyperlinkButtonWidgetOptionsImpl).linkType === 'url' && (
                    <LabelGroup label={i18n.t('URL')} key='url'>
                        <ActionInput key='widget.options.url' option={widget.options} value='url' />
                    </LabelGroup>
                ),
                (widget.options as HyperlinkButtonWidgetOptionsImpl).linkType === 'slideNumber' && (
                    <LabelGroup
                        label={
                            AuthService.isPresentationsMode
                                ? i18n.t('Slide Number')
                                : i18n.t('Scene Number')
                        }
                        key='slideNumber'>
                        <Select
                            className='fullscreen__scene-select'
                            isMulti={false}
                            isClearable={false}
                            isDisabled={false}
                            name='slide'
                            valueKey='id'
                            labelKey='name'
                            onChange={onChangeSlideNumber}
                            options={screens}
                            value={widget.options.slideNumber}
                            menuPlacement='top'
                        />
                    </LabelGroup>
                ),
                <LabelGroup label={i18n.t('Text')} key='text'>
                    <ActionInput key='widget.options.text' option={widget.options} value='text' />
                </LabelGroup>,
                opacityTunables(widget),
                <ActionCheckboxSliderColorSwatchGroup
                    key='widget.options.b tunable'
                    label={i18n.t('Border')}
                    showValue
                    checkbox={{
                        checked: (widget.options as HyperlinkButtonWidgetOptionsImpl).buttonBorder
                            .enabled,
                        value: 'enabled',
                        option: (widget.options as HyperlinkButtonWidgetOptionsImpl).buttonBorder,
                    }}
                    slider={{
                        min: 0,
                        max: 100,
                        value: 'size',
                        option: (widget.options as HyperlinkButtonWidgetOptionsImpl).buttonBorder,
                    }}
                    colorSwatch={{
                        option: (widget.options as HyperlinkButtonWidgetOptionsImpl).buttonBorder,
                        value: 'color',
                        secondaryIcon: (widget.options as HyperlinkButtonWidgetOptionsImpl)
                            .buttonBorder.color
                            ? 'icon-undo'
                            : undefined,
                        color: (widget.options as HyperlinkButtonWidgetOptionsImpl).buttonBorder
                            .color,
                        onChange: action(
                            ({ rgb: { r, g, b, a } }) =>
                                ((widget.options as HyperlinkButtonWidgetOptionsImpl).buttonBorder.color = `rgba(${r}, ${g}, ${b}, ${a})`)
                        ),
                    }}
                />,
                <ActionSliderGroup
                    key='widget.options.buttonBorder.radius'
                    label={i18n.t('Radius')}
                    option={widget.options.buttonBorder}
                    value='radius'
                    showValue
                    min={0}
                    max={70}
                />,
                <LabelGroup label={i18n.t('Background')} key='label widget.options.backgroundColor'>
                    <ActionColorSwatchGroup
                        key='widget.options.backgroundColor'
                        option={widget.options}
                        value='backgroundColor'
                        secondaryIcon={
                            (widget.options as HyperlinkButtonWidgetOptionsImpl).backgroundColor
                        }
                        color={
                            (widget.options as HyperlinkButtonWidgetOptionsImpl).backgroundColor !==
                            ''
                                ? (widget.options as HyperlinkButtonWidgetOptionsImpl)
                                      .backgroundColor
                                : getMergedBackgroundColor(mergedTheme)
                        }
                        onChange={action(
                            ({ rgb: { r, g, b, a } }: any) =>
                                (widget.options.backgroundColor = `rgba(${r}, ${g}, ${b}, ${a})`)
                        )}
                    />
                </LabelGroup>,
            ],
        },
        {
            props: {
                label: i18n.t('Typography'),
                startOpen: true,
            } as CollapseProps,
            children: [
                <SupportsButtonText
                    key='supportsButtonText'
                    widget={widget}
                    mergedTheme={mergedTheme}
                />,
            ],
        },
    ];
};

export default HyperlinkButtonTunables;
