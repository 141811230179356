/**
 * Encapuslates functionality around geo data
 *
 * Created by dboyd on 4/25/20
 */
import { GeographyInterface } from './types';
import { RegionType } from './types';
import { Projection, Coordinate } from './Projection';
import { ResolveRegionNametoCode } from 'utils/CountryCodeTransformer/CountryCodeTransformer';
import DataSet from '@sprinklr/stories/analytics/DataSet';
import { Threshold } from 'components/Threshold/Thresholds';

export interface GeoItem extends Coordinate {
    x: string; // city
    y: number; // value
    normalized?: number;
    rank?: number;
    twoCharCode?: string;
}

export class GeoData {
    private overallMin: number;
    private overallMax: number;
    regionData: any;
    cityData: any;

    constructor(public items: GeoItem[]) {}

    static create(dataSet: DataSet, regionType: RegionType): GeoData {
        // check for dims - if there aren't any, dataSet.toXY() throws an error
        if (!dataSet || !dataSet.dimensions || dataSet.dimensions.length < 1) {
            return new GeoData([]);
        }

        dataSet = dataSet.filterRowsData();
        const data = new GeoData(dataSet.toXY());

        data.slice(regionType);

        return data;
    }

    get length(): number {
        return this.items.length;
    }

    // returns the y value from the data by matching the id
    getValue(twoCharCode: string) {
        const found = this.items.filter(item => item.twoCharCode === twoCharCode);
        let value = 0;
        if (found.length) {
            value = found[0].y;
        }
        return value;
    }

    getRank(twoCharCode: string) {
        const found = this.items.filter(item => item.twoCharCode === twoCharCode);
        if (found.length) {
            return found[0].rank;
        } else {
            return this.items.length - 2;
        }
    }

    getMinMax(): { overallMin: number; overallMax: number } {
        if (this.overallMin === undefined) {
            const values = this.items.map(item => item.y);
            this.overallMin = Math.min(...values);
            this.overallMax = Math.max(...values);
        }

        return { overallMin: this.overallMin, overallMax: this.overallMax };
    }

    // getSlicesMinMax(dataSlices: WorldHeatMapSlice[]): { overallMin: number; overallMax: number } {
    //     const minMaxes = dataSlices.map(value => this.getMinMax(value.data));
    //     const minValues = minMaxes.map(value => value.overallMin);
    //     const maxValues = minMaxes.map(value => value.overallMax);
    //     const overallMin = Math.min(...minValues);
    //     const overallMax = Math.max(...maxValues);
    //     return { overallMin, overallMax };
    // }

    // This filters the data to only those that have coordinates within this projection
    filterCoords(projection: Projection) {
        this.items = this.items.filter((item: GeoItem) => {
            const result = item.longitude && projection.getPoint([item.longitude, item.latitude]);

            // if (!result) {
            //     console.log('City out of projection bounds: ' + coordinate.x);
            // }

            return result;
        });
    }

    // returns the corresponding color value for each rank by matching the id
    colorMapper(twoCharCode: string, colors: string[], thresholds: Threshold[]): string {
        const { overallMin, overallMax } = this.getMinMax();

        const found = this.items.filter(item => item.twoCharCode === twoCharCode);
        if (found.length > 0 && colors?.length > 0) {
            if (thresholds) {
                const matches = thresholds.filter(threshold => threshold.value >= found[0].y);
                if (matches.length) {
                    return matches[0].color;
                }
            } else {
                const percentage = overallMax === 0 ? 0 : found[0].y / overallMax;
                const volumeRank = colors.length * percentage;

                if (volumeRank === 0) {
                    return colors[0];
                } else if (volumeRank <= 1) {
                    return colors[1];
                } else {
                    return colors[Math.ceil(volumeRank) - 1];
                }
            }
        }

        return colors?.[0];
    }

    // Sets coordinates for every item found and returns new GeoData instance
    mergeCoordinates(cityCoords: any[]): GeoData {
        const items = this.items
            .filter(item => cityCoords[item.x])
            .map((item: any) => {
                const newValues = {
                    latitude: cityCoords[item.x].lat,
                    longitude: cityCoords[item.x].lng,
                };

                return Object.assign(newValues, item);
            });

        return new GeoData(items);
    }

    // Sets coordinates for every item thats label matches a feature name. Returns new GeoData instance
    mergeCenterCoordinates(projection: Projection, geography: GeographyInterface): GeoData {
        const items = this.items
            .map((item: any) => {
                const feature = geography.findFeature(item.twoCharCode);
                if (feature) {
                    let coords: [number, number];

                    // For the United States, the center looks right excluding Alaska and Hawaii
                    if (item.twoCharCode === 'US') {
                        coords = [-98.583333, 39.833333];
                    } else {
                        coords = projection.getCenterCoords(feature);
                    }

                    const newValues = {
                        longitude: coords[0],
                        latitude: coords[1],
                    };

                    return Object.assign(newValues, item);
                }

                return item;
            })
            .filter(item => !isNaN(item.latitude));

        return new GeoData(items);
    }

    // Store rank index as a property of each array object for the color mapping function to utilize
    slice(regionType: RegionType) {
        const items = this.items;

        for (let i = 0; i < items.length; i++) {
            const check = items[i] as any;

            // Handle LookupItem and TopicTheme objects
            items[i].x = check.x?.displayName?.trim() || check.x?.name || check.x;
            items[i].rank = i;
            items[i].twoCharCode = ResolveRegionNametoCode(items[i].x, regionType || 'country');

            // remove UN data
            if (items[i].twoCharCode === 'UN') {
                items.splice(i, 1);
            }
        }
    }

    // TODO LEGZ
    // A better quantile scale would be nice here - this doesn't quite work as expected though
    // maybe it's just because the top country value is so disproportionately large
    // Add a log scale perhaps
    // colorMapper(twoCharCode, colors, overallMin, overallMax) {
    //     var found = this.itmes.filter((item) => item.twoCharCode === twoCharCode);

    //     var value: number;
    //     if (found.length) {
    //         value = found[0].y;
    //     } else {
    //         value = 0;
    //     }

    //     return (d3.scale.quantile().domain([overallMin, overallMax]).range(colors)(value));
    // }
}
