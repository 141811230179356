import { observable, action, computed } from 'mobx';
import { ArrayDiff } from '../../utils/ArrayDiff/ArrayDiff';
import { PromiseWithCancel } from '../../services/SprinklrAPIService/SprinklrAPIService';
import { AxiosError } from 'axios';
import SignalService from '../../services/SignalService/SignalService';

export abstract class Source<T, R> {
    static changed = 'source_changed';

    uniqueKey: string;
    @observable request: R;
    diff: ArrayDiff<T>;
    abstract items: T[];
    @observable protected itemsInternal: T[] = [];
    @observable builderLoaded = false;
    private pendingLoad: PromiseWithCancel<T[]> = null;
    @observable error = null;
    protected signalService: SignalService;

    abstract isLoaded(): boolean;
    abstract isShuffle(): boolean;
    abstract needsUpdate(): boolean;
    abstract updateItems(): PromiseWithCancel<T[]>;
    abstract setData(data: T[]): void;
    abstract setDataS3(data: T[]): void;

    constructor(
        signalService: SignalService,
        uniqueKey: string,
        request: R = null,
        ordered = false
    ) {
        this.signalService = signalService;
        this.uniqueKey = uniqueKey;
        this.diff = new ArrayDiff<T>(uniqueKey, ordered);
        this.request = request;
    }

    @computed get isBuilderLoaded() {
        return this.builderLoaded;
    }

    @action
    update(): void {
        if (this.needsUpdate()) {
            this.cancelUpdate();

            try {
                const promise = this.updateItems();
                this.pendingLoad = promise;

                promise
                    .then((data: T[]) => {
                        this.pendingLoad = null;
                        this.setData(data);
                    })
                    .catch(
                        action((error: AxiosError) => {
                            if (error.message) {
                                console.error(error);
                            }
                            if (error.response && error.response.status !== 200) {
                                if (error?.response?.data?.message) {
                                    this.error = `${error.response.data.message}`;
                                } else {
                                    this.error = error;
                                }
                            }
                        })
                    );
            } catch (e) {
                console.error('Error starting data request', e);
            }
        }
    }

    cancelUpdate(): void {
        if (this.pendingLoad) {
            this.pendingLoad.cancel();
            this.pendingLoad = null;
        }
    }
}
