import { Widget } from '@sprinklr/stories/widget/Widget';
import { WIDGET_LABEL_SIZE } from '../../components/Widget/WidgetLabel/constants';
import { DefaultWidgetPosition } from './PanelTemplatesTheme/constants';
import { WidgetCustomTableRichText } from './WidgetCustomTableRichText';
import { CustomTableChartWidgetOptions } from '../../components/_charts/CustomTableChartWidget/options';
import { TEXT_SHADOW_DEFAULT } from 'models/Widget/Widget';

export const WidgetCustomTableChart: Widget = {
    type: 'customTable',
    id: 'customTable',
    name: 'Custom Table Chart',
    children: [
        { ...WidgetCustomTableRichText, id: 'richText1' },
        { ...WidgetCustomTableRichText, id: 'richText2' },
        { ...WidgetCustomTableRichText, id: 'richText3' },
        { ...WidgetCustomTableRichText, id: 'richText4' },
        { ...WidgetCustomTableRichText, id: 'richText5' },
        { ...WidgetCustomTableRichText, id: 'richText6' },
        { ...WidgetCustomTableRichText, id: 'richText7' },
        { ...WidgetCustomTableRichText, id: 'richText8' },
        { ...WidgetCustomTableRichText, id: 'richText9' },
    ],
    label: {
        titleText: 'Custom Table Chart',
        enabled: false,
        size: WIDGET_LABEL_SIZE.primary,
        textShadow: TEXT_SHADOW_DEFAULT,
    },
    position: DefaultWidgetPosition,
    options: {
        player: {
            playing: true,
            duration: 15,
        },
        smartSize: {
            enabled: true,
            mode: 'responsive',
            size: 'xl',
        },
        tableData: {
            rows: [
                {
                    height: 0.3333,
                    cells: [{ id: 'richText1' }, { id: 'richText2' }, { id: 'richText3' }],
                },
                {
                    height: 0.3333,
                    cells: [{ id: 'richText4' }, { id: 'richText5' }, { id: 'richText6' }],
                },
                {
                    height: 0.3333,
                    cells: [{ id: 'richText7' }, { id: 'richText8' }, { id: 'richText9' }],
                },
            ],
            columns: [{ width: 0.3333 }, { width: 0.3333 }, { width: 0.3333 }],
        },
        portrait: {
            widgetOrientation: 'portrait',
        },
    } as CustomTableChartWidgetOptions,
};
