import { action, observable } from 'mobx';
import {
    BackgroundImage,
    Ext,
    MergedLegacyTheme,
    TextShadowOptions,
    ThemeInterface,
    Widget,
    WidgetFont,
    WidgetLabelInterface,
    WidgetPosition,
} from '@sprinklr/stories/widget/Widget';

import { BarChartWidgetOptions } from '../../widgets/BarChartWidget/options';
import { BubbleChartWidgetOptions } from '../../widgets/BubbleChartWidget/options';
import { CountUpWidgetOptions } from '../../widgets/CountUpWidget/options';
import { LegendWidgetOptions } from '../../widgets/LegendWidget/options';
import { LineChartWidgetOptions } from '../../widgets/LineChartWidget/options';
import { MetricValueLockupWidgetOptions } from '../../widgets/MetricValueLockupWidget/options'; // deprecated
import { MetricValueWidgetOptions } from '../../widgets/MetricValueWidget/options'; // deprecated
import { MetricWidgetOptions } from '../../widgets/MetricWidget/options';
import { MultiMetricWidgetOptions } from '../../widgets/MultiMetricWidget/options';
import { PieChartWidgetOptions } from '../../widgets/PieChartWidget/options';
import { PostsWidgetOptions } from '../../widgets/PostsWidget/options';
import { PostsWindowWidgetOptions } from '../../widgets/PostsWindowWidget/options';
import { ProfileWidgetOptions } from '../../widgets/ProfileWidget/options';
import { RankedListWidgetOptions } from '../../widgets/RankedListWidget/options';
import { RankedTableWidgetOptions } from '../../widgets/RankedTableWidget/options';
import { StackedBarChartWidgetOptions } from '../../widgets/StackedBarChartWidget/options';
import { TreeMapWidgetOptions } from '../../widgets/TreeMapWidget/options';
import { WordCloudWidgetOptions } from '../../widgets/WordCloudWidget/options';
import { WorldHeatMapWidgetOptions } from '../../widgets/WorldHeatMapWidget/options';
import { MetricRollupWidgetOptions } from '../../widgets/MetricRollupWidget/options';
import { HeatMapTableChartWidgetOptions } from '../../widgets/HeatMapTableChartWidget/options';
import { SmallMultiplesPieChartWidgetOptions } from '../../widgets/SmallMultiplesPieChartWidget/options';
import { CustomContentWidgetOptions } from '../../widgets/CustomContentWidget/options';
import { AnalyticsRequest } from '@sprinklr/stories/analytics/AnalyticsRequest';
import { AnalyticsResult } from '@sprinklr/stories/analytics/AnalyticsResult';
import { PostsFormatRequest } from '@sprinklr/stories/post/PostsFormatRequest';
import { WIDGET_LABEL_SIZE } from 'components/Widget/WidgetLabel/constants';
import { PostsRequest } from '@sprinklr/stories/post/PostsRequest';
import { ProfileRequest } from '@sprinklr/stories/profile/ProfileRequest';
import { TrendRequest } from 'services/TrendService/TrendService';
import { BrandColors } from '../../stores/PanelTemplatesStore/PanelTemplatesTheme/constants';
import { FontCase, FontStyle, FontWeight, ThemeFields } from '../Theme/Theme';
import { AutoAlertsWidgetOptions } from '../../widgets/AutoAlertsWidget/options';
import { SunburstWidgetOptions } from '../../widgets/SunburstWidget/options';
import { DataSourceName, DataSourceNames } from 'models/DataSource/DataSource';
import { DataTableWidgetOptions } from '../../widgets/DataTableWidget/options';

export type WidgetTypeId =
    | 'posts'
    | 'postsCollage'
    | 'postsGrid'
    | 'postsGridV2'
    | 'postsFilmStrip'
    | 'postsTypePoster'
    | 'postsCinematic'
    | 'postsSinglePost'
    | 'contentMarketing'
    | 'postsTicker'
    | 'postsWaterfall'
    | 'postCard'
    | 'storyCard'
    | 'runOfShow'
    | 'barChart'
    | 'pieChart'
    | 'stackedBarChart'
    | 'lineChart'
    | 'comboChart'
    | 'dualAxis'
    | 'axisBubbleChart'
    | 'bubblePlotChart'
    | 'dynamicImage'
    | 'dynamicTitle'
    | 'sparkline' // backwards compatibility
    | 'countUp'
    | 'wordCloud'
    | 'bubbleChart'
    | 'legend'
    | 'metric'
    | 'multiMetric'
    | 'metricValue' // backwards compatibility
    | 'metricValueLockup' // backwards compatibility
    | 'rankedList'
    | 'rankedTable'
    | 'dataTablePivot'
    | 'dataTableCrossTab'
    | 'dataTableSummary'
    | 'dataTableGroupedSummary'
    | 'treeMap'
    | 'worldHeatMap'
    | 'geoStream'
    | 'geoStreamTicker'
    | 'profile'
    | 'metricRollup'
    | 'heatMapTableChart'
    | 'googleTrends'
    | 'twitterTrends'
    | 'autoAlerts'
    | 'smallMultiplesPieChart'
    | 'customContentRichText'
    | 'customContentImage'
    | 'customContentVideo'
    | 'customContentSocialPost'
    | 'customContentIframe'
    | 'sunburst'
    | 'shape'
    | 'customTable'
    | 'customTableRichText'
    | 'funnelChart'
    | 'hyperlinkButton'
    | 'speechBubble'
    | 'smartTrends';

export const WidgetDaysOfWeek = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
]; // this is only used by HeatmapTableChart

export const WidgetMonthsOfYear = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

export class ExtImpl implements Ext {
    @observable padding = 40;
    @observable gridLayoutColumns = 5;
    @observable gridLayoutRows = 5;
    @observable showGuides = false;
    @observable colorGrid = 'rgb(55, 55, 65)';
    @observable showGridLayout = false;
    @observable snapToGrid = false;
    @observable gutter = 20;
}

export class LegacyThemeImpl implements ThemeInterface {
    @observable colorPrimary?: string = '';
    @observable colorSecondary?: string = BrandColors.red;
    @observable colorBackground?: string = '';
    @observable colorNegative?: string = '';
    @observable colorPostive?: string = '';
    @observable colorFont?: string;
    @observable fontFamilyPrimary?: string = '';
    @observable fontFamilySecondary?: string = '';
    @observable fontSizePrimary?: string = '';
    @observable fontSizeSecondary?: string;
    @observable backgroundImage?: BackgroundImage = { url: '' };
    @observable ext?: Ext;

    @action
    setValues(opts?: any): this {
        // console.log('opts', opts);
        // console.log('this', this);
        if (opts) {
            const ext: any = opts.ext;
            delete opts.ext;

            Object.assign(this as any, opts);

            // map over the ext to make sure the keys are observable
            Object.assign(this.ext, ext);
        }
        return this;
    }
}

export const TEXT_SHADOW_DEFAULT = {
    enabled: false,
    color: 'rgba(0,0,0,.3)',
    x: 1,
    y: 1,
    blur: 2,
};

export class WidgetLabelImpl implements WidgetLabelInterface {
    @observable titleText = 'Header';
    @observable enabled = false;
    @observable size: number = WIDGET_LABEL_SIZE.primary;
    @observable textShadow: TextShadowOptions = TEXT_SHADOW_DEFAULT;

    @action
    setValues(opts?: any): this {
        if (opts) {
            if (opts.enabled === null) {
                opts.enabled = this.enabled;
            }
            if (opts.titleText === null) {
                opts.titleText = this.titleText;
            }
            if (opts.size === null) {
                opts.size = WIDGET_LABEL_SIZE.primary;
            }
            if (opts.textShadow === null) {
                opts.textShadow = TEXT_SHADOW_DEFAULT;
            }
            Object.assign(this as any, opts);
        }
        return this;
    }
}

export const CreateFontFace = (font: WidgetFont) => {
    if (!font) {
        return;
    }
    const fields = WidgetFontCssDirectives(font);

    if (font.url) {
        fields.push(`src: url('${font.url}');`);
    }

    return `
    @font-face {
        ${fields.join('\n')}
    }`;
};

export const WidgetFontCssDirectives = (font: WidgetFont) => {
    const fields = [];
    fields.push(`font-family: "${font.family}";`);

    if (font.weight) {
        fields.push(`font-weight: ${font.weight};`);
    }

    if (font.style) {
        fields.push(`font-style: ${font.style};`);
    }

    return fields;
};

export class WidgetFontImpl {
    @observable samId?: string; // id from Sprinklr Core SAM
    @observable name?: string;
    @observable description?: string;
    @observable family?: string;
    @observable subFamily?: string;
    @observable weight?: FontWeight;
    @observable style?: FontStyle;
    @observable url?: string;
    @observable textTransform?: FontCase;

    static readonly graphQLFields: string = `
                samId
                name
                description
                family
                subFamily
                weight
                style
                url
    `;
}

type WidgetOptionsUnion =
    | BarChartWidgetOptions
    | StackedBarChartWidgetOptions
    | PieChartWidgetOptions
    | BubbleChartWidgetOptions
    | CountUpWidgetOptions
    | LegendWidgetOptions
    | LineChartWidgetOptions
    | MetricWidgetOptions
    | MultiMetricWidgetOptions
    | MetricValueWidgetOptions // old
    | MetricValueLockupWidgetOptions // old
    | PostsWidgetOptions
    | PostsWindowWidgetOptions
    | ProfileWidgetOptions
    | RankedListWidgetOptions
    | RankedTableWidgetOptions
    | TreeMapWidgetOptions
    | WordCloudWidgetOptions
    | WorldHeatMapWidgetOptions
    | MetricRollupWidgetOptions
    | HeatMapTableChartWidgetOptions
    | SmallMultiplesPieChartWidgetOptions
    | CustomContentWidgetOptions
    | AutoAlertsWidgetOptions
    | SunburstWidgetOptions
    | DataTableWidgetOptions;

export class WidgetImpl implements Widget<WidgetOptionsUnion> {
    @observable id: string;
    @observable name: string;
    @observable type: WidgetTypeId;
    @observable userAssetId?: string;
    @observable userAssetContentUrl?: string;
    @observable classes: string;
    @observable theme: MergedLegacyTheme;
    @observable position: WidgetPosition;
    @observable label: WidgetLabelInterface;
    @observable css: string;
    @observable js: string;
    @observable postRequests: PostsFormatRequest[];
    @observable postsRequest: PostsRequest;
    @observable analyticsRequests: AnalyticsRequest[];
    @observable analyticsResults: AnalyticsResult[];
    @observable profileRequests: ProfileRequest[];
    @observable trendRequests: TrendRequest[];
    @observable children: Widget[];
    @observable useGlobalTimePeriod?: boolean;
    @observable useGlobalDataSources?: boolean;
    @observable globalDataSourceName?: DataSourceName;
    @observable dataSourceNames?: DataSourceNames;
    @observable options: WidgetOptionsUnion;

    static readonly graphQLFields: string = ``;
}

export const WidgetSnippet = `
    widget {
        ...widgetRootNodeType
    }
`;

export const WidgetFragments = `
    fragment widgetRootNodeType on Widget {
            children {
                ...widgetNodeType1
            }
            ...widgetLeafType
    }
    fragment widgetNodeType1 on Widget {
            children {
                ...widgetNodeType2
            }
            ...widgetLeafType
    }
    fragment widgetNodeType2 on Widget {
            children {
                ...widgetNodeType3
            }
            ...widgetLeafType
    }
    fragment widgetNodeType3 on Widget {
            children {
                ...widgetNodeType4
            }
            ...widgetLeafType
    }
    fragment widgetNodeType4 on Widget {
            children {
                ...widgetLeafType
            }
            ...widgetLeafType
    }
    fragment widgetLeafType on Widget {
            id name type css js
            userAssetId
            userAssetContentUrl
            classes
            useGlobalTimePeriod
            useGlobalDataSources
            globalDataSourceName
            options
            theme {
                ...legacyThemeType
            }
            position {
                ...positionType
            }
            analyticsRequests {
                ...analyticsRequestType
            }
            profileRequests {
                ...profileRequestType
            }
            postRequests
            trendRequests {
                ...trendRequestType
            }
            label {
                ...widgetLabelType
            }
    }
    fragment analyticsRequestType on analyticsRequest {
        report
        reportingEngine
        limit
        sorts { heading order }
        includeTotal
        timeField
        timePeriods {
            duration startTime endTime timeZone key previousPeriod wholePeriods xValue
        }
        groupBys {
            heading limit limitType dimensionName groupType details
        }
        filters {
            dimensionName filterType values details
        }
        projections {
            heading measurementName aggregateFunction details
        }
        additional {
            TARGET_LANG_CODE
            Currency
            slaConfig {
                id
                name
                assetClass
                assetId
                slaIntervals {
                    from
                    to
                }
                slaObjective
                complianceTarget
                tzOffset
                country
                timezone
                workingHours {
                    MONDAY
                    TUESDAY
                    WEDNESDAY
                    THURSDAY
                    FRIDAY
                    SATURDAY
                    SUNDAY
                }
                inactiveDays {
                    name
                    inactiveHours
                }
                applyWorkingHours
                deleted
                locked
            }
        }
    }
    fragment profileRequestType on profileRequest {
        reportingEngine
        report
        page
        pageSize
        groupBys {
            heading dimensionName groupType details
        }
        sorts {
            heading
            order
        }
        timePeriod {
            duration
            startTime
            endTime
            timeZone
            key
            previousPeriod
            wholePeriods
            xValue
        }
        filters {
            dimensionName
            filterType
            values
            details
        }
    }
    fragment trendRequestType on trendRequest {
        source
        woeid
        limit
        country
    }
    fragment positionType on WidgetPosition {
        left
        right
        top
        bottom
        width
        height
        portrait { left top width height }
        landscape { left top width height }
        backgroundColor
        display
        maxWidth
        maxHeight
    }
    fragment legacyThemeType on WidgetTheme {
        colorPrimary
        colorSecondary
        colorBackground
        colorNegative
        colorPostive
        colorFont
        fontFamilyPrimary
        fontFamilySecondary
        fontSizePrimary
        fontSizeSecondary
        ext
        backgroundImage {
            url previewUrl samId name description
            backgroundRepeat backgroundSize
        }
        fontPrimary {
            ${WidgetFontImpl.graphQLFields}
        }
        fontSecondary {
            ${WidgetFontImpl.graphQLFields}
        }
        ${ThemeFields}
    }
    fragment widgetLabelType on WidgetLabel {
        titleText
        subTitleText
        classes
        overlap
        layout
        size
        enabled
        icon
        textShadow { enabled x y blur color }
    }`;
