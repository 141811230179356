import { computed, action } from 'mobx';
import { State } from '@sprinklr/stories/post/Post';
import { Source } from './Source';
import { Format } from './Format';
import { FormatWindowOptions, PostsFormatRequest } from '@sprinklr/stories/post/PostsFormatRequest';
import { PlayerInterval } from '../../utils/PlayerInterval/PlayerInterval';
import SignalService from '../../services/SignalService/SignalService';
import ObjectUtils from '../../utils/ObjectUtils/ObjectUtils';

const PRIMARY = 0;
const INTERLEAVE = 1;

export class FormatWindow<T extends State> extends Format<T> {
    private options: FormatWindowOptions;
    private itemsUnique: T[][] = [null, null]; // Only used if not enough posts for window size... or shuffling
    private itemsInterleaved: T[] = null;
    private interleave = false;
    private uniquePostsEndSequence = false;

    constructor(
        request: PostsFormatRequest,
        sources: Source<T, any>[],
        options: FormatWindowOptions,
        uniquePostsEndSequence: boolean,
        signalService: SignalService
    ) {
        super(request, sources, signalService);

        // Formatter can modify options, so keep copy for that possibility
        this.options = ObjectUtils.copy(options);

        if (sources.length === 2) {
            this.interleave = true;
            this.itemsInterleaved = [];
        }

        this.uniquePostsEndSequence = uniquePostsEndSequence;

        this.options.padding = options.padding || 2;
        this.options.injectAt = options.injectAt || 1;
        this.options.guard = options.guard || 0;
        this.options.interleave = options.interleave || 0;

        if (options.offsetCenter) {
            this._offset = options.offsetCenter;
        }

        this.signalService.add(Source.changed, this.onSourceChanged);
    }

    destroy() {
        this.signalService.remove(Source.changed, this.onSourceChanged);
    }

    isActive(offset: number): boolean {
        return offset === this.options.padding;
    }

    set offset(offset: number) {
        const posts = this.getPrimary();

        this._offset = offset;
        if (this._offset < 0) {
            this._offset = posts.length - 1;
        } else if (this._offset >= posts.length) {
            this._offset = 0;
        }
    }

    @action offsetDecrement() {
        const posts = this.getPrimary();
        this._offset--;

        if (this._offset < 0) {
            if (this.options.shouldFireEvents) {
                this.signalService.dispatch(PlayerInterval.sequenceBegin);
            }
            this._offset = !posts.length ? 0 : posts.length - 1;
        }
    }

    @action offsetIncrement() {
        const posts = this.getPrimary();
        this._offset++;

        if (this.options.shouldFireEvents && this._offset >= this.getPrimaryCount()) {
            this.signalService.dispatch(PlayerInterval.sequenceEnd, {
                dedupe: true,
                posts,
                offset: this._offset,
            });
        }

        if (this._offset >= posts.length) {
            this._offset = 0;
        }
    }

    @computed get items(): T[] {
        const result: T[] = [];

        // Get mobx to react to changes in these variables
        if (this.itemsChanged && this._offset >= 0) {
            const allLoaded = this.sources.every(source => source.isLoaded());
            if (allLoaded) {
                const posts = this.getPrimary();
                if (posts.length) {
                    this.buildWindow(posts, result);
                }
            }
        }

        return result;
    }

    @action setSource(whichSource: number, source: Source<any, any>): void {
        if (whichSource >= 0 && whichSource < this.sources.length) {
            this.sources[whichSource] = source;
            this.itemsInternal[whichSource].length = 0;
            this.offset = 0;
        } else {
            console.log('ERROR: Source ' + whichSource + " isn't defined for FormatWindow");
        }
    }

    private getItems(offset: number): T[] {
        return this.itemsUnique[offset] ? this.itemsUnique[offset] : this.itemsInternal[offset];
    }

    private getPrimary(): T[] {
        return this.interleave ? this.itemsInterleaved : this.getItems(PRIMARY);
    }

    private getPrimaryCount(): number {
        const posts = this.uniquePostsEndSequence ? this.itemsInternal[PRIMARY] : this.getPrimary();
        return posts.length;
    }

    // If we have less posts than our overall window size, then duplicate the posts,
    // each with distinct "unique" values, and use that array within
    // buildWindow()
    private buildUniqueItems(sources: T[][]) {
        let uniqueMap: { [id: number]: number };
        let item: T;
        let offset;
        const isShuffle = this.isShuffle();

        const getItem = (items: T[]) => {
            if (offset < 0) {
                offset = length - 1;
            } else if (offset >= items.length) {
                offset = 0;
            }

            item = items[offset];

            // If we have a duplicate already in our window array, make a shallow copy of the
            // post and give it a different unique value.
            if (uniqueMap[item[this.uniqueKey]]) {
                item = Object.assign({}, item);
                item.unique += '_' + uniqueMap[item[this.uniqueKey]]++;
            } else {
                uniqueMap[item[this.uniqueKey]] = 1;
            }

            offset++;

            return item;
        };

        // +1 more than window size.  This allows Video components to get destroyed
        //   https://sprinklr.atlassian.net/browse/DISPLAY-1743
        const count = (this.options.padding << 1) + 2;

        for (let x = 0; x < sources.length; x++) {
            // Maybe should always do max(count, len)? But definitely when shuffling. Issue is when shuffling we're
            // piggybacking on the mechanism used for handling post vs. window size shortfalls, and that mechanism winds
            // up with a truncated list of posts for building the window out of. When there aren't enough posts in the
            // first place that's fine, but when we're just bastardizing the itemsUnique[] stuff for shuffling, we still
            // need to have all the posts!
            const itemsUniqueTargetSize = isShuffle ? Math.max(count, sources[x].length) : count;

            if (sources[x].length && (isShuffle || sources[x].length < count)) {
                offset = 0;
                uniqueMap = {};
                this.itemsUnique[x] = [];

                for (let y = 0; y < itemsUniqueTargetSize; y++) {
                    this.itemsUnique[x].push(getItem(sources[x]));
                }

                if (isShuffle) {
                    this.shuffle(this.itemsUnique[x]);
                }
            } else {
                this.itemsUnique[x] = null;
            }
        }
    }

    private interleaveSources() {
        let item: T;
        let offset = 0;
        const interstitials = this.itemsInternal[INTERLEAVE];

        const getInterstitial = (direction: number): T => {
            if (interstitials.length) {
                item = interstitials[offset];

                offset += direction;

                const count = interstitials.length;
                if (offset < 0) {
                    offset = count - 1;
                } else if (offset >= count) {
                    offset = 0;
                }

                return item;
            } else {
                return null;
            }
        };

        // Add the post object for our current array
        let injectInterstitial = this.options.interleave;

        this.itemsInterleaved.length = 0;

        const count = this.getItems(PRIMARY).length;
        let multiplier = 1;

        // If we have less pimary posts then we have per the interleave interval,
        // pad out our interleaved array with dups so we can do the interleave correctly
        if (interstitials && count && interstitials.length >= count) {
            multiplier = Math.floor(interstitials.length / count);
        }

        // Build out our interleaved post array
        for (let x = 0; x < count * multiplier; x++) {
            item = this.getItems(PRIMARY)[x % count];
            this.itemsInterleaved.push(item);

            if (--injectInterstitial === 0) {
                item = getInterstitial(1);
                if (item) {
                    this.itemsInterleaved.push(item);
                }

                injectInterstitial = this.options.interleave;
            }
        }
    }

    private buildWindow(posts: T[], result: T[]) {
        const length = posts.length;
        let offset = this._offset,
            baseOffset = this._offset;

        const getItem = (direction: number) => {
            offset += direction;

            if (offset < 0) {
                offset = length - 1;
            } else if (offset >= length) {
                offset = 0;
            }

            return posts[offset];
        };

        // Add the post object for our current array
        let post = getItem(0);
        result.push(post);

        // Add post objects backwards through the post array, looping around as we do
        for (var x = 0; x < this.options.padding; x++) {
            post = getItem(-1);
            result.unshift(post);
        }

        // Add post objects forwards through the post array, looping around as we do
        offset = baseOffset;

        for (x = 0; x < this.options.padding; x++) {
            post = getItem(1);
            result.push(post);
        }
    }

    // Fired when a source's data changes
    private onSourceChanged = (type: string, source: Source<any, any>) => {
        const offset = this.sources.indexOf(source);
        if (offset !== -1) {
            // Find out what posts were added and/or removed, if any
            const changed = this.diffs[offset].compare(source.diff);
            if (changed) {
                this.diffs[offset].sync(this.itemsInternal[offset], changed);
                this.buildUniqueItems(this.itemsInternal);

                if (this.interleave) {
                    this.interleaveSources();
                }

                // Trigger mobx recomputation for items()
                this.itemsChanged++;
            }
        }
    };
}
