import { action, computed, observable } from 'mobx';
import { setObservable } from 'utils/Mobx/Utils';
import { AnalyticsEngine } from '@sprinklr/stories/analytics/AnalyticsRequest';
import { PostsRequest } from '@sprinklr/stories/post/PostsRequest';
import { PostsFormatRequest } from '@sprinklr/stories/post/PostsFormatRequest';
import MetaInfoService from 'services/MetaInfoService/MetaInfoService';
import PostsService, { MonitoringDashboard } from '@sprinklr/stories-services/PostsService/PostsService';
import { WidgetFilter } from './Filter/WidgetFilter';
import { WidgetFilterPosts } from './Filter/WidgetFilterPosts';
import { WidgetSort } from './Sort/WidgetSort';
import { WidgetRequestType } from './WidgetRequest';
import { WidgetRequestAnalytics } from './WidgetRequestAnalytics';
import { WidgetMetric } from './Metric/WidgetMetric';

export class WidgetRequestPosts extends WidgetRequestAnalytics {
    dashboards: MonitoringDashboard[];

    @observable
    private loading = true;

    constructor(metaInfoService: MetaInfoService, request?: PostsFormatRequest | PostsRequest) {
        super(metaInfoService, request);

        this.createDataIfNeeded(request);

        metaInfoService.loadDashboards();
        if (this.getRequest().source === 'LISTENING_COLUMN') {
            this.loadDashboards();
        }
    }

    @computed
    get isIncomplete(): boolean {
        const request: PostsRequest = this.getRequest() as any;

        if (request.source === 'LISTENING_COLUMN') {
            return request.columnId == null || !request.columnId.length;
        } else {
            return super.getIsIncomplete();
        }
    }

    getRequest(): PostsRequest {
        return (this.request as any).sources[0].id;
    }

    getOptions(): any {
        return (this.request as any).sources[0].options;
    }

    getFormatOptions(): any {
        return (this.request as any)?.format?.options;
    }

    get type(): WidgetRequestType {
        return 'posts';
    }

    get engine(): any {
        const request: PostsRequest = this.getRequest();

        if (request.source === 'LISTENING_COLUMN') {
            return 'MONITORING_DASHBOARD';
        } else {
            return request.reportingEngine;
        }
    }

    private static getSource(value: AnalyticsEngine): any {
        if ((value as any) === 'CAMPAIGN') {
            return 'CAMPAIGN';
        } else if ((value as any) === 'MONITORING_DASHBOARD') {
            return 'LISTENING_COLUMN';
        } else {
            return 'REPORTING';
        }
    }

    @action
    setEngine(engine: AnalyticsEngine): void {
        if ((engine as any) === '' || typeof engine === 'undefined') {
            engine = null;
        }

        const request: PostsRequest = this.getRequest();
        const source = WidgetRequestPosts.getSource(engine);

        if (!request.reportingEngine) {
            request.reportingEngine = null;
        }

        if (source !== request.source || engine !== request.reportingEngine) {
            request.source = source as any;

            if (engine === "MONITORING_DASHBOARD") {
                request.columnId = null;
                request.reportingEngine = engine as any;

                this.sources.length = this.filters.length = request.filters.length = 0;
                WidgetFilterPosts.create(this, request);

                this.loadDashboards();

            } else if (engine !== null) {
                super.setEngine(engine as any);

                // clear sort so taht setSort can repopulate it
                this.sorts.length = 0;
                request.sorts.length = 0;

                if ('PAID' === engine) {
                    request.projections = [
                        {
                            heading: 'spent',
                            measurementName: 'spent',
                            aggregateFunction: 'SUM',
                            details: {
                                dataType: 'CURRENCY',
                            },
                        },
                    ];
                } else {
                    request.projections.length = 0;
                }
            } else {
            }

            this.setSort();
            this.setReport();
        }
    }

    @action
    setReport(value?: string) {
        const request: PostsRequest = this.getRequest();
        let engine = request.reportingEngine;

        if (!engine || engine === 'MONITORING_DASHBOARD') {
            engine = null;
        }

        if (value) {
            request.report = value;
        } else if (
            engine !== null &&
            engine in PostsService.engines &&
            PostsService.engines[engine].defaultReport
        ) {
            // Set the default report
            request.report = PostsService.engines[engine].defaultReport;
        }
    }

    get isLoading() {
        return this.loading;
    }

    get limit(): number {
        return this.getRequest().pageSize || 20;
    }

    setLimit(value: number): void {
        this.getRequest().pageSize = value;
    }

    getDashboard(columnId?: string): MonitoringDashboard {
        const request = this.getRequest();

        for (let x = 0; x < this.dashboards.length; x++) {
            for (let y = 0; y < this.dashboards[x].columns.length; y++) {
                if (request.columnId === this.dashboards[x].columns[y].id) {
                    return this.dashboards[x];
                }
            }
        }

        return null;
    }

    getColumn(dashboard: MonitoringDashboard, columnId?: string): any {
        if (dashboard) {
            const request = this.getRequest();

            for (let x = 0; x < dashboard.columns.length; x++) {
                if (request.columnId === dashboard.columns[x].id) {
                    return dashboard.columns[x];
                }
            }
        }

        return null;
    }

    @action
    addFilter(filter?: WidgetFilter): WidgetFilter {
        if ((this.engine as any) !== 'MONITORING_DASHBOARD') {
            super.addFilter(filter);
        }

        return filter;
    }

    @action
    deleteFilter(filter: WidgetFilter): void {
        if ((this.engine as any) !== 'MONITORING_DASHBOARD') {
            super.deleteFilter(filter);
        }
    }

    @action
    setSort() {
        const request = this.getRequest();

        if (
            request.reportingEngine &&
            request.reportingEngine in PostsService.engines &&
            (!request.sorts || request.sorts.length === 0)
        ) {
            request.sorts = request.sorts || [];

            if (!PostsService.engines[request.reportingEngine].defaultSortKey) {
                action(async () => {
                    const result = await this.metaInfoService.searchMetrics(
                        request.reportingEngine,
                        null,
                        0,
                        1
                    );

                    if (result && result.fieldResponse.length > 0) {
                        const firstMetric = result.fieldResponse[0].field;
                        this.addElement(
                            this.sorts,
                            new WidgetSort(this as any, {
                                heading: firstMetric?.fieldName,
                                order: 'DESC',
                            }),
                            this.getRequest().sorts
                        );
                        this.setReport();
                    }
                });
            }
        }
    }

    @action
    sync(setEngine?: true) {
        const request: PostsRequest = this.getRequest();

        if (request.source === 'REPORTING' || request.source === 'CAMPAIGN') {
            super.sync(setEngine);
        } else {
            this.sources.length = this.filters.length = 0;
            WidgetFilterPosts.create(this, request);

            this.dashboards = null;
            this.loadDashboards();
        }
    }

    @action
    protected initialize() {
        super.initialize();

        const request: PostsRequest = this.getRequest();
        if (!request.reportingEngine || !request.reportingEngine.length) {
            WidgetFilterPosts.create(this, request);
        }

        if ('PAID' === this.engine) {
            this.metrics = [new WidgetMetric(this)];
        } else {
            this.metrics = [];
        }

        if (request.projections) {
            this.metrics = request.projections.map(
                projection => new WidgetMetric(this, projection)
            );
        }

        // Filters are part of analytics side of posts, so if not present,
        // add an observable version here.
        if (!request.filters) {
            setObservable(request, 'filters', []);
        }

        this.setSort();
        this.setReport();
    }

    @action
    protected createDataIfNeeded(request: PostsFormatRequest | PostsRequest) {
        if (!request) {
            request = {
                sources: [
                    {
                        type: 'posts',
                        id: {
                            source: 'LISTENING_COLUMN',
                            columnType: null,
                            columnId: null,
                            timePeriod: {
                                key: 'last_7_days',
                                timeZone: 'America/Los_Angeles',
                            },
                            filters: [],
                            sorts: [
                                {
                                    heading: null,
                                    order: 'DESC',
                                },
                            ],
                        },
                        options: null,
                    },
                ],
                format: null,
            } as PostsFormatRequest;

            this.request = observable(request);
        }
    }

    @action
    private loadDashboards() {
        if (!this.dashboards) {
            this.loading = true;

            MetaInfoService.loaderDashboards.then(
                action((dashboards: MonitoringDashboard[]) => {
                    this.dashboards = dashboards;

                    const dashboard = this.getDashboard();
                    if (dashboard) {
                        this.sources[0].setValue(
                            dashboard.id,
                            dashboard.name
                        );

                        const column = this.getColumn(dashboard);
                        if (column) {
                            this.sources[1].setValue(column.id, column.name);
                        }
                    }

                    this.loading = false;
                }),
                action(() => {
                    this.loading = false;
                })
            );
        }
    }
}
