import { scaleLog } from '@vx/scale';
import DataSet from '@sprinklr/stories/analytics/DataSet';
import { FieldType } from '@sprinklr/stories/reporting/types';
import { normalizeLabel, sanitizeString } from 'utils/NormalizeLabel/NormalizeLabel';
import { GetWidgetTypeStyles } from 'models/Widget/WidgetType';
import { toJS } from 'mobx';
import {
    generateColorPalette,
    getColor,
    getSocialColor,
} from 'utils/GenerateColors/GenerateColors';
import { StackedBarChartWidgetOptions, StackedBarChartWidgetOptionsImpl } from './options';
import _clone from 'lodash/clone';
import TopicTheme from '@sprinklr/stories/analytics/TopicTheme';
import { styler } from 'utils/GenerateStyles/GenerateStyles';
import TemporalDimension from '@sprinklr/stories/analytics/TemporalDimension';
import { getEndianTime } from 'utils/Number/NumberUtils';
import { LabelDateFormats, LegendItem, SecondAxis } from './types';

export const prepareStackedBarchartData = (
    dataSet: DataSet
): {
    dataSet: DataSet;
    firstDimensionIndex: number;
    metricName: string;
    type: FieldType;
    secondAxis: SecondAxis;
} => {
    if (!dataSet) {
        return;
    }
    const firstMetric = dataSet.getFirstMetric();
    if (!firstMetric) {
        return;
    }

    const firstMetricIndex = dataSet.getMetricIndex(firstMetric);

    const firstDimension = dataSet.getFirstDimension();
    if (!firstDimension) {
        return;
    }

    const firstDimensionIndex = dataSet.getDimensionIndex(firstDimension);
    const secondDimension = dataSet.dimensions[1];

    const secondAxis: { keys: SecondAxis['keys']; type: FieldType } = secondDimension
        ? {
              keys: dataSet.categories(secondDimension, val => val),
              type: secondDimension?.type ?? 'STRING',
          }
        : dataSet.metrics.length > 1
        ? {
              keys: dataSet.metrics.map(metric => metric.name),
              type: 'STRING',
          }
        : null;

    const metricName = normalizeLabel(
        dataSet.metrics && dataSet.metrics.length === 1 ? dataSet.metrics[0].name : ''
    );

    const type: FieldType =
        dataSet.metrics[dataSet.metrics && dataSet.metrics.length < 2 ? 0 : firstMetricIndex]?.type;

    return { dataSet, firstDimensionIndex, metricName, type, secondAxis };
};

export const getStackedBarChartCss: GetWidgetTypeStyles = (
    mergedTheme,
    dataSet: DataSet,
    widget,
    cssPrefix: string
): string => {
    let output = '';
    if (!mergedTheme || !dataSet || !widget.options) {
        return output;
    }

    const computedBarchart = prepareStackedBarchartData(toJS(dataSet));
    if (!computedBarchart) {
        return output;
    }

    const secondAxisKeys = computedBarchart.secondAxis ? computedBarchart.secondAxis.keys : null;

    const colors =
        generateColorPalette(mergedTheme, secondAxisKeys?.length ?? 0, false, widget.theme) || [];
    const legend: { [key: string]: LegendItem } = getLegend(computedBarchart.secondAxis, colors);

    const {
        colorPalette: { type },
    } = mergedTheme;

    const {
        useThemeColor,
        legendNameSize,
        metricValueOpacity,
        spacing,
    } = widget.options as StackedBarChartWidgetOptions & StackedBarChartWidgetOptionsImpl;

    Object.keys(legend)?.map((key, index: number) => {
        const legendItem = legend[key];
        const label = sanitizeString(_clone(legend[key]).toString()); // Don't clobber the key, if it is a TopicTheme.
        let color =
            type === null || type === 'solid'
                ? getSocialColor(label, mergedTheme)
                : getColor(label, legend[key].color);

        if (useThemeColor && legendItem.label instanceof TopicTheme && legendItem.label.toColor()) {
            color = legendItem.label.toColor();
        }

        output += `${cssPrefix} .segment_label_${label} { background-color: ${color}; }`;
        output += `${cssPrefix} .legend_item_label_${label} .legend_item_swatch { background-color: ${color}; }`;
    });

    output += `${cssPrefix} .legend_item_name_size { font-size: ${legendNameSize * 0.07}em; } `;
    output += `${cssPrefix} .segment_ratio { opacity: ${metricValueOpacity * 0.01}; } `;

    // Calculate how many bars/columns the chart has
    const itemCount =
        (computedBarchart?.dataSet?.rows?.length || 1) / (secondAxisKeys?.length || 1);

    if (itemCount > 20) {
        output += `${cssPrefix} .segment_ratio { opacity: 0 } `;
    }

    output += getDynamicMargin(itemCount, spacing, cssPrefix);

    return output;
};

export const sanitizeKeys = (keys): string[] => {
    return keys && keys.map(key => sanitizeString(key.toString()));
};

export const getDynamicMargin = (itemCount, spacing, cssPrefix) => {
    let output = '';
    // Backwards compatibility for charts with less than 8 bars/columns
    const maxItemsWithDefaultMargin = 8;
    const legacyMultiplier = 0.1; // the previous hard coded multiplier
    const minMultiplier = 0.005; // min amount of margin for crazy dense histogram charts

    const getMultiplierScale = scaleLog({
        domain: [maxItemsWithDefaultMargin, 50],
        range: [0.05, 0],
    });
    // If we have more that 8 items we switch into this new mode of using the multiplier scale
    const calculatedMultiplier =
        itemCount <= maxItemsWithDefaultMargin ? legacyMultiplier : getMultiplierScale(itemCount);
    const multiplier = Math.max(calculatedMultiplier, minMultiplier);

    output += `${cssPrefix} .bar_chart_inner.vertical .bar { margin: ${styler(
        spacing,
        multiplier,
        'em 0',
        ''
    )}; } `;
    output += `${cssPrefix} .bar_chart_inner.horizontal .bar { margin: ${styler(
        spacing,
        multiplier,
        'em',
        '0em'
    )}; } `;

    return output;
};

export const getLegend = (
    secondAxis: SecondAxis,
    colors?: string[],
    legendDateFormat?: LabelDateFormats
): { [key: string]: LegendItem } => {
    const timeRegex = new RegExp(/[0-9]{2}:[0-9]{2}/); // example 01:00
    // this makes sure the times are sorted from 0 to 23
    if (
        secondAxis?.keys[0] instanceof TemporalDimension &&
        timeRegex.test(secondAxis?.keys[0].toString())
    ) {
        secondAxis?.keys.sort();
    }

    return (
        secondAxis.keys?.reduce((legend, axisKey, index) => {
            const label =
                secondAxis.type === 'TIMESTAMP' && legendDateFormat !== 'none'
                    ? getEndianTime(secondAxis.keys[index] as any, legendDateFormat)
                    : secondAxis.keys[index].toString();

            legend[index] = {
                color: colors?.[index],
                label: label.replace(/_/g, ' '),
            };
            return legend;
        }, {}) ?? {}
    );
};
